import React, { MouseEvent, useState } from "react";
import { HeadCell, Order } from "./type";
import { Table, TableContainer } from "@mui/material";
import HeaderTable from "./HeaderTable";
import BodyTable from "./BodyTable";

interface Props {
  headerTableCells: HeadCell[];
  bodyTableCells: any[];
  hasCheckbox?: boolean;
  hasDeletion?: boolean;
  hasModification?: boolean;
  handleClickEvent?: (
    event: MouseEvent<unknown>,
    id: string,
    functionName: string,
  ) => void;
}

const CustomTable = ({
  headerTableCells,
  bodyTableCells,
  hasCheckbox = false,
  hasDeletion = false,
  hasModification = false,
  handleClickEvent,
}: Props) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState<readonly string[]>([]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = bodyTableCells.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleSelectClick = (event: MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  return (
    <TableContainer>
      <Table sx={{ minWidth: "100%" }}>
        <HeaderTable
          headCells={headerTableCells}
          numSelected={selected.length}
          onRequestSort={handleRequestSort}
          onSelectAllClick={handleSelectAllClick}
          order={order}
          orderBy={orderBy}
          rowCount={bodyTableCells.length}
          hasCheckbox={hasCheckbox}
        />
        <BodyTable
          bodyCells={bodyTableCells}
          order={order}
          orderBy={orderBy}
          selectedItems={selected}
          hasCheckbox={hasCheckbox}
          hasDeletion={hasDeletion}
          hasModification={hasModification}
          handleSelect={handleSelectClick}
          handleClickEvent={handleClickEvent}
        />
      </Table>
    </TableContainer>
    // TODO When adding pages on table add this
    // <TablePagination
    //       rowsPerPageOptions={[5, 10, 25]}
    //       component="div"
    //       count={rows.length}
    //       rowsPerPage={rowsPerPage}
    //       page={page}
    //       onPageChange={handleChangePage}
    //       onRowsPerPageChange={handleChangeRowsPerPage}
    //     />
  );
};

export default CustomTable;
