import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ResourceBox from "scenes/Campaigns/SeeUserModal/Coaching/Resources/ResourceBox";
import Box from "components/Box";
import {
  getLibraryResourcesRequest,
  updateCampaignResources,
} from "services/resource/actions";
import { getCampaignResourcesWithDetails } from "services/resource/selectors";
import { getCampaignResourcesStats } from "services/campaign/selectors";
import { sendMobileNavigationSocket } from "services/general/actions";
import { getSelectedClientId, getUserId } from "services/user/selectors";
import { useDispatch, useSelector } from "react-redux";
import LibraryResourcesModal from "scenes/Campaigns/SeeUserModal/Coaching/Resources/LibraryResourcesModal";
import NewResourceModal from "scenes/Campaigns/SeeUserModal/Coaching/Resources/NewResourceModal";
import BoxHeader from "components/BoxHeader";
import BoxTitle from "components/BoxTitle";
import i18n from "i18n";
import { AddButtonBox, LightButton, Loader } from "components";
import LibraryIcon from "scenes/Campaigns/SeeUserModal/Coaching/Resources/LibraryIcon";
import Colors from "constants/Colors";
import { useGetCampaignResourcesRequest } from "./hooks";
import { getSelectedCampaignId } from "services/campaign/selectors";
import PreviewSide from "scenes/CreateTrack/CreateActionTextStep/PreviewSide";
import ResourcePreviewBox from "scenes/Campaigns/CampaignScreen/ResourceSide/ResourcePreviewBox";
import StatBox from "scenes/Campaigns/CampaignScreen/StatBox";
import ViewIcon from "scenes/Campaigns/SeeUserModal/Coaching/Resources/ResourceBoxIcons/ViewIcon";
import StarIcon from "scenes/CreateTrack/StarIcon";
import CommentIcon from "scenes/Campaigns/CampaignScreen/ActionsSide/CommentIcon";

const useStyles = makeStyles(theme => ({
  main: {
    marginTop: 15,
    marginBottom: 15,
    height: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
  },
  body: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  withResources: {
    overflowY: "scroll",
    justifyContent: "flex-start",
  },
  firstResources: {
    marginTop: 45,
    alignSelf: "center",
    textAlign: "center",
    color: "#A8A8A8",
    fontSize: 18,
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  bold: {
    color: Colors.pureMain,
  },
  title: {
    flex: 1,
  },
  lightButton: {
    borderWidth: 2,
    paddingLeft: 15,
    paddingRight: 15,
    height: 42,
    marginRight: 15,
    "&:hover": {
      borderWidth: 2,
    },
    "&:focus": {
      borderWidth: 2,
    },
  },
  icon: {
    marginRight: 10,
  },
  noBold: {
    fontWeight: "normal",
  },
  box: {
    overflow: "auto",
    height: "-webkit-fill-available",
    flex: 2,
    display: "flex",
    marginRight: 20,
  },
  boxHeader: {},
  rightBox: {
    overflow: "hidden",
    width: "auto",
  },
  topDiv: {
    overflow: "auto",
    marginBottom: 10,
    position: "relative",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  bottomDiv: {
    width: "100%",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    marginBottom: 15,
  },
}));

const ResourceSide = () => {
  const classes = useStyles();
  const clientId = useSelector(getSelectedClientId);
  const userId = useSelector(getUserId);
  const campaignId = useSelector(getSelectedCampaignId);
  const dispatch = useDispatch();
  const resources = useSelector(getCampaignResourcesWithDetails);
  const [
    requesting,
    getCampaignResourcesRequest,
  ] = useGetCampaignResourcesRequest();

  const [isResourceModalVisible, openResourceModal] = useState(false);
  const [isLibraryModalVisible, openLibraryModal] = useState(false);
  const [
    isNewResourceModalOpenFromLibrary,
    setNewResourceModalOpenFromLibrary,
  ] = useState(false);
  const [resourceSelected, setResourceSelected] = useState({});

  const resourcesStats = useSelector(getCampaignResourcesStats);

  const onInit = () => {
    getCampaignResourcesRequest();
  };

  useEffect(onInit, []);

  const onOpenLibraryModal = () => {
    dispatch(
      getLibraryResourcesRequest({
        clientId,
        userId,
      }),
    );
    openLibraryModal(true);
  };

  const onSelectResource = resource => e => {
    e.stopPropagation();
    setResourceSelected(resource);
    onOpenResourceModal();
  };

  const onClickAddResource = resource => e => {
    e.stopPropagation();
    const newResources = [
      {
        ...resource,
        rating: 0,
        totalComments: 0,
        totalFeedbacks: 0,
        totalViews: 0,
        comment: "",
        comments: [],
        resourceId: resource._id,
      },
      ...resources,
    ];
    dispatch(updateCampaignResources({ campaignId, resources: newResources }));
  };

  const onClickRemove = resourceId => e => {
    e.stopPropagation();
    const newResources = resources.filter(el => el.resourceId !== resourceId);

    dispatch(updateCampaignResources({ campaignId, resources: newResources }));
  };

  const onOpenResourceModal = () => {
    openResourceModal(true);
    setNewResourceModalOpenFromLibrary(false);
  };

  const onOpenResourceModalFromLibrary = () => {
    openResourceModal(true);
    setNewResourceModalOpenFromLibrary(true);
  };

  const navigateMobileToLibrary = () => {
    dispatch(sendMobileNavigationSocket({ userId, route: "Library" }));
  };

  return (
    <div className={classes.main}>
      <LibraryResourcesModal
        onClickAdd={onClickAddResource}
        isVisible={isLibraryModalVisible}
        onClose={() => openLibraryModal(false)}
        onClickNewResource={onOpenResourceModalFromLibrary}
      />
      <NewResourceModal
        isCreatedFromLibrary={isNewResourceModalOpenFromLibrary}
        sideExplanation={{
          explanation: i18n.t("campaign-resource-explanation-text"),
        }}
        setResourceSelected={setResourceSelected}
        resourceSelected={resourceSelected}
        isVisible={isResourceModalVisible}
        onClose={() => openResourceModal(false)}
      />
      <div className={classes.topDiv}>
        <StatBox
          icon={<ViewIcon height={23} width={26} />}
          stat={resourcesStats.totalViews || 0}
          title={i18n.t("Views")}
          lightColor={"rgba(23, 175, 252, 0.22)"}
        />
        <StatBox
          icon={<StarIcon color={"#FCC21B"} sizePercentage={1} />}
          title={i18n.t("total-rating")}
          subTitle={` (${resourcesStats.totalFeedbacks || 0})`}
          stat={resourcesStats?.totalRating?.toFixed(1) || 0}
          lightColor={"rgba(252, 194, 27, 0.22)"}
        />
        <StatBox
          isLast
          icon={<CommentIcon color={"#FF9AC4"} sizePercentage={1.5} />}
          lightColor={"rgba(255, 154, 196, 0.22)"}
          stat={resourcesStats.totalComments || 0}
          title={i18n.t("comments")}
        />
      </div>
      <div className={classes.bottomDiv}>
        <Box className={classes.box}>
          <BoxHeader className={classes.boxHeader}>
            <BoxTitle className={classes.title}>
              {i18n.t("resources")}
              <span className={classes.noBold}> ({resources.length})</span>
            </BoxTitle>
            <LightButton
              icon={<LibraryIcon className={classes.icon} />}
              title={i18n.t("library")}
              className={classes.lightButton}
              onClick={onOpenLibraryModal}
            />
            <AddButtonBox title={i18n.t("add")} onClick={onOpenResourceModal} />
          </BoxHeader>
          <div
            className={`${classes.body} ${
              resources.length > 0 && classes.withResources
            }`}
          >
            {resources.length < 1 && (
              <Typography
                className={classes.firstResources}
                onClick={onOpenResourceModal}
              >
                <span className={classes.bold}>{i18n.t("add")} </span>
                {i18n.t("first-resources")}
              </Typography>
            )}
            {requesting ? (
              <Loader size={30} />
            ) : (
              resources.map((el, index) => (
                <ResourceBox
                  {...el}
                  resource={el}
                  key={index}
                  onClick={onSelectResource(el)}
                  onClickEdit={onSelectResource(el)}
                  onClickRemove={onClickRemove(el.resourceId)}
                  isLast={index === resources.length - 1}
                />
              ))
            )}
          </div>
        </Box>
        <Box className={classes.rightBox}>
          <PreviewSide
            title={i18n.t("resources-preview")}
            isPreviewVisible={true}
            qrCodeParams={{ route: "Library" }}
            onClickConnectMobile={navigateMobileToLibrary}
            phoneChildren={resources.map((el, index) => (
              <ResourcePreviewBox {...el} resource={el} key={index} />
            ))}
          />
        </Box>
      </div>
    </div>
  );
};

export default ResourceSide;
