import React from "react";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import i18n from "i18n";
import InvitationInfoBox from "./InvitationInfoBox";
import InvitationCoachEmailInput from "./InvitationCoachEmailInput";
import MagicCodeBox from "./MagicCodeBox";
import { getCampaignAccessCode } from "services/campaign/selectors";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  main: {
    marginTop: 35,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  title: {
    width: "100%",
    fontSize: 22,
    fontWeight: "bold",
    fontFamily: "Montserrat",
    marginBottom: 30,
  },
  bottomDiv: {
    marginTop: 35,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  or: {
    fontFamily: "Hind",
    fontSize: 18,
    textTransform: "uppercase",
    marginLeft: 40,
    marginRight: 35,
    color: "rgba(182, 182, 182, 1)",
  },
}));

const CoachInvitation = () => {
  const classes = useStyles();
  const accessCode = useSelector(getCampaignAccessCode);

  return (
    <div className={classes.main}>
      <Typography className={classes.title}>
        {i18n.t("invitation-by-email")}
      </Typography>
      <InvitationInfoBox />
      <div className={classes.bottomDiv}>
        <InvitationCoachEmailInput />
        <Typography className={classes.or}>{i18n.t("or")}</Typography>
        <MagicCodeBox accessCode={accessCode} />
      </div>
    </div>
  );
};

export default CoachInvitation;
