import React from "react";
import { Image } from "@react-pdf/renderer";

const UPLOADER_WIDTH = 250;
const UPLOADER_HEIGHT = 100;

const imageStyle = {
  height: UPLOADER_HEIGHT,
  width: "100%",
  maxWidth: UPLOADER_WIDTH,
  objectFit: "contain",
  "object-fit": "contain",
};

const divStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
};

interface Props {
  value: string;
}

const PdfImage = ({ value }: Props) => {

  return (
    <div style={divStyle}>
      {!!value && <Image src={value} source={value} style={imageStyle} />}
    </div>
  );
};

export default PdfImage;
