import React from "react";
import withStyles from "@mui/styles/withStyles";

const styles = theme => ({
  main: {},
});

const LibraryIcon = ({
  className = "",
  width = 15,
  height = 14,
  color = "#17AFFC",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 14"
      fill="none"
      className={className}
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M4.54452 0.0122884H1.07862C0.941173 -0.000817184 0.804016 0.0400884 0.696197 0.126343C0.588378 0.212599 0.518363 0.337431 0.500977 0.474407V13.8758H5.12216V0.474407C5.10478 0.337431 5.03476 0.212599 4.92694 0.126343C4.81912 0.0400884 4.68197 -0.000817184 4.54452 0.0122884Z"
        fill={color}
      />
      <path
        d="M14.2582 12.9932L11.0511 4.53644C11.0296 4.47969 10.9971 4.42774 10.9555 4.38356C10.9139 4.33938 10.8639 4.30383 10.8086 4.27894C10.7532 4.25405 10.6935 4.24031 10.6328 4.2385C10.5722 4.23669 10.5117 4.24686 10.455 4.26841L8.8191 4.90613V2.78501C8.8191 2.66244 8.77041 2.5449 8.68375 2.45824C8.59708 2.37157 8.47954 2.32289 8.35698 2.32289H6.04639V13.8759H8.8191V5.41446L12.0216 13.8759L14.2582 12.9932Z"
        fill={color}
      />
    </svg>
  );
};

export default withStyles(styles)(LibraryIcon);
