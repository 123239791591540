import React, { useCallback, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import {
  commentReviewRequest,
  getUserCampaignNextSelectedReview,
  getUserCampaignPreviousSelectedReview,
  getUserCampaignsListRequest,
  seeCommentsReviewRequest,
  validateReviewRequest,
} from "services/campaign/actions";
import {
  getUserId,
  getUserName,
  getUserPicture,
} from "services/user/selectors";
import { ReviewStatusType } from "./types";
import { push } from "connected-react-router";
import { getUserCampaignSelectedDoneStepFormated } from "services/campaign/selectors";
import ReviewContentAction from "./ReviewContentAction";
import ReviewHeader from "./ReviewHeader";
import ReviewInfoAction from "./ReviewInfo/ReviewInfoAction";
import CorrectorFeedback from "./CorrectorFeedback";
import { useParams } from "react-router";
import { getFileToUpload } from "services/upload/selectors";
import { cleanMessage, setReceiver } from "services/message/actions";
import { seeUserProfileRequest } from "services/manager/actions";
import {
  clearCoachingSession,
  getCoachingSessionRequest,
} from "services/coaching/actions";

const useStyles = makeStyles(theme => ({
  main: {
    overflow: "hidden",
    position: "fixed",
    width: "100%",
    height: "100%",
  },
  body: {
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
  },
}));

const ReviewPage = () => {
  const reviewSelected: any = useSelector(
    getUserCampaignSelectedDoneStepFormated,
  );
  const coachId = useSelector(getUserId);
  const coachName = useSelector(getUserName);
  const coachPicture = useSelector(getUserPicture);
  const fileToUpload = useSelector(getFileToUpload);

  const [correctorComment, setCorrectorComment] = useState("");
  const [selectedStatus, setSelectedStatus] = useState<ReviewStatusType>(
    reviewSelected?.isReviewValidated
      ? "validated"
      : reviewSelected?.status || "new",
  );

  const dispatch = useDispatch();
  // reviewId can be taken from params
  const { campaignId = "" }: any = useParams() || { campaignId: "" };

  const classes = useStyles();
  const {
    comment,
    files,
    _id,
    userId,
    createdAt,
    rating,
    donePercentage,
    isReviewValidated,
    firstName,
    lastName,
    picture,
    status,
  } = reviewSelected;
  const {
    title = {},
    content = {},
    skills = [],
    rating: globalRating,
    totalDone,
  } = reviewSelected?.stepId || {};
  const { comments = [] } = reviewSelected?.review || {};

  const mounComponent = useCallback(() => {
    dispatch(getUserCampaignsListRequest({ campaignId, page: 0, limit: 1000 }));
    dispatch(seeCommentsReviewRequest({ stepFeedbackId: _id }));
  }, [campaignId, _id, dispatch]);

  useEffect(() => {
    mounComponent();
  }, [mounComponent]);

  useEffect(() => {
    setSelectedStatus(
      isReviewValidated ? "validated" : reviewSelected?.status || "new",
    );
  }, [reviewSelected, isReviewValidated]);

  const handleChangeCorrectorComment = e => {
    const value = e.target.value;
    setCorrectorComment(value);
  };

  const handleClickBackButton = () => {
    dispatch(push(`/campaigns/${campaignId}`));
  };

  const handleSelectOption = (optionSelectedStatus: ReviewStatusType) => {
    setSelectedStatus(optionSelectedStatus);
  };

  const handleValidateClick = async () => {
    const stepFeedbackId = _id;

    let newMessage = null;

    if (!!fileToUpload.file || correctorComment) {
      newMessage = {
        text: correctorComment,
        isSeen: false,
        user: {
          _id: coachId,
          avatar: coachPicture,
          name: coachName,
        },
      };
    }

    dispatch(
      validateReviewRequest({
        selectedStatus,
        stepFeedbackId,
        userId,
        comment: newMessage,
      }),
    );
  };

  const handleSendComment = () => {
    if (!fileToUpload.file && !correctorComment) return;

    const newMessage = {
      text: correctorComment,
      isSeen: false,
      user: {
        _id: coachId,
        avatar: coachPicture,
        name: coachName,
      },
    };

    dispatch(
      commentReviewRequest({
        stepFeedbackId: _id,
        coachId,
        comment: newMessage,
        userId,
      }),
    );
    setCorrectorComment("");
  };

  const handleNextReview = () => {
    setCorrectorComment("");
    dispatch(getUserCampaignNextSelectedReview());
  };

  const handlePreviousReview = () => {
    setCorrectorComment("");
    dispatch(getUserCampaignPreviousSelectedReview());
  };

  const onClickUser = () => {
    dispatch(clearCoachingSession());
    dispatch(cleanMessage());
    dispatch(
      setReceiver({
        _id: userId,
        picture: picture,
      }),
    );
    dispatch(
      seeUserProfileRequest({
        userId: userId,
        managerId: coachId,
      }),
    );
    dispatch(
      getCoachingSessionRequest({
        userId: userId,
        coachId: coachId,
      }),
    );
    dispatch(push(`/user/${userId}`));
  };

  return (
    <div className={classes.main}>
      <ReviewHeader
        name={`${firstName} ${lastName}`}
        status={status}
        onClickUser={onClickUser}
        picture={picture}
        date={createdAt}
        onClickBackButton={handleClickBackButton}
        onPressNextReviewWithoutValidation={handleNextReview}
        onPressPreviousReviewWithoutValidation={handlePreviousReview}
      />
      <div className={classes.body}>
        <ReviewContentAction
          step={{
            title: title,
            content: content,
            skills,
          }}
          globalRating={globalRating}
          totalDone={totalDone}
        />
        <ReviewInfoAction
          userValidationComment={comment}
          comments={comments}
          date={createdAt}
          files={files}
          donePercentage={donePercentage}
          rating={rating}
          userCorrectedPicture={picture}
          userCorrectedName={`${firstName} ${lastName}`}
          userCorrectedId={userId}
        />
        <CorrectorFeedback
          onSelectOption={handleSelectOption}
          status={selectedStatus}
          onChangeCorrectorComment={handleChangeCorrectorComment}
          onSendComment={handleSendComment}
          comment={correctorComment}
          onPressNextReview={handleValidateClick}
          comments={comments}
        />
      </div>
    </div>
  );
};

export default ReviewPage;
