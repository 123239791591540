import React from "react";

const EmptyStarIcon = ({ sizePercentage, color = "white" }) => (
  <svg
    width={sizePercentage * 20}
    height={sizePercentage * 18}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 18 18"
    version="1.1"
  >
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.84438 17.9146C4.64224 17.9146 4.4415 17.8515 4.27019 17.7277C3.95107 17.4964 3.802 17.0988 3.88885 16.7153L5.0758 11.4867L1.05043 7.95659C0.754532 7.69828 0.641497 7.28921 0.762979 6.91491C0.884461 6.54131 1.21498 6.27681 1.60631 6.24064L6.93238 5.7571L9.03811 0.829266C9.19338 0.467213 9.54699 0.233398 9.93987 0.233398C10.3327 0.233398 10.6864 0.467213 10.8416 0.828421L12.9474 5.7571L18.2726 6.24064C18.6648 6.27597 18.9953 6.54131 19.1168 6.91491C19.2382 7.28851 19.1259 7.69828 18.83 7.95659L14.8046 11.486L15.9916 16.7144C16.0786 17.0988 15.9294 17.4964 15.6104 17.727C15.2921 17.9575 14.8677 17.9753 14.5325 17.7738L9.93987 15.0293L5.3472 17.7754C5.19193 17.8676 5.01893 17.9146 4.84438 17.9146V17.9146ZM9.93987 13.847C10.1144 13.847 10.2873 13.8939 10.4427 13.9861L14.777 16.5784L13.6568 11.6436C13.5769 11.2923 13.6961 10.9256 13.9675 10.688L17.7684 7.35467L12.7397 6.89802C12.3777 6.86494 12.0663 6.63732 11.9248 6.3037L9.93987 1.65332L7.95252 6.3044C7.8126 6.63577 7.50122 6.86339 7.14001 6.89647L2.11068 7.35312L5.9114 10.6865C6.18364 10.9248 6.30273 11.2908 6.22207 11.6429L5.10269 16.5776L9.43705 13.9861C9.59231 13.8939 9.76532 13.847 9.93987 13.847V13.847ZM6.8917 5.8524C6.8917 5.8524 6.8917 5.85324 6.89085 5.85395L6.8917 5.8524ZM12.9865 5.85015L12.9873 5.8517C12.9873 5.85085 12.9873 5.85085 12.9865 5.85015Z"
        fill={color}
      />
    </svg>
  </svg>
);

EmptyStarIcon.defaultProps = {
  sizePercentage: 1,
};

export default EmptyStarIcon;
