import React, { FC, useEffect } from "react";
import { Image } from "@react-pdf/renderer";
import makeStyles from '@mui/styles/makeStyles';
import Uploader from "components/Uploader";
import { useUploadPicture } from "scenes/Invoice/InvoicePage/useUploadPicture";
import { Invoice } from "scenes/Invoice/data/types";

interface Props {
  className?: string;
  placeholder?: string;
  value?: string;
  onChange?: (value: string) => void;
  pdfMode?: boolean;
  handleChange: (name: keyof Invoice, value: string) => void;
}

const UPLOADER_WIDTH = 250;
const UPLOADER_HEIGHT = 100;

const imageStyle = {
  height: UPLOADER_HEIGHT,
  width: "100%",
  maxWidth: UPLOADER_WIDTH,
  objectFit: "contain",
};

const divStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
};

const useStyles = makeStyles(theme => ({
  imageStyle: {
    height: UPLOADER_HEIGHT,
    width: "100%",
    maxWidth: UPLOADER_WIDTH,
    objectFit: "contain",
  },
}));

const EditableImage: FC<Props> = ({
  className,
  placeholder,
  value,
  onChange,
  pdfMode,
  handleChange,
}) => {
  const [uploading, picture, uploadPicture] = useUploadPicture();
  const classes = useStyles();

  const onPictureUploaded = async files => {
    await uploadPicture(files);
  };

  useEffect(() => {
    if (picture && !pdfMode) {
      handleChange("logo", picture);
    }
  }, [picture, handleChange, pdfMode]);

  return (
    <div style={divStyle}>
      {pdfMode && !!picture ? (
        <Image
          // cache={false}
          src={value}
          source={value}
          // allowDangerousPaths="true"
          style={imageStyle}
          // style={[{ height: 50, width: 200, border: "1px solid grey" }]}
          // style={compose("image " + (className ? className : ""))}
        />
      ) : (
        <Uploader
          isSmall
          className={classes.imageStyle}
          imageClassName={classes.imageStyle}
          picture={picture || value}
          width={UPLOADER_WIDTH}
          height={UPLOADER_HEIGHT}
          // className={classes.uploader}
          onFileUploaded={onPictureUploaded}
          uploading={uploading}
        />
      )}
    </div>
  );
};

export default EditableImage;
