import ErrorBoundary from "ErrorBoundary";
import moment from "moment";
import React, { Component } from "react";
import { withRouter } from "react-router";
import Snackbar from "scenes/Dashboard/Snackbar";
import { getCreateTrackRequest } from "services/createTrack/actions";
import { getIsLocatedOnTrack } from "services/createTrack/selectors";
import { getRouterQuery } from "services/general/selectors";
import { store } from "services/store";
import { TOKEN_REFRESH } from "services/token/constants";
import {
  getIsSuperAdmin,
  getSelectedClientId,
  getUserFirstName,
  getUserId,
  getUserPicture,
} from "services/user/selectors";
import socket from "./services/socket";

class App extends Component {
  constructor(props) {
    super(props);
    this.socket = socket;
    this.socket.on("request-init", this.handleSocketInit);
  }

  componentDidMount = async () => {
    const storeState = await store.getState();
    const user = storeState.user;
    const client = storeState.client;
    if (user._id) {
      window.Intercom("boot", {
        app_id: "f3rbos9o",
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phoneNumber,
        companies: [
          {
            id: user.client,
            size: client.companySize,
            name: client.organization,
          },
        ],
        name: `${user.firstName} ${user.lastName}`, // Full name
        email: `${user.email}`, // Email address
        created_at: `${user.createdAt}`, // Signup date as a Unix timestamp
      });
    } else {
      window.Intercom("boot", { app_id: "f3rbos9o" });
    }
    // check if refresh token
    setTimeout(() => this.checkRefreshToken(), 2000);
    this.refreshTokenInterval = setInterval(() => {
      this.checkRefreshToken();
    }, 1000 * 60 * 60);

    // Initial loader
    const loader = document.querySelector(".preloader");

    loader?.classList?.remove("preloader");
    loader?.classList?.add("loader-hide");
  };

  componentDidUpdate = prevProps => {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.Intercom("update");
    }
  };

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      //
      if (location.pathname.includes("/create")) {
        //
        //
        window.Intercom("trackEvent", "Viewed Creation Step Page");
      }
      window.Intercom("update");
    });
  }

  componentWillUnmount() {
    this.unlisten();
    clearInterval(this.refreshTokenInterval);
  }

  handleSocketInit = async () => {
    const storeState = await store.getState();
    const userId = getUserId(storeState);
    const isLocatedOnTrack = getIsLocatedOnTrack(storeState);

    this.socket.emit("admin-init", userId);

    // if was on library or track - repull
    if (isLocatedOnTrack) {
      const routerQuery = getRouterQuery(storeState);
      const { trackId } = routerQuery;
      store.dispatch(
        getCreateTrackRequest({
          trackId,
          isEditingCampaign: true,
          noNavigation: true,
        }),
      );
    }

    // join automatically clientId
    // join a selectedClientId
    const isSuperAdmin = getIsSuperAdmin(storeState);
    if (isSuperAdmin) {
      const selectedClientId = getSelectedClientId(storeState);
      const firstName = getUserFirstName(storeState);
      const picture = getUserPicture(storeState);
      this.socket.emit("admin-join-client", {
        clientId: selectedClientId,
        firstName,
        picture,
        userId,
      });
    }
  };

  checkRefreshToken = async () => {
    const storeState = await store.getState();
    if (storeState.token.expiresAt) {
      const diff = moment(storeState.token.expiresAt).diff(moment(), "days");
      if (diff <= 2) {
        await store.dispatch({ type: TOKEN_REFRESH });
      }
    }
  };

  render() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Snackbar />
        <ErrorBoundary>{this.props.children}</ErrorBoundary>
      </div>
    );
  }
}
export default withRouter(App);
