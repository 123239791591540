import React from "react";

const ConfidenceIcon = props => (
  <svg width="50px" height="61px" viewBox="0 0 50 61">
    <g
      id="Dashboard"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Desktop"
        transform="translate(-1185.000000, -358.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="003-shield" transform="translate(1185.000000, 358.000000)">
          <path
            d="M25,4.76309524 C24.3407143,4.76309524 23.8107143,5.29404762 23.8107143,5.95238095 C23.8107143,6.56511905 24.2716667,7.075 24.8809524,7.13547619 C25.5863095,7.20547619 26.1892857,6.65607143 26.1892857,5.95238095 C26.1892857,5.29309524 25.6583333,4.76309524 25,4.76309524 Z"
            id="Path"
          />
          <path
            d="M48.8095238,7.14285714 C45.527381,7.14285714 42.8571429,4.47261905 42.8571429,1.19047619 C42.8571429,0.533095238 42.3240476,0 41.6666667,0 L8.33333333,0 C7.67595238,0 7.14285714,0.533095238 7.14285714,1.19047619 C7.14285714,4.47261905 4.47261905,7.14285714 1.19047619,7.14285714 C0.533095238,7.14285714 0,7.67595238 0,8.33333333 L0,27.7607143 C0,42.9008333 9.86845238,56.4672619 24.6580952,60.9022619 C24.7696429,60.9355952 24.8847619,60.952381 25,60.952381 C25.1152381,60.952381 25.2305952,60.9355952 25.3420238,60.9021429 C39.8113095,56.5617857 50,43.2320238 50,27.7607143 L50,8.33333333 C50,7.67595238 49.4669048,7.14285714 48.8095238,7.14285714 Z M47.6190476,27.7607143 C47.6190476,41.9807143 38.545119,54.2954762 25,58.5170238 C11.5628571,54.3291667 2.38095238,41.9028571 2.38095238,27.7607143 L2.38095238,9.43904762 C6.02511905,8.91547619 8.91547619,6.02511905 9.43904762,2.38095238 L40.5609524,2.38095238 C41.0845238,6.02511905 43.974881,8.91547619 47.6190476,9.43904762 L47.6190476,27.7607143 Z"
            id="Shape"
          />
          <path
            d="M44.5252381,11.0107143 C42.042619,9.92345238 40.0765476,7.95738095 38.9892857,5.4747619 C38.7995238,5.04166667 38.3716667,4.76190476 37.8988095,4.76190476 L29.7595238,4.76190476 C29.1021429,4.76190476 28.5690476,5.295 28.5690476,5.95238095 C28.5690476,6.6097619 29.1021429,7.14285714 29.7595238,7.14285714 L37.1460714,7.14285714 C38.4038095,9.60488095 40.395119,11.5960714 42.8571429,12.8539286 L42.8571429,27.7607143 C42.8571429,39.4110714 35.8632143,49.4634524 25,53.487381 C14.1367857,49.4633333 7.14285714,39.4110714 7.14285714,27.7607143 L7.14285714,12.8539286 C9.60488095,11.5960714 11.5960714,9.60488095 12.8539286,7.14285714 L20.2404762,7.14285714 C20.8978571,7.14285714 21.4309524,6.6097619 21.4309524,5.95238095 C21.4309524,5.295 20.8978571,4.76190476 20.2404762,4.76190476 L12.1011905,4.76190476 C11.6283333,4.76190476 11.2004762,5.04178571 11.0107143,5.4747619 C9.92345238,7.95738095 7.95738095,9.92345238 5.4747619,11.0107143 C5.04178571,11.2004762 4.76190476,11.6284524 4.76190476,12.1011905 L4.76190476,27.7607143 C4.76190476,40.5241667 12.6560714,51.6479762 24.6028571,55.8757143 C24.7313095,55.9211905 24.8655952,55.9439286 25,55.9439286 C25.1344048,55.9439286 25.2686905,55.9211905 25.3971429,55.8757143 C37.3339286,51.6515476 45.2380952,40.5345238 45.2380952,27.7607143 L45.2380952,12.1011905 C45.2380952,11.6283333 44.9582143,11.2004762 44.5252381,11.0107143 Z"
            id="Path"
          />
          <path
            d="M25,17.3809524 C19.0921429,17.3809524 14.2857143,22.187381 14.2857143,28.0952381 C14.2857143,34.0030952 19.0921429,38.8095238 25,38.8095238 C30.9078571,38.8095238 35.7142857,34.0030952 35.7142857,28.0952381 C35.7142857,22.187381 30.9078571,17.3809524 25,17.3809524 Z M25,36.4285714 C20.405,36.4285714 16.6666667,32.6902381 16.6666667,28.0952381 C16.6666667,23.5002381 20.405,19.7619048 25,19.7619048 C29.595,19.7619048 33.3333333,23.5002381 33.3333333,28.0952381 C33.3333333,32.6902381 29.595,36.4285714 25,36.4285714 Z"
            id="Shape"
          />
          <path
            d="M30.6036905,24.872381 C30.1386905,24.407619 29.385119,24.407619 28.92,24.872381 L23.8095238,29.9829762 L22.2703571,28.4439286 C21.8053571,27.9791667 21.0517857,27.9791667 20.5866667,28.4439286 C20.1217857,28.9088095 20.1217857,29.662619 20.5866667,30.127619 L22.967619,32.5085714 C23.2002381,32.7408333 23.5047619,32.8571429 23.8095238,32.8571429 C24.1142857,32.8571429 24.4188095,32.7408333 24.6513095,32.5084524 L30.6036905,26.5560714 C31.0685714,26.0911905 31.0685714,25.337381 30.6036905,24.872381 Z"
            id="Path"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ConfidenceIcon;
