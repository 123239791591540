import React from "react";
import withStyles from '@mui/styles/withStyles';

import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  Configure,
} from "react-instantsearch-dom";
import i18n from "../../i18n";
import UserChatHits from "./UserChatHits";
import "./SearchBar.css";

const searchClient = algoliasearch(
  "MTMFJ78PRC",
  "a6400a80c85f1dcceaaddde616c096a3",
);

const styles = theme => ({
  main: {
    width: "100%",
  },
});

const SearchUserBox = ({
  classes,
  clientId,
  onFocus,
  onBlur,
  specialRef,
  displaySearchResults,
  refresh,
  onClickUser,
  autoFocus,
}) => {
  return (
    <div className={classes.main}>
      <InstantSearch
        refresh={refresh}
        searchClient={searchClient}
        indexName={clientId}
        // indexName={"public_steps"}
      >
        <Configure filters="type:user" />
        <SearchBox
          autoFocus={autoFocus}
          ref={specialRef}
          onBlur={onBlur}
          onFocus={onFocus}
          translations={{
            placeholder: i18n.t("search-user-placeholder"),
          }}
        />
        {displaySearchResults && <UserChatHits onClickUser={onClickUser} />}
      </InstantSearch>
    </div>
  );
};

export default withStyles(styles)(SearchUserBox);
