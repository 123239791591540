import React, { useCallback, useMemo } from "react";
import { makeStyles } from "@mui/styles";
import DialogBlurParent from "components/Modals/DialogBlurParent";
import { MainText } from "components/Texts/MainText";
import { useDispatch, useSelector } from "react-redux";
import { getUserCampaigns } from "services/campaign/selectors";
import { push } from "connected-react-router";
import {
  clearCoachingSession,
  getCoachingSessionRequest,
} from "services/coaching/actions";
import { seeUserProfileRequest } from "services/manager/actions";
import { cleanMessage, setReceiver } from "services/message/actions";
import { Avatar, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LightIcon from "scenes/CreateTrack/LightIcon";
import { displayNumberOfActionDone } from "utils/doneStepsByUser";
import Colors from "constants/Colors";

const useStyles = makeStyles(theme => ({
  row: {
    boxSizing: "border-box",
    cursor: "pointer",
    width: "100%",
    paddingLeft: 24,
    paddingRight: 24,
    marginBottom: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    transition: "all 0.2s",
    "&:hover": {
      opacity: 0.9,
      backgroundColor: "#ECF6FF",
    },
  },
  number: {
    fontSize: 16,
    color: "#BABCC5",
    marginRight: 45,
  },
  picture: {
    marginRight: 33,
    width: 56,
    height: 56,
    objectFit: "cover",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  rightContainer: {
    display: "flex",
    marginLeft: 20,
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-end",
  },
  name: {
    fontSize: 16,
    color: Colors.darkBlue,
    fontFamily: "Montserrat",
    fontWeight: "bold",
  },
  description: {
    fontSize: 14,
    color: "#BABCC5",
  },
  points: {
    fontSize: 24,
    marginRight: 14,
    fontFamily: "Montserrat",
    color: Colors.darkBlue,
    fontWeight: "bold",
  },
  textDiv: { flex: 1 },
  closeDialog: {
    position: "absolute",
    right: 16,
    top: 16,
    cursor: "pointer",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: 24,
    paddingRight: 24,
    marginBottom: 24,
  },
  doublePointInfo: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    paddingLeft: 24,
    paddingRight: 24,
    marginTop: 24,
  },
  doublePointCircle: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: "green",
    marginRight: 16,
  },
}));

interface Props {
  team: {
    name: "";
    weekPoints: 1;
    users: {
      name: "";
      picture: "";
      firstName: "";
      lastName: "";
      description: "";
      weekActions: 0;
      user: "";
    }[];
  };
  teamDialogIsOpen: boolean;
  handleClose: () => void;
}

const TeamDialog = ({ team, teamDialogIsOpen, handleClose }: Props) => {
  const userCampaign = useSelector(getUserCampaigns);

  const usersInfo = useMemo(() => {
    const users = userCampaign.filter(user =>
      team.users.some(el => el.user === user.userId),
    );

    return users
      .map(user => {
        return {
          ...user,
          numberOfActionDone: displayNumberOfActionDone(user.steps),
        };
      })
      .sort((a, b) => b.numberOfActionDone - a.numberOfActionDone);
  }, [team.users, userCampaign]);

  const numberOfWeeklyAction = useMemo(() => {
    return team.users?.filter(user => user.weekActions !== 0).length;
  }, [team.users]);

  const classes = useStyles();

  const dispatch = useDispatch();

  const onClickUser = userSelected => {
    dispatch(clearCoachingSession());
    dispatch(cleanMessage());
    dispatch(
      setReceiver({
        _id: userSelected.userId,
        picture: userSelected.picture,
      }),
    );
    dispatch(
      seeUserProfileRequest({
        userId: userSelected.userId,
        managerId: "",
      }),
    );
    dispatch(
      getCoachingSessionRequest({
        userId: userSelected.userId,
        coachId: "",
      }),
    );
    dispatch(push(`/user/${userSelected.userId}`));
  };

  const renderUserInfos = useCallback(() => {
    return usersInfo.map((value, index) => (
      <div
        onClick={() => onClickUser(value)}
        className={classes.row}
        style={{ marginBottom: index === usersInfo.length - 1 && 0 }}
        key={index}
      >
        <div className={classes.leftContainer}>
          <Typography className={classes.number}>{index + 1}</Typography>
          <Avatar
            className={classes.picture}
            alt={`${value.firstName} ${value.lastName}`}
            src={value.picture}
          >
            {`${value.firstName} ${value.lastName}`}
          </Avatar>
          <div className={classes.textDiv}>
            <Typography className={classes.name}>
              {`${value.firstName} ${value.lastName}`}
            </Typography>
            <Typography className={classes.description}>
              {" "}
              {value.description}
            </Typography>
          </div>
        </div>
        <div className={classes.rightContainer}>
          <Typography className={classes.points}>
            {value.numberOfActionDone}
          </Typography>
          <LightIcon color="#F9A849" sizePercentage={1.1} />
        </div>
      </div>
    ));
  }, [usersInfo]);

  return (
    <DialogBlurParent isOpen={teamDialogIsOpen} handleClose={handleClose}>
      <div style={{ paddingTop: 24, paddingBottom: 24, position: "relative" }}>
        <div className={classes.closeDialog} onClick={handleClose}>
          <CloseIcon
            style={{ color: "#BABCC5", width: 24, height: 24, zIndex: 5 }}
          />
        </div>
        <div className={classes.header}>
          <MainText
            text={team.name}
            size="large"
            isBold
            style={{ marginRight: 24 }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: 4,
            }}
          >
            <MainText
              text={`${team.weekPoints} ` + " points"}
              size="small"
              style={{ color: Colors.pureMain, marginRight: 4 }}
            />
            <MainText text="this week" size="small" />
          </div>
        </div>
        {renderUserInfos()}
        <div className={classes.doublePointInfo}>
          <div className={classes.doublePointCircle} />
          <MainText
            text={
              "(" +
              numberOfWeeklyAction +
              "/" +
              team.users.length +
              ") Points doublés si chacun passe a l’action"
            }
          />
        </div>
      </div>
    </DialogBlurParent>
  );
};

export default TeamDialog;
