import React, { PureComponent } from "react";
import withStyles from "@mui/styles/withStyles";
import DeleteConfirmModal from "components/DeleteConfirmModal";

import Colors from "../../../../constants/Colors";
import moment from "moment";
import { seeUserProfileRequest } from "../../../../services/manager/actions";

import { connect } from "react-redux";
import {
  getSelectedClientId,
  getUserId,
} from "../../../../services/user/selectors";
import {
  getSurveysByCampaignRequest,
  sendSurveyRequest,
  deleteSurveyRequest,
} from "services/survey/actions";
import {
  loadMoreUserCampaignsRequest,
  deleteUserCampaignRequest,
  addRandomStepRequest,
} from "../../../../services/campaign/actions";
import {
  cleanMessage,
  setReceiver,
} from "../../../../services/message/actions";
import { openSnackMessage } from "../../../../services/general/actions";

import i18n from "../../../../i18n";
import { getAppLanguage } from "../../../../services/general/selectors";

import StatBox from "scenes/Campaigns/CampaignScreen/StatBox";
import ParticipantIcon from "scenes/Campaigns/CampaignScreen/StatsSide/ParticipantIcon";
import LightIcon from "scenes/CreateTrack/LightIcon";
import PulseIcon from "scenes/Campaigns/CampaignScreen/StatsSide/PulseIcon";
import { getCampaignSurveys } from "services/survey/selectors";
import SurveyBubble from "scenes/Campaigns/CampaignScreen/SurveysSide/SurveyBubble";
import selectLanguage from "utils/selectLanguage";

import MyTabs from "components/MyTabs";
import ConsolidatedResults from "./ConsolidatedResults";
import ByQuestionResults from "./ByQuestionResults";
import AddSurveyModal from "scenes/SurveyBuilder/AddSurveyModal/AddSurveyModal";
import SendNewSurveyModal from "scenes/Campaigns/CampaignScreen/SurveysSide/SendNewSurveyModal";
import { Typography } from "@mui/material";
import { getIsCoachingAccount } from "services/client/selectors";
import AddButton from "components/AddButton";
import { getCampaignSelected } from "services/campaign/selectors";

moment.locale("fr");

const styles = (theme: any): any => ({
  rightDiv: {
    position: "relative",
    overflow: "visible",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 4,
  },
  title: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 22,
    color: Colors.darkBlue,
    marginRight: 30,
    marginBottom: 20,
  },
  firstSection: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    overflow: "auto",
    justifyContent: "space-around",
    alignItems: "flex-start",
    marginBottom: 15,
  },
  topDiv: {
    overflow: "auto",
    position: "relative",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 15,
  },
  surveys: {
    width: "100%",
    flexWrap: "wrap",
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "row",
    marginBottom: 20,
  },
  bottom: {
    width: "100%",
    marginBottom: 15,
  },
  emptyText: {
    color: "#5D6D79",
    marginLeft: 5,
    fontSize: 16,
    marginBottom: 20,
  },
  helper: {
    marginTop: -15,
    marginBottom: 15,
    fontSize: 12,
    fontStyle: "italic",
    color: Colors.grey,
  },
  emptyDiv: {
    marginTop: 200,
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  topButton: {
    position: "absolute",
    top: -70,
    right: 0,
    zIndex: 10,
  },
});

class SurveysSide extends PureComponent<any, {}> {
  state = {
    page: 0,
    limit: 300,
    userSelected: {},
    surveyToDelete: { _id: null },
    surveyToCreate: { _id: null },
    isDeleteConfirmModalVisible: false,
    isAddParticipantModalVisible: false,
    isAddSurveyModalVisible: false,
    surveySelectedIndex: 0,
    tabSelected: 0,
  };

  componentDidMount = () => {
    const { campaignId, onboardingSurveyId } = this.props;
    this.props.getSurveysByCampaignRequest({
      campaignId,
      surveyId: onboardingSurveyId,
    });

    window.addEventListener("keydown", this.downHandler);
    window.addEventListener("keyup", this.upHandler);
  };

  componentWillUnmount = () => {
    window.removeEventListener("keydown", this.downHandler);
    window.removeEventListener("keyup", this.upHandler);
  };

  downHandler = e => {
    if (
      e.keyCode === 39 &&
      this.state.surveySelectedIndex < this.props.surveys.length - 1
    ) {
      this.setState({
        surveySelectedIndex: this.state.surveySelectedIndex + 1,
      });
    }
  };

  upHandler = e => {
    if (e.keyCode === 37 && this.state.surveySelectedIndex > 0) {
      this.setState({
        surveySelectedIndex: this.state.surveySelectedIndex - 1,
      });
    }
  };

  componentWillReceiveProps = nextProps => {
    if (
      this.props.surveys.length > nextProps.surveys.length &&
      nextProps.surveys.length >= this.state.surveySelectedIndex
    ) {
      this.setState({ surveySelectedIndex: 0 });
    }
  };

  onClickDelete = surveyToDelete => event => {
    event.stopPropagation();
    this.setState({ isDeleteConfirmModalVisible: true, surveyToDelete });
  };

  onConfirmDelete = () => {
    const { surveyToDelete } = this.state;
    this.props.deleteSurveyRequest({
      surveyId: surveyToDelete._id,
    });
    this.setState({ isDeleteConfirmModalVisible: false });
  };

  onCloseConfirmDeleteModal = () => {
    this.setState({ isDeleteConfirmModalVisible: false });
  };

  onSelectSurvey = surveySelectedIndex => () => {
    this.setState({ surveySelectedIndex });
  };

  onCloseSurveyModal = () => {
    this.setState({ isAddSurveyModalVisible: false });
  };

  onOpenSurveyModal = () => {
    this.setState({ isAddSurveyModalVisible: true });
  };

  onSelectCreateSurvey = surveyToCreate => () => {
    this.setState({ surveyToCreate });
  };

  onSendNewSurvey = () => {
    const { campaignId, userId, participants } = this.props;

    const survey = JSON.parse(JSON.stringify(this.state.surveyToCreate));

    survey["campaignId"] = campaignId;
    survey["participants"] = participants;

    this.props.sendSurveyRequest({ survey, userId, participants });
  };

  onCloseSendNewSurveyModal = () => {
    this.setState({ surveyToCreate: {} });
  };

  selectTab = (tabSelected: number) => {
    this.setState({ tabSelected });
  };

  render() {
    const {
      classes,
      participants = [],
      surveys,
      language,
      isCoachingAccount,
    } = this.props;
    const { surveySelectedIndex } = this.state;

    return (
      <div className={classes.rightDiv}>
        <AddButton
          title={i18n.t("new")}
          onClick={this.onOpenSurveyModal}
          className={classes.topButton}
        />
        <div className={classes.firstSection}>
          <AddSurveyModal
            surveySelectedIds={surveys.map(el => el._id)}
            onSelectSurvey={this.onSelectCreateSurvey}
            onClose={this.onCloseSurveyModal}
            isVisible={this.state.isAddSurveyModalVisible}
          />
          <SendNewSurveyModal
            onSendNewSurvey={this.onSendNewSurvey}
            language={language}
            participants={participants}
            surveySelected={this.state.surveyToCreate}
            isVisible={Boolean(this.state.surveyToCreate._id)}
            onClose={this.onCloseSendNewSurveyModal}
          />
          <DeleteConfirmModal
            title={i18n.t("delete-confirmation-modal-survey-title")}
            isVisible={this.state.isDeleteConfirmModalVisible}
            onClose={this.onCloseConfirmDeleteModal}
            onClickConfirm={this.onConfirmDelete}
          />
          <div className={classes.surveys}>
            {surveys.map((survey, index) => (
              <SurveyBubble
                key={index}
                title={selectLanguage({ text: survey.title, language })}
                onClick={this.onSelectSurvey(index)}
                onClickDelete={this.onClickDelete(surveys[index])}
                isSelected={surveySelectedIndex === index}
              />
            ))}
          </div>
          {surveys.length > 1 && (
            <Typography className={classes.helper}>
              {i18n.t("arrow-to-navigate-surveys")}
            </Typography>
          )}
          {!surveys.length && (
            <div className={classes.emptyDiv}>
              <Typography className={classes.emptyText}>
                {i18n.t("no-survey-active")}
              </Typography>
              <AddButton
                title={i18n.t("send-new-survey")}
                onClick={this.onOpenSurveyModal}
              />
            </div>
          )}
          {!!surveys.length && (
            <>
              <Typography className={classes.title}>
                {i18n.t("Answers")}
              </Typography>
              <div className={classes.topDiv}>
                <StatBox
                  icon={<ParticipantIcon />}
                  stat={
                    surveys[surveySelectedIndex].participants?.length ||
                    participants.length
                  }
                  title={
                    surveys[surveySelectedIndex].participants?.length === 1
                      ? i18n.t("Participant")
                      : i18n.t("Participants")
                  }
                />
                <StatBox
                  icon={<LightIcon color={"#F9A849"} sizePercentage={2} />}
                  title={i18n.t("Answers")}
                  stat={
                    surveys[surveySelectedIndex].questions[0].answers?.length
                  }
                  lightColor={"rgba(238, 134, 13, 0.22)"}
                />
                <StatBox
                  isLast
                  icon={<PulseIcon color={"#7BEC9C"} />}
                  lightColor={"#DEF9EA"}
                  stat={`${
                    !participants.length
                      ? 0
                      : (
                          (surveys[surveySelectedIndex].questions[0].answers
                            ?.length /
                            participants.length) *
                          100
                        ).toFixed(1)
                  }%`}
                  title={i18n.t("answer-rate")}
                />
              </div>
              <MyTabs
                variant="standard"
                centered={false}
                selectTab={this.selectTab}
                tabSelected={this.state.tabSelected}
                tabs={
                  isCoachingAccount
                    ? [i18n.t("by-question")]
                    : [i18n.t("by-question"), i18n.t("consolidated")]
                }
              />
              <div className={classes.bottom}>
                {this.state.tabSelected === 0 && (
                  <ByQuestionResults
                    participants={participants.length}
                    language={language}
                    questions={surveys[surveySelectedIndex].questions}
                  />
                )}
                {this.state.tabSelected === 1 && (
                  <ConsolidatedResults
                    language={language}
                    questions={surveys[surveySelectedIndex].questions}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const language = getAppLanguage(state);
  const userId = getUserId(state);
  const clientId = getSelectedClientId(state);
  const surveys = getCampaignSurveys(state);

  const { isLoading } = state.campaign;
  const campaignSelected = getCampaignSelected(state);
  const campaignId = campaignSelected._id;
  const { participants, onboardingSurveyId } = campaignSelected;

  return {
    isCoachingAccount: getIsCoachingAccount(state),
    isLoading,
    campaignId,
    onboardingSurveyId,
    language,
    userId,
    clientId,
    participants,
    surveys,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    openSnackMessage,
    loadMoreUserCampaignsRequest,
    deleteUserCampaignRequest,
    seeUserProfileRequest,
    cleanMessage,
    setReceiver,
    addRandomStepRequest,
    getSurveysByCampaignRequest,
    sendSurveyRequest,
    deleteSurveyRequest,
  })(SurveysSide),
);
