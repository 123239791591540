import { Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MainText } from "components/Texts/MainText";
import React from "react";

const useStyle = makeStyles({
  receiverDropDownList: {
    width: "100%",
  },
  receiverDropDownListDesc: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 5,
    marginTop: 5,
    alignItems: "center",
  },
  avatar: {
    objectFit: "cover",
    width: 30,
    height: 30,
    borderRadius: 20,
    marginRight: 20,
  },
});

export interface IHistoryReceiverListProps {
  receivers: any[];
}

const HistoryReceiverList = ({ receivers }: IHistoryReceiverListProps) => {
  const classes = useStyle();

  return (
    <div className={classes.receiverDropDownList}>
      {receivers.map((receiver, index) => (
        <div key={index} className={classes.receiverDropDownListDesc}>
          <Avatar src={receiver.picture} className={classes.avatar} />
          <MainText
            size="small"
            text={receiver.firstName + " " + receiver.lastName}
          />
        </div>
      ))}
    </div>
  );
};

export default HistoryReceiverList;
