import React from "react";

const EyeOpenIcon = () => (
  <svg
    width="16"
    height="11"
    viewBox="0 0 16 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 5.5C10.5 6.16304 10.2366 6.79893 9.76777 7.26777C9.29893 7.73661 8.66304 8 8 8C7.33696 8 6.70107 7.73661 6.23223 7.26777C5.76339 6.79893 5.5 6.16304 5.5 5.5C5.5 4.83696 5.76339 4.20107 6.23223 3.73223C6.70107 3.26339 7.33696 3 8 3C8.66304 3 9.29893 3.26339 9.76777 3.73223C10.2366 4.20107 10.5 4.83696 10.5 5.5Z"
      fill="black"
    />
    <path
      d="M0 5.5C0 5.5 3 0 8 0C13 0 16 5.5 16 5.5C16 5.5 13 11 8 11C3 11 0 5.5 0 5.5ZM8 9C8.92826 9 9.8185 8.63125 10.4749 7.97487C11.1313 7.3185 11.5 6.42826 11.5 5.5C11.5 4.57174 11.1313 3.6815 10.4749 3.02513C9.8185 2.36875 8.92826 2 8 2C7.07174 2 6.1815 2.36875 5.52513 3.02513C4.86875 3.6815 4.5 4.57174 4.5 5.5C4.5 6.42826 4.86875 7.3185 5.52513 7.97487C6.1815 8.63125 7.07174 9 8 9Z"
      fill="black"
    />
  </svg>
);

export default EyeOpenIcon;
