import React, { useState } from "react";
import withStyles from '@mui/styles/withStyles';
import Box from "components/Box";
import BoxTitle from "components/BoxTitle";
import FlatButton from "components/FlatButton";
import i18n from "i18n";
import BoxHeader from "components/BoxHeader";
import GoalsTable from "scenes/Campaigns/SeeUserModal/Coaching/RoadmapCoaching/GoalsTable";
import NewGoalModal from "scenes/Campaigns/SeeUserModal/Coaching/RoadmapCoaching/NewGoalModal";
import { connect } from "react-redux";
import { getAppLanguage } from "services/general/selectors";
import {
  getCoachingGoals,
  getCoachingSessionId,
} from "services/coaching/selectors";
import { getListOfStepsRequest } from "services/createTrack/actions";
import { updateCoachingSessionRequest } from "services/coaching/actions";
import EmptyText from "scenes/Campaigns/SeeUserModal/Coaching/RoadmapCoaching/EmptyText";
import ExistingContentModal from "scenes/CreateTrack/ExistingContentModal";
import AddIcon from "scenes/Campaigns/SeeUserModal/Coaching/RoadmapCoaching/NewGoalModal/GoalSettings/AddIcon";

const styles = theme => ({
  main: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  body: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "-webkit-fill-available",
    paddingLeft: 45,
    paddingRight: 45,
  },
  empty: {
    marginTop: 40,
    alignSelf: "center",
    textAlign: "center",
  },
  buttonIcon: {
    marginRight: 10,
  },
});

const RoadmapCoaching = ({
  classes,
  language,
  coachingSessionId,
  goals = [{}],
  updateCoachingSessionRequest,
  getListOfStepsRequest,
}) => {
  const [isNewGoalModalVisible, openNewGoalModal] = useState(false);
  const [isExistingContentModalVisible, openExistingContentModal] = useState(
    false,
  );
  const [goalSelected, selectGoal] = useState({});

  const onEditGoal = goal => e => {
    e.stopPropagation();
    openNewGoalModal(true);
    getListOfStepsRequest({ stepIds: goal.recommendedSteps });
    selectGoal(goal);
  };

  const onRemoveGoal = goal => e => {
    e.stopPropagation();
    const newGoals = goals.filter(
      el => el?.skill?.value !== goal?.skill?.value,
    );
    updateCoachingSessionRequest({ goals: newGoals, coachingSessionId });
  };

  const onClose = () => {
    openNewGoalModal(false);
    selectGoal({});
  };

  const onOpenNewGoalModal = () => {
    openNewGoalModal(true);
    getListOfStepsRequest({ stepIds: [] });
  };

  return (
    <>
      <NewGoalModal
        {...goalSelected}
        goalSelected={goalSelected}
        isVisible={isNewGoalModalVisible}
        onClose={onClose}
        isEditing={!!goalSelected._id}
        openExistingContentModal={() => openExistingContentModal(true)}
      />
      <ExistingContentModal
        isVisible={isExistingContentModalVisible}
        onClose={() => openExistingContentModal(false)}
      />
      <Box className={classes.main} intercomTarget={"coaching-roadmap"}>
        <BoxHeader>
          <BoxTitle>{i18n.t("coaching-roadmap")}</BoxTitle>
          <FlatButton
            title={i18n.t("add-goal")}
            onClick={onOpenNewGoalModal}
            icon={<AddIcon color={"#fff"} className={classes.buttonIcon} />}
          />
        </BoxHeader>
        <div className={classes.body}>
          {goals.length < 1 && (
            <div className={classes.empty}>
              <EmptyText
                onClick={onOpenNewGoalModal}
                firstTextPart={i18n.t("create-first-goal")}
              />
            </div>
          )}
          {goals.length > 0 && (
            <GoalsTable
              language={language}
              onEditGoal={onEditGoal}
              onRemoveGoal={onRemoveGoal}
              goals={goals}
            />
          )}
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = state => {
  return {
    language: getAppLanguage(state),
    goals: getCoachingGoals(state),
    coachingSessionId: getCoachingSessionId(state),
  };
};

export default connect(mapStateToProps, {
  updateCoachingSessionRequest,
  getListOfStepsRequest,
})(withStyles(styles)(RoadmapCoaching));
