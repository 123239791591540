import React from "react";
import moment from "moment";
import "./Message.css";
import ReviewComment from "scenes/Campaigns/CampaignScreen/ReviewsSide/ReviewComment";

export default function Message(props) {
  const { data, isMine, startsSequence, endsSequence, showTimestamp } = props;

  const friendlyTimestamp = moment(data.createdAt).format("LLLL");

  return (
    <div
      className={[
        "message",
        `${isMine ? "mine" : ""}`,
        `${startsSequence ? "start" : ""}`,
        `${endsSequence ? "end" : ""}`,
      ].join(" ")}
    >
      {showTimestamp && <div className="timestamp">{friendlyTimestamp}</div>}
      <div className="bubble-div">
        <ReviewComment
          {...props}
          {...data}
          isMine={isMine}
          likedBy={data.likedBy ? data.likedBy : []}
        />
      </div>
    </div>
  );
}
