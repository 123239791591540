import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  changeField,
  createInvitedUserRequest,
} from "../../services/signup/actions";

import Typography from "@mui/material/Typography";
import withStyles from '@mui/styles/withStyles';
import TextField from "@mui/material/TextField";
import FlatButton from "../../components/FlatButton";

import Colors from "../../constants/Colors";
import i18n from "../../i18n";

const styles = theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "100vh",
    width: "100vw",
    backgroundColor: Colors.pureMain,
  },
  logo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: 75,
    // paddingTop: 0,
    margin: "20px auto 20px",
  },
  paper: {
    width: 470,
    display: "flex",
    borderRadius: 3,
    background: "white",
    padding: 40,
    paddingBottom: 20,
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 20,
  },
  title: {
    textAlign: "center",
    fontWeight: 300,
    color: "#444",
    margin: 0,
    marginTop: 0,
    fontSize: 30,
    lineHeight: "38px",
    textTransform: "none",
    letterSpacing: 0,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE11 issue.
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  loginError: {
    backgroundColor: "#FCE6E7",
    borderRadius: 8,
  },
  errorText: {
    color: "#e6525a",
    padding: "13px 15px",
    borderRadius: 6,
    backgroundColor: "rgba(230,82,90,0.15)",
  },
  textField: {
    marginTop: 20,
  },
  submitButton: {
    marginTop: 20,
    padding: 12,
  },
  noDecoration: {
    textDecoration: "none",
  },
  forgotPassword: {
    marginTop: 20,
    cursor: "pointer",
    textDecoration: "none",
    color: Colors.pureMain,
    fontSize: 17,
    lineHeight: 1.5,
    textAlign: "center",
  },
  newAccountText: {
    color: Colors.white,
    fontSize: 16,
    textAlign: "center",
  },
  bold: {
    marginLeft: 5,
    color: Colors.white,
    fontSize: 16,
    cursor: "pointer",
    fontWeight: "bold",
    textDecoration: "underline",
  },
  noAccountDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  topField: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  splitTextField: {
    width: "48.5%",
  },
  agreeTerms: {
    textAlign: "left",
    fontSize: 13,
    marginTop: 22,
    marginBottom: 10,
  },
  link: {
    textAlign: "left",
    textDecoration: "none",
    color: Colors.pureMain,
    fontWeight: "bold",
  },
  stepDiv: {
    marginBottom: 25,
    marginTop: 10,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  step: {
    borderRadius: 30,
    height: 4,
    width: 40,
    marginLeft: 5,
    marginRight: 5,
    backgroundColor: Colors.lightMain,
  },
  stepActive: {
    backgroundColor: Colors.pureMain,
  },
  info: {
    fontSize: 15,
  },
  infoBold: {
    fontWeight: 500,
  },
});

class CreateUserForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    window.addEventListener("keydown", this.keyPress);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.keyPress);
  }

  onChangeField = field => event => {
    this.props.changeField({ [field]: event.target.value });
  };

  onSubmit = () => {
    const {
      email,
      password,
      confirmPassword,
      invitationVerificationToken,
    } = this.props;

    if (password !== confirmPassword) {
      return this.props.changeField({ error: "Password are differents" });
    }

    this.props.createInvitedUserRequest({
      invitationVerificationToken,
      email,
      password,
    });
  };

  keyPress = e => {
    if (e.keyCode === 13) {
      this.onSubmit();
      // put the login here
    }
  };

  render() {
    const { error, classes, requesting } = this.props;

    return (
      <React.Fragment>
        <div className={classes.form}>
          {error && <p className={classes.errorText}>{error}</p>}
          <Typography className={classes.info}>
            {i18n.t("you-have-been-invited")}
            <span className={classes.infoBold}>{this.props.clientName}</span> !
          </Typography>
          <TextField
            id="outlined-multiline-flexible"
            label={i18n.t("your-email")}
            onChange={this.onChangeField("email")}
            fullWidth
            autoFocus
            autoComplete="email"
            // rowsMax="4"
            value={this.props.email}
            className={classes.textField}
            margin="normal"
            variant="outlined"
          />
          <TextField
            id="outlined-multiline-flexible"
            label={i18n.t("your-password")}
            fullWidth
            autoComplete="new-password"
            type="password"
            // rowsMax="4"
            onChange={this.onChangeField("password")}
            value={this.props.password}
            className={classes.textField}
            margin="normal"
            variant="outlined"
          />
          <TextField
            id="outlined-multiline-flexible"
            label={i18n.t("confirm-your-password")}
            fullWidth
            autoComplete="new-password"
            type="password"
            // rowsMax="4"
            onChange={this.onChangeField("confirmPassword")}
            value={this.props.confirmPassword}
            className={classes.textField}
            margin="normal"
            variant="outlined"
          />
          <FlatButton
            requesting={requesting}
            fullWidth
            disabled={
              !this.props.password ||
              !this.props.confirmPassword ||
              !this.props.email
            }
            title={i18n.t("create-account")}
            onClick={this.onSubmit}
            className={classes.submitButton}
          />
          <Typography className={classes.agreeTerms}>
            {i18n.t("agree-terms-by-signing-up")}
            <a
              target="_blank"
              href="https://microdoing.com/conditions-generales-dutilisation-du-site"
              className={classes.link}
              rel="noopener noreferrer"
            >
              {i18n.t("terms-of-service")}
            </a>
            {i18n.t("and-the")}
            <a
              href="https://microdoing.com/privacy"
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {i18n.t("privacy-policy")}
            </a>
          </Typography>
        </div>
      </React.Fragment>
    );
  }
}

CreateUserForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const {
    step,
    email,
    error,
    requesting,
    password,
    confirmPassword,
    phoneNumber,
    firstName,
    lastName,
    clientName,
    invitationVerificationToken,
  } = state.signup;
  return {
    email,
    step,
    clientName,
    phoneNumber,
    password,
    confirmPassword,
    firstName,
    lastName,
    requesting,
    invitationVerificationToken,
    error,
  };
};

export default connect(mapStateToProps, {
  changeField,
  createInvitedUserRequest,
})(withStyles(styles)(CreateUserForm));
