import { createSelector } from "reselect";

export const getUserSelectedId = state => state.manager.userSelected._id;

export const getUserSelected = state => state.manager.userSelected;

export const getUserSelectedEmail = createSelector(
  getUserSelected,
  userSelected => userSelected.email,
);

export const getUserSelectedFirstName = createSelector(
  getUserSelected,
  userSelected => userSelected.firstName,
);

export const getUserSelectedName = createSelector(
  getUserSelected,
  userSelected => `${userSelected.firstName} ${userSelected.lastName}`,
);

export const getIsUserSelectedATestAccount = createSelector(
  getUserSelected,
  userSelected => userSelected.isTestAccount,
);

export const getManager = state => state.manager;
