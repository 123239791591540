import moment from "moment-timezone";
import i18n from "../i18n";

interface Props {
  date: Date;
  timezone: string;
  durationInMinutes: number;
  language: string;
}

const displayMeetingDate = ({
  date,
  timezone,
  durationInMinutes,
  language,
}: Props) => {
  const locale = language === "fr" ? "fr" : "en";
  moment.locale(locale);

  const firstMoment = moment(date).tz(timezone);
  const secondMoment = moment(date)
    .tz(timezone)
    .add(durationInMinutes, "minutes");

  const dateToDisplay = firstMoment.format("dddd D MMMM YYYY");

  const firstHour = firstMoment.format("HH:mm").replace(":", "h");
  const secondHour = secondMoment.format("HH:mm").replace(":", "h");

  return i18n.t("meeting-duration", {
    date: dateToDisplay,
    firstHour,
    secondHour,
  });
};

export default displayMeetingDate;
