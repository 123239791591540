import React from "react";

const DeleteIcon = ({ sizePercentage, color }) => (
  <svg
    width={sizePercentage * 19}
    height={sizePercentage * 19}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 19 19"
    version="1.1"
  >
    <g
      id="Dashboard"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Recherche-rapide-contenu"
        transform="translate(-1347.000000, -587.000000)"
        fill={color}
        fillRule="nonzero"
      >
        <g
          id="add-(1)-copy-2"
          transform="translate(1356.500000, 596.500000) rotate(-45.000000) translate(-1356.500000, -596.500000) translate(1344.000000, 584.000000)"
        >
          <path
            d="M23.6432623,10.7557974 L14.2441447,10.7557974 L14.2441447,1.35659072 C14.2441447,0.928418365 13.6627292,0 12.4999492,0 C11.3371691,0 10.7557537,0.928469192 10.7557537,1.35659072 L10.7557537,10.7558482 L1.35658521,10.7558482 C0.928465417,10.7557974 7.27595761e-12,11.3372152 7.27595761e-12,12.4999492 C7.27595761e-12,13.6626831 0.928465417,14.2441518 1.35658521,14.2441518 L10.7558045,14.2441518 L10.7558045,23.6434093 C10.7558045,24.07148 11.3371691,25 12.5,25 C13.6628309,25 14.2441955,24.07148 14.2441955,23.6434093 L14.2441955,14.2441518 L23.6434148,14.2441518 C24.0714838,14.2441518 25,13.6627848 25,12.4999492 C25,11.3371136 24.0713821,10.7557974 23.6432623,10.7557974 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

DeleteIcon.defaultProps = {
  sizePercentage: 1,
  color: "#fff",
};

export default DeleteIcon;
