import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import ResultBox from "scenes/Campaigns/CampaignScreen/SurveysSide/ByQuestionResults/ResultBox";
import Feedback from "scenes/Recap/Feedback";

const useStyles = makeStyles(theme => ({
  answers: {},
}));

interface AnswerType {
  value: string;
  createdAt: Date;
}

interface Props {
  title: string;
  index: number;
  answers: AnswerType[];
  participants: number;
}

const FreeAnswerResult = ({ title, index, answers, participants }: Props) => {
  const classes = useStyles();

  const totalVotes = answers.length;

  return (
    <ResultBox
      title={title}
      index={index}
      totalVotes={totalVotes}
      participants={participants}
    >
      <div className={classes.answers}>
        {answers.map((el, index) => (
          <Feedback key={index} comment={el.value} createdAt={el.createdAt} />
        ))}
      </div>
    </ResultBox>
  );
};

export default FreeAnswerResult;
