import React from "react";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";

const styles = theme => ({
  main: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 100,
  },
  title: {
    marginBottom: 15,
  },
  inputForm: {
    marginTop: 15,
    marginBottom: 15,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  inputLabel: {
    marginRight: 10,
    marginLeft: 10,
  },

  table: {
    minWidth: 700,
  },
  root: {
    width: "90%",
    marginTop: theme.spacing.unit * 3,
    backgroundColor: "#FAFAFA",
    // backgroundColor: "#fff",
    overflowX: "auto",
  },
  button: {
    marginLeft: 15,
  },
  firstSection: {
    backgroundColor: "#fff",
    minHeight: 160,
  },
  secondSection: {
    backgroundColor: "#FAFAFA",
    minHeight: 500,
  },
  separatorText: {
    color: "#788593",
    marginRight: 20,
  },
  middleSeparator: {
    marginLeft: 20,
  },
  separation: {
    // marginTop: 35,
    paddingTop: 45,
    paddingLeft: "5%",
    paddingRight: "5%",
    // backgroundColor: "#FAFAFA",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  greyBackground: {
    backgroundColor: "#FAFAFA",
    marginTop: 0,
  },
  whiteBackground: {
    marginTop: 0,
    paddingTop: 50,
  },
  separator: {
    flex: 1,
    height: 1,
    backgroundColor: "#E8F3FD",
  },
});

const SectionSeparator = ({ isGrey, classes, text, isMiddle }) => {
  return (
    <div
      className={`${classes.separation} ${isGrey && classes.greyBackground}`}
    >
      {isMiddle && <div className={classes.separator} />}
      <Typography
        variant="h6"
        classes={{
          root: `${classes.separatorText} ${
            isMiddle && classes.middleSeparator
          }`,
        }}
      >
        {text}
      </Typography>
      <div className={classes.separator} />
    </div>
    //     <div className={`${classes.separation} ${classes.whiteBackground}`}>
    //     <Typography variant="h6" classes={{ root: classes.separatorText }}>
    //       ÉVOLUTION DU BIEN-ÊTRE
    //     </Typography>
    //     <div className={classes.separator} />
    //   </div>
  );
};

SectionSeparator.defaultProps = {
  isGrey: false,
  isMiddle: false,
};

export default withStyles(styles)(SectionSeparator);
