// First, we need to import the FlatList and other React Native component
import "intersection-observer";
import React, { Component } from "react";
// We also need to add the connectInfiniteHits connector to our import
import { connectInfiniteHits } from "react-instantsearch-dom";
import { connect } from "react-redux";
import withStyles from '@mui/styles/withStyles';
import Colors from "../../../constants/Colors";
import "moment/locale/fr";
import { getAppLanguage } from "../../../services/general/selectors";
import UserRow from "../UserRow";

const styles = theme => ({
  list: {
    // flexDirection: "column",
    // height: "100%",
    // flex: 1,
    height: 600,
    overflow: "scroll",
    minWidth: "100%",
    marginTop: 20,
  },
  name: {
    color: Colors.text,
  },
  widthDiv: {
    width: "100%",
  },
  title: {
    color: "black",
    backgroundColor: "white",
    border: "1px solid rgba(0,0,0,.09)",
    boxShadow: "0 2px 0 0 rgba(0,0,0,.03)",
    borderRadius: 5,
    padding: 10,
    fontWeight: 500,
    // marginBottom: 10,
    marginBottom: 20,
  },
  firstSection: {
    marginTop: 0,
    margin: "12px 0 0",
    padding: "35px 30px 0",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    overflow: "auto",
    justifyContent: "space-around",
    alignItems: "flex-start",
    marginBottom: 15,
  },
  evolutionTitle: {
    width: "100%",
    color: Colors.black,
    textAlign: "left",
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.5,
    marginBottom: 16,
  },
  whiteBackground: {
    backgroundColor: Colors.white,
  },
  rankingSection: {
    paddingTop: 10,
  },
  firstTitle: {
    alignSelf: "center",
    marginTop: 15,
    marginBottom: 15,
  },
  userRow: {
    // boxShadow: "0 2px 0 0 rgba(0,0,0,.03)",
    border: "1px solid rgba(0,0,0,.09)",
    width: "100%",
    backgroundColor: "#fff",
    marginBottom: 10,
    borderRadius: 8,
    padding: 15,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  userName: {
    marginLeft: 15,
  },
  subTitle: {
    fontWeight: 500,
    marginLeft: 10,
  },
  stat: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: 1,
  },
  progressSection: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  statDiv: {
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    border: "1px solid rgba(0,0,0,.09)",
    // boxShadow: "0 2px 0 0 rgba(0,0,0,.03)",
    borderRadius: 5,
    padding: "12px 15px",
    fontWeight: 500,
    // marginBottom: 10,
    marginBottom: 20,
  },
  biggerRow: {
    display: "flex",
    flex: 1.5,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  firstRow: {
    display: "flex",
    flex: 1.5,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  row: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  tableHeaderRow: {
    width: "100%",
    // backgroundColor: "#fff",
    marginBottom: 10,
    borderRadius: 8,
    padding: 10,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  tableHeader: {
    fontSize: 15,
    fontWeight: 500,
    color: "black",
    // color: "#fff"
  },
  avatar: {
    width: 30,
    height: 30,
    borderRadius: 15,
  },
  date: {
    marginRight: 10,
    overflowWrap: "break-word",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    "text-overflow": "ellipsis",
  },
});

class UserClickHits extends Component {
  onSentinelIntersection = entries => {
    const { hasMore, refine } = this.props;

    entries.forEach(entry => {
      if (entry.isIntersecting && hasMore) {
        // if (hasMore) {
        refine();
      }
    });
  };

  sentinel = null;

  componentDidMount() {
    this.observer = new IntersectionObserver(this.onSentinelIntersection);
    this.observer.observe(this.sentinel);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  render() {
    const { hits, classes, isExcluded, onClick } = this.props;
    // return null;
    return (
      <div className={classes.list}>
        {hits.map((item, index) => {
          const {
            firstName,
            lastName,
            picture,
            createdAt,
            email,
            role,
            _id,
          } = item;
          if (isExcluded(item)) {
            return null;
          }
          return (
            <UserRow
              key={index}
              onClick={onClick(item)}
              firstName={firstName}
              lastName={lastName}
              picture={picture}
              createdAt={createdAt}
              email={email}
              role={role}
              _id={_id}
              index={index}
              isRoleInputDisabled={() => true}
            />
          );
        })}
        <div
          className="ais-InfiniteHits-sentinel"
          ref={c => (this.sentinel = c)}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const language = getAppLanguage(state);
  return {
    language,
  };
};

export default connect(
  mapStateToProps,
  {},
)(connectInfiniteHits(withStyles(styles)(UserClickHits)));
