import React from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography } from "@mui/material";
import Colors from "constants/Colors";

const BOX_SIZE = 80;

const styles = theme => ({
  main: {
    width: 350,
    height: 120,
    marginRight: 30,
    backgroundColor: "#fff",
    borderRadius: 10,
    padding: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  iconBox: {
    width: BOX_SIZE,
    height: BOX_SIZE,
    minWidth: BOX_SIZE,
    marginRight: 30,
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  text: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  stat: {
    textAlign: "left",
    fontSize: 20,
    fontFamily: "Montserrat",
    fontWeight: "bold",
    color: Colors.text,
  },
  legend: {
    textAlign: "left",
    color: "#BABCC5",
    fontSize: 18,
    fontFamily: "Roboto",
  },
});

const StatBox = ({ classes, iconBackgroundColor, icon, stat, legend }) => {
  return (
    <div className={classes.main}>
      <div
        className={classes.iconBox}
        style={{ backgroundColor: iconBackgroundColor }}
      >
        {icon}
      </div>
      <div className={classes.text}>
        <Typography className={classes.stat}>{stat}</Typography>
        <Typography className={classes.legend}>{legend}</Typography>
      </div>
    </div>
  );
};

export default withStyles(styles)(StatBox);
