import { useDispatch, useSelector } from "react-redux";
import {
  getCampaignResourcesList,
  getSelectedCampaignId,
} from "services/campaign/selectors";
import { getCampaignResourcesRequest } from "services/resource/actions";
import { getResourceRequesting } from "services/resource/selectors";

export const useGetCampaignResourcesRequest = () => {
  const resources = useSelector(getCampaignResourcesList);
  const campaignId = useSelector(getSelectedCampaignId);
  const requesting = useSelector(getResourceRequesting);

  const dispatch = useDispatch();

  const sendRequest = async () => {
    dispatch(
      getCampaignResourcesRequest({
        resources,
        campaignId,
      }),
    );
  };

  return [requesting, sendRequest] as const;
};
