import { ISkill } from "@microdoing/shared/services/userCampaign/types";
import { makeStyles } from "@mui/styles";
import React from "react";
import Colors from "constants/Colors";
import selectLanguage from "utils/selectLanguage";
import { MainText } from "./Texts/MainText";

const useStyles = makeStyles(theme => ({
  skillDiv: {
    display: "flex",
    flexDirection: "row",
    height: 30,
  },
  textDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  textButton: {
    color: Colors.pureMain,
    marginRight: 5,
  },
  textButtonEllipsis: {
    height: "100%",
    overflow: "hidden",
    lineHeight: "1.5em" /* fallback */,
    maxHeight: "1.5em",
    wordBreak: "break-all",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 1 /* number of lines to show */,
    color: Colors.pureMain,
    cursor: "pointer",
    transition: "all 0.2s ease",
    maxWidth: "max-content",
    "&:hover": { textDecoration: "underline" },
  },
}));

interface Props {
  skills: ISkill[];
  languageSelected?: string;
}

const InlineSkills = ({ skills = [], languageSelected = "fr" }: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.skillDiv}>
      {skills.map((skill, index) => {
        return (
          <div key={index} className={classes.textDiv}>
            <MainText
              text={selectLanguage({
                text: skill.label,
                language: languageSelected,
              })}
              className={classes.textButtonEllipsis}
            />

            {!(index === skills.length - 1) && (
              <MainText text={","} className={classes.textButton} />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default InlineSkills;
