import React from "react";
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  main: {},
});

const ViewIcon = ({
  className = "",
  width = 17,
  height = 12,
  color = "#17AFFC",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 12"
      fill="none"
      className={className}
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M10.5889 6.21707C10.5889 6.88011 10.3255 7.516 9.85663 7.98484C9.38779 8.45368 8.75191 8.71707 8.08887 8.71707C7.42583 8.71707 6.78994 8.45368 6.3211 7.98484C5.85226 7.516 5.58887 6.88011 5.58887 6.21707C5.58887 5.55403 5.85226 4.91815 6.3211 4.4493C6.78994 3.98046 7.42583 3.71707 8.08887 3.71707C8.75191 3.71707 9.38779 3.98046 9.85663 4.4493C10.3255 4.91815 10.5889 5.55403 10.5889 6.21707Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0888672 6.21707C0.0888672 6.21707 3.08887 0.717072 8.08887 0.717072C13.0889 0.717072 16.0889 6.21707 16.0889 6.21707C16.0889 6.21707 13.0889 11.7171 8.08887 11.7171C3.08887 11.7171 0.0888672 6.21707 0.0888672 6.21707ZM8.08887 9.71707C9.01713 9.71707 9.90736 9.34832 10.5637 8.69195C11.2201 8.03557 11.5889 7.14533 11.5889 6.21707C11.5889 5.28881 11.2201 4.39858 10.5637 3.7422C9.90736 3.08582 9.01713 2.71707 8.08887 2.71707C7.16061 2.71707 6.27037 3.08582 5.61399 3.7422C4.95762 4.39858 4.58887 5.28881 4.58887 6.21707C4.58887 7.14533 4.95762 8.03557 5.61399 8.69195C6.27037 9.34832 7.16061 9.71707 8.08887 9.71707Z"
        fill={color}
      />
    </svg>
  );
};

export default withStyles(styles)(ViewIcon);
