import React from "react";

const CircleStarIcon = ({
  width = 40,
  height = 40,
  color = "#FAFAFB",
  className = "",
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 40 40"
    version="1.1"
  >
    <circle cx="20" cy="20" r="20" fill={color} />
    <path
      d="M27.9329 18.1325C27.8564 17.9005 27.7157 17.6938 27.5264 17.5352C27.3371 17.3765 27.1066 17.2722 26.8604 17.2337L22.9968 16.6201L21.2057 13.2189C21.0916 13.0023 20.9187 12.8207 20.7059 12.6939C20.4931 12.5671 20.2487 12.5 19.9995 12.5C19.7503 12.5 19.5059 12.5671 19.2931 12.6939C19.0804 12.8207 18.9074 13.0023 18.7934 13.2189L17.0023 16.6201L13.1387 17.2337C12.8927 17.2728 12.6625 17.3774 12.4734 17.5359C12.2842 17.6945 12.1434 17.9009 12.0664 18.1325C11.9894 18.3641 11.9792 18.612 12.0368 18.8489C12.0945 19.0859 12.2178 19.3027 12.3933 19.4757L15.1499 22.1913L14.5533 25.9717C14.515 26.2125 14.5454 26.4589 14.6411 26.6839C14.7369 26.9089 14.8943 27.1037 15.096 27.247C15.2978 27.3902 15.536 27.4763 15.7846 27.4958C16.0331 27.5152 16.2823 27.4673 16.5049 27.3574L19.9995 25.6345L23.4943 27.3574C23.7168 27.4671 23.966 27.5148 24.2144 27.4953C24.4629 27.4757 24.701 27.3896 24.9027 27.2464C25.1044 27.1032 25.2617 26.9085 25.3576 26.6836C25.4534 26.4588 25.4839 26.2124 25.4459 25.9717L24.8492 22.1913L27.6059 19.4757C27.7819 19.3031 27.9056 19.0862 27.9633 18.8491C28.021 18.6121 28.0105 18.364 27.9329 18.1325Z"
      fill="#DCDDE3"
    />
  </svg>
);

export default CircleStarIcon;
