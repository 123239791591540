import React from "react";

interface Props {
  className?: string;
}

const CoachingFileIcon = ({ className = "" }: Props) => {

  return (
    <svg
      preserveAspectRatio="xMidYMid meet"
      className={className}
      width="18"
      height="13"
      viewBox="0 0 16 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.23125 4.97855C6.81778 4.97855 6.48153 5.30133 6.48153 5.69823V6.23799H7.98097V5.69823C7.98097 5.30133 7.64472 4.97855 7.23125 4.97855Z"
        fill="white"
      />
      <path
        d="M7.41868 8.03718V7.62732C7.5302 7.56489 7.60611 7.4501 7.60611 7.3175C7.60611 7.11905 7.43799 6.95766 7.23125 6.95766C7.02451 6.95766 6.85639 7.11905 6.85639 7.3175C6.85639 7.4501 6.9323 7.56489 7.04382 7.62732V8.03718C7.04382 8.13649 7.1276 8.2171 7.23125 8.2171C7.3349 8.2171 7.41868 8.13649 7.41868 8.03718Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6551 2.7706C12.2559 2.77135 12.7441 3.25576 12.7496 3.85658L13.71 9.66181C13.7108 9.6713 13.7108 9.68086 13.71 9.69035C13.7093 10.2945 13.2197 10.7841 12.6155 10.7849H2.80027C2.20215 10.7842 1.71507 10.3041 1.70572 9.70605L0.752454 3.91366C0.751705 3.90417 0.751705 3.89461 0.752454 3.88512C0.750064 3.28205 1.23537 2.79036 1.83844 2.78487L6.74605 2.7706H11.6551ZM8.35584 6.23799H8.49641C8.72882 6.23799 8.91813 6.41953 8.91813 6.6428V8.53196C8.91813 8.75524 8.72882 8.93678 8.49641 8.93678H5.96609C5.73368 8.93678 5.54437 8.75524 5.54437 8.53196V6.6428C5.54437 6.41953 5.73368 6.23799 5.96609 6.23799H6.10667V5.69823C6.10667 5.10288 6.61104 4.61871 7.23125 4.61871C7.85146 4.61871 8.35584 5.10288 8.35584 5.69823V6.23799Z"
        fill="white"
      />
      <path
        d="M14.2209 1.37782H9.39608L8.44139 0.236179C8.40624 0.193938 8.35359 0.170249 8.29868 0.171962H4.4057C3.80759 0.172675 3.3205 0.65277 3.31115 1.25081L3.14276 2.27257L11.6551 2.27115C12.5129 2.27486 13.2167 2.95114 13.2548 3.80808L14.0682 8.74138C14.1283 8.60656 14.1604 8.46089 14.1624 8.31326L15.3083 2.50804C15.3094 2.49666 15.3094 2.48517 15.3083 2.47379C15.3083 1.87182 14.8229 1.38253 14.2209 1.37782Z"
        fill="white"
      />
    </svg>
  );
};

export default CoachingFileIcon;
