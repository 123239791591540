import React from "react";

const Logo = ({ sizePercentage, className = "", color = "#fff" }) => (
  <svg
    className={className}
    width={sizePercentage * 15}
    height={sizePercentage * 15}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 15 15"
    version="1.1"
  >
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
      <path
        d="M8.17084 6.86526V0.987152H6.49139V6.86526H0.613281V8.54471H6.49139V14.4228H8.17084V8.54471H14.0489V6.86526H8.17084Z"
        fill={color}
      />
    </svg>
  </svg>
);

Logo.defaultProps = {
  sizePercentage: 1,
};

export default Logo;
