//@flow
import { select, call, put, takeLatest, all } from "redux-saga/effects";

import {
  NOTIFICATION_MESSAGE_SEEN,
  NOTIFICATION_PULSE_CLEAR,
  NOTIFICATION_HOME_SEEN,
  NOTIFICATION_RANDOM_COFFEE_SEEN,
  NOTIFICATION_GENERAL_GET_ERROR,
  NOTIFICATION_GENERAL_GET_SUCCESS,
  NOTIFICATION_GENERAL_GET_REQUESTING,
  NOTIFICATION_GENERAL_LOAD_SUCCESS,
  NOTIFICATION_GENERAL_LOAD_ERROR,
  NOTIFICATION_GENERAL_LOAD_REQUESTING,
  NOTIFICATION_STOP_LOAD,
  NOTIFICATION_GENERAL_CLEAR,
  NOTIFICATION_CLICK,
  NOTIFICATION_TEAM_CLEAR,
  NOTIFICATION_HISTORY_REQUESTING,
  NOTIFICATION_HISTORY_SUCCESS,
  GET_FAVORITE_NOTIFICATIONS_REQUESTING,
  ADD_NOTIFICATION_TO_FAVORITE_REQUESTING,
  GET_FAVORITE_NOTIFICATIONS_SUCCESS,
  GET_FAVORITE_NOTIFICATIONS_ERROR,
} from "./constants";

import { fetchApi } from "../api";
import { getUserClientId, getUserId } from "../user/selectors";
import { openSnackMessage } from "services/general/actions";
import i18n from "i18n";

const endPoints = {
  updateUserWithoutResponse: "/v1/users/update-without-response",
  seeNotification: "/v1/notifications/see",
  getLatestNotifications: "/v1/notifications/latest", //userId // page
  getLatestHistoryNotifications: "/v1/notifications/latestNotificationHistory", // campaignId // page
  getFavoriteNotifications: "/v1/notifications/favorite", // clientId // page
  addNotificationToFavorite: "/v1/notifications/favorite",
};

const updateUserApi = payload => {
  return fetchApi(endPoints.updateUserWithoutResponse, payload, "post");
};

const getLatestNotificationsApi = payload => {
  const { userId, page, numberByPage } = payload;
  return fetchApi(
    `${endPoints.getLatestNotifications}/${userId}/${page}/${numberByPage}`,
    payload,
    "get",
  );
};

const getLatestHistoryNotificationsApi = payload => {
  const { campaignId, page, numberByPage } = payload;
  return fetchApi(
    `${endPoints.getLatestHistoryNotifications}/${campaignId}/${page}/${numberByPage}`,
    payload,
    "get",
  );
};

const seeNotificationApi = payload => {
  return fetchApi(endPoints.seeNotification, payload, "post");
};

const addNotificationToFavoriteApi = payload => {
  const { notificationId, isFavorite } = payload;
  return fetchApi(`${endPoints.addNotificationToFavorite}`, payload, "patch");
};

const getFavoriteNotificationsApi = payload => {
  const { clientId } = payload;
  return fetchApi(
    `${endPoints.getFavoriteNotifications}?clientId=${clientId}`,
    payload,
    "get",
  );
};

function* getLatestNotificationsFlow(action) {
  try {
    const result = yield call(getLatestNotificationsApi, action.payload);
    yield put({ type: NOTIFICATION_GENERAL_GET_SUCCESS, payload: result });
  } catch (error) {
    console.log(error);
    yield put({ type: NOTIFICATION_GENERAL_GET_ERROR, payload: error.message });
  }
}

function* loadMoreNotificationsFlow(action) {
  try {
    const result = yield call(getLatestNotificationsApi, action.payload);
    if (result.length < 1) {
      yield put({ type: NOTIFICATION_STOP_LOAD });
    }
    yield put({ type: NOTIFICATION_GENERAL_LOAD_SUCCESS, payload: result });
  } catch (error) {
    console.log(error);
    yield put({
      type: NOTIFICATION_GENERAL_LOAD_ERROR,
      payload: error.message,
    });
  }
}

function* getLatestHistoryNotifications(action) {
  try {
    const result = yield call(getLatestHistoryNotificationsApi, action.payload);
    yield put({ type: NOTIFICATION_HISTORY_SUCCESS, payload: result });
  } catch (error) {
    console.log(error);
  }
}

function* seeMessageNotificationsFlow(action) {
  try {
    const userId = yield select(getUserId);
    yield call(updateUserApi, { userId, newMessageNotifications: 0 });
  } catch (error) {
    console.log(error);
  }
}

function* clearPulseNotificationsFlow(action) {
  try {
    const { userId } = action.payload;
    yield select(state => state.user);

    // const clearedPulseNotifications = pulseNotifications.filter(
    //   el => el.user !== userId
    // );

    yield call(updateUserApi, {
      userId,
      pulseNotifications: [],
      // pulseNotifications: clearedPulseNotifications
    });
  } catch (error) {
    console.log(error);
  }
}

function* clearTeamNotificationsFlow(action) {
  try {
    const user = yield select(state => state.user);
    // const notification = yield select(state => state.notification);
    // const { teamNotifications } = notification;
    const userId = user._id;

    // const clearedTeamNotifications = teamNotifications.filter(
    //   el => el.campaignId !== campaignId
    // );

    yield call(updateUserApi, {
      userId,
      teamNotifications: [],
    });
  } catch (error) {
    console.log(error);
  }
}

function* seeHomeNotificationsFlow(action) {
  try {
    const userId = yield select(getUserId);
    yield call(updateUserApi, { userId, newHomeNotifications: 0 });
  } catch (error) {
    console.log(error);
  }
}

function* seeRandomCoffeeNotificationsFlow(action) {
  try {
    const userId = yield select(getUserId);
    yield call(updateUserApi, { userId, newRandomCoffeeNotifications: 0 });
  } catch (error) {
    console.log(error);
  }
}

function* seeGeneralNotificationsFlow(action) {
  try {
    const userId = yield select(getUserId);
    yield call(updateUserApi, { userId, newGeneralNotifications: 0 });
  } catch (error) {
    console.log(error);
  }
}

function* clickNotificationFlow(action) {
  try {
    yield call(seeNotificationApi, action.payload);
  } catch (error) {
    console.log(error);
  }
}

function* addNotificationToFavoriteFlow(action) {
  try {
    const { isFavorite, notificationId } = action.payload;
    const clientId = yield select(getUserClientId);
    yield put(
      openSnackMessage({
        snackMessage: isFavorite
          ? i18n.t("notification-added-to-library")
          : i18n.t("notification-removed-from-library"),
      }),
    );
    yield call(addNotificationToFavoriteApi, {
      isFavorite,
      clientId,
      notificationId,
    });
  } catch (error) {
    console.log(error);
  }
}

function* getFavoriteNotificationsFlow(action) {
  try {
    const response = yield call(getFavoriteNotificationsApi, action.payload);
    yield put({ type: GET_FAVORITE_NOTIFICATIONS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_FAVORITE_NOTIFICATIONS_ERROR, payload: error });
    console.log(error);
  }
}

function* notificationsWatcher() {
  yield all([
    takeLatest(NOTIFICATION_MESSAGE_SEEN, seeMessageNotificationsFlow),
    takeLatest(NOTIFICATION_PULSE_CLEAR, clearPulseNotificationsFlow),
    takeLatest(NOTIFICATION_TEAM_CLEAR, clearTeamNotificationsFlow),
    takeLatest(NOTIFICATION_HOME_SEEN, seeHomeNotificationsFlow),
    takeLatest(NOTIFICATION_GENERAL_CLEAR, seeGeneralNotificationsFlow),
    takeLatest(
      NOTIFICATION_RANDOM_COFFEE_SEEN,
      seeRandomCoffeeNotificationsFlow,
    ),
    takeLatest(NOTIFICATION_GENERAL_GET_REQUESTING, getLatestNotificationsFlow),
    takeLatest(NOTIFICATION_GENERAL_LOAD_REQUESTING, loadMoreNotificationsFlow),
    takeLatest(NOTIFICATION_CLICK, clickNotificationFlow),
    takeLatest(NOTIFICATION_HISTORY_REQUESTING, getLatestHistoryNotifications),
    takeLatest(
      ADD_NOTIFICATION_TO_FAVORITE_REQUESTING,
      addNotificationToFavoriteFlow,
    ),
    takeLatest(
      GET_FAVORITE_NOTIFICATIONS_REQUESTING,
      getFavoriteNotificationsFlow,
    ),
  ]);
}

export default notificationsWatcher;
