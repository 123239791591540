import makeStyles from '@mui/styles/makeStyles';
import Colors from "constants/Colors";

const useReviewStyle = makeStyles(theme => ({
  elementSeparator: {
    marginBottom: 16,
  },
  reviewDivSeparator: {
    marginBottom: 17,
  },
  text: {
    color: Colors.text,
  },
}));

export default useReviewStyle;
