import React from "react";

const StarIcon = ({ sizePercentage, color, className }) => (
  <svg
    // width={sizePercentage * 24}
    className={className}
    width={sizePercentage * 24}
    height={sizePercentage * 24}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
    version="1.1"
  >
    <g
      id="Dashboard"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Recherche-rapide-contenu"
        transform="translate(-31.000000, -305.000000)"
        fill={color}
        fillRule="nonzero"
      >
        <g id="star-(5)" transform="translate(31.000000, 305.000000)">
          <path
            d="M13.1587037,0.753425677 L15.8655996,6.4887265 C16.0538358,6.88762201 16.4177924,7.16402205 16.8388206,7.22788722 L22.8919145,8.14765023 C23.9522447,8.30888358 24.3752755,9.67099135 23.6083133,10.4525543 L19.2283183,14.9168338 C18.9239365,15.2272603 18.7847619,15.6748399 18.8568523,16.1129968 L19.8906492,22.4168023 C20.0718766,23.520832 18.9634861,24.3625957 18.0152965,23.8417282 L12.6015046,20.8657165 C12.2250323,20.6589399 11.7749677,20.6589399 11.3984954,20.8657165 L5.98470349,23.8417282 C5.03651393,24.3631192 3.92812339,23.520832 4.10935075,22.4168023 L5.14314771,16.1129968 C5.21523815,15.6748399 5.07606355,15.2272603 4.77168168,14.9168338 L0.391686744,10.4525543 C-0.375275451,9.67046787 0.0477552634,8.3083601 1.1080855,8.14765023 L7.16117944,7.22788722 C7.58220764,7.16402205 7.94616424,6.88762201 8.1344004,6.4887265 L10.8412963,0.753425677 C11.3148905,-0.251141892 12.6846089,-0.251141892 13.1587037,0.753425677 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

StarIcon.defaultProps = {
  sizePercentage: 1,
  color: "#FFC41F",
  className: "",
};

export default StarIcon;
