import React, { PureComponent } from "react";
import { connect } from "react-redux";

import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";

import Colors from "../../constants/Colors";
import RepartitionChart from "./RepartitionChart";

const getRepartition = allActions => {
  let dataByFunction = [
    {
      id: "Finance",
      label: "Finance",
      value: 0,
      color: firstColorRange[0],
    },
    {
      id: "Sales",
      label: "Sales",
      value: 0,
      color: firstColorRange[1],
    },
    {
      id: "Marketing",
      label: "Marketing",
      value: 0,
      color: firstColorRange[2],
    },
    {
      id: "IT",
      label: "IT",
      value: 0,
      color: firstColorRange[3],
    },
    {
      id: "Legal",
      label: "Légal",
      value: 0,
      color: firstColorRange[4],
    },
    {
      id: "HR",
      label: "RH",
      value: 0,
      color: firstColorRange[5],
    },
    {
      id: "Support",
      label: "Support",
      value: 0,
      color: firstColorRange[6],
    },
    {
      id: "Operations",
      label: "Opérations",
      value: 0,
      color: firstColorRange[7],
    },
    {
      id: "Consulting",
      label: "Consulting",
      value: 0,
      color: firstColorRange[8],
    },
    {
      id: "Accounting",
      label: "Comptabilité",
      value: 0,
      color: firstColorRange[9],
    },

    {
      id: "Autre",
      label: "Autre",
      value: 0,
      color: firstColorRange[10],
    },
  ];

  let dataByCategory = [
    {
      id: "Leadership",
      label: "Leadership",
      value: 0,
      color: secondColorRange[0],
    },
    {
      id: "Capacité à convaincre",
      label: "Capacité à convaincre",
      value: 0,
      color: secondColorRange[1],
    },
    {
      id: "Pro-activité",
      label: "Pro-activité",
      value: 0,
      color: secondColorRange[2],
    },
    {
      id: "Cohésion d'Équipe",
      label: "Cohésion d'Équipe",
      value: 0,
      color: secondColorRange[3],
    },
    {
      id: "Résilience",
      label: "Résilience",
      value: 0,
      color: secondColorRange[4],
    },
  ];

  allActions.forEach(el => {
    // category
    if (el.category === "leadership") {
      dataByCategory[0].value++;
    } else if (el.category === "publicSpeaking") {
      dataByCategory[1].value++;
    } else if (el.category === "proactivity") {
      dataByCategory[2].value++;
    } else if (el.category === "teamCohesion") {
      dataByCategory[3].value++;
    } else if (el.category === "resilience") {
      dataByCategory[4].value++;
    }

    // Function
    if (el.jobFunction === "finance") {
      dataByFunction[0].value++;
    } else if (el.jobFunction === "sales") {
      dataByFunction[1].value++;
    } else if (el.jobFunction === "marketing") {
      dataByFunction[2].value++;
    } else if (
      el.jobFunction === "engineering" ||
      el.jobFunction === "information technology"
    ) {
      dataByFunction[3].value++;
    } else if (el.jobFunction === "legal") {
      dataByFunction[4].value++;
    } else if (el.jobFunction === "human resources") {
      dataByFunction[5].value++;
    } else if (el.jobFunction === "support") {
      dataByFunction[6].value++;
    } else if (el.jobFunction === "operations") {
      dataByFunction[7].value++;
    } else if (el.jobFunction === "consulting") {
      dataByFunction[8].value++;
    } else if (el.jobFunction === "accounting") {
      dataByFunction[9].value++;
    } else {
      dataByFunction[10].value++;
    }
  });

  // filter and display only superior to 0
  dataByFunction = dataByFunction.filter(el => el.value > 0);
  dataByCategory = dataByCategory.filter(el => el.value > 0);

  return { dataByFunction, dataByCategory };
};

const secondColorRange = [
  "rgba(252,87,122,0.8)",
  "rgba(66,80,251,0.8)",
  "rgba(0,170,255,0.8)",
  "#FF9F40",
  "#FFC233",
];

const firstColorRange = [
  "#573697",
  "#590d82",
  "#b61aae",
  "#f25d9c",
  "#6b76ff",
  "#d195f9",
  "#a275e3",
  "#070d59",
  "#6b76ff",
  "#9fe8fa",
  "#0c056d",
];
/*
const dataTest = [
  {
    id: "Finance",
    label: "Finance",
    value: 532,
    color: secondColorRange[0],
  },
  {
    id: "RH",
    label: "RH",
    value: 79,
    color: secondColorRange[1],
  },
  {
    id: "Dev",
    label: "Dev",
    value: 83,
    color: secondColorRange[2],
  },
  {
    id: "Opé",
    label: "Opé",
    value: 42,
    color: secondColorRange[3],
  },
];*/

const styles = theme => ({
  main: {
    // backgroundColor: "#FAFAFA",
    backgroundColor: "#fff",
    // minHeight: 500,
    flex: 1,
    paddingRight: "2%",
    paddingLeft: "2%",
    paddingTop: 35,
    display: "flex",
    // alignItems: "flex-start",
    alignItems: "center",
    justifyContent: "center",
  },
  leftSection: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: 350,
    alignItems: "center",
    justifyContent: "center",
  },
  rightSection: {
    flex: 1,
    height: 350,
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  companyLogo: {
    width: "40%",
  },
  accent: {
    color: "#6198DE",
  },
  rightTitle: {
    fontWeight: 500,
    marginBottom: 5,
  },
  bold: {
    fontWeight: 500,
  },
  totalDone: {
    color: "#fff",
    fontWeight: 500,
  },
  actionLegend: {
    color: "#fff",
  },
  circle: {
    width: 200,
    height: 200,
    borderRadius: 100,
    webkitBoxShadow: "0px 0px 15px 2px rgba(66,80,251,1)",
    mozBoxShadow: "0px 0px 15px 2px rgba(66,80,251,1)",
    boxShadow: "0px 0px 15px 2px rgba(66,80,251,1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.main,
  },
  rightBox: {
    webkitBoxShadow: "0px 0px 4px 1px #D8D8D8",
    mozBoxShadow: "0px 0px 4px 1px #D8D8D8",
    boxShadow: "0px 0px 4px 1px #D8D8D8",
    height: 250,
    flex: 1,
    padding: 5,
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
  },
  chartContainer: {
    backgroundColor: "blue",
    height: 350,
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

class RepartitionSection extends PureComponent {
  state = {
    name: "",
    owner: "",
    domain: "",
    data1: [
      {
        id: "Finance",
        label: "Finance",
        value: 532,
        color: secondColorRange[0],
      },
      {
        id: "RH",
        label: "RH",
        value: 79,
        color: secondColorRange[1],
      },
      {
        id: "Dev",
        label: "Dev",
        value: 83,
        color: secondColorRange[2],
      },
      {
        id: "Opé",
        label: "Opé",
        value: 42,
        color: secondColorRange[3],
      },
    ],
    data2: [
      {
        id: "Leadership",
        label: "Leadership",
        value: 234,
        color: secondColorRange[0],
      },
      {
        id: "Capacité à convaincre",
        label: "Capacité à convaincre",
        value: 122,
        color: secondColorRange[1],
      },
      {
        id: "Gestion du stress",
        label: "Gestion du stress",
        value: 23,
        color: secondColorRange[2],
      },
      {
        id: "Cohésion d'Équipe",
        label: "Cohésion d'Équipe",
        value: 80,
        color: secondColorRange[3],
      },
      {
        id: "Résilience",
        label: "Résilience",
        value: 30,
        color: secondColorRange[4],
      },
    ],
  };

  componentDidMount = () => {
    // this.props.getActionListRequest();
  };

  onChangeClient = event => {
    this.props.changeCreateField({ name: event.target.value });
  };

  onChangeEmail = event => {
    this.props.changeCreateField({ owner: event.target.value });
  };

  onChangeDomain = event => {
    this.props.changeCreateField({ domain: event.target.value });
  };

  onSubmit = () => {
    this.props.createClientRequest({
      name: this.props.name,
      owner: this.props.owner,
      domain: this.props.domain,
    });
  };

  getRepartitionByFunction = allActions => {};

  getRepartitionByCategory = allActions => {
    const dataByCategory = [
      {
        id: "Leadership",
        label: "Leadership",
        value: 0,
        color: secondColorRange[0],
      },
      {
        id: "Capacité à convaincre",
        label: "Capacité à convaincre",
        value: 0,
        color: secondColorRange[1],
      },
      {
        id: "Pro-activité",
        label: "Pro-activité",
        value: 0,
        color: secondColorRange[2],
      },
      {
        id: "Cohésion d'Équipe",
        label: "Cohésion d'Équipe",
        value: 0,
        color: secondColorRange[3],
      },
      {
        id: "Résilience",
        label: "Résilience",
        value: 0,
        color: secondColorRange[4],
      },
    ];

    allActions.forEach(el => {
      if (el.category === "leadership") {
        dataByCategory[0].value++;
      } else if (el.category === "publicSpeaking") {
        dataByCategory[1].value++;
      } else if (el.category === "proactivity") {
        dataByCategory[2].value++;
      } else if (el.category === "teamCohesion") {
        dataByCategory[3].value++;
      } else if (el.category === "resilience") {
        dataByCategory[4].value++;
      }
    });
    return dataByCategory;
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.main}>
        <div className={classes.leftSection}>
          <Typography variant="h6" classes={{ root: classes.bold }}>
            Actions par département
          </Typography>
          {/* <div className={classes.chartContainer}> */}
          <RepartitionChart
            data={this.props.repartitionByFunction}
            firstChart
          />
        </div>

        <div className={classes.rightSection}>
          <Typography variant="h6" classes={{ root: classes.bold }}>
            Actions par catégorie
          </Typography>
          <RepartitionChart data={this.props.repartitionByCategory} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    list,
    allActions,
  } = state.client;
  const repartition = getRepartition(allActions);
  return {
    token: state.auth.token,
    list,
    repartitionByCategory: repartition.dataByCategory,
    repartitionByFunction: repartition.dataByFunction,
    allActions,
    period: "Janvier 2018",
    companyName: "Pulse",
    endRange: "28/01/2018",
    startingRange: "31/12/2017",
    totalDoneActions: 93,
  };
};

export default connect(
  mapStateToProps,
  {},
)(withStyles(styles)(RepartitionSection));
