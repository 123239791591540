import React, { useEffect, useRef } from "react";
import { Avatar, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { connectInfiniteHits } from "react-instantsearch-dom";
import Colors from "constants/Colors";
import FlatButton from "components/FlatButton";
import i18n from "i18n";
import displayDateWithoutHour from "utils/displayDateWithoutHour";
import { useIsIntersecting } from "./hooks";

const useStyles = makeStyles(theme => ({
  main: {},
  user: {},
  name: {},
  nameText: {},
  firstRow: {
    display: "flex",
    flex: 2,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  row: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  userRow: {
    position: "relative",
    // cursor: "pointer",
    borderTop: "1px solid rgba(0,0,0,.09)",
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 0,
    padding: 15,
    // paddingLeft: 45,
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
    transition: "0.2s all",
    "&:hover": {
      opacity: 0.9,
      backgroundColor: "#ECF6FF",
    },
  },
  tableHeaderRow: {
    width: "100%",
    // backgroundColor: "#fff",
    marginBottom: 10,
    borderRadius: 8,
    padding: 10,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  tableHeader: {
    fontSize: 15,
    fontWeight: 500,
    color: "black",
    // color: "#fff"
  },
  avatar: {
    objectFit: "cover",
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  userName: {
    lineHeight: "1.5em" /* fallback */,
    maxHeight: "1.5em",
    wordBreak: "break-all",
    color: Colors.darkBlue,
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: "Montserrat",
  },
  nameDiv: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    height: 50,
    marginLeft: 15,
  },
  jobDescription: {
    "-webkit-line-clamp": 1 /* number of lines to show */,
    lineHeight: "1.5em" /* fallback */,
    maxHeight: "1.5em",
    wordBreak: "break-all",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#BABCC5",
    fontSize: 16,
  },
  button: {
    height: 40,
    fontWeight: 550,
    fontSize: 15,
  },
  date: {
    fontSize: 14,
    color: "#474747",
  },
}));

interface Props {
  onClickStartCoaching: (userId: string) => () => void;
  isAvailable: (userId: string) => boolean;
  requesting: boolean;
  hits: [
    {
      _id: string;
      firstName: string;
      lastName: string;
      jobDescription: string;
      createdAt: Date;
      picture: string;
    },
  ];
  hasMore: boolean;
  refineNext: () => void;
}

const UserCoachingHits = ({
  hits,
  onClickStartCoaching,
  isAvailable,
  requesting,
  hasMore,
  refineNext,
}: Props) => {
  const classes = useStyles();
  const sentinel = useRef(null);
  const isIntersecting = useIsIntersecting(sentinel);

  useEffect(() => {
    if (hasMore) {
      refineNext();
    }
  }, [isIntersecting, hasMore, refineNext]);

  return (
    <div className={classes.main}>
      {hits
        .filter(el => isAvailable(el._id))
        .map(item => {
          const {
            firstName,
            lastName,
            picture,
            jobDescription,
            createdAt,
            _id,
          } = item;
          return (
            <div className={classes.userRow} key={_id}>
              <div className={classes.firstRow}>
                <Avatar src={picture} className={classes.avatar} />
                <div className={classes.nameDiv}>
                  <Typography
                    className={classes.userName}
                  >{`${firstName} ${lastName}`}</Typography>
                  {jobDescription && (
                    <Typography
                      className={classes.jobDescription}
                    >{`${jobDescription}`}</Typography>
                  )}
                </div>
              </div>
              <div className={classes.row}>
                <Typography className={classes.date}>
                  {displayDateWithoutHour(createdAt)}
                </Typography>
              </div>
              <div className={classes.row}>
                <FlatButton
                  requesting={requesting}
                  className={classes.button}
                  onClick={onClickStartCoaching(_id)}
                  title={i18n.t("to-coach")}
                />
              </div>
            </div>
          );
        })}
      <div ref={sentinel} className="ais-InfiniteHits-sentinel" />
    </div>
  );
};

export default connectInfiniteHits(UserCoachingHits);
