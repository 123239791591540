import React from "react";
import withStyles from "@mui/styles/withStyles";
import { Typography } from "@mui/material";
import Colors from "constants/Colors";
import CustomArrowTooltip from "components/CustomArrowTooltip";

const styles = (theme): any => ({
  main: {
    borderRadius: 8,
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: 150,
    marginRight: 30,
    width: "100%",
  },
  circle: {
    borderRadius: 20,
    height: 40,
    width: 40,
    marginBottom: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  stat: {
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Montserrat",
    color: Colors.darkBlue,
  },
  title: {
    textAlign: "center",
    color: "#909090",
    fontSize: 18,
  },
  isLast: {
    marginRight: 0,
  },
});

interface Props {
  stat: number;
  title: string;
  explanation: string;
  icon: JSX.Element;
  iconBackgroundColor: string;
  isLast?: boolean;
  classes: any;
}

const StatBox = ({
  classes,
  stat,
  title,
  explanation,
  icon,
  iconBackgroundColor,
  isLast = false,
}: Props) => {
  return (
    <div className={`${classes.main} ${isLast && classes.isLast}`}>
      <div
        className={classes.circle}
        style={{ backgroundColor: iconBackgroundColor }}
      >
        {icon}
      </div>
      <Typography className={classes.stat}>{stat}</Typography>
      <CustomArrowTooltip title={explanation}>
        <Typography className={classes.title}>{title}</Typography>
      </CustomArrowTooltip>
    </div>
  );
};

export default withStyles(styles)(StatBox);
