import React from "react";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import FileIcon from "./FileIcon";
import {
  getFileNameUpload,
  getFileTypeUpload,
  getFileUploadProgress,
  getUploading,
} from "services/upload/selectors";
import { useSelector } from "react-redux";
import { Loader } from "components";
import Colors from "constants/Colors";

const UPLOADER_WIDTH = 350;

const useStyles = makeStyles(theme => ({
  main: {
    backgroundColor: "#fff",
    width: UPLOADER_WIDTH,
    position: "absolute",
    right: -UPLOADER_WIDTH,
    bottom: 15,
    opacity: 0,
    zIndex: 1,
    height: 50,
    transition: "all 0.3s ease",
    border: "1px solid #E7E7E7",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    borderRadius: 4,
    marginRight: 30,
    paddingLeft: 20,
    paddingRight: 20,
  },
  visible: {
    right: 0,
    opacity: 1,
  },
  fileName: {
    "-webkit-line-clamp": 1 /* number of lines to show */,
    lineHeight: "1.5em" /* fallback */,
    maxHeight: "1.5em",
    wordBreak: "break-all",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginRight: 15,
    marginLeft: 15,
    color: "#282828",
  },
  fill: {
    flex: 1,
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 20,
    width: 20,
    minWidth: 20,
    minHeight: 20,
  },
  uploadProgress: {
    position: "absolute",
    left: 0,
    bottom: 0,
    height: 3,
    borderRadius: 8,
    width: 5,
    backgroundColor: Colors.pureMain,
  },
}));

const UploaderSnack = () => {
  const classes = useStyles();
  const fileType = useSelector(getFileTypeUpload);
  const fileName = useSelector(getFileNameUpload);
  const fileUploadProgress = useSelector(getFileUploadProgress);
  const fileUploading = useSelector(getUploading);

  return (
    <div className={`${classes.main} ${fileUploading && classes.visible}`}>
      <div className={classes.icon}>
        <FileIcon fileType={fileType} />
      </div>
      <Typography className={classes.fileName}>{fileName}</Typography>
      <div className={classes.fill} />
      <Loader size={25} />
      <div
        className={classes.uploadProgress}
        style={{ width: `${fileUploadProgress}%` }}
      />
    </div>
  );
};

export default UploaderSnack;
