import React, { useState } from "react";
import withStyles from "@mui/styles/withStyles";
import { IconButton, Typography } from "@mui/material";
import { RemoveButtonIcon } from "components";
import VideoIcon from "scenes/Campaigns/SeeUserModal/Coaching/Resources/ResourceBoxIcons/VideoIcon";
import LinkIcon from "scenes/Campaigns/SeeUserModal/Coaching/Resources/NewResourceModal/icons/LinkIcon";
import Colors from "constants/Colors";
import EditIcon from "components/EditIcon";
import i18n from "i18n";
import FlatButton from "components/FlatButton";
import DeleteIcon from "scenes/Campaigns/DeleteIcon";
import PdfIcon from "scenes/Campaigns/SeeUserModal/Coaching/Resources/NewResourceModal/icons/PdfIcon";
import ResourceStats from "scenes/Campaigns/SeeUserModal/Coaching/Resources/ResourceStats";

const styles = theme => ({
  main: {
    boxSizing: "border-box",
    position: "relative",
    cursor: "pointer",
    width: "100%",
    paddingLeft: 20,
    paddingRight: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    paddingTop: 15,
    paddingBottom: 15,
    borderBottom: `1px solid #E8E8E8`,
    borderWidth: "calc(100% - 90px)",
    transition: "all 0.2s ease",
    "&:hover": { backgroundColor: Colors.hoverBlue },
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
  },
  link: {
    fontSize: 12,
    color: "#515151",
    overflow: "hidden",
    lineHeight: "1.5em" /* fallback */,
    maxHeight: "1.5em",
    wordBreak: "break-all",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 1 /* number of lines to show */,
    fontFamily: "Montserrat",
  },
  removeIcon: {},
  editIcon: {
    marginLeft: 8,
  },
  iconBox: {
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: 40,
    width: 40,
    marginRight: 15,
  },
  isLast: {
    borderBottom: "none",
  },
  texts: {
    flex: 1,
    marginRight: 15,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
  },
  addButton: {
    fontSize: 14,
    marginLeft: 15,
    height: 40,
  },
});

const icons = {
  videoLink: { icon: <VideoIcon />, backgroundColor: "#EC6A6A" },
  webLink: {
    icon: <LinkIcon color="#fff" width={20} />,
    backgroundColor: "#FCC21B",
  },
  pdf: {
    icon: <PdfIcon color="#fff" width={20} />,
    backgroundColor: "#F8935B",
  },
};

const ResourceBox = ({
  classes,
  resource = {},
  totalViews = 0,
  totalComments = 0,
  comment = "",
  rating = 0,
  onClickEdit,
  onClickRemove = null,
  onClickAdd = null,
  onClickDelete = null,
  isLast = false,
  onClick = null,
}) => {
  const [isHovered, setHover] = useState(false);
  const { title, link, type } = resource;

  const iconSettings = icons[type];

  return (
    <div
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onMouseOver={() => setHover(true)}
      className={`${classes.main} ${isLast && classes.isLast}`}
    >
      <div
        className={classes.iconBox}
        style={{ backgroundColor: iconSettings?.backgroundColor }}
      >
        {iconSettings?.icon}
      </div>
      <div className={classes.texts}>
        <Typography className={`${classes.link} ${classes.title}`}>
          {title}
        </Typography>
        <Typography className={classes.link}>{link}</Typography>
      </div>
      <ResourceStats
        totalViews={totalViews}
        rating={rating}
        comment={comment}
        totalComments={totalComments}
      />
      {isHovered && (
        <IconButton
          onClick={onClickEdit}
          className={classes.editIcon}
          size="large"
        >
          <EditIcon color="#C4C4C4" />
        </IconButton>
      )}
      {!!onClickDelete && isHovered && (
        <IconButton
          onClick={onClickDelete}
          className={classes.removeIcon}
          size="large"
        >
          <DeleteIcon color={"#C4C4C4"} />
        </IconButton>
      )}
      {!!onClickRemove && isHovered && (
        <RemoveButtonIcon
          onClick={onClickRemove}
          className={classes.removeIcon}
        />
      )}
      {!!onClickAdd && isHovered && (
        <FlatButton
          onClick={onClickAdd}
          className={classes.addButton}
          title={i18n.t("add-resource")}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(ResourceBox);
