import React from "react";
import { TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  main: {
    backgroundColor: "#F7F7F7",
    borderRadius: 4,
    padding: "5px 10px",
  },
  text: {
    fontSize: 16,
  },
  hourField: {},
}));

interface Props {
  handleHourChange: (hour: any) => void;
  value: Date;
}

const TimeSelect = ({ handleHourChange, value }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <TextField
        variant="standard"
        InputProps={{
          disableUnderline: true,
          classes: {
            input: classes.text,
          },
        }}
        id="time"
        type="time"
        onChange={handleHourChange}
        value={moment(value).format("HH:mm")}
        className={classes.hourField}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </div>
  );
};

export default TimeSelect;
