import React from "react";

const AddFolderIcon = ({ color = "#707781", className = "" }) => (
  <svg
    width="17"
    height="14"
    viewBox="0 0 17 14"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.1495 2.47589H8.9201L6.55797 0.216452C6.52791 0.188316 6.48838 0.172503 6.44721 0.17215H1.32824C0.971805 0.17215 0.683838 0.460117 0.683838 0.816552V12.738C0.683838 13.0944 0.971805 13.3824 1.32824 13.3824H16.1495C16.5059 13.3824 16.7939 13.0944 16.7939 12.738V3.12029C16.7939 2.76385 16.5059 2.47589 16.1495 2.47589ZM11.1554 8.08621C11.1554 8.16274 11.0869 8.22718 11.0043 8.22718H9.30272V9.93686C9.30272 10.0154 9.23828 10.0798 9.16175 10.0798H8.31598C8.23945 10.0798 8.17501 10.0154 8.17501 9.93686V8.22718H6.47339C6.39082 8.22718 6.32236 8.16274 6.32236 8.08621V7.24044C6.32236 7.16391 6.39082 7.09947 6.47339 7.09947H8.17501V5.38979C8.17501 5.31126 8.23945 5.24682 8.31598 5.24682H9.16175C9.23828 5.24682 9.30272 5.31126 9.30272 5.38979V7.09947H11.0043C11.0869 7.09947 11.1554 7.16391 11.1554 7.24044V8.08621Z"
      fill={color}
    />
  </svg>
);

export default AddFolderIcon;
