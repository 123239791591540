import React from "react";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Colors from "constants/Colors";

const styles = {
  button: {
    cursor: "pointer",
    height: 50,
    paddingLeft: 35,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    transition: "all 0.2s",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.15)",
    },
  },
  buttonSelected: {
    backgroundColor: Colors.pureMain,
    "&:hover": {
      backgroundColor: Colors.pureMain,
      opacity: 0.9,
    },
  },
  selected: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    color: Colors.white,
  },
  text: {
    marginLeft: 15,
    position: "relative",
    color: "rgba(255,255,255,.7)",
  },
};

const Tab = ({ classes, icon, text, isSelected, onClick, hasAccess }) => {
  if (!hasAccess) {
    return null;
  }

  return (
    <div
      onClick={onClick}
      className={`${classes.button} ${isSelected && classes.buttonSelected}`}
      selected={isSelected}
    >
      <div className={classes.icon}>{icon}</div>
      <Typography
        className={classes.text}
        style={isSelected ? styles.selected : null}
      >
        {text}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(Tab);
