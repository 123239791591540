import React from "react";
import { Avatar, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Colors from "constants/Colors";
import BackIcon from "./BackIcon";
import { MainText } from "components/Texts/MainText";
import useSelectLanguage from "hooks/useSelectLanguage";
import { useSelector } from "react-redux";
import { getAppLanguage } from "services/general/selectors";
import i18n from "i18n";
import { getStatusColor } from "./ReviewRow";
import { ISkill } from "services/campaign/types";

const AVATAR_HEIGHT = 30;

const useStyles = makeStyles(theme => ({
  summaryTopDiv: {
    height: 60,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    position: "relative",
    boxShadow: `0px 4px 4px ${Colors.shadow}`,
    webkitBoxShadow: `0px 4px 4px ${Colors.shadow}`,
    mozBoxShadow: `0px 4px 4px ${Colors.shadow}`,
    backgroundColor: "#FFF",
  },
  fullWidth: {
    width: "100%",
  },
  leftTopDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  date: {
    marginRight: 8,
    color: "#4F4F4F",
  },
  name: {
    marginRight: 7,
    color: "#202020",
  },
  avatar: {
    marginBottom: 0,
    marginTop: 0,
    height: AVATAR_HEIGHT,
    width: AVATAR_HEIGHT,
    borderRadius: AVATAR_HEIGHT / 2,
    marginRight: 10,
  },
  backButton: {
    marginLeft: 15,
    color: "inherit",
    borderRadius: 8,
  },
  nextReview: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    color: "#757575",
    paddingLeft: 28,
    paddingRight: 28, // 40 padding of content - 12 padding intern of button
  },
  icon: {
    marginRight: 13,
  },
  reverseIcon: {
    marginLeft: 13,
    transform: "rotate(180deg)",
  },
  userInfo: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  status: {
    color: "#fff",
    borderRadius: 4,
    padding: "5px 12px",
    paddingTop: "7px",
  },
}));

interface Props {
  status: string;
  name: string;
  picture: string;
  date: string;
  onClickBackButton?: () => void | null;
  onPressNextReviewWithoutValidation: () => void;
  onPressPreviousReviewWithoutValidation: () => void;
  onClickUser: () => void;
}

const ReviewHeader = ({
  name = "",
  picture = "",
  status = "new",
  date = "",
  onClickBackButton = null,
  onPressNextReviewWithoutValidation,
  onPressPreviousReviewWithoutValidation,
  onClickUser,
}: Props) => {
  const classes = useStyles();

  const dates = new Date(date);
  const languageSelected = useSelector(getAppLanguage);
  const datesOption: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return (
    <div className={classes.summaryTopDiv}>
      <IconButton
        className={classes.backButton}
        onClick={onClickBackButton}
        size="large"
      >
        <BackIcon />
      </IconButton>
      <div className={`${classes.leftTopDiv} ${classes.fullWidth}`}>
        <div className={classes.leftTopDiv}>
          <div className={classes.userInfo} onClick={() => onClickUser()}>
            <Avatar src={picture} className={classes.avatar} />
            <MainText
              isBold
              size="extraLarge"
              text={name}
              className={classes.name}
            />
          </div>
          <MainText
            text={
              i18n.t("action-realisation-date") +
              dates.toLocaleDateString(languageSelected, datesOption)
            }
            className={classes.date}
          />
        </div>
        <MainText
          size="small"
          text={i18n.t(`review-status-${status}`)}
          className={classes.status}
          style={{ backgroundColor: getStatusColor(status) }}
        />
      </div>
      <div
        className={classes.nextReview}
        onClick={onPressPreviousReviewWithoutValidation}
      >
        <IconButton
          className={classes.backButton}
          onClick={onClickBackButton}
          size="large"
        >
          <BackIcon
            className={classes.icon}
            color={"currentColor"}
            sizePercentage={0.7}
          />
          <MainText
            size="large"
            text={i18n.t("previous-review-without-validation")}
          />
        </IconButton>
      </div>
      <div
        className={classes.nextReview}
        onClick={onPressNextReviewWithoutValidation}
      >
        <IconButton
          className={classes.backButton}
          onClick={onClickBackButton}
          size="large"
        >
          <MainText
            size="large"
            text={i18n.t("next-review-without-validation")}
          />
          <BackIcon
            className={classes.reverseIcon}
            color={"currentColor"}
            sizePercentage={0.7}
          />
        </IconButton>
      </div>
    </div>
  );
};

export default ReviewHeader;
