import React, { PureComponent } from "react";
import { connect } from "react-redux";

import moment from "moment";
import withStyles from "@mui/styles/withStyles";
import Fuse from "fuse.js";
import Colors from "../../constants/Colors";
import {
  getAllMyClientsRequest,
  selectClient,
  deleteMyClientRequest,
  getSelectedClientRequest,
  modifyMyClientRequest,
} from "../../services/myClients/actions";
import "moment/locale/fr";
import { Loader } from "../../components";
import CreationModal from "./CreationModal";
import ClientBox from "./ClientBox";
import i18n from "../../i18n";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import {
  getSelectedClientId,
  getUserClientId,
  getUserId,
} from "../../services/user/selectors";
import { openSnackMessage } from "../../services/general/actions";
import { changeField, getClientRequest } from "../../services/client/actions";
import CreateNewBox from "./CreateNewBox";
import { getAppLanguage } from "../../services/general/selectors";
import socket from "../../services/socket";
import SearchBar from "../../components/SearchBar";

moment.locale("fr");

const fuseOptions = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 80,
  maxPatternLength: 20,
  minMatchCharLength: 1,
  keys: [
    { name: "name", weight: 0.5 },
    { name: "clientCreatorDescription", weight: 0.3 },
    { name: "clientCreatorId.name", weight: 0.2 },
  ],
  // keys: ["name", "clientCreatorDescription", "clientCreatorId.name"],
};

const styles = theme => ({
  main: {
    width: "90%",
    marginTop: 15,
    flexDirection: "column",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  root: {
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
    marginBottom: 15,
  },
  leftSide: {
    width: "45%",
    minHeight: 700,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    display: "flex",
  },
  rightSide: {
    width: "45%",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    display: "flex",
    position: "relative",
    minHeight: 800,
    // flex: 1
  },
  container: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: 15,
    flex: 1,
    flexDirection: "row",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  saveButton: {
    marginTop: 15,
  },
  title: {
    fontWeight: 500,
    marginTop: 15,
    marginBottom: 15,
  },
  appTitleDiv: {
    width: 280,
    backgroundColor: Colors.mediumGreen,
    padding: 15,
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: 8,
    wordWrap: "break-word",
    // wordWrap: "break-word"
    // maxWidth: 280
  },
  appText: {
    minWidth: "1%",
    textAlign: "center",
    wordWrap: "break-word",
  },
  separator: {
    height: 1,
    marginTop: 5,
    marginBottom: 10,
    width: "45%",
    backgroundColor: Colors.mediumGrey,
  },
  separatorContainer: {
    marginTop: 15,
    width: 280,
    display: "flex",
    flexWrap: "wrap",
    wordBreak: "break-all",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  content: {
    width: 280,
    marginTop: 80,
    wordWrap: "break-word",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  paper: {
    flex: 1,
    marginTop: "calc(1rem)",
    marginLeft: "-1.5rem",
    marginRight: "-1.5rem",
    marginBottom: "calc(-3rem)",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "row",
  },
  activateDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  bold: {
    fontWeight: "bold",
  },
  notifText: {
    marginTop: 10,
    marginBottom: 10,
  },
  createDiv: {
    width: "33.3333%",
    flex: "0 0 auto",
    padding: "0px 1.5rem calc(2rem)",
    // padding: "0px 1rem calc(3rem)"
  },
  createCampaign: {
    textDecoration: "none",
    width: 300,
    // height: 250,
    height: 450,
    borderRadius: 8,
    backgroundColor: Colors.main,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    // marginRight: 25,
    // marginBottom: 20,
    transition: "0.2s",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
  createPollText: {
    color: "#fff",
  },
  bottomDiv: {
    width: "100%",
    borderTopWidth: 1,
    borderTop: "solid",
    borderTopColor: Colors.lightGrey,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  votesText: {
    marginLeft: 15,
    alignSelf: "left",
    backgroundColor: Colors.main,
    borderRadius: 8,
    color: "#fff",
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 8,
    paddingRight: 8,
  },
  noVotes: {
    marginLeft: 15,
    color: Colors.grey,
  },
  topDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  pollTitle: {
    textAlign: "center",
    marginLeft: 10,
    marginRight: 10,
  },
  searchBar: {
    minWidth: 300,
  },
  inputClassName: {
    border: "1px solid rgba(0,0,0,.09)",
    backgroundColor: "#fff",
  },
  "@media (max-width: 1024px)": {
    createDiv: { width: "50%" },
  },
});

class MyClients extends PureComponent {
  state = {
    expanded: null,
    labelWidth: 0,
    isCreationModalVisible: false,
    isSeeCampaignModalVisible: false,
    isDeleteConfirmModalVisible: false,
    isModifyInterface: false,
    search: "",
    clientSelected: {},
  };

  componentDidMount() {
    this.props.getAllMyClientsRequest(this.props.clientId);
  }

  onChangeDay = event => {
    const isoWeekday = Number(event.target.value);
    const reminderTime = moment(this.props.reminderTime).isoWeekday(isoWeekday);
    this.props.changeCoffeeSettings({ reminderTime });
  };

  onChangeTime = event => {
    const time = event.target.value;
    const minute = Number(time.split(":")[1]);
    const hour = Number(time.split(":")[0]);

    const reminderTime = moment(this.props.reminderTime)
      .minute(minute)
      .hour(hour);
    this.props.changeCoffeeSettings({ reminderTime });
  };

  onClickMore = () => {};

  onClickMainClient = () => {
    this.props.selectClient(null);
    this.props.getClientRequest({ clientId: this.props.client?._id });
    this.props.openSnackMessage({ snackMessage: i18n.t("client-selected") });
  };

  onClickClient = client => () => {
    const { selectedClientId, userId } = this.props;
    // modify client Id of the user to act as a user of another client
    // this.props.modifyUser({ client: client._id });
    socket.emit("admin-join-client", { clientId: selectedClientId, userId });

    this.props.selectClient(client._id);
    this.props.getSelectedClientRequest({ clientId: client._id });
    this.props.openSnackMessage({ snackMessage: i18n.t("client-selected") });
  };

  onClickDelete = clientSelected => () => {
    this.setState({
      isDeleteConfirmModalVisible: true,
      clientSelected,
    });
  };

  onClickModify = clientSelected => () => {
    this.setState({
      isCreationModalVisible: true,
      clientSelected,
    });
  };

  onClickHide = clientId => isHidden => {
    this.props.modifyMyClientRequest({ clientId: clientId, isHidden });
  };

  onConfirmDelete = event => {
    event.stopPropagation();
    this.props.deleteMyClientRequest({
      clientId: this.state.clientSelected._id,
    });
    this.onClickMainClient();
    this.setState({ isDeleteConfirmModalVisible: false });
  };

  displayClients = allClients => {
    const { selectedClientId } = this.props;
    return allClients.map((el, i) => {
      return (
        <ClientBox
          noSettings={!this.hasAccessToSettings()}
          isSelected={selectedClientId === el._id}
          key={i}
          {...el}
          onClickHide={this.onClickHide(el._id)}
          onClickModify={this.onClickModify(el)}
          onClickDelete={this.onClickDelete(el)}
          onClick={this.onClickClient(el)}
        />
      );
    });
  };

  hasAccessToSettings = () => {
    const { role } = this.props;
    return role === "owner" || role === "admin" || role === "superAdmin";
  };

  onCloseCreationModal = () => {
    this.setState({ isCreationModalVisible: false });
  };

  displayCreationModal = () => {
    this.setState({ isCreationModalVisible: true, clientSelected: null });
  };

  onChangeSearch = search => {
    this.setState({ search });
  };

  render() {
    const { classes, allClients, showHiddenClients = false } = this.props;
    const { search } = this.state;

    const filteredClients = allClients.filter(client =>
      showHiddenClients ? client.isHidden : !client.isHidden,
    );
    const fuse = new Fuse(filteredClients, fuseOptions);
    const searchResults =
      search.length > 0 ? fuse.search(search) : filteredClients;

    return (
      <div className={classes.main}>
        <CreationModal
          clientSelected={this.state.clientSelected}
          isVisible={this.state.isCreationModalVisible}
          onClose={this.onCloseCreationModal}
        />
        <DeleteConfirmModal
          title={i18n.t("delete-client-confirm-title")}
          onClickConfirm={this.onConfirmDelete}
          isVisible={this.state.isDeleteConfirmModalVisible}
          onClose={() => this.setState({ isDeleteConfirmModalVisible: false })}
        />
        <SearchBar
          placeholder={i18n.t("search-client-placeholder")}
          className={classes.searchBar}
          inputClassName={classes.inputClassName}
          onChangeSearch={this.onChangeSearch}
          onSubmit={() => console.log("submit")}
          value={this.state.search}
        />
        <div className={classes.paper}>
          <CreateNewBox
            title={i18n.t("create-new-client")}
            onClick={this.displayCreationModal}
          />
          <ClientBox
            {...this.props.client}
            noSettings
            isSelected={this.props.client._id === this.props.selectedClientId}
            label={i18n.t("main-account")}
            onClick={this.onClickMainClient}
          />
          {this.props.requesting ? (
            <Loader size={45} />
          ) : (
            this.displayClients(searchResults)
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { requesting, allClients = [] } = state.myClients;
  const { role } = state.user;
  const language = getAppLanguage(state);
  const clientId = getUserClientId(state);
  return {
    userId: getUserId(state),
    role,
    selectedClientId: getSelectedClientId(state),
    language,
    requesting,
    clientId,
    allClients,
    client: state.client,
  };
};

export default connect(mapStateToProps, {
  getAllMyClientsRequest,
  selectClient,
  getClientRequest,
  deleteMyClientRequest,
  getSelectedClientRequest,
  modifyMyClientRequest,
  openSnackMessage,
  changeField,
})(withStyles(styles)(MyClients));
