import React from "react";

import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";

import Colors from "../../constants/Colors";
import { motion } from "framer-motion";

const variants = {
  hidden: { opacity: 0, y: 40 },
  visible: { opacity: 1, y: 0 },
};

const styles = theme => ({
  main: {
    padding: "0px 1.5rem calc(2rem)",
  },

  createCampaign: {
    textDecoration: "none",
    width: 250,
    // height: 250,
    height: 350,
    borderRadius: 8,
    backgroundColor: Colors.main,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    // marginRight: 25,
    // marginBottom: 20,
    transition: "0.2s",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
  createPollText: {
    color: "#fff",
  },
  "@media (max-width: 1024px)": {
    main: { width: "50%" },
  },
});

const CreateNewBox = ({ classes, title, onClick }) => {
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      transition={{ duration: 0.5 }}
      variants={variants}
      // className={classes.main}
    >
      <div className={classes.main}>
        <div
          // to="/create"
          className={classes.createCampaign}
          onClick={onClick}
          data-intercom-target="New Client"
        >
          <AddIcon style={{ color: "#fff" }} />
          <Typography className={classes.createPollText}>{title}</Typography>
        </div>
      </div>
    </motion.div>
  );
};

export default withStyles(styles)(CreateNewBox);
