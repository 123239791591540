import React, { useState } from "react";
import Folder from "./Folder";
import Colors from "../../../constants/Colors";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ClickOutside from "../../../components/ClickOutside";
import Fuse from "fuse.js";
import AddFolderBox from "./AddFolderBox";
import { useSelector } from "react-redux";

import i18n from "../../../i18n";
import useFocus from "./useFocus";
import { getAppLanguage } from "../../../services/general/selectors";
import differenceBy from "lodash/differenceBy";
import { getFolderList } from "services/createTrack/selectors";
import { isEqual } from "lodash";
import areEqualShallow from "utils/areEqualShallow";

export const cleanInput = input => {
  if (!input) return input;
  const clearedInput = input
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .trim()
    .toLowerCase();
  return clearedInput;
};

const fuseOptions = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 80,
  maxPatternLength: 20,
  minMatchCharLength: 1,
  keys: ["label", "value"],
};

const useStyles = makeStyles(theme => ({
  main: {
    paddingLeft: 8,
    paddingRight: 2,
    cursor: "pointer",
    borderRadius: 5,
    paddingTop: 8,
    paddingBottom: 2,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    transition: "all 0.2s",
    "&:hover": {},
  },
  focused: {
    maxHeight: 240,
    width: "100%",
    minHeight: 34,
    backgroundColor: "rgba(242, 241, 238, 0.6)",
    boxShadow: "rgba(55, 53, 47, 0.16) 0px -1px inset",
    borderRadius: 0,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    fontSize: 14,
    cursor: "text",
    overflow: "hidden",
    padding: "8px 9px 1px",
    "&:hover": {
      backgroundColor: "rgba(242, 241, 238, 0.6)",
    },
  },
  input: {
    padding: 0,
    height: 20,
    fontSize: "inherit",
    lineHeight: "inherit",
    border: "none",
    background: "none",
    resize: "none",
    "&:focus": {
      outline: 0,
    },
  },
  bigBox: {
    borderRadius: 3,
    background: "white",
    position: "relative",
    maxWidth: "calc(100vw - 24px)",
    boxShadow:
      "rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px",
    width: 477,
  },
  emptyFolder: {
    padding: "5px 10px",
    border: "dashed 1px",
    borderRadius: 4,
    borderColor: Colors.pureMain,
  },
  notEmpty: {
    backgroundColor: Colors.editable,
  },
  notFocused: {
    "&:hover": {
      opacity: 0.8,
    },
  },
}));

const FolderInput = ({
  folders = [],
  updateStep,
  isDisabled = false,
  onOpen = () => null,
}) => {
  const [isFocused, setFocus] = useState(false);
  const [inputRef, setInputFocus] = useFocus();
  const [isOptionMenuOpen, openOptionMenu] = useState(false);
  const [search, setSearch] = useState("");

  const classes = useStyles();
  const folderList = useSelector(getFolderList);
  const language = useSelector(getAppLanguage);

  const onSearch = event => {
    setSearch(event.target.value);
  };

  const submit = () => {};

  const onKeyDown = event => {
    // delete last folder if search empty on Delete Key
    if ((event.key === "Delete" || event.key === "Backspace") && !search) {
      const stepFolders = folders.slice(0, folders.length - 1);
      updateStep(stepFolders);
    }
    // Tab and Enter event
    if (event.keyCode === 9 || event.keyCode === 13) {
      submit();
    }
  };

  const onClickFolderSuggestion = folder => () => {
    const stepFolders = [...folders, folder];
    updateStep(stepFolders);
    setSearch("");
    setInputFocus();
  };

  const onRemoveFolder = folder => () => {
    const stepFolders = folders.filter(el => el.value !== folder.value);
    updateStep(stepFolders);
    setInputFocus();
  };

  const getAvailableFolders = () => {
    return differenceBy(folderList.folders, folders, "value");
  };

  const onClickFolderInput = () => {
    if (!isDisabled) {
      setFocus(true);
      onOpen();
    }
  };

  const onClickOutside = () => {
    if (!isOptionMenuOpen) {
      setFocus(false);
    }
  };

  const foldersSuggestion = getAvailableFolders();
  const fuse = new Fuse(foldersSuggestion, fuseOptions);
  const searchResults =
    search.length > 0 ? fuse.search(search) : foldersSuggestion;

  const notEmpty = folders.length > 0;

  return (
    <ClickOutside onClickOutside={onClickOutside}>
      <div className={`${isFocused && classes.bigBox}`}>
        <div
          className={`${classes.main} ${isFocused && classes.focused} ${
            notEmpty && !isDisabled && classes.notEmpty
          } ${notEmpty && !isFocused && classes.notFocused}`}
          onClick={onClickFolderInput}
        >
          {folders.length < 1 && !isFocused && !isDisabled && (
            <Typography className={classes.emptyFolder}>{`+ ${i18n.t(
              "add-folder",
            )}`}</Typography>
          )}
          {folders.map((el, index) => {
            const folder = el;
            return (
              <Folder
                {...folder}
                label={folder.label}
                key={index}
                hasRemove={isFocused}
                onClickRemove={onRemoveFolder(folder)}
              />
            );
          })}
          {isFocused && (
            <input
              ref={inputRef}
              onKeyDown={onKeyDown}
              autoFocus
              className={classes.input}
              onChange={onSearch}
              value={search}
            />
          )}
        </div>
        {isFocused && (
          <AddFolderBox
            search={search}
            language={language}
            onClickCreate={submit}
            onClickFolder={onClickFolderSuggestion}
            foldersSuggestion={searchResults}
            openOptionMenu={openOptionMenu}
          />
        )}
      </div>
    </ClickOutside>
  );
};

function areEqual(prevProps, nextProps) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */

  const equal = areEqualShallow(
    { ...prevProps, folders: undefined },
    { ...nextProps, folders: undefined },
  );

  if (!equal) return false;

  if (prevProps.folders.length === 0 && nextProps.folders.length === 0)
    return true;

  if (prevProps.folders.length === nextProps.folders.length) {
    return isEqual(prevProps.folders, nextProps.folders);
  }
}

export default React.memo(FolderInput, areEqual);
