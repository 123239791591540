import React from "react";

const RecordAudioIcon = ({
  width = "1.5rem",
  height = "1.5rem",
  color = "#006cfa",
  className = "",
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
    version="1.1"
  >
    <path
      d="M13 7.25C12.5858 7.25 12.25 7.58579 12.25 8C12.25 8.41421 12.5858 8.75 13 8.75V7.25ZM13 12.25C12.5858 12.25 12.25 12.5858 12.25 13C12.25 13.4142 12.5858 13.75 13 13.75V12.25ZM20.75 12C20.75 11.5858 20.4142 11.25 20 11.25C19.5858 11.25 19.25 11.5858 19.25 12H20.75ZM4.75 12C4.75 11.5858 4.41421 11.25 4 11.25C3.58579 11.25 3.25 11.5858 3.25 12H4.75ZM7.75 14V7H6.25V14H7.75ZM16.25 7V8H17.75V7H16.25ZM17 7.25H13V8.75H17V7.25ZM16.25 8V13H17.75V8H16.25ZM16.25 13V14H17.75V13H16.25ZM17 12.25H13V13.75H17V12.25ZM12 18.25C9.65279 18.25 7.75 16.3472 7.75 14H6.25C6.25 17.1756 8.82436 19.75 12 19.75V18.25ZM12 19.75C15.1756 19.75 17.75 17.1756 17.75 14H16.25C16.25 16.3472 14.3472 18.25 12 18.25V19.75ZM12 2.75C14.3472 2.75 16.25 4.65279 16.25 7H17.75C17.75 3.82436 15.1756 1.25 12 1.25V2.75ZM12 1.25C8.82436 1.25 6.25 3.82436 6.25 7H7.75C7.75 4.65279 9.65279 2.75 12 2.75V1.25ZM19.25 12V14H20.75V12H19.25ZM4.75 14V12H3.25V14H4.75ZM12 21.25C7.99594 21.25 4.75 18.0041 4.75 14H3.25C3.25 18.8325 7.16751 22.75 12 22.75V21.25ZM12 22.75C16.8325 22.75 20.75 18.8325 20.75 14H19.25C19.25 18.0041 16.0041 21.25 12 21.25V22.75Z"
      fill={color}
    />
  </svg>
);

export default RecordAudioIcon;
