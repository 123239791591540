import React from "react";

const EmptyPremiumIcon = ({ sizePercentage }) => (
  <svg
    width={sizePercentage * 24}
    height={sizePercentage * 25}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 25"
    version="1.1"
  >
    <path
      d="M3.51625 9.79311L11.5067 20.157C11.5647 20.2327 11.6394 20.294 11.725 20.3362C11.8106 20.3784 11.9047 20.4004 12.0001 20.4004C12.0955 20.4004 12.1897 20.3784 12.2752 20.3362C12.3608 20.294 12.4355 20.2327 12.4936 20.157L20.484 9.79311C20.5626 9.69082 20.6082 9.56704 20.6147 9.43822C20.6212 9.30941 20.5884 9.18166 20.5205 9.07195L17.8102 4.69693C17.7544 4.60639 17.6763 4.53162 17.5835 4.47972C17.4907 4.42782 17.3861 4.40051 17.2798 4.40039H6.72049C6.61414 4.40051 6.50958 4.42782 6.41675 4.47972C6.32392 4.53162 6.2459 4.60639 6.19011 4.69693L3.47971 9.07195C3.41188 9.18166 3.37905 9.30941 3.38558 9.43822C3.39211 9.56704 3.43768 9.69082 3.51625 9.79311V9.79311Z"
      stroke="white"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4 9.32373H20.0001"
      stroke="white"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.5385 5.01578L15.6923 9.32349L12 4.40039"
      stroke="white"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.46191 5.01578L8.30808 9.32349L12.0004 4.40039"
      stroke="white"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.9999 19.7853L8.30762 9.32373"
      stroke="white"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 19.7853L15.6923 9.32373"
      stroke="white"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

EmptyPremiumIcon.defaultProps = {
  sizePercentage: 1,
};

export default EmptyPremiumIcon;
