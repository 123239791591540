import React from "react";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import i18n from "i18n";

const AppleStoreWhiteBadge = require("../../../resources/AppleStoreWhiteBadge.png");
const GooglePlayWhiteBadge = require("../../../resources/GooglePlayWhiteBadge.png");

const APP_STORE_URL = "https://apps.apple.com/us/app/microdoing/id1479333825?";
const GOOGLE_PLAY_URL =
  "https://play.google.com/store/apps/details?id=com.microdoing&hl=fr&gl=US";

const useStyles = makeStyles(theme => ({
  main: {
    width: "100%",
    backgroundColor: "#F5FBFF",
    borderRadius: 4,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 25,
    paddingBottom: 25,
    position: "relative",
    border: "1px solid rgba(99, 186, 249, 0.4)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  leftDiv: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    flex: 1,
    marginRight: 45,
  },
  rightDiv: {
    // height: "100%",
    display: "flex",
    alignItems: "center",
    height: 130 * 0.8,
    justifyContent: "space-between",
    flexDirection: "column",
  },
  title: {
    color: "rgba(99, 186, 249, 1)",
    fontSize: 18,
    fontFamily: "Montserrat",
    fontWeight: "bold",
    marginBottom: 10,
  },
  text: {
    fontSize: 16,
    fontFamily: "Hind",
    color: "#7A8F9F",
    lineHeight: "1.7em",
  },
  badge: {
    border: "1px solid rgba(122, 143, 159, 0.2)",
    borderRadius: 4,
    alignSelf: "center",
    objectFit: "contain",
    width: 220 * 0.75,
    height: 61 * 0.75,
    transition: "all 0.2s easeIn",
    "&:hover": {
      border: "1px solid rgba(122, 143, 159, 0.7)",
    },
  },
}));

const InvitationInfoBox = () => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <div className={classes.leftDiv}>
        <Typography className={classes.title}>
          {i18n.t("connect-application")}
        </Typography>
        <Typography className={classes.text}>
          {i18n.t("connect-application-explanation")}
        </Typography>
      </div>
      <div className={classes.rightDiv}>
        <a href={APP_STORE_URL} target="_blank" rel="noopener noreferrer">
          <img
            src={AppleStoreWhiteBadge}
            className={classes.badge}
            alt="apple store badge"
          />
        </a>
        <a href={GOOGLE_PLAY_URL} target="_blank" rel="noopener noreferrer">
          <img
            src={GooglePlayWhiteBadge}
            className={classes.badge}
            alt="google play badge"
          />
        </a>
      </div>
    </div>
  );
};

export default InvitationInfoBox;
