import React, { useState } from "react";
import withStyles from '@mui/styles/withStyles';
import { CSVReader, readString } from "react-papaparse";
import { Typography, TextField } from "@mui/material";
import i18n from "../../i18n";
import validateEmail from "../../utils/validateEmail";

const config = {
  skipEmptyLines: "greedy",
};

const styles = theme => ({
  main: {
    marginBottom: 25,
  },
  text: {
    fontFamily: "Montserrat",
    fontSize: 18,
  },
  title: {
    fontFamily: "Montserrat",
    fontSize: 16,
    marginTop: 15,
    marginBottom: 25,
  },
  bold: {
    fontWeight: "bold",
  },
  input: {},
  zone: {
    padding: 100,
    marginBottom: 25,
  },
  separator: {
    width: "100%",
    textAlign: "center",
    fontSize: 18,
    fontFamily: "Montserrat",
    marginTop: 25,
    marginBottom: 25,
    fontWeight: "bold",
  },
});

const CSVReaderInput = ({
  classes,
  setResults,
  results = [],
  hasUpload = true,
}) => {
  const [input, setInput] = useState(results.join(", "));

  const handleOnDrop = data => {
    if (data.length) {
      const allEmails = data.reduce((prev, curr) => {
        curr.data.forEach(element => {
          if (element) {
            const cleanElement = element.trim();
            if (validateEmail(cleanElement)) {
              prev.push(cleanElement);
            }
          }
        });
        return prev;
      }, []);
      setResults(allEmails);

      setInput(allEmails.join(", "));
    }
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleOnRemoveFile = data => {};

  const onChangeInput = e => {
    const value = e.target.value;
    setInput(value);
    const allEmails = readString(value, config);
    if (!value) {
      return setResults([]);
    }

    if (allEmails && allEmails.data && allEmails.data.length > 0) {
      const cleanResults = allEmails.data[0].reduce((prev, curr) => {
        const cleanEmail = curr.trim();
        if (validateEmail(cleanEmail)) {
          prev.push(cleanEmail);
        }
        return prev;
      }, []);
      setResults(cleanResults);
    }
  };

  return (
    <div className={classes.main}>
      <Typography className={classes.title}>
        {hasUpload ? (
          <>
            {i18n.t("emails-number")}
            <span className={classes.bold}>{results.length}</span>
          </>
        ) : (
          i18n.t("enter-coachee-email")
        )}
      </Typography>
      <TextField
        fullWidth
        multiline
        label={hasUpload ? i18n.t("paste-contacts") : i18n.t("email-address")}
        variant="outlined"
        className={classes.input}
        value={input}
        onChange={onChangeInput}
        helperText={hasUpload ? i18n.t("paste-contacts-explanation") : null}
      ></TextField>
      {hasUpload && (
        <>
          <Typography className={classes.separator}>{i18n.t("Or")}</Typography>
          <CSVReader
            className={classes.zone}
            onDrop={handleOnDrop}
            onError={handleOnError}
            addRemoveButton
            onRemoveFile={handleOnRemoveFile}
          >
            <Typography className={classes.text}>
              {i18n.t("click-or-drag-upload")}
            </Typography>
          </CSVReader>
        </>
      )}
    </div>
  );
};

export default withStyles(styles)(CSVReaderInput);
