import React from "react";

const PulseIcon = ({ sizePercentage, color, className }) => (
  <svg
    className={className}
    width={sizePercentage * 30}
    height={sizePercentage * 24}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 30 24"
    version="1.1"
  >
    <path
      d="M27.7902 10.2833H22.1906C22.0916 10.2698 21.9912 10.2698 21.8922 10.2833H21.6506C21.5763 10.3257 21.5051 10.3732 21.4374 10.4254C21.3583 10.4777 21.2823 10.5346 21.21 10.5959C21.1626 10.6541 21.1198 10.7158 21.0821 10.7807C21.017 10.8702 20.9599 10.9654 20.9115 11.0649L18.6376 17.3325L12.7111 1.27276C12.6115 0.998393 12.4299 0.76134 12.1909 0.593817C11.9519 0.426294 11.6671 0.336426 11.3752 0.336426C11.0833 0.336426 10.7985 0.426294 10.5595 0.593817C10.3205 0.76134 10.1388 0.998393 10.0392 1.27276L6.75622 10.2833H2.20833C1.8314 10.2833 1.4699 10.433 1.20337 10.6995C0.936844 10.9661 0.787109 11.3276 0.787109 11.7045C0.787109 12.0814 0.936844 12.4429 1.20337 12.7094C1.4699 12.976 1.8314 13.1257 2.20833 13.1257H8.31956C8.40034 13.0868 8.4766 13.0392 8.54696 12.9836C8.62609 12.9313 8.70203 12.8744 8.77435 12.813L8.90226 12.6283C8.97 12.5405 9.02724 12.4451 9.07281 12.344L11.3752 5.90593L17.2874 22.1362C17.3868 22.4109 17.5683 22.6483 17.8073 22.8162C18.0464 22.984 18.3313 23.0741 18.6234 23.0742C18.9155 23.0741 19.2004 22.984 19.4395 22.8162C19.6785 22.6483 19.86 22.4109 19.9593 22.1362L23.2281 13.1257H27.7902C28.1672 13.1257 28.5287 12.976 28.7952 12.7094C29.0617 12.4429 29.2115 12.0814 29.2115 11.7045C29.2115 11.3276 29.0617 10.9661 28.7952 10.6995C28.5287 10.433 28.1672 10.2833 27.7902 10.2833Z"
      fill={color}
    />
  </svg>
);

PulseIcon.defaultProps = {
  sizePercentage: 1,
  color: "#FF43BF",
  className: "",
};

export default PulseIcon;
