import React from "react";
import withStyles from '@mui/styles/withStyles';
import Colors from "constants/Colors";

const styles = theme => ({
  main: {
    borderRadius: 2,
    backgroundColor: "#F2F2F2",
    width: "100%",
    height: 32,
  },
  bar: {
    borderRadius: 2,
    height: 32,
    transition: "all .5s ease-in-out",
    background: Colors.pureMain,
    width: "0%",
  },
});

const Bar = ({ classes, color, percentage }) => {
  return (
    <div className={classes.main}>
      <div
        className={classes.bar}
        style={{ background: color, width: `${percentage}%` }}
      ></div>
    </div>
  );
};

export default withStyles(styles)(Bar);
