let url = "https://api.microdoing.com";
// const url = "http://localhost";
// let url = "https://microdoing-api.eu.ngrok.io";

if (process.env.NODE_ENV === "production") {
  url = "https://api.microdoing.com";
}

export default {
  url,
};

console.log("NODE ENV");
console.log(process.env.NODE_ENV);
