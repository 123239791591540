import removeHtmlTags from "./removeHtmlTags";

interface Args {
  text:
    | {
        fr: string;
        en: string;
      }
    | {};
  language?: string;
  noFallBack?: boolean;
}

const selectLanguage = ({ text, language, noFallBack }: Args) => {
  let goodLanguage = "fr";

  if (language) {
    goodLanguage = language;
    if (language.includes("fr-")) {
      goodLanguage = "fr";
    }
  }

  if (!text) {
    return "";
  }
  if (text?.hasOwnProperty(goodLanguage)) {
    return removeHtmlTags(text[goodLanguage]);
  }
  if (noFallBack) {
    return "";
    // fallback language
  } else if (text["fr"]) {
    return removeHtmlTags(text["fr"]);
  }
  // the only language
  else if (Object.values(text)[0]) {
    return removeHtmlTags(Object.values(text)[0]);
  }
  return "";
};

export default selectLanguage;
