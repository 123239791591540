import React from "react";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import i18n from "i18n";

const useStyles = makeStyles(theme => ({
  main: {
    marginBottom: 25,
    marginTop: 25,
    width: "100%",
    alignSelf: "center",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  line: {
    backgroundColor: "#B0BDC9",
    height: 1,
    width: "100%",
    position: "absolute",
  },
  or: {
    alignSelf: "center",
    color: "#B0BDC9",
    width: 50,
    backgroundColor: "#fff",
    zIndex: 1,
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const OrSeparator = () => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Typography className={classes.or}>{i18n.t("OR")}</Typography>
      <div className={classes.line} />
    </div>
  );
};

export default OrSeparator;
