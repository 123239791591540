import React, { useState } from "react";
import withStyles from '@mui/styles/withStyles';
import Folder from "./Folder";
import { IconButton } from "@mui/material";
import CreateFolder from "scenes/CreateTrack/FolderList/CreateFolder";
import EditIcon from "components/EditIcon";
import DeleteIcon from "scenes/Campaigns/DeleteIcon";
import Colors from "constants/Colors";

const styles = theme => ({
  main: {},
  folderRow: {
    height: 50,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: Colors.lightGrey,
    },
  },
  icons: {
    flex: 1,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    flexDirection: "row",
  },
});

const FolderRow = ({
  classes,
  onClickFolder,
  onClickDelete = null,
  folderSelected = {},
  folder = {},
}) => {
  const [isEditing, setEditing] = useState(false);
  const [isHovered, setHover] = useState(false);

  const onClickEdit = e => {
    e.stopPropagation();
    setEditing(true);
  };

  const onDelete = e => {
    e.stopPropagation();
    onClickDelete();
  };

  return (
    <div
      className={classes.folderRow}
      onClick={onClickFolder}
      onMouseLeave={() => setHover(false)}
      onMouseEnter={() => setHover(true)}
    >
      {isEditing ? (
        <CreateFolder
          label={folder.label}
          value={folder.value}
          setCreating={() => setEditing(false)}
        />
      ) : (
        <Folder
          {...folder}
          onClick={onClickFolder}
          isSelected={folderSelected.value === folder.value}
        />
      )}
      {isHovered && (
        <div className={classes.icons}>
          <IconButton className={classes.settings} onClick={onClickEdit} size="large">
            <EditIcon color={"#000000"} />
          </IconButton>
          <IconButton className={classes.settings} onClick={onDelete} size="large">
            <DeleteIcon color={"red"} />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(FolderRow);
