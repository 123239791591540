import React from "react";

import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import Uploader from "../../../components/Uploader";
import i18n from "../../../i18n";

const styles = theme => ({
  main: {
    position: "relative",
  },
  bodyDiv: {
    maxWidth: "100%",
    width: 960,
  },
  subTitle: {
    fontFamily: "Montserrat",
    marginTop: 20,
    marginBottom: 5,
    fontSize: 22,
    lineHeight: 1.2143,
    fontWeight: 700,
  },
  explanation: {
    textAlign: "left",
    maxWidth: "100%",
    width: 960,
    marginTop: 25,
    fontSize: 15,
    lineHeight: 1.2143,
  },
  uploader: {
    marginTop: 16,
  },
  firstDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  leftDiv: {
    justifyContent: "flex-start",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  rightDiv: {
    marginLeft: 40,
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
});

const TrackSettings = ({
  classes,
  onClickSubmit,
  onClickPrevious,
  requesting,
  title,
  description,
  onChangeDescription,
  onChangeTitle,
  onFileUploaded,
  uploading,
  picture,
  onClickAdministrationMode,
  templateSettings,
  openFromTemplate,
  isSuperAdmin = false,
  onChangePicture,
}) => {
  const onClickUnsplashPicture = picture => () => {
    onChangePicture(picture);
  };

  let TITLE_TEXT = i18n.t("campaign-title");
  let DESCRIPTION_TEXT = i18n.t("campaign-description");
  let PICTURE_TEXT = i18n.t("campaign-picture");
  if (openFromTemplate) {
    TITLE_TEXT = i18n.t("template-description");
    DESCRIPTION_TEXT = i18n.t("template-description");
    PICTURE_TEXT = i18n.t("template-picture");
  }
  return (
    <div className={classes.main}>
      <Typography className={classes.explanation}>
        {i18n.t("track-explanation")}
      </Typography>

      <div className={classes.firstDiv}>
        <div className={classes.leftDiv}>
          <Typography className={classes.subTitle}>{TITLE_TEXT}</Typography>
          <TextField
            autoFocus
            fullWidth
            label={TITLE_TEXT}
            value={title}
            onChange={onChangeTitle}
            margin="normal"
            variant="outlined"
          />
          <Typography className={classes.subTitle}>
            {DESCRIPTION_TEXT}
          </Typography>
          <TextField
            fullWidth
            multiline
            label={DESCRIPTION_TEXT}
            value={description}
            onChange={onChangeDescription}
            margin="normal"
            variant="outlined"
          />
        </div>
        <div className={classes.rightDiv}>
          <Typography className={classes.subTitle}>{PICTURE_TEXT}</Typography>
          <Uploader
            width={250}
            height={400}
            picture={picture}
            className={classes.uploader}
            onFileUploaded={onFileUploaded}
            uploading={uploading}
            onClickUnsplashPicture={onClickUnsplashPicture}
            hasUnsplash
          />
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(TrackSettings);
