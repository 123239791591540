import { createSelector } from "reselect";
import { getUserId } from "services/user/selectors";

export const getAppLanguage = state => state.general.language;

export const getIsFirstConnection = state => state.general.isFirstConnection;

export const getAdminConnections = state =>
  state.general.adminConnections || [];

export const getIsMyMobileConnected = state =>
  state.general.isMyMobileConnected;

export const getLocation = state => state.router.location.pathname;

export const getLocationObject = state => state.router.location;

export const getRouterQuery = state => state.router.location.query || {};

export const getOtherAdminConnections = createSelector(
  getAdminConnections,
  getUserId,
  (adminConnections, userId) => {
    return adminConnections;
  },
);
