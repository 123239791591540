import React from "react";
import withStyles from '@mui/styles/withStyles';
import TopBar from "components/TopBar";
import i18n from "i18n";
import { Typography } from "@mui/material";
import ObjectiveBar from "scenes/Campaigns/CampaignScreen/StatsSide/ObjectivesSection/ObjectiveBar";
import { flatten, uniqBy, sortBy } from "lodash";
import selectLanguage from "utils/selectLanguage";

const styles = theme => ({
  main: {
    overflow: "hidden",
    marginTop: 30,
    paddingBottom: 30,
    borderRadius: 10,
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
  },
  topDiv: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    marginTop: 35,
    paddingLeft: 45,
    paddingRight: 45,
  },
  firstTitle: {
    width: 250,
    color: "#717171",
  },
  title: {
    textAlign: "center",
    width: 130,
    color: "#717171",
  },
  number: {
    marginLeft: 10,
    textAlign: "center",
    width: 80,
    color: "#717171",
  },
  bottomDiv: {
    boxSizing: "border-box",
    marginTop: 40,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    paddingLeft: 45,
    paddingRight: 45,
  },
});

const formatTopicChoices = topicChoices => {
  const choices = flatten(topicChoices);
  const choicesCount = choices.reduce((prev, curr) => {
    const { value } = curr;
    prev[value] = (prev[value] || 0) + 1;
    return prev;
  }, {});
  const uniqChoices = uniqBy(choices, "value");
  const uniqWithCount = uniqChoices.map(el => {
    el.count = choicesCount[el.value];
    return el;
  });

  const sortedChoices = sortBy(uniqWithCount, "count").reverse();
  return sortedChoices;
};

const ObjectivesSection = ({
  classes,
  language = "fr",
  topicChoices = [
    {
      title: {
        fr: "Mieux s’organiser et se dégager du temps",
        en: "Better organization",
      },
      value: "topic1",
    },
  ],
}) => {
  const formattedChoices = formatTopicChoices(topicChoices);
  const totalUniqUsers = topicChoices.length;
  return (
    <div className={classes.main}>
      <TopBar title={i18n.t("participant-objectives")} />
      <div className={classes.topDiv}>
        <Typography className={classes.firstTitle}>
          {i18n.t("skill")}
        </Typography>
        <Typography className={classes.title}>
          {i18n.t("selection-rate")}
        </Typography>
        <Typography className={classes.number}>
          {i18n.t("selections")}
        </Typography>
      </div>
      <div className={classes.bottomDiv}>
        {formattedChoices.map((el, i) => {
          const { count, title } = el;
          return (
            <ObjectiveBar
              key={i}
              {...el}
              title={selectLanguage({ text: title, language })}
              percent={((count / totalUniqUsers) * 100).toFixed(0)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default withStyles(styles)(ObjectivesSection);
