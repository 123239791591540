import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Colors from "../../../../constants/Colors";
import moment from "moment";
import Fuse from "fuse.js";
import findLastIndex from "lodash/findLastIndex";
import {
  changeField,
  editManagerScheduledNotification,
  seeUserProfileRequest,
  sendNotificationRequest,
} from "../../../../services/manager/actions";
import AddParticipantIcon from "scenes/Campaigns/CampaignScreen/SideBar/AddParticipantIcon";
import { changeAddUsersModal } from "services/campaign/actions";
import "tippy.js/dist/tippy.css";

import { useDispatch, useSelector } from "react-redux";
import { getUser, getUserId } from "../../../../services/user/selectors";
import {
  getUserCampaignsListRequest,
  loadMoreUserCampaignsRequest,
  deleteUserCampaignRequest,
  addRandomStepRequest,
} from "../../../../services/campaign/actions";
import {
  cleanMessage,
  setReceiver,
} from "../../../../services/message/actions";
import {
  getCoachingSessionRequest,
  clearCoachingSession,
} from "services/coaching/actions";
import { openSnackMessage } from "../../../../services/general/actions";

import i18n from "../../../../i18n";
import ListItemLoader from "../ListItemLoader";

import DeleteConfirmModal from "../../../../components/DeleteConfirmModal";
import LearnerRow from "./LearnerRow";
import StatBox from "scenes/Campaigns/CampaignScreen/StatBox";
import ParticipantIcon from "scenes/Campaigns/CampaignScreen/StatsSide/ParticipantIcon";
import LightIcon from "scenes/CreateTrack/LightIcon";
import PulseIcon from "scenes/Campaigns/CampaignScreen/StatsSide/PulseIcon";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { sortBy as lodashSortBy, findLast } from "lodash";
import CheckBox from "components/CheckBox";
import FlatButton from "components/FlatButton";
import EyeOpenIcon from "scenes/Campaigns/CampaignScreen/LearnerSide/EyeOpenIcon";
import EyeClosedIcon from "scenes/Campaigns/CampaignScreen/LearnerSide/EyeClosedIcon";
import {
  getCampaign,
  getCampaignSelected,
  getUserCampaigns,
} from "services/campaign/selectors";
import { Loader } from "components";
import HeaderParticipantList from "./HeaderParticipantList";
import { push } from "connected-react-router";
import NotificationModal from "../Notifications/NotificationModal";
import { displayNumberOfActionDone } from "utils/doneStepsByUser";
import SelectedActions from "./SelectedActions";

const fuseOptions = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 80,
  maxPatternLength: 20,
  minMatchCharLength: 1,
  keys: ["fullName"],
};

moment.locale("fr");

const useStyle = makeStyles({
  rightDiv: {
    overflow: "auto",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 4,
    marginBottom: 15,
    marginTop: 15,
  },
  title: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "29px",
    color: Colors.darkBlue,
    marginRight: 30,
    flex: 1,
  },
  firstSection: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    overflow: "auto",
    justifyContent: "space-around",
    alignItems: "flex-start",
    marginBottom: 15,
  },
  firstRow: {
    display: "flex",
    flex: 1.2,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  row: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  tableHeaderRow: {
    boxSizing: "border-box",
    color: "#101534",
    fontSize: 18,
    width: "100%",
    marginBottom: 30,
    borderRadius: 8,
    display: "flex",
    alignItems: "space-between",
    flexDirection: "row",
    justifyContent: "center",
  },
  tableHeader: {
    fontSize: 18,
    fontWeight: 500,
    color: Colors.darkBlue,
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  topDiv: {
    overflow: "scroll",
    position: "relative",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  box: {
    marginTop: 30,
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 40,
    paddingRight: 32,
    width: "-webkit-fill-available",
    overflow: "hidden",
    backgroundColor: "#fff",
    borderRadius: 10,
  },
  topBar: {
    alignItems: "center",
    justifyContent: "flex-start",
    borderBottom: `solid 1px ${Colors.lightGrey}`,
    backgroundColor: "#fff",
  },
  header: {
    display: "flex",
    flexDirection: "row",
  },
  checkBox: {
    marginRight: 8,
  },
  normal: {
    fontWeight: "normal",
  },
  icon: {
    marginRight: 12,
  },
  notificationVisibleCheckBox: {
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": { backgroundColor: Colors.hoverBlue },
    padding: 10,
    minHeight: 44,
    borderRadius: 5,
    position: "absolute",
    left: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  notificationTitle: {
    color: "red",
    textAlign: "end",
  },
});

const getLastActionDate = steps => {
  const lastDoneStep = findLast(steps, { done: true });
  if (lastDoneStep) {
    return lastDoneStep.doneDate;
  }
  return null;
};

const formatUserCampaigns = userCampaigns => {
  return userCampaigns.map(el => {
    el.lastDoneDate = getLastActionDate(el.steps);
    return el;
  });
};

const LearnersSide = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const limit = 1000;
  const [userSelected, setUserSelected] = useState({});
  const [usersSelected, setUsersSelected] = useState([]);
  const [areAllUsersSelected, setAreAllUsersSelected] = useState(false);
  const [
    isDeleteConfirmModalVisible,
    setIsDeleteConfirmModalVisible,
  ] = useState(false);
  const [
    isSendNotificationModalVisible,
    setIsSendNotificationModalVisible,
  ] = useState(false);
  const [isNotificationStatVisible, setIsNotificationStatVisible] = useState(
    false,
  );
  const [sortedBy, setSortedBy] = useState("points");
  const [isSortedDesc, setIsSortedDesc] = useState(true);
  const [
    isHistoryNotificationModalVisible,
    setIsHistoryNotificationModalVisible,
  ] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("mixedUser"); // TODO typing <FilterParticipantsType>
  const [numberOfInactiveUsers, setNumberOfInactiveUsers] = useState(0);
  const [numberOfActiveUsers, setNumberOfActiveUsers] = useState(0);
  const [numberOfRealisation, setNumberOfRealisation] = useState(0);
  const [numberOfParticipation, setNumberOfParticipation] = useState(0);
  const [isOnEditNotification, setIsOnEditNotification] = useState(false);
  const [notificationId, setNotificationId] = useState("");

  const { timezone, usersAccess, hasUsersAccessRestricted, role } = useSelector(
    getUser,
  );
  const userId = useSelector(getUserId);
  const { isLoading, doneSteps } = useSelector(getCampaign);
  const campaignSelected = useSelector(getCampaignSelected);
  const userCampaigns = formatUserCampaigns(useSelector(getUserCampaigns));
  const { participants, campaignActionGoal } = campaignSelected;
  const campaignId = campaignSelected._id;
  const trackId = campaignSelected.trackId;
  const isSuperAdmin = role === "superAdmin";
  const dispatch = useDispatch();

  const setFilter = filterSelected => {
    // TODO mettre le typescript file : FilterParticipantsType
    const tempUsersSelected = [];

    if (filterSelected !== "cancelSelection") {
      userCampaigns.forEach(el => {
        const timeFromLastStep = moment().diff(moment(el.lastDoneDate), "days");

        if (
          filterSelected === "realisationCampaign" &&
          displayNumberOfActionDone(el.steps) >= campaignActionGoal
        ) {
          tempUsersSelected.push(el.userId);
        } else if (
          filterSelected === "participationCampaign" &&
          displayNumberOfActionDone(el.steps) < campaignActionGoal
        ) {
          tempUsersSelected.push(el.userId);
        } else if (
          filterSelected === "activeUser" &&
          timeFromLastStep <= 7 &&
          el.lastDoneDate !== null
        ) {
          tempUsersSelected.push(el.userId);
        } else if (
          (filterSelected === "inactiveUser" && timeFromLastStep > 7) ||
          (filterSelected === "inactiveUser" && el.lastDoneDate === null)
        ) {
          tempUsersSelected.push(el.userId);
        } else if (filterSelected === "allUser") {
          tempUsersSelected.push(el.userId);
        }
      });
    }

    setSelectedFilter(filterSelected);
    setUsersSelected(tempUsersSelected);
    setAreAllUsersSelected(filterSelected === "allUser" ? true : false);
  };

  useEffect(() => {
    dispatch(
      getUserCampaignsListRequest({
        campaignId: campaignId,
        page: 0,
        limit: limit,
      }),
    );
  }, [dispatch, campaignId, limit]);

  useEffect(() => {
    let inactive = 0;
    let active = 0;
    let realisation = 0;
    let participation = 0;

    userCampaigns.forEach(el => {
      const timeFromLastStep = moment().diff(moment(el.lastDoneDate), "days");

      if (timeFromLastStep <= 7 && el.lastDoneDate !== null) {
        active += 1;
      } else if (timeFromLastStep > 7 || el.lastDoneDate === null) {
        inactive += 1;
      }
      if (displayNumberOfActionDone(el.steps) >= campaignActionGoal) {
        realisation += 1;
      } else {
        participation += 1;
      }
    });
    setNumberOfInactiveUsers(inactive);
    setNumberOfActiveUsers(active);
    setNumberOfRealisation(realisation);
    setNumberOfParticipation(participation);
  }, [userCampaigns, campaignActionGoal]);

  useEffect(() => {
    if (areAllUsersSelected) {
      setFilter("allUser");
    }
  }, [areAllUsersSelected]);

  const handleScroll = event => {
    const element = event.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      dispatch(
        loadMoreUserCampaignsRequest({
          campaignId: campaignId,
          // limit: limit, // TODO probleme dans le saga => il ne recois pas de limit ni de page dans le reducer => j'adore typescript
          // page: page + 1,
        }),
      );
      setPage(page + 1);
    }
  };

  const openUserModal = userSelected => {
    if (!hasAccess(userSelected.userId)) {
      return dispatch(
        openSnackMessage({
          snackMessage: i18n.t("access-forbidden"),
          snackStatus: "alert",
        }),
      );
    }

    dispatch(clearCoachingSession());
    dispatch(cleanMessage());
    dispatch(
      setReceiver({
        _id: userSelected.userId,
        picture: userSelected.picture,
      }),
    );
    dispatch(
      seeUserProfileRequest({
        userId: userSelected.userId,
        managerId: userId,
      }),
    );
    dispatch(
      getCoachingSessionRequest({
        userId: userSelected.userId,
        coachId: userId,
      }),
    );
    dispatch(push(`/user/${userSelected.userId}`));
  };

  const hasAccess = userId => {
    if (hasUsersAccessRestricted) {
      if (!usersAccess) {
        return false;
      }
      return usersAccess.findIndex(el => el._id === userId) > -1;
    }
    return true;
  };

  const onClickDelete = userSelected => event => {
    event.stopPropagation();
    setIsDeleteConfirmModalVisible(true);
    setUserSelected(userSelected);
  };

  const onConfirmDelete = () => {
    const userCampaignId = userSelected._id;
    dispatch(deleteUserCampaignRequest({ userCampaignId }));
    setIsDeleteConfirmModalVisible(false);
  };

  const onClickAddRandomStep = ({ userCampaignId, userId }) => event => {
    event.stopPropagation();
    dispatch(addRandomStepRequest({ userCampaignId, trackId, userId }));
  };

  const getActiveParticipants = () => {
    const result = userCampaigns.reduce((prev, curr) => {
      const lastDoneStepIndex = findLastIndex(curr.steps, el => el.done);
      if (lastDoneStepIndex === -1) return prev;
      return prev + 1;
    }, 0);
    return result;
  };

  const sortBy = steps => {
    if (isSortedDesc) {
      return lodashSortBy(steps, sortedBy).reverse();
    }

    return lodashSortBy(steps, sortedBy);
  };

  const displayArrow = param => {
    return sortedBy === param ? (
      isSortedDesc ? (
        <ExpandLessIcon />
      ) : (
        <ExpandMoreIcon />
      )
    ) : null;
  };

  const changeSortingParam = param => () => {
    setSortedBy(param);
    setIsSortedDesc(!isSortedDesc);
  };

  const handleNotificationVisibleCheck = () => {
    setIsNotificationStatVisible(!isNotificationStatVisible);
  };

  const onCheckUser = userId => e => {
    e.preventDefault();
    e.stopPropagation();

    let tempUsersSelected = [...usersSelected, userId];

    // was checked, delete it
    if (usersSelected.includes(userId)) {
      tempUsersSelected = tempUsersSelected.filter(user => user !== userId);
    }

    const areAllUserSelected = tempUsersSelected.length === participants.length;

    if (areAllUserSelected) {
      setSelectedFilter("allUser");
    } else {
      setSelectedFilter("mixedUser");
    }
    setUsersSelected(tempUsersSelected);
    setAreAllUsersSelected(areAllUserSelected);
  };

  const openSendNotificationModal = () => {
    const isAUserSelected = getIsAUserSelected();
    if (isAUserSelected) {
      setIsSendNotificationModalVisible(true);
      setIsHistoryNotificationModalVisible(true);
    }
  };

  const onCloseSendNotificationModal = () => {
    setIsHistoryNotificationModalVisible(false);
    setIsSendNotificationModalVisible(false);

    dispatch(changeField({ notificationTitle: "" }));
    dispatch(changeField({ notificationText: "" }));
    dispatch(changeField({ notificationDate: "" }));
  };

  const openAddParticipantModal = () => {
    dispatch(
      changeAddUsersModal({
        isAddUsersModalVisible: true,
        hasPublishCampaignAnimation: false,
      }),
    );
  };

  const getIsAUserSelected = () => {
    return usersSelected.length > 0;
  };

  const allUsersAreChecked = () => {
    const tempUsersSelected = [];

    userCampaigns.forEach(el => {
      if (!areAllUsersSelected) {
        tempUsersSelected.push(el.userId);
      } else {
        setSelectedFilter("mixedUser");
      }
    });

    setUsersSelected(tempUsersSelected);
    setAreAllUsersSelected(!areAllUsersSelected);
  };

  const handleEditNotification = notification => {
    const arrayNotificationText = notification.text.split("\n");
    const notificationTitle = arrayNotificationText[0];
    const notificationText = arrayNotificationText.slice(1).join("\n");

    if (!isSendNotificationModalVisible) {
      setIsSendNotificationModalVisible(true);
    }

    dispatch(changeField({ notificationTitle }));
    dispatch(changeField({ notificationText }));
    dispatch(changeField({ notificationDate: notification.data.sendingDate }));
    setNotificationId(notification._id);
    setIsOnEditNotification(true);
    setUsersSelected(notification.receiver.map(user => user._id));
  };

  const handleSendNotification = (
    notificationTitle,
    notificationText,
    notificationDate,
  ) => {
    if (!isOnEditNotification) {
      dispatch(
        sendNotificationRequest({
          sendingDate: moment(notificationDate).toDate(),
          userIds: usersSelected,
          managerId: userId,
          title: notificationTitle,
          text: notificationText,
          campaignId: campaignId,
        }),
      );
    } else {
      dispatch(
        editManagerScheduledNotification({
          notificationId: notificationId,
          title: notificationTitle,
          text: notificationText,
          sendingDate: notificationDate,
        }),
      );
    }
    setNotificationId("");
    setIsOnEditNotification(false);
  };

  // allow fuzy search to consider firstName and lastName as one search string
  const userCampaignsWithFullName = userCampaigns.map(el => {
    el.fullName = `${el.firstName} ${el.lastName}`;
    return el;
  });

  const fuse = new Fuse(userCampaignsWithFullName, fuseOptions);
  const searchResults = search.length > 0 ? fuse.search(search) : userCampaigns;

  const isAUserSelected = getIsAUserSelected();
  const monthlyActiveUsers = getActiveParticipants();

  const classes = useStyle();

  return (
    <div className={classes.rightDiv}>
      <DeleteConfirmModal
        title={i18n.t("delete-userCampaign-confirm-title")}
        onClickConfirm={onConfirmDelete}
        isVisible={isDeleteConfirmModalVisible}
        onClose={() => setIsDeleteConfirmModalVisible(false)}
      />
      <NotificationModal
        onClose={onCloseSendNotificationModal}
        isVisible={
          isSendNotificationModalVisible || isHistoryNotificationModalVisible
        }
        isSendNotificationModalVisible={isSendNotificationModalVisible}
        isHistoryNotificationModalVisible={isHistoryNotificationModalVisible}
        usersSelected={usersSelected}
        handleSendNotification={handleSendNotification}
        handleEditNotification={handleEditNotification}
      />
      <div className={classes.firstSection} onScroll={handleScroll}>
        <div className={classes.topDiv}>
          <StatBox
            icon={<ParticipantIcon />}
            stat={participants.length}
            title={
              participants.length === 1
                ? i18n.t("Participant")
                : i18n.t("Participants")
            }
          />
          <StatBox
            icon={<LightIcon color={"#F9A849"} sizePercentage={2} />}
            title={"Actions"}
            stat={doneSteps.length}
            lightColor={"rgba(238, 134, 13, 0.22)"}
          />
          <StatBox
            isLast
            icon={<PulseIcon color={"#7BEC9C"} />}
            lightColor={"#DEF9EA"}
            stat={monthlyActiveUsers}
            title={i18n.t("active-participant")}
          />
        </div>
        <div className={classes.box}>
          <div className={classes.topBar}>
            <div className={classes.header}>
              <Typography className={classes.title}>
                {i18n.t("participants-list")}
                <span
                  className={classes.normal}
                >{` (${userCampaigns.length})`}</span>
              </Typography>
              <FlatButton
                icon={<AddParticipantIcon className={classes.icon} />}
                title={i18n.t("add-participants")}
                onClick={openAddParticipantModal}
              />
            </div>
            <SelectedActions
              isAUserSelected={isAUserSelected}
              usersSelected={usersSelected}
              openSendNotificationModal={openSendNotificationModal}
              cancelSelection={setFilter}
              setIsHistoryNotificationModalVisible={
                setIsHistoryNotificationModalVisible
              }
            />
          </div>
          {
            // ^^ Filter and research
          }
          <HeaderParticipantList
            handleFilterSelected={setFilter}
            researchField={setSearch}
            search={search}
            selectedFilter={selectedFilter}
            numberOfActiveUsers={numberOfActiveUsers}
            numberOfInactiveUsers={numberOfInactiveUsers}
            numberOfRealisation={numberOfRealisation}
            numberOfParticipation={numberOfParticipation}
          />
          <div className={classes.tableHeaderRow}>
            {isSuperAdmin && (
              <div
                className={classes.notificationVisibleCheckBox}
                onClick={handleNotificationVisibleCheck}
              >
                {isNotificationStatVisible ? (
                  <EyeClosedIcon />
                ) : (
                  <EyeOpenIcon />
                )}
              </div>
            )}
            <CheckBox
              isChecked={areAllUsersSelected}
              onCheck={allUsersAreChecked}
              className={classes.checkBox}
            />
            <div
              className={classes.firstRow}
              onClick={changeSortingParam("fullName")}
            >
              <Typography className={classes.tableHeader}>
                {i18n.t("table-header-participant")}
              </Typography>
              {displayArrow("fullName")}
            </div>
            <div className={classes.row} onClick={changeSortingParam("points")}>
              <Typography className={classes.tableHeader}>
                {i18n.t("table-header-done-action")}
              </Typography>
              {displayArrow("points")}
            </div>
            <div
              className={classes.row}
              onClick={changeSortingParam("lastDoneDate")}
            >
              <Typography className={classes.tableHeader}>
                {i18n.t("table-header-last-action")}
              </Typography>
              {displayArrow("lastDoneDate")}
            </div>
            <div
              className={classes.row}
              onClick={changeSortingParam("createdAt")}
            >
              <Typography className={classes.tableHeader}>
                {i18n.t("table-header-last-registered")}
              </Typography>
              {displayArrow("createdAt")}
            </div>
            <div
              className={classes.row}
              onClick={changeSortingParam("certification")}
            >
              <Typography className={classes.tableHeader}>
                {i18n.t("table-header-certification")}
              </Typography>
              {displayArrow("certification")}
            </div>
          </div>
          {userCampaigns.length < 1 && isLoading && <ListItemLoader />}
          {sortBy(searchResults).map((el, index) => (
            <LearnerRow
              timezone={timezone}
              index={index}
              key={el._id}
              userCampaign={el}
              openUserModal={() => openUserModal(el)}
              hasAccess={hasAccess}
              hasUsersAccessRestricted={hasUsersAccessRestricted}
              onClickDelete={e => onClickDelete(el)(e)}
              isSuperAdmin={isSuperAdmin}
              isChecked={usersSelected.includes(el.userId)}
              isNotificationStatVisible={isNotificationStatVisible}
              hasCheckBox
              handleCheck={e => onCheckUser(el.userId)(e)}
              onClickAddRandomStep={e =>
                onClickAddRandomStep({
                  userCampaignId: el._id,
                  userId: el.userId,
                })(e)
              }
            />
          ))}
          {isLoading && <Loader size={50} />}
        </div>
      </div>
    </div>
  );
};

export default LearnersSide;
