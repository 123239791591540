import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import SummaryRow from "scenes/Campaigns/SeeUserModal/Coaching/Sessions/SessionSummary/SummaryRow";
import i18n from "i18n";
import moment from "moment";

const convertTime = num => {
  const hours = Math.floor(num / 60) || 0;
  const minutes = num % 60 || 0;
  return `${hours},${minutes} h`;
};

const NextIcon = () => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.98389 3.43433L7.23616 4.16271L10.1539 7.09454H3.80078V8.14032H10.1539L7.23616 11.0544L7.98389 11.8005L12.167 7.61743L7.98389 3.43433Z"
      fill="#5FB8F9"
    />
    <path
      d="M7.98352 14.9379C6.53568 14.9379 5.12035 14.5085 3.91651 13.7042C2.71267 12.8998 1.77439 11.7565 1.22032 10.4188C0.666258 9.08121 0.521289 7.60931 0.803749 6.18929C1.08621 4.76926 1.78341 3.46489 2.80719 2.44111C3.83098 1.41732 5.13535 0.720121 6.55538 0.43766C7.9754 0.1552 9.4473 0.300169 10.7849 0.854235C12.1226 1.4083 13.2659 2.34658 14.0702 3.55042C14.8746 4.75426 15.304 6.16959 15.304 7.61743C15.3017 9.55825 14.5298 11.4189 13.1574 12.7913C11.785 14.1637 9.92434 14.9357 7.98352 14.9379ZM7.98352 1.34278C6.74251 1.34278 5.52937 1.71078 4.49751 2.40025C3.46565 3.08972 2.66141 4.06968 2.1865 5.21623C1.71158 6.36277 1.58732 7.62439 1.82943 8.84156C2.07154 10.0587 2.66914 11.1768 3.54667 12.0543C4.4242 12.9318 5.54223 13.5294 6.7594 13.7715C7.97656 14.0136 9.23819 13.8894 10.3847 13.4145C11.5313 12.9395 12.5112 12.1353 13.2007 11.1034C13.8902 10.0716 14.2582 8.85844 14.2582 7.61743C14.2562 5.95389 13.5945 4.35903 12.4182 3.18272C11.2419 2.00641 9.64707 1.34471 7.98352 1.34278Z"
      fill="#5FB8F9"
    />
  </svg>
);

const DoneIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.10711 10.1853L5.44499 7.52323L4.55762 8.4106L8.10711 11.9601L15.7132 4.35405L14.8258 3.46667L8.10711 10.1853Z"
      fill="#5ED85C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.68837 15.1475C4.95957 15.9969 6.45408 16.4502 7.98293 16.4502C10.0323 16.4479 11.9971 15.6327 13.4463 14.1836C14.8954 12.7344 15.7106 10.7696 15.7129 8.72024C15.7129 8.20803 15.662 7.69968 15.5624 7.20229L14.5846 8.16397C14.6004 8.34828 14.6084 8.53385 14.6086 8.72024C14.6086 10.0307 14.2201 11.3117 13.492 12.4013C12.764 13.4909 11.7292 14.3401 10.5185 14.8416C9.30779 15.3431 7.97558 15.4743 6.69032 15.2186C5.40506 14.963 4.22447 14.3319 3.29784 13.4053C2.37122 12.4787 1.74018 11.2981 1.48453 10.0128C1.22888 8.72759 1.36009 7.39538 1.86157 6.18469C2.36306 4.974 3.21229 3.9392 4.30188 3.21116C5.39148 2.48311 6.67249 2.09452 7.98293 2.09452C9.48862 2.09628 10.941 2.60988 12.1075 3.53881L12.902 2.75738C12.3136 2.27199 11.6534 1.87372 10.9411 1.57865C9.5286 0.993584 7.97436 0.840504 6.47488 1.13877C4.97541 1.43703 3.59806 2.17324 2.517 3.2543C1.43594 4.33536 0.699727 5.71272 0.401463 7.21219C0.103199 8.71166 0.256279 10.2659 0.841344 11.6784C1.42641 13.0909 2.41718 14.2981 3.68837 15.1475Z"
      fill="#5ED85C"
    />
  </svg>
);

const HourIcon = () => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5447 6.20205H12.4835C12.3966 6.20205 12.3254 6.27323 12.3254 6.36022V7.30919C12.3254 7.39618 12.3966 7.46735 12.4835 7.46735H17.5447C17.6317 7.46735 17.7029 7.39618 17.7029 7.30919V6.36022C17.7029 6.27323 17.6317 6.20205 17.5447 6.20205ZM14.8955 8.89081H12.4835C12.3966 8.89081 12.3254 8.96198 12.3254 9.04897V9.99794C12.3254 10.0849 12.3966 10.1561 12.4835 10.1561H14.8955C14.9825 10.1561 15.0537 10.0849 15.0537 9.99794V9.04897C15.0537 8.96198 14.9825 8.89081 14.8955 8.89081ZM8.29818 4.43262H7.44213C7.31955 4.43262 7.2207 4.53147 7.2207 4.65404V9.55707C7.2207 9.62824 7.25431 9.69348 7.31165 9.735L10.2554 11.882C10.3543 11.9532 10.4927 11.9335 10.5639 11.8346L11.0719 11.1407V11.1387C11.1431 11.0398 11.1214 10.9014 11.0225 10.8303L8.51763 9.01932V4.65404C8.51961 4.53147 8.41878 4.43262 8.29818 4.43262Z"
      fill="#FFBC10"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.69889 14.6065C4.96999 15.4558 6.46441 15.9092 7.99315 15.9092C10.0424 15.9068 12.0071 15.0917 13.4561 13.6427C14.1133 12.9855 14.64 12.2223 15.0198 11.3933H13.7868C13.6986 11.5525 13.6035 11.7083 13.5019 11.8605C12.7739 12.95 11.7391 13.7992 10.5285 14.3006C9.31792 14.8021 7.9858 14.9333 6.70063 14.6776C5.41545 14.422 4.23494 13.791 3.30838 12.8644C2.38182 11.9379 1.75083 10.7574 1.49519 9.4722C1.23955 8.18703 1.37075 6.85491 1.87221 5.6443C2.37366 4.43369 3.22283 3.39896 4.31235 2.67097C5.40187 1.94298 6.6828 1.55441 7.99315 1.55441C9.74966 1.55646 11.4336 2.25513 12.6757 3.49717C13.1154 3.93686 13.487 4.43195 13.7837 4.96603H15.0229C14.8519 4.59189 14.6505 4.23042 14.42 3.88541C13.5707 2.6143 12.3635 1.6236 10.9511 1.03857C9.53872 0.453544 7.98458 0.300475 6.48521 0.598719C4.98584 0.896962 3.60857 1.63312 2.52759 2.71411C1.4466 3.7951 0.710439 5.17236 0.412195 6.67173C0.113951 8.1711 0.267021 9.72524 0.852047 11.1376C1.43707 12.55 2.42778 13.7572 3.69889 14.6065Z"
      fill="#FFBC10"
    />
  </svg>
);

const useStyles = makeStyles(theme => ({
  main: {
    marginRight: "25px",
    minWidth: 270,
    marginBottom: -40,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
}));

interface Props {
  sessions: any[];
}

const SessionSummary = ({ sessions }: Props) => {
  const classes = useStyles();

  const nextSession = sessions.find(el => !el.isDone);
  const nextDate = nextSession?.nextDate;

  const totalMinutes =
    sessions.reduce((prev, curr) => prev + (curr.durationInMinutes || 0), 0) ||
    0;

  const totalMinutesDone = sessions.reduce((prev, curr) => {
    if (curr.isDone) {
      return prev + (curr.durationInMinutes || 0);
    }
    return prev;
  }, 0);

  return (
    <div className={classes.main}>
      <SummaryRow
        isBig
        title={i18n.t("next-meeting")}
        info={nextDate ? moment(nextDate).format("LL") : i18n.t("none")}
        icon={<NextIcon />}
      />
      <SummaryRow
        title={i18n.t("hours-done")}
        info={`${convertTime(totalMinutesDone)}`}
        icon={<DoneIcon />}
      />
      <SummaryRow
        title={i18n.t("hours-left")}
        info={`${convertTime(totalMinutes - totalMinutesDone)}`}
        icon={<HourIcon />}
      />
    </div>
  );
};

export default SessionSummary;
