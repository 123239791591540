import React, { useState } from "react";
import withStyles from '@mui/styles/withStyles';
import { connect } from "react-redux";
import EditBar from "scenes/CreateTrack/CreateActionTextStep/EditBar";
import i18n from "i18n";
import CreateRatingQuestion from "scenes/SurveyBuilder/CreateQuestionSide/CreateRatingQuestion";
import CreateMultipleChoices from "scenes/SurveyBuilder/CreateQuestionSide/CreateMultipleChoices";
import RatingQuestionPreview from "scenes/SurveyBuilder/CreateQuestionSide/RatingQuestionPreview";
import Preview from "scenes/SurveyBuilder/CreateQuestionSide/Preview";
import Language from "scenes/CreateTrack/CreateActionTextStep/Language";

import {
  changeField,
  createNewQuestion,
  removeQuestion,
} from "services/survey/actions";
import { getSelectedQuestion } from "services/survey/selectors";
import MultipleChoicesPreview from "scenes/SurveyBuilder/CreateQuestionSide/MultipleChoicesPreview";
import CreateFreeAnswer from "scenes/SurveyBuilder/CreateQuestionSide/FreeAnswer/CreateFreeAnswer";
import PreviewFreeAnswer from "scenes/SurveyBuilder/CreateQuestionSide/FreeAnswer/PreviewFreeAnswer";

const styles = theme => ({
  main: {},
  bottomDiv: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  leftSide: {
    paddingLeft: 30,
    paddingRight: 25,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    flex: 2,
  },
  language: {
    width: "fit-content",
    marginTop: 20,
    marginLeft: -8,
  },
});

const displayQuestionBuilder = questionType => {
  if (questionType === "RATING") return <CreateRatingQuestion />;
  if (questionType === "MULTIPLE_CHOICES") return <CreateMultipleChoices />;
  if (questionType === "FREE_ANSWER") return <CreateFreeAnswer />;
  return null;
};

const displayPreviewBuilder = questionType => {
  if (questionType === "RATING") return <RatingQuestionPreview />;
  if (questionType === "MULTIPLE_CHOICES") return <MultipleChoicesPreview />;
  if (questionType === "FREE_ANSWER") return <PreviewFreeAnswer />;
  return null;
};

const CreateQuestionSide = ({
  classes,
  stepId,
  stepRequesting,
  isDisabled,
  changeField,
  languageSelected,
  selectedQuestion = {},
  createNewQuestion,
  questionSelectedIndex,
}) => {
  const { questionType = "RATING" } = selectedQuestion;
  const [isPreviewVisible, setPreview] = useState(true);

  const handlePreview = () => {
    setPreview(!isPreviewVisible);
  };

  const onClickDuplicate = () => {
    const deepCopy = JSON.parse(JSON.stringify(selectedQuestion));

    const stepCopy = {
      ...deepCopy,
      totalDone: 0,
      totalViews: 0,
      rating: 0,
    };
    console.log(stepCopy);
    // delete stepCopy["origin"];
    delete stepCopy["stepIndex"];
    delete stepCopy["__v"];
    delete stepCopy["_id"];
    console.log(stepCopy);

    createNewQuestion(stepCopy);
  };

  const onClickSubmit = () => null;

  const onSelectLanguage = languageSelected => () => {
    changeField({ languageSelected });
  };

  return (
    <div className={classes.main}>
      <EditBar
        title={i18n.t("question-detail")}
        previewTitle={i18n.t("question-preview")}
        isLibrary={true}
        saving={stepRequesting}
        stepId={true}
        isEditable={false}
        requesting={stepRequesting}
        onClickDuplicate={onClickDuplicate}
        isPreviewVisible={isPreviewVisible}
        handlePreview={handlePreview}
        // onClickDelete={this.openDeleteModal}
        isSaveButtonDisabled={false}
        onClickSave={onClickSubmit}
        // saveButtonTitle={
        //   stepId
        //     ? i18n.t("modify-question-submit")
        //     : i18n.t("create-question-submit")
        // }
      />

      <div className={classes.bottomDiv}>
        <div className={classes.leftSide}>
          <Language
            language={languageSelected}
            onSelect={onSelectLanguage}
            className={classes.language}
          />
          {displayQuestionBuilder(questionType)}
        </div>
        <Preview isPreviewVisible={isPreviewVisible}>
          {displayPreviewBuilder(questionType)}
        </Preview>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    languageSelected: state.survey.languageSelected,
    selectedQuestion: getSelectedQuestion(state),
    questionSelectedIndex: state.survey.questionSelectedIndex,
  };
};

export default connect(mapStateToProps, {
  changeField,
  createNewQuestion,
  removeQuestion,
})(withStyles(styles)(CreateQuestionSide));
