import React, { useState } from "react";
import withStyles from "@mui/styles/withStyles";
import { Typography } from "@mui/material";
import i18n from "../../../i18n";
import Colors from "../../../constants/Colors";
import moment from "moment";
import CumulativeEvolutionChart from "./CumulativeEvolutionChart";
import { useSelector } from "react-redux";
import { getCampaignCreationDate } from "services/campaign/selectors";

moment.locale("fr");

const styles = (theme): any => ({
  main: {},
  bigBox: {
    marginTop: 30,
    overflow: "hidden",
    paddingBottom: 30,
    position: "relative",
    borderColor: "#DEDEDE",
    width: "100%",
    display: "flex",
    minHeight: 180,
    height: 500,
    backgroundColor: "#fff",
    borderRadius: 10,
    alignItems: "center",
    flexDirection: "column",
    animation: "FadeIn 0.3s ease-in-out",
    transition: "all 0.2s cubic-bezier(0.41, 0.094, 0.54, 0.07) 0s",
  },
  title: {
    fontFamily: "Montserrat",
    width: "100%",
    color: Colors.blackGrey,
    textAlign: "left",
    fontSize: 20,
    fontWeight: "bold",
    marginLeft: 45,
  },
  titleBar: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    height: 90,
    minHeight: 90,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    backgroundColor: "#fff",
    borderBottom: `solid 1px ${Colors.lightGrey}`,
  },

  firstTitle: {
    alignSelf: "center",
    marginTop: 15,
    marginBottom: 25,
  },
  selectChartPeriod: {
    paddingRight: 45,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  selectedPeriod: {
    color: Colors.pureMain,
    fontWeight: "500",
    backgroundColor: Colors.veryLightMain,
  },
  filter: {
    color: Colors.darkBlue,
    cursor: "pointer",
    zIndex: 10,
    marginLeft: 15,
    padding: "7px 15px",
    backgroundColor: Colors.lightGrey,
    borderRadius: 4,
    transition: "all 0.2s ease",
    "&:hover": {
      color: "#fff",
      opacity: 0.9,
      backgroundColor: Colors.pureMain,
    },
  },
  filterSelected: {
    color: "#fff",
    backgroundColor: Colors.pureMain,
  },
  filterText: {
    fontSize: 16,
    color: "inherit",
  },
});

interface chartData {
  x: string;
  y: number;
}

const getCumulativeData = (data: chartData[]) => {
  const result = data.reduce((prev, curr, index) => {
    if (index === 0) {
      prev.push({ ...curr });
      return prev;
    }

    const previousData = prev[index - 1];
    const newData = { ...curr, y: previousData.y + curr.y };
    prev.push(newData);
    return prev;
  }, []);

  return result;
};

const getEvolutionData = ({
  doneSteps,
  createdAt,
  evolutionPeriod,
  startingDate = undefined,
}) => {
  const dataStructure: chartData[] = [];
  if (evolutionPeriod === "year") {
    let i = 0;
    const numberOfMonths = 12;
    while (i < numberOfMonths) {
      dataStructure[i] = {
        x: moment(startingDate)
          .subtract(1, "month")
          .add(i, "month")
          .format("MMMM"),
        y: 0,
      };
      i++;
    }
    doneSteps.forEach(step => {
      const month = moment(step.createdAt).format("MMMM");
      const monthIndex = dataStructure.findIndex(el => el.x === month);
      if (monthIndex > -1) {
        dataStructure[monthIndex].y += 1;
      }
    });
    return dataStructure;
  }

  // starting from the current month displaying last 6 month
  else if (evolutionPeriod === "month") {
    let i = 0;
    const numberOfMonths = 7;
    while (i < numberOfMonths) {
      dataStructure[i] = {
        x: moment(startingDate)
          .subtract(1, "month")
          .add(i, "month")
          .format("MMMM"),
        y: 0,
      };
      i++;
    }
    doneSteps.forEach(step => {
      const month = moment(step.createdAt).format("MMMM");
      const monthIndex = dataStructure.findIndex(el => el.x === month);
      if (monthIndex > -1) {
        dataStructure[monthIndex].y += 1;
      }
    });
    return dataStructure;
  } else if (evolutionPeriod === "week") {
    let i = 0;
    // const startingWeek = parseInt(moment(createdAt).format("w"));
    while (i < 10) {
      const week = moment(startingDate)
        .subtract(1, "week")
        .add(i, "week")
        .weekday(0)
        .format("MMM DD");

      dataStructure[i] = {
        x: week,
        y: 0,
      };
      i++;
    }
    doneSteps.forEach(step => {
      const week = moment(step.createdAt).weekday(0).format("MMM DD");
      const weekIndex = dataStructure.findIndex(el => el.x === week);
      if (weekIndex > -1) {
        dataStructure[weekIndex].y += 1;
      }
    });

    return dataStructure;
  } else if (evolutionPeriod === "day") {
    let i = 0;
    const numberOfDays = 14;
    while (i < numberOfDays + 1) {
      dataStructure[i] = {
        x: moment()
          .subtract(numberOfDays, "day")
          .add(i, "day")
          .format("dddd DD"),
        y: 0,
      };
      i++;
    }
    doneSteps.forEach(step => {
      const day = moment(step.createdAt).format("dddd DD");
      const dayIndex = dataStructure.findIndex(el => el.x === day);
      if (dayIndex > -1) {
        dataStructure[dayIndex].y += 1;
      } else {
        dataStructure[0].y += 1;
      }
    });
    return dataStructure;
  }
};

/*const getReferenceValue = (period, numberOfParticipants) => {
  if (period === "day") {
    return 0.25;
  } else if (period === "week") {
    return (numberOfParticipants * 1) / 4 || 1;
  } else if (period === "month") {
    return numberOfParticipants || 1;
  }
  return 1;
};*/

const EvolutionSection = ({
  classes,
  onClickMonth,
  onClickWeek,
  onClickDay,
  doneSteps,
  createdAt,
  participants = [],
  data,
}) => {
  const [evolutionPeriod, setEvolutionPeriod] = useState("week");
  const selectFilter = filter => () => {
    setEvolutionPeriod(filter);
  };

  const startingDate = useSelector(getCampaignCreationDate);

  return (
    <div className={classes.bigBox}>
      <div className={classes.titleBar}>
        <Typography variant="h6" className={classes.title}>
          {i18n.t("action-evolution")}
        </Typography>
        <div className={classes.selectChartPeriod}>
          {["day", "week", "month"].map((el, index) => {
            const isSelected = evolutionPeriod === el;
            return (
              <div
                key={index}
                className={`${classes.filter} ${
                  isSelected && classes.filterSelected
                }`}
                onClick={selectFilter(el)}
              >
                <Typography className={classes.filterText}>
                  {i18n.t(el)}
                </Typography>
              </div>
            );
          })}
        </div>
      </div>

      <CumulativeEvolutionChart
        data={[
          {
            id: "Cumulative Actions",
            data: getCumulativeData(
              getEvolutionData({
                evolutionPeriod,
                doneSteps,
                createdAt,
                startingDate,
              }),
            ),
          },
        ]}
        color={Colors.main}
      />
    </div>
  );
};

export default withStyles(styles)(EvolutionSection);
