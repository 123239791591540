import { makeStyles } from "@mui/styles";
import React from "react";
import StarIcon from "scenes/CreateTrack/StarIcon";

const useStyle = makeStyles({
  container: {
    flex: 1,
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
  },
  scoreAlign: {
    marginRight: 3,
    marginLeft: 3,
  },
});

export interface IStartRateProps {
  rate: number;
  size: number;
}

const StarRate = ({ rate = 0, size = 0.5 }: IStartRateProps) => {
  const classes = useStyle();

  const displayStarRate = () => {
    const starIcons = [];

    for (let index = 0; index < 5; index++) {
      let starColor = index < rate ? "#FFBC10" : "#E4E3E3";
      starIcons.push(
        <div className={classes.scoreAlign}>
          <StarIcon key={index} color={starColor} sizePercentage={size} />
        </div>,
      );
    }
    return starIcons;
  };

  return <div className={classes.container}>{displayStarRate()}</div>;
};

export default StarRate;
