import React from "react";

const ClearIcon = ({
  sizePercentage,
  color,
  className,
  onClick = () => null,
}) => (
  <svg
    // width={sizePercentage * 24}
    onClick={onClick}
    className={className}
    width={sizePercentage * 14}
    height={sizePercentage * 14}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 20 20"
    version="1.1"
  >
    <path
      fill={color}
      d="M8.114 10L.944 2.83 0 1.885 1.886 0l.943.943L10 8.113l7.17-7.17.944-.943L20 1.886l-.943.943-7.17 7.17 7.17 7.17.943.944L18.114 20l-.943-.943-7.17-7.17-7.17 7.17-.944.943L0 18.114l.943-.943L8.113 10z"
    ></path>
  </svg>
);

ClearIcon.defaultProps = {
  sizePercentage: 1,
  color: "black",
};

export default ClearIcon;
