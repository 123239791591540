import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Colors from "../../../constants/Colors";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";
import { changeField, addStep } from "../../../services/createTrack/actions";
import moment from "moment";
import { Typography } from "@mui/material";
import i18n from "../../../i18n";
import AddIcon from "../AddIcon";
import CreateActionTextStep from "../CreateActionTextStep";
import Feedback from "../../Recap/Feedback";
import {
  getSelectedClientId,
  getUserId,
} from "../../../services/user/selectors";
import { getAppLanguage } from "../../../services/general/selectors";
import StarIcon from "../StarIcon";

moment.locale("fr");

const styles = theme => ({
  paper: {
    alignItems: "center",
    display: "flex",
    minWidth: "60%",
    justifyContent: "center",
    lineHeight: 1.46666667,
    overflowY: "auto",
    paddingTop: "25px",
    borderRight: ".25rem solid transparent",
    borderRadius: 12,
  },
  closeButton: {
    fontSize: 16,
    borderRadius: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    zIndex: 10,
    position: "absolute",
    marginRight: 16,
    top: 15,
    right: 0,
    "&:hover": {
      color: Colors.black,
    },
  },
  labelIconButton: {
    flexDirection: "column",
  },
  bodyDiv: {
    maxWidth: "100%",
  },
  title: {
    fontFamily: "Montserrat",
    alignSelf: "center",
    marginBottom: 10,
    fontSize: 28,
    lineHeight: 1.2143,
    fontWeight: 700,
  },
  addButton: {
    position: "absolute",
    marginLeft: 16,
    top: 20,
    right: 0,
    cursor: "pointer",
    zIndex: 10,
    padding: "7px 15px",
    marginRight: 85,
    backgroundColor: Colors.pureMain,
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  buttonText: {
    fontSize: 16,
    marginLeft: 8,
    color: "white",
  },
  comments: {
    width: 960,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  ratingDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  star: {
    marginRight: 5,
  },
  rating: {
    marginTop: 5,
    marginLeft: 5,
    fontSize: "1rem",
  },
});

class PreviewModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      error: "",
    };
    this.baseState = this.state;
  }

  _handleKeyDown = event => {
    if (
      (window.navigator.platform.match("Mac")
        ? event.metaKey
        : event.ctrlKey) &&
      event.keyCode === 83
    ) {
      event.preventDefault();
    }
  };

  onClickAdd = () => {
    this.props.addStep({
      ...this.props.stepPreview,
      step: this.props.stepPreview._id,
    });
    this.props.onClose();
  };

  onRendered = () => {
    // reset state and props
    // this.setState({ stepPreview: this.props.stepPreview });
  };

  handleClose = onClose => () => {
    // document.removeEventListener("keydown", this._handleKeyDown, false);
    onClose();
  };

  displayStars = rating => {
    const { classes } = this.props;
    const result = [];
    let i = 0;
    while (i < 5) {
      if (i < rating) {
        result.push(<StarIcon className={classes.star} />);
      } else {
        result.push(<StarIcon color={Colors.grey} className={classes.star} />);
      }
      i++;
    }
    return result;
  };

  render() {
    const { classes, isVisible, onClose } = this.props;
    return (
      <Dialog
        open={isVisible}
        ref={this.onRendered}
        onClose={this.handleClose(onClose)}
        classes={{ paper: classes.paper }}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        <IconButton
          className={classes.closeButton}
          classes={{ label: classes.labelIconButton }}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.title}>
          {i18n.t("step-preview")}
        </Typography>
        {this.props.isAddButton && (
          <div className={classes.addButton} onClick={this.onClickAdd}>
            <AddIcon sizePercentage={0.6} />
            <Typography className={classes.buttonText}>
              {i18n.t("add-challenge")}
            </Typography>
          </div>
        )}
        <DialogContent className={classes.bodyDiv}>
          {this.props.rating !== null && (
            <div className={classes.ratingDiv}>
              {this.displayStars(this.props.rating)}
              <Typography className={classes.rating}>
                {this.props.rating} / 5
              </Typography>
            </div>
          )}
          <CreateActionTextStep
            stepPreview={this.props.stepPreview}
            stepPreviewIndex={this.props.stepPreviewIndex}
          />
          <div className={classes.comments}>
            {this.props.comments.length > 0 && (
              <Typography className={classes.title}>
                {i18n.t("Comments")}
              </Typography>
            )}
            {this.props.comments.map(el => (
              <Feedback title={el} />
            ))}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

PreviewModal.defaultProps = {
  isAddButton: true,
  rating: null,
  comments: [],
};

const mapStateToProps = state => {
  const language = getAppLanguage(state);
  const clientId = getSelectedClientId(state);
  const userId = getUserId(state);
  const {
    languageSelected,
    title,
    description,
    picture,
    requesting,
  } = state.createTrack;
  const { uploading } = state.upload;

  return {
    trackId: state.createTrack._id,
    userId,
    clientId,
    language,
    languageSelected,
    title,
    description,
    uploading,
    picture,
    uploadedPicture: state.upload.picture,
    requesting,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    changeField,
    addStep,
  })(PreviewModal),
);
