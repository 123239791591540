import React from "react";

const ViewsAscIcon = ({ sizePercentage, color, className }) => (
  <svg
    // width={sizePercentage * 24}
    className={className}
    width={sizePercentage * 13}
    height={sizePercentage * 11}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 13 11"
    version="1.1"
  >
    <path
      d="M7.9309 6.375C7.09712 6.375 6.41968 7.05124 6.41968 7.88353C6.41968 8.71582 7.09712 9.39206 7.9309 9.39206C8.76468 9.39206 9.44212 8.71582 9.44212 7.88353C9.44212 7.05124 8.76468 6.375 7.9309 6.375ZM7.81626 7.43617C7.62866 7.43617 7.47232 7.59223 7.47232 7.77949H6.97206C6.98248 7.31133 7.35768 6.9368 7.81626 6.9368V7.43617Z"
      fill={color}
    />
    <path
      d="M12.5171 7.57165C12.0064 6.93703 10.1825 4.8667 7.9313 4.8667C5.6801 4.8667 3.85621 6.93703 3.34552 7.57165C3.19961 7.74851 3.19961 8.00861 3.34552 8.19587C3.85621 8.83049 5.6801 10.9008 7.9313 10.9008C10.1825 10.9008 12.0064 8.83049 12.5171 8.19587C12.663 8.01901 12.663 7.75892 12.5171 7.57165ZM7.9313 10.0685C6.72232 10.0685 5.74263 9.09059 5.74263 7.88376C5.74263 6.67694 6.72232 5.69899 7.9313 5.69899C9.14027 5.69899 10.12 6.67694 10.12 7.88376C10.12 9.09059 9.14027 10.0685 7.9313 10.0685Z"
      fill={color}
    />
    <path
      d="M3.23565 2.604C2.81873 2.604 2.47998 2.94215 2.47998 3.35832C2.47998 3.7745 2.81873 4.11265 3.23565 4.11265C3.65257 4.11265 3.99131 3.7745 3.99131 3.35832C3.99131 2.94215 3.65257 2.604 3.23565 2.604ZM3.17832 3.13463C3.08451 3.13463 3.00634 3.21266 3.00634 3.3063H2.75619C2.7614 3.0722 2.94902 2.88492 3.17832 2.88492V3.13463Z"
      fill={color}
    />
    <path
      d="M5.52902 3.20218C5.27366 2.88485 4.36164 1.84961 3.23596 1.84961C2.11028 1.84961 1.19827 2.88485 0.942904 3.20218C0.869943 3.29062 0.869943 3.42068 0.942904 3.51432C1.19827 3.83165 2.11028 4.86689 3.23596 4.86689C4.36164 4.86689 5.27366 3.83165 5.52902 3.51432C5.60198 3.42588 5.60198 3.29582 5.52902 3.20218ZM3.23596 4.45072C2.63143 4.45072 2.14155 3.96171 2.14155 3.35825C2.14155 2.75479 2.63143 2.26579 3.23596 2.26579C3.8405 2.26579 4.33038 2.75479 4.33038 3.35825C4.33038 3.96171 3.8405 4.45072 3.23596 4.45072Z"
      fill={color}
    />
    <path
      d="M7.34366 0.906738C7.03094 0.906738 6.77686 1.16037 6.77686 1.47253C6.77686 1.7847 7.03094 2.03833 7.34366 2.03833C7.65638 2.03833 7.91047 1.7847 7.91047 1.47253C7.91047 1.16037 7.65638 0.906738 7.34366 0.906738ZM7.30066 1.30475C7.2303 1.30475 7.17166 1.36328 7.17166 1.43351H6.98403C6.98794 1.25792 7.12867 1.11745 7.30066 1.11745V1.30475Z"
      fill={color}
    />
    <path
      d="M9.06397 1.35535C8.87243 1.11733 8.18836 0.34082 7.34401 0.34082C6.49967 0.34082 5.81559 1.11733 5.62405 1.35535C5.56933 1.42169 5.56933 1.51924 5.62405 1.58947C5.81559 1.8275 6.49967 2.604 7.34401 2.604C8.18836 2.604 8.87243 1.8275 9.06397 1.58947C9.1187 1.52314 9.1187 1.42559 9.06397 1.35535ZM7.34401 2.29184C6.89057 2.29184 6.52312 1.92505 6.52312 1.47241C6.52312 1.01978 6.89057 0.652984 7.34401 0.652984C7.79746 0.652984 8.1649 1.01978 8.1649 1.47241C8.1649 1.92505 7.79746 2.29184 7.34401 2.29184Z"
      fill={color}
    />
  </svg>
);

ViewsAscIcon.defaultProps = {
  sizePercentage: 1,
  color: "#4BADF7",
};

export default ViewsAscIcon;
