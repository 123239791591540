export const CLIENT_CHANGE_FIELD = "CLIENT_CHANGE_FIELD";

export const MY_CLIENTS_GET_ALL_REQUESTING = "MY_CLIENTS_GET_ALL_REQUESTING";
export const MY_CLIENTS_GET_ALL_SUCCESS = "MY_CLIENTS_GET_ALL_SUCCESS";
export const MY_CLIENTS_GET_ALL_ERROR = "MY_CLIENTS_GET_ALL_ERROR";

export const MY_CLIENTS_SWAP = "MY_CLIENTS_SWAP";
export const MY_CLIENTS_SELECT = "MY_CLIENTS_SELECT";

export const MY_CLIENTS_DELETE_REQUESTING = "MY_CLIENTS_DELETE_REQUESTING";
export const MY_CLIENTS_DELETE_SUCCESS = "MY_CLIENTS_DELETE_SUCCESS";
export const MY_CLIENTS_DELETE_ERROR = "MY_CLIENTS_DELETE_ERROR";

export const MY_CLIENTS_CREATE_NEW_REQUESTING =
  "MY_CLIENTS_CREATE_NEW_REQUESTING";
export const MY_CLIENTS_CREATE_NEW_SUCCESS = "MY_CLIENTS_CREATE_NEW_SUCCESS";
export const MY_CLIENTS_CREATE_NEW_ERROR = "MY_CLIENTS_CREATE_NEW_ERROR";

export const MY_CLIENTS_MODIFY_REQUESTING = "MY_CLIENTS_MODIFY_REQUESTING";
export const MY_CLIENTS_MODIFY_SUCCESS = "MY_CLIENTS_MODIFY_SUCCESS";
export const MY_CLIENTS_MODIFY_ERROR = "MY_CLIENTS_MODIFY_ERROR";

export const MY_CLIENTS_GET_SELECTED_REQUESTING =
  "MY_CLIENTS_GET_SELECTED_REQUESTING";
export const MY_CLIENTS_GET_SELECTED_SUCCESS =
  "MY_CLIENTS_GET_SELECTED_SUCCESS";
export const MY_CLIENTS_GET_SELECTED_ERROR = "MY_CLIENTS_GET_SELECTED_ERROR";

export const MY_CLIENTS_GET_ACTIVE_USERS_REQUESTING =
  "MY_CLIENTS_GET_ACTIVE_USERS_REQUESTING";
export const MY_CLIENTS_GET_ACTIVE_USERS_SUCCESS =
  "MY_CLIENTS_GET_ACTIVE_USERS_SUCCESS";
export const MY_CLIENTS_GET_ACTIVE_USERS_ERROR =
  "MY_CLIENTS_GET_ACTIVE_USERS_ERROR";
