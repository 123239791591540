import React from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import { selectClient } from "../../services/myClients/actions";
import { Typography } from "@mui/material";
import { push } from "connected-react-router";
import Colors from "../../constants/Colors";
import { getIsObserver, getIsTrainer } from "services/user/selectors";

const styles = () => ({
  main: {
    cursor: "pointer",
    padding: "12px 20px",
    display: "flex",
    flexDirection: "row",
    color: "white",
    transition: "color .4s ease-out",
  },
  selected: {
    backgroundColor: Colors.pureMain,
    fontWeight: 700,
  },
  text: {
    fontWeight: 500,
    color: "white",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  logo: {
    borderWidth: 1,
    border: "solid",
    borderColor: "white",
    height: 20,
    borderRadius: 2,
    marginRight: 15,
  },
});

const MyClientsMenu = ({
  classes,
  selectedClient,
  push,
  isTrainer = false,
  isObserver = false,
}) => {
  if (!selectedClient) {
    return null;
  }
  return (
    <div
      onClick={isTrainer || isObserver ? undefined : () => push("/my-clients")}
      className={`${classes.main} ${classes.selected}`}
    >
      {selectedClient.logo ? (
        <img
          src={selectedClient.logo}
          className={classes.logo}
          alt={selectedClient.name}
        />
      ) : (
        <div className={classes.logo}></div>
      )}
      <Typography className={classes.text}>{selectedClient.name}</Typography>
    </div>
  );
};

const mapStateToProps = state => {
  const { allClients = [], selectedClientId } = state.myClients;
  const mainClient = state.client;
  const selectedClient =
    allClients?.find(el => el._id === selectedClientId) || mainClient;
  const isTrainer = getIsTrainer(state);
  const isObserver = getIsObserver(state);
  return {
    selectedClient,
    isTrainer,
    isObserver,
  };
};

export default connect(mapStateToProps, { selectClient, push })(
  withStyles(styles)(MyClientsMenu),
);
