import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Colors from "constants/Colors";

const useStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    cursor: "pointer",
    transition: "all 0.2s ease",
  },
  step: {
    width: 15,
    borderRadius: 3,
    height: 3,
    backgroundColor: "#E5E5E5",
    marginRight: 8,
  },
  stepSelected: {
    backgroundColor: Colors.pureMain,
  },
}));

interface Props {
  numberOfSteps: number;
  stepSelected: number;
  handleStepClick: (stepIndex: number) => (e: any) => void;
}

const Steps = ({ numberOfSteps, stepSelected, handleStepClick }: Props) => {
  const classes = useStyles();

  const steps = Array.from(Array(numberOfSteps).keys());

  return (
    <div className={classes.main}>
      {steps.map((el, index) => (
        <div
          onClick={handleStepClick(index)}
          className={`${classes.step} ${
            index === stepSelected && classes.stepSelected
          }`}
        />
      ))}
    </div>
  );
};

export default Steps;
