import {
  UPLOAD_PICTURE_REQUESTING,
  UPLOAD_RESET,
  SEARCH_UNSPLASH_REQUESTING,
  GET_RANDOM_UNSPLASH_REQUESTING,
  UPLOAD_FILE_REQUESTING,
  UPLOAD_PROGRESS_CHANGE,
  DOWNLOAD_FILE_REQUESTING,
  UPDATE_FILES_TO_UPLOAD,
} from "./constants";
import { IFileToUploadType } from "./types";

export const uploadPictureRequest = (payload: {
  urlName: string;
  file: any;
}) => {
  return {
    type: UPLOAD_PICTURE_REQUESTING,
    payload,
  };
};

export const resetUpload = () => {
  return {
    type: UPLOAD_RESET,
  };
};

export const searchUnsplashRequest = payload => {
  return {
    type: SEARCH_UNSPLASH_REQUESTING,
    payload,
  };
};

export const getRandomUnsplashRequest = payload => {
  return {
    type: GET_RANDOM_UNSPLASH_REQUESTING,
    payload,
  };
};

export const uploadFileRequest = (payload: {
  fileType?: string;
  urlName: string;
  file: any;
}) => {
  return {
    type: UPLOAD_FILE_REQUESTING,
    payload,
  };
};

export const downloadFileRequest = (payload: {
  fileType: string;
  fileName: string;
  urlName: string;
  isExportingVideo?: boolean;
}) => {
  return {
    type: DOWNLOAD_FILE_REQUESTING,
    payload,
  };
};

export const changeUploadProgress = (payload: { progress: number }) => {
  return {
    type: UPLOAD_PROGRESS_CHANGE,
    payload,
  };
};

export const updateFilesToUpload = (payload: IFileToUploadType) => {
  return {
    type: UPDATE_FILES_TO_UPLOAD,
    payload,
  };
};