import { all } from "redux-saga/effects";
import AuthSaga from "./auth/saga";
import ClientSaga from "./client/saga";
import ManagerSaga from "./manager/saga";
import RealTimeSaga from "./realTime/sagas";
import PollSaga from "./poll/saga";
import TokenSaga from "./token/sagas";
import CreateTrack from "./createTrack/sagas";
import Campaign from "./campaign/sagas";
import Upload from "./upload/sagas";
import User from "./user/sagas";
import Signup from "./signup/sagas";
import MyClients from "./myClients/sagas";
import MyUsers from "./myUsers/sagas";
import Message from "./message/sagas";
import Notification from "./notification/sagas";
import Survey from "./survey/sagas";
import Coaching from "./coaching/sagas";
import Resource from "./resource/sagas";
import MyCoachees from "./myCoachees/sagas";
import General from "./general/sagas";
import Invoice from "./invoice/sagas";

export default function* rootSagas() {
  yield all([
    AuthSaga(),
    ClientSaga(),
    ManagerSaga(),
    RealTimeSaga(),
    PollSaga(),
    TokenSaga(),
    CreateTrack(),
    Campaign(),
    Upload(),
    User(),
    Signup(),
    MyClients(),
    MyUsers(),
    Message(),
    Notification(),
    Survey(),
    Coaching(),
    Resource(),
    MyCoachees(),
    General(),
    Invoice(),
  ]);
}
