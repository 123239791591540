const Colors = {
  // 5100ff
  // main: '#4250fb',
  // main: "rgba(60,80,251,0.8)",
  hoverBlue: "#ECF6FF",
  editable: "#f4f6f9",
  darkBlue: "#00042A",
  lightDarkBlue: "#00042abf",
  text: "#19212B",
  blackGrey: "#282828",
  infoSquare: "#FAFAFA",
  // text: "black",
  // darkBlue: "#18171B",
  // blueIcon: "#021d35",
  blueIcon: "#18171B",
  white: "white",
  black: "black",
  shadow: "rgba(228, 228, 228, 0.25)",
  lightWhite: "rgba(255,255,255, 0.7)",
  main: "rgba(0, 170, 255, 0.9)",
  // pureMain: "rgba(0, 170, 255, 1)",
  pureMain: "#5FB8F9",
  // pureMain: "rgba(60,80,251,1)",
  lightMain: "rgba(0, 170, 255, 0.3)",
  veryLightMain: "rgba(0, 170, 255, 0.1)",
  // lightMain: "rgba(60,80,251,0.3)",
  grey: "rgba(0, 0, 0, 0.54)",
  hardGrey: "rgba(155, 155, 155, 0.9)",
  goodGreen: "#92D148",
  done: "#75D67E",
  green: "#57BA55",
  hardRed: "red",
  red: "rgba(255,0,47, 0.8)",
  mediumGreen: "#7DFFB3",
  pink: "#FC577A",
  hoverPink: "rgba(252,87,122,0.2)",
  lightGrey: "rgba(216,216,216,0.3)",
  veryLightGrey: "rgba(216,216,216,0.1)",
  mediumGrey: "#E6E6E6",
  orange: "#FFC107",
  hoverOrange: "rgba(255, 196, 31, 0.40)",
  gold: "#FCD462",
  yellow: "#FFD54F",
  brandYellow: "#ebe55a",
  backGrey: "#FAFAFA",
  lightBlue: "#32C1FF",
  veryLightBlue: "#EDF6FE",
  pollBlue: "#F3F5FA",
  pinkMetric: "rgba(252,87,122,0.8)",
  yellowMetric: "#FFC233",
  orangeMetric: "#FF9F40",
  blueMetric: "rgba(0,170,255,0.8)",
  whiteBlue: "#D4F1FF",
  textSymbolBackground: "rgba(0, 170, 255, 0.17)",
  lightPink: "rgba(255, 101, 132, 0.40)",
  lightOrange: "rgba(255, 196, 31, 0.40)",
  normalGreen: "green",
  blueGrey: "#edf2fa",
  timeTextColor: "#aaa",
  dateBlue: "#1486f3",
  deepBlue: "#0069ff",
  strongGreen: "#62d26f",
  indigo: "#7a6ff0",
  purple: "#aa62e3",
  coolGrey: "#8da3a6",
  feeling1: "#80C7BC",
  feeling2: "#59B4BF",
  feeling3: "#489CC0",
  feeling4: "#5A83B8",
};

export default Colors;
