import React from "react";

import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";

import Colors from "../../constants/Colors";

import StepSymbol from "./StepSymbol";

// const HEIGHT_BAR = 60;

const styles = theme => ({
  main: {
    // width: "100%",
    cursor: "pointer",
    borderColor: "#ECEBF1",
    width: 256,
    padding: 16,
    paddingTop: 4,
    paddingBottom: 4,
    // flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    transition: "0.25s",
    "&:hover": {
      backgroundColor: "#F0F0F0",
    },
  },
  selected: {
    backgroundColor: "#F0F0F0",
  },
  title: {
    color: Colors.black,
    // fontWeight: "bold",
    fontSize: "0.9em",
    marginLeft: 15,
  },
  menuTextSelected: {
    fontWeight: "bold",
    color: Colors.white,
  },
  bottomBorder: {
    position: "absolute",
    bottom: 0,
    backgroundColor: Colors.white,
    borderRadius: 3,
    height: 5,
    width: "100%",
  },
});

const StepBoxRow = ({
  classes,
  isSelected,
  title,
  onClick,
  onMouseEnter,
  stepType,
}) => {
  return (
    <div
      onMouseEnter={onMouseEnter}
      className={`${classes.main} ${isSelected && classes.selected}`}
      onClick={onClick}
    >
      <StepSymbol stepType={stepType} />
      <Typography className={`${classes.title}`}>{title}</Typography>
    </div>
  );
};

export default withStyles(styles)(StepBoxRow);
