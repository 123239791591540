import {
  NOTIFICATION_NEW_MESSAGES,
  NOTIFICATION_MESSAGE_INCREMENT,
  NOTIFICATION_MESSAGE_SEEN,
  NOTIFICATION_HOME_SEEN,
  NOTIFICATION_PULSE_SET,
  NOTIFICATION_PULSE_ADD,
  NOTIFICATION_PULSE_CLEAR,
  NOTIFICATION_NEW_HOME,
  NOTIFICATION_HOME_INCREMENT,
  NOTIFICATION_RANDOM_COFFEE_SEEN,
  NOTIFICATION_RANDOM_COFFEE_INCREMENT,
  NOTIFICATION_NEW_RANDOM_COFFEE,
  NOTIFICATION_GENERAL_GET_REQUESTING,
  NOTIFICATION_GENERAL_GET_ERROR,
  NOTIFICATION_GENERAL_GET_SUCCESS,
  NOTIFICATION_GENERAL_LOAD_REQUESTING,
  NOTIFICATION_GENERAL_LOAD_SUCCESS,
  NOTIFICATION_GENERAL_LOAD_ERROR,
  NOTIFICATION_GENERAL_SET,
  NOTIFICATION_GENERAL_ADD,
  NOTIFICATION_GENERAL_CLEAR,
  NOTIFICATION_STOP_LOAD,
  NOTIFICATION_CLICK,
  NOTIFICATION_TEAM_CLEAR,
  NOTIFICATION_TEAM_ADD,
  NOTIFICATION_NEW_ACHIEVEMENT,
  NOTIFICATION_ACHIEVEMENT_SEEN,
  NOTIFICATION_HISTORY_SUCCESS,
  ADD_NOTIFICATION_TO_FAVORITE_REQUESTING,
  GET_FAVORITE_NOTIFICATIONS_REQUESTING,
  GET_FAVORITE_NOTIFICATIONS_SUCCESS,
  GET_FAVORITE_NOTIFICATIONS_ERROR,
} from "./constants";
import { AUTH_LOGOUT_SUCCESS } from "../auth/constants";

const INITIAL_STATE = {
  requesting: false,
  loading: false,
  hasResult: true,
  page: 0,
  newMessageNotifications: 0,
  newHomeNotifications: 0,
  newGeneralNotifications: 0,
  teamNotifications: [],
  pulseNotifications: [], // [{ user: '' }]
  notifications: [],
  notificationsHistory: [],
  favoriteNotifications: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NOTIFICATION_NEW_MESSAGES:
      return {
        ...state,
        newMessageNotifications: action.payload,
      };

    case NOTIFICATION_GENERAL_SET:
      return {
        ...state,
        newGeneralNotifications: action.payload,
      };

    case NOTIFICATION_GENERAL_ADD:
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
        newGeneralNotifications: state.newGeneralNotifications + 1,
      };

    case NOTIFICATION_GENERAL_CLEAR:
      return {
        ...state,
        newGeneralNotifications: 0,
      };

    case NOTIFICATION_HISTORY_SUCCESS:
      return {
        ...state,
        notificationsHistory: action.payload,
      };

    case NOTIFICATION_NEW_RANDOM_COFFEE:
      return {
        ...state,
        newRandomCoffeeNotifications: action.payload,
      };

    case NOTIFICATION_MESSAGE_INCREMENT:
      return {
        ...state,
        newMessageNotifications: state.newMessageNotifications + 1,
      };

    case NOTIFICATION_RANDOM_COFFEE_INCREMENT:
      return {
        ...state,
        newRandomCoffeeNotifications: state.newRandomCoffeeNotifications + 1,
      };

    case NOTIFICATION_MESSAGE_SEEN:
      return {
        ...state,
        newMessageNotifications: 0,
      };

    case NOTIFICATION_HOME_SEEN:
      return {
        ...state,
        newHomeNotifications: 0,
      };

    case NOTIFICATION_TEAM_CLEAR: {
      // const teamNotifications = state.teamNotifications.filter(
      //   el => el.campaignId !== campaignId
      // );
      return {
        ...state,
        teamNotifications: [],
      };
    }

    case NOTIFICATION_PULSE_CLEAR: {
      // const pulseNotifications = state.pulseNotifications.filter(
      //   el => el.user !== userId
      // );
      return {
        ...state,
        pulseNotifications: [],
      };
    }

    case NOTIFICATION_RANDOM_COFFEE_SEEN:
      return {
        ...state,
        newRandomCoffeeNotifications: 0,
      };

    case NOTIFICATION_TEAM_ADD:
      return {
        ...state,
        teamNotifications: [...state.teamNotifications, ...action.payload],
        newHomeNotifications:
          state.newHomeNotifications + action.payload.length,
      };

    case NOTIFICATION_PULSE_ADD:
      return {
        ...state,
        pulseNotifications: [...state.pulseNotifications, action.payload],
        newHomeNotifications: state.newHomeNotifications + 1,
      };

    case NOTIFICATION_NEW_HOME:
      return {
        ...state,
        newHomeNotifications: action.payload,
      };

    case NOTIFICATION_HOME_INCREMENT:
      return {
        ...state,
        newHomeNotifications: state.newHomeNotifications + action.payload,
      };

    case NOTIFICATION_PULSE_SET:
      return {
        ...state,
        pulseNotifications: action.payload,
      };

    case GET_FAVORITE_NOTIFICATIONS_REQUESTING:
    case NOTIFICATION_GENERAL_GET_REQUESTING:
      return {
        ...state,
        requesting: true,
      };

    case GET_FAVORITE_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        requesting: false,
        favoriteNotifications: action.payload,
      };
    }

    case GET_FAVORITE_NOTIFICATIONS_ERROR:
    case NOTIFICATION_GENERAL_GET_ERROR:
      return {
        ...state,
        requesting: false,
        error: action.payload,
      };

    case NOTIFICATION_GENERAL_GET_SUCCESS:
      return {
        ...state,
        hasResult: true,
        requesting: false,
        page: 1,
        notifications: action.payload,
      };

    case NOTIFICATION_GENERAL_LOAD_REQUESTING:
      return {
        ...state,
        loading: true,
      };

    case NOTIFICATION_GENERAL_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        page: state.page + 1,
        notifications: [...state.notifications, ...action.payload],
      };

    case NOTIFICATION_GENERAL_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case NOTIFICATION_STOP_LOAD: {
      return {
        ...state,
        hasResult: false,
      };
    }

    case ADD_NOTIFICATION_TO_FAVORITE_REQUESTING: {
      const { isFavorite, notificationId, clientId } = action.payload;

      let favoriteNotifications = state.favoriteNotifications;

      const notificationsHistory = state.notificationsHistory.map(el => {
        if (el._id === notificationId) {
          el.clientFavoriteList = {
            ...(el.clientFavoriteList ? el.clientFavoriteList : {}),
          };
          el.clientFavoriteList[clientId] = isFavorite;
        }
        return el;
      });

      if (!isFavorite) {
        favoriteNotifications = state.favoriteNotifications.filter(
          el => el._id !== notificationId,
        );
      }

      return {
        ...state,
        notificationsHistory,
        favoriteNotifications,
      };
    }

    case NOTIFICATION_CLICK: {
      const notifications = state.notifications.map(el => {
        if (el._id === action.payload.notificationId) {
          el.readBy.push({ readerId: action.payload.userId });
        }
        return el;
      });
      return {
        ...state,
        notifications,
      };
    }

    case NOTIFICATION_ACHIEVEMENT_SEEN:
      return {
        ...state,
        achievement: {},
      };

    case NOTIFICATION_NEW_ACHIEVEMENT:
      return {
        ...state,
        achievement: action.payload,
      };

    case AUTH_LOGOUT_SUCCESS:
      return INITIAL_STATE;

    default:
      return state;
  }
};
