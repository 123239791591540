import React from "react";

const ViewsDescIcon = ({ sizePercentage, color, className }) => (
  <svg
    // width={sizePercentage * 24}
    className={className}
    width={sizePercentage * 10}
    height={sizePercentage * 7}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 10 7"
    version="1.1"
  >
    <path
      d="M4.75707 2.19922C3.92329 2.19922 3.24585 2.87546 3.24585 3.70775C3.24585 4.54004 3.92329 5.21628 4.75707 5.21628C5.59085 5.21628 6.26829 4.54004 6.26829 3.70775C6.26829 2.87546 5.59085 2.19922 4.75707 2.19922ZM4.64243 3.26039C4.45483 3.26039 4.29849 3.41645 4.29849 3.60371H3.79823C3.80865 3.13555 4.18385 2.76102 4.64243 2.76102V3.26039Z"
      fill={color}
    />
    <path
      d="M9.34325 3.39587C8.83256 2.76125 7.00867 0.690918 4.75747 0.690918C2.50627 0.690918 0.682378 2.76125 0.171689 3.39587C0.0257781 3.57273 0.0257781 3.83282 0.171689 4.02009C0.682378 4.65471 2.50627 6.72504 4.75747 6.72504C7.00867 6.72504 8.83256 4.65471 9.34325 4.02009C9.48916 3.84323 9.48916 3.58314 9.34325 3.39587ZM4.75747 5.89275C3.54849 5.89275 2.5688 4.9148 2.5688 3.70798C2.5688 2.50116 3.54849 1.52321 4.75747 1.52321C5.96645 1.52321 6.94613 2.50116 6.94613 3.70798C6.94613 4.9148 5.96645 5.89275 4.75747 5.89275Z"
      fill={color}
    />
  </svg>
);

ViewsDescIcon.defaultProps = {
  sizePercentage: 1,
  color: "#4BADF7",
};

export default ViewsDescIcon;
