import React from "react";
import withStyles from '@mui/styles/withStyles';

import { useWindowSize } from "@react-hook/window-size";

import Colors from "constants/Colors";
import IphoneMockup from "scenes/CreateTrack/CreateActionTextStep/PreviewSide/IphoneMockup";
import PhonePreviewBoxes from "../PhonePreviewBoxes";

const styles = theme => ({
  main: {
    marginTop: 30,
  },
  svgWrapperDiv: {
    position: "relative",
  },
  connectionAppDiv: {
    boxSizing: "border-box",
    width: "100%",
    height: "85%",
    zIndex: 1,
    top: 0,
    display: "flex",
    position: "absolute",
    alignItems: "center",
    flexDirection: "column",
    overflow: "scroll",
    overflowX: "hidden",
    marginTop: 45,
  },
  connectionAppText: {
    boxSizing: "border-box",
    wordBreak: "break-word",
    width: 200,
    marginLeft: 25,
    marginRight: 25,
    fontSize: 14,
    textAlign: "center",
  },
  connectionAppButton: {
    position: "absolute",
    bottom: 0,
    marginBottom: 40,
    fontSize: 16,
    padding: "8px 35px",
  },
  picture: {
    backgroundColor: Colors.white,
    borderRadius: 4,
    width: "50%",
  },
  badges: {
    width: "90%",
    paddingLeft: 30,
    paddingRight: 30,
    marginBottom: 25,
    marginTop: 15,
    flexDirection: "row",
    display: "flex",
    alignItems: "space-between",
    justifyContent: "space-between",
  },
  badge: {
    width: "100%",
  },
  downloadIosBadge: {
    textDecoration: "none",
    marginRight: 10,
  },
  connectionStatus: {
    position: "absolute",
    top: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  connectionStatusText: {
    color: "#8A8A8A",
    marginTop: 5,
    fontSize: 14,
    marginLeft: 2,
  },
  connectedText: {
    color: "#75D67E",
    fontWeight: "bold",
  },
  bold: {
    fontWeight: "bold",
  },
  isPremiumDiv: {
    marginTop: 20,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  booleanText: {
    fontSize: "1em",
    flex: 1,
    maxWidth: "100%",
    marginRight: 25,
  },
  subtitleModal: {
    color: "red",
    textAlign: "center",
  },
});

const PhonePreview = ({ classes, children, languageSelected }) => {
  const [width] = useWindowSize();
  const isSmall = width < 1440;
  return (
    <div className={classes.main}>
      <div className={classes.svgWrapperDiv}>
        <IphoneMockup sizePercentage={isSmall ? 0.7 : 0.78} />
        <div className={classes.connectionAppDiv}>
          {children ? (
            children
          ) : (
            <PhonePreviewBoxes languageSelected={languageSelected} />
          )}
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(React.memo(PhonePreview));
