import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Colors from "../../constants/Colors";
import MoreIcon from "./MoreIcon";
import { useDispatch, useSelector } from "react-redux";
import { selectTab } from "../../services/campaign/actions";
import i18n from "../../i18n";
import { getIsSuperAdminClientSelected } from "services/client/selectors";
import { getCampaign } from "services/campaign/selectors";
import { makeStyles } from "@mui/styles";
import {
  getIsSuperAdmin,
  getIsTrainerOrObserver,
} from "services/user/selectors";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "90%",
  },
  tabsRoot: {
    borderBottom: "1px solid #e8e8e8",
  },
  tabsIndicator: {
    backgroundColor: Colors.pureMain,
  },
  tabRoot: {
    textTransform: "initial",
    minWidth: 72,
    fontWeight: 500,
    fontFamily: ["Montserrat"].join(","),
    "&:hover": {
      color: Colors.pureMain,
      opacity: 1,
    },
    "&:tabSelected": {
      color: Colors.pureMain,
      fontWeight: 600,
    },
    "&:focus": {
      color: Colors.pureMain,
    },
  },
  tabSelected: {
    fontWeight: 600,
    color: Colors.pureMain,
  },
  notif: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: Colors.pink,
    position: "absolute",
    top: 0,
    right: -12,
    opacity: 1,
    zIndex: 10,
  },
  button: {
    cursor: "pointer",
    color: "white",
    position: "absolute",
    right: 0,
    bottom: 0,
    borderRadius: 4,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    padding: "10px 14px 10px 14px",
    backgroundColor: Colors.pureMain,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 0.2s",
    "&:hover": {
      opacity: 0.8,
    },
  },
  buttonTitle: {
    color: "inherit",
    marginLeft: 10,
  },
  customTab: {
    fontWeight: 500,
    fontSize: 14,
    fontFamily: "Montserrat",
    position: "relative",
    color: Colors.black,
    "&:hover": {
      color: Colors.pureMain,
    },
  },
  customTabSelected: {
    fontWeight: 600,
    color: Colors.pureMain,
    "&:hover": {
      fontWeight: 600,
      color: Colors.pureMain,
    },
  },
}));

interface IProps {
  hasDraft?: boolean;
  onClickCreate: () => void;
}

const CustomizedTabs = ({ hasDraft = false, onClickCreate }: IProps) => {
  const classes = useStyles();

  const { tabSelected } = useSelector(getCampaign);
  const isSuperAdminClientSelected = useSelector(getIsSuperAdminClientSelected);
  const isSuperAdmin = useSelector(getIsSuperAdmin);
  const hasLimitedAccess = useSelector(getIsTrainerOrObserver);

  const dispatch = useDispatch();

  const handleChange = (event, value) => {
    if (value === 1 && hasLimitedAccess) {
      dispatch(selectTab(2));
    } else {
      dispatch(selectTab(value));
    }
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={tabSelected}
        onChange={handleChange}
        classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
      >
        {isSuperAdminClientSelected && (
          <Tab
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label={i18n.t("my-campaigns-premium-demo")}
          />
        )}
        <Tab
          disableRipple
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          label={i18n.t("my-campaigns")}
        />
        {!hasLimitedAccess && (
          <Tab
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label={i18n.t("my-templates")}
          />
        )}
        {!hasLimitedAccess && (
          <Tab
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label={i18n.t("Archived")}
          />
        )}
        {!hasLimitedAccess && (
          <Tab
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label={
              <Typography
                className={`${classes.customTab} ${
                  tabSelected === 3 && classes.customTabSelected
                }`}
              >
                {i18n.t("my-drafts")}
                {hasDraft && <span className={classes.notif} />}
              </Typography>
            }
          />
        )}
        {isSuperAdmin && (
          <Tab
            disableRipple
            classes={{
              root: classes.tabRoot,
              selected: classes.tabSelected,
            }}
            label={i18n.t("admin-templates")}
          />
        )}
        {!hasLimitedAccess && (
          <div className={classes.button} onClick={onClickCreate}>
            <MoreIcon />
            <Typography className={classes.buttonTitle}>
              {i18n.t("new-campaign")}
            </Typography>
          </div>
        )}
      </Tabs>
    </div>
  );
};

export default CustomizedTabs;
