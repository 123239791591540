import React from "react";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Colors from "constants/Colors";

const WIDTH = 90;

const MinusIcon = () => (
  <svg
    width="16"
    height="2"
    viewBox="0 0 16 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8281 0.215759H0.795931C0.704067 0.215759 0.628906 0.29092 0.628906 0.382784V1.63547C0.628906 1.72733 0.704067 1.80249 0.795931 1.80249H15.8281C15.92 1.80249 15.9952 1.72733 15.9952 1.63547V0.382784C15.9952 0.29092 15.92 0.215759 15.8281 0.215759Z"
      fill="#F4FBFF"
    />
  </svg>
);

const PlusIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.40638 6.19796V0.51947H5.78395V6.19796H0.105469V7.82038H5.78395V13.4989H7.40638V7.82038H13.0849V6.19796H7.40638Z"
      fill="#F4FBFF"
    />
  </svg>
);

const useStyles = makeStyles(theme => ({
  main: {
    height: 100,
    width: WIDTH,
    borderRadius: 10,
    backgroundColor: "#F4FBFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  number: {
    fontSize: 40,
    color: "#282828",
    fontWeight: 550,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  leftButton: {
    height: 32,
    width: WIDTH / 2,
    borderBottomLeftRadius: 10,
    backgroundColor: Colors.pureMain,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    cursor: "pointer",
    transition: "all 0.2s ease",
    borderRight: "1px solid #fff",
    "&:hover": {
      opacity: 0.9,
    },
  },
  rightButton: {
    height: 32,
    width: WIDTH / 2,
    borderBottomRightRadius: 10,
    backgroundColor: Colors.pureMain,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": {
      opacity: 0.9,
    },
  },
}));

interface Props {
  number: number;
  onChangeNumber: (number) => void;
}

const NumberInput = ({ number, onChangeNumber }: Props) => {
  const classes = useStyles();

  const handleIncrease = () => {
    onChangeNumber(number + 1);
  };

  const handleDecrease = () => {
    if (number) {
      onChangeNumber(number - 1);
    }
  };

  return (
    <div className={classes.main}>
      <Typography className={classes.number}>{number}</Typography>
      <div className={classes.buttons}>
        <div className={classes.leftButton} onClick={handleDecrease}>
          <MinusIcon />
        </div>
        <div className={classes.rightButton} onClick={handleIncrease}>
          <PlusIcon />
        </div>
      </div>
    </div>
  );
};

export default NumberInput;
