import { call, put, takeLatest, all } from "redux-saga/effects";

import {
  MANAGER_GET_TEAM_REQUESTING,
  MANAGER_GET_TEAM_SUCCESS,
  MANAGER_GET_TEAM_ERROR,
  MANAGER_CREATE_TEAM_REQUESTING,
  MANAGER_CREATE_TEAM_SUCCESS,
  MANAGER_CREATE_TEAM_ERROR,
  MANAGER_SEE_USER_PROFILE_REQUESTING,
  MANAGER_SEE_USER_PROFILE_SUCCESS,
  MANAGER_SEE_USER_PROFILE_ERROR,
  MANAGER_SUBMIT_COMMENT_REQUESTING,
  MANAGER_SUBMIT_COMMENT_SUCCESS,
  MANAGER_SUBMIT_COMMENT_ERROR,
  MANAGER_GET_COMMENTS_REQUESTING,
  MANAGER_GET_COMMENTS_ERROR,
  MANAGER_GET_COMMENTS_SUCCESS,
  MANAGER_DELETE_COMMENT_SUCCESS,
  MANAGER_DELETE_COMMENT_ERROR,
  MANAGER_DELETE_COMMENT_REQUESTING,
  MANAGER_SUBMIT_GOALS_REQUESTING,
  MANAGER_SUBMIT_GOALS_SUCCESS,
  MANAGER_SUBMIT_GOALS_ERROR,
  MANAGER_CREATE_SURVEY_REQUESTING,
  MANAGER_CREATE_SURVEY_SUCCESS,
  MANAGER_CREATE_SURVEY_ERROR,
  MANAGER_MODIFY_SURVEY_SUCCESS,
  MANAGER_MODIFY_SURVEY_ERROR,
  MANAGER_MODIFY_SURVEY_REQUESTING,
  MANAGER_GET_SURVEYS_REQUESTING,
  MANAGER_GET_SURVEYS_SUCCESS,
  MANAGER_GET_SURVEYS_ERROR,
  MANAGER_DELETE_SURVEY_SUCCESS,
  MANAGER_DELETE_SURVEY_ERROR,
  MANAGER_DELETE_SURVEY_REQUESTING,
  MANAGER_SEND_NOTIFICATION_REQUESTING,
  MANAGER_SEND_NOTIFICATION_SUCCESS,
  MANAGER_SEND_NOTIFICATION_ERROR,
  MANAGER_EDIT_SCHEDULED_NOTIFICATION_REQUESTING,
  MANAGER_EDIT_SCHEDULED_NOTIFICATION_ERROR,
  MANAGER_EDIT_SCHEDULED_NOTIFICATION_SUCCESS,
  MANAGER_DELETE_SCHEDULED_NOTIFICATION_REQUESTING,
  MANAGER_DELETE_SCHEDULED_NOTIFICATION_ERROR,
  MANAGER_DELETE_SCHEDULED_NOTIFICATION_SUCCESS,
} from "./constants";

import { fetchApi } from "../api";
import { openSnackMessage } from "../general/actions";
import i18n from "../../i18n";

const apiEndpoints = {
  createManagerTeam: "/v1/users/managers/create-team",
  getManagerTeam: "/v1/users/managers/team/",
  managerGetUserProfile: "/v1/users/managers/profile",
  submitManagerComment: "/v1/comments/create",
  deleteManagerComment: "/v1/comments",
  getManagerComments: "/v1/comments", //:managerId/:userId
  submitGoals: "/v1/users/managers/submit-goals",
  modifyManagerSurvey: "/v1/manager-surveys/",
  getAllManagerSurveys: "/v1/manager-surveys/all",
  getTodayManagerSurvey: "/v1/manager-surveys/today",
  createManagerSurvey: "/v1/manager-surveys/create",
  deleteManagerSurvey: "/v1/manager-surveys/",
  sendNotification: "/v1/users/managers/send-notification",
  editScheduledNotification: "/v1/users/managers/edit-notification",
  cancelNotification: "/v1/users/managers/cancel-notification",
};

const createTeamApi = data => {
  return fetchApi(apiEndpoints.createManagerTeam, data, "post");
};

const getManagerTeamApi = data => {
  return fetchApi(apiEndpoints.getManagerTeam + data, {}, "get");
};

const seeUserProfileApi = data => {
  return fetchApi(apiEndpoints.managerGetUserProfile, data, "post");
};

const submitCommentApi = data => {
  return fetchApi(apiEndpoints.submitManagerComment, data, "post");
};

const deleteManagerCommentApi = data => {
  return fetchApi(apiEndpoints.deleteManagerComment, data, "delete");
};

const submitGoalsApi = data => {
  return fetchApi(apiEndpoints.submitGoals, data, "post");
};

const getManagerCommentsApi = data => {
  const { managerId, userId } = data;
  return fetchApi(
    `${apiEndpoints.getManagerComments}/${managerId}/${userId}`,
    {},
    "get",
  );
};

const getAllManagerSurveysApi = data => {
  const { managerId, userId } = data;
  return fetchApi(
    `${apiEndpoints.getAllManagerSurveys}/${managerId}/${userId}`,
    {},
    "get",
  );
};

/*const getTodayManagerSurveyApi = data => {
  const { managerId, userId } = data;
  return fetchApi(
    `${apiEndpoints.getTodayManagerSurvey}/${managerId}/${userId}`,
    {},
    "get",
  );
};*/

const createManagerSurveyApi = data => {
  return fetchApi(apiEndpoints.createManagerSurvey, data, "post");
};

const modifyManagerSurveyApi = data => {
  return fetchApi(apiEndpoints.modifyManagerSurvey, data, "patch");
};

const deleteManagerSurveyApi = data => {
  return fetchApi(apiEndpoints.deleteManagerSurvey, data, "delete");
};

const sendNotificationApi = data => {
  return fetchApi(apiEndpoints.sendNotification, data, "post");
};

const editScheduledNotificationApi = data => {
  return fetchApi(apiEndpoints.editScheduledNotification, data, "put");
};

const cancelNotificationApi = data => {
  return fetchApi(apiEndpoints.cancelNotification, data, "put");
};

function* createTeamFlow(action) {
  try {
    const result = yield call(createTeamApi, action.payload);
    yield put({
      type: MANAGER_CREATE_TEAM_SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: MANAGER_CREATE_TEAM_ERROR,
      payload: error,
    });
  }
}

function* getTeamFlow(action) {
  try {
    const result = yield call(getManagerTeamApi, action.payload);
    yield put({
      type: MANAGER_GET_TEAM_SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: MANAGER_GET_TEAM_ERROR,
      payload: error,
    });
  }
}

function* seeUserProfileFlow(action) {
  try {
    const result = yield call(seeUserProfileApi, action.payload);
    yield put({
      type: MANAGER_SEE_USER_PROFILE_SUCCESS,
      payload: result,
    });
    // get or generate survey
  } catch (error) {
    yield put({
      type: MANAGER_SEE_USER_PROFILE_ERROR,
      payload: error,
    });
  }
}

function* submitCommentFlow(action) {
  try {
    const result = yield call(submitCommentApi, action.payload);
    yield put({
      type: MANAGER_SUBMIT_COMMENT_SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: MANAGER_SUBMIT_COMMENT_ERROR,
      payload: error,
    });
  }
}

function* getManagerCommentsFlow(action) {
  try {
    const result = yield call(getManagerCommentsApi, action.payload);
    yield put({
      type: MANAGER_GET_COMMENTS_SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: MANAGER_GET_COMMENTS_ERROR,
      payload: error,
    });
  }
}

function* deleteManagerCommentFlow(action) {
  try {
    yield call(deleteManagerCommentApi, action.payload);
    yield put({
      type: MANAGER_DELETE_COMMENT_SUCCESS,
      payload: action.payload.commentId,
    });
  } catch (error) {
    yield put({
      type: MANAGER_DELETE_COMMENT_ERROR,
      payload: error,
    });
  }
}

function* submitGoalsFlow(action) {
  try {
    yield call(submitGoalsApi, action.payload);
    yield put({
      type: MANAGER_SUBMIT_GOALS_SUCCESS,
    });
    yield put(
      openSnackMessage({ snackMessage: i18n.t("goals-modified-success") }),
    );
  } catch (error) {
    yield put({
      type: MANAGER_SUBMIT_GOALS_ERROR,
      payload: error,
    });
  }
}

function* createManagerSurveyFlow(action) {
  try {
    const result = yield call(createManagerSurveyApi, action.payload);
    yield put({
      type: MANAGER_CREATE_SURVEY_SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: MANAGER_CREATE_SURVEY_ERROR,
      payload: error,
    });
  }
}

function* modifyManagerSurveyFlow(action) {
  try {
    const result = yield call(modifyManagerSurveyApi, action.payload);
    yield put({
      type: MANAGER_MODIFY_SURVEY_SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: MANAGER_MODIFY_SURVEY_ERROR,
      payload: error,
    });
  }
}

function* deleteManagerSurveyFlow(action) {
  try {
    yield call(deleteManagerSurveyApi, action.payload);
    yield put({
      type: MANAGER_DELETE_SURVEY_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: MANAGER_DELETE_SURVEY_ERROR,
      payload: error,
    });
  }
}

function* getAllManagerSurveysFlow(action) {
  try {
    const result = yield call(getAllManagerSurveysApi, action.payload);
    yield put({
      type: MANAGER_GET_SURVEYS_SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: MANAGER_GET_SURVEYS_ERROR,
      payload: error,
    });
  }
}

function* sendNotificationFlow(action) {
  try {
    const result = yield call(sendNotificationApi, action.payload);
    yield put({
      type: MANAGER_SEND_NOTIFICATION_SUCCESS,
      payload: result,
    });
    yield put(openSnackMessage({ snackMessage: i18n.t("notification-sent") }));
  } catch (error) {
    yield put({
      type: MANAGER_SEND_NOTIFICATION_ERROR,
      payload: error,
    });
  }
}

function* editScheduledNotificationFlow(action) {
  try {
    const result = yield call(editScheduledNotificationApi, action.payload);
    yield put({
      type: MANAGER_EDIT_SCHEDULED_NOTIFICATION_SUCCESS,
      payload: result,
    });
    yield put(openSnackMessage({ snackMessage: i18n.t("notification-edit") }));
  } catch (error) {
    yield put({
      type: MANAGER_EDIT_SCHEDULED_NOTIFICATION_ERROR,
      payload: error,
    });
  }
}

function* cancelNotificationFlow(action) {
  try {
    const result = yield call(cancelNotificationApi, action.payload);
    yield put({
      type: MANAGER_DELETE_SCHEDULED_NOTIFICATION_SUCCESS,
      payload: result,
    });
    yield put(
      openSnackMessage({ snackMessage: i18n.t("notification-cancel") }),
    );
  } catch (error) {
    yield put({
      type: MANAGER_DELETE_SCHEDULED_NOTIFICATION_ERROR,
      payload: error,
    });
  }
}

function* Saga() {
  yield all([
    takeLatest(MANAGER_CREATE_TEAM_REQUESTING, createTeamFlow),
    takeLatest(MANAGER_GET_TEAM_REQUESTING, getTeamFlow),
    takeLatest(MANAGER_SEE_USER_PROFILE_REQUESTING, seeUserProfileFlow),
    takeLatest(MANAGER_SUBMIT_COMMENT_REQUESTING, submitCommentFlow),
    takeLatest(MANAGER_GET_COMMENTS_REQUESTING, getManagerCommentsFlow),
    takeLatest(MANAGER_DELETE_COMMENT_REQUESTING, deleteManagerCommentFlow),
    takeLatest(MANAGER_SUBMIT_GOALS_REQUESTING, submitGoalsFlow),
    takeLatest(MANAGER_CREATE_SURVEY_REQUESTING, createManagerSurveyFlow),
    takeLatest(MANAGER_MODIFY_SURVEY_REQUESTING, modifyManagerSurveyFlow),
    takeLatest(MANAGER_GET_SURVEYS_REQUESTING, getAllManagerSurveysFlow),
    takeLatest(MANAGER_DELETE_SURVEY_REQUESTING, deleteManagerSurveyFlow),
    takeLatest(MANAGER_SEND_NOTIFICATION_REQUESTING, sendNotificationFlow),
    takeLatest(
      MANAGER_EDIT_SCHEDULED_NOTIFICATION_REQUESTING,
      editScheduledNotificationFlow,
    ),
    takeLatest(
      MANAGER_DELETE_SCHEDULED_NOTIFICATION_REQUESTING,
      cancelNotificationFlow,
    ),
  ]);
}

export default Saga;
