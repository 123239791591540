import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Colors from "../../../constants/Colors";
import TextField from "@mui/material/TextField";

import { connect } from "react-redux";
import { changeField } from "../../../services/createTrack/actions";
import { getUniqueStepsSkills } from "../../../services/createTrack/selectors";
import {
  createCampaignRequest,
  modifyCampaignRequest,
  selectCampaignRequest,
} from "../../../services/campaign/actions";
import {
  uploadPictureRequest,
  resetUpload,
} from "../../../services/upload/actions";
import moment from "moment";
import { Typography } from "@mui/material";
import i18n from "i18n";
import Uploader from "components/Uploader";
import FullScreenLoading from "components/FullScreenLoading";
import {
  getIsSuperAdmin,
  getSelectedClientId,
  getUserId,
} from "services/user/selectors";
import { openSnackMessage } from "services/general/actions";
import { getAppLanguage } from "services/general/selectors";
import { getSkillsListRequest } from "services/createTrack/actions";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import BooleanField from "../BooleanField";
import { getSelectedClientIsEnterpriseAccount } from "../../../services/myClients/selectors";
import Language from "scenes/CreateTrack/CreateActionTextStep/Language";
import AdministrationMode from "components/AdministrationMode";
import DateCalendarInput from "./DateCalendarInput";
import AdvancedOptions from "scenes/CreateTrack/PublishCampaignModal/AdvancedOptions";
import TeamSize from "scenes/CreateTrack/PublishCampaignModal/TeamSize";
import Frequency from "scenes/CreateTrack/PublishCampaignModal/Frequency";
import Strategy from "scenes/CreateTrack/PublishCampaignModal/Strategy";
import DialogTopBar from "components/DialogTopBar";
import AddSurveyButton from "scenes/SurveyBuilder/AddSurveyButton";
import AddSurveyModal from "scenes/SurveyBuilder/AddSurveyModal/AddSurveyModal";
import selectLanguage from "utils/selectLanguage";
import SkillInput from "scenes/CreateTrack/Skills/SkillInput";
import { fetchApi } from "services/api";
import { getSelectedCampaignId } from "services/campaign/selectors";
import slugify from "slugify";
import { omit } from "lodash";
import CampaignObjectives from "./CampaignObjectives/CampaignObjectives";
import CampaignEvents from "./CampaignEvents/CampaignEvents";

moment.locale("fr");

const GET_SURVEY_API_ENDPOINT = "/v1/surveys"; // :surveyId

const styles = theme => ({
  paper: {
    alignItems: "center",
    display: "flex",
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: "center",
    lineHeight: 1.46666667,
    overflow: "scroll",
    overflowY: "auto",
    padding: "0px 0px 0px 0px",
    borderRadius: "0px",
    minHeight: "100%",
    minWidth: "100%",
    maxWidth: "100%",
  },
  content: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    overflow: "scroll",
    // paddingBottom: 100,
  },
  closeButton: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    zIndex: 10,
    position: "absolute",
    top: 15,
    right: 5,
    "&:hover": {
      color: Colors.black,
    },
  },
  labelIconButton: {
    flexDirection: "column",
  },
  line: {
    width: "100%",
    marginBottom: 25,
    borderRadius: 8,
    height: 2,
    backgroundColor: Colors.blackGrey,
  },
  errorText: {
    color: "#e6525a",
    padding: "13px 15px",
    borderRadius: 6,
    backgroundColor: "rgba(230,82,90,0.15)",
  },
  bodyDiv: {
    marginTop: 25,
    maxWidth: "100%",
    width: 960,
  },
  exitModalText: {
    marginTop: -4,
    fontSize: 13,
  },
  title: {
    // color: Colors.black,
    fontFamily: "Montserrat",
    marginBottom: 10,
    fontSize: 28,
    lineHeight: 1.2143,
    fontWeight: 700,
  },
  subTitle: {
    // color: Colors.black,
    fontFamily: "Montserrat",
    marginTop: 20,
    marginBottom: 5,
    fontSize: 18,
    lineHeight: 1.2143,
    fontWeight: 700,
  },
  buttonDiv: {
    flex: 1,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  leftButton: {
    fontWeight: "normal",
    marginRight: 12,
    color: Colors.grey,
    backgroundColor: "transparent",
    // border: "1px solid",
    transition: "all 80ms linear",
    "&:hover": {
      opacity: 0.8,
      backgroundColor: Colors.hoverBlue,
    },
  },
  uploader: {
    marginTop: 16,
  },
  flagDiv: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  textInfo: {
    fontWeight: "normal",
    color: Colors.grey,
    fontSize: 18,
  },
  autoInviteDiv: {
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "row",
  },
  autoInvite: {
    marginTop: 5,
    flex: 1,
    maxWidth: "75%",
    marginRight: 25,
  },
  teamSizeField: {
    minWidth: "33%",
  },
  rightButtonDiv: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  templateButton: {
    marginLeft: 15,
    backgroundColor: Colors.orange,
    "&:hover": {
      backgroundColor: Colors.orange,
      opacity: 0.85,
    },
  },
  dateBox: {
    zIndex: 10,
    marginTop: 15,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  switchDate: {
    flex: 1,
    paddingRight: 75,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    flexDirection: "column",
  },
  topBar: {
    position: "relative",
    width: "100%",
    height: 75,
    minHeight: 75,
    background: Colors.white,
    boxShadow: "0px 2px 15px rgba(169, 169, 169, 0.25)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 25,
  },
  barBody: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    width: 960,
  },
  topBarTitle: {
    fontFamily: "Montserrat",
    // flex: 1,
    fontWeight: "bold",
    fontSize: 24,
    lineHeight: "27px",
    color: Colors.text,
  },
  firstDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  leftDiv: {
    justifyContent: "flex-start",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  rightDiv: {
    marginLeft: 40,
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  administrationModes: {
    display: "flex",
    marginTop: 15,
    alignItems: "center",
    flexDirection: "row",
  },
  skills: {
    marginBottom: 15,
  },
});

class PublishCampaignModal extends Component {
  constructor(props) {
    super(props);
    // participants
    const { endDate, startDate } = this.props;
    this.state = {
      open: false,
      surveyTitle: this.props.onboardingSurveyId
        ? { en: i18n.t("onboarding-survey-selected") }
        : null,
      isSurveyModalVisible: false,
      registerAllEmployees: false,
      frequency: "oneADay", // "oneADay" // noLimit
      teamSize: 4,
      isFeedbackRequired: false,
      autoRegister: false,
      hasRepetition: true,
      strategy: "ordered",
      isRankingDisabled: false,
      registeredEmails: [],
      skills: [],
      isMicrosoftTeamsReminderActivated: false,
      isMobileModeActivated: true,
      isEmailModeActivated: false,
      isAnchorEmailMode: false,
      hasReviews: false,
      hasPeerToPeer: false,
      debriefText: {
        fr: "Qu'avez-vous ressenti lors du défi ?",
        en: "How did you feel during the challenge?",
      },
      ...this.props,
      ...this.props.templateSettings,
      languageSelected: this.props.language,
      startDate: startDate ? moment(startDate) : null,
      endDate: endDate ? moment(endDate) : null,
      isMbtiRequired: false,
      isDraft: false,
      campaignActionGoal: "6",
      // label: "new",
      error: "",
    };
  }

  _handleKeyDown = event => {
    if (
      (window.navigator.platform.match("Mac")
        ? event.metaKey
        : event.ctrlKey) &&
      event.keyCode === 83
    ) {
      event.preventDefault();
    }
  };

  componentDidMount = () => {
    const { campaignId } = this.props;
    this.props.selectCampaignRequest({ campaignId });
  };

  onRendered = async () => {
    const { endDate, startDate, onboardingSurveyId, clientId } = this.props;
    // convert string to moment object for the calendar component
    // reset state and props
    this.setState({
      ...this.props,
      skills: this.getAllSkills(),
      startDate: startDate ? moment(startDate) : null,
      endDate: endDate ? moment(endDate) : null,
    });

    this.props.getSkillsListRequest({ clientId });

    // fetch Survey Name
    if (onboardingSurveyId) {
      fetchApi(
        `${GET_SURVEY_API_ENDPOINT}?surveyId=${onboardingSurveyId}`,
        {},
        "get",
      ).then(res => {
        this.setState({ surveyTitle: { ...res.title } });
      });
    }
    // this.props.resetUpload();
    // document.addEventListener("keydown", this._handleKeyDown, false);
  };

  handleClose = onClose => () => {
    // document.removeEventListener("keydown", this._handleKeyDown, false);
    onClose();
  };

  onClickPrevious = () => {
    this.handleClose();
  };

  onCloseSurveyModal = () => {
    this.setState({ isSurveyModalVisible: false });
  };

  onSelectSurvey = ({ _id, title }) => () => {
    if (_id === this.state.onboardingSurveyId) {
      this.setState({ onboardingSurveyId: null, surveyTitle: null });
      this.props.openSnackMessage({
        snackMessage: i18n.t("survey-unselected"),
      });
    } else {
      this.setState({ onboardingSurveyId: _id, surveyTitle: title });
      this.props.openSnackMessage({ snackMessage: i18n.t("survey-selected") });
    }
  };

  removeSurvey = () => {
    this.setState({ onboardingSurveyId: null, surveyTitle: null });
  };

  openSurveyModal = () => {
    this.setState({ isSurveyModalVisible: true });
  };

  onChangeParams = param => event => {
    const value = event.target.value;
    const newParam = { ...this.state[param] };
    newParam[this.state.languageSelected] = value;
    this.setState({ [param]: newParam });
  };

  onChangeCampaignObjective = campaignActionGoal => () => {
    this.setState({ campaignActionGoal });
  };

  onChangeSelectField = param => event => {
    const value = event.target.value;
    this.setState({ [param]: value });
  };

  onChangeStrategy = strategy => () => {
    this.setState({ strategy });
  };

  onChangeTeamSize = teamSize => () => {
    this.setState({ teamSize });
  };

  onChangeFrequency = frequency => () => {
    this.setState({ frequency });
  };

  onChangeBooleanField = field => () => {
    this.setState({ [field]: !this.state[field] });
  };

  onSelectLanguage = languageSelected => () => {
    this.setState({ languageSelected });
  };

  onChangeRegisteredEmails = registeredEmails => {
    this.setState({ registeredEmails });
  };

  componentDidUpdate = prevProps => {
    if (prevProps.uploadedPicture !== this.props.uploadedPicture) {
      this.setState({ picture: this.props.uploadedPicture });
    }
  };

  onClickUnsplashPicture = picture => () => {
    this.setState({ picture });
  };

  onChangeSkills = skills => {
    this.setState({ skills });
  };

  onClickPublish = () => {
    const {
      clientId,
      userId,
      campaignId,
      isEditingMode,
      isEnterpriseAccount,
    } = this.props;
    const administrationMode = isEnterpriseAccount ? "email" : "mobile";
    // Prevent override participants
    const stateWithoutParticipants = omit(this.state, "participants");
    const campaignActionGoal = this.state.campaignActionGoal
      ? parseInt(this.state.campaignActionGoal)
      : 6;

    if (isEditingMode) {
      this.props.modifyCampaignRequest({
        ...stateWithoutParticipants,
        campaignActionGoal,
        campaignId,
        administrationMode,
      });
    } else {
      this.props.createCampaignRequest({
        ...this.state,
        campaignActionGoal,
        // skills,
        clientId,
        userId,
        administrationMode,
      });
    }
  };

  getAllSkills = () => {
    const { uniqueStepsSkills } = this.props;
    const { skills } = this.state;
    if (skills.length > 0) {
      return skills;
    }

    if (uniqueStepsSkills && uniqueStepsSkills.length > 0)
      return uniqueStepsSkills;
    return skills;
  };

  onFileUploaded = async files => {
    const { clientId, userId } = this.props;

    const date = new Date().getTime();
    await this.props.uploadPictureRequest({
      urlName: slugify(`campaign-cover-picture/${clientId}/${userId}/${date}`),
      file: files[0],
    });
  };

  onClickAdministrationMode = mode => () => {
    if (mode === "mobile") {
      this.setState({
        isMobileModeActivated: !this.state.isMobileModeActivated,
      });
    } else if (mode === "web") {
      this.setState({ isAnchorEmailMode: !this.state.isAnchorEmailMode });
    } else if (mode === "email") {
      this.setState({ isEmailModeActivated: !this.state.isEmailModeActivated });
    } else if (mode === "microsoftTeams") {
      this.setState({
        isMicrosoftTeamsReminderActivated: !this.state
          .isMicrosoftTeamsReminderActivated,
      });
    }
  };

  render() {
    const {
      classes,
      isVisible,
      onClose,
      uploading,
      requesting,
      isEditingMode,
      isSuperAdmin,
    } = this.props;
    const {
      title,
      description,
      languageSelected,
      debriefText,
      picture,
      isMicrosoftTeamsReminderActivated,
      isMobileModeActivated,
      isEmailModeActivated,
      isAnchorEmailMode,
    } = this.state;

    return (
      <Dialog
        open={isVisible}
        ref={this.onRendered}
        onClose={this.handleClose(onClose)}
        classes={{ paper: classes.paper }}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        <AddSurveyModal
          surveySelectedId={this.state.onboardingSurveyId}
          onSelectSurvey={this.onSelectSurvey}
          isVisible={this.state.isSurveyModalVisible}
          onClose={this.onCloseSurveyModal}
        />
        {requesting && !isEditingMode && (
          <FullScreenLoading title={i18n.t("publishing-loading")} />
        )}
        <DialogTopBar
          title={
            isEditingMode
              ? i18n.t("modify-campaign")
              : i18n.t("publish-campaign")
          }
          onClickRightButton={this.onClickPublish}
          onClickLeftButton={onClose}
          onClose={onClose}
          rightButtonTitle={
            isEditingMode ? i18n.t("save-changes") : i18n.t("publish")
          }
          rightButtonDisabled={!title}
          requesting={requesting}
        />
        <DialogContent className={classes.content}>
          <div className={classes.bodyDiv}>
            <Typography className={classes.subTitle} style={{ marginTop: 0 }}>
              {i18n.t("language", { lng: languageSelected })}
            </Typography>
            <div className={classes.flagDiv}>
              <Language
                className={classes.flag}
                language={languageSelected}
                onSelect={this.onSelectLanguage}
              />
            </div>
            <div className={classes.firstDiv}>
              <div className={classes.leftDiv}>
                <Typography className={classes.subTitle}>
                  {i18n.t("campaign-title", { lng: languageSelected })}
                </Typography>

                <TextField
                  autoFocus
                  fullWidth
                  label={i18n.t("campaign-title", { lng: languageSelected })}
                  value={title[languageSelected] || ""}
                  onChange={this.onChangeParams("title")}
                  margin="normal"
                  variant="outlined"
                />
                <Typography className={classes.subTitle}>
                  {i18n.t("campaign-description", { lng: languageSelected })}
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  label={i18n.t("campaign-description", {
                    lng: languageSelected,
                  })}
                  value={description[languageSelected] || ""}
                  onChange={this.onChangeParams("description")}
                  margin="normal"
                  variant="outlined"
                />
                <AddSurveyButton
                  surveyTitle={
                    this.state.onboardingSurveyId
                      ? selectLanguage({
                          text: this.state.surveyTitle,
                          languageSelected,
                        })
                      : null
                  }
                  languageSelected={languageSelected}
                  onClickRemove={this.removeSurvey}
                  onClick={this.openSurveyModal}
                />

                <Typography className={classes.subTitle}>
                  {i18n.t("campaign-administration-mode", {
                    lng: languageSelected,
                  })}
                </Typography>
                <div className={classes.administrationModes}>
                  <AdministrationMode
                    availableModes={
                      isSuperAdmin
                        ? ["mobile", "web", "email", "microsoftTeams"]
                        : ["mobile", "web", "microsoftTeams"]
                    }
                    isMicrosoftTeamsReminderActivated={
                      isMicrosoftTeamsReminderActivated
                    }
                    isMobileModeActivated={isMobileModeActivated}
                    isEmailModeActivated={isEmailModeActivated}
                    isAnchorEmailMode={isAnchorEmailMode}
                    onClick={this.onClickAdministrationMode}
                  />
                </div>
                <Typography className={`${classes.subTitle} ${classes.skills}`}>
                  {i18n.t("campaign-skills-selected", {
                    lng: languageSelected,
                  })}
                </Typography>
                <SkillInput
                  language={languageSelected}
                  updateStep={this.onChangeSkills}
                  skills={this.state.skills}
                />
              </div>
              <div className={classes.rightDiv}>
                <Typography className={classes.subTitle}>
                  {i18n.t("campaign-picture", { lng: languageSelected })}
                </Typography>
                <Uploader
                  hasUnsplash
                  onClickUnsplashPicture={this.onClickUnsplashPicture}
                  width={250}
                  height={400}
                  picture={picture}
                  className={classes.uploader}
                  onFileUploaded={this.onFileUploaded}
                  uploading={uploading}
                />
              </div>
            </div>

            <DateCalendarInput
              languageSelected={languageSelected}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onDatesChange={({ startDate, endDate }) =>
                this.setState({
                  startDate: startDate
                    ? moment(startDate).startOf("day")
                    : startDate,
                  endDate: endDate ? moment(endDate).endOf("day") : endDate,
                })
              }
            />
            <CampaignObjectives
              objectiveSelected={this.state.campaignActionGoal}
              languageSelected={this.state.languageSelected}
              onChangeCampaignObjectives={this.onChangeCampaignObjective}
            />
            {!!isEditingMode && (
              <CampaignEvents
                languageSelected={this.state.languageSelected}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
              />
            )}
          </div>

          <AdvancedOptions>
            <Typography className={classes.subTitle}>
              {i18n.t("campaign-debrief-text", { lng: languageSelected })}
            </Typography>

            <TextField
              fullWidth
              label={i18n.t("campaign-debrief-text", { lng: languageSelected })}
              value={debriefText[languageSelected] || ""}
              onChange={this.onChangeParams("debriefText")}
              margin="normal"
              variant="outlined"
            />
            <Frequency
              languageSelected={languageSelected}
              onChangeFrequency={this.onChangeFrequency}
              frequency={this.state.frequency}
            />
            <TeamSize
              languageSelected={languageSelected}
              onChangeTeamSize={this.onChangeTeamSize}
              teamSize={this.state.teamSize}
            />
            <Strategy
              languageSelected={languageSelected}
              onChangeStrategy={this.onChangeStrategy}
              strategy={this.state.strategy}
            />

            <BooleanField
              title={i18n.t("campaign-peer-to-peer-title", {
                lng: languageSelected,
              })}
              explanation={i18n.t("campaign-peer-to-peer-explanation", {
                lng: languageSelected,
              })}
              onChange={this.onChangeBooleanField("hasPeerToPeer")}
              checked={this.state.hasPeerToPeer}
            />

            <BooleanField
              title={i18n.t("campaign-reviews-title", {
                lng: languageSelected,
              })}
              explanation={i18n.t("campaign-reviews-explanation", {
                lng: languageSelected,
              })}
              onChange={this.onChangeBooleanField("hasReviews")}
              checked={this.state.hasReviews}
            />

            <BooleanField
              title={i18n.t("campaign-feedback-required-title", {
                lng: languageSelected,
              })}
              explanation={i18n.t("campaign-feedback-required-explanation", {
                lng: languageSelected,
              })}
              onChange={this.onChangeBooleanField("isFeedbackRequired")}
              checked={this.state.isFeedbackRequired}
            />

            <BooleanField
              title={i18n.t("campaign-repetition", {
                lng: languageSelected,
              })}
              explanation={i18n.t("campaign-repetition-explanation", {
                lng: languageSelected,
              })}
              onChange={this.onChangeBooleanField("hasRepetition")}
              checked={this.state.hasRepetition}
            />

            <BooleanField
              title={i18n.t("campaign-ranking-system", {
                lng: languageSelected,
              })}
              explanation={i18n.t("campaign-ranking-system-explanation", {
                lng: languageSelected,
              })}
              onChange={() =>
                this.setState({
                  isRankingDisabled: !this.state.isRankingDisabled,
                })
              }
              checked={!this.state.isRankingDisabled}
            />
            <BooleanField
              title={i18n.t("campaign-invite-auto-title", {
                lng: languageSelected,
              })}
              explanation={i18n.t("campaign-invite-auto-explanation", {
                lng: languageSelected,
              })}
              onChange={() =>
                this.setState({
                  registerAllEmployees: !this.state.registerAllEmployees,
                  autoRegister: !this.state.registerAllEmployees,
                })
              }
              checked={
                this.state.registerAllEmployees || this.state.autoRegister
              }
            />
            <BooleanField
              title={i18n.t("force-MBTI-configuration", {
                lng: languageSelected,
              })}
              explanation={i18n.t("force-MBTI-configuration-explanation", {
                lng: languageSelected,
              })}
              onChange={() =>
                this.setState({
                  isMbtiRequired: !this.state.isMbtiRequired,
                })
              }
              checked={this.state.isMbtiRequired}
            />
          </AdvancedOptions>
        </DialogContent>
      </Dialog>
    );
  }
}

PublishCampaignModal.defaultProps = {
  isEditingMode: false,
  requesting: false,
  languageSelected: "fr",
  templateSettings: {},
  title: {},
  description: {},
  picture: "",
  endDate: null,
  startDate: null,
};

const mapStateToProps = state => {
  const language = getAppLanguage(state);
  const { plan } = state.client;
  const { uploading } = state.upload;
  const clientId = getSelectedClientId(state);
  const isEnterpriseAccount = getSelectedClientIsEnterpriseAccount(state);
  const campaignId = getSelectedCampaignId(state);
  const isSuperAdmin = getIsSuperAdmin(state);
  const userId = getUserId(state);
  return {
    uniqueStepsSkills: getUniqueStepsSkills(state),
    userId,
    clientId,
    language,
    uploading,
    isEnterpriseAccount,
    isSuperAdmin,
    uploadedPicture: state.upload.picture,
    plan,
    campaignId,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    createCampaignRequest,
    modifyCampaignRequest,
    changeField,
    uploadPictureRequest,
    resetUpload,
    openSnackMessage,
    selectCampaignRequest,
    getSkillsListRequest,
  })(PublishCampaignModal),
);
