//@flow
import {
  call,
  put,
  all,
  takeLatest,
} from "redux-saga/effects";

import {
  USER_MODIFY_PICTURE,
  USER_MODIFY_NAME,
  USER_MODIFY_REQUESTING,
  USER_MODIFY_ERROR,
  USER_GET_COMPANY_LOGO_REQUESTING,
  USER_GET_COMPANY_LOGO_SUCCESS,
  USER_GET_COMPANY_LOGO_ERROR,
  USER_GET_ERROR,
  USER_GET_SUCCESS,
  USER_GET_REQUESTING,
} from "./constants";

import { modifyUser, setUser } from "./actions";

import { fetchApi } from "../api";
import { openSnackMessage } from "../general/actions";
import i18n from "../../i18n";

const endPoints = {
  getProfile: "/v1/users/profile",
  getUserById: "/v1/users/",
  modifyUser: "/v1/users/",
  addFriend: "/v1/users/friends/create",
  sendPulseNotification: "/v1/users/send-pulse-notification",
  getCompanyLogo: "/v1/clients/logo/",
};

type modifyUserType = {
  fullName?: string;
  firstName?: string;
  LastName?: string;
  picture?: string;
  title?: string;
  experience?: number;
  level?: number;
  email?: string;
};

type addFriendType = {
  userId: string;
  friendId: string;
};

const getUserApi = (data: string) => {
  return fetchApi(endPoints.getUserById + data, {}, "get");
};

/*const addFriendApi = (addFriendData: addFriendType) => {
  return fetchApi(endPoints.addFriend, addFriendData, "post");
};*/

const getCompanyLogoApi = (data: string) => {
  return fetchApi(endPoints.getCompanyLogo + data, {}, "get");
};

export const modifyUserApi = data => {
  const { userId } = data;
  return fetchApi(endPoints.modifyUser + userId, data, "patch");
};

export const sendPulseNotificationApi = (data: {
  userId: string;
  friendId: string;
}) => {
  return fetchApi(endPoints.sendPulseNotification, data, "post");
};

function* modifyUserFlow(action) {
  try {
    const response = yield call(modifyUserApi, action.payload);
    yield put(modifyUser(response));
    yield put(
      openSnackMessage({ snackMessage: i18n.t("data-modified-success") }),
    );
  } catch (error) {
    yield put({ type: USER_MODIFY_ERROR, payload: error });
  }
}

export function* getUserFlow(action) {
  try {
    const data = action.payload;
    const response = yield call(getUserApi, data);
    yield put({ type: USER_GET_SUCCESS, payload: response });
    yield put(setUser(response));
  } catch (error) {
    yield put({ type: USER_GET_ERROR, payload: error });
    // yield put({ type:, error })
  }
}

function* getCompanyLogoFlow(action) {
  try {
    const response = yield call(getCompanyLogoApi, action.payload);
    yield put({ type: USER_GET_COMPANY_LOGO_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: USER_GET_COMPANY_LOGO_ERROR, payload: error });
  }
}

// Watches for the SIGNUP_REQUESTING action type
// When it gets it, it will call signupFlow()
// WITH the action we dispatched
function* userWatcher() {
  yield all([
    takeLatest(USER_GET_REQUESTING, getUserFlow),
    // takeEvery(ONBOARDING_END, setOnboardingPictureFlow),
    takeLatest(
      [USER_MODIFY_REQUESTING, USER_MODIFY_NAME, USER_MODIFY_PICTURE],
      modifyUserFlow,
    ),
    takeLatest(USER_GET_COMPANY_LOGO_REQUESTING, getCompanyLogoFlow),
  ]);
}

export default userWatcher;
