// First, we need to import the FlatList and other React Native component
import "intersection-observer";
import React, { Component } from "react";
// We also need to add the connectInfiniteHits connector to our import
import { connectInfiniteHits } from "react-instantsearch-dom";
import { connect } from "react-redux";
import withStyles from '@mui/styles/withStyles';
import "moment/locale/fr";
import UserRow from "./UserRow";

const styles = theme => ({
  list: {
    height: 600,
    overflow: "scroll",
    minWidth: "100%",
    marginTop: 20,
  },
});

class UserClickHits extends Component {
  onSentinelIntersection = entries => {
    const { hasMore, refine } = this.props;

    entries.forEach(entry => {
      if (entry.isIntersecting && hasMore) {
        refine();
      }
    });
  };

  sentinel = null;

  componentDidMount() {
    this.observer = new IntersectionObserver(this.onSentinelIntersection);
    this.observer.observe(this.sentinel);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  render() {
    const { hits, classes, isExcluded, onClick } = this.props;
    return (
      <div className={classes.list}>
        {hits.map((item, index) => {
          const {
            firstName,
            lastName,
            picture,
            createdAt,
            email,
            role,
            _id,
          } = item;
          if (isExcluded(item)) {
            return null;
          }
          return (
            <UserRow
              key={index}
              onClick={onClick(item)}
              firstName={firstName}
              lastName={lastName}
              picture={picture}
              createdAt={createdAt}
              email={email}
              role={role}
              _id={_id}
              index={index}
              isRoleInputDisabled={() => true}
            />
          );
        })}
        <div
          className="ais-InfiniteHits-sentinel"
          ref={c => (this.sentinel = c)}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  {},
)(connectInfiniteHits(withStyles(styles)(UserClickHits)));
