import React, { Component } from "react";
import withStyles from '@mui/styles/withStyles';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Colors from "../../constants/Colors";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import Switch from "react-switch";
import { connect } from "react-redux";
import {
  modifyMyUserRequest,
  changeField,
} from "../../services/myUsers/actions";
import FlatButton from "../../components/FlatButton";
import moment from "moment";
import { getSelectedClientId } from "../../services/user/selectors";
import i18n from "../../i18n";
import { Avatar, Typography } from "@mui/material";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  Configure,
  Index,
} from "react-instantsearch-dom";
import UserClickHits from "./UsersAccess/UserClickHits";
import UserRow from "./UserRow";

const searchClient = algoliasearch(
  "MTMFJ78PRC",
  "a6400a80c85f1dcceaaddde616c096a3",
);

moment.locale("fr");

const styles = theme => ({
  closeButton: {
    zIndex: 10,
    position: "absolute",
    top: 1,
    right: 1,
  },
  minDiv: {
    // width: 300,
    minWidth: 700,
  },

  commentDiv: {
    width: "100%",
    borderRadius: 8,
    border: "solid",
    borderWidth: 1,
    borderColor: Colors.lightGrey,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    marginBottom: 15,
  },
  date: {
    textAlign: "right",
    fontSize: 12,
  },
  commentMainDiv: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    flexDirection: "row",
  },
  actionsButtonDiv: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  deleteButton: {
    padding: 1,
  },
  editButton: {
    padding: 1,
    marginRight: -3,
  },
  commentBottom: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    display: "flex",
  },
  leftDiv: {
    height: "100%",
    borderRight: "solid 1px",
    paddingRight: 25,
    borderRightColor: Colors.lightGrey,
    flex: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  rightDiv: {
    flex: 3,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  mainDiv: {
    height: 700,
    flex: 1,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  paper: {
    maxWidth: "calc(100% - 96px)",
    width: 1000,
    // minWidth: 950
    // maxWidth: 1100,
    // minHeight: 800
    // width: "75%"
    // maxWidth: 1200
  },
  addOptionButton: {
    marginTop: 10,
  },
  saveButton: {
    alignSelf: "center",
    width: "75%",
    marginTop: 10,
    marginBottom: 15,
  },
  answerSeparatorText: {
    color: Colors.black,
    fontSize: 16,
    fontWeight: "500",
    whiteSpace: "nowrap",
    marginTop: 5,
    marginLeft: 10,
    marginRight: 10,
  },
  clickText: {
    fontSize: 18,
    fontWeight: "500",
    marginBottom: 15,
    marginLeft: 10,
    marginRight: 10,
  },
  separator: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  line: {
    width: "100%",
    borderRadius: 8,
    height: 2,
    backgroundColor: Colors.blackGrey,
  },
  errorText: {
    color: "#e6525a",
    padding: "13px 15px",
    borderRadius: 6,
    backgroundColor: "rgba(230,82,90,0.15)",
  },
  dialogTitle: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  userDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  picture: {
    objectFit: "cover",
    width: 150,
    height: 150,
    marginBottom: 15,
  },
  accessUsers: {
    flex: 1,
    // height: 200,
    overflow: "scroll",
    // minHeight: 100,
    // maxHeight: 200
  },
  searchDiv: {
    height: 200,
  },
  accent: {
    fontSize: 22,
    fontWeight: "500",
  },
  name: {
    fontWeight: 500,
    fontSize: 20,
    color: Colors.black,
  },
  searchBox: {
    // width: 400
  },
  activateDiv: {
    marginTop: 10,
    marginBottom: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  info: {
    marginLeft: 10,
  },
  dialogContent: {
    paddingRight: 0,
  },
});

class UsersAccessModal extends Component {
  state = {
    open: false,
  };

  formatDate = date => {
    return moment(date).format("L");
  };

  onDelete = commentId => () => {
    this.setState({ open: true, commentId });
  };

  onRendered = () => {
    // document.addEventListener("keydown", this._handleKeyDown, false);
  };

  handleClose = onClose => () => {
    // document.removeEventListener("keydown", this._handleKeyDown, false);
    onClose();
  };

  save = () => {
    const { userSelected } = this.props;
    const { usersAccess, hasUsersAccessRestricted } = userSelected;
    this.props.modifyMyUserRequest({
      userId: userSelected._id,
      usersAccess,
      hasUsersAccessRestricted,
    });
  };

  onClickRemove = user => () => {
    const usersAccess = this.props.userSelected.usersAccess.filter(
      el => el._id !== user._id,
    );
    const userSelected = {
      ...this.props.userSelected,
      usersAccess,
    };

    this.props.changeField({ userSelected });
  };

  displayUsersAccessible = usersAccess => {
    return usersAccess.map((el, index) => {
      return (
        <UserRow
          {...el}
          key={index}
          onClick={this.onClickRemove(el)}
          isRoleInputDisabled={() => true}
        />
      );
    });
  };

  onClickAdd = userAdded => () => {
    const userSelected = {
      ...this.props.userSelected,
      hasUsersAccessRestricted: true,
      usersAccess: [...this.props.userSelected.usersAccess, userAdded],
    };
    this.props.changeField({ userSelected });
  };

  onChangeUserAccess = () => {
    const userSelected = {
      ...this.props.userSelected,
      hasUsersAccessRestricted: !this.props.userSelected
        .hasUsersAccessRestricted,
    };
    this.props.changeField({ userSelected });
  };

  isExcluded = usersAccess => item => {
    const userId = item._id;
    return usersAccess.findIndex(el => el._id === userId) > -1;
  };

  render() {
    const { classes, isVisible, onClose } = this.props;
    const { hasUsersAccessRestricted, usersAccess } = this.props.userSelected;
    return (
      <Dialog
        open={isVisible}
        ref={this.onRendered}
        onClose={this.handleClose(onClose)}
        classes={{ paper: classes.paper }}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        <IconButton className={classes.closeButton} onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
        <DialogTitle id="scroll-dialog-title" className={classes.dialogTitle}>
          {i18n.t("users-access-modal-title")}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.mainDiv}>
            <div className={classes.leftDiv}>
              <div className={classes.userDiv}>
                <Avatar src={this.props.picture} className={classes.picture} />
                <Typography className={classes.name}>
                  {`${this.props.firstName} ${this.props.lastName}`}
                </Typography>
              </div>
              <div className={classes.activateDiv}>
                <Switch
                  checked={!hasUsersAccessRestricted}
                  onChange={this.onChangeUserAccess}
                />
                <Typography className={classes.info}>
                  {i18n.t("has-all-users-access")}
                </Typography>
              </div>
              <div className={classes.separator}>
                <Typography className={classes.answerSeparatorText}>
                  {i18n.t("users-access-line-1")}
                  <span className={classes.accent}>
                    {hasUsersAccessRestricted
                      ? usersAccess.length
                      : i18n.t("all-the")}
                  </span>
                  {i18n.t("users-access-line-2")}
                </Typography>
              </div>
              {this.props.requesting ? (
                <CircularProgress />
              ) : (
                <FlatButton
                  className={classes.saveButton}
                  fullWidth
                  onClick={this.save}
                  variant="contained"
                  color="primary"
                  title={i18n.t("save-changes")}
                />
              )}
              <div className={classes.accessUsers}>
                {hasUsersAccessRestricted &&
                  this.displayUsersAccessible(usersAccess)}
              </div>
            </div>

            <div className={classes.rightDiv}>
              <div className={classes.separator}>
                <Typography className={classes.clickText}>
                  {i18n.t("users-search-add")}
                </Typography>
              </div>
              <div className={classes.searchDiv}>
                <InstantSearch
                  searchClient={searchClient}
                  indexName={this.props.clientId}
                  refresh={this.state.refresh}
                  // indexName={"public_steps"}
                >
                  <div className={classes.searchBox}>
                    <SearchBox
                      // ref={this.SearchBox}
                      translations={{
                        placeholder: i18n.t("search-user-placeholder"),
                      }}
                    />
                  </div>

                  <Index
                    className={classes.index}
                    indexName={this.props.clientId}
                  >
                    <Configure filters="type:user" />
                    <UserClickHits
                      onClick={this.onClickAdd}
                      isExcluded={this.isExcluded(this.props.usersAccess)}
                    />
                  </Index>
                </InstantSearch>
              </div>
              {this.state.error && (
                <p className={classes.errorText}>{this.state.error}</p>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

UsersAccessModal.defaultProps = {
  usersAccess: [],
  hasUsersAccessRestricted: false,
};

const mapStateToProps = state => {
  const clientId = getSelectedClientId(state);
  const { userSelected, requesting } = state.myUsers;
  const { usersAccess } = userSelected;
  return {
    clientId,
    requesting,
    userSelected,
    usersAccess,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    modifyMyUserRequest,
    changeField,
  })(UsersAccessModal),
);
