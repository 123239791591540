import React from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography, TextField } from "@mui/material";
import i18n from "i18n";
import Colors from "constants/Colors";
import { getSelectedQuestion } from "services/survey/selectors";
import { changeQuestionSelected } from "services/survey/actions";

import { connect } from "react-redux";

const styles = theme => ({
  main: {
    width: "100%",
    height: "100%",
    flexDirection: "column",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingTop: 5,
  },
  title: {
    marginTop: 20,
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 18,
  },
  scale: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
});

const CreateRatingQuestion = ({
  classes,
  languageSelected = "fr",
  isDisabled = false,
  selectedQuestion = {},
  changeQuestionSelected,
}) => {
  const {
    questionTitle = { fr: "", en: "" },
    leftLabel = { fr: "", en: "" },
    rightLabel = { fr: "", en: "" },
    questionLabel = { fr: "", en: "" },
  } = selectedQuestion;

  const onChangeParam = param => e => {
    const { value } = e.target;
    const newParam = {
      ...selectedQuestion[param],
      [languageSelected]: value,
    };
    changeQuestionSelected({ [param]: newParam });
  };

  return (
    <div className={classes.main}>
      <Typography className={classes.title}>
        {i18n.t("question-title", { lng: languageSelected })}
      </Typography>
      <TextField
        variant="outlined"
        InputProps={{
          classes: {
            input: classes.titleInput,
          },
        }}
        disabled={isDisabled}
        className={classes.titleInput}
        autoFocus
        fullWidth
        title={i18n.t("question-title", {
          lng: languageSelected,
        })}
        placeholder={i18n.t("question-title", {
          lng: languageSelected,
        })}
        value={
          questionTitle && questionTitle[languageSelected]
            ? questionTitle[languageSelected]
            : ""
        }
        onChange={onChangeParam("questionTitle")}
        margin="normal"
      />
      <Typography className={classes.title} style={{ color: Colors.red }}>
        {i18n.t("left-label", {
          lng: languageSelected,
        })}
      </Typography>
      <TextField
        variant="outlined"
        InputProps={{
          classes: {
            input: classes.titleInput,
          },
        }}
        disabled={isDisabled}
        className={classes.titleInput}
        fullWidth
        title={i18n.t("left-label", {
          lng: languageSelected,
        })}
        placeholder={i18n.t("left-label", {
          lng: languageSelected,
        })}
        value={
          leftLabel && leftLabel[languageSelected]
            ? leftLabel[languageSelected]
            : ""
        }
        onChange={onChangeParam("leftLabel")}
        margin="normal"
      />
      <Typography className={classes.title} style={{ color: "#92D148" }}>
        {i18n.t("right-label", {
          lng: languageSelected,
        })}
      </Typography>
      <TextField
        variant="outlined"
        InputProps={{
          classes: {
            input: classes.titleInput,
          },
        }}
        disabled={isDisabled}
        className={classes.titleInput}
        fullWidth
        title={i18n.t("right-label", {
          lng: languageSelected,
        })}
        placeholder={i18n.t("right-label", {
          lng: languageSelected,
        })}
        value={
          rightLabel && rightLabel[languageSelected]
            ? rightLabel[languageSelected]
            : ""
        }
        onChange={onChangeParam("rightLabel")}
        margin="normal"
      />
      <Typography className={classes.title}>
        {i18n.t("question-label", { lng: languageSelected })}
      </Typography>
      <TextField
        variant="outlined"
        InputProps={{
          classes: {
            input: classes.titleInput,
          },
        }}
        disabled={isDisabled}
        className={classes.titleInput}
        autoFocus
        fullWidth
        title={i18n.t("question-label", {
          lng: languageSelected,
        })}
        placeholder={i18n.t("question-label", {
          lng: languageSelected,
        })}
        value={
          questionLabel && questionLabel[languageSelected]
            ? questionLabel[languageSelected]
            : ""
        }
        onChange={onChangeParam("questionLabel")}
        margin="normal"
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    selectedQuestion: getSelectedQuestion(state),
    languageSelected: state.survey.languageSelected,
  };
};

export default connect(mapStateToProps, { changeQuestionSelected })(
  withStyles(styles)(CreateRatingQuestion),
);
