import React from "react";
import withStyles from '@mui/styles/withStyles';
import Colors from "constants/Colors";

const styles = theme => ({
  main: {
    boxSizing: "border-box",
    padding: "35px 40px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    height: 90,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    backgroundColor: "#fff",
    borderBottom: `solid 1px ${Colors.lightGrey}`,
    // boxShadow: "rgba(142, 142, 142, 0.25) 0px 6px 18px",
    // boxShadow: "0px -6px 18px rgba(169, 169, 169, 0.25)",
    // boxShadow: "0px -6px 18px rgba(142, 142, 142, 0.25)",
  },
});

const BoxHeader = ({ classes, className, children }) => {
  return <div className={`${classes.main} ${className}`}>{children}</div>;
};

export default withStyles(styles)(BoxHeader);
