import React, { useCallback, useState } from "react";
import withStyles from '@mui/styles/withStyles';
import SearchIcon from "./SearchIcon";
import ClearIcon from "./ClearIcon";
import Colors from "../../constants/Colors";
import FilterMenu from "./Library/FilterMenu";
import debounce from "lodash/debounce";

const styles = theme => ({
  main: {
    position: "relative",
    width: "100%",
    borderBottom: `solid 1px #ECEBF1`,
    height: 78,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  searchBar: {
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    " -webkit-font-soothing": "antialiased",
    maxHeight: 200,
    // overflow: "scroll",
    bottom: 0,
    zIndex: 0,
    fontSize: "1rem",
    overflowX: "hidden",
    overflowY: "hidden",
    outline: "none",
    userSelect: "text",
    padding: "0.5rem 1.9rem",
    borderRadius: 25,
    width: "100%",
    position: "relative",
    color: "#3a4570",
    backgroundColor: "#fff",
    border: "1px solid #c4c8d8",
    "&::placeholder": {
      color: "#A5AED1",
    },
  },
  searchDiv: {
    // marginTop: 20,
    width: "100%",
    marginLeft: 20,
    position: "relative",
    display: "block",
  },
  searchIcon: {
    position: "absolute",
    top: "30%",
    zIndex: 1,
    width: 14,
    height: 14,
    color: "#A5AED1",
    fontSize: "1rem",
    left: "0.6rem",
    right: "0.5rem",
  },

  clearIcon: {
    right: "0.6rem",
    zIndex: 100,
    top: "35%",
    position: "absolute",
    cursor: "pointer",
    color: Colors.grey,
  },
  filter: {
    marginRight: 15,
    marginLeft: 0,
  },
});

const SearchMyActions = ({
  classes,
  search,
  onChangeSearch,
  placeholder,
  isLibrary = false,
  skillsList = [],
  selectFilter = () => null,
  filterSelected = [],
  selectSort = () => null,
  sortSelected = "",
}) => {
  const [userSearch, setUserSearch] = useState("");

  const handleDebounceFn = inputValue => {
    onChangeSearch(inputValue);
  };

  const debounceFn = useCallback(debounce(handleDebounceFn, 350), [
    onChangeSearch,
  ]);

  const clearSearch = () => {
    setUserSearch("");
    onChangeSearch("");
  };

  const changeSearch = e => {
    const value = e.target.value;
    setUserSearch(value);
    debounceFn(value);
  };

  return (
    <div className={classes.main}>
      <div className={`${classes.searchDiv}`}>
        <SearchIcon className={classes.searchIcon} color={"#495588"} />
        <input
          role="button"
          tabIndex="0"
          value={userSearch}
          onChange={changeSearch}
          contentEditable="true"
          placeholder={placeholder}
          className={`${classes.searchBar}`}
        />
        {search !== "" && (
          <ClearIcon
            sizePercentage={0.85}
            color={"#495588"}
            onClick={clearSearch}
            className={classes.clearIcon}
          />
        )}
      </div>
      <div className={classes.filter}>
        <FilterMenu
          isLibrary={isLibrary}
          skillsList={skillsList}
          selectFilter={selectFilter}
          selectSort={selectSort}
          filterSelected={filterSelected}
          sortSelected={sortSelected}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(SearchMyActions);
