import { useDispatch, useSelector } from "react-redux";
import { getUserId, getSelectedClientId } from "services/user/selectors";
import { getUserName, getUserPicture } from "services/user/selectors";
import { getCoachingRequesting } from "services/coaching/selectors";
import { createCoachingSessionRequest } from "services/coaching/actions";
import { useEffect, useState } from "react";

export const useStartNewCoaching = () => {
  const coachName = useSelector(getUserName);
  const clientId = useSelector(getSelectedClientId);
  const picture = useSelector(getUserPicture);
  const userId = useSelector(getUserId);
  const requesting = useSelector(getCoachingRequesting);
  const dispatch = useDispatch();

  const sendRequest = async (userSelectedId: string) => {
    dispatch(
      createCoachingSessionRequest({
        clientId,
        coachId: userId,
        userId: userSelectedId,
        coachName,
        coachPicture: picture,
      }),
    );
  };

  return [requesting, sendRequest] as const;
};

export const useIsIntersecting = ref => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting),
  );

  const onInit = function() {
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }

  useEffect(onInit, []);

  return isIntersecting;
};
