import React, { useState, useEffect } from "react";
import withStyles from '@mui/styles/withStyles';
import { Menu, Typography, IconButton } from "@mui/material";
import { SketchPicker } from "react-color";
import {
  updateSkillsListRequest,
  openSnackMessage,
} from "../../../services/createTrack/actions";
import DeleteIcon from "@mui/icons-material/Delete";
import i18n from "../../../i18n";
import Colors from "../../../constants/Colors";
import CheckIcon from "./CheckIcon";
import { connect } from "react-redux";
import { getAppLanguage } from "../../../services/general/selectors";
import { cleanInput } from "./SkillInput";
import LanguageSelection from "./LanguageSelection";

import { LANGUAGES_AVAILABLE } from "scenes/CreateTrack/CreateActionTextStep/Language";

export const SKILLS_COLORS = [
  // { label: "Orange", color: Colors.orange },
  // { label: "Yellow", color: Colors.yellow },
  // { label: "Red", color: Colors.red },
  // { label: "Pink", color: Colors.pink },
  // { label: "Blue", color: Colors.main },
  // { label: "Green", color: Colors.strongGreen },
  // { label: "Indigo", color: Colors.indigo },
  // { label: "Purple", color: Colors.purple },
  // { label: "Grey", color: Colors.coolGrey },
];

const styles = theme => ({
  main: {
    outline: "none",
    display: "flex",
    flexDirection: "column",
    width: "260px",
    minWidth: 180,
    maxWidth: "calc(100vw - 24px)",
    height: "100%",
    maxHeight: "70vh",
    borderRadius: 4,
    border: "solid 1px",
    borderColor: Colors.lightGrey,
  },
  deleteDiv: {
    marginTop: 1,
    marginBottom: -5,
    paddingLeft: 6,
    paddingRight: 6,
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingBottom: 0,
    transition: "all 0.2s",
    "&:hover": {
      background: Colors.lightGrey,
    },
  },
  colors: {
    boxShadow: "rgba(55, 53, 47, 0.09) 0px -1px 0px",
    paddingTop: 6,
    paddingBottom: 6,
    marginTop: 6,
    // justifyContent: "center",
    // alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  colorDiv: {
    paddingLeft: 14,
    paddingRight: 14,
    minHeight: 28,
    fontSize: 14,
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    padding: "5px 10px",
    cursor: "pointer",
    transition: "all 0.2s",
    "&:hover": {
      background: Colors.lightGrey,
    },
  },
  colorBlock: {
    width: 18,
    height: 18,
    background: "rgba(206, 205, 202, 0.5)",
    boxShadow: "rgba(15, 15, 15, 0.1) 0px 0px 0px 1px inset",
    borderRadius: 3,
  },
  colorTitle: {
    color: "rgba(55, 53, 47, 0.6)",
    paddingLeft: 14,
    paddingRight: 14,
    marginTop: 6,
    fontSize: 11,
    marginBottom: 8,
  },
  colorName: {
    fontSize: 14,
    marginLeft: 8,
    marginRight: 14,
    color: "rgb(55, 53, 47, 1)",
  },
  input: {
    borderWidth: 0,
    "&:focus": {
      outline: 0,
    },
    marginLeft: 10,
    marginRight: 10,
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    lineHeight: 20,
    padding: "3px 6px",
    position: "relative",
    borderRadius: "3px",
    boxShadow:
      "rgba(15, 15, 15, 0.1) 0px 0px 0px 1px inset, rgba(15, 15, 15, 0.1) 0px 1px 1px inset",
    background: "rgba(242, 241, 238, 0.6)",
    cursor: "text",
    height: 28,
  },
  deleteButton: {
    paddingLeft: 2,
    paddingRight: 5,
  },
  checkIcon: {
    flex: 1,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  colorPicker: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    paddingTop: 10,
    paddingBottom: 10,
  },
  labelDiv: {
    marginTop: 15,
    marginBottom: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
});

const TagOptionMenu = ({
  language,
  classes,
  anchorEl,
  skill,
  onClose,
  skillsList,
  updateSkillsListRequest,
  openSnackMessage,
}) => {
  const { label, color, value } = skill;
  const [labelInput, setLabelInput] = useState({ fr: "", en: "" });
  const [languageSelected, setLanguageSelected] = useState(language);
  const [colorSelected, setColorSelected] = useState(color);

  useEffect(() => {
    setLabelInput({ ...label });
  }, [value, label]);

  const onChangeLabel = e => {
    const value = e.target.value;
    setLabelInput({ ...labelInput, [languageSelected]: value });
  };

  const handleClose = () => {
    const newSkills = skillsList.skills.map(el => {
      if (el.value === value) {
        return {
          ...el,
          value: !!value ? value : cleanInput(labelInput[language]),
          label: { ...el.label, ...labelInput },
        };
      }
      return el;
    });
    updateSkillsListRequest({
      skillsListId: skillsList._id,
      skills: newSkills,
    });
    onClose();
  };

  const onClickDelete = () => {
    const newSkills = skillsList.skills.filter(el => el.value !== value);
    updateSkillsListRequest({
      skillsListId: skillsList._id,
      skills: newSkills,
    });
    openSnackMessage(i18n.t("skill-delete-success"));
    onClose();
  };

  const onClickColor = colorSelected => () => {
    const newSkills = skillsList.skills.map(el => {
      if (el.value === value) {
        return { ...el, color: colorSelected };
      }
      return el;
    });
    updateSkillsListRequest({
      skillsListId: skillsList._id,
      skills: newSkills,
    });
    setColorSelected(colorSelected);
  };

  const handleChangeComplete = color => {
    onClickColor(color.hex)();
    setColorSelected(color.hex);
    // setColorPickerVisible(false);
  };

  return (
    <Menu
      onClose={handleClose}
      elevation={0}
      id="simple-menu"
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(anchorEl)}
    >
      <div className={classes.main}>
        {/* <div
          className={classes.colorDiv}
          style={{
            color: Colors.grey,
            fill: Colors.grey,
            paddingTop: 10,
            paddingBottom: 10,
          }}
          onClick={handleClose}
        >
          <CloseIcon sizePercentage={1.2} />
          <Typography
            className={classes.colorName}
            style={{ color: Colors.grey }}
          >
            {i18n.t("close")}
          </Typography>
        </div> */}
        <div className={classes.labelDiv}>
          {LANGUAGES_AVAILABLE.map((el, index) => (
            <LanguageSelection
              key={index}
              isSelected={el.value === languageSelected}
              onClick={() => setLanguageSelected(el.value)}
            >
              {el.flag}
            </LanguageSelection>
          ))}
          <input
            type="text"
            id="bestinputid"
            key="bestinput"
            value={labelInput[languageSelected]}
            className={classes.input}
            onChange={onChangeLabel}
            autoFocus
          />
        </div>
        <div className={classes.deleteDiv} onClick={onClickDelete}>
          <IconButton className={classes.deleteButton} size="large">
            <DeleteIcon style={{ fontSize: 22 }} />
          </IconButton>
          <Typography className={classes.delete}>{i18n.t("delete")}</Typography>
        </div>
        <div className={classes.colors}>
          <Typography className={classes.colorTitle}>
            {i18n.t("colors")}
          </Typography>
          {/* <div
            className={classes.colorDiv}
            onClick={() => setColorPickerVisible(true)}
          >
            <div
              className={classes.colorBlock}
              style={{ background: colorSelected }}
            />
            <Typography className={classes.colorName}>
              {i18n.t("random")}
            </Typography>
            {randomColor === colorSelected && (
              <div className={classes.checkIcon}>
                <CheckIcon />
              </div>
            )}
          </div> */}
          {SKILLS_COLORS.map((el, index) => {
            const isSelected = el.color === colorSelected;
            return (
              <div
                className={classes.colorDiv}
                onClick={onClickColor(el.color)}
                key={index}
              >
                <div
                  className={classes.colorBlock}
                  style={{ background: el.color }}
                />
                <Typography className={classes.colorName}>
                  {i18n.t(el.label)}
                </Typography>
                {isSelected && (
                  <div className={classes.checkIcon}>
                    <CheckIcon />
                  </div>
                )}
              </div>
            );
          })}
          <div className={classes.colorPicker}>
            <SketchPicker
              color={colorSelected}
              onChangeComplete={handleChangeComplete}
            />
          </div>
        </div>
      </div>
    </Menu>
  );
};

const mapStateToProps = state => {
  const language = getAppLanguage(state);
  return {
    skillsList: state.createTrack.skillsList,
    language,
  };
};

export default React.memo(
  connect(mapStateToProps, {
    updateSkillsListRequest,
    openSnackMessage,
  })(withStyles(styles)(TagOptionMenu)),
);
