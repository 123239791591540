import React, { useCallback, useMemo } from "react";
import withStyles from "@mui/styles/withStyles";
import { useWindowSize } from "@react-hook/window-size";
import LibraryIcon from "@mui/icons-material/Folder";
import IconButton from "components/IconButton";
import Colors from "constants/Colors";
import NewButton from "./NewButton";
import i18n from "i18n";
import { Typography } from "@mui/material";
import { MainText } from "components/Texts/MainText";
import { useSelector } from "react-redux";
import {
  getLanguageSelected,
  getStepsWithData,
} from "services/createTrack/selectors";
import { CSVLink } from "react-csv";
import selectLanguage from "utils/selectLanguage";
import { getIsModifyForbidden, getIsSuperAdmin } from "services/user/selectors";

const styles = theme => ({
  main: {},
  helperBar: {
    position: "relative",
    background: Colors.white,
    boxShadow: "0px 2px 15px rgba(169, 169, 169, 0.25)",
    height: 60,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 10,
  },
  title: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 20,
    lineHeight: "27px",
    color: Colors.text,
  },
  text: {
    fontFamily: "Montserrat",
    fontSize: 18,
    lineHeight: "27px",
    flex: 1,
    marginLeft: 7,
  },
  libraryButton: {
    minWidth: 0,
    fontWeight: "normal",
    backgroundColor: Colors.lightGrey,
    color: Colors.text,
    borderRadius: 6,
    marginRight: 15,
    padding: "8px 14px",
    fontSize: 14,
    "&:hover": {
      opacity: 0.85,
      color: "white",
    },
  },
  "@media (max-width: 1200px)": {
    title: {
      fontSize: 18,
    },
  },
  link: {
    fontSize: 12,
    marginBottom: -3,
    marginTop: -2,
    bottom: 0,
    color: Colors.pureMain,
    cursor: "pointer",
    width: "fit-content",
    "&:hover": { textDecoration: "underline" },
  },
});

const HelperBar = ({
  classes,
  isLibrary,
  numberOfSteps,
  displayLibrary,
  onCreateStep,
}) => {
  const librarySteps = useSelector(getStepsWithData);

  const [width] = useWindowSize();
  const isSmall = width < 1400;

  const language = useSelector(getLanguageSelected);
  const isSuperAdmin = useSelector(getIsSuperAdmin);
  const isModifyForbidden = useSelector(getIsModifyForbidden);

  const CSVHeaders = useMemo(
    () => [
      {
        label: i18n.t("export-title-actions", { lng: language }),
        key: "stepTitle",
      },
      {
        label: i18n.t("export-skill-actions", { lng: language }),
        key: "contentSkill",
      },
      {
        label: i18n.t("export-content-title-actions", {
          lng: language,
        }),
        key: "contentTitle",
      },
      {
        label: i18n.t("export-content-why-actions", { lng: language }),
        key: "contentWhy",
      },
      {
        label: i18n.t("export-content-how-actions", { lng: language }),
        key: "contentHow",
      },
      {
        label: i18n.t("export-total-done-actions", { lng: language }),
        key: "totalDone",
      },
      {
        label: i18n.t("export-total-views-actions", { lng: language }),
        key: "totalViews",
      },
      {
        label: i18n.t("export-done-percentage-actions", { lng: language }),
        key: "donePercentage",
      },
      {
        label: i18n.t("export-rating-actions", { lng: language }),
        key: "rating",
      },
    ],
    [language],
  );

  const formatDataForExport = useCallback(() => {
    const results = librarySteps.map((step, index) => {
      const {
        title,
        content,
        totalDone,
        totalViews,
        donePercentage,
        rating,
        skills = [],
      } = step || {};
      const stepTitle = selectLanguage({ text: title, language }) || "";
      const contentSkill = skills
        .map(skill => {
          return selectLanguage({ text: skill?.label, language });
        })
        .join(", ");

      const contentTitle = (
        selectLanguage({ text: content?.title, language }) || ""
      )?.replace(/,/g, ";");
      const contentHow = (
        selectLanguage({ text: content?.how, language }) || ""
      )?.replace(/,/g, ";");
      const contentWhy = (
        selectLanguage({ text: content?.why, language }) || ""
      )?.replace(/,/g, ";");

      return {
        stepTitle,
        contentSkill,
        contentTitle,
        contentWhy,
        contentHow: contentHow,
        totalDone,
        totalViews,
        donePercentage,
        rating,
      };
    });
    return results;
  }, [librarySteps, language]);

  return (
    <div className={classes.helperBar}>
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Typography className={classes.title}>
            {isLibrary
              ? i18n.t("all-challenges")
              : i18n.t("selected-challenges")}
          </Typography>
          <Typography
            className={classes.text}
          >{`(${numberOfSteps})`}</Typography>
        </div>
        {isSuperAdmin && (
          <CSVLink
            style={{ textDecoration: "none" }}
            filename={`${i18n.t("csv-action-name", {
              numberOfAction: librarySteps.length,
              language,
            })}.csv`}
            headers={CSVHeaders}
            target="_blank"
            data={formatDataForExport()}
          >
            <MainText className={classes.link} text={i18n.t("export-csv")} />
          </CSVLink>
        )}
      </div>
      {!isModifyForbidden && (
        <>
          <IconButton
            className={classes.libraryButton}
            icon={<LibraryIcon />}
            title={
              !isSmall ? i18n.t("add-action-from-library") : i18n.t("library")
            }
            onClick={displayLibrary}
            size="large"
          />
          <NewButton onClick={onCreateStep} />
        </>
      )}
    </div>
  );
};

export default withStyles(styles)(HelperBar);
