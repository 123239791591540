import React from "react";
import Colors from "constants/Colors";
import { Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';

const OPTION_HEIGHT = 55;

const styles = () => ({
  main: {
    backgroundColor: Colors.veryLightMain,
    // border: "solid 1px",
    // borderColor: Colors.pureMain,
    minHeight: OPTION_HEIGHT,
    borderRadius: 10,
    cursor: "pointer",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
    transition: "all .2s ease",
    "&:hover": {
      transform: "translateY(-2px)",
      opacity: 0.9,
    },
  },
  titleView: {
    // height: (wp("100%") * 1.33) / 3,
    textAlign: "left",
    flex: 1,
    paddingLeft: 20,
    display: "flex",
  },
  title: {
    minWidth: 0,
    overflow: "hidden",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 2 /* number of lines to show */,
    wordBreak: "break-all",
    // fontWeight: "bold",
    fontSize: "0.9em",
    // color: " #5B5B5B",
    color: Colors.grey,
  },
  box: {
    border: "solid",
    borderColor: Colors.main,
    borderWidth: 2,
    marginRight: 20,
    marginLeft: 20,
    borderRadius: 2,
    height: 14,
    width: 14,
    transition: "all 0.2s ease",
  },
  selected: {
    backgroundColor: Colors.main,
  },
  titleSelected: {
    color: Colors.darkBlue,
  },
});

const ChoiceBox = ({ classes, title, isUserChoice, onClick }) => {
  return (
    <div className={classes.main} onClick={onClick}>
      <div className={classes.titleView}>
        <Typography
          className={`${classes.title} ${
            isUserChoice && classes.titleSelected
          }`}
        >
          {title}
        </Typography>
      </div>
      <div className={`${classes.box} ${isUserChoice && classes.selected}`} />
    </div>
  );
};

export default withStyles(styles)(ChoiceBox);
