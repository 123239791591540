import React from "react";
import withStyles from '@mui/styles/withStyles';
import i18n from "i18n";
import CopyIcon from "@mui/icons-material/FileCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Typography } from "@mui/material";
import { openSnackMessage } from "services/general/actions";
import { connect } from "react-redux";
import Colors from "constants/Colors";
import beautifyCode from "utils/beautifyCode";

const styles = theme => ({
  main: {},
  codeDiv: {
    cursor: "pointer",
    zIndex: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    marginBottom: 20,
  },
  codeRightDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  codeExplanation: {},
  code: {
    color: "#fff",
    fontSize: 14,
    borderRadius: 4,
    transition: "all 0.2s ease",
    "&:hover": {
      opacity: 0.9,
    },
  },
  copyButton: {
    color: "#3A4554",
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 8,
    borderRadius: 100,
    fontSize: 12,
    backgroundColor: Colors.lightGrey,
    padding: "8px 12px",
    transition: "all 0.2s ease",
    "&:hover": {
      opacity: 0.9,
    },
  },
  copyText: {
    marginLeft: 3,
  },
  smallText: {
    marginLeft: 5,
    fontSize: 10,
    color: "#fff",
  },
});

const CopyCode = ({ classes, accessCode, openSnackMessage }) => {
  const beautifulCode = beautifyCode(accessCode);

  return (
    <CopyToClipboard
      text={beautifulCode}
      onCopy={() =>
        openSnackMessage({
          snackMessage: i18n.t("access-code-copied"),
        })
      }
    >
      <div className={classes.codeDiv}>
        <div className={classes.copyButton}>
          <CopyIcon style={{ color: "#fff", fontSize: 12 }} />
          <Typography className={classes.smallText}>
            {i18n.t("copy-code")}
          </Typography>
        </div>
        <Typography className={classes.code}>{beautifulCode}</Typography>
      </div>
    </CopyToClipboard>
  );
};

export default connect(null, { openSnackMessage })(
  withStyles(styles)(CopyCode),
);
