import React, { useState } from "react";
import { TextField, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import i18n from "i18n";
import FlatButton from "components/FlatButton";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  getCampaignAccessCode,
  getDynamicLink,
} from "services/campaign/selectors";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedClientId,
  getUserLastName,
  getUserFirstName,
  getUserId,
} from "services/user/selectors";
import { sendEmailInvitationsRequest } from "services/auth/actions";
import { getAppLanguage } from "services/general/selectors";
import { openSnackMessage } from "services/general/actions";
import { getAuthRequesting } from "services/auth/selectors";

const useStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  text: {
    alignSelf: "flex-start",
    textAlign: "left",
    fontSize: 18,
    lineHeight: "1.7em",
    marginBottom: 35,
    fontFamily: "Hind",
  },
  copyText: {
    color: "#A4A4A4",
    fontFamily: "Hind",
    marginTop: 30,
  },
  textField: {
    marginBottom: 30,
  },
  input: {
    backgroundColor: "rgba(246, 246, 246, 1)",
    border: "1px solid rgba(228, 228, 228, 1)",
    color: "rgba(172, 172, 172, 1)",
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 15,
    paddingBottom: 15,
  },
  button: {
    height: 50,
    fontWeight: 500,
  },
}));

const InvitationCoachEmailInput = () => {
  const accessCode = useSelector(getCampaignAccessCode);
  const clientId = useSelector(getSelectedClientId);
  const userId = useSelector(getUserId);
  const firstName = useSelector(getUserFirstName);
  const lastName = useSelector(getUserLastName);
  const language = useSelector(getAppLanguage);
  const dynamicLink = useSelector(getDynamicLink);
  const requesting = useSelector(getAuthRequesting);

  const dispatch = useDispatch();

  const classes = useStyles();
  const [email, setEmail] = useState("");

  const handleSendInvitation = () => {
    const emails = [{ email, role: "user" }];

    dispatch(
      sendEmailInvitationsRequest({
        clientId,
        userId,
        accessCode,
        firstName,
        lastName,
        emails,
        language,
        dynamicLink,
      }),
    );
  };

  const handleChangeEmail = e => {
    const value = e.target.value;
    console.log(value);
    setEmail(value);
  };

  const handleCopyLink = () => {
    dispatch(
      openSnackMessage({
        snackMessage: i18n.t("link-copied"),
      }),
    );
  };

  return (
    <div className={classes.main}>
      <Typography className={classes.text}>
        {i18n.t("coach-invitation-explanation")}
      </Typography>

      <TextField
        InputProps={{
          classes: {
            input: classes.input,
          },
        }}
        className={classes.textField}
        autoComplete="email"
        fullWidth
        placeholder={i18n.t("email-label-coachee")}
        value={email}
        onChange={handleChangeEmail}
        margin="normal"
        variant="outlined"
      />

      <FlatButton
        disabled={!email}
        requesting={requesting}
        className={classes.button}
        title={i18n.t("send-email-invitation")}
        onClick={handleSendInvitation}
        fullWidth
      />
      <CopyToClipboard text={dynamicLink} onCopy={handleCopyLink}>
        <Typography className={classes.copyText}>
          {i18n.t("copy-invitation-link")}
        </Typography>
      </CopyToClipboard>
    </div>
  );
};

export default InvitationCoachEmailInput;
