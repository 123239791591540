import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Link } from "react-router-dom";

import { changeField } from "../../services/signup/actions";

import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";

import Colors from "../../constants/Colors";
import i18n from "../../i18n";
import SnackBar from "./Snackbar";
import CreateUserForm from "./CreateUserForm";
import CompleteUserForm from "./CompleteUserForm";
import FullScreenLoading from "../../components/FullScreenLoading";
import queryString from "query-string";
import LogoDiv from "scenes/Signup/LogoDiv";
import { getRouterQuery } from "services/general/selectors";

import { logout } from "../../services/auth/reducer";

const styles = theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "100vh",
    width: "100vw",
    backgroundColor: Colors.darkBlue,
  },
  logo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: 75,
    // paddingTop: 0,
    margin: "20px auto 20px",
  },
  paper: {
    width: 470,
    display: "flex",
    borderRadius: 3,
    background: "white",
    padding: 40,
    paddingBottom: 20,
    flexDirection: "column",
    alignItems: "center",
    // marginBottom: 20
  },
  title: {
    // lineHeight: 1.35,
    fontFamily: "Montserrat",
    textAlign: "center",
    color: Colors.darkBlue,
    margin: 0,
    marginTop: 0,
    fontWeight: "bold",
    fontSize: 22,
    marginBottom: 15,
    textTransform: "none",
    letterSpacing: 0,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE11 issue.
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  loginError: {
    backgroundColor: "#FCE6E7",
    borderRadius: 8,
  },
  errorText: {
    color: "#e6525a",
    padding: "13px 15px",
    borderRadius: 6,
    backgroundColor: "rgba(230,82,90,0.15)",
  },
  textField: {
    marginTop: 20,
  },
  submitButton: {
    marginTop: 20,
    padding: 12,
    backgroundColor: "#15CD72",
    "&:hover": {
      backgroundColor: "#15CD72",
    },
  },
  noDecoration: {
    textDecoration: "none",
  },
  forgotPassword: {
    marginTop: 20,
    cursor: "pointer",
    textDecoration: "none",
    color: Colors.pureMain,
    fontSize: 17,
    lineHeight: 1.5,
    textAlign: "center",
  },
  newAccountText: {
    fontFamily: "Montserrat",
    color: Colors.white,
    fontSize: 16,
    textAlign: "center",
  },
  bold: {
    fontFamily: "Montserrat",
    marginLeft: 5,
    color: Colors.white,
    fontSize: 16,
    cursor: "pointer",
    fontWeight: "bold",
    textDecoration: "underline",
  },
  noAccountDiv: {
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: Colors.darkBlue,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  topField: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  splitTextField: {
    width: "48.5%",
  },
  agreeTerms: {
    fontSize: 13,
    marginTop: 22,
    marginBottom: 10,
  },
  link: {
    textDecoration: "none",
    color: Colors.pureMain,
    fontWeight: "bold",
  },
  stepDiv: {
    marginBottom: 25,
    marginTop: 10,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  step: {
    borderRadius: 30,
    height: 4,
    width: 40,
    marginLeft: 5,
    marginRight: 5,
    backgroundColor: Colors.lightMain,
  },
  stepActive: {
    backgroundColor: Colors.pureMain,
  },
});

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAnimationVisible: false,
    };
  }

  componentDidMount = () => {
    const { token, existingFirstName } = this.props;
    if (token && existingFirstName) {
      this.props.logout();
    }
    let params = queryString.parse(window.location.search);
    const { clientName = "" } = params;

    this.props.changeField({
      ...params,
      clientName: clientName.replace(/%20/g, " "),
    });
  };

  displayStepForm = () => {
    if (this.props.step === 0) {
      return <CreateUserForm />;
    }
    return <CompleteUserForm />;
  };

  displayTitleForm = () => {
    if (this.props.step === 0) {
      return i18n.t("create-your-account");
    }
    return i18n.t("more-details");
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <SnackBar />
        {this.state.isAnimationVisible && (
          <FullScreenLoading title={i18n.t("loading-session")} />
        )}
        <div className={classes.main}>
          <LogoDiv />
          <div className={classes.paper}>
            <Typography className={classes.title}>
              {this.displayTitleForm()}
            </Typography>
            {this.displayStepForm()}
          </div>
          <div className={classes.noAccountDiv}>
            <Typography className={classes.newAccountText}>
              {i18n.t("got-an-account")}
            </Typography>
            <Link to="/login" className={classes.newAccountText}>
              <Typography className={classes.bold}>
                {i18n.t("login-now")}
              </Typography>
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Signup.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const {
    step,
    email,
    error,
    requesting,
    password,
    phoneNumber,
    firstName,
    lastName,
    loading,
  } = state.signup;
  const { accessToken } = state.token;

  const routerQuery = getRouterQuery(state);
  const { isVerified } = state.user;
  return {
    existingFirstName: state.user.firstName,
    token: accessToken,
    userId: state.user._id,
    isVerified,
    routerQuery,
    router: state.router,
    email,
    step,
    phoneNumber,
    password,
    firstName,
    lastName,
    requesting,
    loading,
    error,
  };
};

export default connect(mapStateToProps, { changeField, push, logout })(
  withStyles(styles)(Signup),
);
