import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";
import { closeSnackMessage } from "../../services/poll/actions";

const styles = theme => ({
  close: {
    padding: theme.spacing.unit / 2,
  },
});

class SimpleSnackbar extends React.Component {
  state = {
    open: true,
  };

  handleClick = () => {
    this.setState({ open: true });
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.props.closeSnackMessage();
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.props.isSnackMessage}
          autoHideDuration={3000}
          onClose={this.handleClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.props.snackMessage}</span>}
          action={[
            // <Button
            //   key="undo"
            //   color="secondary"
            //   size="small"
            //   onClick={this.handleClose}
            // >
            //   UNDO
            // </Button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
              size="large">
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}

SimpleSnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const { isSnackMessage, snackMessage } = state.poll;
  return {
    isSnackMessage,
    snackMessage,
  };
};

export default connect(mapStateToProps, { closeSnackMessage })(
  withStyles(styles)(SimpleSnackbar),
);
