import React, { useMemo, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import useFocus from "scenes/CreateTrack/Skills/useFocus";
import ClickOutside from "components/ClickOutside";
import Fuse from "fuse.js";
import SuggestionBox from "components/SelectOrCreateInput/SuggestionBox";
import Colors from "constants/Colors";

const useStyles = makeStyles(theme => ({
  main: {
    paddingLeft: 8,
    paddingRight: 2,
    cursor: "pointer",
    borderRadius: 5,
    paddingTop: 8,
    paddingBottom: 8,
    display: "flex",
    flexDirection: "column",
    transition: "all 0.2s",
    "&:hover": {
      // backgroundColor: Colors.editable,
    },
  },
  focused: {
    maxHeight: 240,
    width: "100%",
    minHeight: 34,
    backgroundColor: "rgba(242, 241, 238, 0.6)",
    boxShadow: "rgba(55, 53, 47, 0.16) 0px -1px inset",
    borderRadius: 0,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    fontSize: 14,
    cursor: "text",
    overflow: "hidden",
    padding: "8px 9px 1px",
    "&:hover": {
      backgroundColor: "rgba(242, 241, 238, 0.6)",
    },
  },
  input: {
    padding: 0,
    height: 20,
    fontSize: "inherit",
    lineHeight: "inherit",
    border: "none",
    background: "none",
    resize: "none",
    "&:focus": {
      outline: 0,
    },
  },
  bigBox: {
    borderRadius: 3,
    background: "white",
    position: "relative",
    maxWidth: "calc(100vw - 24px)",
    boxShadow:
      "rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px",
    width: 250,
  },
  emptyObjective: {
    padding: "5px 10px",
    border: "dashed 1px",
    borderRadius: 4,
    borderColor: Colors.pureMain,
  },
  notEmpty: {
    backgroundColor: Colors.editable,
  },
  notFocused: {
    "&:hover": {
      opacity: 0.8,
    },
  },
}));

interface Suggestion {
  value: string;
  label: string;
}

interface Props {
  list: Suggestion[];
  placeholder: string;
  search: string;
  setSearch: (string) => void;
  onSubmit: () => void;
  onClickSuggestion: (suggestion: Suggestion) => void;
  searchOptions: any[];
  isDisabled?: boolean;
  maxWidth?: number;
  suggestionPlaceholder: string;
}

const SelectOrCreateInput = ({
  list,
  placeholder,
  suggestionPlaceholder,
  search,
  setSearch,
  onSubmit,
  onClickSuggestion,
  searchOptions,
  isDisabled = false,
  maxWidth = 250,
}: Props) => {
  const classes = useStyles();
  const [isFocused, setFocus] = useState(true);
  const [inputRef] = useFocus();
  const [isOptionMenuOpen] = useState(false);

  const fuseOptions = useMemo(() => {
    return {
      shouldSort: true,
      threshold: 0.3,
      location: 0,
      distance: 80,
      maxPatternLength: 20,
      minMatchCharLength: 1,
      keys: searchOptions,
    };
  }, [searchOptions]);

  const onSearch = event => {
    const value = event.target.value;
    setSearch(value);
  };

  const onKeyDown = event => {
    // delete last objective if search empty on Delete Key
    // Enter event
    if (event.keyCode === 13) {
      onSubmit();
    }
  };

  const handleSuggestionClick = suggestion => () => {
    onClickSuggestion(suggestion);
  };

  const onClickInput = () => {
    setFocus(true);
  };

  const fuse = new Fuse(list, fuseOptions);
  const searchResults = search.length > 0 ? fuse.search(search) : list;

  const notEmpty = !search;

  return (
    <ClickOutside
      onClickOutside={() => {
        if (!isOptionMenuOpen) {
          setFocus(false);
        }
      }}
    >
      <div
        className={`${isFocused && classes.bigBox}`}
        style={{ width: maxWidth }}
      >
        <div
          className={`${classes.main} ${isFocused && classes.focused} ${
            notEmpty && !isDisabled && classes.notEmpty
          } ${notEmpty && !isFocused && classes.notFocused}`}
          onClick={onClickInput}
        >
          <input
            placeholder={placeholder}
            ref={inputRef}
            onKeyDown={onKeyDown}
            autoFocus
            className={classes.input}
            onChange={onSearch}
            value={search}
          />
        </div>
        {isFocused && (
          <SuggestionBox
            search={search}
            onSubmit={onSubmit}
            placeholder={suggestionPlaceholder}
            onClickSuggestion={handleSuggestionClick}
            suggestions={searchResults}
          />
        )}
      </div>
    </ClickOutside>
  );
};

export default SelectOrCreateInput;
