import React from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography } from "@mui/material";
import Colors from "constants/Colors";
import CheckIcon from "components/CheckIcon";
import MobileIcon from "components/AdministrationMode/MobileIcon";
import TeamsIcon from "components/AdministrationMode/TeamsIcon";
import EmailIcon from "components/AdministrationMode/EmailIcon";
import WebIcon from "components/AdministrationMode/WebIcon";

const styles = theme => ({
  main: {
    marginRight: 20,
    cursor: "pointer",
    position: "relative",
    paddingTop: 20,
    paddingBottom: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: 120,
    height: 100,
    borderRadius: 8,
    border: `solid 1px #929292`,
    transition: "all 0.2s ease",
    color: "#2D2D2D",
    "&:hover": {
      backgroundColor: Colors.hoverBlue,
    },
  },
  selected: {
    borderWidth: 2,
    borderColor: Colors.pureMain,
    fontWeight: "bold",
    color: Colors.pureMain,
  },
  check: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "absolute",
    top: 10,
    left: 10,
    height: 15,
    width: 15,
    borderRadius: 2,
    borderColor: "#929292",
    border: "solid 1px",
    transition: "all 0.1s ease",
  },
  text: {
    fontSize: 12,
    marginTop: 15,
    color: "inherit",
    fontWeight: "inherit",
  },
  checkSelected: {
    borderColor: Colors.pureMain,
    backgroundColor: Colors.pureMain,
  },
  notSelectable: {
    cursor: "unset",
    "&:hover": {
      backgroundColor: "white",
    },
  },
});

const displayIcon = (icon, isSelected) => {
  if (icon === "microsoftTeams") return <TeamsIcon sizePercentage={1} />;
  if (icon === "web")
    return <WebIcon color={isSelected ? Colors.pureMain : "#2D2D2D"} />;
  if (icon === "email")
    return <EmailIcon color={isSelected ? Colors.pureMain : "#2D2D2D"} />;
  return <MobileIcon color={isSelected ? Colors.pureMain : "#2D2D2D"} />;
};

const Box = ({
  classes,
  onClick = () => null,
  icon = "mobile",
  text = "Mobile",
  isSelected = false,
  notSelectable = false,
}) => {
  return (
    <div
      className={`${classes.main} ${isSelected && classes.selected} ${
        notSelectable && classes.notSelectable
      }`}
      onClick={onClick}
    >
      {!notSelectable && (
        <div
          className={`${classes.check} ${isSelected && classes.checkSelected}`}
        >
          {isSelected && <CheckIcon color={"#fff"} sizePercentage={0.75} />}
        </div>
      )}
      {displayIcon(icon, isSelected)}
      <Typography className={classes.text}>{text}</Typography>
    </div>
  );
};

export default withStyles(styles)(Box);
