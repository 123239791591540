import moment from "moment";

const changeLocal = language => {
  if (language === "fr") {
    moment.locale("fr");
  } else {
    moment.locale("en");
  }
};

export default changeLocal;
