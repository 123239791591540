import React, { useState } from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography, IconButton } from "@mui/material";
import i18n from "../../../i18n";
import Skill from "./Skill";
import Colors from "../../../constants/Colors";
import MoreIcon from "@mui/icons-material/MoreVert";
import SkillOptionMenu from "./SkillOptionMenu";
import selectLanguage from "../../../utils/selectLanguage";

const styles = theme => ({
  main: {
    boxShadow: "rgba(55, 53, 47, 0.16) 0px -1px inset",
    background: "white",
    paddingTop: 6,
    paddingBottom: 6,
  },
  skillDiv: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    position: "relative",
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 14,
    paddingRight: 14,
    minHeight: 28,
    userSelect: "none",
    transition: "background 120ms ease-in 0s",
    cursor: "pointer",
    width: "100%",
    "&:hover": {
      background: Colors.lightGrey,
    },
  },
  delimiter: {
    marginTop: 6,
    marginBottom: 8,
    fontSize: 12,
    paddingLeft: 14,
    paddingRight: 14,
    color: "rgba(55, 53, 47, 0.6)",
  },
  moreButton: {
    padding: 5,
    position: "absolute",
    right: 2,
  },
  create: {
    marginRight: 7,
  },
  moreIcon: {
    fontSize: 16,
  },
  skill: {
    marginBottom: 0,
  },
});

const AddSkillBox = ({
  classes,
  search = "",
  language,
  skillsSuggestion,
  openOptionMenu,
  onClickSkill,
  onClickCreate,
  className,
}) => {
  const emptySkill = {
    value: "",
    label: { fr: "", en: "" },
    color: Colors.grey,
  };
  const divRef = React.useRef();
  const [skillSelected, setSkillSelected] = useState(emptySkill);
  const [anchorEl, setAnchorEl] = useState(null);

  const onClickSkillOption = skill => event => {
    event.stopPropagation();
    openOptionMenu(true);
    setSkillSelected(skill);
    setAnchorEl(divRef.current);
  };

  const onCloseMenu = () => {
    openOptionMenu(false);
    setSkillSelected(emptySkill);
    setAnchorEl(null);
  };

  return (
    <div className={`${classes.main} ${className}`} ref={divRef}>
      <SkillOptionMenu
        isVisible={Boolean(anchorEl)}
        anchorEl={anchorEl}
        skill={skillSelected}
        onClose={onCloseMenu}
      />
      <Typography className={classes.delimiter}>
        {i18n.t("select-skill-or-create")}
      </Typography>
      <div className={classes.skillsSuggestion}>
        {search.length > 0 && (
          <div className={classes.skillDiv} onClick={onClickCreate}>
            <Typography className={classes.create}>
              {i18n.t("create")}
            </Typography>
            <Skill label={search} />
          </div>
        )}
        {skillsSuggestion.map((el, index) => (
          <div
            key={index}
            onClick={onClickSkill(el)}
            className={classes.skillDiv}
          >
            <Skill
              {...el}
              className={classes.skill}
              label={selectLanguage({ text: el.label, language })}
            />
            <IconButton
              size="small"
              className={classes.moreButton}
              onClick={onClickSkillOption(el)}
            >
              <MoreIcon className={classes.moreIcon} />
            </IconButton>
          </div>
        ))}
      </div>
    </div>
  );
};

export default withStyles(styles)(AddSkillBox);
