import React from "react";
import { Route, Switch } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";

import Login from "./scenes/Login";
import Signup from "./scenes/Signup";
import PasswordForget from "./scenes/Login/PasswordForget";
import Dashboard from "./scenes/Dashboard/index.js";
import NotFound from "./NotFound";
import CreateTrack from "./scenes/CreateTrack";
import Invitation from "./scenes/Invitation";
import MobileRedirect from "./scenes/MobileRedirect/MobileRedirect";
import CampaignScreen from "scenes/Campaigns/CampaignScreen";
import ReviewPage from "scenes/Campaigns/CampaignScreen/ReviewsSide/ReviewPage";
import UserProfile from "scenes/UserProfile";

const Router = props => {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/signup" component={Signup} />
      <Route path="/invitation" component={Invitation} />
      <Route path="/password-forget" component={PasswordForget} />
      <Route path="/mobile-redirect" component={MobileRedirect} />
      <Route exact path="/user/:userProfileId" component={UserProfile} />
      <Route exact path="/campaigns/:campaignId" component={CampaignScreen} />
      <Route
        exact
        path="/campaigns/:campaignId/review/:reviewId"
        component={ReviewPage}
      />
      {/* <Route path="/onboarding" component={Onboarding} /> */}
      {/* <Route path="/" component={Dashboard} /> */}
      <PrivateRoute path="/create" component={CreateTrack} />
      <PrivateRoute path="/library" component={CreateTrack} />
      <PrivateRoute path="/" component={Dashboard} />

      {/* <Route path="/" component={Settings} /> */}
      <Route path="404" component={NotFound} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default Router;
