import React from "react";

const CrownIcon = ({ sizePercentage, color, color2, className }) => (
  <svg
    // width={sizePercentage * 24}
    className={className}
    width={sizePercentage * 17}
    height={sizePercentage * 11}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 17 11"
    version="1.1"
  >
    <path
      d="M8.30769 8.56993C11.5066 8.56993 14.0998 7.48778 14.0998 6.15288C14.0998 4.81799 11.5066 3.73584 8.30769 3.73584C5.10882 3.73584 2.51562 4.81799 2.51562 6.15288C2.51562 7.48778 5.10882 8.56993 8.30769 8.56993Z"
      fill={color}
    />
    <path
      d="M15.1902 2.7783L13.908 8.57037L13.6722 9.66099C13.6722 10.221 11.2698 10.6779 8.30749 10.6779C5.34513 10.6779 2.94283 10.221 2.94283 9.66099L2.70702 8.57037L1.4248 2.7783L5.87571 5.50485L8.30749 1.6582L10.7393 5.50485L15.1902 2.7783Z"
      fill={color2}
    />
    <path
      d="M7.59961 6.56445L8.29774 5.86632L8.99587 6.56445L8.29774 7.26258L7.59961 6.56445Z"
      fill={color}
    />
    <path
      d="M13.908 8.57031L13.6722 9.66094C13.6722 10.221 11.2699 10.6779 8.30751 10.6779C5.34515 10.6779 2.94284 10.221 2.94284 9.66094L2.70703 8.57031C3.7387 8.9535 5.86098 9.21879 8.30751 9.21879C10.754 9.21879 12.8763 8.9535 13.908 8.57031Z"
      fill={color}
    />
    <path
      d="M15.1901 3.98665C15.825 3.98665 16.3397 3.47197 16.3397 2.83707C16.3397 2.20218 15.825 1.6875 15.1901 1.6875C14.5552 1.6875 14.0405 2.20218 14.0405 2.83707C14.0405 3.47197 14.5552 3.98665 15.1901 3.98665Z"
      fill={color}
    />
    <path
      d="M8.30777 2.82209C8.94266 2.82209 9.45734 2.30741 9.45734 1.67252C9.45734 1.03763 8.94266 0.522949 8.30777 0.522949C7.67288 0.522949 7.1582 1.03763 7.1582 1.67252C7.1582 2.30741 7.67288 2.82209 8.30777 2.82209Z"
      fill={color}
    />
    <path
      d="M1.42496 3.98665C2.05985 3.98665 2.57453 3.47197 2.57453 2.83707C2.57453 2.20218 2.05985 1.6875 1.42496 1.6875C0.790071 1.6875 0.275391 2.20218 0.275391 2.83707C0.275391 3.47197 0.790071 3.98665 1.42496 3.98665Z"
      fill={color}
    />
  </svg>
);

CrownIcon.defaultProps = {
  sizePercentage: 1,
  color: "#F4C400",
  color2: "#F9D12F",
};

export default CrownIcon;
