import React, { useCallback, useMemo } from "react";

import Typography from "@mui/material/Typography";
import EditableInput from "./EditableInput";

import Colors from "../../../constants/Colors";
import { useDispatch, useSelector } from "react-redux";
import { getAdminConnections } from "services/general/selectors";
import AdminAvatar from "scenes/Dashboard/AdminAvatar";
import {
  getIsSuperAdmin,
  getSelectedClientId,
  getUserClientId,
  getUserId,
} from "services/user/selectors";
import {
  getStepSelectedId,
  getStepSelectedContent,
} from "services/createTrack/selectors";
import {
  sendAdminConnectionSocket,
  updateAdminConnection,
} from "services/general/actions";
import makeStyles from '@mui/styles/makeStyles';
import { changeStepSelected } from "services/createTrack/actions";

const useStyles = makeStyles(theme => ({
  main: {
    width: "100%",
    position: "relative",
    paddingLeft: 2,
    paddingRight: 2,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  title: {
    color: Colors.darkBlue,
    fontFamily: "Montserrat",
    fontSize: "1.4em",
    fontWeight: "bold",
  },
  bottomBorder: {
    position: "absolute",
    height: 8,
    width: "100%",
    bottom: 1,
    backgroundColor: Colors.lightMain,
  },
  titleDiv: {
    marginRight: 10,
    width: "100%",
    position: "relative",
    display: "flex",
    alignSelf: "flex-start",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  topDiv: {
    minHeight: 35,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  editor: {
    width: "100%",
  },
}));

const UnderlineTitle = ({
  title,
  bottomColor,
  value,
  placeholder,
  isDisabled,
  languageSelected,
  stepSelectedIndex,
  stepPreviewIndex,
  contentKey,
  contentObjectKey,
  modifyStep,
}) => {
  const adminConnections = useSelector(getAdminConnections);
  const stepSelectedId = useSelector(getStepSelectedId);
  const userId = useSelector(getUserId);
  const selectedClientId = useSelector(getSelectedClientId);
  const clientId = useSelector(getUserClientId);
  const isSuperAdmin = useSelector(getIsSuperAdmin);

  const stepSelectedContent = useSelector(getStepSelectedContent);

  const classes = useStyles();
  const dispatch = useDispatch();

  const myPreviousSelectedContent = useMemo(
    () => adminConnections.find(el => el.userId === userId)?.contentSelected,
    [adminConnections, userId],
  );

  const onChangeInput = useCallback(
    text => {
      const content = { ...stepSelectedContent };
      const key = contentObjectKey;
      // do not update if not change detected
      if (content[key] && content[key][languageSelected] === text) {
        return;
      }

      if (!content[key]) {
        content[key] = {};
      }
      content[key][languageSelected] = text;

      dispatch(changeStepSelected({ content }));

      if (stepSelectedId) {
        const change = {};
        if (key === "title") {
          change.contentTitle = content[key];
        } else if (key === "how") {
          change.contentHow = content[key];
        } else if (key === "why") {
          change.contentWhy = content[key];
        }

        modifyStep(change);
      }
    },
    [
      dispatch,
      stepSelectedContent,
      contentObjectKey,
      languageSelected,
      modifyStep,
      stepSelectedId,
    ],
  );

  const onChangeFocus = useCallback(() => {
    if (isDisabled) return;

    const adminConnectionObject = {
      userId,
      clientId: isSuperAdmin ? selectedClientId : clientId,
      stepId: stepSelectedId,
      contentSelected: contentKey,
    };

    dispatch(updateAdminConnection(adminConnectionObject));
    sendAdminConnectionSocket(adminConnectionObject);
  }, [
    dispatch,
    userId,
    clientId,
    stepSelectedId,
    contentKey,
    isDisabled,
    isSuperAdmin,
    selectedClientId,
  ]);

  const onClickOutside = useCallback(() => {
    if (isDisabled) return;

    // prevent from triggering click outside when not concerned - hotfix because Dante2 library do not give access to focus events on input..

    const wasPreviouslySelected = myPreviousSelectedContent === contentKey;
    if (!wasPreviouslySelected) return;

    const adminConnectionObject = {
      userId,
      clientId: isSuperAdmin ? selectedClientId : clientId,
      stepId: stepSelectedId,
      contentSelected: null,
    };

    dispatch(updateAdminConnection(adminConnectionObject));
    sendAdminConnectionSocket(adminConnectionObject);
  }, [
    dispatch,
    userId,
    clientId,
    myPreviousSelectedContent,
    stepSelectedId,
    isDisabled,
    contentKey,
    isSuperAdmin,
    selectedClientId,
  ]);

  return (
    <div className={`${classes.main}`}>
      <div className={classes.topDiv}>
        <div className={classes.titleDiv}>
          <Typography className={classes.title}>{title}</Typography>
          <div
            className={classes.bottomBorder}
            style={bottomColor && { backgroundColor: bottomColor }}
          />
        </div>
        {adminConnections.map(admin => {
          if (
            admin.contentSelected === contentKey &&
            admin.stepId === stepSelectedId &&
            !isDisabled
          ) {
            return <AdminAvatar key={admin.userId} {...admin} />;
          }
          return null;
        })}
      </div>

      <div className={classes.editor}>
        <EditableInput
          onFocus={onChangeFocus}
          onBlur={onClickOutside}
          key={contentKey}
          languageSelected={languageSelected}
          stepPreviewIndex={stepPreviewIndex}
          stepSelectedIndex={stepSelectedIndex}
          isDisabled={isDisabled}
          onChange={onChangeInput}
          value={value}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default React.memo(UnderlineTitle);
