import React, { useState } from "react";
import { Menu, Typography } from "@mui/material";
import CheckIcon from "components/CheckIcon";
import ClickOutside from "components/ClickOutside";
import Colors from "constants/Colors";
import makeStyles from "@mui/styles/makeStyles";
import DropDownIcon from "scenes/Campaigns/CampaignScreen/StatsSide/DropDownIcon";

const useStyles = makeStyles(theme => ({
  main: {
    padding: "10px 15px",
    borderRadius: 6,
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": { backgroundColor: Colors.hoverBlue },
    borderWidth: 1,
    border: "solid",
    borderColor: "#CACACA",
    backgroundColor: "#fff",
  },
  checkIcon: {
    marginLeft: 15,
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
  colorDiv: {
    paddingLeft: 14,
    paddingRight: 14,
    minHeight: 28,
    fontSize: 14,
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    padding: "10px 10px",
    cursor: "pointer",
    transition: "all 0.2s",
    "&:hover": {
      background: "rgba(255, 200, 87, 0.3)",
    },
  },
  filterText: {
    marginLeft: 15,
  },
  sortSelected: {
    fontSize: 16,
    color: "#BABCC5",
    marginRight: 15,
    marginLeft: 15,
  },
  sortDiv: {
    display: "flex",
    cursor: "pointer",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  libraryFilter: {
    outline: "none",
    display: "flex",
    flexDirection: "column",
    minWidth: 225,
    maxWidth: "calc(100vw - 24px)",
    height: "100%",
    maxHeight: "70vh",
    borderRadius: 4,
    border: "solid 1px",
    borderColor: Colors.lightGrey,
  },
  divSelected: {},
}));

interface OptionType {
  label: string;
  value: string;
  icon?: JSX.Element;
  color?: string;
}

interface OptionProps {
  selectOption: (option: OptionType) => () => void;
  optionSelected: OptionType;
  options: OptionType[];
  className?: string;
}

const SelectOptionInput = ({
  selectOption,
  optionSelected,
  options = [],
  className = "",
}: OptionProps) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isHovered, setHover] = useState(false);

  const onMouseLeave = () => {
    setHover(false);
  };

  const onMouseEnter = e => {
    setAnchorEl(e.currentTarget);
    setHover(true);
  };

  const onClickOption = option => () => {
    setHover(false);
    selectOption(option)();
  };

  return (
    <div className={`${classes.main} ${className}`} onClick={onMouseEnter}>
      <div className={classes.sortDiv}>
        {!!optionSelected?.icon && optionSelected?.icon}
        <Typography
          className={`${classes.sortSelected}`}
          style={{ color: optionSelected.color || "black" }}
        >
          {optionSelected.label}
        </Typography>
        <DropDownIcon />
      </div>

      <Menu
        MenuListProps={{ disablePadding: true }}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={anchorEl}
        open={isHovered}
      >
        <ClickOutside onClickOutside={onMouseLeave}>
          <div className={classes.libraryFilter} onMouseLeave={onMouseLeave}>
            {options.map((el, i) => {
              const isSelected = optionSelected.value === el.value;
              return (
                <div
                  className={`${classes.colorDiv} ${
                    isSelected && classes.divSelected
                  }`}
                  onClick={onClickOption(el)}
                  key={i}
                >
                  {!!el.icon && el.icon}
                  <Typography className={classes.filterText}>
                    {el.label}
                  </Typography>
                  {isSelected && (
                    <div className={classes.checkIcon}>
                      <CheckIcon />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </ClickOutside>
      </Menu>
    </div>
  );
};

export { SelectOptionInput };
