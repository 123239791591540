import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Colors from "../../constants/Colors";
import IconButton from "@mui/material/IconButton";

import AddIcon from "@mui/icons-material/Add";

import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import { connect } from "react-redux";
import { sendEmailInvitationsRequest } from "../../services/auth/actions";
import { openSnackMessage } from "../../services/general/actions";

import moment from "moment";
import { Typography } from "@mui/material";
import FlatButton from "../../components/FlatButton";
import InviteEmailUserInput from "../../components/InviteEmailUserInput";
import AccessCodeLottie from "./AccessCodeLottie";
import i18n from "../../i18n";
import { getUserId, getSelectedClientId } from "../../services/user/selectors";
import { getAppLanguage } from "../../services/general/selectors";
import { getAccessCode } from "../../services/myClients/selectors";
import CopyAccessCode from "../../components/CopyAccessCode";

moment.locale("fr");

const styles = theme => ({
  paper: {
    alignItems: "center",
    display: "flex",
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: "center",
    lineHeight: 1.46666667,
    overflowY: "auto",
    padding: "3vh 64px 32px 64px",
    borderRight: ".25rem solid transparent",
    borderRadius: 0,
    minHeight: "100%",
    minWidth: "100%",
    maxWidth: "100%",
  },
  closeButton: {
    width: 64,
    borderRadius: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    zIndex: 10,
    position: "absolute",
    marginLeft: 16,
    top: 5,
    right: 5,
    "&:hover": {
      color: Colors.black,
    },
  },
  labelIconButton: {
    flexDirection: "column",
  },
  minDiv: {
    // width: 300,
    // minWidth: 100
  },
  accent: {
    color: "#6198DE",
  },
  commentDiv: {
    width: "100%",
    borderRadius: 8,
    border: "solid",
    borderWidth: 1,
    borderColor: Colors.lightGrey,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    marginBottom: 15,
  },
  date: {
    textAlign: "right",
    fontSize: 12,
  },
  commentMainDiv: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    flexDirection: "row",
  },
  actionsButtonDiv: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  deleteButton: {
    padding: 1,
  },
  editButton: {
    padding: 1,
    marginRight: -3,
  },
  commentBottom: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    display: "flex",
  },
  leftDiv: {
    flex: 2,
    flexWrap: "wrap",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  rightDiv: {
    flex: 3,
    height: 400,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  mainDiv: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  addOptionButton: {
    marginTop: 10,
  },
  saveButton: {
    marginTop: 35,
  },
  answerSeparatorText: {
    color: Colors.blackGrey,
    fontWeight: "bold",
    whiteSpace: "nowrap",
    marginLeft: 10,
    marginRight: 10,
  },
  separator: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  line: {
    width: "100%",
    marginBottom: 25,
    borderRadius: 8,
    height: 2,
    backgroundColor: Colors.blackGrey,
  },
  errorText: {
    color: "#e6525a",
    padding: "13px 15px",
    borderRadius: 6,
    backgroundColor: "rgba(230,82,90,0.15)",
  },
  bodyDiv: {
    maxWidth: "100%",
    width: 960,
  },
  exitModalText: {
    marginTop: -4,
    fontSize: 13,
  },
  title: {
    fontFamily: "Montserrat",
    // color: Colors.black,
    textAlign: "center",
    maxWidth: "100%",
    width: 960,
    marginBottom: 25,
    fontSize: 28,
    lineHeight: 1.2143,
    fontWeight: 700,
  },
  subTitle: {
    textAlign: "center",
    maxWidth: "100%",
    width: 960,
    // color: Colors.grey,
    marginBottom: 25,
    fontSize: 18,
    // lineHeight: 1.2143
    // fontWeight: 700
  },
  inviteDivButton: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  inviteButton: {},
  AddFieldDiv: {
    marginTop: 15,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  addFieldText: {
    color: Colors.main,
    marginLeft: 5,
  },
  addButton: {
    right: 0,
    color: "#fff",
    cursor: "pointer",
    zIndex: 10,
    padding: 4,
    backgroundColor: Colors.main,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  codeDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    marginBottom: 20,
  },
  codeRightDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  codeExplanation: {},
  code: {
    fontSize: 20,
    // backgroundColor: Colors.mediumGrey,
    backgroundColor: Colors.lightGrey,
    borderRadius: 4,
    padding: "5px 10px",
  },
  copyButton: {
    color: "#3A4554",
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 10,
    borderRadius: 4,
    fontSize: 20,
    backgroundColor: Colors.lightGrey,
    padding: "5px 10px",
  },
  copyText: {
    marginLeft: 3,
  },
});

class InviteUsersModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emails: [{ email: "", role: "user" }],
      error: "",
    };
    this.baseState = this.state;
  }

  _handleKeyDown = event => {
    if (
      (window.navigator.platform.match("Mac")
        ? event.metaKey
        : event.ctrlKey) &&
      event.keyCode === 83
    ) {
      event.preventDefault();
    }
  };

  formatDate = date => {
    return moment(date).format("L");
  };

  onRendered = () => {
    // reset state and props
    this.setState(this.baseState);
    // document.addEventListener("keydown", this._handleKeyDown, false);
  };

  handleClose = onClose => () => {
    // document.removeEventListener("keydown", this._handleKeyDown, false);
    onClose();
  };

  createFromScratch = () => {};

  onClickGoal = goalSelected => () => {
    this.setState({ goalSelected, ...goalSelected, step: 1 });
  };

  onChangeState = key => event => {
    const value = event.target.value;
    const newState = { ...this.state[key] };
    newState[this.props.language] = value;

    this.setState({ [key]: newState });
  };

  onClickSubmit = () => {
    const {
      clientId,
      userId,
      firstName,
      lastName,
      accessCode,
      language,
    } = this.props;
    const { emails } = this.state;

    this.props.sendEmailInvitationsRequest({
      clientId,
      userId,
      accessCode,
      firstName,
      lastName,
      emails,
      language,
    });
  };

  onChangeEmail = fieldNumber => event => {
    const value = event.target.value;
    const emails = [...this.state.emails];
    emails[fieldNumber].email = value;
    this.setState({ emails });
  };

  onClickDelete = fieldNumber => () => {
    const emails = this.state.emails.filter((el, i) => i !== fieldNumber);
    this.setState({ emails });
  };

  addField = () => {
    const emails = [...this.state.emails, { email: "", role: "user" }];
    this.setState({ emails });
  };

  onChangeRole = fieldNumber => event => {
    const value = event.target.value;
    const emails = [...this.state.emails];
    emails[fieldNumber].role = value;
    this.setState({ emails });
  };

  isDisabled = () => {
    const hasNotEmail = this.state.emails.filter(el => el.email !== "");
    return hasNotEmail.length === 0;
  };

  getForbiddenRoles = () => {
    const { role } = this.props;
    if (role === "contributor") {
      return ["admin", "owner"];
    } else if (role === "user" || role === "observer") {
      return ["admin", "owner", "contributor"];
    }
    return [];
  };

  displayInputs = () => {
    const { language } = this.props;
    return this.state.emails.map((el, i) => {
      return (
        <InviteEmailUserInput
          language={language}
          forbiddenRoles={this.getForbiddenRoles()}
          key={i}
          emailValue={el.email}
          roleValue={el.role}
          onChangeEmail={this.onChangeEmail(i)}
          onChangeRole={this.onChangeRole(i)}
          emailLabel={i18n.t("email")}
          roleLabel={i18n.t("userRole")}
          onClickDelete={
            this.state.emails.length > 0 ? this.onClickDelete(i) : null
          }
        />
      );
    });
  };

  render() {
    const { classes, isVisible, onClose, accessCode } = this.props;
    return (
      <Dialog
        open={isVisible}
        ref={this.onRendered}
        onClose={this.handleClose(onClose)}
        classes={{ paper: classes.paper }}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        <IconButton
          className={classes.closeButton}
          classes={{ label: classes.labelIconButton }}
          onClick={onClose}
          size="large"
        >
          <CloseIcon fontSize="large" />
          <Typography className={classes.exitModalText}>échap</Typography>
        </IconButton>
        <Typography className={classes.title}>
          {i18n.t("invite-users")}
        </Typography>
        <DialogContent className={classes.bodyDiv}>
          <div className={classes.line} />
          <Typography className={classes.subTitle}>
            {i18n.t("explanation-code")}
          </Typography>
          <div className={classes.codeDiv}>
            <AccessCodeLottie />
            <CopyAccessCode accessCode={accessCode} />
          </div>
          <Typography className={classes.title}>
            {i18n.t("invite-mail")}
          </Typography>
          <Typography className={classes.subTitle}>
            {i18n.t("explanation-invite-mail")}
          </Typography>
          {this.displayInputs()}
          <div className={classes.AddFieldDiv} onClick={this.addField}>
            <div className={classes.addButton}>
              <AddIcon />
            </div>
            <Typography className={classes.addFieldText}>
              {i18n.t("add-email-field")}
            </Typography>
          </div>
          <div className={classes.inviteDivButton}>
            {this.props.requesting ? (
              <CircularProgress size={50} />
            ) : (
              <FlatButton
                title={i18n.t("send-invitations")}
                onClick={this.onClickSubmit}
                className={classes.inviteButton}
                disabled={this.isDisabled()}
              />
            )}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  const { requesting } = state.auth;
  const { role } = state.user;
  const language = getAppLanguage(state);
  const accessCode = getAccessCode(state);
  const userId = getUserId(state);
  const clientId = getSelectedClientId(state);
  const { firstName, lastName } = state.user;
  const { uploading, picture } = state.upload;
  return {
    role,
    clientId,
    userId,
    firstName,
    lastName,
    requesting,
    language,
    pictureUploading: uploading,
    accessCode,
    picture,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    sendEmailInvitationsRequest,
    openSnackMessage,
  })(InviteUsersModal),
);
