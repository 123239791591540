import React from "react";
import withStyles from "@mui/styles/withStyles";
import ArchiveIcon from "./ArchiveIcon";
import IconButton from "@mui/material/IconButton";
import i18n from "../../i18n";
import Colors from "constants/Colors";
import CustomArrowTooltip from "components/CustomArrowTooltip";

const styles = theme => ({
  main: {
    width: 44,
    height: 44,
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: Colors.lightGrey,
    },
  },
});

const ArchiveComponent = ({
  classes,
  settingsDisabled,
  onClick,
  isDone,
  style,
}) => {
  return (
    <CustomArrowTooltip
      title={
        isDone ? i18n.t("archive-toolTip-off") : i18n.t("archive-toolTip-on")
      }
    >
      <IconButton
        className={classes.main}
        style={style}
        disabled={settingsDisabled}
        onClick={onClick}
        size="large"
      >
        <ArchiveIcon sizePercentage={1.4} />
      </IconButton>
    </CustomArrowTooltip>
  );
};

export default withStyles(styles)(ArchiveComponent);
