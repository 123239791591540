import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Link } from "react-router-dom";

import { changeField } from "../../services/signup/actions";

import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import withStyles from '@mui/styles/withStyles';

import Colors from "../../constants/Colors";
import i18n from "../../i18n";
import SnackBar from "./Snackbar";
import CreateUserForm from "./CreateUserForm";
import SignupValidateEmail from "./SignupValidateEmail";
import CreateClientForm from "./CreateClientForm";
import FullScreenLoading from "../../components/FullScreenLoading";
import LogoDiv from "scenes/Signup/LogoDiv";

const styles = theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "100vh",
    width: "100vw",
    paddingBottom: 50,
    backgroundColor: Colors.darkBlue,
  },
  logo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: 75,
    // paddingTop: 0,
    margin: "20px auto 20px",
  },
  paper: {
    marginRight: 20,
    marginLeft: 20,
    maxWidth: 470,
    display: "flex",
    borderRadius: 3,
    background: "white",
    padding: 40,
    paddingBottom: 20,
    flexDirection: "column",
    alignItems: "center",
    // marginBottom: 20
  },
  title: {
    lineHeight: 1.35,
    fontFamily: "Montserrat",
    textAlign: "center",
    fontWeight: "normal",
    color: Colors.darkBlue,
    margin: 0,
    marginTop: 0,
    fontSize: 18,
    textTransform: "none",
    letterSpacing: 0,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE11 issue.
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  loginError: {
    backgroundColor: "#FCE6E7",
    borderRadius: 8,
  },
  errorText: {
    color: "#e6525a",
    padding: "13px 15px",
    borderRadius: 6,
    backgroundColor: "rgba(230,82,90,0.15)",
  },
  textField: {
    marginTop: 20,
  },
  submitButton: {
    marginTop: 20,
    padding: 12,
    backgroundColor: "#15CD72",
    "&:hover": {
      backgroundColor: "#15CD72",
    },
  },
  noDecoration: {
    textDecoration: "none",
  },
  forgotPassword: {
    marginTop: 20,
    cursor: "pointer",
    textDecoration: "none",
    color: Colors.pureMain,
    fontSize: 17,
    lineHeight: 1.5,
    textAlign: "center",
  },
  newAccountText: {
    color: Colors.white,
    fontSize: 16,
    textAlign: "center",
  },
  bold: {
    marginLeft: 5,
    color: Colors.white,
    fontSize: 16,
    cursor: "pointer",
    fontWeight: "bold",
    textDecoration: "underline",
  },
  noAccountDiv: {
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: Colors.darkBlue,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  topField: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  splitTextField: {
    width: "48.5%",
  },
  agreeTerms: {
    fontSize: 13,
    marginTop: 22,
    marginBottom: 10,
  },
  link: {
    textDecoration: "none",
    color: Colors.pureMain,
    fontWeight: "bold",
  },
  stepDiv: {
    marginBottom: 25,
    marginTop: 30,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  step: {
    borderRadius: 30,
    height: 4,
    width: 40,
    marginLeft: 5,
    marginRight: 5,
    backgroundColor: Colors.lightMain,
  },
  stepActive: {
    backgroundColor: Colors.pureMain,
  },
});

class Signup extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isAnimationVisible: false,
    };
  }

  displayStepForm = () => {
    if (this.props.step === 0) {
      return <CreateUserForm />;
    } else if (this.props.step === 1) {
      return <CreateClientForm />;
    }
    return <SignupValidateEmail />;
  };

  displayTitleForm = () => {
    const { classes } = this.props;
    if (this.props.step === 0) {
      return (
        <>
          <Typography className={classes.title} style={{ fontWeight: "bold" }}>
            {i18n.t("welcome-1")}
          </Typography>
          <Typography className={classes.title}>
            {i18n.t("welcome-2")}
          </Typography>
          <Typography className={classes.title}>
            {i18n.t("welcome-3")}
          </Typography>
        </>
      );
    } else if (this.props.step === 1) {
      return (
        <Typography className={classes.title} style={{ fontWeight: "bold" }}>
          {i18n.t("more-details")}
        </Typography>
      );
    }
    return (
      <Typography className={classes.title} style={{ fontWeight: "bold" }}>
        {i18n.t("verify-your-email")}
      </Typography>
    );
  };

  componentDidUpdate = prevProps => {
    if (prevProps.loading !== this.props.loading && this.props.loading) {
      this.setState({ isAnimationVisible: true });
      setTimeout(() => {
        this.setState({ isAnimationVisible: false });
        this.props.push("/campaigns?product_tour_id=77919");
      }, 2000);
    }
  };

  render() {
    const { classes, step } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <SnackBar />
        {this.state.isAnimationVisible && (
          <FullScreenLoading title={i18n.t("loading-session")} />
        )}
        <div className={classes.main}>
          <LogoDiv />
          <div className={classes.paper}>
            <Typography className={classes.title}>
              {this.displayTitleForm()}
            </Typography>
            <div className={classes.stepDiv}>
              <div
                className={`${classes.step} ${
                  step === 0 && classes.stepActive
                }`}
              />
              <div
                className={`${classes.step} ${
                  step === 1 && classes.stepActive
                }`}
              />
              <div
                className={`${classes.step} ${
                  step === 2 && classes.stepActive
                }`}
              />
            </div>
            {this.displayStepForm()}
          </div>
          <div className={classes.noAccountDiv}>
            <Typography className={classes.newAccountText}>
              {i18n.t("got-an-account")}
            </Typography>
            <Link to="/login" className={classes.newAccountText}>
              <Typography className={classes.bold}>
                {i18n.t("login-now")}
              </Typography>
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Signup.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const {
    step,
    email,
    error,
    requesting,
    password,
    phoneNumber,
    firstName,
    lastName,
    loading,
  } = state.signup;
  const { isVerified } = state.user;
  return {
    userId: state.user._id,
    isVerified,
    email,
    step,
    phoneNumber,
    password,
    firstName,
    lastName,
    requesting,
    loading,
    error,
  };
};

export default connect(mapStateToProps, { changeField, push })(
  withStyles(styles)(Signup),
);
