import { createSelector } from "reselect";
import { getCoachResources } from "services/resource/selectors";
import moment from "moment";

export const getCoachingRequesting = state => state.coaching.requesting;

export const getCoachingNotes = state => state.coaching.notes;

export const getCoachingFiles = state => state.coaching.files;

export const getSelectedNote = state => state.coaching.selectedNote;

export const getCoachingSession = state => state.coaching.coachingSession;

export const getCoachingSessionId = createSelector(
  getCoachingSession,
  coachingSession => coachingSession._id,
);

export const getHasCoachingSession = createSelector(
  getCoachingSessionId,
  coachingSessionId => !!coachingSessionId,
);

export const getSessions = createSelector(
  getCoachingSession,
  coachingSession => coachingSession.sessions,
);

export const getCoachingGoals = createSelector(
  getCoachingSession,
  coachingSession => coachingSession.goals,
);

export const getCoachingResources = createSelector(
  getCoachingSession,
  coachingSession => coachingSession.resources || [],
);

export const getCoachingResourcesWithDetails = createSelector(
  getCoachingResources,
  getCoachResources,
  (coachingResources, resources) => {
    return coachingResources.map(sessionResource => {
      return {
        ...sessionResource,
        resource: resources.find(el => el._id === sessionResource.resourceId),
      };
    });
  },
);

export const getCoachingDurationInMonths = createSelector(
  getSessions,
  sessions => {
    if (!sessions.length) return 1;

    const startingDate = sessions[0].nextDate;

    const endDate = sessions[sessions.length - 1].nextDate;

    const durationInMonths =
      moment(endDate).diff(moment(startingDate), "months") + 1;

    return durationInMonths;
  },
);

export const getTotalDoneSessions = createSelector(getSessions, sessions => {
  if (!sessions.length) return 1;

  const total = sessions.reduce((prev, curr) => {
    return prev + !!curr.isDone;
  }, 0);
  return total;
});

export const getTotalDoneActions = createSelector(
  getCoachingSession,
  coachingSession => coachingSession.totalDone,
);

export const getGoalsResult = createSelector(
  getCoachingGoals,
  coachingGoals => {
    const totalGoals = coachingGoals.length;
    const totalFullDone = coachingGoals.reduce((prev, curr) => {
      const isFullyDone = curr.doneSteps.length >= curr.goalNumber;

      return prev + isFullyDone;
    }, 0);

    return `${totalFullDone} / ${totalGoals}`;
  },
);

export const getIsCoachingFinished = createSelector(
  getCoachingSession,
  coachingSession => coachingSession.isFinished || false,
);

export const getCoachingFinishedDate = createSelector(
  getCoachingSession,
  coachingSession => coachingSession.finishedDate,
);

export const getEndCoachingMessage = createSelector(
  getCoachingSession,
  coachingSession => coachingSession.endCoachingMessage || "",
);

export const getIsCoachingSessionForbidden = createSelector(
  getCoachingSession,
  coachingSession => coachingSession.isForbidden,
);
