import React from "react";

const ParticipantIcon = ({ sizePercentage, color, className }) => (
  <svg
    className={className}
    width={sizePercentage * 30}
    height={sizePercentage * 23}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 30 23"
    version="1.1"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1365 22.3899C13.1365 22.3899 11.2724 22.3899 11.2724 20.5258C11.2724 18.6617 13.1365 13.0694 20.5929 13.0694C28.0493 13.0694 29.9134 18.6617 29.9134 20.5258C29.9134 22.3899 28.0493 22.3899 28.0493 22.3899H13.1365ZM20.5929 11.2053C22.0761 11.2053 23.4985 10.6161 24.5473 9.56737C25.596 8.51861 26.1852 7.0962 26.1852 5.61303C26.1852 4.12987 25.596 2.70745 24.5473 1.65869C23.4985 0.609937 22.0761 0.020752 20.5929 0.020752C19.1097 0.020752 17.6873 0.609937 16.6386 1.65869C15.5898 2.70745 15.0006 4.12987 15.0006 5.61303C15.0006 7.0962 15.5898 8.51861 16.6386 9.56737C17.6873 10.6161 19.1097 11.2053 20.5929 11.2053ZM9.811 22.3899C9.53466 21.8079 9.39684 21.1699 9.40836 20.5258C9.40836 17.9999 10.6759 15.3995 13.0172 13.5913C11.8486 13.2313 10.6311 13.0552 9.40836 13.0694C1.95198 13.0694 0.0878906 18.6617 0.0878906 20.5258C0.0878906 22.3899 1.95198 22.3899 1.95198 22.3899H9.811ZM8.47631 11.2053C9.71228 11.2053 10.8976 10.7143 11.7716 9.84036C12.6456 8.9664 13.1365 7.78105 13.1365 6.54508C13.1365 5.30911 12.6456 4.12376 11.7716 3.2498C10.8976 2.37583 9.71228 1.88485 8.47631 1.88485C7.24034 1.88485 6.05499 2.37583 5.18103 3.2498C4.30706 4.12376 3.81608 5.30911 3.81608 6.54508C3.81608 7.78105 4.30706 8.9664 5.18103 9.84036C6.05499 10.7143 7.24034 11.2053 8.47631 11.2053Z"
      fill={color}
    />
  </svg>
);

ParticipantIcon.defaultProps = {
  sizePercentage: 1,
  color: "#44BFFF",
  className: "",
};

export default ParticipantIcon;
