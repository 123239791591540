import { TOKEN_SET, TOKEN_UNSET } from "./constants";
import { AUTH_LOGOUT_SUCCESS, AUTH_SUCCESS } from "../auth/constants";

const initialState = {
  accessToken: null,
  expiresIn: null,
  expiresAt: null,
  refreshToken: null,
  tokenType: null,
};

const reducer = function tokenReducer(state = initialState, action) {
  switch (action.type) {
    case TOKEN_SET:
      return action.token;

    case AUTH_SUCCESS:
      return action.payload;

    case TOKEN_UNSET:
    case AUTH_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
