import React, { useState, useRef, useEffect } from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography } from "@mui/material";
import i18n from "i18n";
import ClickOutside from "components/ClickOutside";
import Colors from "constants/Colors";
import MoreIcon from "scenes/Campaigns/MoreIcon";
import ToolTip from "components/ToolTip";

const styles = theme => ({
  wrapper: {
    minWidth: 100,
    width: "fit-content",
  },
  main: {
    width: "fit-content",
    marginTop: 10,
    marginLeft: 20,
  },
  addNote: {
    width: 200,
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    padding: "7px 10px",
    transition: "all 0.2s",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: Colors.lightGrey,
      opacity: 0.9,
    },
  },
  text: {
    marginLeft: 10,
    color: Colors.white,
    fontStyle: "underline",
  },
  input: {
    minWidth: 50,
    fontFamily: "Roboto",
    fontSize: 14,
    outline: "none",
    border: "none",
    color: "#fff",
    backgroundColor: Colors.pureMain,
    borderRadius: 25,
    lineHeight: 30,
    height: 30,
    margin: "0px 6px 6px 0px",
    padding: "2px 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&::placeholder": {
      fontWeight: "normal",
      color: Colors.lightWhite,
    },
  },
  labelDiv: {
    cursor: "pointer",
    width: "fit-content",
    backgroundColor: Colors.pureMain,
    borderRadius: 25,
    margin: "0px 6px 6px 0px",
    padding: "2px 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      opacity: 0.9,
    },
  },
  label: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 1 /* number of lines to show */,
    lineHeight: "1.5em" /* fallback */,
    maxHeight: "1.5em",
    wordBreak: "break-all",
    fontSize: 14,
    color: "white",
    flex: 1,
  },
});

const AddLabel = ({ classes, onChange, value }) => {
  const [isLabelVisible, openLabel] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    if (isLabelVisible) {
      inputRef.current.focus();
    }
  }, [isLabelVisible]);

  const onClickOpen = () => {
    openLabel(true);
  };

  const onClickOutside = () => {
    if (isLabelVisible) {
      openLabel(false);
    }
  };

  const onClickLabel = () => {
    openLabel(true);
  };

  return (
    <ClickOutside onClickOutside={onClickOutside} className={classes.wrapper}>
      <ToolTip content={i18n.t("label-info")}>
        <div className={classes.main}>
          {!value && !isLabelVisible && (
            <div className={classes.addNote} onClick={onClickOpen}>
              <MoreIcon color={Colors.main} sizePercentage={0.7} />
              <Typography className={classes.text}>
                {i18n.t("add-label")}
              </Typography>
            </div>
          )}
          {isLabelVisible && (
            <input
              className={classes.input}
              ref={inputRef}
              placeholder={i18n.t("campaign-label")}
              value={value}
              onChange={onChange}
            />
          )}
          {value && !isLabelVisible && (
            <div className={`${classes.labelDiv}`} onClick={onClickLabel}>
              <Typography className={`${classes.label}`}>{value}</Typography>
            </div>
          )}
        </div>
      </ToolTip>
    </ClickOutside>
  );
};

export default withStyles(styles)(AddLabel);
