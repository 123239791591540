import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import { Typography } from "@mui/material";
import Colors from "../../constants/Colors";
import LightIcon from "scenes/CreateTrack/LightIcon";
import i18n from "i18n";
import FilterList, { IFilter } from "components/FilterList";
import { useSelector } from "react-redux";
import { getUserCampaigns } from "services/campaign/selectors";
import { displayNumberOfActionDone } from "utils/doneStepsByUser";

const FILTER_1 = "ranking-filter-1";
const FILTER_2 = "ranking-filter-2";
const FILTER_3 = "ranking-filter-3";

const FILTERS: IFilter[] = [
  { label: FILTER_1, color: null },
  { label: FILTER_2, color: null },
  { label: FILTER_3, color: null },
];

const useStyle = makeStyles({
  root: {
    boxSizing: "border-box",
    minHeight: 150,
    overflow: "hidden",
    paddingBottom: 15,
    borderRadius: 10,
    backgroundColor: "#fff",
    width: "100%",
    transition: "all 0.2s cubic-bezier(0.41, 0.094, 0.54, 0.07) 0s",
  },
  title: {
    marginLeft: 45,
    color: Colors.blackGrey,
    fontSize: 20,
    fontFamily: "Montserrat",
    fontWeight: "bold",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  rightContainer: {
    display: "flex",
    marginLeft: 20,
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-end",
  },
  points: {
    fontSize: 24,
    marginRight: 14,
    fontFamily: "Montserrat",
    color: Colors.darkBlue,
    fontWeight: "bold",
  },
  picture: {
    marginRight: 15,
    width: 50,
    height: 50,
    objectFit: "cover",
  },
  topBar: {
    boxSizing: "border-box",
    paddingRight: 45,
    height: 90,
    borderBottom: `solid 1px ${Colors.lightGrey}`,
    borderRadius: 10,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    background: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  row: {
    boxSizing: "border-box",
    paddingTop: 15,
    paddingBottom: 15,
    cursor: "pointer",
    // height: 90,
    width: "100%",
    paddingLeft: 45,
    paddingRight: 45,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    transition: "all 0.2s",
    "&:hover": {
      opacity: 0.9,
      backgroundColor: "#ECF6FF",
    },
  },
  number: {
    fontSize: 16,
    color: "#BABCC5",
    marginRight: 30,
  },
  name: {
    fontSize: 16,
    color: Colors.darkBlue,
    fontFamily: "Montserrat",
    fontWeight: "bold",
  },
  description: {
    fontSize: 14,
    color: "#BABCC5",
  },
  teamPictureDiv: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 50,
    width: 60,
    marginRight: 10,
  },
  smallPictureLeft: {
    zIndex: 1,
    objectFit: "cover",
    width: 30,
    height: 30,
    position: "absolute",
    left: 0,
  },
  smallPictureRight: {
    zIndex: 2,
    objectFit: "cover",
    width: 30,
    height: 30,
    position: "absolute",
    right: 0,
  },
  smallPictureTop: {
    zIndex: 3,
    border: "solid 1px #fff",
    objectFit: "cover",
    width: 30,
    height: 30,
    position: "absolute",
    top: 0,
  },
  smallPictureBottom: {
    border: "solid 1px #fff",
    zIndex: 4,
    objectFit: "cover",
    width: 30,
    height: 30,
    position: "absolute",
    bottom: 0,
  },
  moreUsersPicture: {
    backgroundColor: Colors.darkBlue,
    borderRadius: 15,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: Colors.white,
  },
  textDiv: { flex: 1 },
});

const RankingComponent = ({
  onClickUser = ([]) => {},
  title = "",
  ranking = [],
  hasPicture,
  isTeam = false,
}) => {
  const [filterSelected, setFilter] = useState<IFilter>(FILTERS[0]);
  const userCampaign = useSelector(getUserCampaigns);
  const classes = useStyle();

  const selectFilter = (filter: IFilter) => {
    setFilter(filter);
  };

  const getFilteredRanking = (ranking, filterSelected) => {
    if (filterSelected === FILTER_1) return ranking.slice(0, 3);
    if (filterSelected === FILTER_2) return ranking.slice(0, 10);
    return ranking;
  };

  const displayTeamMembersNames = ranking => {
    const { users } = ranking;
    return users.reduce((prev, curr, index) => {
      if (curr.name) {
        const firstName = curr.name.split(" ")[0];
        // for the first one
        if (index === 0) return firstName;
        prev = `${prev}, ${firstName}`;
      }
      return prev;
    }, "");
  };

  const displayTeamPicture = ranking => {
    const { users } = ranking;
    if (users.length === 1)
      return (
        <Avatar
          className={classes.picture}
          alt={users[0].name}
          src={users[0].picture}
        />
      );

    if (users.length === 2)
      return (
        <div className={classes.teamPictureDiv}>
          <Avatar
            className={classes.smallPictureLeft}
            alt={users[0].name}
            src={users[0].picture}
          />
          <Avatar
            className={classes.smallPictureRight}
            alt={users[1].name}
            src={users[1].picture}
          />
        </div>
      );

    if (users.length === 3)
      return (
        <div className={classes.teamPictureDiv}>
          <Avatar
            className={classes.smallPictureLeft}
            alt={users[0].name}
            src={users[0].picture}
          />
          <Avatar
            className={classes.smallPictureTop}
            alt={users[1].name}
            src={users[1].picture}
          />
          <Avatar
            className={classes.smallPictureRight}
            alt={users[2].name}
            src={users[2].picture}
          />
        </div>
      );

    if (users.length === 4)
      return (
        <div className={classes.teamPictureDiv}>
          <Avatar
            className={classes.smallPictureLeft}
            alt={users[0].name}
            src={users[0].picture}
          />
          <Avatar
            className={classes.smallPictureTop}
            alt={users[1].name}
            src={users[1].picture}
          />
          <Avatar
            className={classes.smallPictureBottom}
            alt={users[2].name}
            src={users[2].picture}
          />
          <Avatar
            className={classes.smallPictureRight}
            alt={users[3].name}
            src={users[3].picture}
          />
        </div>
      );
    if (users.length > 4) {
      return (
        <div className={classes.teamPictureDiv}>
          <Avatar
            className={classes.smallPictureLeft}
            alt={users[0].name}
            src={users[0].picture}
          />
          <Avatar
            className={classes.smallPictureTop}
            alt={users[1].name}
            src={users[1].picture}
          />
          <Avatar
            className={classes.smallPictureRight}
            alt={users[2].name}
            src={users[2].picture}
          />
          <div
            className={[
              classes.smallPictureBottom,
              classes.moreUsersPicture,
            ].join(" ")}
          >
            <Typography>{"+" + (users.length - 3).toString()}</Typography>
          </div>
        </div>
      );
    }
  };

  const displayActionDone = rankingInfo => {
    let users = [];

    if (userCampaign.length === 0 || rankingInfo.length === 0) {
      return 0;
    }

    if (rankingInfo.users) {
      users = rankingInfo.users.map(user => {
        return userCampaign.filter(
          userCampaign => userCampaign.userId === user.user,
        )[0];
      });
    } else {
      users = userCampaign.filter(user => user.userId === rankingInfo._id);
    }

    const numberOfActionDone = users.reduce(
      (prev, curr) => prev + displayNumberOfActionDone(curr.steps),
      0,
    );

    return numberOfActionDone;
  };

  return (
    <div className={classes.root}>
      <div className={classes.topBar}>
        <Typography className={classes.title}>{title}</Typography>
        {ranking.length > 0 && (
          <FilterList
            filterSelected={filterSelected}
            filters={FILTERS}
            selectFilter={selectFilter}
          />
        )}
      </div>
      {getFilteredRanking(ranking, filterSelected.label).map((value, index) => {
        return (
          <div
            onClick={() => onClickUser(value)}
            className={classes.row}
            key={index}
          >
            <div className={classes.leftContainer}>
              <Typography className={classes.number}>{index + 1}</Typography>
              {isTeam ? (
                displayTeamPicture(value)
              ) : (
                <Avatar
                  className={classes.picture}
                  alt={value.name || `${value.firstName} ${value.lastName}`}
                  src={hasPicture ? value.picture : ""}
                >
                  {!hasPicture && value.name[0]}
                </Avatar>
              )}
              <div className={classes.textDiv}>
                <Typography className={classes.name}>
                  {isTeam
                    ? i18n.t("Team", { teamName: value.name })
                    : `${value.firstName} ${value.lastName}`}
                </Typography>
                <Typography className={classes.description}>
                  {!isTeam && value.description}
                </Typography>
              </div>
            </div>
            <div className={classes.rightContainer}>
              <Typography className={classes.points}>
                {isTeam ? value.points : displayActionDone(value)}
              </Typography>
              <LightIcon color={"#F9A849"} sizePercentage={1.1} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RankingComponent;
