import React from "react";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Colors from "constants/Colors";

const useStyles = makeStyles(theme => ({
  link: {
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    textDecoration: "none",
    width: "100%",
    textAlign: "center",
    color: Colors.pureMain,
    fontSize: 12,
    "&:hover": {
      textDecoration: "underline",
      transition: "all 0.2s ease",
    },
  },
}));

interface Props {
  href: string;
  text: string;
  className?: string;
}

const MyLink = ({ href, text, className }: Props) => {
  const classes = useStyles();

  return (
    <a className={`${classes.link} ${className}`} target="_blank" rel="noopener noreferrer" href={href}>
      <Typography>{text}</Typography>
    </a>
  );
};

export default MyLink;
