import React, { useCallback, useState } from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography } from "@mui/material";
import Colors from "constants/Colors";

import Toggle from "scenes/CreateTrack/CreateActionTextStep/PreviewSide/Toggle";
import PhonePreview from "scenes/CreateTrack/CreateActionTextStep/PreviewSide/PhonePreview";
import LivePreview from "scenes/CreateTrack/CreateActionTextStep/PreviewSide/LivePreview";

const styles = theme => ({
  main: {
    width: 0,
    opacity: 0,
    zIndex: -1,
    paddingTop: 30,
    height: "100%",
    // flex: 1.1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    display: "flex",
    transition: "all 0.3s ease",
  },
  hasBorder: {
    borderLeft: "solid 1px",
    borderColor: Colors.lightGrey,
  },
  topPreview: {
    alignItems: "center",
    justifyContent: "center",
    // paddingRight: 15,
    // paddingLeft: 15,
    width: "100%",
    height: 60,
    marginBottom: 25,
    display: "flex",
    borderBottom: "solid 1px #EFF0F2",
  },
  previewSubtitle: {
    marginTop: 30,
    marginBottom: 30,
    width: "100%",
    color: "#A6AECE",
    fontSize: 13,
    textAlign: "center",
    paddingLeft: 15,
    paddingRight: 15,
  },
  topRightDiv: {
    paddingTop: 30,
    alignItems: "center",
    paddingRight: 30,
    paddingLeft: 40,
    width: "100%",
    height: 60,
    display: "flex",
  },
  topBarTitle: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 22,
    color: Colors.text,
  },
  menuButton: {
    position: "absolute",
    right: 10,
  },
  visible: {
    width: 375,
    opacity: 1,
    zIndex: 0,
  },
  title: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    // marginLeft: 30,
    marginBottom: 30,
    fontSize: 20,
    alignSelf: "center",
    textAlign: "left",
  },
  "@media (max-width: 1440px)": {
    visible: {
      width: 325,
    },
  },
});

const PreviewSide = ({
  classes,
  onClickConnectMobile = null,
  isPreviewVisible = true,
  phoneChildren,
  languageSelected = "",
  hasBorder = false,
  title = "",
  qrCodeParams = {
    route: "Home",
  },
}) => {
  const [toggleVisible, setToggle] = useState(0);
  const onClickFirstBox = useCallback(() => setToggle(0), [setToggle]);
  const onClickSecondBox = useCallback(() => setToggle(1), [setToggle]);

  return (
    <div
      className={`${classes.main} ${isPreviewVisible && classes.visible} ${
        hasBorder && classes.hasBorder
      }`}
    >
      {!!title && <Typography className={classes.title}>{title}</Typography>}
      <Toggle
        toggleVisible={toggleVisible}
        onClickFirstBox={onClickFirstBox}
        onClickSecondBox={onClickSecondBox}
      />

      {toggleVisible === 0 && (
        <PhonePreview languageSelected={languageSelected}>
          {phoneChildren ? phoneChildren : null}
        </PhonePreview>
      )}
      {toggleVisible === 1 && (
        <LivePreview
          onClickConnectMobile={onClickConnectMobile}
          qrCodeParams={qrCodeParams}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(React.memo(PreviewSide));
