import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Colors from "constants/Colors";

const SIZE = 30;

const useStyles = makeStyles(theme => ({
  main: {
    width: SIZE,
    height: SIZE,
    borderRadius: SIZE / 2,
    marginRight: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ${Colors.lightGrey}`,
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": { backgroundColor: Colors.hoverBlue },
  },
  selected: {
    borderColor: Colors.pureMain,
  },
}));

interface Props {
  isSelected: boolean;
  children: JSX.Element;
  onClick: () => void;
}

const LanguageSelection = ({ isSelected, children, onClick }: Props) => {
  const classes = useStyles();

  return (
    <div
      onClick={onClick}
      className={`${classes.main} ${isSelected && classes.selected}`}
    >
      {children}
    </div>
  );
};

export default LanguageSelection;
