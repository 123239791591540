import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import Colors from "../../constants/Colors";

const useStyles = makeStyles(theme => ({
  normal: {
    position: "relative",
    color: "rgba(255,255,255,.7)",
  },
  selected: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    color: Colors.white,
  },
  buttonSelected: {
    boxShadow: `inset 3px 0 0 0 ${Colors.pureMain}`,
  },
  button: {
    transition: "all 0.2s",
    "&:hover": {
      backgroundColor: Colors.veryLightGrey,
    },
  },
}));

const MenuTab = ({ currentRoute, route, path, icon, hasAccess, text }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    history.push(path || route);
    if (path === "campaigns" || route === "campaigns") {
    }
  };

  const isSelected = currentRoute === route;
  if (hasAccess) {
    return (
      <div>
        <ListItem
          onClick={handleClick}
          button
          className={classes.button}
          selected={isSelected}
          classes={{ selected: classes.buttonSelected }}
        >
          <ListItemIcon
            className={`${classes.normal} ${isSelected && classes.selected}`}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                className={`${classes.normal} ${
                  isSelected && classes.selected
                }`}
              >
                {text}
              </Typography>
            }
          />
        </ListItem>
      </div>
    );
  }
  return null;
};

export default MenuTab;
