import React from "react";
import withStyles from '@mui/styles/withStyles';
import Colors from "../constants/Colors";
import i18n from "../i18n";
import getUserRoles from "../utils/getUserRoles";
import { SelectOptionInput } from "components";

const styles = {
  main: {
    height: 55,
    position: "relative",
    width: "90%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  text: {
    marginTop: 20,
    color: Colors.white,
    fontSize: 13,
  },
  textField: {
    fontSize: 13,
  },
  addButton: {
    right: 0,
    cursor: "pointer",
    zIndex: 10,
    padding: 10,
    backgroundColor: Colors.green,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  removeButton: {
    padding: 7,
    backgroundColor: Colors.mediumGrey,
  },
  invisible: {
    opacity: 0,
    zIndex: -2,
  },
  roleExplanation: {
    padding: 0,
    marginLeft: 5,
    fontSize: 10,
    position: "relative",
  },
  menuItem: {},
};

const UserRoleInput = ({ classes, roleValue, onChangeRole }) => {
  const optionsRole = getUserRoles();

  const defaultOption = {
    value: "user",
    label: "Learner",
  };

  const onChangeValue = option => () => {
    onChangeRole({ target: { value: option.value } }); // pas ouf
  };

  return (
    <div className={classes.main}>
      <SelectOptionInput
        className={classes.menuItem}
        options={optionsRole}
        selectOption={onChangeValue}
        optionSelected={
          optionsRole.find(el => el.value === roleValue) || defaultOption
        }
      />
    </div>
  );
};

UserRoleInput.defaultProps = {
  emailLabel: i18n.t("email"),
  roleLabel: i18n.t("userRole"),
};

export default withStyles(styles)(UserRoleInput);
