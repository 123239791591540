import React from "react";

const AwardIcon = ({ width = 24, height = 24, color, className = "" }) => (
  <svg
    className={className}
    width={width}
    height={height}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
  >
    <path
      d="M17 8.5a5 5 0 1 1-10 0 5 5 0 0 1 10 0Z"
      fill={color}
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4.15a4.35 4.35 0 1 0 0 8.7 4.35 4.35 0 0 0 0-8.7ZM6.35 8.5a5.65 5.65 0 1 1 11.3 0 5.65 5.65 0 0 1-11.3 0Z"
      fill={color}
    />
    <path d="M8.968 12.509 8 20.5l4-2.632 4 2.632-.968-8" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.954 11.855a.65.65 0 0 1 .723.567l.968 8a.65.65 0 0 1-1.003.621L12 18.647l-3.643 2.396a.65.65 0 0 1-1.003-.621l.968-7.991a.65.65 0 1 1 1.29.156l-.799 6.6 2.83-1.861a.65.65 0 0 1 .714 0l2.829 1.86-.8-6.608a.65.65 0 0 1 .568-.723Z"
      fill={color}
    />
  </svg>
);

export default AwardIcon;
