import React from "react";

const Logo = ({ sizePercentage, className = "", color = "#fff" }) => (
  <svg
    className={className}
    width={sizePercentage * 20}
    height={sizePercentage * 20}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 20 20"
    version="1.1"
  >
    <g
      id="Dashboard"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Recherche-rapide-contenu"
        transform="translate(-34.000000, -388.000000)"
        fill={color}
        fillRule="nonzero"
      >
        <g id="add-(1)" transform="translate(34.000000, 388.000000)">
          <path
            d="M18.9092276,8.60219512 L11.3920732,8.60219512 L11.3920732,1.085 C11.3920732,0.742560976 10.9270732,4.06504065e-05 9.99711382,4.06504065e-05 C9.06715447,4.06504065e-05 8.60215447,0.742601626 8.60215447,1.085 L8.60215447,8.60223577 L1.08495935,8.60223577 C0.742560976,8.60219512 0,9.06719512 0,9.99711382 C0,10.9270325 0.742560976,11.3920732 1.08495935,11.3920732 L8.60219512,11.3920732 L8.60219512,18.9093089 C8.60219512,19.2516667 9.06715447,19.9942683 9.99715447,19.9942683 C10.9271545,19.9942683 11.3921138,19.2516667 11.3921138,18.9093089 L11.3921138,11.3920732 L18.9093496,11.3920732 C19.2517073,11.3920732 19.9943089,10.9271138 19.9943089,9.99711382 C19.9943089,9.06711382 19.251626,8.60219512 18.9092276,8.60219512 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

Logo.defaultProps = {
  sizePercentage: 1,
};

export default Logo;
