import React from "react";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Colors from "../constants/Colors";

const IconButton = withStyles({
  root: {
    padding: "10px 12px 10px 12px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row",
    // height: "36px",
    boxShadow: "none",
    fontWeight: 550,
    textTransform: "none",
    fontSize: 12,
    minWidth: 200,
    // fontWeight: "bold",
    color: Colors.white,
    lineHeight: 1.5,
    opacity: 1,
    borderRadius: 4,
    borderWidth: 0,
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    borderColor: "rgba(255, 255, 255, 0.2)",
    fontFamily: [
      "Roboto",
      "-apple-system",
      "BlinkMacSystemFont",
      "Arial",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      borderColor: Colors.pureMain,
      backgroundColor: Colors.pureMain,
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      borderColor: Colors.pureMain,
      backgroundColor: Colors.pureMain,
    },
    "&:focus": {
      boxShadow: "none",
      // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)"
      // boxShadow: `0 0 0 0.2rem ${Colors.lightMain}`,
      // borderColor: Colors.pureMain,
      // backgroundColor: Colors.pureMain,
    },
  },
  disabled: {
    fontWeight: "normal",
    color: Colors.black,
  },
  outlined: {
    backgroundColor: "transparent",
    border: "1px solid",
    borderColor: "rgba(29, 28, 29, 0.3)",
  },
  label: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
})(Button);

export default function CustomizedButtons({
  title,
  onClick,
  disabled,
  className,
  fullWidth,
  requesting,
  outlined,
  classes,
  icon = null,
}) {
  return (
    <IconButton
      className={className}
      disabled={disabled}
      fullWidth={fullWidth}
      variant="contained"
      color="primary"
      disableRipple
      onClick={onClick}
      size="large"
    >
      <div
        style={{
          color: "inherit",
          marginRight: 15,
          width: 20,
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        {icon}
      </div>
      {title}
    </IconButton>
  );
}
