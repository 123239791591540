import React from "react";
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  main: {},
});

const PdfIcon = ({
  className = "",
  height = 41,
  width = 33,
  color = "#5FB8F9",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 41"
      fill="none"
      className={className}
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M0.0576172 4.05438C0.0576172 2.99352 0.479044 1.9761 1.22919 1.22596C1.97934 0.47581 2.99675 0.0543823 4.05762 0.0543823H20.0576C20.588 0.0544956 21.0966 0.265284 21.4716 0.640382L31.4716 10.6404C31.8467 11.0154 32.0575 11.524 32.0576 12.0544V36.0544C32.0576 37.1152 31.6362 38.1327 30.886 38.8828C30.1359 39.633 29.1185 40.0544 28.0576 40.0544H4.05762C2.99675 40.0544 1.97934 39.633 1.22919 38.8828C0.479044 38.1327 0.0576172 37.1152 0.0576172 36.0544V4.05438ZM27.2296 12.0544L20.0576 4.88238V12.0544H27.2296ZM16.0576 4.05438H4.05762V36.0544H28.0576V16.0544H18.0576C17.5272 16.0544 17.0185 15.8437 16.6434 15.4686C16.2683 15.0935 16.0576 14.5848 16.0576 14.0544V4.05438Z"
        fill={color}
      />
    </svg>
  );
};

export default withStyles(styles)(PdfIcon);
