import React, { PureComponent } from "react";
import { connect } from "react-redux";
import moment from "moment";

import withStyles from "@mui/styles/withStyles";

import Colors from "../../constants/Colors";
import { Typography } from "@mui/material";
import { sendMessage } from "../../services/realTime/sagas";
import { clearMessageNotifications } from "../../services/notification/actions";
import {
  getLatestConversationsRequest,
  getChatMessagesRequest,
  seeConversation,
  setConversation,
  openConversationRequest,
  setReceiver,
  getMessageClientNameRequest,
  cleanMessage,
} from "../../services/message/actions";
import i18n from "../../i18n";
import { getUserId, getSelectedClientId } from "../../services/user/selectors";
import { getAppLanguage } from "../../services/general/selectors";
import SendIcon from "./SendIcon";
import MessageList from "./MessageList";
import SearchUserBox from "./SearchUserBox";
import { getUserClientSelectedName } from "services/message/selectors";
import { Loader } from "components";
import { seeUserProfileRequest } from "services/manager/actions";
import { push } from "connected-react-router";
import {
  getCoachingSessionRequest,
  clearCoachingSession,
} from "services/coaching/actions";

moment.locale("fr");

const styles = theme => ({
  main: {
    height: "80vh",
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  leftSide: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    background: "white",
    width: 350,
    borderRadius: 8,
    border: "solid",
    borderWidth: 1,
    borderColor: "rgb(230, 236, 240)",
    height: "100%",
    flexDirection: "column",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  rightSide: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    flex: 1,
    borderRadius: 8,
    border: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderColor: "rgb(230, 236, 240)",
    background: "white",
    height: "100%",
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  title: {
    fontSize: 24,
    fontWeight: "550",
    color: Colors.black,
  },
  topBar: {
    minHeight: 61,
    height: 60,
    paddingLeft: 15,
    borderWidth: 0,
    borderBottomWidth: 1,
    width: "100%",
    border: "solid",
    borderColor: "rgb(230, 236, 240)",
    flexDirection: "column",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  userRow: {
    borderRight: "solid",
    borderRightColor: "white",
    cursor: "pointer",
    color: "black",
    borderBottom: "solid",
    borderBottomWidth: 1,
    borderBottomColor: "rgb(230, 236, 240)",
    padding: 10,
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    transition: "all .2s ease",
    "&:hover": {
      background: "#F5F8FA",
    },
  },
  topText: {
    display: "flex",
    flex: 1,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  name: {
    marginRight: 5,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 1 /* number of lines to show */,
    lineHeight: "1.5em" /* fallback */,
    maxHeight: "1.5em",
    wordBreak: "break-all",
    fontWeight: "500",
    color: Colors.black,
    fontSize: 16,
  },
  clientName: {
    marginRight: 5,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 1 /* number of lines to show */,
    lineHeight: "1.5em" /* fallback */,
    maxHeight: "1.5em",
    wordBreak: "break-all",
    color: Colors.black,
  },
  date: {
    color: "rgb(101, 119, 134)",
    fontSize: 16,
  },
  bottomText: {
    display: "flex",
    flex: 1,
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  smallAvatar: {
    objectFit: "cover",
    width: 40,
    height: 40,
    marginLeft: 5,
    marginRight: 15,
    borderRadius: "50%",
  },
  avatar: {
    objectFit: "cover",
    marginLeft: 5,
    marginRight: 15,
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  lastMessage: {
    marginRight: 5,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 1 /* number of lines to show */,
    lineHeight: "1.5em" /* fallback */,
    maxHeight: "1.5em",
    wordBreak: "break-all",
    fontWeight: "normal",
    textAlign: "left",
    fontSize: 16,
    color: "rgb(101, 119, 134)",
    flexWrap: "wrap",
  },
  notSeen: {
    marginRight: 10,
    width: 15,
    height: 15,
    backgroundColor: Colors.pink,
    borderRadius: 7.5,
  },
  isSelected: {
    borderRadius: 2,
    borderWidth: 5,
    borderRight: "solid",
    borderRightColor: "rgb(29, 161, 242)",
    background: "#F5F8FA",
  },
  middleView: {
    display: "flex",
    flex: 5,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  notSeenText: {
    fontWeight: "500",
    color: Colors.black,
  },
  rightTopSide: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  leftTopBar: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "rgb(230, 236, 240)",
    boxShadow: "0 1px 0px 0 rgba(0, 0, 0, .10)",
    display: "flex",
    height: 60,
    justifyContent: "flex-start",
    paddingLeft: 16,
    marginBottom: 1,
    cursor: "pointer",
  },
  rightTopBar: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "rgb(230, 236, 240)",
    boxShadow: "0 1px 0 0 rgba(0, 0, 0, .10)",
    display: "flex",
    height: 60,
    justifyContent: "flex-end",
    paddingRight: 16,
    marginBottom: 1,
  },
  messageDiv: {
    width: "100%",
    display: "flex",
    flex: 1,
    maxHeight: "100%",
    overflowX: "hidden",
    paddingTop: 20,
    height: "100%",
    backgroundColor: "white",
  },
  newMessageBar: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    borderTop: "solid",
    borderTopWidth: 1,
    borderTopColor: "rgb(230, 236, 240)",
  },
  messageBarInput: {
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
    lineHeight: 1,
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    color: "#565867",
    " -webkit-font-soothing": "antialiased",
    maxHeight: 200,
    overflow: "scroll",
    bottom: 0,
    overflowX: "hidden",
    overflowY: "auto",
    paddingLeft: 10,
    paddingTop: 7,
    paddingBottom: 7,
    paddingRight: 10,
    outline: "none",
    userSelect: "text",
    fontSize: 15,
    border: "solid",
    borderColor: "#fff",
    borderWidth: 1.5,
    width: "100%",
    backgroundColor: Colors.lightGrey,
    borderRadius: 20,
    marginRight: 10,
    transition: "all 0.2s",
    "&:empty:before": {
      content: "attr(placeholder)",
      display: "block" /* For Firefox */,
      color: "rgba(86, 88, 103, 0.3)",
      outline: "none",
    },
    "&:focus": {
      borderColor: Colors.pureMain,
    },
  },
  conversationsList: {
    width: "100%",
    overflow: "scroll",
    overflowX: "hidden",
  },
  searchBar: {
    paddingTop: 20,
    paddingBottom: 20,
    padding: 10,
    width: "100%",
    height: 70,
  },
});

class Conversations extends PureComponent {
  constructor() {
    super();
    this.state = {
      searchFocused: false,
      refresh: false,
      message: "",
      isSearchFocused: false,
    };

    this.SearchBox = React.createRef();
  }

  componentDidMount = async () => {
    const { receiver } = this.props;

    if (this.props.newMessageNotifications) {
      this.props.clearMessageNotifications();
    }
    this.props.getLatestConversationsRequest({ userId: this.props.userId });
    this.refreshAlgoliaSearch();

    this.props.getMessageClientNameRequest({ userId: receiver?._id });
  };

  componentDidUpdate = async () => {
    if (this.props.newMessageNotifications) {
      this.props.clearMessageNotifications();
    }
  };

  onClickConversation = ({ conversationId, user }) => () => {
    if (this.props.newMessageNotifications) {
      this.props.clearMessageNotifications();
    }
    this.props.setReceiver(user);
    this.props.setConversation(conversationId);
    this.openConversation(user);
    this.props.seeConversation(conversationId);
    this.userInput.focus();
  };

  displayLatestText = (user, text) => {
    if (user._id === this.props.userId) {
      return i18n.t("you-messaged", { text });
    }
    return text;
  };

  sortConversations = conversations => {
    const sorted = conversations.sort((a, b) => {
      return (
        new Date(b.latestMessage.createdAt) -
        new Date(a.latestMessage.createdAt)
      );
    });
    return sorted;
  };

  handleKeyDown(event) {
    if (event.keyCode === 13 && !event.shiftKey) {
      return this._submitText(event);
    }
  }

  handleKeyUp(event) {
    const inputHasText =
      event.target.innerHTML.length !== 0 && event.target.innerText !== "\n";
    this.setState({ inputHasText });
  }

  openConversation = receiver => {
    const { userId, firstName, lastName, picture } = this.props;

    this.props.openConversationRequest({
      sender: {
        _id: userId,
        picture: picture,
        name: `${firstName} ${lastName}`,
      },
      receiver,
    });

    this.props.getMessageClientNameRequest({ userId: receiver?._id });
  };

  onSendMessage = message => {
    const {
      userId,
      firstName,
      lastName,
      picture,
      receiver,
      conversationId,
    } = this.props;

    sendMessage({
      text: message,
      user: {
        _id: userId,
        avatar: picture,
        name: `${firstName} ${lastName}`,
      },
      conversationId: conversationId,
      receiverId: receiver._id,
    });
  };

  _submitText(event) {
    event.preventDefault();
    const text = this.userInput.textContent;
    if (text && text.length > 0) {
      this.onSendMessage(text);

      this.userInput.innerHTML = "";
    }
  }

  onClickSearchUser = user => () => {
    this.setState({ isSearchFocused: false });
    if (this.SearchBox && this.SearchBox.current) {
      this.SearchBox.current.refine();
    }
    this.props.setReceiver(user);
    this.openConversation(user);
    this.userInput.focus();
  };

  onFocusSearch = e => {
    this.setState({ isSearchFocused: true });
  };

  onBlurFocusSearch = e => {
    // this.setState({ isSearchFocused: false });
    setTimeout(() => {
      this.setState({ isSearchFocused: false });
      if (this.SearchBox && this.SearchBox.current) {
        this.SearchBox.current.refine();
      }
    }, 350);
  };

  refreshAlgoliaSearch = () => {
    this.setState({ refresh: true }, () => {
      this.setState({ refresh: false });
    });
  };

  renderUserList = data => {
    const { classes, conversationId, userId } = this.props;
    const { latestMessage, participants, _id } = data;
    let otherId = participants.filter(el => el !== this.props.userId);
    if (otherId.length < 1) {
      otherId = participants;
    }
    const conversationData = data.data[otherId[0]];
    // bug with an account send to himself
    if (!conversationData) return null;
    const otherUser = { _id: otherId[0], ...conversationData };
    const { user, text, createdAt, seen, seenBy } = latestMessage;
    const isConversationNotSeen =
      !seen && user._id !== userId && !seenBy?.[userId];

    const isSelected = conversationId === _id;
    return (
      <div
        key={data._id}
        onClick={this.onClickConversation({
          user: otherUser,
          conversationId: _id,
        })}
        className={`${classes.userRow} ${isSelected && classes.isSelected}`}
      >
        {!!isConversationNotSeen && <div className={classes.notSeen} />}
        <div className={classes.leftView}>
          <img
            className={classes.avatar}
            src={conversationData.picture}
            alt=""
          />
        </div>
        <div className={classes.middleView}>
          <div className={classes.topText}>
            <Typography className={classes.name}>
              {conversationData.name}
            </Typography>
            <Typography className={classes.date}>
              {this.props.language === "fr"
                ? moment(createdAt).format("dddd")
                : moment(createdAt).locale("en").format("dddd")}
            </Typography>
          </div>
          <div className={classes.bottomText}>
            <Typography
              className={`${classes.lastMessage} ${
                !!isConversationNotSeen && classes.notSeenText
              }`}
            >
              {this.displayLatestText(user, text)}
            </Typography>
          </div>
        </div>
      </div>
    );
  };

  onClickUser = () => {
    this.props.cleanMessage();

    this.props.clearCoachingSession();
    this.props.setReceiver({
      _id: this.props.receiver._id,
      picture: this.props.receiver.picture,
    });

    this.props.seeUserProfileRequest({
      userId: this.props.receiver._id,
      managerId: this.props.userId,
    });

    this.props.getCoachingSessionRequest({
      userId: this.props.receiver._id,
      coachId: this.props.userId,
    });

    this.props.push(`/user/${this.props.receiver._id}`);
  };

  render() {
    const {
      classes,
      receiver,
      clientNameRequesting,
      userSelectedClientName,
    } = this.props;

    return (
      <div className={classes.main}>
        <div className={classes.leftSide}>
          <div className={classes.topBar}>
            <Typography className={classes.title}>Conversations</Typography>
          </div>
          <div className={classes.searchBar}>
            <SearchUserBox
              onClickUser={this.onClickSearchUser}
              refresh={this.state.refresh}
              specialRef={this.SearchBox}
              displaySearchResults={this.state.isSearchFocused}
              onBlur={this.onBlurFocusSearch}
              onFocus={this.onFocusSearch}
              clientId={this.props.clientId}
            />
          </div>
          {!this.state.isSearchFocused && (
            <div className={classes.conversationsList}>
              {this.sortConversations(this.props.conversations).map(el =>
                this.renderUserList(el),
              )}
            </div>
          )}
        </div>
        <div className={classes.rightSide}>
          <div className={classes.rightTopSide}>
            <div
              className={classes.leftTopBar}
              onClick={() => this.onClickUser()}
            >
              <img
                className={classes.smallAvatar}
                src={receiver.picture}
                alt=""
              />
              <Typography className={classes.name}>{receiver.name}</Typography>
            </div>
            <div className={classes.rightTopBar}>
              {!clientNameRequesting ? (
                <Typography className={classes.clientName}>
                  {userSelectedClientName}
                </Typography>
              ) : (
                <Loader size={20} backgroundWhite={true}></Loader>
              )}
            </div>
          </div>
          <div className={classes.messageDiv}>
            <MessageList
              messages={this.props.messages.slice().reverse()}
              authorId={this.props.userId}
            />
          </div>
          <div className={classes.newMessageBar}>
            <div
              role="button"
              tabIndex="0"
              onFocus={() => {
                this.setState({ inputActive: true });
              }}
              onBlur={() => {
                this.setState({ inputActive: false });
              }}
              ref={e => {
                this.userInput = e;
              }}
              // onChange={e => this.setState({ message: e.target.value })}
              // value={this.state.message}
              onKeyDown={this.handleKeyDown.bind(this)}
              onKeyUp={this.handleKeyUp.bind(this)}
              contentEditable="true"
              placeholder={i18n.t("message-bar-placeholder")}
              className={classes.messageBarInput}
              // className="sc-user-input--text"
            />
            <div className="sc-user-input--button">
              <SendIcon onClick={this._submitText.bind(this)} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { plan } = state.client;
  const { role, firstName, lastName, picture } = state.user;
  const {
    conversations,
    requesting,
    receiver,
    messages,
    conversationId,
    clientNameRequesting,
  } = state.message;
  const { newMessageNotifications } = state.notification;
  const language = getAppLanguage(state);
  const userId = getUserId(state);
  const clientId = getSelectedClientId(state);
  const userSelectedClientName = getUserClientSelectedName(state);

  return {
    clientNameRequesting,
    clientId,
    firstName,
    lastName,
    picture,
    messages,
    language,
    receiver,
    conversationId,
    userId,
    role,
    plan,
    conversations,
    requesting,
    newMessageNotifications,
    userSelectedClientName,
  };
};

export default connect(mapStateToProps, {
  seeConversation,
  openConversationRequest,
  getChatMessagesRequest,
  getLatestConversationsRequest,
  setConversation,
  setReceiver,
  clearMessageNotifications,
  getMessageClientNameRequest,
  cleanMessage,
  seeUserProfileRequest,
  getCoachingSessionRequest,
  push,
  clearCoachingSession,
})(withStyles(styles)(Conversations));
