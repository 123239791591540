import React from "react";

const ArrowIcon = ({ sizePercentage, color }) => (
  <svg
    width={sizePercentage * 29}
    height={sizePercentage * 29}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 29 29"
    version="1.1"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.1661 14.6562C28.1661 11.0194 26.7214 7.53144 24.1497 4.95977C21.5781 2.38811 18.0901 0.943359 14.4532 0.943359C10.8164 0.943359 7.32843 2.38811 4.75677 4.95977C2.1851 7.53144 0.740356 11.0194 0.740356 14.6562C0.740356 18.2931 2.1851 21.7811 4.75677 24.3527C7.32843 26.9244 10.8164 28.3691 14.4532 28.3691C18.0901 28.3691 21.5781 26.9244 24.1497 24.3527C26.7214 21.7811 28.1661 18.2931 28.1661 14.6562ZM15.3103 19.7986C15.3103 20.0259 15.22 20.2439 15.0593 20.4046C14.8985 20.5653 14.6806 20.6556 14.4532 20.6556C14.2259 20.6556 14.0079 20.5653 13.8472 20.4046C13.6865 20.2439 13.5962 20.0259 13.5962 19.7986V11.5828L9.91771 15.263C9.83802 15.3427 9.74342 15.4059 9.63931 15.4491C9.53519 15.4922 9.42361 15.5144 9.31091 15.5144C9.19822 15.5144 9.08663 15.4922 8.98252 15.4491C8.8784 15.4059 8.7838 15.3427 8.70412 15.263C8.62443 15.1834 8.56122 15.0888 8.5181 14.9846C8.47497 14.8805 8.45277 14.7689 8.45277 14.6562C8.45277 14.5436 8.47497 14.432 8.5181 14.3279C8.56122 14.2237 8.62443 14.1291 8.70412 14.0495L13.8465 8.90712C13.9261 8.82731 14.0206 8.76398 14.1248 8.72078C14.2289 8.67757 14.3405 8.65533 14.4532 8.65533C14.566 8.65533 14.6776 8.67757 14.7817 8.72078C14.8859 8.76398 14.9804 8.82731 15.06 8.90712L20.2024 14.0495C20.3633 14.2104 20.4537 14.4287 20.4537 14.6562C20.4537 14.8838 20.3633 15.1021 20.2024 15.263C20.0414 15.424 19.8232 15.5144 19.5956 15.5144C19.368 15.5144 19.1497 15.424 18.9888 15.263L15.3103 11.5828V19.7986Z"
      fill={color}
    />
  </svg>
);

ArrowIcon.defaultProps = {
  sizePercentage: 1,
  color: "#58DE7F",
};

export default ArrowIcon;
