import {
  ADD_STEP,
  REMOVE_STEP,
  CREATE_OPEN_SNACK_MESSAGE,
  CREATE_CLOSE_SNACK_MESSAGE,
  CREATE_TRACK_CHANGE_FIELD,
  CHANGE_STEP_SELECTED,
  STEP_CREATE_ERROR,
  STEP_CREATE_REQUESTING,
  STEP_CREATE_SUCCESS,
  TRACK_CREATE_REQUESTING,
  TRACK_CREATE_SUCCESS,
  TRACK_CREATE_ERROR,
  TRACK_GET_REQUESTING,
  TRACK_GET_SUCCESS,
  TRACK_GET_ERROR,
  TRACK_MODIFY_REQUESTING,
  TRACK_MODIFY_ERROR,
  TRACK_MODIFY_SUCCESS,
  STEP_MODIFY_REQUESTING,
  STEP_MODIFY_ERROR,
  STEP_MODIFY_SUCCESS,
  STEP_ADMIN_MODIFY_REQUESTING,
  STEP_ADMIN_MODIFY_ERROR,
  STEP_ADMIN_MODIFY_SUCCESS,
  STEP_ADMIN_CREATE_REQUESTING,
  STEP_ADMIN_CREATE_ERROR,
  STEP_ADMIN_CREATE_SUCCESS,
  SAVE_TRACK_REQUESTING,
  TRACK_CREATE_GET_SUCCESS,
  TRACK_CREATE_GET_REQUESTING,
  TRACK_CREATE_GET_ERROR,
  CREATE_AND_MODIFY_TEMPLATE_REQUESTING,
  CREATE_AND_MODIFY_TEMPLATE_ERROR,
  CREATE_AND_MODIFY_TEMPLATE_SUCCESS,
  STEP_ADMIN_DELETE_REQUESTING,
  STEP_ADMIN_DELETE_ERROR,
  STEP_ADMIN_DELETE_SUCCESS,
  REORDER_STEPS,
  GET_TAGS_LIST_REQUESTING,
  GET_TAGS_LIST_SUCCESS,
  GET_TAGS_LIST_ERROR,
  UPDATE_TAGS_LIST_REQUESTING,
  UPDATE_TAGS_LIST_SUCCESS,
  UPDATE_TAGS_LIST_ERROR,
  GET_SKILLS_LIST_REQUESTING,
  GET_SKILLS_LIST_SUCCESS,
  GET_SKILLS_LIST_ERROR,
  UPDATE_SKILLS_LIST_REQUESTING,
  UPDATE_SKILLS_LIST_SUCCESS,
  UPDATE_SKILLS_LIST_ERROR,
  GET_FOLDER_LIST_REQUESTING,
  GET_FOLDER_LIST_SUCCESS,
  GET_FOLDER_LIST_ERROR,
  UPDATE_FOLDER_LIST_REQUESTING,
  UPDATE_FOLDER_LIST_SUCCESS,
  UPDATE_FOLDER_LIST_ERROR,
  MY_LIBRARY_GET_TOTAL_COUNT_REQUESTING,
  MY_LIBRARY_GET_TOTAL_COUNT_SUCCESS,
  MY_LIBRARY_GET_TOTAL_COUNT_ERROR,
  PREMIUM_GET_TOTAL_COUNT_REQUESTING,
  PREMIUM_GET_TOTAL_COUNT_SUCCESS,
  PREMIUM_GET_TOTAL_COUNT_ERROR,
  STEP_GET_ALL_REQUESTING,
  STEP_GET_ALL_SUCCESS,
  STEP_GET_ALL_ERROR,
  STEP_DELETE_REQUESTING,
  STEP_DELETE_SUCCESS,
  STEP_DELETE_ERROR,
  DUPLICATE_STEP,
  CREATE_NEW_STEP,
  GET_ALL_OBJECTIVES_REQUESTING,
  GET_ALL_OBJECTIVES_SUCCESS,
  GET_ALL_OBJECTIVES_ERROR,
  GET_ALL_COMPETENCIES_REQUESTING,
  GET_ALL_COMPETENCIES_SUCCESS,
  GET_ALL_COMPETENCIES_ERROR,
  STEP_OVERRIDE,
  STEP_GET_LIST_SUCCESS,
  STEP_GET_LIST_REQUESTING,
  STEP_GET_LIST_ERROR,
  REMOVE_STEP_FROM_LIST,
  REORDER_ALL_STEPS,
  ADD_STEP_WITHOUT_SAVE,
  REMOVE_STEP_WITHOUT_SAVE,
  SELECT_STEP,
  GET_MY_LIBRARY_STEPS_REQUESTING,
  GET_MY_LIBRARY_STEPS_SUCCESS,
  GET_MY_LIBRARY_STEPS_ERROR,
  SEARCH_CHANGE,
} from "./constants";

import { AUTH_LOGOUT_SUCCESS } from "../auth/reducer";
import i18n from "../../i18n";

const insertItem = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
];

const initialState = {
  _id: "",
  adminConnections: [], // { userId, picture, firstName, isConnected}
  myLibraryTotalCount: 0,
  premiumTotalCount: 0,
  error: null,
  stepError: null,
  saving: false,
  picture: "",
  search: "",
  title: { fr: "", en: "" },
  description: { fr: "", en: "" },
  stepSelectedIndex: 0,
  stepSelectedId: 0,
  lastStepSelectedId: "",
  folderSelected: {},
  skillSelected: {},
  languageSelected: "fr",
  stepsIds: [],
  stepsByIds: {},
  myLibrarySteps: [],
  lastStepCreatedId: "",
  allObjectives: [],
  allCompetencies: [],
  folderList: { _id: "", folders: [] },
  folderListRequesting: false,
  skillsList: { _id: "", skills: [] },
  skillsListRequesting: { _id: "", skills: [] },
  tagsList: { _id: "", tags: [] },
  tagsListRequesting: false,
  isTemplate: false,
  templateSettings: {
    templateLabel: { fr: "", en: "" },
    templateDescription: { fr: "", en: "" },
    editable: false,
    teamSize: 4,
    strategy: "bestFit",
    frequency: "oneADay", // "oneADay" noLimit
    autoRegister: true,
    hasRepetition: true,
  },
  isContentEditable: true,
  stepRequesting: false,
  isMyLibraryStepsLoading: false,
  rightSideScreen: "none", // createActionTextStep
  snackMessage: null,
  snackStatus: "normal", // status
  requesting: false,
  lastUpdateDate: null,
  indexToScroll: 0,
  lastScrollDate: new Date(),
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STEP_OVERRIDE: {
      const { _id } = action.payload;
      const stepsByIds = { ...state.stepsByIds };
      stepsByIds[_id] = {
        ...stepsByIds[_id],
        ...action.payload,
        content: {
          ...stepsByIds[_id].content,
          title: {
            ...stepsByIds[_id].content.title,
            ...action.payload.content.title,
          },
          how: {
            ...stepsByIds[_id].content.how,
            ...action.payload.content.how,
          },
          why: {
            ...stepsByIds[_id].content.why,
            ...action.payload.content.why,
          },
        },
        step: action.payload._id,
      };

      return {
        ...state,
        lastUpdateDate: new Date(),
        stepsByIds,
      };
    }

    case CHANGE_STEP_SELECTED: {
      const { stepSelectedId } = state;

      const stepsByIds = {
        ...state.stepsByIds,
      };

      stepsByIds[stepSelectedId] = {
        ...stepsByIds[stepSelectedId],
        ...action.payload,
      };

      return {
        ...state,
        stepsByIds,
      };
    }

    case CREATE_NEW_STEP: {
      return {
        ...state,
        stepRequesting: true,
        rightSideScreen: "createActionTextStep",
      };
    }

    case SELECT_STEP: {
      const { stepId } = action.payload;
      return {
        ...state,
        rightSideScreen: "createActionTextStep",
        lastStepSelectedId: stepId,
        stepSelectedId: stepId,
      };
    }

    case ADD_STEP_WITHOUT_SAVE:
    case ADD_STEP: {
      const { _id, order = 0 } = action.payload;
      const stepsByIds = { ...state.stepsByIds };
      stepsByIds[_id] = action.payload;

      const stepsIds = insertItem(state.stepsIds, order, _id);

      return {
        ...state,
        rightSideScreen: "createActionTextStep",
        snackMessage: i18n.t("step-added"),
        stepsByIds,
        stepsIds,
      };
    }

    case DUPLICATE_STEP: {
      return {
        ...state,
        snackMessage: i18n.t("step-duplicated"),
      };
    }

    case REORDER_ALL_STEPS: {
      const { stepsIds } = action.payload;

      return {
        ...state,
        stepsIds,
      };
    }

    case REORDER_STEPS: {
      const { startIndex, endIndex } = action.payload;
      const stepsIds = [...state.stepsIds];
      const [removed] = stepsIds.splice(startIndex, 1);
      stepsIds.splice(endIndex, 0, removed);

      return {
        ...state,
        stepsIds,
      };
    }

    case SEARCH_CHANGE: {
      return {
        ...state,
        search: action.payload,
      };
    }

    case GET_ALL_COMPETENCIES_REQUESTING:
    case GET_ALL_OBJECTIVES_REQUESTING:
      return {
        ...state,
      };

    case GET_ALL_COMPETENCIES_SUCCESS:
      return {
        ...state,
        allCompetencies: action.payload,
      };

    case GET_ALL_OBJECTIVES_SUCCESS:
      return {
        ...state,
        allObjectives: action.payload,
      };

    case GET_ALL_COMPETENCIES_ERROR:
    case GET_ALL_OBJECTIVES_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case STEP_GET_LIST_REQUESTING:
    case STEP_GET_ALL_REQUESTING:
      return {
        ...state,
        stepSelectedIndex: -1,
        steps: [],
        requesting: true,
      };

    case TRACK_CREATE_GET_REQUESTING: {
      return {
        ...state,
        stepSelectedIndex: -1,
        requesting: true,
        steps: [],
      };
    }

    case CREATE_AND_MODIFY_TEMPLATE_REQUESTING:
    case TRACK_GET_REQUESTING:
    case TRACK_CREATE_REQUESTING: {
      return {
        ...state,
        requesting: true,
      };
    }

    case SAVE_TRACK_REQUESTING:
    case TRACK_MODIFY_REQUESTING:
      return {
        ...state,
        saving: true,
      };

    case TRACK_MODIFY_SUCCESS:
      return {
        ...state,
        saving: false,
      };

    case TRACK_MODIFY_ERROR:
      return {
        ...state,
        saving: false,
        error: action.payload,
      };

    case CREATE_AND_MODIFY_TEMPLATE_SUCCESS: {
      const { templateSettings, isTemplate } = action.payload;
      return {
        ...state,
        requesting: false,
        templateSettings,
        isTemplate,
      };
    }

    case STEP_GET_LIST_SUCCESS:
    case STEP_GET_ALL_SUCCESS: {
      const {
        allCompetencies,
        tagsList,
        skillsList,
        allObjectives,
        folderList,
        lastStepSelectedId,
        folderSelected,
      } = state;
      const { entities = {}, result = {} } = action.payload;
      const { steps = [] } = result;
      const rightSideScreen =
        steps.length > 0 ? "createActionTextStep" : "none";

      return {
        ...initialState,
        lastStepSelectedId,
        folderSelected,
        rightSideScreen,
        allCompetencies,
        tagsList,
        skillsList,
        folderList,
        allObjectives,
        stepSelectedId: lastStepSelectedId || steps?.[0] ? steps?.[0] : "",
        stepsIds: steps,
        stepsByIds: entities?.steps || {},
        steps,
        requesting: false,
      };
    }

    case GET_MY_LIBRARY_STEPS_REQUESTING: {
      return {
        ...state,
        isMyLibraryStepsLoading: true,
      };
    }

    case GET_MY_LIBRARY_STEPS_SUCCESS: {
      return {
        ...state,
        myLibrarySteps: action.payload,
        isMyLibraryStepsLoading: false,
      };
    }

    case TRACK_CREATE_GET_SUCCESS:
    case TRACK_GET_SUCCESS:
    case TRACK_CREATE_SUCCESS: {
      const { stepsIds } = action.payload;

      return {
        ...initialState,
        ...action.payload,
        stepSelectedId: stepsIds?.[0] ? stepsIds?.[0] : "",
        rightSideScreen:
          action.payload?.steps?.length > 0 ? "createActionTextStep" : "none",
        templateSettings: {
          ...initialState.templateSettings,
          ...action.payload.templateSettings,
        },
        requesting: false,
      };
    }

    case GET_MY_LIBRARY_STEPS_ERROR:
      return {
        ...state,
        isMyLibraryStepsLoading: false,
        error: action.payload,
      };

    case STEP_GET_LIST_ERROR:
    case STEP_GET_ALL_ERROR:
    case CREATE_AND_MODIFY_TEMPLATE_ERROR:
    case TRACK_CREATE_GET_ERROR:
    case TRACK_GET_ERROR:
    case TRACK_CREATE_ERROR:
      return {
        ...state,
        requesting: false,
        error: action.payload,
      };

    case STEP_DELETE_SUCCESS:
    case STEP_ADMIN_DELETE_SUCCESS:
    case REMOVE_STEP_WITHOUT_SAVE:
    case REMOVE_STEP: {
      const stepId = action.payload;

      let stepSelectedIndex = state.stepsIds?.indexOf(stepId);

      const stepsIds = state.stepsIds?.filter((el, index) => el !== stepId);
      const stepsByIds = { ...state.stepsByIds };

      delete stepsByIds[stepId];

      const steps = state.steps?.filter(el => el?._id !== stepId);

      let rightSideScreen = state.rightSideScreen;
      let stepSelectedId = stepId;

      if (
        (stepSelectedIndex === 0 && stepsIds.length === 1) ||
        stepsIds.length < 1
      ) {
        rightSideScreen = "library";
      } else if (stepSelectedIndex === stepsIds.length) {
        stepSelectedIndex -= 1;
        stepSelectedId = stepsIds?.[stepSelectedIndex];
      } else if (stepsIds.length > stepSelectedIndex) {
        stepSelectedId = stepsIds?.[stepSelectedIndex];
      }

      return {
        ...state,
        snackMessage: i18n.t("step-removed"),
        stepSelectedId,
        stepSelectedIndex,
        steps,
        stepsIds,
        stepsByIds,
        rightSideScreen,
      };
    }

    case REMOVE_STEP_FROM_LIST: {
      const stepId = action.payload._id;

      const stepsIds = state.stepsIds.filter((el, index) => el !== stepId);
      const stepsByIds = { ...state.stepsByIds };
      delete stepsByIds[stepId];

      return {
        ...state,
        snackMessage: i18n.t("step-removed"),
        stepsIds,
        stepsByIds,
      };
    }

    case CREATE_OPEN_SNACK_MESSAGE:
      return {
        ...state,
        snackMessage: action.payload,
      };

    case CREATE_CLOSE_SNACK_MESSAGE:
      return {
        ...state,
        snackStatus: initialState.snackStatus,
        snackMessage: initialState.snackMessage,
      };

    case STEP_ADMIN_DELETE_REQUESTING:
    case STEP_ADMIN_MODIFY_REQUESTING:
    case STEP_ADMIN_CREATE_REQUESTING:
    case STEP_DELETE_REQUESTING:
    case STEP_MODIFY_REQUESTING:
    case STEP_CREATE_REQUESTING:
      return {
        ...state,
        stepRequesting: true,
      };

    case STEP_ADMIN_MODIFY_SUCCESS:
    case STEP_MODIFY_SUCCESS: {
      const { _id } = action.payload;
      const steps = state.steps.map((el, i) => {
        if (el._id === _id) {
          // do not override current modifications if it is selected
          if (i === state.stepSelectedIndex) {
            return { ...action.payload, ...el, step: action.payload._id };
          }
          return { ...el, ...action.payload, step: action.payload._id };
        }
        return el;
      });
      return {
        ...state,
        steps,
        stepRequesting: false,
      };
    }

    case STEP_ADMIN_CREATE_SUCCESS:
    case STEP_CREATE_SUCCESS: {
      const { _id } = action.payload;
      const stepsByIds = { ...state.stepsByIds };
      stepsByIds[_id] = {
        ...stepsByIds[_id],
        ...action.payload,
        content: {
          ...stepsByIds[_id].content,
          title: {
            ...stepsByIds[_id].content.title,
            ...action.payload.content.title,
          },
          how: {
            ...stepsByIds[_id].content.how,
            ...action.payload.content.how,
          },
          why: {
            ...stepsByIds[_id].content.why,
            ...action.payload.content.why,
          },
        },
        step: action.payload._id,
      };

      return {
        ...state,
        stepsByIds,
        lastStepCreatedId: _id,
        myLibraryTotalCount: state.myLibraryTotalCount + 1,
        snackStatus: "success",
        snackMessage: i18n.t("step-created"),
        stepRequesting: false,
      };
    }

    case MY_LIBRARY_GET_TOTAL_COUNT_REQUESTING:
    case PREMIUM_GET_TOTAL_COUNT_REQUESTING:
      return {
        ...state,
      };

    case MY_LIBRARY_GET_TOTAL_COUNT_ERROR:
    case PREMIUM_GET_TOTAL_COUNT_ERROR:
      return {
        ...state,
      };

    case MY_LIBRARY_GET_TOTAL_COUNT_SUCCESS:
      return {
        ...state,
        myLibraryTotalCount: action.payload,
      };

    case PREMIUM_GET_TOTAL_COUNT_SUCCESS:
      return {
        ...state,
        premiumTotalCount: action.payload,
      };

    case GET_SKILLS_LIST_REQUESTING:
    case UPDATE_SKILLS_LIST_REQUESTING: {
      return {
        ...state,
        skillsListRequesting: true,
      };
    }

    case GET_FOLDER_LIST_REQUESTING:
    case UPDATE_FOLDER_LIST_REQUESTING: {
      return {
        ...state,
        folderListRequesting: true,
      };
    }

    case GET_FOLDER_LIST_SUCCESS:
    case UPDATE_FOLDER_LIST_SUCCESS:
      return {
        ...state,
        folderList: action.payload,
        folderListRequesting: false,
      };

    case GET_FOLDER_LIST_ERROR:
    case UPDATE_FOLDER_LIST_ERROR:
      return {
        ...state,
        folderListRequesting: false,
        error: action.payload,
      };

    case GET_SKILLS_LIST_SUCCESS:
    case UPDATE_SKILLS_LIST_SUCCESS:
      return {
        ...state,
        skillsListRequesting: false,
        skillsList: action.payload,
      };

    case GET_SKILLS_LIST_ERROR:
    case UPDATE_SKILLS_LIST_ERROR:
      return {
        ...state,
        skillsListRequesting: false,
        error: action.payload,
      };

    case UPDATE_TAGS_LIST_REQUESTING:
    case GET_TAGS_LIST_REQUESTING: {
      return {
        ...state,
        tagsListRequesting: true,
      };
    }

    case UPDATE_TAGS_LIST_SUCCESS:
    case GET_TAGS_LIST_SUCCESS:
      return {
        ...state,
        tagsListRequesting: false,
        tagsList: action.payload,
      };

    case UPDATE_TAGS_LIST_ERROR:
    case GET_TAGS_LIST_ERROR:
      return {
        ...state,
        tagsListRequesting: false,
        error: action.payload,
      };

    case STEP_DELETE_ERROR:
    case STEP_ADMIN_DELETE_ERROR:
    case STEP_ADMIN_MODIFY_ERROR:
    case STEP_ADMIN_CREATE_ERROR:
    case STEP_MODIFY_ERROR:
    case STEP_CREATE_ERROR:
      return {
        ...state,
        stepRequesting: false,
        stepError: action.payload,
      };

    case CREATE_TRACK_CHANGE_FIELD:
      return {
        ...state,
        ...action.payload,
      };

    case AUTH_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
