import Colors from "../constants/Colors";

const getLabelColor = label => {
  switch (label) {
    case "new":
    case "essential":
      return Colors.pink;
    case "popular":
      return Colors.orange;
    case "rejected":
      return "red";
    case "developing":
      return Colors.skyBlue;
    default:
      return Colors.pureMain;
  }
};

export default getLabelColor;
