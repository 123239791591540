import React from "react";
import { Typography } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  main: {},
  image: {},
  explanation: {
    marginTop: 50,
    width: 250,
    textAlign: "center",
  },
}));

interface Props {
  explanation: string;
  image: string;
}

const ExplanationSide = ({ explanation, image }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <img className={classes.image} src={image} alt="new coaching" />
      <Typography className={classes.explanation}>{explanation}</Typography>
    </div>
  );
};

export default ExplanationSide;
