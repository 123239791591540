import React from "react";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  main: {},
  video: {
    width: "100%",
    height: "auto",
    position: "relative",
    borderRadius: 4,
  },
  videoPreview: {
    marginTop: "2.5%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    overflow: "hidden",
    width: "95%",
    minHeight: 200,
    backgroundColor: "#f8f9fa",
    height: "auto",
    position: "relative",
    borderRadius: 4,
  },
}));

interface Props {
  video: any;
  videoRef: any;
  previewStream: any;
  audioRecord: any;
}

const RecordPreview = ({
  video,
  previewStream,
  videoRef,
  audioRecord,
}: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.videoPreview}>
      {!!video && (
        <video src={video} className={classes.video} autoPlay controls />
      )}
      {previewStream && !video && (
        <video ref={videoRef} className={classes.video} autoPlay />
      )}
      {!!audioRecord && <audio id="audio" controls src={audioRecord}></audio>}
    </div>
  );
};

export default RecordPreview;
