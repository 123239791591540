import React from "react";
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  main: {},
});

const VideoIcon = ({
  className = "",
  width = 19,
  height = 20,
  color = "#fff",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 20"
      fill="none"
      className={className}
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M9.54888 18.4042C14.116 18.4042 17.8185 14.7017 17.8185 10.1346C17.8185 5.56741 14.116 1.86499 9.54888 1.86499C4.98172 1.86499 1.2793 5.56741 1.2793 10.1346C1.2793 14.7017 4.98172 18.4042 9.54888 18.4042Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.89502 6.8269L12.8568 10.1347L7.89502 13.4426V6.8269Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default withStyles(styles)(VideoIcon);
