import React from "react";

const RecordScreenIcon = ({
  width = "1.5rem",
  height = "1.5rem",
  color = "#006cfa",
  className = "",
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 16 16"
    version="1.1"
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.02745 1.43435C7.02745 0.624261 7.7059 0 8.50196 0H12.5176C13.313 0 13.9909 0.623145 13.9922 1.43218L15.386 0.986153C15.5291 0.940355 15.6855 0.96566 15.8069 1.05426C15.9282 1.14285 16 1.28408 16 1.43435V5.28941C16 5.43968 15.9282 5.58091 15.8069 5.66951C15.6855 5.7581 15.5291 5.78341 15.386 5.73761L13.9922 5.29158C13.9909 6.10062 13.313 6.72376 12.5176 6.72376H8.50196C7.7059 6.72376 7.02745 6.0995 7.02745 5.28941V1.43435ZM8.50196 0.941176C8.18912 0.941176 7.96863 1.1799 7.96863 1.43435V5.28941C7.96863 5.54386 8.18912 5.78259 8.50196 5.78259H12.5176C12.8305 5.78259 13.051 5.54386 13.051 5.28941V4.6469C13.051 4.49663 13.1227 4.3554 13.2441 4.2668C13.3655 4.17821 13.5219 4.1529 13.665 4.1987L15.0588 4.64473V2.07904L13.665 2.52506C13.5219 2.57086 13.3655 2.54556 13.2441 2.45696C13.1227 2.36836 13.051 2.22713 13.051 2.07686V1.43435C13.051 1.1799 12.8305 0.941176 12.5176 0.941176H8.50196Z"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.47451 5.76C1.16167 5.76 0.941176 5.99872 0.941176 6.25317V10.6014H12.0471V8.1807C12.0471 7.9208 12.2577 7.71011 12.5176 7.71011C12.7775 7.71011 12.9882 7.9208 12.9882 8.1807V12.0358C12.9882 12.8459 12.3098 13.4701 11.5137 13.4701H6.96471V14.4565H8.50196C8.76186 14.4565 8.97255 14.6671 8.97255 14.927C8.97255 15.1869 8.76186 15.3976 8.50196 15.3976H4.48628C4.22638 15.3976 4.01569 15.1869 4.01569 14.927C4.01569 14.6671 4.22638 14.4565 4.48628 14.4565H6.02353V13.4701H1.47451C0.67845 13.4701 0 12.8459 0 12.0358V6.25317C0 5.44308 0.678451 4.81882 1.47451 4.81882H5.4902C5.75009 4.81882 5.96078 5.02951 5.96078 5.28941C5.96078 5.54931 5.75009 5.76 5.4902 5.76H1.47451ZM0.941176 11.5426V12.0358C0.941176 12.2902 1.16167 12.5289 1.47451 12.5289H6.48775L6.49412 12.5289L6.50049 12.5289H11.5137C11.8266 12.5289 12.0471 12.2902 12.0471 12.0358V11.5426H0.941176Z"
    ></path>
  </svg>
);

export default RecordScreenIcon;
