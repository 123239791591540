import React from "react";

const DocumentIcon = ({
  className = "",
  height = 13,
  width = 12,
  color = "#5FB8F9",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 16"
      fill="none"
      className={className}
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M0.922485 2.4V13.6C0.922485 13.9713 1.06998 14.3274 1.33254 14.5899C1.59509 14.8525 1.95118 15 2.32249 15H10.7225C11.0938 15 11.4499 14.8525 11.7124 14.5899C11.975 14.3274 12.1225 13.9713 12.1225 13.6V5.4394C12.1225 5.25289 12.0852 5.06827 12.0128 4.89637C11.9404 4.72447 11.8345 4.56877 11.7011 4.4384L8.59309 1.399C8.33154 1.14326 7.98029 1.00005 7.61449 1H2.32249C1.95118 1 1.59509 1.1475 1.33254 1.41005C1.06998 1.6726 0.922485 2.0287 0.922485 2.4V2.4Z"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.42249 8.7H8.62249"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.42249 11.5H6.52249"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.92249 1V3.8C7.92249 4.1713 8.06999 4.5274 8.33254 4.78995C8.59509 5.0525 8.95118 5.2 9.32249 5.2H12.1225"
        stroke={color}
        strokeWidth="1.4"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DocumentIcon;
