import React, { useState } from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography } from "@mui/material";
import ViewIcon from "scenes/CreateTrack/CreateActionTextStep/ViewIcon";
import Colors from "constants/Colors";
import StarIcon from "scenes/CreateTrack/StarIcon";
import CommentIcon from "scenes/Campaigns/CampaignScreen/ActionsSide/CommentIcon";
import i18n from "i18n";

const styles = theme => ({
  stats: {
    width: "100%",
    maxWidth: 220,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  stat: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    marginLeft: 13,
  },
  textStat: {
    color: "#4A4A4A",
    fontSize: 12.5,
    fontFamily: "Avenir",
    fontWeight: "800",
    marginLeft: 8,
  },
  seeComment: {
    color: Colors.pureMain,
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Avenir",
    marginLeft: 10,
    transition: "all 0.2s ease",
    cursor: "pointer",
    "&:hover": {
      opacity: 9,
    },
  },
  comment: {
    fontSize: 16,
    color: "#7A7A7A",
    marginTop: 15,
    textAlign: "left",
  },
  statWithComment: {
    marginLeft: 0,
  },
  isSmall: {
    maxWidth: 600,
    justifyContent: "flex-start",
  },
});

const ResourceStats = ({
  classes,
  rating = 5,
  comment = "",
  comments = [],
  totalViews = 0,
  totalComments = 0,
  hasComment = false,
  isSmall = false,
}) => {
  const [isCommentVisible, openComment] = useState(false);
  return (
    <>
      <div className={`${classes.stats} ${isSmall && classes.isSmall}`}>
        <div
          className={`${classes.stat} ${hasComment && classes.statWithComment}`}
        >
          <ViewIcon color={Colors.pureMain} height={12} />
          <Typography className={classes.textStat}>{totalViews}</Typography>
        </div>
        <div className={`${classes.stat}`}>
          <StarIcon color={"#FCC21B"} sizePercentage={0.7} />
          <Typography className={classes.textStat}>
            {rating.toFixed(1)}
          </Typography>
        </div>
        <div className={classes.stat}>
          <CommentIcon />
          <Typography className={classes.textStat}>
            {totalComments > 0 ? totalComments : !!comment ? 1 : 0}
          </Typography>
        </div>
        {!!hasComment && (
          <Typography
            className={classes.seeComment}
            onClick={() => openComment(!isCommentVisible)}
          >
            {i18n.t("see-comments")}
          </Typography>
        )}
      </div>
      {isCommentVisible && (
        <>
          {!!comment && (
            <Typography className={classes.comment}>{comment}</Typography>
          )}
          {comments.map(el => (
            <Typography className={classes.comment}>{el}</Typography>
          ))}
        </>
      )}
    </>
  );
};

export default withStyles(styles)(ResourceStats);
