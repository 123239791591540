import React from "react";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Colors from "constants/Colors";

const useStyles = makeStyles(theme => ({
  main: {
    padding: "10px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: 5,
    cursor: "pointer",
    transition: "all 0.2s ease",
    border: "solid",
    borderWidth: 1,
    borderColor: "#fff",
    "&:hover": { borderColor: Colors.pureMain },
    "&:focus": { borderColor: "#fff" },
  },
  text: {
    fontWeight: 500,
    fontSize: 16,
  },
  isSelected: {
    backgroundColor: "#F5F5F5",
  },
}));

interface Props {
  color?: string;
  text: string;
  onClick: () => void;
  isSelected: boolean;
}

const InvoiceTypeFilter = ({
  color = "#282828",
  text,
  onClick,
  isSelected,
}: Props) => {
  const classes = useStyles();

  return (
    <div
      className={`${classes.main} ${isSelected && classes.isSelected}`}
      onClick={onClick}
    >
      <Typography className={classes.text} style={{ color }}>
        {text}
      </Typography>
    </div>
  );
};

export default InvoiceTypeFilter;
