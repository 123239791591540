import {
  MY_USERS_GET_LIST_ERROR,
  MY_USERS_GET_LIST_REQUESTING,
  MY_USERS_GET_LIST_SUCCESS,
  MY_USERS_LOAD_MORE_ERROR,
  MY_USERS_LOAD_MORE_REQUESTING,
  MY_USERS_LOAD_MORE_SUCCESS,
  MY_USERS_MODIFY_ERROR,
  MY_USERS_MODIFY_SUCCESS,
  MY_USERS_MODIFY_REQUESTING,
  MY_USERS_DELETE_REQUESTING,
  MY_USERS_DELETE_SUCCESS,
  MY_USERS_DELETE_ERROR,
  MY_USERS_GET_REQUESTING,
  MY_USERS_GET_SUCCESS,
  MY_USERS_GET_ERROR,
  MY_USERS_CHANGE_FIELD,
  MY_USERS_UPDATE_ROLE_REQUESTING,
} from "./constants";
import getNavigatorLanguage from "../../utils/getNavigatorLanguage";
import { AUTH_LOGOUT_SUCCESS } from "../auth/constants";

const initialState = {
  userSelected: {
    hasUsersAccessRestricted: false,
    usersAccess: [],
  },
  users: [],
  isLoading: false,
  language: getNavigatorLanguage(),
  snackMessage: null,
  requesting: false,
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MY_USERS_CHANGE_FIELD:
      return {
        ...state,
        ...action.payload,
      };

    case MY_USERS_UPDATE_ROLE_REQUESTING:
    case MY_USERS_GET_REQUESTING:
    case MY_USERS_DELETE_REQUESTING:
    case MY_USERS_MODIFY_REQUESTING:
      return {
        ...state,
        requesting: true,
      };

    case MY_USERS_GET_ERROR:
    case MY_USERS_DELETE_ERROR:
    case MY_USERS_MODIFY_ERROR:
      return {
        ...state,
        requesting: false,
      };

    case MY_USERS_DELETE_SUCCESS:
    case MY_USERS_MODIFY_SUCCESS:
      return {
        ...state,
        requesting: false,
      };

    case MY_USERS_GET_SUCCESS:
      return {
        ...state,
        requesting: false,
        userSelected: { ...state.userSelected, ...action.payload },
      };

    case MY_USERS_GET_LIST_SUCCESS:
      return {
        ...state,
        users: action.payload,
        isLoading: false,
      };

    case MY_USERS_LOAD_MORE_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload],
        isLoading: false,
      };

    case MY_USERS_GET_LIST_REQUESTING:
      return {
        ...state,
        users: initialState.users,
        isLoading: true,
      };

    case MY_USERS_LOAD_MORE_REQUESTING:
      return {
        ...state,
        isLoading: true,
      };

    case MY_USERS_GET_LIST_ERROR:
    case MY_USERS_LOAD_MORE_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case AUTH_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
