import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Colors from "../../../../constants/Colors";
import moment from "moment";
import selectLanguage from "../../../../utils/selectLanguage";
import StarIcon from "../../../CreateTrack/StarIcon";

import { connect } from "react-redux";
import {
  getIsSuperAdmin,
  getSelectedClientId,
  getUserId,
} from "../../../../services/user/selectors";
import {
  getUserCampaignsListRequest,
  getCampaignStepsRequest,
  getCampaignCommentsRequest,
} from "services/campaign/actions";

import i18n from "../../../../i18n";
import { getAppLanguage } from "../../../../services/general/selectors";
import StatBox from "./StatBox";
import LightIcon from "scenes/CreateTrack/LightIcon";
import ViewIcon from "scenes/CreateTrack/CreateActionTextStep/ViewIcon";
import CommentsIcon from "./CommentIcon";
import CheckIcon from "components/CheckIcon";
import removeHtmlTags from "utils/removeHtmlTags";
import ActionsTable from "scenes/Campaigns/CampaignScreen/ActionsSide/ActionsTable";
import { sortBy } from "lodash";
import {
  getCampaignSelected,
  getCampaignSelectedTrackId,
  getUserCampaigns,
  getCampaignSteps,
  getConsolidatedCampaignSteps,
} from "services/campaign/selectors";
import { MainText } from "components/Texts/MainText";
import { CSVLink } from "react-csv";

moment.locale("fr");

const styles = theme => ({
  rightDiv: {
    marginTop: 15,
    marginBottom: 15,
    overflow: "auto",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 4,
  },
  firstSection: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "auto",
    justifyContent: "space-around",
    alignItems: "flex-start",
    marginBottom: 15,
  },
  topStats: {
    overflow: "auto",
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
  },
  link: {
    fontSize: 12,
    marginBottom: -3,
    marginTop: -2,
    bottom: 0,
    color: Colors.pureMain,
    cursor: "pointer",
    width: "fit-content",
    "&:hover": { textDecoration: "underline" },
  },
});

const getDonePercentageAndRatingMedium = steps => {
  const result = steps.reduce(
    (prev, curr) => {
      prev.donePercentage += curr.donePercentage;
      prev.rating += curr.rating;
      return prev;
    },
    { donePercentage: 0, rating: 0 },
  );
  result.donePercentage = (result.donePercentage / steps.length).toFixed(1);
  result.rating = (result.rating / steps.length).toFixed(1);
  return result;
};

export const formatDoneSteps = (doneSteps, userCampaigns, language) => {
  const j = {};
  const views = {};

  const isSingleUser = userCampaigns.length === 1;

  userCampaigns.forEach(el => {
    el.allStepsViews.forEach(step => {
      if (views[step?.step]) {
        views[step?.step] += step?.views;
      } else {
        views[step?.step] = step?.views;
      }
    });
  });

  doneSteps.forEach(function (step) {
    if (j[step?.stepId?._id]) {
      j[step?.stepId?._id].push(step);
    } else {
      j[step?.stepId?._id] = [step];
    }
  });

  return Object.keys(j).map(key => {
    const steps = j[key];
    const sortedStep = sortBy([...steps], "createdAt").reverse();
    const result = { ...sortedStep[0] };
    result.createdAt = isSingleUser
      ? userCampaigns?.[0]?.steps?.find(el => el.step === key)?.doneDate
      : result.createdAt;

    const { category, skills, title } = result?.stepId || {};
    result.totalDone = steps?.length;
    const donePercentageAndRatingMedium = getDonePercentageAndRatingMedium(
      steps,
    );
    result.donePercentage = donePercentageAndRatingMedium?.donePercentage;
    result.rating = donePercentageAndRatingMedium?.rating;
    result.category =
      skills && skills.length > 0
        ? selectLanguage({ text: skills?.[0]?.label, language })
        : category;
    result.comments = steps.reduce((prev, curr) => {
      if (curr.comment) {
        prev.push(curr.comment);
      }
      return prev;
    }, []);
    result.totalComments = result?.comments?.length;
    result.views = views?.[key] || 0;
    // for fuse search

    result.title = removeHtmlTags(
      selectLanguage({ text: title, language, noFallBack: true }),
    );

    // add number of views from userCampaings
    return result;
  });
};

const CSVHeaders = language => {
  return [
    {
      label: i18n.t("export-title-actions", { lng: language }),
      key: "stepTitle",
    },
    {
      label: i18n.t("export-skill-actions", { lng: language }),
      key: "contentSkill",
    },
    {
      label: i18n.t("export-content-title-actions", {
        lng: language,
      }),
      key: "contentTitle",
    },
    {
      label: i18n.t("export-content-why-actions", { lng: language }),
      key: "contentWhy",
    },
    {
      label: i18n.t("export-content-how-actions", { lng: language }),
      key: "contentHow",
    },
    {
      label: i18n.t("export-total-done-actions", { lng: language }),
      key: "totalDone",
    },
    {
      label: i18n.t("export-total-views-actions", { lng: language }),
      key: "totalViews",
    },
    {
      label: i18n.t("export-done-percentage-actions", { lng: language }),
      key: "donePercentage",
    },
    {
      label: i18n.t("export-rating-actions", { lng: language }),
      key: "rating",
    },
    {
      label: i18n.t("export-comments-actions", { lng: language }),
      key: "comments",
    },
    {
      label: i18n.t("export-files-actions", { lng: language }),
      key: "files",
    },
  ];
};

const formatDataForExport = (doneSteps, language) => {
  const exportFormat = doneSteps?.map(step => {
    const {
      title,
      content,
      totalDone,
      totalViews,
      donePercentage,
      rating,
      skills = [],
    } = step?.stepId || {};
    const { comments = [], files = [] } = step;
    const stepTitle = selectLanguage({ text: title, language }) || "";
    const contentSkill = skills
      ?.map(skill => {
        return selectLanguage({ text: skill?.label, language });
      })
      .join("\n");
    const contentTitle = (
      selectLanguage({ text: content?.title, language }) || ""
    )?.replace(/,/g, ";");
    const contentHow = (
      selectLanguage({ text: content?.how, language }) || ""
    )?.replace(/,/g, ";");
    const contentWhy = (
      selectLanguage({ text: content?.why, language }) || ""
    )?.replace(/,/g, ";");
    const contentComments = comments
      ?.map(comment =>
        !!comment?.comment
          ? comment?.comment?.replace(/,/gm, ";")?.replace(/\"/gm, "'")
          : "",
      )
      .join("\n\n-----------\n\n");
    const contentFiles = files
      ?.map(file => (!!file ? file?.fileUrl : ""))
      .join("\n\n----------\n\n");

    return {
      stepTitle,
      contentSkill,
      contentTitle,
      contentWhy,
      contentHow: contentHow,
      totalDone: totalDone?.toFixed(2),
      totalViews,
      donePercentage: donePercentage?.toFixed(2),
      rating: rating?.toFixed(2),
      comments: contentComments,
      files: contentFiles,
    };
  });
  return exportFormat;
};

class ActionsSide extends Component {
  state = {
    page: 0,
    limit: 40,
    stepPreview: {},
    stepPreviewIndex: 0,
    comments: [],
    search: "",
    isPreviewVisible: false,
    isSortedDesc: true,
    sortedBy: "totalDone", // rating - comments - donePercentage
  };

  componentDidMount = () => {
    this.props.getCampaignStepsRequest({ trackId: this.props.trackId });
    this.props.getCampaignCommentsRequest({
      campaignId: this.props.campaignId,
      limit: 0,
      page: 0,
    });
    this.props.getUserCampaignsListRequest({
      campaignId: this.props.campaignId,
      page: 0,
      limit: 1000,
    });
  };

  onChangeSearch = search => {
    this.setState({ search });
  };

  onClickTitle = (stepPreview, comments) => () => {
    this.setState({
      isPreviewVisible: true,
      stepPreview,
      comments,
      stepPreviewIndex: stepPreview._id,
    });
  };

  getCommentsNumber = steps => {
    return steps.reduce((prev, curr) => {
      return prev + curr.comments.length;
    }, 0);
  };

  getMediumRating = steps => {
    if (steps.length < 1) {
      return 0;
    }
    return (
      steps.reduce((prev, curr) => {
        return prev + parseFloat(curr.rating);
      }, 0) / steps.length
    ).toFixed(1);
  };

  getDonePercentageMean = steps => {
    if (steps.length < 1) {
      return 0;
    }
    return (
      steps.reduce((prev, curr) => {
        return prev + parseFloat(curr.donePercentage);
      }, 0) / steps.length
    ).toFixed(0);
  };

  changeSortingParam = param => () => {
    this.setState({ sortedBy: param, isSortedDesc: !this.state.isSortedDesc });
  };

  sortBy = steps => {
    const { sortedBy, isSortedDesc } = this.state;
    return steps.sort((a, b) => {
      if (isSortedDesc) {
        if (sortedBy === "category") {
          return b[sortedBy].localeCompare(a[sortedBy]);
        }
        return b[sortedBy] - a[sortedBy];
      }
      if (sortedBy === "category") {
        return a[sortedBy].localeCompare(b[sortedBy]);
      }
      return a[sortedBy] - b[sortedBy];
    });
  };

  displayArrow = param => {
    return this.state.sortedBy === param ? (
      this.state.isSortedDesc ? (
        <ExpandLessIcon />
      ) : (
        <ExpandMoreIcon />
      )
    ) : null;
  };

  getTotalViews = doneSteps => {
    const totalViews = doneSteps.reduce((prev, curr) => {
      return prev + curr.views;
    }, 0);
    return totalViews;
  };

  render() {
    const {
      classes,
      doneSteps,
      totalDone,
      isSuperAdmin,
      language,
    } = this.props;
    const totalViews = this.getTotalViews(doneSteps);

    return (
      <div className={classes.rightDiv}>
        <div className={classes.firstSection} onScroll={this.handleScroll}>
          <div className={classes.topStats}>
            <StatBox
              explanation={i18n.t("done-actions-explanation")}
              iconBackgroundColor={"rgba(238, 134, 13, 0.22)"}
              icon={<LightIcon color="#F9A849" />}
              stat={totalDone}
              title={totalDone === 1 ? i18n.t("Action") : i18n.t("Actions")}
            />
            <StatBox
              explanation={i18n.t("view-actions-explanation")}
              iconBackgroundColor={"#D0F1FF"}
              icon={<ViewIcon color={Colors.pureMain} />}
              stat={totalViews}
              title={totalViews === 1 ? i18n.t("View") : i18n.t("Views")}
            />
            <StatBox
              explanation={i18n.t("rating-explanation")}
              iconBackgroundColor={Colors.lightOrange}
              icon={<StarIcon color={Colors.orange} sizePercentage={0.8} />}
              stat={this.getMediumRating(doneSteps)}
              title={i18n.t("MediumRating")}
            />
            <StatBox
              explanation={i18n.t("completion-rate-explanation")}
              iconBackgroundColor={"#DEF9EA"}
              icon={<CheckIcon color={"#7BEC9C"} sizePercentage={1.1} />}
              stat={`${this.getDonePercentageMean(doneSteps)}%`}
              title={i18n.t("DonePercentageMean")}
            />
            <StatBox
              isLast
              explanation={i18n.t("comments-explanation")}
              iconBackgroundColor={"#FFDAE2"}
              icon={<CommentsIcon color={"#EA87B7"} />}
              stat={this.getCommentsNumber(doneSteps)}
              title={i18n.t("Comments")}
            />
          </div>
          <ActionsTable
            doneSteps={doneSteps}
            topRightComponent={
              isSuperAdmin && (
                <CSVLink
                  style={{ textDecoration: "none" }}
                  filename={`${i18n.t("csv-action-name", {
                    numberOfAction: doneSteps.length,
                    language,
                  })}.csv`}
                  headers={CSVHeaders(language)}
                  target="_blank"
                  data={formatDataForExport(doneSteps, language)}
                >
                  <MainText
                    className={classes.link}
                    text={i18n.t("export-csv")}
                  />
                </CSVLink>
              )
            }
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const language = getAppLanguage(state);
  const isSuperAdmin = getIsSuperAdmin(state);
  const { timezone } = state.user;
  const userId = getUserId(state);
  const clientId = getSelectedClientId(state);
  const trackId = getCampaignSelectedTrackId(state);
  const { isLoading, doneSteps = [] } = state.campaign;

  const campaignSelected = getCampaignSelected(state);
  const userCampaigns = getUserCampaigns(state);
  const { participants = [], createdAt } = campaignSelected;
  const campaignId = campaignSelected?._id;
  const totalDone = doneSteps?.length;

  return {
    isLoading,
    campaignId,
    trackId,
    language,
    userId,
    timezone,
    clientId,
    participants,
    totalDone,
    doneSteps: getConsolidatedCampaignSteps(state),
    createdAt,
    userCampaigns,
    isSuperAdmin,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    getUserCampaignsListRequest,
    getCampaignStepsRequest,
    getCampaignCommentsRequest,
  })(ActionsSide),
);
