export const SIGNUP_CREATE_USER_REQUESTING = "SIGNUP_CREATE_USER_REQUESTING";
export const SIGNUP_CREATE_USER_SUCCESS = "SIGNUP_CREATE_USER_SUCCESS";
export const SIGNUP_CREATE_USER_ERROR = "SIGNUP_CREATE_USER_ERROR";

export const SIGNUP_CREATE_CLIENT_REQUESTING =
  "SIGNUP_CREATE_CLIENT_REQUESTING";
export const SIGNUP_CREATE_CLIENT_SUCCESS = "SIGNUP_CREATE_CLIENT_SUCCESS";
export const SIGNUP_CREATE_CLIENT_ERROR = "SIGNUP_CREATE_CLIENT_ERROR";

export const SIGNUP_SEND_VERIFICATION_EMAIL_REQUESTING =
  "SIGNUP_SEND_VERIFICATION_EMAIL_REQUESTING";
export const SIGNUP_SEND_VERIFICATION_EMAIL_SUCCESS =
  "SIGNUP_SEND_VERIFICATION_EMAIL_SUCCESS";
export const SIGNUP_SEND_VERIFICATION_EMAIL_ERROR =
  "SIGNUP_SEND_VERIFICATION_EMAIL_ERROR";

export const CHANGE_FIELD = "CHANGE_FIELD";

export const SIGNUP_CLOSE_SNACK_MESSAGE = "SIGNUP_CLOSE_SNACK_MESSAGE";

export const SIGNUP_CONFIRM_EMAIL_REQUESTING =
  "SIGNUP_CONFIRM_EMAIL_REQUESTING";
export const SIGNUP_CONFIRM_EMAIL_SUCCESS = "SIGNUP_CONFIRM_EMAIL_SUCCESS";
export const SIGNUP_CONFIRM_EMAIL_ERROR = "SIGNUP_CONFIRM_EMAIL_ERROR";

export const INVITATION_CREATE_USER_REQUESTING =
  "INVITATION_CREATE_USER_REQUESTING";
export const INVITATION_CREATE_USER_SUCCESS = "INVITATION_CREATE_USER_SUCCESS";
export const INVITATION_CREATE_USER_ERROR = "INVITATION_CREATE_USER_ERROR";

export const SIGNUP_ONBOARDING_END_REQUESTING =
  "SIGNUP_ONBOARDING_END_REQUESTING";
export const SIGNUP_ONBOARDING_END_ERROR = "SIGNUP_ONBOARDING_END_ERROR";
export const SIGNUP_ONBOARDING_END_SUCCESS = "SIGNUP_ONBOARDING_END_SUCCESS";
