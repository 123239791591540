import React, { useState } from "react";
import { TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Colors from "constants/Colors";
import i18n from "i18n";
import {
  getSelectedQuestion,
  getSurveyLanguage,
} from "services/survey/selectors";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  main: {},
  title: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 14,
    color: Colors.darkBlue,
    marginBottom: 25,
  },
  input: {
    minHeight: 150,
    paddingTop: 15,
    paddingLeft: 20,
    paddingRight: 10,
    borderRadius: 6,
    flex: 1,
    height: "100%",
    backgroundColor: "#F9F9F9",
    width: "100%",
  },
}));

const PreviewFreeAnswer = () => {
  const question = useSelector(getSelectedQuestion);
  const languageSelected = useSelector(getSurveyLanguage);
  const { questionTitle = { fr: "", en: "" } } = question;

  const [answer, setAnswer] = useState("");

  const handleAnswerChange = e => {
    const value = e.target.value;
    setAnswer(value);
  };

  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Typography className={classes.title}>
        {questionTitle[languageSelected]}
      </Typography>

      <TextField
        variant="standard"
        InputProps={{
          disableUnderline: true,
        }}
        placeholder={i18n.t("write-your-answer")}
        multiline
        onChange={handleAnswerChange}
        className={classes.input}
        value={answer}
      />
    </div>
  );
};

export default PreviewFreeAnswer;
