import React from "react";
import withStyles from '@mui/styles/withStyles';
import Button from "@mui/material/Button";
import Colors from "../constants/Colors";

const RoundButton = withStyles({
  root: {
    padding: "10px 12px 10px 12px",
    // height: "36px",
    boxShadow: "none",
    textTransform: "none",
    minWidth: 150,
    fontSize: 15,
    fontWeight: 700,
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    // padding: "6px 12px",
    color: Colors.white,
    // border: "1px solid",
    lineHeight: 1.5,
    opacity: 1,
    borderRadius: 6,
    backgroundColor: Colors.pureMain,
    borderColor: Colors.pureMain,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      opacity: 0.9,
      borderColor: Colors.pureMain,
      backgroundColor: Colors.pureMain,
    },
    "&:active": {
      boxShadow: "none",
      borderColor: Colors.pureMain,
      backgroundColor: Colors.pureMain,
      opacity: 0.9,
    },
    "&:focus": {
      // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)"
      boxShadow: `0 0 0 0.2rem ${Colors.lightMain}`,
      borderColor: Colors.pureMain,
      backgroundColor: Colors.pureMain,
    },
  },
  disabled: {
    fontWeight: "normal",
    color: Colors.black,
  },
  outlined: {
    backgroundColor: "transparent",
    border: "1px solid",
    borderColor: "rgba(29, 28, 29, 0.3)",
  },
})(Button);

export default function CustomizedButtons({
  title,
  onClick,
  disabled,
  className,
  fullWidth,
  outlined,
  classes,
}) {
  return (
    <RoundButton
      className={className}
      disabled={disabled}
      fullWidth={fullWidth}
      variant="contained"
      color="primary"
      disableRipple
      onClick={onClick}
    >
      {title}
    </RoundButton>
  );
}
