import React, { useState } from "react";
import withStyles from '@mui/styles/withStyles';
import Colors from "../constants/Colors";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import i18n from "../i18n";
import DeleteIcon from "../scenes/CreateTrack/DeleteIcon";
import getUserRoles from "../utils/getUserRoles";

const styles = {
  main: {
    position: "relative",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  text: {
    marginTop: 20,
    color: Colors.white,
    fontSize: 16,
  },
  textField: {
    width: "33%",
    marginLeft: 10,
    marginRight: 10,
  },
  addButton: {
    right: 0,
    cursor: "pointer",
    zIndex: 10,
    padding: 10,
    backgroundColor: Colors.green,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  removeButton: {
    padding: 7,
    backgroundColor: Colors.mediumGrey,
  },
  invisible: {
    opacity: 0,
    zIndex: -2,
  },
  roleExplanation: {
    padding: 0,
    marginLeft: 5,
    fontSize: 10,
    position: "relative",
  },
  menuItem: {},
};

/*const userRoles = () => [
  { label: i18n.t("role-user"), value: "user" },
  {
    label: i18n.t("role-administrator"),
    value: "admin",
    explanation: i18n.t("explanation-role-administrator"),
  },
];*/

const InviteEmailUserInput = ({
  classes,
  language,
  emailValue,
  emailLabel,
  roleValue,
  roleLabel,
  onChangeEmail,
  onChangeRole,
  onClickDelete,
  forbiddenRoles,
}) => {
  const [isHovered, setHover] = useState(false);
  return (
    <div className={classes.main}>
      <TextField
        className={classes.textField}
        autoComplete="email"
        fullWidth
        placeholder={i18n.t("email-label")}
        label={emailLabel}
        value={emailValue}
        onChange={onChangeEmail}
        margin="normal"
        variant="outlined"
      />
      <TextField
        className={classes.textField}
        select
        fullWidth
        label={roleLabel}
        value={roleValue}
        onChange={event => {
          setHover(false);
          onChangeRole(event);
        }}
        margin="normal"
        variant="outlined"
      >
        {getUserRoles({ forbiddenRoles, language }).map(option => (
          <MenuItem
            key={option.value}
            value={option.value}
            className={classes.menuItem}
            onMouseLeave={() => setHover(false)}
            onMouseEnter={() => setHover(true)}
          >
            {option.label}
            {option.explanation && isHovered && (
              <p className={classes.roleExplanation}>{option.explanation}</p>
            )}
          </MenuItem>
        ))}
      </TextField>
      <div
        className={`${classes.addButton} ${classes.removeButton} ${
          !onClickDelete && classes.invisible
        }`}
        onClick={onClickDelete}
      >
        <DeleteIcon sizePercentage={0.6} />
      </div>
    </div>
  );
};

InviteEmailUserInput.defaultProps = {
  emailLabel: i18n.t("email"),
  roleLabel: i18n.t("userRole"),
  forbiddenRoles: [],
};

export default withStyles(styles)(InviteEmailUserInput);
