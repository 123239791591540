import React from "react";
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  main: {},
});

const LinkIcon = ({
  className = "",
  width = 41,
  height = 41,
  color = "#5FB8F9",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 41 41"
      fill="none"
      className={className}
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M17.3928 25.7042C17.1194 25.7047 16.8486 25.6512 16.596 25.5466C16.3434 25.442 16.114 25.2886 15.9209 25.095C14.0939 23.2629 13.0679 20.7811 13.0679 18.1937C13.0679 15.6063 14.0939 13.1245 15.9209 11.2924L23.6004 3.61287C25.4435 1.76979 27.8958 0.75354 30.5017 0.75354C33.1076 0.75354 35.5599 1.76979 37.403 3.61287C39.23 5.44496 40.256 7.92678 40.256 10.5142C40.256 13.1016 39.23 15.5834 37.403 17.4155L33.8935 20.925C33.4989 21.292 32.9773 21.4916 32.4385 21.4818C31.8997 21.4721 31.3857 21.2537 31.0046 20.8726C30.6236 20.4916 30.4052 19.9776 30.3954 19.4388C30.3856 18.9 30.5852 18.3784 30.9522 17.9838L34.4618 14.4742C35.2424 13.69 35.7735 12.6923 35.9882 11.6067C36.2028 10.5212 36.0914 9.39639 35.668 8.37405C35.2445 7.35172 34.528 6.47756 33.6086 5.86171C32.6893 5.24585 31.6083 4.91585 30.5017 4.91327C29.0068 4.91327 27.5989 5.49691 26.5416 6.55412L18.8621 14.2336C17.8133 15.2846 17.2242 16.7088 17.2242 18.1937C17.2242 19.6785 17.8133 21.1027 18.8621 22.1537C19.1524 22.4449 19.35 22.8156 19.4298 23.2189C19.5097 23.6223 19.4683 24.0402 19.3108 24.4201C19.1533 24.7999 18.8869 25.1246 18.5451 25.3531C18.2033 25.5817 17.8014 25.7038 17.3902 25.7042H17.3928Z"
        fill={color}
      />
      <path
        d="M10.0229 40.7535C8.74066 40.7567 7.47051 40.5057 6.28592 40.0149C5.10133 39.5241 4.02581 38.8033 3.12158 37.8941C1.29455 36.0621 0.268555 33.5802 0.268555 30.9928C0.268555 28.4054 1.29455 25.9236 3.12158 24.0915L6.63111 20.582C7.02573 20.215 7.54732 20.0154 8.08612 20.0252C8.62492 20.0349 9.13893 20.2533 9.51998 20.6344C9.90104 21.0154 10.1194 21.5294 10.1292 22.0682C10.139 22.607 9.93935 23.1286 9.57236 23.5232L6.06283 27.0328C5.28217 27.817 4.75107 28.8148 4.53643 29.9003C4.32178 30.9858 4.4332 32.1106 4.85664 33.133C5.28008 34.1553 5.99661 35.0295 6.91595 35.6453C7.83529 36.2612 8.91634 36.5912 10.0229 36.5938C11.5204 36.5938 12.9257 36.0101 13.983 34.9529L21.6624 27.2734C22.7113 26.2224 23.3004 24.7982 23.3004 23.3133C23.3004 21.8285 22.7113 20.4043 21.6624 19.3533C21.2955 18.9587 21.0958 18.4371 21.1056 17.8983C21.1154 17.3595 21.3338 16.8455 21.7148 16.4644C22.0959 16.0834 22.6099 15.865 23.1487 15.8552C23.6875 15.8454 24.2091 16.045 24.6037 16.412C26.4307 18.2441 27.4567 20.7259 27.4567 23.3133C27.4567 25.9007 26.4307 28.3826 24.6037 30.2146L16.9242 37.8941C16.02 38.8033 14.9445 39.5241 13.7599 40.0149C12.5753 40.5057 11.3051 40.7567 10.0229 40.7535Z"
        fill={color}
      />
    </svg>
  );
};

export default withStyles(styles)(LinkIcon);
