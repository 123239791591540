import {
  MY_CLIENTS_GET_ALL_REQUESTING,
  MY_CLIENTS_SELECT,
  MY_CLIENTS_DELETE_REQUESTING,
  MY_CLIENTS_CREATE_NEW_REQUESTING,
  MY_CLIENTS_MODIFY_REQUESTING,
  MY_CLIENTS_GET_SELECTED_REQUESTING,
  MY_CLIENTS_GET_ACTIVE_USERS_REQUESTING,
} from "./constants";

export const modifyMyClientRequest = (payload: {
  clientId: string;
  organization: string;
}) => {
  return {
    type: MY_CLIENTS_MODIFY_REQUESTING,
    payload,
  };
};

export const createMyClientRequest = (payload: {
  clientId: string;
  organization: string;
}) => {
  return {
    type: MY_CLIENTS_CREATE_NEW_REQUESTING,
    payload,
  };
};

export const getAllMyClientsRequest = (payload: string) => {
  return {
    type: MY_CLIENTS_GET_ALL_REQUESTING,
    payload,
  };
};

export const selectClient = (payload: string) => {
  return {
    type: MY_CLIENTS_SELECT,
    payload,
  };
};

export const deleteMyClientRequest = (payload: { clientId: string }) => {
  return {
    type: MY_CLIENTS_DELETE_REQUESTING,
    payload,
  };
};

export const getSelectedClientRequest = (payload: { clientId: string }) => {
  return {
    type: MY_CLIENTS_GET_SELECTED_REQUESTING,
    payload,
  };
};

export const getActiveUsersRequest = (payload: { clientId: string }) => {
  return {
    type: MY_CLIENTS_GET_ACTIVE_USERS_REQUESTING,
    payload,
  };
};
