import React from "react";

const ArchiveIcon = ({ sizePercentage, color }) => (
  <svg
    width={sizePercentage * 13}
    height={sizePercentage * 11}
    viewBox="0 0 13 11"
    preserveAspectRatio="xMidYMid meet"
  >
    <path
      d="M11.4556 3.35938V10.3957H1.71301V3.35938"
      stroke="white"
      strokeWidth="0.9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.630554 0.653076H12.5381V3.35934H0.630554V0.653076Z"
      stroke="white"
      strokeWidth="0.9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.50183 5.52435H7.66684"
      stroke="white"
      strokeWidth="0.9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

ArchiveIcon.defaultProps = {
  sizePercentage: 1.3,
  color: "#F4F6F9",
};

export default ArchiveIcon;
