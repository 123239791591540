import React from "react";
import withStyles from '@mui/styles/withStyles';
import i18n from "../i18n";
import CopyIcon from "@mui/icons-material/FileCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Typography } from "@mui/material";
import { openSnackMessage } from "../services/general/actions";
import { connect } from "react-redux";
import Colors from "../constants/Colors";
import beautifyCode from "utils/beautifyCode";

const styles = theme => ({
  main: {},
  codeDiv: {
    zIndex: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    marginBottom: 20,
  },
  codeRightDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  codeExplanation: {},
  code: {
    fontSize: 20,
    // backgroundColor: Colors.mediumGrey,
    backgroundColor: Colors.lightGrey,
    borderRadius: 4,
    padding: "5px 10px",
  },
  copyButton: {
    color: "#3A4554",
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 10,
    borderRadius: 4,
    fontSize: 20,
    backgroundColor: Colors.lightGrey,
    padding: "5px 10px",
  },
  copyText: {
    marginLeft: 3,
  },
});

const CopyAccessCode = ({ classes, accessCode, openSnackMessage }) => {
  const beautifulCode = beautifyCode(accessCode);

  return (
    <div className={classes.codeDiv}>
      <Typography className={classes.code}>{beautifulCode}</Typography>
      <CopyToClipboard
        text={beautifulCode}
        onCopy={() =>
          openSnackMessage({
            snackMessage: i18n.t("access-code-copied"),
          })
        }
      >
        <div className={classes.copyButton}>
          <CopyIcon />
          <span className={classes.copyText}>{i18n.t("copy")} </span>
        </div>
      </CopyToClipboard>
    </div>
  );
};

export default connect(null, { openSnackMessage })(
  withStyles(styles)(CopyAccessCode),
);
