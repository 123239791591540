import React from "react";
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  main: {},
});

interface Props {
  className?: string;
  color?: string;
}

const AddIcon = ({ className = "", color = "#17AFFC" }: Props) => {
  return (
    <svg
      className={className}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.62352 7.62851V0.755371H7.65976V7.62851H0.786621V9.59227H7.65976V16.4654H9.62352V9.59227H16.4967V7.62851H9.62352Z"
        fill={color}
      />
    </svg>
  );
};

export default withStyles(styles)(AddIcon);
