import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import PlusIcon from "components/PlusIcon";
import FlatButton from "components/FlatButton";

const useStyles = makeStyles(theme => ({
  main: {},
  buttonIcon: {
    marginRight: 10,
  },
}));

interface Props {
  title: string;
  onClick: (any) => void;
  disabled?: boolean;
  className?: string;
  fullWidth?: boolean;
  requesting?: boolean;
}

const AddButton = ({ title, onClick, className, ...rest }: Props) => {
  const classes = useStyles();

  return (
    <FlatButton
      title={title}
      onClick={onClick}
      className={className}
      icon={<PlusIcon color={"#fff"} className={classes.buttonIcon} />}
      {...rest}
    />
  );
};

export default AddButton;
