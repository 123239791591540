import React from "react";

const SeeGridIcon = ({ color = "#CBCBCB" }) => (
  <svg
    width="24"
    height="12"
    viewBox="0 0 24 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.46711 9.36823C7.46711 9.25777 7.37757 9.16823 7.26711 9.16823H5.21746C5.107 9.16823 5.01746 9.25777 5.01746 9.36823V11.4179C5.01746 11.5283 5.107 11.6179 5.21746 11.6179H7.26711C7.37757 11.6179 7.46711 11.5283 7.46711 11.4179V9.36823Z"
      fill="#707781"
    />
    <path
      d="M2.97627 0.386157C2.97627 0.2757 2.88673 0.186157 2.77627 0.186157H0.726611C0.616154 0.186157 0.526611 0.2757 0.526611 0.386157V2.43582C0.526611 2.54627 0.616154 2.63582 0.726611 2.63582H2.77627C2.88673 2.63582 2.97627 2.54627 2.97627 2.43582V0.386157Z"
      fill="#707781"
    />
    <path
      d="M7.46711 0.386157C7.46711 0.2757 7.37757 0.186157 7.26711 0.186157H5.21746C5.107 0.186157 5.01746 0.2757 5.01746 0.386157V2.43582C5.01746 2.54627 5.107 2.63582 5.21746 2.63582H7.26711C7.37757 2.63582 7.46711 2.54627 7.46711 2.43582V0.386157Z"
      fill="#707781"
    />
    <path
      d="M11.9582 0.386157C11.9582 0.2757 11.8687 0.186157 11.7582 0.186157H9.70854C9.59809 0.186157 9.50854 0.2757 9.50854 0.386157V2.43582C9.50854 2.54627 9.59809 2.63582 9.70854 2.63582H11.7582C11.8687 2.63582 11.9582 2.54627 11.9582 2.43582V0.386157Z"
      fill="#707781"
    />
    <path
      d="M2.97615 4.8772C2.97615 4.76674 2.8866 4.6772 2.77615 4.6772H0.726489C0.616032 4.6772 0.526489 4.76674 0.526489 4.8772V6.92686C0.526489 7.03732 0.616032 7.12686 0.726489 7.12686H2.77615C2.8866 7.12686 2.97615 7.03732 2.97615 6.92686V4.8772Z"
      fill="#707781"
    />
    <path
      d="M7.46711 4.8772C7.46711 4.76674 7.37757 4.6772 7.26711 4.6772H5.21746C5.107 4.6772 5.01746 4.76674 5.01746 4.8772V6.92686C5.01746 7.03732 5.107 7.12686 5.21746 7.12686H7.26711C7.37757 7.12686 7.46711 7.03732 7.46711 6.92686V4.8772Z"
      fill="#707781"
    />
    <path
      d="M11.9582 4.8772C11.9582 4.76674 11.8687 4.6772 11.7582 4.6772H9.70854C9.59809 4.6772 9.50854 4.76674 9.50854 4.8772V6.92686C9.50854 7.03732 9.59809 7.12686 9.70854 7.12686H11.7582C11.8687 7.12686 11.9582 7.03732 11.9582 6.92686V4.8772Z"
      fill="#707781"
    />
    <path
      d="M2.97615 9.36823C2.97615 9.25777 2.8866 9.16823 2.77615 9.16823H0.726489C0.616032 9.16823 0.526489 9.25777 0.526489 9.36823V11.4179C0.526489 11.5283 0.616032 11.6179 0.726489 11.6179H2.77615C2.8866 11.6179 2.97615 11.5283 2.97615 11.4179V9.36823Z"
      fill="#707781"
    />
    <path
      d="M11.9582 9.36823C11.9582 9.25777 11.8687 9.16823 11.7582 9.16823H9.70854C9.59809 9.16823 9.50854 9.25777 9.50854 9.36823V11.4179C9.50854 11.5283 9.59809 11.6179 9.70854 11.6179H11.7582C11.8687 11.6179 11.9582 11.5283 11.9582 11.4179V9.36823Z"
      fill="#707781"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1387 4.25269C18.1723 4.21905 18.2121 4.19237 18.256 4.17416C18.2999 4.15595 18.3469 4.14658 18.3944 4.14658C18.4419 4.14658 18.489 4.15595 18.5329 4.17416C18.5767 4.19237 18.6166 4.21905 18.6501 4.25269L20.5616 6.16483L22.473 4.25269C22.5408 4.18487 22.6328 4.14677 22.7287 4.14677C22.8246 4.14677 22.9166 4.18487 22.9844 4.25269C23.0522 4.32051 23.0903 4.4125 23.0903 4.50841C23.0903 4.60433 23.0522 4.69631 22.9844 4.76413L20.8173 6.93127C20.7837 6.96491 20.7439 6.99159 20.7 7.0098C20.6561 7.02801 20.6091 7.03738 20.5616 7.03738C20.5141 7.03738 20.467 7.02801 20.4231 7.0098C20.3793 6.99159 20.3394 6.96491 20.3058 6.93127L18.1387 4.76413C18.1051 4.73058 18.0784 4.69072 18.0602 4.64684C18.042 4.60296 18.0326 4.55592 18.0326 4.50841C18.0326 4.4609 18.042 4.41386 18.0602 4.36998C18.0784 4.3261 18.1051 4.28624 18.1387 4.25269Z"
      fill="#707781"
    />
  </svg>
);

export default SeeGridIcon;
