import React from "react";
import { makeStyles } from "@mui/styles";
import ReviewFile from "../ReviewsSide/ReviewFile";
import getFileFormat from "utils/getFileFormat";
import { Avatar, Typography } from "@mui/material";
import LikeIcon from "components/Icons/LikeIcon";
import i18n from "i18n";
import DotsIcon from "components/Icons/DotsIcon";
import DotMenu from "components/DotMenu";
import EyeClosedIcon from "../LearnerSide/EyeClosedIcon";
import DeleteIcon from "scenes/Campaigns/DeleteIcon";
import { useDispatch } from "react-redux";
import {
  hideStepCommentRequest,
  addLikeToCommentary,
  removeLikeFromCommentary,
} from "services/campaign/actions";

const useStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: 25,
    paddingTop: 25,
    paddingLeft: 25,
    paddingRight: 25,
  },
  leftDiv: {
    alignSelf: "flex-start",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  rightDiv: {
    width: 280,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  header: {
    width: 280,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    marginBottom: 8,
  },
  statusDiv: {
    marginLeft: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    backgroundColor: "rgba(240, 240, 245, 1)",
    padding: "1px 6px",
    borderRadius: 6,
  },
  statusText: {
    fontSize: 12,
    marginLeft: 5,
  },
  avatar: {
    marginTop: 2,
    height: 24,
    width: 24,
    borderRadius: "50%",
    marginRight: 8,
  },
  dott: {
    backgroundColor: "rgba(251, 145, 145, 1)",
    borderRadius: 3,
    height: 6,
    width: 6,
  },
  greenDott: {
    backgroundColor: "rgba(76, 188, 48, 1)",
  },
  dotsDiv: {
    width: "100%",
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  comment: {
    fontSize: 14,
    fontWeight: 400,
    color: "#000528",
  },
  like: {
    color: "rgba(0, 126, 252, 1)",
    marginLeft: 8,
    fontSize: 14,
    fontWeight: 700,
  },
  likeDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  likeRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  responsesRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  bottomRow: {
    marginTop: 16,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  responsesText: {
    marginRight: 8,
    fontWeight: 600,
    fontSize: 14,
  },
}));

interface Props {
  file: any;
  comment: string;
  userName: string;
  userPicture: string;
  responses: any[] | undefined;
  stepCommentId: string;
  userId: string;
  like: {
    users: string[];
    numberOfLike: number;
  };
}

const UserComment = ({
  file = {
    fileUrl: "",
    fileType: "",
    filePreview: "",
    fileName: "",
    createdAt: "",
  },
  like,
  comment,
  userName,
  userPicture,
  responses,
  stepCommentId,
  userId,
}: Props) => {
  const fileType = getFileFormat(file.fileType);

  const classes = useStyles();

  const commentStatus =
    responses instanceof Array ? "published" : "not-published";

  const dispatch = useDispatch();
  const hideUserComment = () => {
    dispatch(hideStepCommentRequest({ stepCommentId }));
  };

  const MENU_OPTIONS = [
    {
      text: i18n.t("menu-hide-user-comment"),
      icon: <EyeClosedIcon />,
      onClick: hideUserComment,
    },
  ];

  const commentIsLike = like?.users?.some(el => el === userId);

  const handleClickLike = () => {
    if (commentIsLike) {
      dispatch(
        removeLikeFromCommentary({
          stepCommentId,
          userId,
        }),
      );
    } else {
      dispatch(addLikeToCommentary({ stepCommentId: stepCommentId, userId }));
    }
  };

  return (
    <div className={classes.main}>
      <div className={classes.leftDiv}>
        <Avatar src={userPicture} className={classes.avatar} />
      </div>
      <div className={classes.rightDiv}>
        <div className={classes.header}>
          <Typography>{userName}</Typography>
          <div className={classes.statusDiv}>
            <div
              className={`${classes.dott} ${
                commentStatus === "published" && classes.greenDott
              }`}
            />
            <Typography className={classes.statusText}>
              {i18n.t(`step-comment-${commentStatus}`)}
            </Typography>
          </div>

          <div className={classes.dotsDiv}>
            {commentStatus === "published" && (
              <DotMenu options={MENU_OPTIONS} />
            )}
          </div>
        </div>
        {!!comment ? (
          <Typography className={classes.comment}>{comment}</Typography>
        ) : (
          <ReviewFile
            hasFileData={false}
            createdAt={file.createdAt}
            fileName={file.fileName}
            fileType={fileType}
            fileUrl={file.fileUrl}
            filePreview={file.filePreview}
          />
        )}
        <div className={classes.bottomRow}>
          <div className={classes.likeRow}>
            <div
            // onClick={handleClickLike}
            >
              <LikeIcon color={"#B0B2BD"} height={16} width={16} />
            </div>
            {like?.numberOfLike > 0 && (
              <div className={classes.likeDiv}>
                <Typography className={classes.like}>
                  {like?.numberOfLike}
                </Typography>
              </div>
            )}
          </div>
          {!!responses?.length && (
            <div className={classes.responsesRow}>
              <Typography className={classes.responsesText}>
                {responses?.length}
              </Typography>
              <Typography>{i18n.t("comments")}</Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserComment;
