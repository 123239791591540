import { call, put, takeLatest, select } from "redux-saga/effects";
import { AUTH_LOGOUT } from "../auth/constants";

import { TOKEN_REFRESH } from "./constants";

import { fetchApi } from "../api";

import { setToken } from "./actions";
import { getToken } from "./selectors";
import { getUserEmail } from "services/user/selectors";

const endPoints = {
  refresh: "/v1/auth/refresh-token",
};

// const SESSION_TIMEOUT_THRESHOLD = 5; // refresh token every hour
// const SESSION_TIMEOUT_THRESHOLD = 3600; // refresh token every hour

type refreshData = {
  email: string;
  refreshToken: string;
};

const refreshToken = async ({ token, email }) => {
  const data: refreshData = {
    email,
    refreshToken: token.refreshToken,
  };
  if (!token.refreshToken) {
    throw new Error("No refreshToken");
  }

  return fetchApi(endPoints.refresh, data, "post");
};

// const setSessionTimeout = time => {
//   clearTimeout(sessionTimeout);
//
//
//   sessionTimeout = setTimeout(refreshFlow(), time);
// };

function* refreshFlow() {
  try {
    const tokenState = yield select(getToken);
    const email = yield select(getUserEmail);
    const newToken = yield call(refreshToken, { token: tokenState, email });
    // inform Redux to set our client token, this is non blocking so...
    yield put(setToken(newToken));
    // const expireTime = moment(token.expiresAt).subtract(5, "days");
    // const time = expireTime.diff(moment());
    // .subtract(50, "seconds")
    // .diff());

    // yield delay(time);
    // yield put({ type: TOKEN_REFRESH, meta: { retry: true } });
  } catch (error) {
    // yield put({ type: TOKEN_REFRESH });
    // if the api call fails, it will "put" the LOGIN_ERROR
    // into the dispatch along with the error.
    // clearTimeout(sessionTimeout);

    if (error.message === "Incorrect email or refreshToken") {
      yield put({ type: AUTH_LOGOUT });
    }
    // else if (error.message === "No refreshToken") {
    //
    // } else {
    //
    //   // retry one minute later (maybe offline or else)
    //   yield delay(60000);
    //   yield put({ type: TOKEN_REFRESH, meta: { retry: true } });
    // }
  }
}

function* refreshWatcher() {
  // yield take([LOGIN_SUCCESS, SIGNUP_SUCCESS, TOKEN_REFRESH]);
  yield takeLatest([TOKEN_REFRESH], refreshFlow);
}

export default refreshWatcher;
