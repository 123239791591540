import {
  OPEN_SNACK_MESSAGE,
  CLOSE_SNACK_MESSAGE,
  CHANGE_GENERAL_SETTINGS,
  MY_MOBILE_CONNECTION,
  UPDATE_ADMIN_CONNECTION,
} from "./constants";
import getNavigatorLanguage from "../../utils/getNavigatorLanguage";
import { AUTH_LOGOUT_SUCCESS } from "../auth/constants";

const initialState = {
  language: getNavigatorLanguage(),
  adminConnections: [], // { userId, picture, firstName, isConnected}
  tabSelected: 0,
  accessCode: null,
  isInviteUsersModalVisible: false,
  snackMessage: null,
  snackStatus: null, // alert
  requesting: false,
  isFirstConnection: true,
  isMyMobileConnected: false,
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_SNACK_MESSAGE:
      return {
        ...state,
        ...action.payload,
      };

    case CLOSE_SNACK_MESSAGE: {
      return { ...state, snackMessage: null, snackStatus: null };
    }

    case CHANGE_GENERAL_SETTINGS: {
      return { ...state, ...action.payload };
    }

    case UPDATE_ADMIN_CONNECTION: {
      const adminConnections = state.adminConnections.map(el => {
        if (el.userId === action.payload.userId) {
          return {
            ...el,
            ...action.payload,
            clientId: undefined,
          };
        }
        return el;
      });

      return { ...state, adminConnections };
    }

    case MY_MOBILE_CONNECTION: {
      return {
        ...state,
        isMyMobileConnected: action.payload.connected,
      };
    }

    case AUTH_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
