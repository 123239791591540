import React from "react";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import i18n from "i18n";
import Colors from "constants/Colors";

const useStyles = makeStyles(theme => ({
  main: {},
  tableHeaderRow: {
    paddingLeft: 35,
    marginRight: 25,
    fontWeight: "bold",
    fontFamily: "Montserrat",
    color: "#282828",
    fontSize: 16,
    width: "100%",
    // backgroundColor: "#fff",
    // marginBottom: 30,
    marginTop: 30,
    // borderRadius: 8,
    display: "flex",
    alignItems: "space-between",
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: 15,
    // borderBottom: `solid 1px #DEDEDE`,
  },
  row: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  firstRow: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },

  emptyRow: {
    flex: 0.5,
  },
  tableHeader: {
    fontSize: 18,
    fontWeight: 500,
    marginRight: 3,
    color: Colors.darkBlue,
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": {
      textDecoration: "underline",
    },
    // color: "#fff"
  },
}));

interface Props {
  sortedBy: string;
  isSortedDesc: boolean;
  changeSortingParam: (string) => () => void;
}

const InvoiceTableHeader = ({
  sortedBy,
  isSortedDesc,
  changeSortingParam,
}: Props) => {
  const classes = useStyles();

  const displayArrow = param => {
    return sortedBy === param ? (
      isSortedDesc ? (
        <ExpandLessIcon />
      ) : (
        <ExpandMoreIcon />
      )
    ) : null;
  };

  return (
    <div className={classes.tableHeaderRow}>
      <div
        className={classes.firstRow}
        onClick={changeSortingParam("invoiceName")}
      >
        <Typography className={classes.tableHeader}>
          {i18n.t("table-header-invoiceName")}
        </Typography>
        {displayArrow("invoiceName")}
      </div>
      <div className={classes.row} onClick={changeSortingParam("companyName")}>
        <Typography className={classes.tableHeader}>
          {i18n.t("table-header-companyName")}
        </Typography>
        {displayArrow("companyName")}
      </div>
      <div
        className={classes.row}
        onClick={changeSortingParam("invoiceDueDate")}
      >
        <Typography className={classes.tableHeader}>
          {i18n.t("table-header-invoiceDueDate")}
        </Typography>
        {displayArrow("invoiceDueDate")}
      </div>
      <div className={classes.row} onClick={changeSortingParam("invoiceTitle")}>
        <Typography className={classes.tableHeader}>
          {i18n.t("table-header-invoiceTitle")}
        </Typography>
        {displayArrow("invoiceTitle")}
      </div>
      <div className={classes.row} onClick={changeSortingParam("totalAmount")}>
        <Typography className={classes.tableHeader}>
          {i18n.t("table-header-totalAmount")}
        </Typography>
        {displayArrow("totalAmount")}
      </div>
      <div className={classes.row} onClick={changeSortingParam("isPaid")}>
        <Typography className={classes.tableHeader}>
          {i18n.t("table-header-isPaid")}
        </Typography>
        {displayArrow("isPaid")}
      </div>
      <div className={classes.emptyRow} />
    </div>
  );
};

export default InvoiceTableHeader;
