import React, { PureComponent } from "react";
import { connect } from "react-redux";

import withStyles from '@mui/styles/withStyles';

import Tab from "./Tab";
import MyClientsList from "./MyClientsList";

const styles = theme => ({
  main: {
    flex: 1,
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // paddingTop: 20,
    paddingBottom: 100,
  },
});

class MyClients extends PureComponent {
  state = {
    tabSelected: 0,
  };

  onChangeTab = tabSelected => () => {
    this.setState({ tabSelected });
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.main}>
        <Tab
          tabSelected={this.state.tabSelected}
          onChangeTab={this.onChangeTab}
        />
        {this.state.tabSelected === 0 && (
          <MyClientsList showHiddenClients={false} />
        )}
        {this.state.tabSelected === 1 && (
          <MyClientsList showHiddenClients={true} />
        )}
        {/* {this.props.tabSelected === 1 && <MyTracks />} */}
        {/* {this.props.tabSelected === 2 && <MyTemplates />} */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { plan } = state.client;
  const { role } = state.user;
  return {
    role,
    plan,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(MyClients));
