import React from "react";

const FillStarIcon = ({ sizePercentage }) => (
  <svg
    width={sizePercentage * 18}
    height={sizePercentage * 18}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 18 18"
    version="1.1"
  >
    <path
      d="M17.739 6.92263C17.6229 6.56343 17.3043 6.30831 16.9273 6.27433L11.8073 5.80943L9.78267 1.07063C9.63338 0.723341 9.2934 0.498535 8.91566 0.498535C8.53791 0.498535 8.19793 0.723341 8.04864 1.07145L6.02403 5.80943L0.903164 6.27433C0.526908 6.30912 0.209121 6.56343 0.0923191 6.92263C-0.0244826 7.28183 0.0833864 7.67582 0.368015 7.92418L4.23817 11.3183L3.09695 16.3454C3.01345 16.715 3.15691 17.0971 3.4636 17.3188C3.62845 17.4379 3.82131 17.4985 4.0158 17.4985C4.18349 17.4985 4.34983 17.4533 4.49912 17.364L8.91566 14.7244L13.3306 17.364C13.6536 17.5584 14.0609 17.5406 14.3669 17.3188C14.6737 17.0964 14.8171 16.7142 14.7335 16.3454L13.5923 11.3183L17.4625 7.92485C17.7471 7.67582 17.8558 7.28251 17.739 6.92263V6.92263Z"
      fill="#FFC107"
    />
  </svg>
);

FillStarIcon.defaultProps = {
  sizePercentage: 1,
};

export default FillStarIcon;
