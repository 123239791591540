import { useDispatch } from "react-redux";
import { createNewStep } from "services/createTrack/actions";

export function useCreateNewAction() {
  const dispatch = useDispatch();

  const onCreate = () => {
    dispatch(createNewStep({}));
  };

  return [onCreate] as const;
}
