import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import moment from "moment";
import Fuse from "fuse.js";
import withStyles from "@mui/styles/withStyles";
import Colors from "../../constants/Colors";
import {
  getAllCampaignsRequest,
  deleteCampaignRequest,
  selectCampaign,
  getAllDraftsRequest,
  addCampaignToTemplateRequest,
  modifyCampaignRequest,
  selectPreviewTab,
} from "../../services/campaign/actions";
import "moment/locale/fr";
import { Loader } from "../../components";
import selectLanguage from "../../utils/selectLanguage";
import CampaignBox from "./CampaignBox";
import i18n from "../../i18n";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import { getSelectedClientId } from "../../services/user/selectors";
import {
  getAppLanguage,
  getRouterQuery,
} from "../../services/general/selectors";
import SearchBar from "../../components/SearchBar";
import { FILTER_ALL } from "./FilterCampaigns";
import getCampaignStatus from "utils/getCampaignStatus";
import { getCampaigns } from "services/campaign/selectors";

moment.locale("fr");

const fuseOptions = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 80,
  maxPatternLength: 20,
  minMatchCharLength: 1,
  keys: [
    "title.fr",
    "title.en",
    "description.fr",
    "description.en",
    "label",
    "notes",
  ],
};

const styles = theme => ({
  main: {
    width: "90%",
    marginTop: 15,
    flexDirection: "column",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  root: {
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
    marginBottom: 15,
  },
  leftSide: {
    width: "45%",
    minHeight: 700,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    display: "flex",
  },
  rightSide: {
    width: "45%",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    display: "flex",
    position: "relative",
    minHeight: 800,
    // flex: 1
  },
  container: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: 15,
    flex: 1,
    flexDirection: "row",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  saveButton: {
    marginTop: 15,
  },
  title: {
    fontWeight: 500,
    marginTop: 15,
    marginBottom: 15,
  },
  appTitleDiv: {
    width: 280,
    backgroundColor: Colors.mediumGreen,
    padding: 15,
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: 8,
    wordWrap: "break-word",
  },
  appText: {
    minWidth: "1%",
    textAlign: "center",
    wordWrap: "break-word",
  },
  separator: {
    height: 1,
    marginTop: 5,
    marginBottom: 10,
    width: "45%",
    backgroundColor: Colors.mediumGrey,
  },
  separatorContainer: {
    marginTop: 15,
    width: 280,
    display: "flex",
    flexWrap: "wrap",
    wordBreak: "break-all",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  content: {
    width: 280,
    marginTop: 80,
    wordWrap: "break-word",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  paper: {
    flex: 1,
    marginTop: "calc(1rem)",
    marginLeft: "-1.5rem",
    marginRight: "-1.5rem",
    marginBottom: "calc(-3rem)",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "row",
  },
  activateDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  bold: {
    fontWeight: "bold",
  },
  notifText: {
    marginTop: 10,
    marginBottom: 10,
  },
  createDiv: {
    flex: "0 0 auto",
    padding: "0px 1.5rem calc(2rem)",
  },
  createCampaign: {
    textDecoration: "none",
    width: 300,
    height: 450,
    borderRadius: 8,
    backgroundColor: Colors.main,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    transition: "0.2s",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
  createPollText: {
    color: "#fff",
  },
  bottomDiv: {
    width: "100%",
    borderTopWidth: 1,
    borderTop: "solid",
    borderTopColor: Colors.lightGrey,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  votesText: {
    marginLeft: 15,
    alignSelf: "left",
    backgroundColor: Colors.main,
    borderRadius: 8,
    color: "#fff",
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 8,
    paddingRight: 8,
  },
  noVotes: {
    marginLeft: 15,
    color: Colors.grey,
  },
  topDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },
  pollTitle: {
    textAlign: "center",
    marginLeft: 10,
    marginRight: 10,
  },
  searchBar: {
    marginTop: 5,
    marginBottom: 5,
    minWidth: 300,
    width: 300,
    flex: 0,
  },
  inputClassName: {
    border: "1px solid rgba(0,0,0,.09)",
    backgroundColor: "#fff",
  },
  "@media (max-width: 1024px)": {
    createDiv: { width: "50%" },
  },
  badge: {
    backgroundColor: Colors.pink,
    color: Colors.white,
    right: 10,
  },
});

class MyPremiumDemoCampaigns extends PureComponent {
  state = {
    expanded: null,
    search: "",
    labelWidth: 0,
    isDeleteConfirmModalVisible: false,
    isModifyCampaignModalVisible: false,
    campaignSelected: {},
    filterSelected: FILTER_ALL, // utilisé ?
  };

  componentDidMount() {
    const { clientId } = this.props;
    this.props.getAllCampaignsRequest({ clientId });
    this.props.getAllDraftsRequest({
      clientId,
      isDraft: true,
      isPrivate: true,
    });
  }

  onClickCampaign = campaign => () => {
    this.props.selectCampaign(campaign);
    this.props.push(`/campaigns/${campaign._id}`);
  };

  onClickModify = campaign => () => {
    this.setState({
      isModifyCampaignModalVisible: true,
      campaignSelected: campaign,
    });
  };

  onClickDelete = campaign => () => {
    this.setState({
      isDeleteConfirmModalVisible: true,
      campaignSelected: campaign,
    });
  };

  onConfirmDelete = event => {
    event.stopPropagation();
    this.props.deleteCampaignRequest({
      campaignId: this.state.campaignSelected._id,
    });
    this.setState({ isDeleteConfirmModalVisible: false });
  };

  onAddTemplate = ({ trackId, isTemplate }) => {
    console.log("nice");
    this.props.addCampaignToTemplateRequest({ trackId, isTemplate });
  };

  onAddPremiumDemo = ({ trackId, isPremiumDemo }) => {
    console.log("HEY");
    this.props.addCampaignToTemplateRequest({ trackId, isPremiumDemo });
  };

  onAddPremiumDemo = ({ trackId, isPremiumDemo, campaignId }) => {
    console.log("HEY");
    this.props.addCampaignToTemplateRequest({
      trackId,
      isPremiumDemo,
    });
    this.props.modifyCampaignRequest({
      campaignId,
      isPremiumDemo,
    });
  };

  onClickArchive = campaignId => isDone => {
    this.props.modifyCampaignRequest({ campaignId, isDone: !isDone });
  };

  displayCampaigns = campaigns => {
    const { language } = this.props;

    return campaigns.map((el, i) => {
      return (
        <CampaignBox
          {...el}
          {...el.track}
          campaignId={el._id}
          onAddTemplate={this.onAddTemplate}
          onClickArchive={this.onClickArchive}
          onClickModify={this.onClickModify(el)}
          onClickDelete={this.onClickDelete(el)}
          onAddPremiumDemo={this.onAddPremiumDemo}
          onClick={this.onClickCampaign(el)}
          hasArchive={true}
          title={selectLanguage({ text: el.title, language })}
          description={selectLanguage({ text: el.description, language })}
          hoverText={i18n.t("open-campaign")}
        />
      );
    });
  };

  onChangeSearch = search => {
    this.setState({ search });
  };

  getCampaignsStatus = campaigns => {
    return campaigns.map(campaign => {
      const { startDate, endDate, isDone } = campaign;

      const status = getCampaignStatus({ startDate, endDate, isDone });

      campaign.status = status;
      return campaign;
    });
  };

  filterCampaigns = campaigns => {
    return campaigns.filter(el => el.isPremiumDemo);
  };

  render() {
    const { classes, campaigns } = this.props;
    const { search } = this.state;

    const campaignsWithStatus = this.getCampaignsStatus(campaigns);

    const filteredCampaigns = this.filterCampaigns(campaignsWithStatus);

    const fuse = new Fuse(filteredCampaigns, fuseOptions);
    const searchResults =
      search.length > 0 ? fuse.search(search) : filteredCampaigns;

    return (
      <div className={classes.main}>
        <DeleteConfirmModal
          title={i18n.t("delete-campaign-confirm-title")}
          onClickConfirm={this.onConfirmDelete}
          isVisible={this.state.isDeleteConfirmModalVisible}
          onClose={() => this.setState({ isDeleteConfirmModalVisible: false })}
        />
        <div className={classes.topDiv}>
          <SearchBar
            placeholder={i18n.t("search-campaign-placeholder")}
            className={classes.searchBar}
            inputClassName={classes.inputClassName}
            onChangeSearch={this.onChangeSearch}
            value={this.state.search}
          />
        </div>
        <div className={classes.paper}>
          {this.props.requesting ? (
            <Loader size={30} />
          ) : (
            this.displayCampaigns(searchResults)
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { requesting, campaignSelected } = state.campaign;
  const campaigns = getCampaigns(state);
  const routerQuery = getRouterQuery(state);
  const language = getAppLanguage(state);
  const clientId = getSelectedClientId(state);
  const { notifications } = state.notification;
  const userId = state.user._id;
  return {
    token: state.auth.token,
    language,
    requesting,
    clientId,
    campaigns,
    routerQuery,
    notifications,
    userId,
    campaignSelected,
  };
};

export default connect(mapStateToProps, {
  getAllCampaignsRequest,
  selectCampaign,
  deleteCampaignRequest,
  getAllDraftsRequest,
  addCampaignToTemplateRequest,
  modifyCampaignRequest,
  push,
  selectPreviewTab,
})(withStyles(styles)(MyPremiumDemoCampaigns));
