import {
  POLL_GET_SETTINGS_REQUESTING,
  POLL_MODIFY_SETTINGS_REQUESTING,
  POLL_CHANGE_SETTINGS,
  POLL_CLOSE_SNACK_MESSAGE,
  POLL_ADD_OPTION,
  POLL_REMOVE_OPTION,
  POLL_MODIFY_OPTION,
  POLL_CHANGE_TITLE,
  POLL_CREATE_REQUESTING,
  POLL_GET_ALL_REQUESTING,
  POll_CHANGE_FIELD,
} from "./constants";

export const changeField = payload => {
  return {
    type: POll_CHANGE_FIELD,
    payload,
  };
};

export const getAllPollsRequest = (payload: string) => {
  return {
    type: POLL_GET_ALL_REQUESTING,
    payload,
  };
};

export const createPollRequest = payload => {
  return {
    type: POLL_CREATE_REQUESTING,
    payload,
  };
};

export const addOption = () => {
  return {
    type: POLL_ADD_OPTION,
  };
};

export const removeOption = payload => {
  return {
    type: POLL_REMOVE_OPTION,
    payload,
  };
};

export const modifyOption = payload => {
  return {
    type: POLL_MODIFY_OPTION,
    payload,
  };
};

export const changeTitle = payload => {
  return {
    type: POLL_CHANGE_TITLE,
    payload,
  };
};

export const getCoffeeSettingsRequest = (payload: {}) => {
  return {
    type: POLL_GET_SETTINGS_REQUESTING,
    payload,
  };
};

export const modifyCoffeeSettingsRequest = payload => {
  return {
    type: POLL_MODIFY_SETTINGS_REQUESTING,
    payload,
  };
};

export const changeCoffeeSettings = payload => {
  return {
    type: POLL_CHANGE_SETTINGS,
    payload,
  };
};

export const closeSnackMessage = () => {
  return {
    type: POLL_CLOSE_SNACK_MESSAGE,
  };
};
