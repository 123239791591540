import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { MainText } from "components/Texts/MainText";
import { useState } from "react";
import moment from "moment";

const displayCounter = counter => {
  var d = new Date(counter * 1000);
  return moment(d).format("mm:ss");
};

const useStyles = makeStyles(theme => ({
  main: {},
  text: {
    fontSize: 16,
    fontWeight: 500,
  },
}));

interface Props {
  isPlaying: boolean;
}

const Timer = ({ isPlaying }: Props) => {
  const classes = useStyles();
  const [time, setTime] = useState(0);

  let intervalRef = React.useRef<number | null>(0);

  React.useEffect(() => {
    if (isPlaying) {
      setTime(0);
      intervalRef.current = setInterval(() => setTime(prev => prev + 1), 1000);
    } else {
      clearInterval(intervalRef.current);
    }
  }, [isPlaying]);

  return <MainText className={classes.text} text={displayCounter(time)} />;
};

export default Timer;
