import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { LightButton } from "components";
import i18n from "i18n";

const useStyles = makeStyles(theme => ({
  main: {
    marginTop: 15,
    width: "100%",
    border: "1px solid rgba(52, 99, 207, 1)",
    color: "rgba(52, 99, 207, 1)",
  },
  imageCalendar: {
    top: "7px",
    left: "10px",
    position: "absolute",
    alignSelf: "flex-start",
    borderRadius: 2,
    borderColor: "#fff",
    width: 30,
    height: 30,
  },
  icon: {
    marginTop: 2.5,
    marginRight: 3,
  },
}));

interface Props {
  requesting?: boolean;
  hasToken: boolean;
  onClick: () => void;
  className: string;
}

const GoogleCalendarButton = ({
  requesting = false,
  hasToken = false,
  onClick = () => null,
  className = "",
}: Props) => {
  const classes = useStyles();

  return (
    <LightButton
      requesting={requesting}
      icon={
        <img
          className={classes.imageCalendar}
          alt="google calendar"
          src="https://collegeinfogeek.com/wp-content/uploads/2016/08/Google_Calendar_Logo.png"
        />
      }
      title={
        hasToken
          ? i18n.t("send-google-calendar-invitation")
          : i18n.t("connect-google-calendar")
      }
      onClick={onClick}
      className={`${classes.main} ${className}`}
    />
  );
};

export default GoogleCalendarButton;
