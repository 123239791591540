import React, { useEffect, useMemo, useState } from "react";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import i18n from "i18n";
import FlatButton from "components/FlatButton";
import PlusIcon from "components/PlusIcon";
import SearchBar from "components/SearchBar";
import InvoiceTypeFilter from "./InvoiceTypeFilter";
import ClientFilter from "./ClientFilter";
import FullSideModal from "components/FullSideModal";
import SelectClient from "./SelectClient";
import {
  getAllInvoicesRequest,
  updateInvoiceRequest,
} from "services/invoice/actions";
import { useDispatch, useSelector } from "react-redux";
import InvoiceRow from "scenes/Invoice/InvoiceList/InvoiceRow";
import InvoiceTableHeader from "scenes/Invoice/InvoiceList/InvoiceTableHeader";
import { getAllInvoices, getInvoiceLoading } from "services/invoice/selectors";
import Fuse from "fuse.js";
import { sortBy as lodashSortBy } from "lodash";
import InvoicePage from "scenes/Invoice/InvoicePage/InvoicePage";
import InvoiceStats from "./InvoiceStats";
import ListItemLoader from "scenes/Campaigns/CampaignScreen/ListItemLoader";

import NEW_INVOICE_PICTURE from "../../../resources/InvoicePicture.svg";

const fuseOptions = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 80,
  maxPatternLength: 20,
  minMatchCharLength: 1,
  keys: ["invoiceTitle", "invoiceName", "clientName", "totalAmount"],
};

const useStyles = makeStyles(theme => ({
  main: {
    position: "relative",
    backgroundColor: "#fff",
    borderRadius: 12,
    paddingTop: 30,
    paddingLeft: 35,
    paddingRight: 25,
    paddingBottom: 25,
    minHeight: "85vh",
  },
  title: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 24,
  },
  subTitle: {
    color: "#959EA8",
    fontSize: 14,
  },
  addButton: {
    position: "absolute",
    top: 35,
    right: 25,
  },
  controlsDiv: {
    marginTop: 30,
    paddingBottom: 35,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    borderBottom: "solid 1px #EBEBEB",
  },
  icon: {
    marginRight: 10,
  },
  searchBar: {
    backgroundColor: "#fff",
    border: "1px solid #D1D1D1",
    color: "#282828",
    width: "100%",
  },
  search: {
    maxWidth: "100%",
    width: 500,
  },
}));

const InvoiceList = () => {
  const classes = useStyles();
  const [typeFilter, setTypeFilter] = useState("all");
  const [isNewInvoiceModalVisible, openNewInvoiceModal] = useState(false);
  const [isSortedDesc, setIsSortedDesc] = useState(true);
  const [sortedBy, setSortedBy] = useState("createdAt");
  const [search, setSearch] = useState("");

  const invoices = useSelector(getAllInvoices);
  const loading = useSelector(getInvoiceLoading);

  const dispatch = useDispatch();

  const handleClickCreateInvoice = () => {
    openNewInvoiceModal(true);
  };

  const handleClickTypeFilter = typeFilter => () => {
    setTypeFilter(typeFilter);
  };

  useEffect(() => {
    dispatch(getAllInvoicesRequest());
  }, [dispatch]);

  const onChangeSearch = searchValue => {
    setSearch(searchValue);
  };

  const changeSortingParam = param => () => {
    setSortedBy(param);
    setIsSortedDesc(!isSortedDesc);
  };

  const sortBy = invoices => {
    let invoicesToSort = invoices;

    if (typeFilter === "paid") {
      invoicesToSort = invoices.filter(invoice => invoice.isPaid);
    } else if (typeFilter === "notPaid") {
      invoicesToSort = invoices.filter(invoice => !invoice.isPaid);
    }

    if (isSortedDesc) {
      return lodashSortBy(invoicesToSort, sortedBy).reverse();
    }

    return lodashSortBy(invoicesToSort, sortedBy);
  };

  const onClickDownload = invoice => async () => {
    const doc = <InvoicePage pdfMode={true} invoice={invoice} />;

    const base: any = [];
    const asPdf = pdf(base); // {} is important, throws without an argument
    asPdf.updateContainer(doc);

    // we need to wait to let react useEffect do their jobs
    setTimeout(async () => {
      const blob = await asPdf.toBlob();
      saveAs(blob, `${invoice.invoiceName || invoice.clientName}.pdf`);
    }, 250);
  };

  const onClickPaid = invoice => async () => {
    dispatch(
      updateInvoiceRequest({ invoiceId: invoice._id, isPaid: !invoice.isPaid }),
    );
  };

  const numberOfPaidInvoices = useMemo(() => {
    return invoices.filter(el => el.isPaid).length;
  }, [invoices]);

  const numberOfNotPaidInvoices = useMemo(
    () => invoices.length - numberOfPaidInvoices,
    [invoices, numberOfPaidInvoices],
  );

  const fuse = new Fuse(invoices, fuseOptions);
  const searchResults = search.length > 0 ? fuse.search(search) : invoices;

  return (
    <>
      <InvoiceStats invoices={invoices} />
      <div className={classes.main}>
        <FullSideModal
          isVisible={isNewInvoiceModalVisible}
          onClose={() => openNewInvoiceModal(false)}
          title={i18n.t("new-invoice")}
          subtitle={i18n.t("new-invoice-select-client")}
          leftSide={<img src={NEW_INVOICE_PICTURE} alt="new-invoice" />}
          rightSide={<SelectClient />}
        />
        <Typography className={classes.title}>{i18n.t("billing")}</Typography>
        <Typography className={classes.subTitle}>
          {i18n.t("billing-subtitle")}
        </Typography>
        <FlatButton
          onClick={handleClickCreateInvoice}
          icon={<PlusIcon className={classes.icon} color={"#fff"} />}
          title={i18n.t("new-invoice")}
          className={classes.addButton}
        />
        <div className={classes.controlsDiv}>
          <SearchBar
            className={classes.search}
            value={search}
            placeholder={i18n.t("search-invoice")}
            inputClassName={classes.searchBar}
            onChangeSearch={onChangeSearch}
          />
          <InvoiceTypeFilter
            isSelected={typeFilter === "all"}
            text={`${i18n.t("invoice-filter-all")} (${invoices.length})`}
            onClick={handleClickTypeFilter("all")}
          />
          <InvoiceTypeFilter
            isSelected={typeFilter === "paid"}
            color="#8BD85C"
            text={`${i18n.t("invoice-filter-paid")} (${numberOfPaidInvoices})`}
            onClick={handleClickTypeFilter("paid")}
          />
          <InvoiceTypeFilter
            isSelected={typeFilter === "notPaid"}
            color="#F3B515"
            text={`${i18n.t(
              "invoice-filter-not-paid",
            )} (${numberOfNotPaidInvoices})`}
            onClick={handleClickTypeFilter("notPaid")}
          />
          <ClientFilter />
        </div>
        <InvoiceTableHeader
          sortedBy={sortedBy}
          changeSortingParam={changeSortingParam}
          isSortedDesc={isSortedDesc}
        />
        {loading && <ListItemLoader />}
        {sortBy(searchResults).map(invoice => (
          <InvoiceRow
            onClickPaid={onClickPaid(invoice)}
            onClickDownload={onClickDownload(invoice)}
            {...invoice}
            key={invoice._id}
          />
        ))}
      </div>
    </>
  );
};

export default InvoiceList;
