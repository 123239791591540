import React, { useEffect } from "react";
import FlatButton from "components/FlatButton";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import i18n from "i18n";

import Colors from "constants/Colors";
import { useSelector } from "react-redux";
import { getUserEmail } from "services/user/selectors";
import QrCode from "scenes/Campaigns/SeeUserModal/QrCode";
import {
  useGetDynamicAuthLink,
  useSendEmailInvitation,
} from "scenes/Campaigns/SeeUserModal/hooks";
import OrSeparator from "./OrSeparator";

const EmailIcon = ({ className }) => (
  <svg
    className={className}
    width="15"
    height="12"
    viewBox="0 0 15 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2353 0.125061H0.631277C0.503007 0.125211 0.380036 0.176246 0.289353 0.266965C0.19867 0.357683 0.147683 0.480674 0.147583 0.608945V1.58239C0.147583 1.62724 0.196217 1.67871 0.235958 1.69953L7.38653 5.78387C7.40485 5.79434 7.42561 5.79976 7.44671 5.79957C7.46827 5.79963 7.48945 5.79388 7.50802 5.78292L14.4425 1.7035C14.4815 1.68174 14.5848 1.62421 14.6236 1.59772C14.6705 1.56574 14.719 1.53678 14.719 1.47944V0.608756C14.7188 0.480518 14.6678 0.357576 14.5771 0.266898C14.4865 0.17622 14.3635 0.125211 14.2353 0.125061Z"
      fill="white"
    />
    <path
      d="M14.6586 3.05019C14.64 3.03957 14.6189 3.03405 14.5975 3.03418C14.576 3.03431 14.555 3.0401 14.5365 3.05094L10.6123 5.35985C10.5966 5.36896 10.5833 5.38147 10.5731 5.39646C10.563 5.41146 10.5564 5.42854 10.5537 5.44644C10.5511 5.46435 10.5525 5.48262 10.5579 5.49989C10.5633 5.51717 10.5725 5.53301 10.5848 5.54625L14.5097 9.77706C14.5209 9.78927 14.5346 9.79901 14.5498 9.80566C14.565 9.81231 14.5814 9.81571 14.598 9.81566C14.6301 9.81561 14.6608 9.80285 14.6835 9.78019C14.7061 9.75752 14.7189 9.72679 14.719 9.69474V3.15521C14.719 3.13394 14.7135 3.11302 14.7029 3.09458C14.6923 3.07614 14.677 3.06082 14.6586 3.05019Z"
      fill="white"
    />
    <path
      d="M9.4814 6.13508C9.46277 6.1148 9.43774 6.10151 9.4105 6.09745C9.38326 6.09338 9.35545 6.09879 9.33171 6.11275L7.75876 7.03832C7.6666 7.09157 7.56218 7.11994 7.45574 7.12063C7.34931 7.12133 7.24453 7.09433 7.15168 7.0423L5.7674 6.25147C5.74505 6.23874 5.71923 6.23345 5.69368 6.23638C5.66813 6.23931 5.64417 6.25029 5.62528 6.26774L0.368604 11.1437C0.354823 11.1565 0.344242 11.1725 0.337705 11.1902C0.331167 11.2079 0.328854 11.2268 0.33095 11.2456C0.333045 11.2643 0.339491 11.2823 0.349774 11.2982C0.360057 11.314 0.373892 11.3272 0.390177 11.3367C0.471928 11.3848 0.550841 11.4077 0.631078 11.4077H14.096C14.1195 11.4077 14.1426 11.4008 14.1623 11.3879C14.182 11.3749 14.1974 11.3565 14.2067 11.3348C14.2161 11.3133 14.219 11.2894 14.2151 11.2663C14.2111 11.2431 14.2004 11.2216 14.1844 11.2044L9.4814 6.13508Z"
      fill="white"
    />
    <path
      d="M4.46866 5.69098C4.48259 5.67811 4.49329 5.66214 4.4999 5.64437C4.50652 5.6266 4.50886 5.60752 4.50674 5.58868C4.50462 5.56984 4.4981 5.55176 4.48771 5.5359C4.47731 5.52004 4.46333 5.50685 4.4469 5.49739L0.327929 3.14477C0.309547 3.13433 0.288747 3.12891 0.267609 3.12903C0.246471 3.12915 0.225736 3.13483 0.207478 3.14548C0.189219 3.15613 0.174078 3.17139 0.163567 3.18973C0.153057 3.20807 0.147546 3.22885 0.147585 3.24999V9.42296C0.147452 9.44653 0.154235 9.46963 0.167094 9.48939C0.179954 9.50915 0.198326 9.52471 0.219936 9.53413C0.241546 9.54356 0.265445 9.54644 0.288676 9.54242C0.311908 9.5384 0.33345 9.52766 0.350638 9.51152L4.46866 5.69098Z"
      fill="white"
    />
  </svg>
);

const useStyles = makeStyles(theme => ({
  main: {
    paddingLeft: 10,
    paddingRight: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  svgWrapperDiv: {
    position: "relative",
  },
  connectionAppDiv: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  connectionAppText: {
    wordBreak: "break-word",
    color: "black",
    width: 250,
    marginLeft: 25,
    marginBottom: 20,
    marginRight: 25,
    fontSize: "1.1rem",
    lineHeight: "150%",
    textAlign: "center",
  },
  picture: {
    backgroundColor: Colors.white,
    borderRadius: 4,
    width: "50%",
  },
  button: {
    // width: TEXT_WIDTH,
    height: 50,
    width: "100%",
  },
  closeIcon: {
    position: "absolute",
    top: 20,
    right: 20,
  },
  icon: {
    marginRight: 20,
  },
  email: {
    marginTop: 20,
    color: "#8F8F8F",
  },
}));

const StartLivePreview = ({ qrCodeParams = {} }) => {
  const [, sendGetLinkRequest, link,] = useGetDynamicAuthLink();
  const [isEmailRequesting, sendEmailRequest] = useSendEmailInvitation();
  const email = useSelector(getUserEmail);

  const classes = useStyles();

  const handleSendEmail = () => {
    sendEmailRequest(link);
  };

  const onInit = () => {
    sendGetLinkRequest(qrCodeParams);
  }

  useEffect(onInit, []);

  return (
    <div className={classes.main}>
      <Typography className={classes.connectionAppText}>
        {i18n.t("scan-me")}
      </Typography>

      <QrCode link={link} />
      <OrSeparator />
      <Typography className={classes.connectionAppText}>
        {i18n.t("connect-with-magic-link-email")}
      </Typography>
      <FlatButton
        requesting={isEmailRequesting}
        onClick={handleSendEmail}
        className={classes.button}
        icon={<EmailIcon className={classes.icon} />}
        title={i18n.t("receive-the-link-by-email")}
      />
      <Typography className={classes.email}>{email}</Typography>
    </div>
  );
};

export default StartLivePreview;
