import { store } from "../store";
import apiConfig from "./config";

export const fetchApi = async (
  endPoint,
  payload = {},
  method = "get",
  headers = {},
) => {
  const accessToken = store.getState().token.accessToken;

  const goodHeaders = accessToken
    ? { Authorization: `Bearer ${accessToken}` }
    : { "Client-ID": "" };

  if (method.toUpperCase() === "GET") {
    const response = await fetch(`${apiConfig.url}${endPoint}`, {
      method: method.toUpperCase(),
      headers: {
        ...headers,
        Accept: "application/json",
        "Content-Type": "application/json",
        ...goodHeaders,
      },
    });
    const json = await response.json();
    return response.ok ? json : Promise.reject(json);
  }
  const response = await fetch(`${apiConfig.url}${endPoint}`, {
    method: method.toUpperCase(),
    headers: {
      ...headers,
      Accept: "application/json",
      "Content-Type": "application/json",
      ...goodHeaders,
    },
    body: JSON.stringify(payload),
  });
  const json = await response.json();
  return response.ok ? json : Promise.reject(json);
};
