import React, { useEffect, useRef } from "react";
import makeStyles from '@mui/styles/makeStyles';
import QRCodeStyling from "qr-code-styling";

const qrCode = new QRCodeStyling({
  width: 200,
  height: 200,
  image:
    "https://pulse-experience.s3.eu-west-3.amazonaws.com/images/microdoinground.svg",

  dotsOptions: { type: "rounded", color: "#0d0d37", gradient: null },
  cornersSquareOptions: {
    type: "extra-rounded",
  },
  imageOptions: {
    hideBackgroundDots: true,
    imageSize: 0.3,
    margin: 0,
  },
  backgroundOptions: { color: "#ffffff" },
});

const useStyles = makeStyles(theme => ({
  main: {
    padding: 5,
    backgroundColor: "#fff",
    borderRadius: 8,
  },
}));

interface Props {
  link: string;
}

const QrCode = ({ link }: Props) => {
  const classes = useStyles();
  const ref = useRef(null);

  useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: link,
    });
  }, [link]);

  return (
    <div className={classes.main}>
      <div ref={ref} />
    </div>
  );
};

export default QrCode;
