import { createSelector } from "reselect";
import { getSelectedCampaignId } from "services/campaign/selectors";

export const getNotifications = state => state.notification.notifications || [];

export const getCampaignSelectedNotificationsHistory = state =>
  state.notification.notificationsHistory || [];

export const getNumberOfNewNotifications = state =>
  state.notification.newGeneralNotifications || 0;

export const getNotificationPage = state => state.notification.page || 0;

export const getFavoriteNotifications = state =>
  state.notification.favoriteNotifications || [];

export const getNotificationsRequesting = state =>
  state.notification.requesting || false;

export const getCampaignSelectedNotifications = createSelector(
  getNotifications,
  getSelectedCampaignId,
  (notifications, campaignId) => {
    return notifications.filter(
      notification => notification?.data?.campaignId === campaignId,
    );
  },
);
