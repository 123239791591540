import {
  MY_USERS_GET_LIST_REQUESTING,
  MY_USERS_LOAD_MORE_REQUESTING,
  MY_USERS_MODIFY_REQUESTING,
  MY_USERS_DELETE_REQUESTING,
  MY_USERS_UPDATE_ROLE_REQUESTING,
  MY_USERS_GET_REQUESTING,
  MY_USERS_CHANGE_FIELD,
} from "./constants";

interface User {
  firstName?: string;
  lastName?: string;
  email?: string;
  hasAvatars?: boolean;
  picture?: string;
}

export const changeField = (payload: {}) => {
  return {
    type: MY_USERS_CHANGE_FIELD,
    payload,
  };
};

export const getUserRequest = (payload: { userId: string }) => {
  return {
    type: MY_USERS_GET_REQUESTING,
    payload,
  };
};

export const getMyUsersListRequest = (payload: { clientId: string }) => {
  return {
    type: MY_USERS_GET_LIST_REQUESTING,
    payload,
  };
};

export const loadMoreUsersRequest = (payload: { clientId: string }) => {
  return {
    type: MY_USERS_LOAD_MORE_REQUESTING,
    payload,
  };
};

interface Request {
  userId: string;
}

type UserRequest = Request & User;

export const modifyMyUserRequest = (payload: UserRequest) => {
  return {
    type: MY_USERS_MODIFY_REQUESTING,
    payload,
  };
};

export const modifyMyUserRoleRequest = (payload: {
  userId: string;
  role: string;
}) => {
  return {
    type: MY_USERS_UPDATE_ROLE_REQUESTING,
    payload,
  };
};

export const deleteMyUserRequest = (payload: { userId: string }) => {
  return {
    type: MY_USERS_DELETE_REQUESTING,
    payload,
  };
};
