import React from "react";

const DropDownIcon = ({ sizePercentage, color, className }) => (
  <svg
    className={className}
    width={sizePercentage * 13}
    height={sizePercentage * 9}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 13 9"
    version="1.1"
  >
    <path
      d="M12.7165 1.31803L7.59017 7.74108C7.10388 8.32868 6.35418 8.32868 5.88816 7.74108L0.761868 1.31803C0.27558 0.710167 0.518724 0.244141 1.26842 0.244141H12.2099C12.9799 0.244141 13.2027 0.710167 12.7165 1.31803Z"
      fill={color}
    />
  </svg>
);

DropDownIcon.defaultProps = {
  sizePercentage: 1,
  color: "#BABCC5",
  className: "",
};

export default DropDownIcon;
