export const MESSAGE_OPEN_CHAT = "MESSAGE_OPEN_CHAT";
export const MESSAGE_GOT_NEW = "MESSAGE_GOT_NEW";
export const MESSAGE_SET_RECEIVER = "MESSAGE_SET_RECEIVER";
export const MESSAGE_CLEAN = "MESSAGE_CLEAN";
export const MESSAGE_SEE_CONVERSATION = "MESSAGE_SEE_CONVERSATION";
export const MESSAGE_GET_LATEST_CONVERSATIONS_REQUEST =
  "MESSAGE_GET_LATEST_CONVERSATIONS_REQUEST";
export const MESSAGE_GET_LATEST_CONVERSATIONS_SUCCESS =
  "MESSAGE_GET_LATEST_CONVERSATIONS_SUCCESS";
export const MESSAGE_GET_LATEST_CONVERSATIONS_ERROR =
  "MESSAGE_GET_LATEST_CONVERSATIONS_ERROR";
export const GET_CHAT_MESSAGES_REQUESTING = "GET_CHAT_MESSAGES_REQUESTING";
export const GET_CHAT_MESSAGES_SUCCESS = "GET_CHAT_MESSAGES_SUCCESS";
export const GET_CHAT_MESSAGES_ERROR = "GET_CHAT_MESSAGES_ERROR";
export const MESSAGE_OPEN_CONVERSATION_REQUESTING =
  "MESSAGE_OPEN_CONVERSATION_REQUESTING";
export const MESSAGE_OPEN_CONVERSATION_SUCCESS =
  "MESSAGE_OPEN_CONVERSATION_SUCCESS";
export const MESSAGE_OPEN_CONVERSATION_ERROR =
  "MESSAGE_OPEN_CONVERSATION_ERROR";

export const MESSAGE_SET_CONVERSATION = "MESSAGE_SET_CONVERSATION";

export const MESSAGE_GET_CLIENT_NAME_SUCCESS =
  "message/MESSAGE_GET_CLIENT_NAME_SUCCESS";
export const MESSAGE_GET_CLIENT_NAME_REQUESTING =
  "message/MESSAGE_GET_CLIENT_NAME_REQUESTING";
export const MESSAGE_GET_CLIENT_NAME_ERROR =
  "message/MESSAGE_GET_CLIENT_NAME_ERROR";

export const MESSAGE_LIKE_REQUESTING = "message/MESSAGE_LIKE_REQUESTING";
export const MESSAGE_LIKE_SUCCESS = "message/MESSAGE_LIKED_SUCCESS";
export const MESSAGE_LIKE_ERROR = "message/MESSAGE_LIKED_ERROR";
