import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import moment from "moment";
import Fuse from "fuse.js";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Colors from "../../constants/Colors";
import AddIcon from "@mui/icons-material/Add";
import {
  getAllCampaignsRequest,
  deleteCampaignRequest,
  selectCampaign,
  getAllDraftsRequest,
  addCampaignToTemplateRequest,
  modifyCampaignRequest,
  selectPreviewTab,
} from "../../services/campaign/actions";
import "moment/locale/fr";
import { Loader } from "../../components";
import selectLanguage from "../../utils/selectLanguage";
import CampaignBox from "./CampaignBox";
import i18n from "../../i18n";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import {
  getIsTrainerOrObserver,
  getSelectedClientId,
} from "../../services/user/selectors";
import {
  getAppLanguage,
  getRouterQuery,
} from "../../services/general/selectors";
import SearchBar from "../../components/SearchBar";
import getCampaignStatus from "utils/getCampaignStatus";
import { getCampaigns } from "services/campaign/selectors";
import { Badge } from "@mui/material";
import FilterList from "components/FilterList";
import { getNotifications } from "services/notification/selectors";

const FILTER_ALL = "FILTER_ALL";
const FILTER_DONE = "FILTER_DONE";
const FILTER_NOT_DONE = "FILTER_NOT_DONE";
const FILTER_CURRENT = "FILTER_CURRENT";
const FILTER_INCOMING = "FILTER_INCOMING";
const FILTER_ARCHIVED = "FILTER_ARCHIVED";

const ALL_FILTERS = [
  { label: FILTER_ALL, color: "#fff" },
  { label: FILTER_NOT_DONE, color: Colors.red },
  { label: FILTER_CURRENT, color: Colors.pureMain },
  { label: FILTER_INCOMING, color: Colors.orange },
  { label: FILTER_DONE, color: "#3CC926" },
];

moment.locale("fr");

const fuseOptions = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 80,
  maxPatternLength: 20,
  minMatchCharLength: 1,
  keys: [
    "title.fr",
    "title.en",
    "description.fr",
    "description.en",
    "label",
    "notes",
  ],
};

const styles = theme => ({
  main: {
    width: "90%",
    marginTop: 15,
    flexDirection: "column",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  paper: {
    flex: 1,
    marginTop: "calc(1rem)",
    marginLeft: "-1.5rem",
    marginRight: "-1.5rem",
    marginBottom: "calc(-3rem)",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "row",
  },
  createDiv: {
    flex: "0 0 auto",
    padding: "0px 1.5rem calc(2rem)",
  },
  createCampaign: {
    textDecoration: "none",
    width: 300,
    height: 450,
    borderRadius: 8,
    backgroundColor: Colors.main,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    transition: "0.2s",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
  createPollText: {
    color: "#fff",
  },
  topDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },
  searchBar: {
    marginTop: 5,
    marginBottom: 5,
    minWidth: 300,
    width: 300,
    flex: 0,
  },

  "@media (max-width: 1024px)": {
    createDiv: { width: "50%" },
  },
  badge: {
    backgroundColor: Colors.pink,
    color: Colors.white,
    right: 10,
  },
});

class MyCampaigns extends PureComponent {
  state = {
    expanded: null,
    search: "",
    labelWidth: 0,
    isDeleteConfirmModalVisible: false,
    isModifyCampaignModalVisible: false,
    campaignSelected: {},
    filterSelected: ALL_FILTERS[1],
  };

  componentDidMount() {
    const { clientId } = this.props;
    this.props.getAllCampaignsRequest({ clientId });
    this.props.getAllDraftsRequest({
      clientId,
      isDraft: true,
      isPrivate: true,
    });
  }

  onChangeDay = event => {
    const isoWeekday = Number(event.target.value);
    const reminderTime = moment(this.props.reminderTime).isoWeekday(isoWeekday);
    this.props.changeCoffeeSettings({ reminderTime });
  };

  onChangeTime = event => {
    const time = event.target.value;
    const minute = Number(time.split(":")[1]);
    const hour = Number(time.split(":")[0]);

    const reminderTime = moment(this.props.reminderTime)
      .minute(minute)
      .hour(hour);
    this.props.changeCoffeeSettings({ reminderTime });
  };

  onClickMore = () => {};

  onClickCampaign = campaign => () => {
    this.props.selectCampaign(campaign);
    this.props.push(`/campaigns/${campaign._id}`);
  };

  onClickModify = campaign => () => {
    this.setState({
      isModifyCampaignModalVisible: true,
      campaignSelected: campaign,
    });
  };

  onClickDelete = campaign => () => {
    this.setState({
      isDeleteConfirmModalVisible: true,
      campaignSelected: campaign,
    });
  };

  onConfirmDelete = event => {
    event.stopPropagation();
    this.props.deleteCampaignRequest({
      campaignId: this.state.campaignSelected._id,
    });
    this.setState({ isDeleteConfirmModalVisible: false });
  };

  onAddTemplate = ({ trackId, isTemplate }) => {
    this.props.addCampaignToTemplateRequest({ trackId, isTemplate });
  };

  onClickArchive = campaignId => isDone => {
    this.props.modifyCampaignRequest({ campaignId, isDone: !isDone });
  };

  onAddPremiumDemo = ({ trackId, isPremiumDemo, campaignId }) => {
    this.props.addCampaignToTemplateRequest({
      trackId,
      isPremiumDemo,
    });
    this.props.modifyCampaignRequest({
      campaignId,
      isPremiumDemo,
    });
  };

  displayCampaigns = campaigns => {
    const { language, notifications, userId } = this.props;

    return campaigns.map((el, i) => {
      let campaignOccurrence = notifications.filter(
        element => element.data?.campaignId === el._id,
      );

      campaignOccurrence = campaignOccurrence.filter(element => {
        const list = element.readBy?.map(reader => {
          if (reader?.readerId === userId) {
            return false;
          }
          return true;
        });
        if (list.length === 0 || list[0]) {
          return element;
        }
        return null;
      });

      return (
        <Badge
          key={i}
          badgeContent={campaignOccurrence.length}
          classes={{ badge: this.props.classes.badge }}
        >
          <CampaignBox
            {...el}
            {...el.track}
            campaignId={el._id}
            onAddPremiumDemo={this.onAddPremiumDemo}
            onAddTemplate={this.onAddTemplate}
            onClickArchive={this.onClickArchive}
            onClickModify={this.onClickModify(el)}
            onClickDelete={this.onClickDelete(el)}
            onClick={this.onClickCampaign(el)}
            hasArchive={true}
            title={selectLanguage({ text: el.title, language })}
            description={selectLanguage({ text: el.description, language })}
            hoverText={i18n.t("open-campaign")}
          />
        </Badge>
      );
    });
  };

  onChangeSearch = search => {
    this.setState({ search });
  };

  selectFilter = filterSelected => {
    this.setState({ filterSelected });
  };

  getHasEnded = (endDate, isDone) => {
    const now = moment();
    if (isDone) {
      return true;
    }
    if (endDate) {
      return moment(now).isAfter(endDate);
    }
    return false;
  };

  getHasStarted = startDate => {
    const now = moment();
    if (startDate) {
      const isBefore = moment(startDate).isBefore(now);
      return isBefore;
    }
    return true;
  };

  getCampaignsStatus = campaigns => {
    return campaigns.map(campaign => {
      const { startDate, endDate, isDone } = campaign;

      const status = getCampaignStatus({ startDate, endDate, isDone });

      campaign.status = status;
      return campaign;
    });
  };

  filterCampaigns = campaigns => {
    const { filterSelected } = this.state;
    switch (filterSelected.label) {
      case FILTER_ALL:
        return campaigns;
      case FILTER_NOT_DONE:
        return campaigns.filter(el => !el.isDone);
      case FILTER_INCOMING:
        return campaigns.filter(el => el?.status?.text === "incoming");
      case FILTER_CURRENT:
        return campaigns.filter(el => el?.status?.text === "current");
      case FILTER_DONE:
        return campaigns.filter(el => el?.status?.text === "done");
      default:
        return campaigns;
    }
  };

  render() {
    const { classes, campaigns, isCreationForbidden } = this.props;
    const { search, filterSelected } = this.state;

    const campaignsWithStatus = this.getCampaignsStatus(campaigns);

    const filteredCampaigns = this.filterCampaigns(campaignsWithStatus);

    const fuse = new Fuse(filteredCampaigns, fuseOptions);
    const searchResults =
      search.length > 0 ? fuse.search(search) : filteredCampaigns;

    return (
      <div className={classes.main}>
        <DeleteConfirmModal
          title={i18n.t("delete-campaign-confirm-title")}
          onClickConfirm={this.onConfirmDelete}
          isVisible={this.state.isDeleteConfirmModalVisible}
          onClose={() => this.setState({ isDeleteConfirmModalVisible: false })}
        />
        <div className={classes.topDiv}>
          <SearchBar
            placeholder={i18n.t("search-campaign-placeholder")}
            className={classes.searchBar}
            onChangeSearch={this.onChangeSearch}
            value={this.state.search}
          />
          <FilterList
            filterSelected={filterSelected}
            selectFilter={this.selectFilter}
            filters={ALL_FILTERS}
          />
        </div>

        <div className={classes.paper}>
          {!isCreationForbidden && (
            <div className={classes.createDiv}>
              <div
                className={classes.createCampaign}
                onClick={this.props.displayCreationModal}
                data-intercom-target="New Campaign"
              >
                <AddIcon style={{ color: "#fff" }} />
                <Typography className={classes.createPollText}>
                  {i18n.t("new-campaign")}
                </Typography>
              </div>
            </div>
          )}
          {this.props.requesting ? (
            <Loader size={30} />
          ) : (
            this.displayCampaigns(searchResults)
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { requesting, campaignSelected } = state.campaign;
  const campaigns = getCampaigns(state);
  const routerQuery = getRouterQuery(state);
  const language = getAppLanguage(state);
  const clientId = getSelectedClientId(state);
  const notifications = getNotifications(state);
  const userId = state.user._id;
  const isCreationForbidden = getIsTrainerOrObserver(state);

  return {
    token: state.auth.token,
    language,
    requesting,
    clientId,
    campaigns,
    routerQuery,
    notifications,
    userId,
    campaignSelected,
    isCreationForbidden,
  };
};

export default connect(mapStateToProps, {
  getAllCampaignsRequest,
  selectCampaign,
  deleteCampaignRequest,
  getAllDraftsRequest,
  addCampaignToTemplateRequest,
  modifyCampaignRequest,
  push,
  selectPreviewTab,
})(withStyles(styles)(MyCampaigns));
