//@flow
import {
  call,
  put,
  all,
  takeLatest,
  takeEvery,
} from "redux-saga/effects";

import {
  GET_COACHING_SESSION_REQUESTING,
  GET_COACHING_SESSION_SUCCESS,
  GET_COACHING_SESSION_ERROR,
  CREATE_COACHING_SESSION_REQUESTING,
  CREATE_COACHING_SESSION_SUCCESS,
  CREATE_COACHING_SESSION_ERROR,
  UPDATE_COACHING_SESSION_REQUESTING,
  UPDATE_COACHING_SESSION_SUCCESS,
  UPDATE_COACHING_SESSION_ERROR,
  GET_COACHING_NOTES_SUCCESS,
  GET_COACHING_NOTES_ERROR,
  GET_COACHING_NOTES_REQUESTING,
  UPDATE_COACHING_NOTE_REQUESTING,
  UPDATE_COACHING_NOTE_SUCCESS,
  UPDATE_COACHING_NOTE_ERROR,
  DELETE_COACHING_NOTE_REQUESTING,
  DELETE_COACHING_NOTE_SUCCESS,
  DELETE_COACHING_NOTE_ERROR,
  CREATE_COACHING_NOTE_REQUESTING,
  CREATE_COACHING_NOTE_SUCCESS,
  CREATE_COACHING_NOTE_ERROR,
  END_COACHING_SUCCESS,
  END_COACHING_REQUESTING,
  END_COACHING_ERROR,
  GET_COACHING_FILES_SUCCESS,
  GET_COACHING_FILES_ERROR,
  GET_COACHING_FILES_REQUESTING,
  UPDATE_COACHING_FILE_REQUESTING,
  UPDATE_COACHING_FILE_SUCCESS,
  UPDATE_COACHING_FILE_ERROR,
  DELETE_COACHING_FILE_REQUESTING,
  DELETE_COACHING_FILE_SUCCESS,
  DELETE_COACHING_FILE_ERROR,
  CREATE_COACHING_FILE_REQUESTING,
  CREATE_COACHING_FILE_SUCCESS,
  CREATE_COACHING_FILE_ERROR,
} from "./constants";

import { fetchApi } from "../api";
import { openSnackMessage } from "../general/actions";
import i18n from "../../i18n";
import { getResourcesListRequest } from "services/resource/actions";
import {
  DELETE_COACHEE_ERROR,
  DELETE_COACHEE_REQUESTING,
  DELETE_COACHEE_SUCCESS,
} from "services/myCoachees/constants";
import { uploadFileFlow } from "services/upload/sagas";

const endPoints = {
  getMyCoachees: "/v1/coaching-sessions/coachee/get-all",
  createCoachee: "/v1/coaching-sessions/coachee/create",
  getCoachingSession: "/v1/coaching-sessions/",
  endCoachingSession: "/v1/coaching-sessions/end",
  updateCoachingSession: "/v1/coaching-sessions/",
  createCoachingSession: "/v1/coaching-sessions/",
  getCoachingNotes: "/v1/coaching/coachee/notes/latest",
  updateCoachingNote: "/v1/coaching/coachee/notes",
  createCoachingNote: "/v1/coaching/coachee/notes",
  getCoachingFiles: "/v1/coaching-files/coachee/", // ? coachId & userId
  updateCoachingFile: "/v1/coaching-files/coachee/",
  createCoachingFile: "/v1/coaching-files/coachee/",
};

const getCoachingFilesApi = data => {
  const { coachId, userId } = data;
  return fetchApi(
    `${endPoints.getCoachingFiles}?coachId=${coachId}&userId=${userId}`,
    {},
    "get",
  );
};

export const updateCoachingFileApi = data => {
  return fetchApi(endPoints.updateCoachingFile, data, "patch");
};

export const createCoachingFileApi = data => {
  return fetchApi(endPoints.createCoachingFile, data, "post");
};

const getCoachingNotesApi = data => {
  const { coachId, userId } = data;
  return fetchApi(
    `${endPoints.getCoachingNotes}?coachId=${coachId}&userId=${userId}`,
    {},
    "get",
  );
};

export const updateCoachingNoteApi = data => {
  return fetchApi(endPoints.updateCoachingNote, data, "patch");
};

export const createCoachingNoteApi = data => {
  return fetchApi(endPoints.createCoachingNote, data, "post");
};

export const endCoachingSessionApi = data => {
  return fetchApi(endPoints.endCoachingSession, data, "post");
};

const getCoachingSessionApi = data => {
  const { coachId, userId } = data;
  return fetchApi(
    `${endPoints.getCoachingSession}?coachId=${coachId}&userId=${userId}`,
    {},
    "get",
  );
};

export const updateCoachingSessionApi = data => {
  return fetchApi(endPoints.updateCoachingSession, data, "patch");
};

export const createCoachingSessionApi = data => {
  return fetchApi(endPoints.createCoachingSession, data, "post");
};

function* getCoachingFilesFlow(action) {
  try {
    const response = yield call(getCoachingFilesApi, action.payload);
    // yield put(
    //   openSnackMessage({ snackMessage: i18n.t("data-modified-success") })
    // );
    yield put({ type: GET_COACHING_FILES_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_COACHING_FILES_ERROR, payload: error });
  }
}

function* updateCoachingFileFlow(action) {
  try {
    const response = yield call(updateCoachingFileApi, action.payload);
    // yield put(
    //   openSnackMessage({ snackMessage: i18n.t("data-modified-success") })
    // );
    yield put({ type: UPDATE_COACHING_FILE_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: UPDATE_COACHING_FILE_ERROR, payload: error });
  }
}

function* deleteCoachingFileFlow(action) {
  try {
    console.log({ action });
    const response = yield call(updateCoachingFileApi, {
      ...action.payload,
      isRemoved: true,
    });
    yield put(
      openSnackMessage({ snackMessage: i18n.t("data-modified-success") }),
    );
    yield put({ type: DELETE_COACHING_FILE_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: DELETE_COACHING_FILE_ERROR, payload: error });
  }
}

function* createCoachingFileFlow(action) {
  try {
    const uploadResult = yield uploadFileFlow(action);
    const { fileUrl, fileName } = uploadResult.payload;

    const response = yield call(createCoachingFileApi, {
      ...action.payload,
      url: fileUrl,
      fileName,
    });
    yield put(
      openSnackMessage({ snackMessage: i18n.t("file-uploaded-success") }),
    );
    yield put({ type: CREATE_COACHING_FILE_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: CREATE_COACHING_FILE_ERROR, payload: error });
  }
}

function* getCoachingNotesFlow(action) {
  try {
    const response = yield call(getCoachingNotesApi, action.payload);
    // yield put(
    //   openSnackMessage({ snackMessage: i18n.t("data-modified-success") })
    // );
    yield put({ type: GET_COACHING_NOTES_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_COACHING_NOTES_ERROR, payload: error });
  }
}

function* updateCoachingNoteFlow(action) {
  try {
    const response = yield call(updateCoachingNoteApi, action.payload);
    // yield put(
    //   openSnackMessage({ snackMessage: i18n.t("data-modified-success") })
    // );
    yield put({ type: UPDATE_COACHING_NOTE_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: UPDATE_COACHING_NOTE_ERROR, payload: error });
  }
}

function* deleteCoachingNoteFlow(action) {
  try {
    console.log({ action });
    const response = yield call(updateCoachingNoteApi, {
      ...action.payload,
      isRemoved: true,
    });
    yield put(
      openSnackMessage({ snackMessage: i18n.t("data-modified-success") }),
    );
    yield put({ type: DELETE_COACHING_NOTE_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: DELETE_COACHING_NOTE_ERROR, payload: error });
  }
}

function* createCoachingNoteFlow(action) {
  try {
    const response = yield call(createCoachingNoteApi, action.payload);
    yield put(
      openSnackMessage({ snackMessage: i18n.t("data-modified-success") }),
    );
    yield put({ type: CREATE_COACHING_NOTE_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: CREATE_COACHING_NOTE_ERROR, payload: error });
  }
}

function* getCoachingSessionFlow(action) {
  try {
    const response = yield call(getCoachingSessionApi, action.payload);
    // yield put(
    //   openSnackMessage({ snackMessage: i18n.t("data-modified-success") })
    // );
    yield put({ type: GET_COACHING_SESSION_SUCCESS, payload: response });
    const resourceIds = response.resources.map(el => el.resourceId);
    yield put(getResourcesListRequest({ resourceIds }));
  } catch (error) {
    yield put({ type: GET_COACHING_SESSION_ERROR, payload: error });
  }
}

function* updateCoachingSessionFlow(action) {
  try {
    const response = yield call(updateCoachingSessionApi, action.payload);
    yield put(
      openSnackMessage({ snackMessage: i18n.t("data-modified-success") }),
    );
    yield put({ type: UPDATE_COACHING_SESSION_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: UPDATE_COACHING_SESSION_ERROR, payload: error });
  }
}

function* deleteCoachingSessionFlow(action) {
  try {
    const response = yield call(updateCoachingSessionApi, {
      ...action.payload,
      isRemoved: true,
    });
    yield put(
      openSnackMessage({ snackMessage: i18n.t("data-modified-success") }),
    );
    yield put({ type: DELETE_COACHEE_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: DELETE_COACHEE_ERROR, payload: error });
  }
}

function* createCoachingSessionFlow(action) {
  try {
    const response = yield call(createCoachingSessionApi, action.payload);
    yield put(
      openSnackMessage({ snackMessage: i18n.t("data-modified-success") }),
    );
    yield put({ type: CREATE_COACHING_SESSION_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: CREATE_COACHING_SESSION_ERROR, payload: error });
  }
}

function* endCoachingSessionFlow(action) {
  try {
    const response = yield call(endCoachingSessionApi, action.payload);
    yield put({ type: END_COACHING_SUCCESS, payload: response });
    const { isFinished } = response;
    yield put(
      openSnackMessage({
        snackMessage: i18n.t(
          isFinished ? "coaching-finished" : "coaching-relaunched",
        ),
      }),
    );
  } catch (error) {
    console.log(error);
    yield put({ type: END_COACHING_ERROR, payload: error });
  }
}

function* userWatcher() {
  yield all([
    takeLatest(UPDATE_COACHING_SESSION_REQUESTING, updateCoachingSessionFlow),
    takeLatest(CREATE_COACHING_SESSION_REQUESTING, createCoachingSessionFlow),
    takeLatest(GET_COACHING_SESSION_REQUESTING, getCoachingSessionFlow),
    takeLatest(DELETE_COACHEE_REQUESTING, deleteCoachingSessionFlow),
    takeLatest(UPDATE_COACHING_NOTE_REQUESTING, updateCoachingNoteFlow),
    takeLatest(CREATE_COACHING_NOTE_REQUESTING, createCoachingNoteFlow),
    takeLatest(GET_COACHING_NOTES_REQUESTING, getCoachingNotesFlow),
    takeLatest(DELETE_COACHING_NOTE_REQUESTING, deleteCoachingNoteFlow),
    takeLatest(END_COACHING_REQUESTING, endCoachingSessionFlow),
    takeLatest(GET_COACHING_FILES_REQUESTING, getCoachingFilesFlow),
    takeEvery(CREATE_COACHING_FILE_REQUESTING, createCoachingFileFlow),
    takeLatest(UPDATE_COACHING_FILE_REQUESTING, updateCoachingFileFlow),
    takeLatest(DELETE_COACHING_FILE_REQUESTING, deleteCoachingFileFlow),
  ]);
}

export default userWatcher;
