import React, { PureComponent } from "react";
import { connect } from "react-redux";

import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";

import Colors from "../../../constants/Colors";
import i18n from "../../../i18n";
import "./style.css";

import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  Configure,
  Index,
  connectStateResults,
} from "react-instantsearch-dom";
import LibraryHits from "./LibraryHits";
import {
  getUserClientId,
  getSelectedClientId,
  getUserId,
  getIsSuperAdmin,
} from "../../../services/user/selectors";
import {
  addStep,
  deleteStepRequest,
  duplicateStep,
  emitStepUpdate,
  getMyLibraryStepsRequest,
} from "services/createTrack/actions";
import { getAppLanguage } from "../../../services/general/selectors";
import FilterMenu from "./FilterMenu";
import { LightButton, Loader } from "../../../components";
import PreviewSideBar from "scenes/Campaigns/CampaignScreen/ActionsSide/PreviewSideBar";
import FolderList from "scenes/CreateTrack/FolderList";
import AddIcon from "scenes/MyCoachees/AddIcon";
import {
  getIsLibrary,
  getTrackId,
  getMyLibrarySteps,
  getNumberOfSteps,
} from "services/createTrack/selectors";
import { getIsCoachingAccount } from "services/client/selectors";
import MyLibraryHits from "./MyLibraryHits";
import SearchMyActions from "../SearchMyActions";
import Fuse from "fuse.js";

const searchClient = algoliasearch(
  "MTMFJ78PRC",
  "a6400a80c85f1dcceaaddde616c096a3",
);

const fuseOptions = {
  isCaseSensitive: false,
  shouldSort: true,
  threshold: 0.6,
  location: 0,
  distance: 1000,
  maxPatternLength: 80,
  minMatchCharLength: 1,
  keys: [
    "title.fr",
    "title.en",
    "content.title.fr",
    "content.title.en",
    "content.why.fr",
    "content.why.en",
    "content.how.fr",
    "content.how.en",
  ],
};

const LoadingIndicator = connectStateResults(({ isSearchStalled }) =>
  isSearchStalled ? <Loader style={{ marginTop: 10 }} size={50} /> : null,
);

const styles = theme => ({
  main: {
    width: "100%",
    height: "100%",
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
  },
  bottom: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  topSide: {
    backgroundColor: Colors.white,
    zIndex: 3,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  searchBox: {
    marginTop: 20,
    marginLeft: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  search: {
    width: "100%",
  },
  index: {
    height: "100%",
  },
  helperBar: {
    width: "100%",
    background: Colors.white,
    height: 60,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 22,
    lineHeight: "27px",
    color: Colors.text,
  },
  text: {
    flex: 1,
    fontSize: 14,
    marginLeft: 10,
    paddingLeft: 10,
    borderLeft: `solid ${Colors.text}`,
    borderLeftWidth: 1,
  },
  helperBarRight: {
    borderRadius: 4,
    paddingTop: 15,
    paddingLeft: 20,
    paddingRight: 10,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  helperBarLeft: {
    borderRadius: 4,
    paddingTop: 15,
    paddingLeft: 20,
    paddingRight: 10,
    width: "100%",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  leftSide: {
    overflow: "hidden",
    borderRight: `solid ${Colors.lightGrey}`,
    borderRightWidth: 1,
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  loaderDiv: {
    backgroundColor: "#F5F5F5",
  },
  rightSide: {
    flex: 1,
    display: "flex",
  },
  emptyDiv: {
    height: 60,
  },
  emptyDivWithoutFolders: {
    height: 20,
  },
  icon: {
    marginRight: 5,
  },
});

class Library extends PureComponent {
  constructor() {
    super();

    this.state = {
      stepSelectedIndex: 0,
      isPreviewVisible: false,
      isNewActionModalVisible: false,
      stepPreview: { content: {} },
      nextStepPreview: { content: {} },
      refresh: false,
      filterSelected: {},
      folderSelected: {},
      sideSelected: null,
      premiumFilterSelected: {},
      search: "",
    };
  }

  componentDidMount = () => {
    this.refresh();
    if (!this.props.isLibrary) {
      const goodClientId = this.props.isSuperAdmin
        ? this.props.selectedClientId
        : this.props.clientId;

      this.props.getMyLibraryStepsRequest({ clientId: goodClientId });
    }
  };

  onSearchStateChange = searchState => {
    // update the URL when there is a new search state.
    this.setState(previousState => {
      const hasQueryChanged =
        previousState.searchState.query !== searchState.query;

      return {
        ...previousState,
        searchState: {
          ...searchState,
          boundingBox: !hasQueryChanged ? searchState.boundingBox : null,
        },
      };
    });
  };

  selectFilter = filterSelected => () => {
    this.setState({ filterSelected });
  };

  selectPremiumFilter = premiumFilterSelected => () => {
    this.setState({ premiumFilterSelected });
  };

  scrollToBottom = () => {
    this.leftSideEndRef.scrollBy(0, 800);
  };

  refresh = () => {};

  onClickStep = sideSelected => ({ stepPreview, stepSelectedIndex }) => {
    this.setState({
      isPreviewVisible: true,
      stepPreview,
      sideSelected,
      stepSelectedIndex,
      stepPreviewIndex: stepPreview._id,
    });
  };

  componentDidUpdate = prevProps => {
    if (prevProps.numberOfSteps > this.props.numberOfSteps) {
      // refresh algolia cache
      this.refresh();
    } else if (
      prevProps.stepRequesting !== this.props.stepRequesting &&
      !this.props.stepRequesting
    ) {
      this.refresh();
    }
  };

  onClickAdd = () => {
    const {
      selectedClientId,
      isSuperAdmin,
      clientId,
      trackId,
      isLibrary,
    } = this.props;
    // We are in coaching account and want to import a model
    if (!this.state.stepPreview.isPrivate && isLibrary) {
      return this.props.duplicateStep(this.state.stepPreview);
    }

    if (this.props.isCoachingAccount && !this.state.stepPreview.isPrivate) {
      this.props.duplicateStep(this.state.stepPreview);
      return;
    }

    // We are in the library so we only add premium content and duplicate it right away in our own library
    const result = {
      ...this.state.stepPreview,
      trackId,
      step: this.state.stepPreview._id,
    };
    this.props.addStep(result);
    if (isSuperAdmin) {
      emitStepUpdate({
        step: result,
        clientId: selectedClientId,
        trackId,
        updateType: "add",
      });
    } else {
      emitStepUpdate({ step: result, clientId, trackId, updateType: "add" });
    }
  };

  onClosePreview = () => {
    this.setState({
      isPreviewVisible: false,
      stepPreview: { content: {} },
      isSideSelected: "none",
    });
  };

  onClickFolder = folderSelected => () => {
    if (folderSelected.value === this.state.folderSelected.value) {
      this.setState({ folderSelected: {} });
    } else {
      this.setState({ folderSelected });
    }
  };

  onChangeSearch = search => {
    this.setState({ search });
  };

  onClickDelete = step => {
    const { clientId } = this.props;
    const { isPrivate } = step;
    const stepId = step._id;

    if (stepId) {
      if (isPrivate) {
        this.props.deleteStepRequest({
          ...this.props.step,
          stepId,
          clientId,
        });
      }
    }
    this.onClosePreview();
  };

  openNewActionModal = () => {
    this.setState({ isNewActionModalVisible: true });
  };

  getAddButtonText = () => {
    if (this.props.isCoachingAccount && !this.state.stepPreview.isPrivate) {
      return i18n.t("import-copy");
    }
    return "";
  };

  render() {
    const {
      classes,
      skillsList = { skills: [] },
      hasCreateNewAction = false,
      isLibrary,
      myLibrarySteps,
    } = this.props;
    const { search } = this.state;

    const fuse = new Fuse(this.props.myLibrarySteps, fuseOptions);
    const searchResults =
      search.length > 0 ? fuse.search(search) : myLibrarySteps;

    return (
      <div className={classes.main}>
        <PreviewSideBar
          addButtonText={this.getAddButtonText()}
          onClickDelete={this.onClickDelete}
          onClickAdd={this.onClickAdd}
          stepPreviewIndex={this.state.stepPreviewIndex}
          stepPreview={this.state.stepPreview}
          isVisible={this.state.isPreviewVisible}
          onClose={this.onClosePreview}
        />
        <div className={classes.topSide}>
          <div className={classes.helperBar}>
            {!isLibrary && (
              <div className={classes.helperBarLeft}>
                <Typography className={classes.title}>
                  {i18n.t("my-library")}
                </Typography>
                {hasCreateNewAction && (
                  <LightButton
                    icon={
                      <AddIcon
                        className={classes.icon}
                        color={Colors.pureMain}
                      />
                    }
                    title={i18n.t("create-new-challenge")}
                    className={classes.lightButton}
                    onClick={this.openNewActionModal}
                  />
                )}
              </div>
            )}
            <div className={classes.helperBarRight}>
              <Typography className={classes.title}>
                {i18n.t("premium-content")}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.bottom}>
          {!isLibrary && (
            <div className={classes.leftSide}>
              <SearchMyActions
                isLibrary={isLibrary}
                skillsList={skillsList}
                selectFilter={this.selectFilter}
                filterSelected={this.state.filterSelected}
                placeholder={i18n.t("search-library-placeholder")}
                search={this.state.search}
                onChangeSearch={this.onChangeSearch}
              />
              {!isLibrary && (
                <FolderList
                  folderSelected={this.state.folderSelected}
                  onClickFolder={this.onClickFolder}
                />
              )}
              {this.props.isMyLibraryStepsLoading && (
                <div className={classes.loaderDiv}>
                  <Loader
                    size={25}
                    style={{ marginTop: 10, marginBottom: 10 }}
                  />
                </div>
              )}
              <div
                className={classes.index}
                indexName={
                  this.props.isSuperAdmin
                    ? this.props.selectedClientId
                    : this.props.clientId
                }
              >
                <MyLibraryHits
                  hits={searchResults}
                  isSideSelected={this.state.sideSelected === "left"}
                  stepSelectedIndex={this.state.stepSelectedIndex}
                  stepSelectedId={this.state.stepPreview._id}
                  filterSelected={this.state.filterSelected}
                  folderSelected={this.state.folderSelected}
                  onClosePreview={this.onClosePreview}
                  onClickStep={this.onClickStep("left")}
                  language={this.props.language}
                  selectTeamMember={this.onSelect}
                />
              </div>
            </div>
          )}
          <div className={classes.rightSide}>
            <InstantSearch
              searchClient={searchClient}
              indexName={this.props.clientId}
              // refresh={this.state.refresh}
              searchState={this.state.searchState}
              // indexName={"public_steps"}
            >
              <div className={classes.searchBox}>
                <SearchBox
                  showLoadingIndicator
                  className={classes.search}
                  translations={{
                    placeholder: i18n.t("search-library-placeholder"),
                  }}
                />
                <FilterMenu
                  skillsList={skillsList}
                  selectFilter={this.selectPremiumFilter}
                  filterSelected={this.state.premiumFilterSelected}
                />
              </div>
              <div
                className={`${classes.emptyDiv} ${
                  isLibrary && classes.emptyDivWithoutFolders
                }`}
              />
              <LoadingIndicator />
              <Index indexName="public_steps">
                <Configure
                  filters={`type:content AND isRemoved!=1`}
                  hitsPerPage={1000}
                />
                <LibraryHits
                  isSideSelected={this.state.sideSelected === "right"}
                  stepSelectedIndex={this.state.stepSelectedIndex}
                  stepSelectedId={this.state.stepPreview._id}
                  filterSelected={this.state.premiumFilterSelected}
                  onClosePreview={this.onClosePreview}
                  onClickStep={this.onClickStep("right")}
                  language={this.props.language}
                  selectTeamMember={this.onSelect}
                />
              </Index>
            </InstantSearch>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    skillsList,
    premiumTotalCount,
    myLibraryTotalCount,
    stepRequesting,
    isMyLibraryStepsLoading,
  } = state.createTrack;
  const language = getAppLanguage(state);
  const clientId = getUserClientId(state);
  const selectedClientId = getSelectedClientId(state);
  const isSuperAdmin = getIsSuperAdmin(state);
  const userId = getUserId(state);
  const isLibrary = getIsLibrary(state);
  const trackId = getTrackId(state);

  const isCoachingAccount = getIsCoachingAccount(state);

  return {
    isCoachingAccount,
    isLibrary,
    userId,
    clientId,
    selectedClientId,
    numberOfSteps: getNumberOfSteps(state),
    myLibrarySteps: getMyLibrarySteps(state),
    isMyLibraryStepsLoading,
    premiumTotalCount,
    myLibraryTotalCount,
    skillsList,
    isSuperAdmin,
    language,
    stepRequesting,
    trackId,
  };
};

export default connect(mapStateToProps, {
  addStep,
  deleteStepRequest,
  duplicateStep,
  getMyLibraryStepsRequest,
})(withStyles(styles)(Library));
