import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import i18n from "../i18n";
import Colors from "../constants/Colors";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    paddingTop: 9,
    paddingBottom: 9,
    paddingLeft: 24,
    paddingRight: 24,
    border: "solid",
    borderRadius: 4,
    borderWidth: 2,
    borderColor: "#DCDDE3",
  },
  focus: {
    borderColor: Colors.pureMain,
  },
  messageBarInput: {
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
    lineHeight: 1.33,
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    color: "#565867",
    " -webkit-font-soothing": "antialiased",
    maxHeight: 200,
    overflowX: "hidden",
    overflowY: "hidden",
    border: "none",
    marginLeft: 12,
    marginRight: 12,
    outline: "none",
    userSelect: "text",
    fontSize: 15,
    width: "100%",
    backgroundColor: "transparent",
    "&:empty:before": {
      content: "attr(placeholder)",
      display: "block" /* For Firefox */,
      color: "rgba(86, 88, 103, 0.3)",
      outline: "none",
    },
  },
  searchIcon: {
    fontSize: 20,
    zIndex: 1,
    cursor: "pointer",
    color: "#007EFC",
  },
  clearIcon: {
    fontSize: 20,
    zIndex: 1,
    cursor: "pointer",
    color: Colors.grey,
  },
});

const SearchBar = ({
  onSubmit = () => null,
  onChangeSearch,
  value,
  className = "",
  inputClassName = "",
  placeholder = i18n.t("search-placeholder") as string,
  autoFocus = false,
}) => {
  const classes = useStyles();
  const [isFocus, setIsFocus] = React.useState(false);

  const onSearch = e => {
    const { value } = e.target;
    onChangeSearch(value);
  };

  const onBlur = () => setIsFocus(false);
  const onFocus = () => setIsFocus(true);

  const onClearSearch = () => onChangeSearch("");

  return (
    <div
      className={`${classes.main} ${isFocus && classes.focus} ${className} `}
    >
      <SearchIcon onClick={onSubmit} className={classes.searchIcon} />
      <input
        role="button"
        tabIndex={0}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        autoFocus={autoFocus}
        onChange={onSearch}
        contentEditable="true"
        placeholder={placeholder}
        className={`${classes.messageBarInput} ${inputClassName}`}
      />
      {!!value && (
        <ClearIcon onClick={onClearSearch} className={classes.clearIcon} />
      )}
    </div>
  );
};

export default SearchBar;
