import React from "react";
import withStyles from "@mui/styles/withStyles";
import { Typography } from "@mui/material";

import Colors from "../../constants/Colors";

import QuoteIcon from "./QuoteIcon";
import moment from "moment";

const styles = theme => ({
  main: {
    boxSizing: "border-box",
    position: "relative",
    display: "inline-block",
    width: "100%",
    backgroundColor: "#FAFAFC",
    borderRadius: 10,
    marginTop: 30,
    paddingTop: 25,
    paddingLeft: 30,
    paddingRight: 25,
  },
  quote: {
    width: "100%",
    height: "100%",
    alignItems: "flex-start",
    justifyContent: "center",
    display: "flex",
    marginTop: 15,
    flex: 3,
  },
  quoteIcon: {
    left: 20,
    top: -15,
    position: "absolute",
  },
  quoteText: {
    color: Colors.darkBlue,
    width: "100%",
    textAlign: "left",
    fontSize: 16,
    marginBottom: 50,
  },
  date: {
    color: "#BEC6FF",
    position: "absolute",
    bottom: 8,
    right: 10,
  },
  userDiv: {
    marginTop: 25,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  name: {
    fontSize: 16,
    fontFamily: "Montserrat",
    fontWeight: "bold",
  },
});

const Feedback = ({
  comment = "",
  userId = "",
  classes,
  className = "",
  createdAt,
}) => {
  const isObject = false;
  return (
    <div className={`${classes.main} ${className}`}>
      <div className={classes.quoteIcon}>
        <QuoteIcon />
      </div>
      {isObject && (
        <div className={classes.userDiv}>
          <Typography
            className={classes.name}
          >{`${userId.firstName} ${userId.lastName}`}</Typography>
        </div>
      )}
      <div className={classes.quote}>
        <Typography className={classes.quoteText} align="left">
          {`${comment}`}
        </Typography>
      </div>
      <Typography className={classes.date}>
        {!!createdAt && moment(createdAt).format("L")}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(Feedback);
