import React from "react";

import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import Colors from "../../constants/Colors";
import FlatButton from "../../components/FlatButton";
import { Loader } from "../../components";
import Uploader from "../../components/Uploader";
import i18n from "../../i18n";
import Switch from "react-switch";

const styles = theme => ({
  main: {
    position: "relative",
  },
  line: {
    width: "100%",
    marginBottom: 25,
    borderRadius: 8,
    height: 2,
    backgroundColor: Colors.blackGrey,
  },
  errorText: {
    color: "#e6525a",
    padding: "13px 15px",
    borderRadius: 6,
    backgroundColor: "rgba(230,82,90,0.15)",
  },
  bodyDiv: {
    maxWidth: "100%",
    width: 960,
  },
  exitModalText: {
    marginTop: -4,
    fontSize: 13,
  },
  title: {
    fontFamily: "Montserrat",
    // color: Colors.black,
    marginBottom: 10,
    fontSize: 28,
    lineHeight: 1.2143,
    fontWeight: 700,
  },
  subTitle: {
    fontFamily: "Montserrat",
    // color: Colors.black,
    marginTop: 20,
    marginBottom: 5,
    fontSize: 22,
    lineHeight: 1.2143,
    fontWeight: 700,
  },
  goals: {
    flex: 1,
    marginTop: "calc(1rem)",
    marginLeft: "-1.5rem",
    marginRight: "-1.5rem",
    marginBottom: "calc(-3rem)",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  buttonDiv: {
    paddingTop: 24,
  },
  leftButton: {
    marginRight: 12,
    color: Colors.black,
    backgroundColor: "transparent",
    border: "1px solid",
    borderColor: "rgba(29, 28, 29, 0.3)",
    transition: "all 80ms linear",
    "&:hover": {
      opacity: 0.9,
      backgroundColor: "transparent",
      border: "1px solid",
      borderColor: "rgba(29, 28, 29, 0.3)",
      boxShadow: "0 1px 3px 0 rgba(0,0,0,.08)",
    },
  },
  explanation: {
    textAlign: "left",
    maxWidth: "100%",
    width: 960,
    // color: Colors.grey,
    marginTop: 25,
    fontSize: 15,
    lineHeight: 1.2143,
  },
  uploader: {
    marginTop: 16,
  },
});

const NewClientForm = ({
  classes,
  onClickSubmit,
  onClickPrevious,
  requesting,
  organization,
  clientCreatorDescription,
  onChangeClientCreatorDescription,
  onChangeOrganization,
  onFileUploaded,
  uploading,
  picture,
  buttonText,
  isSuperAdmin,
  isCoachingAccount,
  onChangeIsCoachingAccount,
}) => {
  return (
    <div className={classes.main}>
      <Typography className={classes.explanation}>
        {i18n.t("client-explanation")}
      </Typography>
      <Typography className={classes.subTitle}>
        {i18n.t("client-name")} *
      </Typography>
      <TextField
        autoFocus
        fullWidth
        label={i18n.t("client-name")}
        value={organization}
        onChange={onChangeOrganization}
        margin="normal"
        variant="outlined"
      />
      <Typography className={classes.subTitle}>
        {i18n.t("client-description")}
      </Typography>
      <TextField
        fullWidth
        multiline
        label={i18n.t("client-description")}
        value={clientCreatorDescription}
        onChange={onChangeClientCreatorDescription}
        margin="normal"
        variant="outlined"
      />
      <Typography className={classes.subTitle}>
        {i18n.t("client-logo")}
      </Typography>
      <Uploader
        placeholder={i18n.t("upload-client-logo")}
        width={300}
        height={150}
        picture={picture}
        className={classes.uploader}
        onFileUploaded={onFileUploaded}
        uploading={uploading}
      />
      {/* <Switch onChange={this.toggleWhiteLabel} checked={hasWhiteLabel} /> */}
      {isSuperAdmin && (
        <>
          <Typography className={classes.subTitle}>
            {i18n.t("coaching-account")}
          </Typography>
          <Switch
            onChange={onChangeIsCoachingAccount}
            checked={isCoachingAccount}
          />
          {/* <BooleanField
          title={i18n.t("coaching-account")}
          explanation={i18n.t("coaching-account-explanation")}
          onChange={onChangeIsCoachingAccount}
          checked={isCoachingAccount}
        /> */}
        </>
      )}
      <div className={classes.buttonDiv}>
        <FlatButton
          className={classes.leftButton}
          onClick={onClickPrevious}
          color="primary"
          title={i18n.t("previous")}
        ></FlatButton>
        {requesting ? (
          <Loader size={50} />
        ) : (
          <FlatButton
            disabled={!organization || requesting}
            variant="contained"
            color="primary"
            onClick={onClickSubmit}
            title={buttonText}
          ></FlatButton>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(NewClientForm);
