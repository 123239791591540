import React, { useCallback, useState } from "react";
import Skill from "./Skill";
import Colors from "../../../constants/Colors";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { updateSkillsListRequest } from "../../../services/createTrack/actions";
import ClickOutside from "../../../components/ClickOutside";
import Fuse from "fuse.js";
import AddSkillBox from "./AddSkillBox";
import { useDispatch, useSelector } from "react-redux";

import { SKILLS_COLORS } from "./SkillOptionMenu";
import i18n from "../../../i18n";
import useFocus from "./useFocus";
import selectLanguage from "../../../utils/selectLanguage";
import getRandomColor from "utils/getRandomColor";
import { getSkillsList } from "services/createTrack/selectors";

export const cleanInput = input => {
  if (!input) return input;
  const clearedInput = input
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .trim()
    .toLowerCase();
  return clearedInput;
};

const fuseOptions = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 80,
  maxPatternLength: 20,
  minMatchCharLength: 1,
  keys: ["label.fr", "label.en", "value"],
};

const useStyles = makeStyles(theme => ({
  mainBox: {
    position: "relative",
    width: "fit-content",
  },
  mainBoxOpen: {
    position: "relative",
    width: "fit-content",
  },
  main: {
    paddingLeft: 8,
    paddingRight: 2,
    cursor: "pointer",
    borderRadius: 5,
    paddingTop: 8,
    paddingBottom: 2,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    transition: "all 0.2s",
    "&:hover": {
      // backgroundColor: Colors.editable,
    },
  },
  focused: {
    maxHeight: 240,
    width: "100%",
    minHeight: 34,
    backgroundColor: "rgba(242, 241, 238, 0.6)",
    boxShadow: "rgba(55, 53, 47, 0.16) 0px -1px inset",
    borderRadius: 0,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    fontSize: 14,
    cursor: "text",
    overflow: "hidden",
    padding: "8px 9px 1px",
    "&:hover": {
      backgroundColor: "rgba(242, 241, 238, 0.6)",
    },
  },
  input: {
    width: "100%",
    padding: 0,
    height: 20,
    fontSize: "inherit",
    lineHeight: "inherit",
    border: "none",
    background: "none",
    resize: "none",
    "&:focus": {
      outline: 0,
    },
  },
  bigBox: {
    borderRadius: 3,
    background: "white",
    position: "relative",
    maxWidth: "calc(100vw - 24px)",
    boxShadow:
      "rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px",
    width: 477,
  },
  emptySkill: {
    padding: "5px 10px",
    border: "dashed 1px",
    borderRadius: 4,
    borderColor: Colors.pureMain,
  },
  notEmpty: {},
  notFocused: {
    "&:hover": {
      opacity: 0.8,
    },
  },
}));

const SkillInput = ({
  skills = [],
  updateStep,
  isDisabled = false,
  language,
  onOpen = null,
}) => {
  const [isFocused, setFocus] = useState(false);
  const [inputRef, setInputFocus] = useFocus();
  const [isOptionMenuOpen, openOptionMenu] = useState(false);
  const [search, setSearch] = useState("");

  const classes = useStyles();
  const skillsList = useSelector(getSkillsList);

  const dispatch = useDispatch();

  const onSearch = event => {
    setSearch(event.target.value);
  };

  const submit = () => {
    const searchValue = cleanInput(search);

    if (!search) return;
    // create skill
    const skillIndex = skillsList?.skills.findIndex(
      el =>
        cleanInput(selectLanguage({ text: el.label, language })) ===
        searchValue,
    );
    const isPresent = skillIndex > -1;
    if (!isPresent) {
      const availableColors = SKILLS_COLORS.filter(el => {
        return (
          skillsList?.skills?.findIndex(skill => skill?.color === el?.color) ===
          -1
        );
      });
      const color =
        availableColors.length > 1
          ? availableColors[0].color
          : getRandomColor();

      const cleanLabel = search.trim();

      const newSkill = {
        label: { fr: cleanLabel, en: cleanLabel },
        color,
        value: searchValue,
      };
      dispatch(
        updateSkillsListRequest({
          skillsListId: skillsList._id,
          skills: [...skillsList.skills, newSkill],
        }),
      );
      const stepSkills = [...skills, newSkill];
      updateStep(stepSkills);
    } else {
      const newSkill = skillsList.skills[skillIndex];
      const stepSkills = [...skills, newSkill];
      updateStep(stepSkills);
    }
    setSearch("");
    setInputFocus();
  };

  const onKeyDown = event => {
    // delete last skill if search empty on Delete Key
    if ((event.key === "Delete" || event.key === "Backspace") && !search) {
      const stepSkills = skills.slice(0, skills.length - 1);
      updateStep(stepSkills);
    }
    // Tab and Enter event
    if (event.keyCode === 9 || event.keyCode === 13) {
      submit();
    }
  };

  const onClickSkillSuggestion = skill => () => {
    const stepSkills = [...skills, skill];
    updateStep(stepSkills);
    setSearch("");
    setInputFocus();
  };

  const onRemoveSkill = useCallback(
    skill => {
      const stepSkills = skills.filter(el => el?.value !== skill?.value);
      updateStep(stepSkills);
      setInputFocus();
    },
    [updateStep, setInputFocus, skills],
  );

  const getAvailableSkillsSuggestion = () => {
    return skillsList?.skills?.filter(el => {
      return skills?.findIndex(skill => skill?.value === el?.value) === -1;
    });
  };

  const onClickSkillInput = () => {
    if (!isDisabled) {
      setFocus(true);
      if (onOpen) {
        onOpen();
      }
    }
  };

  const onClickOutside = () => {
    if (!isOptionMenuOpen) {
      setFocus(false);
    }
  };

  const skillsSuggestion = getAvailableSkillsSuggestion();
  const fuse = new Fuse(skillsSuggestion, fuseOptions);
  const searchResults =
    search.length > 0 ? fuse.search(search) : skillsSuggestion;

  const notEmpty = skills.length > 0;

  return (
    <ClickOutside
      className={`${classes.mainBox} ${isFocused && classes.mainBoxOpen}`}
      onClickOutside={onClickOutside}
    >
      <div className={`${isFocused && classes.bigBox}`}>
        <div
          className={`${classes.main} ${isFocused && classes.focused} ${
            notEmpty && !isDisabled && classes.notEmpty
          } ${notEmpty && !isFocused && classes.notFocused}`}
          onClick={onClickSkillInput}
        >
          {skills.length < 1 && !isFocused && !isDisabled && (
            <Typography className={classes.emptySkill}>{`+ ${i18n.t(
              "add-skill",
            )}`}</Typography>
          )}
          {skills.map((el, index) => (
            <Skill
              {...el}
              label={selectLanguage({ text: el?.label, language })}
              key={`${index} ${el?.value}`}
              hasRemove={isFocused}
              onClickRemove={onRemoveSkill}
            />
          ))}
          {isFocused && (
            <input
              ref={inputRef}
              onKeyDown={onKeyDown}
              autoFocus
              className={classes.input}
              onChange={onSearch}
              value={search}
            />
          )}
        </div>
        {isFocused && (
          <AddSkillBox
            search={search}
            language={language}
            onClickCreate={submit}
            onClickSkill={onClickSkillSuggestion}
            skillsSuggestion={searchResults}
            openOptionMenu={openOptionMenu}
          />
        )}
      </div>
    </ClickOutside>
  );
};

export default React.memo(SkillInput);
