import {
  SEND_EMAIL_INVITATIONS_ERROR,
  SEND_EMAIL_INVITATIONS_SUCCESS,
  SEND_EMAIL_INVITATIONS_REQUESTING,
  RESET_PASSWORD_REQUESTING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
} from "./constants";
import i18n from "i18n";

export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILURE = "AUTH_FAILURE";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_LOGOUT_SUCCESS = "AUTH_LOGOUT_SUCCESS";

export const authorize = (email, password) => ({
  type: AUTH_REQUEST,
  payload: { email, password },
});

export const logout = () => ({
  type: AUTH_LOGOUT,
});

const initialState = {
  passwordResetMessage: "",
  accessToken: localStorage.getItem("token"),
  role: localStorage.getItem("role"),
  requesting: false,
  pendingInvitations: [],
  userId: "",
  clientId: "",
  refreshToken: "",
  expiresIn: "",
  expiresAt: "",
  tokenType: "",
  error: null,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case AUTH_REQUEST: {
      return {
        ...state,
        error: null,
        requesting: true,
      };
    }

    case AUTH_SUCCESS: {
      return { ...state, ...payload, requesting: false, error: null };
    }

    case AUTH_FAILURE: {
      return { ...state, requesting: false, error: payload };
    }

    case AUTH_LOGOUT_SUCCESS: {
      return { ...initialState, accessToken: "", role: "user" };
    }
    case SEND_EMAIL_INVITATIONS_ERROR:
      return { ...state, error: payload, requesting: false };

    case SEND_EMAIL_INVITATIONS_SUCCESS:
      return {
        ...state,
        requesting: false,
        pendingInvitations: [...state.pendingInvitations, ...payload],
      };

    case SEND_EMAIL_INVITATIONS_REQUESTING:
      return {
        ...state,
        requesting: true,
      };

    case RESET_PASSWORD_REQUESTING: {
      return {
        ...state,
        requesting: true,
        resetPasswordSent: false,
        passwordResetMessage: "",
      };
    }

    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        requesting: false,
        passwordResetMessage: i18n.t("reset-password-sent"),
      };
    }

    case RESET_PASSWORD_ERROR: {
      return {
        ...state,
        requesting: false,
        error: i18n.t("password-reset-error"),
      };
    }

    default:
      return state;
  }
};

export default reducer;
