import React from "react";
import { ResponsiveLine } from "@nivo/line";

// const dataTest = [
//   {
//     id: "Confiance",
//     data: [{ x: "Janvier", y: 7 }, { x: "Fevrier", y: 9 },]
//   },
//   {
//     id: "Stress",
//     data: [{ x: "Janvier", y: 6 }, { x: "Fevrier", y: 5.5 }]
//   },
//   {
//     id: "Cohésion",
//     data: [{ x: "Janvier", y: 4 }, { x: "Fevrier", y: -2 }]
//   },
//   {
//     id: "Aisance à l'oral",
//     data: [{ x: "Janvier", y: 5 }, { x: "Fevrier", y: 10 }]
//   }
// ];

// const dataTest = [
//   {
//     id: "Confiance",
//     data: [{ x: "Janvier", y: 7 }, { x: "Fevrier", y: 9 }, { x: "Mars", y: 10 }]
//   },
//   {
//     id: "Stress",
//     data: [
//       { x: "Janvier", y: 6 },
//       { x: "Fevrier", y: 5.5 },
//       { x: "Mars", y: 7 }
//     ]
//   },
//   {
//     id: "Cohésion",
//     data: [{ x: "Janvier", y: 4 }, { x: "Fevrier", y: 4 }, { x: "Mars", y: 5 }]
//   },
//   {
//     id: "Aisance à l'oral",
//     data: [{ x: "Janvier", y: 5 }, { x: "Fevrier", y: 6 }, { x: "Mars", y: 8 }]
//   }
// ];

const roundTwoDigits = num => {
  return Math.floor(num * 100) / 100;
};

// const dataTest = [
//   {
//     id: "Confiance",
//     data: [{ x: "Janvier", y: 7 }, { x: "Fevrier", y: 9 }, { x: "Mars", y: 10 }]
//   },
//   {
//     id: "Stress",
//     data: [
//       { x: "Janvier", y: 6 },
//       { x: "Fevrier", y: 6.5 },
//       { x: "Mars", y: 7 }
//     ]
//   },
//   {
//     id: "Cohésion",
//     data: [{ x: "Janvier", y: 4 }, { x: "Fevrier", y: 4 }, { x: "Mars", y: 5 }]
//   },
//   {
//     id: "Aisance à l'oral",
//     data: [
//       { x: "Janvier", y: 5 },
//       { x: "Fevrier", y: 5.5 },
//       { x: "Mars", y: 6 }
//     ]
//   }
// ];

const formatData = data => {
  // if (!data.length) {
  //
  //   return [
  //     {
  //       id: "Stress",
  //       data: []
  //     },

  //     {
  //       id: "Aisance à l'oral",
  //       data: []
  //     },
  //     {
  //       id: "Cohésion",
  //       data: []
  //     },
  //     {
  //       id: "Confiance",
  //       data: []
  //     }
  //   ];
  // }
  const endResult = [
    {
      id: "Stress",
      data: [],
    },

    {
      id: "Aisance à l'oral",
      data: [],
    },
    {
      id: "Cohésion",
      data: [],
    },
    {
      id: "Confiance",
      data: [],
    },
  ];
  data.forEach((el, i) => {
    endResult[0].data.push({ x: el.mois, y: roundTwoDigits(el.Stress) });
    endResult[1].data.push({
      x: el.mois,
      y: roundTwoDigits(el["Aisance à l'oral"]),
    });
    endResult[2].data.push({
      x: el.mois,
      y: roundTwoDigits(el["Cohésion d'équipe"]),
    });
    endResult[3].data.push({ x: el.mois, y: roundTwoDigits(el.Confiance) });
  });
  return endResult;
};

const colorRange = [
  "rgba(252,87,122,0.8)",
  "#FFC233",
  "#FF9F40",
  "rgba(0,170,255,0.8)",
  "rgba(66,80,251,0.8)",
];
// keys={["Stress", "Aisance à l'oral", "Confiance", "Cohésion d'équipe"]}

// 0: {mois: "janvier", monthNumber: 0, users: 1, Stress: 1, Confiance: 0.1, …}
// 1: {mois: "février", monthNumber: 1, users: 1, Stress: -1, Confiance: 2, …}
// 2: {mois: "mars", monthNumber: 2, users: 1, Stress: 1, Confiance: 0.1, …}
// 3: {mois: "avril", monthNumber: 3, users: 1, Stress: 2, Confiance: 1, …}
// 4: {mois: "mai", monthNumber: 4, users: 2, Stress: -0.5, Confiance: 1, …}
// 5: {mois: "novembre", monthNumber: 10, users: 1, Stress: 0.1, Confiance: 0.1, …}
// length: 6
// __proto__: Array(0

const EvolutionChart = ({ data }) => {
  return (
    // make sure parent container have a defined height when using
    // responsive component, otherwise height will be 0 and
    // no chart will be rendered.
    // website examples showcase many properties,
    // you'll often use just a few of them.
    <ResponsiveLine
      data={formatData(data)}
      margin={{
        top: 20,
        // top: 0,
        right: 25,
        bottom: 80,
        // bottom: 0,
        left: 45,
      }}
      xScale={{ type: "point" }}
      yScale={{ type: "linear", stacked: false, min: "auto", max: "auto" }}
      curve="cardinal"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        // legend: "Mois",
        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "niveau",
        legendOffset: -40,
        legendPosition: "middle",
      }}
      colors={colorRange}
      pointSize={9}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabel="y"
      pointLabelYOffset={-12}
      enableArea={false}
      areaOpacity={0.15}
      useMesh={false}
      enableGridX={false}
      enableGridY={false}
      legends={[
        {
          anchor: "bottom",
          direction: "row",
          justify: false,
          translateX: 0,
          translateY: 60,
          //   itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemsSpacing: 20,
          itemWidth: 100,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

export default EvolutionChart;
