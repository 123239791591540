import React, { useState } from "react";
import withStyles from "@mui/styles/withStyles";
import { IconButton, Typography } from "@mui/material";
import Colors from "constants/Colors";

import { TITLE_WIDTH } from "./GoalsTable";
import EditIcon from "components/EditIcon";
import { RemoveButtonIcon } from "components";
import CustomArrowTooltip from "components/CustomArrowTooltip";

const BAR_HEIGHT = 22;

const styles = theme => ({
  main: {
    paddingTop: 15,
    paddingBottom: 15,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: Colors.hoverBlue,
    },
  },
  title: {
    marginRight: 10,
    fontFamily: "Montserrat",
    fontWeight: "bold",
    cursor: "pointer",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 1 /* number of lines to show */,
    fontSize: 16,
    textAlign: "left",
    flexWrap: "wrap",
    paddingRight: 15,
    width: TITLE_WIDTH,
    color: Colors.darkBlue,
    transition: "all 0.2s ease",
    padding: "3px 5px",
    borderRadius: 4,
  },
  number: {
    marginRight: 25,
    fontSize: 16,
    textAlign: "left",
    color: Colors.darkBlue,
  },
  container: {
    backgroundColor: "#F2F2F2",
    borderRadius: 2,
    position: "relative",
    height: BAR_HEIGHT,
    flex: 1,
  },
  bar: {
    transition: "all .5s ease-in-out",
    height: BAR_HEIGHT,
    borderRadius: 2,
    width: "0%",
    backgroundColor: Colors.pureMain,
  },
  percent: {
    height: BAR_HEIGHT,
    color: "#6D6D6D",
    marginRight: 15,
    position: "absolute",
    right: 0,
    top: 1,
  },
  donePercent: {
    fontWeight: "600",
    color: "#fff",
  },
  isLast: {
    borderWidth: 0,
    marginBottom: 0,
  },
  modifyIcon: {
    width: 44,
    height: 44,
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: Colors.lightGrey,
    },
  },
  buttons: {
    marginLeft: 10,
    marginRight: 0,
    opacity: 0,
    zIndex: 0,
  },
  visible: {
    zIndex: 1,
    opacity: 1,
    transition: "all 0.2s ease",
  },
  noBold: {
    fontWeight: "normal",
  },
  line: {
    borderBottom: "1px solid #EEEEEE",
    width: "100%",
    height: 1,
  },
});

const getPercent = (totalDone, goal) => {
  const fraction = (totalDone / goal) * 100;
  if (fraction >= 100) {
    return `100%`;
  } else {
    return `${fraction.toFixed(0)}%`;
  }
};

const GoalRow = ({
  classes,
  title,
  totalDone,
  goalNumber,
  titleClassName,
  isLast = false,
  numberOfRecommendedSteps = 0,
  onClickEdit = () => null,
  onClickRemove = () => null,
}) => {
  const [isHovered, setHover] = useState(false);
  const percent = getPercent(totalDone, goalNumber);
  return (
    <>
      <div
        onClick={onClickEdit}
        className={`${classes.main} ${isLast && classes.isLast}`}
        onMouseLeave={() => setHover(false)}
        onMouseEnter={() => setHover(true)}
      >
        <CustomArrowTooltip title={title}>
          <Typography
            onClick={onClickEdit}
            className={`${classes.title} ${titleClassName}`}
          >
            {title}
            <span className={classes.noBold}>
              {" "}
              ({numberOfRecommendedSteps})
            </span>
          </Typography>
        </CustomArrowTooltip>
        <Typography
          className={classes.number}
        >{`${totalDone}/${goalNumber}`}</Typography>
        <div className={classes.container}>
          <div className={classes.bar} style={{ width: percent }} />
          <Typography
            className={`${classes.percent} ${
              percent === "100%" && classes.donePercent
            }`}
          >
            {percent}
          </Typography>
        </div>
        <div className={`${classes.buttons} ${isHovered && classes.visible}`}>
          <RemoveButtonIcon onClick={onClickRemove} />
          <IconButton
            className={`${classes.modifyIcon}`}
            onClick={onClickEdit}
            size="large"
          >
            <EditIcon color="#C4C4C4" />
          </IconButton>
        </div>
      </div>
      {!isLast && <div className={classes.line} />}
    </>
  );
};

export default withStyles(styles)(GoalRow);
