import React, { useState } from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography, IconButton } from "@mui/material";
import i18n from "i18n";
import Colors from "constants/Colors";
import DeleteIcon from "scenes/Campaigns/DeleteIcon";
import EditIcon from "components/EditIcon";
import DuplicateIcon from "scenes/CreateTrack/DuplicateIcon";
import CheckIcon from "components/CheckIcon";
import displayDateWithoutHour from "utils/displayDateWithoutHour";

const styles = theme => ({
  container: {},
  main: {
    // flex: "1 1 auto",
    width: 200,
    position: "relative",
    // width: 170,
    height: 170,
    marginBottom: 15,
    padding: "10px 15px",
    borderRadius: 8,
    backgroundColor: Colors.darkBlue,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    cursor: "pointer",
    transition: "all 0.2s ease",
    border: "solid 4px #fff",
    "&:hover": {
      backgroundColor: Colors.lightDarkBlue,
    },
  },
  title: {
    minWidth: 0,
    overflow: "hidden",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 3 /* number of lines to show */,
    wordBreak: "break-all",
    textAlign: "center",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 14,
    color: "#fff",
  },
  bold: {
    fontWeight: "bold",
  },
  text: {
    fontSize: 13,
    textAlign: "center",
    color: Colors.pureMain,
  },
  icons: {
    width: "100%",
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: Colors.darkBlue,
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  deleteIcon: {
    marginRight: 2,
    width: 44,
    height: 44,
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: Colors.lightGrey,
    },
  },
  modifyIcon: {
    width: 44,
    height: 44,
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: Colors.lightGrey,
    },
  },
  selected: {
    borderColor: Colors.pureMain,
  },
  selectedIcon: {
    zIndex: 100,
    position: "absolute",
    top: -15,
    right: -15,
    width: 30,
    height: 30,
    backgroundColor: Colors.pureMain,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "50%",
  },
  date: {
    fontSize: 12,
    paddingTop: 3,
    paddingBottom: 5,
    paddingLeft: 3,
    paddingRight: 3,
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 5,
    textAlign: "center",
    color: "#fff",
  },
  selectText: {
    zIndex: 1,
    fontSize: 12,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 3,
    paddingRight: 3,
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    textAlign: "center",
    color: "#fff",
    borderRadius: 8,
    backgroundColor: Colors.darkBlue,
  },
});

const SurveyBox = ({
  classes,
  title,
  numberOfQuestions,
  onClick,
  onClickEdit,
  onClickDelete,
  onClickDuplicate,
  isSelected = false,
  createdAt,
}) => {
  const [isHovered, setHover] = useState(false);

  const onMouseEnter = () => setHover(true);
  const onMouseLeave = () => setHover(false);

  return (
    <div
      className={`${classes.main} ${isSelected && classes.selected}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {isSelected && (
        <div className={classes.selectedIcon}>
          <CheckIcon color={"#fff"} />
        </div>
      )}
      {isHovered && (
        <div className={classes.icons}>
          <IconButton className={classes.deleteIcon} onClick={onClickDelete} size="large">
            <DeleteIcon sizePercentage={1.4} />
          </IconButton>
          <IconButton className={classes.deleteIcon} onClick={onClickDuplicate} size="large">
            <DuplicateIcon color={"#fff"} />
          </IconButton>
          <IconButton className={`${classes.modifyIcon}`} onClick={onClickEdit} size="large">
            <EditIcon />
          </IconButton>
        </div>
      )}
      {isHovered && (
        <Typography className={classes.selectText}>
          {!isSelected
            ? i18n.t("click-to-select")
            : i18n.t("click-to-unselect")}
        </Typography>
      )}
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.text}>
        <span className={classes.bold}>{numberOfQuestions} </span>
        {i18n.t("questions")}
      </Typography>
      <Typography className={classes.date}>
        {i18n.t("created-at") +
          " " +
          displayDateWithoutHour(createdAt).toLowerCase()}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(SurveyBox);
