import React, { useCallback, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { enGB, fr } from "date-fns/locale";
import { DatePickerCalendar } from "react-nice-dates";
import { getAppLanguage } from "services/general/selectors";
import { useSelector } from "react-redux";
import TimeSelect from "scenes/Campaigns/SeeUserModal/Coaching/Sessions/NextSessionCalendarModal/TimeSelect";
import "react-nice-dates/build/style.css";
import "./calendar.css";
import moment from "moment";
import i18n from "i18n";

const useStyles = makeStyles(theme => ({
  main: {
    alignSelf: "center",
    marginBottom: 30,
  },
  times: {
    paddingLeft: 15,
    paddingRight: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  separatorLine: {
    width: "100%",
    border: "1px solid #E9E9E9",
    marginTop: 15,
    marginBottom: 25,
  },
  text: {
    fontSize: 16,
    color: "black",
    marginRight: 20,
  },
  separator: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    fontSize: 16,
    color: "black",
    marginLeft: 35,
    marginRight: 35,
    flex: 1,
  },
}));

interface Props {
  date: Date;
  onDateChange: (date: Date) => void;
  duration: number;
  handleDurationChange: (duration: number) => void;
}

const AddAgendaSessionStep = ({
  date,
  onDateChange = () => null,
  handleDurationChange,
  duration,
}: Props) => {
  const [secondDate, setSecondDate] = useState(new Date(date));
  const classes = useStyles();
  const language = useSelector(getAppLanguage);

  const onInit = function() {
    setSecondDate(moment(date).add(duration, "minutes").toDate());
  }

  useEffect(onInit, []);

  const onChangedate = useCallback(() => {
    setSecondDate(moment(date).add(duration, "minutes").toDate());
  }, [date, duration]);

  useEffect(() => {
    onChangedate()
  }, [date, onChangedate]);

  const handleHourChange = e => {
    const value = e.target.value;
    try {
      const splitted = value.split(":");
      const hour = splitted[0];
      const minutes = splitted[1];

      const goodDate = moment(date).hour(hour).minutes(minutes).toDate();
      onDateChange(goodDate);
    } catch (e) {
      console.log(e);
    }
  };

  const onDurationChange = e => {
    const value = e.target.value;

    try {
      const splitted = value.split(":");
      const hour = splitted[0];
      const minutes = splitted[1];

      const nextDate = moment(date).hour(hour).minutes(minutes);
      setSecondDate(nextDate.toDate());

      const duration = nextDate.diff(moment(date), "minutes");
      handleDurationChange(duration);
    } catch (e) {
      console.log(e);
    }
  };

  const locale = language === "fr" ? fr : enGB;

  return (
    <div className={classes.main}>
      <DatePickerCalendar
        date={new Date(date)}
        locale={locale}
        onDateChange={onDateChange}
      />
      <div className={classes.separatorLine} />
      <div className={classes.times}>
        <Typography className={classes.text}>{i18n.t("Start")}</Typography>
        <TimeSelect value={date} handleHourChange={handleHourChange} />
        <Typography className={classes.separator}>-</Typography>
        <Typography className={classes.text}>{i18n.t("End")}</Typography>
        <TimeSelect value={secondDate} handleHourChange={onDurationChange} />
      </div>
    </div>
  );
};

export default AddAgendaSessionStep;
