import React from "react";
import Lottie from "react-lottie";
// import * as animationData from "../../resources/empty.json";
import Loading from "../resources/loading.json";

class Loader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isStopped: false, isPaused: false };
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: Loading,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
      initialSegment: this.props.backgroundWhite ? [49, 80] : undefined,
    };

    return (
      <div style={this.props.style}>
        <Lottie
          options={defaultOptions}
          height={this.props.size}
          width={this.props.size}
          isStopped={this.state.isStopped}
          isPaused={this.state.isPaused}
        />
      </div>
    );
  }
}

Loader.defaultProps = {
  size: "30%",
  style: {},
  backgroundWhite: false, //if background white, start first frame at 49, if you want to have continuous loader to show, put the last frame to 80
};

export { Loader };
