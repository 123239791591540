import React, { PureComponent } from "react";
import { connect } from "react-redux";

import withStyles from '@mui/styles/withStyles';

import Colors from "../../constants/Colors";

import { modifyUserRequest } from "../../services/user/actions";
import { uploadPictureRequest } from "../../services/upload/actions";
import i18n from "../../i18n";
import { Typography, TextField } from "@mui/material";
import { getAppLanguage } from "../../services/general/selectors";
import RoundButton from "../../components/RoundButton";
import Uploader from "../../components/Uploader";
import { getUserId } from "../../services/user/selectors";
import slugify from "slugify";

const styles = theme => ({
  main: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 100,
  },
  root: {
    width: "90%",
    minHeight: 500,
    paddingTop: 20,
    paddingLeft: 45,
    paddingRight: 45,
    paddingBottom: 25,
    marginTop: theme.spacing.unit * 3,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "column",
    // backgroundColor: "#FAFAFA",
    backgroundColor: "#fff",
    border: "solid",
    borderWidth: 1,
    borderRadius: 8,
    borderColor: Colors.mediumGrey,
    // backgroundColor: "#fff",
    overflowX: "auto",
  },
  button: {
    backgroundColor: Colors.pink,
  },
  languageDiv: {
    marginTop: 10,
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
  },
  title: {
    // color: Colors.black,
    marginBottom: 10,
    fontSize: 28,
    lineHeight: 1.2143,
    fontWeight: 700,
  },
  subTitle: {
    // color: Colors.black,
    marginTop: 20,
    marginBottom: 5,
    fontSize: 22,
    lineHeight: 1.2143,
    fontWeight: 700,
  },
  textField: {
    maxWidth: 300,
    minWidth: "33%",
  },
  uploader: {
    marginTop: 16,
  },
  saveButton: {
    marginTop: 25,
  },
  logoutButton: {
    marginTop: 15,
  },
  explanation: {
    marginTop: 5,
  },
});

class Recap extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
    };
  }

  componentDidMount = () => {};

  componentDidUpdate = prevProps => {
    if (prevProps.uploadedPicture !== this.props.uploadedPicture) {
      this.setState({ picture: this.props.uploadedPicture });
    }
  };

  saveChanges = () => {
    const { userId } = this.props;
    const { firstName, picture, lastName } = this.state;
    this.props.modifyUserRequest({ userId, picture, firstName, lastName });
  };

  onChangeUserParams = param => event => {
    const value = event.target.value;
    this.setState({ [param]: value });
  };

  onFileUploaded = async files => {
    const { clientId } = this.props;

    const date = new Date().getTime();
    await this.props.uploadPictureRequest({
      urlName: slugify(`client-logo/${clientId}/${date}`),
      file: files[0],
    });
  };

  render() {
    const { firstName, lastName, picture } = this.state;
    const { classes, uploading, email } = this.props;
    return (
      <div className={classes.main}>
        <div className={classes.root}>
          <Typography className={classes.subTitle}>
            {i18n.t("profile-picture")}
          </Typography>
          <Typography className={classes.explanation}>
            {i18n.t("profile-picture-explanation")}
          </Typography>
          <Uploader
            isSmall
            placeholder={i18n.t("upload-picture")}
            width={100}
            height={100}
            picture={picture}
            className={classes.uploader}
            onFileUploaded={this.onFileUploaded}
            uploading={uploading}
          />
          <Typography className={classes.subTitle}>
            {i18n.t("Email")}
          </Typography>
          <TextField
            fullWidth
            className={classes.textField}
            label={i18n.t("Email")}
            value={email}
            disabled={true}
            margin="normal"
            variant="outlined"
          />
          <Typography className={classes.subTitle}>
            {i18n.t("firstName")}
          </Typography>
          <TextField
            fullWidth
            className={classes.textField}
            label={i18n.t("firstName")}
            value={firstName}
            onChange={this.onChangeUserParams("firstName")}
            margin="normal"
            variant="outlined"
          />
          <Typography className={classes.subTitle}>
            {i18n.t("lastName")}
          </Typography>
          <TextField
            fullWidth
            className={classes.textField}
            label={i18n.t("lastName")}
            value={lastName}
            onChange={this.onChangeUserParams("lastName")}
            margin="normal"
            variant="outlined"
          />

          <RoundButton
            onClick={this.saveChanges}
            className={classes.saveButton}
            title={i18n.t("save-changes")}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const language = getAppLanguage(state);
  const { uploading } = state.upload;
  const { firstName, lastName, picture, email } = state.user;
  const userId = getUserId(state);
  const uploadedPicture = state.upload.picture;
  return {
    userId,
    language,
    email,
    firstName,
    lastName,
    picture,
    uploadedPicture,
    uploading,
  };
};

export default connect(mapStateToProps, {
  uploadPictureRequest,
  modifyUserRequest,
})(withStyles(styles)(Recap));
