import React, { Component } from "react";
import TextMessage from "./TextMessage";

class Message extends Component {
  _renderMessageOfType(type) {
    // switch(type) {
    // case 'text':
    //   return <TextMessage {...this.props.message} />;
    // case 'emoji':
    //   return <EmojiMessage {...this.props.message} />;
    // case 'file':
    //   return <FileMessage {...this.props.message} />;
    // default:
    return <TextMessage {...this.props.message} />;
    // console.error(`Attempting to load message with unsupported file type '${type}'`);
    // }
  }

  render() {
    let contentClassList = [
      "sc-message--content",
      this.props.message.user._id === this.props.authorId ? "sent" : "received",
    ];
    return (
      <div className="sc-message">
        <div className={contentClassList.join(" ")}>
          <div
            className="sc-message--avatar"
            style={{
              backgroundImage: `url(${this.props.message.user.avatar})`,
            }}
          ></div>
          <TextMessage {...this.props.message} />
          {/* {this._renderMessageOfType(this.props.message.type)} */}
        </div>
      </div>
    );
  }
}

export default Message;
