import React from "react";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import i18n from "i18n";
import Colors from "constants/Colors";

const LeftArrowIcon = () => (
  <svg
    width="15"
    height="12"
    viewBox="0 0 15 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.243 5.33733H3.50322L6.48427 1.75678C6.62367 1.58907 6.69073 1.37286 6.67071 1.1557C6.65069 0.938547 6.54522 0.73824 6.37751 0.598846C6.20981 0.459452 5.99359 0.392389 5.77644 0.412411C5.55928 0.432432 5.35898 0.537898 5.21958 0.705606L1.11344 5.63297C1.08582 5.67217 1.06111 5.71334 1.03953 5.75616C1.03953 5.79722 1.03953 5.82186 0.982047 5.86292C0.944823 5.95708 0.925333 6.05731 0.924561 6.15856C0.925333 6.25981 0.944823 6.36004 0.982047 6.4542C0.982047 6.49526 0.982046 6.5199 1.03953 6.56096C1.06111 6.60378 1.08582 6.64495 1.11344 6.68415L5.21958 11.6115C5.2968 11.7042 5.39349 11.7788 5.50278 11.8299C5.61207 11.881 5.73128 11.9074 5.85193 11.9072C6.04381 11.9075 6.22977 11.8407 6.37751 11.7183C6.46067 11.6493 6.52941 11.5647 6.57979 11.4691C6.63018 11.3736 6.66122 11.269 6.67113 11.1615C6.68105 11.0539 6.66965 10.9454 6.63759 10.8423C6.60552 10.7391 6.55342 10.6433 6.48427 10.5603L3.50322 6.97979H13.243C13.4608 6.97979 13.6697 6.89327 13.8237 6.73926C13.9777 6.58525 14.0642 6.37636 14.0642 6.15856C14.0642 5.94076 13.9777 5.73187 13.8237 5.57786C13.6697 5.42385 13.4608 5.33733 13.243 5.33733Z"
      fill="black"
    />
  </svg>
);

const useStyles = makeStyles(theme => ({
  main: {
    height: "fit-content",
    padding: 5,
    borderRadius: 8,
    marginRight: 35,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": { backgroundColor: Colors.hoverBlue },
  },
  text: {
    fontSize: 16,
    marginLeft: 12,
  },
}));

interface Props {
  onClick: () => void;
}

const PreviousButton = ({ onClick }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.main} onClick={onClick}>
      <LeftArrowIcon />
      <Typography className={classes.text}>{i18n.t("previous")}</Typography>
    </div>
  );
};

export default PreviousButton;
