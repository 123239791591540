import React from "react";

const CheckIcon = ({ sizePercentage, color, className }) => (
  <svg
    // width={sizePercentage * 24}
    className={className}
    width={sizePercentage * 12}
    height={sizePercentage * 14}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 12 14"
    version="1.1"
  >
    <path
      d="M11.1276 7.62636C11.0566 7.40788 10.8447 7.25777 10.5465 7.2145L8.1162 6.86193L7.02931 4.6637C6.89598 4.39395 6.6875 4.23926 6.45738 4.23926C6.22727 4.23926 6.01879 4.39395 5.88539 4.6637L4.79864 6.86193L2.36816 7.2145C2.06998 7.25777 1.85814 7.40788 1.78707 7.62636C1.716 7.84484 1.79896 8.09056 2.01475 8.30046L3.77343 10.0116L3.35819 12.4279C3.3051 12.7369 3.39559 12.9221 3.48084 13.023C3.58093 13.1415 3.72672 13.2067 3.8915 13.2067C4.01559 13.2067 4.14748 13.1705 4.2836 13.0991L6.45738 11.9582L8.63124 13.0991C8.76736 13.1705 8.89925 13.2067 9.02327 13.2068C9.18805 13.2068 9.33398 13.1415 9.43399 13.023C9.51925 12.9221 9.60973 12.7369 9.55658 12.4279L9.14148 10.0116L10.9 8.30053C11.1158 8.09056 11.1988 7.84484 11.1276 7.62636Z"
      fill={color}
    />
    <path
      d="M4.30016 3.83944C4.27426 3.75985 4.19708 3.70516 4.08845 3.6894L3.20304 3.56095L2.80707 2.7601C2.7585 2.66182 2.68255 2.60547 2.59871 2.60547C2.51488 2.60547 2.43892 2.66182 2.39032 2.7601L1.9944 3.56095L1.10894 3.6894C1.00031 3.70516 0.923132 3.75985 0.897241 3.83944C0.871349 3.91904 0.901573 4.00856 0.980188 4.08503L1.62091 4.70842L1.46963 5.58871C1.45029 5.70129 1.48325 5.76877 1.51431 5.80554C1.55077 5.84868 1.60389 5.87244 1.66392 5.87244C1.70913 5.87244 1.75718 5.85926 1.80677 5.83323L2.59871 5.41762L3.39068 5.83323C3.44027 5.85926 3.48832 5.87244 3.5335 5.87247C3.59353 5.87247 3.6467 5.84868 3.68314 5.80554C3.7142 5.76877 3.74716 5.70129 3.7278 5.58871L3.57657 4.70842L4.21723 4.08505C4.29585 4.00856 4.32607 3.91904 4.30016 3.83944Z"
      fill={color}
    />
    <path
      d="M6.18164 1.19614C6.16369 1.14097 6.1102 1.10307 6.0349 1.09214L5.42118 1.00311L5.14672 0.448002C5.11305 0.379882 5.0604 0.34082 5.00229 0.34082C4.94418 0.34082 4.89154 0.379882 4.85785 0.448002L4.58342 1.00311L3.96967 1.09214C3.89437 1.10307 3.84087 1.14097 3.82293 1.19614C3.80498 1.25131 3.82593 1.31337 3.88042 1.36637L4.32453 1.79847L4.21967 2.40864C4.20627 2.48668 4.22912 2.53345 4.25065 2.55893C4.27592 2.58884 4.31274 2.60531 4.35435 2.60531C4.38568 2.60531 4.41899 2.59617 4.45336 2.57813L5.00229 2.29005L5.55124 2.57813C5.58562 2.59617 5.61892 2.60531 5.65024 2.60533C5.69185 2.60533 5.7287 2.58884 5.75396 2.55893C5.77549 2.53345 5.79834 2.48668 5.78491 2.40864L5.68009 1.79847L6.12416 1.36639C6.17866 1.31337 6.19961 1.25131 6.18164 1.19614Z"
      fill={color}
    />
  </svg>
);

CheckIcon.defaultProps = {
  sizePercentage: 1,
  color: "#FFD059",
};

export default CheckIcon;
