export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILURE = "AUTH_FAILURE";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_LOGOUT_SUCCESS = "AUTH_LOGOUT_SUCCESS";
export const LOGOUT = "LOGOUT";

export const RESET_PASSWORD_REQUESTING = "RESET_PASSWORD_REQUESTING";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const SEND_EMAIL_INVITATIONS_REQUESTING =
  "SEND_EMAIL_INVITATIONS_REQUESTING";
export const SEND_EMAIL_INVITATIONS_SUCCESS = "SEND_EMAIL_INVITATIONS_SUCCESS";
export const SEND_EMAIL_INVITATIONS_ERROR = "SEND_EMAIL_INVITATIONS_ERROR";
