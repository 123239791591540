import {
  CLIENT_CHANGE_FIELD,
  CLIENT_GET_REQUESTING,
  CLIENT_GET_SUCCESS,
  CLIENT_GET_ERROR,
  CLIENT_GET_RANKING_SUCCESS,
  CLIENT_MODIFY_REQUESTING,
  CLIENT_MODIFY_SUCCESS,
  CLIENT_MODIFY_ERROR,
  CLIENT_GET_PENDING_USERS,
} from "./constants";

import { AUTH_LOGOUT_SUCCESS, AUTH_SUCCESS } from "../auth/reducer";
import { SIGNUP_CREATE_CLIENT_SUCCESS } from "../signup/constants";
import getNavigatorLanguage from "../../utils/getNavigatorLanguage";

const initialState = {
  role: "user",
  owner: "",
  logo: "",
  domain: "",
  name: "",
  isEnterpriseAccount: false,
  defaultUserSkills: [],
  hasCustomOnboarding: false,
  error: null,
  requesting: false,
  language: getNavigatorLanguage(),
  pendingInvitations: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CLIENT_MODIFY_REQUESTING:
    case CLIENT_GET_REQUESTING: {
      return {
        ...state,
        requesting: true,
      };
    }

    case CLIENT_MODIFY_SUCCESS:
    case CLIENT_GET_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        requesting: false,
      };
    }

    case CLIENT_GET_PENDING_USERS: {
      return {
        ...state,
        pendingInvitations: [...state.pendingInvitations, ...action.payload],
      };
    }

    case CLIENT_GET_RANKING_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case CLIENT_MODIFY_ERROR:
    case CLIENT_GET_ERROR: {
      return { ...state, error: action.payload, requesting: false };
    }

    case SIGNUP_CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    case CLIENT_CHANGE_FIELD:
      return {
        ...state,
        ...action.payload,
      };

    case AUTH_SUCCESS: {
      const { client } = action;
      return { ...state, ...client };
    }

    case AUTH_LOGOUT_SUCCESS: {
      return { ...initialState, clientId: "", userId: "", role: "" };
    }

    default:
      return state;
  }
};

export default reducer;
