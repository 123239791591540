import React, { useState } from "react";

import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";

import Colors from "constants/Colors";

import i18n from "i18n";
import AddIcon from "scenes/CreateTrack/AddIcon";
import AddQuestionBox from "./AddQuestionBox";
import LibraryImage from "resources/library.svg";

const styles = theme => ({
  main: {
    width: "100%",
    position: "relative",
    height: 70,
    paddingLeft: 15,
    // position: "static",
    cursor: "pointer",
    // flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    transition: "all 0.1s ease",
    "&:hover": {
      backgroundColor: Colors.hoverBlue,
    },
  },
  title: {
    cursor: "pointer",
    color: Colors.black,
    // fontWeight: "bold",
    fontSize: "1em",
    marginLeft: 15,
  },
  addButton: {
    cursor: "pointer",
    padding: 10,
    backgroundColor: Colors.pureMain,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
});

const AddStepRow = ({
  classes,
  onClick,
  scrollToBottom,
  title = i18n.t("add-new-action"),
  rows = [
    {
      title: i18n.t("from-library"),
      stepType: "library",
      onClick: () => null,
      explanationTitle: i18n.t("favorite-actions"),
      explanationDetails: i18n.t("step-library-details"),
      explanationPicture: LibraryImage,
    },
  ],
}) => {
  const [isVisible, setVisibility] = useState(false);
  const clickOutside = () => setVisibility(false);

  const onClickAddButton = () => {
    if (isVisible) {
      setVisibility(false);
    } else if (!isVisible) {
      setVisibility(true);
      setTimeout(() => {
        scrollToBottom();
      }, 100);
    }
  };
  return (
    <div className={`${classes.main}`} onClick={onClickAddButton}>
      <div className={classes.addButton} data-intercom-target="New Step">
        <AddIcon />
      </div>
      <Typography className={`${classes.title}`}>{title}</Typography>
      <AddQuestionBox
        isVisible={isVisible}
        setVisibility={setVisibility}
        clickOutside={clickOutside}
        rows={rows}
      />
    </div>
  );
};

export default withStyles(styles)(AddStepRow);
