import {
  GET_MY_COACHEES_SUCCESS,
  CREATE_COACHEE_SUCCESS,
  CREATE_COACHEE_ERROR,
  GET_MY_COACHEES_REQUESTING,
  CREATE_COACHEE_REQUESTING,
  GET_MY_COACHEES_ERROR,
  DELETE_COACHEE_REQUESTING,
  UPDATE_COACHEE,
} from "./constants";

import {
  UPDATE_COACHING_SESSION_SUCCESS,
  CREATE_COACHING_SESSION_SUCCESS,
  END_COACHING_SUCCESS,
} from "services/coaching/constants";

import { AUTH_LOGOUT_SUCCESS } from "../auth/reducer";

const initialState = {
  requesting: false,
  error: "",
  myCoachees: [],
};

const reducer = function userReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_COACHEE_REQUESTING:
    case GET_MY_COACHEES_REQUESTING: {
      return {
        ...state,
        requesting: true,
        error: "",
      };
    }

    case UPDATE_COACHEE: {
      const myCoachees = state.myCoachees.map(el => {
        if (el.userId._id === action.payload._id) {
          return {
            ...el,
            userId: { ...el.userId, ...action.payload },
          };
        }
        return el;
      });
      return {
        ...state,
        myCoachees,
      };
    }

    case END_COACHING_SUCCESS:
    case UPDATE_COACHING_SESSION_SUCCESS: {
      const currentMyCoachees = state.myCoachees || [];
      const myCoachees = currentMyCoachees.map(el => {
        if (el._id === action.payload._id) {
          return {
            ...el,
            ...action.payload,
            userId: el.userId,
          };
        }
        return el;
      });
      return {
        ...state,
        myCoachees,
      };
    }

    case GET_MY_COACHEES_SUCCESS: {
      return {
        ...state,
        myCoachees: action.payload,
        requesting: false,
      };
    }

    case CREATE_COACHEE_SUCCESS: {
      return {
        ...state,
        myCoachees: [action.payload, ...state.myCoachees],
        requesting: false,
      };
    }

    case CREATE_COACHING_SESSION_SUCCESS: {
      const currentMyCoachees = state.myCoachees || [];
      const myCoachees = [
        { ...action.payload, userId: action.payload },
        ...currentMyCoachees,
      ];
      return {
        ...state,
        myCoachees,
      };
    }

    case DELETE_COACHEE_REQUESTING: {
      const myCoachees = state.myCoachees.filter(
        el => el._id !== action.payload.coachingSessionId,
      );
      return {
        ...state,
        myCoachees,
      };
    }

    case CREATE_COACHEE_ERROR:
    case GET_MY_COACHEES_ERROR: {
      return {
        ...state,
        requesting: false,
        error: action.payload,
      };
    }

    case AUTH_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
