import React from "react";

const MobileIcon = ({ sizePercentage, color, className }) => (
  <svg
    className={className}
    width={sizePercentage * 19}
    height={sizePercentage * 27}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 19 27"
    version="1.1"
  >
    <path
      d="M16.4851 0.149902H2.58778C1.5305 0.149902 0.670898 0.987221 0.670898 2.01709V24.4234C0.670898 25.4533 1.5305 26.2906 2.58778 26.2906H16.4851C17.5424 26.2906 18.402 25.4533 18.402 24.4234V2.01709C18.402 0.987221 17.5424 0.149902 16.4851 0.149902ZM17.2455 24.19H1.82739V2.25049H17.2455V24.19ZM8.33841 21.2142C8.33841 21.5237 8.46463 21.8205 8.68931 22.0393C8.91399 22.2582 9.21871 22.3811 9.53646 22.3811C9.8542 22.3811 10.1589 22.2582 10.3836 22.0393C10.6083 21.8205 10.7345 21.5237 10.7345 21.2142C10.7345 20.9046 10.6083 20.6078 10.3836 20.389C10.1589 20.1701 9.8542 20.0472 9.53646 20.0472C9.21871 20.0472 8.91399 20.1701 8.68931 20.389C8.46463 20.6078 8.33841 20.9046 8.33841 21.2142Z"
      fill={color}
    />
  </svg>
);

MobileIcon.defaultProps = {
  sizePercentage: 1,
  color: "#00A7FC",
  className: {},
};

export default MobileIcon;
