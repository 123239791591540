import React from "react";

const AddParticipantIcon = ({ sizePercentage, color, className }) => (
  <svg
    className={className}
    width={sizePercentage * 19}
    height={sizePercentage * 14}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 19 14"
    version="1.1"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.32434 13.7954C8.32434 13.7954 7.24609 13.7954 7.24609 12.7172C7.24609 11.639 8.32434 8.40422 12.6373 8.40422C16.9503 8.40422 18.0285 11.639 18.0285 12.7172C18.0285 13.7954 16.9503 13.7954 16.9503 13.7954H8.32434ZM12.6373 7.32597C13.4952 7.32597 14.318 6.98517 14.9246 6.37854C15.5312 5.77191 15.872 4.94914 15.872 4.09124C15.872 3.23334 15.5312 2.41057 14.9246 1.80394C14.318 1.19731 13.4952 0.856506 12.6373 0.856506C11.7794 0.856506 10.9566 1.19731 10.35 1.80394C9.74338 2.41057 9.40258 3.23334 9.40258 4.09124C9.40258 4.94914 9.74338 5.77191 10.35 6.37854C10.9566 6.98517 11.7794 7.32597 12.6373 7.32597Z"
      fill={color}
    />
    <path
      d="M5.04814 4.57801H7.738V6.52276H5.04814V9.56283H2.99907V6.52276H0.301758V4.57801H2.99907V1.66461H5.04814V4.57801Z"
      fill={color}
    />
  </svg>
);

AddParticipantIcon.defaultProps = {
  sizePercentage: 1,
  color: "#fff",
};

export default AddParticipantIcon;
