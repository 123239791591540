import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@mui/styles/withStyles";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Colors from "../../constants/Colors";

import menu from "./menu";
import MenuTab from "./MenuTab";
import { Route, Redirect, Switch } from "react-router-dom";
import { logout } from "../../services/auth/reducer";

import MyClients from "../MyClients";
import Recap from "../Recap";
import Settings from "../Settings";
import Polls from "../Polls";
import Tracks from "../Campaigns";
import MyUsers from "../MyUsers";

import Logo from "../../resources/MicrodoingLogo";
import InviteUsersModal from "./InviteUsersModal";

import { connect } from "react-redux";
import NotificationDrawer from "./NotificationDrawer";
import { Avatar } from "@mui/material";
import i18n from "../../i18n";
import {
  getIsAdmin,
  getIsMicrodoingAdmin,
  getIsObserver,
  getIsSuperAdmin,
  getIsTrainer,
  getUserId,
} from "../../services/user/selectors";
import {
  getAdminConnections,
  getAppLanguage,
} from "../../services/general/selectors";
import { changeGeneralSettings } from "../../services/general/actions";
import {
  getIsCoachingAccount,
  getIsEnterpriseAccount,
} from "../../services/client/selectors";
import { getNotificationsRequest } from "services/notification/actions";
import Conversations from "../Conversations";
import AvatarMenu from "./AvatarMenu";
import ProfileSettings from "../ProfileSettings";
import mobileCheck from "../../utils/mobileCheck";
import ConnectOnDesktop from "./ConnectOnDesktop";
import AdminAvatar from "scenes/Dashboard/AdminAvatar";
import MyCoachees from "scenes/MyCoachees";
import InvoiceList from "scenes/Invoice/InvoiceList/InvoiceList";
import InvoiceRoute from "scenes/Invoice/InvoicePage/InvoiceRoute";
import MyClientsMenu from "./MyClientsMenu";

const drawerWidth = window.innerWidth < 1100 ? 200 : 240;

const styles = theme => ({
  root: {
    backgroundColor: "#F4F6F9",
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 8px",
    borderRight: "1px solid rgba(255,255,255, 0.15)",
    ...theme.mixins.toolbar,
  },
  appBar: {
    boxShadow: null,
    backgroundColor: Colors.darkBlue,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    fontWeight: 700,
    fontSize: 18,
    fontFamily: "Montserrat",
    flexGrow: 1,
  },
  drawerPaper: {
    border: "none",
    backgroundColor: Colors.darkBlue,
    borderBottomColor: "#404854",
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 9,
    },
  },
  leftMenu: {
    paddingTop: 0,
    paddingBottom: 0,
    height: "100%",
    backgroundColor: Colors.darkBlue,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: "100vh",
    overflow: "auto",
    padding: 24,
  },
  isConversationRoute: {
    backgroundColor: Colors.blueGrey,
  },
  avatar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderStyle: "solid",
    width: 42,
    height: 42,
    borderColor: Colors.pureMain,
    borderWidth: 2,
  },
  avatarLink: {
    marginLeft: 5,
    cursor: "pointer",
    textDecoration: "none",
    borderRadius: "50%",
    padding: 4,
    "&:hover": {
      backgroundColor: "rgba(255,255,255, 0.2)",
    },
  },
  badge: {
    backgroundColor: Colors.pink,
  },
  inviteUsers: {
    minWidth: 100,
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    borderColor: "#fff",
    color: "#fff",
    background: "transparent",
    borderRadius: 4,
    borderWidth: 1,
    border: "solid",
    cursor: "pointer",
    padding: "7px 15px",
    width: "85%",
    textAlign: "center",
    transitionDuration: ".2s",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#fff",
      borderColor: "#fff",
      borderStyle: "solid",
      color: "rgba(0,0,0,.93)",
      fill: "rgba(0,0,0,.93)",
    },
  },
  inviteUserText: {
    fontWeight: 500,
    color: "inherit",
  },
});

class Dashboard extends React.Component {
  state = {
    open: true,
    isNotificationDrawerOpen: false,
    isInviteUsersModalVisible: false,
    isAvatarMenuOpen: false,
    selectedIndex: 0,
  };

  componentDidMount() {
    this.props.getNotificationsRequest({
      userId: this.props.userId,
      page: 0,
      numberByPage: 30,
    });
  }

  logout = () => {
    this.props.logout();
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  onClickInviteUsers = () => {
    this.props.changeGeneralSettings({
      isInviteUsersModalVisible: true,
      accessCode: null,
    });
  };

  onCloseInviteModal = () => {
    this.props.changeGeneralSettings({
      isInviteUsersModalVisible: false,
      accessCode: null,
    });
  };

  displayRouteName = location => {
    if (location.indexOf("settings") > -1) {
      return i18n.t("menu-settings");
    } else if (location.indexOf("actions") > -1) {
      return i18n.t("menu-actions");
    } else if (location.indexOf("team") > -1) {
      return i18n.t("menu-team");
    } else if (location.indexOf("coffee") > -1) {
      return i18n.t("menu-coffee");
    } else if (location.indexOf("polls") > -1) {
      return i18n.t("menu-polls");
    } else if (location.indexOf("campaigns") > -1) {
      return i18n.t("menu-tracks");
    } else if (location.indexOf("my-clients") > -1) {
      return i18n.t("menu-clients");
    } else if (location.indexOf("my-users") > -1) {
      return i18n.t("my-users");
    } else if (location.indexOf("conversations") > -1) {
      return i18n.t("conversations");
    } else if (location.indexOf("my-coachees") > -1) {
      return i18n.t("my-coachees");
    } else if (location.indexOf("invoices") > -1) {
      return i18n.t("invoices");
    }
    return "Dashboard";
  };

  getRouteName = location => {
    if (location.indexOf("settings") > -1) {
      return "settings";
    } else if (location.indexOf("actions") > -1) {
      return "actions";
    } else if (location.indexOf("team") > -1) {
      return "team";
    } else if (location.indexOf("coffee") > -1) {
      return "coffee";
    } else if (location.indexOf("polls") > -1) {
      return "polls";
    } else if (location.indexOf("campaigns") > -1) {
      return "campaigns";
    } else if (location.indexOf("my-clients") > -1) {
      return "my-clients";
    } else if (location.indexOf("my-users") > -1) {
      return "my-users";
    } else if (location.indexOf("conversations") > -1) {
      return "conversations";
    } else if (location.indexOf("my-coachees") > -1) {
      return "my-coachees";
    } else if (location.indexOf("invoices") > -1) {
      return "invoices";
    }
    return "dashboard";
  };

  toggleDrawer = isNotificationDrawerOpen => () => {
    this.setState({ isNotificationDrawerOpen });
  };

  toggleAvatarMenu = isAvatarMenuOpen => event => {
    const anchorEl = event ? event.currentTarget : null;
    this.setState({ isAvatarMenuOpen, anchorEl });
  };

  hasAccess = ({
    clientAccessRequired,
    accessRequired,
    isEnterpriseAccountForbidden,
    isCoachingMenu,
    isCoachingRequired,
    observerAccess,
    trainerAccess,
  }) => {
    const {
      hasClientAccess,
      hasAccess,
      isEnterpriseAccount,
      isCoachingAccount,
      isObserver,
      isTrainer,
    } = this.props;

    if (isTrainer) {
      return trainerAccess;
    }

    if (isObserver) {
      return observerAccess;
    }

    if (isCoachingRequired && !isCoachingAccount) {
      return false;
    }

    if (clientAccessRequired) {
      return hasClientAccess;
    }

    if (isCoachingAccount) {
      if (isCoachingMenu) {
        return true;
      } else {
        return false;
      }
    }

    if (isEnterpriseAccount) {
      if (isEnterpriseAccountForbidden) return false;
    }

    if (accessRequired) {
      return hasAccess;
    }

    return true;
  };

  render() {
    const {
      classes,
      adminConnections = [],
      isCoachingAccount,
      isSuperAdmin,
      isMicrodoingAdmin,
      isTrainer = false,
      isObserver = false,
    } = this.props;

    if (mobileCheck()) {
      return <ConnectOnDesktop />;
    }

    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.root}>
          {/* <Snackbar /> */}
          <AvatarMenu
            onClickLogout={this.logout}
            anchorEl={this.state.anchorEl}
            isOpen={this.state.isAvatarMenuOpen}
            onClose={this.toggleAvatarMenu(false)}
          />
          <NotificationDrawer
            userId={this.props.userId}
            timezone={this.props.timezone}
            isOpen={this.state.isNotificationDrawerOpen}
            toggleDrawer={this.toggleDrawer(false)}
          />
          <InviteUsersModal
            isVisible={this.props.isInviteUsersModalVisible}
            onClose={this.onCloseInviteModal}
          />
          <AppBar
            elevation={0}
            position="absolute"
            className={classNames(
              classes.appBar,
              this.state.open && classes.appBarShift,
            )}
          >
            <Toolbar
              disableGutters={!this.state.open}
              className={classes.toolbar}
            >
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(
                  classes.menuButton,
                  this.state.open && classes.menuButtonHidden,
                )}
                size="large"
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                {this.displayRouteName(this.props.location.pathname)}
              </Typography>
              {adminConnections
                .filter(el => el.userId !== this.props.userId)
                .map((admin, index) => (
                  <AdminAvatar {...admin} key={index} />
                ))}
              <IconButton
                color="inherit"
                onClick={this.toggleDrawer(true)}
                size="large"
              >
                <Badge
                  badgeContent={this.props.newGeneralNotifications}
                  classes={{ badge: classes.badge }}
                >
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <div
                to="/settings"
                className={classes.avatarLink}
                onClick={this.toggleAvatarMenu(true)}
              >
                <Avatar
                  src={this.props.userPicture}
                  alt="user picture"
                  className={classes.avatar}
                />
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            classes={{
              paper: classNames(
                classes.drawerPaper,
                !this.state.open && classes.drawerPaperClose,
              ),
            }}
            open={this.state.open}
          >
            <div className={classes.toolbarIcon}>
              <Logo sizePercentage={1.4} />
            </div>
            <List className={classes.leftMenu}>
              <MyClientsMenu />
              {menu({
                messageNotifications: this.props.newMessageNotifications,
              }).map((el, i) => {
                return (
                  <MenuTab
                    currentRoute={this.getRouteName(
                      this.props.location.pathname,
                    )}
                    {...el}
                    icon={el.icon}
                    route={el.route}
                    path={el.path}
                    hasAccess={this.hasAccess(el)}
                    text={el.text}
                    key={i}
                  />
                );
              })}
              {!isTrainer && !isObserver && (
                <div
                  data-intercom-target="Invite"
                  className={classes.inviteUsers}
                  onClick={this.onClickInviteUsers}
                >
                  <Typography className={classes.inviteUserText}>
                    {i18n.t("invite-users")}
                  </Typography>
                </div>
              )}
            </List>
          </Drawer>
          <main
            className={`${classes.content} ${
              this.getRouteName(this.props.location.pathname) ===
                "conversations" && classes.isConversationRoute
            }`}
          >
            <div className={classes.appBarSpacer} />
            <div>
              <Switch>
                <Route exact path="/polls" component={Polls} />
                <Route exact path="/campaigns" component={Tracks} />
                <Route exact path="/settings" component={Settings} />
                <Route exact path="/dashboard" component={Recap} />
                <Route exact path="/my-users" component={MyUsers} />
                <Route exact path="/my-coachees" component={MyCoachees} />
                <Route exact path="/me" component={ProfileSettings} />
                <Route exact path="/conversations" component={Conversations} />
                <Route path={`/invoices-create`} component={InvoiceRoute} />
                <Route exact path="/invoices" component={InvoiceList} />
                {/* <Route exact path={`/invoices/create`} component={InvoiceRoute} /> */}
                <Route
                  exact
                  path="/"
                  render={() => (
                    <Redirect
                      to={isCoachingAccount ? "/my-coachees" : "/campaigns"}
                    />
                  )}
                />
                {this.props.hasClientAccess && (
                  <Route exact path="/my-clients" component={MyClients} />
                )}
              </Switch>
            </div>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const language = getAppLanguage(state);
  const isEnterpriseAccount = getIsEnterpriseAccount(state);
  const { role } = state.user;
  const { isInviteUsersModalVisible } = state.general;
  const { picture, notifications, timezone } = state.user; // notifications du user n'est jamais modifié ? tout se fait dans notification
  // il faut peut être virer le notification du reducer user
  const userId = getUserId(state);
  const { plan } = state.client;
  const {
    newMessageNotifications,
    newGeneralNotifications,
  } = state.notification;
  const isSuperAdmin = getIsSuperAdmin(state);
  const isObserver = getIsObserver(state);
  const isTrainer = getIsTrainer(state);
  const isMicrodoingAdmin = getIsMicrodoingAdmin(state);
  const isAdmin = getIsAdmin(state);
  const hasClientAccess =
    plan !== "free" || isSuperAdmin || isMicrodoingAdmin || isAdmin;

  return {
    token: state.auth.accessToken,
    isInviteUsersModalVisible,
    isEnterpriseAccount,
    isCoachingAccount: getIsCoachingAccount(state),
    isMicrodoingAdmin,
    isSuperAdmin,
    userPicture: picture,
    isTrainer,
    hasAccess: role !== "user",
    isObserver,
    notifications,
    firstName: state.user.firstName,
    language,
    timezone,
    userId,
    hasClientAccess,
    newMessageNotifications,
    newGeneralNotifications,
    adminConnections: getAdminConnections(state),
  };
};

export default connect(mapStateToProps, {
  logout,
  changeGeneralSettings,
  getNotificationsRequest,
})(withStyles(styles)(Dashboard));
