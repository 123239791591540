import React, { MouseEvent, useMemo } from "react";
import { Order } from "./type";
import { Checkbox, TableBody, TableCell, TableRow } from "@mui/material";
import DeleteIcon from "scenes/Campaigns/DeleteIcon";
import ModifyPencil from "scenes/Campaigns/CampaignScreen/ReviewsSide/icons/ModifyPencil";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  cursor: {
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

interface Props {
  bodyCells: any;
  order: Order;
  orderBy: string;
  selectedItems: readonly string[];
  hasCheckbox?: boolean;
  hasModification?: boolean;
  hasDeletion?: boolean;
  handleSelect: (event: MouseEvent<unknown>, id: string) => void;
  handleClickEvent?: (
    event: MouseEvent<unknown>,
    id: string,
    functionName: string,
  ) => void;
}

// TODO when we will want to have pages into table add
// TODO .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
// TODO after the sort of the table

const BodyTable = ({
  bodyCells,
  order,
  orderBy,
  selectedItems,
  hasCheckbox = false,
  hasModification = false,
  hasDeletion = false,
  handleSelect,
  handleClickEvent,
}: Props) => {
  const descendingComparator = (a: any, b: any, orderBy: string) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order: Order, orderBy: string) => (
    a: { [key in any]: number | string },
    b: { [key in any]: number | string },
  ) => {
    return order === "desc"
      ? descendingComparator(a, b, orderBy)
      : -descendingComparator(a, b, orderBy);
  };

  const isSelected = (id: string) => selectedItems.indexOf(id) !== -1;

  const bodyArray = useMemo(() => {
    return bodyCells.sort(getComparator(order, orderBy));
  }, [order, orderBy, getComparator]);

  // TODO when we will want to have pages on tables
  // Avoid a layout jump when reaching the last page with empty rows.
  //   const emptyRows =
  //     page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // TODO add this after map
  // {emptyRows > 0 && (
  //   <TableRow
  //     style={{
  //       height: (dense ? 33 : 53) * emptyRows,
  //     }}
  //   >
  //     <TableCell colSpan={6} />
  //   </TableRow>
  // )};

  const classes = useStyles();

  return (
    <TableBody>
      {bodyArray.map((row, index) => {
        const isItemSelected = isSelected(row.id);

        return (
          <TableRow
            hover
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
          >
            {hasCheckbox && (
              <TableCell
                padding="checkbox"
                onClick={event => handleSelect(event, row.id)}
              >
                <Checkbox color="primary" checked={isItemSelected} />
              </TableCell>
            )}
            {Object.values(row).map((item, index) => {
              if (index === 0) return;

              return (
                <TableCell
                  align="left"
                  padding="normal"
                  sx={{ paddingLeft: 0 }}
                >
                  {item}
                </TableCell>
              );
            })}
            {hasDeletion && (
              <TableCell
                padding="none"
                className={classes.cursor}
                onClick={event => handleClickEvent(event, row.id, "delete")}
              >
                <DeleteIcon color="red" />
              </TableCell>
            )}
            {hasModification && (
              <TableCell
                padding="none"
                className={classes.cursor}
                onClick={event => handleClickEvent(event, row.id, "modify")}
              >
                <ModifyPencil sizePercentage={0.7} />
              </TableCell>
            )}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default BodyTable;
