import JSZip from "jszip";
import { saveAs } from "file-saver";

const useZipeDownload = () => {
  const downloadSignedFiles = (url, requestOptions) => {
    return new Promise(resolve => {
      fetch(url, requestOptions)
        .then(response => response.blob())
        .then(blob => {
          resolve(blob);
        });
    });
  };

  const getAllBlobs = (
    downloadArray = [{ url: "", name: "" }],
    requestOptions = {},
  ) => {
    const downloadRequests = [];

    downloadArray.forEach(file => {
      const fileUrl = file.url;
      let name = file.name;
      downloadRequests.push({
        name: name,
        blob: downloadSignedFiles(fileUrl, requestOptions),
      });
    });

    return downloadRequests;
  };

  const getMegatronZip = async (
    zipFileName = "download",
    downloadArray = [],
    requestOptions = {},
  ) => {
    const zip = new JSZip();
    const folder = zip.folder(zipFileName);
    const downloadRequests = getAllBlobs(downloadArray, requestOptions);

    await Promise.all(downloadRequests).then(response => {
      response.forEach(item => {
        folder.file(item.name, item.blob);
      });
    });

    return zip;
  };

  const generateZipFile = async (
    zipFileName = "download",
    downloadArray = [],
    requestOptions = {},
  ) => {
    let megatronBlob = await getMegatronZip(
      zipFileName,
      downloadArray,
      requestOptions,
    );

    megatronBlob.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, `${zipFileName}.zip`);
    });
  };

  return [generateZipFile] as const;
};

export default useZipeDownload;
