import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import DeleteConfirmModal from "components/DeleteConfirmModal";
import Modal from "components/Modal";
import i18n from "i18n";
import Fuse from "fuse.js";
import { useDispatch, useSelector } from "react-redux";
import {
  addNotificationToFavoriteRequest,
  getFavoriteNotificationsRequest,
} from "services/notification/actions";
import { getUserClientId } from "services/user/selectors";
import {
  getFavoriteNotifications,
  getNotificationsRequesting,
} from "services/notification/selectors";
import SearchBar from "components/SearchBar";
import { Loader } from "components";
import FavoriteNotificationBox from "./FavoriteNotificationBox";
import { Typography } from "@mui/material";

const fuseOptions = {
  shouldSort: true,
  threshold: 0.6,
  location: 0,
  ignoreLocation: true,
  distance: 1000,
  maxPatternLength: 20,
  minMatchCharLength: 1,
  keys: ["text"],
};

const useStyles = makeStyles(theme => ({
  main: {},
  emptyText: {
    height: "100%",
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    alignSelf: "center",
    textAlign: "center",
    color: "#A8A8A8",
    fontSize: 18,
    transition: "all 0.2s ease",
  },
  modal: {
    minWidth: 750,
    width: 900,
  },
  searchBar: {
    marginBottom: 15,
  },
  resourcesList: {
    boxSizing: "border-box",
    height: 600,
    overflowX: "scroll",
  },
  newResourceButton: {},
  buttonIcon: {
    marginRight: 10,
  },
}));

interface Props {
  onClose: () => void;
  onImportNotification: (notification) => () => void;
  isVisible: boolean;
}

const NotificationLibraryModal = ({
  onClose,
  isVisible,
  onImportNotification = () => () => null,
}: Props) => {
  const classes = useStyles();
  const clientId = useSelector(getUserClientId);

  const [search, setSearch] = useState("");
  const [isDeleteModalVisible, openDeleteModal] = useState(false);
  const [notificationSelected, setNotificationSelected] = useState({ _id: "" });
  const dispatch = useDispatch();

  const favoriteNotifications = useSelector(getFavoriteNotifications);
  const notificationRequesting = useSelector(getNotificationsRequesting);

  useEffect(() => {
    if (isVisible) {
      dispatch(getFavoriteNotificationsRequest({ clientId }));
    }
  }, [isVisible]);

  const onChangeSearch = value => {
    setSearch(value);
  };

  const onClickDelete = resource => e => {
    e.stopPropagation();
    setNotificationSelected(resource);
    openDeleteModal(true);
  };

  const onConfirmDelete = () => {
    dispatch(
      addNotificationToFavoriteRequest({
        notificationId: notificationSelected._id,
        isFavorite: false,
        clientId,
      }),
    );
    openDeleteModal(false);
  };

  const onCloseHandle = () => {
    setSearch("");
    onClose();
  };

  const fuse = new Fuse(favoriteNotifications, fuseOptions);
  const searchResults =
    search.length > 0 ? fuse.search(search) : favoriteNotifications;

  return (
    <>
      <DeleteConfirmModal
        onClose={() => openDeleteModal(false)}
        isVisible={isDeleteModalVisible}
        title={i18n.t("remove-notification-modal-text")}
        dialogTitle={i18n.t("remove-notification-modal-title")}
        onClickConfirm={onConfirmDelete}
      />
      <Modal
        className={classes.modal}
        title={i18n.t("notification-library")}
        isVisible={isVisible}
        onClose={onCloseHandle}
        topComponent={null}
      >
        <div className={classes.main}>
          <SearchBar
            className={classes.searchBar}
            autoFocus
            value={search}
            onChangeSearch={onChangeSearch}
            placeholder={i18n.t("search")}
          />
          <div className={classes.resourcesList}>
            {notificationRequesting && <Loader size={35} />}
            {!favoriteNotifications.length && (
              <Typography className={classes.emptyText}>
                {i18n.t("notification-library-empty-text")}
              </Typography>
            )}
            {searchResults.map((el, index) => (
              <FavoriteNotificationBox
                {...el}
                key={el?._id}
                resource={el}
                index={index}
                onClickDelete={onClickDelete(el)}
                onClick={onImportNotification(el)}
              />
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NotificationLibraryModal;
