import React from "react";
import { useSelector } from "react-redux";

import Feedback from "./Feedback";
import { getAllActionsClient } from "services/client/selectors";
import { makeStyles } from "@mui/styles";

const getBestComments = allActions => {
  const result = [];
  const cleanActions = allActions.filter(el => {
    return el.donePercentage > 0;
  });
  const ranked = cleanActions.sort((a, b) => b.rating - a.rating);
  let i = 0;
  while (result?.length < 4 && i < ranked?.length) {
    if (ranked[i].comment) {
      result.push(ranked[i].comment);
    }
    i++;
  }

  return result;
};

const useStyles = makeStyles(theme => ({
  main: {
    overflow: "hidden",
    backgroundColor: "#FAFAFA",
    flex: 1,
    flexDirection: "row",
    minHeight: 300,
    paddingBottom: 35,
    paddingRight: "2%",
    paddingLeft: "2%",
    paddingTop: 35,
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
}));

const FeedbackSection = ({ className = {} }) => {
  const { allActions } = useSelector(getAllActionsClient);
  const bestComments = getBestComments(allActions);

  const classes = useStyles();

  return (
    <div className={`${classes.main} ${className}`}>
      <Feedback title={bestComments[0]} />
      <Feedback title={bestComments[1]} />
      <Feedback title={bestComments[2]} />
      <Feedback title={bestComments[3]} />
    </div>
  );
};

export default FeedbackSection;
