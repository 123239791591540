import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import EmailRecapIcon from "./EmailRecapIcon";
import i18n from "i18n";
import { MainText } from "components/Texts/MainText";
import Colors from "constants/Colors";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "components";
import { sendEmailRecapRequest } from "services/campaign/actions";
import { getIsEmailRecapRequesting } from "services/campaign/selectors";
import { getUserEmail } from "services/user/selectors";

const useStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    borderRadius: 8,
    padding: "5px 10px",
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": { backgroundColor: Colors.hoverBlue, borderWidth: 1 },
  },
  text: {
    color: "#686868",
    marginLeft: 15,
  },
}));

const RecapEmailButton = () => {
  const classes = useStyles();
  const requesting = useSelector(getIsEmailRecapRequesting);
  const email = useSelector(getUserEmail);
  const dispatch = useDispatch();

  const handleClick = async () => {
    dispatch(sendEmailRecapRequest({ email }));
  };

  return (
    <div className={classes.main} onClick={handleClick}>
      {requesting ? <Loader size={20} /> : <EmailRecapIcon />}
      <MainText
        className={classes.text}
        text={i18n.t("generate-email-recap")}
      />
    </div>
  );
};

export default RecapEmailButton;
