//@flow
import {
  CREATE_COACHING_SESSION_REQUESTING,
  GET_COACHING_SESSION_REQUESTING,
  UPDATE_COACHING_SESSION_REQUESTING,
  CLEAR_COACHING_SESSION,
  UPDATE_COACHING_SESSION,
  UPDATE_COACHING_NOTE_REQUESTING,
  DELETE_COACHING_NOTE_REQUESTING,
  GET_COACHING_NOTES_REQUESTING,
  CREATE_COACHING_NOTE_REQUESTING,
  SELECT_COACHING_NOTE,
  END_COACHING_REQUESTING,
  CREATE_COACHING_FILE_REQUESTING,
  GET_COACHING_FILES_REQUESTING,
  DELETE_COACHING_FILE_REQUESTING,
} from "./constants";

export const getCoachingSessionRequest = (payload: {
  coachId: string;
  userId: string;
}) => {
  return {
    type: GET_COACHING_SESSION_REQUESTING,
    payload,
  };
};

export const updateCoachingSessionRequest = (payload: {}) => {
  return {
    type: UPDATE_COACHING_SESSION_REQUESTING,
    payload,
  };
};

export const createCoachingSessionRequest = (payload: {}) => {
  return {
    type: CREATE_COACHING_SESSION_REQUESTING,
    payload,
  };
};

export const getCoachingNotesRequest = (payload: {
  coachId: string;
  userId: string;
}) => {
  return {
    type: GET_COACHING_NOTES_REQUESTING,
    payload,
  };
};

export const updateCoachingNoteRequest = (payload: {
  coachingNoteId: string;
}) => {
  return {
    type: UPDATE_COACHING_NOTE_REQUESTING,
    payload,
  };
};

export const createCoachingNoteRequest = (payload: {
  coachId: string;
  userId: string;
}) => {
  return {
    type: CREATE_COACHING_NOTE_REQUESTING,
    payload,
  };
};

export const deleteCoachingNoteRequest = (payload: {
  coachingNoteId: string;
}) => {
  return {
    type: DELETE_COACHING_NOTE_REQUESTING,
    payload,
  };
};

export const clearCoachingSession = () => {
  return {
    type: CLEAR_COACHING_SESSION,
  };
};

export const updateCoachingSession = payload => {
  return {
    type: UPDATE_COACHING_SESSION,
    payload,
  };
};

export const selectCoachingNote = payload => {
  return {
    type: SELECT_COACHING_NOTE,
    payload,
  };
};

export const endCoachingRequest = (payload: {
  userId: string;
  coachingSessionId: string;
  coachId: string;
  isFinished: boolean;
  isForbidden: boolean;
  endCoachingMessage?: string;
}) => {
  return {
    type: END_COACHING_REQUESTING,
    payload,
  };
};

export const createCoachingFileRequest = (payload: {
  urlName: string;
  file: any;
  fileType: string;
  fileName?: string;
  userId: string;
  coachId: string;
}) => {
  return {
    type: CREATE_COACHING_FILE_REQUESTING,
    payload,
  };
};

export const getCoachingFilesRequest = (payload: {
  coachId: string;
  userId: string;
}) => {
  return {
    type: GET_COACHING_FILES_REQUESTING,
    payload,
  };
};

export const deleteCoachingFilesRequest = (payload: {
  coachingFileId: string;
}) => {
  return {
    type: DELETE_COACHING_FILE_REQUESTING,
    payload,
  };
};
