import React from "react";

const MicrodoingLogo = ({ sizePercentage }) => (
  <svg
    width={sizePercentage * 238}
    height={sizePercentage * 39}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 239 39"
    version="1.1"
    fill="none"
  >
    <path
      d="M25.4166 30.3722L25.378 14.1634L17.428 27.5164H14.6107L6.6993 14.5108V30.3722H0.833252V3.35754H6.00464L16.1159 20.1452L26.0727 3.35754H31.2055L31.2827 30.3722H25.4166Z"
      fill="#000528"
    />
    <path
      d="M37.2675 9.60951H43.2879V30.3722H37.2675V9.60951ZM40.2777 6.71508C39.1714 6.71508 38.2709 6.39348 37.5763 5.75027C36.8816 5.10706 36.5343 4.30949 36.5343 3.35754C36.5343 2.40559 36.8816 1.60802 37.5763 0.964812C38.2709 0.321604 39.1714 0 40.2777 0C41.384 0 42.2845 0.30874 42.9792 0.92622C43.6739 1.5437 44.0212 2.31555 44.0212 3.24177C44.0212 4.24517 43.6739 5.08134 42.9792 5.75027C42.2845 6.39348 41.384 6.71508 40.2777 6.71508Z"
      fill="#000528"
    />
    <path
      d="M58.9094 30.681C56.6968 30.681 54.7028 30.2307 52.9276 29.3302C51.1781 28.404 49.8016 27.1305 48.7982 25.5096C47.8205 23.8887 47.3317 22.0491 47.3317 19.9909C47.3317 17.9326 47.8205 16.093 48.7982 14.4722C49.8016 12.8513 51.1781 11.5906 52.9276 10.6901C54.7028 9.76389 56.6968 9.30078 58.9094 9.30078C61.0963 9.30078 63.0002 9.76389 64.6211 10.6901C66.2677 11.5906 67.4641 12.8899 68.2102 14.5879L63.5405 17.0964C62.4599 15.1926 60.9034 14.2406 58.8708 14.2406C57.3014 14.2406 56.0021 14.7552 54.973 15.7843C53.9439 16.8134 53.4293 18.2156 53.4293 19.9909C53.4293 21.7661 53.9439 23.1683 54.973 24.1975C56.0021 25.2266 57.3014 25.7411 58.8708 25.7411C60.9291 25.7411 62.4856 24.7892 63.5405 22.8853L68.2102 25.4324C67.4641 27.079 66.2677 28.3654 64.6211 29.2917C63.0002 30.2179 61.0963 30.681 58.9094 30.681Z"
      fill="#000528"
    />
    <path
      d="M77.4268 12.3496C78.1472 11.3462 79.112 10.5872 80.3213 10.0726C81.5562 9.55806 82.9713 9.30078 84.5664 9.30078V14.8581C83.8975 14.8066 83.4473 14.7809 83.2157 14.7809C81.4919 14.7809 80.1412 15.2697 79.1635 16.2474C78.1858 17.1994 77.697 18.6401 77.697 20.5698V30.3722H71.6766V9.60951H77.4268V12.3496Z"
      fill="#000528"
    />
    <path
      d="M97.8374 30.681C95.6505 30.681 93.6823 30.2307 91.9328 29.3302C90.209 28.404 88.8582 27.1305 87.8806 25.5096C86.9029 23.8887 86.4141 22.0491 86.4141 19.9909C86.4141 17.9326 86.9029 16.093 87.8806 14.4722C88.8582 12.8513 90.209 11.5906 91.9328 10.6901C93.6823 9.76389 95.6505 9.30078 97.8374 9.30078C100.024 9.30078 101.98 9.76389 103.703 10.6901C105.427 11.5906 106.778 12.8513 107.756 14.4722C108.733 16.093 109.222 17.9326 109.222 19.9909C109.222 22.0491 108.733 23.8887 107.756 25.5096C106.778 27.1305 105.427 28.404 103.703 29.3302C101.98 30.2307 100.024 30.681 97.8374 30.681ZM97.8374 25.7411C99.3811 25.7411 100.642 25.2266 101.619 24.1975C102.623 23.1426 103.125 21.7404 103.125 19.9909C103.125 18.2414 102.623 16.852 101.619 15.8229C100.642 14.768 99.3811 14.2406 97.8374 14.2406C96.2937 14.2406 95.0202 14.768 94.0168 15.8229C93.0134 16.852 92.5117 18.2414 92.5117 19.9909C92.5117 21.7404 93.0134 23.1426 94.0168 24.1975C95.0202 25.2266 96.2937 25.7411 97.8374 25.7411Z"
      fill="#000528"
    />
    <path
      d="M132.813 1.73666V30.3722H130.188V26.3586C129.365 27.7222 128.272 28.7642 126.908 29.4846C125.57 30.205 124.052 30.5652 122.354 30.5652C120.45 30.5652 118.726 30.1278 117.183 29.2531C115.639 28.3783 114.43 27.1562 113.555 25.5868C112.68 24.0174 112.243 22.2292 112.243 20.2224C112.243 18.2156 112.68 16.4275 113.555 14.8581C114.43 13.2887 115.639 12.0794 117.183 11.2304C118.726 10.3556 120.45 9.91825 122.354 9.91825C124.001 9.91825 125.48 10.2656 126.792 10.9602C128.13 11.6292 129.224 12.6197 130.073 13.9319V1.73666H132.813ZM122.586 28.1339C124.001 28.1339 125.274 27.8123 126.406 27.1691C127.564 26.5001 128.465 25.5611 129.108 24.3518C129.777 23.1426 130.111 21.7661 130.111 20.2224C130.111 18.6787 129.777 17.3023 129.108 16.093C128.465 14.8838 127.564 13.9576 126.406 13.3144C125.274 12.6455 124.001 12.311 122.586 12.311C121.145 12.311 119.846 12.6455 118.688 13.3144C117.556 13.9576 116.655 14.8838 115.986 16.093C115.343 17.3023 115.022 18.6787 115.022 20.2224C115.022 21.7661 115.343 23.1426 115.986 24.3518C116.655 25.5611 117.556 26.5001 118.688 27.1691C119.846 27.8123 121.145 28.1339 122.586 28.1339Z"
      fill="#000528"
    />
    <path
      d="M148.741 30.5652C146.786 30.5652 145.023 30.1278 143.454 29.2531C141.885 28.3526 140.65 27.1176 139.749 25.5482C138.849 23.9788 138.398 22.2035 138.398 20.2224C138.398 18.2414 138.849 16.4661 139.749 14.8967C140.65 13.3273 141.885 12.1052 143.454 11.2304C145.023 10.3556 146.786 9.91825 148.741 9.91825C150.696 9.91825 152.459 10.3556 154.028 11.2304C155.598 12.1052 156.82 13.3273 157.695 14.8967C158.595 16.4661 159.045 18.2414 159.045 20.2224C159.045 22.2035 158.595 23.9788 157.695 25.5482C156.82 27.1176 155.598 28.3526 154.028 29.2531C152.459 30.1278 150.696 30.5652 148.741 30.5652ZM148.741 28.1339C150.182 28.1339 151.468 27.8123 152.6 27.1691C153.758 26.5001 154.659 25.5611 155.302 24.3518C155.945 23.1426 156.267 21.7661 156.267 20.2224C156.267 18.6787 155.945 17.3023 155.302 16.093C154.659 14.8838 153.758 13.9576 152.6 13.3144C151.468 12.6455 150.182 12.311 148.741 12.311C147.3 12.311 146.001 12.6455 144.843 13.3144C143.711 13.9576 142.811 14.8838 142.142 16.093C141.499 17.3023 141.177 18.6787 141.177 20.2224C141.177 21.7661 141.499 23.1426 142.142 24.3518C142.811 25.5611 143.711 26.5001 144.843 27.1691C146.001 27.8123 147.3 28.1339 148.741 28.1339Z"
      fill="#000528"
    />
    <path
      d="M164.639 10.0726H167.379V30.3722H164.639V10.0726ZM166.029 5.6345C165.463 5.6345 164.987 5.44153 164.601 5.05561C164.215 4.66969 164.022 4.20658 164.022 3.66628C164.022 3.15172 164.215 2.70147 164.601 2.31555C164.987 1.92962 165.463 1.73666 166.029 1.73666C166.595 1.73666 167.071 1.92962 167.457 2.31555C167.843 2.67574 168.036 3.11312 168.036 3.62769C168.036 4.19371 167.843 4.66969 167.457 5.05561C167.071 5.44153 166.595 5.6345 166.029 5.6345Z"
      fill="#000528"
    />
    <path
      d="M185.346 9.91825C187.893 9.91825 189.913 10.6644 191.405 12.1566C192.923 13.6231 193.682 15.7714 193.682 18.6016V30.3722H190.942V18.8717C190.942 16.762 190.415 15.154 189.36 14.0476C188.305 12.9413 186.8 12.3882 184.845 12.3882C182.658 12.3882 180.921 13.0442 179.635 14.3564C178.374 15.6428 177.744 17.4309 177.744 19.7207V30.3722H175.004V10.0726H177.628V13.8161C178.374 12.5811 179.403 11.6292 180.715 10.9602C182.053 10.2656 183.597 9.91825 185.346 9.91825Z"
      fill="#000528"
    />
    <path
      d="M219.991 10.0726V27.9023C219.991 31.3499 219.142 33.897 217.444 35.5436C215.772 37.216 213.237 38.0521 209.841 38.0521C207.963 38.0521 206.175 37.7691 204.477 37.2031C202.805 36.6628 201.441 35.9038 200.386 34.9261L201.776 32.8422C202.753 33.7169 203.937 34.3987 205.326 34.8876C206.741 35.3764 208.22 35.6208 209.764 35.6208C212.337 35.6208 214.228 35.0162 215.437 33.807C216.646 32.6235 217.251 30.771 217.251 28.2497V25.664C216.402 26.9504 215.283 27.9281 213.894 28.597C212.53 29.2659 211.012 29.6004 209.34 29.6004C207.436 29.6004 205.699 29.1887 204.13 28.3654C202.586 27.5164 201.364 26.3458 200.463 24.8535C199.589 23.3356 199.151 21.6246 199.151 19.7207C199.151 17.8168 199.589 16.1188 200.463 14.6265C201.364 13.1343 202.586 11.9765 204.13 11.1532C205.673 10.3299 207.41 9.91825 209.34 9.91825C211.063 9.91825 212.62 10.2656 214.009 10.9602C215.399 11.6549 216.518 12.6583 217.367 13.9705V10.0726H219.991ZM209.61 27.1691C211.076 27.1691 212.401 26.8603 213.585 26.2429C214.768 25.5996 215.682 24.712 216.325 23.58C216.994 22.4479 217.328 21.1615 217.328 19.7207C217.328 18.2799 216.994 17.0064 216.325 15.9001C215.682 14.768 214.768 13.8933 213.585 13.2758C212.427 12.6326 211.102 12.311 209.61 12.311C208.143 12.311 206.818 12.6197 205.635 13.2372C204.477 13.8547 203.564 14.7294 202.895 15.8615C202.252 16.9935 201.93 18.2799 201.93 19.7207C201.93 21.1615 202.252 22.4479 202.895 23.58C203.564 24.712 204.477 25.5996 205.635 26.2429C206.818 26.8603 208.143 27.1691 209.61 27.1691Z"
      fill="#000528"
    />
    <circle cx="233.362" cy="25.8667" r="4.45131" fill="#75B6F3" />
  </svg>
);

export default MicrodoingLogo;
