import React, { ReactElement } from "react";
import withStyles from '@mui/styles/withStyles';
import AdministrationBox from "components/AdministrationMode/AdministrationBox";
import capitalizeFirstLetter from "utils/capitalizeFirstLetter";

const styles = (theme): any => ({
  main: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
});

const isSelectedMode = ({
  isMobileModeActivated = false,
  isAnchorEmailMode = false,
  isEmailModeActivated = false,
  isMicrosoftTeamsReminderActivated = false,
  mode = "mobile",
}) => {
  if (mode === "mobile") return isMobileModeActivated;
  if (mode === "web") return isAnchorEmailMode;
  if (mode === "email") return isEmailModeActivated;
  if (mode === "microsoftTeams") return isMicrosoftTeamsReminderActivated;
  return false;
};

type Modes = "mobile" | "web" | "email" | "microsoftTeams";

interface Params {
  classes: any;
  onClick: (mode: string) => () => any;
  isMobileModeActivated: boolean;
  isAnchorEmailMode: boolean;
  isEmailModeActivated: boolean;
  isMicrosoftTeamsReminderActivated: boolean;
  availableModes: Modes[];
}

const AdministrationMode = ({
  classes,
  onClick = mode => () => null,
  isMobileModeActivated = false,
  isAnchorEmailMode = false,
  isEmailModeActivated = false,
  isMicrosoftTeamsReminderActivated = false,
  availableModes = ["mobile", "web", "email", "microsoftTeams"],
}: Params): ReactElement => {
  return (
    <div className={classes.main}>
      {availableModes.map((el, index) => {
        return (
          <AdministrationBox
            onClick={onClick(el)}
            isSelected={isSelectedMode({
              mode: el,
              isMobileModeActivated,
              isAnchorEmailMode,
              isEmailModeActivated,
              isMicrosoftTeamsReminderActivated,
            })}
            icon={el}
            key={index}
            text={capitalizeFirstLetter(el)}
          />
        );
      })}
    </div>
  );
};

export default withStyles(styles)(AdministrationMode);
