import React, { PureComponent } from "react";
import { connect } from "react-redux";

import withStyles from '@mui/styles/withStyles';
import moment from "moment";

import Colors from "../../constants/Colors";

import { logout } from "../../services/auth/reducer";
import { getSelectedClient } from "../../services/myClients/selectors";
import {
  getSelectedClientRequest,
  modifyMyClientRequest,
  getActiveUsersRequest,
} from "../../services/myClients/actions";
import { getSelectedClientId } from "../../services/user/selectors";
import { changeField } from "../../services/client/actions";
import { uploadPictureRequest } from "../../services/upload/actions";
import { changeGeneralSettings } from "../../services/general/actions";
import i18n from "../../i18n";
import { Typography } from "@mui/material";
import { getAppLanguage } from "../../services/general/selectors";
import slugify from "slugify";

const styles = theme => ({
  main: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 100,
  },
  root: {
    width: "100%",
    minHeight: 500,
    paddingLeft: 20,
    paddingRight: 45,
    marginTop: theme.spacing.unit * 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "column",
    // backgroundColor: "#FAFAFA",
    backgroundColor: "#fff",
    // backgroundColor: "#fff",
    overflowX: "auto",
  },
  button: {
    backgroundColor: Colors.pink,
  },
  languageDiv: {
    marginTop: 10,
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
  },
  title: {
    textTransform: "capitalize",
    // color: Colors.black,
    fontFamily: "Montserrat",
    marginBottom: 10,
    fontSize: 28,
    lineHeight: 1.2143,
    fontWeight: 700,
  },
  subTitle: {
    // color: Colors.black,
    fontFamily: "Montserrat",
    marginTop: 20,
    marginBottom: 5,
    fontSize: 22,
    lineHeight: 1.2143,
    fontWeight: 700,
  },
  textField: {
    maxWidth: 300,
    minWidth: "33%",
  },
  uploader: {
    marginTop: 16,
  },
  saveButton: {
    marginTop: 25,
  },
  logoutButton: {
    marginTop: 15,
  },
  explanation: {
    marginTop: 5,
  },
  autoInviteDiv: {
    paddingTop: 15,
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "row",
  },
});

class AccountUsage extends PureComponent {
  state = {
    ...this.props,
  };

  logout = () => {
    this.props.logout();
  };

  componentDidMount = () => {
    const { clientId } = this.props;
    this.props.getActiveUsersRequest({ clientId });
  };

  componentDidUpdate = prevProps => {
    if (prevProps.uploadedPicture !== this.props.uploadedPicture) {
      this.setState({ logo: this.props.uploadedPicture });
    }
  };

  saveChanges = () => {
    const { logo, name, plan } = this.state;
    const { clientId } = this.props;
    this.props.modifyMyClientRequest({ clientId, logo, name, plan });
  };

  onChangeClientParams = param => event => {
    const value = event.target.value;
    this.setState({ [param]: value });
  };

  togglePremium = () => {
    const { plan } = this.state;

    const newPlan = plan === "free" ? "premium" : "free";
    this.setState({ plan: newPlan });
  };

  onFileUploaded = async files => {
    const { clientId } = this.props;

    const date = new Date().getTime();
    await this.props.uploadPictureRequest({
      urlName: slugify(`client-logo/${clientId}/${date}`),
      file: files[0],
    });
  };

  displayActiveUsersByMonth = () => {
    const { activeUsers, classes } = this.props;
    if (!activeUsers) return null;
    const allDates = activeUsers
      .map(el => el.firstDoneStepDate)
      .sort((a, b) => new Date(b) - new Date(a));

    const results = allDates.reduce((prev, curr) => {
      const month = moment(curr).format("MMMM YYYY");
      const monthIndex = prev.findIndex(el => el.month === month);
      if (monthIndex > -1) {
        prev[monthIndex].total += 1;
      } else {
        prev.push({ month, total: 1 });
      }
      return prev;
    }, []);

    return results.map((el, index) => {
      return (
        <Typography
          className={classes.subTitle}
          key={index}
          style={{ textTransform: "capitalize" }}
        >
          - {el.month} :{" "}
          <span style={{ color: Colors.pureMain }}>{el.total}</span>
        </Typography>
      );
    });
  };

  render() {
    const { classes, activeUsers = [] } = this.props;
    return (
      <div className={classes.main}>
        <div className={classes.root}>
          <Typography className={classes.subTitle}>
            {i18n.t("total-active-users")} :{" "}
            <span style={{ color: Colors.pureMain }}>{activeUsers.length}</span>
          </Typography>
          <Typography className={classes.explanation}>
            {i18n.t("active-users-explanation")}
          </Typography>
          {/* <Typography className={classes.subTitle}>
            {i18n.t("active-users-month")}
          </Typography> */}
          {this.displayActiveUsersByMonth()}
          {/* <Typography className={classes.subTitle}>
            {activeUsers.length}
          </Typography> */}

          {/* <RoundButton
            onClick={this.saveChanges}
            className={classes.saveButton}
            title={i18n.t("save-changes")}
          /> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { activeUsers } = state.myClients;
  const language = getAppLanguage(state);
  const clientId = getSelectedClientId(state);
  // const clientId = getUserClientId(state);
  const selectedClient = getSelectedClient(state);
  const { uploading } = state.upload;
  const { name } = selectedClient;
  const { role } = state.user;
  const isSuperAdmin = role === "superAdmin";
  const uploadedPicture = state.upload.picture;

  return {
    language,
    clientId,
    name,
    activeUsers,
    uploadedPicture,
    uploading,
    isSuperAdmin,
  };
};

export default connect(mapStateToProps, {
  logout,
  changeGeneralSettings,
  changeField,
  modifyMyClientRequest,
  uploadPictureRequest,
  getSelectedClientRequest,
  getActiveUsersRequest,
})(withStyles(styles)(AccountUsage));
