import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import moment from "moment";
import Fuse from "fuse.js";
import withStyles from "@mui/styles/withStyles";
import {
  getAllCampaignsRequest,
  deleteCampaignRequest,
  selectCampaign,
  getAllDraftsRequest,
  addCampaignToTemplateRequest,
  modifyCampaignRequest,
} from "../../services/campaign/actions";
import "moment/locale/fr";
import { Loader } from "../../components";
import selectLanguage from "../../utils/selectLanguage";
import CampaignBox from "./CampaignBox";
import i18n from "../../i18n";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import { getSelectedClientId } from "../../services/user/selectors";
import {
  getAppLanguage,
  getRouterQuery,
} from "../../services/general/selectors";
import SearchBar from "../../components/SearchBar";
import { FILTER_ALL } from "./FilterCampaigns";
import getCampaignStatus from "utils/getCampaignStatus";
import { getCampaigns } from "services/campaign/selectors";

moment.locale("fr");

const fuseOptions = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 80,
  maxPatternLength: 20,
  minMatchCharLength: 1,
  keys: [
    "title.fr",
    "title.en",
    "description.fr",
    "description.en",
    "label",
    "notes",
  ],
};

const styles = theme => ({
  main: {
    width: "90%",
    marginTop: 15,
    flexDirection: "column",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  paper: {
    flex: 1,
    marginTop: "calc(1rem)",
    marginLeft: "-1.5rem",
    marginRight: "-1.5rem",
    marginBottom: "calc(-3rem)",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "row",
  },
  topDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },
  searchBar: {
    marginTop: 5,
    marginBottom: 5,
    minWidth: 300,
    width: 300,
    flex: 0,
  },
  inputClassName: {
    border: "1px solid rgba(0,0,0,.09)",
    backgroundColor: "#fff",
  },
});

class MyDoneCampaigns extends PureComponent {
  state = {
    expanded: null,
    search: "",
    labelWidth: 0,
    isCreationModalVisible: false,
    isSeeCampaignModalVisible: false,
    isDeleteConfirmModalVisible: false,
    isModifyCampaignModalVisible: false,
    campaignSelected: {},
    filterSelected: FILTER_ALL,
  };

  componentDidMount() {
    const { campaigns, clientId, routerQuery } = this.props;
    this.props.getAllCampaignsRequest({ clientId });

    // in case we hit previous and we have a campaignId in params
    const { campaignId } = routerQuery;
    if (campaignId) {
      const campaign = campaigns.find(el => el._id === campaignId);
      if (campaign) {
        this.props.selectCampaign(campaign);
        this.setState({ isSeeCampaignModalVisible: true });
      }
    }
  }

  onChangeDay = event => {
    const isoWeekday = Number(event.target.value);
    const reminderTime = moment(this.props.reminderTime).isoWeekday(isoWeekday);
    this.props.changeCoffeeSettings({ reminderTime });
  };

  onChangeTime = event => {
    const time = event.target.value;
    const minute = Number(time.split(":")[1]);
    const hour = Number(time.split(":")[0]);

    const reminderTime = moment(this.props.reminderTime)
      .minute(minute)
      .hour(hour);
    this.props.changeCoffeeSettings({ reminderTime });
  };

  onClickMore = () => {};

  onClickCampaign = campaign => () => {
    this.props.selectCampaign(campaign);
    this.props.push(`/campaigns/${campaign._id}`);
  };

  onClickModify = campaign => () => {
    this.setState({
      isModifyCampaignModalVisible: true,
      campaignSelected: campaign,
    });
  };

  onClickDelete = campaign => () => {
    this.setState({
      isDeleteConfirmModalVisible: true,
      campaignSelected: campaign,
    });
  };

  onConfirmDelete = event => {
    event.stopPropagation();
    this.props.deleteCampaignRequest({
      campaignId: this.state.campaignSelected._id,
    });
    this.setState({ isDeleteConfirmModalVisible: false });
  };

  onAddTemplate = ({ trackId, isTemplate }) => {
    this.props.addCampaignToTemplateRequest({ trackId, isTemplate });
  };

  onClickArchive = campaignId => isDone => {
    this.props.modifyCampaignRequest({ campaignId, isDone: !isDone });
  };

  displayCampaigns = campaigns => {
    const { language } = this.props;
    return campaigns.map((el, i) => {
      return (
        <CampaignBox
          key={i}
          {...el}
          {...el.track}
          campaignId={el._id}
          onAddTemplate={this.onAddTemplate}
          onClickArchive={this.onClickArchive}
          onClickModify={this.onClickModify(el)}
          onClickDelete={this.onClickDelete(el)}
          onClick={this.onClickCampaign(el)}
          hasArchive={true}
          title={selectLanguage({ text: el.title, language })}
          description={selectLanguage({ text: el.description, language })}
          hoverText={i18n.t("open-campaign")}
        />
      );
    });
  };

  onChangeSearch = search => {
    this.setState({ search });
  };

  selectFilter = filterSelected => () => {
    this.setState({ filterSelected });
  };

  getHasEnded = (endDate, isDone) => {
    const now = moment();
    if (isDone) {
      return true;
    }
    if (endDate) {
      return moment(now).isAfter(endDate);
    }
    return false;
  };

  getHasStarted = startDate => {
    const now = moment();
    if (startDate) {
      const isBefore = moment(startDate).isBefore(now);
      return isBefore;
    }
    return true;
  };

  getCampaignsStatus = campaigns => {
    return campaigns.map(campaign => {
      const { startDate, endDate, isDone } = campaign;

      const status = getCampaignStatus({ startDate, endDate, isDone });

      campaign.status = status;
      return campaign;
    });
  };

  filterCampaigns = campaigns => campaigns.filter(el => el.isDone);

  render() {
    const { classes, campaigns } = this.props;
    const { search } = this.state;

    const campaignsWithStatus = this.getCampaignsStatus(campaigns);

    const filteredCampaigns = this.filterCampaigns(campaignsWithStatus);

    const fuse = new Fuse(filteredCampaigns, fuseOptions);
    const searchResults =
      search.length > 0 ? fuse.search(search) : filteredCampaigns;

    return (
      <div className={classes.main}>
        <DeleteConfirmModal
          title={i18n.t("delete-campaign-confirm-title")}
          onClickConfirm={this.onConfirmDelete}
          isVisible={this.state.isDeleteConfirmModalVisible}
          onClose={() => this.setState({ isDeleteConfirmModalVisible: false })}
        />
        <div className={classes.topDiv}>
          <SearchBar
            placeholder={i18n.t("search-campaign-placeholder")}
            className={classes.searchBar}
            inputClassName={classes.inputClassName}
            onChangeSearch={this.onChangeSearch}
            value={this.state.search}
          />
        </div>

        <div className={classes.paper}>
          {this.props.requesting ? (
            <Loader size={75} />
          ) : (
            this.displayCampaigns(searchResults)
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { requesting } = state.campaign;
  const campaigns = getCampaigns(state);
  const routerQuery = getRouterQuery(state);
  const language = getAppLanguage(state);
  const clientId = getSelectedClientId(state);
  return {
    token: state.auth.token,
    language,
    requesting,
    clientId,
    campaigns,
    routerQuery,
  };
};

export default connect(mapStateToProps, {
  getAllCampaignsRequest,
  selectCampaign,
  deleteCampaignRequest,
  getAllDraftsRequest,
  addCampaignToTemplateRequest,
  modifyCampaignRequest,
  push,
})(withStyles(styles)(MyDoneCampaigns));
