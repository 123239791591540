import React, { useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Colors from "../../constants/Colors";
import i18n from "../../i18n";
import { LottieEmpty } from "../../components";
import displayDate from "../../utils/displayDate";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import {
  getNotificationPage,
  getNotifications,
} from "services/notification/selectors";
import { selectPreviewTab } from "services/campaign/actions";
import {
  clearGeneralNotifications,
  clickNotification,
  loadNotificationsRequest,
} from "services/notification/actions";

const useStyles = makeStyles(theme => ({
  mainDiv: {
    width: 412,
    marginTop: 5,
    backgroundColor: Colors.white,
    display: "flex",
    flexDirection: "column",
  },
  topDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    padding: 4,
    paddingLeft: 16,
    backgroundColor: Colors.main,
    boxShadow:
      "0px 1px 2px 0px rgba(60,64,67,.3), 0px 1px 3px 1px rgba(60,64,67,.15)",
  },
  bottomDiv: {
    color: "rgba(0,0,0,.54)",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
  },
  title: {
    flex: 1,
    fontSize: 20,
    color: Colors.white,
    fontWeight: 500,
  },
  list: {
    width: 412,
  },
  closeButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: Colors.lightWhite,
  },
  fullList: {
    width: "auto",
  },
  noNotificationDiv: {
    width: "100%",
    color: "rgba(0,0,0,.54)",
    textAlign: "center",
    padding: "40px 0px",
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  noNotificationText: {
    width: 300,
    fontSize: 16,
    marginTop: 16,
  },
  noNotificationSubtitle: {
    width: 300,
    fontSize: 14,
    marginTop: 16,
    marginBottom: 16,
  },
  notificationDiv: {
    cursor: "pointer",
    border: "solid",
    borderWidth: 0,
    borderBottomWidth: 1,
    borderColor: "#dddfe2",
    paddingTop: 6,
    paddingRight: 20,
    paddingBottom: 6,
    paddingLeft: 12,
    width: "100%",
    backgroundColor: Colors.blueGrey,
    // padding: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    opacity: 1,
    justifyContent: "flex-start",
    "&:hover": {
      opacity: 0.7,
    },
  },
  seeNotification: {
    backgroundColor: "#fff",
  },
  textView: {
    flex: 1,
    paddingLeft: 10,
    paddingRight: 10,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  pictureDiv: {
    width: 50,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  picture: {
    minWidth: 50,
    minHeight: 50,
    maxWidth: 50,
    maxHeight: 50,
    borderRadius: 30,
    border: "solid",
    borderWidth: 2,
    borderColor: "white",
  },
  dateView: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  date: {
    color: Colors.dateBlue,
    // marginTop: 4
    fontSize: 12,
  },
  notificationTitle: {
    fontWeight: "bold",
    color: Colors.black,
    fontSize: 14,
  },
  seenText: {
    color: "#90949c",
  },
  profilPictureText: {
    color: "black",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: 16,
    justifyContent: "center",
    display: "flex",
    width: 60,
    height: 60,
    borderRadius: 30,
    border: "solid",
    borderWidth: 2,
    borderColor: "white",
  },
  loadMoreNotification: {
    cursor: "pointer",
    border: "solid",
    borderWidth: 0,
    borderBottomWidth: 1,
    borderColor: "#fff",
    paddingTop: 6,
    paddingRight: 20,
    paddingBottom: 6,
    paddingLeft: 12,
    width: "100%",
    backgroundColor: Colors.blueGrey,
    // padding: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    opacity: 1,
    justifyContent: "center",
    "&:hover": {
      opacity: 0.7,
    },
  },
}));

interface Props {
  isOpen?: boolean;
  toggleDrawer: () => void;
  timezone?: string;
  userId?: string;
}

const NotificationDrawer = ({
  isOpen,
  toggleDrawer,
  timezone,
  userId,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notifications = useSelector(getNotifications);
  const page = useSelector(getNotificationPage);

  const onInit = function () {
    if (isOpen) {
      dispatch(clearGeneralNotifications());
    }
  };

  useEffect(onInit, []);

  const toggleNotificationDrawer = () => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    toggleDrawer();
  };

  const loadMore = () => {
    dispatch(
      loadNotificationsRequest({
        userId: userId,
        page: page,
        numberByPage: 30,
      }),
    );
  };

  const redirectToCampagn = (data, _id, type) => {
    if (data?.campaignId) {
      toggleDrawer();
      switch (type) {
        case "statistics":
          dispatch(selectPreviewTab(0));
          break;
        case "learners":
          dispatch(selectPreviewTab(1));
          break;
        case "actions":
          dispatch(selectPreviewTab(2));
          break;
        case "resources":
          dispatch(selectPreviewTab(3));
          break;
        case "surveys":
          dispatch(selectPreviewTab(4));
          break;
        case "review":
          dispatch(selectPreviewTab(5));
          break;
      }
      dispatch(
        clickNotification({
          notificationId: _id,
          userId: userId,
        }),
      );
      dispatch(push(`/campaigns/${data?.campaignId}`));
    }
  };

  const displayNotifications = notifications => {
    if (notifications.length) {
      return notifications.map((notification, index) => {
        const {
          picture,
          text,
          type,
          // event,
          createdAt,
          data,
          readBy,
          _id,
        } = notification;
        const seen = readBy?.findIndex(el => el.readerId === userId) > -1;
        return (
          <div
            className={`${classes.notificationDiv} ${
              seen && classes.seeNotification
            }`}
            key={index}
            onClick={() => redirectToCampagn(data, _id, type)}
          >
            {picture ? (
              <div className={classes.pictureDiv}>
                <img
                  src={picture}
                  className={classes.picture}
                  alt="Notification"
                />
              </div>
            ) : (
              <Typography className={classes.profilPictureText}>
                {text?.length > 0 ? text[0] : "A"}
              </Typography>
            )}
            <div className={classes.textView}>
              <Typography className={classes.notificationTitle}>
                {text}
              </Typography>
              <Typography
                className={`${classes.date} ${seen && classes.seenText}`}
              >
                {displayDate(createdAt, timezone)}
              </Typography>
            </div>
          </div>
        );
      });
    }
    return (
      <div className={classes.noNotificationDiv}>
        <LottieEmpty />
        <Typography className={classes.noNotificationText}>
          {i18n.t("no-notification-yet")}
        </Typography>
        <Typography className={classes.noNotificationSubtitle}>
          {i18n.t("no-notification-subtitle")}
        </Typography>
      </div>
    );
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={toggleNotificationDrawer()}>
      <div className={classes.mainDiv}>
        <div className={classes.topDiv}>
          <Typography className={classes.title}>
            {i18n.t("notifications")}
          </Typography>
          <IconButton
            className={classes.closeButton}
            onClick={toggleNotificationDrawer()}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.bottomDiv}>
          {displayNotifications(notifications)}
          <div className={classes.loadMoreNotification} onClick={loadMore}>
            <Typography>{i18n.t("see-more")}</Typography>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

NotificationDrawer.defaultProps = {
  timezone: "Europe/Paris",
};

export default NotificationDrawer;
