import { useDispatch, useSelector } from "react-redux";
import { uploadPictureRequest } from "services/upload/actions";
import { getUploadedPicture, getUploading } from "services/upload/selectors";
import { getUserClientId, getUserId } from "services/user/selectors";
import slugify from "slugify";

export const useUploadPicture = () => {
  const clientId = useSelector(getUserClientId);
  const userId = useSelector(getUserId);
  const picture = useSelector(getUploadedPicture);
  const uploading = useSelector(getUploading);

  const dispatch = useDispatch();

  const sendRequest = async files => {
    const date = new Date().toISOString().toString();
    const file = files[0];
    if (!file) {
      return;
    }

    await dispatch(
      uploadPictureRequest({
        urlName: slugify(`picture/${clientId}/${userId}/${date}`),
        file: files[0],
      }),
    );
  };

  return [uploading, picture, sendRequest] as const;
};
