import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";
import withStyles from '@mui/styles/withStyles';
import Colors from "../../constants/Colors";
import i18n from "../../i18n";

const styles = theme => ({
  mainDiv: {
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontWeight: "bold",
    fontSize: "1.2em",
    color: Colors.black,
    marginLeft: 20,
  },
  percentage: {
    width: "100%",
    marginTop: 5,
    marginLeft: 5,
    color: Colors.grey,
    fontSize: "0.8em",
    textAlign: "left",
  },
});

class PollBar extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.mainDiv}>
        <ProgressBar
          height={50}
          borderRadius={8}
          width={"100%"}
          style={{ marginTop: 15 }}
          unfilledBackground={Colors.lightGrey}
          percent={this.props.isProgressVisible ? this.props.progress : 0}
          filledBackground={"linear-gradient(to right, #b2fefa, #0ed2f7)"}
        >
          <p className={classes.title}>{this.props.title}</p>
        </ProgressBar>
        <p className={classes.percentage}>
          {this.props.votes
            ? i18n.t("people-voted", { people: this.props.progress.toFixed(0) })
            : i18n.t("no-votes")}
        </p>
      </div>
    );
  }
}

export default withStyles(styles)(PollBar);
