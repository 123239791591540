import React from "react";

const PhoneIcon = ({ sizePercentage, color, className }) => (
  <svg
    className={className}
    width={sizePercentage * 11}
    height={sizePercentage * 17}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 11 17"
    version="1.1"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.89331 1.83522H2.89331C2.62809 1.83522 2.37374 1.94058 2.1862 2.12811C1.99867 2.31565 1.89331 2.57 1.89331 2.83522V14.8352C1.89331 15.1004 1.99867 15.3548 2.1862 15.5423C2.37374 15.7299 2.62809 15.8352 2.89331 15.8352H8.89331C9.15853 15.8352 9.41288 15.7299 9.60042 15.5423C9.78795 15.3548 9.89331 15.1004 9.89331 14.8352V2.83522C9.89331 2.57 9.78795 2.31565 9.60042 2.12811C9.41288 1.94058 9.15853 1.83522 8.89331 1.83522ZM2.89331 0.83522C2.36288 0.83522 1.85417 1.04593 1.4791 1.42101C1.10402 1.79608 0.893311 2.30479 0.893311 2.83522V14.8352C0.893311 15.3657 1.10402 15.8744 1.4791 16.2494C1.85417 16.6245 2.36288 16.8352 2.89331 16.8352H8.89331C9.42374 16.8352 9.93245 16.6245 10.3075 16.2494C10.6826 15.8744 10.8933 15.3657 10.8933 14.8352V2.83522C10.8933 2.30479 10.6826 1.79608 10.3075 1.42101C9.93245 1.04593 9.42374 0.83522 8.89331 0.83522H2.89331Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.89331 14.8352C6.15853 14.8352 6.41288 14.7299 6.60042 14.5423C6.78795 14.3548 6.89331 14.1004 6.89331 13.8352C6.89331 13.57 6.78795 13.3157 6.60042 13.1281C6.41288 12.9406 6.15853 12.8352 5.89331 12.8352C5.62809 12.8352 5.37374 12.9406 5.1862 13.1281C4.99867 13.3157 4.89331 13.57 4.89331 13.8352C4.89331 14.1004 4.99867 14.3548 5.1862 14.5423C5.37374 14.7299 5.62809 14.8352 5.89331 14.8352Z"
      fill={color}
    />
  </svg>
);

PhoneIcon.defaultProps = {
  sizePercentage: 1,
  color: "black",
  className: null,
};

export { PhoneIcon };
