import React, { Component } from "react";
import withStyles from '@mui/styles/withStyles';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Colors from "../../../constants/Colors";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import FirstFeelingChart from "../../Recap/FirstFeelingChart";
import FlatButton from "../../../components/FlatButton";
import Switch from "react-switch";

import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import { connect } from "react-redux";
import {
  getManagerCommentsRequest,
  deleteManagerCommentRequest,
  onEditGoal,
  submitGoalsRequest,
  modifyUserSelected,
} from "../../../services/manager/actions";
import moment from "moment";
import { getUserId } from "../../../services/user/selectors";
import i18n from "../../../i18n";
import { Typography } from "@mui/material";

moment.locale("fr");

const styles = theme => ({
  closeButton: {
    zIndex: 10,
    position: "absolute",
    top: 1,
    right: 1,
  },
  minDiv: {
    width: 300,
    minWidth: 100,
  },
  accent: {
    color: "#6198DE",
  },
  commentDiv: {
    width: "100%",
    borderRadius: 8,
    border: "solid",
    borderWidth: 1,
    borderColor: Colors.lightGrey,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    marginBottom: 15,
  },
  date: {
    textAlign: "right",
    fontSize: 12,
  },
  commentMainDiv: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    flexDirection: "row",
  },
  actionsButtonDiv: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  deleteButton: {
    padding: 1,
  },
  editButton: {
    padding: 1,
    marginRight: -3,
  },
  commentBottom: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    display: "flex",
  },
  leftDiv: {
    flex: 2,
    flexWrap: "wrap",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  rightDiv: {
    flex: 3,
    height: 400,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  mainDiv: {
    flex: 1,
    minWidth: 800,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "row",
  },
  paper: {
    maxWidth: 1200,
  },
  saveButton: {
    marginTop: 15,
  },
  activateDiv: {
    marginTop: 10,
    marginBottom: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  info: {
    marginLeft: 10,
  },
});

class CommentsModal extends Component {
  state = {
    open: false,
    commentId: "",
  };

  _handleKeyDown = event => {
    if (
      (window.navigator.platform.match("Mac")
        ? event.metaKey
        : event.ctrlKey) &&
      event.keyCode === 83
    ) {
      event.preventDefault();
      this.submitGoals();
    }
  };

  formatDate = date => {
    return moment(date).format("L");
  };

  onDelete = commentId => () => {
    this.setState({ open: true, commentId });
  };

  onRendered = () => {
    document.addEventListener("keydown", this._handleKeyDown, false);
  };

  handleClose = onClose => () => {
    document.removeEventListener("keydown", this._handleKeyDown, false);
    onClose();
  };

  onEditGoal = goal => event => {
    const value = event.target.value;
    this.props.onEditGoal({ [goal]: value });
  };

  toggleGoals = () => {
    console.log("HAS GOALS");
    console.log(this.props.hasGoals);
    const { hasGoals } = this.props.userSelected;
    this.props.modifyUserSelected({ hasGoals: !hasGoals });
  };

  submitGoals = () => {
    const { hasGoals } = this.props.userSelected;
    this.props.submitGoalsRequest({
      userId: this.props.userSelected._id,
      goals: this.props.userSelected.goals,
      hasGoals,
    });
  };

  deleteComment = () => {
    this.props.deleteManagerCommentRequest({ commentId: this.state.commentId });
    this.setState({ open: false });
  };

  formatGoalsEvolution = (doneSteps, goals) => {
    const result = [
      {
        title: i18n.t("leadership"),
        [i18n.t("current-situation")]: 0,
        [i18n.t("goal")]: goals.leadership,
      },
      {
        title: i18n.t("publicSpeaking"),
        [i18n.t("current-situation")]: 0,
        [i18n.t("goal")]: goals.publicSpeaking,
      },
      {
        title: i18n.t("proactivity"),
        [i18n.t("current-situation")]: 0,
        [i18n.t("goal")]: goals.proactivity,
      },
      {
        title: i18n.t("teamCohesion"),
        [i18n.t("current-situation")]: 0,
        [i18n.t("goal")]: goals.teamCohesion,
      },
      {
        title: i18n.t("resilience"),
        [i18n.t("goal")]: goals.resilience,
        [i18n.t("current-situation")]: 0,
      },
    ];

    doneSteps.forEach(el => {
      if (el.stepId) {
        if (el.stepId.category === "leadership") {
          result[0][i18n.t("current-situation")]++;
        } else if (el.stepId.category === "publicSpeaking") {
          result[1][i18n.t("current-situation")]++;
        } else if (el.stepId.category === "proactivity") {
          result[2][i18n.t("current-situation")]++;
        } else if (el.stepId.category === "teamCohesion") {
          result[3][i18n.t("current-situation")]++;
        } else if (el.stepId.category === "resilience") {
          result[4][i18n.t("current-situation")]++;
        }
      }
    });

    return result;
  };

  render() {
    const { classes, isVisible, onClose, userSelectedDoneSteps } = this.props;
    const { goals, hasGoals } = this.props.userSelected;
    return (
      <Dialog
        open={isVisible}
        ref={this.onRendered}
        onClose={this.handleClose(onClose)}
        classes={{ paper: classes.paper }}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        <IconButton className={classes.closeButton} onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
        <DialogTitle id="scroll-dialog-title">
          {i18n.t("which-goal-set")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.minDiv} />
          {this.props.requesting ? (
            <CircularProgress />
          ) : (
            <div className={classes.mainDiv}>
              <div className={classes.leftDiv}>
                <TextField
                  label={i18n.t("leadership")}
                  type="number"
                  value={goals.leadership}
                  onChange={this.onEditGoal("leadership")}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  label={i18n.t("resilience")}
                  type="number"
                  value={goals.resilience}
                  onChange={this.onEditGoal("resilience")}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  label={i18n.t("proactivity")}
                  type="number"
                  value={goals.proactivity}
                  onChange={this.onEditGoal("proactivity")}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  label={i18n.t("publicSpeaking")}
                  type="number"
                  onChange={this.onEditGoal("publicSpeaking")}
                  value={goals.publicSpeaking}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  label={i18n.t("teamCohesion")}
                  type="number"
                  value={goals.teamCohesion}
                  onChange={this.onEditGoal("teamCohesion")}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  label={i18n.t("global-goal")}
                  type="number"
                  value={goals.global}
                  onChange={this.onEditGoal("global")}
                  margin="normal"
                  variant="outlined"
                />
                <div className={classes.activateDiv}>
                  <Switch checked={!!hasGoals} onChange={this.toggleGoals} />
                  <Typography className={classes.info}>
                    {i18n.t("has-goals-activated")}
                  </Typography>
                </div>
                <FlatButton
                  className={classes.saveButton}
                  title={i18n.t("save-changes")}
                  fullWidth
                  onClick={this.submitGoals}
                  variant="contained"
                  color="primary"
                ></FlatButton>
              </div>
              <div className={classes.rightDiv}>
                <FirstFeelingChart
                  isSmall
                  keys={[i18n.t("current-situation"), i18n.t("goal")]}
                  data={this.formatGoalsEvolution(userSelectedDoneSteps, goals)}
                />
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  const {
    userSelected,
    userComment,
    managerComments,
    requesting,
  } = state.manager;
  const { doneSteps } = state.campaign;
  const userId = getUserId(state);
  const userSelectedDoneSteps = doneSteps.filter(
    el => el.userId === userSelected._id,
  );
  return {
    userId,
    userSelectedDoneSteps,
    requesting,
    managerComments: managerComments.reverse(),
    userSelected,
    userComment,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    getManagerCommentsRequest,
    deleteManagerCommentRequest,
    onEditGoal,
    submitGoalsRequest,
    modifyUserSelected,
  })(CommentsModal),
);
