import withStyles from "@mui/styles/withStyles";
import CloseIcon from "@mui/icons-material/Close";
import React, { useCallback, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import HeaderBar from "scenes/Campaigns/CampaignScreen/HeaderBar";
import ResourceSide from "scenes/Campaigns/CampaignScreen/ResourceSide";
import SurveysSide from "scenes/Campaigns/CampaignScreen/SurveysSide";
import { getAppLanguage } from "services/general/selectors";
import { getSurveysByCampaignRequest } from "services/survey/actions";
import Colors from "../../../constants/Colors";
import i18n from "../../../i18n";
import {
  changeAddUsersModal,
  getCampaignAllDoneStepsRequest,
  getCampaignCommentsRequest,
  getCampaignInteraction,
  getCampaignTeamRankingRequest,
  getCampaignUserRankingRequest,
  getCampaignUsersInfosRequest,
  selectPreviewTab,
} from "../../../services/campaign/actions";
import {
  getSelectedClientId,
  getUserId,
} from "../../../services/user/selectors";
import ActionsSide from "./ActionsSide/index";
import LearnersSide from "./LearnerSide";
import ReviewsSide from "./ReviewsSide";
import SideBar from "./SideBar";
import StatsSide from "./StatsSide";
import { useParams } from "react-router";
import AddUsersToCampaignModal from "../AddUsersToCampaignModal";
import { push } from "connected-react-router";
import { getNotificationsHistoryRequest } from "services/notification/actions";
import { getCampaignSelected } from "services/campaign/selectors";

const styles = theme => ({
  paper: {
    flex: 1,
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    borderRadius: 0,
    minHeight: "100%",
    minWidth: "100%",
    "-webkit-font-smoothing": "antialiased",
  },
  closeButton: {
    cursor: "pointer",
    backgroundColor: Colors.pureMain,
    borderBottomLeftRadius: 4,
    height: 40,
    width: 40,
    zIndex: 10,
    position: "fixed",
    top: 0,
    right: 0,
    transition: "all 0.2s ease",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "&:hover": {
      opacity: 0.9,
    },
  },
  leftDiv: {
    width: 240,
  },
  rightDiv: {
    flex: 3,
    zIndex: 1,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F2F3F5",
  },
  box: {
    boxSizing: "border-box",
    paddingLeft: 75,
    paddingRight: 75,
    width: "100%",
    alignSelf: "center",
  },
  "@media screen and (max-device-width: 1200px)": {
    box: {},
  },
  "@media screen and (max-device-width: 1440px) and (min-device-width: 1200px)": {
    box: {},
  },
  "@media screen and (min-device-width: 1440px)": {
    box: {
      maxWidth: 1200,
    },
  },
});

const CampaignScreen = ({
  classes,
  userId,
  onboardingSurveyId,
  previewTabSelected,
  language,
  isAddUsersModalVisible,
}) => {
  const { campaignId } = useParams();
  const dispatch = useDispatch();

  const mountComponent = useCallback(() => {
    dispatch(
      getNotificationsHistoryRequest({ campaignId, page: 0, numberByPage: 20 }), // ! attention, ici le numbre de limit ne fonctionne pas, il a été retiré en back afin de tout récupérer
    );
    dispatch(
      getCampaignAllDoneStepsRequest({
        campaignId,
      }),
    );
    dispatch(
      getCampaignTeamRankingRequest({
        campaignId,
        limit: 20,
        page: 0,
      }),
    );
    dispatch(
      getCampaignUserRankingRequest({
        campaignId,
        limit: 20,
        page: 0,
      }),
    );
    dispatch(
      getCampaignCommentsRequest({
        campaignId,
        limit: 0,
        page: 0,
      }),
    );
    dispatch(
      getCampaignUsersInfosRequest({
        campaignId,
      }),
    );
    dispatch(
      getSurveysByCampaignRequest({
        campaignId,
        surveyId: onboardingSurveyId,
      }),
    );
    dispatch(getCampaignInteraction({ campaignId })); // get stat of interactions
  }, [campaignId, dispatch, onboardingSurveyId]);

  useEffect(() => {
    mountComponent();
  }, [mountComponent]);

  const displayRightSide = tabSelected => {
    if (tabSelected === 0) {
      return <StatsSide />;
    } else if (tabSelected === 1) {
      return <LearnersSide />;
    } else if (tabSelected === 2) {
      return <ActionsSide />;
    } else if (tabSelected === 3) {
      return <ResourceSide />;
    } else if (tabSelected === 4) {
      return <SurveysSide />;
    } else if (tabSelected === 5) {
      return <ReviewsSide />;
    }
  };

  const getTabTitle = (tabSelected, language) => {
    if (tabSelected === 0) return i18n.t("statistics");
    if (tabSelected === 1) return i18n.t("learners");
    if (tabSelected === 2) return i18n.t("Actions");
    if (tabSelected === 3) return i18n.t("resources");
    if (tabSelected === 4) return i18n.t("Surveys");
    if (tabSelected === 5) return i18n.t("Reviews");
  };

  const onClose = () => {
    dispatch(selectPreviewTab(0));
    dispatch(push("/campaigns"));
  };

  const onCloseAddUsersModal = () => {
    dispatch(
      changeAddUsersModal({
        isAddUsersModalVisible: false,
        hasPublishCampaignAnimation: false,
      }),
    );
  };

  return (
    <div className={classes.paper}>
      {/* <div className={classes.closeButton} onClick={onClose}>
        <CloseIcon
          style={{ color: "#fff", width: 20, height: 20, zIndex: 5 }}
        />
      </div> */}
      <div className={classes.leftDiv}>
        <SideBar onClose={onClose} />
      </div>
      <div className={classes.rightDiv}>
        <div className={classes.box}>
          <HeaderBar
            onClick={onClose}
            title={getTabTitle(previewTabSelected, language)}
            tabSelected={previewTabSelected}
          />
          {displayRightSide(previewTabSelected)}
        </div>
      </div>
      <AddUsersToCampaignModal
        isVisible={isAddUsersModalVisible}
        onClose={onCloseAddUsersModal}
      />
    </div>
  );
};

const mapStateToProps = state => {
  const clientId = getSelectedClientId(state);
  const userId = getUserId(state);
  const { previewTabSelected, isAddUsersModalVisible } = state.campaign;
  const campaignSelected = getCampaignSelected(state);
  const { onboardingSurveyId } = campaignSelected;
  return {
    language: getAppLanguage(state),
    clientId,
    userId,
    onboardingSurveyId,
    previewTabSelected,
    isAddUsersModalVisible,
  };
};

export default connect(mapStateToProps, {
  getCampaignAllDoneStepsRequest,
  getCampaignUserRankingRequest,
  getCampaignTeamRankingRequest,
  getCampaignCommentsRequest,
  getCampaignUsersInfosRequest,
  getSurveysByCampaignRequest,
  selectPreviewTab,
})(withStyles(styles)(CampaignScreen));
