import React from "react";

import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";

import Colors from "../../constants/Colors";

const styles = theme => ({
  main: {
    position: "absolute",
    top: 0,
    padding: 10,
    backgroundColor: Colors.red,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  text: {
    margin: 0,
    lineHeight: 1,
    marginBlockEnd: 0,
    marginBlockStart: 0,
    color: Colors.white,
    fontWeight: 700,
    fontSize: "0.7em",
  },
});

const Label = ({ classes, title, className }) => {
  return (
    <div className={`${classes.main} ${className}`}>
      <Typography className={classes.text}>{title}</Typography>
    </div>
  );
};

export default withStyles(styles)(Label);
