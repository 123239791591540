export const NOTIFICATION_NEW_HOME = "NOTIFICATION_NEW_HOME";
export const NOTIFICATION_HOME_SEEN = "NOTIFICATION_HOME_SEEN";
export const NOTIFICATION_HOME_INCREMENT = "NOTIFICATION_HOME_INCREMENT";

export const NOTIFICATION_MESSAGE_INCREMENT = "NOTIFICATION_MESSAGE_INCREMENT";
export const NOTIFICATION_NEW_MESSAGES = "NOTIFICATION_NEW_MESSAGES";
export const NOTIFICATION_MESSAGE_SEEN = "NOTIFICATION_MESSAGE_SEEN";

export const NOTIFICATION_PULSE_SET = "NOTIFICATION_PULSE_SET";
export const NOTIFICATION_PULSE_CLEAR = "NOTIFICATION_PULSE_CLEAR";
export const NOTIFICATION_PULSE_ADD = "NOTIFICATION_PULSE_ADD";

export const NOTIFICATION_TEAM_SET = "NOTIFICATION_TEAM_SET";
export const NOTIFICATION_TEAM_CLEAR = "NOTIFICATION_TEAM_CLEAR";
export const NOTIFICATION_TEAM_ADD = "NOTIFICATION_TEAM_ADD";

export const NOTIFICATION_RANDOM_COFFEE_SEEN =
  "NOTIFICATION_RANDOM_COFFEE_SEEN";
export const NOTIFICATION_RANDOM_COFFEE_INCREMENT =
  "NOTIFICATION_RANDOM_COFFEE_INCREMENT";
export const NOTIFICATION_NEW_RANDOM_COFFEE = "NOTIFICATION_NEW_RANDOM_COFFEE";

export const NOTIFICATION_GENERAL_GET_REQUESTING =
  "NOTIFICATION_GENERAL_GET_REQUESTING";
export const NOTIFICATION_GENERAL_GET_SUCCESS =
  "NOTIFICATION_GENERAL_GET_SUCCESS";
export const NOTIFICATION_GENERAL_GET_ERROR = "NOTIFICATION_GENERAL_GET_ERROR";

export const NOTIFICATION_GENERAL_LOAD_REQUESTING =
  "NOTIFICATION_GENERAL_LOAD_REQUESTING";
export const NOTIFICATION_GENERAL_LOAD_SUCCESS =
  "NOTIFICATION_GENERAL_LOAD_SUCCESS";
export const NOTIFICATION_GENERAL_LOAD_ERROR =
  "NOTIFICATION_GENERAL_LOAD_ERROR";

export const NOTIFICATION_GENERAL_SET = "NOTIFICATION_GENERAL_SET";
export const NOTIFICATION_GENERAL_CLEAR = "NOTIFICATION_GENERAL_CLEAR";
export const NOTIFICATION_GENERAL_ADD = "NOTIFICATION_GENERAL_ADD";

export const NOTIFICATION_STOP_LOAD = "NOTIFICATION_STOP_LOAD";
export const NOTIFICATION_CLICK = "NOTIFICATION_CLICK";

export const NOTIFICATION_NEW_ACHIEVEMENT = "NOTIFICATION_NEW_ACHIEVEMENT";
export const NOTIFICATION_ACHIEVEMENT_SEEN = "NOTIFICATION_ACHIEVEMENT_SEEN";

export const NOTIFICATION_HISTORY_REQUESTING =
  "notification/NOTIFICATION_HISTORY_REQUESTING";
export const NOTIFICATION_HISTORY_SUCCESS =
  "notification/NOTIFICATION_HISTORY_SUCCESS";
export const NOTIFICATION_HISTORY_ERROR =
  "notification/NOTIFICATION_HISTORY_ERROR";

export const ADD_NOTIFICATION_TO_FAVORITE_REQUESTING =
  "notification/ADD_NOTIFICATION_TO_FAVORITE_REQUESTING";

export const GET_FAVORITE_NOTIFICATIONS_REQUESTING =
  "notification/GET_FAVORITE_NOTIFICATIONS_REQUESTING";
export const GET_FAVORITE_NOTIFICATIONS_SUCCESS =
  "notification/GET_FAVORITE_NOTIFICATIONS_SUCCESS";
export const GET_FAVORITE_NOTIFICATIONS_ERROR =
  "notification/GET_FAVORITE_NOTIFICATIONS_ERROR";
