import React, { PureComponent } from "react";
import { connect } from "react-redux";

import withStyles from "@mui/styles/withStyles";
import { Redirect } from "react-router-dom";
import { selectTab } from "../../services/campaign/actions";

import { changeAddUsersModal } from "../../services/campaign/actions";
import Tab from "./Tab";
import Snackbar from "./Snackbar";
import MyCampaigns from "./MyCampaigns";
import MyTemplates from "./MyTemplates";
import MyDrafts from "./MyDrafts";
import AdminTemplates from "./AdminTemplates";
import NewCampaignModal from "./NewCampaignModal";
import {
  getIsCoachingAccount,
  getIsEnterpriseAccount,
  getIsSuperAdminClientSelected,
} from "../../services/client/selectors";
import MyDoneCampaigns from "scenes/Campaigns/MyDoneCampaigns";
import { getDrafts } from "services/campaign/selectors";
import MyPremiumDemoCampaigns from "./MyPremiumDemoCampaigns";

const styles = theme => ({
  main: {
    flex: 1,
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 100,
  },
});

class ActionsScreen extends PureComponent {
  state = {
    isCreationModalVisible: false,
  };

  displayCreationModal = () => {
    this.setState({ isCreationModalVisible: true });
  };

  onCloseCreationModal = () => {
    this.setState({ isCreationModalVisible: false });
  };

  onCloseAddUsersModal = () => {
    this.props.changeAddUsersModal({
      isAddUsersModalVisible: false,
      hasPublishCampaignAnimation: false,
    });
  };

  componentDidMount() {
    this.props.selectTab(0);
  }

  render() {
    const {
      classes,
      drafts = [],
      isEnterpriseAccount,
      isCoachingAccount,
      tabSelected,
      isSuperAdminClientSelected,
    } = this.props;
    if (isCoachingAccount) return <Redirect to={"/my-coachees"} />;

    return (
      <div className={classes.main}>
        <Tab
          isEnterpriseAccount={isEnterpriseAccount}
          plan={this.props.plan}
          hasDraft={drafts.length > 0}
          onClickCreate={this.displayCreationModal}
        />
        <NewCampaignModal
          isVisible={this.state.isCreationModalVisible}
          onClose={this.onCloseCreationModal}
        />
        {isSuperAdminClientSelected && (
          <>
            {tabSelected === 0 && <MyPremiumDemoCampaigns />}
            {tabSelected === 1 && (
              <MyCampaigns displayCreationModal={this.displayCreationModal} />
            )}
            {tabSelected === 2 && <MyTemplates />}
            {tabSelected === 3 && <MyDoneCampaigns />}
            {tabSelected === 4 && <MyDrafts />}
            {tabSelected === 5 && <AdminTemplates />}
          </>
        )}

        {!isSuperAdminClientSelected && (
          <>
            {tabSelected === 0 && (
              <MyCampaigns displayCreationModal={this.displayCreationModal} />
            )}
            {tabSelected === 1 && <MyTemplates />}
            {tabSelected === 2 && <MyDoneCampaigns />}
            {tabSelected === 3 && <MyDrafts />}
            {tabSelected === 4 && <AdminTemplates />}
          </>
        )}
        <Snackbar />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { tabSelected } = state.campaign;
  const { plan } = state.client;
  const { role } = state.user;
  const isEnterpriseAccount = getIsEnterpriseAccount(state);
  const isCoachingAccount = getIsCoachingAccount(state);
  const isSuperAdminClientSelected = getIsSuperAdminClientSelected(state);
  return {
    tabSelected,
    isEnterpriseAccount,
    isCoachingAccount,
    isSuperAdminClientSelected,
    drafts: getDrafts(state),
    role,
    plan,
  };
};

export default connect(mapStateToProps, { changeAddUsersModal, selectTab })(
  withStyles(styles)(ActionsScreen),
);
