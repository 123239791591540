import {
  NOTIFICATION_MESSAGE_SEEN,
  NOTIFICATION_HOME_SEEN,
  NOTIFICATION_PULSE_CLEAR,
  NOTIFICATION_RANDOM_COFFEE_SEEN,
  NOTIFICATION_GENERAL_CLEAR,
  NOTIFICATION_GENERAL_GET_REQUESTING,
  NOTIFICATION_GENERAL_LOAD_REQUESTING,
  NOTIFICATION_CLICK,
  NOTIFICATION_TEAM_CLEAR,
  NOTIFICATION_ACHIEVEMENT_SEEN,
  NOTIFICATION_NEW_ACHIEVEMENT,
  NOTIFICATION_HISTORY_REQUESTING,
  ADD_NOTIFICATION_TO_FAVORITE_REQUESTING,
  GET_FAVORITE_NOTIFICATIONS_REQUESTING,
} from "./constants";

export const clearMessageNotifications = () => {
  return {
    type: NOTIFICATION_MESSAGE_SEEN,
  };
};

export const clearTeamNotifications = (payload: { campaignId: String }) => {
  return {
    type: NOTIFICATION_TEAM_CLEAR,
    payload,
  };
};

export const clearHomeNotifications = () => {
  return {
    type: NOTIFICATION_HOME_SEEN,
  };
};

export const clearPulseNotifications = (payload: { userId: String }) => {
  return {
    type: NOTIFICATION_PULSE_CLEAR,
    payload,
  };
};

export const clearGeneralNotifications = () => {
  return {
    type: NOTIFICATION_GENERAL_CLEAR,
  };
};

export const clearRandomCoffeeNotifications = () => {
  return {
    type: NOTIFICATION_RANDOM_COFFEE_SEEN,
  };
};

export const getNotificationsRequest = (payload: {
  userId: string;
  page: number;
}) => {
  return {
    type: NOTIFICATION_GENERAL_GET_REQUESTING,
    payload,
  };
};

export const getNotificationsHistoryRequest = (payload: {
  campaignId: string;
  page: number;
  numberByPage: number;
}) => {
  return { type: NOTIFICATION_HISTORY_REQUESTING, payload };
};

export const loadNotificationsRequest = payload => {
  return {
    type: NOTIFICATION_GENERAL_LOAD_REQUESTING,
    payload,
  };
};

export const clickNotification = payload => {
  return {
    type: NOTIFICATION_CLICK,
    payload,
  };
};

export const seeAchievement = () => {
  return {
    type: NOTIFICATION_ACHIEVEMENT_SEEN,
  };
};

export const newAchievement = achievement => {
  return {
    type: NOTIFICATION_NEW_ACHIEVEMENT,
    payload: achievement,
  };
};

export const addNotificationToFavoriteRequest = (payload: { notificationId: string, isFavorite: boolean, clientId: string }) => {
  return {
    type: ADD_NOTIFICATION_TO_FAVORITE_REQUESTING,
    payload,
  };
}

export const getFavoriteNotificationsRequest = (payload: { clientId: string }) => {
  return {
    type: GET_FAVORITE_NOTIFICATIONS_REQUESTING,
    payload,
  };
}