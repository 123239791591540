const fileExtensions = {
  aac: "audio",
  wav: "audio",
  jpeg: "image",
  jpg: "image",
  gif: "image",
  png: "image",
  webm: "video",
  mkv: "video",
  flv: "video",
  vob: "video",
  ogv: "video",
  ogg: "video",
  rrc: "video",
  gifv: "video",
  mng: "video",
  mov: "video",
  avi: "video",
  qt: "video",
  wmv: "video",
  yuv: "video",
  rm: "video",
  asf: "video",
  amv: "video",
  mp4: "video",
  m4p: "video",
  m4v: "video",
  mpg: "video",
  mp2: "video",
  mpeg: "video",
  mpe: "video",
  mpv: "video",
  svi: "video",
  "3gp": "video",
  "3g2": "video",
  mxf: "video",
  roq: "video",
  nsv: "video",
  f4v: "video",
  f4p: "video",
  f4a: "video",
  f4b: "video",
};

const getFileFormat = (fileType: string): string => {
  if (!fileType) return "";

  const fileSplit = fileType.split("/");

  if (!fileSplit.length) return "";

  const fileExtension = fileSplit[fileSplit.length - 1]?.toLowerCase();

  return fileExtensions[fileExtension] || "";
};

export default getFileFormat;
