//@flow
import { call, put, all, takeLatest, select } from "redux-saga/effects";

import {
  GET_MY_COACHEES_SUCCESS,
  CREATE_COACHEE_SUCCESS,
  CREATE_COACHEE_ERROR,
  GET_MY_COACHEES_REQUESTING,
  CREATE_COACHEE_REQUESTING,
  GET_MY_COACHEES_ERROR,
} from "./constants";

import { fetchApi } from "../api";
import { openSnackMessage } from "../general/actions";
import i18n from "../../i18n";
import {
  getSelectedClientId,
  getUser,
  getUserId,
} from "services/user/selectors";
import { getMyCoachees } from "services/myCoachees/selectors";
import { CREATE_COACHING_SESSION_SUCCESS } from "services/coaching/constants";

const endPoints = {
  getMyCoachees: "/v1/coaching/coachee/all",
  createCoachee: "/v1/coaching/coachee/create",
};

declare global {
  interface Window {
    Intercom: any;
  }
}

window.Intercom = window.Intercom || {};

const getMyCoacheesApi = data => {
  const { coachId } = data;
  return fetchApi(`${endPoints.getMyCoachees}?coachId=${coachId}`, {}, "get");
};

export const createCoacheeApi = data => {
  return fetchApi(endPoints.createCoachee, data, "post");
};

function* getMyCoacheesFlow(action) {
  try {
    const response = yield call(getMyCoacheesApi, action.payload);
    // yield put(
    //   openSnackMessage({ snackMessage: i18n.t("data-modified-success") })
    // );
    yield put({ type: GET_MY_COACHEES_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_MY_COACHEES_ERROR, payload: error });
  }
}

function* createCoacheeFlow(action) {
  try {
    const user = yield select(getUser);
    const myCoachees = yield select(getMyCoachees);
    const clientId = yield select(getSelectedClientId);
    const userId = yield select(getUserId);
    const coachName = `${user.firstName} ${user.lastName}`;
    const coachPicture = user.picture;

    const isFirstCoachee = myCoachees.length === 0;
    const response = yield call(createCoacheeApi, {
      ...action.payload,
      isTestAccount: isFirstCoachee,
      userId,
      coachName,
      coachPicture,
      clientId,
    });

    yield put({ type: CREATE_COACHEE_SUCCESS, payload: response });
    // First coachee creation
    console.log("COACHEE");
    console.log(myCoachees.length);
    if (myCoachees.length === 0) {
      // yield put(push("/my-coachees?product_tour_id=197091"));
      if (window.Intercom) {
        window.Intercom("startTour", 197091);
      }
    }
    yield put(
      openSnackMessage({ snackMessage: i18n.t("data-modified-success") }),
    );
  } catch (error) {
    yield put({ type: CREATE_COACHEE_ERROR, payload: error });
  }
}

function* userWatcher() {
  yield all([
    takeLatest(CREATE_COACHEE_REQUESTING, createCoacheeFlow),
    takeLatest(
      [GET_MY_COACHEES_REQUESTING, CREATE_COACHING_SESSION_SUCCESS],
      getMyCoacheesFlow,
    ),
  ]);
}

export default userWatcher;
