import React, { useCallback, useEffect, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import { Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SearchBar from "components/SearchBar";
import i18n from "i18n";
import Fuse from "fuse.js";
import Colors from "constants/Colors";
import ListItemLoader from "../ListItemLoader";
import PreviewSideBar from "./PreviewSideBar";
import { sortBy as lodashSortBy } from "lodash";
import { getAppLanguage } from "../../../../services/general/selectors";
import { connect, useDispatch, useSelector } from "react-redux";

import ActionRow from "./ActionRow";
import selectLanguage from "utils/selectLanguage";
import removeHtmlTags from "utils/removeHtmlTags";
import PreviewStep from "./PreviewStep";
import { makeStyles } from "@mui/styles";
import { getStepCommentsByStepId } from "services/campaign/actions";
import { getConsolidatedCampaignSteps } from "services/campaign/selectors";

const fuseOptions = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 80,
  maxPatternLength: 20,
  minMatchCharLength: 1,
  keys: ["title", "titleEn", "category"],
};

const useStyles = makeStyles(theme => ({
  title: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "29px",
    color: Colors.darkBlue,
    marginRight: 30,
  },
  firstRow: {
    display: "flex",
    flex: 2,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  row: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  tableHeaderRow: {
    boxSizing: "border-box",
    width: "100%",
    borderRadius: 8,
    padding: 15,
    paddingTop: 25,
    paddingBottom: 25,
    paddingLeft: 45,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  tableHeader: {
    color: Colors.darkBlue,
    fontSize: 16,
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    transition: "0.2s all ease",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  sortingRow: {
    cursor: "pointer",
  },
  box: {
    position: "relative",
    marginTop: 30,
    width: "100%",
    overflow: "hidden",
    backgroundColor: "#fff",
    borderRadius: 10,
  },
  topBar: {
    boxSizing: "border-box",
    paddingLeft: 45,
    paddingRight: 45,
    height: 90,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    borderBottom: `solid 1px ${Colors.lightGrey}`,
    backgroundColor: "#fff",
  },
  firstHeader: {
    "&:hover": {
      textDecoration: "none",
    },
  },
  topBarWithoutSearch: {
    paddingRight: 0,
  },
}));

const ActionsTable = ({
  tableTitle = i18n.t("done-actions"),
  doneSteps = [],
  isLoading = false,
  hasDateRow = false,
  hasComments = true,
  defaultSort = "totalDone",
  onClickRemove = null,
  className,
  isStepPreviewEditable = false,
  onClickStep = null,
  isCoachingActions = false,
  lastStepCreatedId = "",
  topRightComponent = null,
  hasSearch = true,
}) => {
  const [sortedBy, setSort] = useState(defaultSort);
  const [isSortedDesc, setSortDesc] = useState(true);
  const [search, setSearch] = useState("");
  const [stepPreview, setStepPreview] = useState({
    isPrivate: true,
    content: { title: {}, how: {}, why: {} },
    title: {},
    _id: "",
    stepId: "",
  });
  const [stepPreviewId, setStepPreviewId] = useState("");
  const [stepFiles, setStepFiles] = useState([]);
  const [comments, setComments] = useState([]);

  const classes = useStyles();

  const language = useSelector(getAppLanguage);

  const dispatch = useDispatch();

  const onClickRow = useCallback(
    (newStepPreview, comments, files) => () => {
      dispatch(getStepCommentsByStepId({ stepId: newStepPreview?._id }));
      setStepFiles(files);
      setStepPreview(newStepPreview);
      setComments(comments);
      setStepPreviewId(newStepPreview._id);

      if (onClickStep) {
        onClickStep(newStepPreview._id);
      }
    },
    [
      setStepPreview,
      setComments,
      setStepPreviewId,
      onClickStep,
      stepPreview?._id,
    ],
  );

  useEffect(() => {
    const { comments = [] } =
      searchResults?.find(
        el => el._id === stepPreviewId || el?.stepId?._id === stepPreviewId,
      ) || {};
    setComments(comments);
  }, [doneSteps]);

  const onChangeLastStepCreatedId = useCallback(() => {
    // For autoFocus when creating a new coaching action
    if (isCoachingActions && lastStepCreatedId) {
      const lastStep = doneSteps.find(el => el?._id === lastStepCreatedId);
      if (lastStep) {
        onClickRow(lastStep, lastStep.comments, lastStep.files)();
      }
    }
  }, [doneSteps, isCoachingActions, lastStepCreatedId, onClickRow]);

  useEffect(onChangeLastStepCreatedId, [lastStepCreatedId]);

  const onRemove = step => e => {
    e.stopPropagation();
    onClickRemove(step);
  };

  const changeSortingParam = param => () => {
    setSort(param);
    if (param === sortedBy) {
      setSortDesc(!isSortedDesc);
    }
  };

  const sortBy = steps => {
    if (isSortedDesc) {
      return lodashSortBy(steps, sortedBy).reverse();
    }

    return lodashSortBy(steps, sortedBy);
  };

  const displayArrow = param => {
    return sortedBy === param ? (
      isSortedDesc ? (
        <ExpandLessIcon />
      ) : (
        <ExpandMoreIcon />
      )
    ) : null;
  };

  const onChangeSearch = search => {
    setSearch(search);
  };

  const closePreview = () => {
    setStepPreviewId("");
  };

  const fuse = new Fuse(doneSteps, fuseOptions);
  const searchResults = search.length > 0 ? fuse.search(search) : doneSteps;

  return (
    <>
      {!isStepPreviewEditable ? (
        <PreviewStep
          stepPreviewId={stepPreviewId}
          stepPreview={stepPreview}
          comments={comments}
          isVisible={stepPreviewId !== ""}
          onClose={closePreview}
          files={stepFiles}
        />
      ) : (
        <PreviewSideBar
          isStepPreviewEditable={isStepPreviewEditable && stepPreview.isPrivate}
          stepPreviewIndex={parseInt(stepPreviewId)}
          stepPreview={stepPreview}
          comments={comments}
          isVisible={stepPreviewId !== ""}
          onClose={closePreview}
          files={stepFiles}
        />
      )}
      <div className={`${classes.box} ${className}`}>
        <div
          className={`${classes.topBar} ${
            !hasSearch && classes.topBarWithoutSearch
          }`}
        >
          <Typography className={classes.title}>{tableTitle}</Typography>
          {hasSearch && (
            <SearchBar
              placeholder={i18n.t("search-actions-placeholder")}
              onChangeSearch={onChangeSearch}
              value={search}
            />
          )}
          {topRightComponent}
        </div>
        <div className={classes.tableHeaderRow}>
          <div className={classes.firstRow}>
            <Typography
              className={`${classes.tableHeader} ${classes.firstHeader}`}
            >
              {i18n.t("action-table-header-title")}
            </Typography>
          </div>
          <div
            className={`${classes.row} ${classes.sortingRow}`}
            onClick={changeSortingParam("category")}
            style={{ justifyContent: "flex-start" }}
          >
            <Typography className={classes.tableHeader}>
              {i18n.t("action-table-header-category")}
              {displayArrow("category")}
            </Typography>
          </div>
          <div
            className={`${classes.row} ${classes.sortingRow}`}
            onClick={changeSortingParam("totalDone")}
          >
            <Typography className={classes.tableHeader}>
              {i18n.t("action-table-header-done")}
              {displayArrow("totalDone")}
            </Typography>
          </div>
          <div
            className={`${classes.row} ${classes.sortingRow}`}
            onClick={changeSortingParam("views")}
          >
            <Typography className={classes.tableHeader}>
              {i18n.t("action-table-header-views")}
              {displayArrow("views")}
            </Typography>
          </div>
          <div
            className={`${classes.row} ${classes.sortingRow}`}
            onClick={changeSortingParam("rating")}
          >
            <Typography className={classes.tableHeader}>
              {i18n.t("action-table-header-rating")}
              {displayArrow("rating")}
            </Typography>
          </div>
          <div
            className={`${classes.row} ${classes.sortingRow}`}
            onClick={changeSortingParam("donePercentage")}
          >
            <Typography className={classes.tableHeader}>
              {i18n.t("action-table-header-donePercentage")}
              {displayArrow("donePercentage")}
            </Typography>
          </div>
          {hasComments && (
            <div
              className={`${classes.row} ${classes.sortingRow}`}
              onClick={changeSortingParam("totalComments")}
            >
              <Typography className={classes.tableHeader}>
                {i18n.t("action-table-header-comment")}
                {displayArrow("totalComments")}
              </Typography>
            </div>
          )}
          {hasDateRow && (
            <div
              className={`${classes.row} ${classes.sortingRow}`}
              onClick={changeSortingParam("createdAt")}
            >
              <Typography className={classes.tableHeader}>
                {i18n.t("action-table-header-createdAt")}
                {displayArrow("createdAt")}
              </Typography>
            </div>
          )}
        </div>
        {doneSteps.length < 1 && isLoading && <ListItemLoader />}
        {sortBy(searchResults).map((el, index) => {
          const { title } = el;
          const isStepSelected = el?.stepId?._id === stepPreviewId;

          return (
            <ActionRow
              hasComments
              hasDateRow={hasDateRow}
              isStepSelected={isStepSelected}
              step={el}
              onRemove={onClickRemove ? onRemove : null}
              title={
                title
                  ? title
                  : removeHtmlTags(
                      selectLanguage({
                        text: el?.stepId?.content?.title,
                        language,
                      }),
                    )
              }
              key={index}
              onClickRow={onClickRow}
            />
          );
        })}
      </div>
    </>
  );
};

export default ActionsTable;
