import { put, takeLatest, all } from "redux-saga/effects";

import { SEND_MOBILE_NAVIGATION_EVENT } from "./constants";

import i18n from "i18n";
import socket from "services/socket";
import { openSnackMessage } from "services/general/actions";

/*const endPoints = {
  getOnboardingStats: "/v1/clients/onboarding-stats/",
};*/

function* sendMobileNavigationEvent(action) {
  try {
    socket.emit("mobile-connection-navigate", action.payload);
    yield put(openSnackMessage({ snackMessage: i18n.t("request-sent") }));
  } catch (error) {
    console.log({ error });
  }
}

function* Saga() {
  yield all([
    takeLatest(SEND_MOBILE_NAVIGATION_EVENT, sendMobileNavigationEvent),
  ]);
}

export default Saga;
