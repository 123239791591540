import { createSelector } from "reselect";
import {
  getCampaignOnboardingSurveyId,
  getCampaignSelectedEvolutionSurveyId,
  getUserCampaigns,
} from "services/campaign/selectors";
import { getAppLanguage } from "services/general/selectors";
import { sumBy } from "lodash";
import selectLanguage from "utils/selectLanguage";
import moment from "moment";

const getCampaignParticipants = state =>
  state.campaign.campaignSelected?.participants || [];

export const getSelectedQuestion = state => {
  return state.survey?.questions?.length
    ? state.survey?.questions[state.survey.questionSelectedIndex]
    : {};
};

export const getSurveys = state => state.survey?.surveys || [];

export const getSurveyRequesting = state => state.survey.requesting;

export const getCampaignSurveysAnswers = state =>
  state.survey?.surveysByCampaign?.surveyAnswers || [];

export const getSurveyLanguage = state => {
  return state.survey?.languageSelected;
};

export const getSurveysByCampaign = state => {
  return state?.survey?.surveysByCampaign || { surveys: [] };
};

export const getCampaignSurveys = createSelector(
  getSurveysByCampaign,
  getCampaignParticipants,
  (surveysByCampaign, participants) => {
    // const results = {};
    surveysByCampaign?.surveys?.map(survey => {
      const surveyId = survey._id;
      const surveyQuestions = survey.questions;

      // we filter by existing surveyId && existing participants
      const answerBySurvey = surveysByCampaign?.surveyAnswers.filter(
        answer =>
          answer?.surveyId === surveyId &&
          participants.find(el => el === answer.userId),
      );

      // we group all answers by question
      surveyQuestions.map(question => {
        question.answers = answerBySurvey.reduce((prev, curr) => {
          const answer = curr.answers.find(
            el => el.questionId === question._id,
          );
          if (answer) {
            prev.push(answer);
          }
          return prev;
        }, []);
        return question;
      });
      return survey;
    });

    return surveysByCampaign?.surveys || [];
  },
);

export const getCampaignOnboardingSurvey = createSelector(
  getCampaignOnboardingSurveyId,
  getSurveysByCampaign,
  getCampaignParticipants,
  (surveyId, surveysByCampaign, participants) => {
    if (!surveysByCampaign?.surveys?.length) return {};
    const survey = {
      ...(surveysByCampaign.surveys.find(el => el._id === surveyId) || {}),
    };

    if (!survey.questions) return survey;

    const surveyQuestions = survey.questions;

    // we filter by existing surveyId && existing participants
    const answerBySurvey = surveysByCampaign.surveyAnswers.filter(
      answer =>
        answer.surveyId === surveyId &&
        participants.find(el => el === answer.userId),
    );

    // we group all answers by question
    surveyQuestions.map(question => {
      question.answers = answerBySurvey.reduce((prev, curr) => {
        const answer = curr.answers.find(el => el.questionId === question._id);
        if (answer) {
          prev.push(answer);
        }
        return prev;
      }, []);
      return question;
    });

    return survey;
  },
);

export const getCampaignOnboardingSurveyStats = createSelector(
  getCampaignOnboardingSurvey,
  getAppLanguage,
  (survey, language) => {
    const stats = {};
    const topicChoices = [];
    const feelingEvolution = [];

    if (!survey?.questions?.length)
      return {
        stats: [{}],
        topicChoices,
        feelingEvolution: [{ date: moment().toDate() }],
      };

    survey.questions.map(question => {
      const { questionType, answers, questionLabel, questionTitle } = question;
      if (questionType === "RATING") {
        if (answers.length) {
          const value =
            selectLanguage({ text: questionLabel, language }) ||
            selectLanguage({ text: questionTitle, language });

          stats[value] = sumBy(answers, "value") / answers.length;
        }
      } else if (questionType === "MULTIPLE_CHOICES") {
        answers.map(answer => {
          answer.choices.map(choice => {
            topicChoices.push({
              title: choice,
              value: selectLanguage({ text: choice, language }),
            });
            return choice;
          });
          return answer;
        });
      }
      return question;
    });

    const feelingEvolutionDefault = { date: moment().toDate() };
    Object.keys(stats).map(key => {
      feelingEvolutionDefault[key] = 0;
      return feelingEvolutionDefault;
    });

    feelingEvolution.push(feelingEvolutionDefault);
    return { stats: [stats], topicChoices, feelingEvolution };
  },
);

export const getFeelingEvolutionSurvey = createSelector(
  getCampaignSelectedEvolutionSurveyId,
  getSurveysByCampaign,
  (surveyId, surveysByCampaign) => {
    if (!surveysByCampaign?.surveys?.length) return {};
    const survey = {
      ...(surveysByCampaign.surveys.find(el => el._id === surveyId) || {}),
    };

    if (!survey.questions) return survey;

    const surveyQuestions = survey.questions;

    // we filter by existing surveyId && existing participants
    const answerBySurvey = surveysByCampaign.surveyAnswers.filter(
      answer => answer.surveyId === surveyId,
    );

    // we group all answers by question
    surveyQuestions.map(question => {
      question.answers = answerBySurvey.reduce((prev, curr) => {
        const answer = curr.answers.find(el => el.questionId === question._id);
        if (answer) {
          prev.push(answer);
        }
        return prev;
      }, []);
      return question;
    });

    return survey;
  },
);

export const getFeelingEvolutionSurveyStats = createSelector(
  getFeelingEvolutionSurvey,
  getCampaignOnboardingSurveyStats,
  getAppLanguage,
  (survey, onboardingSurveyStats, language) => {
    const stats = onboardingSurveyStats.stats[0];

    const feelingEvolution = { ...stats, date: moment().toDate() };

    Object.keys(stats).map(key => {
      feelingEvolution[key] = 0;
      return feelingEvolution;
    });

    if (!survey?.questions?.length) return [feelingEvolution];

    survey.questions.map(question => {
      const { questionType, answers, questionLabel, questionTitle } = question;
      if (questionType === "RATING") {
        if (answers.length) {
          const value =
            selectLanguage({ text: questionLabel, language }) ||
            selectLanguage({ text: questionTitle, language });

          feelingEvolution[value] = sumBy(answers, "value") / answers.length;
        }
      }
      return question;
    });

    Object.keys(stats).map(key => {
      feelingEvolution[key] =
        ((feelingEvolution[key] - stats[key]) * 100) / stats[key];
      return true;
    });

    return [feelingEvolution];
  },
);

export const getCampaignSurveyStatsForExportBtoC = createSelector(
  getCampaignSurveysAnswers,
  getCampaignSurveys,
  getUserCampaigns,
  (surveysAnswers, campaignSurveys, userCampaigns) => {
    const survey = campaignSurveys?.[0] || {};

    const finalUserCampaigns = userCampaigns.map(userCampaign => {
      const result = { ...userCampaign };
      result.surveyAnswers = surveysAnswers?.find(
        el => el.userId === userCampaign.userId && el.surveyId === survey._id,
      )?.answers;

      return result;
    });

    return finalUserCampaigns;
  },
);
