import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import withStyles from "@mui/styles/withStyles";
import i18n from "../i18n";
import { Loader } from "./Loader";
import { Typography } from "@mui/material";
import FlatButton from "components/FlatButton";
import UploaderIcon from "components/UploaderIcon";
import Colors from "constants/Colors";
import UnsplashLibraryModal from "components/UnsplashLibraryModal";

const styles = theme => ({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  thumbsContainer: {
    objectFit: "cover",
    borderRadius: 8,
    pointerEvents: "none",
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  thumb: {
    display: "inline-flex",
    borderRadius: 8,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 250,
    height: 400,
    padding: 4,
    boxSizing: "border-box",
  },
  thumbInner: {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  },
  img: {
    objectFit: "cover",
    borderRadius: 8,
    display: "block",
    width: "100%",
    height: "100%",
  },
  dropzone: {
    cursor: "pointer",
    width: 250,
    height: 400,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    borderWidth: "2px",
    borderRadius: 8,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: Colors.veryLightGrey,
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    "&:focus": {
      borderColor: "#2196f3",
    },
  },
  placeholderDiv: {
    transition: "all 0.2s ease",
    zIndex: 100,
    backgroundColor: "transparent",
    width: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  dropImage: {
    marginTop: 10,
    textAlign: "center",
    fontWeight: "bold",
    color: "#535461",
  },
  placeholder: {
    textAlign: "center",
    fontSize: 12,
    color: "#535461",
  },
  or: {
    marginTop: 15,
    marginBottom: 15,
    textAlign: "center",
    color: "#535461",
  },
  importImage: {
    color: Colors.pureMain,
    fontWeight: "bold",
    textDecoration: "underline",
  },
  loader: {
    zIndex: 5,
  },
  unsplashButton: {
    fontSize: 14,
    padding: "7px 9px",
    fontWeight: "normal",
    marginTop: 15,
  },
  placeholderHovered: {
    borderRadius: 8,
    backgroundColor: Colors.white,
    height: "100%",
    width: "100%",
  },
  placeholderSmall: {
    borderRadius: 8,
    height: 40,
    width: 40,
  },
});

function Previews(props) {
  const {
    classes,
    className,
    imageClassName,
    onFileUploaded,
    onClickUnsplashPicture,
    uploading,
    picture,
    width,
    height,
    borderRadius = 8,
    placeholder,
    hasUnsplash = false,
    isSmall = false,
  } = props;
  const [isHovered, setHover] = useState(false);
  const [files, setFiles] = useState([]);
  const [isUnsplashVisible, openUnsplash] = useState(false);

  const openUnsplashModal = () => openUnsplash(true);
  const closeUnsplashModal = () => openUnsplash(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
    onDrop: acceptedFiles => {
      onFileUploaded(acceptedFiles);
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  const renderPicture = picture => {
    if (picture) {
      return (
        <div className={classes.thumb} style={{ width, height, borderRadius }}>
          <div className={classes.thumbInner}>
            <img
              src={picture}
              className={`${classes.img} ${imageClassName}`}
              alt=""
              style={{ borderRadius }}
            />
          </div>
        </div>
      );
    } else {
      return thumbs;
    }
  };

  const thumbs = files.map(file => (
    <div className={classes.thumb} style={{ width, height }} key={file.name}>
      <div className={classes.thumbInner}>
        <img
          src={file.preview}
          className={`${classes.img} ${imageClassName}`}
          alt=""
        />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  return (
    <section className={`${classes.container} ${className}`}>
      {hasUnsplash && (
        <UnsplashLibraryModal
          onClickPicture={onClickUnsplashPicture}
          isVisible={isUnsplashVisible}
          onClose={closeUnsplashModal}
        />
      )}
      <div
        onMouseLeave={() => setHover(false)}
        onMouseEnter={() => setHover(true)}
        {...getRootProps({ className: "dropzone" })}
        className={classes.dropzone}
        style={{ width, height, borderRadius }}
      >
        <input {...getInputProps()} />
        {uploading ? (
          <div className={classes.loader}>
            <Loader size={50} />
          </div>
        ) : (
          (isHovered || !picture) && (
            <div
              className={`${classes.placeholderDiv} ${
                isHovered && classes.placeholderHovered
              } ${isSmall && classes.placeholderSmall}
              }`}
            >
              <UploaderIcon />
              {!isSmall && (
                <>
                  <Typography className={classes.dropImage}>
                    {i18n.t("drop-image")}{" "}
                  </Typography>
                  <Typography className={classes.placeholder}>
                    {placeholder}
                  </Typography>
                  <Typography className={classes.or}>{i18n.t("or")}</Typography>
                  <Typography className={classes.importImage}>
                    {i18n.t("import-image")}
                  </Typography>
                </>
              )}
            </div>
          )
        )}
      </div>
      <aside className={classes.thumbsContainer} style={{ borderRadius }}>
        {renderPicture(picture)}
      </aside>
      {hasUnsplash && (
        <FlatButton
          className={classes.unsplashButton}
          onClick={openUnsplashModal}
          title={i18n.t("import-from-library")}
        />
      )}
    </section>
  );
}

Previews.defaultProps = {
  width: 400,
  height: 250,
  placeholder: i18n.t("upload-picture-text"),
};

export default withStyles(styles)(Previews);
