import {
  CHANGE_FIELD,
  SIGNUP_CREATE_USER_REQUESTING,
  SIGNUP_CREATE_USER_ERROR,
  SIGNUP_CREATE_USER_SUCCESS,
  SIGNUP_CREATE_CLIENT_ERROR,
  SIGNUP_CREATE_CLIENT_REQUESTING,
  SIGNUP_CREATE_CLIENT_SUCCESS,
  SIGNUP_SEND_VERIFICATION_EMAIL_REQUESTING,
  SIGNUP_SEND_VERIFICATION_EMAIL_ERROR,
  SIGNUP_SEND_VERIFICATION_EMAIL_SUCCESS,
  SIGNUP_CLOSE_SNACK_MESSAGE,
  SIGNUP_CONFIRM_EMAIL_REQUESTING,
  SIGNUP_CONFIRM_EMAIL_SUCCESS,
  SIGNUP_CONFIRM_EMAIL_ERROR,
  INVITATION_CREATE_USER_REQUESTING,
  INVITATION_CREATE_USER_ERROR,
  INVITATION_CREATE_USER_SUCCESS,
  SIGNUP_ONBOARDING_END_REQUESTING,
  SIGNUP_ONBOARDING_END_ERROR,
  SIGNUP_ONBOARDING_END_SUCCESS,
} from "./constants";

import { RESET_PASSWORD_SUCCESS } from "../auth/constants";

import { AUTH_LOGOUT_SUCCESS } from "../auth/reducer";
import i18n from "../../i18n";

const initialState = {
  step: 0,
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  organization: "",
  companySize: "",
  password: "",
  confirmPassword: "",
  logo: "",
  source: "", // 360Learning etc.
  requesting: false,
  loading: false,
  error: "",
  loginCode: "",
  invitationVerificationToken: "",
  clientName: "",
  snackMessage: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_ONBOARDING_END_ERROR:
    case INVITATION_CREATE_USER_ERROR:
    case SIGNUP_CONFIRM_EMAIL_ERROR:
    case SIGNUP_SEND_VERIFICATION_EMAIL_ERROR:
    case SIGNUP_CREATE_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        requesting: false,
      };

    case SIGNUP_SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        error: "",
        requesting: false,
        snackMessage: i18n.t("email-sent-successfully"),
      };

    case SIGNUP_CREATE_CLIENT_REQUESTING:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case SIGNUP_CREATE_CLIENT_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case SIGNUP_CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case SIGNUP_CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        requesting: false,
        snackMessage: i18n.t("email-confirmed"),
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        snackMessage: i18n.t("reset-email-sent"),
      };

    case INVITATION_CREATE_USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        requesting: false,
        step: 1,
      };

    case SIGNUP_CREATE_USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        requesting: false,
        snackMessage: i18n.t("email-sent-successfully"),
        step: 1,
      };

    case SIGNUP_ONBOARDING_END_REQUESTING:
    case INVITATION_CREATE_USER_REQUESTING:
    case SIGNUP_CONFIRM_EMAIL_REQUESTING:
    case SIGNUP_SEND_VERIFICATION_EMAIL_REQUESTING:
    case SIGNUP_CREATE_USER_REQUESTING:
      return {
        ...state,
        error: "",
        requesting: true,
      };

    case SIGNUP_ONBOARDING_END_SUCCESS: {
      return {
        ...initialState,
        error: "",
        requesting: false,
      };
    }

    case SIGNUP_CLOSE_SNACK_MESSAGE:
      return {
        ...state,
        snackMessage: null,
      };

    case CHANGE_FIELD: {
      return { ...state, ...action.payload };
    }

    case AUTH_LOGOUT_SUCCESS: {
      return initialState;
    }

    default:
      return state;
  }
};

export default reducer;
