import React from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography } from "@mui/material";
import i18n from "i18n";

const styles = theme => ({
  main: {
    position: "relative",
    width: 250,
    height: 400,
    marginBottom: 25,
    borderRadius: 8,
  },
  image: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    borderRadius: 8,
  },
  details: {
    borderRadius: 8,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",

    width: "100%",
    top: 0,
    height: "100%",
    opacity: 0,
    zIndex: 100,
    position: "absolute",
    transition: "all 100ms ease",
    //  transition: "all 0.2s cubic-bezier(0.41, 0.094, 0.54, 0.07) 0s",
    "&:hover": {
      opacity: 1,
      backgroundColor: "rgba(31, 31, 31, 0.8)",
    },
  },
  text: {
    fontSize: 14,
    color: "#fff",
    fontWeight: "bold",
    fontFamily: "Montserrat",
  },
  name: {
    color: "#fff",
    fontSize: 12,
    position: "absolute",
    textAlign: "center",
    bottom: 15,
  },
  link: {
    color: "#fff",
    fontSize: 12,
    textDecoration: "underline",
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": { opacity: 0.9 },
  },
});

const PortraitPhoto = ({
  classes,
  url = "https://images.unsplash.com/photo-1416339306562-f3d12fefd36f?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&s=92f3e02f63678acc8416d044e189f515",
  user = { profile_image: { small: "" } },
  alt,
  onClick,
}) => {
  const { name, links = {} } = user;
  return (
    <div className={classes.main} onClick={onClick}>
      <img src={url} className={classes.image} alt={alt} />
      <div className={classes.details}>
        <Typography className={classes.text}>
          {i18n.t("select-this-image")}
        </Typography>
        <Typography className={classes.name}>
          {i18n.t("photo-by")}
          <a
            className={classes.link}
            href={`${links.html}?utm_source=microdoing&utm_medium=referral`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {name}
          </a>
        </Typography>
      </div>
    </div>
  );
};

export default withStyles(styles)(PortraitPhoto);
