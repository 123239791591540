import {
  CLIENT_GET_REQUESTING,
  CLIENT_GET_SUCCESS,
  CLIENT_GET_RANKING_REQUESTING,
  CLIENT_CHANGE_FIELD,
  CLIENT_MODIFY_REQUESTING,
} from "./constants";

export const getClientRequest = (payload: { clientId: string }) => {
  return {
    type: CLIENT_GET_REQUESTING,
    payload,
  };
};

export const modifyClientRequest = (payload: { clientId: string }) => {
  return {
    type: CLIENT_MODIFY_REQUESTING,
    payload,
  };
};

export const setClient = payload => {
  return {
    type: CLIENT_GET_SUCCESS,
    payload,
  };
};

export const getRankingRequest = (payload: {
  clientId: string;
  userId: string;
}) => {
  return {
    type: CLIENT_GET_RANKING_REQUESTING,
    payload,
  };
};

export const changeField = payload => {
  return {
    type: CLIENT_CHANGE_FIELD,
    payload,
  };
};
