const audioExtensions = {
  aac: true,
  wav: true
};

const hasAudioExtension = (fileName: string): boolean => {
  if (!fileName) return false;

  const fileSplit = fileName.split(".");

  if (!fileSplit.length) return false;

  const fileExtension = fileSplit[fileSplit.length - 1]?.toLowerCase();

  return audioExtensions[fileExtension] || false;
};

export default hasAudioExtension;
