import React from "react";
import withStyles from '@mui/styles/withStyles';
import Modal from "components/Modal";
import Colors from "constants/Colors";
import i18n from "i18n";
import { Typography } from "@mui/material";
import FlatButton from "components/FlatButton";
import selectLanguage from "utils/selectLanguage";
import { getSurveyRequesting } from "services/survey/selectors";
import { useSelector } from "react-redux";

const styles = theme => ({
  main: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  modal: {
    minHeight: 0,
  },
  text: {
    color: Colors.darkBlue,
    fontSize: 16,
    width: "100%",
    textAlign: "left",
  },
  button: {
    alignSelf: "flex-end",
    marginTop: 25,
    marginBottom: 15,
  },
  middleDiv: {
    width: "100%",
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  surveyDiv: {
    marginTop: 15,
    alignSelf: "center",
    backgroundColor: Colors.veryLightMain,
    borderRadius: 4,
    padding: "3px 5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  surveyTitle: {
    fontSize: 20,
    textAlign: "center",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    color: Colors.pureMain,
  },
  explanation: {
    marginTop: 35,
    color: Colors.darkBlue,
  },
  bold: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    color: Colors.darkBlue,
    fontSize: 22,
  },
});

const SendNewSurveyModal = ({
  classes,
  isVisible,
  onClose,
  surveySelected,
  onSendNewSurvey,
  participants,
  language,
}) => {
  const { title } = surveySelected;
  const requesting = useSelector(getSurveyRequesting);

  return (
    <Modal
      className={classes.modal}
      isVisible={isVisible}
      onClose={onClose}
      title={i18n.t("send-new-survey")}
    >
      <div className={classes.main}>
        <Typography className={classes.text}>
          {i18n.t("do-you-want-to-send")}
        </Typography>
        <div className={classes.middleDiv}>
          <div className={classes.surveyDiv}>
            <Typography className={classes.surveyTitle}>
              {selectLanguage({ text: title, language })}
            </Typography>
          </div>
        </div>
        <Typography className={classes.explanation}>
          <span className={classes.bold}>{participants.length} </span>
          {i18n.t("send-survey-explanation")}
        </Typography>
        <FlatButton
          title={i18n.t("send-survey")}
          className={classes.button}
          onClick={onSendNewSurvey}
          requesting={requesting}
          fullWidth
        />
      </div>
    </Modal>
  );
};

export default withStyles(styles)(SendNewSurveyModal);
