import React from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography } from "@mui/material";
import i18n from "../../i18n";
import MoreIcon from "../Campaigns/MoreIcon";
import Colors from "../../constants/Colors";

const styles = theme => ({
  button: {
    position: "relative",
    cursor: "pointer",
    color: "white",
    borderRadius: 4,
    padding: "8px 14px 8px 14px",
    backgroundColor: Colors.pureMain,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 0.2s",
    "&:hover": {
      opacity: 0.8,
    },
  },
  buttonTitle: {
    fontSize: 14,
    color: "inherit",
    marginLeft: 10,
  },
  "@media (max-width: 1100px)": {
    fontSize: 12,
  },
});

const NewButton = ({ classes, onClick }) => {
  return (
    <div className={classes.button} onClick={onClick}>
      <MoreIcon />
      <Typography className={classes.buttonTitle}>{i18n.t("new")}</Typography>
    </div>
  );
};

export default withStyles(styles)(NewButton);
