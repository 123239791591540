import React from "react";

const EmailIcon = ({ sizePercentage, color, className }) => (
  <svg
    className={className}
    width={sizePercentage * 35}
    height={sizePercentage * 25}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 35 25"
    version="1.1"
    fill="none"
  >
    {/* <path
      d="M1.35059 0.943848L17.6566 12.2891L33.9627 0.943848M1.35059 23.6344H33.9627V0.943848H1.35059V23.6344Z"
      fill={color}
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    /> */}
    <path
      d="M1.35059 0.943848L17.6566 12.2891L33.9627 0.943848M1.35059 23.6344H33.9627V0.943848H1.35059V23.6344Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

EmailIcon.defaultProps = {
  sizePercentage: 1,
  color: "#00A7FC",
  className: {},
};

export default EmailIcon;
