import React, { useCallback, useEffect, useRef, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import { TextField, Typography } from "@mui/material";
import debounce from "lodash/debounce";
import i18n from "i18n";
import Colors from "constants/Colors";
import DeleteConfirmModal from "components/DeleteConfirmModal";
import DeleteIcon from "scenes/Campaigns/DeleteIcon";
import {
  updateCoachingNoteRequest,
  deleteCoachingNoteRequest,
} from "services/coaching/actions";
import { connect } from "react-redux";
import CloseIcon from "scenes/CreateTrack/Skills/CloseIcon";
import EditableInput from "scenes/CreateTrack/CreateActionTextStep/EditableInput";

const styles = theme => ({
  main: {
    opacity: 0,
    zIndex: -10,
    backgroundColor: "#fff",
    width: 0,
    height: "100%",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    boxShadow: "-9px 4px 12px rgba(197, 178, 178, 0.14);",
    transition: "all 0.350s ease",
    display: "flex",
    flexDirection: "column",
    overflow: "scroll",
    paddingLeft: 15,
    paddingBottom: 50,
  },
  visible: {
    zIndex: 10,
    minWidth: 550,
    width: "50vw",
    opacity: 1,
    overflow: "auto",
  },
  topButtons: {
    zIndex: 50,
    position: "fixed",
    top: -200,
    right: -200,
    opacity: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  close: {},
  buttonVisible: {
    top: 20,
    right: 20,
    opacity: 1,
  },
  body: {
    position: "relative",
    paddingLeft: 45,
    paddingRight: 45,
    height: "100%",
    zIndex: 1,
  },
  title: {
    height: "auto",
    paddingRight: 45,
    textAlign: "left",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    color: Colors.darkBlue,
    fontSize: 28,
    lineHeight: "32px",
    marginBottom: 10,
  },
  buttonText: {
    fontSize: 16,
    marginLeft: 8,
    color: "white",
  },
  delete: {
    marginTop: 16,
    position: "absolute",
    top: 0,
    right: 15,
    backgroundColor: "#fff",
    color: Colors.red,
    border: "solid",
    borderWidth: 1,
    borderColor: "#fff",
    fontSize: 13,
    "&:hover": {
      backgroundColor: "#fff",
      borderWidth: 1,
      borderColor: Colors.red,
    },
    "&:focus": {
      backgroundColor: "#fff",
      borderColor: Colors.red,
      borderWidth: 1,
    },
  },
  savingText: {
    fontFamily: "Roboto",
    marginRight: 20,
    color: "#5F5F5F",
    fontStyle: "italic",
  },
  date: {
    paddingLeft: 45,
    paddingRight: 45,
    marginTop: 75,
    fontFamily: "Roboto",
    color: "#5F5F5F",
    fontSize: 12,
  },
  notePrivacy: {
    textAlign: "left",
    paddingLeft: 45,
    marginTop: 20,
    color: "#ADADAD",
    fontSize: 14,
  },
  noteInput: {
    marginBottom: 25,
  },
});

const NoteSideBar = ({
  classes,
  onClose = null,
  isVisible = true,
  content = "",
  onClickDelete = false,
  saving = false,
  title = "",
  createdAt = new Date(),
  _id = "",
  coachingNoteId,
  updateCoachingNoteRequest,
  deleteCoachingNoteRequest,
}) => {
  // necessary for a modal getting argument updated
  const coachingNoteIdRef = useRef(coachingNoteId);

  const [isDeleteModalOpen, openDeleteModal] = useState(false);
  const [titleText, setTitleText] = useState(title);
  const [noteContent, setNoteContent] = useState(content);

  const delayedQuery = useCallback(
    debounce((coachingNoteId, titleText, content) => {
      if (coachingNoteId) {
        updateCoachingNoteRequest({
          coachingNoteId,
          title: titleText,
          content,
        });
      }
    }, 250),
    [],
  );

  useEffect(() => {
    setTitleText(title);
    setNoteContent(content);
    coachingNoteIdRef.current = coachingNoteId;
  }, [coachingNoteId, title, content]);

  const closeDeleteModal = () => openDeleteModal(false);
  const clickDeleteButton = () => openDeleteModal(true);

  const onClickConfirmDelete = coachingNoteId => () => {
    deleteCoachingNoteRequest({ coachingNoteId });
    closeDeleteModal();
  };

  const onChangeTitle = e => {
    const value = e.target.value;
    setTitleText(value);

    delayedQuery(coachingNoteId, value, noteContent);
  };

  const onChangeContent = content => {
    setNoteContent(content);
    delayedQuery(_id, titleText, content);
  };

  return (
    <div className={`${classes.main} ${isVisible && classes.visible}`}>
      <DeleteConfirmModal
        onClose={closeDeleteModal}
        isVisible={isDeleteModalOpen}
        title={i18n.t("delete-confirmation-modal-title")}
        onClickConfirm={onClickConfirmDelete(coachingNoteIdRef.current)}
      />
      <div
        className={`${classes.topButtons} ${
          isVisible && classes.buttonVisible
        }`}
      >
        <Typography className={classes.savingText}>
          {saving ? i18n.t("saving") : i18n.t("saved")}
        </Typography>

        <IconButton className={classes.close} onClick={onClose} size="large">
          <CloseIcon sizePercentage={2} />
        </IconButton>
      </div>
      <Typography className={classes.date}>
        {moment(createdAt).format("L")}
      </Typography>
      <Typography className={classes.notePrivacy}>
        {i18n.t("note-privacy")}
      </Typography>
      <div className={classes.body}>
        <IconButton
          className={classes.delete}
          onClick={clickDeleteButton}
          size="large"
        >
          <DeleteIcon color={Colors.blackGrey} />
        </IconButton>
        <TextField
          variant="standard"
          InputProps={{
            disableunderline: "true",
            classes: {
              input: classes.title,
            },
          }}
          autoFocus={true}
          fullWidth
          title={i18n.t("note-title")}
          placeholder={i18n.t("note-title")}
          value={titleText}
          onChange={onChangeTitle}
          margin="normal"
        />
        <EditableInput
          className={classes.noteInput}
          hasFocusClass={false}
          onChange={onChangeContent}
          value={content}
          placeholder={i18n.t("note-placeholder")}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    saving: state.coaching.noteRequesting,
  };
};

export default connect(mapStateToProps, {
  updateCoachingNoteRequest,
  deleteCoachingNoteRequest,
})(withStyles(styles)(NoteSideBar));
