import React from "react";
import { makeStyles } from "@mui/styles";
import { MainText } from "./MainText";

const useStyles = makeStyles(theme => ({
  main: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },
  pointer: {
    cursor: "pointer",
  },
}));

interface TextProps {
  text: string;
  style?: {};
  size?: "extraSmall" | "small" | "medium" | "large" | "extraLarge";
  isBold?: boolean;
  textClassName?: string;
  onClick: () => void;
  isLeft?: boolean;
  icon: React.ReactNode;
  className?: string;
}

const MainTextWithIcon = ({
  text,
  style,
  size = "medium",
  isBold = false,
  textClassName = "",
  onClick = () => {},
  isLeft = false,
  icon,
  className = "",
}: TextProps) => {
  const classes = useStyles();

  return (
    <div
      className={`${classes.pointer} ${classes.main} ${className}`}
      onClick={onClick}
    >
      {isLeft && icon}
      <MainText
        text={text}
        style={style}
        size={size}
        isBold={isBold}
        className={textClassName}
      />
      {!isLeft && icon}
    </div>
  );
};

export { MainTextWithIcon };
