import React, { useState } from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography } from "@mui/material";
import Colors from "constants/Colors";
import DeleteIcon from "scenes/Campaigns/DeleteIcon";

const styles: any = theme => ({
  main: {
    marginRight: 10,
    borderRadius: 25,
    marginBottom: 10,
    padding: "5px 15px",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: "normal",
    color: Colors.darkBlue,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    cursor: "pointer",
    transition: "all 0.2s ease",
    backgroundColor: Colors.mediumGrey,
    "&:hover": {
      color: "#fff",
      backgroundColor: Colors.pureMain,
    },
  },
  title: {
    fontSize: "inherit",
    fontFamily: "inherit",
    color: "inherit",
    fontWeight: "inherit",
  },
  selected: {
    fontWeight: "bold",
    color: "#fff",
    cursor: "default",
    backgroundColor: Colors.pureMain,
    "&:hover": {
      backgroundColor: Colors.pureMain,
    },
  },
  delete: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 5,
    marginRight: -5,
    cursor: "pointer",
    transition: "all 0.2s ease",
    width: 25,
    height: 25,
    borderRadius: 12.5,
    border: "1px solid",
    borderColor: Colors.pureMain,
    "&:hover": {
      borderColor: Colors.red,
    },
  },
});

interface SurveyParams {
  classes: any;
  title: string;
  onClick: () => void;
  onClickDelete: (event: any) => void;
  isSelected: boolean;
}

const SurveyBubble = ({
  classes,
  title,
  onClick,
  onClickDelete,
  isSelected,
}: SurveyParams) => {
  const [isHovered, setHover] = useState(false);
  return (
    <div
      onMouseLeave={() => setHover(false)}
      onMouseEnter={() => setHover(true)}
      className={`${classes.main} ${isSelected && classes.selected}`}
      onClick={onClick}
    >
      <Typography className={classes.title}>{title}</Typography>
      {isHovered && (
        <div className={classes.delete} onClick={onClickDelete}>
          <DeleteIcon sizePercentage={1.4} />
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(SurveyBubble);
