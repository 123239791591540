import React, { useCallback } from "react";
import withStyles from '@mui/styles/withStyles';
import i18n from "i18n";
import { Typography } from "@mui/material";
import ActionsTable from "scenes/Campaigns/CampaignScreen/ActionsSide/ActionsTable";
import {
  selectStep,
  getListOfStepsRequest,
  removeStepFromList,
} from "services/createTrack/actions";
import { connect } from "react-redux";
import removeHtmlTags from "utils/removeHtmlTags";
import selectLanguage from "utils/selectLanguage";
import { getAppLanguage } from "services/general/selectors";
import EmptyText from "scenes/Campaigns/SeeUserModal/Coaching/RoadmapCoaching/EmptyText";
import { getUserClientId, getUserId } from "services/user/selectors";
import {
  getNumberOfSteps,
  getStepsWithData,
} from "services/createTrack/selectors";
import NumberInput from "scenes/Campaigns/SeeUserModal/Coaching/RoadmapCoaching/NewGoalModal/GoalSettings/NumberInput";

const styles = theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    width: "100%",
  },
  actions: {
    width: "100%",
    marginBottom: 25,
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  goalNumber: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    flexDirection: "row",
  },
  goalTexts: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    marginLeft: 20,
  },
  text: {
    fontSize: 20,
    color: "#282828",
    marginRight: 10,
    fontFamily: "Montserrat",
    fontWeight: "bold",
  },
  goalExplanation: {
    fontSize: 16,
    fontWeight: 300,
    lineHeight: "140%",
  },
  steps: {
    width: "100%",
  },
  actionsTable: {
    marginLeft: -25,
    paddingRight: 15,
    marginTop: 0,
  },
});

const formatSteps = (steps, language) => {
  const result = steps.map(el => {
    return {
      ...el,
      rating: el?.rating?.toFixed(1),
      donePercentage: el?.donePercentage?.toFixed(0),
      title:
        selectLanguage({ text: el.title, language }) ||
        removeHtmlTags(selectLanguage({ text: el?.content?.title, language })),
      stepId: el,
    };
  });
  return result;
};

const GoalSettings = ({
  classes,
  goalSelected = { _id: "" },
  skill = { label: {} },
  doneSteps = [],
  recommendedSteps = [],
  goalNumber = 3,
  lastStepCreatedId,
  setGoalNumber,
  steps,
  requesting,
  getListOfStepsRequest,
  removeStepFromList,
  language,
  openExistingContentModal,
  buttons = null,
  userId,
  clientId,
  numberOfSteps,
  selectStep,
}) => {
  const onClickStep = useCallback(
    stepSelectedId => {
      selectStep({
        stepId: stepSelectedId,
      });
    },
    [selectStep],
  );

  const onChangeNumber = number => {
    setGoalNumber(number);
  };

  const onClickRemove = step => {
    removeStepFromList(step);
  };

  const availableSteps = useCallback(formatSteps(steps, language), [
    steps,
    language,
  ]);

  return (
    <>
      <div className={classes.main}>
        <div className={classes.actions}>
          <div className={classes.goalNumber}>
            <NumberInput number={goalNumber} onChangeNumber={onChangeNumber} />
            <div className={classes.goalTexts}>
              <Typography className={classes.text}>
                {i18n.t("goal-number")}
              </Typography>
              <Typography className={classes.goalExplanation}>
                {i18n.t("goal-number-explanation")}
              </Typography>
            </div>
          </div>
        </div>

        <div className={classes.steps}>
          <ActionsTable
            isCoachingActions
            hasSearch={false}
            topRightComponent={buttons}
            onClickStep={onClickStep}
            isStepPreviewEditable={true}
            className={classes.actionsTable}
            tableTitle={i18n.t("available-actions")}
            doneSteps={availableSteps}
            isLoading={requesting}
            hasComments={false}
            onClickRemove={onClickRemove}
            lastStepCreatedId={lastStepCreatedId}
          />
        </div>
        {availableSteps.length < 1 && (
          <EmptyText
            onClick={() => openExistingContentModal(true)}
            firstTextPart={`${i18n.t("add")} ${i18n.t("your-first-action")}`}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    steps: getStepsWithData(state),
    lastStepCreatedId: state.createTrack.lastStepCreatedId,
    requesting: state.createTrack.stepRequesting,
    userId: getUserId(state),
    clientId: getUserClientId(state),
    language: getAppLanguage(state),
    numberOfSteps: getNumberOfSteps(state),
  };
};

export default connect(mapStateToProps, {
  getListOfStepsRequest,
  removeStepFromList,
  selectStep,
})(withStyles(styles)(GoalSettings));
