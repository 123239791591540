import React from "react";
import { IconButton } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import useHover from "hooks/useHover";

const useStyles = makeStyles(theme => ({
  main: {},
  icon: {
    marginLeft: 10,
    height: 30,
    width: 30,
    padding: 0,
    backgroundColor: "#EAEAEA",
    borderRadius: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: "#F5FBFF",
    },
  },
}));

interface Props {
  hoverBackgroundColor: string;
  isHoveredIconColor: string;
  children: any;
  onClick: () => void;
}

const ActionIcon = ({
  hoverBackgroundColor,
  isHoveredIconColor,
  onClick,
  children,
}: Props) => {
  const classes = useStyles();
  const [isHovered, hoverProperties] = useHover();

  const childrenWithExtraProp = React.Children.map(children, child =>
    React.cloneElement(child, {
      color: isHovered ? isHoveredIconColor : "#000000",
    }),
  );

  return (
    <IconButton
      className={classes.icon}
      style={{ backgroundColor: isHovered ? hoverBackgroundColor : "#EAEAEA" }}
      onClick={onClick}
      {...hoverProperties}
      size="large">
      {childrenWithExtraProp}
    </IconButton>
  );
};

export default ActionIcon;
