export const CLIENT_GET_REQUESTING = "CLIENT_GET_REQUESTING";
export const CLIENT_GET_SUCCESS = "CLIENT_GET_SUCCESS";
export const CLIENT_GET_ERROR = "CLIENT_GET_ERROR";
export const CLIENT_GET_RANKING_REQUESTING = "CLIENT_GET_RANKING_REQUESTING";
export const CLIENT_GET_RANKING_SUCCESS = "CLIENT_GET_RANKING_SUCCESS";

export const CLIENT_CHANGE_FIELD = "CLIENT_CHANGE_FIELD";

export const CLIENT_MODIFY_REQUESTING = "CLIENT_MODIFY_REQUESTING";
export const CLIENT_MODIFY_SUCCESS = "CLIENT_MODIFY_SUCCESS";
export const CLIENT_MODIFY_ERROR = "CLIENT_MODIFY_ERROR";

export const CLIENT_GET_PENDING_USERS = "client/CLIENT_GET_PENDING_USERS";
