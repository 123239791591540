import { useSelector } from "react-redux";
import { getAppLanguage } from "services/general/selectors";
import selectLanguage from "utils/selectLanguage";

interface textObjectType {
  [key: string]: string;
}

export default function useSelectLanguage() {
  const appLanguage = useSelector(getAppLanguage);

  return (textObject: textObjectType | {}, language?: string) =>
    selectLanguage({
      text: textObject,
      language: !!language ? language : appLanguage,
    });
}
