import {
  MANAGER_SELECT_TEAM_MEMBER,
  MANAGER_CLOSE_SNACK_MESSAGE,
  MANAGER_CREATE_TEAM_REQUESTING,
  MANAGER_GET_TEAM_REQUESTING,
  MANAGER_SELECT_TAB,
  MANAGER_SEE_USER_PROFILE_REQUESTING,
  MANAGER_CHANGE_FIELD,
  MANAGER_SUBMIT_COMMENT_REQUESTING,
  MANAGER_GET_COMMENTS_REQUESTING,
  MANAGER_GET_DASHBOARD_REQUESTING,
  MANAGER_DELETE_COMMENT_REQUESTING,
  MANAGER_SUBMIT_GOALS_REQUESTING,
  MANAGER_ON_EDIT_GOAL,
  MANAGER_CREATE_SURVEY_REQUESTING,
  MANAGER_MODIFY_SURVEY_REQUESTING,
  MANAGER_GET_SURVEYS_REQUESTING,
  MANAGER_DELETE_SURVEY_REQUESTING,
  MANAGER_SEND_NOTIFICATION_REQUESTING,
  MANAGER_MODIFY_USER_SELECTED,
  MANAGER_EDIT_SCHEDULED_NOTIFICATION_REQUESTING,
  MANAGER_DELETE_SCHEDULED_NOTIFICATION_REQUESTING,
} from "./constants";
import { CancelNotificationType, EditScheduledNotificationType } from "./type";

export const selectTeamMember = (payload: string) => {
  return {
    type: MANAGER_SELECT_TEAM_MEMBER,
    payload,
  };
};

export const closeSnackMessage = () => {
  return {
    type: MANAGER_CLOSE_SNACK_MESSAGE,
  };
};

export const createTeamRequest = (payload: {
  userId: string;
  selectionList: [];
}) => {
  return {
    type: MANAGER_CREATE_TEAM_REQUESTING,
    payload,
  };
};

export const getManagerTeamRequest = payload => {
  return {
    type: MANAGER_GET_TEAM_REQUESTING,
    payload,
  };
};

export const selectTab = payload => {
  return {
    type: MANAGER_SELECT_TAB,
    payload,
  };
};

export const seeUserProfileRequest = payload => {
  return {
    type: MANAGER_SEE_USER_PROFILE_REQUESTING,
    payload,
  };
};

export const changeField = payload => {
  return {
    type: MANAGER_CHANGE_FIELD,
    payload,
  };
};

export const submitComment = payload => {
  return {
    type: MANAGER_SUBMIT_COMMENT_REQUESTING,
    payload,
  };
};

export const getManagerCommentsRequest = payload => {
  return {
    type: MANAGER_GET_COMMENTS_REQUESTING,
    payload,
  };
};

export const getDashboardManagerRequest = payload => {
  return {
    type: MANAGER_GET_DASHBOARD_REQUESTING,
    payload,
  };
};

export const deleteManagerCommentRequest = payload => {
  return {
    type: MANAGER_DELETE_COMMENT_REQUESTING,
    payload,
  };
};

export const submitGoalsRequest = payload => {
  return {
    type: MANAGER_SUBMIT_GOALS_REQUESTING,
    payload,
  };
};

export const onEditGoal = payload => {
  return {
    type: MANAGER_ON_EDIT_GOAL,
    payload,
  };
};

export const createManagerSurveyRequest = payload => {
  return {
    type: MANAGER_CREATE_SURVEY_REQUESTING,
    payload,
  };
};

export const deleteManagerSurveyRequest = payload => {
  return {
    type: MANAGER_DELETE_SURVEY_REQUESTING,
    payload,
  };
};

export const modifyManagerSurveyRequest = payload => {
  return {
    type: MANAGER_MODIFY_SURVEY_REQUESTING,
    payload,
  };
};

export const getAllManagerSurveysRequest = payload => {
  return {
    type: MANAGER_GET_SURVEYS_REQUESTING,
    payload,
  };
};

export const sendNotificationRequest = payload => {
  return {
    type: MANAGER_SEND_NOTIFICATION_REQUESTING,
    payload,
  };
};

export const editManagerScheduledNotification = (
  payload: EditScheduledNotificationType,
) => {
  return {
    type: MANAGER_EDIT_SCHEDULED_NOTIFICATION_REQUESTING,
    payload,
  };
};

export const cancelManagerNotification = (payload: CancelNotificationType) => {
  return {
    type: MANAGER_DELETE_SCHEDULED_NOTIFICATION_REQUESTING,
    payload,
  };
};

export const modifyUserSelected = payload => {
  return {
    type: MANAGER_MODIFY_USER_SELECTED,
    payload,
  };
};
