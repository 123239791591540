import React from "react";
import withStyles from '@mui/styles/withStyles';
import Colors from "constants/Colors";
import FeelingArrow from "scenes/Campaigns/CampaignScreen/StatsSide/FeelingSection/FeelingArrow";
import { MainTitle } from "components/Titles/MainTitle";
import { MainText } from "components/Texts/MainText";

const styles = theme => ({
  main: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 20,
    position: "relative",
    width: 120,
    height: 120,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: 10,
    backgroundColor: "#F5F5F5",
  },
  number: {
    fontWeight: "bold",
    fontSize: 38,
  },
  highNumber: {
    fontSize: 30,
  },
  percentageFont: {
    fontSize: 25,
    fontWeight: "bold",
  },
  title: {
    marginTop: 8,
    overflow: "hidden",
    lineHeight: "1.5em" /* fallback */,
    maxHeight: "1.5em",
    wordBreak: "break-all",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 1,
    marginLeft: 10,
    marginRight: 10,
  },
  accentDiv: {
    position: "absolute",
    bottom: 0,
    width: 75,
    height: 10,
    borderTopRightRadius: 6,
    borderTopLeftRadius: 6,
  },
  rowFeelingPersentage: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
  },
});

const FeelingBox = ({
  classes,
  title,
  number = 0,
  accentColor = Colors.grey,
}) => {
  const isNegative = number < 0;
  return (
    <div className={classes.main}>
      <FeelingArrow isNegative={isNegative} />
      <div className={classes.rowFeelingPersentage}>
        <MainTitle
          className={`${classes.number} ${number > 99 && classes.highNumber}`}
          text={isNegative ? `${number}` : `+${number}`}
          size="extraLarge"
        />
        <MainTitle
          className={classes.percentageFont}
          text="%"
          size="extraLarge"
        />
      </div>
      <MainText text={title} className={classes.title} size="small" />
      <div
        className={classes.accentDiv}
        style={{ backgroundColor: accentColor }}
      />
    </div>
  );
};

export default withStyles(styles)(FeelingBox);
