import React from "react";

const EditIcon = ({ sizePercentage, color, className }) => (
  <svg
    className={className}
    width={sizePercentage * 16}
    height={sizePercentage * 16}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 16 16"
    version="1.1"
  >
    <path
      d="M1.35418 15.627C1.41961 15.627 1.48505 15.6189 1.54967 15.6028L4.78094 14.795C4.92311 14.7594 5.05317 14.6859 5.15657 14.5825L15.0871 4.65203C15.3924 4.34667 15.5604 3.94115 15.5604 3.50978C15.5604 3.0784 15.3924 2.67288 15.0871 2.36752L13.8059 1.08633C13.1951 0.475619 12.1321 0.475619 11.5214 1.08633L1.59087 11.0168C1.48752 11.1203 1.41411 11.2498 1.37842 11.3916L0.5706 14.6229C0.540761 14.742 0.53847 14.8664 0.563899 14.9865C0.589328 15.1066 0.641808 15.2194 0.717352 15.3162C0.792896 15.413 0.889514 15.4913 0.999864 15.5452C1.11021 15.599 1.23139 15.627 1.35418 15.627ZM12.6636 2.22858L13.9448 3.50978L12.6636 4.79097L11.3824 3.50978L12.6636 2.22858ZM2.89146 12.0007L10.2402 4.65203L11.5214 5.93322L4.17184 13.2819L2.46412 13.7085L2.89146 12.0007Z"
      fill={color}
    />
  </svg>
);

EditIcon.defaultProps = {
  sizePercentage: 1,
  color: "#fff",
  className: null,
};

export default EditIcon;
