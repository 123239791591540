import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";

import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import { persistStore, persistReducer, createMigrate } from "redux-persist";

import rootSagas from "./rootSagas";
import createRootReducer, { allReducersName } from "./rootReducer";
import { createBlacklistFilter } from "redux-persist-transform-filter";
import localforage from "localforage";

// create middlewares
export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const blacklistFilters = [
  "error",
  "noteRequesting",
  "fileRequesting",
  "loading",
  "requesting",
  "definitionRequesting",
  "uploading",
  "stepLoading",
  "saving",
  "teamRankingRequesting",
  "userRankingRequesting",
  "campaignRequesting",
  "templateRequesting",
  "skillsListRequesting",
  "isMyLibraryStepsLoading",
  "myLibrarySteps",
  "steps",
  "notifications",
  "notificationsHistory",
  "drafts",
  "campaigns",
  "allObjectives",
  "allCompetencies",
  "users",
  "myCoachees",
  "userCampaigns",
  "isReviewRequesting",
  "myClients",
  "invoice",
  "campaign",
  "survey",
  "surveys",
  "userInfos",
  "surveysByCampaign",
  "allClients",
  "comments",
  "userRanking",
  "teamRanking",
  "campaignSelected",
  "usersInfos",
  "steps",
];

const allBlacklistFilters = allReducersName.reduce((prev, curr) => {
  const saveSubsetBlacklistFilter = createBlacklistFilter(
    curr,
    blacklistFilters,
  );
  prev.push(saveSubsetBlacklistFilter);
  return prev;
}, []);

// migration config
const migrations = {
  5: state => {
    return {
      ...state,
      myUsers: {
        ...state.myUsers,
        userSelected: {
          hasUsersAccessRestricted: false,
          usersAccess: [],
        },
      },
    };
  },
  6: state => {
    return {
      ...state,
    };
  },
  7: state => {
    return {
      ...state,
      campaign: {
        ...state.campaign,
        doneSteps: [],
      },
    };
  },
  8: state => {
    return {
      ...state,
      createTrack: {
        ...state.createTrack,
        // steps: [],
      },
    };
  },
};

const persistConfig = {
  key: "root",
  version: 9,
  storage: localforage,
  migrate: createMigrate(migrations),
  transforms: allBlacklistFilters,
};

// create store

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = applyMiddleware(routerMiddleware(history), sagaMiddleware);

const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history),
);

export const store = createStore(
  persistedReducer,
  composeEnhancers(middleware),
);

export const persistor = persistStore(store);
// run saga middleware
sagaMiddleware.run(rootSagas);
