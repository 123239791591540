import React from "react";
import withStyles from "@mui/styles/withStyles";
import Colors from "constants/Colors";
import { IconButton, Typography } from "@mui/material";
import i18n from "i18n";
import moment from "moment";
import { DEFAULT_PICTURE } from "scenes/Campaigns/CampaignBox";
import ArrowBackIcon from "../ArrowBackIcon";

const styles = theme => ({
  main: {
    overflow: "hidden",
    position: "relative",
  },
  pictureDiv: {
    objectFit: "cover",
    height: 240,
    borderRadius: 8,
    width: "100%",
  },
  picture: {
    objectFit: "cover",
    filter: "brightness(0.6)",
    flex: 1,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    height: "100%",
    minWidth: "100%",
  },
  status: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "fit-content",
    backgroundColor: "#3CC926",
    borderRadius: 2,
    padding: "4px 7px 3px 7px",
  },
  statusText: {
    color: "white",
    fontWeight: "bold",
    fontFamily: "Roboto",
  },
  date: {
    fontSize: 14,
    marginTop: 5,
    fontWeight: 200,
    color: "white",
    fontFamily: "Roboto",
  },
  textDiv: {
    overflow: "auto",
    overflowX: "hidden",
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    top: 25,
    left: 0,
    height: 205,
    paddingLeft: 35,
    paddingRight: 35,
  },
  title: {
    fontFamily: "Montserrat",
    marginTop: 15,
    lineHeight: "24px",
    color: Colors.white,
    fontSize: 20,
    fontWeight: 700,
  },
  description: {
    fontFamily: "Montserrat",
    marginTop: 10,
    fontSize: 16,
    color: Colors.white,
    textOverflow: "ellipsis",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 1 /* number of lines to show */,
    lineHeight: "1.5em" /* fallback */,
    maxHeight: "1.5em",
    wordBreak: "break-all",
  },
});

const CampaignSummary = ({
  classes,
  title = "",
  picture = "",
  status = {},
  createdAt = "",
  description = "",
}) => {
  return (
    <div className={classes.main}>
      <div className={classes.pictureDiv}>
        <img
          alt=""
          src={picture || DEFAULT_PICTURE}
          className={classes.picture}
        />
      </div>
      <div className={classes.textDiv}>
        <div
          className={classes.status}
          style={{ backgroundColor: status?.color }}
        >
          <Typography className={classes.statusText}>
            {i18n.t(status?.text).toUpperCase()}
          </Typography>
        </div>
        <Typography className={classes.date}>
          {`${i18n.t("created")}
                  ${moment(createdAt).format("L")}`}
        </Typography>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.description}>{description}</Typography>
      </div>
    </div>
  );
};

export default withStyles(styles)(CampaignSummary);
