import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  changeField,
  createClientRequest,
} from "../../services/signup/actions";

import withStyles from '@mui/styles/withStyles';
import TextField from "@mui/material/TextField";
import FlatButton from "../../components/FlatButton";

import Colors from "../../constants/Colors";
import i18n from "../../i18n";
import { Loader } from "../../components";

const styles = theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "100vh",
    width: "100vw",
    paddingBottom: 50,
    backgroundColor: Colors.pureMain,
  },
  logo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: 75,
    // paddingTop: 0,
    margin: "20px auto 20px",
  },
  paper: {
    width: 470,
    display: "flex",
    borderRadius: 3,
    background: "white",
    padding: 40,
    paddingBottom: 20,
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 20,
  },
  title: {
    textAlign: "center",
    fontWeight: 300,
    color: "#444",
    margin: 0,
    marginTop: 0,
    fontSize: 30,
    lineHeight: "38px",
    textTransform: "none",
    letterSpacing: 0,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE11 issue.
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  loginError: {
    backgroundColor: "#FCE6E7",
    borderRadius: 8,
  },
  errorText: {
    color: "#e6525a",
    padding: "13px 15px",
    borderRadius: 6,
    backgroundColor: "rgba(230,82,90,0.15)",
  },
  textField: {
    marginTop: 20,
  },
  submitButton: {
    marginBottom: 15,
    marginTop: 20,
    padding: 12,
    backgroundColor: "#15CD72",
    "&:hover": {
      backgroundColor: "#15CD72",
    },
  },
  noDecoration: {
    textDecoration: "none",
  },
  forgotPassword: {
    marginTop: 20,
    cursor: "pointer",
    textDecoration: "none",
    color: Colors.pureMain,
    fontSize: 17,
    lineHeight: 1.5,
    textAlign: "center",
  },
  newAccountText: {
    color: Colors.white,
    fontSize: 16,
    textAlign: "center",
  },
  bold: {
    marginLeft: 5,
    color: Colors.white,
    fontSize: 16,
    cursor: "pointer",
    fontWeight: "bold",
    textDecoration: "underline",
  },
  noAccountDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  topField: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  splitTextField: {
    width: "48.5%",
  },
  agreeTerms: {
    fontSize: 13,
    marginTop: 22,
    marginBottom: 10,
  },
  link: {
    textDecoration: "none",
    color: Colors.pureMain,
    fontWeight: "bold",
  },
  stepDiv: {
    marginBottom: 25,
    marginTop: 10,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  step: {
    borderRadius: 30,
    height: 4,
    width: 40,
    marginLeft: 5,
    marginRight: 5,
    backgroundColor: Colors.lightMain,
  },
  stepActive: {
    backgroundColor: Colors.pureMain,
  },
});

class CreateClientForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChangeField = field => event => {
    this.props.changeField({ [field]: event.target.value });
  };

  onSubmit = () => {
    this.props.changeField({ step: 2 });
    // this.props.createClientRequest({
    //   companyName,
    //   companySize,
    //   owner: this.props.userId,
    //   source,
    //   logo
    // });
  };

  render() {
    const { error, classes, requesting } = this.props;

    return (
      <React.Fragment>
        <div className={classes.form}>
          {error && <p className={classes.errorText}>{error}</p>}
          <div className={classes.topField}>
            <TextField
              id="outlined-multiline-flexible"
              label={i18n.t("company")}
              fullWidth
              autoFocus
              autoComplete="organization"
              // rowsMax="4"
              value={this.props.organization}
              onChange={this.onChangeField("organization")}
              className={`${classes.textField}`}
              margin="normal"
              variant="outlined"
            />
          </div>
          <TextField
            id="outlined-multiline-flexible"
            label={i18n.t("companySize")}
            fullWidth
            type="number"
            onChange={this.onChangeField("companySize")}
            value={this.props.companySize}
            className={classes.textField}
            margin="normal"
            variant="outlined"
          />
          {requesting ? (
            <Loader />
          ) : (
            <FlatButton
              fullWidth
              disabled={!this.props.organization || !this.props.companySize}
              title={i18n.t("create-account")}
              onClick={this.onSubmit}
              className={classes.submitButton}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

CreateClientForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const {
    step,
    error,
    requesting,
    organization,
    companySize,
    source,
    logo,
  } = state.signup;
  return {
    step,
    organization,
    companySize,
    requesting,
    source,
    logo,
    error,
  };
};

export default connect(mapStateToProps, { changeField, createClientRequest })(
  withStyles(styles)(CreateClientForm),
);
