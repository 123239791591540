import React from "react";

const EnglishFlag = ({ sizePercentage, color }) => (
  <svg
    width={sizePercentage * 25}
    height={sizePercentage * 25}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 25 25"
    version="1.1"
  >
    <path d="M25 4.16699H0V20.8338H25V4.16699Z" fill="#F0F0F0" />
    <path
      d="M14.0625 4.16602H10.9375V10.9368H0V14.0618H10.9375V20.8326H14.0625V14.0618H25V10.9368H14.0625V4.16602Z"
      fill="#D80027"
    />
    <path
      d="M19.2275 15.3984L24.9998 18.6053V15.3984H19.2275Z"
      fill="#0052B4"
    />
    <path
      d="M15.2173 15.3984L24.9999 20.8332V19.2964L17.9836 15.3984H15.2173Z"
      fill="#0052B4"
    />
    <path
      d="M22.3941 20.8332L15.2173 16.8457V20.8332H22.3941Z"
      fill="#0052B4"
    />
    <path
      d="M15.2173 15.3984L24.9999 20.8332V19.2964L17.9836 15.3984H15.2173Z"
      fill="#F0F0F0"
    />
    <path
      d="M15.2173 15.3984L24.9999 20.8332V19.2964L17.9836 15.3984H15.2173Z"
      fill="#D80027"
    />
    <path d="M4.41118 15.3975L0 17.8481V15.3975H4.41118Z" fill="#0052B4" />
    <path
      d="M9.78257 16.0879V20.8316H1.24463L9.78257 16.0879Z"
      fill="#0052B4"
    />
    <path
      d="M7.01626 15.3984L0 19.2964V20.8332L9.78262 15.3984H7.01626Z"
      fill="#D80027"
    />
    <path d="M5.77222 9.60039L0 6.39355V9.60039H5.77222Z" fill="#0052B4" />
    <path
      d="M9.78262 9.60078L0 4.16602V5.70283L7.01626 9.60078H9.78262Z"
      fill="#0052B4"
    />
    <path
      d="M2.60571 4.16602L9.78257 8.15352V4.16602H2.60571Z"
      fill="#0052B4"
    />
    <path
      d="M9.78262 9.60078L0 4.16602V5.70283L7.01626 9.60078H9.78262Z"
      fill="#F0F0F0"
    />
    <path
      d="M9.78262 9.60078L0 4.16602V5.70283L7.01626 9.60078H9.78262Z"
      fill="#D80027"
    />
    <path
      d="M20.5886 9.60107L24.9998 7.15039V9.60107H20.5886Z"
      fill="#0052B4"
    />
    <path
      d="M15.2173 8.90971V4.16602H23.7552L15.2173 8.90971Z"
      fill="#0052B4"
    />
    <path
      d="M17.9836 9.60078L24.9999 5.70283V4.16602L15.2173 9.60078H17.9836Z"
      fill="#D80027"
    />
  </svg>
);

EnglishFlag.defaultProps = {
  sizePercentage: 1,
  color: "#fff",
};

export default EnglishFlag;
