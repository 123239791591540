import React from "react";
import { Tooltip, tooltipClasses, TooltipProps, Zoom } from "@mui/material";
import styled from "@emotion/styled";

const CustomArrowTooltip = ({ children, title }) => {
  const TooltipCustomArrow = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#262627",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#262627",
      maxWidth: 300,
      border: "none !important",
      borderRadius: 2,
      paddingRight: 10,
      paddingLeft: 10,
      fontFamily: "Montserrat",
      fontSize: 12,
      padding: "8px 12px",
      opacity: "1 !important",
    },
  }));

  return (
    <TooltipCustomArrow
      title={title}
      placement="bottom"
      arrow
      TransitionComponent={Zoom}
    >
      {children}
    </TooltipCustomArrow>
  );
};

export default CustomArrowTooltip;
