import { createSelector } from "reselect";

export const getAllInvoices = state => state.invoice.invoices;

export const getInvoiceLoading = state => state.invoice.loading;

const getLatestInvoiceSate = state => state.invoice.latestInvoice;

export const getPaidInvoices = createSelector(getAllInvoices, invoices => {
  return invoices.filter(el => !el.isPaid);
});

export const getLatestInvoice = createSelector(
  getAllInvoices,
  getLatestInvoiceSate,
  (invoices, latestInvoice) => {
    return invoices.length ? invoices[0] : latestInvoice;
  },
);

export const getInitialInvoice = state => state.invoice.initialInvoice;

export const getInvoiceClients = state => state.invoice.invoiceClients || [];

export const getInvoiceRequesting = state => state.invoice.requesting;
