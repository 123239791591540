import React from "react";
import withStyles from '@mui/styles/withStyles';
import selectLanguage from "utils/selectLanguage";
import SliderQuestionResult from "scenes/Campaigns/CampaignScreen/SurveysSide/ByQuestionResults/SliderQuestionResult";
import MultipleChoiceResult from "scenes/Campaigns/CampaignScreen/SurveysSide/ByQuestionResults/MultipleChoiceResult";
import FreeAnswerResult from "scenes/Campaigns/CampaignScreen/SurveysSide/ByQuestionResults/FreeAnswerResult";

const styles = theme => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
});

const formatTopics = (question, language) => {
  return question.answers.map(answer => {
    return answer.choices.map(choice => {
      const title = { ...choice };
      return { title, value: selectLanguage({ language, text: title }) };
    });
  });
};

const ByQuestionResults = ({
  classes,
  questions = [],
  language,
  participants,
}) => {
  return (
    <div className={classes.main}>
      {questions.map((question, index) => {
        const {
          questionTitle,
          answers,
          questionType,
          leftLabel,
          rightLabel,
        } = question;

        const title = selectLanguage({ text: questionTitle, language });
        if (questionType === "RATING") {
          return (
            <SliderQuestionResult
              key={index}
              answers={answers}
              title={title}
              leftLabel={selectLanguage({ text: leftLabel, language })}
              rightLabel={selectLanguage({ text: rightLabel, language })}
              participants={participants}
              index={index}
            />
          );
        }
        if (questionType === "FREE_ANSWER") {
          return (
            <FreeAnswerResult
              key={index}
              answers={answers}
              title={title}
              leftLabel={selectLanguage({ text: leftLabel, language })}
              rightLabel={selectLanguage({ text: rightLabel, language })}
              participants={participants}
              index={index}
            />
          );
        }
        return (
          <MultipleChoiceResult
            key={index}
            topicChoices={formatTopics(question, language)}
            answers={answers}
            title={title}
            participants={participants}
            index={index}
          />
        );
      })}
    </div>
  );
};

export default withStyles(styles)(ByQuestionResults);
