import React from "react";
import withStyles from '@mui/styles/withStyles';
import Button from "@mui/material/Button";
import Colors from "../constants/Colors";
import { Loader } from "./Loader";

const ButtonCustom = withStyles({
  root: {
    padding: "10px 12px",
    // height: "36px",
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    fontWeight: "bold",
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    // padding: "6px 12px",
    color: Colors.pureMain,
    // border: "1px solid",
    lineHeight: 1.5,
    opacity: 1,
    border: "solid",
    borderRadius: 6,
    borderWidth: 1,
    borderColor: Colors.pureMain,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      boxShadow: "none",
      opacity: 0.9,
    },
    "&:active": {
      boxShadow: "none",
      border: "none",
      opacity: 0.9,
    },
    "&:focus": {
      border: "solid",
      borderRadius: 6,
      borderWidth: 1,
      // border: "none",
      // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)"
      // boxShadow: `0 0 0 0.2rem ${Colors.lightMain}`,
      borderColor: Colors.pureMain,
      // backgroundColor: Colors.pureMain,
    },
  },
  disabled: {
    fontWeight: "normal",
    color: Colors.black,
  },
  "@media (max-width: 375px)": {
    root: {
      fontSize: 14,
    },
  },
})(Button);

const LightButton = ({
  title,
  onClick,
  disabled = false,
  className = "",
  fullWidth = false,
  requesting = false,
  icon = null,
}) => {
  if (requesting) {
    return <Loader size={35} />;
  }
  return (
    <ButtonCustom
      className={className}
      disabled={disabled}
      fullWidth={fullWidth}
      variant="outlined"
      color="primary"
      disableRipple
      onClick={onClick}
    >
      {icon}
      {title}
    </ButtonCustom>
  );
};

export { LightButton };
