import { debounce } from "lodash";
import socket from "services/socket";
import {
  OPEN_SNACK_MESSAGE,
  CLOSE_SNACK_MESSAGE,
  CHANGE_GENERAL_SETTINGS,
  SEND_MOBILE_NAVIGATION_EVENT,
  UPDATE_ADMIN_CONNECTION,
} from "./constants";

export const openSnackMessage = (payload: {
  snackStatus?: string;
  snackMessage: string;
}) => {
  return {
    type: OPEN_SNACK_MESSAGE,
    payload,
  };
};

export const closeSnackMessage = (payload: string) => {
  return {
    type: CLOSE_SNACK_MESSAGE,
    payload,
  };
};

export const changeGeneralSettings = (payload: {}) => {
  return {
    type: CHANGE_GENERAL_SETTINGS,
    payload,
  };
};

export const sendAdminConnectionSocket = debounce(payload => {
  socket.emit("admin-connection", payload);
}, 850);

export const sendMobileNavigationSocket = (payload: {
  route: string;
  userId: string;
}) => {
  return {
    type: SEND_MOBILE_NAVIGATION_EVENT,
    payload,
  };
};

export const updateAdminConnection = payload => {
  return {
    type: UPDATE_ADMIN_CONNECTION,
    payload,
  };
};
