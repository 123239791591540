import React from "react";

const EyeClosedIcon = () => (
  <svg
    width="16"
    height="13"
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.79 11.2655L9.176 9.65052C8.55184 9.87367 7.87715 9.915 7.23042 9.76969C6.58369 9.62438 5.99153 9.2984 5.52282 8.8297C5.05411 8.36099 4.72814 7.76882 4.58283 7.1221C4.43752 6.47537 4.47885 5.80067 4.702 5.17652L2.642 3.11652C0.938 4.63152 0 6.35352 0 6.35352C0 6.35352 3 11.8535 8 11.8535C8.9604 11.8502 9.90994 11.6501 10.79 11.2655ZM5.21 1.44152C6.09005 1.05694 7.03959 0.856823 8 0.853516C13 0.853516 16 6.35352 16 6.35352C16 6.35352 15.061 8.07452 13.359 9.59152L11.297 7.52952C11.5202 6.90536 11.5615 6.23066 11.4162 5.58394C11.2709 4.93721 10.9449 4.34504 10.4762 3.87634C10.0075 3.40763 9.41531 3.08166 8.76858 2.93635C8.12185 2.79103 7.44716 2.83236 6.823 3.05552L5.21 1.44252V1.44152Z"
      fill="black"
    />
    <path
      d="M5.525 6C5.46999 6.38431 5.50524 6.77615 5.62796 7.14447C5.75068 7.51279 5.95749 7.84747 6.23201 8.12199C6.50653 8.39651 6.8412 8.60332 7.20953 8.72604C7.57785 8.84876 7.96969 8.88401 8.354 8.829L5.524 6H5.525ZM10.475 6.708L7.646 3.878C8.03031 3.82299 8.42215 3.85824 8.79047 3.98096C9.15879 4.10368 9.49347 4.31049 9.76799 4.58501C10.0425 4.85953 10.2493 5.19421 10.372 5.56253C10.4948 5.93085 10.53 6.32269 10.475 6.707V6.708ZM13.646 12.708L1.646 0.708L2.354 0L14.354 12L13.646 12.708Z"
      fill="black"
    />
  </svg>
);

export default EyeClosedIcon;
