import { call, put, takeLatest, all, select, delay } from "redux-saga/effects";

import { push } from "connected-react-router";

import {
  CAMPAIGN_GET_ALL_REQUESTING,
  CAMPAIGN_GET_ALL_SUCCESS,
  CAMPAIGN_GET_ALL_ERROR,
  CAMPAIGN_CREATE_REQUESTING,
  CAMPAIGN_CREATE_SUCCESS,
  CAMPAIGN_CREATE_ERROR,
  TRACK_GET_ALL_REQUESTING,
  TRACK_GET_ALL_SUCCESS,
  TRACK_GET_ALL_ERROR,
  CAMPAIGN_GET_ALL_DONE_STEPS_ERROR,
  CAMPAIGN_GET_ALL_DONE_STEPS_REQUESTING,
  CAMPAIGN_GET_ALL_DONE_STEPS_SUCCESS,
  CAMPAIGN_GET_TEAM_RANKING_REQUESTING,
  CAMPAIGN_GET_TEAM_RANKING_SUCCESS,
  CAMPAIGN_GET_TEAM_RANKING_ERROR,
  CAMPAIGN_GET_USER_RANKING_SUCCESS,
  CAMPAIGN_GET_USER_RANKING_REQUESTING,
  CAMPAIGN_GET_USER_RANKING_ERROR,
  CAMPAIGN_GET_ALL_TEMPLATES_REQUESTING,
  CAMPAIGN_GET_ALL_TEMPLATES_ERROR,
  CAMPAIGN_GET_ALL_TEMPLATES_SUCCESS,
  USER_CAMPAIGNS_GET_LIST_ERROR,
  USER_CAMPAIGNS_GET_LIST_SUCCESS,
  USER_CAMPAIGNS_GET_LIST_REQUESTING,
  USER_CAMPAIGNS_LOAD_MORE_ERROR,
  USER_CAMPAIGNS_LOAD_MORE_SUCCESS,
  USER_CAMPAIGNS_LOAD_MORE_REQUESTING,
  CAMPAIGN_MODIFY_REQUESTING,
  CAMPAIGN_MODIFY_SUCCESS,
  CAMPAIGN_MODIFY_ERROR,
  CAMPAIGN_ACTIVATE_EMAIL_REMINDER_REQUESTING,
  CAMPAIGN_ACTIVATE_EMAIL_REMINDER_SUCCESS,
  CAMPAIGN_ACTIVATE_EMAIL_REMINDER_ERROR,
  CAMPAIGN_DELETE_REQUESTING,
  CAMPAIGN_DELETE_SUCCESS,
  CAMPAIGN_DELETE_ERROR,
  CAMPAIGN_RESET_TEAMS_REQUESTING,
  CAMPAIGN_RESET_TEAMS_SUCCESS,
  CAMPAIGN_RESET_TEAMS_ERROR,
  TRACK_DELETE_REQUESTING,
  TRACK_DELETE_SUCCESS,
  TRACK_DELETE_ERROR,
  GET_ALL_MY_CLIENTS_TRACKS_ERROR,
  GET_ALL_MY_CLIENTS_TRACKS_REQUESTING,
  GET_ALL_MY_CLIENTS_TRACKS_SUCCESS,
  USER_CAMPAIGN_DELETE_REQUESTING,
  USER_CAMPAIGN_DELETE_SUCCESS,
  CAMPAIGN_ADD_PARTICIPANTS_REQUESTING,
  CAMPAIGN_ADD_PARTICIPANTS_SUCCESS,
  CAMPAIGN_ADD_PARTICIPANTS_ERROR,
  CAMPAIGN_GET_ALL_DRAFTS_ERROR,
  CAMPAIGN_GET_ALL_DRAFTS_REQUESTING,
  CAMPAIGN_GET_ALL_DRAFTS_SUCCESS,
  CAMPAIGN_ADD_TO_TEMPLATE_REQUESTING,
  CAMPAIGN_ADD_TO_TEMPLATE_SUCCESS,
  CAMPAIGN_ADD_TO_TEMPLATE_ERROR,
  CAMPAIGN_GET_ALL_PUBLIC_TEMPLATES_REQUESTING,
  CAMPAIGN_GET_ALL_PUBLIC_TEMPLATES_SUCCESS,
  CAMPAIGN_GET_ALL__PUBLIC_TEMPLATES_ERROR,
  DRAFT_REMOVE_REQUESTING,
  DRAFT_REMOVE_SUCCESS,
  DRAFT_REMOVE_ERROR,
  VALIDATE_RANDOM_STEP_REQUESTING,
  CAMPAIGN_GET_COMMENTS_ERROR,
  CAMPAIGN_GET_COMMENTS_REQUESTING,
  CAMPAIGN_GET_COMMENTS_SUCCESS,
  CAMPAIGN_GET_USERS_INFOS_REQUESTING,
  CAMPAIGN_GET_USERS_INFOS_SUCCESS,
  CAMPAIGN_GET_USERS_INFOS_ERROR,
  SELECT_CAMPAIGN_REQUESTING,
  SELECT_CAMPAIGN_SUCCESS,
  SELECT_CAMPAIGN_ERROR,
  SEE_COMMENTS_REVIEW_REQUESTING,
  SEE_COMMENTS_REVIEW_SUCCESS,
  SEE_COMMENTS_REVIEW_ERROR,
  COMMENT_REVIEW_REQUESTING,
  COMMENT_REVIEW_SUCCESS,
  COMMENT_REVIEW_ERROR,
  CAMPAIGN_GET_ALL_DONE_STEPS_FORMATED_REQUESTING,
  CAMPAIGN_GET_ALL_DONE_STEPS_FORMATED_ERROR,
  CAMPAIGN_GET_ALL_DONE_STEPS_FORMATED_SUCCESS,
  CAMPAIGN_GET_NEXT_DONE_STEP_REVIEW,
  CAMPAIGN_GET_NEXT_DONE_STEP_REVIEW_SUCCESS,
  CAMPAIGN_GET_NEXT_DONE_STEP_REVIEW_ERROR,
  CAMPAIGN_VALIDATE_REVIEW_ERROR,
  CAMPAIGN_VALIDATE_REVIEW_SUCCESS,
  CAMPAIGN_VALIDATE_REVIEW_REQUESTING,
  CAMPAIGN_GET_PREVIOUS_DONE_STEP_REVIEW,
  USER_CAMPAIGN_DELETE_ERROR,
  CAMPAIGN_SEND_EMAIL_RECAP_REQUESTING,
  CAMPAIGN_SEND_EMAIL_RECAP_ERROR,
  CAMPAIGN_SEND_EMAIL_RECAP_SUCCESS,
  CAMPAIGN_CREATE_EVENT,
  CAMPAIGN_CREATE_EVENT_SUCCESS,
  CAMPAIGN_CREATE_EVENT_ERROR,
  CAMPAIGN_CREATE_EVENT_REQUESTING,
  CAMPAIGN_UPDATE_EVENT_SUCCESS,
  CAMPAIGN_UPDATE_EVENT_ERROR,
  CAMPAIGN_DELETE_EVENT_SUCCESS,
  CAMPAIGN_DELETE_EVENT_ERROR,
  CAMPAIGN_UPDATE_EVENT_REQUESTING,
  CAMPAIGN_DELETE_EVENT_REQUESTING,
  CAMPAIGN_REGENERATE_CERTIFICATE_REQUESTING,
  CAMPAIGN_REGENERATE_CERTIFICATE_SUCCESS,
  CAMPAIGN_REGENERATE_CERTIFICATE_ERROR,
  CAMPAIGN_GET_STEPS_REQUESTING,
  CAMPAIGN_GET_STEPS_SUCCESS,
  GET_STEP_COMMENT_SUCCESS,
  GET_STEP_COMMENT_ERROR,
  GET_STEP_COMMENT_REQUESTING,
  HIDE_STEP_COMMENT_REQUESTING,
  HIDE_STEP_COMMENT_SUCCESS,
  HIDE_STEP_COMMENT_ERROR,
  CAMPAIGN_GET_INTERACTIONS_ERROR,
  CAMPAIGN_GET_INTERACTIONS_SUCCESS,
  CAMPAIGN_GET_INTERACTIONS_REQUESTING,
  ADD_LIKE_STEP_COMMENT_REQUESTING,
  REMOVE_LIKE_STEP_COMMENT_REQUESTING,
  REMOVE_LIKE_STEP_COMMENT_SUCCESS,
  REMOVE_LIKE_STEP_COMMENT_ERROR,
  ADD_LIKE_STEP_COMMENT_SUCCESS,
  ADD_LIKE_STEP_COMMENT_ERROR,
} from "./constants";

import { ReviewStatusType } from "scenes/Campaigns/CampaignScreen/ReviewsSide/types";

import { fetchApi } from "../api";
import {
  changeAddUsersModal,
  getUserCampaignsListRequest,
  selectCampaign,
  getCampaignUserRankingRequest,
  getCampaignTeamRankingRequest,
  setSelectedDoneStep,
  createEvent,
} from "./actions";
import { openSnackMessage } from "../general/actions";
import i18n from "../../i18n";
import { modifyTrackApi } from "../createTrack/sagas";
import {
  getSelectedClientId,
  getUserClientId,
  getUserFirstName,
  getUserId,
} from "../user/selectors";
import {
  getCampaignSelectedActionPerParticipants,
  getCampaignSelectedActiveUsers,
  getCampaignSelectedAverageCompletionRate,
  getCampaignSelectedAverageRating,
  getCampaignSelectedBestActions,
  getCampaignSelectedDoneStep,
  getCampaignSelectedLongestComments,
  getCampaignSelectedParticipants,
  getCampaignSelectedParticipationRate,
  getCampaignSelectedTotalDoneAction,
  getCampaignSelectedTotalViews,
  getCampaignSelectedTrackId,
  getSelectedCampaignId,
  getTeamRanking,
  getUserCampaigns,
  getUserCampaignSortedDoneSteps,
  getUserRanking,
} from "./selectors";
import selectLanguage from "utils/selectLanguage";
import removeHtmlTags from "utils/removeHtmlTags";
import { getAppLanguage } from "services/general/selectors";
import { getSelectedClientName } from "services/myClients/selectors";
import { getFileToUpload } from "services/upload/selectors";
import ObjectID from "bson-objectid";
import hasImageExtension from "utils/hasImageExtension";
import hasVideoExtension from "utils/hasVideoExtension";
import hasAudioExtension from "utils/hasAudioExtension";
import { uploadFileFlow } from "services/upload/sagas";
import createUploadUrlName from "utils/createUploadUrlName";
import { resetUpload } from "services/upload/actions";
import { displayNumberOfActionDone } from "utils/doneStepsByUser";
import { cloneDeep } from "lodash";

const apiEndpoints = {
  getAll: "/v1/campaigns/library/all", // clientId=
  getCampaign: "/v1/campaigns/",
  createCampaign: "/v1/campaigns/",
  modifyCampaign: "/v1/campaigns/",
  deleteCampaign: "/v1/campaigns",
  deleteUserCampaign: "/v1/usercampaigns/",
  deleteTrack: "/v1/tracks/",
  validateRandomStep: "/v1/usercampaigns/perfect-step/validate/random",
  regenerateCertificate: "/v1/usercampaigns/certificate/regenerate",
  addParticipants: "/v1/campaigns/participants/add",
  resetTeams: "/v1/campaigns/reset-teams",
  activateEmailReminder: "/v1/campaigns/email/reminder",
  getAllTracks: "/v1/tracks/client/all",
  getAllTemplates: "/v1/tracks/templates/all",
  addCampaignToTemplates: "/v1/tracks/templates/add",
  getAllMyClientsTracks: "/v1/tracks/my-clients/all",
  getAllMyClientsTemplates: "/v1/tracks/my-clients/templates/all",
  getUserRanking: "/v1/campaigns/ranking/user",
  getCampaignInteractions:
    "/v1/campaigns/interactions/get-campaign-interactions",
  getUserCampaignsList: "/v1/campaigns/user-campaign/list",
  getUsersInfo: "/v1/campaigns/users/infos",
  getTeamRanking: "/v1/teams/campaign/ranking",
  getAllDoneSteps: "/v1/campaigns/steps/all", // campaignId
  getComments: "/v1/campaigns/comments/all", // ?campaignId=
  commentReview: "/v1/step-feedbacks/reviews/comment", // userId: String, campaignId: String
  seeCommentsReview: "/v1/step-feedbacks/reviews/comments/see", // userId: String, campaignId: String
  apiReviewValidated: "/v1/step-feedbacks/reviews/validate",
  apiReviewUpdate: "/v1/step-feedbacks/reviews",
  sendCampaignRecap: "/v1/emails/send/campaign-report",
  getCampaignSteps: "/v1/campaigns/track/steps", // ?trackId: String
  getStepCommentsByStepIdByAdmin:
    "/v1/step-comments/get-all-step-comments-by-step-id-by-admin",
  hideStepComment: "/v1/step-comments/hide",
  createEvent: "/v1/events",
  deleteEvents: "/v1/events", // eventId
  updateEvents: "/v1/events", // eventId
  addLikeToCommentary: "/v1/step-comments/add-like",
  removeLikeFromCommentary: "/v1/step-comments/remove-like",
};

const createEventApi = data => {
  return fetchApi(apiEndpoints.createEvent, data, "post");
};

const updateEventsApi = (eventId: string, data) => {
  return fetchApi(
    `${apiEndpoints.updateEvents}?eventId=${eventId}`,
    data,
    "patch",
  );
};

const deleteEventsApi = data => {
  const { eventId } = data;
  return fetchApi(
    `${apiEndpoints.deleteEvents}?eventId=${eventId}`,
    {},
    "delete",
  );
};

const hideStepCommentApi = data => {
  const { stepId } = data;

  return fetchApi(apiEndpoints.hideStepComment, data, "patch");
};

const getCampaignStepsApi = data => {
  const { trackId } = data;
  return fetchApi(
    apiEndpoints.getCampaignSteps + `?trackId=${trackId}`,
    data,
    "get",
  );
};

const sendCampaignEmailRecapApi = data => {
  return fetchApi(apiEndpoints.sendCampaignRecap, data, "post");
};

const regenerateCertificateApi = data => {
  return fetchApi(apiEndpoints.regenerateCertificate, data, "post");
};
const getStepCommentsByStepIdByAdminApi = data => {
  const { stepId } = data;

  return fetchApi(
    `${apiEndpoints.getStepCommentsByStepIdByAdmin}?stepId=${stepId}`,
    "get",
  );
};

const getCampaignApi = data => {
  const { campaignId } = data;
  return fetchApi(`${apiEndpoints.getCampaign}/${campaignId}`, {}, "get");
};

const validateRandomStepApi = data => {
  return fetchApi(apiEndpoints.validateRandomStep, data, "post");
};

const addCampaignToTemplatesApi = data => {
  return fetchApi(apiEndpoints.addCampaignToTemplates, data, "patch");
};

export const modifyCampaignApi = data => {
  return fetchApi(apiEndpoints.modifyCampaign, data, "patch");
};

const addParticipantsApi = data => {
  return fetchApi(apiEndpoints.addParticipants, data, "post");
};

const deleteCampaignApi = data => {
  return fetchApi(apiEndpoints.deleteCampaign, data, "delete");
};

const deleteUserCampaignApi = data => {
  return fetchApi(apiEndpoints.deleteUserCampaign, data, "delete");
};

const deleteTrackApi = data => {
  return fetchApi(apiEndpoints.deleteTrack, data, "delete");
};

const activateEmailReminderApi = data => {
  return fetchApi(apiEndpoints.activateEmailReminder, data, "patch");
};

const resetTeamsApi = data => {
  return fetchApi(apiEndpoints.resetTeams, data, "post");
};

const commentReviewApi = data => {
  return fetchApi(apiEndpoints.commentReview, data, "post");
};

const seeCommentsReviewApi = data => {
  return fetchApi(apiEndpoints.seeCommentsReview, data, "patch");
};

const getUserRankingApi = data => {
  const { campaignId, page, limit } = data;
  return fetchApi(
    `${apiEndpoints.getUserRanking}?campaignId=${campaignId}&page=${page}&limit=${limit}`,
    {},
    "get",
  );
};

const getCampaignInteractionsApi = data => {
  const { campaignId, trackId } = data;
  return fetchApi(
    `${apiEndpoints.getCampaignInteractions}?campaignId=${campaignId}&trackId=${trackId}`,
    "get",
  );
};

const getCommentsApi = data => {
  const { campaignId, page, limit } = data;
  return fetchApi(
    `${apiEndpoints.getComments}?campaignId=${campaignId}&page=${page}&limit=${limit}`,
    {},
    "get",
  );
};

const getUsersInfosApi = data => {
  const { campaignId } = data;
  return fetchApi(
    `${apiEndpoints.getUsersInfo}?campaignId=${campaignId}`,
    {},
    "get",
  );
};

const getTeamRankingApi = data => {
  const { campaignId, page, limit } = data;
  return fetchApi(
    `${apiEndpoints.getTeamRanking}?campaignId=${campaignId}&page=${page}&limit=${limit}`,
    {},
    "get",
  );
};

const getUserCampaignsListApi = data => {
  const { campaignId, page, limit } = data;
  return fetchApi(
    `${apiEndpoints.getUserCampaignsList}?campaignId=${campaignId}&page=${page}&limit=${limit}`,
    {},
    "get",
  );
};

const getAllCampaignsApi = data => {
  const { clientId } = data;
  return fetchApi(`${apiEndpoints.getAll}?clientId=${clientId}`, {}, "get");
};

const getAllDoneStepsApi = data => {
  const { campaignId } = data;
  return fetchApi(`${apiEndpoints.getAllDoneSteps}/${campaignId}`, {}, "get");
};

const getAllTracksApi = data => {
  const { clientId, limit, page, isDraft = false } = data;
  return fetchApi(
    `${apiEndpoints.getAllTracks}?clientId=${clientId}&page=${page}&limit=${limit}&isDraft=${isDraft}`,
    {},
    "get",
  );
};

const getAllMyClientsTracksApi = data => {
  const { clientId, limit, page } = data;
  return fetchApi(
    `${apiEndpoints.getAllMyClientsTemplates}?clientId=${clientId}&page=${page}&limit=${limit}`,
    {},
    "get",
  );
};

const getAllTemplatesApi = data => {
  const { clientId, limit, page, isPrivate } = data;
  return fetchApi(
    `${apiEndpoints.getAllTemplates}?clientId=${clientId}&page=${page}&limit=${limit}&isPrivate=${isPrivate}`,
    {},
    "get",
  );
};

const createCampaignApi = data => {
  return fetchApi(apiEndpoints.createCampaign, data, "post");
};

const addLikeToCommentaryApi = data => {
  const { stepCommentId, userId } = data;

  return fetchApi(
    `${apiEndpoints.addLikeToCommentary}/${stepCommentId}`,
    { userId },
    "post",
  );
};

const removeLikeFromCommentaryApi = data => {
  const { stepCommentId, userId } = data;

  return fetchApi(
    `${apiEndpoints.removeLikeFromCommentary}/${stepCommentId}`,
    { userId },
    "patch",
  );
};

const validateReviewApi = (data: {
  isValidated: Boolean;
  selectedStatus: ReviewStatusType;
  stepFeedbackId: string;
  userId: string;
  coachId: string;
}) => {
  const { isValidated, selectedStatus, stepFeedbackId, userId, coachId } = data;
  const apiEndpoint = isValidated
    ? apiEndpoints.apiReviewValidated
    : apiEndpoints.apiReviewUpdate;
  const restRequest = isValidated ? "post" : "patch";

  return fetchApi(
    `${apiEndpoint}`,
    {
      stepFeedbackId,
      status: selectedStatus,
      isReviewValidated: isValidated ? true : false,
      userId,
      coachId,
    },
    restRequest,
  );
};

function* validateRandomStepFlow(action) {
  try {
    yield call(validateRandomStepApi, action.payload);

    yield put(
      openSnackMessage({ snackMessage: i18n.t("campaign-modified-success") }),
    );
  } catch (error) {
    console.log(error);
  }
}

function* activateEmailReminderFlow(action) {
  try {
    const result = yield call(activateEmailReminderApi, action.payload);

    yield put({
      type: CAMPAIGN_ACTIVATE_EMAIL_REMINDER_SUCCESS,
      payload: result,
    });
    yield put(
      openSnackMessage({ snackMessage: i18n.t("campaign-modified-success") }),
    );
  } catch (error) {
    yield put({ type: CAMPAIGN_ACTIVATE_EMAIL_REMINDER_ERROR, payload: error });
  }
}

function* commentReviewFlow(action) {
  try {
    const fileToUpload = yield select(getFileToUpload);
    const userId = yield select(getUserId);
    const clientId = yield select(getSelectedClientId);

    const { fileUrl, file, fileName, fileType, type } = fileToUpload;
    const { stepFeedbackId } = action.payload;

    const isImage = hasImageExtension(fileName);
    const isVideo = hasVideoExtension(fileName);
    const isAudio = hasAudioExtension(fileName);

    let commentObject = {
      ...action.payload.comment,
      createdAt: new Date(),
      _id: ObjectID().toHexString(),
      audio: isAudio ? fileUrl : undefined,
      image: isImage ? fileUrl : undefined,
      video: isVideo ? fileUrl : undefined,
    };

    if (fileUrl) {
      let fileUploadResult = {
        payload: { fileType: "", url: "", fileUrl: "" },
      };

      const goodFileType = fileType || type || "video/mp4";

      const urlName = createUploadUrlName({ userId, fileName });

      fileUploadResult = yield uploadFileFlow({
        payload: {
          ...fileToUpload,
          file,
          modelToUpdate: "stepFeedbackComment",
          urlName,
          fileType: goodFileType,
          fileName,
          data: {
            userId,
            correctorId: userId,
            stepFeedbackId,
            commentId: commentObject._id,
            clientId,
          },
        },
      });

      commentObject = {
        ...commentObject,
        audio: isAudio ? fileUploadResult?.payload.fileUrl : undefined,
        image: isImage ? fileUploadResult?.payload.fileUrl : undefined,
        video: isVideo ? fileUploadResult?.payload.fileUrl : undefined,
      };
    }

    const response = yield call(commentReviewApi, {
      ...action.payload,
      ...fileToUpload,
      comment: commentObject,
    });

    yield put({ type: COMMENT_REVIEW_SUCCESS, payload: response });
    yield put(openSnackMessage({ snackMessage: i18n.t("message-send") }));
    yield put(resetUpload());
  } catch (error) {
    yield put({ type: COMMENT_REVIEW_ERROR, payload: error });
    console.log(error);
  }
}

function* modifyCampaignFlow(action) {
  try {
    const result = yield call(modifyCampaignApi, action.payload);

    yield put({ type: CAMPAIGN_MODIFY_SUCCESS, payload: result });
    yield put(
      openSnackMessage({ snackMessage: i18n.t("campaign-modified-success") }),
    );
  } catch (error) {
    yield put({ type: CAMPAIGN_MODIFY_ERROR, payload: error });
  }
}

function* addParticipantsFlow(action) {
  try {
    const { campaignId } = action.payload;
    const result = yield call(addParticipantsApi, action.payload);

    yield put({ type: CAMPAIGN_ADD_PARTICIPANTS_SUCCESS, payload: result });
    // refreshing
    yield all([
      put(
        getUserCampaignsListRequest({
          campaignId,
          page: 0,
          limit: 1000,
        }),
      ),
      put(getCampaignUserRankingRequest({ campaignId, limit: 20, page: 0 })),
      put(getCampaignTeamRankingRequest({ campaignId, limit: 20, page: 0 })),
    ]);

    yield put(
      openSnackMessage({ snackMessage: i18n.t("participants-added-success") }),
    );
  } catch (error) {
    yield put({ type: CAMPAIGN_ADD_PARTICIPANTS_ERROR, payload: error });
  }
}

function* deleteCampaignFlow(action) {
  try {
    const { campaignId } = action.payload;
    yield call(deleteCampaignApi, action.payload);

    yield put({ type: CAMPAIGN_DELETE_SUCCESS, payload: { campaignId } });
    yield put(
      openSnackMessage({ snackMessage: i18n.t("campaign-deleted-success") }),
    );
  } catch (error) {
    yield put({ type: CAMPAIGN_DELETE_ERROR, payload: error });
  }
}

function* deleteUserCampaignFlow(action) {
  try {
    const { userCampaignId } = action.payload;
    const result = yield call(deleteUserCampaignApi, action.payload);

    yield put({
      type: USER_CAMPAIGN_DELETE_SUCCESS,
      payload: { userCampaignId, campaign: result },
    });
    yield put(
      openSnackMessage({ snackMessage: i18n.t("usercampaign-delete-success") }),
    );
  } catch (error) {
    console.log(error);
    yield put({ type: USER_CAMPAIGN_DELETE_ERROR, payload: error });
  }
}

function* deleteTrackFlow(action) {
  try {
    const { trackId } = action.payload;
    yield call(deleteTrackApi, action.payload);

    yield put({ type: TRACK_DELETE_SUCCESS, payload: { trackId } });
    yield put(
      openSnackMessage({ snackMessage: i18n.t("track-deleted-success") }),
    );
  } catch (error) {
    yield put({ type: TRACK_DELETE_ERROR, payload: error });
  }
}

function* resetTeamsFlow(action) {
  try {
    const { campaignId, teamSize } = action.payload;
    yield call(resetTeamsApi, action.payload);

    yield put({ type: CAMPAIGN_RESET_TEAMS_SUCCESS, payload: { campaignId } });
    yield put({
      type: CAMPAIGN_MODIFY_REQUESTING,
      payload: { campaignId, teamSize },
    });
    yield getTeamRankingFlow({ payload: { campaignId, limit: 10, page: 0 } });
  } catch (error) {
    yield put({ type: CAMPAIGN_RESET_TEAMS_ERROR, payload: error });
  }
}

function* getCommentsFlow(action) {
  try {
    const result = yield call(getCommentsApi, action.payload);
    yield put({ type: CAMPAIGN_GET_COMMENTS_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: CAMPAIGN_GET_COMMENTS_ERROR, payload: error });
  }
}

function* getUsersInfosFlow(action) {
  try {
    const result = yield call(getUsersInfosApi, action.payload);
    yield put({ type: CAMPAIGN_GET_USERS_INFOS_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: CAMPAIGN_GET_USERS_INFOS_ERROR, payload: error });
  }
}

function* getUserRankingFlow(action) {
  try {
    const result = yield call(getUserRankingApi, action.payload);
    yield put({ type: CAMPAIGN_GET_USER_RANKING_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: CAMPAIGN_GET_USER_RANKING_ERROR, payload: error });
  }
}

function* getTeamRankingFlow(action) {
  try {
    const result = yield call(getTeamRankingApi, action.payload);
    yield put({ type: CAMPAIGN_GET_TEAM_RANKING_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: CAMPAIGN_GET_TEAM_RANKING_ERROR, payload: error });
  }
}

function* getCampaignInteractions(action) {
  try {
    const { campaignId } = action.payload;
    const trackId = yield select(getCampaignSelectedTrackId);

    const result = yield call(getCampaignInteractionsApi, {
      campaignId,
      trackId,
    });

    yield put({ type: CAMPAIGN_GET_INTERACTIONS_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: CAMPAIGN_GET_INTERACTIONS_ERROR, payload: error });
  }
}

function* getUserCampaignsListFlow(action) {
  try {
    const result = yield call(getUserCampaignsListApi, action.payload);
    yield put({ type: USER_CAMPAIGNS_GET_LIST_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: USER_CAMPAIGNS_GET_LIST_ERROR, payload: error });
  }
}

function* loadUserCampaignsFlow(action) {
  try {
    const result = yield call(getUserCampaignsListApi, action.payload);
    yield delay(1000);
    yield put({ type: USER_CAMPAIGNS_LOAD_MORE_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: USER_CAMPAIGNS_LOAD_MORE_ERROR, payload: error });
  }
}

function* getAllTracksFlow(action) {
  try {
    const result = yield call(getAllTracksApi, action.payload);
    yield put({ type: TRACK_GET_ALL_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: TRACK_GET_ALL_ERROR, payload: error });
  }
}

function* getAllDraftsFlow(action) {
  try {
    const result = yield call(getAllTracksApi, action.payload);
    yield put({ type: CAMPAIGN_GET_ALL_DRAFTS_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: CAMPAIGN_GET_ALL_DRAFTS_ERROR, payload: error });
  }
}

function* getAllMyClientsTracksFlow(action) {
  try {
    const result = yield call(getAllMyClientsTracksApi, action.payload);
    yield put({ type: GET_ALL_MY_CLIENTS_TRACKS_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: GET_ALL_MY_CLIENTS_TRACKS_ERROR, payload: error });
  }
}

function* getAllCampaignsFlow(action) {
  try {
    const result = yield call(getAllCampaignsApi, action.payload);
    yield put({ type: CAMPAIGN_GET_ALL_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: CAMPAIGN_GET_ALL_ERROR, payload: error });
  }
}

function* getAllDoneStepsFlow(action) {
  try {
    const result = yield call(getAllDoneStepsApi, action.payload);
    yield put({ type: CAMPAIGN_GET_ALL_DONE_STEPS_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: CAMPAIGN_GET_ALL_DONE_STEPS_ERROR, payload: error });
  }
}

function* createCampaignFlow(action) {
  try {
    const result = yield call(createCampaignApi, action.payload);
    yield put({ type: CAMPAIGN_CREATE_SUCCESS, payload: result });
    // For forcing rerender and fetching
    yield put(push(`/polls`));
    yield put(push(`/`));

    yield put(selectCampaign(result));
    yield put(
      changeAddUsersModal({
        hasPublishCampaignAnimation: true,
        isAddUsersModalVisible: true,
      }),
    );
  } catch (error) {
    yield put({ type: CAMPAIGN_CREATE_ERROR, payload: error });
  }
}

function* getAllTemplatesFlow(action) {
  try {
    const result = yield call(getAllTemplatesApi, action.payload);

    yield put({ type: CAMPAIGN_GET_ALL_TEMPLATES_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: CAMPAIGN_GET_ALL_TEMPLATES_ERROR, payload: error });
  }
}

function* getAllPublicTemplatesFlow(action) {
  try {
    const result = yield call(getAllTemplatesApi, action.payload);

    yield put({
      type: CAMPAIGN_GET_ALL_PUBLIC_TEMPLATES_SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: CAMPAIGN_GET_ALL__PUBLIC_TEMPLATES_ERROR,
      payload: error,
    });
  }
}

function* addCampaignToTemplateFlow(action) {
  try {
    const { isTemplate, isPremiumDemo } = action.payload;
    // in case it is from a main client for a sub-client
    const clientId = yield select(getUserClientId);
    const result = yield call(addCampaignToTemplatesApi, {
      ...action.payload,
      clientId,
    });

    yield put({ type: CAMPAIGN_ADD_TO_TEMPLATE_SUCCESS, payload: result });
    if (isTemplate) {
      yield put(
        openSnackMessage({
          snackMessage: isTemplate
            ? i18n.t("added-to-template")
            : i18n.t("removed-from-template"),
        }),
      );
    }

    if (isPremiumDemo) {
      yield put(
        openSnackMessage({
          snackMessage: isTemplate
            ? i18n.t("added-to-premium")
            : i18n.t("removed-from-premium"),
        }),
      );
    }
  } catch (error) {
    yield put({ type: CAMPAIGN_ADD_TO_TEMPLATE_ERROR, payload: error });
  }
}

function* removeDraftFlow(action) {
  try {
    const result = yield call(modifyTrackApi, action.payload);

    yield put({ type: DRAFT_REMOVE_SUCCESS, payload: result });
    yield put(
      openSnackMessage({
        snackMessage: i18n.t("draft-removed"),
      }),
    );
  } catch (error) {
    yield put({ type: DRAFT_REMOVE_ERROR, payload: error });
  }
}

function* selectCampaignFlow(action) {
  try {
    const result = yield call(getCampaignApi, action.payload);

    yield put({ type: SELECT_CAMPAIGN_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: SELECT_CAMPAIGN_ERROR, payload: error });
  }
}

function* seeCommentsReviewFlow(action) {
  try {
    const { stepFeedbackId } = action.payload;

    const response = yield call(seeCommentsReviewApi, { stepFeedbackId });
    yield put({ type: SEE_COMMENTS_REVIEW_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: SEE_COMMENTS_REVIEW_ERROR, payload: error });
  }
}

function* getFormatedDoneStepsFlow(action) {
  try {
    const { campaignId, userReviewedId, doneSteps } = action.payload;
    const userCampaigns = yield call(getUserCampaignsListApi, campaignId);
    const language = yield select(getAppLanguage());

    const formatedDoneSteps = doneSteps.map(step => {
      const result = { ...step };
      const user = userCampaigns.find(el => el.userId === userReviewedId) || {
        firstName: "",
        lastName: "",
        picture: "",
      };
      result.firstName = user?.firstName;
      result.lastName = user?.lastName;
      result.picture = user?.picture;

      result.status = result?.isReviewValidated
        ? "validated"
        : result?.status || "new";

      const { category, skills = [], content }: any = result?.stepId || {
        skills: [{ label: { fr: "", en: "" } }],
      };

      result.category =
        skills && skills.length > 0
          ? selectLanguage({ text: skills[0]?.label, language })
          : category;

      result.totalComments = result?.comments?.length;
      // for fuse search

      result.title = removeHtmlTags(
        selectLanguage({ text: content?.title, language }),
      );

      return result;
    });

    yield put({
      type: CAMPAIGN_GET_ALL_DONE_STEPS_FORMATED_SUCCESS,
      payload: formatedDoneSteps,
    });
  } catch (error) {
    yield put({
      type: CAMPAIGN_GET_ALL_DONE_STEPS_FORMATED_ERROR,
      payload: error,
    });
  }
}

function* getNextReviewFlow() {
  try {
    const sortedDoneSteps = yield select(getUserCampaignSortedDoneSteps);
    const reviewId = yield select(getCampaignSelectedDoneStep);
    const campaignId = yield select(getSelectedCampaignId);
    let newPath = `/campaigns/${campaignId}`;
    let newReviewId = "";

    const selectedReviewIndex = yield sortedDoneSteps.findIndex(
      el => el._id === reviewId,
    );
    if (selectedReviewIndex + 1 !== sortedDoneSteps.length) {
      newReviewId = sortedDoneSteps[selectedReviewIndex + 1]._id;
      newPath = `/campaigns/${campaignId}/review/${newReviewId}`;
    }

    yield put(setSelectedDoneStep(newReviewId));
    yield put(push(newPath));
    yield put({ type: CAMPAIGN_GET_NEXT_DONE_STEP_REVIEW_SUCCESS });
  } catch (error) {
    yield put({ type: CAMPAIGN_GET_NEXT_DONE_STEP_REVIEW_ERROR });
    console.log(error);
  }
}

function* getPreviousReviewFlow() {
  try {
    const sortedDoneSteps = yield select(getUserCampaignSortedDoneSteps);
    const reviewId = yield select(getCampaignSelectedDoneStep);
    const campaignId = yield select(getSelectedCampaignId);
    let newPath = `/campaigns/${campaignId}`;
    let newReviewId = "";

    const selectedReviewIndex = yield sortedDoneSteps.findIndex(
      el => el._id === reviewId,
    );
    if (selectedReviewIndex - 1 >= 0) {
      newReviewId = sortedDoneSteps[selectedReviewIndex - 1]._id;
      newPath = `/campaigns/${campaignId}/review/${newReviewId}`;
    }

    yield put(setSelectedDoneStep(newReviewId));
    yield put(push(newPath));
  } catch (error) {
    console.log(error);
  }
}

function* validateReviewFlow(action) {
  try {
    const { selectedStatus, stepFeedbackId, userId, comment } = action.payload;
    const coachId = yield select(getUserId);

    if (comment) {
      yield commentReviewFlow({
        payload: { comment, stepFeedbackId, userId, coachId },
      });
    }

    const isValidated = selectedStatus === "validated";
    yield call(validateReviewApi, {
      isValidated,
      selectedStatus,
      stepFeedbackId,
      userId,
      coachId,
    });

    yield put({ type: CAMPAIGN_GET_NEXT_DONE_STEP_REVIEW });

    yield put({
      type: CAMPAIGN_VALIDATE_REVIEW_SUCCESS,
      payload: { reviewId: stepFeedbackId, isReviewValidated: isValidated },
    });
    yield put(
      openSnackMessage({
        snackMessage: i18n.t(
          isValidated ? "review-validated-success" : "review-updated-success",
        ),
      }),
    );
    yield put(resetUpload());
  } catch (error) {
    yield put({
      type: CAMPAIGN_VALIDATE_REVIEW_ERROR,
      error,
    });
  }
}

function* getCampaignStepsFlow(action) {
  try {
    const { trackId } = action.payload;

    const result = yield call(getCampaignStepsApi, {
      trackId,
    });

    yield put({ type: CAMPAIGN_GET_STEPS_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: CAMPAIGN_SEND_EMAIL_RECAP_ERROR, error });
  }
}

function* sendEmailRecapFlow(action) {
  try {
    const { email } = action.payload;

    yield select(getCampaignSelectedActionPerParticipants);
    yield select(getCampaignSelectedParticipationRate);
    const participants = yield select(getCampaignSelectedParticipants);
    const views = yield select(getCampaignSelectedTotalViews);
    const totalDoneActions = yield select(getCampaignSelectedTotalDoneAction);
    const averageRating = yield select(getCampaignSelectedAverageRating);
    const completionRate = yield select(
      getCampaignSelectedAverageCompletionRate,
    );

    const firstName = yield select(getUserFirstName);
    const userCampaigns = yield select(getUserCampaigns);
    const userRanking = yield select(getUserRanking);
    const teams = yield select(getTeamRanking);
    const comments = yield select(getCampaignSelectedLongestComments);
    const actions = yield select(getCampaignSelectedBestActions);
    const activeUsers = yield select(getCampaignSelectedActiveUsers);
    const clientName = yield select(getSelectedClientName);

    const players = cloneDeep(userRanking);

    userCampaigns.forEach(userInfo => {
      players.forEach(user => {
        if (user._id === userInfo.userId) {
          user.actions = displayNumberOfActionDone(userInfo.steps);
        }
      });
    });

    const result = yield call(sendCampaignEmailRecapApi, {
      email,
      views,
      viewPerUser: (views / participants.length).toFixed(1),
      activeUsers,
      averageRating,
      completionRate,
      totalDoneActions,
      averageDoneAction: (totalDoneActions / participants.length).toFixed(1),
      players,
      teams,
      actions,
      comments,
      clientName,
      firstName,
    });

    yield put(openSnackMessage({ snackMessage: i18n.t("report-sent") }));

    yield put({ type: CAMPAIGN_SEND_EMAIL_RECAP_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: CAMPAIGN_SEND_EMAIL_RECAP_ERROR, error });
  }
}

function* getStepCommentsRequestByStepIdFlow(action) {
  try {
    const { stepId } = action?.payload || {};

    const response = yield call(getStepCommentsByStepIdByAdminApi, { stepId });

    yield put({ type: GET_STEP_COMMENT_SUCCESS, payload: response });
  } catch (error) {
    console.log("error", error);
    yield put({ type: GET_STEP_COMMENT_ERROR, payload: error?.message });
  }
}

function* createEventFlow(action) {
  try {
    const { title, type, date, data } = action.payload;

    const userId = yield select(getUserId);
    const campaignId = yield select(getSelectedCampaignId);

    const result = yield call(createEventApi, {
      title,
      type,
      data,
      campaignId,
      date,
      user: userId,
    });
    yield put({ type: CAMPAIGN_CREATE_EVENT_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: CAMPAIGN_CREATE_EVENT_ERROR, error });
  }
}

function* updateEventFlow(action) {
  try {
    const { eventId } = action.payload;

    delete action.payload.eventId;
    const response = yield call(updateEventsApi, eventId, action.payload);
    yield put(
      openSnackMessage({
        snackMessage: i18n.t("event-updated"),
      }),
    );
    yield put({ type: CAMPAIGN_UPDATE_EVENT_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: CAMPAIGN_UPDATE_EVENT_ERROR, payload: error });
  }
}

function* hideStepCommentFlow(action) {
  try {
    const { stepCommentId } = action.payload;

    const response = yield call(hideStepCommentApi, { stepCommentId });

    yield put({ type: HIDE_STEP_COMMENT_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: HIDE_STEP_COMMENT_ERROR, payload: error?.message });
  }
}

function* deleteEventFlow(action) {
  try {
    yield call(deleteEventsApi, action.payload);
    yield put(
      openSnackMessage({
        snackMessage: i18n.t("event-deleted"),
      }),
    );
    yield put({ type: CAMPAIGN_DELETE_EVENT_SUCCESS, payload: action.payload });
  } catch (error) {
    yield put({ type: CAMPAIGN_DELETE_EVENT_ERROR, payload: error });
    console.log(error);
  }
}

function* regenerateCertificateFlow(action) {
  try {
    const result = yield call(regenerateCertificateApi, action.payload);

    yield put(
      openSnackMessage({
        snackMessage: i18n.t("certificate-regenerated"),
      }),
    );
    yield put({
      type: CAMPAIGN_REGENERATE_CERTIFICATE_SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({ type: CAMPAIGN_REGENERATE_CERTIFICATE_ERROR, payload: error });
  }
}

function* addLikeToCommentaryFlow(action) {
  try {
    const { stepCommentId } = action.payload;
    const userId = yield select(getUserId);

    yield call(addLikeToCommentaryApi, {
      stepCommentId,
      userId,
    });

    yield put({
      type: ADD_LIKE_STEP_COMMENT_SUCCESS,
      payload: { userId, stepCommentId },
    });
  } catch (error) {
    yield put({ type: ADD_LIKE_STEP_COMMENT_ERROR, payload: error?.message });
    console.log(error);
  }
}

function* removeLikeFromCommentaryFlow(action) {
  try {
    const { stepCommentId } = action.payload;
    const userId = yield select(getUserId);

    yield call(removeLikeFromCommentaryApi, {
      stepCommentId,
      userId,
    });

    yield put({
      type: REMOVE_LIKE_STEP_COMMENT_SUCCESS,
      payload: { userId, stepCommentId },
    });
  } catch (error) {
    yield put({
      type: REMOVE_LIKE_STEP_COMMENT_ERROR,
      payload: error?.message,
    });
    console.log(error);
  }
}

function* Saga() {
  yield all([
    takeLatest(CAMPAIGN_ADD_PARTICIPANTS_REQUESTING, addParticipantsFlow),
    takeLatest(CAMPAIGN_GET_ALL_REQUESTING, getAllCampaignsFlow),
    takeLatest(CAMPAIGN_CREATE_REQUESTING, createCampaignFlow),
    takeLatest(TRACK_GET_ALL_REQUESTING, getAllTracksFlow),
    takeLatest(USER_CAMPAIGN_DELETE_REQUESTING, deleteUserCampaignFlow),
    takeLatest(CAMPAIGN_GET_ALL_DONE_STEPS_REQUESTING, getAllDoneStepsFlow),
    takeLatest(CAMPAIGN_GET_COMMENTS_REQUESTING, getCommentsFlow),
    takeLatest(CAMPAIGN_GET_USER_RANKING_REQUESTING, getUserRankingFlow),
    takeLatest(CAMPAIGN_GET_TEAM_RANKING_REQUESTING, getTeamRankingFlow),
    takeLatest(CAMPAIGN_GET_ALL_TEMPLATES_REQUESTING, getAllTemplatesFlow),
    takeLatest(USER_CAMPAIGNS_GET_LIST_REQUESTING, getUserCampaignsListFlow),
    takeLatest(USER_CAMPAIGNS_LOAD_MORE_REQUESTING, loadUserCampaignsFlow),
    takeLatest(CAMPAIGN_MODIFY_REQUESTING, modifyCampaignFlow),
    takeLatest(
      CAMPAIGN_ACTIVATE_EMAIL_REMINDER_REQUESTING,
      activateEmailReminderFlow,
    ),
    takeLatest(CAMPAIGN_RESET_TEAMS_REQUESTING, resetTeamsFlow),
    takeLatest(CAMPAIGN_DELETE_REQUESTING, deleteCampaignFlow),
    takeLatest(TRACK_DELETE_REQUESTING, deleteTrackFlow),
    takeLatest(GET_ALL_MY_CLIENTS_TRACKS_REQUESTING, getAllMyClientsTracksFlow),
    takeLatest(CAMPAIGN_GET_ALL_DRAFTS_REQUESTING, getAllDraftsFlow),
    takeLatest(CAMPAIGN_ADD_TO_TEMPLATE_REQUESTING, addCampaignToTemplateFlow),
    takeLatest(
      CAMPAIGN_GET_ALL_PUBLIC_TEMPLATES_REQUESTING,
      getAllPublicTemplatesFlow,
    ),
    takeLatest(DRAFT_REMOVE_REQUESTING, removeDraftFlow),
    takeLatest(VALIDATE_RANDOM_STEP_REQUESTING, validateRandomStepFlow),
    takeLatest(CAMPAIGN_GET_USERS_INFOS_REQUESTING, getUsersInfosFlow),
    takeLatest(SELECT_CAMPAIGN_REQUESTING, selectCampaignFlow),
    takeLatest(COMMENT_REVIEW_REQUESTING, commentReviewFlow),
    takeLatest(SEE_COMMENTS_REVIEW_REQUESTING, seeCommentsReviewFlow),
    takeLatest(
      CAMPAIGN_GET_ALL_DONE_STEPS_FORMATED_REQUESTING,
      getFormatedDoneStepsFlow,
    ),
    takeLatest(CAMPAIGN_GET_NEXT_DONE_STEP_REVIEW, getNextReviewFlow),
    takeLatest(CAMPAIGN_VALIDATE_REVIEW_REQUESTING, validateReviewFlow),
    takeLatest(CAMPAIGN_GET_PREVIOUS_DONE_STEP_REVIEW, getPreviousReviewFlow),
    takeLatest(CAMPAIGN_SEND_EMAIL_RECAP_REQUESTING, sendEmailRecapFlow),
    takeLatest(CAMPAIGN_GET_STEPS_REQUESTING, getCampaignStepsFlow),
    takeLatest(GET_STEP_COMMENT_REQUESTING, getStepCommentsRequestByStepIdFlow),
    takeLatest(HIDE_STEP_COMMENT_REQUESTING, hideStepCommentFlow),
    takeLatest(CAMPAIGN_GET_INTERACTIONS_REQUESTING, getCampaignInteractions),
    takeLatest(CAMPAIGN_CREATE_EVENT_REQUESTING, createEventFlow),
    takeLatest(CAMPAIGN_UPDATE_EVENT_REQUESTING, updateEventFlow),
    takeLatest(CAMPAIGN_DELETE_EVENT_REQUESTING, deleteEventFlow),
    takeLatest(CAMPAIGN_GET_INTERACTIONS_REQUESTING, getCampaignInteractions),
    takeLatest(
      CAMPAIGN_REGENERATE_CERTIFICATE_REQUESTING,
      regenerateCertificateFlow,
    ),
    takeLatest(ADD_LIKE_STEP_COMMENT_REQUESTING, addLikeToCommentaryFlow),
    takeLatest(
      REMOVE_LIKE_STEP_COMMENT_REQUESTING,
      removeLikeFromCommentaryFlow,
    ),
  ]);
}

export default Saga;
