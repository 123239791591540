import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Colors from "../../../constants/Colors";
import ChatIcon from "@mui/icons-material/Chat";
import moment from "moment";
import {
  getChatMessagesRequest,
  seeConversation,
  openConversationRequest,
} from "../../../services/message/actions";
import { navigateCreateTrack } from "../../../services/createTrack/actions";
import { createCoachingSessionRequest } from "services/coaching/actions";
import { sendMessage } from "../../../services/realTime/sagas";
import { Launcher } from "../../../lib/src/index";

import { connect } from "react-redux";
import GoalsModal from "./GoalsModal";
import {
  getIsSuperAdmin,
  getSelectedClientId,
  getUserId,
} from "../../../services/user/selectors";
import i18n from "../../../i18n";
import { getOrderedMessages } from "services/message/selectors";
import {
  getCoachingRequesting,
  getHasCoachingSession,
  getIsCoachingFinished,
} from "services/coaching/selectors";
import FlatButton from "components/FlatButton";
import { Loader, PhoneIcon } from "components";
import {
  getIsCoachingAccount,
  getSelectedClientIsPhoneNumberRequired,
} from "services/client/selectors";
import { changeAddUsersModal } from "services/campaign/actions";
import {
  getCampaignSelectedTitle,
  getIsCoachingCampaign,
  getSelectedCampaignId,
  getCampaignSelectedTrackId,
} from "services/campaign/selectors";
import QrCodeModal from "scenes/Campaigns/SeeUserModal/QrCodeModal";
import { getIsUserSelectedATestAccount } from "services/manager/selectors";
import CheckIcon from "scenes/CreateTrack/Folders/CheckIcon";
import EndCoachingModal from "scenes/Campaigns/SeeUserModal/Coaching/EndCoaching/EndCoachingModal";
import LightIcon from "scenes/CreateTrack/LightIcon";
import selectLanguage from "utils/selectLanguage";
import TestAccountLabel from "scenes/MyCoachees/TestAccountLabel";
import EditUserInfoModal from "scenes/Campaigns/SeeUserModal/EditUserInfoModal";
import CoachingFilesModal from "scenes/Campaigns/SeeUserModal/Coaching/CoachingFiles/CoachingFilesModal";
import CoachingFileIcon from "scenes/Campaigns/SeeUserModal/CoachingFileIcon";

moment.locale("fr");

const MIN_PICTURE_HEIGHT = 200;
const MAX_PICTURE_HEIGHT = 250;

const EditIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5306 3.91015L15.6486 6.02715L13.5306 3.91015ZM14.8926 2.04515L9.16564 7.77215C8.86973 8.06765 8.66792 8.44413 8.58564 8.85415L8.05664 11.5021L10.7046 10.9721C11.1146 10.8901 11.4906 10.6891 11.7866 10.3931L17.5136 4.66615C17.6857 4.49405 17.8223 4.28974 17.9154 4.06488C18.0085 3.84003 18.0565 3.59903 18.0565 3.35565C18.0565 3.11226 18.0085 2.87126 17.9154 2.64641C17.8223 2.42155 17.6857 2.21724 17.5136 2.04515C17.3415 1.87305 17.1372 1.73653 16.9124 1.6434C16.6875 1.55026 16.4465 1.50232 16.2031 1.50232C15.9598 1.50232 15.7188 1.55026 15.4939 1.6434C15.269 1.73653 15.0647 1.87305 14.8926 2.04515V2.04515Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0566 13.5022V16.5022C16.0566 17.0326 15.8459 17.5413 15.4709 17.9164C15.0958 18.2915 14.5871 18.5022 14.0566 18.5022H3.05664C2.52621 18.5022 2.0175 18.2915 1.64243 17.9164C1.26735 17.5413 1.05664 17.0326 1.05664 16.5022V5.5022C1.05664 4.97176 1.26735 4.46306 1.64243 4.08798C2.0175 3.71291 2.52621 3.5022 3.05664 3.5022H6.05664"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const styles = theme => ({
  leftDiv: {
    backgroundColor: Colors.darkBlue,
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  pictureDiv: {
    marginBottom: 30,
    width: "100%",
    minHeight: MIN_PICTURE_HEIGHT,
    position: "relative",
    background: Colors.darkBlue,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  picture: {
    minHeight: MIN_PICTURE_HEIGHT,
    maxHeight: MAX_PICTURE_HEIGHT,
    objectFit: "cover",
    filter: "brightness(0.6)",
    flex: 1,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    width: "100%",
  },
  topLeft: {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  bottomLeft: {
    flex: 1,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "column",
    padding: 10,
  },
  nameDiv: {
    position: "absolute",
    bottom: 45,
    left: 0,
    marginLeft: 30,
    display: "flex",
    flexDirection: "column",
  },
  name: {
    fontSize: 22,
    color: "#fff",
    fontWeight: "bold",
    fontFamily: "Montserrat",
  },
  allButtons: {
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  buttonDiv: {
    marginBottom: 20,
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  messageButton: {
    fontWeight: "500",
    marginBottom: 15,
    fontSize: 14,
    color: "#fff",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    width: 200,
    paddingLeft: 0,
    paddingRight: 0,
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": {
      opacity: 1,
      backgroundColor: Colors.pureMain,
      borderWidth: 1,
      borderColor: Colors.pureMain,
    },
    "&:focus": {
      backgroundColor: "rgba(255, 255, 255, 0.2)",
    },
  },
  mbti: {
    textAlign: "left",
    color: "#fff",
    fontWeight: "600",
    fontFamily: "Montserrat",
    fontSize: 14,
    marginBottom: 5,
  },
  text: {
    fontSize: 12,
    textAlign: "justify",
    color: "#fff",
  },
  jobDescription: {
    marginBottom: 5,
    textAlign: "left",
    color: "#fff",
    fontWeight: "bold",
    fontFamily: "Montserrat",
    fontSize: 16,
  },
  textDiv: {
    marginBottom: 25,
    width: "-webkit-fill-available",
    paddingLeft: 30,
    paddingRight: 40,
  },
  inviteButton: {
    fontSize: 14,
    minWidth: 200,
    width: 200,
    maxWidth: 200,
    marginBottom: 15,
  },
  coachingButton: {
    fontSize: 14,
    minWidth: 200,
  },
  testCoachingButton: {
    marginBottom: 15,
    fontSize: 14,
    color: "black",
    backgroundColor: "#fff",
    height: 40,
    width: 200,
    paddingLeft: 0,
    paddingRight: 0,
    cursor: "pointer",
    transition: "all 0.2s easeIn",
    "&:hover": {
      backgroundColor: Colors.hoverBlue,
      borderWidth: 1,
      borderColor: Colors.pureMain,
    },
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  testIcon: {
    marginRight: 12,
  },
  messageIcon: {
    fontSize: "1rem",
    marginRight: "12px",
    marginTop: "3px",
  },
  email: {
    width: 200,
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    color: "#fff",
    marginBottom: 20,
  },
  endCoachingButton: {
    backgroundColor: "rgba(92, 182, 246, 0.2)",
    color: "#5CB6F6",
    fontSize: 14,
    marginBottom: 15,
    fontWeight: "normal",
    height: 40,
    width: 200,
    paddingLeft: 0,
    paddingRight: 0,
    "&:hover": {
      backgroundColor: "rgba(92, 182, 246, 0.3)",
      borderWidth: 1,
      borderColor: Colors.pureMain,
    },
    "&:focus": {
      backgroundColor: "rgba(92, 182, 246, 0.2)",
    },
  },
  editIcon: {
    zIndex: 10,
    position: "absolute",
    top: 5,
    right: 15,
  },
});

class LeftUserProfile extends Component {
  state = {
    isGoalsModalVisible: false,
    isConversationOpen: false,
    isQrCodeModalVisible: false,
    isEndCoachingModalVisible: false,
    isEditUserModalVisible: false,
    isCoachingFilesModalVisible: false,
    messageList: [],
  };

  closeGoals = () => {
    this.setState({ isGoalsModalVisible: false });
  };

  createCoachingSession = () => {
    const {
      userId,
      userSelected,
      clientId,
      senderFirstName,
      senderLastName,
      senderPicture,
    } = this.props;
    const coachName = `${senderFirstName} ${senderLastName}`;

    this.props.createCoachingSessionRequest({
      clientId,
      coachId: userId,
      userId: userSelected._id,
      coachName,
      coachPicture: senderPicture,
    });
  };

  openConversation = () => {
    if (!this.state.isConversationOpen) {
      const {
        userId,
        senderPicture,
        senderFirstName,
        senderLastName,
        userSelected,
      } = this.props;

      const receiver = {
        _id: userSelected._id,
        picture: userSelected.picture,
        name: `${userSelected.firstName} ${userSelected.lastName}`,
      };
      this.props.openConversationRequest({
        sender: {
          _id: userId,
          picture: senderPicture,
          name: `${senderFirstName} ${senderLastName}`,
        },
        receiver,
      });
    }

    this.setState({ isConversationOpen: !this.state.isConversationOpen });
  };

  _onMessageWasSent = message => {
    const {
      userId,
      senderPicture,
      senderFirstName,
      senderLastName,
      userSelected,
      conversationId,
    } = this.props;

    sendMessage({
      text: message.data.text,
      user: {
        _id: userId,
        avatar: senderPicture,
        name: `${senderFirstName} ${senderLastName}`,
      },
      conversationId: conversationId,
      receiverId: userSelected._id,
    });
  };

  inviteUser = () => {
    this.props.changeAddUsersModal({
      isAddUsersModalVisible: true,
      hasPublishCampaignAnimation: false,
    });
    this.setState({ isInviteUsersModalVisible: true });
  };

  handleClickTest = () => {
    this.setState({ isQrCodeModalVisible: true });
  };

  handleCloseQrCodeModal = () => {
    this.setState({ isQrCodeModalVisible: false });
  };

  handleEndCoaching = () => {
    this.setState({ isEndCoachingModalVisible: true });
  };

  handleCloseEndCoachingModal = () => {
    this.setState({ isEndCoachingModalVisible: false });
  };

  handleSeeCoachingActions = () => {
    const { trackId, campaignId, campaignSelectedTitle, language } = this.props;
    const campaignName = selectLanguage({
      text: campaignSelectedTitle,
      language,
    });
    this.props.navigateCreateTrack({
      trackId,
      isEditingCampaign: true,
      isCoachingCampaign: true,
      campaignId,
      campaignName,
    });
  };

  openEditUserModal = () => {
    this.setState({ isEditUserModalVisible: true });
  };

  handleCloseEditUserModal = () => {
    this.setState({ isEditUserModalVisible: false });
  };

  handleCloseCoachingFilesModal = () => {
    this.setState({ isCoachingFilesModalVisible: false });
  };

  openCoachingFilesModal = () => {
    this.setState({ isCoachingFilesModalVisible: true });
  };

  render() {
    const {
      classes,
      userSelected,
      isCoachingAccount,
      isUserSelectedATestAccount,
      hasCoaching,
      isCoachingFinished,
      isSuperAdmin,
    } = this.props;
    const {
      picture,
      firstName,
      lastName,
      bio,
      jobDescription,
      mbti,
      coachingAccessCode,
      hasCompleteOnboarding,
      email,
      phoneNumber,
    } = userSelected;

    return (
      <div className={classes.leftDiv}>
        {isCoachingAccount && (
          <>
            <QrCodeModal
              isVisible={this.state.isQrCodeModalVisible}
              onClose={this.handleCloseQrCodeModal}
            />
            <EditUserInfoModal
              isVisible={this.state.isEditUserModalVisible}
              onClose={this.handleCloseEditUserModal}
            />
            <CoachingFilesModal
              isVisible={this.state.isCoachingFilesModalVisible}
              onClose={this.handleCloseCoachingFilesModal}
            />
          </>
        )}
        {hasCoaching && (
          <EndCoachingModal
            isVisible={this.state.isEndCoachingModalVisible}
            onClose={this.handleCloseEndCoachingModal}
          />
        )}
        <GoalsModal
          isVisible={this.state.isGoalsModalVisible}
          onClose={this.closeGoals}
        />
        <div className={classes.topLeft}>
          <div className={classes.pictureDiv}>
            {isCoachingAccount && isUserSelectedATestAccount && (
              <TestAccountLabel />
            )}
            {isCoachingAccount && (
              <IconButton
                className={classes.editIcon}
                onClick={this.openEditUserModal}
                size="large"
              >
                <EditIcon />
              </IconButton>
            )}
            <img src={picture} alt={firstName} className={classes.picture} />
            <div className={classes.nameDiv}>
              <Typography className={classes.name}>{`${firstName}`}</Typography>
              <Typography className={classes.name}>{`${lastName}`}</Typography>
            </div>
          </div>
          {(!!jobDescription || !!mbti || !!bio) && (
            <div className={classes.textDiv}>
              {jobDescription && (
                <Typography
                  className={classes.jobDescription}
                >{`${jobDescription}`}</Typography>
              )}
              {mbti && (
                <Typography className={classes.mbti}>{`${mbti}`}</Typography>
              )}
              {bio && (
                <Typography className={classes.text}>{`${bio}`}</Typography>
              )}
            </div>
          )}
          {!!email && isCoachingAccount && (
            <Typography className={classes.email}>{email}</Typography>
          )}
          {!!phoneNumber &&
            isSuperAdmin &&
            this.state.isPhoneNUmberRequired && (
              <Typography className={classes.email}>{phoneNumber}</Typography>
            )}
          <div className={classes.allButtons}>
            {isCoachingAccount && isUserSelectedATestAccount && (
              <FlatButton
                title={i18n.t("test-my-coaching")}
                icon={<PhoneIcon className={classes.testIcon} />}
                className={classes.testCoachingButton}
                onClick={this.handleClickTest}
              />
            )}
            {!hasCompleteOnboarding && !!coachingAccessCode && (
              <>
                <FlatButton
                  className={classes.inviteButton}
                  onClick={this.inviteUser}
                  title={i18n.t("invite-x", { firstName })}
                />
              </>
            )}
            <div className={classes.buttonDiv}>
              <FlatButton
                icon={
                  <ChatIcon className={classes.messageIcon} fontSize="small" />
                }
                className={classes.messageButton}
                onClick={this.openConversation}
                title={i18n.t("discuss")}
              />
              {this.props.hasCoaching && this.props.isCoachingCampaign && (
                <FlatButton
                  title={i18n.t("coaching-content-default")}
                  className={classes.messageButton}
                  onClick={this.handleSeeCoachingActions}
                  icon={
                    <LightIcon color={"#fff"} className={classes.testIcon} />
                  }
                />
              )}
              {this.props.hasCoaching && isCoachingAccount && (
                <FlatButton
                  title={i18n.t("coaching-file-safe")}
                  className={`${classes.messageButton}`}
                  onClick={this.openCoachingFilesModal}
                  icon={<CoachingFileIcon className={classes.testIcon} />}
                />
              )}
            </div>
            {this.props.coachingRequesting ? (
              <Loader size={25} />
            ) : (
              !this.props.hasCoaching &&
              !isCoachingAccount && (
                <FlatButton
                  intercomTarget={"invite-coachee"}
                  className={classes.coachingButton}
                  onClick={this.createCoachingSession}
                  title={i18n.t("start-coaching")}
                />
              )
            )}
          </div>
        </div>
        <div className={classes.bottomLeft}>
          {this.props.hasCoaching && (
            <FlatButton
              title={i18n.t(
                isCoachingFinished ? "see-summary" : "end-coaching",
              )}
              className={classes.endCoachingButton}
              onClick={this.handleEndCoaching}
              icon={
                isCoachingFinished ? null : (
                  <CheckIcon
                    color={Colors.pureMain}
                    className={classes.testIcon}
                  />
                )
              }
            />
          )}
          {this.state.isConversationOpen && (
            <Launcher
              agentProfile={{
                teamName: `${firstName} ${lastName}`,
                imageUrl: picture,
              }}
              isOpen={this.state.isConversationOpen}
              handleClick={this.openConversation}
              authorId={this.props.userId}
              onMessageWasSent={this._onMessageWasSent}
              messageList={this.props.messages}
              showEmoji
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    receiver,
    conversationId,
    messagePage,
    loadingMessage,
  } = state.message;
  const { userSelected } = state.manager;
  const userId = getUserId(state);
  const senderPicture = state.user.picture;
  const senderFirstName = state.user.firstName;
  const senderLastName = state.user.lastName;

  return {
    isPhoneNUmberRequired: getSelectedClientIsPhoneNumberRequired(state),
    campaignSelectedTitle: getCampaignSelectedTitle(state),
    campaignId: getSelectedCampaignId(state),
    trackId: getCampaignSelectedTrackId(state),
    isCoachingCampaign: getIsCoachingCampaign(state),
    isCoachingAccount: getIsCoachingAccount(state),
    isCoachingFinished: getIsCoachingFinished(state),
    isUserSelectedATestAccount: getIsUserSelectedATestAccount(state),
    senderFirstName,
    senderLastName,
    senderPicture,
    clientId: getSelectedClientId(state),
    messages: getOrderedMessages(state),
    hasCoaching: getHasCoachingSession(state),
    coachingRequesting: getCoachingRequesting(state),
    userId,
    receiver,
    conversationId,
    messagePage,
    loadingMessage,
    userSelected,
    isSuperAdmin: getIsSuperAdmin(state),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    getChatMessagesRequest,
    seeConversation,
    openConversationRequest,
    createCoachingSessionRequest,
    changeAddUsersModal,
    navigateCreateTrack,
  })(LeftUserProfile),
);
