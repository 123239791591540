import React, { useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import i18n from "i18n";
import { MessageBoxInput } from "components";
import { ReviewStatusType } from "./types";
import Colors from "constants/Colors";
import CheckRow from "components/CheckRow";
import CheckIcon from "components/CheckIcon";
import ModifyPencil from "./icons/ModifyPencil";
import FlatButton from "components/FlatButton";
import { MainTitle } from "components/Titles/MainTitle";
import useReviewStyle from "./review.css";
import { useSelector } from "react-redux";
import { getIsReviewRequesting } from "services/campaign/selectors";
import RecordBar from "./Record/RecordBar";
import { RecordingOptionType } from "./Record/types";
import { getFileToUpload } from "services/upload/selectors";

const useStyles = makeStyles(theme => ({
  main: {
    boxSizing: "border-box",
    flex: 1.2,
    paddingRight: 40,
    paddingLeft: 40,
    paddingTop: 30,
    paddingBottom: 70,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
  },
  blockDiv: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
  },
  checkRow: {
    width: "100%",
  },
  button: {
    height: 60,
    width: "100%",
    borderRadius: 6,
  },
  messageTextSend: {
    backgroundColor: Colors.veryLightBlue,
    color: Colors.pureMain,
    "&:hover": {
      boxShadow: "none",
      opacity: 0.8,
      color: Colors.veryLightBlue,
      backgroundColor: Colors.pureMain,
    },
  },
  textBox: {
    width: "100%",
    border: "solid 1px #E7E7E7",
    borderRadius: 5,
  },
  textBoxFocused: {
    borderColor: Colors.pureMain,
  },
  sendBox: {
    width: "100%",
    textAlign: "end",
  },
  actionsBar: {
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
}));

interface Props {
  status: ReviewStatusType;
  onSelectOption: (status: ReviewStatusType) => void;
  onChangeCorrectorComment: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSendComment: () => void;
  onPressNextReview: () => void;
  comment: string;
  comments: any[];
}

const CorrectorFeedback = ({
  status = "new",
  onChangeCorrectorComment,
  onSelectOption = null,
  onSendComment,
  comment = "",
  comments = [],
  onPressNextReview,
}: Props) => {
  const classes = useStyles();
  const classesReviewStyle = useReviewStyle();
  const isReviewRequesting = useSelector(getIsReviewRequesting);
  const fileToUpload = useSelector(getFileToUpload);

  const [isCommentaryFocused, setIsCommentaryFocused] = useState(false);

  const [recordingOptionSelected, selectRecordingOption] = useState<
    RecordingOptionType | ""
  >("");

  return (
    <div className={classes.main}>
      <div
        className={`${classes.blockDiv} ${classesReviewStyle.reviewDivSeparator}`}
      >
        <MainTitle
          size="medium"
          text={i18n.t(`challenge-commentary`)}
          className={classesReviewStyle.elementSeparator}
        />
        {!recordingOptionSelected && (
          <div
            className={`${classes.textBox} ${
              isCommentaryFocused && classes.textBoxFocused
            } ${classesReviewStyle.elementSeparator}`}
          >
            <MessageBoxInput
              value={comment}
              autoFocus={false}
              onChange={onChangeCorrectorComment}
              setIsFocused={setIsCommentaryFocused}
            />

            <div className={classes.sendBox}>
              <FlatButton
                className={classes.messageTextSend}
                requesting={isReviewRequesting}
                title={i18n.t("send")}
                onClick={onSendComment}
                size="large"
              />
            </div>
          </div>
        )}

        <div className={classes.actionsBar}>
          <RecordBar
            requesting={isReviewRequesting}
            onSubmit={onSendComment}
            setOptionSelected={selectRecordingOption}
            optionSelected={recordingOptionSelected}
          />
        </div>
      </div>
      <div
        className={`${classes.blockDiv} ${classesReviewStyle.reviewDivSeparator}`}
      >
        <MainTitle
          size="medium"
          text={i18n.t(`challenge-review-status`)}
          className={classesReviewStyle.elementSeparator}
        />
        <div className={classes.checkRow}>
          <CheckRow
            text={i18n.t("challenge-validated")}
            isSelected={status === "validated"}
            onPress={() => onSelectOption("validated")}
            className={classesReviewStyle.elementSeparator}
            icon={
              <CheckIcon
                color={status === "validated" ? Colors.pureMain : Colors.grey}
              />
            }
          />
          <CheckRow
            text={i18n.t("challenge-toComplete")}
            isSelected={status === "toComplete"}
            onPress={() => onSelectOption("toComplete")}
            className={classesReviewStyle.elementSeparator}
            icon={
              <ModifyPencil
                color={status === "toComplete" ? Colors.pureMain : Colors.grey}
              />
            }
          />
          <FlatButton
            disabled={
              !(
                status !== "new" &&
                (comments.length > 0 || !!comment || !!fileToUpload?.file)
              )
            }
            requesting={isReviewRequesting}
            className={`${classes.button} ${classesReviewStyle.elementSeparator}`}
            title={i18n.t("validate-review")}
            onClick={onPressNextReview}
            size="large"
          />
        </div>
      </div>
    </div>
  );
};

export default CorrectorFeedback;
