import React from "react";

const RecordCameraIcon = ({
  width = "1.5rem",
  height = "1.5rem",
  color = "#006cfa",
  className = "",
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 16 16"
    version="1.1"
  >
    <svg width="16" height="16" viewBox="0 0 16 16">
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4.5C6.89543 4.5 6 5.39543 6 6.5C6 7.20508 6.36486 7.82494 6.91607 8.18109C6.13143 8.45101 5.46174 9.00924 5.05882 9.76471C4.92887 10.0084 5.02105 10.3112 5.26471 10.4412C5.50836 10.5711 5.81123 10.4789 5.94118 10.2353C6.3467 9.47495 7.13827 9 8 9C8.86173 9 9.65331 9.47495 10.0588 10.2353C10.1888 10.4789 10.4916 10.5711 10.7353 10.4412C10.9789 10.3112 11.0711 10.0084 10.9412 9.76471C10.5383 9.00924 9.86857 8.45101 9.08393 8.18109C9.63514 7.82494 10 7.20508 10 6.5C10 5.39543 9.10457 4.5 8 4.5ZM7 6.5C7 5.94772 7.44772 5.5 8 5.5C8.55229 5.5 9 5.94772 9 6.5C9 7.05229 8.55229 7.5 8 7.5C7.44772 7.5 7 7.05229 7 6.5Z"
      ></path>
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 3C1 2.72386 1.22386 2.5 1.5 2.5H5.5C5.77614 2.5 6 2.27614 6 2C6 1.72386 5.77614 1.5 5.5 1.5H1.5C0.671573 1.5 0 2.17157 0 3V11C0 11.8284 0.671573 12.5 1.5 12.5H5.40977L5.07644 14.5H4C3.72386 14.5 3.5 14.7239 3.5 15C3.5 15.2761 3.72386 15.5 4 15.5H12C12.2761 15.5 12.5 15.2761 12.5 15C12.5 14.7239 12.2761 14.5 12 14.5H10.9236L10.5902 12.5H14.5C15.3284 12.5 16 11.8284 16 11V3C16 2.17157 15.3284 1.5 14.5 1.5H10.5C10.2239 1.5 10 1.72386 10 2C10 2.27614 10.2239 2.5 10.5 2.5H14.5C14.7761 2.5 15 2.72386 15 3V11C15 11.2761 14.7761 11.5 14.5 11.5H1.5C1.22386 11.5 1 11.2761 1 11V3ZM9.90977 14.5L9.57644 12.5H6.42356L6.09023 14.5H9.90977Z"
      ></path>
    </svg>
  </svg>
);

export default RecordCameraIcon;
