import React, { useEffect } from "react";
import withStyles from '@mui/styles/withStyles';
import Box from "components/Box";
import BoxHeader from "components/BoxHeader";
import BoxTitle from "components/BoxTitle";
import i18n from "i18n";
import { Typography } from "@mui/material";
import Colors from "constants/Colors";
import NoteSideBar from "scenes/Campaigns/SeeUserModal/Coaching/Notes/NoteSideBar";
import NoteRow from "scenes/Campaigns/SeeUserModal/Coaching/Notes/NoteRow";
import { AddButtonBox, Loader } from "components";
import { connect } from "react-redux";
import {
  getCoachingNotesRequest,
  createCoachingNoteRequest,
  selectCoachingNote,
} from "services/coaching/actions";
import { getCoachingNotes, getSelectedNote } from "services/coaching/selectors";
import { getUserSelectedId } from "services/manager/selectors";
import { getUserId } from "services/user/selectors";

const styles = theme => ({
  main: {
    height: 360,
    marginRight: 12.5,
    paddingBottom: 0,
  },
  body: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    overflowY: "scroll",
  },
  firstNote: {
    alignSelf: "center",
    textAlign: "center",
    color: Colors.pureMain,
    fontSize: 18,
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  bold: {
    color: Colors.pureMain,
  },
  bodyNotes: {
    flexDirection: "column",
    display: "flex",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  noBold: {
    fontWeight: "normal",
  },
});

const Notes = ({
  classes,
  notes = [
    { title: "Jour 1 - Notes", date: new Date(), _id: 1 },
    {
      title: "Jour 2 - Progression",
      date: new Date(),
      _id: 2,
    },
  ],
  userId,
  coachId,
  getCoachingNotesRequest,
  createCoachingNoteRequest,
  noteRequesting,
  selectCoachingNote,
  selectedNote = {},
}) => {
  useEffect(() => {
    getCoachingNotesRequest({ userId, coachId });
  }, [getCoachingNotesRequest, userId, coachId]);

  useEffect(() => {
    return () => {
      selectCoachingNote({});
    };
  }, [selectCoachingNote]);

  const onSelectNote = note => () => {
    selectCoachingNote(note);
  };

  const onCreateNote = () => {
    createCoachingNoteRequest({ userId, coachId });
  };

  return (
    <>
      <NoteSideBar
        {...selectedNote}
        coachingNoteId={selectedNote._id}
        isVisible={Boolean(selectedNote._id)}
        onClose={() => selectCoachingNote({})}
      />
      <Box className={classes.main} intercomTarget={"coaching-notes"}>
        <BoxHeader className={classes.box}>
          <BoxTitle>
            {i18n.t("note-taking")}
            <span className={classes.noBold}> ({notes.length})</span>
          </BoxTitle>
          <AddButtonBox onClick={onCreateNote} />
        </BoxHeader>
        <div className={`${classes.body} ${notes.length && classes.bodyNotes}`}>
          {notes.length < 1 && (
            <Typography className={classes.firstNote} onClick={onCreateNote}>
              <span className={classes.bold}>{i18n.t("add")} </span>
              {i18n.t("first-notes")}
            </Typography>
          )}
          {noteRequesting && notes.length < 1 && <Loader sizePercentage={25} />}
          {notes.map((note, index) => (
            <NoteRow
              isSelected={note._id === selectedNote?._id}
              title={note.title}
              key={index}
              date={note.createdAt}
              onClick={onSelectNote(note)}
            />
          ))}
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = state => {
  return {
    noteRequesting: state.coaching.noteRequesting,
    selectedNote: getSelectedNote(state),
    notes: getCoachingNotes(state),
    userId: getUserSelectedId(state),
    coachId: getUserId(state),
  };
};

export default connect(mapStateToProps, {
  createCoachingNoteRequest,
  getCoachingNotesRequest,
  selectCoachingNote,
})(withStyles(styles)(Notes));
