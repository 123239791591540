export const emptyStep = {
  _id: "",
  title: { fr: "", en: "" },
  content: {
    title: { fr: "", en: "" },
    how: { fr: "", en: "" },
    why: { fr: "", en: "" },
  },
  skills: []
};
