import React from "react";
import withStyles from '@mui/styles/withStyles';
import { Slider } from "@mui/material";
import { Typography } from "@mui/material";
import Colors from "constants/Colors";

const styles = theme => ({
  main: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  sliderDiv: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  sliderLabels: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  leftLabel: {
    fontSize: 12,
    textAlign: "left",
    marginTop: 1,
    fontWeight: "500",
    color: Colors.pink,
  },
  rightLabel: {
    fontSize: 12,
    marginTop: 1,
    textAlign: "right",
    fontWeight: "500",
    color: Colors.goodGreen,
  },
  "@media (max-width: 640px)": {
    leftLabel: {
      marginRight: 5,
    },
    rightLabel: {
      marginLeft: 5,
    },
  },
});

function valuetext(value) {
  return `${value}`;
}

const QuestionSlider = ({
  classes,
  onValueChange,
  value,
  leftLabel = "",
  rightLabel = "",
  defaultValue = 6,
}) => {
  return (
    <div className={classes.sliderDiv}>
      <div className={classes.sliderLabels}>
        <Typography className={classes.leftLabel}>{leftLabel}</Typography>
        <Typography className={classes.rightLabel}>{rightLabel}</Typography>
      </div>
      <Slider
        onChange={onValueChange}
        defaultValue={defaultValue}
        value={value}
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider-always"
        step={null}
        min={1}
        max={10}
        marks={[
          { value: 1, label: 1 },
          { value: 2, label: 2 },
          { value: 3, label: 3 },
          { value: 4, label: 4 },
          { value: 5, label: 5 },
          { value: 6, label: 6 },
          { value: 7, label: 7 },
          { value: 8, label: 8 },
          { value: 9, label: 9 },
          { value: 10, label: 10 },
        ]}
        valueLabelDisplay="on"
      />
    </div>
  );
};

export default withStyles(styles)(QuestionSlider);
