import { IconButton } from "@mui/material";
import React from "react";

const RemoveButtonIcon = ({ sizePercentage, className, onClick, color }) => (
  <IconButton onClick={onClick} className={className} size="large">
    <svg
      width={sizePercentage * 19}
      height={sizePercentage * 19}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 19 19"
      version="1.1"
    >
      <circle cx="9.8285" cy="9.7487" r="8.85584" fill={color} />
      <circle cx="9.8285" cy="9.7487" r="8.85584" fill="#FF7272" />
      <rect
        x="6.08203"
        y="9.06107"
        width="7.4929"
        height="1.37518"
        fill="white"
      />
    </svg>
  </IconButton>
);

RemoveButtonIcon.defaultProps = {
  sizePercentage: 1,
  color: "#FF7272",
  className: "",
};

export { RemoveButtonIcon };
