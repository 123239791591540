import React from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Switch from "react-switch";
import Colors from 'constants/Colors';
import i18n from 'i18n';

const useStyles = makeStyles((theme) => ({
  topDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  title: {
	fontFamily: "Montserrat",
	fontWeight: "bold",
	fontSize: 16,
      },
  text: {
	fontFamily: "Hind",
	color: "#A4A4A4",
	fontSize: 12,
	textAlign: "right",
      },
      subTitle: {
	fontFamily: "Hind",
	color: "#333333",
	fontSize: 16,
      },
      stateCheck: {
        marginLeft: 10,
        color: Colors.pureMain,
        fontSize: 12,
        fontWeight: 550,
        fontFamily: "Hind",
      },
      textDiv: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexDirection: "column",
        marginRight: 50,
        flex: 1,
      },
      explanation: {
        color: "#687688",
        fontSize: 14,
        fontFamily: "Hind",
      },
      unCheckedText: {
        color: "#A4A4A4",
      },
}));

interface Props {
	onChange: () => void;
	checked: boolean;
  title: string;
  explanation: string;
  }

const SettingBooleanBlock = ({ onChange, checked, title, explanation }: Props) => {
const classes = useStyles();

    return (
	    <div className={classes.topDiv}>
            <div className={classes.textDiv}>
              <Typography className={classes.subTitle}>
                {title}
              </Typography>
              <Typography className={classes.explanation}>
                {explanation}
              </Typography>
            </div>
            <Switch
              onChange={onChange}
              checked={checked}
              onColor={"#00A7FC"}
            />
            <Typography
              className={`${classes.stateCheck} ${
                !checked && classes.unCheckedText
              }`}
            >
               {i18n.t(checked ? "yes" : "no")}
            </Typography>
          </div>
    )
}

export default SettingBooleanBlock;