import React from "react";
import { ResponsiveRadar } from "@nivo/radar";

import Colors from "../../constants/Colors";

// const dataTest = [
//   {
//     title: "Stress",
//     "Situation initiale": 2
//   },
//   {
//     title: "Confiance",
//     "reduce initial": 4
//   },
//   {
//     title: "Cohésion d'équipe",
//     "État initial": 9
//   },
//   {
//     title: "Aisance à l'oral",
//     "État initial": 8
//   }
// ];

// const colorRange = [
//   "rgba(252,87,122,0.8)",
//   "rgba(66,80,251,0.8)",
//   "rgba(0,170,255,0.8)",
//   "#FF9F40",
//   "#FFC233"
// ];

const EvolutionChart = ({ data, keys, isSmall }) => {
  let margin = isSmall
    ? {
        top: 35,
        right: 20,
        bottom: 15,
        left: 10,
      }
    : {
        top: 70,
        right: 80,
        bottom: 50,
        left: 80,
      };
  return (
    <ResponsiveRadar
      data={data}
      keys={keys}
      indexBy="title"
      // maxValue={10}
      maxValue="auto"
      margin={margin}
      curve="cardinalClosed"
      // curve="cat"
      borderWidth={2}
      // borderColor="inherit"
      gridLevels={5}
      gridShape="circular"
      gridLabelOffset={25}
      enableDots={true}
      dotSize={8}
      dotBorderWidth={0}
      dotBorderColor="#ffffff"
      enableDotLabel={true}
      dotLabel="value"
      dotLabelYOffset={isSmall ? 0 : -12}
      dotLabelXOffset={isSmall ? 5 : 0}
      colors={[Colors.pink, Colors.yellow]}
      theme={{
        fontSize: "12px",
        fontFamily: "Roboto",
        legends: {
          text: {
            fontSize: 13,
            fontFamily: "Roboto",
          },
        },
      }}
      fillOpacity={0.1}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      isInteractive={true}
      legends={[
        {
          anchor: "top-left",
          direction: "column",
          translateX: -10,
          translateY: -20,
          itemWidth: 80,
          itemHeight: 20,
          itemTextColor: "#999",
          symbolSize: 12,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ]}
    />
  );
};

EvolutionChart.defaultProps = {
  keys: ["Situation initiale", "Objectif"],
};

export default EvolutionChart;
