import { createSelector } from "reselect";

export const getReceiverId = state => state.message.receiver._id;

export const getUserClientSelectedName = state =>
  state.message.userSelectedClientName;

const getMessages = state => state.message.messages;

export const getOrderedMessages = createSelector(getMessages, messages => {
  return messages.slice().reverse();
});
