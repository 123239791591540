// First, we need to import the FlatList and other React Native component
import "intersection-observer";
import React, { PureComponent } from "react";
// We also need to add the connectInfiniteHits connector to our import
import AutoSizer from "react-virtualized-auto-sizer";
import memoize from "memoize-one";

import { FixedSizeList as List } from "react-window";
import { connect } from "react-redux";
import {
  addStep,
  duplicateStep,
  emitStepUpdate,
} from "../../../services/createTrack/actions";
import withStyles from '@mui/styles/withStyles';
import Colors from "../../../constants/Colors";
import StepRow from "../StepRow";
import selectLanguage from "../../../utils/selectLanguage";
import i18n from "../../../i18n";
import { getAppLanguage } from "../../../services/general/selectors";
import {
  getIsLibrary,
  getStepsIds,
  getTrackId,
} from "services/createTrack/selectors";
import { getIsCoachingAccount } from "services/client/selectors";
import { getSelectedClientId, getUserClientId } from "services/user/selectors";

const styles = theme => ({
  list: {
    // flexDirection: "column",
    // height: "100%",
    height: `calc(75vh - 60px)`,
    // overflow: "scroll",
    minWidth: "100%",
    // marginTop: 20,
    paddingBottom: 5,
  },
  name: {
    color: Colors.text,
  },
  widthDiv: {
    width: "100%",
  },
});

const Row = props => {
  const { data, index, style } = props;

  const {
    items,
    onClickStep,
    onClickAddStep,
    selectLanguage,
    isForbidden,
    language,
    stepSelectedId,
  } = data;
  const item = items[index];
  const {
    content,
    title,
    isPrivate,
    _id,
    isPremium,
    skills,
  } = item;
  const isForbiddenStep = isForbidden(isPremium);
  const titleToDisplay =
    selectLanguage({ text: title, language }) ||
    selectLanguage({ text: content.title, language });

  return (
    <div key={index} style={style}>
      <StepRow
        label={
          isPrivate
            ? null
            : isForbiddenStep
            ? i18n.t("premium")
            : i18n.t("free")
        }
        noRightBorder
        hasNoLeftBorder
        isSelected={stepSelectedId === _id}
        skills={skills}
        onClick={onClickStep({ stepPreview: item, stepSelectedIndex: index })}
        onClickAddButton={onClickAddStep(item)}
        isSearchResult
        isForbidden={isForbiddenStep}
        stepType={!isPrivate ? "library" : undefined}
        isFirst={index === 0}
        key={`${index} ${_id}`}
        title={titleToDisplay}
      />
    </div>
  );
};

const createItemData = memoize(
  (
    items,
    onClickAddStep,
    onClickStep,
    selectLanguage,
    isForbidden,
    language,
    stepSelectedId,
  ) => ({
    items,
    onClickAddStep,
    onClickStep,
    selectLanguage,
    isForbidden,
    language,
    stepSelectedId,
  }),
);

class Hits extends PureComponent {
  state = {
    selectedIndex: -1,
    hits: [],
  };

  componentDidMount() {
    window.addEventListener("keydown", this.downHandler);
    window.addEventListener("keyup", this.upHandler);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.downHandler);
    window.removeEventListener("keyup", this.upHandler);
  }

  componentDidUpdate = prevProps => {
    if (
      this.props.stepsIds?.length !== prevProps.stepsIds?.length &&
      this.props.isSideSelected
    ) {
      const currentIndex = this.props.stepSelectedIndex;

      const filteredHits = this.filteredHits;
      const stepPreview = filteredHits[currentIndex];
      if (
        currentIndex > -1 &&
        currentIndex < filteredHits.length &&
        stepPreview
      ) {
        this.onClickStep({ stepPreview, stepSelectedIndex: currentIndex })();
      } else {
        this.props.onClosePreview();
      }
    }
  };

  selectNextAction = stepSelectedId => {
    const filteredHits = this.filteredHits;
    const currentIndex = filteredHits.findIndex(
      el => el._id === stepSelectedId,
    );
    if (currentIndex > -1 && currentIndex < filteredHits.length - 1) {
      const stepSelectedIndex = currentIndex + 1;
      this.onClickStep({
        stepPreview: filteredHits[stepSelectedIndex],
        stepSelectedIndex,
      })();
    }
  };

  selectPreviousAction = stepSelectedId => {
    const filteredHits = this.filteredHits;
    const currentIndex = filteredHits.findIndex(
      el => el._id === stepSelectedId,
    );
    if (currentIndex > 0) {
      const stepSelectedIndex = currentIndex - 1;
      this.onClickStep({
        stepPreview: filteredHits[stepSelectedIndex],
        stepSelectedIndex,
      })();
    }
  };

  downHandler = e => {
    const { isSideSelected, stepSelectedId } = this.props;
    if (e.keyCode === 40 && isSideSelected && stepSelectedId) {
      this.selectNextAction(stepSelectedId);
    }
  };

  upHandler = e => {
    const { isSideSelected, stepSelectedId } = this.props;
    if (e.keyCode === 38 && isSideSelected && stepSelectedId) {
      this.selectPreviousAction(stepSelectedId);
    }
  };

  onClickStep = step => () => {
    // animation modal with the step

    if (!this.isForbidden(step.isPremium)) {
      this.props.onClickStep(step);
    }
  };

  onClickAddStep = step => () => {
    const { clientId, selectedClientId, trackId } = this.props;

    if (!this.isForbidden(step.isPremium) && this.props.isLibrary) {
      return this.props.duplicateStep({ ...step, step: step._id, trackId });
    }

    if (this.props.isCoachingAccount && !step.isPrivate) {
      return this.props.duplicateStep({ ...step, step: step._id, trackId });
    }

    if (!this.isForbidden(step.isPremium)) {
      const result = { ...step, step: step._id };
      this.props.addStep(result);
      if (this.props.isSuperAdmin) {
        emitStepUpdate({
          step: result,
          clientId: selectedClientId,
          trackId,
          updateType: "add",
        });
      } else {
        emitStepUpdate({ step: result, clientId, trackId, updateType: "add" });
      }
    }

    // if (isCoachingAccount) {
    //   this.props.
    // }
  };

  isAlreadySelected = stepId => {
    return this.props.stepsIds?.findIndex(el => el === stepId) > -1;
  };

  hasFilter = skills => {
    // return true;
    if (!this.props.filterSelected?.value) {
      return true;
    }
    if (!skills) {
      return false;
    }
    return (
      skills.findIndex(el => el?.value === this.props.filterSelected?.value) >
      -1
    );
  };

  hasFolders = folders => {
    if (!this.props.folderSelected?.value) return true;
    if (!folders || folders.length < 1) return false;

    return folders.find(el => el?.value === this.props.folderSelected?.value);
  };

  isForbidden = isPremium => {
    return isPremium && this.props.plan === "free" && !this.props.isSuperAdmin;
  };

  filterHits = hits => {
    return hits.filter(
      el =>
        !this.isAlreadySelected(el._id) &&
        this.hasFilter(el.skills) &&
        this.hasFolders(el.folders),
    );
  };

  render() {
    const {
      hits,
      classes,
      language,
      stepSelectedId,
    } = this.props;

    this.filteredHits = this.filterHits(hits);

    const itemData = createItemData(
      this.filteredHits,
      this.onClickAddStep,
      this.onClickStep,
      selectLanguage,
      this.isForbidden,
      language,
      stepSelectedId,
    );

    return (
      <div className={classes.list}>
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height}
              itemCount={this.filteredHits.length}
              itemData={itemData}
              itemSize={80}
              width={width}
            >
              {Row}
            </List>
          )}
        </AutoSizer>
      </div>
    );
  }
}

Hits.defaultProps = {
  folderSelected: {},
};

const mapStateToProps = state => {
  const language = getAppLanguage(state);
  const { role } = state.user;
  const { plan } = state.client;
  const isSuperAdmin = role === "superAdmin";

  const isLibrary = getIsLibrary(state);
  const clientId = getUserClientId(state);
  const trackId = getTrackId(state);
  const selectedClientId = getSelectedClientId(state);

  return {
    isCoachingAccount: getIsCoachingAccount(state),
    isLibrary,
    stepsIds: getStepsIds(state),
    language,
    plan,
    isSuperAdmin,
    clientId,
    selectedClientId,
    trackId,
  };
};

export default connect(mapStateToProps, { addStep, duplicateStep })(
  withStyles(styles)(Hits),
);
