import React, { PureComponent } from "react";
import { connect } from "react-redux";

import withStyles from '@mui/styles/withStyles';

import { Typography } from "@mui/material";
import i18n from "i18n";
import CoacheesList from "scenes/MyCoachees/CoacheesList";
import Colors from "constants/Colors";
import CoachingStats from "scenes/MyCoachees/CoachingStats";
import AddUsersToCampaignModal from "scenes/Campaigns/AddUsersToCampaignModal";
import { getIsCoachingAccount } from "services/client/selectors";
import { changeAddUsersModal } from "services/campaign/actions";
import { getIsFirstCoachingConnection } from "services/user/selectors";

const styles = theme => ({
  main: {
    paddingTop: 5,
    paddingLeft: 25,
    paddingRight: 25,
    width: "100%",
    flex: 1,
    flexDirection: "column",
    display: "flex",
    paddingBottom: 100,
  },
  title: {
    marginBottom: 25,
    fontSize: 22,
    fontFamily: "Montserrat",
    color: Colors.darkBlue,
    fontWeight: "bold",
  },
});

class MyCoachees extends PureComponent {
  state = {
    tabSelected: 0,
  };

  onCloseAddUsersModal = () => {
    this.props.changeAddUsersModal({
      isAddUsersModalVisible: false,
      hasPublishCampaignAnimation: false,
    });
  };

  render() {
    const { classes, isAddUsersModalVisible } = this.props;
    return (
      <>
        <AddUsersToCampaignModal
          isVisible={isAddUsersModalVisible}
          onClose={this.onCloseAddUsersModal}
        />
        <div className={classes.main}>
          <Typography className={classes.title}>
            {i18n.t("coaching-stats")}
          </Typography>
          <CoachingStats />

          <Typography className={classes.title}>
            {i18n.t("Coachees")}
          </Typography>
          <CoacheesList />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    isCoachingAccount: getIsCoachingAccount(state),
    isFirstCoachingConnection: getIsFirstCoachingConnection(state),
    isAddUsersModalVisible: state.campaign.isAddUsersModalVisible,
  };
};

export default connect(mapStateToProps, {
  changeAddUsersModal,
})(withStyles(styles)(MyCoachees));
