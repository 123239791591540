import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import Colors from "constants/Colors";
import { useHistory } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import LeftUserProfile from "scenes/Campaigns/SeeUserModal/LeftUserProfile";
import RightUserProfile from "scenes/Campaigns/SeeUserModal/RightUserProfile";
import MyTabs from "components/MyTabs";
import Coaching from "scenes/Campaigns/SeeUserModal/Coaching";
import SurveysSide from "scenes/Campaigns/CampaignScreen/SurveysSide/SurveysSide";
import InvoiceList from "scenes/Invoice/InvoiceList/InvoiceList";
import queryString from "query-string";
import { getHasCoachingSession } from "services/coaching/selectors";
import { getIsCoachingAccount } from "services/client/selectors";
import i18n from "i18n";

const useStyle = makeStyles({
  paper: {
    flex: 1,
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    borderRadius: 0,
    height: "inherit",
    width: "inherit",
  },
  leftDiv: {
    width: 240,
  },
  closeButton: {
    cursor: "pointer",
    backgroundColor: Colors.pureMain,
    borderBottomLeftRadius: 4,
    height: 40,
    width: 40,
    zIndex: 10,
    position: "absolute",
    top: 0,
    right: 0,
    transition: "all 0.2s ease",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "&:hover": {
      opacity: 0.9,
    },
  },
  rightDiv: {
    overflow: "auto",
    display: "flex",
    flex: 3,
    flexDirection: "column",
    backgroundColor: "#F2F3F5",
    paddingRight: 75,
    paddingLeft: 75,
  },
  box: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "#F2F3F5",
    flex: 4,
    width: "100%",
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 50,
    marginTop: 50,
    alignSelf: "center",
    maxWidth: "1200px",
  },
  "@media screen and (max-device-width: 1200px)": {
    box: {
      paddingLeft: 30,
      paddingRight: 30,
      maxWidth: 700,
    },
  },
  "@media screen and (max-device-width: 1440px) and (min-device-width: 1200px)": {
    box: {
      paddingLeft: 60,
      paddingRight: 60,
      maxWidth: 1000,
    },
  },
  "@media screen and (min-device-width: 1440px)": {
    box: {
      paddingLeft: 0,
      paddingRight: 0,
      maxWidth: 1100,
    },
  },
  closeIcon: {
    color: "#fff",
    width: 20,
    height: 20,
    zIndex: 5,
  },
  separator: {
    marginBottom: 25,
  },
});

const renderTabs = (hasCoaching, isCoachingAccount) => {
  if (hasCoaching && isCoachingAccount) {
    return [
      i18n.t("coaching"),
      i18n.t("Stats"),
      i18n.t("Surveys"),
      i18n.t("invoices"),
    ];
  } else if (hasCoaching) {
    return [i18n.t("coaching"), i18n.t("stats")];
  }
  return [i18n.t("stats")];
};

const renderTabsContent = (tabs, tabSelected) => {
  if (tabs.length === 1 || tabSelected === 1) {
    return <RightUserProfile />;
  }
  if (tabSelected === 0) return <Coaching />;
  if (tabSelected === 2) return <SurveysSide />;
  if (tabSelected === 3) return <InvoiceList />;
};

const UserProfile = () => {
  const hasCoaching = useSelector(getHasCoachingSession);
  const isCoachingAccount = useSelector(getIsCoachingAccount);

  const query = queryString.parse(window.location.search);
  const { tabSelectedIndex = 0 } = query;
  const [tabSelected, selectTab] = useState(parseInt(tabSelectedIndex, 10));
  const tabs = useMemo(() => renderTabs(hasCoaching, isCoachingAccount), [
    hasCoaching,
    isCoachingAccount,
  ]);
  const tabsContent = useMemo(() => renderTabsContent(tabs, tabSelected), [
    tabs,
    tabSelected,
  ]);

  const classes = useStyle();
  const history = useHistory();

  const handleClose = () => {
    history.goBack();
  };

  const handleSelectTab = tabIndex => {
    const { campaignId } = query;
    if (isCoachingAccount) {
      window.history.replaceState(
        null,
        null,
        `/my-coachees?campaignId=${campaignId}&tabSelectedIndex=${tabIndex}`,
      );
    }

    selectTab(tabIndex);
  };

  return (
    <div className={classes.paper}>
      <div className={classes.closeButton} onClick={handleClose}>
        <CloseIcon className={classes.closeIcon} />
      </div>
      <div className={classes.leftDiv}>
        <LeftUserProfile />
      </div>
      <div className={classes.rightDiv}>
        <div className={classes.box}>
          <MyTabs
            variant="standard"
            centered={false}
            tabSelected={tabSelected}
            selectTab={handleSelectTab}
            tabs={tabs}
          />
          <div className={classes.separator} />
          {tabsContent}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
