import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Colors from "../../../../constants/Colors";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";
import { addParticipantsRequest } from "../../../../services/campaign/actions";
import { changeGeneralSettings } from "../../../../services/general/actions";
import { getSelectedClientId } from "../../../../services/user/selectors";
import FlatButton from "../../../../components/FlatButton";
import moment from "moment";
import i18n from "../../../../i18n";
import { Typography } from "@mui/material";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  Configure,
  Index,
} from "react-instantsearch-dom";
import UserClickHits from "../../../MyUsers/UsersAccess/UserClickHits";
import UserRow from "../../../MyUsers/UserRow";
import { Loader } from "../../../../components";
import {
  getCampaignSelected,
  getUserCampaigns,
} from "services/campaign/selectors";

const searchClient = algoliasearch(
  "MTMFJ78PRC",
  "a6400a80c85f1dcceaaddde616c096a3",
);

moment.locale("fr");

const styles = theme => ({
  closeButton: {
    zIndex: 10,
    position: "absolute",
    top: 1,
    right: 1,
  },
  leftDiv: {
    height: "100%",
    borderRight: "solid 1px",
    paddingRight: 25,
    borderRightColor: Colors.lightGrey,
    flex: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  rightDiv: {
    flex: 3,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  mainDiv: {
    height: 700,
    flex: 1,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  paper: {
    maxWidth: "calc(100% - 96px)",
    width: 1000,
  },
  saveButton: {
    alignSelf: "center",
    width: "75%",
    marginTop: 20,
    marginBottom: 10,
  },
  answerSeparatorText: {
    color: Colors.black,
    fontSize: 16,
    fontWeight: "500",
    whiteSpace: "nowrap",
    marginTop: 5,
    marginLeft: 10,
    marginRight: 10,
  },
  clickText: {
    fontSize: 18,
    fontWeight: "500",
    marginBottom: 15,
    marginLeft: 10,
    marginRight: 10,
  },
  separator: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  errorText: {
    color: "#e6525a",
    padding: "13px 15px",
    borderRadius: 6,
    backgroundColor: "rgba(230,82,90,0.15)",
  },
  dialogTitle: {
    color: "black",
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  userDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  accessUsers: {
    flex: 1,
    overflow: "scroll",
  },
  searchDiv: {
    height: 200,
  },
  accent: {
    fontSize: 22,
    fontWeight: "500",
  },
  searchBox: {},
  invite: {
    cursor: "pointer",
    color: "#1264A3",
    transition: "all 0.2s",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  title: {
    fontSize: "1.2rem",
    color: Colors.black,
    fontWeight: "500",
  },
  participantText: {
    marginBottom: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dialogContent: {
    paddingRight: 0,
  },
});

class AddParticipantModal extends Component {
  state = {
    userCampaigns: this.props.userCampaigns,
    usersSelected: [],
    open: false,
  };

  onRendered = () => {
    this.setState({
      userCampaigns: this.props.userCampaigns,
      usersSelected: [],
    });
  };

  handleClose = onClose => () => {
    onClose();
  };

  save = () => {
    const { campaignId } = this.props;
    const { usersSelected } = this.state;
    const newParticipants = usersSelected.map(el => el._id);
    this.props.addParticipantsRequest({
      campaignId,
      newParticipants,
    });
  };

  onClickRemove = user => () => {
    const usersSelected = this.state.usersSelected.filter(
      el => el._id !== user._id,
    );
    const userCampaigns = this.state.userCampaigns.filter(
      el => el.userId !== user._id,
    );
    this.setState({ usersSelected, userCampaigns });
  };

  displayUsers = users => {
    return users.map((el, index) => {
      return (
        <UserRow
          {...el}
          key={index}
          onClick={this.onClickRemove(el)}
          isRoleInputDisabled={() => true}
        />
      );
    });
  };

  onClickAdd = userAdded => () => {
    const userId = userAdded._id;
    const userCampaigns = [...this.state.userCampaigns, { userId }];
    const usersSelected = [...this.state.usersSelected, userAdded];
    this.setState({ usersSelected, userCampaigns });
  };

  clickInvite = () => {
    const { accessCode, autoRegister } = this.props;
    this.props.changeGeneralSettings({
      isInviteUsersModalVisible: true,
      accessCode: autoRegister ? null : accessCode,
    });
  };

  isExcluded = userCampaigns => item => {
    const userId = item._id;
    return userCampaigns.findIndex(el => el.userId === userId) > -1;
  };

  render() {
    const { classes, isVisible, onClose } = this.props;
    return (
      <Dialog
        open={isVisible}
        ref={this.onRendered}
        onClose={this.handleClose(onClose)}
        classes={{ paper: classes.paper }}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        <IconButton
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle id="scroll-dialog-title" className={classes.dialogTitle}>
          <Typography className={classes.title}>
            {i18n.t("add-participant-modal-title")}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.mainDiv}>
            <div className={classes.leftDiv}>
              <div className={classes.userDiv}>
                <Typography className={classes.subtitle}>
                  {i18n.t("invite-first-1")}
                  <span className={classes.invite} onClick={this.clickInvite}>
                    {i18n.t("invite-first-2")}
                  </span>
                </Typography>
              </div>

              {this.props.requesting ? (
                <Loader />
              ) : (
                <FlatButton
                  className={classes.saveButton}
                  fullWidth
                  disabled={this.state.usersSelected.length === 0}
                  onClick={this.save}
                  variant="contained"
                  color="primary"
                  title={i18n.t("add-participant-save")}
                />
              )}
              <div className={classes.participantText}>
                <Typography className={classes.answerSeparatorText}>
                  {i18n.t("add-new-participant-1")}
                  <span className={classes.accent}>
                    {this.state.usersSelected.length}
                  </span>
                  {this.state.usersSelected.length > 1
                    ? i18n.t("add-new-participant-2-plural")
                    : i18n.t("add-new-participant-2-singular")}
                </Typography>
              </div>
              <div className={classes.accessUsers}>
                {this.displayUsers(this.state.usersSelected)}
              </div>
            </div>

            <div className={classes.rightDiv}>
              <div className={classes.separator}>
                <Typography className={classes.clickText}>
                  {i18n.t("users-search-add")}
                </Typography>
              </div>
              <div className={classes.searchDiv}>
                <InstantSearch
                  searchClient={searchClient}
                  indexName={this.props.clientId}
                  refresh={this.state.refresh}
                >
                  <div className={classes.searchBox}>
                    <SearchBox
                      translations={{
                        placeholder: i18n.t("search-user-placeholder"),
                      }}
                    />
                  </div>

                  <Index
                    className={classes.index}
                    indexName={this.props.clientId}
                  >
                    <Configure filters="type:user" />
                    <UserClickHits
                      onClick={this.onClickAdd}
                      isExcluded={this.isExcluded(this.state.userCampaigns)}
                    />
                  </Index>
                </InstantSearch>
              </div>
              {this.state.error && (
                <p className={classes.errorText}>{this.state.error}</p>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

AddParticipantModal.defaultProps = {
  usersAccess: [],
  hasUsersAccessRestricted: false,
};

const mapStateToProps = state => {
  const clientId = getSelectedClientId(state);
  const { userSelected } = state.myUsers;
  const { requesting } = state.campaign;

  const campaignSelected = getCampaignSelected(state);
  const campaignId = campaignSelected._id;
  const { accessCode } = campaignSelected;
  return {
    clientId,
    campaignId,
    requesting,
    userSelected,
    userCampaigns: getUserCampaigns(state),
    accessCode,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    changeGeneralSettings,
    addParticipantsRequest,
  })(AddParticipantModal),
);
