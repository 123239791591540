import {
  MY_CLIENTS_CREATE_NEW_ERROR,
  MY_CLIENTS_CREATE_NEW_REQUESTING,
  MY_CLIENTS_CREATE_NEW_SUCCESS,
  MY_CLIENTS_GET_ALL_REQUESTING,
  MY_CLIENTS_GET_ALL_ERROR,
  MY_CLIENTS_GET_ALL_SUCCESS,
  MY_CLIENTS_SELECT,
  MY_CLIENTS_DELETE_REQUESTING,
  MY_CLIENTS_DELETE_SUCCESS,
  MY_CLIENTS_DELETE_ERROR,
  MY_CLIENTS_MODIFY_REQUESTING,
  MY_CLIENTS_MODIFY_SUCCESS,
  MY_CLIENTS_MODIFY_ERROR,
  MY_CLIENTS_GET_SELECTED_REQUESTING,
  MY_CLIENTS_GET_SELECTED_SUCCESS,
  MY_CLIENTS_GET_SELECTED_ERROR,
  MY_CLIENTS_GET_ACTIVE_USERS_REQUESTING,
  MY_CLIENTS_GET_ACTIVE_USERS_SUCCESS,
  MY_CLIENTS_GET_ACTIVE_USERS_ERROR,
} from "./constants";

import { AUTH_LOGOUT_SUCCESS } from "../auth/reducer";

const initialState = {
  requesting: false,
  selectedClient: {},
  selectedClientId: "",
  allClients: [],
  activeUsers: [],
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MY_CLIENTS_GET_ACTIVE_USERS_REQUESTING:
    case MY_CLIENTS_MODIFY_REQUESTING:
    case MY_CLIENTS_DELETE_REQUESTING:
    case MY_CLIENTS_GET_ALL_REQUESTING:
    case MY_CLIENTS_CREATE_NEW_REQUESTING: {
      return {
        ...state,
        requesting: true,
      };
    }

    case MY_CLIENTS_CREATE_NEW_SUCCESS: {
      return {
        ...state,
        allClients: [action.payload, ...state.allClients],
        requesting: false,
      };
    }

    case MY_CLIENTS_GET_SELECTED_REQUESTING:
      return {
        ...state,
      };

    case MY_CLIENTS_GET_SELECTED_SUCCESS: {
      return {
        ...state,
        requesting: false,
        selectedClient: action.payload,
      };
    }

    case MY_CLIENTS_GET_ALL_SUCCESS:
      let selectedClient = state.selectedClient;
      action.payload.map(client => {
        if (client._id === selectedClient._id) {
          selectedClient = { ...selectedClient, ...client };
        }
        return client;
      });

      return {
        ...state,
        allClients: action.payload,
        selectedClient,
        requesting: false,
      };

    case MY_CLIENTS_DELETE_SUCCESS: {
      const { clientId } = action.payload;
      const allClients = state.allClients.filter(el => el._id !== clientId);
      return {
        ...state,
        allClients,
        requesting: false,
      };
    }

    case MY_CLIENTS_MODIFY_SUCCESS: {
      const { _id } = action.payload;
      let selectedClient = state.selectedClient;
      if (selectedClient._id === _id) {
        selectedClient = action.payload;
      }
      const allClients = state.allClients.map(el => {
        if (el._id === _id) {
          return action.payload;
        }
        return el;
      });
      return {
        ...state,
        allClients,
        selectedClient,
        requesting: false,
      };
    }

    case MY_CLIENTS_GET_ACTIVE_USERS_SUCCESS: {
      return {
        ...state,
        requesting: false,
        activeUsers: action.payload,
      };
    }

    case MY_CLIENTS_GET_ACTIVE_USERS_ERROR:
    case MY_CLIENTS_GET_SELECTED_ERROR:
    case MY_CLIENTS_MODIFY_ERROR:
    case MY_CLIENTS_DELETE_ERROR:
    case MY_CLIENTS_GET_ALL_ERROR:
    case MY_CLIENTS_CREATE_NEW_ERROR: {
      return { ...state, error: action.payload, requesting: false };
    }

    case MY_CLIENTS_SELECT:
      return {
        ...state,
        selectedClientId: action.payload,
      };

    case AUTH_LOGOUT_SUCCESS: {
      return initialState;
    }

    default:
      return state;
  }
};

export default reducer;
