import i18n from "../i18n";

const getAllRoles = () => [
  { label: i18n.t("role-user"), value: "user" },
  {
    label: i18n.t("role-administrator"),
    value: "admin",
    explanation: i18n.t("explanation-role-administrator"),
  },
  {
    label: i18n.t("role-contributor"),
    value: "contributor",
    explanation: i18n.t("explanation-role-contributor"),
  },
  {
    label: i18n.t("role-observer"),
    value: "observer",
    explanation: i18n.t("explanation-role-observer"),
  },
  {
    label: i18n.t("role-trainer"),
    value: "trainer",
    explanation: i18n.t("explanation-role-trainer"),
  },
];

const getUserRoles = args => {
  const allRoles = getAllRoles();
  if (args && args.forbiddenRoles) {
    return allRoles.filter(role => {
      if (args.forbiddenRoles.findIndex(el => el === role.value) > -1) {
        return false;
      }
      return true;
    });
  }
  return allRoles;
};

export default getUserRoles;
