import React from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography } from "@mui/material";
import Colors from "constants/Colors";

const styles = theme => ({
  main: {
    fontSize: 20,
    color: Colors.darkBlue,
    fontWeight: "bold",
    fontFamily: "Montserrat",
  },
});

const BoxTitle = ({ classes, className, children }) => {
  return (
    <Typography className={`${classes.main} ${className}`}>
      {children}
    </Typography>
  );
};

export default withStyles(styles)(BoxTitle);
