import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MaterialCloseIcon from "@mui/icons-material/Close";

import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import debounce from "lodash/debounce";

//import css module
import "react-flags-select/css/react-flags-select.css";

import {
  saveTrackRequest,
  changeField,
  createAndModifyTemplateRequest,
} from "../../../services/createTrack/actions";
import { uploadPictureRequest } from "../../../services/upload/actions";
import Uploader from "../../../components/Uploader";

import FlatButton from "../../../components/FlatButton";
import Colors from "../../../constants/Colors";
import i18n from "../../../i18n";
import Switch from "react-switch";
import {
  getUserId,
  getSelectedClientId,
} from "../../../services/user/selectors";
import EditableInput from "../CreateActionTextStep/EditableInput";
import { getAppLanguage } from "../../../services/general/selectors";
import BooleanField from "scenes/CreateTrack/BooleanField";
import Language from "scenes/CreateTrack/CreateActionTextStep/Language";
import AdministrationMode from "components/AdministrationMode";
import slugify from "slugify";

const styles = theme => ({
  paper: {
    alignItems: "center",
    display: "flex",
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: "center",
    lineHeight: 1.46666667,
    overflowY: "auto",
    padding: "0px 0px 0px 0px",
    borderRadius: "0px",
    minHeight: "100%",
    minWidth: "100%",
    maxWidth: "100%",
  },
  closeButton: {
    cursor: "pointer",
    backgroundColor: Colors.pureMain,
    borderBottomLeftRadius: 4,
    height: 40,
    width: 40,
    zIndex: 10,
    position: "absolute",
    top: 0,
    right: 0,
    transition: "all 0.2s ease",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "&:hover": {
      opacity: 0.9,
    },
  },
  line: {
    width: "100%",
    borderRadius: 8,
    minHeight: 2,
    height: 2,
    backgroundColor: Colors.blackGrey,
  },
  title: {
    fontFamily: "Montserrat",
    textAlign: "center",
    maxWidth: "100%",
    width: 960,
    marginBottom: 20,
    fontSize: 25,
    lineHeight: 1.2143,
    fontWeight: 700,
  },
  subTitle: {
    fontFamily: "Montserrat",
    marginTop: 20,
    marginBottom: 5,
    fontSize: 20,
    lineHeight: 1.2143,
    fontWeight: 700,
  },
  uploader: {
    marginTop: 15,
  },
  flag: {
    marginBottom: 0,
  },
  templateButton: {
    marginTop: 15,
    backgroundColor: Colors.orange,
    "&:hover": {
      backgroundColor: Colors.orange,
      opacity: 0.85,
    },
  },
  templateSettings: {
    width: "100%",
    flexDirection: "column",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginBottom: 75,
  },
  isTemplateDiv: {
    width: "100%",
    marginTop: 25,
    marginBottom: 25,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  templateDiv: {
    width: "100%",
  },
  autoInvite: {
    marginTop: 5,
    flex: 1,
    maxWidth: "75%",
    marginRight: 25,
  },
  autoInviteDiv: {
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "row",
  },
  teamSizeField: {
    minWidth: "33%",
  },
  topBar: {
    width: "100%",
    height: 75,
    minHeight: 75,
    background: Colors.white,
    boxShadow: "0px 2px 15px rgba(169, 169, 169, 0.25)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 25,
  },
  barBody: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    width: 960,
  },
  topBarTitle: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 24,
    lineHeight: "27px",
    color: Colors.text,
  },
  bodyDiv: {
    padding: 0,
    height: "100%",
    flexDirection: "column",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    maxWidth: "100%",
    width: 960,
  },
  firstDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  leftDiv: {
    justifyContent: "flex-start",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  rightDiv: {
    marginLeft: 40,
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  administrationModes: {
    display: "flex",
    marginTop: 15,
    alignItems: "center",
    flexDirection: "row",
  },
  buttonDiv: {
    flex: 1,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  leftButton: {
    fontWeight: "normal",
    color: Colors.grey,
    backgroundColor: "transparent",
    transition: "all 80ms linear",
    "&:hover": {
      opacity: 0.8,
      backgroundColor: Colors.hoverBlue,
    },
  },
});

class CreateSettings extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      inputValue: "",
      pictureUploading: false,
      isTemplate: this.props.isTemplate,
      isPrivate: this.props.isPrivate,
      templateSettings: this.props.templateSettings,
    };

    this.saveTrack = debounce(this.saveTrack, 2000);
  }

  saveTrack = () => {
    this.props.saveTrackRequest();
  };

  componentDidUpdate = prevProps => {
    if (
      prevProps.uploadedPicture !== this.props.uploadedPicture &&
      this.state.pictureUploading
    ) {
      this.setState({ pictureUploading: false });
      this.props.changeField({ picture: this.props.uploadedPicture });
      this.saveTrack();
    }
  };

  onClickUnsplashPicture = picture => () => {
    this.props.changeField({ picture });
  };

  onFileUploaded = async files => {
    const { clientId, userId } = this.props;

    const date = new Date().getTime();
    this.setState({ pictureUploading: true });
    await this.props.uploadPictureRequest({
      urlName: slugify(`track-cover-picture/${clientId}/${userId}/${date}`),
      file: files[0],
    });
  };

  onChangeIsContentEditable = () => {
    const { isContentEditable } = this.props;
    this.props.changeField({ isContentEditable: !isContentEditable });
    this.saveTrack();
  };

  onChangeParams = param => event => {
    const value = event.target.value;
    const newParam = { ...this.props[param] };
    newParam[this.props.languageSelected] = value;
    this.props.changeField({ [param]: newParam });
    // debounced with lodash
    this.saveTrack();
  };

  onChangeTemplateSettings = param => event => {
    const value = event.target.value;

    const templateSettings = { ...this.state.templateSettings };
    templateSettings[param] = value;
    this.setState({ templateSettings });
    // debounced with lodash
  };

  onChangeTeamSizeTemplate = event => {
    const value = event.target.value;

    const templateSettings = { ...this.state.templateSettings };
    templateSettings.teamSize = value;
    this.setState({ templateSettings });
  };

  onChangeStrategyTemplate = event => {
    const value = event.target.value;
    const templateSettings = { ...this.state.templateSettings };
    templateSettings.strategy = value;
    this.setState({ templateSettings });
  };

  onChangeTemplateDescription = event => {
    // const value = event.target.value;
    const templateSettings = { ...this.state.templateSettings };
    templateSettings.templateDescription[this.props.languageSelected] = event;
    // templateSettings.templateDescription[this.props.languageSelected] = value;
    this.setState({ templateSettings });
  };

  onClickAdministrationMode = mode => () => {
    const {
      isMicrosoftTeamsReminderActivated = false,
      isMobileModeActivated = true,
      isEmailModeActivated = false,
      isAnchorEmailMode = false,
    } = this.props.templateSettings;
    const templateSettings = { ...this.props.templateSettings };
    if (mode === "mobile") {
      templateSettings.isMobileModeActivated = !isMobileModeActivated;
    } else if (mode === "web") {
      templateSettings.isAnchorEmailMode = !isAnchorEmailMode;
    } else if (mode === "email") {
      templateSettings.isEmailModeActivated = !isEmailModeActivated;
    } else if (mode === "microsoftTeams") {
      templateSettings.isMicrosoftTeamsReminderActivated = !isMicrosoftTeamsReminderActivated;
    }
    this.props.changeField({ templateSettings });
    this.saveTrack();
  };

  onChangeTemplateLabel = event => {
    const value = event.target.value;
    const templateSettings = { ...this.state.templateSettings };
    templateSettings.templateLabel[this.props.languageSelected] = value;
    // templateSettings.templateDescription[this.props.languageSelected] = value;
    this.setState({ templateSettings });
  };

  onChangeBooleanTemplate = param => value => {
    const templateSettings = { ...this.state.templateSettings };
    templateSettings[param] = value;
    this.setState({ templateSettings });
  };

  onClickPublishTemplate = () => {
    const { clientId, userId, trackId } = this.props;

    this.props.createAndModifyTemplateRequest({
      templateSettings: this.state.templateSettings,
      isTemplate: this.state.isTemplate,
      isPrivate: this.state.isPrivate,
      trackId,
      clientId,
      userId,
    });
  };

  onSelectLanguage = languageSelected => () => {
    this.props.changeField({ languageSelected });
  };

  onClickCloseButton = () => {
    this.props.changeField({ rightSideScreen: "library" });
  };

  render() {
    const {
      isVisible,
      onClose,
      classes,
      title,
      description,
      uploading,
      picture,
      languageSelected,
      isSuperAdmin,
    } = this.props;
    const { templateSettings } = this.props;

    const {
      isMicrosoftTeamsReminderActivated = false,
      isMobileModeActivated = true,
      isEmailModeActivated = false,
      isAnchorEmailMode = false,
    } = templateSettings;

    const {
      templateDescription = {},
      templateLabel = {},
    } = this.state.templateSettings;

    return (
      <Dialog
        open={isVisible}
        // open={true}
        onClose={onClose}
        classes={{ paper: classes.paper }}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        <div className={classes.closeButton} onClick={onClose}>
          <MaterialCloseIcon
            style={{ color: "#fff", width: 20, height: 20, zIndex: 5 }}
          />
        </div>
        <div className={classes.topBar}>
          <div className={classes.barBody}>
            <Typography className={classes.topBarTitle}>
              {i18n.t("track-settings")}
            </Typography>
            <div className={classes.buttonDiv}>
              <FlatButton
                className={classes.leftButton}
                onClick={onClose}
                color="primary"
                title={i18n.t("previous")}
              ></FlatButton>
            </div>
          </div>
        </div>

        <DialogContent className={classes.bodyDiv}>
          <Typography className={classes.subTitle} style={{ marginTop: 0 }}>
            {i18n.t("language", { lng: languageSelected })}
          </Typography>
          <Language
            className={classes.flag}
            language={languageSelected}
            onSelect={this.onSelectLanguage}
          />
          <div className={classes.firstDiv}>
            <div className={classes.leftDiv}>
              <Typography className={classes.subTitle}>
                {i18n.t("campaign-title", { lng: languageSelected })}
              </Typography>
              <TextField
                autoFocus
                fullWidth
                label={i18n.t("campaign-title", { lng: languageSelected })}
                value={title[languageSelected] || ""}
                onChange={this.onChangeParams("title")}
                margin="normal"
                variant="outlined"
              />
              <Typography className={classes.subTitle}>
                {i18n.t("campaign-description", { lng: languageSelected })}
              </Typography>
              <TextField
                fullWidth
                multiline
                label={i18n.t("campaign-description", {
                  lng: languageSelected,
                })}
                value={description[languageSelected] || ""}
                onChange={this.onChangeParams("description")}
                margin="normal"
                variant="outlined"
              />
              <Typography className={classes.subTitle}>
                {i18n.t("campaign-administration-mode", {
                  lng: languageSelected,
                })}
              </Typography>
              <div className={classes.administrationModes}>
                <AdministrationMode
                  availableModes={
                    isSuperAdmin
                      ? ["mobile", "web", "email", "microsoftTeams"]
                      : ["mobile", "web", "microsoftTeams"]
                  }
                  isMicrosoftTeamsReminderActivated={
                    isMicrosoftTeamsReminderActivated
                  }
                  isMobileModeActivated={isMobileModeActivated}
                  isEmailModeActivated={isEmailModeActivated}
                  isAnchorEmailMode={isAnchorEmailMode}
                  onClick={this.onClickAdministrationMode}
                />
              </div>
            </div>
            <div className={classes.rightDiv}>
              <Typography
                className={classes.subTitle}
                style={{ marginLeft: 2 }}
              >
                {i18n.t("campaign-picture", { lng: languageSelected })}
              </Typography>
              <Uploader
                onClickUnsplashPicture={this.onClickUnsplashPicture}
                hasUnsplash
                width={250}
                height={400}
                picture={picture}
                className={classes.uploader}
                onFileUploaded={this.onFileUploaded}
                uploading={uploading}
              />
            </div>
          </div>
          <BooleanField
            title={i18n.t("is-content-editable", { lng: languageSelected })}
            explanation={i18n.t("is-content-editable-explanation", {
              lng: languageSelected,
            })}
            onChange={this.onChangeIsContentEditable}
            checked={this.props.isContentEditable}
          />
          {this.props.isSuperAdmin && (
            <div className={classes.templateSettings}>
              <div className={classes.isTemplateDiv}>
                <Typography className={`${classes.autoInvite}`}>
                  {i18n.t("activate-template", {
                    lng: languageSelected,
                  })}
                </Typography>
                <Switch
                  onChange={() =>
                    this.setState({
                      isTemplate: !this.state.isTemplate,
                    })
                  }
                  checked={this.state.isTemplate}
                />
              </div>
              {this.state.isTemplate && (
                <div className={classes.templateDiv}>
                  <Typography className={classes.title}>
                    {i18n.t("template-settings", { lng: languageSelected })}
                  </Typography>
                  <div className={classes.line} />
                  <Typography className={classes.subTitle}>
                    {i18n.t("public-template", { lng: languageSelected })}
                  </Typography>
                  <div className={classes.autoInviteDiv}>
                    <Typography className={`${classes.autoInvite}`}>
                      {i18n.t("public-template-explanation", {
                        lng: languageSelected,
                      })}
                    </Typography>
                    <Switch
                      onChange={() =>
                        this.setState({
                          isPrivate: !this.state.isPrivate,
                        })
                      }
                      checked={!this.state.isPrivate}
                    />
                  </div>
                  <Typography className={classes.subTitle}>
                    {i18n.t("template-editable", { lng: languageSelected })}
                  </Typography>
                  <div className={classes.autoInviteDiv}>
                    <Typography className={`${classes.autoInvite}`}>
                      {i18n.t("template-editable-explanation", {
                        lng: languageSelected,
                      })}
                    </Typography>
                    <Switch
                      onChange={this.onChangeBooleanTemplate("editable")}
                      checked={this.state.templateSettings.editable}
                    />
                  </div>
                  <Typography className={classes.subTitle}>
                    {i18n.t("template-description", {
                      lng: languageSelected,
                    })}
                  </Typography>
                  <EditableInput
                    value={
                      templateDescription
                        ? templateDescription[languageSelected]
                        : ""
                    }
                    languageSelected={languageSelected}
                    onChange={this.onChangeTemplateDescription}
                  />
                  <Typography className={classes.subTitle}>
                    {i18n.t("template-label", { lng: languageSelected })}
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    label={i18n.t("template-label", {
                      lng: languageSelected,
                    })}
                    value={templateLabel ? templateLabel[languageSelected] : ""}
                    onChange={this.onChangeTemplateLabel}
                    margin="normal"
                    variant="outlined"
                  />

                  <Typography className={classes.subTitle}>
                    {i18n.t("template-editable", { lng: languageSelected })}
                  </Typography>
                  <div className={classes.autoInviteDiv}>
                    <Typography className={`${classes.autoInvite}`}>
                      {i18n.t("template-editable-explanation", {
                        lng: languageSelected,
                      })}
                    </Typography>
                    <Switch
                      onChange={this.onChangeBooleanTemplate("editable")}
                      checked={this.state.templateSettings.editable}
                    />
                  </div>
                  <Typography className={classes.subTitle}>
                    {i18n.t("campaign-repetition", {
                      lng: languageSelected,
                    })}
                  </Typography>
                  <div className={classes.autoInviteDiv}>
                    <Typography className={`${classes.autoInvite}`}>
                      {i18n.t("campaign-repetition-explanation", {
                        lng: languageSelected,
                      })}
                    </Typography>
                    <Switch
                      onChange={this.onChangeBooleanTemplate("hasRepetition")}
                      checked={this.state.templateSettings.hasRepetition}
                    />
                  </div>
                  <Typography className={classes.subTitle}>
                    {i18n.t("campaign-team", { lng: languageSelected })}
                    <span className={classes.textInfo}>
                      {i18n.t("optional", { lng: languageSelected })}
                    </span>
                  </Typography>
                  <Typography>
                    {i18n.t("campaign-team-explanation", {
                      lng: languageSelected,
                    })}
                  </Typography>
                  <TextField
                    className={classes.teamSizeField}
                    select
                    label={i18n.t("campaign-team", {
                      lng: languageSelected,
                    })}
                    value={this.state.templateSettings.teamSize}
                    onChange={this.onChangeTeamSizeTemplate}
                    margin="normal"
                    variant="outlined"
                  >
                    {[0, 1, 2, 3, 4].map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Typography className={classes.subTitle}>
                    {i18n.t("campaign-strategy", { lng: languageSelected })}
                  </Typography>
                  <Typography>
                    {i18n.t("campaign-strategy-explanation", {
                      lng: languageSelected,
                    })}
                  </Typography>
                  <TextField
                    className={classes.teamSizeField}
                    select
                    label={i18n.t("campaign-strategy", {
                      lng: languageSelected,
                    })}
                    value={this.state.templateSettings.strategy}
                    onChange={this.onChangeStrategyTemplate}
                    margin="normal"
                    variant="outlined"
                  >
                    {[
                      {
                        label: i18n.t("campaign-strategy-ai", {
                          lng: languageSelected,
                        }),
                        value: "bestFit",
                      },
                      {
                        label: i18n.t("campaign-strategy-ordered", {
                          lng: languageSelected,
                        }),
                        value: "ordered",
                      },
                    ].map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Typography className={classes.subTitle}>
                    {i18n.t("campaign-frequency", {
                      lng: languageSelected,
                    })}
                  </Typography>
                  <Typography>
                    {i18n.t("campaign-frequency-explanation", {
                      lng: languageSelected,
                    })}
                  </Typography>
                  <TextField
                    className={classes.teamSizeField}
                    select
                    label={i18n.t("campaign-frequency", {
                      lng: languageSelected,
                    })}
                    value={this.state.templateSettings.frequency}
                    onChange={this.onChangeTemplateSettings("frequency")}
                    margin="normal"
                    variant="outlined"
                  >
                    {[
                      {
                        label: i18n.t("campaign-frequency-noLimit", {
                          lng: languageSelected,
                        }),
                        value: "noLimit",
                      },
                      {
                        label: i18n.t("campaign-frequency-oneADay", {
                          lng: languageSelected,
                        }),
                        value: "oneADay",
                      },
                    ].map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              )}
              {!this.props.requesting && (
                <FlatButton
                  disabled={!title || !picture}
                  variant="contained"
                  className={classes.templateButton}
                  onClick={this.onClickPublishTemplate}
                  title={
                    this.props.isTemplate
                      ? i18n.t("modify-template")
                      : i18n.t("publish-template")
                  }
                />
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  const language = getAppLanguage(state);
  const userId = getUserId(state);
  const clientId = getSelectedClientId(state);
  const {
    requesting,
    languageSelected,
    title,
    description,
    picture,
    isTemplate,
    isPrivate,
    templateSettings = { templateDescription: {} },
    isContentEditable = true,
  } = state.createTrack;
  const { role } = state.user;
  const { uploading } = state.upload;
  const isSuperAdmin = role === "superAdmin";

  return {
    trackId: state.createTrack._id,
    requesting,
    userId,
    clientId,
    isSuperAdmin,
    isTemplate,
    isPrivate,
    templateSettings,
    language,
    languageSelected,
    title,
    description,
    uploading,
    isContentEditable,
    picture,
    uploadedPicture: state.upload.picture,
  };
};

export default connect(mapStateToProps, {
  saveTrackRequest,
  changeField,
  uploadPictureRequest,
  createAndModifyTemplateRequest,
})(withStyles(styles)(CreateSettings));
