import React from "react";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Colors from "constants/Colors";
import i18n from "i18n";

const useStyles = makeStyles(theme => ({
  main: {
    maxWidth: 768,
    width: "100%",
    paddingTop: 20,
    paddingBottom: 38,
    marginTop: 24,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#fff",
    border: "solid",
    borderWidth: 1,
    borderRadius: 8,
    borderColor: Colors.mediumGrey,
  },
  title: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    lineHeight: 1.5,
    fontSize: 16,
    color: "#262627",
  },

  numberDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#0070f3",
    position: "relative",
    marginRight: 10,
    height: 28,
    width: 28,
    borderRadius: 14,
  },
  number: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    color: "#fff",
    lineHeight: 1.5,
    fontSize: 16,
  },
  topDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  averageContainer: {
    marginLeft: 20,
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 30,
    backgroundColor: "#F0F0F0",
    borderRadius: 8,
    paddingLeft: 12,
    paddingRight: 12,
  },
  average: {
    fontSize: 14,
    color: "#898989",
  },
  answers: {
    color: "#898989",
    fontSize: 12,
    paddingTop: 8,
    paddingBottom: 34,
  },
  leftDiv: {
    paddingLeft: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 30,
  },
  rightDiv: {
    paddingRight: 32,
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  chart: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    paddingRight: 15,
  },
  rateScale: {
    marginTop: 36,
    paddingRight: 55,
    display: "flex",
    flexDirection: "row",
  },
  leftScale: {
    textAlign: "left",
    flex: 1,
    color: "#262627",
    opacity: 0.5,
  },
  rightScale: {
    textAlign: "right",
    flex: 1,
    color: "#262627",
    opacity: 0.5,
  },
  comments: {
    marginTop: 25,
    width: "fit-content",
    cursor: "pointer",
    padding: "5px 10px",
    borderColor: Colors.grey,
    border: "solid",
    borderWidth: 1,
    borderRadius: 4,
    transition: "all 0.2s",
    "&:hover": {
      background: Colors.lightGrey,
    },
  },
  accent: {
    color: "#0070f3",
    fontWeight: "bold",
  },
  chartLegend: {
    marginBottom: 25,
    marginTop: -25,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  firstTitle: {
    width: 150,
    color: "#898989",
  },
  secondTitle: {
    textAlign: "center",
    width: 130,
    color: "#898989",
  },
  numberTitle: {
    marginLeft: 10,
    textAlign: "center",
    width: 80,
    color: "#898989",
  },
  barTitle: {
    width: 150,
  },
}));

interface Props {
  index: number;
  title: string;
  totalVotes: number;
  participants: number;
  average?: number;
  children: JSX.Element;
}

const ResultBox = ({
  index,
  totalVotes,
  participants,
  average,
  title,
  children,
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <div className={classes.leftDiv}>
        <span className={classes.numberDiv}>
          <Typography className={classes.number}>{index + 1}</Typography>
        </span>
      </div>
      <div className={classes.rightDiv}>
        <div className={classes.topDiv}>
          <Typography className={classes.title}>{title}</Typography>
          {!!average && (
            <div className={classes.averageContainer}>
              <Typography className={classes.average}>
                {i18n.t("avg-choice-per-participant", {
                  avg: average.toFixed(1),
                })}
              </Typography>
            </div>
          )}
        </div>
        <Typography className={classes.answers}>
          {i18n.t("total-answered", { totalVotes, participants })}
        </Typography>
        {children}
      </div>
    </div>
  );
};

export default ResultBox;
