import { Moment } from "moment";
import { ReviewStatusType } from "scenes/Campaigns/CampaignScreen/ReviewsSide/types";
import {
  CAMPAIGN_GET_ALL_REQUESTING,
  CAMPAIGN_CREATE_REQUESTING,
  TRACK_GET_ALL_REQUESTING,
  CAMPAIGN_SELECT,
  CAMPAIGN_GET_ALL_DONE_STEPS_REQUESTING,
  CAMPAIGN_GET_USER_RANKING_REQUESTING,
  CAMPAIGN_GET_TEAM_RANKING_REQUESTING,
  CAMPAIGN_CLOSE_SNACK_MESSAGE,
  CAMPAIGN_SELECT_TAB,
  CAMPAIGN_GET_ALL_TEMPLATES_REQUESTING,
  CHANGE_ADD_USERS_MODAL,
  CAMPAIGN_SELECT_PREVIEW_TAB,
  USER_CAMPAIGNS_GET_LIST_REQUESTING,
  USER_CAMPAIGNS_LOAD_MORE_REQUESTING,
  CAMPAIGN_MODIFY_REQUESTING,
  CAMPAIGN_ACTIVATE_EMAIL_REMINDER_REQUESTING,
  CAMPAIGN_RESET_TEAMS_REQUESTING,
  CAMPAIGN_DELETE_REQUESTING,
  TRACK_DELETE_REQUESTING,
  GET_ALL_MY_CLIENTS_TRACKS_REQUESTING,
  USER_CAMPAIGN_DELETE_REQUESTING,
  CAMPAIGN_ADD_PARTICIPANTS_REQUESTING,
  CAMPAIGN_GET_ALL_DRAFTS_REQUESTING,
  CAMPAIGN_ADD_TO_TEMPLATE_REQUESTING,
  CAMPAIGN_GET_ALL_PUBLIC_TEMPLATES_REQUESTING,
  DRAFT_REMOVE_REQUESTING,
  VALIDATE_RANDOM_STEP_REQUESTING,
  CAMPAIGN_GET_COMMENTS_REQUESTING,
  CAMPAIGN_GET_USERS_INFOS_REQUESTING,
  SELECT_CAMPAIGN_REQUESTING,
  COMMENT_REVIEW_REQUESTING,
  SEE_COMMENTS_REVIEW_REQUESTING,
  CAMPAIGN_SELECTED_DONE_STEP,
  CHANGE_CAMPAIGN_DONE_STEP_SORTED,
  CHANGE_CAMPAIGN_DONE_STEP_SORTED_DESC,
  CAMPAIGN_GET_ALL_DONE_STEPS_FORMATED_REQUESTING,
  CAMPAIGN_GET_NEXT_DONE_STEP_REVIEW,
  CAMPAIGN_GET_PREVIOUS_DONE_STEP_REVIEW,
  CAMPAIGN_VALIDATE_REVIEW_REQUESTING,
  CAMPAIGN_SEND_EMAIL_RECAP_REQUESTING,
  CAMPAIGN_GET_STEPS_REQUESTING,
  GET_STEP_COMMENT_REQUESTING,
  HIDE_STEP_COMMENT_REQUESTING,
  CAMPAIGN_CREATE_EVENT_REQUESTING,
  CAMPAIGN_UPDATE_EVENT_REQUESTING,
  CAMPAIGN_DELETE_EVENT_REQUESTING,
  CAMPAIGN_REGENERATE_CERTIFICATE_REQUESTING,
  CAMPAIGN_GET_INTERACTIONS_REQUESTING,
  ADD_LIKE_STEP_COMMENT_REQUESTING,
  REMOVE_LIKE_STEP_COMMENT_REQUESTING,
} from "./constants";

import { ReviewCommentType } from "./types";

export const selectTab = payload => {
  return {
    type: CAMPAIGN_SELECT_TAB,
    payload,
  };
};

export const selectPreviewTab = payload => {
  return {
    type: CAMPAIGN_SELECT_PREVIEW_TAB,
    payload,
  };
};

export const closeSnackMessage = () => {
  return {
    type: CAMPAIGN_CLOSE_SNACK_MESSAGE,
  };
};

export const getAllCampaignsRequest = (payload: { clientId: string }) => {
  return {
    type: CAMPAIGN_GET_ALL_REQUESTING,
    payload,
  };
};

export const getAllTracksRequest = (payload: { clientId: string }) => {
  return {
    type: TRACK_GET_ALL_REQUESTING,
    payload,
  };
};

export const getAllDraftsRequest = (payload: { clientId: string }) => {
  return {
    type: CAMPAIGN_GET_ALL_DRAFTS_REQUESTING,
    payload,
  };
};

export const getAllMyClientsTracksRequest = (payload: { clientId: string }) => {
  return {
    type: GET_ALL_MY_CLIENTS_TRACKS_REQUESTING,
    payload,
  };
};

export const createCampaignRequest = (payload: any) => {
  return {
    type: CAMPAIGN_CREATE_REQUESTING,
    payload,
  };
};

export const selectCampaignRequest = (payload: any) => {
  return {
    type: SELECT_CAMPAIGN_REQUESTING,
    payload,
  };
};

export const selectCampaign = (payload: any) => {
  return {
    type: CAMPAIGN_SELECT,
    payload,
  };
};

export const getCampaignAllDoneStepsRequest = (payload: {
  campaignId: string;
}) => {
  return {
    type: CAMPAIGN_GET_ALL_DONE_STEPS_REQUESTING,
    payload,
  };
};

export const getCampaignAllDoneStepsFormatedRequest = (payload: {
  campaignId: string;
  userReviewedId: string;
  doneSteps: any;
}) => {
  return {
    type: CAMPAIGN_GET_ALL_DONE_STEPS_FORMATED_REQUESTING,
    payload,
  };
};

export const getCampaignCommentsRequest = (payload: { campaignId: string }) => {
  return {
    type: CAMPAIGN_GET_COMMENTS_REQUESTING,
    payload,
  };
};

export const getCampaignUserRankingRequest = (payload: {
  campaignId: string;
  limit?: number;
  page?: number;
}) => {
  return {
    type: CAMPAIGN_GET_USER_RANKING_REQUESTING,
    payload,
  };
};

export const getCampaignTeamRankingRequest = (payload: {
  campaignId: string;
  limit?: number;
  page?: number;
}) => {
  return {
    type: CAMPAIGN_GET_TEAM_RANKING_REQUESTING,
    payload,
  };
};

export const getCampaignInteraction = (payload: { campaignId: string }) => {
  return { type: CAMPAIGN_GET_INTERACTIONS_REQUESTING, payload };
};

export const getAllTemplatesRequest = payload => {
  return {
    type: CAMPAIGN_GET_ALL_TEMPLATES_REQUESTING,
    payload,
  };
};

export const getAllPublicTemplatesRequest = payload => {
  return {
    type: CAMPAIGN_GET_ALL_PUBLIC_TEMPLATES_REQUESTING,
    payload,
  };
};

export const changeAddUsersModal = (payload: {
  isAddUsersModalVisible: Boolean;
  hasPublishCampaignAnimation: Boolean;
}) => {
  return {
    type: CHANGE_ADD_USERS_MODAL,
    payload,
  };
};

export const getUserCampaignsListRequest = (payload: {
  campaignId: string;
  page: number;
  limit: number;
}) => {
  return {
    type: USER_CAMPAIGNS_GET_LIST_REQUESTING,
    payload,
  };
};

export const loadMoreUserCampaignsRequest = (payload: {
  campaignId: string;
}) => {
  return {
    type: USER_CAMPAIGNS_LOAD_MORE_REQUESTING,
    payload,
  };
};

export const modifyCampaignRequest = (payload: {
  campaignId: string;
  resources?: any[];
}) => {
  return {
    type: CAMPAIGN_MODIFY_REQUESTING,
    payload,
  };
};

export const deleteCampaignRequest = (payload: { campaignId: string }) => {
  return {
    type: CAMPAIGN_DELETE_REQUESTING,
    payload,
  };
};

export const deleteTrackRequest = (payload: { trackId: string }) => {
  return {
    type: TRACK_DELETE_REQUESTING,
    payload,
  };
};

export const activateEmailReminderRequest = (payload: {
  campaignId: string;
}) => {
  return {
    type: CAMPAIGN_ACTIVATE_EMAIL_REMINDER_REQUESTING,
    payload,
  };
};

export const resetTeamsRequest = (payload: {
  campaignId: string;
  teamSize: number;
}) => {
  return {
    type: CAMPAIGN_RESET_TEAMS_REQUESTING,
    payload,
  };
};

export const deleteUserCampaignRequest = (payload: {
  userCampaignId: string;
}) => {
  return {
    type: USER_CAMPAIGN_DELETE_REQUESTING,
    payload,
  };
};

export const addParticipantsRequest = (payload: {
  participants: [];
  campaignId: string;
}) => {
  return {
    type: CAMPAIGN_ADD_PARTICIPANTS_REQUESTING,
    payload,
  };
};

export const addCampaignToTemplateRequest = (payload: {
  trackId: string;
  isTemplate: boolean;
}) => {
  return {
    type: CAMPAIGN_ADD_TO_TEMPLATE_REQUESTING,
    payload,
  };
};

export const removeDraftRequest = (payload: {
  trackId: string;
  isRemoved: true;
}) => {
  return {
    type: DRAFT_REMOVE_REQUESTING,
    payload,
  };
};

export const addRandomStepRequest = (payload: {
  userCampaignId: string;
  userId: string;
  trackId: string;
}) => {
  return {
    type: VALIDATE_RANDOM_STEP_REQUESTING,
    payload,
  };
};

export const getCampaignUsersInfosRequest = (payload: {
  campaignId: string;
}) => {
  return {
    type: CAMPAIGN_GET_USERS_INFOS_REQUESTING,
    payload,
  };
};

export const commentReviewRequest = (payload: {
  stepFeedbackId: string;
  comment: ReviewCommentType;
  coachId: string;
  userId: string;
}) => {
  return {
    type: COMMENT_REVIEW_REQUESTING,
    payload,
  };
};

export const seeCommentsReviewRequest = (payload: {
  stepFeedbackId: string;
}) => {
  return {
    type: SEE_COMMENTS_REVIEW_REQUESTING,
    payload,
  };
};

export const setSelectedDoneStep = (payload: string) => {
  return {
    type: CAMPAIGN_SELECTED_DONE_STEP,
    payload,
  };
};

export const setSortedDoneStep = (payload: string) => {
  return {
    type: CHANGE_CAMPAIGN_DONE_STEP_SORTED,
    payload,
  };
};

export const setSortedDoneStepDesc = (payload: string) => {
  return {
    type: CHANGE_CAMPAIGN_DONE_STEP_SORTED_DESC,
    payload,
  };
};

export const getUserCampaignNextSelectedReview = () => {
  return {
    type: CAMPAIGN_GET_NEXT_DONE_STEP_REVIEW,
  };
};

export const getUserCampaignPreviousSelectedReview = () => {
  return {
    type: CAMPAIGN_GET_PREVIOUS_DONE_STEP_REVIEW,
  };
};

export const validateReviewRequest = (payload: {
  selectedStatus: ReviewStatusType;
  stepFeedbackId: string;
  userId: string;
  comment?: ReviewCommentType;
}) => {
  return {
    type: CAMPAIGN_VALIDATE_REVIEW_REQUESTING,
    payload,
  };
};

export const sendEmailRecapRequest = (payload: { email: string }) => {
  return {
    type: CAMPAIGN_SEND_EMAIL_RECAP_REQUESTING,
    payload,
  };
};

export const getCampaignStepsRequest = (payload: { trackId: string }) => {
  return {
    type: CAMPAIGN_GET_STEPS_REQUESTING,
    payload,
  };
};

export const getStepCommentsByStepId = (payload: { stepId: string }) => {
  return {
    type: GET_STEP_COMMENT_REQUESTING,
    payload,
  };
};

export const createEvent = (payload: {
  title: string;
  type: string;
  data: { url?: string; survey?: string };
  date: Moment;
}) => {
  return {
    type: CAMPAIGN_CREATE_EVENT_REQUESTING,
    payload,
  };
};

export const hideStepCommentRequest = (payload: { stepCommentId: string }) => {
  return {
    type: HIDE_STEP_COMMENT_REQUESTING,
    payload,
  };
};

export const updateEvent = (payload: {
  eventId: string;
  title: string;
  type: string;
  data: { url?: string; survey?: string };
  date: Moment;
}) => {
  return {
    type: CAMPAIGN_UPDATE_EVENT_REQUESTING,
    payload,
  };
};

export const deleteEvent = (payload: { eventId: string }) => {
  return {
    type: CAMPAIGN_DELETE_EVENT_REQUESTING,
    payload,
  };
};

export const regenerateCertificateRequest = (payload: {
  userCampaignId: string;
  userId: string;
}) => {
  return {
    type: CAMPAIGN_REGENERATE_CERTIFICATE_REQUESTING,
    payload,
  };
};


export const addLikeToCommentary = (payload: {
  stepCommentId: string;
  userId: string;
}) => {
  return {
    type: ADD_LIKE_STEP_COMMENT_REQUESTING,
    payload,
  };
};

export const removeLikeFromCommentary = (payload: {
  stepCommentId: string;
  userId: string;
}) => {
  return {
    type: REMOVE_LIKE_STEP_COMMENT_REQUESTING,
    payload,
  };
};