// First, we need to import the FlatList and other React Native component
import "intersection-observer";
import React, { useState } from "react";
import withStyles from '@mui/styles/withStyles';
import Colors from "constants/Colors";
import Typography from "@mui/material/Typography";
import { Avatar } from "@mui/material";
import { motion } from "framer-motion";
import "moment/locale/fr";
import DeleteIcon from "scenes/CreateTrack/DeleteIcon";
import SelectedIcon from "scenes/Campaigns/AddUsersToCampaignModal/SelectedIcon";
import AddIcon from "scenes/Campaigns/AddUsersToCampaignModal/AddIcon";

const styles = theme => ({
  list: {
    minWidth: "100%",
    marginTop: 20,
  },
  name: {
    color: Colors.text,
  },
  widthDiv: {
    width: "100%",
  },
  title: {
    color: "black",
    backgroundColor: "white",
    border: "1px solid rgba(0,0,0,.09)",
    boxShadow: "0 2px 0 0 rgba(0,0,0,.03)",
    borderRadius: 5,
    padding: 10,
    fontWeight: 500,
    marginBottom: 20,
  },
  firstSection: {
    marginTop: 0,
    margin: "12px 0 0",
    padding: "35px 30px 0",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    overflow: "auto",
    justifyContent: "space-around",
    alignItems: "flex-start",
    marginBottom: 15,
  },
  evolutionTitle: {
    width: "100%",
    color: Colors.black,
    textAlign: "left",
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.5,
    marginBottom: 16,
  },
  whiteBackground: {
    backgroundColor: Colors.white,
  },
  rankingSection: {
    paddingTop: 10,
  },
  firstTitle: {
    alignSelf: "center",
    marginTop: 15,
    marginBottom: 15,
  },
  userRow: {
    position: "relative",
    border: "1px solid rgba(0,0,0,.09)",
    backgroundColor: "#fff",
    marginBottom: 10,
    borderRadius: 8,
    padding: 15,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  userName: {
    marginLeft: 15,
  },
  subTitle: {
    fontWeight: 500,
    marginLeft: 10,
  },
  stat: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: 1,
  },
  progressSection: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  statDiv: {
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    border: "1px solid rgba(0,0,0,.09)",
    borderRadius: 5,
    padding: "12px 15px",
    fontWeight: 500,
    marginBottom: 20,
  },
  biggerRow: {
    display: "flex",
    flex: 1.5,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  firstRow: {
    display: "flex",
    flex: 1.5,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  row: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  tableHeaderRow: {
    width: "100%",
    marginBottom: 10,
    borderRadius: 8,
    padding: 10,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  tableHeader: {
    fontSize: 15,
    fontWeight: 500,
    color: "black",
  },
  avatar: {
    objectFit: "cover",
    width: 30,
    height: 30,
    borderRadius: 15,
  },
  date: {
    marginRight: 10,
    overflowWrap: "break-word",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    "text-overflow": "ellipsis",
  },
  deleteButton: {
    position: "absolute",
    right: 15,
    transition: "all .2s ease",
    "&:hover": {
      color: "red",
    },
  },
  roleButton: {
    position: "absolute",
    right: 55,
    "&:hover": {
      color: Colors.pureMain,
    },
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  clickable: {
    transition: "all 0.2s",
    cursor: "pointer",
    "&:hover": {
      borderColor: Colors.pureMain,
    },
  },
});

const UserRow = ({
  classes,
  firstName,
  lastName,
  picture,
  createdAt,
  email,
  role,
  _id,
  index,
  onClick,
  isDeleted,
  isEmail,
  isSelected = false,
}) => {
  const [isHovered, setHover] = useState(false);
  const onMouseLeave = () => setHover(false);
  const onMouseEnter = () => setHover(true);
  if (isDeleted) {
    return null;
  }

  return (
    <motion.div
      onClick={onClick}
      key={_id}
      className={`${classes.userRow} ${onClick && classes.clickable}`}
      custom={index}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      initial="hidden"
      animate="visible"
    >
      <div className={classes.firstRow}>
        <Avatar src={picture} className={classes.avatar} />
        <Typography
          className={classes.userName}
        >{`${firstName} ${lastName}`}</Typography>
      </div>
      {isEmail && (
        <div className={classes.biggerRow}>
          <Typography className={classes.date}>{email}</Typography>
        </div>
      )}
      <div className={classes.button}>
        {isHovered && isSelected ? (
          <DeleteIcon sizePercentage={1} color={Colors.red} />
        ) : isSelected ? (
          <SelectedIcon />
        ) : (
          <AddIcon />
        )}
      </div>
    </motion.div>
  );
};

export default withStyles(styles)(UserRow);
