//@flow
import {
  USER_SET,
  USER_UNSET,
  USER_MODIFY,
  USER_MODIFY_REQUESTING,
  USER_GET_COMPANY_LOGO_REQUESTING,
  USER_GET_REQUESTING,
} from "./constants";

type addFriendType = {
  userId: string;
  friendId: string;
};

type hideFriendRequestType = {
  userId: string;
  friendId: string;
};

export const getUserRequest = (payload: string) => {
  return {
    type: USER_GET_REQUESTING,
    payload,
  };
};

export const modifyUserRequest = (payload: { userId: string }) => {
  return {
    type: USER_MODIFY_REQUESTING,
    payload,
  };
};

export const modifyUser = payload => {
  return {
    type: USER_MODIFY,
    payload,
  };
};

export const setUser = user => {
  return {
    type: USER_SET,
    user,
  };
};

export const unsetUser = () => {
  return {
    type: USER_UNSET,
  };
};

export const getCompanyLogoRequest = (payload: string) => {
  return {
    type: USER_GET_COMPANY_LOGO_REQUESTING,
    payload,
  };
};
