import { createSelector } from "reselect";

export const getCoachResources = state => state.resource.coachResources;

export const getLibraryResources = state => state.resource.libraryResources;

export const getResourceRequesting = state => state.resource.requesting;

export const getCampaignResources = state =>
  state.resource.campaignResources || [];

export const getCampaignResourcesWithDetails = createSelector(
  getCampaignResources,
  campaignResources => {
    return campaignResources
      .map(resource => {
        return {
          ...resource,
          resourceId: resource._id,
        };
      })
      .sort((a, b) => {
        return new Date(b.addedDate) - new Date(a.addedDate);
      });
  },
);
