import React, { useState } from "react";
import withStyles from "@mui/styles/withStyles";
import Modal from "components/Modal";
import SearchBar from "components/SearchBar";
import i18n from "i18n";
import { connect } from "react-redux";
import getAvailableResources from "services/resource/getAvailableResourceSelector";
import { addResource, deleteResourceRequest } from "services/resource/actions";
import { updateCoachingSessionRequest } from "services/coaching/actions";
import ResourceBox from "scenes/Campaigns/SeeUserModal/Coaching/Resources/ResourceBox";
import { Loader } from "components";
import Fuse from "fuse.js";
import { getCoachingSessionId } from "services/coaching/selectors";
import DeleteConfirmModal from "components/DeleteConfirmModal";
import FlatButton from "components/FlatButton";
import AddIcon from "scenes/Campaigns/SeeUserModal/Coaching/RoadmapCoaching/NewGoalModal/GoalSettings/AddIcon";
import EmptyText from "scenes/Campaigns/SeeUserModal/Coaching/RoadmapCoaching/EmptyText";

const fuseOptions = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 80,
  maxPatternLength: 20,
  minMatchCharLength: 1,
  keys: ["title", "url"],
};

const styles = theme => ({
  modal: {
    minWidth: 750,
    width: 900,
  },
  main: {},
  searchBar: {
    marginBottom: 15,
  },
  resourcesList: {
    boxSizing: "border-box",
    height: 600,
    overflowX: "scroll",
  },
  newResourceButton: {},
  buttonIcon: {
    marginRight: 10,
  },
});

const LibraryResourcesModal = ({
  classes,
  isVisible,
  onClose,
  onClickAdd,
  onClickNewResource = null,
  libraryResources = [],
  updateCoachingSessionRequest,
  addResource,
  deleteResourceRequest,
  coachingSessionId,
  requesting,
}) => {
  const [search, setSearch] = useState("");
  const [isDeleteModalVisible, openDeleteModal] = useState(false);
  const [stepSelected, setSelectedStep] = useState({});

  const onChangeSearch = value => {
    setSearch(value);
  };

  const onClickDelete = resource => e => {
    e.stopPropagation();
    setSelectedStep(resource);
    openDeleteModal(true);
  };

  const onConfirmDelete = () => {
    deleteResourceRequest({ ...stepSelected, resourceId: stepSelected._id });
    openDeleteModal(false);
  };

  const onOpenResource = resource => () => {
    window.open(resource.link);
  };

  const onCloseHandle = () => {
    setSearch("");
    onClose();
  };

  const fuse = new Fuse(libraryResources, fuseOptions);
  const searchResults =
    search.length > 0 ? fuse.search(search) : libraryResources;

  return (
    <>
      <DeleteConfirmModal
        onClose={() => openDeleteModal(false)}
        isVisible={isDeleteModalVisible}
        title={i18n.t("delete-confirmation-modal-title")}
        onClickConfirm={onConfirmDelete}
      />
      <Modal
        className={classes.modal}
        title={i18n.t("library")}
        isVisible={isVisible}
        onClose={onCloseHandle}
        topComponent={
          <div className={classes.newResourceButton}>
            {Boolean(onClickNewResource) && (
              <FlatButton
                icon={<AddIcon color={"#fff"} className={classes.buttonIcon} />}
                onClick={onClickNewResource}
                title={i18n.t("create")}
              />
            )}
          </div>
        }
      >
        <div className={classes.main}>
          <SearchBar
            className={classes.searchBar}
            autoFocus
            value={search}
            onChangeSearch={onChangeSearch}
            placeholder={i18n.t("search")}
          />
          <div className={classes.resourcesList}>
            {requesting && <Loader size={35} />}
            {!libraryResources.length && (
              <EmptyText
                onClick={onClickNewResource}
                firstTextPart={`${i18n.t("add")} ${i18n.t("first-resources")}`}
              />
            )}
            {searchResults.map(el => (
              <ResourceBox
                {...el}
                key={el._id}
                resource={el}
                index={el._id}
                onClick={onOpenResource(el)}
                onClickDelete={onClickDelete(el)}
                onClickAdd={onClickAdd(el)}
              />
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = state => {
  return {
    libraryResources: getAvailableResources(state),
    requesting: state.resource.requesting,
    coachingSessionId: getCoachingSessionId(state),
  };
};

export default connect(mapStateToProps, {
  updateCoachingSessionRequest,
  deleteResourceRequest,
  addResource,
})(withStyles(styles)(LibraryResourcesModal));
