import React from "react";
import Colors from "constants/Colors";

import makeStyles from '@mui/styles/makeStyles';
const BOX_WIDTH = 190;
const BOX_MARGIN = 20;

const LINE_WIDTH = BOX_WIDTH + BOX_MARGIN;

const BAR_HEIGHT = 1;
const CIRCLE_SIZE = 7;

const DashedLine = () => (
  <svg
    style={{ position: "absolute" }}
    width="208"
    height="2"
    viewBox="0 0 208 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="0.137207"
      y1="1.17297"
      x2="207.072"
      y2="1.17297"
      stroke="#E5E5E5"
      stroke-dasharray="6 6"
    />
  </svg>
);

const useStyles = makeStyles(theme => ({
  main: {
    width: LINE_WIDTH,
    paddingLeft: 60,
    position: "absolute",
    bottom: 42,
    transition: "all 0.2s ease",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  container: {
    width: LINE_WIDTH,
    position: "relative",
  },
  line: {
    transition: "all 0.2s ease",
    border: `${BAR_HEIGHT}px solid ${Colors.pureMain}`,
    height: 1,
    position: "absolute",
    left: 0,
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  circle: {
    zIndex: 2,
    bottom: -CIRCLE_SIZE / 2,
    position: "absolute",
    left: -CIRCLE_SIZE / 2,
    height: CIRCLE_SIZE,
    width: CIRCLE_SIZE,
    borderRadius: "50%",
    backgroundColor: Colors.pureMain,
  },
  activeCircle: {
    zIndex: 3,
    bottom: -CIRCLE_SIZE,
    left: -CIRCLE_SIZE,
    height: CIRCLE_SIZE * 2,
    width: CIRCLE_SIZE * 2,
  },
  nextLine: {
    borderWidth: 0,
  },
  nextCircle: {
    backgroundColor: "#E5E5E5",
  },
  leftCircle: {
    backgroundColor: "#E5E5E5",
    right: 0,
  },
}));

const Line = ({ isActive, index, isPlanned, isLast = false }) => {
  const classes = useStyles();
  return (
    <div
      className={`${classes.main}`}
      style={{
        left: LINE_WIDTH * index + BOX_WIDTH / 2,
        width: LINE_WIDTH,
      }}
    >
      <div className={classes.container}>
        <div
          className={`${classes.circle} ${isActive && classes.activeCircle} ${
            isPlanned && classes.nextCircle
          }`}
        />
        {(isActive || isPlanned) && !isLast ? (
          <DashedLine />
        ) : (
          !isLast && (
            <div className={`${classes.line}`} style={{ width: LINE_WIDTH }} />
          )
        )}
        {isLast && (
          <div className={`${classes.circle} ${classes.leftCircle}`} />
        )}
      </div>
    </div>
  );
};

export default Line;
