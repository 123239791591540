import React, { useCallback, useEffect, useState } from "react";
import i18n from "i18n";
import { Dialog, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import FlatButton from "components/FlatButton";
import { connect } from "react-redux";
import { createCoacheeRequest } from "services/myCoachees/actions";
import ExplanationSide from "./ExplanationSide";
import AvatarSide from "./AvatarSide";
import InfosSide from "scenes/MyCoachees/NewCoacheeModal/InfosSide";
import Steps from "scenes/MyCoachees/NewCoacheeModal/Steps";

import NEW_COACHING_PICTURE from "../../../resources/NewCoaching.svg";
import AVATAR_COACHING_PICTURE from "../../../resources/CoachingPicture.svg";

const ExitIcon = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6192 1.42315C12.6913 1.35118 12.7484 1.26573 12.7875 1.17167C12.8265 1.07761 12.8466 0.976779 12.8467 0.874942C12.8467 0.773105 12.8267 0.672253 12.7878 0.578143C12.7489 0.484034 12.6919 0.398511 12.6199 0.326457C12.5479 0.254402 12.4625 0.197229 12.3684 0.1582C12.2743 0.11917 12.1735 0.09905 12.0717 0.0989874C11.9698 0.0989248 11.869 0.118921 11.7749 0.157835C11.6808 0.196748 11.5952 0.253817 11.5232 0.325782L6.84658 5.00239L2.17132 0.325782C2.0258 0.180263 1.82843 0.0985107 1.62263 0.0985107C1.41684 0.0985107 1.21947 0.180263 1.07395 0.325782C0.928432 0.471302 0.84668 0.668669 0.84668 0.874465C0.84668 1.08026 0.928432 1.27763 1.07395 1.42315L5.75056 6.09841L1.07395 10.7737C1.0019 10.8457 0.944741 10.9313 0.905746 11.0254C0.86675 11.1196 0.84668 11.2205 0.84668 11.3224C0.84668 11.4243 0.86675 11.5252 0.905746 11.6193C0.944741 11.7134 1.0019 11.799 1.07395 11.871C1.21947 12.0166 1.41684 12.0983 1.62263 12.0983C1.72453 12.0983 1.82544 12.0782 1.91958 12.0392C2.01372 12.0002 2.09926 11.9431 2.17132 11.871L6.84658 7.19443L11.5232 11.871C11.6687 12.0164 11.866 12.098 12.0717 12.0978C12.2773 12.0977 12.4745 12.0159 12.6199 11.8704C12.7652 11.7248 12.8468 11.5276 12.8467 11.3219C12.8466 11.1162 12.7647 10.919 12.6192 10.7737L7.9426 6.09841L12.6192 1.42315Z"
      fill="black"
    />
  </svg>
);

const useStyles = makeStyles(theme => ({
  modal: {
    minWidth: 840,
    maxWidth: 840,
    height: 600,
    borderRadius: 10,
    minHeight: "40%",
    display: "flex",
    flexDirection: "row",
    // height: "fit-content",
  },
  main: {
    flex: 1,
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  textField: {
    width: "48%",
  },
  inputs: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  bottomSide: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  button: {
    fontSize: 14,
    height: 50,
    marginTop: 40,
    marginBottom: 20,
    width: "100%",
  },
  leftSide: {
    paddingRight: 75,
    paddingLeft: 75,
    width: 420,
    background: "#F4FBFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    flex: 1,
  },
  rightSide: {
    width: 420,
    paddingTop: 50,
    paddingBottom: 25,
    paddingRight: 75,
    paddingLeft: 75,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    flex: 1,
  },
  exitButton: {
    position: "absolute",
    top: 10,
    right: 10,
  },
}));

const NewCoacheeModal = ({
  isVisible,
  onClose,
  requesting,
  error,
  createCoacheeRequest,
}) => {
  const [modalStep, setModalStep] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [picture, setPicture] = useState("");
  const [lastName, setLastName] = useState("");
  const [hasAvatars, setHasAvatars] = useState(true);

  const classes = useStyles();

  const reset = () => {
    setLastName("");
    setFirstName("");
    setPicture("");
    setModalStep(0);
  };

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [onClose]);

  // useEffect(() => {
  //   if (!requesting && !error) {
  //     handleClose();
  //   }
  // }, [requesting, error, handleClose]);

  const handleFirstNameChange = e => {
    const value = e.target.value;
    setFirstName(value);
  };

  const handleLastNameChange = e => {
    const value = e.target.value;
    setLastName(value);
  };

  const handlePictureChange = value => {
    setPicture(value);
  };

  const handleHasAvatars = value => () => {
    setHasAvatars(value);
  };

  const onSubmit = () => {
    console.log("CREATE NEW");
    createCoacheeRequest({ firstName, lastName, picture, hasAvatars });
  };

  const handleContinue = () => {
    setModalStep(1);
  };

  const onKeyDown = e => {
    if (e.keyCode === 13) {
      if (!firstName) return;
      if (modalStep === 0) {
        handleContinue();
      } else if (modalStep === 1) {
        onSubmit();
      }
    }
  };

  const handleStepClick = step => () => {
    if (modalStep === 0 && !firstName) return;
    setModalStep(step);
  };

  return (
    <Dialog
      classes={{ paper: `${classes.modal}` }}
      open={isVisible}
      onClose={handleClose}
      onKeyDown={onKeyDown}
    >
      <IconButton
        onClick={handleClose}
        className={classes.exitButton}
        size="large"
      >
        <ExitIcon />
      </IconButton>
      <div className={classes.leftSide}>
        <ExplanationSide
          image={
            modalStep === 0 ? NEW_COACHING_PICTURE : AVATAR_COACHING_PICTURE
          }
          explanation={i18n.t(
            modalStep === 0
              ? "new-coaching-explanation"
              : "no-avatar-explanation",
          )}
        />
      </div>
      <div className={classes.rightSide}>
        {modalStep === 0 ? (
          <InfosSide
            lastName={lastName}
            firstName={firstName}
            handleLastNameChange={handleLastNameChange}
            handleFirstNameChange={handleFirstNameChange}
          />
        ) : (
          <AvatarSide
            hasAvatars={hasAvatars}
            handleHasAvatars={handleHasAvatars}
            picture={picture}
            handlePictureChange={handlePictureChange}
          />
        )}
        <div className={classes.bottomSide}>
          <FlatButton
            disabled={!firstName}
            requesting={requesting}
            fullWidth
            className={classes.button}
            onClick={modalStep === 0 ? handleContinue : onSubmit}
            title={i18n.t(modalStep === 0 ? "Continue" : "validate")}
          />
          <Steps
            numberOfSteps={2}
            stepSelected={modalStep}
            handleStepClick={handleStepClick}
          />
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    requesting: state.myCoachees.requesting,
    error: state.myCoachees.error,
  };
};

export default connect(mapStateToProps, { createCoacheeRequest })(
  NewCoacheeModal,
);
