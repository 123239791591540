import React from "react";
import withStyles from "@mui/styles/withStyles";
import i18n from "i18n";
import { Typography } from "@mui/material";
import GoalRow from "scenes/Campaigns/SeeUserModal/Coaching/RoadmapCoaching/GoalRow";
import selectLanguage from "utils/selectLanguage";

export const TITLE_WIDTH = 300;

const styles = theme => ({
  main: {
    paddingTop: 25,
    paddingLeft: 40,
    paddingRight: 40,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  legend: {
    color: "#101534",
  },
  firsLegend: {
    marginRight: 10,
    paddingLeft: 5,
    width: TITLE_WIDTH,
  },
  tableHeader: {
    marginBottom: 5,
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  tableBody: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
});

const GoalsTable = ({
  classes,
  onEditGoal,
  onRemoveGoal,
  language,
  goals = [
    {
      title: "Nice",
      goalNumber: 4,
      totalDone: 2,
      _id: 1,
      skill: { value: "nice", label: { fr: "Nice" } },
    },
    {
      title: "Résister dans les moments difficiles",
      skill: { value: "french", label: { fr: "French" } },
      goalNumber: 2,
      totalDone: 5,
      _id: 2,
    },
  ],
}) => {
  return (
    <div className={classes.main}>
      <div className={classes.tableHeader}>
        <Typography className={`${classes.legend} ${classes.firsLegend}`}>
          {i18n.t("goals")}
        </Typography>
        <Typography className={classes.legend}>
          {i18n.t("challenges")}
        </Typography>
      </div>
      <div className={classes.tableBody}>
        {goals.map((el, index) => (
          <GoalRow
            key={index}
            {...el}
            goalNumber={el.goalNumber}
            numberOfRecommendedSteps={el.recommendedSteps.length}
            totalDone={el.doneSteps.length}
            title={selectLanguage({ text: el?.skill?.label, language })}
            isLast={goals.length - 1 === index}
            onClickEdit={onEditGoal(el)}
            onClickRemove={onRemoveGoal(el)}
          />
        ))}
      </div>
    </div>
  );
};

export default withStyles(styles)(GoalsTable);
