import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import withStyles from '@mui/styles/withStyles';
import i18n from "i18n";
import { Typography } from "@mui/material";
import UploaderIcon from "components/UploaderIcon";
import Colors from "constants/Colors";
import { Loader } from "components";

// 5mo
const DEFAULT_MAX_SIZE = 1048576 * 5;

const styles = theme => ({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  thumbsContainer: {
    objectFit: "cover",
    borderRadius: 8,
    pointerEvents: "none",
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },

  thumb: {
    display: "inline-flex",
    borderRadius: 8,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 250,
    height: 400,
    padding: 4,
    boxSizing: "border-box",
  },

  thumbInner: {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  },

  img: {
    objectFit: "cover",
    borderRadius: 8,
    display: "block",
    width: "100%",
    height: "100%",
  },
  dropzone: {
    cursor: "pointer",
    width: 250,
    height: 400,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    borderWidth: "2px",
    borderRadius: 8,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: Colors.veryLightGrey,
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    "&:focus": {
      borderColor: "#2196f3",
    },
  },
  placeholderDiv: {
    transition: "all 0.2s ease",
    zIndex: 100,
    backgroundColor: "transparent",
    width: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  dropImage: {
    marginTop: 10,
    textAlign: "center",
    fontWeight: "bold",
    color: "#535461",
  },
  placeholder: {
    textAlign: "center",
    fontSize: 12,
    color: "#535461",
  },
  or: {
    marginTop: 15,
    marginBottom: 15,
    textAlign: "center",
    color: "#535461",
  },
  importImage: {
    color: Colors.pureMain,
    fontWeight: "bold",
    textDecoration: "underline",
  },
  loader: {
    zIndex: 5,
  },
  unsplashButton: {
    fontSize: 14,
    padding: "7px 9px",
    fontWeight: "normal",
    marginTop: 15,
  },
  placeholderHovered: {
    borderRadius: 8,
    backgroundColor: Colors.white,
    height: "100%",
    width: "100%",
  },
  warning: {
    color: Colors.pink,
    marginTop: 10,
    textAlign: "center",
  },
});

function Previews(props) {
  const {
    classes,
    className,
    onFileUploaded,
    uploading,
    width,
    height,
    fileName = "",
    maxSize = DEFAULT_MAX_SIZE,
    hasButton = false,
    children,
  } = props;
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps, rejectedFiles, open } = useDropzone({
    // acceptedFiles: ".pdf",
    maxSize,
    // accept: ".pdf",
    multiple: false,
    onDrop: acceptedFiles => {
      onFileUploaded(acceptedFiles);
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  const isFileTooLarge =
    rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

  if (hasButton)
    return (
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        {React.cloneElement(children, { onClick: () => console.log(open) })}
      </div>
    );

  return (
    <section className={`${classes.container} ${className}`}>
      <div
        {...getRootProps({ className: "dropzone" })}
        className={classes.dropzone}
        style={{ width, height }}
      >
        <input {...getInputProps()} />
        {uploading ? (
          <div className={classes.loader}>
            <Loader size={40} />
          </div>
        ) : (
          !fileName && (
            <div className={`${classes.placeholderDiv}`}>
              <UploaderIcon />
              <>
                <Typography className={classes.dropImage}>
                  {i18n.t("drop-file")}{" "}
                </Typography>
                <Typography className={classes.or}>{i18n.t("or")}</Typography>
                <Typography className={classes.importImage}>
                  {i18n.t("import-file")}
                </Typography>
              </>
            </div>
          )
        )}
        {!uploading && !!fileName && (
          <Typography className={classes.fileName}>{fileName}</Typography>
        )}
      </div>
      {isFileTooLarge && (
        <Typography className={classes.warning} style={{ width }}>
          {i18n.t("file-too-large")}
        </Typography>
      )}
      <aside className={classes.thumbsContainer}></aside>
    </section>
  );
}

Previews.defaultProps = {
  width: 400,
  height: 250,
  placeholder: i18n.t("upload-picture-text"),
};

export default withStyles(styles)(Previews);
