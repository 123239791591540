import React, { useState } from "react";
import withStyles from '@mui/styles/withStyles';
import Title from "scenes/CreateTrack/PublishCampaignModal/Title";
import i18n from "i18n";
import { Typography } from "@mui/material";
import Colors from "constants/Colors";
import DeleteIcon from "scenes/CreateTrack/DeleteIcon";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "components/CheckIcon";

const styles = theme => ({
  main: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  box: {
    height: 55,
    width: "100%",
    marginTop: 10,
    border: `solid 1px ${Colors.mediumGrey}`,
    borderRadius: 6,
    padding: "10px 15px",
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": { backgroundColor: Colors.hoverBlue },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  surveyTitle: {
    color: Colors.darkBlue,
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  placeholder: {
    color: "#9D9D9D",
  },
  modify: {
    padding: "5px 10px",
    borderRadius: 6,
    fontWeight: "bold",
    color: Colors.darkBlue,
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: Colors.lightGrey,
    },
  },
  removeButton: {
    alignItems: "center",
    justifyContent: "center",
    marginRight: 7,
    padding: 8,
  },
});

const AddSurveyButton = ({
  classes,
  languageSelected,
  onClickRemove,
  onClick = () => null,
  surveyTitle = "",
  surveyId = "",
}) => {
  const [isHovered, setHover] = useState(false);

  const handleRemove = e => {
    e.stopPropagation();
    onClickRemove();
  };

  const onMouseEnter = () => {
    setHover(true);
  };

  const onMouseLeave = () => {
    setHover(false);
  };

  return (
    <div className={classes.main}>
      <Title
        text={i18n.t("campaign-add-survey", {
          lng: languageSelected,
        })}
        languageSelected={languageSelected}
      />
      <div
        className={classes.box}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Typography
          className={`${classes.surveyTitle} ${
            !surveyTitle && classes.placeholder
          }`}
        >
          {surveyTitle || i18n.t("click-to-add-survey")}
        </Typography>

        {isHovered && !!surveyTitle && (
          <IconButton className={`${classes.removeButton}`} onClick={handleRemove} size="large">
            <DeleteIcon color={Colors.red} sizePercentage={0.65} />
          </IconButton>
        )}
        {!isHovered && !!surveyTitle && <CheckIcon color={Colors.pureMain} />}
        {isHovered && (
          <Typography className={classes.modify}>
            {surveyTitle ? i18n.t("modify") : i18n.t("add")}
          </Typography>
        )}
      </div>
      {/* <Switch
        onColor={"#00A7FC"}
        // onChange={onClickSwitch}
        checked={surveyId}
      /> */}
    </div>
  );
};

export default withStyles(styles)(AddSurveyButton);
