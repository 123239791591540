import React from "react";

const PaidIcon = () => {

  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7996 28.1445C12.0306 28.1445 9.32387 27.3235 7.02158 25.7851C4.7193 24.2468 2.92488 22.0603 1.86525 19.5021C0.805627 16.9439 0.52838 14.129 1.06857 11.4133C1.60877 8.69754 2.94214 6.20298 4.90007 4.24504C6.85801 2.28711 9.35257 0.953737 12.0683 0.413544C14.784 -0.126649 17.599 0.150597 20.1571 1.21022C22.7153 2.26985 24.9018 4.06427 26.4401 6.36655C27.9785 8.66884 28.7996 11.3756 28.7996 14.1445C28.7953 17.8563 27.319 21.4148 24.6944 24.0394C22.0698 26.6639 18.5113 28.1403 14.7996 28.1445ZM14.7996 2.14454C12.4262 2.14454 10.1061 2.84833 8.13272 4.1669C6.15933 5.48548 4.62126 7.35962 3.71301 9.55234C2.80476 11.7451 2.56712 14.1578 3.03014 16.4856C3.49317 18.8134 4.63605 20.9516 6.31429 22.6298C7.99252 24.308 10.1307 25.4509 12.4585 25.914C14.7863 26.377 17.1991 26.1393 19.3918 25.2311C21.5845 24.3228 23.4586 22.7848 24.7772 20.8114C26.0958 18.838 26.7996 16.5179 26.7996 14.1445C26.7959 10.9631 25.5304 7.91298 23.2808 5.66335C21.0311 3.41371 17.981 2.14824 14.7996 2.14454Z"
        fill="#8BD85C"
      />
      <path
        d="M21.5022 8.93121C21.6165 8.97863 21.7204 9.04813 21.8078 9.13574C22.1858 9.50429 22.1858 10.0997 21.8078 10.4682L13.1231 19.1529C12.7546 19.5214 12.1592 19.5214 11.7906 19.1529L7.78378 15.146C7.69628 15.0585 7.62688 14.9547 7.57953 14.8404C7.53218 14.726 7.50781 14.6035 7.50781 14.4798C7.50781 14.2299 7.60708 13.9903 7.78378 13.8136C7.96047 13.6369 8.20012 13.5376 8.45001 13.5376C8.6999 13.5376 8.93955 13.6369 9.11625 13.8136L12.4616 17.1589L20.4753 9.13574C20.5627 9.04813 20.6666 8.97863 20.7809 8.93121C20.8952 8.88378 21.0178 8.85938 21.1416 8.85938C21.2653 8.85938 21.3879 8.88378 21.5022 8.93121Z"
        fill="#8BD85C"
      />
    </svg>
  );
};

export default PaidIcon;
