//@flow
import { select, call, put, fork, all, take } from "redux-saga/effects";
import { eventChannel } from "redux-saga";

import socket from "../socket.js";
import { AUTH_LOGOUT_SUCCESS } from "../auth/constants";
import { MESSAGE_GOT_NEW, MESSAGE_OPEN_CHAT } from "../message/constants";
import { POLL_RECEIVED } from "../poll/constants";
import {
  GET_FOLDER_LIST_SUCCESS,
  REORDER_ALL_STEPS,
  STEP_OVERRIDE,
  ADD_STEP_WITHOUT_SAVE,
  REMOVE_STEP_WITHOUT_SAVE,
} from "../createTrack/constants";
import {
  DONE_STEP_RECEIVED,
  CAMPAIGN_RECEIVED,
  USER_CAMPAIGN_RECEIVED,
  CAMPAIGN_RESOURCE_VISIT_RECEIVED,
  CAMPAIGN_RESOURCE_FEEDBACK_RECEIVED,
  UPDATE_STEP_FEEDBACK_FILE,
  UPDATE_STEP_FEEDBACK,
} from "../campaign/constants";
import {
  getCampaignTeamRankingRequest,
  getCampaignUserRankingRequest,
  getCampaignCommentsRequest,
} from "../campaign/actions";
import {
  NOTIFICATION_GENERAL_ADD,
  NOTIFICATION_GENERAL_SET,
  NOTIFICATION_MESSAGE_INCREMENT,
  NOTIFICATION_NEW_HOME,
  NOTIFICATION_NEW_MESSAGES,
  NOTIFICATION_NEW_RANDOM_COFFEE,
} from "../notification/constants";
import { modifyUser } from "../user/actions";
import { CLIENT_CHANGE_FIELD } from "../client/constants.js";
import { getUserId } from "../user/selectors.js";
import { getReceiverId } from "../message/selectors";
import { getLatestConversationsRequest } from "../message/actions";
import {
  changeGeneralSettings,
  openSnackMessage,
} from "services/general/actions";
import {
  getFolderList,
  getIsLibrary,
  getIsLocatedOnTrack,
  getStepsIds,
  getTrackId,
} from "services/createTrack/selectors.js";
import { GET_COACHING_SESSION_SUCCESS } from "services/coaching/constants";
import { getCoachingSessionId } from "services/coaching/selectors.js";
import { MANAGER_MODIFY_USER_SELECTED } from "services/manager/constants.js";
import { UPDATE_COACHEE } from "services/myCoachees/constants.js";
import { getUserSelectedId } from "services/manager/selectors.js";
import {
  getCampaignSelected,
  getSelectedCampaignId,
} from "services/campaign/selectors";
import { MY_MOBILE_CONNECTION } from "services/general/constants.js";
import { EXPORT_VIDEO_DONE } from "services/upload/constants.js";
import i18n from "i18n.js";

// wrapping function for socket.on
const connect = () => {
  return new Promise(resolve => {
    socket.on("connect", () => {
      resolve(socket);
    });
  });
};

export const openChat = users => {
  socket.emit("chat", users);
  return socket.connected;
};

export const sendMessage = payload => {
  socket.emit("message", payload);
};

const createSocketChannel = channel => {
  return socket =>
    eventChannel(emit => {
      const handler = data => {
        emit(data || "null");
      };
      socket.on(channel, handler);
      return () => {
        socket.off(channel, handler);
      };
    });
};

const createUserSocketChannel = createSocketChannel("userEvent");
const createClientSocketChannel = createSocketChannel("client");
const createPollSocketChannel = createSocketChannel("poll");
const createAdminMobileConnectionSocketChannel = createSocketChannel(
  "admin-mobile-connection",
);
const createDoneStepSocketChannel = createSocketChannel("done-step");
const createCampaignSocketChannel = createSocketChannel("campaign-update");
const createUserCampaignSocketChannel = createSocketChannel(
  "userCampaign-update",
);

const createCampaignResourceFeedbackSocketChannel = createSocketChannel(
  "campaign-resource-feedback",
);

const createCampaignResourceVisitSocketChannel = createSocketChannel(
  "campaign-resource-visit",
);

const createChatSocketChannel = createSocketChannel("priorMessages");
const createMessageSocketChannel = createSocketChannel("incomingMessage");
const createAdminConnectionSocketChannel = createSocketChannel(
  "admin-connection",
);

const createStepSocketChannel = createSocketChannel("step-update");

const createTrackSocketChannel = createSocketChannel("track-update");

const createFolderListSocketChannel = createSocketChannel("folder-list-update");

const createCoachingSocketChannel = createSocketChannel(
  "coaching-session-update",
);

const createStepFeedbackChannel = createSocketChannel("stepFeedback");

// const createStepSocketChannel = createSocketChannel("step-update");

const createCoacheeUpdateSocketChannel = createSocketChannel("coachee-update");

const createStepFeedbackFileUpdateChannel = createSocketChannel(
  "stepFeedback-file-update",
);

const createNotificationSocketChannel = createSocketChannel(
  "notificationEvent",
);

const createGeneralNotificationSocketChannel = createSocketChannel(
  "newNotification",
);

const createVideoExportSocketChannel = createSocketChannel("videoExport");

const listenStepFeedbackFileUpdateEvent = function* (newSocket) {
  const socketChannel = yield call(
    createStepFeedbackFileUpdateChannel,
    newSocket,
  );

  while (true) {
    const payload = yield take(socketChannel);
    yield put({ type: UPDATE_STEP_FEEDBACK_FILE, payload });
  }
  // if user is forbidden log him out
};

const listenStepFeedbackEvent = function* (newSocket) {
  const socketChannel = yield call(createStepFeedbackChannel, newSocket);

  while (true) {
    const payload = yield take(socketChannel);
    yield put({ type: UPDATE_STEP_FEEDBACK, payload });
  }
  // if user is forbidden log him out
};

const listenNotificationEvent = function* (newSocket) {
  const socketChannel = yield call(createNotificationSocketChannel, newSocket);

  // then put the new data into the reducer
  while (true) {
    const payload = yield take(socketChannel);
    const {
      newMessageNotifications,
      newHomeNotifications,
      newRandomCoffeeNotifications,
      newGeneralNotifications,
    } = payload;
    if (newMessageNotifications) {
      yield put({
        type: NOTIFICATION_NEW_MESSAGES,
        payload: newMessageNotifications,
      });
      const userId = yield select(getUserId);
      yield put(getLatestConversationsRequest({ userId }));
    }
    if (newHomeNotifications) {
      yield put({ type: NOTIFICATION_NEW_HOME, payload: newHomeNotifications });
    }
    if (newRandomCoffeeNotifications) {
      yield put({
        type: NOTIFICATION_NEW_RANDOM_COFFEE,
        payload: newRandomCoffeeNotifications,
      });
    }
    if (newGeneralNotifications) {
      yield put({
        type: NOTIFICATION_GENERAL_SET,
        payload: newGeneralNotifications,
      });
    }
  }
};

const listenGeneralNotificationSocket = function* (newSocket) {
  const socketChannel = yield call(
    createGeneralNotificationSocketChannel,
    newSocket,
  );

  while (true) {
    const payload = yield take(socketChannel);
    yield put({ type: NOTIFICATION_GENERAL_ADD, payload });
  }
  // if user is forbidden log him out
};

const listenCampaignResourceFeedbackEvent = function* (newSocket) {
  // then create a socket channel
  const socketChannel = yield call(
    createCampaignResourceFeedbackSocketChannel,
    newSocket,
  );

  // then put the new data into the reducer
  while (true) {
    const payload = yield take(socketChannel);
    const campaignSelectedId = yield select(getSelectedCampaignId);
    if (campaignSelectedId && campaignSelectedId === payload.campaignId) {
      yield put({ type: CAMPAIGN_RESOURCE_FEEDBACK_RECEIVED, payload });
      // yield put({
      //   type: UPDATE_CAMPAIGN,
      //   payload: { resourcesStats: payload.resourcesStats },
      // });
    }
  }
  // if user is forbidden log him out
};

const listenTrackEvent = function* (newSocket) {
  // then create a socket channel
  const socketChannel = yield call(createTrackSocketChannel, newSocket);

  // then put the new data into the reducer
  while (true) {
    const payload = yield take(socketChannel);
    const trackId = yield select(getTrackId);
    if (trackId && trackId === payload._id) {
      yield put({ type: REORDER_ALL_STEPS, payload });
    }
  }
};

const listenCampaignResourceVisitEvent = function* (newSocket) {
  // then create a socket channel
  const socketChannel = yield call(
    createCampaignResourceVisitSocketChannel,
    newSocket,
  );

  // then put the new data into the reducer
  while (true) {
    const payload = yield take(socketChannel);
    const campaignSelectedId = yield select(getSelectedCampaignId);
    if (campaignSelectedId && campaignSelectedId === payload.campaignId) {
      yield put({ type: CAMPAIGN_RESOURCE_VISIT_RECEIVED, payload });
      // yield put({
      //   type: UPDATE_CAMPAIGN,
      //   payload: { resourcesStats: payload.resourcesStats },
      // });
    }
  }
  // if user is forbidden log him out
};

const listenCoacheeUpdateEvent = function* (newSocket) {
  // then create a socket channel
  const socketChannel = yield call(createCoacheeUpdateSocketChannel, newSocket);

  // then put the new data into the reducer
  while (true) {
    const payload = yield take(socketChannel);
    const userSelectedId = yield select(getUserSelectedId);
    if (!userSelectedId || userSelectedId === payload._id) {
      yield put({ type: UPDATE_COACHEE, payload });
      yield put({ type: MANAGER_MODIFY_USER_SELECTED, payload });
    }
  }
  // if user is forbidden log him out
};

const listenCoachingSessionUpdate = function* (newSocket) {
  const socketChannel = yield call(createCoachingSocketChannel, newSocket);

  while (true) {
    const payload = yield take(socketChannel);
    const coachingSessionId = yield select(getCoachingSessionId);
    if (coachingSessionId === payload._id) {
      yield put({ type: GET_COACHING_SESSION_SUCCESS, payload });
    }
  }
};

const listenFolderListEvent = function* (newSocket) {
  const socketChannel = yield call(createFolderListSocketChannel, newSocket);

  while (true) {
    const payload = yield take(socketChannel);
    const folderList = yield select(getFolderList);
    if (folderList._id === payload._id) {
      yield put({ type: GET_FOLDER_LIST_SUCCESS, payload });
    }
  }
};

const listenStepEvent = function* (newSocket) {
  // then create a socket channel
  const socketChannel = yield call(createStepSocketChannel, newSocket);

  // then put the new data into the reducer
  while (true) {
    const payload = yield take(socketChannel);
    const stepId = payload._id;
    const isRemoved = payload.isRemoved;
    const { updateType } = payload;

    const isLibrary = yield select(getIsLibrary);
    const isLocatedOnTrack = yield select(getIsLocatedOnTrack);
    const trackId = yield select(getTrackId);
    const stepsIds = yield select(getStepsIds);
    const hasStep = !!stepId && stepsIds.find(el => el === stepId);

    if (isLibrary || isLocatedOnTrack) {
      if (hasStep) {
        if (isRemoved) {
          yield put({ type: REMOVE_STEP_WITHOUT_SAVE, payload: stepId });
        } else if (updateType === "remove") {
          yield put({ type: REMOVE_STEP_WITHOUT_SAVE, payload: stepId });
        } else {
          yield put({ type: STEP_OVERRIDE, payload });
        }
      } else if (
        isLibrary ||
        (isLocatedOnTrack && trackId === payload.trackId)
      ) {
        if (updateType === "add") {
          yield put({ type: ADD_STEP_WITHOUT_SAVE, payload });
        } else if (updateType === "remove") {
          yield put({ type: REMOVE_STEP_WITHOUT_SAVE, payload: stepId });
        }
      }
    }
  }
  // if user is forbidden log him out
};

const listenAdminConnectionEvent = function* (newSocket) {
  // then create a socket channel
  const socketChannel = yield call(
    createAdminConnectionSocketChannel,
    newSocket,
  );

  // then put the new data into the reducer
  while (true) {
    const payload = yield take(socketChannel);

    yield put(changeGeneralSettings({ adminConnections: payload }));
  }
};

const listenChatEvent = function* (newSocket) {
  // then create a socket channel
  const socketChannel = yield call(createChatSocketChannel, newSocket);

  // then put the new data into the reducer
  while (true) {
    const payload = yield take(socketChannel);

    yield put({ type: MESSAGE_OPEN_CHAT, payload });
  }
  // if user is forbidden log him out
};

const listenMessageEvent = function* (newSocket) {
  // then create a socket channel
  const socketChannel = yield call(createMessageSocketChannel, newSocket);

  while (true) {
    const payload = yield take(socketChannel);

    const userId = yield select(getUserId);
    const receiverId = yield select(getReceiverId);
    // means chat is currently open
    if (payload?.user?._id === userId || payload?.user?._id === receiverId) {
      yield put({ type: MESSAGE_GOT_NEW, payload });
    }
    // we are not the sender
    if (payload.user._id !== userId) {
      yield put({ type: NOTIFICATION_MESSAGE_INCREMENT });
    }
    yield put(getLatestConversationsRequest({ userId }));
  }
};

const listenUserEvent = function* (newSocket) {
  // then create a socket channel
  const socketChannel = yield call(createUserSocketChannel, newSocket);

  // then put the new data into the reducer
  while (true) {
    const payload = yield take(socketChannel);
    // if user is forbidden or have no access log him out
    if (payload.isForbidden || payload.role === "user") {
      yield put({ type: AUTH_LOGOUT_SUCCESS });
      // yield put({ type: LOGIN_ERROR, error: { message: "isForbidden" } });
    } else {
      // if (payload.pulseNotifications) {
      //   yield put({
      //     type: NOTIFICATION_PULSE_SET,
      //     payload: payload.pulseNotifications
      //   });
      // }
      // if (payload.teamNotifications) {
      //   yield put({
      //     type: NOTIFICATION_TEAM_ADD,
      //     payload: payload.teamNotifications
      //   });
      // }
      yield put(modifyUser(payload));
    }
  }
};

const listenPollEvent = function* (newSocket) {
  const socketChannel = yield call(createPollSocketChannel, newSocket);

  while (true) {
    const payload = yield take(socketChannel);
    yield put({ type: POLL_RECEIVED, payload });
    // if user is forbidden log him out
  }
};

const listenAdminMobileEvent = function* (newSocket) {
  const socketChannel = yield call(
    createAdminMobileConnectionSocketChannel,
    newSocket,
  );

  while (true) {
    const payload = yield take(socketChannel);
    const userId = yield select(state => state.user._id);

    if (userId === payload.userId) {
      yield put({ type: MY_MOBILE_CONNECTION, payload });
    }
    // if user is forbidden log him out
  }
};

const listenDoneStepEvent = function* (newSocket) {
  const socketChannel = yield call(createDoneStepSocketChannel, newSocket);

  while (true) {
    const payload = yield take(socketChannel);
    const campaignSelected = yield select(getCampaignSelected);

    if (campaignSelected?._id === payload.campaignId) {
      yield put({ type: DONE_STEP_RECEIVED, payload });
      // refresh ranking
      const { campaignId } = payload;
      yield all([
        put(getCampaignCommentsRequest({ campaignId, limit: 0, page: 0 })),
        put(
          getCampaignUserRankingRequest({
            campaignId,
            limit: 10,
            page: 0,
          }),
        ),
        put(
          getCampaignTeamRankingRequest({
            campaignId,
            limit: 10,
            page: 0,
          }),
        ),
      ]);
    }
    // if user is forbidden log him out
  }
};

const listenUserCampaignEvent = function* (newSocket) {
  const socketChannel = yield call(createUserCampaignSocketChannel, newSocket);

  while (true) {
    const payload = yield take(socketChannel);

    const campaignSelected = yield select(getCampaignSelected);
    if (campaignSelected?._id === payload?.campaignId) {
      yield put({ type: USER_CAMPAIGN_RECEIVED, payload });
    }
  }
};

const listenCampaignEvent = function* (newSocket) {
  const socketChannel = yield call(createCampaignSocketChannel, newSocket);

  while (true) {
    const payload = yield take(socketChannel);
    yield put({ type: CAMPAIGN_RECEIVED, payload });
  }
};

const listenClientEvent = function* (newSocket) {
  const socketChannel = yield call(createClientSocketChannel, newSocket);

  while (true) {
    const payload = yield take(socketChannel);
    yield put({ type: CLIENT_CHANGE_FIELD, payload });
  }
};

const listenExportVideoEvent = function* (newSocket) {
  const socketChannel = yield call(createVideoExportSocketChannel, newSocket);

  while (true) {
    const payload = yield take(socketChannel);
    console.log("payload", payload);
    yield put({ type: EXPORT_VIDEO_DONE, payload });
    const downloadButton: any = document?.querySelector("#downloadButton");

    if (downloadButton) {
      downloadButton.href = payload;
      downloadButton.click();
    }

    // downloadImage
    yield put(openSnackMessage({ snackMessage: i18n.t("download-success") }));
  }
};

const channelFlow = function* () {
  // connect to the server
  const newSocket = yield call(connect);
  yield fork(listenChatEvent, newSocket);
  yield fork(listenMessageEvent, newSocket);
  yield fork(listenUserEvent, newSocket);
  yield fork(listenPollEvent, newSocket);
  yield fork(listenAdminMobileEvent, newSocket);
  yield fork(listenAdminConnectionEvent, newSocket);
  yield fork(listenDoneStepEvent, newSocket);
  yield fork(listenCampaignEvent, newSocket);
  yield fork(listenUserCampaignEvent, newSocket);
  yield fork(listenClientEvent, newSocket);
  yield fork(listenStepEvent, newSocket);
  yield fork(listenFolderListEvent, newSocket);
  yield fork(listenCoachingSessionUpdate, newSocket);
  yield fork(listenCoacheeUpdateEvent, newSocket);
  yield fork(listenCampaignResourceFeedbackEvent, newSocket);
  yield fork(listenCampaignResourceVisitEvent, newSocket);
  yield fork(listenTrackEvent, newSocket);
  yield fork(listenStepFeedbackEvent, newSocket);
  yield fork(listenStepFeedbackFileUpdateEvent, newSocket);
  yield fork(listenGeneralNotificationSocket, newSocket);
  yield fork(listenNotificationEvent, newSocket);
  yield fork(listenExportVideoEvent, newSocket);
};

function* trackWatcher() {
  yield all([channelFlow()]);
}

export default trackWatcher;
