import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Menu from "@mui/material/Menu";
import CheckIcon from "components/CheckIcon";
import ClickOutside from "components/ClickOutside";
import Colors from "constants/Colors";
import i18n from "i18n";
import { MainTextWithIcon } from "./Texts/MainTextWithIcon";
import BackIcon from "scenes/Campaigns/CampaignScreen/ReviewsSide/BackIcon";

const useStyle = makeStyles({
  main: {
    padding: "6px 12px",
    borderRadius: 6,
    transition: "all 0.2s",
    width: "max-content",
    marginTop: 7,
    marginBottom: 7,
    "&:hover": {
      background: "rgba(255, 200, 87, 0.3)",
    },
  },
  checkIcon: {
    display: "flex",
    justifyContent: "flex-end",
  },
  colorDiv: {
    paddingLeft: 14,
    paddingRight: 14,
    minHeight: 28,
    fontSize: 14,
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    padding: "5px 10px",
    cursor: "pointer",
    transition: "all 0.2s",
    "&:hover": {
      background: "rgba(255, 200, 87, 0.3)",
    },
  },
  sortDiv: {
    display: "flex",
    cursor: "pointer",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  libraryFilter: {
    outline: "none",
    display: "flex",
    flexDirection: "column",
    minWidth: 225,
    maxWidth: "calc(100vw - 24px)",
    height: "100%",
    maxHeight: "70vh",
    borderRadius: 4,
    border: "solid 1px",
    borderColor: Colors.lightGrey,
  },
  reverseIcon: {
    transform: "rotate(-90deg)",
    justifyContent: "flex-end",
    marginLeft: 8,
  },
  colorBlock: {
    width: 18,
    height: 18,
    background: "rgba(206, 205, 202, 0.5)",
    boxShadow: "rgba(15, 15, 15, 0.1) 0px 0px 0px 1px inset",
    borderRadius: 3,
    marginRight: 8,
  },
});

export interface IFilter {
  label: string;
  color: string;
}

interface FilterProps {
  selectFilter: (label: IFilter) => void;
  filterSelected: IFilter;
  filters: IFilter[];
  isSmall?: boolean;
}

const FilterList = ({
  selectFilter,
  filterSelected,
  filters = [],
  isSmall = false,
}: FilterProps) => {
  const [isFilterVisible, setIsFilterVisible] = useState<Boolean>(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isHovered, setHover] = useState(false);

  const onMouseLeave = () => {
    setHover(false);
  };

  const onMouseEnter = e => {
    setAnchorEl(e.currentTarget);
    setHover(true);
  };

  const onClickFilter = (filter: IFilter) => {
    selectFilter(filter);
    setHover(false);
  };

  const classes = useStyle();

  return (
    <div className={classes.main}>
      <div className={classes.sortDiv} onClick={onMouseEnter}>
        {filterSelected.color && (
          <div
            className={classes.colorBlock}
            style={{
              background: filterSelected.color,
              borderRadius: isSmall ? "50%" : 3,
              height: isSmall ? 10 : 18,
              width: isSmall ? 10 : 18,
              minWidth: isSmall ? 10 : 18,
              minHeight: isSmall ? 10 : 18,
            }}
          />
        )}
        <MainTextWithIcon
          size={isSmall ? "small" : "medium"}
          text={i18n.t(filterSelected.label)}
          onClick={() => setIsFilterVisible(!isFilterVisible)}
          icon={
            <BackIcon
              className={classes.reverseIcon}
              color={"currentColor"}
              sizePercentage={isSmall ? 0.6 : 0.7}
            />
          }
          isLeft={false}
        />
      </div>
      <Menu
        MenuListProps={{ disablePadding: true }}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={anchorEl}
        open={isHovered}
      >
        <ClickOutside onClickOutside={onMouseLeave}>
          <div className={classes.libraryFilter} onMouseLeave={onMouseLeave}>
            {filters.map((el, index) => {
              const isSelected = filterSelected.label === el.label;
              return (
                <div className={classes.colorDiv} key={index}>
                  {el.color && (
                    <div
                      className={classes.colorBlock}
                      style={{
                        background: el.color,
                        borderRadius: isSmall ? "50%" : 3,
                        height: isSmall ? 10 : 18,
                        width: isSmall ? 10 : 18,
                        minWidth: isSmall ? 10 : 18,
                        minHeight: isSmall ? 10 : 18,
                      }}
                    />
                  )}
                  <MainTextWithIcon
                    text={i18n.t(el.label)}
                    size={isSmall ? "small" : "medium"}
                    icon={
                      isSelected && <CheckIcon className={classes.checkIcon} />
                    }
                    onClick={() => onClickFilter(el)}
                  />
                </div>
              );
            })}
          </div>
        </ClickOutside>
      </Menu>
    </div>
  );
};

export default FilterList;
