import React, { useState } from "react";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import moment from "moment";

import DownloadIcon from "./DownloadIcon";

import Typography from "@mui/material/Typography";

import Feedback from "../../Recap/Feedback";
import i18n from "../../../i18n";
import Colors from "constants/Colors";
import FilterList, { IFilter } from "components/FilterList";
import { makeStyles } from "@mui/styles";
import { getCampaignComments } from "services/campaign/selectors";
import FilesFeedback from "scenes/Recap/FilesFeedback";

const FILTER_1 = { label: "FILTER_3_COMMENTS", color: null };
const FILTER_2 = { label: "FILTER_9_COMMENTS", color: null };
const FILTER_3 = { label: "FILTER_ALL_COMMENTS", color: null };
const FILTERS: IFilter[] = [FILTER_1, FILTER_2, FILTER_3];

const useStyle = makeStyles({
  main: {
    boxSizing: "border-box",
    overflow: "hidden",
    marginTop: 30,
    paddingBottom: 40,
    borderRadius: 10,
    backgroundColor: "#fff",
    width: "100%",
    transition: "all 0.2s cubic-bezier(0.41, 0.094, 0.54, 0.07) 0s",
  },
  topBar: {
    boxSizing: "border-box",
    paddingRight: 45,
    height: 90,
    marginBottom: 15,
    borderBottom: `solid 1px ${Colors.lightGrey}`,
    borderRadius: 10,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    background: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  title: {
    marginLeft: 45,
    color: Colors.blackGrey,
    fontSize: 20,
    fontFamily: "Montserrat",
    fontWeight: "bold",
  },
  comments: {
    boxSizing: "border-box",
    columnCount: 3,
    columnGap: 30,
    paddingLeft: 45,
    paddingRight: 45,
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "row",
    "@media (max-width: 1024px)": {
      columnCount: 2,
    },
    "@media (max-width: 768px)": {
      columnCount: 1,
    },
  },
  totalComments: {
    fontWeight: 300,
    fontFamily: "Roboto",
    color: "#BABCC5",
  },
  noColumn: {
    columnCount: 0,
    columnGap: 0,
    flexWrap: "wrap",
    display: "flex",
  },
  feedbackNoColumn: {
    width: "30%",
  },
  emptyView: {
    flex: 1,
  },
});

const formatCSVData = comments => {
  const results = comments.map(comment => {
    return {
      createdAt: moment(comment.createdAt).format("LL") || " ",
      comment: comment.comment.replace(/"/g, "'") || " ",
      rating: comment.rating || 5,
    };
  });
  return results;
};

const CSVHeaders = [
  { label: i18n.t("date-header"), key: "createdAt" },
  { label: i18n.t("rating-header"), key: "rating" },
  { label: i18n.t("comment-header"), key: "comment" },
];

const FeedbackSection = ({ className }) => {
  const [filterSelected, selectCommentsFilter] = useState(FILTER_1);

  const comments = useSelector(getCampaignComments);

  const selectFilter = filter => {
    selectCommentsFilter(filter);
  };

  const sortComments = comments => {
    return comments.sort((a, b) => b.comment.length - a.comment.length);
  };

  const bestComments = sortComments(comments);

  const getFilteredComments = (bestComments, filterSelected) => {
    if (filterSelected === FILTER_1) return bestComments.slice(0, 3);
    if (filterSelected === FILTER_2) return bestComments.slice(0, 9);
    return bestComments;
  };

  const isThreeBlock = filterSelected === FILTER_1;

  const classes = useStyle();

  return (
    <div className={`${classes.main} ${className}`}>
      <div className={classes.topBar}>
        <Typography className={classes.title}>
          {i18n.t("participant-feedback")}{" "}
          <span className={classes.totalComments}>({comments.length})</span>
        </Typography>

        <div className={classes.emptyView} />
        <CSVLink
          style={{ textDecoration: "none", marginRight: 8 }}
          filename={`${i18n.t("comments")}.csv`}
          headers={CSVHeaders}
          target="_blank"
          data={formatCSVData(bestComments)}
        >
          <DownloadIcon />
        </CSVLink>
        {bestComments.length > 0 && (
          <FilterList
            selectFilter={selectFilter}
            filters={FILTERS}
            filterSelected={filterSelected}
          />
        )}
      </div>
      <div
        className={`${classes.comments} ${isThreeBlock && classes.noColumn}`}
      >
        {getFilteredComments(bestComments, filterSelected).map(
          (feedback, index) => {
            return (
              <>
                {feedback.comment?.length > 0 && (
                  <Feedback
                    comment={feedback.comment}
                    createdAt={feedback.createdAt}
                    key={index}
                    className={isThreeBlock && classes.feedbackNoColumn}
                  />
                )}
                {feedback.files?.length > 0 && (
                  <FilesFeedback
                    key={index}
                    file={feedback.files[0]}
                    className={isThreeBlock && classes.feedbackNoColumn}
                  />
                )}
              </>
            );
          },
        )}
      </div>
    </div>
  );
};

export default FeedbackSection;
