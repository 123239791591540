import React, { useMemo, useState } from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography } from "@mui/material";
import Colors from "constants/Colors";
import i18n from "i18n";
import DeleteComponent from "scenes/Campaigns/DeleteComponent";
import TestAccountLabel from "scenes/MyCoachees/TestAccountLabel";

const BOX_HEIGHT = 280;
const BOX_WIDTH = 210;
const BOX_RADIUS = 4;

const styles = theme => ({
  main: {
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": { marginTop: -5 },
    marginBottom: 25,
    marginRight: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  box: {
    position: "relative",
    transition: "all 0.2s",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.9,
    },
    backgroundColor: "gainsboro",
    width: BOX_WIDTH,
    height: BOX_HEIGHT,
    borderRadius: BOX_RADIUS,
    paddingLeft: 20,
    paddingBottom: 20,
  },
  picture: {
    objectFit: "cover",
    position: "absolute",
    top: 0,
    left: 0,
    borderRadius: BOX_RADIUS,
    width: BOX_WIDTH,
    height: BOX_HEIGHT,
  },
  lastActivity: {
    marginBottom: 5,
    padding: "3px 7px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    backgroundColor: "#010329",
    borderRadius: 4,
  },
  activityText: {
    marginRight: 1,
    fontSize: 12,
    color: "#F5F5F5",
  },
  round: {
    marginLeft: 2,
    marginRight: 10,
    width: 9,
    height: 9,
    borderRadius: "50%",
    boxShadow: "0px 0px 6px 0px #8CFE63",
    backgroundColor: "#8DFD4B",
  },
  textDiv: {
    left: 20,
    bottom: 5,
    position: "absolute",
    zIndex: 1,
  },
  shadow: {
    borderRadius: BOX_RADIUS,
    zIndex: 1,
    position: "absolute",
    bottom: 0,
    left: 0,
    height: BOX_HEIGHT / 1.2,
    width: BOX_WIDTH,
    background:
      "linear-gradient(9.59deg, rgba(0, 0, 0, 0.59) 18.51%, rgba(0, 0, 0, 0) 61.71%);",
  },
  name: {
    fontSize: 15,
    fontFamily: "Montserrat",
    fontWeight: "600",
    color: "#fff",
  },
  numberOfSessions: {
    fontFamily: "Roboto",
    color: "#fff",
    marginBottom: 5,
  },
  deleteIcon: {
    zIndex: 10,
    position: "absolute",
    top: 0,
    right: 0,
  },
  pictureBackground: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: Colors.darkBlue,
  },
  letter: {
    fontSize: 32,
    color: "#fff",
    fontFamily: "Montserrat",
    fontWeight: "bold",
  },
});

const getDoneSessions = sessions => {
  return sessions.filter(el => el.isDone).length;
};

const CoachingSessionBox = ({
  classes,
  coachingName = "Marie Dumas",
  sessions = [],
  picture = "",
  onClick,
  onClickDelete,
  isTestAccount = false,
}) => {
  const [isHovered, setHover] = useState(false);
  const doneSessions = useMemo(() => getDoneSessions(sessions), [sessions]);

  const onEnter = () => setHover(true);
  const onLeave = () => setHover(false);

  return (
    <div className={classes.main}>
      <div
        data-intercom-target="coachee"
        className={classes.box}
        onClick={onClick}
        onMouseEnter={onEnter}
        onMouseLeave={onLeave}
      >
        {isTestAccount && <TestAccountLabel />}
        {isHovered && (
          <DeleteComponent
            hoverTip={i18n.t("delete-coachee")}
            onClick={onClickDelete}
            className={classes.deleteIcon}
          />
        )}
        <div className={classes.shadow} />
        {!!picture ? (
          <img className={classes.picture} alt={coachingName} src={picture} />
        ) : (
          <div className={`${classes.picture} ${classes.pictureBackground}`}>
            <Typography className={classes.letter}>
              {coachingName?.[0].toUpperCase()}
            </Typography>
          </div>
        )}
        <div className={classes.textDiv}>
          <Typography className={classes.name}>{`${coachingName}`}</Typography>
          <Typography className={classes.numberOfSessions}>
            {`${doneSessions}/${sessions.length} ${i18n.t("done-sessions")}`}
          </Typography>
          {/* <div className={classes.lastActivity}>
            <div className={classes.round} />
            <Typography className={classes.activityText}>
              Actif il y a 6 jours
            </Typography>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(CoachingSessionBox);
