export const GET_INVOICE_REQUESTING = "invoice/GET_INVOICE_REQUESTING";
export const GET_INVOICE_SUCCESS = "invoice/GET_INVOICE_SUCCESS";
export const GET_INVOICE_ERROR = "invoice/GET_INVOICE_ERROR";

export const GET_ALL_INVOICES_REQUESTING =
  "invoice/GET_ALL_INVOICES_REQUESTING";
export const GET_ALL_INVOICES_SUCCESS = "invoice/GET_ALL_INVOICES_SUCCESS";
export const GET_ALL_INVOICES_ERROR = "invoice/GET_ALL_INVOICES_ERROR";

export const UPDATE_INVOICE_REQUESTING = "invoice/UPDATE_INVOICE_REQUESTING";
export const UPDATE_INVOICE_SUCCESS = "invoice/UPDATE_INVOICE_SUCCESS";
export const UPDATE_INVOICE_ERROR = "invoice/UPDATE_INVOICE_ERROR";

export const CREATE_INVOICE_REQUESTING = "invoice/CREATE_INVOICE_REQUESTING";
export const CREATE_INVOICE_SUCCESS = "invoice/CREATE_INVOICE_SUCCESS";
export const CREATE_INVOICE_ERROR = "invoice/CREATE_INVOICE_ERROR";

export const DELETE_INVOICE_REQUESTING = "invoice/DELETE_INVOICE_REQUESTING";
export const DELETE_INVOICE_SUCCESS = "invoice/DELETE_INVOICE_SUCCESS";
export const DELETE_INVOICE_ERROR = "invoice/DELETE_INVOICE_ERROR";

export const GET_INVOICE_CLIENTS_REQUESTING =
  "invoice/GET_INVOICE_CLIENTS_REQUESTING";
export const GET_INVOICE_CLIENTS_SUCCESS =
  "invoice/GET_INVOICE_CLIENTS_SUCCESS";
export const GET_INVOICE_CLIENTS_ERROR = "invoice/GET_INVOICE_CLIENTS_ERROR";

export const CLEAR_INVOICE = "invoice/CLEAR_INVOICE";

export const UPDATE_INVOICE = "invoice/UPDATE_INVOICE";

export const REMOVE_RESOURCE_REQUESTING = "invoice/REMOVE_INVOICE";

export const START_NEW_INVOICE = "invoice/START_NEW_INVOICE";

export const SET_NEW_INVOICE = "invoice/SET_NEW_INVOICE";
