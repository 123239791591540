import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import { connect } from "react-redux";
import { createTrackRequest } from "../../../services/createTrack/actions";
import {
  getAllPublicTemplatesRequest,
  getAllTemplatesRequest,
  createCampaignRequest,
} from "../../../services/campaign/actions";
import {
  uploadPictureRequest,
  resetUpload,
} from "../../../services/upload/actions";
import moment from "moment";
import SelectGoal from "./SelectGoal";
import TrackSettings from "./TrackSettings";
import i18n from "../../../i18n";
import {
  getSelectedClientId,
  getUserId,
} from "../../../services/user/selectors";
import { getAppLanguage } from "../../../services/general/selectors";
import DialogTopBar from "components/DialogTopBar";
import slugify from "slugify";

moment.locale("fr");

const styles = theme => ({
  paper: {
    alignItems: "center",
    display: "flex",
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: "center",
    lineHeight: 1.46666667,
    overflow: "scroll",
    overflowY: "auto",
    padding: "0px 0px 30px 0px",
    borderRadius: "0px",
    minHeight: "100%",
    minWidth: "100%",
    maxWidth: "100%",
  },
  content: {},
  bodyDiv: {
    width: 960,
    maxWidth: "100%",
    padding: 0,
  },
});

class CreationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: [],
      open: false,
      step: 0,
      goalSelected: null,
      templateSelected: {
        title: {},
        description: {},
        templateSettings: {
          templateDescription: {},
          isMicrosoftTeamsReminderActivated: false,
          isMobileModeActivated: true,
          isEmailModeActivated: false,
          isAnchorEmailMode: false,
        },
      },
      isPreviewTemplateVisible: false,
      title: {},
      description: {},
      picture: "",
      error: "",
    };
    this.baseState = this.state;
  }

  onRendered = () => {
    this.setState(this.baseState);
    // this.props.getAllPublicTemplatesRequest({
    //   clientId: this.props.clientId,
    //   isPrivate: false,
    // });
    this.props.resetUpload();
  };

  handleClose = onClose => () => {
    onClose();
  };

  onClickTemplate = templateSelected => () => {
    this.setState({
      templateSelected,
      isPreviewTemplateVisible: true,
    });
  };

  onClickUseTemplate = async () => {
    const { userId, clientId } = this.props;
    const { templateSelected } = this.state;
    if (templateSelected.templateSettings.editable) {
      await this.setState({
        templateSelected,
        ...templateSelected,
        step: 1,
        isPreviewTemplateVisible: false,
      });
      this.onClickSubmit();
    } else {
      this.props.createCampaignRequest({
        ...templateSelected,
        trackId: templateSelected._id,
        userId,
        clientId,
      });
      this.props.onClose();
    }
  };

  onCloseTemplatePreview = () => {
    this.setState({ isPreviewTemplateVisible: false });
  };

  onClickMyCampaignTemplate = templateSelected => {
    console.log(templateSelected);
    this.setState({
      templateSelected,
      ...templateSelected,
      step: 1,
      isPreviewTemplateVisible: false,
    });
  };

  onClickNext = () => {
    this.setState({ step: 1 });
  };

  onClickNewCampaign = () => {
    this.setState({ ...this.baseState, step: 1 });
  };

  onChangePicture = picture => {
    this.setState({ picture });
  };

  onClickPrevious = () => {
    this.setState({ step: 0 });
  };

  onChangeState = key => event => {
    const value = event.target.value;
    const newState = { ...this.state[key] };
    newState[this.props.language] = value;

    this.setState({ [key]: newState });
  };

  onClickAdministrationMode = mode => () => {
    const {
      isMicrosoftTeamsReminderActivated = false,
      isMobileModeActivated = true,
      isEmailModeActivated = false,
      isAnchorEmailMode = false,
    } = this.state.templateSelected.templateSettings;
    const templateSettings = {
      ...this.state.templateSelected.templateSettings,
    };
    if (mode === "mobile") {
      templateSettings.isMobileModeActivated = !isMobileModeActivated;
    } else if (mode === "web") {
      templateSettings.isAnchorEmailMode = !isAnchorEmailMode;
    } else if (mode === "email") {
      templateSettings.isEmailModeActivated = !isEmailModeActivated;
    } else if (mode === "microsoftTeams") {
      templateSettings.isMicrosoftTeamsReminderActivated = !isMicrosoftTeamsReminderActivated;
    }
    this.setState({
      templateSelected: { ...this.state.templateSelected, templateSettings },
    });
  };

  componentDidUpdate = prevProps => {
    if (prevProps.picture !== this.props.picture) {
      this.setState({ picture: this.props.picture });
    }
  };

  onClickSubmit = () => {
    const { clientId, userId, openFromTemplate } = this.props;
    const { templateSettings } = this.state.templateSelected;

    const track = {
      ...this.state,
      templateSettings,
      isTemplate: openFromTemplate ? true : false,
      isPrivate: true,
      isPremiumDemo: false,
      isDraft: openFromTemplate ? false : true,
      clientId,
      userId,
    };
    delete track["_id"];
    this.props.createTrackRequest(track);
  };

  onFileUploaded = async files => {
    const { clientId, userId } = this.props;

    console.log({ files });
    const date = new Date().getTime();
    await uploadPictureRequest({
      urlName: slugify(
        `track-cover-picture/picture/${clientId}/${userId}/${date}`,
      ),
      file: files[0],
    });
  };

  render() {
    const {
      classes,
      isVisible,
      onClose,
      openFromTemplate,
      isSuperAdmin,
    } = this.props;
    return (
      <Dialog
        open={isVisible}
        ref={this.onRendered}
        onClose={this.handleClose(onClose)}
        classes={{ paper: classes.paper }}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTopBar
          title={
            openFromTemplate
              ? i18n.t("create-template")
              : i18n.t("create-campaign")
          }
          onClickRightButton={
            this.state.step === 0 ? this.onClickNext : this.onClickSubmit
          }
          onClickLeftButton={
            this.state.step === 0 ? onClose : this.onClickPrevious
          }
          onClose={onClose}
          rightButtonTitle={
            this.state.step === 0
              ? i18n.t("next")
              : i18n.t("create-the-campaign")
          }
          rightButtonDisabled={
            this.state.step === 1 && !this.state.title[this.props.language]
          }
          requesting={this.props.requesting}
        />
        <DialogContent className={classes.content}>
          <div className={classes.bodyDiv}>
            {this.state.step === 0 && (
              <SelectGoal
                openFromTemplate={this.props.openFromTemplate}
                isSuperAdmin={this.props.isSuperAdmin}
                plan={this.props.plan}
                templateRequesting={this.props.templateRequesting}
                language={this.props.language}
                onClickGoal={this.onClickTemplate}
                onClickNext={this.onClickNext}
                onClickNewCampaign={this.onClickNewCampaign}
                onClickMyCampaignTemplate={this.onClickMyCampaignTemplate}
                requesting={this.props.requesting}
                onClose={onClose}
                templates={this.props.templates}
                tracks={this.props.tracks}
                goals={this.props.publicTemplates}
              />
            )}
            {this.state.step === 1 && (
              <TrackSettings
                isSuperAdmin={isSuperAdmin}
                onClickAdministrationMode={this.onClickAdministrationMode}
                templateSettings={this.state.templateSelected.templateSettings}
                openFromTemplate={this.props.openFromTemplate}
                onClickSubmit={this.onClickSubmit}
                language={this.props.language}
                onChangeDescription={this.onChangeState("description")}
                onChangeTitle={this.onChangeState("title")}
                picture={this.state.picture}
                title={this.state.title[this.props.language]}
                description={this.state.description[this.props.language]}
                onClickPrevious={this.onClickPrevious}
                requesting={this.props.requesting}
                uploading={this.props.pictureUploading}
                onFileUploaded={this.onFileUploaded}
                onClose={onClose}
                onChangePicture={this.onChangePicture}
              />
            )}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  const { role } = state.user;
  const { requesting } = state.createTrack;
  const {
    templates,
    templateRequesting,
    tracks,
    publicTemplates = [],
  } = state.campaign;
  const { plan } = state.client;
  const language = getAppLanguage(state);
  const clientId = getSelectedClientId(state);
  const userId = getUserId(state);
  const { uploading, picture } = state.upload;
  const isSuperAdmin = role === "superAdmin";
  return {
    clientId,
    isSuperAdmin,
    userId,
    templateRequesting,
    requesting,
    language,
    pictureUploading: uploading,
    publicTemplates,
    templates,
    tracks,
    picture,
    plan,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    createTrackRequest,
    uploadPictureRequest,
    getAllTemplatesRequest,
    getAllPublicTemplatesRequest,
    createCampaignRequest,
    resetUpload,
  })(CreationModal),
);
