export const POLL_GET_SETTINGS_REQUESTING = "POLL_GET_SETTINGS_REQUESTING";
export const POLL_GET_SETTINGS_SUCCESS = "POLL_GET_SETTINGS_SUCCESS";
export const POLL_GET_SETTINGS_ERROR = "POLL_GET_SETTINGS_ERROR";
export const POLL_MODIFY_SETTINGS_REQUESTING =
  "POLL_MODIFY_SETTINGS_REQUESTING";
export const POLL_MODIFY_SETTINGS_SUCCESS = "POLL_MODIFY_SETTINGS_SUCCESS";
export const POLL_MODIFY_SETTINGS_ERROR = "POLL_MODIFY_SETTINGS_ERROR";
export const POLL_CHANGE_SETTINGS = "POLL_CHANGE_SETTINGS";
export const POLL_CLOSE_SNACK_MESSAGE = "POLL_CLOSE_SNACK_MESSAGE";

export const POLL_ADD_OPTION = "POLL_ADD_OPTION";
export const POLL_REMOVE_OPTION = "POLL_REMOVE_OPTION";
export const POLL_MODIFY_OPTION = "POLL_MODIFY_OPTION";

export const POLL_CHANGE_TITLE = "POLL_CHANGE_TITLE";

export const POLL_CREATE_REQUESTING = "POLL_CREATE_REQUESTING";
export const POLL_CREATE_SUCCESS = "POLL_CREATE_SUCCESS";
export const POLL_CREATE_ERROR = "POLL_CREATE_ERROR";

export const POLL_GET_ALL_REQUESTING = "POLL_GET_ALL_REQUESTING";
export const POLL_GET_ALL_SUCCESS = "POLL_GET_ALL_SUCCESS";
export const POLL_GET_ALL_ERROR = "POLL_GET_ALL_ERROR";

export const POLL_RECEIVED = "POLL_RECEIVED";
export const POll_CHANGE_FIELD = "POll_CHANGE_FIELD";
