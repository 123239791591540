import React, { useEffect, useRef, KeyboardEvent } from "react";
import { TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import i18n from "i18n";

const useStyles = makeStyles(theme => ({
  main: {
    padding: "25px 30px",
    minHeight: 100,
    fontFamily: "Hind",
    lineHeight: 1.5,
  },
}));

interface Props {
  placeholder?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setIsFocused: (bool: boolean) => void;
  className?: string;
  value: string;
  autoFocus?: boolean;
  onSendWithKey?: (event: KeyboardEvent) => void;
}

const MessageBoxInput = ({
  placeholder = i18n.t("your-message"),
  className = "",
  value,
  onChange,
  setIsFocused,
  autoFocus = true,
  onSendWithKey = () => {},
}: Props) => {
  const classes = useStyles();
  const inputRef = useRef(null);

  const handleClickInput = () => {
    inputRef?.current?.focus();
  };

  useEffect(() => {
    if (autoFocus) {
      handleClickInput();
    }
  }, [autoFocus]);

  const onFocus = e => {
    e.currentTarget.setSelectionRange(
      e.currentTarget.value.length,
      e.currentTarget.value.length,
    );
    setIsFocused(true);
  };

  const onBlur = () => {
    setIsFocused(false);
  };

  return (
    <TextField
      inputRef={inputRef}
      variant="standard"
      InputProps={{
        disableUnderline: true,
        className: `${classes.main} ${className}`,
      }}
      fullWidth
      onFocus={onFocus}
      onBlur={onBlur}
      autoFocus={autoFocus}
      onClick={handleClickInput}
      placeholder={placeholder}
      multiline
      onChange={onChange}
      value={value}
      onKeyPress={event => onSendWithKey(event)}
    />
  );
};

export { MessageBoxInput };
