import React from "react";
import withStyles from '@mui/styles/withStyles';
import SliderResults from "scenes/Campaigns/CampaignScreen/SurveysSide/SliderResults";
import ObjectivesSection from "scenes/Campaigns/CampaignScreen/StatsSide/ObjectivesSection";
import selectLanguage from "utils/selectLanguage";

const styles = theme => ({
  main: {},
});

export const formatTopics = (question, language) => {
  return question.answers.map(answer => {
    return answer.choices.map(choice => {
      const title = { ...choice };
      return { title, value: selectLanguage({ language, text: title }) };
    });
  });
};

const ConsolidateResults = ({ classes, questions = [], language }) => {
  return (
    <div className={classes.main}>
      <SliderResults
        className={""}
        results={questions.filter(
          question => question.questionType === "RATING",
        )}
      />
      {questions
        .filter(question => question.questionType === "MULTIPLE_CHOICES")
        .map(question => (
          <ObjectivesSection
            topicChoices={formatTopics(question, language)}
            language={language}
          />
        ))}
    </div>
  );
};

export default withStyles(styles)(ConsolidateResults);
