import React from "react";
import withStyles from '@mui/styles/withStyles';
import moment from "moment";
import { Typography } from "@mui/material";
import Colors from "constants/Colors";

const styles = theme => ({
  main: {
    boxSizing: "border-box",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 45,
    paddingRight: 45,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    borderBottom: "1px solid #EEEEEE",
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: Colors.hoverBlue,
    },
  },
  selected: {
    backgroundColor: Colors.hoverBlue,
  },
  title: {
    overflow: "hidden",
    lineHeight: "1.5em" /* fallback */,
    maxHeight: "1.5em",
    wordBreak: "break-all",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 1 /* number of lines to show */,
    fontFamily: "Montserrat",
    flex: 1,
    fontSize: 16,
    color: "#5F5F5F",
    fontWeight: "bold",
    marginRight: 50,
  },
  description: {
    fontWeight: "normal",
  },
  date: {
    color: "#5F5F5F",
    fontSize: 12,
  },
});

const NoteRow = ({ classes, title, date, onClick, isSelected = false }) => {
  return (
    <div
      className={`${classes.main} ${isSelected && classes.selected}`}
      onClick={onClick}
    >
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.date}>
        {moment(date).format("LL")}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(NoteRow);
