import React, { memo } from "react";
import { makeStyles } from "@mui/styles";
import { MainTitle } from "components/Titles/MainTitle";
import { MainText } from "components/Texts/MainText";
import i18n from "i18n";
import Colors from "constants/Colors";
import CircleStarIcon from "components/Icons/CircleStarIcon";
import { RemoveButtonIcon } from "components";
import CustomArrowTooltip from "components/CustomArrowTooltip";

const useStyles = makeStyles(theme => ({
  main: {
    borderRadius: 8,
    paddingTop: 24,
    paddingBottom: 24,
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: Colors.hoverBlue,

      paddingLeft: 24,
    },
  },
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: "rgba(240, 240, 245, 1)",
  },
  leftView: {
    width: 60,
    display: "flex",
    alignItems: "center",
    // justifyContent: "center",
  },
  middleView: {
    flexDirection: "column",
    display: "flex",
    flex: 1,
    marginRight: 24,
  },
  rightView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  selectText: {
    fontSize: 16,
    color: Colors.pureMain,
    fontWeight: 500,
    marginRight: 15,
  },
}));

interface Props {
  text: string;
  index: number;
  onClick: () => void;
  onClickDelete: () => void;
}

const FavoriteNotificationBox = ({
  text = "",
  index,
  onClick,
  onClickDelete = () => null,
  ...props
}: Props) => {
  const classes = useStyles();

  const notificationSplitted = text?.split(/\r?\n/);
  const notificationTitle = notificationSplitted?.[0] || "";
  const notificationText = notificationSplitted.slice(1).join("\n") || "";

  return (
    <>
      {index > 0 && <div className={classes.divider} />}
      <div className={classes.main} onClick={onClick}>
        <div className={classes.leftView}>
          <CircleStarIcon />
        </div>
        <div className={classes.middleView}>
          <MainTitle text={notificationTitle} />
          {!!notificationText && <MainText text={notificationText} />}
        </div>
        <div className={classes.rightView}>
          <MainText
            text={i18n.t("notification-select")}
            className={classes.selectText}
          />
          <CustomArrowTooltip title={"remove"}>
            <RemoveButtonIcon
              onClick={onClickDelete}
              // className={classes.removeIcon}
            />
          </CustomArrowTooltip>
        </div>
      </div>
    </>
  );
};

export default memo(FavoriteNotificationBox);
