//@flow
import {
  CREATE_COACHEE_REQUESTING,
  DELETE_COACHEE_REQUESTING,
  GET_MY_COACHEES_REQUESTING,
} from "./constants";

export const createCoacheeRequest = (payload: {
  coachId: string;
  userId: string;
}) => {
  return {
    type: CREATE_COACHEE_REQUESTING,
    payload,
  };
};

export const getMyCoacheesRequest = (payload: { coachId: string }) => {
  return {
    type: GET_MY_COACHEES_REQUESTING,
    payload,
  };
};

export const deleteCoacheeRequest = payload => {
  return {
    type: DELETE_COACHEE_REQUESTING,
    payload,
  };
};
