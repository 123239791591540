import React from "react";
import Lottie from "react-lottie";
import DoneAnimation from "../../resources/done.json";

class DoneLottie extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isStopped: true, isPaused: false };
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({ isStopped: false });
    }, 500);
  };

  render() {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: DoneAnimation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    const { width, height } = this.props;

    return (
      <Lottie
        options={defaultOptions}
        height={height}
        width={width}
        isStopped={this.state.isStopped}
        isPaused={this.state.isPaused}
      />
    );
  }
}

export default DoneLottie;
