import React from "react";

const CommentIcon = ({ sizePercentage, color, className }) => (
  <svg
    className={className}
    width={sizePercentage * 17}
    height={sizePercentage * 15}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 17 15"
    version="1.1"
  >
    <path
      d="M2.91711 14.2045H1.80996L2.59283 13.4216C3.01494 12.9995 3.27854 12.4505 3.34941 11.8549C1.54175 10.6687 0.64209 8.95012 0.64209 7.14848C0.64209 3.82467 3.69698 0.446899 8.4813 0.446899C13.5498 0.446899 16.2676 3.55508 16.2676 6.85728C16.2676 10.1812 13.5211 13.2873 8.4813 13.2873C7.59846 13.2873 6.67722 13.1695 5.85473 12.9532C5.07614 13.7518 4.02121 14.2045 2.91711 14.2045Z"
      fill={color}
    />
  </svg>
);

CommentIcon.defaultProps = {
  sizePercentage: 1,
  color: "#EA87B7",
  className: "",
};

export default CommentIcon;
