import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { MainText } from "components/Texts/MainText";
import i18n from "i18n";
import Colors from "constants/Colors";

const useStyles = makeStyles(theme => ({
  main: {},
  block: {
    padding: "1.5rem 0.5rem",
    borderRadius: "0.25rem",
    border: "1px solid #e6e9ef",
    background: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    cursor: "pointer",
    transition: "all 0.2s ease",
    width: 150,
    "&:hover": { backgroundColor: Colors.hoverBlue },
  },
  smallText: {
    color: Colors.text,
    textAlign: "center",
  },
}));

interface Props {
  onClick: () => void;
  recordType: string;
}

const RecordAskPermission = ({ onClick, recordType = "camera" }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.block} onClick={onClick}>
      <MainText
        className={classes.smallText}
        size="small"
        text={i18n.t(recordType + "-permissions")}
      />
    </div>
  );
};

export default RecordAskPermission;
