import { createSelector } from "reselect";
// import { getIsFirstConnection } from "services/general/selectors";
import moment from "moment";

export const getUserId = state => state.user?._id;

export const getUserTimezone = state => state.user.timezone || "Europe/Paris";

// export const getSelectedClientId = state =>
//   // if selectedClientId exists put the user as a client's user
//   state.myClients.selectedClientId || state.user.client;

export const getSelectedClientId = state =>
  state.myClients.selectedClientId || state.user.client;

export const getUserClientId = state => state.user.client;

export const getIsSuperAdmin = state => state.user.role === "superAdmin";

export const getIsMicrodoingAdmin = state =>
  state.user.role === "microdoingAdmin";

export const getIsAdmin = state => state.user.role === "admin";

export const getIsObserver = state => state.user.role === "observer";

export const getIsTrainer = state => state.user.role === "trainer";

export const getIsTrainerOrObserver = createSelector(
  getIsTrainer,
  getIsObserver,
  (isTrainer, isObserver) => isTrainer || isObserver,
);

export const getIsModifyForbidden = createSelector(
  getIsTrainerOrObserver,
  isTrainerOrObserver => isTrainerOrObserver,
);

export const getUser = state => state.user;

export const getUserEmail = state => state.user.email;

export const getGoogleTokens = state => state.user.googleTokens;

export const getUserFirstName = state => state.user.firstName;

export const getUserLastName = state => state.user.lastName;

export const getUserPicture = state => state.user.picture;

export const getUserName = state =>
  `${state.user.firstName} ${state.user.lastName}`;

export const getUserCreationDate = state => state.user.createdAt;

const getIsCreatedToday = state =>
  moment(state.user.createdAt).isSame(moment(), "day");

export const getIsFirstCoachingConnection = createSelector(
  getIsCreatedToday,
  // getIsFirstConnection,
  // (isCreatedToday, isFirstConnection) => {
  isCreatedToday => {
    console.log({ isCreatedToday });
    return isCreatedToday && true;
  },
);
