import React, { useEffect, useState } from "react";

import { seeUserProfileRequest } from "services/manager/actions";
import { cleanMessage, setReceiver } from "services/message/actions";
import {
  getCoachingSessionRequest,
  clearCoachingSession,
} from "services/coaching/actions";
import {
  getMyCoacheesRequest,
  deleteCoacheeRequest,
} from "services/myCoachees/actions";
import { getSurveysByCampaignRequest } from "services/survey/actions";
import {
  getCampaignAllDoneStepsRequest,
  getUserCampaignsListRequest,
  selectCampaign,
  selectCampaignRequest,
} from "services/campaign/actions";

import Fuse from "fuse.js";
import queryString from "query-string";

import withStyles from "@mui/styles/withStyles";
import FlatButton from "components/FlatButton";
import { connect } from "react-redux";
import CoachingSessionBox from "scenes/MyCoachees/CoachingSessionBox";
import i18n from "i18n";
import NewCoacheeModal from "scenes/MyCoachees/NewCoacheeModal";
import SeeUserModal from "scenes/Campaigns/SeeUserModal";
import {
  getActiveCoachees,
  getFinishedCoachees,
} from "services/myCoachees/selectors";
import SearchBar from "components/SearchBar";
import AddIcon from "./AddIcon";
import Colors from "constants/Colors";
import DeleteConfirmModal from "components/DeleteConfirmModal";
import { getIsCoachingAccount } from "services/client/selectors";
import { Typography } from "@mui/material";
import AddCoachingModal from "scenes/MyCoachees/AddCoachingModal";
import MyTabs from "components/MyTabs";
import { push } from "connected-react-router";
import { getUserId } from "services/user/selectors";

const fuseOptions = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 80,
  maxPatternLength: 20,
  minMatchCharLength: 1,
  keys: ["userId.firstName", "userId.lastName"],
};

const styles = theme => ({
  main: {},
  topDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  list: {
    display: "flex",
    marginTop: 35,
    flexWrap: "wrap",
    flexDirection: "row",
  },
  newButton: {
    marginRight: 25,
    minWidth: 210,
  },
  icon: {
    marginRight: 10,
  },
  noCoaching: {
    fontStyle: "italic",
    color: "#454545",
    fontSize: 16,
    paddingRight: 45,
    flex: 1,
    width: "100%",
    textAlign: "center",
    height: 350,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  tabs: {
    marginTop: 20,
    width: "95%",
  },
});

const CoacheesList = ({
  classes,
  activeCoachees = [],
  finishedCoachees = [],
  clearCoachingSession,
  seeUserProfileRequest,
  getCoachingSessionRequest,
  deleteCoacheeRequest,
  getMyCoacheesRequest,
  getUserCampaignsListRequest,
  getCampaignAllDoneStepsRequest,
  getSurveysByCampaignRequest,
  selectCampaignRequest,
  selectCampaign,
  setReceiver,
  cleanMessage,
  isCoachingAccount,
  coachId,
  push,
}) => {
  const [isCreateCoacheeModalVisible, openCreateCoacheeModal] = useState(false);
  const [isAddCoachingModalVisible, openAddCoachingModal] = useState(false);
  const [coachingSessionIdSelected, selectCoachingSessionId] = useState("");
  const [isUserModalOpen, openUserModal] = useState(false);
  const [search, setSearch] = useState("");

  const [tabSelected, selectTab] = useState(0);

  const myCoachees =
    tabSelected === 0
      ? activeCoachees.filter(el => el.userId)
      : finishedCoachees.filter(el => el.userId);

  const onInit = () => {
    const query = queryString.parse(window.location.search);
    const { campaignId, tabSelectedIndex = 0 } = query;

    if (campaignId) {
      const coachingSession = activeCoachees?.find(
        el => el.campaignId === campaignId,
      );
      if (coachingSession) {
        clickOpenUserModal({
          userId: coachingSession.userId?._id || coachingSession.userId,
          picture: coachingSession.userId?.picture,
          campaignId,
          tabSelectedIndex,
        })();
      }
    }
  };

  useEffect(onInit, []);

  const clickOpenUserModal = userSelected => () => {
    const { campaignId, userId, picture, tabSelectedIndex = 0 } = userSelected;
    clearCoachingSession();
    cleanMessage();
    setReceiver({
      _id: userId,
      picture,
    });
    seeUserProfileRequest({
      userId,
      managerId: coachId,
      tabSelectedIndex,
    });
    getCoachingSessionRequest({
      userId,
      coachId,
    });
    getUserCampaignsListRequest({
      campaignId,
      page: 0,
      limit: 1000,
    });
    getCampaignAllDoneStepsRequest({
      campaignId,
    });
    getSurveysByCampaignRequest({
      campaignId,
    });

    selectCampaign({ _id: campaignId, participants: [userId] });
    selectCampaignRequest({ campaignId });

    if (campaignId) {
      push(
        `/my-coachees?campaignId=${campaignId}&tabSelectedIndex=${tabSelectedIndex}`,
      );
    } else {
      push(`/my-coachees`);
    }
    openUserModal(true);
  };

  useEffect(() => {
    getMyCoacheesRequest({ coachId });
  }, [getMyCoacheesRequest, coachId]);

  const onCreateCoachee = () => {
    openCreateCoacheeModal(true);
    console.log("Coachee");
  };

  const onChangeSearch = searchValue => {
    setSearch(searchValue);
  };

  const onDeleteCoachee = ({ coachingSessionId }) => e => {
    e.stopPropagation();
    selectCoachingSessionId(coachingSessionId);
  };

  const onCloseDeleteModal = () => selectCoachingSessionId(null);

  const onConfirmDelete = () => {
    deleteCoacheeRequest({ coachingSessionId: coachingSessionIdSelected });
    selectCoachingSessionId(null);
  };

  const onCloseUserModal = () => {
    openUserModal(false);
    push("/my-coachees");
  };

  const fuse = new Fuse(myCoachees, fuseOptions);
  const searchResults = search.length > 0 ? fuse.search(search) : myCoachees;

  return (
    <>
      <DeleteConfirmModal
        onClose={onCloseDeleteModal}
        isVisible={!!coachingSessionIdSelected}
        title={i18n.t("delete-confirmation-modal-title-coachee")}
        onClickConfirm={onConfirmDelete}
      />
      <SeeUserModal open={isUserModalOpen} onClose={onCloseUserModal} />
      <NewCoacheeModal
        isVisible={isCreateCoacheeModalVisible}
        onClose={() => openCreateCoacheeModal(false)}
      />
      <AddCoachingModal
        isVisible={isAddCoachingModalVisible}
        onClose={() => openAddCoachingModal(false)}
        onCreateCoachee={onCreateCoachee}
      />
      <div className={classes.main}>
        <div className={classes.topDiv}>
          <FlatButton
            intercomTarget={"add-new-coachee"}
            icon={<AddIcon className={classes.icon} color={"#fff"} />}
            className={classes.newButton}
            onClick={isCoachingAccount ? onCreateCoachee : openAddCoachingModal}
            title={i18n.t("add-new-coachee")}
          />
          <SearchBar value={search} onChangeSearch={onChangeSearch} />
        </div>
        <MyTabs
          className={classes.tabs}
          variant="standard"
          centered={false}
          tabSelected={tabSelected}
          selectTab={selectTab}
          tabs={[i18n.t("active-coaching"), i18n.t("finished-coaching")]}
        />
        <div className={classes.list}>
          {!myCoachees.length && (
            <Typography className={classes.noCoaching}>
              {i18n.t("no-coaching-yet")}
            </Typography>
          )}
          {searchResults.map(coachingSession => {
            const {
              firstName,
              lastName,
              isTestAccount,
            } = coachingSession.userId ? coachingSession.userId : {};
            const { campaignId } = coachingSession;
            return (
              <CoachingSessionBox
                key={coachingSession._id}
                {...coachingSession}
                isTestAccount={isTestAccount}
                coachingName={`${firstName} ${lastName}`}
                picture={coachingSession.userId?.picture}
                onClick={clickOpenUserModal({
                  userId: coachingSession.userId?._id || coachingSession.userId,
                  picture: coachingSession.userId?.picture,
                  campaignId,
                })}
                onClickDelete={onDeleteCoachee({
                  coachingSessionId: coachingSession._id,
                })}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    coachId: getUserId(state),
    activeCoachees: getActiveCoachees(state),
    finishedCoachees: getFinishedCoachees(state),
    isCoachingAccount: getIsCoachingAccount(state),
  };
};

export default connect(mapStateToProps, {
  clearCoachingSession,
  seeUserProfileRequest,
  getCoachingSessionRequest,
  getMyCoacheesRequest,
  setReceiver,
  cleanMessage,
  deleteCoacheeRequest,
  getUserCampaignsListRequest,
  getCampaignAllDoneStepsRequest,
  getSurveysByCampaignRequest,
  selectCampaign,
  selectCampaignRequest,
  push,
})(withStyles(styles)(CoacheesList));
