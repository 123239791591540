import React, { PureComponent } from "react";
import { connect } from "react-redux";

import moment from "moment";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import Colors from "../../constants/Colors";
import AddIcon from "@mui/icons-material/Add";
import { getAllTemplatesRequest } from "../../services/campaign/actions";
import { navigateCreateTrack } from "../../services/createTrack/actions";
import { push } from "connected-react-router";
import "moment/locale/fr";
import { Loader } from "../../components";
import NewCampaignModal from "./NewCampaignModal";
import selectLanguage from "../../utils/selectLanguage";
import CampaignBox from "./CampaignBox";
import i18n from "../../i18n";
import { getUserId, getUserClientId } from "../../services/user/selectors";
import { getAppLanguage } from "../../services/general/selectors";

moment.locale("fr");

const styles = theme => ({
  main: {
    width: "90%",
    marginTop: 15,
    flexDirection: "row",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  paper: {
    flex: 1,
    marginTop: "calc(1rem)",
    marginLeft: "-1.5rem",
    marginRight: "-1.5rem",
    marginBottom: "calc(-3rem)",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  createDiv: {
    flex: "0 0 auto",
    padding: "0px 1.5rem calc(2rem)",
  },
  createCampaign: {
    textDecoration: "none",
    width: 300,
    height: 450,
    borderRadius: 8,
    backgroundColor: Colors.main,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    transition: "0.2s",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
  createPollText: {
    color: "#fff",
  },
});

class AdminTemplates extends PureComponent {
  state = {
    expanded: null,
    labelWidth: 0,
    isCreationModalVisible: false,
  };

  componentDidMount() {
    this.props.getAllTemplatesRequest({
      clientId: this.props.clientId,
      isPrivate: false,
    });
  }

  onChangeTime = event => {
    const time = event.target.value;
    const minute = Number(time.split(":")[1]);
    const hour = Number(time.split(":")[0]);

    const reminderTime = moment(this.props.reminderTime)
      .minute(minute)
      .hour(hour);
    this.props.changeCoffeeSettings({ reminderTime });
  };

  onClickMore = () => {};

  onClickTrack = trackId => () => {
    this.props.navigateCreateTrack({ trackId });
  };

  displayTemplates = () => {
    const { language } = this.props;
    return this.props.templates.map((el, i) => {
      return (
        <CampaignBox
          key={i}
          {...el}
          participants={[]}
          showSteps
          onClick={this.onClickTrack(el._id)}
          title={selectLanguage({ text: el.title, language })}
          description={selectLanguage({ text: el.description, language })}
        />
      );
    });
  };

  onCloseCreationModal = () => {
    this.setState({ isCreationModalVisible: false });
  };

  displayCreationModal = () => {
    this.setState({ isCreationModalVisible: true });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.main}>
        <NewCampaignModal
          openFromTemplate
          isVisible={this.state.isCreationModalVisible}
          onClose={this.onCloseCreationModal}
        />
        <div className={classes.paper}>
          <div className={classes.createDiv}>
            <div
              className={classes.createCampaign}
              onClick={this.displayCreationModal}
            >
              <AddIcon style={{ color: "#fff" }} />
              <Typography className={classes.createPollText}>
                {i18n.t("new-template")}
              </Typography>
            </div>
          </div>
          {this.props.requesting ? (
            <Loader size={75} />
          ) : (
            this.displayTemplates()
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { requesting, templates } = state.campaign;
  const language = getAppLanguage(state);
  const clientId = getUserClientId(state);
  const userId = getUserId(state);
  return {
    token: state.auth.token,
    userId,
    language,
    requesting,
    clientId,
    templates,
  };
};

export default connect(mapStateToProps, {
  getAllTemplatesRequest,
  navigateCreateTrack,
  push,
})(withStyles(styles)(AdminTemplates));
