import React from "react";
import withStyles from '@mui/styles/withStyles';
import IphoneMockup from "scenes/CreateTrack/CreateActionTextStep/PreviewSide/IphoneMockup";
import Colors from "constants/Colors";

const styles = theme => ({
  main: {
    width: 0,
    opacity: 0,
    zIndex: -1,
    paddingTop: 30,
    height: "100%",
    borderLeft: "solid 1px",
    borderColor: Colors.lightGrey,
    // flex: 1.1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    display: "flex",
    transition: "all 0.3s ease",
    marginTop: 30,
  },
  svgWrapperDiv: {
    position: "relative",
  },
  connectionAppDiv: {
    paddingLeft: 45,
    paddingRight: 45,
    width: "100%",
    height: "85%",
    zIndex: 1,
    top: 0,
    display: "flex",
    position: "absolute",
    alignItems: "center",
    flexDirection: "column",
    overflow: "scroll",
    overflowX: "hidden",
    marginTop: 45,
  },
  visible: {
    width: 375,
    opacity: 1,
    zIndex: 0,
  },
});

const Preview = ({
  classes,
  isSmall = false,
  isPreviewVisible = true,
  children,
}) => {
  return (
    <div className={`${classes.main} ${isPreviewVisible && classes.visible}`}>
      <div className={classes.svgWrapperDiv}>
        <IphoneMockup sizePercentage={isSmall ? 0.7 : 0.78} color={"#fff"} />
        <div className={classes.connectionAppDiv}>{children}</div>
      </div>
    </div>
  );
};

export default withStyles(styles)(Preview);
