import React from "react";

const WebIcon = ({ sizePercentage, color, className }) => (
  <svg
    className={className}
    width={sizePercentage * 28}
    height={sizePercentage * 25}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 28 25"
    version="1.1"
  >
    <path
      d="M1.47742 18.9503H12.6987V22.8853H8.21022V24.6342H18.9827V22.8853H14.4942V18.9503H25.7155C26.0725 18.9499 26.4148 18.8116 26.6672 18.5657C26.9196 18.3198 27.0616 17.9864 27.062 17.6387V1.46156C27.0616 1.11381 26.9196 0.78042 26.6672 0.534525C26.4148 0.288629 26.0725 0.150307 25.7155 0.149902H1.47742C1.12042 0.150307 0.778156 0.288629 0.525717 0.534525C0.273278 0.78042 0.131275 1.11381 0.130859 1.46156V17.6387C0.131275 17.9864 0.273278 18.3198 0.525717 18.5657C0.778156 18.8116 1.12042 18.9499 1.47742 18.9503ZM1.92627 1.89878H25.2666V17.2014H1.92627V1.89878Z"
      fill={color}
    />
  </svg>
);

WebIcon.defaultProps = {
  sizePercentage: 1,
  color: "#00A7FC",
  className: {},
};

export default WebIcon;
