import React, { useState } from "react";
import withStyles from '@mui/styles/withStyles';
import FilterIcon from "@mui/icons-material/FilterListRounded";
import { Typography } from "@mui/material";
import i18n from "../../../i18n";
import ClickOutside from "../../../components/ClickOutside";
import Colors from "../../../constants/Colors";
import Skill from "../Skills/Skill";
import selectLanguage from "../../../utils/selectLanguage";
import FilterContent from "./FilterContent";

const styles = theme => ({
  main: {
    marginLeft: 10,
    marginRight: 5,
    cursor: "pointer",
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#6f7782",
    padding: "0 10px",
    height: 35,
    borderRadius: 4,
    transition: "background 0.1s",
    "&:hover": {
      backgroundColor: "#e8ecee",
      color: "#273240",
      fill: "#273240",
    },
  },
  text: {
    color: "inherit",
    fontSize: 14,
  },
  leftIcon: {
    marginRight: 5,
  },
  isFocused: {
    backgroundColor: "#e8ecee",
    color: Colors.pureMain,
    fill: Colors.pureMain,
    "&:hover": {
      backgroundColor: "#e8ecee",
      color: Colors.pureMain,
      fill: Colors.pureMain,
    },
  },
  withFilter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const FilterMenu = ({
  language,
  classes,
  filterSelected,
  selectFilter,
  skillsList,
  sortSelected = "",
  selectSort,
  isLibrary,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = e => {
    setAnchorEl(e.currentTarget);
  };

  const onRemoveFilter = () => {
    selectFilter({})();
  };

  return (
    <ClickOutside onClickOutside={() => setAnchorEl(null)}>
      <FilterContent
        language={language}
        isLibrary={isLibrary}
        skills={skillsList.skills}
        selectFilter={selectFilter}
        filterSelected={filterSelected}
        sortSelected={sortSelected}
        selectSort={selectSort}
        anchorEl={anchorEl}
      />
      <div className={classes.withFilter}>
        <div
          className={`${classes.main} ${anchorEl && classes.isFocused}`}
          onClick={openMenu}
        >
          <FilterIcon className={classes.leftIcon} />
          <Typography className={classes.text}>{i18n.t("filter")}</Typography>
        </div>
        {filterSelected.value && (
          <Skill
            {...filterSelected}
            label={selectLanguage({ text: filterSelected?.label, language })}
            isSmall
            hasRemove
            onClickRemove={onRemoveFilter}
          />
        )}
      </div>
    </ClickOutside>
  );
};

export default withStyles(styles)(FilterMenu);
