import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import Colors from "constants/Colors";
import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "components/IconButton";
import SchoolIcon from "@mui/icons-material/School";
import Notifications from "../Notifications";
import debounce from "lodash/debounce";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import { connect } from "react-redux";
import {
  getUserId,
  getSelectedClientId,
  getIsSuperAdmin,
  getIsMicrodoingAdmin,
  getIsTrainerOrObserver,
} from "services/user/selectors";
import {
  activateEmailReminderRequest,
  resetTeamsRequest,
  modifyCampaignRequest,
} from "services/campaign/actions";
import { sendNotificationRequest } from "services/manager/actions";
import { navigateCreateTrack } from "services/createTrack/actions";
import {
  changeGeneralSettings,
  openSnackMessage,
} from "services/general/actions";
import i18n from "i18n";
import {
  getAppLanguage,
  getAdminConnections,
} from "services/general/selectors";
import selectLanguage from "utils/selectLanguage";
import PublishCampaignModal from "scenes/CreateTrack/PublishCampaignModal";

import AddNotes from "scenes/Campaigns/CampaignScreen/AddNotes";
import AddLabel from "scenes/Campaigns/CampaignScreen/AddLabel";
import CampaignSummary from "scenes/Campaigns/CampaignScreen/SideBar/CampaignSummary";
import NavBar from "./NavBar/NavBar";
import AddParticipantIcon from "scenes/Campaigns/CampaignScreen/SideBar/AddParticipantIcon";
import NotificationIcon from "scenes/Campaigns/CampaignScreen/SideBar/NotificationIcon";
import AddParticipantModal from "scenes/Campaigns/CampaignScreen/LearnerSide/AddParticipantModal";
import { changeAddUsersModal } from "services/campaign/actions";
import CopyCode from "scenes/Campaigns/CampaignScreen/SideBar/CopyCode";
import getCampaignStatus from "utils/getCampaignStatus";
import moment from "moment";
import Modal from "components/Modal";
import { getCampaignSelected } from "services/campaign/selectors";
import BackIcon from "../ReviewsSide/BackIcon";
import ArrowBackIcon from "../ArrowBackIcon";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import EmailRecapIcon from "../StatsSide/EmailRecapIcon";
import { fetchApi } from "services/api";
import { Loader } from "components";

const GET_CAMPAIGN_REPORT = "/v1/campaigns/report/campaign-report";

const styles = theme => ({
  leftDiv: {
    backgroundColor: Colors.darkBlue,
    overflowX: "scroll",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    height: "100%",
    width: "inherit",
    paddingBottom: 25,
    position: "fixed",
  },
  topLeft: {
    flexDirection: "column",
    display: "flex",
  },
  button: {
    width: 200,
    marginBottom: 20,
    marginLeft: 15,
    marginRight: 15,
    alignSelf: "center",
  },
  accessCodeDiv: {
    zIndex: 2,
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  space: {
    background: "#3D3E63",
    height: 1,
    width: "100%",
    marginBottom: 15,
  },
  line: {
    height: 1,
    width: "100%",
    background: "#3D3E63",
  },
  backButton: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    flexDirection: "row",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 0.2s",
    padding: "7px",
    paddingLeft: 30,
    "&:hover": {
      background: Colors.lightGrey,
    },
  },
  backButtonText: {
    fontFamily: "Montserrat",
    fontSize: "1em",
    fontWeight: 700,
    marginLeft: 12,
    color: "white",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  chevron: {
    color: "#fff",
    marginLeft: -5,
    fontSize: 32,
  },
});

class LeftUserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNotesModalVisible: false,
      isGoalsModalVisible: false,
      isNotificationsVisible: false,
      isSettingsModalVisible: false,
      isAddParticipantModalVisible: false,
      isDisconnectUserModalVisible: false,
      picture: "",
      notes: this.props.notes,
      label: this.props.label,
      userConnected: {},
      reportRequesting: false,
    };

    this.updateCampaign = debounce(this.updateCampaign, 875);
  }

  displayNotifs = () => {
    this.setState({ isNotificationsVisible: true });
  };

  openSettingsModal = () => {
    this.setState({ isSettingsModalVisible: true });
  };

  closeSettingsModal = () => {
    this.setState({ isSettingsModalVisible: false });
  };

  closeNotifs = () => {
    this.setState({ isNotificationsVisible: false });
  };

  onChangeParams = param => event => {
    const value = event.target.value;
    this.setState({ [param]: value });
    // debounced
    this.updateCampaign();
  };

  updateCampaign = () => {
    const { campaignId } = this.props;
    const { notes, label } = this.state;
    this.props.modifyCampaignRequest({ campaignId, notes, label });
  };

  modifyTrack = () => {
    const { trackId, campaignId, title, language } = this.props;
    const campaignName = selectLanguage({ text: title, language });
    this.props.navigateCreateTrack({
      trackId,
      isEditingCampaign: true,
      campaignId,
      campaignName,
    });
  };

  onClickModifyTrack = () => {
    this.modifyTrack();
  };

  openParticipantModal = () => {
    this.props.changeAddUsersModal({
      isAddUsersModalVisible: true,
      hasPublishCampaignAnimation: false,
    });
  };

  closeParticipantModal = () => {
    this.setState({ isAddParticipantModalVisible: false });
  };

  sendnotification = (
    notificationTitle,
    notificationText,
    notificationDate,
  ) => {
    this.props.sendNotificationRequest({
      sendingDate: moment(notificationDate).toDate(),
      userIds: this.props.participants,
      managerId: this.props.userId,
      title: notificationTitle,
      text: notificationText,
      campaignId: this.props.campaignId,
    });
  };

  downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  handleCampaignRecap = () => {
    const { trackId, campaignId, title, language } = this.props;
    this.setState({ reportRequesting: true });
    fetchApi(`${GET_CAMPAIGN_REPORT}?campaignId=${campaignId}`, {}, "get")
      .then(res => {
        const skills = res?.skills?.map(skill =>
          selectLanguage({ text: skill.label, language }),
        );

        const tags = res?.tags?.map(tag => {
          return selectLanguage({ text: tag?.label, language });
        });

        const onboardingSurvey = res?.onboardingSurvey?.map(survey =>
          selectLanguage({ text: survey?.questions?.questionTitle, language }),
        );

        const gardiansMostDoneSteps = res?.mbtiStat?.mostDoneSteps?.gardians.map(
          gardianSteps => {
            const stepTitle = gardianSteps?.stat?.step[0]?.title;
            return selectLanguage({ text: stepTitle, language });
          },
        );
        const craftsmansMostDoneSteps = res?.mbtiStat.mostDoneSteps.craftsmans.map(
          craftsmanSteps => {
            const stepTitle = craftsmanSteps?.stat?.step[0]?.title;
            return selectLanguage({ text: stepTitle, language });
          },
        );
        const conceptualsMostDoneSteps = res?.mbtiStat.mostDoneSteps.conceptuals.map(
          conceptualSteps => {
            const stepTitle = conceptualSteps?.stat?.step[0]?.title;
            return selectLanguage({ text: stepTitle, language });
          },
        );
        const idealistsMostDoneSteps = res?.mbtiStat.mostDoneSteps.idealists.map(
          idealistSteps => {
            const stepTitle = idealistSteps?.stat?.step[0]?.title;
            return selectLanguage({ text: stepTitle, language });
          },
        );

        const numberOfMostDoneStep = res?.mostDoneSteps?.reduce(
          (acc, curr) => {
            return {
              globalDonePercentage:
                acc?.globalDonePercentage + Number(curr?.stat?.donePercentage),
              globalRating: acc?.globalRating + Number(curr?.stat?.rating),
            };
          },
          {
            globalDonePercentage: 0,
            globalRating: 0,
          },
        );
        numberOfMostDoneStep.globalDonePercentage = (
          numberOfMostDoneStep?.globalDonePercentage / 10
        ).toFixed(2);
        numberOfMostDoneStep.globalRating = (
          numberOfMostDoneStep?.globalRating / 10
        ).toFixed(2);

        const mostDoneActionsSkills = res?.mostDoneActionsSkillValue
          .map(actionSkill => {
            const stat = [
              selectLanguage({ text: actionSkill?.label, language }),
              {
                numberOfActionDone: actionSkill?.numberOfAction,
                percentage: (
                  (actionSkill?.numberOfAction * 100) /
                  res?.mostDoneActionsTotalDone
                ).toFixed(2),
              },
            ];
            return stat;
          })
          .sort((a, b) => b[1]?.numberOfActionDone - a[1]?.numberOfActionDone)
          .reduce((acc, curr) => {
            return [
              ...acc,
              [
                curr[0],
                "numberOfActionDone",
                curr[1]?.numberOfActionDone,
                "percentage",
                curr[1]?.percentage,
                ,
              ],
            ];
          }, []);

        const csvReport = [
          [i18n.t("campaign-report-campaign")],
          [
            i18n.t("campaign-report-campaign-title"),
            selectLanguage({ text: res?.title, language }),
          ],
          [
            i18n.t("campaign-report-campaign-objectif"),
            res?.campaignActionGoal,
          ],
          [i18n.t("campaign-report-campaign-?"), skills],
          [i18n.t("campaign-report-campaign-tags"), tags],
          [
            i18n.t("campaign-report-campaign-total-done-action"),
            res?.totalActionDone,
          ],
          [
            i18n.t("campaign-report-campaign-done-action-per-person"),
            res?.actionPerPerson,
          ],
          [
            i18n.t("campaign-report-campaign-done-percentage"),
            res?.globalDonePercentage,
          ],
          [i18n.t("campaign-report-campaign-global-rating"), res?.globalRating],
          [
            i18n.t("campaign-report-campaign-total-commentaries"),
            res?.numberOfStepComment,
          ],
          [
            i18n.t("campaign-report-campaign-percentage-objectif-reached"),
            res?.percentageOfUserWithReachedGoal,
          ],
          [],
          [i18n.t("campaign-report-onboarding")],
          [i18n.t("campaign-report-onboarding-questions"), onboardingSurvey],
          [],
          [i18n.t("campaign-report-mbti")],
          [
            i18n.t("campaign-report-mbti-gardian-percentage"),
            res?.mbtiStat?.percentage?.gardians,
          ],
          [
            i18n.t("campaign-report-mbti-idealist-percentage"),
            res?.mbtiStat?.percentage?.idealists,
          ],
          [
            i18n.t("campaign-report-mbti-conceptual-percentage"),
            res?.mbtiStat?.percentage?.conceptuals,
          ],
          [
            i18n.t("campaign-report-mbti-craftman-percentage"),
            res?.mbtiStat?.percentage?.craftsmans,
          ],
          [
            i18n.t("campaign-report-mbti-gardian-total-person"),
            res?.mbtiStat?.numberOfPerson?.gardians,
          ],
          [
            i18n.t("campaign-report-mbti-idealist-total-person"),
            res?.mbtiStat?.numberOfPerson?.idealists,
          ],
          [
            i18n.t("campaign-report-mbti-conceptual-total-person"),
            res?.mbtiStat?.numberOfPerson?.conceptuals,
          ],
          [
            i18n.t("campaign-report-mbti-craftman-total-person"),
            res?.mbtiStat?.numberOfPerson?.craftsmans,
          ],
          [
            i18n.t("campaign-report-mbti-gardian-most-done-actions"),
            gardiansMostDoneSteps,
          ],
          [
            i18n.t("campaign-report-mbti-idealist-most-done-actions"),
            idealistsMostDoneSteps,
          ],
          [
            i18n.t("campaign-report-mbti-conceptual-most-done-actions"),
            conceptualsMostDoneSteps,
          ],
          [
            i18n.t("campaign-report-mbti-craftman-most-done-actions"),
            craftsmansMostDoneSteps,
          ],
          [],
          [i18n.t("campaign-report-most-done-actions")],
          [
            i18n.t("campaign-report-most-done-actions-total-done"),
            res?.mostDoneActionsTotalDone,
          ],
          [
            i18n.t("campaign-report-most-done-actions-done-percentage"),
            numberOfMostDoneStep?.globalDonePercentage,
          ],
          [
            i18n.t("campaign-report-most-done-actions-global-rating"),
            numberOfMostDoneStep?.globalRating,
          ],
          [],
          [i18n.t("campaign-report-most-done-actions-skills")],
          [mostDoneActionsSkills.join("\n")],
        ];

        return { csv: csvReport, fileName: res?.title };
      })
      .then(result => {
        this.downloadFile({
          data: result.csv.join("\n"),
          fileName: `${selectLanguage({
            text: result.fileName,
            language,
          })}.csv`,
          fileType: "text/csv",
        });

        this.setState({ reportRequesting: false });
      })
      .catch(error => {
        this.setState({ reportRequesting: false });
        this.props.openSnackMessage({
          snackMessage: i18n.t("errort-export-campaign-report"),
          snackStatus: "alert",
        });
      });
  };

  render() {
    const {
      classes,
      picture,
      title,
      language,
      description,
      createdAt,
      endDate,
      startDate,
      isDone,
      participants,
      campaignRequesting,
      accessCode,
      isObserver,
      onClose,
      hasLimitedAccess,
      isSuperAdmin,
      isMicrodoingAdmin,
    } = this.props;

    return (
      <div className={classes.leftDiv}>
        <div className={classes.topLeft}>
          <PublishCampaignModal
            {...this.props.campaignSelected}
            campaignId={this.props.campaignId}
            isVisible={this.state.isSettingsModalVisible}
            requesting={campaignRequesting}
            onClose={this.closeSettingsModal}
            isEditingMode
          />
          <Modal
            onClose={this.closeNotifs}
            isVisible={this.state.isNotificationsVisible}
            title={i18n.t("send-notification")}
          >
            <Notifications
              userIds={participants}
              sendNotification={this.sendnotification}
            />
          </Modal>
          <AddParticipantModal
            onClose={this.closeParticipantModal}
            isVisible={this.state.isAddParticipantModalVisible}
          />
          <Link to={"/campaigns"} className={classes.backButton}>
            <ChevronLeftIcon className={classes.chevron} />
            <Typography className={classes.backButtonText}>
              {i18n.t("campaign-back-home")}
            </Typography>
          </Link>
          <CampaignSummary
            onClose={onClose}
            title={selectLanguage({ text: title, language })}
            description={selectLanguage({ text: description, language })}
            picture={picture}
            createdAt={createdAt}
            status={getCampaignStatus({ startDate, endDate, isDone })}
          />
          <NavBar />
          <div className={classes.space} />
          <div className={classes.accessCodeDiv}>
            <CopyCode accessCode={accessCode} />
          </div>
          {!hasLimitedAccess && (
            <IconButton
              className={classes.button}
              title={i18n.t("add-participant")}
              onClick={this.openParticipantModal}
              icon={<AddParticipantIcon color={"#fff"} sizePercentage={1.5} />}
              size="large"
            />
          )}
          {!hasLimitedAccess && (
            <IconButton
              className={classes.button}
              title={i18n.t("settings-button")}
              onClick={this.openSettingsModal}
              icon={<SettingsIcon color={"#fff"} fontSize="small" />}
              size="large"
            />
          )}
          <IconButton
            className={classes.button}
            title={i18n.t("modify-campaign-content")}
            onClick={this.onClickModifyTrack}
            icon={<SchoolIcon color={"#fff"} fontSize="small" />}
            size="large"
          />
          {!hasLimitedAccess && (
            <IconButton
              className={classes.button}
              title={i18n.t("send-campaign-notification")}
              onClick={this.displayNotifs}
              icon={<NotificationIcon color={"#fff"} sizePercentage={1.2} />}
              size="large"
            />
          )}
          {(isSuperAdmin || isMicrodoingAdmin) && (
            <IconButton
              className={classes.button}
              title={
                this.state.reportRequesting ? (
                  <Loader />
                ) : (
                  i18n.t("get-campaign-global-information")
                )
              }
              onClick={this.handleCampaignRecap}
              icon={<EmailRecapIcon color="#fff" />}
              size="large"
            />
          )}

          <div className={classes.line} />

          {!hasLimitedAccess && (
            <>
              <AddNotes
                onChangeNote={this.onChangeParams("notes")}
                notes={this.state.notes}
              />
              <AddLabel
                onChange={this.onChangeParams("label")}
                value={this.state.label}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const language = getAppLanguage(state);
  const userId = getUserId(state);
  const clientId = getSelectedClientId(state);
  const { campaignRequesting } = state.campaign;
  const campaignSelected = getCampaignSelected(state);
  const hasLimitedAccess = getIsTrainerOrObserver(state);
  const isSuperAdmin = getIsSuperAdmin(state);
  const isMicrodoingAdmin = getIsMicrodoingAdmin(state);
  const {
    title,
    description,
    picture,
    participants,
    createdAt,
    endDate,
    startDate,
    isEmailReminderActivated,
    teamSize,
    accessCode,
    trackId,
    notes,
    label,
  } = campaignSelected;

  return {
    campaignSelected,
    adminConnections: getAdminConnections(state),
    campaignId: campaignSelected._id,
    userId,
    hasLimitedAccess,
    trackId,
    clientId,
    title,
    description,
    picture,
    participants,
    createdAt,
    endDate,
    startDate,
    isEmailReminderActivated,
    campaignRequesting,
    teamSize,
    language,
    accessCode,
    notes,
    label,
    isSuperAdmin,
    isMicrodoingAdmin,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    activateEmailReminderRequest,
    resetTeamsRequest,
    changeGeneralSettings,
    navigateCreateTrack,
    modifyCampaignRequest,
    changeAddUsersModal,
    sendNotificationRequest,
    openSnackMessage,
  })(LeftUserProfile),
);
