import React from "react";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  Configure,
  Index,
} from "react-instantsearch-dom";
import { useSelector } from "react-redux";
import { getSelectedClientId } from "services/user/selectors";
import i18n from "i18n";
import UserCoachingHits from "./UserCoachingHits";
import Colors from "constants/Colors";
import { getMyCoachees } from "services/myCoachees/selectors";
import { useStartNewCoaching } from "./hooks";

const searchClient = algoliasearch(
  "MTMFJ78PRC",
  "a6400a80c85f1dcceaaddde616c096a3",
);

const useStyles = makeStyles(theme => ({
  main: {
    height: "100%",
    position: "relative",
    width: "100%",
  },
  searchDiv: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
  },
  searchBox: {},
  index: {
    height: 500,
    overflowY: "scroll",
  },
  tableHeaders: {
    width: "100%",
    marginTop: 25,
    marginBottom: 15,
    color: Colors.text,
    fontWeight: 550,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  tableHeaderRow: {
    color: "#101534",
    fontSize: 18,
    width: "100%",
    // backgroundColor: "#fff",
    marginBottom: 30,
    marginTop: 30,
    borderRadius: 8,
    paddingLeft: 45,
    display: "flex",
    alignItems: "space-between",
    flexDirection: "row",
    justifyContent: "center",
  },
  firstTableHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    fontSize: 16,
    fontWeight: 500,
    color: Colors.darkBlue,
    flex: 2,
  },
  tableHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    fontSize: 16,
    fontWeight: 500,
    color: Colors.darkBlue,
    // cursor: "pointer",
    // transition: "all 0.2s ease",
    // "&:hover": {
    //   textDecoration: "underline",
    // },
    // color: "#fff"
  },
}));

const UserList = () => {
  const classes = useStyles();
  const clientId = useSelector(getSelectedClientId);
  const myCoachees = useSelector(getMyCoachees);
  const [requesting, startCoachingRequest] = useStartNewCoaching();

  const isAvailable = userId => {
    return myCoachees.findIndex(el => el?.userId?._id === userId) === -1;
    // return true;
  };

  const onClickStartCoaching = (_id: any) => () => {
    startCoachingRequest(_id);
  };

  return (
    <div className={classes.main}>
      <div className={classes.searchDiv}>
        <InstantSearch searchClient={searchClient} indexName={clientId}>
          <div className={classes.searchBox}>
            <SearchBox
              showLoadingIndicator
              translations={{
                placeholder: i18n.t("search-user-placeholder"),
              }}
            />
          </div>
          <div className={classes.tableHeaders}>
            <Typography className={`${classes.firstTableHeader}`}>
              {i18n.t("user")}
            </Typography>
            <Typography className={classes.tableHeader}>
              {i18n.t("table-header-last-registered")}
            </Typography>
            <div className={classes.tableHeader} />
          </div>
          <div className={classes.index}>
            <Index indexName={clientId}>
              <Configure filters="type:user" hitsPerPage={25} />
              <UserCoachingHits
                requesting={requesting}
                onClickStartCoaching={onClickStartCoaching}
                isAvailable={isAvailable}
              />
            </Index>
          </div>
        </InstantSearch>
      </div>
    </div>
  );
};

export default UserList;
