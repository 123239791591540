import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { authorize } from "../../services/auth/reducer";
import { resetPasswordRequest } from "../../services/auth/actions";

import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import withStyles from '@mui/styles/withStyles';
import TextField from "@mui/material/TextField";
import FlatButton from "../../components/FlatButton";

import Snackbar from "../Signup/Snackbar";
import Colors from "../../constants/Colors";
import i18n from "../../i18n";
import LogoDiv from "../Signup/LogoDiv";
import { Loader } from "../../components/Loader";

const styles = theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "100vh",
    width: "100vw",
    backgroundColor: Colors.darkBlue,
  },
  logo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    // height: 75,
    // paddingTop: 0,
    margin: "30px auto 30px",
  },
  paper: {
    maxWidth: 425,
    display: "flex",
    borderRadius: 3,
    background: "white",
    padding: 25,
    marginLeft: 25,
    marginRight: 25,
    paddingBottom: 30,
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 20,
  },
  title: {
    fontFamily: "Montserrat",
    textAlign: "center",
    color: Colors.darkBlue,
    margin: "0 auto 15px",
    fontSize: 22,
    fontWeight: "bold",
    // lineHeight: "38px",
    textTransform: "none",
    letterSpacing: 0,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE11 issue.
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  loginError: {
    backgroundColor: "#FCE6E7",
    borderRadius: 8,
  },
  errorText: {
    marginTop: 10,
    color: "#e6525a",
    padding: "13px 15px",
    borderRadius: 6,
    backgroundColor: "rgba(230,82,90,0.15)",
  },
  textField: {
    marginTop: 20,
  },
  submitButton: {
    marginTop: 20,
    padding: 12,
  },
  noDecoration: {
    textDecoration: "none",
  },
  forgotPassword: {
    marginTop: 20,
    cursor: "pointer",
    textDecoration: "none",
    color: Colors.pureMain,
    fontSize: 17,
    lineHeight: 1.5,
    textAlign: "center",
  },
  newAccountText: {
    fontWeight: "bold",
    fontFamily: "Montserrat",
    color: Colors.white,
    fontSize: 16,
    textAlign: "center",
  },
  bold: {
    fontFamily: "Montserrat",
    marginLeft: 5,
    color: Colors.white,
    fontSize: 16,
    cursor: "pointer",
    fontWeight: "bold",
    textDecoration: "underline",
  },
  noAccountDiv: {
    maxWidth: 425,
    marginLeft: 25,
    marginRight: 25,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  explanation: {
    textAlign: "center",
    fontSize: 16,
    marginBottom: 5,
  },
  error: {
    color: Colors.red,
  },
  resetMessage: {
    marginTop: 10,
    color: Colors.darkBlue,
    padding: "13px 15px",
    borderRadius: 6,
    backgroundColor: Colors.veryLightMain,
  },
});

class PasswordForget extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { email: "" };
  }

  onChangeEmail = event => {
    this.setState({ email: event.target.value });
  };

  onSubmit = () => {
    const { email } = this.state;
    this.props.resetPasswordRequest({ email });
  };

  render() {
    const { error, requesting, passwordResetMessage, classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <Snackbar />
        <div className={classes.main}>
          <LogoDiv />
          <div className={classes.paper}>
            <Typography className={classes.title}>
              {i18n.t("password-reset")}
            </Typography>
            <div className={classes.form}>
              <Typography className={classes.explanation}>
                {i18n.t("password-reset-explanation")}
              </Typography>
              <TextField
                id="outlined-multiline-flexible"
                label={i18n.t("your-email")}
                fullWidth
                autoFocus
                inputProps={{
                  autoCapitalize: "none",
                }}
                autoComplete="email"
                value={this.state.email}
                onChange={this.onChangeEmail}
                className={classes.textField}
                margin="normal"
                variant="outlined"
              />
              {requesting && <Loader size={30} />}
              {!!error && (
                <Typography className={classes.errorText}>{error}</Typography>
              )}
              {passwordResetMessage && (
                <Typography className={classes.resetMessage}>
                  {passwordResetMessage}
                </Typography>
              )}
              <FlatButton
                fullWidth
                title={i18n.t("reset-password-button")}
                onClick={this.onSubmit}
                className={classes.submitButton}
              />
            </div>
          </div>
          <div className={classes.noAccountDiv}>
            <Link to="/login" className={classes.newAccountText}>
              <Typography className={classes.newAccountText}>
                {i18n.t("got-an-account")}
              </Typography>
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

PasswordForget.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  passwordResetMessage: state.auth.passwordResetMessage,
  requesting: state.auth.requesting,
  error: state.auth.error,
});

export default connect(mapStateToProps, { authorize, resetPasswordRequest })(
  withStyles(styles)(PasswordForget),
);
