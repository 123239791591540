import React from "react";
import withStyles from '@mui/styles/withStyles';
import Colors from "../../../constants/Colors";
import { Typography } from "@mui/material";

const styles = theme => ({
  main: {
    marginLeft: 15,
    cursor: "pointer",
    padding: "5px 15px",
    border: "dashed 1px",
    borderColor: Colors.grey,
    borderRadius: 20,
    transition: "all 0.2s",
    color: Colors.grey,
    "&:hover": {
      color: "black",
      borderColor: "black",
    },
  },
  title: {
    fontSize: 14,
    color: "inherit",
    fontWeight: "inherit",
  },
  selected: {
    color: "#fff",
    borderColor: "transparent",
    border: "solid 1px",
    backgroundColor: Colors.pureMain,
    "&:hover": {
      color: "#fff",
      borderColor: "transparent",
    },
  },
  textSelected: {
    color: "#fff",
    fontWeight: "bold",
  },
});

const FilterTag = ({ classes, title, isSelected, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`${classes.main} ${isSelected && classes.selected}`}
    >
      <Typography className={`${classes.title}`}>{title}</Typography>
    </div>
  );
};

export default withStyles(styles)(FilterTag);
