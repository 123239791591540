import React, { PureComponent } from "react";
import { connect } from "react-redux";

import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Switch from "react-switch";
import debounce from "lodash/debounce";

import socket from "services/socket";

import {
  createAdminStepRequest,
  modifyAdminStepRequest,
  changeStepSelected,
  changeField,
  createStepRequest,
  modifyStepRequest,
  deleteAdminStepRequest,
  deleteStepRequest,
  addStep,
  removeStep,
  duplicateStep,
} from "../../../services/createTrack/actions";

import Colors from "../../../constants/Colors";
import i18n from "../../../i18n";
import UnderlineTitle from "./UnderlineTitle";
import "./style.css";
import {
  getSelectedClientId,
  getUserId,
  getUserClientId,
  getIsSuperAdmin,
  getIsModifyForbidden,
} from "../../../services/user/selectors";
import {
  getAppLanguage,
  getIsMyMobileConnected,
} from "../../../services/general/selectors";
import EditBar from "./EditBar";
import Language from "./Language";
import CommentsModal from "./CommentsModal";
import SkillInput from "../Skills/SkillInput";
import DeleteConfirmModal from "components/DeleteConfirmModal";
import TopBar from "scenes/CreateTrack/CreateActionTextStep/TopBar";
import {
  getIsEnterpriseAccount,
  getIsSuperAdminClientSelected,
} from "services/client/selectors";
import { Button, TextField } from "@mui/material";
import FolderInput from "scenes/CreateTrack/Folders/FolderInput";
import {
  sendAdminConnectionSocket,
  openSnackMessage,
} from "services/general/actions";
import {
  getNumberOfAdminsConnectedOnStep,
  getStepSelected,
} from "services/createTrack/selectors";
import Banner from "components/Banner";
import StepSettings from "./StepSettings/StepSettings";
import SettingsToggle from "./StepSettings/SettingsToggle";
import PreviewSide from "./PreviewSide";
import { fetchApi } from "services/api";
import FlatButton from "components/FlatButton";

const styles = theme => ({
  main: {
    width: "100%",
    height: "100%",
    flexDirection: "column",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  categoryTitle: {
    fontFamily: "Montserrat",
    fontSize: "1.2em",
    fontWeight: "bold",
    marginBottom: 10,
  },
  bottomDiv: {
    overflowX: "hidden",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  emptyBottomDiv: {
    marginTop: 60,
    marginBottom: 20,
    height: 1,
    width: 1,
    backgroundColor: "transparent",
  },
  topDiv: {
    marginBottom: 15,
    flexDirection: "row",
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  rightDiv: {
    width: "100%",
    overflowY: "scroll",
    overflowX: "hidden",
    display: "flex",
    flex: 2,
    flexDirection: "column",
    height: "fit-content",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  isPremiumDiv: {
    marginTop: 20,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  booleanText: {
    fontSize: "1em",
    flex: 1,
    maxWidth: "100%",
    marginRight: 25,
  },
  textDiv: {
    display: "flex",
    width: "100%",
    paddingTop: 15,
    paddingLeft: 25,
    paddingRight: 40,
    flexDirection: "column",
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  noOverflow: {
    height: "auto",
  },
  titleInput: {
    fontWeight: "bold",
    fontFamily: "Montserrat",
    color: Colors.darkBlue,
    fontSize: 28,
  },
  inputMultiline: {
    padding: "inherit",
  },
  inputDisable: {
    padding: "12px 0px 14px",
  },
  divSelectInput: {
    marginBottom: 25,
  },
  banner: {},
  languageDiv: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  translateButton: {
    marginLeft: 5,
    marginTop: 8,
    height: 35,
    padding: "5px 10px",
  },
});

class CreateActionTextStep extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDeleteModalOpen: false,
      isCommentsVisible: false,
      isPreviewVisible: true,
      areSettingsVisible: false,
      isTranslationRequesting: false,
    };

    this.modifyStep = debounce(this.modifyStep, 350);
  }

  onOpenCommentsModal = () => {
    this.setState({ isCommentsVisible: true });
  };

  onCloseCommentsModal = () => {
    this.setState({ isCommentsVisible: false });
  };

  scrollToTop = () => {
    setTimeout(() => {
      const rightDiv = document.getElementById("rightDiv");
      if (rightDiv) {
        rightDiv.scrollBy({ top: -8000, behavior: "smooth" });
      }
    }, 10);
  };

  scrollToId = id => () => {
    setTimeout(() => {
      const divToScroll = document.getElementById(id);
      if (divToScroll) {
        divToScroll.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  handlePreview = () => {
    this.setState({ isPreviewVisible: !this.state.isPreviewVisible });
  };

  onChangeSkills = skills => {
    const { stepId } = this.props;
    if (stepId) {
      this.props.changeStepSelected({ skills });
      this.modifyStep({ skills });
    } else {
      this.props.changeStepSelected({ skills });
    }
  };

  onChangeFolders = folders => {
    const { stepId } = this.props;
    if (stepId) {
      this.props.changeStepSelected({ folders });
      this.modifyStep({ folders });
    } else {
      this.props.changeStepSelected({ folders });
    }
  };

  onChangeTitle = e => {
    const title = { ...this.props.title };

    title[this.props.languageSelected] = e.target.value;
    this.props.changeStepSelected({ title });
    this.modifyStep({ title });
  };

  onChangeBoolean = param => value => {
    this.props.changeStepSelected({ [param]: value });
  };

  onChangeSettings = setting => value => {
    const { stepId } = this.props;
    const settings = { ...this.props.settings, [setting]: value };
    this.props.changeStepSelected({ settings });
    if (stepId) {
      this.modifyStep({ settings });
    }
  };

  isButtonDisabled = () => {
    const { content = {}, languageSelected, skills = [] } = this.props;
    if (!content || !content.title) return true;
    const currentTitle = content?.title[languageSelected];
    return (
      !currentTitle ||
      currentTitle === '<p class="md-block-unstyled"><br/></p>' ||
      skills.length < 1
    );
  };

  openDeleteModal = () => {
    this.setState({ isDeleteModalOpen: true });
  };

  closeDeleteModal = () => {
    this.setState({ isDeleteModalOpen: false });
  };

  onClickDelete = () => {
    const { clientId, stepId, isSuperAdmin, isPrivate } = this.props;

    if (stepId) {
      if (!isPrivate) {
        if (isSuperAdmin) {
          this.props.deleteAdminStepRequest({
            ...this.props.stepSelected,
            stepId,
            clientId,
          });
        }
      } else {
        this.props.deleteStepRequest({
          ...this.props.stepSelected,
          stepId,
          clientId,
        });
      }
    } else {
      this.props.removeStep(stepId);
    }
    this.setState({ isDeleteModalOpen: false });
  };

  modifyStep = params => {
    const {
      clientId,
      stepId,
      isSuperAdmin,
      isPrivate,
      selectedClientId,
      isSuperAdminClientSelected,
    } = this.props;
    if (!isPrivate) {
      if (isSuperAdmin && isSuperAdminClientSelected) {
        this.props.modifyAdminStepRequest({
          ...this.props.stepSelected,
          stepId,
          clientId,
        });
      }
    } else if (isSuperAdmin) {
      // we create a step for the client selected
      this.props.modifyStepRequest({
        ...params,
        clientId: selectedClientId,
        stepId,
      });
    } else {
      this.props.modifyStepRequest({
        ...params,
        clientId,
        stepId,
      });
    }
  };

  createStep = () => {
    const {
      clientId,
      stepId,
      isSuperAdmin,
      selectedClientId,
      trackId,
      isSuperAdminClientSelected,
    } = this.props;
    if (isSuperAdmin && isSuperAdminClientSelected) {
      this.props.createAdminStepRequest({
        ...this.props.stepSelected,
        stepId,
        clientId,
        title: {},
      });
    } else if (isSuperAdmin) {
      // we create a step for the client selected
      const payload = {
        ...this.props.stepSelected,
        clientId: selectedClientId,
        stepId,
      };
      if (trackId) {
        payload.origin = trackId;
      }
      this.props.createStepRequest(payload);
    } else {
      const payload = {
        ...this.props.stepSelected,
        clientId,
        stepId,
      };
      if (trackId) {
        payload.origin = trackId;
      }
      this.props.createStepRequest(payload);
    }
  };

  onClickSubmit = () => {
    const { stepId } = this.props;

    if (stepId) {
      this.modifyStep();
    } else {
      this.createStep();
    }
  };

  onClickRemove = () => {
    const { stepId } = this.props;
    this.props.removeStep(stepId);
  };

  onClickDuplicate = () => {
    const { stepSelected } = this.props;

    this.props.duplicateStep(stepSelected);
  };

  onSelectLanguage = languageSelected => () => {
    this.props.changeField({ languageSelected });
    if (this.props.isMyMobileConnected) {
      socket.emit("mobile-connection-step-change", {
        userId: this.props.userId,
        step: { ...this.props.stepSelected, language: languageSelected },
      });
    }
  };

  componentDidUpdate = prevProps => {
    if (prevProps.stepSelectedId !== this.props.stepSelectedId) {
      this.scrollToTop();
    }
  };

  connectMobileApp = () => {
    socket.emit("mobile-connection-start", {
      step: {
        ...this.props.stepSelected,
        language: this.props.languageSelected,
        category: this.props.category,
      },
      userId: this.props.userId,
    });
  };

  onFocusTitle = () => {
    const {
      userId,
      isSuperAdmin,
      selectedClientId,
      clientId,
      isLibrary,
      trackId,
      stepId,
    } = this.props;

    sendAdminConnectionSocket({
      userId,
      clientId: isSuperAdmin ? selectedClientId : clientId,
      trackId: isLibrary ? "library" : trackId,
      stepId,
      contentSelected: `title`,
    });
  };

  handleToggleSettings = () => {
    this.setState({ areSettingsVisible: !this.state.areSettingsVisible });
  };

  scrollToSkillInput = () => {
    this.scrollToId("skills");
  };

  scrollToFolderInput = () => {
    this.scrollToId("folders");
  };

  sendTradReq = async () => {
    const { content, title } = this.props;

    try {
      this.setState({ isTranslationRequesting: true });
      const translations = await fetchApi(
        "/v1/steps/translate",
        {
          text: [
            this.props.title?.fr,
            this.props.content?.title?.fr,
            this.props.content?.why?.fr,
            this.props.content?.how?.fr,
          ],
          target_lang: "en-GB",
        },
        "post",
      );

      const newStep = {
        title: { ...title, en: translations?.[0]?.text },
        contentTitle: {
          ...content?.title,
          en: translations?.[1]?.text,
        },
        contentWhy: {
          ...content?.why,
          en: translations?.[2]?.text,
        },
        contentHow: {
          ...content?.how,
          en: translations?.[3]?.text,
        },
      };

      this.modifyStep(newStep);
      this.props.changeStepSelected({
        title: newStep.title,
        content: {
          title: newStep.contentTitle,
          how: newStep.contentHow,
          why: newStep.contentWhy,
        },
      });
      this.props.openSnackMessage({
        snackMessage: i18n.t("create-track-challenge-translated-success"),
      });
      this.onSelectLanguage("en")();
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isTranslationRequesting: false });
    }
  };

  render() {
    const {
      classes,
      stepPreview,
      languageSelected,
      isDisabled,
      isLibrary,
      stepSelectedIndex,
      stepPreviewIndex,
      stepRequesting,
      totalDone = 0,
      views = 0,
      rating = 0,
      comments = [],
      stepId,
      content = { title: {} },
      skills = [],
      title = {},
      folders = [],
      numberOfAdminsConnectedOnStep,
      settings,
      isModifyForbidden,
    } = this.props;

    return (
      <div className={`${classes.main} ${stepPreview && classes.noOverflow}`}>
        {isLibrary && this.state.isCommentsVisible && (
          <CommentsModal
            comments={comments}
            isVisible={this.state.isCommentsVisible}
            onClose={this.onCloseCommentsModal}
          />
        )}
        {this.state.isDeleteModalOpen && (
          <DeleteConfirmModal
            onClose={this.closeDeleteModal}
            isVisible={this.state.isDeleteModalOpen}
            title={i18n.t("delete-confirmation-modal-title")}
            onClickConfirm={this.onClickDelete}
          />
        )}
        {!stepPreview && isLibrary && (
          <TopBar
            totalDone={totalDone}
            comments={comments}
            views={views}
            isLibrary={isLibrary}
            rating={rating}
            onOpenCommentsModal={this.onOpenCommentsModal}
          />
        )}
        {!isModifyForbidden && !stepPreview && (
          <EditBar
            isPreviewVisible={this.state.isPreviewVisible}
            handlePreview={this.handlePreview}
            saving={stepRequesting}
            stepId={stepId}
            isLibrary={isLibrary}
            isEditable={!isDisabled}
            requesting={stepRequesting}
            onClickRemove={this.onClickRemove}
            onClickDuplicate={this.onClickDuplicate}
            onClickDelete={this.openDeleteModal}
            isSaveButtonDisabled={this.isButtonDisabled()}
            onClickSave={this.onClickSubmit}
            saveButtonTitle={
              stepId
                ? i18n.t("modify-action-submit")
                : i18n.t("create-action-submit")
            }
          />
        )}
        {numberOfAdminsConnectedOnStep > 1 && (
          <Banner
            className={classes.banner}
            title={i18n.t("multiple-admin-connected-warning")}
          />
        )}
        <div className={classes.bottomDiv}>
          <div className={classes.rightDiv} id="rightDiv">
            <div className={classes.textDiv}>
              <div className={classes.topDiv}>
                <div className={classes.languageDiv}>
                  <Language
                    language={languageSelected}
                    onSelect={this.onSelectLanguage}
                  />
                  {!isModifyForbidden && !stepPreview && (
                    <FlatButton
                      className={classes.translateButton}
                      size="small"
                      disabled={this.state.isTranslationRequesting}
                      onClick={this.sendTradReq}
                      requesting={this.state.isTranslationRequesting}
                      title={i18n.t("create-track-translate-in-english")}
                    />
                  )}
                </div>
                {!isModifyForbidden && !isDisabled && (
                  <SettingsToggle
                    onClick={this.handleToggleSettings}
                    isOpen={this.state.areSettingsVisible}
                  />
                )}
              </div>
              {!isDisabled && this.state.areSettingsVisible && (
                <StepSettings
                  settings={settings}
                  language={languageSelected}
                  onChangeSettings={this.onChangeSettings}
                />
              )}
              {!isDisabled ? (
                <TextField
                  variant="standard"
                  disabled={isDisabled}
                  onFocus={this.onFocusTitle}
                  autoFocus
                  fullWidth
                  multiline
                  title={i18n.t("create-action-title", {
                    lng: languageSelected,
                  })}
                  placeholder={i18n.t("challenge-title", {
                    lng: languageSelected,
                  })}
                  value={
                    title && title[languageSelected]
                      ? title[languageSelected]
                      : ""
                  }
                  onChange={this.onChangeTitle}
                  margin="normal"
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.titleInput,
                      root: classes.titleInput,
                      inputMultiline: classes.inputMultiline,
                    },
                  }}
                />
              ) : (
                title &&
                title[languageSelected] && (
                  <Typography
                    className={`${classes.titleInput} ${classes.inputDisable}`}
                  >
                    {title[languageSelected]}
                  </Typography>
                )
              )}
              <UnderlineTitle
                stepPreviewIndex={stepPreviewIndex}
                stepSelectedIndex={stepSelectedIndex}
                languageSelected={languageSelected}
                isDisabled={isDisabled}
                title={i18n.t("Challenge", {
                  lng: languageSelected,
                })}
                placeholder={i18n.t("create-action-title-placeholder", {
                  lng: languageSelected,
                })}
                value={content?.title?.[languageSelected]}
                contentObjectKey={"title"}
                contentKey={"content.title"}
                modifyStep={this.modifyStep}
                bottomColor={Colors.lightMain}
              />
              <UnderlineTitle
                contentKey={"content.why"}
                stepPreviewIndex={stepPreviewIndex}
                stepSelectedIndex={stepSelectedIndex}
                languageSelected={languageSelected}
                isDisabled={isDisabled}
                placeholder={i18n.t("create-action-why-placeholder", {
                  lng: languageSelected,
                })}
                title={i18n.t("create-action-why", { lng: languageSelected })}
                bottomColor={Colors.lightPink}
                contentObjectKey={"why"}
                value={content?.why?.[languageSelected]}
                modifyStep={this.modifyStep}
              />
              <UnderlineTitle
                contentKey={"content.how"}
                modifyStep={this.modifyStep}
                contentObjectKey={"how"}
                stepPreviewIndex={stepPreviewIndex}
                stepSelectedIndex={stepSelectedIndex}
                languageSelected={languageSelected}
                isDisabled={isDisabled}
                placeholder={i18n.t("create-action-how-placeholder", {
                  lng: languageSelected,
                })}
                title={i18n.t("create-action-how", { lng: languageSelected })}
                bottomColor={Colors.lightOrange}
                value={content?.how?.[languageSelected]}
              />
              <div className={classes.divSelectInput} id="skills">
                <Typography className={classes.categoryTitle}>
                  {i18n.t("tags", { lng: languageSelected })}
                </Typography>
                <SkillInput
                  language={languageSelected}
                  onOpen={this.scrollToSkillInput}
                  skills={skills}
                  updateStep={this.onChangeSkills}
                  isDisabled={isDisabled}
                />
              </div>
              <div className={classes.divSelectInput} id={"folders"}>
                <Typography className={classes.categoryTitle}>
                  {i18n.t("folders", { lng: languageSelected })}
                </Typography>
                <FolderInput
                  onOpen={this.scrollToFolderInput}
                  folders={folders}
                  updateStep={this.onChangeFolders}
                  isDisabled={isDisabled}
                />
              </div>
              {this.props.isSuperAdmin && (
                <div className={classes.adminDiv}>
                  <div className={classes.isPremiumDiv}>
                    <Typography className={`${classes.booleanText}`}>
                      {i18n.t("turn-step-premium", {
                        lng: languageSelected,
                      })}
                    </Typography>
                    <Switch
                      onChange={this.onChangeBoolean("isPremium")}
                      checked={this.props.isPremium}
                    />
                  </div>
                </div>
              )}
              <div className={classes.emptyBottomDiv} />
            </div>
          </div>
          {!stepPreview && this.state.isPreviewVisible && (
            <PreviewSide
              hasBorder={true}
              isPreviewVisible={this.state.isPreviewVisible}
              onClickConnectMobile={this.connectMobileApp}
              languageSelected={languageSelected}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const language = getAppLanguage(state);
  const userId = getUserId(state);
  const clientId = getUserClientId(state);
  const selectedClientId = getSelectedClientId(state);
  const isEnterpriseAccount = getIsEnterpriseAccount(state);
  const {
    stepSelectedIndex,
    languageSelected,
    stepLoading,
    stepRequesting,
    allObjectives,
    allCompetencies,
    isContentEditable = true,
    isTemplate,
  } = state.createTrack;
  const trackId = state.createTrack._id;
  const {
    stepPreview,
    stepPreviewIndex,
    isLibrary = false,
    isStepPreviewEditable = false,
  } = ownProps;

  let stepSelected = {};
  if (stepPreview && !isStepPreviewEditable) {
    stepSelected = stepPreview;
  } else {
    stepSelected = getStepSelected(state);
  }

  const isSuperAdmin = getIsSuperAdmin(state);
  const isSuperAdminClientSelected = getIsSuperAdminClientSelected(state);
  const isMyMobileConnected = getIsMyMobileConnected(state);
  const isModifyForbidden = getIsModifyForbidden(state);

  const {
    content,
    isPrivate,
    isPremium,
    category,
    tags,
    skills,
    competencies,
    objectives,
    expertId,
    rating = 0,
    totalDone = 0,
    views = 0,
    comments,
    creating = false,
    title,
    folders,
    settings,
  } = stepSelected;

  const isStepEditable =
    isContentEditable ||
    isStepPreviewEditable ||
    (stepSelected.origin === trackId && !isContentEditable) ||
    isLibrary ||
    creating ||
    isTemplate;

  const isDisabled =
    (stepPreview && !isStepPreviewEditable) ||
    !isStepEditable ||
    (!isPrivate && !isSuperAdmin) ||
    isModifyForbidden;
  const stepId = stepSelected.step || stepSelected._id;

  return {
    numberOfAdminsConnectedOnStep: getNumberOfAdminsConnectedOnStep(state),
    isLibrary,
    isPrivate,
    userId,
    trackId,
    comments,
    rating,
    views,
    totalDone,
    category,
    stepLoading,
    stepRequesting,
    stepPreview,
    isSuperAdmin,
    isDisabled,
    stepId,
    isPremium,
    content,
    clientId,
    language,
    stepSelected,
    stepSelectedIndex,
    stepPreviewIndex,
    languageSelected,
    isMyMobileConnected,
    isSuperAdminClientSelected,
    isEnterpriseAccount,
    selectedClientId,
    tags,
    skills,
    title,
    competencies,
    objectives,
    expertId,
    allObjectives,
    allCompetencies,
    folders,
    settings,
    isModifyForbidden,
  };
};

export default connect(mapStateToProps, {
  changeStepSelected,
  changeField,
  createStepRequest,
  modifyStepRequest,
  modifyAdminStepRequest,
  createAdminStepRequest,
  deleteAdminStepRequest,
  deleteStepRequest,
  addStep,
  removeStep,
  openSnackMessage,
  duplicateStep,
})(withStyles(styles)(CreateActionTextStep));
