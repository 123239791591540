import React from "react";

const DotsIcon = ({
  width = 24,
  height = 24,
  color = "#007EFC",
  className = "",
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
    version="1.1"
  >
    <g clip-path="url(#clip0_503_1427)">
      <path
        d="M5.537 13.5618C5.24573 13.2697 5.1001 12.9157 5.1001 12.5C5.1001 12.0843 5.24573 11.7303 5.537 11.4382C5.82827 11.1461 6.18116 11 6.59565 11C7.01015 11 7.36304 11.1461 7.65431 11.4382C7.94558 11.7303 8.09121 12.0843 8.09121 12.5C8.09121 12.9157 7.94558 13.2697 7.65431 13.5618C7.36304 13.8539 7.01015 14 6.59565 14C6.18116 14 5.82827 13.8539 5.537 13.5618Z"
        fill="#848694"
      />
      <path
        d="M10.5414 13.5618C10.2502 13.2697 10.1045 12.9157 10.1045 12.5C10.1045 12.0843 10.2502 11.7303 10.5414 11.4382C10.8327 11.1461 11.1856 11 11.6001 11C12.0146 11 12.3675 11.1461 12.6588 11.4382C12.95 11.7303 13.0957 12.0843 13.0957 12.5C13.0957 12.9157 12.95 13.2697 12.6588 13.5618C12.3675 13.8539 12.0146 14 11.6001 14C11.1856 14 10.8327 13.8539 10.5414 13.5618Z"
        fill="#848694"
      />
      <path
        d="M15.5459 13.5618C15.2546 13.2697 15.109 12.9157 15.109 12.5C15.109 12.0843 15.2546 11.7303 15.5459 11.4382C15.8372 11.1461 16.19 11 16.6045 11C17.019 11 17.3719 11.1461 17.6632 11.4382C17.9545 11.7303 18.1001 12.0843 18.1001 12.5C18.1001 12.9157 17.9545 13.2697 17.6632 13.5618C17.3719 13.8539 17.019 14 16.6045 14C16.19 14 15.8372 13.8539 15.5459 13.5618Z"
        fill="#848694"
      />
    </g>
    <defs>
      <clipPath id="clip0_503_1427">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default DotsIcon;
