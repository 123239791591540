import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { push } from "connected-react-router";

import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";

import Colors from "../../constants/Colors";
import queryString from "query-string";
import { getRouterQuery } from "services/general/selectors";

import { logout } from "../../services/auth/reducer";
import QrCode from "scenes/Campaigns/SeeUserModal/QrCode";
import LogoDiv from "scenes/Signup/LogoDiv";
import i18n from "i18n";
import { fetchApi } from "services/api";

import EmailIllustration from "../../resources/EmailIllustration.svg";
const AppleStoreWhiteBadge = require("../../resources/AppleStoreWhiteBadge.png");
const GooglePlayWhiteBadge = require("../../resources/GooglePlayWhiteBadge.png");

const URL = `/v1/auth/dynamic-link/generate`;
const DEFAULT_LINK =
  "https://apps.apple.com/us/app/microdoing/id1479333825?l=fr&ls=1";

const styles = theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "100vh",
    width: "100vw",
    backgroundColor: "#fff",
  },
  middleDiv: {
    marginTop: "5vh",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  leftDiv: {
    marginRight: 100,
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  rightDiv: {
    marginTop: 60,
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    width: 450,
    backgroundColor: Colors.darkBlue,
    borderTopRightRadius: 12,
    borderTopLeftRadius: 12,
    paddingLeft: 100,
    paddingRight: 100,
    paddingTop: 60,
    paddingBottom: 60,
    height: "100%",
  },
  title: {
    width: 450,
    lineHeight: 1.2,
    // lineHeight: 1.35,
    color: Colors.darkBlue,
    fontFamily: "Montserrat",
    textAlign: "left",
    // color: "#fff",
    margin: 0,
    marginTop: 0,
    fontWeight: "bold",
    fontSize: 50,
    marginBottom: 50,
  },
  scanText: {
    textAlign: "left",
    fontFamily: "Montserrat",
    fontSize: 40,
    color: "#fff",
    fontWeight: "bold",
  },
  scanTextSubtitle: {
    textAlign: "left",
    fontFamily: "Montserrat",
    fontSize: 20,
    color: "#fff",
    fontWeight: "bold",
  },
  qrBox: {
    marginTop: 45,
    padding: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: 15,
    backgroundColor: "#fff",
    marginBottom: 50,
  },
  badge: {
    alignSelf: "center",
    objectFit: "contain",
    width: 220,
    height: 61,
    marginBottom: 20,
  },
  illustration: {
    height: 50,
    width: 50,
  },
});

const MobileRedirect = ({ token, classes }) => {
  const [link, setLink] = useState(DEFAULT_LINK);
  const dispatch = useDispatch();

  const generateDynamicLink = async () => {
    try {
      let params = queryString.parse(window.location.search);

      const newLink = await fetchApi(URL, params, "POST");
      setLink(newLink);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (token) {
      dispatch(logout());
    }

    generateDynamicLink();
  }, [token, dispatch]);

  // let params = queryString.parse(window.location.search);
  // console.log(this.props.location.search);
  // const { link } = params;

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.main}>
        <LogoDiv isWhite />
        <div className={classes.middleDiv}>
          <div className={classes.leftDiv}>
            <Typography className={classes.title}>
              {i18n.t("open-on-mobile", {
                interpolation: { escapeValue: false },
              })}
            </Typography>
            <img src={EmailIllustration} alt="from email illustration" />
          </div>
          <div className={classes.rightDiv}>
            <Typography className={classes.scanText}>
              {i18n.t("or-scan-qr-code-1")}
            </Typography>
            <Typography className={classes.scanTextSubtitle}>
              {i18n.t("or-scan-qr-code-2")}
            </Typography>
            <div className={classes.qrBox}>
              <QrCode link={link} />
            </div>
            <img
              src={AppleStoreWhiteBadge}
              className={classes.badge}
              alt="apple store badge"
            />
            <img
              src={GooglePlayWhiteBadge}
              className={classes.badge}
              alt="google play badge"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

MobileRedirect.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const { accessToken } = state.token;

  const routerQuery = getRouterQuery(state);
  return {
    token: accessToken,
    routerQuery,
  };
};

export default connect(mapStateToProps, { push, logout })(
  withStyles(styles)(MobileRedirect),
);
