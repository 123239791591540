import React from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography } from "@mui/material";
import { useState } from "react";
import i18n from "i18n";

const styles = theme => ({
  main: {
    cursor: "pointer",
    margin: 5,
    flex: 1,
    height: 150,
    marginBottom: 20,
    position: "relative",
  },
  fill: {
    width: "100%",
    background: "rgba(115, 157, 197, .3)",
    transition: "height .5s ease-in-out",
    borderRadius: 2,
    position: "absolute",
    bottom: 0,
    zIndex: 100,
  },
  bar: {
    position: "relative",
    background: "rgba(115, 157, 197, .1)",
    width: "100%",
    height: "100%",
    borderRadius: 4,
    zIndex: 1,
    top: 0,
  },
  answer: {
    position: "absolute",
    top: 10,
    width: "100%",
    textAlign: "center",
    fontSize: 13,
    fontWeight: 600,
    zIndex: 150,
  },
  tooltipBox: {
    transition: "opacity 0.2s",
    position: "absolute",
    color: "white",
    background: "#262627",
    borderRadius: 4,
    padding: "8px 12px",
    maxWidth: "none",
    whiteSpace: "nowrap",
    width: "auto",
    opacity: 0,
    pointerEvents: "none",
    zIndex: 300,
    top: -12,
    left: 25,
    transform: "translateY(-100%) translateX(-50%)",
  },
  tooltipArrow: {
    width: 4,
    height: 6,
    position: "absolute",
    borderStyle: "solid",
    left: 0,
    right: 0,
    margin: "auto",
    bottom: -6,
    borderWidth: "6px 4px 0 4px",
    borderColor: "#262627 transparent transparent transparent",
  },
  tooltip: {
    color: "#fff",
  },
  percentage: {
    color: "rgb(115, 157, 197)",
    paddingTop: 10,
    textAlign: "center",
  },
});

const QuestionBar = ({ classes, index, percentage = 0, answers = 0 }) => {
  const [isHovered, setHover] = useState(false);
  return (
    <div
      className={classes.main}
      onMouseLeave={() => setHover(false)}
      onMouseEnter={() => setHover(true)}
    >
      <div
        className={classes.bar}
        style={{
          background: isHovered
            ? "rgba(115, 157, 197, .2)"
            : "rgba(115, 157, 197, .1)",
        }}
      >
        <div
          className={classes.tooltipBox}
          style={{ opacity: isHovered ? 1 : 0 }}
        >
          <div className={classes.tooltipArrow}></div>
          <Typography className={classes.tooltip}>
            {answers}{" "}
            {answers === 1
              ? i18n.t("Answer").toLowerCase()
              : i18n.t("Answers").toLowerCase()}
          </Typography>
        </div>
        <Typography className={classes.answer}>{index}</Typography>
        <div
          className={classes.fill}
          style={{ height: `${percentage}%` }}
        ></div>
      </div>
      <Typography className={classes.percentage}>{percentage}%</Typography>
    </div>
  );
};

export default withStyles(styles)(QuestionBar);
