import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import i18n from "i18n";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";

const useStyle = makeStyles({
  container: {
    width: "100%",
    "& .react-datepicker-wrapper": {
      width: "100%",
    },
  },
  selectedDate: {
    fontFamily: "Hind",
    fontSize: 16,
    "& .react-datepicker__day": {
      fontSize: 14,
    },
    "& .react-datepicker__day--selected": {
      backgroundColor: "rgba(0, 170, 255, 0.9)",
    },
    "& .react-datepicker__header": {
      backgroundColor: "#00042A",
    },
    "& .react-datepicker__current-month ": {
      color: "white",
    },
    "& .react-datepicker-time__header": {
      color: "white",
    },
    "& .react-datepicker__day-name": {
      color: "white",
      fontSize: 14,
    },
    "& .react-datepicker__input-time-container": {
      fontSize: 14,
    },
    "& .react-datepicker__time-list-item": {
      fontSize: 14,
    },
    "& .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected": {
      fontSize: 14,
      backgroundColor: "rgba(0, 170, 255, 0.9)",
    },
  },
  focused: {
    borderColor: "rgba(0, 170, 255, 0.9)",
  },
});

export interface ICustomDatePickerProps {
  minDate?: Date;
  maxDate?: Date;
  dateSelected: Date;
  handleChangeDateSelected: (date: Date) => void;
}

const minutes = 15;

export default function CustomDatePicker({
  minDate,
  maxDate,
  dateSelected,
  handleChangeDateSelected,
}: ICustomDatePickerProps) {
  const [isFocused, setIsFocused] = useState(false);

  const roundToNearestMinutes = (date = new Date()) => {
    const ms = 1000 * 60 * minutes;

    return new Date(Math.ceil(date.getTime() / ms) * ms);
  };

  useEffect(() => {
    handleChangeDateSelected(roundToNearestMinutes(dateSelected));
  }, []);

  const classes = useStyle();

  return (
    <div className={classes.container}>
      <DatePicker
        selected={roundToNearestMinutes(dateSelected)}
        onChange={handleChangeDateSelected}
        onFocus={() => setIsFocused(!isFocused)}
        onClickOutside={() => setIsFocused(!isFocused)}
        calendarClassName={classes.selectedDate}
        customInput={
          <TextField
            focused={isFocused}
            fullWidth
            label={i18n.t("date-picker")}
            margin="normal"
            variant="outlined"
          />
        }
        shouldCloseOnSelect={false}
        minDate={minDate}
        maxDate={maxDate}
        showTimeSelect
        timeIntervals={minutes}
        timeCaption={i18n.t("time-header-picker")}
        timeFormat="HH:mm"
        dateFormat="MMMM d, yyyy HH:mm"
      />
    </div>
  );
}
