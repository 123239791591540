import React, { PureComponent } from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import Tab from "./Tab";
import MyUsersList from "./MyUsersList";
import { getIsCoachingAccount } from "services/client/selectors";
import AddUsersToCampaignModal from "scenes/Campaigns/AddUsersToCampaignModal";
import { changeAddUsersModal } from "services/campaign/actions";
import MyUsersPending from "./MyUsersPending";

const styles = theme => ({
  main: {
    flex: 1,
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 100,
  },
});

class MyUsers extends PureComponent {
  state = {
    tabSelected: 0,
  };

  onChangeTab = tabSelected => () => {
    this.setState({ tabSelected });
  };

  onCloseAddUsersModal = () => {
    this.props.changeAddUsersModal({
      isAddUsersModalVisible: false,
      hasPublishCampaignAnimation: false,
    });
  };

  render() {
    const { classes, isAddUsersModalVisible } = this.props;

    return (
      <div className={classes.main}>
        <Tab
          tabSelected={this.state.tabSelected}
          onChangeTab={this.onChangeTab}
        />
        <AddUsersToCampaignModal
          isVisible={isAddUsersModalVisible}
          onClose={this.onCloseAddUsersModal}
        />
        {this.state.tabSelected === 0 && <MyUsersList />}
        {this.state.tabSelected === 1 && <MyUsersPending />}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { plan } = state.client;
  const { role } = state.user;
  return {
    isCoachingAccount: getIsCoachingAccount(state),
    isAddUsersModalVisible: state.campaign.isAddUsersModalVisible,
    role,
    plan,
  };
};

export default connect(mapStateToProps, {
  changeAddUsersModal,
})(withStyles(styles)(MyUsers));
