import React from "react";

const ArrowBackIcon = ({ sizePercentage, color, className }) => (
  <svg
    className={className}
    width={sizePercentage * 26}
    height={sizePercentage * 17}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 26 17"
    version="1.1"
  >
    <path
      d="M22.7564 1.71022V6.99642H5.35157L10.0827 2.25206L8.21934 0.388672L0.290039 8.31797L8.21934 16.2473L10.0827 14.3707L5.35157 9.63952H25.3995V1.71022H22.7564Z"
      fill={color}
    />
  </svg>
);

ArrowBackIcon.defaultProps = {
  sizePercentage: 1,
  color: "#000000",
  className: "",
};

export default ArrowBackIcon;
