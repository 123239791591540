import React, { Component } from "react";
import { connect } from "react-redux";

import moment from "moment";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import Colors from "../../constants/Colors";
import AddIcon from "@mui/icons-material/Add";
import MoreIcon from "@mui/icons-material/MoreHoriz";
import { getAllPollsRequest, changeField } from "../../services/poll/actions";
import { getManagerTeamRequest } from "../../services/manager/actions";
import "moment/locale/fr";
import CreationModal from "./CreationModal";
import SeePollModal from "./SeePollModal";
import { getUserId, getSelectedClientId } from "../../services/user/selectors";
import i18n from "../../i18n";
moment.locale("fr");

const styles = theme => ({
  main: {
    width: "90%",
    marginTop: 15,
    flexDirection: "row",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  root: {
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
    marginBottom: 15,
  },
  leftSide: {
    width: "45%",
    minHeight: 700,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    display: "flex",
  },
  rightSide: {
    width: "45%",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    display: "flex",
    position: "relative",
    minHeight: 800,
    // flex: 1
  },
  container: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: 15,
    flex: 1,
    flexDirection: "row",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  saveButton: {
    marginTop: 15,
  },
  title: {
    fontWeight: 500,
    marginTop: 15,
    marginBottom: 15,
  },
  appTitleDiv: {
    width: 280,
    backgroundColor: Colors.mediumGreen,
    padding: 15,
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: 8,
    wordWrap: "break-word",
    // wordWrap: "break-word"
    // maxWidth: 280
  },
  appText: {
    minWidth: "1%",
    textAlign: "center",
    wordWrap: "break-word",
  },
  separator: {
    height: 1,
    marginTop: 5,
    marginBottom: 10,
    width: "45%",
    backgroundColor: Colors.mediumGrey,
  },
  separatorContainer: {
    marginTop: 15,
    width: 280,
    display: "flex",
    flexWrap: "wrap",
    wordBreak: "break-all",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  content: {
    width: 280,
    marginTop: 80,
    wordWrap: "break-word",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  paper: {
    flex: 1,
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  activateDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  bold: {
    fontWeight: "bold",
  },
  notifText: {
    marginTop: 10,
    marginBottom: 10,
  },
  poll: {
    // flex: "1 0 22%",
    width: "calc(25% - 20px)",
    boxShadow: "0 2px 4px rgba(0,0,0,.08)",
    display: "flex",
    marginRight: 20,
    marginBottom: 20,
    height: 200,
    backgroundColor: "#fff",
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    cursor: "pointer",
    animation: "FadeIn 0.3s ease-in-out",
    transition: "all 0.2s ease-in",
    "&:hover": {
      boxShadow: "0 4px 22px rgba(0,0,0,.06)",
    },
  },
  createPoll: {
    width: "calc(25% - 20px)",
    height: 200,
    borderRadius: 8,
    backgroundColor: Colors.main,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    marginRight: 20,
    marginBottom: 20,
    transition: "0.2s",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
  createPollText: {
    color: "#fff",
  },
  bottomDiv: {
    width: "100%",
    borderTopWidth: 1,
    borderTop: "solid",
    borderTopColor: Colors.lightGrey,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  votesText: {
    marginLeft: 15,
    alignSelf: "left",
    backgroundColor: Colors.pureMain,
    borderRadius: 8,
    color: "#fff",
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 8,
    paddingRight: 8,
  },
  noVotes: {
    marginLeft: 15,
    color: Colors.grey,
  },
  topDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  pollTitle: {
    textAlign: "center",
    marginLeft: 10,
    marginRight: 10,
  },
});

class MyPolls extends Component {
  state = {
    expanded: null,
    labelWidth: 0,
    isCreationModalVisible: false,
    isSeePollModalVisible: false,
  };

  componentDidMount() {
    this.props.getAllPollsRequest(this.props.clientId);
  }

  onChangeDay = event => {
    const isoWeekday = Number(event.target.value);
    const reminderTime = moment(this.props.reminderTime).isoWeekday(isoWeekday);
    this.props.changeCoffeeSettings({ reminderTime });
  };

  onChangeTime = event => {
    const time = event.target.value;
    const minute = Number(time.split(":")[1]);
    const hour = Number(time.split(":")[0]);

    const reminderTime = moment(this.props.reminderTime)
      .minute(minute)
      .hour(hour);
    this.props.changeCoffeeSettings({ reminderTime });
  };

  onClickMore = () => {};

  seePollModal = pollSelectedIndex => () => {
    this.props.changeField({ pollSelectedIndex });
    this.setState({ isSeePollModalVisible: true });
  };

  displayPolls = () => {
    const { classes } = this.props;
    return this.props.polls.map((el, i) => {
      return (
        <div key={i} className={classes.poll} onClick={this.seePollModal(i)}>
          <div className={classes.topDiv}>
            <Typography className={classes.pollTitle}>{el.title}</Typography>
          </div>
          <div className={classes.bottomDiv}>
            {el.totalVotes > 0 ? (
              <Typography className={classes.votesText}>
                {i18n.t("answers", { answers: el.totalVotes })}
              </Typography>
            ) : (
              <p className={classes.noVotes}>{i18n.t("no-answer")}</p>
            )}
            <IconButton className={classes.moreButton} onClick={this.onClickMore} size="large">
              <MoreIcon />
            </IconButton>
          </div>
        </div>
      );
    });
  };

  onCloseCreationModal = () => {
    this.setState({ isCreationModalVisible: false });
  };

  onCloseSeePollModal = () => {
    this.setState({ isSeePollModalVisible: false });
  };

  displayCreationModal = () => {
    this.setState({ isCreationModalVisible: true });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.main}>
        <CreationModal
          isVisible={this.state.isCreationModalVisible}
          onClose={this.onCloseCreationModal}
        />
        <SeePollModal
          isVisible={this.state.isSeePollModalVisible}
          onClose={this.onCloseSeePollModal}
        />
        <div className={classes.paper}>
          {/* {this.props.polls.length < 1 && <LottieEmpty />} */}
          <div
            className={classes.createPoll}
            onClick={this.displayCreationModal}
          >
            <AddIcon style={{ color: "#fff" }} />
            <Typography className={classes.createPollText}>
              {i18n.t("new-poll")}
            </Typography>
          </div>
          {this.props.requesting ? <CircularProgress /> : this.displayPolls()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { requesting, polls } = state.poll;
  const userId = getUserId(state);
  const clientId = getSelectedClientId(state);
  return {
    token: state.auth.token,
    userId,
    requesting,
    clientId,
    polls,
  };
};

export default connect(mapStateToProps, {
  getAllPollsRequest,
  getManagerTeamRequest,
  changeField,
})(withStyles(styles)(MyPolls));
