import React from "react";
import { FileIcon as ReactFileIcon, defaultStyles } from "react-file-icon";

const iconStyles = {
  pdf: {
    color: "red",
    glyphColor: "#fff",
  },
  png: {
    color: "#8FC9F9",
    glyphColor: "#1765C0",
  },
  jpg: {
    color: "#8FC9F9",
    glyphColor: "#1765C0",
  },
  jpeg: {
    color: "#8FC9F9",
    glyphColor: "#1765C0",
  },
  webp: {
    color: "#8FC9F9",
    glyphColor: "#1765C0",
  },
  mp4: {
    color: "#2D8CFF",
    glyphColor: "#fff",
  },
};

interface Props {
  iconStyle?: string;
  fileType: string;
}

const FileIcon = ({ iconStyle, fileType }: Props) => {
  const style =
    { ...defaultStyles[fileType], ...iconStyles[fileType] } ||
    defaultStyles.docx;

  return <ReactFileIcon {...style} />;
};

export default FileIcon;
