import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";

import { authorize } from "../../services/auth/reducer";

import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import withStyles from '@mui/styles/withStyles';
import TextField from "@mui/material/TextField";
import FlatButton from "../../components/FlatButton";
import LogoDiv from "../Signup/LogoDiv";

import Colors from "../../constants/Colors";
import i18n from "../../i18n";
import sanitizeInput from "utils/sanitizeInput";

const styles = theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    minHeight: "100vh",
    height: "100%",
    width: "100vw",
    backgroundColor: Colors.darkBlue,
  },
  paper: {
    maxWidth: 425,
    marginRight: 25,
    marginLeft: 25,
    display: "flex",
    borderRadius: 10,
    background: "white",
    padding: 25,
    paddingBottom: 30,
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 20,
  },
  title: {
    lineHeight: 1.35,
    fontFamily: "Montserrat",
    textAlign: "center",
    fontWeight: "normal",
    color: Colors.darkBlue,
    margin: 0,
    marginTop: 0,
    fontSize: 18,
    textTransform: "none",
    letterSpacing: 0,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE11 issue.
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  loginError: {
    backgroundColor: "#FCE6E7",
    borderRadius: 8,
  },
  errorText: {
    color: "#e6525a",
    padding: "13px 15px",
    borderRadius: 6,
    backgroundColor: "rgba(230,82,90,0.15)",
  },
  textField: {
    marginTop: 20,
  },
  submitButton: {
    marginTop: 20,
    padding: 12,
  },
  noDecoration: {
    textDecoration: "none",
  },
  forgotPassword: {
    marginTop: 20,
    cursor: "pointer",
    textDecoration: "none",
    color: Colors.pureMain,
    fontSize: 17,
    lineHeight: 1.5,
    textAlign: "center",
    transition: "all 0.2s ease",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  newAccountText: {
    fontFamily: "Montserrat",
    color: Colors.white,
    fontSize: 16,
    textAlign: "center",
  },
  bold: {
    fontFamily: "Montserrat",
    marginLeft: 5,
    color: Colors.white,
    fontSize: 16,
    cursor: "pointer",
    fontWeight: "bold",
    textDecoration: "underline",
  },
  noAccountDiv: {
    paddingBottom: 50,
    maxWidth: 425,
    marginLeft: 25,
    marginRight: 25,
    // width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
});

class Login extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { email: "", password: "" };
    window.addEventListener("keydown", this.keyPress);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.keyPress);
  }

  onChangeEmail = event => {
    this.setState({ email: event.target.value });
  };

  onChangePassword = event => {
    this.setState({ password: event.target.value });
  };

  onSubmit = () => {
    const { email, password } = this.state;
    if (!email || !password) return;
    this.props.authorize(sanitizeInput(email), password);
  };

  keyPress = e => {
    if (e.keyCode === 13) {
      this.onSubmit();
      // put the login here
    }
  };

  render() {
    const { error, token, classes, clientId, requesting } = this.props;

    if (token && clientId) {
      return <Redirect to="/campaigns" />;
    }

    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.main}>
          <LogoDiv />
          <div className={classes.paper}>
            <div style={{ marginTop: 5, marginBottom: 15 }}>
              <Typography
                className={classes.title}
                style={{ fontWeight: "bold" }}
              >
                {i18n.t("login-1")}
              </Typography>
              <Typography className={classes.title}>
                {i18n.t("login-2")}
              </Typography>
              <Typography className={classes.title}>
                {i18n.t("login-3")}
              </Typography>
            </div>

            <div className={classes.form}>
              {error && <p className={classes.errorText}>{error}</p>}
              <TextField
                inputProps={{
                  autoCapitalize: "none",
                }}
                id="outlined-multiline-flexible"
                label={i18n.t("your-email")}
                fullWidth
                autoFocus
                autoComplete="email"
                // rowsMax="4"
                value={this.state.email}
                onChange={this.onChangeEmail}
                className={classes.textField}
                margin="normal"
                variant="outlined"
              />
              <TextField
                id="outlined-multiline-flexible"
                label={i18n.t("your-password")}
                fullWidth
                autoComplete="current-password"
                type="password"
                // rowsMax="4"
                value={this.state.password}
                onChange={this.onChangePassword}
                className={classes.textField}
                margin="normal"
                variant="outlined"
              />

              <FlatButton
                requesting={requesting}
                disabled={
                  !this.state.password || !this.state.email || requesting
                }
                fullWidth
                title={i18n.t("loginButton")}
                onClick={this.onSubmit}
                className={classes.submitButton}
              />
              <Link to="/password-forget/" className={classes.noDecoration}>
                <Typography className={classes.forgotPassword}>
                  {i18n.t("forgot-password")}
                </Typography>
              </Link>
            </div>
          </div>
          <Typography className={classes.noAccountDiv}>
            <Typography className={classes.newAccountText}>
              {i18n.t("do-not-have-account")}
              <a
                href="https://microdoing.com/demo"
                className={classes.newAccountText}
              >
                <span className={classes.bold}>{i18n.t("contact-us")}</span>
              </a>
            </Typography>
          </Typography>
        </div>
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  requesting: state.auth.requesting,
  token: state.token.accessToken,
  role: state.auth.role,
  error: state.auth.error,
  clientId: state.user.client,
});

export default connect(mapStateToProps, { authorize })(
  withStyles(styles)(Login),
);
