import React from "react";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Box from "components/Box";
import formateDoneDate from "utils/formatDoneDate";
import Colors from "constants/Colors";
import VideoIcon from "scenes/Campaigns/SeeUserModal/Coaching/Resources/NewResourceModal/icons/VideoIcon";
import PdfIcon from "scenes/Campaigns/SeeUserModal/Coaching/Resources/NewResourceModal/icons/PdfIcon";
import LinkIcon from "scenes/Campaigns/SeeUserModal/Coaching/Resources/NewResourceModal/icons/LinkIcon";

const useStyles = makeStyles(theme => ({
  main: {
    boxSizing: "border-box",
    width: "82%",
    paddingLeft: 10,
    paddingRight: 10,
  },
  box: {
    margin: 10,
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 10,
    paddingRight: 10,
    width: "90%",
    alignSelf: "center",
    alignItems: "flex-start",
  },
  topView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: 15,
  },
  title: {
    fontSize: 10,
    color: Colors.text,
    fontFamily: "Montserrat",
    fontWeight: "bold",
  },
  date: {
    marginLeft: 10,
    alignSelf: "center",
    width: "90%",
    color: "#A0A0A0",
    fontWeight: "bold",
    fontSize: 10,
    marginTop: 15,
  },
  bottomView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  boxIcon: {
    borderRadius: 8,
    backgroundColor: Colors.orange,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: 30,
    width: 30,
  },
  middleView: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    flex: 1,
    marginLeft: 10,
  },
  description: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 12,
    color: "#515151",
    overflow: "hidden",
    lineHeight: "1.5em" /* fallback */,
    maxHeight: "1.5em",
    wordBreak: "break-all",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 1,
  },
  link: {
    overflow: "hidden",
    lineHeight: "1.5em" /* fallback */,
    maxHeight: "1.5em",
    wordBreak: "break-all",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 1,
    fontSize: 10,
    color: "#C0C0C0",
  },
  notification: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: "#FB5353",
    marginRight: 10,
    marginLeft: 10,
  },
  a: {
    textDecoration: "none",
  },
}));

const icons = {
  videoLink: {
    icon: <VideoIcon width={15} color="#fff" />,
    backgroundColor: "#EC6A6A",
  },
  webLink: {
    icon: <LinkIcon color="#fff" width={15} />,
    backgroundColor: "#FCC21B",
  },
  pdf: {
    icon: <PdfIcon color="#fff" width={15} />,
    backgroundColor: "#F8935B",
  },
};

interface Props {
  url: string;
  isVisited?: boolean;
  resource: {
    title: string;
    link: string;
    type: string;
    date: Date;
    addedDate: Date;
  };
}

const ResourcePreviewBox = ({ isVisited = false, resource }: Props) => {
  const classes = useStyles();

  const { title, link, type, addedDate } = resource;
  const iconSettings = icons[type];
  const { backgroundColor = "red", icon = <LinkIcon /> } = iconSettings;

  return (
    <div className={classes.main}>
      <Typography className={classes.date}>
        {formateDoneDate(addedDate)}
      </Typography>
      <a className={classes.a} href={link} target="_blank" rel="noopener noreferrer">
        <Box className={classes.box}>
          <div className={classes.bottomView}>
            <div className={classes.boxIcon} style={{ backgroundColor }}>
              {icon}
            </div>
            <div className={classes.middleView}>
              <Typography className={classes.description}>{title}</Typography>
              <Typography className={classes.link}>{link}</Typography>
            </div>
          </div>
        </Box>
      </a>
    </div>
  );
};

export default ResourcePreviewBox;
