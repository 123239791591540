import React, { PureComponent } from "react";
import cheerio from "cheerio";

import MyCustomUploadAdapterPlugin from "./TextEditor/MyCustomUploadAdapterPlugin";

import { CKEditor } from "@ckeditor/ckeditor5-react";

import InlineEditor from "@ckeditor/ckeditor5-build-inline";

import Colors from "../../../constants/Colors";
import withStyles from '@mui/styles/withStyles';
import { debounce } from "lodash";

const styles = theme => ({
  textEditor: {
    color: "#3A4554",
    cursor: "text",
    fontSize: "1rem",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    lineHeight: "1.1875em",
    outline: "none",
    background: "transparent",
    borderColor: "rgba(0, 0, 0, 0.23)",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "4px",
    padding: "14px 14px",
    verticalAlign: "center",
    width: "100%",
    maxWidth: "40vw",
    transition: "all .2s ease",
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 1)",
    },
    "&:focus": {
      borderColor: Colors.main,
      borderWidth: 2,
    },
  },
  editorDiv: {
    width: "100%",
  },
  label: {
    position: "absolute",
    top: "16px",
    left: 0,
    fontSize: "16px",
    color: "#9098A9",
    fontWeight: 500,
    transformOrigin: "0 0",
    transition: "all .2s ease",
  },
  editor: {
    backgroundColor: "rgb(244, 246, 249)",
    borderRadius: 4,
    fontSize: 17,
    // lineHeight: "1.4em",
    fontFamily: "Avenir,Montserrat,Roboto,Helvetica,sans-serif",
    marginTop: 20,
    marginBottom: 20,
    zIndex: 200,
    width: "100%",
    color: "#01042a",
    // transition: "all .35s ease",
  },
  editorDisabled: {
    backgroundColor: "#fff",
  },
  editorFocus: {
    // transition: "all .35s ease",
    marginTop: 50,
    // height: 55,
  },
});

class EditableInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isFocus: false,
      uploadingImage: false,
    };

    this.updateData = debounce(this.updateData, 350);
  }

  updateData = data => {
    this.props.onChange(data);
  };

  onBlur = (event, editor) => {
    this.setState({ isFocus: false });
    this.props.onBlur();
  };

  render() {
    const { isFocus } = this.state;
    const {
      classes,
      className = "",
      placeholder,
      isDisabled,
      onFocus = () => null,
      hasFocusClass = true,
    } = this.props;
    return (
      <div
        className={`${classes.editor} ${isDisabled && classes.editorDisabled} ${
          isFocus && hasFocusClass && classes.editorFocus
        } ${className}`}
      >
        <CKEditor
          disabled={isDisabled}
          editor={InlineEditor}
          config={{
            placeholder,
            language: "fr",
            mediaEmbed: {
              previewsInData: true,
            },
            allowedContent: true,
            extraAllowedContent: "iframe[*]",
            extraPlugins: [MyCustomUploadAdapterPlugin],
            // plugins: [ Paragraph, Bold, Italic, Essentials ],
            // toolbar: ["bold", "italic", "Image"],

            fontFamily: {
              // supportAllValues: true,
              options: [
                // "default",
                // "Avenir",
                "Arial, Helvetica, sans-serif",
                "Montserrat, Ubuntu, Arial, sans-serif",
                "Ubuntu Mono, Courier New, Courier, monospace",
              ],
            },
            image: {
              upload: {
                types: ["png", "jpeg", "gif", "webp"],
              },
            },
            toolbar: {
              viewportTopOffset: 120,

              items: [
                "bold",
                "italic",
                "link",
                "bulletedList",
                "numberedList",
                "blockQuote",
                "uploadImage",
                "undo",
                "redo",
                "mediaEmbed",
              ],
            },
          }}
          // data={formatHtmlReceived(this.props.value)}
          data={this.props.value}
          onReady={editor => {
            // You can store the "editor" and use when it is needed.
            // editor.filter.check("iframe");
          }}
          onChange={(event, editor) => {
            if (isDisabled) return;

            const data = editor.getData();

            if (this.props.value === data) {
              return;
            }

            // Image upload trigger an onChange with an <img /> without src attribute
            // It makes realtime buggy without - so we wait for the src attribute before triggering onChange

            const $ = cheerio.load(data);
            const imagesWithoutSources = $("img:not([src])");

            if (imagesWithoutSources.length) {
              // this.setState({ uploadingImage: true });
              return;
            }

            // this.props.onChange(data);
            this.updateData(data);
          }}
          onBlur={this.onBlur}
          onFocus={(event, editor) => {
            this.setState({ isFocus: true });
            onFocus();
            // console.log("Focus.", editor);
          }}
          onError={({ willEditorRestart }) => {
            console.log("Error CkEditor");

            // If the editor is restarted, the toolbar element will be created once again.
            // The `onReady` callback will be called again and the new toolbar will be added.
            // This is why you need to remove the older toolbar.
            if (willEditorRestart) {
              this.editor.ui.view.toolbar.element.remove();
            }
          }}
        />
      </div>
    );
  }
}

EditableInput.defaultProps = {
  isDisabled: false,
  html: "",
  value: "",
};

export default withStyles(styles)(EditableInput);
