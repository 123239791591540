import React, { PureComponent } from "react";
import { connect } from "react-redux";
import moment from "moment";

import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";

import Colors from "../../constants/Colors";
import KeyMetricsEvolutionChart from "./KeyMetricsEvolutionChart";

import "moment/locale/fr";
moment.locale("fr");

const colorRange = [
  "rgba(252,87,122,0.8)",
  "rgba(66,80,251,0.8)",
  "rgba(0,170,255,0.8)",
  "#FF9F40",
  "#FFC233",
];

const roundTwoDigits = num => {
  return Math.floor(num * 100) / 100;
};

const getGlobalEvolution = (allFeelings, initialFeeling) => {
  const data = [];
  allFeelings.forEach(feeling => {
    feeling.forEach(el => {
      const month = moment(el.date).format("MMMM");
      const monthIndex = data.findIndex(dataEl => dataEl.mois === month);
      if (monthIndex > -1) {
        data[monthIndex].users++;
        data[monthIndex].Stress += el.stress;
        data[monthIndex].Confiance += el.confidence;
        data[monthIndex]["Aisance à l'oral"] += el.publicSpeaking;
        data[monthIndex]["Cohésion d'équipe"] += el.teamCohesion;
        data[monthIndex]["Productivité"] += el.productivity;
      } else {
        const newData = {
          mois: month,
          date: el.date,
          users: 1,
          Stress: el.stress,
          Confiance: el.confidence,
          "Aisance à l'oral": el.publicSpeaking,
          "Cohésion d'équipe": el.teamCohesion,
          Productivité: el.productivity,
        };
        data.push(newData);
      }
    });
  });

  const result = data.map(el => {
    el.Stress = el.Stress / el.users;
    el.Confiance = el.Confiance / el.users;
    el["Aisance à l'oral"] = el["Aisance à l'oral"] / el.users;
    el["Cohésion d'équipe"] = el["Cohésion d'équipe"] / el.users;
    el["Productivité"] = el["Productivité"] / el.users;
    // fix for a bug in Nivo charts for 0 value in first one
    if (!el.Stress) {
      el.Stress = 0.1;
    }
    if (!el["Aisance à l'oral"]) {
      el["Aisance à l'oral"] = 0.1;
    }
    if (!el["Cohésion d'équipe"]) {
      el["Cohésion d'équipe"] = 0.1;
    }
    if (!el["Productivité"]) {
      el["Productivité"] = 0.1;
    }
    if (!el.Confiance) {
      el.Confiance = 0.1;
    }
    return el;
  });

  // const initialFeeling = getInitialFeeling(this.props.firstFeeling);

  result.forEach((el, index) => {
    if (index === 0) {
      result[index].Stress += initialFeeling[0]["Situation initiale"];
      result[index].Confiance += initialFeeling[1]["Situation initiale"];
      result[index]["Aisance à l'oral"] +=
        initialFeeling[3]["Situation initiale"];
      result[index]["Cohésion d'équipe"] +=
        initialFeeling[2]["Situation initiale"];
      result[index]["Productivité"] += initialFeeling[0]["Situation initiale"];
    } else {
      result[index].Stress += result[index - 1].Stress;
      result[index].Confiance += result[index - 1].Confiance;
      result[index]["Aisance à l'oral"] +=
        result[index - 1]["Aisance à l'oral"];
      result[index]["Cohésion d'équipe"] +=
        result[index - 1]["Cohésion d'équipe"];
      result[index]["Productivité"] += result[index - 1]["Productivité"];
    }
  });

  result.sort((a, b) => moment(a.date) - moment(b.date));
  return result;
};

const getInitialFeeling = firstFeeling => {
  const result = firstFeeling.reduce(
    (prev, curr) => {
      prev[0]["Situation initiale"] += curr.stress;
      prev[1]["Situation initiale"] += curr.confidence;
      prev[2]["Situation initiale"] += curr.teamCohesion;
      prev[3]["Situation initiale"] += curr.publicSpeaking;
      return prev;
    },
    [
      {
        title: "Stress",
        "Situation initiale": 0,
      },
      {
        title: "Confiance",
        "Situation initiale": 0,
      },
      {
        title: "Cohésion d'équipe",
        "Situation initiale": 0,
      },
      {
        title: "Aisance à l'oral",
        "Situation initiale": 0,
      },
    ],
  );
  result.forEach((el, i) => {
    el["Situation initiale"] = roundTwoDigits(
      el["Situation initiale"] / firstFeeling.length,
    );
    // el["Objectif"] = 9 + 0.1 * i;
    el["Objectif"] = 9;
  });
  return result;
};

const styles = theme => ({
  main: {
    // backgroundColor: "#FAFAFA",
    backgroundColor: "#fff",
    flex: 1,
    paddingBottom: 45,
    flexDirection: "row",
    paddingRight: "2%",
    paddingLeft: "2%",
    // paddingTop: 35,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  leftSection: {
    flexDirection: "column",
    flex: 4,
    minHeight: 350,
    height: 350,
    alignItems: "center",
    justifyContent: "center",
  },
  rightSection: {
    flex: 3,
    height: 350,
    minHeight: 350,
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  companyLogo: {
    width: "40%",
  },
  accent: {
    color: "#6198DE",
  },
  rightTitle: {
    fontWeight: 500,
    marginBottom: 5,
  },
  bold: {
    fontWeight: 500,
  },
  totalDone: {
    color: "#fff",
    fontWeight: 500,
  },
  actionLegend: {
    color: "#fff",
  },
  circle: {
    width: 200,
    height: 200,
    borderRadius: 100,
    webkitBoxShadow: "0px 0px 15px 2px rgba(66,80,251,1)",
    mozBoxShadow: "0px 0px 15px 2px rgba(66,80,251,1)",
    boxShadow: "0px 0px 15px 2px rgba(66,80,251,1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.main,
  },
  rightBox: {
    webkitBoxShadow: "0px 0px 4px 1px #D8D8D8",
    mozBoxShadow: "0px 0px 4px 1px #D8D8D8",
    boxShadow: "0px 0px 4px 1px #D8D8D8",
    height: 250,
    flex: 1,
    padding: 5,
    flexDirection: "column",
    display: "flex",
    justifyContent: "flex-start",
  },
  chartContainer: {
    backgroundColor: "blue",
    height: 300,
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  italic: {
    fontStyle: "italic",
  },
  explanationDiv: {
    flexDirection: "column",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  textDiv: {
    width: "100%",
    borderRadius: 8,
    padding: 5,
    webkitBoxShadow: "0px 0px 15px 2px rgba(230, 230, 230,1)",
    mozBoxShadow: "0px 0px 15px 2px rgba(230, 230, 230,1)",
    boxShadow: "0px 0px 15px 2px rgba(230, 230, 230,1)",
    marginTop: 30,
  },
  answers: {
    color: Colors.grey,
  },
});

class KeyMetricsSection extends PureComponent {
  state = {
    name: "",
    owner: "",
    domain: "",
    data: [
      {
        id: "Finance",
        label: "Finance",
        value: 532,
        color: colorRange[0],
      },
      {
        id: "RH",
        label: "RH",
        value: 79,
        color: colorRange[1],
      },
      {
        id: "Dev",
        label: "Dev",
        value: 83,
        color: colorRange[2],
      },
      {
        id: "Opé",
        label: "Opé",
        value: 42,
        color: colorRange[3],
      },
    ],
    data2: [
      {
        id: "Leadership",
        label: "Leadership",
        value: 234,
        color: colorRange[0],
      },
      {
        id: "Capacité à convaincre",
        label: "Capacité à convaincre",
        value: 122,
        color: colorRange[1],
      },
      {
        id: "Gestion du stress",
        label: "Gestion du stress",
        value: 23,
        color: colorRange[2],
      },
      {
        id: "Cohésion d'Équipe",
        label: "Cohésion d'Équipe",
        value: 80,
        color: colorRange[3],
      },
      {
        id: "Résilience",
        label: "Résilience",
        value: 30,
        color: colorRange[4],
      },
    ],
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.main}>
        <div className={classes.leftSection}>
          <Typography
            align="center"
            variant="h6"
            classes={{ root: classes.bold }}
          >
            Évolution
          </Typography>
          <Typography align="center" className={classes.answers}>
            {`(${this.props.globalFeelingEvolution.length} mois)`}
          </Typography>
          {this.props.firstFeeling.length > 1 && (
            <KeyMetricsEvolutionChart
              data={this.props.globalFeelingEvolution}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { feelingEvolution, firstFeeling } = state.client;
  return {
    initialFeeling: getInitialFeeling(firstFeeling),
    globalFeelingEvolution: getGlobalEvolution(
      feelingEvolution,
      getInitialFeeling(firstFeeling),
    ),
    firstFeeling,
    feelingEvolution,
  };
};

export default connect(
  mapStateToProps,
  {},
)(withStyles(styles)(KeyMetricsSection));
