import React from "react";
import withStyles from "@mui/styles/withStyles";
import BoxType from "scenes/Campaigns/SeeUserModal/Coaching/Resources/NewResourceModal/BoxType";

const styles = theme => ({
  main: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    paddingTop: 8,
    paddingBottom: 8,
  },
});

const resourceTypes = ["videoLink", "webLink", "pdf"];

const ChooseResourceType = ({ classes, onClickResourceType }) => {
  return (
    <div className={classes.main}>
      {resourceTypes.map((el, index) => (
        <BoxType key={index} type={el} onClick={onClickResourceType(el)} />
      ))}
    </div>
  );
};

export default withStyles(styles)(ChooseResourceType);
