import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import i18n from "i18n";
import FlatButton from "components/FlatButton";
import SelectOrCreateInput from "components/SelectOrCreateInput/SelectOrCreateInput";
// import SelectOrCreateInput from "./SelectOrCreateInput";
import { useDispatch, useSelector } from "react-redux";
import {
  getInvoiceClientsRequest,
  startNewInvoice,
} from "services/invoice/actions";
import { getInvoiceClients } from "services/invoice/selectors";
import { getUserSelectedId } from "services/manager/selectors";

const useStyles = makeStyles(theme => ({
  main: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  button: {
    // width: "100%",
    height: 50,
    width: 250,
  },
  topDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginBottom: 100,
  },
  text: {
    alignSelf: "flex-start",
    fontFamily: "DM Sans",
    color: "rgba(32, 32, 32, 1)",
    fontWeight: 500,
    marginBottom: 15,
  },
}));

const SelectClient = () => {
  const classes = useStyles();
  const [clientName, setClientName] = useState("");
  const [selectedClient, setSelectedClient] = useState({ clientName: "" });
  const coacheeId = useSelector(getUserSelectedId);

  const invoiceClients = useSelector(getInvoiceClients);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInvoiceClientsRequest());
  }, [dispatch]);

  const handleSubmit = useCallback(() => {
    dispatch(
      startNewInvoice({
        ...selectedClient,
        coacheeId,
        clientName: selectedClient.clientName || clientName,
      }),
    );
  }, [dispatch, selectedClient, clientName, coacheeId]);
  
  useEffect(() => {
    if (selectedClient.clientName) {
      handleSubmit();
    }
  }, [selectedClient, handleSubmit]);

  const onClickSuggestion = suggestion => {
    setSelectedClient(suggestion);
  };

  const formattedClients = useMemo(
    () =>
      invoiceClients.map(client => {
        return {
          ...client,
          label: client.clientName,
          value: client.clientName,
        };
      }),
    [invoiceClients],
  );

  return (
    <div className={classes.main}>
      <div className={classes.topDiv}>
        <Typography className={classes.text}>
          {i18n.t("which-client-bill")}
        </Typography>

        <SelectOrCreateInput
          onClickSuggestion={onClickSuggestion}
          suggestionPlaceholder={i18n.t("select-or-create-client")}
          onSubmit={handleSubmit}
          setSearch={setClientName}
          search={clientName}
          placeholder={i18n.t("select-client-name")}
          list={formattedClients}
          searchOptions={["clientName"]}
        />
      </div>
      <FlatButton
        className={classes.button}
        disabled={!clientName}
        // requesting={requesting}
        onClick={handleSubmit}
        fullWidth
        title={i18n.t("next")}
      />
    </div>
  );
};

export default SelectClient;
