import React, { useState } from "react";
import withStyles from '@mui/styles/withStyles';
import FlatButton from "components/FlatButton";
import i18n from "i18n";
import Colors from "constants/Colors";
import { connect } from "react-redux";
import { updateFolderListRequest } from "services/createTrack/actions";
import { Typography } from "@mui/material";
import ClickOutside from "components/ClickOutside";
import AddFolderIcon from "scenes/CreateTrack/FolderList/AddFolderIcon";

const styles = theme => ({
  main: {
    marginLeft: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  folder: {
    height: 20,
    width: 20,
  },
  input: {
    marginLeft: 10,
    maxWidth: "100%",
    minWidth: 120,
    padding: 0,
    height: 20,
    fontSize: "inherit",
    lineHeight: "inherit",
    border: "none",
    borderBottom: `solid 1px ${Colors.pureMain}`,
    background: "none",
    resize: "none",
    "&:focus": {
      outline: 0,
    },
  },
  button: {
    fontSize: 12,
    marginLeft: 10,
    height: 30,
  },
  error: {
    marginLeft: 15,
    textAlign: "left",
    fontSize: 12,
    fontStyle: "italic",
    color: "red",
  },
  close: {
    color: "#707781",
    cursor: "pointer",
    transition: "all 0.2s ease",
    marginLeft: 5,
    padding: "5px 8px",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: Colors.hoverBlue,
    },
  },
});

const CreateFolder = ({
  classes,
  folderList = {},
  folderListRequesting = false,
  setCreating,
  updateFolderListRequest,
  selectFolder = () => null,
  label = "",
  value = "",
}) => {
  const [name, setName] = useState(label);
  const [error, setError] = useState("");
  const { folders = [] } = folderList;

  const onChangeName = e => {
    if (error) {
      setError("");
    }
    const nameValue = e.target.value;
    setName(nameValue);
  };

  const onCreateFolder = async () => {
    const newFolder = { label: name, value: name };
    if (folders.find(el => el.value === name)) {
      return setError(i18n.t("folder-already-exist"));
    }

    await updateFolderListRequest({
      folderListId: folderList._id,
      folders: [newFolder, ...folders],
    });
    setCreating(false);
    selectFolder(newFolder)();
    console.log("Create folder");
  };

  const onKeyDown = event => {
    if (event.keyCode === 13) {
      if (value) {
        return onEditFolder();
      }
      return onCreateFolder();
    }
  };

  const onEditFolder = async () => {
    const newFolders = folders.map(el => {
      if (el.value === value) {
        return { ...el, label: name };
      }
      return el;
    });
    await updateFolderListRequest({
      folderListId: folderList._id,
      folders: newFolders,
    });
    setCreating(false);
  };

  return (
    <ClickOutside onClickOutside={() => setCreating(false)}>
      <div className={classes.main}>
        <AddFolderIcon color={"#CBCBCB"} className={classes.folder} />
        <input
          onKeyDown={onKeyDown}
          autoFocus
          className={classes.input}
          onChange={onChangeName}
          value={name}
        />
        <FlatButton
          requesting={folderListRequesting}
          className={classes.button}
          title={!!value ? i18n.t("modify") : i18n.t("create")}
          isDisabled={!name}
          disabled={!name}
          onClick={!!value ? onEditFolder : onCreateFolder}
        />
        <Typography
          className={classes.close}
          onClick={() => setCreating(false)}
        >
          {i18n.t("close")}
        </Typography>
      </div>
      {!!error && <Typography className={classes.error}>{error}</Typography>}
    </ClickOutside>
  );
};

const mapStateToProps = state => {
  return {
    folderList: state.createTrack.folderList,
    folderListRequesting: state.createTrack.folderListRequesting,
  };
};

export default connect(mapStateToProps, { updateFolderListRequest })(
  withStyles(styles)(CreateFolder),
);
