import React, { useState } from "react";
import withStyles from '@mui/styles/withStyles';
import { Menu, Typography } from "@mui/material";
import CheckIcon from "../CreateTrack/Tags/CheckIcon";
import i18n from "../../i18n";
import Colors from "../../constants/Colors";
import ClickOutside from "../../components/ClickOutside";

export const FILTER_ALL = "FILTER_ALL";
export const FILTER_DONE = "FILTER_DONE";
export const FILTER_NOT_DONE = "FILTER_NOT_DONE";
export const FILTER_CURRENT = "FILTER_CURRENT";
export const FILTER_INCOMING = "FILTER_INCOMING";
export const FILTER_ARCHIVED = "FILTER_ARCHIVED";

const ALL_FILTERS = [
  { value: FILTER_ALL, color: "#fff" },
  { value: FILTER_NOT_DONE, color: Colors.red },
  { value: FILTER_CURRENT, color: Colors.pureMain },
  { value: FILTER_INCOMING, color: Colors.orange },
  { value: FILTER_DONE, color: "#3CC926" },
];

const styles = theme => ({
  main: {
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": { backgroundColor: Colors.hoverBlue },
    padding: "5px 10px",
  },
  checkIcon: {
    marginLeft: 15,
    flex: 1,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  colorDiv: {
    paddingLeft: 14,
    paddingRight: 14,
    minHeight: 28,
    fontSize: 14,
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    padding: "5px 10px",
    cursor: "pointer",
    transition: "all 0.2s",
    "&:hover": {
      background: "rgba(255, 200, 87, 0.3)",
    },
  },
  colorBlock: {
    width: 18,
    height: 18,
    background: "rgba(206, 205, 202, 0.5)",
    boxShadow: "rgba(15, 15, 15, 0.1) 0px 0px 0px 1px inset",
    borderRadius: 3,
  },
  filterText: {
    marginLeft: 8,
  },
  sortSelected: {
    color: "#7D7D7D",
    marginRight: 10,
  },
  sortDiv: {
    display: "flex",
    cursor: "pointer",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  libraryFilter: {
    outline: "none",
    display: "flex",
    flexDirection: "column",
    minWidth: 225,
    maxWidth: "calc(100vw - 24px)",
    height: "100%",
    maxHeight: "70vh",
    borderRadius: 4,
    borderColor: Colors.lightGrey,
  },
});

const FilterCampaigns = ({ classes, selectFilter, filterSelected }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHovered, setHover] = useState(false);

  const onMouseLeave = () => {
    setHover(false);
  };

  /*const onMouseEnter = e => {
    setAnchorEl(e.currentTarget);
    setHover(true);
  };*/

  const handleOpenMenu = e => {
    setAnchorEl(e.currentTarget);
    setHover(true);
  };

  /*const onRemoveSort = () => {
    selectFilter("")();
  };

  const openMenu = e => {
    setAnchorEl(e.currentTarget);
  };*/

  const onClickFilter = filter => () => {
    selectFilter(filter)();
    setHover(false);
  };

  const getColorSelected = () => {
    return ALL_FILTERS.find(el => el.value === filterSelected).color;
  };

  return (
    <div onClick={handleOpenMenu} className={classes.main}>
      <div className={classes.sortDiv}>
        {filterSelected !== FILTER_ALL && (
          <div
            className={classes.colorBlock}
            style={{ background: getColorSelected(), marginRight: 8 }}
          />
        )}
        <Typography className={classes.sortSelected}>
          {i18n.t(filterSelected)}
        </Typography>
        <svg width="10" height="7" viewBox="0 0 10 7" fill="none">
          <path
            d="M5.16689 6.83252L9.36119 0.0883789H0.972591L5.16689 6.83252Z"
            fill="#7D7D7D"
          />
        </svg>
      </div>

      <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={anchorEl}
        open={isHovered}
      >
        <ClickOutside onClickOutside={onMouseLeave}>
          <div className={classes.libraryFilter} onMouseLeave={onMouseLeave}>
            {ALL_FILTERS.map((el, i) => {
              const isSelected = filterSelected === el.value;
              return (
                <div
                  className={`${classes.colorDiv} ${
                    isSelected && classes.divSelected
                  }`}
                  onClick={onClickFilter(el.value)}
                  key={i}
                >
                  <div
                    className={classes.colorBlock}
                    style={{ background: el.color }}
                  />
                  <Typography className={classes.filterText}>
                    {i18n.t(el.value)}
                  </Typography>
                  {isSelected && (
                    <div className={classes.checkIcon}>
                      <CheckIcon />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </ClickOutside>
      </Menu>
    </div>
  );
};

export default withStyles(styles)(FilterCampaigns);
