import React, { useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import FlatButton from "components/FlatButton";
import i18n from "i18n";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignRequesting } from "services/campaign/selectors";
import { getCampaignSurveys } from "services/survey/selectors";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import selectLanguage from "utils/selectLanguage";
import { createEvent } from "services/campaign/actions";
import isValidHttpUrl from "utils/isValidHttpUrl";
import { MainText } from "components/Texts/MainText";
import Colors from "constants/Colors";

const useStyles = makeStyles(theme => ({
  containerAddNewEvent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  eventName: {
    marginTop: 0,
    marginBottom: 0,
    marginRight: 24,
    flex: 1,
  },
  date: {
    maxWidth: 150,
    marginRight: 24,
  },
  time: {
    maxWidth: 75,
    marginRight: 24,
  },
  urlInput: {
    marginTop: 0,
    marginBottom: 0,
    marginRight: 14,
  },
  selectSurvey: {
    width: 165,
    marginRight: 14,
  },
  button: {
    marginLeft: 10,
    fontSize: 14,
    fontWeight: "normal",
    padding: "13px 14px",
  },
}));

const arrayEventType = [
  { label: "visio", text: "event-type-visio-debrief" },
  { label: "survey", text: "event-type-survey" },
];

interface Props {
  languageSelected: string;
  startDate: Moment;
  endDate: Moment;
}

const AddNewEvent = ({ languageSelected, startDate, endDate }: Props) => {
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState(null);
  const [eventTime, setEventTime] = useState(null);
  const [eventType, setEventType] = useState("");
  const [url, setUrl] = useState(null);
  const [eventSurveySelected, setEventSurveySelected] = useState(null);
  const [urlError, setUrlError] = useState(false);
  const requesting = useSelector(getCampaignRequesting);
  const surveys = useSelector(getCampaignSurveys);

  const dispatch = useDispatch();

  const isStartDateBeforeToday = useMemo(() => {
    return startDate?.isBefore() || false;
  }, [startDate]);

  const isButtonDisabled = useMemo(() => {
    return !(
      (!!eventName &&
        eventDate?._isValid &&
        eventTime?._isValid &&
        !!eventType &&
        !!url) ||
      !!eventSurveySelected
    );
  }, [eventName, eventDate, eventTime, url, eventType, eventSurveySelected]);

  const handleChangeType = (event: SelectChangeEvent) => {
    setEventType(event.target.value);
  };

  const handleChangeSelectedSurvey = (event: SelectChangeEvent) => {
    setEventSurveySelected(event.target.value);
  };

  const handleChangeName = event => {
    const value = event.target.value;
    setEventName(value);
  };

  const handleChangeUrl = event => {
    const value = event.target.value;
    setUrl(value);
  };

  const handleChangeDate = newDate => {
    setEventDate(newDate);
  };

  const formattedDate = useMemo(() => {
    const date = eventDate;

    if (!!eventDate && !!eventTime) {
      date.set({
        hour: eventTime.get("hour"),
        minute: eventTime.get("minute"),
      });
    }

    return date;
  }, [eventDate, eventTime]);

  const ensureHTTP = url => {
    if (!url) return url;

    if (!/^https?:\/\//i.test(url)) {
      return "http://" + url;
    }
    return url;
  };

  const addNewEvent = () => {
    const urlWithHttp = ensureHTTP(url);
    if (urlWithHttp) {
      if (isValidHttpUrl(urlWithHttp)) {
        dispatch(
          createEvent({
            title: eventName,
            type: eventType,
            data: { url: urlWithHttp },
            date: formattedDate,
          }),
        );
      }
    } else if (eventSurveySelected) {
      dispatch(
        createEvent({
          title: eventName,
          type: eventType,
          data: { survey: eventSurveySelected },
          date: formattedDate,
        }),
      );

      setEventName("");
      setEventDate(null);
      setEventTime(null);
      setEventType("");
      setUrl(null);
      setEventSurveySelected(null);
      setUrlError(false);
    } else setUrlError(true);
  };

  const classes = useStyles();

  return (
    <div className={classes.containerAddNewEvent}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <TextField
          type="text"
          label={i18n.t("event-name-placeholder", {
            lng: languageSelected,
          })}
          className={classes.eventName}
          value={eventName || ""}
          onChange={handleChangeName}
          margin="normal"
          variant="outlined"
        />
        <DatePicker
          label={i18n.t("event-date-placeholder", {
            lng: languageSelected,
          })}
          className={classes.date}
          disablePast
          maxDate={endDate}
          minDate={isStartDateBeforeToday ? moment() : startDate}
          showToolbar={false}
          orientation="portrait"
          views={["day"]}
          openTo="day"
          value={eventDate}
          onChange={handleChangeDate}
          renderInput={params => <TextField {...params} />}
        />
        <TimePicker
          label={i18n.t("event-time-placeholder", {
            lng: languageSelected,
          })}
          className={classes.time}
          showToolbar={true}
          ampm={false}
          disableOpenPicker
          ampmInClock
          orientation="portrait"
          value={eventTime}
          onChange={setEventTime}
          renderInput={params => <TextField {...params} />}
        />
      </LocalizationProvider>
      <FormControl
        style={{
          minWidth: eventType === "" ? 165 : "auto",
          marginRight: eventType ? 24 : 14,
        }}
      >
        <InputLabel id="event-type-placeholder-id">
          {i18n.t("event-type-placeholder", {
            lng: languageSelected,
          })}
        </InputLabel>
        <Select
          labelId="event-type-placeholder-id"
          id="event-type-placeholder"
          label={i18n.t("event-type-placeholder", {
            lng: languageSelected,
          })}
          value={eventType || ""}
          onChange={handleChangeType}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {arrayEventType.map((event, index) => {
            return (
              <MenuItem key={index} value={event.label}>
                {i18n.t(event.text)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {eventType === "visio" && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <TextField
            type="url"
            label={i18n.t("event-url-placeholder", {
              lng: languageSelected,
            })}
            className={classes.urlInput}
            value={url || ""}
            onChange={handleChangeUrl}
            margin="normal"
            variant="outlined"
            error={urlError}
          />
          {urlError && (
            <MainText
              size="small"
              text={i18n.t("please-enter-a-valid-link")}
              style={{ color: Colors.red }}
            />
          )}
        </div>
      )}
      {eventType === "survey" && (
        <FormControl className={classes.selectSurvey}>
          <InputLabel id="event-survey-selected-placeholder-id">
            {i18n.t("event-survey-selected-placeholder", {
              lng: languageSelected,
            })}
          </InputLabel>
          <Select
            MenuProps={{
              PaperProps: {
                style: { maxHeight: 250 },
              },
            }}
            labelId="event-survey-selected-placeholder-id"
            id="event-survey-selected-placeholder"
            label={i18n.t("event-survey-selected-placeholder", {
              lng: languageSelected,
            })}
            value={eventSurveySelected || ""}
            onChange={handleChangeSelectedSurvey}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {surveys.map((survey, index) => {
              return (
                <MenuItem key={index} value={survey._id}>
                  {selectLanguage({
                    text: survey.title,
                    language: languageSelected,
                  })}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
      <FlatButton
        requesting={requesting}
        disabled={isButtonDisabled}
        className={classes.button}
        onClick={addNewEvent}
        title={i18n.t("add-new-event")}
      />
    </div>
  );
};

export default AddNewEvent;
