import { createSelector } from "reselect";
import { findLastIndex, sortBy as lodashSortBy, sortBy } from "lodash";
import selectLanguage from "utils/selectLanguage";
import removeHtmlTags from "utils/removeHtmlTags";
import { getAppLanguage } from "services/general/selectors";
import { UserCampaign } from "./types";

const getDonePercentageAndRatingMedium = steps => {
  const result = steps.reduce(
    (prev, curr) => {
      prev.donePercentage += curr.donePercentage;
      prev.rating += curr.rating;
      return prev;
    },
    { donePercentage: 0, rating: 0 },
  );
  result.donePercentage = (result.donePercentage / steps.length).toFixed(1);
  result.rating = (result.rating / steps.length).toFixed(1);
  return result;
};

export const getCampaign = state => state.campaign;

export const getSelectedStepComments = state =>
  state.campaign.stepSelectedComments || [];

export const getCampaignSelected = state =>
  state.campaign?.campaignSelected || {
    title: {},
    participants: [],
    resourcesStats: {},
  };

export const getCampaignRequesting = state =>
  state.campaign?.campaignRequesting;

export const getStepCommentRequesting = state =>
  state.campaign?.stepCommentRequesting;

export const getCampaignSelectedDoneStep = state =>
  state.campaign?.doneStepSelected || "";

export const getUserRanking = state => state.campaign?.userRanking || [];

export const getTeamRanking = state => state.campaign?.teamRanking || [];

export const getCampaignComments = state => state.campaign?.comments || [];

export const getCampaignSteps = state => state.campaign?.steps || [];
export const getEvents = state =>
  state.campaign?.campaignSelected?.events || [];

export const getCampaignSelectedTitle = createSelector(
  getCampaignSelected,
  campaignSelected => {
    return campaignSelected.title;
  },
);

export const getCampaignSelectedTrackId = createSelector(
  getCampaignSelected,
  campaignSelected => {
    return campaignSelected.trackId;
  },
);

export const getSelectedCampaignId = createSelector(
  getCampaignSelected,
  campaignSelected => {
    return campaignSelected._id;
  },
);

export const getCampaignSelectedEvolutionSurveyId = createSelector(
  getCampaignSelected,
  campaignSelected => {
    return campaignSelected.evolutionSurveyId;
  },
);

export const getDynamicLink = createSelector(
  getCampaignSelected,
  campaignSelected => {
    return campaignSelected.dynamicLink;
  },
);

export const getCampaignAccessCode = createSelector(
  getCampaignSelected,
  campaignSelected => {
    return campaignSelected.accessCode;
  },
);

export const getCampaignResourcesList = createSelector(
  getCampaignSelected,
  campaignSelected => {
    return campaignSelected.resources || [];
  },
);

export const getCampaignResourcesStats = createSelector(
  getCampaignSelected,
  campaignSelected => {
    return campaignSelected.resourcesStats || {};
  },
);

export const getCampaignCreationDate = createSelector(
  getCampaignSelected,
  campaignSelected => {
    return campaignSelected.createdAt;
  },
);

export const getIsCoachingCampaign = createSelector(
  getCampaignSelected,
  campaignSelected => {
    return campaignSelected.isCoachingCampaign;
  },
);

export const getCampaignHasReviews = createSelector(
  getCampaignSelected,
  campaignSelected => {
    return campaignSelected.hasReviews;
  },
);

export const getCampaignSelectedParticipants = createSelector(
  getCampaignSelected,
  campaignSelected => {
    return campaignSelected.participants;
  },
);

export const getCampaignSelectedStartDate = createSelector(
  getCampaignSelected,
  campaignSelected => {
    return campaignSelected.startDate;
  },
);

export const getCampaignSelectedEndDate = createSelector(
  getCampaignSelected,
  campaignSelected => {
    return campaignSelected.endDate;
  },
);

export const getCampaigns = state => state.campaign?.campaigns || [];

export const getDrafts = state => state.campaign?.drafts || [];

export const getUserCampaigns = state => state.campaign?.userCampaigns || [];

export const getUserCampaignDoneSteps = state =>
  state.campaign?.doneSteps || [];

export const getUserCampaignDoneStepsSorted = state =>
  state.campaign.doneStepsSorted || "createdAt";

export const getUserCampaignDoneStepsSortedDesc = state =>
  state.campaign.doneStepsSortedDesc;

export const getUserCampaignSelectedDoneStep = createSelector(
  getUserCampaignDoneSteps,
  getCampaignSelectedDoneStep,
  (doneSteps, stepId) => {
    return doneSteps.find(step => step._id === stepId);
  },
);

export const getUserCampaignSelectedDoneStepUserReviewed = createSelector(
  getUserCampaignSelectedDoneStep,
  getUserCampaigns,
  (doneStep, userCampaigns) => {
    return (
      userCampaigns.find(el => el.userId === doneStep.userId) || {
        firstName: "",
        lastName: "",
        picture: "",
      }
    );
  },
);

export const getCampaignSelectedIsLoading = state => state.campaign?.isLoading;

export const getCampaignOnboardingSurveyId = createSelector(
  getCampaignSelected,
  campaignSelected => {
    return campaignSelected.onboardingSurveyId;
  },
);

export const getFormatedUserCampaignDoneSteps = createSelector(
  getUserCampaignDoneSteps,
  getUserCampaigns,
  getAppLanguage,
  (doneSteps, userCampaigns, language) => {
    return doneSteps.map(step => {
      const result = { ...step };
      const { firstName, lastName, picture } = userCampaigns.find(
        el => el.userId === step.userId,
      ) || {
        firstName: "",
        lastName: "",
        picture: "",
      };
      result.firstName = firstName;
      result.lastName = lastName;
      result.picture = picture;
      result.fullName = `${firstName} ${lastName}`;

      result.status = result?.isReviewValidated
        ? "validated"
        : result?.status || "new";

      const { category, skills, title } = result?.stepId || {};
      result.category =
        skills && skills.length > 0
          ? selectLanguage({
            text: skills[0]?.label,
            language,
            noFallBack: true,
          })
          : category;
      result.totalComments = result?.comments?.length;
      // for fuse search

      result.title = removeHtmlTags(
        selectLanguage({ text: title, language, noFallBack: true }),
      );

      return result;
    });
  },
);

export const getConsolidatedCampaignSteps = createSelector(
  getUserCampaignDoneSteps,
  getUserCampaigns,
  getCampaignSteps,
  getCampaignComments,
  getSelectedStepComments,
  getAppLanguage,
  (
    doneSteps,
    userCampaigns,
    campaignSteps,
    stepFeedbacks,
    stepComments,
    language,
  ) => {
    const j = {};
    const views = {};

    userCampaigns.forEach(el => {
      el.allStepsViews.forEach(step => {
        if (views[step?.step]) {
          views[step?.step] += step?.views;
        } else {
          views[step?.step] = step?.views;
        }
      });
    });

    doneSteps.forEach(function (step) {
      if (j[step?.stepId?._id]) {
        j[step?.stepId?._id].push(step);
      } else {
        j[step?.stepId?._id] = [step];
      }
    });

    campaignSteps.forEach(function (step) {
      if (j[step?._id]) {
        j[step?._id].push({ stepId: step, donePercentage: 0, rating: 5 });
      } else {
        j[step?._id] = [{ stepId: step, donePercentage: 0, rating: 5 }];
      }
    });

    const finalResult = Object.keys(j).map(key => {
      const steps = j[key];
      const sortedStep = sortBy([...steps], "createdAt").reverse();
      const result = { ...sortedStep[0] };

      const { category, skills, title } = result?.stepId || {};
      result.totalDone = steps?.length;
      const donePercentageAndRatingMedium = getDonePercentageAndRatingMedium(
        steps,
      );
      result.donePercentage = donePercentageAndRatingMedium?.donePercentage;
      result.rating = donePercentageAndRatingMedium?.rating;
      result.category =
        skills && skills.length > 0
          ? selectLanguage({ text: skills?.[0]?.label, language })
          : category;
      const goodStepComments = stepComments.filter(
        el => el.stepId === key && !el.isHidden,
      );

      const stepFeedbackWithoutStepComment = stepFeedbacks
        .filter(el => el.stepId === key)
        .filter(ele => !goodStepComments.find(el => el._id === ele._id));
      result.comments = [
        ...goodStepComments,
        ...stepFeedbackWithoutStepComment,
      ];

      result.totalComments = result?.comments?.length;
      result.views = views?.[key] || 0;
      // for fuse search

      result.title = removeHtmlTags(
        selectLanguage({ text: title, language, noFallBack: true }),
      );

      // Count 0 for action listed but without doneSteps yet

      if (result.donePercentage === "0.0") {
        result.totalDone = 0;
      }

      // add number of views from userCampaings
      return result;
    });

    return finalResult;
  },
);

export const getUserCampaignSortedDoneSteps = createSelector(
  getFormatedUserCampaignDoneSteps,
  getUserCampaignDoneStepsSorted,
  getUserCampaignDoneStepsSortedDesc,
  (doneSteps, sortedBy, isSortedDesc) => {
    if (isSortedDesc) {
      return lodashSortBy(doneSteps, sortedBy).reverse();
    } else {
      return lodashSortBy(doneSteps, sortedBy);
    }
  },
);

export const getUserCampaignSelectedDoneStepFormated = createSelector(
  getUserCampaignSortedDoneSteps,
  getCampaignSelectedDoneStep,
  (doneSteps: any, stepId) => {
    return (
      doneSteps.find(step => step?._id === stepId) || {
        isReviewValidated: false,
      }
    );
  },
);

export const getIsReviewRequesting = state =>
  state.campaign?.isReviewRequesting;

export const getIsEmailRecapRequesting = state =>
  state.campaign?.isEmailRecapRequesting;

export const getCampaignSelectedParticipationRate = createSelector(
  getCampaignSelectedParticipants,
  getUserCampaignDoneSteps,
  (participants, doneSteps) => {
    if (!doneSteps.length || !participants.length) {
      return 0;
    }
    const distinctUsers = {};
    let distinctCounter = 0;
    doneSteps.map(step => {
      const { userId } = step;
      if (!distinctUsers[userId] && userId) {
        distinctCounter++;
        distinctUsers[userId] = true;
      }
      return step;
    });
    return ((distinctCounter / participants.length) * 100).toFixed(0);
  },
);

export const getCampaignSelectedActionPerParticipants = createSelector(
  getCampaignSelectedParticipants,
  getUserCampaignDoneSteps,
  (participants, doneSteps) => {
    if (!doneSteps?.length || !participants?.length) {
      return 0;
    }
    return (doneSteps?.length / participants.length).toFixed(1);
  },
);

export const getCampaignSelectedTotalViews = createSelector(
  getUserCampaigns,
  getUserCampaignDoneSteps,
  (userCampaigns, doneSteps) => {
    return userCampaigns.reduce((prev, curr) => {
      const totalViews = curr?.allStepsViews?.reduce((p, c) => {
        if (doneSteps.find(el => el?.stepId?._id === c.step)) {
          return c?.views + p;
        }
        return p;
      }, 0);

      return prev + totalViews;
    }, 0);
  },
);

export const getCampaignSelectedTotalDoneAction = createSelector(
  getUserCampaignDoneSteps,
  doneSteps => {
    return doneSteps.length;
  },
);

export const getCampaignSelectedActiveUsers = createSelector(
  getUserCampaigns,
  (userCampaigns: UserCampaign[]) => {
    let totalActiveUsers = 0;

    userCampaigns.map(el => {
      const lastDoneStepIndex = findLastIndex(el.steps, el => el.done);
      const isActive = lastDoneStepIndex !== -1;
      if (isActive) {
        totalActiveUsers += 1;
      }

      return el;
    });

    return totalActiveUsers;
  },
);

export const getCampaignSelectedAverageRating = createSelector(
  getUserCampaignDoneSteps,
  doneSteps => {
    if (doneSteps.length < 1) {
      return 0;
    }
    return (
      doneSteps.reduce((prev, curr) => {
        return prev + parseFloat(curr.rating);
      }, 0) / doneSteps.length
    ).toFixed(1);
  },
);

export const getCampaignSelectedAverageCompletionRate = createSelector(
  getUserCampaignDoneSteps,
  doneSteps => {
    if (doneSteps.length < 1) {
      return 0;
    }
    return (
      doneSteps.reduce((prev, curr) => {
        return prev + parseFloat(curr.donePercentage);
      }, 0) / doneSteps.length
    ).toFixed(0);
  },
);

export const getCampaignSelectedLongestComments = createSelector(
  getCampaignComments,
  comments => {
    return comments
      .sort((a, b) => b.comment.length - a.comment.length)
      .slice(0, 3)
      .map(comment => comment.comment);
  },
);

export const getCampaignSelectedBestActions = createSelector(
  getFormatedUserCampaignDoneSteps,
  steps => {
    const stepObject = {};

    steps.forEach(function (step) {
      if (stepObject[step?.stepId._id]) {
        stepObject[step?.stepId._id].totalDone += 1;
      } else {
        stepObject[step?.stepId._id] = step;
        stepObject[step?.stepId._id].totalDone = 1;
      }
    });

    const result = Object.keys(stepObject)
      .map(key => {
        return stepObject[key];
      })
      .sort((a, b) => b.totalDone - a.totalDone)
      .slice(0, 3);

    return result;
  },
);
