import React from "react";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import i18n from "i18n";

const useStyles = makeStyles(theme => ({
  label: {
    zIndex: 15,
    position: "absolute",
    top: 15,
    left: 15,
    padding: "4px 8px",
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  labelText: {},
}));

const TestAccountLabel = () => {
  const classes = useStyles();

  return (
    <div className={classes.label}>
      <Typography className={classes.labelText}>
        {i18n.t("test-account")}
      </Typography>
    </div>
  );
};

export default TestAccountLabel;
