import React from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import i18n from "i18n";
import Colors from "constants/Colors";
import FlatButton from "components/FlatButton";

const styles = theme => ({
  main: {},
  topBar: {
    position: "relative",
    width: "100%",
    height: 75,
    minHeight: 75,
    background: Colors.white,
    boxShadow: "0px 2px 15px rgba(169, 169, 169, 0.25)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    // marginBottom: 25,
  },
  barBody: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    width: 960,
  },
  topBarTitle: {
    fontFamily: "Montserrat",
    // flex: 1,
    fontWeight: "bold",
    fontSize: 24,
    lineHeight: "27px",
    color: Colors.text,
  },
  buttonDiv: {
    flex: 1,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  leftButton: {
    fontWeight: "normal",
    marginRight: 12,
    color: Colors.grey,
    backgroundColor: "transparent",
    // border: "1px solid",
    transition: "all 80ms linear",
    "&:hover": {
      opacity: 0.8,
      backgroundColor: Colors.hoverBlue,
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  closeButton: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    zIndex: 10,
    position: "absolute",
    top: 15,
    right: 5,
    "&:hover": {
      color: Colors.black,
    },
  },
  labelIconButton: {
    flexDirection: "column",
  },
});

const DialogTopBar = ({
  classes,
  title = "",
  rightButtonTitle = i18n.t("save"),
  onClickRightButton = () => null,
  onClickLeftButton = () => null,
  onClose = () => null,
  requesting = false,
  rightButtonDisabled = false,
}) => {
  return (
    <div className={classes.topBar}>
      <div className={classes.barBody}>
        <Typography className={classes.topBarTitle}>{title}</Typography>
        <div className={classes.buttonDiv}>
          <FlatButton
            className={classes.leftButton}
            onClick={onClickLeftButton}
            color="primary"
            title={i18n.t("previous")}
          ></FlatButton>
          <FlatButton
            requesting={requesting}
            disabled={rightButtonDisabled}
            variant="contained"
            color="primary"
            onClick={onClickRightButton}
            title={rightButtonTitle}
          />
        </div>
      </div>
      <IconButton
        className={classes.closeButton}
        classes={{ label: classes.labelIconButton }}
        onClick={onClose}
        size="large">
        <CloseIcon fontSize="default" />
      </IconButton>
    </div>
  );
};

export default withStyles(styles)(DialogTopBar);
