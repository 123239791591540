import React, { PureComponent } from "react";
import { connect } from "react-redux";
import moment from "moment";

import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import Logo from "../../resources/logo.png";

import "moment/locale/fr";
moment.locale("fr");

const styles = theme => ({
  main: {
    flex: 1,
    minHeight: 150,
    paddingRight: "5%",
    paddingLeft: "5%",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  leftSection: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
  },
  rightSection: {
    flex: 1,
    flexDirection: "column",
    display: "fex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  companyLogo: {
    width: "40%",
  },
  accent: {
    color: "#6198DE",
  },
  rightTitle: {
    fontWeight: 500,
    marginBottom: 5,
  },
  bold: {
    fontWeight: 500,
  },
  totalEmployees: {
    marginTop: 3,
    // fontStyle: "italic"
    // fontWeight: 500
  },
  selectedMembers: {
    marginTop: 15,
  },
});

class FirstSection extends PureComponent {
  state = {
    name: "",
    owner: "",
    domain: "",
  };

  componentDidMount = () => {
    // this.props.getActionListRequest();
  };

  onChangeClient = event => {
    this.props.changeCreateField({ name: event.target.value });
  };

  onChangeEmail = event => {
    this.props.changeCreateField({ owner: event.target.value });
  };

  onChangeDomain = event => {
    this.props.changeCreateField({ domain: event.target.value });
  };

  onSubmit = () => {
    this.props.createClientRequest({
      name: this.props.name,
      owner: this.props.owner,
      domain: this.props.domain,
    });
  };

  firstMaj = str => {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return str;
  };

  render() {
    const { classes, isDashboardManager, requesting } = this.props;
    return (
      <div className={classes.main}>
        <div className={classes.leftSection}>
          {requesting ? (
            <CircularProgress />
          ) : isDashboardManager ? (
            <div className={classes.selectedMembers}>
              {this.props.managerTeam.map(el => {
                return <div />;
              })}
            </div>
          ) : (
            <img
              className={classes.companyLogo}
              src={this.props.companyLogo}
              alt="Company Logo"
            />
          )}
        </div>
        <div className={classes.rightSection}>
          <Typography
            align="right"
            variant="h5"
            classes={{ root: classes.rightTitle }}
          >
            {`Rapport ${this.firstMaj(
              this.props.companyName,
            )} - ${this.firstMaj(this.props.period)}`}
          </Typography>
          {isDashboardManager && (
            <Typography align="right">
              <span className={classes.accent}>{this.props.endRange}</span>
            </Typography>
          )}
          {!isDashboardManager && (
            <Typography align="right">
              Du{" "}
              <span className={classes.accent}>{this.props.startingRange}</span>{" "}
              au <span className={classes.accent}>{this.props.endRange}</span>
            </Typography>
          )}
          <Typography align="right" classes={{ root: classes.totalEmployees }}>
            {`${this.props.totalEmployees} inscrits`}
          </Typography>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { managerTeam } = state.manager;
  const {
    name,
    logo,
    createdAt,
    totalEmployees,
    requesting,
  } = state.client;
  const period = moment().format("MMMM YYYY");
  const startingRange = moment(createdAt).format("L");
  const endRange = moment().format("L");
  return {
    token: state.auth.token,
    requesting,
    period,
    managerTeam,
    companyName: name,
    companyLogo: logo || Logo,
    startingRange,
    endRange,
    totalEmployees,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(FirstSection));
