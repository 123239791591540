import React from "react";

const PictureIcon = ({
  className = "",
  height = 12,
  width = 12,
  color = "#5FB8F9",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      className={className}
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M10 0H2C1.46957 0 0.960859 0.210714 0.585787 0.585787C0.210714 0.960859 0 1.46957 0 2V10C0 10.5304 0.210714 11.0391 0.585787 11.4142C0.960859 11.7893 1.46957 12 2 12H10C10.5304 12 11.0391 11.7893 11.4142 11.4142C11.7893 11.0391 12 10.5304 12 10V2C12 1.46957 11.7893 0.960859 11.4142 0.585787C11.0391 0.210714 10.5304 0 10 0ZM2 1.33333H10C10.1768 1.33333 10.3464 1.40357 10.4714 1.5286C10.5964 1.65362 10.6667 1.82319 10.6667 2V7.57333L8.53333 5.75333C8.2028 5.48136 7.78805 5.33266 7.36 5.33266C6.93196 5.33266 6.5172 5.48136 6.18667 5.75333L1.33333 9.8V2C1.33333 1.82319 1.40357 1.65362 1.5286 1.5286C1.65362 1.40357 1.82319 1.33333 2 1.33333ZM10 10.6667H2.37333L7.04 6.77333C7.12965 6.70677 7.23834 6.67083 7.35 6.67083C7.46166 6.67083 7.57035 6.70677 7.66 6.77333L10.6667 9.33333V10C10.6667 10.1768 10.5964 10.3464 10.4714 10.4714C10.3464 10.5964 10.1768 10.6667 10 10.6667Z"
        fill={color}
      />
    </svg>
  );
};

export default PictureIcon;
