import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { StepType } from "services/campaign/types";
import { emptyStep } from "services/campaign/initialStates";
import useSelectLanguage from "hooks/useSelectLanguage";
import i18n from "i18n";
import Colors from "constants/Colors";
import { MainTitle } from "components/Titles/MainTitle";
import useReviewStyle from "./review.css";
import { MainText } from "components/Texts/MainText";
import removeHtmlTags from "utils/removeHtmlTags";
import { useSelector } from "react-redux";
import { getAppLanguage } from "services/general/selectors";
import LightIcon from "scenes/CreateTrack/LightIcon";
import InlineSkills from "components/InlineSkills";
import StarRate from "components/StarRate";

const useStyles = makeStyles(theme => ({
  main: {
    paddingRight: 40,
    paddingLeft: 40,
    paddingTop: 30,
    paddingBottom: 70,
    flex: 0.6,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    borderRightWidth: 1,
    borderRightColor: Colors.lightGrey,
    borderRightStyle: "solid",
    overflowY: "scroll",
    position: "relative",
    width: "100%",
    backgroundColor: "#FCFCFC",
  },
  fullWidth: {
    width: "100%",
  },
  titleColor: {
    color: Colors.pureMain,
  },
  stat: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  statDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  totalDoneNumber: {
    marginRight: 12,
  },
  globalRatingNumber: {
    marginLeft: 6,
    marginRight: 5,
  },
}));

interface Props {
  step?: StepType;
  globalRating: number;
  totalDone: number;
}

const ReviewContentAction = ({
  step = emptyStep,
  globalRating,
  totalDone,
}: Props) => {
  const classesUseStyle = useStyles();
  const classesReviewStyle = useReviewStyle();
  const { title = {}, skills = [] } = step;
  const selectLanguage = useSelectLanguage();
  const languageSelected = useSelector(getAppLanguage);
  const contentTitle = removeHtmlTags(
    selectLanguage(step?.content?.title, languageSelected),
  );
  const how = removeHtmlTags(
    selectLanguage(step?.content?.how, languageSelected),
  );
  const why = removeHtmlTags(
    selectLanguage(step?.content?.why, languageSelected),
  );

  return (
    <div className={`${classesUseStyle.main}`}>
      <div
        className={`${classesReviewStyle.reviewDivSeparator} ${classesUseStyle.fullWidth}`}
      >
        {skills.length > 0 && <InlineSkills skills={skills} />}
        {!!title ? (
          <MainText
            size="extraLarge"
            className={`${classesReviewStyle.elementSeparator} ${classesReviewStyle.text}`}
            text={selectLanguage(title, languageSelected)}
            isBold
          />
        ) : (
          <MainTitle
            size="medium"
            className={classesReviewStyle.elementSeparator}
            text={i18n.t("review-action")}
          />
        )}
        {totalDone > 0 && (
          <div
            className={`${classesUseStyle.stat} ${classesReviewStyle.elementSeparator}`}
          >
            <div className={`${classesUseStyle.statDiv}`}>
              <StarRate rate={parseInt(globalRating?.toString())} size={0.5} />
              <MainText
                size="small"
                className={`${classesUseStyle.globalRatingNumber} ${classesReviewStyle.text}`}
                isBold
                text={globalRating?.toFixed(1)}
              />
            </div>
            <div className={`${classesUseStyle.statDiv}`}>
              <MainText
                size="small"
                className={`${classesUseStyle.totalDoneNumber} ${classesReviewStyle.text}`}
                text={totalDone?.toString()}
                isBold
              />
              <LightIcon color={"#FFBC10"} sizePercentage={0.8} />
            </div>
          </div>
        )}
      </div>
      {!!contentTitle?.trim() && (
        <div className={classesReviewStyle.reviewDivSeparator}>
          <MainText
            size="large"
            className={`${classesReviewStyle.elementSeparator} ${classesUseStyle.titleColor}`}
            text={i18n.t("Challenge")}
            isBold
          />
          <MainText
            size="small"
            className={`${classesReviewStyle.elementSeparator} ${classesReviewStyle.text}`}
            text={contentTitle}
          />
        </div>
      )}
      {!!why?.trim() && (
        <div className={classesReviewStyle.reviewDivSeparator}>
          <MainText
            size="large"
            className={`${classesReviewStyle.elementSeparator} ${classesUseStyle.titleColor}`}
            text={i18n.t("create-action-why")}
            isBold
          />
          <MainText
            size="small"
            className={`${classesReviewStyle.elementSeparator} ${classesReviewStyle.text}`}
            text={why}
          />
        </div>
      )}
      {!!how?.trim() && (
        <div className={classesReviewStyle.reviewDivSeparator}>
          <MainText
            size="large"
            className={`${classesReviewStyle.elementSeparator} ${classesUseStyle.titleColor}`}
            text={i18n.t("create-action-how")}
            isBold
          />
          <MainText
            size="small"
            className={`${classesReviewStyle.elementSeparator} ${classesReviewStyle.text}`}
            text={how}
          />
        </div>
      )}
    </div>
  );
};

export default ReviewContentAction;
