import React, { useEffect, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import { TextField } from "@mui/material";
import AddSkillBox from "scenes/CreateTrack/Skills/AddSkillBox";
import Colors from "constants/Colors";
import { cleanInput } from "scenes/CreateTrack/Skills/SkillInput";
import selectLanguage from "utils/selectLanguage";
import {
  getSkillsListRequest,
  updateSkillsListRequest,
} from "services/createTrack/actions";
import Fuse from "fuse.js";
import { connect } from "react-redux";
import { getAppLanguage } from "services/general/selectors";
import { getSelectedClientId } from "services/user/selectors";
import { Loader } from "components";

const fuseOptions = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 80,
  maxPatternLength: 20,
  minMatchCharLength: 1,
  keys: ["label.fr", "label.en", "value"],
};

const styles = theme => ({
  main: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  input: {
    paddingRight: 25,
    marginBottom: 15,
    fontSize: 18,
    color: "#474747",
    width: "100%",
    padding: 0,
    height: 20,
    lineHeight: "inherit",
    border: "none",
    background: "none",
    resize: "none",
    "&:focus": {
      outline: 0,
    },
  },
  skillBox: {
    marginTop: 15,
    marginLeft: -15,
    boxShadow: "none",
  },
});

const filterExistingGoals = (skills, existingGoals) => {
  return skills.filter(
    skill => !existingGoals.find(goal => goal?.skill?.value === skill.value),
  );
};

const SelectGoal = ({
  classes,
  skillsList = { skills: [] },
  existingGoals = [{ skill: { value: "nice" } }],
  onSelect,
  language,
  skillsListRequesting,
  getSkillsListRequest,
  updateSkillsListRequest,
  clientId,
}) => {
  const [search, setSearch] = useState("");

  const onInit = () => {
    getSkillsListRequest({ clientId });
  };

  useEffect(onInit, []);

  const onSearch = event => {
    const value = event.target.value;
    setSearch(value);
  };

  const submit = () => {
    const searchValue = cleanInput(search);

    if (!search) return;
    // create skill
    const skillIndex = skillsList.skills.findIndex(
      el =>
        cleanInput(selectLanguage({ text: el.label, language })) ===
        searchValue,
    );
    const isPresent = skillIndex > -1;
    if (!isPresent) {
      const color = Colors.pureMain;

      const cleanLabel = search.trim();

      const newSkill = {
        label: { fr: cleanLabel, en: cleanLabel },
        color,
        value: searchValue,
      };
      updateSkillsListRequest({
        skillsListId: skillsList._id,
        skills: [...skillsList.skills, newSkill],
      });
      const skillSelected = newSkill;
      onSelect(skillSelected);
    } else {
      const newSkill = skillsList.skills[skillIndex];
      onSelect(newSkill);
    }
    setSearch("");
  };

  const onKeyDown = event => {
    // delete last skill if search empty on Delete Key
    // Tab and Enter event
    if (event.keyCode === 9 || event.keyCode === 13) {
      submit();
    }
  };

  const onClickSkillSuggestion = skill => () => {
    onSelect(skill);
    setSearch("");
  };

  const skillsSuggestion = filterExistingGoals(
    skillsList.skills,
    existingGoals,
  );
  const fuse = new Fuse(skillsSuggestion, fuseOptions);
  const searchResults =
    search.length > 0 ? fuse.search(search) : skillsSuggestion;

  return (
    <div className={classes.main}>
      {skillsListRequesting && <Loader size={30} />}
      {!skillsListRequesting && (
        <>
          <TextField
            variant="standard"
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
            fullWidth
            onKeyDown={onKeyDown}
            autoFocus
            onChange={onSearch}
            value={search}
          />
          <AddSkillBox
            className={classes.skillBox}
            search={search}
            language={language}
            skillsSuggestion={searchResults}
            openOptionMenu={() => null}
            onClickSkill={onClickSkillSuggestion}
            onClickCreate={submit}
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const language = getAppLanguage(state);
  return {
    language,
    clientId: getSelectedClientId(state),
    skillsList: state.createTrack.skillsList,
    skillsListRequesting: state.createTrack.skillsListRequesting,
  };
};

export default connect(mapStateToProps, {
  updateSkillsListRequest,
  getSkillsListRequest,
})(withStyles(styles)(SelectGoal));
