import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import i18n from "i18n";
import Colors from "constants/Colors";
import { ReviewFileType } from "services/campaign/types";
import { downloadFileRequest } from "services/upload/actions";
import { useDispatch } from "react-redux";
import { Lightbox } from "react-modal-image";
import hasVideoExtension from "utils/hasVideoExtension";
import ModalPreviewVideo from "./ModalPreviewVideo";
import hasImageExtension from "utils/hasImageExtension";
import hasAudioExtension from "utils/hasAudioExtension";
import FileIcon from "scenes/Campaigns/SeeUserModal/Coaching/CoachingFiles/FileIcon";
import { MainText } from "components/Texts/MainText";
import moment from "moment";
import useReviewStyle from "./review.css";

const PICTURE_SIZE = 190;

moment.locale("fr");

const useStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    pageBreakInside: "avoid",
    breakInside: "avoid-column",
    "@media (max-width: 1400px)": {
      alignItems: "center",
    },
  },
  image: {
    width: "100%",
    minHeight: PICTURE_SIZE,
    maxHeight: PICTURE_SIZE,
    borderRadius: 8,
    objectFit: "cover",
    cursor: "pointer",
  },
  bottomDiv: {
    marginBottom: 10,
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  date: {
    color: "#878787",
  },
  fileIcon: {
    padding: 15,
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": { backgroundColor: Colors.hoverBlue },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: PICTURE_SIZE,
    width: "100%",
    borderRadius: 6,
    borderWidth: "1px solid #ffffff",
  },
  fileDiv: {
    "@media (max-width: 1400px)": {
      width: "70%",
    },
  },
  audio: {
    maxWidth: "100%",
    maxHeight: PICTURE_SIZE,
    borderRadius: 8,
    objectFit: "cover",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginBottom: 15,
  },
}));

interface ReviewFileProps extends ReviewFileType {
  fileNumber?: number;
  text?: string;
  hasFileData?: boolean;
}

const ReviewFile = ({
  fileUrl,
  filePreview,
  fileName,
  fileType,
  createdAt,
  fileNumber,
  text = "",
  hasFileData = true,
}: ReviewFileProps) => {
  const classes = useStyles();
  const classesReviewStyle = useReviewStyle();

  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);

  const isVideo = hasVideoExtension(fileName);
  const isImage = hasImageExtension(fileName);
  const isAudio = hasAudioExtension(fileName);

  const handleClickFile = e => {
    e.preventDefault();
    e.stopPropagation();

    if (!isVideo && !isImage) {
      if (fileUrl) {
        window.open(fileUrl, "_blank");
      } else {
        handleClickDownload(e);
      }
    }

    setOpen(true);
  };

  const handleClickOpenPreview = () => {
    setOpen(true);
  };

  const handleClosePreview = () => {
    setOpen(false);
  };

  const handleClickDownload = e => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(downloadFileRequest({ fileType, fileName, urlName: fileUrl }));
  };

  const displayPreview = () => {
    if (isVideo || isImage) {
      return (
        <img
          className={classes.image}
          src={filePreview || fileUrl}
          alt={fileName}
          onClick={handleClickOpenPreview}
        />
      );
    }
    if (isAudio)
      return (
        <audio controls src={fileUrl} className={classes.audio}>
          Your browser does not support the
          <code>audio</code> element.
        </audio>
      );

    return (
      <div className={classes.fileIcon} onClick={handleClickFile}>
        <FileIcon fileType={fileType?.split("/")?.[1] || fileType} />
      </div>
    );
  };

  return (
    <div className={`${classes.main} ${classesReviewStyle.elementSeparator}`}>
      <div className={`${classes.fileDiv}`}>
        {isOpen &&
          (isImage ? (
            <Lightbox
              className={classes.image}
              small={filePreview}
              large={fileUrl}
              alt={i18n.t("file-number", { fileNumber })}
              onClose={handleClosePreview}
              hideDownload
              hideZoom
            />
          ) : isVideo ? (
            <ModalPreviewVideo
              isVisible={isOpen}
              onClose={handleClosePreview}
              video={{ fileUrl, fileType }}
            />
          ) : null)}

        <div className={classes.bottomDiv}>
          {hasFileData && (
            <>
              <MainText
                size="medium"
                className={classesReviewStyle.text}
                text={!!text ? text : i18n.t("file-number", { fileNumber })}
              />
              <MainText
                size="small"
                className={classes.date}
                text={
                  i18n.t("file-uploaded-at") + moment(createdAt).format("L")
                }
              />
            </>
          )}
        </div>
        {displayPreview()}
      </div>
    </div>
  );
};

export default ReviewFile;
