import React from "react";

const ViewIcon = ({ sizePercentage, color, className }) => (
  <svg
    className={className}
    width={sizePercentage * 18}
    height={sizePercentage * 12}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 18 12"
    version="1.1"
  >
    <path
      d="M8.82466 3.22314C7.33277 3.22314 6.12061 4.43531 6.12061 5.9272C6.12061 7.4191 7.33277 8.63126 8.82466 8.63126C10.3166 8.63126 11.5287 7.4191 11.5287 5.9272C11.5287 4.43531 10.3166 3.22314 8.82466 3.22314ZM8.61953 5.12531C8.28385 5.12531 8.00412 5.40504 8.00412 5.74072H7.10899C7.12763 4.90153 7.79899 4.23017 8.61953 4.23017V5.12531Z"
      fill={color}
    />
    <path
      d="M17.03 5.3677C16.1162 4.23013 12.8527 0.519043 8.82457 0.519043C4.79645 0.519043 1.53294 4.23013 0.619151 5.3677C0.358069 5.68473 0.358069 6.15094 0.619151 6.48662C1.53294 7.62419 4.79645 11.3353 8.82457 11.3353C12.8527 11.3353 16.1162 7.62419 17.03 6.48662C17.2911 6.16959 17.2911 5.70337 17.03 5.3677ZM8.82457 9.84338C6.66132 9.84338 4.90835 8.09041 4.90835 5.92716C4.90835 3.76391 6.66132 2.01094 8.82457 2.01094C10.9878 2.01094 12.7408 3.76391 12.7408 5.92716C12.7408 8.09041 10.9878 9.84338 8.82457 9.84338Z"
      fill={color}
    />
  </svg>
);

ViewIcon.defaultProps = {
  sizePercentage: 1,
  color: "#4BADF7",
};

export default ViewIcon;
