import React, { useState } from "react";
import withStyles from "@mui/styles/withStyles";
import IconRow from "./IconRow";
import i18n from "i18n";
import CSVReaderInput from "scenes/CreateTrack/CSVReaderInput";
import { connect, useSelector } from "react-redux";
import { sendEmailInvitationsRequest } from "services/auth/actions";
import { getSelectedClientId, getUserId } from "services/user/selectors";
import { Typography } from "@mui/material";
import FlatButton from "components/FlatButton";
import Colors from "constants/Colors";
import { getIsCoachingAccount } from "services/client/selectors";
import {
  getCampaignAccessCode,
  getCampaignSelected,
} from "services/campaign/selectors";

const MICROSOFT_TEAMS_LINK =
  "https://teams.microsoft.com/l/app/cf0c47ff-46c3-4b5a-83bc-ee76577c6394?source=store-copy-link";
const WEB_LINK = "https://app.microdoing.com/signup";
const IOS_LINK =
  "https://apps.apple.com/us/app/microdoing/id1479333825?l=fr&ls=1";
const ANDROID_LINK =
  "https://play.google.com/store/apps/details?id=com.microdoing&hl=fr";

const styles = theme => ({
  main: {
    width: "100%",
    marginTop: 50,
    marginBottom: 25,
  },
  subTitle: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 18,
    color: Colors.darkBlue,
  },
  saveButton: {
    paddingTop: 15,
    paddingBottom: 15,
  },
});

const getWebLink = ({ accessCode, clientName }) => {
  let link = WEB_LINK;
  if (accessCode) {
    link = `${link}?accessCode=${accessCode}`;
  }
  if (clientName && accessCode) {
    link = `${link}&clientName=${clientName}`;
  }
  return link;
};

const AddNewLearnersTab = ({
  classes,
  accessCode,
  clientName,
  language,
  firstName,
  lastName,
  clientId,
  userId,
  requesting,
  sendEmailInvitationsRequest,
  isMobileModeActivated,
  isAnchorEmailMode,
  isMicrosoftTeamsReminderActivated,
  dynamicLink,
  isCoachingAccount,
}) => {
  const webLink = getWebLink({ accessCode, clientName });
  const [registeredEmails, setRegisteredEmails] = useState([]);

  const onChangeRegisteredEmails = emails => {
    setRegisteredEmails(emails);
  };

  const onClickSubmit = () => {
    const emails = registeredEmails.map(el => {
      const result = { email: el, role: "user" };
      return result;
    });

    sendEmailInvitationsRequest({
      clientId,
      userId,
      accessCode,
      firstName,
      lastName,
      emails,
      language,
      dynamicLink,
    });
  };

  return (
    <div className={classes.main}>
      {dynamicLink ? (
        <IconRow
          icon="mobile"
          text="Mobile"
          supports={[
            {
              name: i18n.t("on-ios-and-android"),
              link: dynamicLink,
            },
          ]}
        />
      ) : (
        <IconRow
          icon="mobile"
          text="Mobile"
          supports={[
            {
              name: i18n.t("on-ios"),
              link: IOS_LINK,
            },
            {
              name: i18n.t("on-android"),
              link: ANDROID_LINK,
            },
          ]}
        />
      )}
      <IconRow
        icon="web"
        text="Web"
        supports={[
          {
            name: i18n.t("on-web"),
            link: webLink,
          },
        ]}
      />
      {isMicrosoftTeamsReminderActivated && (
        <IconRow
          icon="microsoftTeams"
          text="Microsoft Teams"
          supports={[
            {
              name: i18n.t("on-teams"),
              link: MICROSOFT_TEAMS_LINK,
            },
          ]}
        />
      )}
      <Typography className={classes.subTitle}>
        {i18n.t("invite-mail")}
      </Typography>
      <CSVReaderInput
        setResults={onChangeRegisteredEmails}
        results={registeredEmails}
        hasUpload={!isCoachingAccount}
      />
      <FlatButton
        requesting={requesting}
        className={classes.saveButton}
        fullWidth
        disabled={registeredEmails.length === 0}
        onClick={onClickSubmit}
        variant="contained"
        color="primary"
        title={
          isCoachingAccount
            ? i18n.t("send-invitation")
            : i18n.t("send-invitations")
        }
      />
    </div>
  );
};

const mapStateToProps = state => {
  const clientId = getSelectedClientId(state);
  const userId = getUserId(state);
  const { requesting } = state.auth;
  const { firstName, lastName } = state.user;
  const campaignSelected = getCampaignSelected(state);
  const campaignId = campaignSelected._id;

  const {
    isMobileModeActivated,
    isAnchorEmailMode,
    isMicrosoftTeamsReminderActivated,
    dynamicLink,
  } = campaignSelected;

  return {
    isCoachingAccount: getIsCoachingAccount(state),
    campaignId,
    userId,
    clientId,
    firstName,
    lastName,
    requesting,
    dynamicLink,
    isMobileModeActivated,
    isAnchorEmailMode,
    isMicrosoftTeamsReminderActivated,
  };
};

export default connect(mapStateToProps, {
  sendEmailInvitationsRequest,
})(withStyles(styles)(AddNewLearnersTab));
