export const CAMPAIGN_GET_REQUESTING = "CAMPAIGN_GET_REQUESTING";
export const CAMPAIGN_GET_SUCCESS = "CAMPAIGN_GET_SUCCESS";
export const CAMPAIGN_GET_ERROR = "CAMPAIGN_GET_ERROR";

export const CAMPAIGN_MODIFY_REQUESTING = "CAMPAIGN_MODIFY_REQUESTING";
export const CAMPAIGN_MODIFY_SUCCESS = "CAMPAIGN_MODIFY_SUCCESS";
export const CAMPAIGN_MODIFY_ERROR = "CAMPAIGN_MODIFY_ERROR";

export const CAMPAIGN_ACTIVATE_EMAIL_REMINDER_REQUESTING =
  "CAMPAIGN_ACTIVATE_EMAIL_REMINDER_REQUESTING";
export const CAMPAIGN_ACTIVATE_EMAIL_REMINDER_SUCCESS =
  "CAMPAIGN_ACTIVATE_EMAIL_REMINDER_SUCCESS";
export const CAMPAIGN_ACTIVATE_EMAIL_REMINDER_ERROR =
  "CAMPAIGN_ACTIVATE_EMAIL_REMINDER_ERROR";

export const CAMPAIGN_GET_ALL_REQUESTING = "CAMPAIGN_GET_ALL_REQUESTING";
export const CAMPAIGN_GET_ALL_SUCCESS = "CAMPAIGN_GET_ALL_SUCCESS";
export const CAMPAIGN_GET_ALL_ERROR = "CAMPAIGN_GET_ALL_ERROR";

export const CAMPAIGN_DELETE_REQUESTING = "CAMPAIGN_DELETE_REQUESTING";
export const CAMPAIGN_DELETE_SUCCESS = "CAMPAIGN_DELETE_SUCCESS";
export const CAMPAIGN_DELETE_ERROR = "CAMPAIGN_DELETE_ERROR";

export const CAMPAIGN_RESET_TEAMS_REQUESTING =
  "CAMPAIGN_RESET_TEAMS_REQUESTING";
export const CAMPAIGN_RESET_TEAMS_SUCCESS = "CAMPAIGN_RESET_TEAMS_SUCCESS";
export const CAMPAIGN_RESET_TEAMS_ERROR = "CAMPAIGN_RESET_TEAMS_ERROR";

export const TRACK_GET_ALL_REQUESTING = "TRACK_GET_ALL_REQUESTING";
export const TRACK_GET_ALL_SUCCESS = "TRACK_GET_ALL_SUCCESS";
export const TRACK_GET_ALL_ERROR = "TRACK_GET_ALL_ERROR";

export const GET_ALL_MY_CLIENTS_TRACKS_REQUESTING =
  "GET_ALL_MY_CLIENTS_TRACKS_REQUESTING";
export const GET_ALL_MY_CLIENTS_TRACKS_ERROR =
  "GET_ALL_MY_CLIENTS_TRACKS_ERROR";
export const GET_ALL_MY_CLIENTS_TRACKS_SUCCESS =
  "GET_ALL_MY_CLIENTS_TRACKS_SUCCESS";

export const CAMPAIGN_CREATE_REQUESTING = "CAMPAIGN_CREATE_REQUESTING";
export const CAMPAIGN_CREATE_SUCCESS = "CAMPAIGN_CREATE_SUCCESS";
export const CAMPAIGN_CREATE_ERROR = "CAMPAIGN_CREATE_ERROR";

export const CAMPAIGN_GET_COMMENTS_REQUESTING =
  "CAMPAIGN_GET_COMMENTS_REQUESTING";
export const CAMPAIGN_GET_COMMENTS_SUCCESS = "CAMPAIGN_GET_COMMENTS_SUCCESS";
export const CAMPAIGN_GET_COMMENTS_ERROR = "CAMPAIGN_GET_COMMENTS_ERROR";

export const CAMPAIGN_GET_USER_RANKING_REQUESTING =
  "CAMPAIGN_GET_USER_RANKING_REQUESTING";
export const CAMPAIGN_GET_USER_RANKING_SUCCESS =
  "CAMPAIGN_GET_USER_RANKING_SUCCESS";
export const CAMPAIGN_GET_USER_RANKING_ERROR =
  "CAMPAIGN_GET_USER_RANKING_ERROR";

export const CAMPAIGN_GET_TEAM_RANKING_REQUESTING =
  "CAMPAIGN_GET_TEAM_RANKING_REQUESTING";
export const CAMPAIGN_GET_TEAM_RANKING_SUCCESS =
  "CAMPAIGN_GET_TEAM_RANKING_SUCCESS";
export const CAMPAIGN_GET_TEAM_RANKING_ERROR =
  "CAMPAIGN_GET_TEAM_RANKING_ERROR";

export const CAMPAIGN_GET_USERS_INFOS_REQUESTING =
  "CAMPAIGN_GET_USERS_INFOS_REQUESTING";
export const CAMPAIGN_GET_USERS_INFOS_SUCCESS =
  "CAMPAIGN_GET_USERS_INFOS_SUCCESS";
export const CAMPAIGN_GET_USERS_INFOS_ERROR = "CAMPAIGN_GET_USERS_INFOS_ERROR";

export const CAMPAIGN_SELECT = "CAMPAIGN_SELECT";

export const CAMPAIGN_GET_ALL_DONE_STEPS_REQUESTING =
  "CAMPAIGN_GET_ALL_DONE_STEPS_REQUESTING";
export const CAMPAIGN_GET_ALL_DONE_STEPS_SUCCESS =
  "CAMPAIGN_GET_ALL_DONE_STEPS_SUCCESS";
export const CAMPAIGN_GET_ALL_DONE_STEPS_ERROR =
  "CAMPAIGN_GET_ALL_DONE_STEPS_ERROR";

export const CAMPAIGN_CLOSE_SNACK_MESSAGE = "CAMPAIGN_CLOSE_SNACK_MESSAGE";
export const CAMPAIGN_SELECT_TAB = "CAMPAIGN_SELECT_TAB";

export const CAMPAIGN_GET_ALL_TEMPLATES_REQUESTING =
  "CAMPAIGN_GET_ALL_TEMPLATES_REQUESTING";
export const CAMPAIGN_GET_ALL_TEMPLATES_SUCCESS =
  "CAMPAIGN_GET_ALL_TEMPLATES_SUCCESS";
export const CAMPAIGN_GET_ALL_TEMPLATES_ERROR =
  "CAMPAIGN_GET_ALL_TEMPLATES_ERROR";

export const CAMPAIGN_GET_ALL_PUBLIC_TEMPLATES_REQUESTING =
  "CAMPAIGN_GET_ALL_PUBLIC_TEMPLATES_REQUESTING";
export const CAMPAIGN_GET_ALL_PUBLIC_TEMPLATES_SUCCESS =
  "CAMPAIGN_GET_ALL_PUBLIC_TEMPLATES_SUCCESS";
export const CAMPAIGN_GET_ALL__PUBLIC_TEMPLATES_ERROR =
  "CAMPAIGN_GET_ALL__PUBLIC_TEMPLATES_ERROR";

export const CAMPAIGN_GET_ALL_DRAFTS_REQUESTING =
  "CAMPAIGN_GET_ALL_DRAFTS_REQUESTING";
export const CAMPAIGN_GET_ALL_DRAFTS_SUCCESS =
  "CAMPAIGN_GET_ALL_DRAFTS_SUCCESS";
export const CAMPAIGN_GET_ALL_DRAFTS_ERROR = "CAMPAIGN_GET_ALL_DRAFTS_ERROR";

export const CHANGE_ADD_USERS_MODAL = "CHANGE_ADD_USERS_MODAL";

export const DONE_STEP_RECEIVED = "DONE_STEP_RECEIVED";

export const CAMPAIGN_RECEIVED = "CAMPAIGN_RECEIVED";

export const USER_CAMPAIGN_RECEIVED = "USER_CAMPAIGN_RECEIVED";

export const CAMPAIGN_SELECT_PREVIEW_TAB = "CAMPAIGN_SELECT_PREVIEW_TAB";

export const USER_CAMPAIGNS_GET_LIST_REQUESTING =
  "USER_CAMPAIGNS_GET_LIST_REQUESTING";
export const USER_CAMPAIGNS_GET_LIST_SUCCESS =
  "USER_CAMPAIGNS_GET_LIST_SUCCESS";
export const USER_CAMPAIGNS_GET_LIST_ERROR = "USER_CAMPAIGNS_GET_LIST_ERROR";

export const USER_CAMPAIGNS_LOAD_MORE_REQUESTING =
  "USER_CAMPAIGNS_LOAD_MORE_REQUESTING";
export const USER_CAMPAIGNS_LOAD_MORE_SUCCESS =
  "USER_CAMPAIGNS_LOAD_MORE_SUCCESS";
export const USER_CAMPAIGNS_LOAD_MORE_ERROR = "USER_CAMPAIGNS_LOAD_MORE_ERROR";

export const TRACK_DELETE_REQUESTING = "TRACK_DELETE_REQUESTING";
export const TRACK_DELETE_SUCCESS = "TRACK_DELETE_SUCCESS";
export const TRACK_DELETE_ERROR = "TRACK_DELETE_ERROR";

export const USER_CAMPAIGN_DELETE_REQUESTING =
  "USER_CAMPAIGN_DELETE_REQUESTING";
export const USER_CAMPAIGN_DELETE_SUCCESS = "USER_CAMPAIGN_DELETE_SUCCESS";
export const USER_CAMPAIGN_DELETE_ERROR = "USER_CAMPAIGN_DELETE_ERROR";

export const CAMPAIGN_ADD_PARTICIPANTS_REQUESTING =
  "CAMPAIGN_ADD_PARTICIPANTS_REQUESTING";
export const CAMPAIGN_ADD_PARTICIPANTS_SUCCESS =
  "CAMPAIGN_ADD_PARTICIPANTS_SUCCESS";
export const CAMPAIGN_ADD_PARTICIPANTS_ERROR =
  "CAMPAIGN_ADD_PARTICIPANTS_ERROR";

export const CAMPAIGN_ADD_TO_TEMPLATE_REQUESTING =
  "CAMPAIGN_ADD_TO_TEMPLATE_REQUESTING";
export const CAMPAIGN_ADD_TO_TEMPLATE_SUCCESS =
  "CAMPAIGN_ADD_TO_TEMPLATE_SUCCESS";
export const CAMPAIGN_ADD_TO_TEMPLATE_ERROR = "CAMPAIGN_ADD_TO_TEMPLATE_ERROR";

export const DRAFT_REMOVE_REQUESTING = "DRAFT_REMOVE_REQUESTING";
export const DRAFT_REMOVE_SUCCESS = "DRAFT_REMOVE_SUCCESS";
export const DRAFT_REMOVE_ERROR = "DRAFT_REMOVE_ERROR";

export const VALIDATE_RANDOM_STEP_REQUESTING =
  "VALIDATE_RANDOM_STEP_REQUESTING";

export const SELECT_CAMPAIGN_REQUESTING = "SELECT_CAMPAIGN_REQUESTING";
export const SELECT_CAMPAIGN_SUCCESS = "SELECT_CAMPAIGN_SUCCESS";
export const SELECT_CAMPAIGN_ERROR = "SELECT_CAMPAIGN_ERROR";

export const CAMPAIGN_RESOURCE_VISIT_RECEIVED =
  "campaign/CAMPAIGN_RESOURCE_VISIT_RECEIVED";

export const CAMPAIGN_RESOURCE_FEEDBACK_RECEIVED =
  "campaign/CAMPAIGN_RESOURCE_FEEDBACK_RECEIVED";

export const COMMENT_REVIEW_REQUESTING = "campaign/COMMENT_REVIEW_REQUESTING";
export const COMMENT_REVIEW_SUCCESS = "campaign/COMMENT_REVIEW_SUCCESS";
export const COMMENT_REVIEW_ERROR = "campaign/COMMENT_REVIEW_ERROR";

export const UPDATE_STEP_FEEDBACK_FILE = "campaign/UPDATE_STEP_FEEDBACK_FILE";

export const UPDATE_STEP_FEEDBACK = "campaign/UPDATE_STEP_FEEDBACK";

export const SEE_COMMENTS_REVIEW_REQUESTING =
  "campaign/SEE_COMMENTS_REVIEW_REQUESTING";
export const SEE_COMMENTS_REVIEW_SUCCESS =
  "campaign/SEE_COMMENTS_REVIEW_SUCCESS";
export const SEE_COMMENTS_REVIEW_ERROR = "campaign/SEE_COMMENTS_REVIEW_ERROR";

export const CAMPAIGN_SELECTED_DONE_STEP =
  "campaign/CAMPAIGN_SELECTED_DONE_STEP";

export const CHANGE_CAMPAIGN_DONE_STEP_SORTED =
  "campaign/CHANGE_CAMPAIGN_DONE_STEP_SORTED";

export const CHANGE_CAMPAIGN_DONE_STEP_SORTED_DESC =
  "campaign/CHANGE_CAMPAIGN_DONE_STEP_SORTED_DESC";

export const CAMPAIGN_GET_ALL_DONE_STEPS_FORMATED_REQUESTING =
  "campaign/CAMPAIGN_GET_ALL_DONE_STEPS_FORMATED_REQUEST";
export const CAMPAIGN_GET_ALL_DONE_STEPS_FORMATED_SUCCESS =
  "campaign/CAMPAIGN_GET_ALL_DONE_STEPS_FORMATED_SUCCESS";
export const CAMPAIGN_GET_ALL_DONE_STEPS_FORMATED_ERROR =
  "campaign/CAMPAIGN_GET_ALL_DONE_STEPS_FORMATED_ERROR";

export const CAMPAIGN_GET_NEXT_DONE_STEP_REVIEW =
  "campaign/CAMPAIGN_GET_NEXT_DONE_STEP_REVIEW";
export const CAMPAIGN_GET_NEXT_DONE_STEP_REVIEW_SUCCESS =
  "campaign/CAMPAIGN_GET_NEXT_DONE_STEP_REVIEW_SUCCESS";
export const CAMPAIGN_GET_NEXT_DONE_STEP_REVIEW_ERROR =
  "campaign/CAMPAIGN_GET_NEXT_DONE_STEP_REVIEW_ERROR";

export const CAMPAIGN_VALIDATE_REVIEW_REQUESTING =
  "campaign/CAMPAIGN_VALIDATE_REVIEW_REQUESTING";
export const CAMPAIGN_VALIDATE_REVIEW_SUCCESS =
  "campaign/CAMPAIGN_VALIDATE_REVIEW_SUCCESS";
export const CAMPAIGN_VALIDATE_REVIEW_ERROR =
  "campaign/CAMPAIGN_VALIDATE_REVIEW_ERROR";

export const CAMPAIGN_GET_PREVIOUS_DONE_STEP_REVIEW =
  "campaign/CAMPAIGN_GET_PREVIOUS_DONE_STEP_REVIEW";

export const CAMPAIGN_SEND_EMAIL_RECAP_REQUESTING =
  "campaign/CAMPAIGN_SEND_EMAIL_RECAP_REQUESTING";
export const CAMPAIGN_SEND_EMAIL_RECAP_SUCCESS =
  "campaign/CAMPAIGN_SEND_EMAIL_RECAP_SUCCESS";
export const CAMPAIGN_SEND_EMAIL_RECAP_ERROR =
  "campaign/CAMPAIGN_SEND_EMAIL_RECAP_ERROR";

export const CAMPAIGN_CREATE_EVENT = "campaign/CAMPAIGN_CREATE_EVENT";

export const CAMPAIGN_CREATE_EVENT_REQUESTING =
  "campaign/CAMPAIGN_CREATE_EVENT_REQUESTING";
export const CAMPAIGN_CREATE_EVENT_SUCCESS =
  "campaign/CAMPAIGN_CREATE_EVENT_SUCCESS";
export const CAMPAIGN_CREATE_EVENT_ERROR =
  "campaign/CAMPAIGN_CREATE_EVENT_ERROR";

export const CAMPAIGN_UPDATE_EVENT_REQUESTING =
  "campaign/CAMPAIGN_UPDATE_EVENT_REQUESTING";
export const CAMPAIGN_UPDATE_EVENT_SUCCESS =
  "campaign/CAMPAIGN_UPDATE_EVENT_SUCCESS";
export const CAMPAIGN_UPDATE_EVENT_ERROR =
  "campaign/CAMPAIGN_UPDATE_EVENT_ERROR";

export const CAMPAIGN_DELETE_EVENT_REQUESTING =
  "campaign/CAMPAIGN_DELETE_EVENT_REQUESTING";
export const CAMPAIGN_DELETE_EVENT_SUCCESS =
  "campaign/CAMPAIGN_DELETE_EVENT_SUCCESS";
export const CAMPAIGN_DELETE_EVENT_ERROR =
  "campaign/CAMPAIGN_DELETE_EVENT_ERROR";

export const CAMPAIGN_GET_STEPS_REQUESTING =
  "campaign/CAMPAIGN_GET_STEPS_REQUESTING";
export const CAMPAIGN_GET_STEPS_SUCCESS = "campaign/CAMPAIGN_GET_STEPS_SUCCESS";
export const CAMPAIGN_GET_STEPS_ERROR = "campaign/CAMPAIGN_GET_STEPS_ERROR";

export const GET_STEP_COMMENT_REQUESTING =
  "campaign/GET_STEP_COMMENT_REQUESTING";
export const GET_STEP_COMMENT_ERROR = "campaign/GET_STEP_COMMENT_ERROR";
export const GET_STEP_COMMENT_SUCCESS = "campaign/GET_STEP_COMMENT_SUCCESS";

export const HIDE_STEP_COMMENT_REQUESTING =
  "campaign/HIDE_STEP_COMMENT_REQUESTING";
export const HIDE_STEP_COMMENT_ERROR = "campaign/HIDE_STEP_COMMENT_ERROR";
export const HIDE_STEP_COMMENT_SUCCESS = "campaign/HIDE_STEP_COMMENT_SUCCESS";
export const CAMPAIGN_GET_INTERACTIONS_REQUESTING =
  "campaign/CAMPAIGN_GET_INTERACTIONS_REQUESTING";
export const CAMPAIGN_GET_INTERACTIONS_SUCCESS =
  "campaign/CAMPAIGN_GET_INTERACTIONS_SUCCESS";
export const CAMPAIGN_GET_INTERACTIONS_ERROR =
  "campaign/CAMPAIGN_GET_INTERACTIONS_ERROR";

export const CAMPAIGN_REGENERATE_CERTIFICATE_REQUESTING =
  "campaign/CAMPAIGN_REGENERATE_CERTIFICATE_REQUESTING";
export const CAMPAIGN_REGENERATE_CERTIFICATE_SUCCESS =
  "campaign/CAMPAIGN_REGENERATE_CERTIFICATE_SUCCESS";
export const CAMPAIGN_REGENERATE_CERTIFICATE_ERROR =
  "campaign/CAMPAIGN_REGENERATE_CERTIFICATE_ERROR";

export const REMOVE_LIKE_STEP_COMMENT_REQUESTING =
  "userCampaign/REMOVE_LIKE_STEP_COMMENT_REQUESTING";
export const REMOVE_LIKE_STEP_COMMENT_ERROR =
  "userCampaign/REMOVE_LIKE_STEP_COMMENT_ERROR";
export const REMOVE_LIKE_STEP_COMMENT_SUCCESS =
  "userCampaign/REMOVE_LIKE_STEP_COMMENT_SUCCESS";

export const ADD_LIKE_STEP_COMMENT_REQUESTING =
  "userCampaign/ADD_LIKE_STEP_COMMENT_REQUESTING";
export const ADD_LIKE_STEP_COMMENT_ERROR =
  "userCampaign/ADD_LIKE_STEP_COMMENT_ERROR";
export const ADD_LIKE_STEP_COMMENT_SUCCESS =
  "userCampaign/ADD_LIKE_STEP_COMMENT_SUCCESS";
