import React from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography } from "@mui/material";

const styles = theme => ({
  main: {
    color: "#6E757E",
  },
});

const Explanation = ({ classes, text }) => {
  return <Typography className={classes.main}>{text}</Typography>;
};

export default withStyles(styles)(Explanation);
