import React, { PureComponent } from "react";
import { connect } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Typography, Dialog } from "@mui/material";
import { Loader } from "components";

import withStyles from '@mui/styles/withStyles';

import { getIsSuperAdminClientSelected } from "services/client/selectors";
import {
  getSelectedClientId,
  getUserClientId,
  getIsSuperAdmin,
  getUserId,
} from "services/user/selectors";
import { getAppLanguage } from "services/general/selectors";
import {
  createNewQuestion,
  changeField,
  reorderQuestions,
  removeQuestion,
  createSurveyRequest,
  modifySurveyRequest,
} from "services/survey/actions";

import "scenes/CreateTrack/draggableStyle.css";
import Colors from "constants/Colors";
import Header from "scenes/SurveyBuilder/Header";
import HelperBar from "scenes/SurveyBuilder/HelperBar";
import StepRow from "scenes/CreateTrack/StepRow";

import LibraryImage from "resources/library.svg";
import BookImage from "resources/text.svg";

import i18n from "i18n";
import CreateQuestionSide from "scenes/SurveyBuilder/CreateQuestionSide";
import AddQuestion from "./AddQuestion";

const styles = theme => ({
  main: {
    width: "100%",
    height: "100%",
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
  },
  bottomSide: {
    position: "relative",
    flex: 1,
    width: "100%",
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  leftSide: {
    position: "relative",
    flex: 1,
    height: "calc(100vh - 60px)",
    pointerEvents: "auto",
    border: "solid",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#ECEBF1",
    overflow: "hidden",
  },
  "@media (max-width: 960px)": {
    leftSide: {
      width: 300,
    },
  },
  rightSide: {
    flex: 2,
    height: "calc(100vh - 60px)",
    overflow: "hidden",
    overflowY: "auto",
  },
  noClick: {
    pointerEvents: "none",
  },
  templateWarning: {
    position: "relative",
  },
  helperBar: {
    position: "relative",
    background: Colors.white,
    boxShadow: "0px 2px 15px rgba(169, 169, 169, 0.25)",
    height: 60,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 10,
  },
  title: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 20,
    lineHeight: "27px",
    color: Colors.text,
  },
  text: {
    fontFamily: "Montserrat",
    fontSize: 18,
    lineHeight: "27px",
    flex: 1,
    marginLeft: 7,
  },
  bottomLeft: {
    height: "90vh",
    overflow: "scroll",
    minWidth: "100%",
    background: "#F5F5F5",
    width: "100%",
    paddingBottom: 140,
  },
  searchDiv: {
    width: "100%",
    height: 76,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  explanation: {
    paddingLeft: 50,
    paddingRight: 50,
    marginTop: 30,
    width: "100%",
    flex: 1,
    color: "#AAAAAA",
    fontSize: 16,
    textAlign: "center",
  },
  rightPlaceholder: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.white,
  },
});

class SurveyBuilder extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLeftSideHovered: false,
      isDragging: false,
    };
  }

  componentDidMount() {
    const { questions } = this.props;
    window.addEventListener("keydown", this.downHandler);
    window.addEventListener("keyup", this.upHandler);

    if (questions.length > 0) {
      // this.props.changeField({ rightSideScreen: "createActionTextQuestion" });
    }
    // get all Questions
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.downHandler);
    window.removeEventListener("keyup", this.upHandler);
  }

  downHandler = e => {
    if (
      e.keyCode === 40 &&
      this.state.isLeftSideHovered &&
      this.props.questionSelectedIndex < this.props.questions.length - 1
    ) {
      this.props.changeField({
        questionSelectedIndex: this.props.questionSelectedIndex + 1,
      });
    }
  };

  upHandler = e => {
    if (
      e.keyCode === 38 &&
      this.state.isLeftSideHovered &&
      this.props.questionSelectedIndex > 0
    ) {
      this.props.changeField({
        questionSelectedIndex: this.props.questionSelectedIndex - 1,
      });
    }
  };

  scrollToBottom = () => {
    document
      .getElementById("leftSide")
      .scrollBy({ top: 3000, behavior: "smooth" });
  };

  removeQuestion = questionIndex => () => {
    this.props.removeQuestion(questionIndex);
  };

  onCreateQuestionType = questionType => () => {
    this.onCreateQuestion(questionType);
  };

  onCreateQuestion = (questionType = "RATING") => {
    const { questions, userClientId, isSuperAdmin, clientId } = this.props;
    const questionNumber = questions.length + 1;

    const payload = {
      questionType,
      questionTitle: {
        fr: `Question ${questionNumber}`,
        en: `Question ${questionNumber}`,
      },
      leftLabel: { fr: "Faible", en: "Low" },
      rightLabel: { fr: "Élevé", en: "High" },
      choices: [{}, {}],
      scale: [1, 10],
      clientId: isSuperAdmin ? clientId : userClientId,
      questionIndex: questions.length,
    };

    this.props.createNewQuestion(payload);
    this.scrollToBottom();
  };

  onClickQuestion = questionSelectedIndex => () => {
    // console.log("CLICKING question selected");
    this.props.changeField({
      questionSelectedIndex,
      rightSideScreen: "createActionTextQuestion",
    });
  };

  onBeforeDragStart = result => {
    this.props.changeField({ questionSelectedIndex: result.source.index });
    this.setState({ isDragging: true });
  };

  onDragEnd = result => {
    // dropped outside the list
    this.setState({ isDragging: false });

    if (!result.destination) {
      return;
    }

    this.props.reorderQuestions({
      startIndex: result.source.index,
      endIndex: result.destination.index,
    });
  };

  displayQuestions = (questions, questionSelectedIndex) => {
    const { languageSelected } = this.props;
    return (
      <DragDropContext
        onDragEnd={this.onDragEnd}
        onBeforeDragStart={this.onBeforeDragStart}
      >
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {questions.map((question, index) => {
                const {
                  questionTitle,
                  _id,
                  creating,
                  questionIndex,
                  questionType,
                } = question;

                const titleToDisplay = questionTitle?.[languageSelected] || "";

                return (
                  <Draggable
                    key={index}
                    draggableId={`${index} ${_id}`}
                    index={index}
                  >
                    {(provided, snapshot) => {
                      return (
                        <StepRow
                          {...question}
                          isFirst={index === 0}
                          hasDragNDrop
                          isDragging={snapshot.isDragging}
                          specialRef={provided.innerRef}
                          provided={provided}
                          isSelected={questionIndex === questionSelectedIndex}
                          stepIndex={index + 1}
                          stepType={questionType}
                          onClick={this.onClickQuestion(questionIndex)}
                          creating={creating}
                          label={null}
                          key={index}
                          noRightBorder
                          title={titleToDisplay}
                          languageSelected={languageSelected}
                          onClickDeleteButton={this.removeQuestion(
                            questionIndex,
                          )}
                        />
                      );
                    }}
                  </Draggable>
                );
              })}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  renderRightSide = () => {
    const { classes, questions } = this.props;
    if (questions.length) return <CreateQuestionSide />;
    return (
      <div className={classes.rightPlaceholder}>
        <Typography className={classes.explanation}>
          {i18n.t("add-questions-to-survey")}
        </Typography>
      </div>
    );
  };

  onMouseEnter = () => {
    this.setState({ isLeftSideHovered: true });
  };

  onMouseLeave = () => {
    this.setState({ isLeftSideHovered: false });
  };

  saveSurvey = () => {
    const { surveyId } = this.props;
    this.props.modifySurveyRequest({ surveyId });
  };

  createSurvey = () => {
    const {
      questions,
      title,
      userClientId,
      isSuperAdmin,
      clientId,
    } = this.props;

    this.props.createSurveyRequest({
      questions,
      title,
      clientId: isSuperAdmin ? clientId : userClientId,
    });
  };

  onClickPublish = () => {
    if (this.isPublishButtonDisabled()) return;

    const {
      surveyId,
      questions,
      title,
      clientId,
      userId,
      isSuperAdmin,
      userClientId,
    } = this.props;
    if (surveyId) {
      this.props.modifySurveyRequest({ surveyId, questions, title });
    } else {
      const titleKeys = Object.keys(title);
      const hasTitle = titleKeys.reduce(
        (prev, curr) => !!title[curr] + prev,
        0,
      );
      const defaultTitle = {
        fr: "Sans titre",
        en: "Without title",
      };

      this.props.createSurveyRequest({
        questions,
        title: hasTitle ? title : defaultTitle,
        userId,
        clientId: isSuperAdmin ? clientId : userClientId,
      });
    }
  };

  onMouseEnterLeftSide = () => this.setState({ isLeftSideHovered: true });
  onMouseLeaveLeftSide = () => this.setState({ isLeftSideHovered: true });

  isPublishButtonDisabled = () => {
    const { questions } = this.props;
    if (!questions.length) return true;
    // if (!title.fr && !title.en) return true;
    return false;
  };

  render() {
    const {
      classes,
      questions,
      requesting,
      surveyId,
      isVisible = false,
      onClose,
    } = this.props;

    const formattedQuestions = questions.map((el, i) => {
      el.questionIndex = i;
      return el;
    });

    return (
      <Dialog
        fullScreen
        open={isVisible}
        // open={true}
        onClose={onClose}
      >
        <CssBaseline />
        <div className={classes.main}>
          <Header
            isPublishButtonDisabled={this.isPublishButtonDisabled()}
            onClickPrevious={onClose}
            onClickPublishButton={this.onClickPublish}
            buttonTitle={
              surveyId ? i18n.t("save-survey") : i18n.t("publish-survey")
            }
          />
          <div className={classes.bottomSide}>
            <div
              onMouseEnter={this.onMouseEnterLeftSide}
              onMouseLeave={this.onMouseLeaveRightSide}
              className={`${classes.leftSide} ${
                this.props.questionLoading && classes.noClick
              }`}
              ref={el => {
                this.leftSideEndRef = el;
              }}
            >
              <HelperBar
                numberOfQuestions={questions.length}
                onCreateQuestion={this.onCreateQuestionType("RATING")}
              />
              <div className={classes.bottomLeft} id={"leftSide"}>
                {requesting && <Loader size={"10%"} />}
                {this.displayQuestions(
                  formattedQuestions,
                  this.props.questionSelectedIndex,
                )}
                {!this.state.isDragging && (
                  <AddQuestion
                    rows={[
                      {
                        questionType: "RATING",
                        title: i18n.t("rating-scale"),
                        onClick: this.onCreateQuestionType("RATING"),
                        explanationDetails: i18n.t("RATING-explanation"),
                        explanationPicture: BookImage,
                        explanationTitle: i18n.t("RATING-question-title"),
                      },
                      {
                        questionType: "MULTIPLE_CHOICES",
                        title: i18n.t("multiple-choices"),
                        onClick: this.onCreateQuestionType("MULTIPLE_CHOICES"),
                        explanationDetails: i18n.t(
                          "MULTIPLE_CHOICES-explanation",
                        ),
                        explanationPicture: LibraryImage,
                        explanationTitle: i18n.t(
                          "MULTIPLE_CHOICES-question-title",
                        ),
                      },
                      {
                        questionType: "FREE_ANSWER",
                        title: i18n.t("free-answer"),
                        onClick: this.onCreateQuestionType("FREE_ANSWER"),
                        explanationDetails: i18n.t("FREE_ANSWER-explanation"),
                        explanationPicture: LibraryImage,
                        explanationTitle: i18n.t("FREE_ANSWER-question-title"),
                      },
                    ]}
                    scrollToBottom={this.scrollToBottom}
                    title={i18n.t("add-new-question")}
                  />
                )}
                <Typography
                  className={classes.explanation}
                  dangerouslySetInnerHTML={{
                    __html:
                      questions.length > 1
                        ? i18n.t("move-arrows-to-see-questions", {
                            interpolation: { escapeValue: false },
                          })
                        : i18n.t("add-questions", {
                            interpolation: { escapeValue: false },
                          }),
                  }}
                ></Typography>
              </div>
            </div>
            <div className={classes.rightSide}>{this.renderRightSide()}</div>
          </div>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  const language = getAppLanguage(state);
  const clientId = getSelectedClientId(state);
  const userClientId = getUserClientId(state);
  const userId = getUserId(state);
  const {
    questions,
    rightSideScreen,
    questionLoading,
    questionSelectedIndex,
    requesting,
    title,
    _id,
  } = state.survey;
  const { languageSelected } = state.survey;
  const isSuperAdmin = getIsSuperAdmin(state);
  const isSuperAdminClientSelected = getIsSuperAdminClientSelected(state);
  return {
    surveyId: _id,
    questions,
    title,
    requesting,
    isSuperAdmin,
    isSuperAdminClientSelected,
    language,
    rightSideScreen,
    clientId,
    userId,
    userClientId,
    questionLoading,
    questionSelectedIndex,
    languageSelected,
  };
};

export default connect(mapStateToProps, {
  createNewQuestion,
  changeField,
  reorderQuestions,
  removeQuestion,
  createSurveyRequest,
  modifySurveyRequest,
})(withStyles(styles)(SurveyBuilder));
