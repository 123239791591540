import React, { useEffect, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import Folder from "./Folder";
import SeeGridIcon from "scenes/CreateTrack/FolderList/SeeGridIcon";
import AddFolderIcon from "scenes/CreateTrack/FolderList/AddFolderIcon";
import IconButton from "@mui/material/IconButton";
import i18n from "i18n";
import { Typography } from "@mui/material";
import CreateFolder from "scenes/CreateTrack/FolderList/CreateFolder";
import { getFolderListRequest } from "services/createTrack/actions";
import {
  getIsModifyForbidden,
  getIsSuperAdmin,
  getSelectedClientId,
  getUserClientId,
} from "services/user/selectors";
import FolderSettings from "scenes/CreateTrack/FolderList/FolderSettings";
import Colors from "constants/Colors";
import FolderIcon from "./FolderIcon";
import CloseIcon from "components/SelectInput/CloseIcon";

const styles = theme => ({
  main: {
    maxWidth: "100%",
    height: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  folders: {
    height: "100%",
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    overflow: "scroll",
    scrollbarWidth: "thin",
  },
  actions: {
    paddingLeft: 5,
    height: "100%",
    borderLeft: `1px solid ${Colors.lightGrey}`,
    right: 0,
    marginLeft: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  grid: {
    height: "100%",
    paddingBottom: 50,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  pivot: {
    transform: "rotate(180deg)",
  },
  empty: {
    width: "100%",
    alignSelf: "center",
    textAlign: "center",
    color: "#CBCBCB",
  },
  noFolder: {
    color: "#717780",
    fontSize: 15,
    marginLeft: 10,
  },
  noFolderDiv: {
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": { backgroundColor: Colors.hoverBlue },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    padding: "3px 5px",
    marginLeft: 12,
  },
  folder: {
    marginBottom: "1px",
  },
  folderSelectedDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  closeIcon: {
    marginLeft: 5,
  },
});

const FolderList = ({
  classes,
  folderList = {},
  folderSelected = {},
  clientId,
  getFolderListRequest,
  onClickFolder = () => null,
  isModifyForbidden = false,
}) => {
  const [isGridVisible, setGridVisible] = useState(false);
  const [isCreating, setCreating] = useState(false);
  const { folders = [] } = folderList;

  useEffect(() => {
    getFolderListRequest({ clientId });
  }, [clientId, getFolderListRequest]);

  // useEffect(() =>{
  //   if (folders.length !== folders.)

  // }, [folders])

  const onClickNewFolder = () => {
    setCreating(true);
  };

  const onClickSeeGrid = () => {
    setGridVisible(!isGridVisible);
  };

  const handleFolderClick = folderSelected => () => {
    onClickFolder(folderSelected)();
    onClickSeeGrid();
  };

  const handleCloseFolder = () => {
    onClickFolder(folderSelected)();
  };

  return (
    <div className={`${classes.main} ${isGridVisible && classes.grid}`}>
      {!isGridVisible && (
        <div className={classes.folders}>
          {folders.length < 1 && !isCreating && (
            <Typography className={classes.empty}>
              {i18n.t("create-your-first-folder")}
            </Typography>
          )}
          {!isModifyForbidden && isCreating && (
            <CreateFolder
              selectFolder={onClickFolder}
              setCreating={setCreating}
            />
          )}
          {folderSelected.value ? (
            <div className={classes.folderSelectedDiv}>
              <IconButton
                className={classes.closeIcon}
                onClick={handleCloseFolder}
                size="large"
              >
                <CloseIcon />
              </IconButton>
              <Folder
                {...folderSelected}
                onClick={handleFolderClick(folderSelected)}
                isSelected
              />
            </div>
          ) : (
            <div className={classes.noFolderDiv} onClick={onClickSeeGrid}>
              <FolderIcon
                className={classes.folder}
                color={"#717780"}
                width={25}
                height={13}
              />
              <Typography className={classes.noFolder}>
                {i18n.t("select-folder")}
              </Typography>
            </div>
          )}
        </div>
      )}
      {!isGridVisible && (
        <div className={classes.actions}>
          {!isModifyForbidden && (
            <IconButton
              className={classes.iconButton}
              onClick={onClickNewFolder}
              size="large"
            >
              <AddFolderIcon />
            </IconButton>
          )}
          {folders.length > 0 && (
            <IconButton
              className={`${classes.iconButton} ${
                isGridVisible && classes.pivot
              }`}
              onClick={onClickSeeGrid}
              size="large"
            >
              <SeeGridIcon />
            </IconButton>
          )}
        </div>
      )}
      {isGridVisible && (
        <FolderSettings
          onClickSeeGrid={onClickSeeGrid}
          onClickFolder={handleFolderClick}
          folders={folders}
          folderSelected={folderSelected}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const isSuperAdmin = getIsSuperAdmin(state);
  const selectedClientId = getSelectedClientId(state);
  const userClientId = getUserClientId(state);

  const clientId = isSuperAdmin ? selectedClientId : userClientId;
  const isModifyForbidden = getIsModifyForbidden(state);

  return {
    folderList: state.createTrack.folderList,
    clientId,
    isModifyForbidden,
  };
};

export default connect(mapStateToProps, { getFolderListRequest })(
  withStyles(styles)(FolderList),
);
