import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchApi } from "services/api";
import { sendEmailInvitationsRequest } from "services/auth/actions";
import { getAuthRequesting } from "services/auth/selectors";
import {
  getCampaignAccessCode,
  getDynamicLink,
} from "services/campaign/selectors";
import { getAppLanguage } from "services/general/selectors";
import {
  getIsUserSelectedATestAccount,
  getUserSelectedId,
} from "services/manager/selectors";
import {
  getSelectedClientId,
  getUserEmail,
  getUserFirstName,
  getUserId,
} from "services/user/selectors";

const ENDPOINT = "/v1/users/dynamic-auth-link/generate";

export const useGetDynamicAuthLink = () => {
  const [error, setError] = useState({ message: "" });
  const dynamicLink = useSelector(getDynamicLink);

  const [link, setLink] = useState(dynamicLink);
  const [requesting, setRequesting] = useState(false);
  const accessCode = useSelector(getCampaignAccessCode);
  const isTestAccount = useSelector(getIsUserSelectedATestAccount);
  const userSelectedId = useSelector(getUserSelectedId);
  const userId = useSelector(getUserId);
  // const

  const sendRequest = async ({ route = "Home" }) => {
    setRequesting(true);
    setError({ message: "" });
    try {
      const response = await fetchApi(
        ENDPOINT,
        {
          userId: isTestAccount ? userSelectedId : userId,
          accessCode,
          route,
        },
        "post",
      );
      console.log({ response });
      setLink(response);
    } catch (e) {
      setError(e);
    } finally {
      setRequesting(false);
    }
  };

  return [requesting, sendRequest, link, error] as const;
};

export const useSendEmailInvitation = () => {
  const language = useSelector(getAppLanguage);
  const firstName = useSelector(getUserFirstName);
  const email = useSelector(getUserEmail);
  const clientId = useSelector(getSelectedClientId);
  const requesting = useSelector(getAuthRequesting);

  const dispatch = useDispatch();

  const accessCode = useSelector(getCampaignAccessCode);
  const userId = useSelector(getUserId);

  const sendRequest = async (link: string) => {
    dispatch(
      sendEmailInvitationsRequest({
        emails: [{ email, role: "user" }],
        dynamicLink: link,
        accessCode,
        userId,
        clientId,
        firstName,
        language,
        lastName: "",
      }),
    );
  };

  return [requesting, sendRequest] as const;
};
