import React from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography } from "@mui/material";
import i18n from "i18n";
import FeelingBar from "scenes/Campaigns/CampaignScreen/StatsSide/FeelingSection/FeelingBar";
import Colors from "constants/Colors";

const styles = theme => ({
  main: {
    width: "100%",
    paddingRight: 45,
    paddingLeft: 45,
  },
  topDiv: {
    marginTop: 25,
    marginBottom: 35,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  firstTitle: {
    marginRight: 100,
    textAlign: "left",
  },
  secondTitle: {
    marginLeft: 50,
    textAlign: "left",
  },
  lastTitle: {
    textAlign: "right",
    flex: 1,
  },
  titleBar: {
    width: 300,
  },
});

const SliderBars = ({ classes, stats = [] }) => {
  return (
    <div className={classes.main}>
      <div className={classes.topDiv}>
        <Typography className={classes.firstTitle}>
          {i18n.t("skill")}
        </Typography>
        <Typography className={classes.secondTitle}>
          {i18n.t("niv-current")}
        </Typography>
        <Typography className={classes.lastTitle}>
          {i18n.t("niv-max")}
        </Typography>
      </div>

      <div className={classes.bottomDiv}>
        {stats.map((stat, index) => (
          <FeelingBar
            titleClassName={classes.titleBar}
            key={index}
            number={stat.value}
            color={Colors.blue}
            title={stat.title}
          />
        ))}
      </div>
    </div>
  );
};

export default withStyles(styles)(SliderBars);
