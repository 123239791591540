import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import RecordCameraIcon from './Icons/RecordCameraIcon';
import RecordScreenIcon from './Icons/RecordScreenIcon';
import RecordAudioIcon from './Icons/RecordAudioIcon';
import i18n from 'i18n';
import { MainText } from './Texts/MainText';
import Colors from 'constants/Colors';

const useStyles = makeStyles((theme) => ({
   main: {
	   marginRight: 20,
	padding: '1.5rem 0.5rem',
	borderRadius: "0.25rem",
	border: '1px solid #e6e9ef',
	background: '#fff',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	flexDirection: 'column',
	cursor: 'pointer',
	transition: 'all 0.2s ease',
	width: 160,
	height: 120,

	'&:hover': { backgroundColor: Colors.hoverBlue},
    },
    text: {
	width: 120,
	height: 45,
	    marginTop: "1rem",
	    fontSize: '.875rem',
    fontWeight: 500,
    color: Colors.text,
    textAlign: 'center'
    }
}));


interface Props {
	action: "recordScreen" | "recordCamera" | "recordAudio";
	onClick: () => void;
  }

  const list = {
	  "recordScreen": { icon: <RecordScreenIcon />, text: i18n.t('record-screen') },
	  "recordCamera": { icon: <RecordCameraIcon />, text: i18n.t('record-camera') },
	  "recordAudio": { icon: <RecordAudioIcon />, text: i18n.t('record-audio') },
  }

const MediaActionBlock = ({ action = "recordScreen", onClick }: Props) => {
const classes = useStyles();


    return (
	<div className={classes.main} onClick={onClick}>
			{list[action].icon}
			<MainText className={classes.text} text={list[action].text} />
		
		</div>
    )
}

export default MediaActionBlock;