import React from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography } from "@mui/material";
import Colors from "constants/Colors";

const styles = theme => ({
  main: {
    cursor: "pointer",
    width: 90,
    height: 45,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginLeft: 15,
    borderRadius: 4,
    border: `solid 1px ${Colors.lightGrey}`,
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: Colors.hoverBlue,
    },
  },
  boxSelected: {
    color: Colors.pureMain,
    fontWeight: "bold",
    borderWidth: 2,
    borderColor: Colors.pureMain,
  },
  title: {
    color: Colors.text,
    fontWeight: "inherit",
  },
});

const TextBox = ({ classes, className, title, isSelected, onClick }) => {
  return (
    <div
      className={`${classes.main} ${className} ${
        isSelected && classes.boxSelected
      }`}
      onClick={onClick}
    >
      <Typography className={classes.title}>{title}</Typography>
    </div>
  );
};

export default withStyles(styles)(TextBox);
