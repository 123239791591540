import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import withStyles from '@mui/styles/withStyles';

import TopBar from "components/TopBar";
import i18n from "i18n";
import FeelingEvolution from "scenes/Campaigns/CampaignScreen/StatsSide/FeelingSection/FeelingEvolution";
import InitialFeelingChart from "scenes/Campaigns/CampaignScreen/StatsSide/FeelingSection/InitialFeelingChart";
import { sendCampaignEvolutionSurveyRequest } from "services/survey/actions";
import { flatten } from "lodash";
import { getSurveyRequesting } from "services/survey/selectors";

const styles = theme => ({
  main: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "row",
  },
  firstBox: {
    height: 420,
    marginRight: 30,
    overflow: "hidden",
    marginTop: 30,
    paddingBottom: 30,
    borderRadius: 10,
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flex: 5,
  },
  secondBox: {
    width: 350,
    height: 420,
    overflow: "hidden",
    marginTop: 30,
    paddingBottom: 35,
    borderRadius: 10,
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
});

const formatStats = stats => {
  const totalCount = stats.length;
  const sumStats = stats.reduce((prev, curr) => {
    const keys = Object.keys(curr);
    keys.map(value => (prev[value] = (prev[value] || 0) + curr[value]));
    return prev;
  }, {});

  const keys = Object.keys(sumStats);
  keys.forEach(el => (sumStats[el] = parseInt(sumStats[el] / totalCount)));

  return sumStats;
};

const formatEvolution = (evolution, stats) => {
  const totalCount = evolution.length;
  if (!totalCount) return {};
  const flattened = flatten(evolution);
  if (!flattened.length) return {};
  let data = {
    users: 0,
    stress: 0,
    confidence: 0,
    publicSpeaking: 0,
    teamCohesion: 0,
    productivity: 0,
  };

  flattened.forEach(el => {
    data["stress"] += el.stress;
    data["confidence"] += el.confidence;
    data["publicSpeaking"] += el.publicSpeaking;
    data["teamCohesion"] += el.teamCohesion;
    data["productivity"] += el.productivity;
  });

  data.users = evolution.length || 1;

  // lastMonth data for the moment
  const result = { ...data };
  if (!result) return {};
  const keys = Object.keys(result).filter(el => el !== "users");
  keys.forEach(key => {
    result[key] = parseInt(
      (result[key] / result.users / (stats[key] || 1)) * 100,
    );
  });

  return result;
};

const formatCustomStatEvolution = evolution => {
  const totalCount = evolution.length;
  if (!totalCount) return {};
  const flattened = flatten(evolution);
  if (!flattened.length) return {};

  const lastData = flattened[flattened.length - 1];
  const result = { ...lastData };
  delete result["date"];

  Object.keys(result).map(key => {
    if (!isNaN(result[key])) {
      // result[key] = result[key].toFixed(1);
    }
    return key;
  });
  const keys = Object.keys(result);
  keys.forEach(key => {
    result[key] = parseInt(result[key]);
  });

  return result;
};

const FeelingSection = ({
  classes,
  className,
  stats = [],
  feelingEvolution = [],
  hasEvolution = true,
  hasOnboardingSurvey = false,
  hasSentEvolutionSurvey = false,
}) => {
  const formattedStats = formatStats(stats);
  const dispatch = useDispatch();

  const handleClickSendSurveyLink = () =>
    dispatch(sendCampaignEvolutionSurveyRequest());

  const requesting = useSelector(getSurveyRequesting);

  return (
    <div className={`${classes.main} ${className}`}>
      <div className={classes.firstBox}>
        <TopBar title={i18n.t("initial-feeling")} />
        <InitialFeelingChart
          stats={formattedStats}
          hasOnboardingSurvey={hasOnboardingSurvey}
        />
      </div>
      {hasEvolution && (
        <div className={classes.secondBox}>
          <TopBar
            title={i18n.t("feeling-evolution")}
            hasLink={!hasSentEvolutionSurvey}
            onClickLink={handleClickSendSurveyLink}
            requesting={requesting}
          />
          <FeelingEvolution
            feelingEvolution={
              hasOnboardingSurvey
                ? formatCustomStatEvolution(feelingEvolution)
                : formatEvolution(feelingEvolution, formattedStats)
            }
            isCustomStats={hasOnboardingSurvey}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(withStyles(styles)(FeelingSection));
