import React, { useEffect } from "react";
import { Checkbox, IconButton, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import i18n from "i18n";
import Uploader from "components/Uploader";
import { useDispatch, useSelector } from "react-redux";
import { getUserId } from "services/user/selectors";
import { getUploadedPicture, getUploading } from "services/upload/selectors";
import { resetUpload, uploadPictureRequest } from "services/upload/actions";
import CloseIcon from "scenes/CreateTrack/Skills/CloseIcon";

const avatarImages = [
  "https://pulse-experience.s3.eu-west-3.amazonaws.com/images/avatars/Cocotte.png",
  "https://pulse-experience.s3.eu-west-3.amazonaws.com/images/avatars/Francis.png",
  "https://pulse-experience.s3.eu-west-3.amazonaws.com/images/avatars/Lalie.png",
  "https://pulse-experience.s3.eu-west-3.amazonaws.com/images/avatars/Po.png",
  "https://pulse-experience.s3.eu-west-3.amazonaws.com/images/avatars/Kiki.png",
  "https://pulse-experience.s3.eu-west-3.amazonaws.com/images/avatars/Minou.png",
  "https://pulse-experience.s3.eu-west-3.amazonaws.com/images/avatars/Volant.png",
  "https://pulse-experience.s3.eu-west-3.amazonaws.com/images/avatars/Croquette.png",
];

const UPLOADER_SIZE = 100;
const AVATAR_SIZE = 45;
const AVATARS_VIEW_WIDTH = 240;

const useStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  intro: {
    color: "#202020",
  },
  subtitle: {
    fontFamily: "DM Sans",
    color: "#454545",
    marginBottom: 20,
  },
  avatars: {
    alignSelf: "center",
    width: AVATARS_VIEW_WIDTH,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  avatarView: {
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": {
      opacity: 0.9,
    },
    width: AVATAR_SIZE + 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginBottom: 15,
  },
  avatar: {
    height: AVATAR_SIZE,
    width: AVATAR_SIZE,
    borderRadius: AVATAR_SIZE / 2,
  },
  uploader: {
    alignSelf: "center",
    borderRadius: "50%",
  },
  separatorView: {
    marginTop: 30,
    marginBottom: 35,
    alignSelf: "center",
    position: "relative",
    width: AVATARS_VIEW_WIDTH,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  separator: {
    zIndex: 1,
    position: "absolute",
    left: 0,
    width: AVATARS_VIEW_WIDTH,
    height: 1,
    backgroundColor: "#F3F3F3",
  },
  or: {
    zIndex: 2,
    color: "#A8A9B7",
    fontFamily: "DM Sans",
    paddingRight: 15,
    paddingLeft: 15,
    backgroundColor: "inherit",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  checkDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  checkBox: {
    marginLeft: -9,
  },
  checkText: {
    color: "#A7A7A7",
  },
  avatarDisabled: {
    filter: "grayscale(1)",
  },
  rootCheckBox: {
    color: "#A7A7A7",
    // paddingLeft: 0,
  },
  pictureDiv: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  remove: {
    position: "absolute",
    right: 40,
  },
}));

interface Props {
  picture: string;
  handlePictureChange: (picture: string) => void;
  hasAvatars: boolean;
  handleHasAvatars: (hasAvatars: boolean) => (e: any) => void;
  introText?: string;
}

const AvatarSide = ({
  picture,
  handlePictureChange,
  hasAvatars,
  handleHasAvatars,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const uploading = useSelector(getUploading);
  const uploadedPicture = useSelector(getUploadedPicture);

  useEffect(() => {
    if (uploadedPicture) {
      handlePictureChange(uploadedPicture);
    }
  }, [uploadedPicture, handlePictureChange]);

  const onFileUploaded = async files => {
    console.log("FILES");
    console.log(files);
    console.log(files[0]);

    const date = new Date().getTime();
    dispatch(
      uploadPictureRequest({
        urlName: `profile-picture/${userId}/${date}`,
        file: files[0],
      }),
    );
  };

  const handleAvatarClick = avatar => () => {
    if (hasAvatars) {
      handlePictureChange(avatar);
    }
  };

  const handleRemoveClick = () => {
    handlePictureChange("");
    dispatch(resetUpload());
  };

  return (
    <div className={classes.main}>
      <Typography className={classes.intro}>
        {i18n.t("coachee-picture")}
      </Typography>
      <Typography className={classes.subtitle}>
        {i18n.t("select-picture-or-avatar")}
      </Typography>
      <div className={classes.pictureDiv}>
        <Uploader
          width={UPLOADER_SIZE}
          height={UPLOADER_SIZE}
          className={classes.uploader}
          borderRadius={"50%"}
          isSmall
          uploading={uploading}
          picture={picture}
          onFileUploaded={onFileUploaded}
        />
        <IconButton className={classes.remove} onClick={handleRemoveClick} size="large">
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.separatorView}>
        <Typography className={classes.or}>{i18n.t("OR")}</Typography>
        <div className={classes.separator} />
      </div>
      <div className={classes.avatars}>
        {avatarImages.map(avatar => (
          <div
            key={avatar}
            className={classes.avatarView}
            onClick={handleAvatarClick(avatar)}
          >
            <img
              src={avatar}
              className={`${classes.avatar} ${
                !hasAvatars && classes.avatarDisabled
              }`}
              alt={avatar}
            />
          </div>
        ))}
      </div>
      <div className={classes.checkDiv}>
        <Checkbox
          className={classes.checkBox}
          classes={{ root: classes.rootCheckBox }}
          checked={!hasAvatars}
          onChange={handleHasAvatars(!hasAvatars)}
        />
        <Typography className={classes.checkText}>
          {i18n.t("disable-avatars")}
        </Typography>
      </div>
    </div>
  );
};

export default AvatarSide;
