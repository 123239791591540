import React, { PureComponent } from "react";
import { connect } from "react-redux";

import moment from "moment";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import Colors from "../../constants/Colors";
import AddIcon from "@mui/icons-material/Add";
import Fuse from "fuse.js";
import {
  getAllTemplatesRequest,
  addCampaignToTemplateRequest,
} from "../../services/campaign/actions";
import { navigateCreateTrack } from "../../services/createTrack/actions";
import { push } from "connected-react-router";
import "moment/locale/fr";
import { Loader } from "../../components";
import NewCampaignModal from "./NewCampaignModal";
import selectLanguage from "../../utils/selectLanguage";
import CampaignBox from "./CampaignBox";
import i18n from "../../i18n";
import { getUserId, getUserClientId } from "../../services/user/selectors";
import { getAppLanguage } from "../../services/general/selectors";
import SearchBar from "../../components/SearchBar";

moment.locale("fr");

const fuseOptions = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 80,
  maxPatternLength: 20,
  minMatchCharLength: 1,
  keys: [
    "title.fr",
    "title.en",
    "description.fr",
    "description.en",
    "label",
    "notes",
  ],
};

const styles = theme => ({
  main: {
    width: "90%",
    marginTop: 15,
    flexDirection: "column",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  paper: {
    flex: 1,
    marginTop: "calc(1rem)",
    marginLeft: "-1.5rem",
    marginRight: "-1.5rem",
    marginBottom: "calc(-3rem)",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "row",
  },
  createDiv: {
    flex: "0 0 auto",
    padding: "0px 1.5rem calc(2rem)",
  },
  createCampaign: {
    textDecoration: "none",
    width: 300,
    height: 450,
    borderRadius: 8,
    backgroundColor: Colors.main,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    transition: "0.2s",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
  createPollText: {
    color: "#fff",
  },
  topDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  searchBar: {
    marginTop: 5,
    marginBottom: 5,
    minWidth: 300,
    width: 300,
    flex: 0,
  },
  inputClassName: {
    border: "1px solid rgba(0,0,0,.09)",
    backgroundColor: "#fff",
  },
});

class MyTemplates extends PureComponent {
  state = {
    expanded: null,
    labelWidth: 0,
    search: "",
    isCreationModalVisible: false,
  };

  componentDidMount() {
    this.props.getAllTemplatesRequest({
      clientId: this.props.clientId,
      isPrivate: true,
    });
  }

  onChangeTime = event => {
    const time = event.target.value;
    const minute = Number(time.split(":")[1]);
    const hour = Number(time.split(":")[0]);

    const reminderTime = moment(this.props.reminderTime)
      .minute(minute)
      .hour(hour);
    this.props.changeCoffeeSettings({ reminderTime });
  };

  onClickMore = () => {};

  onClickTrack = trackId => () => {
    this.props.navigateCreateTrack({ trackId });
  };

  onAddTemplate = ({ trackId, isTemplate }) => {
    this.props.addCampaignToTemplateRequest({ trackId, isTemplate });
  };

  displayTemplates = templates => {
    const { language } = this.props;
    return templates.map((el, i) => {
      return (
        <CampaignBox
          key={i}
          {...el}
          trackId={el._id}
          participants={[]}
          showSteps
          hasDelete={false}
          onAddTemplate={this.onAddTemplate}
          onClick={this.onClickTrack(el._id)}
          title={selectLanguage({ text: el.title, language })}
          description={selectLanguage({ text: el.description, language })}
          hoverText={i18n.t("open-template")}
        />
      );
    });
  };

  onChangeSearch = search => {
    this.setState({ search });
  };

  onCloseCreationModal = () => {
    this.setState({ isCreationModalVisible: false });
  };

  displayCreationModal = () => {
    this.setState({ isCreationModalVisible: true });
  };

  render() {
    const { classes, templates } = this.props;
    const { search } = this.state;

    const fuse = new Fuse(templates, fuseOptions);
    const searchResults = search.length > 0 ? fuse.search(search) : templates;

    return (
      <div className={classes.main}>
        <NewCampaignModal
          openFromTemplate
          isVisible={this.state.isCreationModalVisible}
          onClose={this.onCloseCreationModal}
        />
        <div className={classes.topDiv}>
          <SearchBar
            placeholder={i18n.t("search-templates-placeholder")}
            className={classes.searchBar}
            inputClassName={classes.inputClassName}
            onChangeSearch={this.onChangeSearch}
            value={this.state.search}
          />
        </div>
        <div className={classes.paper}>
          <div className={classes.createDiv}>
            <div
              className={classes.createCampaign}
              onClick={this.displayCreationModal}
            >
              <AddIcon style={{ color: "#fff" }} />
              <Typography className={classes.createPollText}>
                {i18n.t("new-template")}
              </Typography>
            </div>
          </div>
          {this.props.requesting ? (
            <Loader size={75} />
          ) : (
            this.displayTemplates(searchResults)
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { requesting, templates } = state.campaign;
  const language = getAppLanguage(state);
  const clientId = getUserClientId(state);
  const userId = getUserId(state);
  return {
    token: state.auth.token,
    userId,
    language,
    requesting,
    clientId,
    templates,
  };
};

export default connect(mapStateToProps, {
  addCampaignToTemplateRequest,
  getAllTemplatesRequest,
  navigateCreateTrack,
  push,
})(withStyles(styles)(MyTemplates));
