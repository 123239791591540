import React from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography } from "@mui/material";
import Colors from "constants/Colors";
import { getUserId } from "services/user/selectors";
import { connect } from "react-redux";
import i18n from "i18n";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const PICTURE_WIDTH = 35;

const styles = theme => ({
  main: {
    position: "relative",
    marginRight: 12,
  },
  picture: {
    border: "solid",
    borderWidth: 2,
    borderColor: Colors.orange,
    objectFit: "cover",
    cursor: "pointer",
    width: PICTURE_WIDTH,
    height: PICTURE_WIDTH,
    borderRadius: PICTURE_WIDTH / 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  tooltip: {
    // color: "#fff",
    // color: Colors.darkBlue,
    backgroundColor: Colors.darkBlue,
    // lineHeight: "16px",
    // borderRadius: 4,
    // paddingRight: 10,
    // paddingLeft: 10,
    // fontSize: 12,
    // fontWeight: "600",
    // maxWidth: 300,
    // border: "none",

    // fontFamily: "Roboto",
    // fontFamily: "-apple-system, Roboto",
    fontFamily: "Roboto",
  },
  letter: {
    color: "#fff",
    fontWeight: "bold",
  },
  active: {
    position: "absolute",
    bottom: 0,
    right: 0,
    height: 8,
    width: 8,
    borderRadius: "50%",
    backgroundColor: Colors.strongGreen,
  },
  noPicture: {
    backgroundColor: Colors.pureMain,
  },
  me: {
    borderColor: Colors.pureMain,
  },
});

const AdminAvatar = ({
  classes,
  picture,
  firstName,
  userId,
  myUserId,
  onClick = null,
}) => {
  const isMe = userId === myUserId;

  return (
    <Tippy
      content={isMe ? `${firstName} (${i18n.t("you")})` : firstName}
      className={classes.tooltip}
    >
      <div className={classes.main} onClick={onClick}>
        {!!picture ? (
          <img
            data-for={userId}
            data-tip={isMe ? `${firstName} (${i18n.t("you")})` : firstName}
            className={`${classes.picture} ${isMe && classes.me}`}
            src={picture}
            alt={firstName}
          />
        ) : (
          <div
            data-for={userId}
            className={`${classes.picture} ${classes.noPicture} ${
              isMe && classes.me
            }`}
            data-tip={isMe ? `${firstName} (${i18n.t("you")})` : firstName}
          >
            <Typography className={classes.letter}>
              {firstName?.[0]?.toUpperCase()}
            </Typography>
          </div>
        )}
      </div>
    </Tippy>
  );
};

const mapStateToProps = state => {
  return {
    myUserId: getUserId(state),
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(AdminAvatar));
