import React from "react";
import withStyles from '@mui/styles/withStyles';
import Logo from "../../resources/MicrodoingLogo";
import WhiteLogo from "../../resources/MicrodoingWhiteLogo";

const styles = theme => ({
  logo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "40px auto",
  },
  "@media (max-width: 960px)": {
    logo: {
      margin: "30px auto 30px",
    },
  },
});

const LogoDiv = ({ classes, isWhite = false }) => {
  const isSmall = window.innerWidth < 960;
  return (
    <div alt="Pulse Logo" className={classes.logo}>
      {isWhite ? (
        <WhiteLogo sizePercentage={0.9} />
      ) : (
        <Logo sizePercentage={isSmall ? 1.8 : 1.8} />
      )}
    </div>
  );
};

export default withStyles(styles)(LogoDiv);
