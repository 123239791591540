import React from "react";

const DownloadIcon = ({ width = 18, height = 18, color = "#007EFC" }) => (
  <svg
    width={width}
    height={height}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 18 18"
  >
    <path
      d="M11.992 9.607a.802.802 0 0 0 .236-.619.877.877 0 0 0-.258-.618.853.853 0 0 0-.63-.247.853.853 0 0 0-.63.247l-.81.81V6.278a.833.833 0 0 0-.26-.63A.89.89 0 0 0 9 5.4a.873.873 0 0 0-.642.258.873.873 0 0 0-.258.642v2.88l-.832-.832a.805.805 0 0 0-.62-.237.88.88 0 0 0-.618.259.853.853 0 0 0-.248.63c0 .255.083.465.248.63l2.34 2.34c.18.18.39.27.63.27s.45-.09.63-.27l2.362-2.363ZM18 9a8.759 8.759 0 0 1-.71 3.51 9.082 9.082 0 0 1-1.922 2.857 9.082 9.082 0 0 1-2.858 1.924A8.759 8.759 0 0 1 9 18a8.759 8.759 0 0 1-3.51-.71 9.082 9.082 0 0 1-2.858-1.922A9.095 9.095 0 0 1 .708 12.51 8.769 8.769 0 0 1 0 9c0-1.245.236-2.415.708-3.51a9.095 9.095 0 0 1 1.925-2.858A9.082 9.082 0 0 1 5.49.71 8.759 8.759 0 0 1 9 0c1.245 0 2.415.236 3.51.71a9.082 9.082 0 0 1 2.857 1.922 9.082 9.082 0 0 1 1.924 2.858A8.759 8.759 0 0 1 18 9Zm-1.8 0c0-1.995-.701-3.694-2.103-5.097C12.694 2.501 10.995 1.8 9 1.8c-1.995 0-3.694.701-5.097 2.103C2.501 5.306 1.8 7.005 1.8 9c0 1.995.701 3.694 2.103 5.096C5.306 15.499 7.005 16.2 9 16.2c1.995 0 3.694-.701 5.097-2.104C15.499 12.694 16.2 10.995 16.2 9Z"
      fill={color}
    />
  </svg>
);

export default DownloadIcon;
