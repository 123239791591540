import {
  call,
  put,
  takeLatest,
  all,
  takeEvery,
  take,
} from "redux-saga/effects";
import { eventChannel, END } from "redux-saga";
import _throttle from "lodash-es/throttle";

import {
  UPLOAD_PICTURE_REQUESTING,
  UPLOAD_PICTURE_SUCCESS,
  UPLOAD_PICTURE_ERROR,
  SEARCH_UNSPLASH_ERROR,
  SEARCH_UNSPLASH_REQUESTING,
  SEARCH_UNSPLASH_SUCCESS,
  GET_RANDOM_UNSPLASH_REQUESTING,
  GET_RANDOM_UNSPLASH_SUCCESS,
  GET_RANDOM_UNSPLASH_ERROR,
  UPLOAD_FILE_REQUESTING,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_ERROR,
  DOWNLOAD_FILE_SUCCESS,
  DOWNLOAD_FILE_ERROR,
  DOWNLOAD_FILE_REQUESTING,
  EXPORT_VIDEO_DONE,
} from "./constants";

import { fetchApi } from "../api";
import { changeUploadProgress } from "./actions";
import superagent from "superagent";
import { openSnackMessage } from "services/general/actions";
import i18n from "i18n";

const endPoints = {
  getUploadUrlS3: "/v1/upload/sign-s3",
  getUploadUrl: "/v1/upload/get-upload-url",
  downloadFile: "/v1/upload/download", // get
  searchUnsplash: "/v1/unsplash/search",
  randomUnsplash: "/v1/unsplash/random",
};

interface GetUploadUrlApiProps {
  urlName: string;
  fileType?: string;
  modelToUpdate?: string;
  modelIdToUpdate?: string;
  fileUrl?: string;
  filePreview?: string;
  data?: {};
}

const getUploadUrlApi = async ({
  urlName,
  fileType = "image/jpg",
  modelToUpdate = "stepFeedback",
  modelIdToUpdate = "",
  fileUrl = "",
  filePreview = "",
  data = {},
}: GetUploadUrlApiProps) => {
  return await fetchApi(
    `${endPoints.getUploadUrl}`,
    {
      fileType,
      fileName: urlName,
      modelToUpdate,
      modelIdToUpdate,
      fileUrl,
      filePreview,
      data,
    },
    "post",
  );
};

const turnBlobUrlIntoFile = async blobUrl => {
  return await fetch(blobUrl).then(r => r.blob());
};

const upload = async ({
  file,
  signedRequest,
  fileType = "image/jpg",
  fileName = "",
}) => {
  return eventChannel(emitter => {
    const onProgress = (e: ProgressEvent) => {
      if (e.lengthComputable) {
        const progress = e.loaded / e.total;
        emitter({ progress });
      }
    };

    const req = superagent
      .put(signedRequest)
      .send(file)
      .on("progress", _throttle(onProgress, 500));

    req.then(
      res => {
        emitter({ result: res.body });
        emitter(END);
      },
      err => {
        emitter({ error: err });
        emitter(END);
      },
    );

    return () => {
      req.abort();
    };
  });
};

const uploadS3WithoutProgress = async ({ file, signedRequest }) => {
  const options = {
    method: "PUT",
    body: file,
  };
  const response = await fetch(signedRequest, options);
  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`);
  }
  return response;
};

// const uploadS3 = async (file, signedRequest, progressCallback) => {
//   return await uploadFile(file, signedRequest, progressCallback);
// };

const downloadFileApi = async ({ fileName, fileType, urlName }) => {
  const s3Url = await fetchApi(
    `${endPoints.downloadFile}?file-name=${fileName}&file-extension=${fileType}&url-name=${urlName}`,
    {},
    "get",
  );

  return s3Url;
};

const searchUnsplashApi = async payload => {
  return await fetchApi(endPoints.searchUnsplash, payload, "post");
};

const getRandomUnsplashApi = async payload => {
  return await fetchApi(endPoints.randomUnsplash, payload, "post");
};

function* uploadPictureFlow(action) {
  try {
    const { file, urlName } = action.payload;

    const resData = yield call(getUploadUrlApi, { urlName });

    const { signedRequest, url } = resData;

    const channel = yield call(upload, { file, signedRequest });

    const result = yield progressListener(channel);
    console.log(result);

    yield put({ type: UPLOAD_PICTURE_SUCCESS, payload: url });
  } catch (error) {
    yield put({ type: UPLOAD_PICTURE_ERROR, payload: error });
  }
}

function* progressListener(channel) {
  while (true) {
    const { progress, result, error } = yield take(channel);
    if (progress) {
      yield put(changeUploadProgress({ progress: progress * 100 }));
    }

    if (result) return result;

    if (error) {
      throw error;
    }
  }
}

export function* uploadFileFlow(action) {
  try {
    const {
      file,
      urlName,
      fileType,
      fileName,
      data = {},
      preview = null,
      modelToUpdate = "stepFeedback",
    } = action.payload;

    const resData = yield call(getUploadUrlApi, {
      urlName,
      fileType,
      modelToUpdate,
      modelIdToUpdate: "",
      fileUrl: file,
      filePreview: preview,
      data,
    });

    const { signedRequest, passthrough = undefined, url } = resData;

    const goodFile =
      file instanceof File ? file : yield call(turnBlobUrlIntoFile, file);

    const channel = yield call(upload, {
      file: goodFile,
      signedRequest,
      fileName,
      fileType,
    });

    yield progressListener(channel);

    return yield put({
      type: UPLOAD_FILE_SUCCESS,
      payload: {
        ...action.payload,
        ...resData,
        fileUrl: url || file,
        video: url,
        passthrough,
        muxVideoId: passthrough,
        fileName,
        fileType,
        uri: url || file,
        filePreview: preview || "",
      },
    });
  } catch (error) {
    yield put({ type: UPLOAD_FILE_ERROR, payload: error });
  }
}

export const uploadPictureWithoutRedux = async ({
  file,
  urlName,
  fileType,
}) => {
  // const dispatch = useDispatch();
  try {
    // dispatch({ action: TEXT_EDITOR_PICTURE_UPLOADING });
    const resData = await getUploadUrlApi({ urlName, fileType });

    const { signedRequest, url } = resData;
    console.log("wait");
    const res = await uploadS3WithoutProgress({ signedRequest, file });
    console.log(res);
    console.log("no wait");
    return url;
  } catch (error) {
    // dispatch({ action: TEXT_EDITOR_PICTURE_UPLOADED_ERROR });
    console.log(error);
  }
};

function* searchUnsplashFlow(action) {
  try {
    const result = yield call(searchUnsplashApi, action.payload);

    yield put({ type: SEARCH_UNSPLASH_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: SEARCH_UNSPLASH_ERROR, payload: error.message });
  }
}

function* getRandomUnsplashFlow(action) {
  try {
    const result = yield call(getRandomUnsplashApi, action.payload);

    yield put({ type: GET_RANDOM_UNSPLASH_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: GET_RANDOM_UNSPLASH_ERROR, payload: error.message });
  }
}

function* downloadFileFlow(action) {
  try {
    const { fileType, fileName, urlName, isExportingVideo } = action.payload;
    const response = yield call(downloadFileApi, {
      fileName,
      // fileName: slugify(fileName),
      fileType,
      urlName,
    });

    if (!response) {
      return yield put({ type: DOWNLOAD_FILE_SUCCESS, payload: {} });
    }

    const downloadButton: any = document.querySelector("#downloadButton");
    downloadButton.href = response;
    downloadButton.click();

    // downloadImage
    yield put(openSnackMessage({ snackMessage: i18n.t("download-success") }));
    yield put({ type: DOWNLOAD_FILE_SUCCESS, payload: {} });
    yield put({ type: EXPORT_VIDEO_DONE })
  } catch (error) {
    console.log(error);
    yield put({ type: DOWNLOAD_FILE_ERROR, payload: error.message });
  }
}

function* Saga() {
  yield all([
    takeLatest(UPLOAD_PICTURE_REQUESTING, uploadPictureFlow),
    takeLatest(SEARCH_UNSPLASH_REQUESTING, searchUnsplashFlow),
    takeLatest(GET_RANDOM_UNSPLASH_REQUESTING, getRandomUnsplashFlow),
    takeEvery(UPLOAD_FILE_REQUESTING, uploadFileFlow),
    takeEvery(DOWNLOAD_FILE_REQUESTING, downloadFileFlow),
  ]);
}

export default Saga;
