import React, { createRef, PureComponent } from "react";
import { connect } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";

import withStyles from "@mui/styles/withStyles";

import "./draggableStyle.css";
import Colors from "../../constants/Colors";
import Header from "./Header";
import Snackbar from "./Snackbar";
import CreateActionTextStep from "./CreateActionTextStep";
import ExistingContentModal from "./ExistingContentModal";

import {
  addStep,
  removeStep,
  changeField,
  createTrackRequest,
  reorderSteps,
  getTagsListRequest,
  getSkillsListRequest,
  getAllStepsRequest,
  getAllObjectivesRequest,
  getMyLibraryTotalCountRequest,
  getPremiumTotalCountRequest,
  getAllCompetenciesRequest,
  createNewStep,
  getCreateTrackRequest,
  updateSearch,
  moveStepSelection,
} from "../../services/createTrack/actions";

import i18n from "../../i18n";
import AdminWarning from "./AdminWarning";
import {
  getSelectedClientId,
  getUserClientId,
  getIsSuperAdmin,
  getUserId,
} from "../../services/user/selectors";
import { getIsSuperAdminClientSelected } from "services/client/selectors";
import {
  getAppLanguage,
  getRouterQuery,
} from "../../services/general/selectors";
import { Typography } from "@mui/material";
import SearchMyActions from "./SearchMyActions";

import { Loader } from "../../components";
import {
  getIsLibrary,
  getTrackId,
  getFolderSelected,
  getCreateTrackSearch,
  getNumberOfSteps,
  getSortSelected,
} from "services/createTrack/selectors";
import CreateSettingsModal from "./CreateSettingsModal";
import HelperBar from "scenes/CreateTrack/HelperBar";
import { sendAdminConnectionSocket } from "services/general/actions";
import StepsList from "scenes/CreateTrack/StepsList";
import WarningSubClient from "scenes/CreateTrack/WarningSubClient";
import FolderList from "scenes/CreateTrack/FolderList";

const styles = theme => ({
  main: {
    width: "100%",
    height: "100%",
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
  },
  bottomSide: {
    position: "relative",
    flex: 1,
    width: "100%",
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  leftSide: {
    position: "relative",
    flex: 1,
    height: "calc(100vh - 60px)",
    pointerEvents: "auto",
    border: "solid",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#ECEBF1",
    overflow: "hidden",
  },
  "@media (max-width: 960px)": {
    leftSide: {
      width: 300,
    },
  },
  rightSide: {
    flex: 2,
    height: "calc(100vh - 60px)",
    overflow: "hidden",
    overflowY: "auto",
  },
  noClick: {
    pointerEvents: "none",
  },
  templateWarning: {
    position: "relative",
  },
  bottomLeft: {
    height: "100%",
    flexGrow: 1,
    flex: 1,
    minWidth: "100%",
    background: "#F5F5F5",
    width: "100%",
    paddingBottom: 140,
  },
  morePadding: {
    paddingBottom: 200,
  },
  searchDiv: {
    width: "100%",
    height: 76,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  explanation: {
    paddingTop: 30,
    paddingLeft: 50,
    paddingRight: 50,
    marginBottom: 30,
    width: "100%",
    flex: 1,
    color: "#AAAAAA",
    fontSize: 16,
    textAlign: "center",
  },
  rightPlaceholder: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.white,
  },
  loader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    paddingBottom: 12,
    paddingTop: 12,
  },
});

class CreateTrack extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      owner: "",
      domain: "",
      isLeftSideHovered: false,
      isCreateSettingsVisible: false,
      isExistingContentModalVisible: false,
      isDragging: false,
      search: "",
      filterSelected: {},
      folderSelected: {},
      sortSelected: "",
    };
    this.listRef = createRef();
  }

  componentDidMount() {
    const { userClientId, isLibrary, clientId, isSuperAdmin } = this.props;

    if (isLibrary) {
      const goodClientId = isSuperAdmin ? clientId : userClientId;
      this.props.getAllStepsRequest({
        clientId: goodClientId,
      });
    } else {
      const { routerQuery } = this.props;
      const { campaignName, campaignId, trackId } = routerQuery;
      this.props.getCreateTrackRequest({
        trackId,
        isEditingCampaign: true,
        noNavigation: true,
        campaignId,
        campaignName,
      });
    }

    window.addEventListener("keydown", this.downHandler);
    window.addEventListener("keyup", this.upHandler);
  }

  componentWillUnmount() {
    const { clientId, userId } = this.props;
    sendAdminConnectionSocket({
      userId,
      clientId,
      trackId: "",
      stepId: "",
      contentSelected: null,
    });
    window.removeEventListener("keydown", this.downHandler);
    window.removeEventListener("keyup", this.upHandler);
  }

  downHandler = e => {
    if (e.keyCode === 40 && this.state.isLeftSideHovered) {
      this.props.moveStepSelection(1);
    }
  };

  upHandler = e => {
    if (e.keyCode === 38 && this.state.isLeftSideHovered) {
      this.props.moveStepSelection(-1);
    }
  };

  selectFilter = skillSelected => () => {
    if (skillSelected?.value === this.props.skillSelected?.value) {
      return this.props.changeField({ skillSelected: {} });
    }
    this.props.changeField({
      skillSelected,
    });
  };

  selectSort = sortSelected => () => {
    if (sortSelected === this.props.sortSelected) {
      return this.props.changeField({ sortSelected: "" });
    }
    this.props.changeField({
      sortSelected,
    });
  };

  onChangeSearch = search => {
    this.props.updateSearch(search);
  };

  onCreateStep = () => {
    this.props.createNewStep();
  };

  onClickSettings = () => {
    this.setState({ isCreateSettingsVisible: true });
  };

  onCloseSettings = () => {
    this.setState({ isCreateSettingsVisible: false });
  };

  renderRightSide = () => {
    const { classes } = this.props;
    if (this.props.rightSideScreen === "createActionTextStep") {
      return <CreateActionTextStep isLibrary={this.props.isLibrary} />;
    }
    return (
      <div className={classes.rightPlaceholder}>
        <Typography className={classes.explanation}>
          {i18n.t("add-challenges-to-campaign")}
        </Typography>
      </div>
    );
  };

  displayLibrary = () => {
    this.setState({ isExistingContentModalVisible: true });
  };

  closeLibrary = () => {
    this.setState({ isExistingContentModalVisible: false });
  };

  onMouseEnter = () => {
    this.setState({ isLeftSideHovered: true });
  };

  onMouseLeave = () => {
    this.setState({ isLeftSideHovered: false });
  };

  onClickFolder = folderSelected => () => {
    if (folderSelected?.value === this.props.folderSelected?.value) {
      return this.props.changeField({
        folderSelected: {},
      });
    }
    this.props.changeField({
      folderSelected,
    });
  };

  render() {
    const {
      classes,
      isLibrary,
      numberOfSteps,
      skillsList = { skills: [] },
      requesting,
    } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.main}>
          <CreateSettingsModal
            onClose={this.onCloseSettings}
            isVisible={this.state.isCreateSettingsVisible}
          />
          <ExistingContentModal
            onClose={this.closeLibrary}
            isVisible={this.state.isExistingContentModalVisible}
          />
          <Snackbar />
          <Header
            isLibrary={isLibrary}
            onClickSettings={this.onClickSettings}
          />
          <WarningSubClient />
          {this.props.isSuperAdmin && (
            <AdminWarning title="ATTENTION vous êtes un Super administrateur - vos actions ont des répercussions sur les clients" />
          )}
          <div className={classes.bottomSide}>
            <div
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
              className={`${classes.leftSide} ${
                this.props.stepLoading && classes.noClick
              }`}
            >
              {this.props.isSuperAdmin && this.props.isTemplate && (
                <AdminWarning
                  title="ATTENTION - ceci est un template"
                  className={classes.templateWarning}
                />
              )}
              <HelperBar
                isLibrary={isLibrary}
                numberOfSteps={numberOfSteps}
                displayLibrary={this.displayLibrary}
                onCreateStep={this.onCreateStep}
              />
              <div className={classes.searchDiv}>
                <SearchMyActions
                  isLibrary={isLibrary}
                  selectFilter={this.selectFilter}
                  selectSort={this.selectSort}
                  filterSelected={this.props.skillSelected}
                  sortSelected={this.props.sortSelected}
                  skillsList={skillsList}
                  placeholder={i18n.t("search-library-placeholder")}
                  search={this.props.search}
                  onChangeSearch={this.onChangeSearch}
                />
              </div>
              {isLibrary && (
                <FolderList
                  folderSelected={this.props.folderSelected}
                  onClickFolder={this.onClickFolder}
                />
              )}
              <div
                className={`${classes.bottomLeft} ${
                  isLibrary && classes.morePadding
                }`}
                id={"leftSide"}
              >
                {requesting && (
                  <div className={classes.loader}>
                    <Loader size={30} />
                  </div>
                )}
                {numberOfSteps < 3 && (
                  <Typography
                    className={classes.explanation}
                    dangerouslySetInnerHTML={{
                      __html:
                        numberOfSteps > 0 && numberOfSteps < 8
                          ? i18n.t("move-arrows-to-see-challenges", {
                              interpolation: { escapeValue: false },
                            })
                          : i18n.t("add-challenges-to-campaign", {
                              interpolation: { escapeValue: false },
                            }),
                    }}
                  ></Typography>
                )}
                <StepsList listRef={this.listRef} />
              </div>
            </div>
            <div className={classes.rightSide}>{this.renderRightSide()}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const language = getAppLanguage(state);
  const clientId = getSelectedClientId(state);
  const userClientId = getUserClientId(state);
  const {
    rightSideScreen,
    stepLoading,
    stepSelectedIndex,
    isTemplate,
    skillsList,
    requesting,
    languageSelected,
    skillSelected,
  } = state.createTrack;
  const trackId = getTrackId(state);
  const isSuperAdmin = getIsSuperAdmin(state);
  const isSuperAdminClientSelected = getIsSuperAdminClientSelected(state);
  const isLibrary = getIsLibrary(state);
  const search = getCreateTrackSearch(state);

  return {
    userId: getUserId(state),
    routerQuery: getRouterQuery(state),
    numberOfSteps: getNumberOfSteps(state),
    folderSelected: getFolderSelected(state),
    sortSelected: getSortSelected(state),
    folderList: state.createTrack.folderList,
    skillSelected,
    requesting,
    isLibrary,
    trackId,
    skillsList,
    isTemplate,
    isSuperAdmin,
    isSuperAdminClientSelected,
    language,
    rightSideScreen,
    clientId,
    userClientId,
    stepLoading,
    stepSelectedIndex,
    languageSelected,
    search,
  };
};

export default connect(mapStateToProps, {
  addStep,
  removeStep,
  changeField,
  reorderSteps,
  createTrackRequest,
  getTagsListRequest,
  getSkillsListRequest,
  getAllStepsRequest,
  getMyLibraryTotalCountRequest,
  getPremiumTotalCountRequest,
  createNewStep,
  getAllObjectivesRequest,
  getAllCompetenciesRequest,
  getCreateTrackRequest,
  updateSearch,
  moveStepSelection,
})(withStyles(styles)(CreateTrack));
