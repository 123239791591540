import React, { useState } from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography } from "@mui/material";
import i18n from "i18n";

const styles = theme => ({
  main: {
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    backgroundColor: "#FBFBFB",
    marginTop: 50,
    paddingTop: 30,
    display: "flex",
    paddingBottom: 100,
  },
  body: {
    width: 960,
  },
  topDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  title: {
    alignSelf: "flex-start",
    fontWeight: "bold",
    fontFamily: "Montserrat",
    fontSize: 26,
  },
  line: {
    marginRight: 15,
    marginLeft: 15,
    backgroundColor: "#D8D8D8",
    height: 1,
    display: "flex",
    flex: 1,
  },
  chevron: {
    transform: "rotate(-90deg)",
    transition: "all 0.2s ease",
  },
  isOpenChevron: {
    transform: "rotate(90deg)",
  },
  bodyDiv: {
    marginTop: 30,
    marginBottom: 50,
  },
});

const AdvancedOptions = ({ classes, children, languageSelected = "fr" }) => {
  const [isVisible] = useState(true);
  return (
    <div className={classes.main}>
      <div className={classes.body}>
        <div className={classes.topDiv}>
          <Typography className={classes.title}>
            {i18n.t("campaign-advanced-settings", { lng: languageSelected })}
          </Typography>
        </div>
        {isVisible && <div className={classes.bodyDiv}>{children}</div>}
      </div>
    </div>
  );
};

export default withStyles(styles)(AdvancedOptions);
