import React from "react";
import { ResponsivePie } from "@nivo/pie";
import Colors from "../../constants/Colors";

/*const colorRange = [
  "rgba(252,87,122,0.8)",
  "rgba(66,80,251,0.8)",
  "rgba(0,170,255,0.8)",
  "#FF9F40",
];*/

// const dataTest = [
//   {
//     id: "Finance",
//     label: "Finance",
//     value: 532,
//     color: colorRange[0]
//   },
//   {
//     id: "RH",
//     label: "RH",
//     value: 79,
//     color: colorRange[1]
//   },
//   {
//     id: "Dev",
//     label: "Dev",
//     value: 83,
//     color: colorRange[2]
//   },
//   {
//     id: "Opé",
//     label: "Opé",
//     value: 42,
//     color: colorRange[3]
//   }
// ];

const theme = {
  labels: {},
};

// theme.labels = Object.assign(theme.labels, { text: { fontSize: "18px" } });

const EvolutionChart = ({ data, firstChart, isSmall }) => {
  return (
    // make sure parent container have a defined height when using responsive component,
    // otherwise height will be 0 and no chart will be rendered.
    // website examples showcase many properties, you'll often use just a few of them.
    <ResponsivePie
      data={data}
      margin={{
        top: isSmall ? 20 : 50,
        right: 10,
        bottom: isSmall ? 20 : 30,
        left: 10,
      }}
      innerRadius={0.5}
      // padAngle={0.7}
      padAngle={3}
      cornerRadius={4}
      colors={data => data.color}
      borderWidth={1}
      borderColor="inherit:darker(0.2)"
      enableRadialLabels={true}
      radialLabelsSkipAngle={10}
      radialLabelsTextXOffset={6}
      radialLabelsTextColor={Colors.text}
      radialLabelsLinkOffset={0}
      radialLabelsLinkDiagonalLength={16}
      radialLabelsLinkHorizontalLength={24}
      radialLabelsLinkStrokeWidth={2}
      radialLabelsLinkColor="inherit"
      enableSlicesLabels={true}
      slicesLabelsSkipAngle={10}
      slicesLabelsTextColor={"#fff"}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      legends={[]}
      theme={theme}
      // legends={[
      //   {
      //     anchor: "bottom",
      //     direction: "row",
      //     translateY: 56,
      //     itemWidth: 100,
      //     itemHeight: 18,
      //     itemTextColor: "#999",
      //     symbolSize: 18,
      //     symbolShape: "circle",
      //     effects: [
      //       {
      //         on: "hover",
      //         style: {
      //           itemTextColor: "#000"
      //         }
      //       }
      //     ]
      //   }
      // ]}
    />
  );
};

export default EvolutionChart;
