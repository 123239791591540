import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { changeField, createUserRequest } from "../../services/signup/actions";

import Typography from "@mui/material/Typography";
import withStyles from '@mui/styles/withStyles';
import TextField from "@mui/material/TextField";
import FlatButton from "../../components/FlatButton";
import getNavigatorLanguage from "../../utils/getNavigatorLanguage";

import Colors from "../../constants/Colors";
import i18n from "../../i18n";
import { Loader } from "../../components";

const styles = theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "100vh",
    width: "100vw",
    backgroundColor: Colors.pureMain,
  },
  logo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: 75,
    // paddingTop: 0,
    margin: "20px auto 20px",
  },
  paper: {
    width: 470,
    display: "flex",
    borderRadius: 3,
    background: "white",
    padding: 40,
    paddingBottom: 20,
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 20,
  },
  title: {
    textAlign: "center",
    fontWeight: 300,
    color: "#444",
    margin: 0,
    marginTop: 0,
    fontSize: 30,
    lineHeight: "38px",
    textTransform: "none",
    letterSpacing: 0,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE11 issue.
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  loginError: {
    backgroundColor: "#FCE6E7",
    borderRadius: 8,
  },
  errorText: {
    color: "#e6525a",
    padding: "13px 15px",
    borderRadius: 6,
    backgroundColor: "rgba(230,82,90,0.15)",
  },
  textField: {
    marginTop: 20,
  },
  submitButton: {
    marginTop: 20,
    padding: 12,
    backgroundColor: "#15CD72",
    "&:hover": {
      backgroundColor: "#15CD72",
    },
  },
  noDecoration: {
    textDecoration: "none",
  },
  forgotPassword: {
    marginTop: 20,
    cursor: "pointer",
    textDecoration: "none",
    color: Colors.pureMain,
    fontSize: 17,
    lineHeight: 1.5,
    textAlign: "center",
  },
  newAccountText: {
    color: Colors.white,
    fontSize: 16,
    textAlign: "center",
  },
  bold: {
    marginLeft: 5,
    color: Colors.white,
    fontSize: 16,
    cursor: "pointer",
    fontWeight: "bold",
    textDecoration: "underline",
  },
  noAccountDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  topField: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  splitTextField: {
    width: "48.5%",
  },
  agreeTerms: {
    textAlign: "center",
    fontSize: 14,
    marginTop: 15,
    // marginBottom: 20,
  },
  link: {
    textDecoration: "underline",
    color: Colors.pureMain,
  },
  stepDiv: {
    marginBottom: 25,
    marginTop: 10,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  step: {
    borderRadius: 30,
    height: 4,
    width: 40,
    marginLeft: 5,
    marginRight: 5,
    backgroundColor: Colors.lightMain,
  },
  stepActive: {
    backgroundColor: Colors.pureMain,
  },
});

class CreateUserForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChangeField = field => event => {
    this.props.changeField({ [field]: event.target.value });
    // this.setState({ [field]: event.target.value });
  };

  onSubmit = () => {
    const { email, password, firstName, lastName, phoneNumber } = this.props;
    const language = getNavigatorLanguage();
    this.props.createUserRequest({
      email,
      password,
      firstName,
      lastName,
      phoneNumber,
      language,
    });
  };

  render() {
    const { error, classes, requesting } = this.props;

    return (
      <React.Fragment>
        <div className={classes.form}>
          {error && <p className={classes.errorText}>{error}</p>}
          <div className={classes.topField}>
            <TextField
              id="outlined-multiline-flexible"
              label={i18n.t("firstName")}
              fullWidth
              autoFocus
              autoComplete="given-name"
              // rowsMax="4"
              value={this.props.firstName}
              onChange={this.onChangeField("firstName")}
              className={`${classes.textField} ${classes.splitTextField}`}
              margin="normal"
              variant="outlined"
            />
            <TextField
              id="outlined-multiline-flexible"
              label={i18n.t("lastName")}
              fullWidth
              autoFocus
              autoComplete="family-name"
              // rowsMax="4"
              value={this.props.lastName}
              onChange={this.onChangeField("lastName")}
              className={`${classes.textField} ${classes.splitTextField}`}
              margin="normal"
              variant="outlined"
            />
          </div>
          <TextField
            id="outlined-multiline-flexible"
            label={i18n.t("your-email")}
            onChange={this.onChangeField("email")}
            fullWidth
            autoFocus
            autoComplete="email"
            // rowsMax="4"
            value={this.props.email}
            className={classes.textField}
            margin="normal"
            variant="outlined"
          />
          <TextField
            id="outlined-multiline-flexible"
            label={i18n.t("phoneNumber")}
            fullWidth
            autoFocus
            autoComplete="tel"
            // rowsMax="4"
            value={this.props.phoneNumber}
            onChange={this.onChangeField("phoneNumber")}
            className={classes.textField}
            margin="normal"
            variant="outlined"
          />
          <TextField
            id="outlined-multiline-flexible"
            label={i18n.t("your-password")}
            fullWidth
            autoComplete="new-password"
            type="password"
            // rowsMax="4"
            onChange={this.onChangeField("password")}
            value={this.props.password}
            className={classes.textField}
            margin="normal"
            variant="outlined"
          />
          {requesting ? (
            <Loader />
          ) : (
            <FlatButton
              fullWidth
              disabled={
                !this.props.password ||
                !this.props.phoneNumber ||
                !this.props.email ||
                !this.props.firstName ||
                !this.props.lastName
              }
              title={i18n.t("create-account")}
              onClick={this.onSubmit}
              className={classes.submitButton}
            />
          )}
          <Typography className={classes.agreeTerms}>
            {i18n.t("agree-terms-by-signing-up")}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://microdoing.com/conditions-generales-dutilisation-du-site"
              className={classes.link}
            >
              {i18n.t("terms-of-service")}
            </a>
            {i18n.t("and-the")}
            <a
              href="https://microdoing.com/privacy"
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {i18n.t("privacy-policy")}
            </a>
          </Typography>
        </div>
      </React.Fragment>
    );
  }
}

CreateUserForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const {
    step,
    email,
    error,
    requesting,
    password,
    phoneNumber,
    firstName,
    lastName,
  } = state.signup;
  return {
    email,
    step,
    phoneNumber,
    password,
    firstName,
    lastName,
    requesting,
    error,
  };
};

export default connect(mapStateToProps, { changeField, createUserRequest })(
  withStyles(styles)(CreateUserForm),
);
