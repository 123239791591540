import { TOKEN_SET, TOKEN_UNSET, TOKEN_REFRESH } from "./constants";

export const setToken = token => {
  return {
    type: TOKEN_SET,
    token,
  };
};

export const unsetToken = () => {
  return {
    type: TOKEN_UNSET,
  };
};

export const refreshToken = () => {
  return {
    type: TOKEN_REFRESH,
    meta: {
      retry: true,
    },
  };
};
