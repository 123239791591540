import { useState } from "react";

// Hook
const useHover = () => {
  const [isHovered, setHover] = useState(false);

  const onMouseEnter = () => setHover(true);
  const onMouseLeave = () => setHover(false);
  const onMouseOver = () => setHover(true);

  const hoverProperties = { onMouseEnter, onMouseLeave, onMouseOver };

  return [isHovered, hoverProperties, setHover] as const;
};

export default useHover;
