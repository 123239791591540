import React from "react";
import withStyles from '@mui/styles/withStyles';
import CoachingSessions from "scenes/Campaigns/SeeUserModal/Coaching/Sessions";
import Notes from "scenes/Campaigns/SeeUserModal/Coaching/Notes";
import Resources from "scenes/Campaigns/SeeUserModal/Coaching/Resources";
import RoadmapCoaching from "scenes/Campaigns/SeeUserModal/Coaching/RoadmapCoaching";
import { getIsCoachingFinished } from "services/coaching/selectors";
import { useSelector } from "react-redux";
import FinishedCoachingBanner from "scenes/Campaigns/SeeUserModal/Coaching/EndCoaching/FinishedCoachingBanner";

const styles = theme => ({
  main: {},
  columns: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
});

const Coaching = ({ classes }) => {
  const isCoachingFinished = useSelector(getIsCoachingFinished);

  return (
    <div className={classes.main}>
      {isCoachingFinished && <FinishedCoachingBanner />}
      <CoachingSessions />
      <RoadmapCoaching />
      <div className={classes.columns}>
        <Notes />
        <Resources />
      </div>
    </div>
  );
};

export default withStyles(styles)(Coaching);
