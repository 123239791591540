import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

// const PrivateRoute = ({ component: Component, token, ...rest }) => {

interface OwnProps {
  component: any;
}

type Props = OwnProps & StateProps;

const PrivateRoute = (props: Props) => {
  const { component: Component, token, client } = props;
  if (!token || !client) {
    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }
  return <Component {...props} />;
};

interface StateProps {
  token: string;
  client: string;
}

const mapStateToProps = (state: any): StateProps => {
  const { accessToken } = state.token;
  const { client } = state.user;
  return {
    token: accessToken,
    client,
  };
};

export default connect(mapStateToProps, null)(PrivateRoute);
