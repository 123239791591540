import makeStyles from "@mui/styles/makeStyles";
import { MainText } from "components/Texts/MainText";
import { PressableMainText } from "components/Texts/PressableMainText";
import { MainTitle } from "components/Titles/MainTitle";
import Colors from "constants/Colors";
import i18n from "i18n";
import moment from "moment";
import React, { useMemo, useState } from "react";
import NotificationType from "services/notification/types";
import HistoryReceiverList from "./HistoryReceiversList";

const useStyle = makeStyles({
  notificationsInfo: {
    display: "flex",
    flexDirection: "column",
  },
  notificationDesc: {
    color: "#888888",
    display: "flex",
    flexDirection: "row",
  },
  notificationsDescClick: {
    marginLeft: 3,
    marginRight: 3,
    color: Colors.main,
  },
  pointers: {
    cursor: "pointer",
  },
});

export interface INotificationHistoryDescriptionProps {
  notification: NotificationType;
  isProgramForLater: boolean;
  date: string;
}

const NotificationHistoryDescription = ({
  notification,
  isProgramForLater,
  date,
}: INotificationHistoryDescriptionProps) => {
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);

  const notificationText = notification.text.split(/\r?\n/);
  const notificationDate = moment().diff(
    moment(notification.createdAt).startOf("day"),
    "days",
  );
  const receivers = notification?.receiver;
  const numberOfUserWereNotified = receivers?.length;
  const { firstName } = notification.sender;

  const senderDesc = useMemo(() => {
    if (notificationDate === 0) {
      return i18n.t("notification-send-by-today");
    } else if (notificationDate === 1) {
      return i18n.t("notification-send-yesterday");
    } else {
      return notificationDate + i18n.t("notification-send-by-day");
    }
  }, [notificationDate]);

  const classes = useStyle();
  return (
    <div className={classes.notificationsInfo}>
      <MainTitle text={notificationText[0]} size="extraSmall" />
      <MainText text={notificationText.slice(1).join("\n")} size="extraSmall" />
      <div className={classes.notificationDesc}>
        <MainText
          text={i18n.t("notification-send-by-name")}
          size="extraSmall"
        />
        <MainText
          text={firstName}
          size="extraSmall"
          className={classes.notificationsDescClick}
        />
        <MainText text={senderDesc} size="extraSmall" />
      </div>
      {isProgramForLater && (
        <div className={classes.notificationDesc}>
          <MainText
            text={i18n.t("notification-scheduled-for-the")}
            size="extraSmall"
          />
          <MainText
            text={date.toString()}
            size="extraSmall"
            className={classes.notificationsDescClick}
          />
        </div>
      )}
      <div className={classes.notificationDesc}>
        <MainText text={i18n.t("notification-send-for")} size="extraSmall" />
        <PressableMainText
          text={
            numberOfUserWereNotified +
            " " +
            (numberOfUserWereNotified > 1
              ? i18n.t("notification-send-for-participants")
              : i18n.t("notification-send-for-participant"))
          }
          size="extraSmall"
          className={classes.notificationsDescClick}
          onClick={() => setIsDropDownVisible(!isDropDownVisible)}
        />
      </div>
      {isDropDownVisible && <HistoryReceiverList receivers={receivers} />}
    </div>
  );
};

export default NotificationHistoryDescription;
