import React, { useState } from "react";
import moment from "moment";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import i18n from "i18n";
import Colors from "constants/Colors";
import { RemoveButtonIcon } from "components";
import displayNextActiveDay from "utils/displayNextActiveDay";
import Line from "scenes/Campaigns/SeeUserModal/Coaching/Sessions/Line";

export const BOX_WIDTH = 190;
export const BOX_HEIGHT = 175;
export const BOX_MARGIN = 20;

const EditIcon = ({ color = "#fff" }) => (
  <svg width="12" height="11" viewBox="0 0 12 11" fill="none">
    <path
      d="M1.54384 10.6581C1.58959 10.6581 1.63535 10.6525 1.68054 10.6412L3.93994 10.0763C4.03936 10.0515 4.1303 10.0001 4.2026 9.92776L11.1463 2.98403C11.3598 2.77052 11.4773 2.48696 11.4773 2.18533C11.4773 1.8837 11.3598 1.60014 11.1463 1.38663L10.2505 0.490773C9.82344 0.0637446 9.0801 0.0637446 8.65307 0.490773L1.70934 7.4345C1.63708 7.50686 1.58574 7.59742 1.56079 7.69659L0.995935 9.956C0.97507 10.0393 0.973468 10.1262 0.991249 10.2102C1.00903 10.2942 1.04573 10.3731 1.09855 10.4408C1.15137 10.5085 1.21893 10.5632 1.29609 10.6009C1.37325 10.6385 1.45798 10.6581 1.54384 10.6581ZM9.45177 1.28947L10.3476 2.18533L9.45177 3.08119L8.55592 2.18533L9.45177 1.28947ZM2.61875 8.12249L7.75722 2.98403L8.65307 3.87989L3.51405 9.01835L2.31995 9.31659L2.61875 8.12249Z"
      fill={color}
    />
  </svg>
);

const CheckIcon = ({ color = "#fff" }) => (
  <svg
    width="17"
    height="12"
    viewBox="0 0 17 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.64479 9.77316L2.40565 6.53403C2.32095 6.44829 2.22007 6.38022 2.10887 6.33377C1.99767 6.28731 1.87835 6.26338 1.75783 6.26338C1.63731 6.26338 1.51799 6.28731 1.40678 6.33377C1.29558 6.38022 1.1947 6.44829 1.11 6.53403C1.02426 6.61873 0.956194 6.71961 0.909736 6.83081C0.863278 6.94202 0.839355 7.06134 0.839355 7.18185C0.839355 7.30237 0.863278 7.42169 0.909736 7.5329C0.956194 7.6441 1.02426 7.74498 1.11 7.82968L4.98771 11.7074C5.34864 12.0683 5.93168 12.0683 6.29262 11.7074L16.1026 1.90669C16.1883 1.82199 16.2564 1.72111 16.3028 1.60991C16.3493 1.4987 16.3732 1.37938 16.3732 1.25886C16.3732 1.13834 16.3493 1.01902 16.3028 0.907819C16.2564 0.796615 16.1883 0.695738 16.1026 0.611036C16.0179 0.525301 15.917 0.457231 15.8058 0.410773C15.6946 0.364315 15.5753 0.340393 15.4547 0.340393C15.3342 0.340393 15.2149 0.364315 15.1037 0.410773C14.9925 0.457231 14.8916 0.525301 14.8069 0.611036L5.64479 9.77316Z"
      fill={color}
    />
  </svg>
);

const getSessionStatus = ({ isDone, isActive, nextDate }) => {
  if (isDone) return i18n.t("done");
  if (isActive) return i18n.t("in-progress");
  if (nextDate) return i18n.t("planned");
  return "";
};

const displaySessionDuration = ({ startDate, durationInMinutes = 45 }) => {
  const startMoment = moment(startDate);
  const endMoment = moment(startDate).add(durationInMinutes, "minutes");
  const start = startMoment.format("HH") + "h" + startMoment.format("mm");

  const end = endMoment.format("HH") + "h" + endMoment.format("mm");

  return `${start} - ${end}`;
};

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  main: {
    marginBottom: 5,
    borderRadius: 10,
    border: `solid 1px ${Colors.pureMain}`,
    paddingTop: 35,
    width: BOX_WIDTH,
    minWidth: BOX_WIDTH,
    height: BOX_HEIGHT,
    minHeight: BOX_HEIGHT,
    position: "relative",
    marginRight: BOX_MARGIN,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    transition: "all 0.2s easeIn",
    "&:hover": {
      opacity: 0.9,
    },
  },
  isActive: {
    cursor: "pointer",
    backgroundColor: "#F4FBFF",
    WebkitBoxShadow: `inset 0px 0px 0px 2px ${Colors.pureMain}`,
    MozBoxShadow: `inset 0px 0px 0px 2px ${Colors.pureMain}`,
    boxShadow: `inset 0px 0px 0px 2px ${Colors.pureMain}`,
  },
  isPlanned: {
    cursor: "pointer",
    "&:hover": {
      borderColor: Colors.pureMain,
    },
  },
  isDone: {
    cursor: "pointer",
    backgroundColor: "#F4FBFF",
    borderColor: "#fff",
  },
  date: {
    fontWeight: "550",
    color: "#2E2E2E",
    fontSize: 16,
    lineHeight: 1.2,
  },
  status: {
    lineHeight: 1.2,
    fontSize: 16,
    color: Colors.main,
  },
  bold: {
    fontWeight: "550",
  },
  button: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    backgroundColor: Colors.pureMain,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    paddingTop: 15,
    paddingBottom: 10,
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": {
      opacity: 0.9,
    },
  },
  buttonText: {
    color: "#fff",
    fontWeight: "550",
    fontSize: 14,
  },
  removeButton: {
    zIndex: 0,
    position: "absolute",
    top: -5,
    left: -5,
  },
  editButton: {
    position: "absolute",
    top: -5,
    right: -5,
  },
  checkCircle: {
    position: "absolute",
    top: -17.5,
    width: 35,
    height: 35,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: Colors.pureMain,
    zIndex: 10,
  },
  editCircle: {
    position: "absolute",
    top: -12.5,
    right: -12.5,
    width: 25,
    height: 25,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: Colors.pureMain,
    zIndex: 10,
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": {
      opacity: 0.9,
    },
  },
  plannedDate: {
    color: "#2E2E2E",
    position: "absolute",
    bottom: 20,
    fontFamily: "Roboto",
    textAlign: "center",
  },
  duration: {
    fontWeight: 300,
    color: "#2E2E2E",
    fontSize: 12,
    lineHeight: "100%",
    marginTop: 7,
    marginBottom: 17,
  },
}));

const SessionComponent = ({
  doneDate,
  nextDate,
  durationInMinutes = 45,
  isDone = false,
  onClick = null,
  onClickValidate = null,
  onClickRemove = null,
  isLast = false,
  index,
}) => {
  const [isHovered, setHover] = useState(false);
  const classes = useStyles();
  const date = nextDate;
  const isActive = moment(nextDate).isBefore(moment()) && !isDone;
  const isPlanned = !isDone && !isActive;
  return (
    <div className={classes.container}>
      <div
        onClick={onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className={`${classes.main} ${isActive && classes.isActive} ${
          isPlanned && classes.isPlanned
        } ${isDone && classes.isDone}`}
      >
        {!!date && (
          <Typography className={`${classes.date} ${isActive && classes.bold}`}>
            {moment(date).format("DD MMM YYYY")}{" "}
          </Typography>
        )}
        <Typography className={classes.duration}>
          {displaySessionDuration({ startDate: nextDate, durationInMinutes })}
        </Typography>
        <Typography className={`${classes.status} ${isActive && classes.bold}`}>
          {getSessionStatus({ isDone, isActive, nextDate })}
        </Typography>
        {isPlanned && (
          <Typography className={classes.plannedDate}>
            {displayNextActiveDay({ nextDate, timezone: "Europe/Paris" })}
          </Typography>
        )}
        {isActive && (
          <div className={classes.button} onClick={onClickValidate}>
            <Typography className={classes.buttonText}>
              {i18n.t("validate-session")}
            </Typography>
          </div>
        )}
        {isDone && (
          <div className={classes.checkCircle}>
            <CheckIcon />
          </div>
        )}
        {isHovered && (
          <div className={classes.editCircle}>
            <EditIcon />
          </div>
        )}
        {isHovered && (
          <>
            <RemoveButtonIcon
              onClick={onClickRemove}
              className={classes.removeButton}
            />
          </>
        )}
      </div>
      <Line
        index={index}
        isPlanned={isPlanned}
        isActive={isActive}
        isLast={isLast}
      />
    </div>
  );
};

export default SessionComponent;
