import { useDispatch, useSelector } from "react-redux";
import { endCoachingRequest } from "services/coaching/actions";
import {
  getCoachingRequesting,
  getCoachingSessionId,
} from "services/coaching/selectors";
import { getUserSelectedId } from "services/manager/selectors";
import { getUserId } from "services/user/selectors";

const useEndCoaching = () => {
  const userSelectedId = useSelector(getUserSelectedId);
  const userId = useSelector(getUserId);
  const requesting = useSelector(getCoachingRequesting);
  const coachingSessionId = useSelector(getCoachingSessionId);
  const dispatch = useDispatch();

  const sendRequest = async ({
    isForbidden = false,
    isFinished = true,
    endCoachingMessage = "",
  }) => {
    try {
      await dispatch(
        endCoachingRequest({
          userId: userSelectedId,
          coachId: userId,
          coachingSessionId,
          isFinished,
          isForbidden,
          endCoachingMessage,
        }),
      );
    } catch (e) {
    } finally {
    }
  };

  return [requesting, sendRequest] as const;
};

export default useEndCoaching;
