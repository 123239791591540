import React, { useEffect, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import Modal from "components/Modal";
import SelectGoal from "./SelectGoal";
import GoalSettings from "scenes/Campaigns/SeeUserModal/Coaching/RoadmapCoaching/NewGoalModal/GoalSettings";
import selectLanguage from "utils/selectLanguage";
import { getAppLanguage } from "services/general/selectors";
import { connect } from "react-redux";
import FlatButton from "components/FlatButton";
import i18n from "i18n";
import {
  getCoachingGoals,
  getCoachingSessionId,
} from "services/coaching/selectors";
import { updateCoachingSessionRequest } from "services/coaching/actions";
import { LightButton } from "components";
import LibraryIcon from "scenes/Campaigns/SeeUserModal/Coaching/Resources/LibraryIcon";
import AddIcon from "scenes/MyCoachees/AddIcon";
import { useCreateNewAction } from "scenes/Campaigns/SeeUserModal/Coaching/RoadmapCoaching/NewGoalModal/hooks";
import { getStepsIds } from "services/createTrack/selectors";

import MODAL_SIDE_PICTURE from "resources/Goal.svg";

const styles = theme => ({
  main: {},
  modal: {
    position: "inherit",
    maxWidth: 950,
    height: 800,
    maxHeight: "90vh",
    width: 950,
  },
  buttonDiv: {
    boxSizing: "border-box",
    backgroundColor: "#fff",
    // position: "fixed",
    paddingTop: 20,
    paddingRight: 25,
    paddingLeft: 25,
    paddingBottom: 25,
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    flexDirection: "row",
  },
  firstButton: {
    marginRight: 15,
  },
  space: {
    flex: 1,
  },
  icon: {
    marginRight: 7,
  },
  buttons: {
    flex: 1,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    flexDirection: "row",
  },
});

const NewGoalModal = ({
  classes,
  isVisible,
  onClose,
  isEditing = false,
  goalSelected = {},
  language,
  goals,
  coachingSessionId,
  updateCoachingSessionRequest,
  openExistingContentModal,
  stepsIds,
}) => {
  const [modalStep, setModalStep] = useState(isEditing ? 1 : 0);
  const [skillSelected, selectSkill] = useState(null);
  const [goalNumber, setGoalNumber] = useState(3);

  const [createNewAction] = useCreateNewAction();

  useEffect(() => {
    if (goalSelected._id) {
      setGoalNumber(goalSelected.goalNumber);
      selectSkill(goalSelected.skill);
      setModalStep(isEditing ? 1 : 0);
    }
  }, [
    goalSelected._id,
    goalSelected.skill,
    goalSelected.goalNumber,
    isEditing,
  ]);

  const onSave = () => {
    if (!skillSelected) {
      selectSkill(null);
      return onClose();
    }
    let newGoals = [];
    const recommendedSteps = stepsIds;
    if (!recommendedSteps?.length) return;

    if (goalSelected._id) {
      newGoals = goals.map(el => {
        if (el._id === goalSelected._id) {
          return {
            ...el,
            goalNumber: goalNumber || 1,
            recommendedSteps,
          };
        }
        return el;
      });
    } else {
      newGoals = [
        ...goals,
        {
          skill: skillSelected,
          goalNumber: goalNumber || 1,
          doneSteps: [],
          recommendedSteps,
        },
      ];
    }

    updateCoachingSessionRequest({
      coachingSessionId,
      goals: newGoals,
    });
    selectSkill(null);
    onClose();
  };

  const onSelectGoal = skill => {
    setModalStep(1);
    selectSkill(skill);
  };

  const onCloseModal = () => {
    setModalStep(0);
    selectSkill(null);
    onSave();
  };

  return (
    <Modal
      id="new-goal-modal"
      className={classes.modal}
      isVisible={isVisible}
      hasSideExplanation={true}
      sideExplanation={{
        title: i18n.t("goal-explanation-title"),
        explanation: i18n.t("goal-explanation-text"),
        picture: MODAL_SIDE_PICTURE,
      }}
      onClose={onCloseModal}
      title={
        !!skillSelected
          ? selectLanguage({ text: skillSelected.label, language })
          : i18n.t("goal-name")
      }
      bottomComponent={
        modalStep === 1 ? (
          <>
            <div className={classes.buttonDiv}>
              <div className={classes.space} />
              <FlatButton
                title={i18n.t("save")}
                onClick={onSave}
                disabled={!stepsIds?.length}
              />
            </div>
          </>
        ) : null
      }
    >
      {modalStep === 0 && (
        <SelectGoal onSelect={onSelectGoal} existingGoals={goals} />
      )}
      {modalStep === 1 && (
        <GoalSettings
          buttons={
            <div className={classes.buttons}>
              <FlatButton
                onClick={createNewAction}
                className={`${classes.firstButton}`}
                icon={<AddIcon className={classes.icon} color="#fff" />}
                title={i18n.t("add")}
              />
              <LightButton
                onClick={openExistingContentModal}
                className={`${classes.button}`}
                icon={<LibraryIcon className={classes.icon} />}
                title={i18n.t("library")}
              />
            </div>
          }
          openExistingContentModal={openExistingContentModal}
          onSave={onSave}
          goalSelected={goalSelected}
          goalNumber={goalNumber}
          setGoalNumber={setGoalNumber}
        />
      )}
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    coachingSessionId: getCoachingSessionId(state),
    language: getAppLanguage(state),
    goals: getCoachingGoals(state),
    requesting: state.coaching.requesting,
    stepsIds: getStepsIds(state),
  };
};

export default connect(mapStateToProps, { updateCoachingSessionRequest })(
  withStyles(styles)(NewGoalModal),
);
