import React from "react";

const SettingsIcon = ({ sizePercentage, color }) => (
  <svg
    width={sizePercentage * 21}
    height={sizePercentage * 7}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 21 7"
    version="1.1"
  >
    <path
      d="M19.9437 2.65476H16.7685C16.5655 2.07184 16.186 1.56656 15.6828 1.20901C15.1796 0.851459 14.5776 0.659363 13.9603 0.659363C13.3431 0.659363 12.7411 0.851459 12.2379 1.20901C11.7347 1.56656 11.3552 2.07184 11.1522 2.65476H1.99363C1.72914 2.65476 1.4755 2.75982 1.28848 2.94684C1.10146 3.13386 0.996399 3.3875 0.996399 3.65199C0.996399 3.91647 1.10146 4.17011 1.28848 4.35713C1.4755 4.54415 1.72914 4.64921 1.99363 4.64921H11.1522C11.3552 5.23214 11.7347 5.73741 12.2379 6.09496C12.7411 6.45251 13.3431 6.64461 13.9603 6.64461C14.5776 6.64461 15.1796 6.45251 15.6828 6.09496C16.186 5.73741 16.5655 5.23214 16.7685 4.64921H19.9437C20.2082 4.64921 20.4618 4.54415 20.6489 4.35713C20.8359 4.17011 20.9409 3.91647 20.9409 3.65199C20.9409 3.3875 20.8359 3.13386 20.6489 2.94684C20.4618 2.75982 20.2082 2.65476 19.9437 2.65476ZM13.9603 4.64921C13.7631 4.64921 13.5703 4.59073 13.4063 4.48115C13.2423 4.37157 13.1145 4.21583 13.039 4.03361C12.9636 3.85139 12.9438 3.65088 12.9823 3.45744C13.0208 3.26399 13.1157 3.0863 13.2552 2.94684C13.3947 2.80738 13.5724 2.7124 13.7658 2.67392C13.9592 2.63544 14.1597 2.65519 14.342 2.73067C14.5242 2.80615 14.6799 2.93396 14.7895 3.09796C14.8991 3.26195 14.9576 3.45475 14.9576 3.65199C14.9573 3.91639 14.8522 4.16988 14.6652 4.35684C14.4782 4.5438 14.2247 4.64895 13.9603 4.64921Z"
      fill={color}
    />
  </svg>
);

SettingsIcon.defaultProps = {
  sizePercentage: 1,
  color: "#19212B",
};

export default SettingsIcon;
