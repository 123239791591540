import React, { useState } from "react";

import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";

import Colors from "../../../constants/Colors";
import FlatButton from "../../../components/FlatButton";
import { Loader } from "../../../components";
import i18n from "../../../i18n";
import FilterTag from "./FilterTag";

import Fuse from "fuse.js";

import SearchBar from "../../../components/SearchBar";
import MyPastCampaigns from "./MyPastCampaigns";

const fuseOptions = {
  shouldSort: true,
  threshold: 0.4,
  location: 0,
  distance: 80,
  maxPatternLength: 26,
  minMatchCharLength: 1,
  keys: ["title.fr", "title.en", "description.fr", "description.en"],
};

const styles = theme => ({
  goalDiv: {},
  line: {
    width: "100%",
    marginBottom: 25,
    borderRadius: 8,
    height: 2,
    backgroundColor: Colors.blackGrey,
  },
  errorText: {
    color: "#e6525a",
    padding: "13px 15px",
    borderRadius: 6,
    backgroundColor: "rgba(230,82,90,0.15)",
  },
  exitModalText: {
    marginTop: -4,
    fontSize: 13,
  },
  title: {
    fontFamily: "Montserrat",
    marginBottom: 25,
    fontSize: 28,
    lineHeight: 1.2143,
    fontWeight: 700,
  },
  subTitle: {
    fontFamily: "Montserrat",
    marginTop: 25,
    marginBottom: 20,
    fontSize: 22,
    lineHeight: 1.2143,
    fontWeight: 700,
  },
  goals: {
    height: "75vh",
    marginTop: "calc(1rem)",
    marginLeft: "-1.5rem",
    marginRight: "-1.5rem",
    marginBottom: "calc(-3rem)",
    flexWrap: "wrap",
    alignItems: "space-between",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "column",
  },
  buttonDiv: {
    paddingTop: 64,
  },
  leftButton: {
    marginRight: 12,
    color: Colors.black,
    backgroundColor: "transparent",
    border: "1px solid",
    borderColor: "rgba(29, 28, 29, 0.3)",
    transition: "all 80ms linear",
    "&:hover": {
      opacity: 0.9,
      backgroundColor: "transparent",
      border: "1px solid",
      borderColor: "rgba(29, 28, 29, 0.3)",
      boxShadow: "0 1px 3px 0 rgba(0,0,0,.08)",
    },
  },
  main: {
    flex: "0 0 auto",
    padding: "0px 1.5rem calc(2rem)",
  },
  createCampaign: {
    textDecoration: "none",
    border: "2px dashed",
    borderColor: "#9ca6af",
    width: 280,
    height: 450,
    borderRadius: 8,
    backgroundColor: Colors.white,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    cursor: "pointer",
    transition: "all 0.2s cubic-bezier(0.41, 0.094, 0.54, 0.07) 0s",
    "&:hover": {
      transform: "translateY(-10px)",
      boxShadow: "rgba(0, 0, 0, 0.07) 1px 7px 15px",
    },
  },
  createPollCampaign: {
    color: "black",
  },
  topDiv: {
    marginTop: 15,
    marginBottom: 50,
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
  },
  search: {
    flex: 2,
  },
  tags: {
    flex: 3,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  "@media (max-width: 1024px)": {
    main: { width: "50%" },
  },
});

const SelectGoal = ({
  classes,
  language,
  onClickGoal,
  requesting,
  onClose,
  onClickNext,
  goals = [],
  templates,
  tracks,
  isSuperAdmin,
  templateRequesting,
  onClickNewCampaign,
  onClickMyCampaignTemplate,
  openFromTemplate = false,
  plan,
}) => {
  const [filterSelected, setFilter] = useState(0);
  const [search, onChangeSearch] = useState("");

  const getArrayToSearch = filterSelected => {
    if (filterSelected === 0) {
      return tracks.filter(track => track.isTemplate);
    } else if (filterSelected === 1) {
      return tracks;
    } else if (filterSelected === 2) {
      return tracks.filter(track => track.isPremiumDemo);
    }
  };

  const arrayToSearch = getArrayToSearch(filterSelected);
  const fuse = new Fuse(arrayToSearch, fuseOptions); // "list" is the item array
  const fuseResult = search.length > 0 ? fuse.search(search) : arrayToSearch;

  return (
    <div className={classes.goalDiv}>
      <Typography className={classes.subTitle}>
        {openFromTemplate
          ? i18n.t("choose-starter-template")
          : i18n.t("choose-campaign-template")}
      </Typography>
      <div className={classes.topDiv}>
        <SearchBar
          className={classes.search}
          placeholder={i18n.t("search-template-placeholder")}
          value={search}
          onChangeSearch={onChangeSearch}
          onSubmit={() => console.log("SUBMIITEED")}
        />
        <div className={classes.tags}>
          <FilterTag
            onClick={() => setFilter(0)}
            isSelected={filterSelected === 0}
            title={i18n.t("my-templates")}
          />
          <FilterTag
            onClick={() => setFilter(1)}
            isSelected={filterSelected === 1}
            title={i18n.t("my-past-campaigns")}
          />
          {isSuperAdmin && (
            <FilterTag
              onClick={() => setFilter(2)}
              isSelected={filterSelected === 2}
              title={i18n.t("premium")}
            />
          )}
        </div>
      </div>
      <div className={classes.goals}>
        {/* <motion.div
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.5 }}
          variants={variants}
          className={classes.newGoal}
        >
          <div className={classes.main}>
            <div
              className={classes.createCampaign}
              onClick={onClickNewCampaign}
              data-intercom-target="New Campaign"
            >
              <AddIcon style={{ color: "#9ca6af" }} />
              <Typography className={classes.createCampaignText}>
                {openFromTemplate
                  ? i18n.t("blank-template")
                  : i18n.t("blank-campaign")}
              </Typography>
            </div>
          </div>
        </motion.div> */}
        {templateRequesting && <Loader size={100} />}
        {filterSelected === 0 && (
          <MyPastCampaigns
            tracks={fuseResult}
            onClickMyCampaignTemplate={onClickMyCampaignTemplate}
          />
        )}
        {filterSelected === 1 && (
          <MyPastCampaigns
            tracks={fuseResult}
            onClickMyCampaignTemplate={onClickMyCampaignTemplate}
          />
        )}
        {filterSelected === 2 && (
          <MyPastCampaigns
            tracks={fuseResult}
            onClickMyCampaignTemplate={onClickMyCampaignTemplate}
          />
        )}
      </div>
      <div className={classes.buttonDiv}>
        <FlatButton
          className={classes.leftButton}
          onClick={onClose}
          color="primary"
          title={i18n.t("cancel")}
        ></FlatButton>
        {requesting && <Loader size={50} />}
      </div>
    </div>
  );
};

export default withStyles(styles)(SelectGoal);
