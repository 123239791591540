import React from "react";
import withStyles from "@mui/styles/withStyles";
import Title from "scenes/CreateTrack/PublishCampaignModal/Title";
import Explanation from "scenes/CreateTrack/PublishCampaignModal/Explanation";
import i18n from "i18n";
import TextBox from "components/TextBox";

const styles = theme => ({
  main: {
    marginTop: 15,
  },
  boxes: {
    marginTop: 10,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  box: {
    width: 250,
    marginLeft: 0,
    marginRight: 15,
  },
});

const Strategy = ({
  classes,
  languageSelected,
  onChangeStrategy,
  strategy,
}) => {
  return (
    <div className={classes.main}>
      <Title
        text={i18n.t("campaign-strategy", {
          lng: languageSelected,
        })}
        languageSelected={languageSelected}
      />
      <Explanation
        text={i18n.t("campaign-strategy-explanation", {
          lng: languageSelected,
        })}
      />
      <div className={classes.boxes}>
        {[
          {
            label: i18n.t("campaign-strategy-ai", {
              lng: languageSelected,
            }),
            value: "bestFit",
          },
          {
            label: i18n.t("campaign-strategy-ordered", {
              lng: languageSelected,
            }),
            value: "ordered",
          },
        ].map((option, index) => (
          <TextBox
            key={index}
            className={classes.box}
            title={option.label}
            onClick={onChangeStrategy(option.value)}
            isSelected={strategy === option.value}
          />
        ))}
      </div>
    </div>
  );
};

export default withStyles(styles)(Strategy);
