import {
  POLL_GET_SETTINGS_REQUESTING,
  POLL_GET_SETTINGS_SUCCESS,
  POLL_GET_SETTINGS_ERROR,
  POLL_MODIFY_SETTINGS_REQUESTING,
  POLL_MODIFY_SETTINGS_SUCCESS,
  POLL_MODIFY_SETTINGS_ERROR,
  POLL_CHANGE_SETTINGS,
  POLL_CLOSE_SNACK_MESSAGE,
  POLL_ADD_OPTION,
  POLL_REMOVE_OPTION,
  POLL_MODIFY_OPTION,
  POLL_CHANGE_TITLE,
  POLL_CREATE_REQUESTING,
  POLL_CREATE_SUCCESS,
  POLL_CREATE_ERROR,
  POLL_GET_ALL_REQUESTING,
  POLL_GET_ALL_SUCCESS,
  POLL_GET_ALL_ERROR,
  POLL_RECEIVED,
  POll_CHANGE_FIELD,
} from "./constants";

import { AUTH_LOGOUT_SUCCESS } from "../auth/reducer";
import moment from "moment";

const initialState = {
  activated: false,
  reminderTime: moment(),
  error: null,
  title: "",
  options: ["", ""],
  isSnackMessage: false,
  pollSelected: {},
  pollSelectedIndex: 0,
  polls: [],
  snackMessage: "",
  tabSelected: 0,
  requesting: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POLL_MODIFY_SETTINGS_REQUESTING:
    case POLL_GET_SETTINGS_REQUESTING:
      return {
        ...state,
        requesting: true,
      };

    case POLL_GET_ALL_REQUESTING:
    case POLL_MODIFY_SETTINGS_ERROR:
    case POLL_GET_SETTINGS_ERROR:
      return {
        ...state,
        error: action.payload,
        requesting: false,
      };

    case POLL_GET_SETTINGS_SUCCESS: {
      const { activated, reminderTime } = action.payload;
      return { ...state, activated, reminderTime, requesting: false };
    }

    case POLL_MODIFY_SETTINGS_SUCCESS:
      return {
        ...state,
        isSnackMessage: true,
        snackMessage: "Sondage modifié avec succès !",
        requesting: false,
      };

    case POLL_CHANGE_SETTINGS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case POLL_CREATE_REQUESTING: {
      return {
        ...state,
        requesting: true,
      };
    }

    case POLL_GET_ALL_SUCCESS: {
      return {
        ...state,
        requesting: false,
        polls: action.payload,
      };
    }

    case POll_CHANGE_FIELD:
      return {
        ...state,
        ...action.payload,
      };

    case POLL_RECEIVED: {
      const polls = state.polls.map(el => {
        if (el._id === action.payload._id) {
          return action.payload;
        }
        return el;
      });
      return {
        ...state,
        polls,
      };
    }

    case POLL_CREATE_SUCCESS: {
      return {
        ...state,
        title: "",
        options: [...initialState.options],
        polls: [action.payload, ...state.polls],
        requesting: false,
      };
    }

    case POLL_GET_ALL_ERROR:
    case POLL_CREATE_ERROR: {
      return {
        ...state,
        requesting: false,
        error: action.payload,
      };
    }

    case POLL_CLOSE_SNACK_MESSAGE:
      return {
        ...state,
        isSnackMessage: false,
      };

    case POLL_MODIFY_OPTION: {
      const options = state.options.map((el, i) => {
        if (i === action.payload.option) {
          return action.payload.value;
        }
        return el;
      });
      return {
        ...state,
        options,
      };
    }

    case POLL_CHANGE_TITLE: {
      return {
        ...state,
        title: action.payload,
      };
    }

    case POLL_REMOVE_OPTION: {
      const options = state.options.filter((el, i) => i !== action.payload);
      return {
        ...state,
        options,
      };
    }

    case POLL_ADD_OPTION:
      return {
        ...state,
        options: [...state.options, ""],
      };

    case AUTH_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
