import React, { Component } from "react";

import { Provider } from "react-redux";

import { ConnectedRouter } from "connected-react-router";
import { Router } from "react-router-dom";

import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  adaptV4Theme,
} from "@mui/material/styles";
import { PersistGate } from "redux-persist/integration/react";

import { store, history, persistor } from "./services/store";

import MyRouter from "./Router";

import Colors from "./constants/Colors";

import AppContainer from "./AppContainer";

const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        light: "#3A4554",
        main: Colors.main,
        dark: Colors.pureMain,
        contrastText: "#fff",
      },
      white: {
        main: "#fff",
        light: Colors.veryLightGrey,
        contrastText: Colors.pureMain,
        dark: "#fff",
      },
      third: {
        light: Colors.main,
      },
      text: {
        primary: Colors.text,
      },
      background: {
        default: "#fff",
      },
    },
    typography: {
      useNextVariants: true,
      suppressDeprecationWarnings: true,
      // fontFamily: "Montserrat",
      fontFamily: "Roboto",
      fontSize: 14,
      display4: {
        fontSize: 14,
      },
      display3: {
        fontSize: 14,
      },
      display2: {
        fontSize: 14,
      },
      display1: {
        fontSize: 14,
      },
      headline: {
        fontSize: 14,
      },
      title: {
        fontSize: 14,
      },
      subheading: {
        fontSize: 14,
      },
      body2: {
        fontSize: 14,
      },
      body1: {
        fontSize: 14,
      },
      caption: {
        fontSize: 14,
      },
      button: {
        fontSize: 14,
      },
    },
  }),
);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <AppContainer>
                  <Router history={history}>
                    <MyRouter />
                  </Router>
                </AppContainer>
              </ThemeProvider>
            </StyledEngineProvider>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    );
  }
}

// export default Sentry.withProfiler(App);
export default App;
