import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Menu from "@mui/material/Menu";
import DotsIcon from "./Icons/DotsIcon";
import { Typography } from "@mui/material";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import Colors from "constants/Colors";

const useStyles = makeStyles(theme => ({
  main: {},
  menu: {
    boxShadow: "0px 4px 23px rgba(0, 0, 0, 0.07)",
    backgroundColor: "#FFFFFF",
    borderRadius: 16,
  },
  optionText: {},
  row: {
    backgroundColor: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    padding: "24px 34px 24px 24px",
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": { backgroundColor: Colors.hoverBlue },
  },
  rowWithLine: {
    borderBottom: "1px solid #E6E6E6",
  },
  optionIcon: {
    marginRight: 16,
  },
  dots: {
    padding: "0px 6px",
    borderRadius: 6,
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": { backgroundColor: Colors.hoverBlue },
  },
}));

interface Option {
  icon?: ReactJSXElement;
  text: string;
  onClick: () => void;
}

interface Props {
  className?: string;
  options: Option[];
}

const DotMenu = ({ className, options }: Props) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isHovered, setHover] = useState(false);

  const onMouseLeave = () => {
    setHover(false);
  };

  const onClickDots = e => {
    setAnchorEl(e.currentTarget);
    setHover(prevState => !prevState);
  };

  return (
    <div className={className}>
      <div onClick={onClickDots} className={classes.dots}>
        <DotsIcon />
      </div>
      <Menu
        MenuListProps={{ disablePadding: true }}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={anchorEl}
        open={isHovered}
      >
        <div className={classes.menu} onMouseLeave={onMouseLeave}>
          {options.map((option, index) => (
            <div
              key={index}
              onClick={option?.onClick}
              className={`${classes.row} ${
                index !== options.length - 1 && classes.rowWithLine
              }`}
            >
              {!!option?.icon && (
                <div className={classes.optionIcon}>{option?.icon}</div>
              )}
              <Typography className={classes.optionText}>
                {option?.text}
              </Typography>
            </div>
          ))}
        </div>
      </Menu>
    </div>
  );
};

export default DotMenu;
