const Colors = {
  hardMain: "rgba(60,80,251,1)",
  blue: "#0395FF",
  skyBlue: "#32C1FF",
  // whiteGrey: "#F5F5FB",
  whiteGrey: "#F3F4F5",
  greyText: "#9AA3AE",
  // main: "rgba(60,80,251,0.8)",
  // lightMain: "rgba(60,80,251,0.3)"
  // main: "#32C1FF",
  // main: "rgba(0, 170, 255, 100)",
  main: "#63BAF9",
  // main: "rgba(0, 170, 255, 100)",
  // pureMain: "#0070f3",
  pureMain: "#63BAF9",
  lightMain: "rgba(0, 170, 255, 0.5)",
  veryLightMain: "rgba(0, 170, 255, 0.1)",
  skyBlue2: "rgba(0, 170, 255, 100)",
  text: "#16193F",
  darkBlue: "#0C056E",
  blueGreen: "#2ADADD",
  lightBlue: "#038CE3",
  lightMain2: "#D4F1FF",
  grey: "rgba(155, 155, 155, 0.6)",
  hardGrey: "rgba(60,60,67,0.6)",
  green: "#7ED321",
  red: "rgba(255,0,47, 0.8)",
  // mediumGreen: "#7DFFB3",
  mediumGreen: "#79EA90",
  // pink: "#FC577A",
  pink: "#FF6584",
  lightPink: "rgba(255, 101, 132, 0.2)",
  lightGrey: "rgba(216,216,216,0.3)",
  veryLightGrey: "rgba(216,216,216,0.1)",
  mediumGrey: "#E6E6E6",
  orange: "#FFC107",
  lightOrange: "#FFF1BA",
  gold: "#FCD462",
  yellow: "#FFD54F",
  // blackGrey: "#4a4a4a",
  blackGrey: "black",
  brandYellow: "#ebe55a",
  purple: "#B41D8D",
  firstOrange: "#F9A619",
  secondOrange: "#FFD400",
  thirdOrange: "#F5811E",
  normalBlue: "#016AFB",
  coffee: "#BF8256",
  defaultColor: "#b2b2b2",
  backgroundTransparent: "transparent",
  defaultBlue: "#0084ff",
  leftBubbleBackground: "#f0f0f0",
  black: "#000",
  white: "#fff",
  veryLightWhite: "rgba(255, 255, 255, 0.25)",
  lightWhite: "rgba(255, 255, 255, 0.75)",
  carrot: "#e67e22",
  emerald: "#2ecc71",
  peterRiver: "#3498db",
  wisteria: "#8e44ad",
  alizarin: "#e74c3c",
  turquoise: "#1abc9c",
  midnightBlue: "#2c3e50",
  optionTintColor: "#007AFF",
  timeTextColor: "#aaa",
  blueGrey: "#edf2fa",
  dateBlue: "#1486f3",
  pollBlue: "#F3F5FA",
  strongGreen: "#41E788",
  transparentGreen: "rgba(65, 231, 136, 0.25)",
};

export default Colors;
