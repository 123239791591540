import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import MediaActionBlock from "components/MediaActionBlock";
import Record from "./Record";
import { RecordingOptionType } from "./types";

const useStyles = makeStyles(theme => ({
  main: {
    width: "100%",
  },
  actions: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
}));

interface Props {
  onSubmit: () => void;
  optionSelected: RecordingOptionType | "";
  setOptionSelected: (option: RecordingOptionType | "") => void;
  requesting?: boolean;
}

const RecordBar = ({
  onSubmit,
  optionSelected,
  setOptionSelected,
  requesting = false,
}: Props) => {
  const classes = useStyles();

  const handleClickOption = option => () => {
    setOptionSelected(option);
  };

  const onClearRecording = () => {
    setOptionSelected("");
  };

  return (
    <div className={classes.main}>
      {!optionSelected && (
        <div className={classes.actions}>
          <MediaActionBlock
            onClick={handleClickOption("camera")}
            action="recordCamera"
          />
          <MediaActionBlock
            onClick={handleClickOption("screen")}
            action="recordScreen"
          />
          <MediaActionBlock
            onClick={handleClickOption("audio")}
            action="recordAudio"
          />
        </div>
      )}
      {optionSelected === "camera" && (
        <Record
          requesting={requesting}
          onSubmit={onSubmit}
          recordType="camera"
          onClearRecording={onClearRecording}
        />
      )}
      {optionSelected === "screen" && (
        <Record
          requesting={requesting}
          onSubmit={onSubmit}
          recordType="screen"
          onClearRecording={onClearRecording}
        />
      )}
      {optionSelected === "audio" && (
        <Record
          requesting={requesting}
          onSubmit={onSubmit}
          recordType="audio"
          onClearRecording={onClearRecording}
        />
      )}
    </div>
  );
};

export default RecordBar;
