import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import i18n from "i18n";
import FilterParticipants from "./FilterParticipant";
import SearchBar from "components/SearchBar";
import { FilterParticipantsType } from "./types";

const useStyles = makeStyles({
  searchRow: {
    marginTop: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  searchBar: {
    flex: 1,
    width: 700,
    maxWidth: "100%",
  },
  csvDiv: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

export interface IHeaderParticipantListProps {
  handleFilterSelected: (filter: string) => void;
  researchField: () => void;
  search: string;
  selectedFilter: FilterParticipantsType;
  numberOfInactiveUsers: number;
  numberOfActiveUsers: number;
  numberOfRealisation: number;
  numberOfParticipation: number;
}

export default function HeaderParticipantList({
  handleFilterSelected = () => {},
  researchField = () => {},
  search = "",
  selectedFilter,
  numberOfInactiveUsers = 0,
  numberOfActiveUsers = 0,
  numberOfRealisation = 0,
  numberOfParticipation = 0,
}: IHeaderParticipantListProps) {
  const classes = useStyles();

  return (
    <div className={classes.searchRow}>
      <FilterParticipants
        numberOfActiveUser={numberOfActiveUsers}
        numberOfInactiveUser={numberOfInactiveUsers}
        numberOfRealisation={numberOfRealisation}
        numberOfParticipation={numberOfParticipation}
        selectedFilter={selectedFilter}
        filterUser={handleFilterSelected}
      />
      <SearchBar
        placeholder={i18n.t("search-user-placeholder")}
        className={classes.searchBar}
        onChangeSearch={researchField}
        value={search}
      />
    </div>
  );
}
