import moment from "moment-timezone";
import i18n from "../i18n";

const displayDate = (date: string | Date, timezone: string) => {
  const current = moment().startOf("day");
  // const today = moment();
  // const doneDate = moment.tz(date, timezone);
  const doneDate = moment(date);
  const hour = doneDate.format("HH:mm");
  const lastDate = doneDate.startOf("day");
  const diff = current.diff(lastDate, "days");
  if (diff === 0) {
    return i18n.t("today-at", { hour });
  } else if (diff === 1) {
    return i18n.t("yesterday-at", { hour });
  }
  return i18n.t("ago-at", { days: diff, hour });
  // return `- Il y a ${diff} jours à ${doneDate.format('HH:mm')}`;
};

export default displayDate;
