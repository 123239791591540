import React from "react";

const LightIcon = ({ sizePercentage, color, className, style }) => (
  <svg
    // width={sizePercentage * 24}
    className={className}
    style={style}
    width={sizePercentage * 11}
    height={sizePercentage * 19}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 11 19"
    version="1.1"
  >
    <path
      d="M10.8201 7.53313C10.7173 7.37342 10.5409 7.27849 10.3521 7.27849H6.44314V1.13582C6.44314 0.87224 6.25886 0.644403 6.00087 0.589678C5.73841 0.533835 5.48153 0.668974 5.37431 0.909096L0.906919 10.9607C0.829856 11.1327 0.846609 11.3338 0.949359 11.4912C1.05211 11.6498 1.22857 11.7459 1.41732 11.7459H5.32629V17.8885C5.32629 18.1521 5.51057 18.38 5.76856 18.4347C5.80765 18.4425 5.84674 18.447 5.88471 18.447C6.10138 18.447 6.30353 18.3208 6.39511 18.1153L10.8625 8.06363C10.9385 7.89052 10.9239 7.69172 10.8201 7.53313Z"
      fill={color}
    />
  </svg>
);

LightIcon.defaultProps = {
  sizePercentage: 1,
  color: "#3691C5",
  className: "",
  style: {},
};

export default LightIcon;
