import moment from "moment-timezone";
import i18n from "../i18n";

const displayDateWithoutHour = (
  date: Date,
  timezone: string = "Europe/Paris",
) => {
  const today = moment.tz(timezone).endOf("day");
  const doneDate = moment.tz(date, timezone);
  const diff = today.diff(doneDate, "days");
  if (diff === 0) {
    return i18n.t("today");
  } else if (diff === 1) {
    return i18n.t("yesterday");
  }
  return i18n.t("ago", { days: diff });
};

export default displayDateWithoutHour;
