import React, { useCallback, useEffect, useRef, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import Modal from "components/Modal";
import i18n from "i18n";
import FlatButton from "components/FlatButton";
import Colors from "constants/Colors";
import ChooseResourceType from "scenes/Campaigns/SeeUserModal/Coaching/Resources/NewResourceModal/ChooseResourceType";
import SetResourceParams from "scenes/Campaigns/SeeUserModal/Coaching/Resources/NewResourceModal/SetResourceParams";
import { connect } from "react-redux";
import { getUserClientId, getUserId } from "services/user/selectors";
import {
  createResourceRequest,
  updateResourceRequest,
} from "services/resource/actions";
import { resetUpload } from "services/upload/actions";
import { useKeyboardEvent } from "hooks/useKeyboardEvent";
import isValidHttpUrl from "utils/isValidHttpUrl";
import isValidYoutubeUrl from "utils/isValidYoutubeUrl";
import isValidVimeoUrl from "utils/isValidVimeoUrl";

import RESOURCE_EXPLANATION_PICTURE from "resources/resourceExplanation.svg";

const styles = theme => ({
  modal: {
    maxWidth: 950,
    minHeight: 650,
    maxHeight: 650,
    width: 950,
  },
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    paddingBottom: 20,
  },
  resource: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    height: "auto",
    paddingRight: 45,
    textAlign: "left",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    color: Colors.darkBlue,
    fontSize: 28,
    lineHeight: "32px",
    marginBottom: 10,
  },
  buttonDiv: {
    backgroundColor: "#fff",
    paddingTop: 20,
    paddingRight: 25,
    paddingBottom: 25,
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    flexDirection: "row",
  },
});

const NewResourceModal = ({
  classes,
  isVisible,
  onClose,
  clientId,
  userId,
  createResourceRequest,
  updateResourceRequest,
  resetUpload,
  resourceSelected = {},
  requesting,
  sideExplanation = {},
  setResourceSelected = null,
  isCreatedFromLibrary = false,
}) => {
  const [error, setError] = useState("");
  const [wasRequesting, setWasRequesting] = useState(false);
  const [resourceType, setResourceType] = useState("");
  const [resourceTitle, setResourceTitle] = useState("");
  const [fileName, setFileName] = useState("");
  const [link, setLink] = useState("");
  const [modalStep, setModalStep] = useState(0);

  useEffect(() => {
    // reset pre-filled data when resourceSelected change
    const hasId = resourceSelected._id;
    if (hasId) {
      const resource = resourceSelected.resource || resourceSelected;
      setFileName(resource.fileName);
      setResourceType(resource.type);
      setLink(resource.link);
      setResourceTitle(resource.title);
      setModalStep(1);
      setFileName(resource.fileName);
    }
  }, [resourceSelected._id, resourceSelected]);

  const handleClose = useCallback(() => {
    onClose();
    setFileName("");
    setResourceSelected({});
    setResourceType("");
    setLink("");
    setResourceTitle("");
    setModalStep(0);
    setWasRequesting(false);

    resetUpload();
  }, [
    onClose,
    setFileName,
    setResourceSelected,
    setResourceType,
    setLink,
    setResourceTitle,
    setModalStep,
    setWasRequesting,
    resetUpload,
  ]);

  // simulate end of the request
  useEffect(() => {
    if (wasRequesting && !requesting) {
      setWasRequesting(false);
      handleClose();
    }
  }, [requesting, wasRequesting, setWasRequesting, handleClose]);

  const onClickResourceType = type => () => {
    setResourceType(type);
    setModalStep(1);
  };

  const onChangeLink = e => {
    const value = e.target.value;
    if (resourceType === "webLink" && !isValidHttpUrl(value)) {
      setError(i18n.t("not-valid-http-url"));
    } else if (
      resourceType === "videoLink" &&
      !isValidYoutubeUrl(value) &&
      !isValidVimeoUrl(value)
    ) {
      setError(i18n.t("not-valid-youtube-or-vimeo-url"));
    } else {
      setError("");
    }

    setLink(value);
  };

  const onChangeTitle = e => {
    const value = e.target.value;
    setResourceTitle(value);
  };

  const onCreateResource = useCallback(() => {
    if (modalStep === 0) return;
    if (!resourceType || !resourceTitle || !link) {
      return null;
    }

    if (!resourceSelected._id) {
      setWasRequesting(true);
      createResourceRequest({
        clientId,
        userId,
        link,
        type: resourceType,
        title: resourceTitle,
        fileName,
        isCreatedFromLibrary,
      });
    } else {
      updateResourceRequest({
        resourceId: resourceSelected.resourceId,
        link,
        type: resourceType,
        title: resourceTitle,
        fileName,
      });
    }
  }, [
    resourceSelected._id,
    resourceSelected.resourceId,
    modalStep,
    resourceType,
    resourceTitle,
    link,
    setWasRequesting,
    createResourceRequest,
    clientId,
    userId,
    fileName,
    updateResourceRequest,
    isCreatedFromLibrary,
  ]);

  // for event listener

  const ref = useRef(onCreateResource);

  useEffect(() => {
    ref.current = onCreateResource;
  }, [onCreateResource]);

  useKeyboardEvent("Enter", () => ref.current());

  return (
    <Modal
      hasSideExplanation
      sideExplanation={{
        title: i18n.t("resource-explanation-title"),
        explanation: i18n.t("resource-explanation-text"),
        picture: RESOURCE_EXPLANATION_PICTURE,
        ...sideExplanation,
      }}
      className={classes.modal}
      isVisible={isVisible}
      onClose={handleClose}
      bottomComponent={
        modalStep === 1 ? (
          <div className={classes.buttonDiv}>
            <FlatButton
              requesting={requesting}
              disabled={!resourceType || !resourceTitle || !link}
              title={i18n.t("save-resource")}
              onClick={onCreateResource}
            />
          </div>
        ) : null
      }
      title={
        modalStep === 0 ? i18n.t("which-resource-type") : i18n.t(resourceType)
      }
    >
      {modalStep === 0 && (
        <ChooseResourceType onClickResourceType={onClickResourceType} />
      )}
      {modalStep === 1 && (
        <SetResourceParams
          onChangeLink={onChangeLink}
          onChangeFileName={setFileName}
          fileName={fileName}
          link={link}
          error={error}
          resourceTitle={resourceTitle}
          onChangeTitle={onChangeTitle}
          setLink={setLink}
          resourceType={resourceType}
          rating={resourceSelected.rating}
          comment={resourceSelected.comment}
          comments={resourceSelected.comments}
          totalComments={resourceSelected.totalComments}
          totalViews={resourceSelected.totalViews}
        />
      )}
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    userId: getUserId(state),
    clientId: getUserClientId(state),
    requesting: state.resource.requesting,
  };
};

export default connect(mapStateToProps, {
  createResourceRequest,
  updateResourceRequest,
  resetUpload,
})(withStyles(styles)(NewResourceModal));
