import React, { useCallback, useState, useMemo } from "react";

import Typography from "@mui/material/Typography";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

import Colors from "../../constants/Colors";
import { useSelector } from "react-redux";

import StepSymbol from "./StepSymbol";
import AddIcon from "./AddIcon";
import DeleteIcon from "./DeleteIcon";
import replaceFirstHtmlPTag from "../../utils/replaceFirstHtmlPTag";
import selectLanguage from "../../utils/selectLanguage";
import i18n from "../../i18n";
import StarIcon from "./StarIcon";
import CheckIcon from "./CheckIcon";
import ViewIcon from "./CreateActionTextStep/ViewIcon";
import Skill from "./Skills/Skill";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import AdminAvatar from "scenes/Dashboard/AdminAvatar";
import { getUserId } from "services/user/selectors";
import makeStyles from '@mui/styles/makeStyles';
import { isEqual } from "lodash";
import areEqualShallow from "utils/areEqualShallow";

// const HEIGHT_BAR = 60;

const variants = {
  hidden: { opacity: 0, y: 40 },
  visible: { opacity: 1, y: 0 },
};

const useStyles = makeStyles(theme => ({
  main: {
    backgroundColor: "#fff",
    width: "100%",
    maxWidth: "100%",
    overflow: "hidden",
    cursor: "pointer",
    borderColor: "#ECEBF1",
    borderRadius: 4,
    position: "relative",
    // position: "static",
    // borderBottomRadius: 4,
    borderWidth: 1,
    borderTopWidth: 0,
    border: "solid",
    height: 80,
    // height: 70,
    paddingLeft: 15,
    // flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    transition: "all 0.2s",
    "&:hover": {
      backgroundColor: Colors.hoverBlue,
      // borderLeftWidth: 0,
      // boxShadow: `inset 5px 0 0 0 ${Colors.pureMain}`,
    },
  },
  selected: {
    borderLeftWidth: 0,
    boxShadow: `inset 5px 0 0 0 ${Colors.pureMain}`,
    backgroundColor: Colors.hoverBlue,
  },
  title: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    // fontWeight: "bold",
    color: Colors.darkBlue,
    // flex: 1,
    minWidth: 0,
    overflow: "hidden",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 1 /* number of lines to show */,
    lineHeight: "1.5em" /* fallback */,
    maxHeight: "1.5em",
    wordBreak: "break-all",
    // fontWeight: "bold",
    fontSize: "0.9em",
    // marginLeft: 15,
    // marginRight: 15
    // whiteSpace: "nowrap",
    // overflow: "hidden",
  },
  menuTextSelected: {
    fontWeight: "bold",
    color: Colors.white,
  },
  bottomBorder: {
    position: "absolute",
    bottom: 0,
    backgroundColor: Colors.white,
    borderRadius: 3,
    height: 5,
    width: "100%",
  },
  first: {
    borderTopWidth: 1,
  },
  addButton: {
    cursor: "pointer",
    zIndex: 10,
    padding: 10,
    marginRight: 15,
    backgroundColor: Colors.pureMain,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  removeButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: 6,
    borderRadius: "50%",
    borderColor: "transparent",
    backgroundColor: "transparent",
    border: "solid",
    borderWidth: 1,
    "&:hover": {
      backgroundColor: Colors.hoverBlue,
    },
  },
  hasNoLeftBorder: {
    borderLeftWidth: 0,
  },
  noRightBorder: {
    borderRightWidth: 0,
    borderRadius: 0,
  },
  middleDiv: {
    flex: 1,
    marginLeft: 15,
    marginRight: 15,
    flexDirection: "column",
    alignItems: "flex-start",
    display: "flex",
    justifyContent: "center",
  },
  creatingText: {
    color: Colors.main,
  },
  searchResult: {},
  forbidden: {
    filter: "grayscale(1)",
  },
  isDragging: {
    userSelect: "none",
    border: "none",
    backgroundColor: "white",
    borderRadius: 8,
    boxShadow: "2px 4px 8px rgba(0,0,0,.08)",
    transition: "all 0.2s ease-in",
  },
  stepIndex: {
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
    fontSize: 12,
    fontWeight: 500,
    marginRight: 15,
    borderRadius: 4,
    padding: "2px 3px",
    color: "black",
  },
  tags: {
    display: "flex",
    flexDirection: "row",
  },
  stats: {
    minWidth: 50,
    fontWeight: "bold",
    marginRight: 10,
  },
  totalDone: {
    fontWeight: "bold",
    color: Colors.main,
  },
  rating: {
    fontWeight: "bold",
    marginLeft: 5,
    color: Colors.text,
  },
  result: {
    marginLeft: 10,
    color: Colors.text,
  },
  statDiv: {
    marginTop: 2.5,
    marginBottom: 2.5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  toolTip: {
    fontFamily: "Roboto",
    // fontWeight: 450,
  },
  drag: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginLeft: 15,
    cursor: "grab",
  },
  isSelectedByOther: {
    // backgroundColor: "rgba(0, 0, 0, 0.075)",
    "&:hover": {
      // cursor: "not-allowed",
      // backgroundColor: "rgba(0, 0, 0, 0.075)",
    },
    borderWidth: 2,
    borderColor: Colors.orange,
    borderLeftWidth: 0,
    boxShadow: `inset 5px 0 0 0 ${Colors.orange}`,
    // backgroundColor: Colors.darkBlue,
  },
}));

const StepRow = ({
  isSelected,
  languageSelected,
  title,
  onClick,
  isFirst,
  stepType,
  skills = [],
  creating,
  isSearchResult,
  noRightBorder,
  hasDragNDrop = false,
  hasNoLeftBorder = false,
  onClickAddButton,
  onClickDeleteButton,
  isForbidden,
  intercomTarget,
  specialRef,
  provided = { draggableProps: {}, dragHandleProps: {} },
  isDragging,
  stepIndex,
  isLibrary = false,
  totalDone = 0,
  rating = 0,
  views = 0,
  otherAdminsConnected = [],
  style = null,
}) => {
  const classes = useStyles();
  const userId = useSelector(getUserId);

  const [isHovered, setHover] = useState(false);

  const isSelectedByOtherAdmin = useMemo(
    () =>
      otherAdminsConnected.length &&
      otherAdminsConnected?.[0]?.userId !== userId,
    [otherAdminsConnected, userId],
  );

  const language = languageSelected;
  const onClickAdd = e => {
    e.stopPropagation();
    onClickAddButton();
  };

  const onClickDelete = e => {
    e.stopPropagation();
    onClickDeleteButton();
  };

  const displaySkills = useCallback(() => {
    return skills.map((el, index) => (
      <Skill
        {...el}
        isSmall
        key={index}
        label={selectLanguage({ text: el?.label, language })}
      />
    ));
  }, [skills, language]);

  const onMouseLeave = () => {
    setHover(false);
  };

  const onMouseEnter = () => {
    setHover(true);
  };

  return (
    <div
      data-intercom-target={intercomTarget}
      {...provided.dragHandleProps}
      {...provided.draggableProps}
      ref={specialRef}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      onMouseOver={onMouseEnter}
      className={`${classes.main} ${isSelected && classes.selected} ${
        isFirst && classes.first
      } ${isSearchResult && classes.searchResult} ${
        noRightBorder && classes.noRightBorder
      } ${hasNoLeftBorder && classes.hasNoLeftBorder} ${
        creating && classes.creating
      } ${isForbidden && classes.forbidden} ${
        isDragging && classes.isDragging
      } ${isSelectedByOtherAdmin && classes.isSelectedByOther}`}
      onClick={onClick}
    >
      {stepIndex && <p className={classes.stepIndex}>{stepIndex}</p>}

      {otherAdminsConnected.map(el => (
        <AdminAvatar {...el} hasToolTip={true} key={el.userId} />
      ))}
      <StepSymbol stepType={stepType} />
      {hasDragNDrop && isHovered && (
        <div className={classes.drag}>
          <DragIndicatorIcon />
        </div>
      )}
      <div
        className={classes.middleDiv}
        variants={variants}
        initial="hidden"
        animate="visible"
      >
        <Typography
          dangerouslySetInnerHTML={{ __html: replaceFirstHtmlPTag(title) }}
          className={`${classes.title} ${creating && classes.creatingText}`}
        ></Typography>
        <div className={classes.tags}>{displaySkills()}</div>
      </div>

      {isLibrary && (
        <div className={classes.stats}>
          {rating > 0 && (
            <div className={classes.statDiv}>
              <StarIcon sizePercentage={0.7} />
              <Typography className={classes.rating}>
                {rating.toFixed(1)}
              </Typography>
            </div>
          )}
          {views > 0 && (
            <div className={classes.statDiv}>
              <ViewIcon color={Colors.pureMain} />
              <Typography className={classes.result}>{views}x</Typography>
            </div>
          )}
          {totalDone > 0 && (
            <div className={classes.statDiv}>
              <CheckIcon color={"#75D67E"} />
              <Typography className={classes.result}>{totalDone}x</Typography>
            </div>
          )}
        </div>
      )}
      {isHovered && onClickDeleteButton && (
        <Tippy content={i18n.t("remove-selection")} className={classes.toolTip}>
          <div
            className={`${classes.addButton} ${classes.removeButton}`}
            onClick={onClickDelete}
          >
            <DeleteIcon sizePercentage={0.5} color="red" />
          </div>
        </Tippy>
      )}
      {isSearchResult && (
        <div
          className={classes.addButton}
          onClick={onClickAdd}
          data-tip="add selection"
        >
          <AddIcon sizePercentage={0.8} />
        </div>
      )}
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */

  const equal = areEqualShallow(
    { ...prevProps, otherAdminsConnected: undefined },
    { ...nextProps, otherAdminsConnected: undefined },
  );

  if (!equal) return false;

  if (
    prevProps.otherAdminsConnected.length === 0 &&
    nextProps.otherAdminsConnected.length === 0
  )
    return true;

  if (
    prevProps.otherAdminsConnected.length ===
    nextProps.otherAdminsConnected.length
  ) {
    const prevUsersIds = prevProps.otherAdminsConnected.map(el => el?.userId);
    const nextUsersIds = nextProps.otherAdminsConnected.map(el => el?.userId);
    return isEqual(prevUsersIds, nextUsersIds);
  }
}

export default React.memo(StepRow, areEqual);
