import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import {
  getAllSurveysRequest,
  setSurvey,
  createNewSurvey,
  deleteSurveyRequest,
  createSurveyRequest,
} from "services/survey/actions";
import { useDispatch, useSelector } from "react-redux";
import i18n from "i18n";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Colors from "constants/Colors";
import SurveyBox from "scenes/SurveyBuilder/AddSurveyModal/SurveyBox";
import SurveyBuilder from "scenes/SurveyBuilder";
import {
  getSelectedClientId,
  getIsSuperAdmin,
  getUserClientId,
} from "services/user/selectors";
import selectLanguage from "utils/selectLanguage";
import { Loader } from "components";
import SearchBar from "components/SearchBar";
import Fuse from "fuse.js";
import DeleteConfirmModal from "components/DeleteConfirmModal";
import AddButton from "components/AddButton";
import EmptyText from "scenes/Campaigns/SeeUserModal/Coaching/RoadmapCoaching/EmptyText";
import { getSurveyRequesting, getSurveys } from "services/survey/selectors";

const fuseOptions = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 80,
  maxPatternLength: 20,
  minMatchCharLength: 1,
  keys: ["title.fr", "title.en"],
};

const useStyles = makeStyles(theme => ({
  modal: {
    minHeight: "60vh",
    minWidth: "50vw",
    width: 850,
  },
  main: {
    height: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  text: {
    color: "#19212B",
    fontSize: 16,
    width: "100%",
    textAlign: "left",
  },
  topDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    marginTop: 15,
    marginBottom: 15,
  },
  button: {
    marginLeft: 10,
  },
  surveys: {
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    gridGap: "1rem",
    justifyContent: "space-between",
    gridAutoFlow: "row",
    paddingTop: 15,
    width: "100%",
  },
  noSurveys: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    color: Colors.grey,
    textAlign: "center",
    fontStyle: "italic",
  },
  placeholder: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  emptyDiv: {
    display: "flex",
    flex: 1,
  },
}));

const AddSurveyModal = ({
  isVisible,
  onClose,
  onSelectSurvey,
  surveySelectedId = null,
  surveySelectedIds = [],
}) => {
  const classes = useStyles();

  const [isSurveyBuilderVisible, openSurveyBuilder] = useState(false);
  const [isDeleteModalVisible, openDeleteModal] = useState(false);
  const [surveySelected, selectSurvey] = useState({});
  const [search, setSearch] = useState("");

  const surveys = useSelector(getSurveys);
  const requesting = useSelector(getSurveyRequesting);
  const selectedClientId = useSelector(getSelectedClientId);
  const userClientId = useSelector(getUserClientId);
  const isSuperAdmin = useSelector(getIsSuperAdmin);

  const dispatch = useDispatch();

  const onChangeSearch = searchText => setSearch(searchText);

  const onCloseSurveyBuilder = () => openSurveyBuilder(false);
  const onOpenSurveyBuilder = () => openSurveyBuilder(true);

  useEffect(() => {
    dispatch(
      getAllSurveysRequest({
        clientId: isSuperAdmin ? selectedClientId : userClientId,
      }),
    );
  }, [isVisible, isSuperAdmin, selectedClientId, userClientId, dispatch]);

  const onEditSurvey = survey => e => {
    e.stopPropagation();
    dispatch(setSurvey(survey));
    onOpenSurveyBuilder();
  };

  const onDeleteSurvey = survey => e => {
    const surveyId = survey._id;
    e.stopPropagation();
    dispatch(deleteSurveyRequest({ surveyId: survey._id, isRemoved: true }));
    // deselect the survey
    if (surveySelectedId === surveyId) {
      onSelectSurvey(survey)();
    }

    onCloseDeleteModal();
  };

  const onOpenDeleteModal = survey => e => {
    e.stopPropagation();
    selectSurvey(survey);
    openDeleteModal(true);
  };

  const onCloseDeleteModal = () => {
    selectSurvey({});
    openDeleteModal(false);
  };

  const onDuplicateSurvey = survey => e => {
    e.stopPropagation();
    const deepCopy = JSON.parse(JSON.stringify(survey));
    deepCopy.questions.map(question => {
      delete question["_id"];
      delete question["__v"];
      return question;
    });
    delete deepCopy["__v"];
    delete deepCopy["_id"];
    delete deepCopy["updatedAt"];
    delete deepCopy["createdAt"];
    const { title } = deepCopy;
    if (title.fr) {
      title["fr"] = `Copie ${title["fr"]}`;
    }
    if (title.en) {
      title["en"] = `Copy ${title["en"]}`;
    }

    dispatch(createSurveyRequest({ ...deepCopy }));
  };

  const onCreateNewSurvey = () => {
    dispatch(createNewSurvey());
    onOpenSurveyBuilder();
  };

  const fuse = new Fuse(surveys, fuseOptions);
  const searchResults = search.length > 0 ? fuse.search(search) : surveys;

  return (
    <Modal
      className={classes.modal}
      title={i18n.t("add-survey-modal")}
      isVisible={isVisible}
      onClose={onClose}
    >
      <SurveyBuilder
        isVisible={isSurveyBuilderVisible}
        onClose={onCloseSurveyBuilder}
      />
      <DeleteConfirmModal
        onClickConfirm={onDeleteSurvey(surveySelected)}
        title={i18n.t("delete-confirmation-modal-survey-title")}
        isVisible={isDeleteModalVisible}
        onClose={onCloseDeleteModal}
      />
      <div className={classes.main}>
        <Typography className={classes.text}>
          {i18n.t("select-or-create-survey")}
        </Typography>
        <div className={classes.topDiv}>
          <SearchBar
            placeholder={i18n.t("search-survey")}
            value={search}
            onChangeSearch={onChangeSearch}
          />
          <AddButton
            title={i18n.t("create-new-survey")}
            className={classes.button}
            onClick={onCreateNewSurvey}
          />
        </div>
        {!requesting && !searchResults.length && (
          <EmptyText
            className={classes.placeholder}
            firstTextPart={i18n.t("create-first-survey")}
            onClick={onCreateNewSurvey}
          />
        )}
        {requesting ? (
          <Loader size={40} />
        ) : (
          <div className={classes.surveys}>
            {searchResults
              ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              ?.map(survey => {
                return (
                  <SurveyBox
                    isSelected={
                      surveySelectedIds.find(el => el === survey._id) ||
                      survey._id === surveySelectedId
                    }
                    key={survey._id}
                    title={selectLanguage({ text: survey.title })}
                    createdAt={survey.createdAt}
                    numberOfQuestions={survey.questions.length}
                    onClick={onSelectSurvey(survey)}
                    onClickEdit={onEditSurvey(survey)}
                    onClickDelete={onOpenDeleteModal(survey)}
                    onClickDuplicate={onDuplicateSurvey(survey)}
                  />
                );
              })}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AddSurveyModal;
