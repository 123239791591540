import React, { PureComponent } from "react";
import { connect } from "react-redux";
import moment from "moment";

import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";

import Paper from "@mui/material/Paper";
import CountUp from "react-countup";

import Colors from "../../constants/Colors";

import "moment/locale/fr";
moment.locale("fr");
/*
const dataTest = [
  {
    Actions: 0,
  },
  {
    Actions: 34,
  },
  {
    Actions: 84,
  },
  {
    Actions: 94,
  },
  {
    Actions: 124,
  },
  {
    Actions: 165,
  },
  {
    Actions: 200,
  },
];

const getWeeklyData = allActions => {
  const result = {};
  allActions.forEach(el => {
    const week = moment(el.date).week();
    if (result[week]) {
      result[week].actions++;
    } else {
      result[week] = {
        actions: 1,
      };
    }
    result[week].week = week;
    result[week].date = moment().week(week).format("D MMMM");
  });
  // Initial date -> 0 actions
  const finalArray = [{ actions: 0 }];
  Object.keys(result).forEach(el => {
    finalArray.push(result[el]);
  });
  finalArray.sort((a, b) => a.week - b.week);
  // cumulative
  finalArray.forEach((el, i) => {
    if (i > 0) {
      finalArray[i].actions += finalArray[i - 1].actions;
    }
  });
  if (allActions.length < 1) {
    return [
      {
        actions: 0,
      },
      {
        actions: 0,
      },
      {
        actions: 0,
      },
      {
        actions: 0,
      },
      {
        actions: 0,
      },
      {
        actions: 0,
      },
      {
        actions: 0,
      },
    ];
  }
  return finalArray;
};*/

const styles = theme => ({
  main: {
    // backgroundColor: "#FAFAFA",
    backgroundColor: "#fff",
    flex: 1,
    paddingRight: "5%",
    paddingLeft: "5%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  leftSection: {
    display: "flex",
    flex: 1,
    height: 250,
    marginRight: "5%",
    alignItems: "center",
    justifyContent: "center",
  },
  rightSection: {
    flex: 3,
    display: "flex",
    height: 250,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  companyLogo: {
    width: "40%",
  },
  accent: {
    color: "#6198DE",
  },
  rightTitle: {
    fontWeight: 500,
    marginBottom: 5,
  },
  bold: {
    fontWeight: 500,
  },
  totalDone: {
    color: "#fff",
    fontWeight: 500,
  },
  actionLegend: {
    color: "#fff",
  },
  circle: {
    width: 200,
    height: 200,
    borderRadius: 100,
    webkitBoxShadow: `0px 0px 15px 2px ${Colors.main}`,
    mozBoxShadow: `0px 0px 15px 2px ${Colors.main}`,
    boxShadow: `0px 0px 15px 2px ${Colors.main}`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.main,
  },
  rightBox: {
    webkitBoxShadow: "0px 0px 4px 1px #D8D8D8",
    mozBoxShadow: "0px 0px 4px 1px #D8D8D8",
    boxShadow: "0px 0px 4px 1px #D8D8D8",
    borderRadius: 10,
    // height: 200,
    width: "100%",
    padding: 10,
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  catchPhrase: {
    color: Colors.text,
    fontSize: "1.25rem",
    // fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    // lineHeight: 1.6,
    letterSpacing: "0.0075em",
  },
});

class TotalDoneActionsSection extends PureComponent {
  state = {
    name: "",
    owner: "",
    domain: "",
  };

  componentDidMount = () => {
    // this.props.getActionListRequest();
    // setTimeout(() => {
    //   this.setState({ data: this.props.weeklyData });
    // }, 300);
  };

  onChangeClient = event => {
    this.props.changeCreateField({ name: event.target.value });
  };

  onChangeEmail = event => {
    this.props.changeCreateField({ owner: event.target.value });
  };

  onChangeDomain = event => {
    this.props.changeCreateField({ domain: event.target.value });
  };

  onSubmit = () => {
    this.props.createClientRequest({
      name: this.props.name,
      owner: this.props.owner,
      domain: this.props.domain,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.main}>
        <div className={classes.leftSection}>
          <div className={classes.circle}>
            <Typography
              align="center"
              variant="h2"
              classes={{ root: classes.totalDone }}
            >
              <CountUp
                end={this.props.totalDoneActions}
                duration={3}
                start={0}
                delay={0.1}
              />
            </Typography>
            <Typography align="center" classes={{ root: classes.actionLegend }}>
              Actions réalisées
            </Typography>
          </div>
        </div>
        <div className={classes.rightSection}>
          <Paper className={classes.rightBox}>
            <p className={classes.catchPhrase}>
              <b>{this.props.totalDoneActions}</b> actions bienvaillantes ont
              été implémentées
            </p>
          </Paper>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    allActions,
    createdAt,
  } = state.client;
  return {
    token: state.auth.token,
    createdAt,
    period: "Janvier 2018",
    companyName: "Pulse",
    endRange: "28/01/2018",
    startingRange: "31/12/2017",
    totalDoneActions: allActions.length,
  };
};

export default connect(
  mapStateToProps,
  {},
)(withStyles(styles)(TotalDoneActionsSection));
