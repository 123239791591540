//@flow
import { call, put, all, takeLatest, select } from "redux-saga/effects";

import {
  GET_RESOURCE_REQUESTING,
  GET_RESOURCE_SUCCESS,
  GET_RESOURCE_ERROR,
  CREATE_RESOURCE_REQUESTING,
  CREATE_RESOURCE_SUCCESS,
  CREATE_RESOURCE_ERROR,
  UPDATE_RESOURCE_REQUESTING,
  UPDATE_RESOURCE_SUCCESS,
  UPDATE_RESOURCE_ERROR,
  GET_RESOURCE_LIST_REQUESTING,
  GET_RESOURCE_LIST_SUCCESS,
  GET_RESOURCE_LIST_ERROR,
  GET_LIBRARY_RESOURCES_SUCCESS,
  GET_LIBRARY_RESOURCES_ERROR,
  GET_LIBRARY_RESOURCES_REQUESTING,
  DELETE_RESOURCE_REQUESTING,
  GET_CAMPAIGN_RESOURCES_REQUESTING,
  REMOVE_CAMPAIGN_RESOURCE,
  ADD_CAMPAIGN_RESOURCE,
  UPDATE_CAMPAIGN_RESOURCES_REQUESTING,
  UPDATE_CAMPAIGN_RESOURCES_SUCCESS,
  UPDATE_CAMPAIGN_RESOURCES_ERROR,
  GET_CAMPAIGN_RESOURCES_SUCCESS,
  GET_CAMPAIGN_RESOURCES_ERROR,
  ADD_LIBRARY_RESOURCE,
} from "./constants";

import { fetchApi } from "../api";
import { openSnackMessage } from "../general/actions";
import i18n from "../../i18n";
import {
  updateCoachingSession,
  updateCoachingSessionRequest,
} from "services/coaching/actions";
import { modifyCampaignRequest } from "services/campaign/actions";
import { getCoachingSession } from "services/coaching/selectors";
import { getCampaignResources } from "services/resource/selectors";
import { getSelectedCampaignId } from "services/campaign/selectors";
import { updateCampaignResources } from "services/resource/actions";

const endPoints = {
  getResource: "/v1/resources/",
  updateResource: "/v1/resources/",
  createResource: "/v1/resources/",
  getResourcesList: "/v1/resources/list",
  getLibraryResources: "/v1/resources/library",
  updateCampaignResources: "/v1/resources/campaign",
  getCampaignResourcesWithStats:
    "/v1/resources/campaign/get-resources-with-stats",
};

const getResourceApi = data => {
  const { coachId, userId } = data;
  return fetchApi(
    `${endPoints.getResource}?coachId=${coachId}&userId=${userId}`,
    {},
    "get",
  );
};

const getResourceListApi = data => {
  const { resourceIds } = data;
  return fetchApi(
    `${endPoints.getResourcesList}?resourceIds=${resourceIds}`,
    {},
    "get",
  );
};

const getCampaignResourcesWithStatsApi = data => {
  return fetchApi(`${endPoints.getCampaignResourcesWithStats}`, data, "post");
};

const getLibraryResourcesApi = data => {
  const { userId, clientId } = data;
  return fetchApi(
    `${endPoints.getLibraryResources}?userId=${userId}&clientId=${clientId}`,
    {},
    "get",
  );
};

export const updateResourceApi = data => {
  return fetchApi(endPoints.updateResource, data, "patch");
};

export const updateCampaignResourcesApi = data => {
  return fetchApi(endPoints.updateCampaignResources, data, "patch");
};

export const createResourceApi = data => {
  return fetchApi(endPoints.createResource, data, "post");
};

function* getLibraryResourcesFlow(action) {
  try {
    const response = yield call(getLibraryResourcesApi, action.payload);
    // yield put(
    //   openSnackMessage({ snackMessage: i18n.t("data-modified-success") })
    // );
    yield put({ type: GET_LIBRARY_RESOURCES_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_LIBRARY_RESOURCES_ERROR, payload: error });
  }
}

function* getResourcesListFlow(action) {
  try {
    const response = yield call(getResourceListApi, action.payload);
    // yield put(
    //   openSnackMessage({ snackMessage: i18n.t("data-modified-success") })
    // );
    yield put({ type: GET_RESOURCE_LIST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_RESOURCE_LIST_ERROR, payload: error });
  }
}

function* getResourceFlow(action) {
  try {
    const response = yield call(getResourceApi, action.payload);
    // yield put(
    //   openSnackMessage({ snackMessage: i18n.t("data-modified-success") })
    // );
    yield put({ type: GET_RESOURCE_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_RESOURCE_ERROR, payload: error });
  }
}

function* updateResourceFlow(action) {
  try {
    const response = yield call(updateResourceApi, action.payload);
    yield put(
      openSnackMessage({ snackMessage: i18n.t("data-modified-success") }),
    );
    yield put({ type: UPDATE_RESOURCE_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: UPDATE_RESOURCE_ERROR, payload: error });
  }
}

function* createResourceFlow(action) {
  try {
    const { isCreatedFromLibrary = false } = action.payload;
    console.log({ isCreatedFromLibrary });

    const response = yield call(createResourceApi, action.payload);
    yield put(
      openSnackMessage({ snackMessage: i18n.t("data-modified-success") }),
    );

    yield put({ type: CREATE_RESOURCE_SUCCESS, payload: response });
    if (isCreatedFromLibrary) {
      return yield put({ type: ADD_LIBRARY_RESOURCE, payload: response });
    }

    // coaching session updated
    const coachingSession = yield select(getCoachingSession);

    if (coachingSession._id) {
      const coachingSessionId = coachingSession._id;
      const newCoachingResources = [
        { resourceId: response._id },
        ...coachingSession.resources,
      ];
      // update local
      yield put(updateCoachingSession({ resources: newCoachingResources }));
      // update DB
      yield put(
        updateCoachingSessionRequest({
          resources: newCoachingResources,
          coachingSessionId,
        }),
      );
    } else {
      // add resource to Campaign
      const campaignId = yield select(getSelectedCampaignId);
      const resources = yield select(getCampaignResources);
      const newResources = [
        {
          ...response,
          rating: 0,
          totalComments: 0,
          totalFeedbacks: 0,
          totalViews: 0,
          comment: "",
          comments: [],
          resourceId: response._id,
        },
        ...resources,
      ];
      yield put(
        updateCampaignResources({ campaignId, resources: newResources }),
      );
    }
  } catch (error) {
    console.log("ERRROR");
    console.log(error);
    yield put({ type: CREATE_RESOURCE_ERROR, payload: error });
  }
}

function* deleteResourceFlow(action) {
  try {
    yield updateResourceFlow({
      payload: { ...action.payload, isRemoved: true },
    });
  } catch (error) {
    console.log(error);
  }
}

function* addCampaignResourceFlow(action) {
  try {
    // coaching session updated
    const campaignId = yield select(getSelectedCampaignId);
    const resources = yield select(getCampaignResources);
    yield put(
      modifyCampaignRequest({
        campaignId,
        resources,
      }),
    );
  } catch (error) {
    console.log("ERRROR");
    console.log(error);
  }
}

function* removeCampaignResourceFlow(action) {
  try {
    // coaching session updated
    const campaignId = yield select(getSelectedCampaignId);
    const resources = yield select(getCampaignResources);
    yield put(
      modifyCampaignRequest({
        campaignId,
        resources,
      }),
    );
  } catch (error) {
    console.log("ERRROR");
    console.log(error);
  }
}

function* updateCampaignResourcesFlow(action) {
  try {
    // coaching session updated
    const result = yield call(updateCampaignResourcesApi, action.payload);
    yield put({ type: UPDATE_CAMPAIGN_RESOURCES_SUCCESS, payload: result });
  } catch (error) {
    console.log("ERRROR");
    console.log(error);
    yield put({ type: UPDATE_CAMPAIGN_RESOURCES_ERROR, payload: error });
  }
}

function* getCampaignResourcesWithStatsFlow(action) {
  try {
    // coaching session updated
    const result = yield call(getCampaignResourcesWithStatsApi, action.payload);
    yield put({ type: GET_CAMPAIGN_RESOURCES_SUCCESS, payload: result });
  } catch (error) {
    console.log("ERRROR");
    console.log(error);
    yield put({ type: GET_CAMPAIGN_RESOURCES_ERROR, payload: error });
  }
}

function* userWatcher() {
  yield all([
    takeLatest(UPDATE_RESOURCE_REQUESTING, updateResourceFlow),
    takeLatest(CREATE_RESOURCE_REQUESTING, createResourceFlow),
    takeLatest(GET_RESOURCE_REQUESTING, getResourceFlow),
    takeLatest(GET_RESOURCE_LIST_REQUESTING, getResourcesListFlow),
    takeLatest(GET_LIBRARY_RESOURCES_REQUESTING, getLibraryResourcesFlow),
    takeLatest(DELETE_RESOURCE_REQUESTING, deleteResourceFlow),
    takeLatest(ADD_CAMPAIGN_RESOURCE, addCampaignResourceFlow),
    takeLatest(REMOVE_CAMPAIGN_RESOURCE, removeCampaignResourceFlow),
    takeLatest(
      UPDATE_CAMPAIGN_RESOURCES_REQUESTING,
      updateCampaignResourcesFlow,
    ),
    takeLatest(
      GET_CAMPAIGN_RESOURCES_REQUESTING,
      getCampaignResourcesWithStatsFlow,
    ),
  ]);
}

export default userWatcher;
