import React from "react";

const BillIcon = () => {

  return (
    <svg
      width="24"
      height="29"
      viewBox="0 0 24 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.06152"
        y="1.14453"
        width="21.4762"
        height="26"
        rx="1"
        stroke="#5FB8F9"
        strokeWidth="2"
      />
      <rect
        x="4.8269"
        y="5.04883"
        width="11.9973"
        height="2.29591"
        rx="1"
        fill="#5FB8F9"
      />
      <rect
        x="4.8269"
        y="9.24121"
        width="9.54887"
        height="2.29591"
        rx="1"
        fill="#5FB8F9"
      />
      <rect
        x="4.8269"
        y="13.4346"
        width="9.54887"
        height="2.29591"
        rx="1"
        fill="#5FB8F9"
      />
    </svg>
  );
};

export default BillIcon;
