import React from "react";
import { TextField, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import i18n from "i18n";
import SettingBooleanBlock from "./SettingBooleanBlock";
import OptionRow from "./OptionRow";

const useStyles = makeStyles(theme => ({
  main: {
    width: "100%",
    marginTop: 15,
    backgroundColor: "#F8F9FA",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 25,
    paddingBottom: 25,
  },
  topDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  title: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 16,
  },
  text: {
    fontFamily: "Hind",
    color: "#A4A4A4",
    fontSize: 12,
    textAlign: "right",
  },
  box: {
    marginTop: 15,
    boxShadow: "0px 4px 4px 0px #E7E7E7",
    background: "#FFFFFF",
    border: "1px solid #E7E8EE",
    borderRadius: 4,
    paddingLeft: 20,
    paddingRight: 30,
    paddingTop: 15,
    paddingBottom: 20,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  leftSide: {
    paddingTop: 2,
    marginRight: 18,
  },
  centerDiv: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  explanation: {
    color: "#687688",
    fontSize: 14,
    fontFamily: "Hind",
  },
  uploadInstruction: {
    marginTop: 25,
  },
  options: {
    marginTop: 40,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
}));

type UploadOption = "video" | "picture" | "document";

interface Props {
  settings: {
    uploadInstruction: { fr: string; en: string };
    isUploadRequired: boolean;
    uploadOptions: UploadOption[];
  };
  language: string;
  onChangeSettings: (setting: any) => (param: any) => void;
}

const StepSettings = ({
  settings = {
    uploadInstruction: {
      fr: "Uploadez des vidéos ou des photos",
      en: "Upload pictures or videos",
    },
    isUploadRequired: false,
    uploadOptions: ["video", "picture", "document"],
  },
  language,
  onChangeSettings,
}: Props) => {
  const {
    isUploadRequired = false,
    uploadInstruction = {},
    uploadOptions = ["video", "picture", "document"],
  } = settings;
  const classes = useStyles();

  const onChangeUploadInstruction = e => {
    const value = e.target.value;
    const newUploadInstruction = { ...uploadInstruction, [language]: value };
    onChangeSettings("uploadInstruction")(newUploadInstruction);
  };

  const onChangeHasUpload = () => {
    onChangeSettings("isUploadRequired")(!isUploadRequired);
  };

  const onSelectOption = (option: any) => () => {
    const hasOption = uploadOptions.find(el => el === option);
    let newUploadOptions = [...uploadOptions];
    if (hasOption) {
      newUploadOptions = uploadOptions.filter(el => el !== option);
    } else {
      newUploadOptions.push(option);
    }

    onChangeSettings("uploadOptions")(newUploadOptions);
    return;
  };

  return (
    <div className={classes.main}>
      <div className={classes.topDiv}>
        <Typography className={classes.title}>{i18n.t("settings")}</Typography>
        <Typography className={classes.text}>{i18n.t("saved")}</Typography>
      </div>
      <div className={classes.box}>
        <div className={classes.leftSide}>
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.6925 4.5121L7.74579 1.2621C7.69515 1.20599 7.63332 1.16109 7.56429 1.13031C7.49526 1.09952 7.42055 1.08353 7.34496 1.08335H3.55329C3.37332 1.0812 3.19469 1.11453 3.02761 1.18142C2.86052 1.24832 2.70824 1.34747 2.57947 1.47322C2.45071 1.59896 2.34797 1.74885 2.27714 1.9143C2.2063 2.07976 2.16875 2.25755 2.16663 2.43752V10.5625C2.16875 10.7425 2.2063 10.9203 2.27714 11.0857C2.34797 11.2512 2.45071 11.4011 2.57947 11.5268C2.70824 11.6526 2.86052 11.7517 3.02761 11.8186C3.19469 11.8855 3.37332 11.9188 3.55329 11.9167H9.44663C9.62659 11.9188 9.80522 11.8855 9.97231 11.8186C10.1394 11.7517 10.2917 11.6526 10.4204 11.5268C10.5492 11.4011 10.6519 11.2512 10.7228 11.0857C10.7936 10.9203 10.8312 10.7425 10.8333 10.5625V4.87502C10.8329 4.74077 10.7828 4.61144 10.6925 4.5121V4.5121ZM9.56038 4.87502H7.42621C7.31454 4.86402 7.21166 4.8096 7.13973 4.72348C7.06779 4.63737 7.03256 4.52644 7.04163 4.4146V2.16668H7.10121L9.56038 4.87502ZM9.44663 10.8333H3.55329C3.51558 10.8355 3.4778 10.8303 3.44213 10.8178C3.40646 10.8054 3.37359 10.7861 3.34541 10.7609C3.31723 10.7357 3.29429 10.7053 3.27791 10.6712C3.26153 10.6372 3.25203 10.6002 3.24996 10.5625V2.43752C3.25203 2.3998 3.26153 2.36285 3.27791 2.32881C3.29429 2.29477 3.31723 2.2643 3.34541 2.23913C3.37359 2.21397 3.40646 2.19462 3.44213 2.18219C3.4778 2.16976 3.51558 2.16449 3.55329 2.16668H5.95829V4.4146C5.94955 4.8136 6.09899 5.19987 6.374 5.48909C6.64902 5.77831 7.02727 5.947 7.42621 5.95835H9.74996V10.5625C9.74788 10.6002 9.73838 10.6372 9.72201 10.6712C9.70563 10.7053 9.68269 10.7357 9.65451 10.7609C9.62633 10.7861 9.59346 10.8054 9.55779 10.8178C9.52212 10.8303 9.48434 10.8355 9.44663 10.8333V10.8333Z"
              fill="#2D2D2E"
            />
          </svg>
        </div>
        <div className={classes.centerDiv}>
          <SettingBooleanBlock
            onChange={onChangeHasUpload}
            checked={isUploadRequired}
            title={i18n.t("request-file-upload")}
            explanation={i18n.t("request-file-upload-explanation")}
          />

          <div className={classes.options}>
            <OptionRow
              icon={"video"}
              text={i18n.t("Video")}
              onClick={onSelectOption("video")}
              isSelected={!!uploadOptions.find(el => el === "video")}
            />
            <OptionRow
              icon={"picture"}
              text={i18n.t("Picture")}
              onClick={onSelectOption("picture")}
              isSelected={!!uploadOptions.find(el => el === "picture")}
            />
            <OptionRow
              icon={"document"}
              text={i18n.t("Document")}
              onClick={onSelectOption("document")}
              isSelected={!!uploadOptions.find(el => el === "document")}
            />
          </div>

          <TextField
            className={classes.uploadInstruction}
            fullWidth
            placeholder={i18n.t("upload-instruction-placeholder")}
            label={i18n.t("upload-instruction", { lng: language })}
            value={uploadInstruction[language] || ""}
            onChange={onChangeUploadInstruction}
            margin="normal"
            variant="outlined"
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(StepSettings);
