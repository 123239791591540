import React from "react";

const MultipleChoicesIcon = ({ sizePercentage, color }) => (
  <svg
    width={sizePercentage * 16}
    height={sizePercentage * 16}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 16 16"
    version="1.1"
  >
    <path
      d="M4.21609 4.00009V0.998064C4.21609 0.799018 4.29516 0.608124 4.43591 0.467377C4.57665 0.326629 4.76755 0.247559 4.96659 0.247559H14.7232C14.9222 0.247559 15.1131 0.326629 15.2539 0.467377C15.3946 0.608124 15.4737 0.799018 15.4737 0.998064V10.7546C15.4737 10.9537 15.3946 11.1446 15.2539 11.2853C15.1131 11.4261 14.9222 11.5051 14.7232 11.5051H11.7211V14.5019C11.7211 14.9192 11.3842 15.2577 10.9654 15.2577H1.21932C1.12005 15.2578 1.02173 15.2383 0.929988 15.2003C0.838251 15.1624 0.754897 15.1067 0.684699 15.0365C0.6145 14.9663 0.558835 14.883 0.52089 14.7912C0.482944 14.6995 0.463464 14.6012 0.463562 14.5019L0.465814 4.75584C0.465814 4.33856 0.802791 4.00009 1.22157 4.00009H4.21609ZM5.7171 4.00009H10.9654C11.3827 4.00009 11.7211 4.33706 11.7211 4.75584V10.0041H13.9727V1.74857H5.7171V4.00009ZM10.2201 5.5011H1.96682L1.96457 13.7567H10.2201V5.5011ZM5.3441 12.2556L2.69031 9.60186L3.75153 8.54064L5.3441 10.1332L8.52774 6.94882L9.58896 8.01004L5.3441 12.2556Z"
      fill={color}
    />
  </svg>
);

MultipleChoicesIcon.defaultProps = {
  sizePercentage: 1,
  color: "#19212B",
};

export default MultipleChoicesIcon;
