import { call, put, takeLatest, all, select } from "redux-saga/effects";

import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_FAILURE,
  AUTH_LOGOUT,
  AUTH_LOGOUT_SUCCESS,
} from "./reducer";
import { fetchApi } from "../api";
import {
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_REQUESTING,
  RESET_PASSWORD_SUCCESS,
  SEND_EMAIL_INVITATIONS_ERROR,
  SEND_EMAIL_INVITATIONS_SUCCESS,
  SEND_EMAIL_INVITATIONS_REQUESTING,
} from "./constants";
import { openSnackMessage } from "../general/actions";
import i18n from "../../i18n";
import socket from "../socket.js";
import { getUserId } from "services/user/selectors";
import { CLIENT_GET_PENDING_USERS } from "services/client/constants";

const apiEndpoints = {
  resetPassword: "/v1/auth/reset-password",
  loginAdmin: "/v1/auth/login-dashboard",
  sendEmailInvitations: "/v1/auth/invite/emails",
};

const loginApi = data => {
  return fetchApi(apiEndpoints.loginAdmin, data, "post");
};

const resetPasswordApi = data => {
  return fetchApi(apiEndpoints.resetPassword, data, "post");
};

const sendEmailInvitationsApi = data => {
  return fetchApi(apiEndpoints.sendEmailInvitations, data, "post");
};

function* sendEmailInvitationsFlow(action) {
  try {
    const result = yield call(sendEmailInvitationsApi, action.payload);
    yield put({ type: SEND_EMAIL_INVITATIONS_SUCCESS, payload: result });
    yield put({ type: CLIENT_GET_PENDING_USERS, payload: result });
    yield put(openSnackMessage({ snackMessage: i18n.t("email-sent") }));
  } catch (error) {
    yield put({ type: SEND_EMAIL_INVITATIONS_ERROR, payload: error.message });
  }
}

function* resetPasswordFlow(action) {
  try {
    yield call(resetPasswordApi, action.payload);
    yield put({ type: RESET_PASSWORD_SUCCESS });
  } catch (error) {
    yield put({ type: RESET_PASSWORD_ERROR });
  }
}

function* authorize(action) {
  try {
    const { token, user, client } = yield call(loginApi, action.payload);
    yield put({
      type: AUTH_SUCCESS,
      payload: token,
      user,
      client,
    });

    socket.emit("admin-init", user._id);

    yield window.Intercom("boot", {
      app_id: "f3rbos9o",
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phoneNumber,
      companies: [
        {
          id: user.client,
          size: client.companySize,
          name: client.organization,
        },
      ],
      name: `${user.firstName} ${user.lastName}`, // Full name
      email: `${user.email}`, // Email address
      created_at: `${user.createdAt}`, // Signup date as a Unix timestamp
    });
    // yield put(getClientRequest({ clientId: client._id }));
  } catch (error) {
    yield put({ type: AUTH_FAILURE, payload: error.message });
  }
}

function* logoutFlow(action) {
  const userId = yield select(getUserId);
  yield window.localStorage.clear();
  yield window.sessionStorage.clear();

  yield put({ type: AUTH_LOGOUT_SUCCESS });
  socket.emit("logout", userId);
}

function* Saga() {
  yield all([
    takeLatest(AUTH_REQUEST, authorize),
    takeLatest(AUTH_LOGOUT, logoutFlow),
    takeLatest(RESET_PASSWORD_REQUESTING, resetPasswordFlow),
    takeLatest(SEND_EMAIL_INVITATIONS_REQUESTING, sendEmailInvitationsFlow),
  ]);
}

export default Saga;
