import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Colors from "constants/Colors";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "scenes/CreateTrack/Skills/CloseIcon";

import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ModalSideHelper from "./ModalSideHelper";

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: 10,
    minWidth: "33%",
    minHeight: "40%",
    display: "flex",
    flexDirection: "row",
    maxWidth: 900,
    maxHeight: `90vh`,
  },
  rightModal: {
    paddingTop: 5,
    paddingRight: 8,
    paddingLeft: 8,
    // minWidth: 600,
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    flex: 1,
    marginRight: 15,
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 24,
    color: Colors.darkBlue,
  },
  closeButton: {
    zIndex: 10,
    marginRight: -15,
    padding: 12,
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  topComponent: {
    width: "100%",
    marginRight: 25,
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
  },
}));

interface Props {
  className?: string;
  titleClassName?: string;
  headerClassName?: string;
  title: string;
  isVisible: boolean;
  children: JSX.Element;
  onClose: () => void;
  onRendered?: () => void;
  topComponent?: JSX.Element;
  topComponentClassName?: string;
  bottomComponent?: JSX.Element;
  hasSideExplanation?: boolean;
  hasExitButton?: boolean;
  onKeyDown?: (event: any) => void;
  onContentOnly?: boolean;
  sideExplanation?: {
    picture: string;
    title: string;
    explanation: string;
    bottomElement?: JSX.Element;
  };
}

const Modal = ({
  className,
  onContentOnly = false,
  titleClassName = "",
  headerClassName = "",
  topComponentClassName = "",
  title,
  children,
  isVisible,
  onClose,
  onRendered = null,
  topComponent = null,
  bottomComponent = null,
  hasSideExplanation = false,
  hasExitButton = true,
  sideExplanation = {
    picture: "",
    title: "",
    explanation: "",
    bottomElement: null,
  },
  onKeyDown = e => {
    if (e.key === "Escape" && isVisible) {
      onClose();
    }
  },
}: Props) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isVisible}
      PaperProps={{
        style: {
          borderRadius: 10,
          minWidth: "33%",
          minHeight: "40%",
          display: "flex",
          flexDirection: "row",
          maxWidth: 900,
          maxHeight: `90vh`,
        },
      }}
      classes={{ paper: `${className}` }}
      onClose={onClose}
      onKeyDown={onKeyDown}
      ref={onRendered}
    >
      {hasSideExplanation && <ModalSideHelper {...sideExplanation} />}
      <div className={`${classes.rightModal}`}>
        {!onContentOnly && (
          <DialogTitle
            classes={{
              root: classes.header,
            }}
            className={`${classes.header} ${headerClassName}`}
          >
            {!!title && (
              <Typography
                variant="h6"
                className={`${classes.title} ${titleClassName}`}
              >
                {title}{" "}
              </Typography>
            )}
            {!!topComponent && (
              <div
                className={`${classes.topComponent} ${topComponentClassName}`}
              >
                {topComponent}
              </div>
            )}
            {hasExitButton && (
              <IconButton className={classes.closeButton} onClick={onClose}>
                <CloseIcon sizePercentage={2} />
              </IconButton>
            )}
          </DialogTitle>
        )}
        <DialogContent className={classes.dialogContent}>
          {children}
        </DialogContent>
        {bottomComponent}
      </div>
    </Dialog>
  );
};

export default Modal;
