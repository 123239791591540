import React, { useState } from "react";
import withStyles from '@mui/styles/withStyles';
import Box from "components/Box";
import BoxTitle from "components/BoxTitle";
import i18n from "i18n";
import BoxHeader from "components/BoxHeader";
import SessionComponent from "scenes/Campaigns/SeeUserModal/Coaching/Sessions/SessionComponent";
import NextSectionCalendarModal from "scenes/Campaigns/SeeUserModal/Coaching/Sessions/NextSessionCalendarModal/NextSectionCalendarModal";
import { getCoachingSessionId, getSessions } from "services/coaching/selectors";
import { updateCoachingSessionRequest } from "services/coaching/actions";
import { connect } from "react-redux";
import FlatButton from "components/FlatButton";
import AddIcon from "scenes/Campaigns/SeeUserModal/Coaching/RoadmapCoaching/NewGoalModal/GoalSettings/AddIcon";
import moment from "moment";
import SessionSummary from "scenes/Campaigns/SeeUserModal/Coaching/Sessions/SessionSummary/SessionSummary";
import EmptyText from "scenes/Campaigns/SeeUserModal/Coaching/RoadmapCoaching/EmptyText";

const styles = theme => ({
  main: {
    marginTop: 0,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    paddingBottom: 25,
  },
  body: {
    width: "100%",
    paddingLeft: 40,
    paddingRight: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  buttons: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "row",
  },
  sessions: {
    borderLeft: "1px solid #F0F0F0",
    paddingLeft: 35,
    marginBottom: -30,
    paddingTop: 40,
    paddingBottom: 80,
    paddingRight: 20,
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    overflow: "scroll",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  firstResources: {
    marginTop: 30,
    alignSelf: "center",
    textAlign: "center",
    color: "#A8A8A8",
    fontSize: 18,
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  regular: {
    fontWeight: "normal",
  },
  addButton: {
    marginLeft: 20,
  },
  buttonIcon: {
    marginRight: 10,
  },
});

const CoachingSessions = ({
  classes,
  sessions = [],
  coachingSessionId,
  updateCoachingSessionRequest,
  requesting,
}) => {
  const [isNewSessionVisible, openNewSessionModal] = useState(false);
  const [sessionSelected, setSessionSelected] = useState({});
  const [sessionSelectedIndex, setSessionSelectedIndex] = useState(null);

  const onSaveNextSessionDate = sessionParams => {
    if (!sessions.length) {
      updateCoachingSessionRequest({
        coachingSessionId,
        sessions: [sessionParams],
      });
    } else {
      // update current
      let newSessions = sessions.map((el, index) => {
        if (index === sessionSelectedIndex) {
          return { ...el, ...sessionParams };
        }
        return el;
      });

      // create new
      if (sessionSelectedIndex === sessions.length) {
        newSessions = [...sessions, { ...sessionParams }];
      }

      updateCoachingSessionRequest({
        coachingSessionId,
        sessions: newSessions,
      });
    }
  };

  const onCreateNewSession = () => {
    console.log("Create");
    const lastSession = sessions[sessions.length - 1] || {};

    const newSession = {
      ...lastSession,
      googleCalendarEventId: "",
      isPlannedWithGoogleCalendar: false,
      nextDate: moment(lastSession.nextDate).add(1, "week"),
    };

    delete newSession["doneDate"];
    delete newSession["isReminderSent"];
    delete newSession["isDone"];
    delete newSession["_id"];

    setSessionSelectedIndex(sessions.length);
    setSessionSelected(newSession);

    openNewSessionModal(true);
  };

  const onClickSession = index => () => {
    setSessionSelectedIndex(index);
    const nextSession = sessions[index];
    setSessionSelected(nextSession);

    openNewSessionModal(true);
  };

  const onClickRemove = sessionIndex => e => {
    e.preventDefault();
    e.stopPropagation();
    const newSessions = sessions.filter((el, index) => index !== sessionIndex);

    updateCoachingSessionRequest({
      coachingSessionId,
      sessions: newSessions,
    });
  };

  const onValidateCurrentSession = e => {
    e.stopPropagation();
    const currentSessionIndex = sessions.findIndex(el => !el.isDone);
    const newSessions = sessions.map((el, index) => {
      if (index === currentSessionIndex) {
        return { ...el, doneDate: new Date(), isDone: true };
      }
      return el;
    });
    updateCoachingSessionRequest({
      coachingSessionId,
      sessions: newSessions,
    });
  };

  const activeSessionIndex = sessions.findIndex(el => !el.isDone);
  return (
    <>
      <NextSectionCalendarModal
        sessionSelectedNumber={sessionSelectedIndex + 1}
        sessionSelected={sessionSelected}
        sessions={sessions}
        isVisible={isNewSessionVisible}
        onSave={onSaveNextSessionDate}
        onClose={() => openNewSessionModal(false)}
      />
      <Box className={classes.main} intercomTarget={"coaching-sessions"}>
        <BoxHeader>
          <BoxTitle>
            {i18n.t("sessions-planning")}{" "}
            <span className={classes.regular}>{`(${sessions.length})`}</span>
          </BoxTitle>
          <div className={classes.buttons}>
            <FlatButton
              icon={<AddIcon color={"#fff"} className={classes.buttonIcon} />}
              className={classes.addButton}
              title={i18n.t("add-a-session")}
              onClick={onCreateNewSession}
              requesting={requesting}
            />
          </div>
        </BoxHeader>
        <div className={classes.body}>
          {sessions.length <= 0 ? (
            <EmptyText
              className={classes.firstResources}
              firstTextPart={`${i18n.t("plan")} ${i18n.t("first-session")}`}
              onClick={onCreateNewSession}
            />
          ) : (
            <>
              <SessionSummary sessions={sessions} />
              <div className={classes.sessions}>
                {sessions.map((el, index) => (
                  <SessionComponent
                    isLast={index === sessions.length - 1}
                    onClickValidate={onValidateCurrentSession}
                    index={index}
                    key={index}
                    durationInMinutes={el.durationInMinutes}
                    nextDate={el.nextDate}
                    doneDate={el.doneDate}
                    isActive={activeSessionIndex === index}
                    isDone={el.isDone}
                    onClick={onClickSession(index)}
                    onClickRemove={onClickRemove(index)}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = state => {
  return {
    coachingSessionId: getCoachingSessionId(state),
    sessions: getSessions(state),
    requesting: state.coaching.requesting,
  };
};

export default connect(mapStateToProps, {
  updateCoachingSessionRequest,
})(withStyles(styles)(CoachingSessions));
