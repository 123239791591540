const beautifyCode = (code: string) => {
  if (!code) {
    return "";
  }
  if (code.length > 8) {
    return `${code.substr(0, 4)}-${code.substr(4, 4)}-${code.substr(
      8,
    )}`.toUpperCase();
  } else {
    return `${code.substr(0, 4)}-${code.substr(4, 4)}`.toUpperCase();
  }
};

export default beautifyCode;
