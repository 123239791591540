const getNavigatorLanguage = () => {
  let language = navigator.language;
  if (language.includes("fr")) {
    language = "fr";
  } else {
    language = "en";
  }
  return language;
};

export default getNavigatorLanguage;
