import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import Colors from "../../../constants/Colors";
import moment from "moment";

import { connect } from "react-redux";
import {
  changeField,
  modifyManagerSurveyRequest,
  createManagerSurveyRequest,
} from "../../../services/manager/actions";
import { getUserId } from "../../../services/user/selectors";
import i18n from "../../../i18n";
import EvolutionSection from "../CampaignScreen/EvolutionSection";
import { getAppLanguage } from "../../../services/general/selectors";
import PreviewModal from "../../CreateTrack/Library/PreviewModal";
import ActionsTable from "scenes/Campaigns/CampaignScreen/ActionsSide/ActionsTable";
import StatBox from "scenes/Campaigns/CampaignScreen/StatBox";
import LightIcon from "scenes/CreateTrack/LightIcon";
import StarIcon from "scenes/CreateTrack/StarIcon";
import ViewIcon from "scenes/CreateTrack/CreateActionTextStep/ViewIcon";

import {
  getCampaignComments,
  getCampaignSelected,
  getUserCampaigns,
} from "services/campaign/selectors";
import { sortBy } from "lodash";
import selectLanguage from "utils/selectLanguage";
import removeHtmlTags from "utils/removeHtmlTags";

const getDonePercentageAndRatingMedium = steps => {
  const result = steps.reduce(
    (prev, curr) => {
      prev.donePercentage += curr.donePercentage;
      prev.rating += curr.rating;
      return prev;
    },
    { donePercentage: 0, rating: 0 },
  );
  result.donePercentage = (result.donePercentage / steps.length).toFixed(1);
  result.rating = (result.rating / steps.length).toFixed(1);
  return result;
};

export const formatDoneSteps = ({
  doneSteps,
  userCampaigns,
  language,
  campaignSteps = [],
  user,
}) => {
  const j = {};
  const views = {};

  userCampaigns.forEach(el => {
    el.allStepsViews.forEach(step => {
      if (views[step?.step]) {
        views[step?.step] += step?.views;
      } else {
        views[step?.step] = step?.views;
      }
    });
  });

  doneSteps.forEach(function (step) {
    if (j[step?.stepId?._id]) {
      j[step?.stepId?._id].push(step);
    } else {
      j[step?.stepId?._id] = [step];
    }
  });

  campaignSteps.forEach(function (step) {
    if (j[step?._id]) {
      j[step?._id].push({ stepId: step, donePercentage: 0, rating: 5 });
    } else {
      j[step?._id] = [{ stepId: step, donePercentage: 0, rating: 5 }];
    }
  });

  return Object.keys(j).map(key => {
    const steps = j[key];
    const sortedStep = sortBy([...steps], "createdAt").reverse();
    const result = { ...sortedStep[0] };

    const { category, skills, title } = result?.stepId || {};
    result.totalDone = steps?.length;
    const donePercentageAndRatingMedium = getDonePercentageAndRatingMedium(
      steps,
    );
    result.donePercentage = donePercentageAndRatingMedium?.donePercentage;
    result.rating = donePercentageAndRatingMedium?.rating;
    result.category =
      skills && skills.length > 0
        ? selectLanguage({ text: skills?.[0]?.label, language })
        : category;
    result.comments = steps.reduce((prev, curr) => {
      if (curr.comment) {
        prev.push({ comment: curr.comment, userId: user, responses: [] });
      }
      return prev;
    }, []);
    result.totalComments = result?.comments?.length;
    result.views = views?.[key] || 0;
    // for fuse search

    result.title = removeHtmlTags(
      selectLanguage({ text: title, language, noFallBack: true }),
    );

    // Count 0 for action listed but without doneSteps yet

    if (result.donePercentage === "0.0") {
      result.totalDone = 0;
    }

    // add number of views from userCampaings
    return result;
  });
};

const LEFT_ARROW_KEY = 37;
const RIGHT_ARROW_KEY = 39;

const styles = theme => ({
  rightDiv: {},
  actionsDiv: {
    width: "100%",
    marginBottom: 25,
    flexWrap: "wrap",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  topStats: {
    overflow: "auto",
    marginBottom: 30,
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    display: "flex",
  },
});

class UserProfile extends Component {
  state = {
    progress: 0,
    areMoreQuestionsVisible: false,
    isPreviewVisible: false,
    stepPreview: {},
    stepPreviewIndex: 0,
    comments: [],
  };

  componentDidMount() {
    if (this.props.language === "fr") {
      moment.locale("fr");
    } else {
      moment.locale("en");
    }

    document.addEventListener("keydown", this._handleKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this._handleKeyDown, false);
  }

  onNext = () => {
    console.log("NEXT");
    const stepPreviewIndex =
      this.state.stepPreviewIndex + 1 < this.props.userSelectedDoneSteps.length
        ? this.state.stepPreviewIndex + 1
        : 0;
    const step = this.props.userSelectedDoneSteps[stepPreviewIndex];
    this.setState({
      stepPreviewIndex,
      stepPreview: step.stepId,
      comment: [step.comment],
      rating: step.rating,
    });
  };

  onPrevious = () => {
    console.log("PREVIOUS");
    const stepPreviewIndex =
      this.state.stepPreviewIndex - 1 >= 0
        ? this.state.stepPreviewIndex - 1
        : this.props.userSelectedDoneSteps.length - 1;
    const step = this.props.userSelectedDoneSteps[stepPreviewIndex];

    this.setState({
      stepPreviewIndex,
      stepPreview: step.stepId,
      comment: [step.comment],
      rating: step.rating,
    });
  };

  _handleKeyDown = event => {
    switch (event.keyCode) {
      case LEFT_ARROW_KEY:
        this.onPrevious();
        break;
      case RIGHT_ARROW_KEY:
        this.onNext();
        break;
      default:
    }
  };

  getTotalViews = allStepsViews => {
    return allStepsViews.reduce((prev, curr) => {
      return prev + curr.views;
    }, 0);
  };

  getMediumRating = steps => {
    if (steps.length < 1) {
      return 0;
    }
    return (
      steps.reduce((prev, curr) => {
        return prev + parseFloat(curr.rating);
      }, 0) / steps.length
    ).toFixed(1);
  };

  render() {
    const {
      classes,
      userSelected,
      userSelectedDoneSteps = [],
      createdAt,
      allStepsViews,
      selectedSteps,
    } = this.props;
    const { totalDoneActions } = userSelected;

    const totalViews = this.getTotalViews(allStepsViews);

    return (
      <div className={classes.rightDiv}>
        <PreviewModal
          isAddButton={false}
          stepPreviewIndex={this.state.stepPreviewIndex}
          stepPreview={this.state.stepPreview}
          comments={this.state.comments}
          rating={this.state.rating}
          isVisible={this.state.isPreviewVisible}
          onClose={() => this.setState({ isPreviewVisible: false })}
        />
        <div className={classes.topStats}>
          <StatBox
            lightColor={"rgba(238, 134, 13, 0.22)"}
            icon={<LightIcon color="#F9A849" sizePercentage={2} />}
            stat={totalDoneActions}
            title={
              totalDoneActions === 1 ? i18n.t("Action") : i18n.t("Actions")
            }
          />
          <StatBox
            lightColor={"rgba(23, 175, 252, 0.22)"}
            icon={<ViewIcon color={Colors.pureMain} sizePercentage={2} />}
            stat={totalViews}
            title={totalViews === 1 ? i18n.t("View") : i18n.t("Views")}
          />
          <StatBox
            lightColor={Colors.lightOrange}
            icon={<StarIcon color={Colors.orange} sizePercentage={1.3} />}
            stat={this.getMediumRating(userSelectedDoneSteps)}
            title={i18n.t("MediumRating")}
            isLast
          />
        </div>
        <EvolutionSection doneSteps={selectedSteps} createdAt={createdAt} />
        <div className={classes.actionsDiv}>
          <ActionsTable
            doneSteps={userSelectedDoneSteps}
            hasDateRow
            defaultSort="createdAt"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    userSelected,
    userComment,
    managerSurvey,
    requesting,
  } = state.manager;
  const { doneSteps } = state.campaign;
  const campaignSelected = getCampaignSelected(state);
  const userCampaigns = getUserCampaigns(state);
  const { createdAt } = campaignSelected;
  const userSelectedId = userSelected._id;
  const userId = getUserId(state);
  const language = getAppLanguage(state);
  const comments = getCampaignComments(state);
  const userCampaignSelected = userCampaigns.find(
    el => el.userId === userSelectedId,
  ) || { allStepsViews: [] };

  const selectedSteps = doneSteps.filter(
    el => el.userId === userSelected._id && el.stepId,
  );

  const userComments = comments.filter(
    comment => comment?.userId?._id === userSelectedId,
  );

  const selectedStepsWithDate = selectedSteps.map(doneStep => {
    const step = userCampaignSelected?.steps?.find(
      el => el?.step === doneStep?.stepId?._id,
    );

    return {
      ...doneStep,
      createdAt: step?.doneDate,
    };
  });

  const allStepsViews = userCampaignSelected.allStepsViews;

  const userSelectedDoneSteps = formatDoneSteps({
    doneSteps: selectedStepsWithDate,
    userCampaigns: [userCampaignSelected],
    campaignSteps: campaignSelected?.steps || [],
    language,
    user: userSelected,
  }).filter(el => {
    return !!el?.stepId?._id;
  });

  return {
    language,
    createdAt,
    userId,
    userSelected,
    managerSurvey,
    userComment,
    requesting,
    selectedSteps: selectedStepsWithDate,
    userSelectedDoneSteps,
    allStepsViews,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    changeField,
    createManagerSurveyRequest,
    modifyManagerSurveyRequest,
  })(UserProfile),
);
