import React, { useState, useCallback, useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import { openSnackMessage } from "services/general/actions";
import {
  searchUnsplashRequest,
  getRandomUnsplashRequest,
} from "services/upload/actions";
import { getUnsplashResults } from "services/upload/selectors";
import CloseIcon from "scenes/CreateTrack/Skills/CloseIcon";
import i18n from "i18n";
import Colors from "constants/Colors";
import SearchBar from "components/SearchBar";
import PortraitPhoto from "components/PortraitPhoto";
import { Loader } from "components/Loader";

const UNSPLASH_KEY = "H4H5Un-Zb8SvCIPp6V0ig8cH7Z2Cpfjnm_ZqIRglqwc";

const styles = theme => ({
  closeButton: {
    zIndex: 10,
    position: "absolute",
    top: 5,
    right: 5,
  },
  main: {},
  paper: {
    borderRadius: 8,
    minHeight: "80%",
    minWidth: 900,
  },
  dialogTitle: {
    fontSize: 20,
    color: Colors.darkBlue,
    fontFamily: "Montserrat",
    fontWeight: "bold",
  },
  title: {
    color: Colors.darkBlue,
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 16,
  },
  searchBar: {
    marginBottom: 15,
    borderWidth: 1,
    backgroundColor: "#fff",
  },
  inputSearch: {
    border: `solid 1px ${Colors.grey}`,
    backgroundColor: "#fff",
  },
  photos: {
    columnCount: 3,
    columnGap: 0,
    // flexWrap: "wrap",
    // display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: 15,
  },
});

const UnsplashLibraryModal = ({
  classes,
  isVisible,
  onClose,
  searchUnsplashRequest,
  openSnackMessage,
  getRandomUnsplashRequest,
  requesting = false,
  onClickPicture = () => () => null,
  photos = [],
}) => {
  const [search, setSearch] = useState("");

  const delayedQuery = useCallback(
    debounce(search => searchUnsplashRequest({ search }), 500),
    [],
  );

  useEffect(() => {
    // code to run on component mount
    delayedQuery("space");
  }, [delayedQuery]);

  const onChangeSearch = searchText => {
    setSearch(searchText);
    delayedQuery(searchText);
    if (!searchText) {
      delayedQuery("space");
      // getRandomUnsplashRequest();
    }
  };

  const handleClick = ({ url, downloadLink }) => () => {
    openSnackMessage({ snackMessage: i18n.t("image-selected") });
    onClickPicture(url)();

    fetch(downloadLink, {
      method: "get",
      headers: {
        "Accept-Version": "v1",
        Authorization: `Client-ID ${UNSPLASH_KEY}`,
      },
    });
  };

  return (
    <Dialog
      open={isVisible}
      onClose={onClose}
      classes={{ paper: classes.paper }}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
    >
      <IconButton
        className={classes.closeButton}
        onClick={onClose}
        size="large"
      >
        <CloseIcon sizePercentage={2} />
      </IconButton>
      <DialogTitle id="scroll-dialog-title" className={classes.dialogTitle}>
        <Typography className={classes.dialogTitle}>
          {i18n.t("select-an-image")}
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <SearchBar
          onChangeSearch={onChangeSearch}
          value={search}
          className={classes.searchBar}
          inputClassName={classes.inputSearch}
          placeholder={i18n.t("search-bar-unsplash-placeholder")}
        />
        <Typography className={classes.title}>Unsplash</Typography>
        <Typography className={classes.subtitle}>
          {i18n.t("discover-photos")}
        </Typography>
        {requesting && <Loader size={50} />}
        <div className={classes.photos}>
          {photos.map((el, index) => (
            <PortraitPhoto
              key={index}
              user={el.user}
              url={el.urls.small}
              alt={el.description}
              onClick={handleClick({
                url: el.urls.small,
                downloadLink: el.links.download_location,
              })}
            />
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    photos: getUnsplashResults(state),
    requesting: state.upload.requesting,
  };
};

export default connect(mapStateToProps, {
  searchUnsplashRequest,
  getRandomUnsplashRequest,
  openSnackMessage,
})(withStyles(styles)(UnsplashLibraryModal));
