//@flow
import { call, put, takeLatest, all } from "redux-saga/effects";

import {
  MESSAGE_GET_LATEST_CONVERSATIONS_ERROR,
  MESSAGE_GET_LATEST_CONVERSATIONS_SUCCESS,
  MESSAGE_GET_LATEST_CONVERSATIONS_REQUEST,
  GET_CHAT_MESSAGES_REQUESTING,
  GET_CHAT_MESSAGES_SUCCESS,
  GET_CHAT_MESSAGES_ERROR,
  MESSAGE_OPEN_CONVERSATION_SUCCESS,
  MESSAGE_OPEN_CONVERSATION_ERROR,
  MESSAGE_OPEN_CONVERSATION_REQUESTING,
  MESSAGE_GET_CLIENT_NAME_REQUESTING,
  MESSAGE_GET_CLIENT_NAME_SUCCESS,
  MESSAGE_GET_CLIENT_NAME_ERROR,
  MESSAGE_LIKE_REQUESTING,
  MESSAGE_LIKE_SUCCESS,
  MESSAGE_LIKE_ERROR,
} from "./constants";

import { fetchApi } from "../api";

const endPoints = {
  getLatestConversations: "/v1/conversations/latest/",
  getChatMessages: "/v1/conversations/messages", // conversationId / page
  openConversation: "/v1/conversations/open",
  getClientName: "/v1/clients/my-clients/clientName",
  likeMessage: "/v1/conversations/like",
};

const getLatestConversationsApi = userId => {
  return fetchApi(endPoints.getLatestConversations + userId, {}, "get");
};

const getMessageClientNameApi = data => {
  return fetchApi(`${endPoints.getClientName}?userId=${data}`, "get");
};

const getChatMessagesApi = payload => {
  const { conversationId, page } = payload;
  return fetchApi(
    `${endPoints.getChatMessages}/${conversationId}/${page}`,
    {},
    "get",
  );
};

const likeMessageApi = data => {
  return fetchApi(
    `${endPoints.likeMessage}/${data.messageId}`,
    { userId: data.userId },
    "put",
  );
};

const openConversationApi = payload => {
  return fetchApi(endPoints.openConversation, payload, "post");
};

function* getLatestConversationsFlow(action) {
  try {
    const response = yield call(
      getLatestConversationsApi,
      action.payload.userId,
    );
    yield put({
      type: MESSAGE_GET_LATEST_CONVERSATIONS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    console.log(error);
    yield put({ type: MESSAGE_GET_LATEST_CONVERSATIONS_ERROR, payload: error });
  }
}

function* getChatMessagesFlow(action) {
  try {
    const response = yield call(getChatMessagesApi, action.payload);
    yield put({ type: GET_CHAT_MESSAGES_SUCCESS, payload: response });
  } catch (error) {
    console.log(error);
    yield put({ type: GET_CHAT_MESSAGES_ERROR, payload: error });
  }
}

function* openConversationFlow(action) {
  try {
    const response = yield call(openConversationApi, action.payload);
    yield put({ type: MESSAGE_OPEN_CONVERSATION_SUCCESS, payload: response });
  } catch (error) {
    console.log(error);
    yield put({ type: MESSAGE_OPEN_CONVERSATION_ERROR, payload: error });
  }
}

function* getMessageClientNameFlow(action) {
  try {
    const result = yield call(getMessageClientNameApi, action.payload.userId);
    yield put({
      type: MESSAGE_GET_CLIENT_NAME_SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: MESSAGE_GET_CLIENT_NAME_ERROR,
      payload: error,
    });
  }
}

function* likingMessageFlow(action) {
  try {
    const { messageId, userId } = action?.payload || {};
    const response = yield call(likeMessageApi, { messageId, userId });
    yield put({
      type: MESSAGE_LIKE_SUCCESS,
      payload: response,
    });
  } catch (error) {
    console.log(error);
    yield put({ type: MESSAGE_LIKE_ERROR, error: error.message });
  }
}

function* trackWatcher() {
  yield all([
    takeLatest(
      MESSAGE_GET_LATEST_CONVERSATIONS_REQUEST,
      getLatestConversationsFlow,
    ),
    takeLatest(GET_CHAT_MESSAGES_REQUESTING, getChatMessagesFlow),
    takeLatest(MESSAGE_OPEN_CONVERSATION_REQUESTING, openConversationFlow),
    takeLatest(MESSAGE_GET_CLIENT_NAME_REQUESTING, getMessageClientNameFlow),
    takeLatest(MESSAGE_LIKE_REQUESTING, likingMessageFlow),
  ]);
}

export default trackWatcher;
