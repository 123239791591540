export const GET_COACHING_SESSION_REQUESTING =
  "coaching/GET_COACHING_SESSION_REQUESTING";
export const GET_COACHING_SESSION_SUCCESS =
  "coaching/GET_COACHING_SESSION_SUCCESS";
export const GET_COACHING_SESSION_ERROR = "coaching/GET_COACHING_SESSION_ERROR";

export const UPDATE_COACHING_SESSION_REQUESTING =
  "coaching/UPDATE_COACHING_SESSION_REQUESTING";
export const UPDATE_COACHING_SESSION_SUCCESS =
  "coaching/UPDATE_COACHING_SESSION_SUCCESS";
export const UPDATE_COACHING_SESSION_ERROR =
  "coaching/UPDATE_COACHING_SESSION_ERROR";

export const CREATE_COACHING_SESSION_REQUESTING =
  "coaching/CREATE_COACHING_SESSION_REQUESTING";
export const CREATE_COACHING_SESSION_SUCCESS =
  "coaching/CREATE_COACHING_SESSION_SUCCESS";
export const CREATE_COACHING_SESSION_ERROR =
  "coaching/CREATE_COACHING_SESSION_ERROR";

export const GET_COACHING_NOTES_REQUESTING =
  "coaching/GET_COACHING_NOTES_REQUESTING";
export const GET_COACHING_NOTES_SUCCESS = "coaching/GET_COACHING_NOTES_SUCCESS";
export const GET_COACHING_NOTES_ERROR = "coaching/GET_COACHING_NOTES_ERROR";

export const CREATE_COACHING_NOTE_REQUESTING =
  "coaching/CREATE_COACHING_NOTE_REQUESTING";
export const CREATE_COACHING_NOTE_SUCCESS =
  "coaching/CREATE_COACHING_NOTE_SUCCESS";
export const CREATE_COACHING_NOTE_ERROR = "coaching/CREATE_COACHING_NOTE_ERROR";

export const UPDATE_COACHING_NOTE_REQUESTING =
  "coaching/UPDATE_COACHING_NOTE_REQUESTING";
export const UPDATE_COACHING_NOTE_SUCCESS =
  "coaching/UPDATE_COACHING_NOTE_SUCCESS";
export const UPDATE_COACHING_NOTE_ERROR = "coaching/UPDATE_COACHING_NOTE_ERROR";

export const DELETE_COACHING_NOTE_REQUESTING =
  "coaching/DELETE_COACHING_NOTE_REQUESTING";
export const DELETE_COACHING_NOTE_SUCCESS =
  "coaching/DELETE_COACHING_NOTE_SUCCESS";
export const DELETE_COACHING_NOTE_ERROR = "coaching/DELETE_COACHING_NOTE_ERROR";

export const CLEAR_COACHING_SESSION = "coaching/CLEAR_COACHING_SESSION";

export const UPDATE_COACHING_SESSION = "coaching/UPDATE_COACHING_SESSION";

export const REMOVE_RESOURCE_REQUESTING = "coaching/REMOVE_COACHING_SESSION";

export const SELECT_COACHING_NOTE = "coaching/SELECT_COACHING_NOTE";

export const END_COACHING_REQUESTING = "coaching/END_COACHING_REQUESTING";
export const END_COACHING_SUCCESS = "coaching/END_COACHING_SUCCESS";
export const END_COACHING_ERROR = "coaching/END_COACHING_ERROR";

export const GET_COACHING_FILES_REQUESTING =
  "coaching/GET_COACHING_FILES_REQUESTING";
export const GET_COACHING_FILES_SUCCESS = "coaching/GET_COACHING_FILES_SUCCESS";
export const GET_COACHING_FILES_ERROR = "coaching/GET_COACHING_FILES_ERROR";

export const CREATE_COACHING_FILE_REQUESTING =
  "coaching/CREATE_COACHING_FILE_REQUESTING";
export const CREATE_COACHING_FILE_SUCCESS =
  "coaching/CREATE_COACHING_FILE_SUCCESS";
export const CREATE_COACHING_FILE_ERROR = "coaching/CREATE_COACHING_FILE_ERROR";

export const UPDATE_COACHING_FILE_REQUESTING =
  "coaching/UPDATE_COACHING_FILE_REQUESTING";
export const UPDATE_COACHING_FILE_SUCCESS =
  "coaching/UPDATE_COACHING_FILE_SUCCESS";
export const UPDATE_COACHING_FILE_ERROR = "coaching/UPDATE_COACHING_FILE_ERROR";

export const DELETE_COACHING_FILE_REQUESTING =
  "coaching/DELETE_COACHING_FILE_REQUESTING";
export const DELETE_COACHING_FILE_SUCCESS =
  "coaching/DELETE_COACHING_FILE_SUCCESS";
export const DELETE_COACHING_FILE_ERROR = "coaching/DELETE_COACHING_FILE_ERROR";
