import React from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography } from "@mui/material";
import i18n from "../../../i18n";
import Colors from "../../../constants/Colors";
import FrenchFlagIcon from "scenes/CreateTrack/CreateActionTextStep/Flags/FrenchFlag";
import EnglishFlag from "scenes/CreateTrack/CreateActionTextStep/Flags/EnglishFlag";

export const LANGUAGES_AVAILABLE = [
  { value: "fr", flag: <FrenchFlagIcon sizePercentage={0.6} /> },
  { value: "en", flag: <EnglishFlag sizePercentage={0.6} /> },
];

const styles = theme => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  box: {
    border: `solid 1px ${Colors.lightGrey}`,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 15px",
    borderRadius: 100,
    marginRight: 10,
    marginTop: 10,
    transition: "all 0.1s ease",
    "&:hover": {
      opacity: 0.8,
      backgroundColor: "#F2F2F2",
    },
  },
  boxSelected: {
    borderColor: "#fff",
    backgroundColor: "#F2F2F2",
  },
  text: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 16,
    color: Colors.text,
    transition: "all 0.1s ease",
  },
  textSelected: {},
  flag: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 8,
  },
});

const Language = ({ classes, className = "", language, onSelect }) => {
  return (
    <div className={`${classes.main} ${className}`}>
      {LANGUAGES_AVAILABLE.map((el, index) => {
        const isSelected = language === el.value;
        return (
          <div
            key={index}
            onClick={onSelect(el.value)}
            className={`${classes.box} ${isSelected && classes.boxSelected}`}
          >
            <Typography
              className={`${classes.text} ${
                isSelected && classes.textSelected
              }`}
            >
              <span className={classes.flag}>{el.flag}</span>
              {i18n.t(el.value, { lng: language })}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};

export default withStyles(styles)(React.memo(Language));
