import React from "react";

const BackIcon = ({
  sizePercentage = 1,
  className = "",
  color = "#2E2E2E",
}) => (
  <svg
    className={className}
    width={sizePercentage * 12}
    height={sizePercentage * 20}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 12 20"
    version="1.1"
    fill={"transparent"}
  >
    <path
      d="M10.1123 18.5214L1.8373 10.2464L10.1123 1.97139"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BackIcon;
