import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { getClientRequest } from "../../services/client/actions";
import { getDashboardManagerRequest } from "../../services/manager/actions";
import withStyles from '@mui/styles/withStyles';

import Paper from "@mui/material/Paper";

import FirstSection from "./FirstSection";
import TotalDoneActionsSection from "./TotalDoneActionsSection";
import ProgressionSection from "./ProgressionSection";
import KeyDataSection from "./KeyDataSection";
import RepartitionSection from "./RepartitionSection";
import RankingSection from "./RankingSection";
import MostDoneActionsSection from "./MostDoneActionsSection";
import FeedbackSection from "./FeedbackSection";
import SectionSeparator from "./SectionSeparator";
import KeyMetricsFirstSection from "./KeyMetricsFirstSection";
import KeyMetricsSecondSection from "./KeyMetricsSecondSection";
import { getUserId, getSelectedClientId } from "../../services/user/selectors";

const styles = theme => ({
  main: {
    width: "100%",
    // flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 100,
  },
  title: {
    marginBottom: 15,
  },
  inputForm: {
    marginTop: 15,
    marginBottom: 15,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  inputLabel: {
    marginRight: 10,
    marginLeft: 10,
  },

  table: {
    minWidth: 700,
  },
  root: {
    width: "90%",
    // width: "auto",
    marginTop: theme.spacing.unit * 2,
    backgroundColor: "#FAFAFA",
    // backgroundColor: "#fff",
    overflowX: "auto",
  },
  button: {
    marginLeft: 15,
  },
  firstSection: {
    backgroundColor: "#fff",
    minHeight: 160,
  },
  secondSection: {
    backgroundColor: "#FAFAFA",
    minHeight: 500,
  },
  separatorText: {
    color: "#788593",
    marginRight: 20,
  },
  middleSeparator: {
    marginLeft: 20,
  },
  separation: {
    marginTop: 35,
    paddingTop: 15,
    paddingLeft: "5%",
    paddingRight: "5%",
    // backgroundColor: "#FAFAFA",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  greyBackground: {
    backgroundColor: "#FAFAFA",
    marginTop: 0,
  },
  whiteBackground: {
    marginTop: 0,
    paddingTop: 50,
  },
  separator: {
    flex: 1,
    height: 1,
    backgroundColor: "#E8F3FD",
  },
  dashboardManager: {
    width: "auto",
  },
});

class Recap extends PureComponent {
  state = {
    name: "",
    owner: "",
    domain: "",
  };

  componentDidMount = () => {
    if (this.props.isFakeData) {
    } else if (this.props.isDashboardManager) {
      this.props.getDashboardManagerRequest({ userId: this.props.userId });
    } else {
      this.props.getClientRequest({ clientId: this.props.clientId });
    }
    // if (!this.props.hasAccess) {
    //   this.props.history.push("/team");
    // }
  };

  render() {
    const { classes, isDashboardManager } = this.props;
    return (
      <div className={classes.main}>
        <Paper className={classes.root}>
          <FirstSection isDashboardManager={isDashboardManager} />
          <TotalDoneActionsSection />
          <KeyMetricsFirstSection />
          <KeyMetricsSecondSection />

          <SectionSeparator isGrey isMiddle text="RÉSULTATS CLÉS" />
          <KeyDataSection />

          <SectionSeparator
            isGrey
            isMiddle
            text="PROGRESSION DU NOMBRE D'ACTIONS MISES EN PLACE"
          />
          <ProgressionSection />

          <SectionSeparator text="RAPPORT DÉTAILLÉ" />
          <RepartitionSection />

          <SectionSeparator text="ÉVOLUTION DU BIEN-ÊTRE" />

          {!isDashboardManager && (
            <SectionSeparator text="CLASSEMENT" isGrey isMiddle />
          )}
          {!isDashboardManager && <RankingSection />}

          <SectionSeparator text="ACTIONS LES PLUS DÉPLOYÉES" />
          <MostDoneActionsSection />

          <SectionSeparator text="CE QU'ILS EN PENSENT !" isGrey isMiddle />
          <FeedbackSection />
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { name } = state.client;
  const userId = getUserId(state);
  const clientId = getSelectedClientId(state);
  const { role } = state.auth;
  return {
    token: state.auth.token,
    userId,
    isFakeData: false,
    name,
    client: state.client,
    hasAccess: role !== "user",
    clientId,
  };
};

export default connect(mapStateToProps, {
  getClientRequest,
  getDashboardManagerRequest,
})(withStyles(styles)(Recap));
