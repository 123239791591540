import React from "react";

const AddIcon = ({ sizePercentage, color }) => (
  <svg
    width={sizePercentage * 32}
    height={sizePercentage * 32}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 32 32"
    version="1.1"
  >
    <circle cx="15.3787" cy="15.9119" r="15.3494" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3789 10.9121C16.3789 10.6469 16.2735 10.3925 16.086 10.205C15.8985 10.0175 15.6441 9.91211 15.3789 9.91211C15.1137 9.91211 14.8593 10.0175 14.6718 10.205C14.4843 10.3925 14.3789 10.6469 14.3789 10.9121V14.9121H10.3789C10.1137 14.9121 9.85934 15.0175 9.6718 15.205C9.48426 15.3925 9.37891 15.6469 9.37891 15.9121C9.37891 16.1773 9.48426 16.4317 9.6718 16.6192C9.85934 16.8068 10.1137 16.9121 10.3789 16.9121H14.3789V20.9121C14.3789 21.1773 14.4843 21.4317 14.6718 21.6192C14.8593 21.8068 15.1137 21.9121 15.3789 21.9121C15.6441 21.9121 15.8985 21.8068 16.086 21.6192C16.2735 21.4317 16.3789 21.1773 16.3789 20.9121V16.9121H20.3789C20.6441 16.9121 20.8985 16.8068 21.086 16.6192C21.2736 16.4317 21.3789 16.1773 21.3789 15.9121C21.3789 15.6469 21.2736 15.3925 21.086 15.205C20.8985 15.0175 20.6441 14.9121 20.3789 14.9121H16.3789V10.9121Z"
      fill="#7D7D7D"
    />
  </svg>
);

AddIcon.defaultProps = {
  sizePercentage: 1,
  color: "#F2F2F2",
};

export default AddIcon;
