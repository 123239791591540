import React, { useEffect, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import { connect, useSelector } from "react-redux";
import { getAppLanguage } from "services/general/selectors";
import { openSnackMessage } from "services/general/actions";
import Modal from "components/Modal";
import i18n from "i18n";
import FlatButton from "components/FlatButton";

import "react-nice-dates/build/style.css";
import "./calendar.css";
import {
  getGoogleTokens,
  getUserId,
  getUserFirstName,
} from "services/user/selectors";
import {
  getUserSelectedEmail,
  getUserSelectedFirstName,
} from "services/manager/selectors";
import MyCalendarLink from "scenes/Campaigns/SeeUserModal/Coaching/Sessions/MyCalendarLink";
import AddAgendaSessionStep from "scenes/Campaigns/SeeUserModal/Coaching/Sessions/NextSessionCalendarModal/AddAgendaSessionStep";
import InviteCoacheeAgendaStep from "scenes/Campaigns/SeeUserModal/Coaching/Sessions/NextSessionCalendarModal/InviteCoacheeAgendaStep";
import { useSendCalendarInvitation } from "scenes/Campaigns/SeeUserModal/Coaching/Sessions/hooks";

import AGENDA_EXPLANATION_PICTURE from "resources/agendaExplanation.svg";
import AGENDA_REMINDER_PICTURE from "resources/agendaReminder.svg";
const DEFAULT_DURATION_IN_MINUTES = 45;

const styles = theme => ({
  modal: {
    maxHeight: "90vh",
    width: 850,
  },
  main: {
    paddingLeft: 20,
    paddingRight: 40,
    paddingTop: 8,
    paddingBottom: 8,
    height: "100%",
  },
  titleClassName: {
    marginTop: 20,
    marginLeft: 20,
  },
  saveButton: {
    height: 50,
    width: "100%",
    marginBottom: 15,
    marginTop: 15,
    alignSelf: "flex-end",
  },
});

const NextSectionCalendarModal = ({
  classes,
  language,
  isVisible,
  onClose,
  onSave,
  initialDate,
  sessionSelected = {},
  initialSummary,
  userId,
  googleTokens,
  openSnackMessage,
  sessions = [],
  isCoachingAccount,
  sessionSelectedNumber = 0,
}) => {
  const [date, setDate] = useState(initialDate);
  const [modalStep, setModalStep] = useState(1);

  const userSelectedFirstName = useSelector(getUserSelectedFirstName);
  const userFirstName = useSelector(getUserFirstName);
  const selectedUserEmail = useSelector(getUserSelectedEmail);

  const [summary, setSummary] = useState(
    `Coaching ${userSelectedFirstName} & ${userFirstName}`,
  );
  const [duration, setDuration] = useState(DEFAULT_DURATION_IN_MINUTES);
  const [
    isPlannedWithGoogleCalendar,
    setIsPlannedWithGoogleCalendar,
  ] = useState(false);
  const [email, setEmail] = useState(selectedUserEmail);
  const [message, setMessage] = useState("");
  const [googleCalendarEventId, setGoogleCalendarEventId] = useState("");

  const [
    invitationRequesting,
    sendUpdateCalendarInvitationRequest,
  ] = useSendCalendarInvitation();

  useEffect(() => {
    if (sessionSelected && modalStep === 1) {
      const {
        nextDate,
        durationInMinutes,
        summary,
        message,
        isPlannedWithGoogleCalendar,
        googleCalendarEventId,
        email,
      } = sessionSelected;
      setDate(nextDate || new Date());
      setDuration(durationInMinutes || DEFAULT_DURATION_IN_MINUTES);
      setSummary(
        summary || `Coaching ${userSelectedFirstName} & ${userFirstName}`,
      );
      setMessage(message);
      setIsPlannedWithGoogleCalendar(isPlannedWithGoogleCalendar);
      setGoogleCalendarEventId(googleCalendarEventId);
      setEmail(email || selectedUserEmail);
    }
  }, [
    selectedUserEmail,
    sessions,
    sessionSelected,
    sessionSelectedNumber,
    userSelectedFirstName,
    userFirstName,
    modalStep,
  ]);

  const onSaveDate = async () => {
    onSave({
      nextDate: date,
      durationInMinutes: duration,
      isPlannedWithGoogleCalendar,
      googleCalendarEventId,
      summary,
      message,
      email,
    });
    if (modalStep === 2) {
      if (googleCalendarEventId && isPlannedWithGoogleCalendar) {
        await sendUpdateCalendarInvitationRequest({
          date,
          duration,
          isPlannedWithGoogleCalendar,
          googleCalendarEventId,
          summary,
          message,
          email,
        });
      }
      onClose();
    } else {
      setModalStep(2);
    }
  };

  const handlePressEnter = e => {
    if (e.key === "Escape" && isVisible) {
      onClose();
    }
  };

  const onChangeSummary = e => {
    const value = e.target.value;
    setSummary(value);
  };

  const handleDurationChange = duration => {
    setDuration(duration);
  };

  const onChangeEmail = e => {
    const value = e.target.value;
    setEmail(value);
  };

  const onChangeMessage = e => {
    const value = e.target.value;
    setMessage(value);
  };

  const onChangeGoogleCalendarEventId = googleCalendarEventId => {
    setGoogleCalendarEventId(googleCalendarEventId);
  };

  const onChangeIsPlannedWithGoogleCalendar = value => () => {
    setIsPlannedWithGoogleCalendar(value);
  };

  const onClickUpdateDate = () => {
    setModalStep(1);
  };

  const handleDateChange = date => {
    try {
      setDate(date);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (isVisible) {
      setModalStep(1);
    }
  }, [isVisible]);

  return (
    <>
      <Modal
        className={classes.modal}
        hasSideExplanation
        sideExplanation={{
          picture:
            modalStep === 1
              ? AGENDA_EXPLANATION_PICTURE
              : AGENDA_REMINDER_PICTURE,
          title: `${i18n.t("calendar-explanation-title")} `,
          explanation: i18n.t(
            modalStep === 1
              ? "calendar-explanation-text"
              : "calendar-reminder-explanation-text",
            {
              interpolation: { escapeValue: false },
            },
          ),
          bottomElement: <MyCalendarLink />,
        }}
        titleClassName={classes.titleClassName}
        onKeyDown={handlePressEnter}
        isVisible={isVisible}
        onClose={onClose}
        title={
          modalStep === 1
            ? `${i18n.t("choose-next-section-date")}`
            : `${i18n.t("reminder-and-invitation")}`
        }
      >
        <div className={classes.main}>
          {modalStep === 1 && (
            <AddAgendaSessionStep
              duration={duration}
              date={date}
              onDateChange={handleDateChange}
              handleDurationChange={handleDurationChange}
            />
          )}
          {modalStep === 2 && (
            <InviteCoacheeAgendaStep
              duration={duration}
              date={date}
              email={email}
              message={message}
              isPlannedWithGoogleCalendar={isPlannedWithGoogleCalendar}
              googleCalendarEventId={googleCalendarEventId}
              onClickUpdateDate={onClickUpdateDate}
              onChangeGoogleCalendarEventId={onChangeGoogleCalendarEventId}
              onChangeMessage={onChangeMessage}
              onChangeSummary={onChangeSummary}
              onChangeEmail={onChangeEmail}
              onChangeIsPlannedWithGoogleCalendar={
                onChangeIsPlannedWithGoogleCalendar
              }
              summary={summary}
            />
          )}
          <FlatButton
            requesting={invitationRequesting}
            className={classes.saveButton}
            title={i18n.t("save")}
            onClick={onSaveDate}
          />
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = state => {
  return {
    language: getAppLanguage(state),
    userId: getUserId(state),
    googleTokens: getGoogleTokens(state),
  };
};

export default connect(mapStateToProps, {
  openSnackMessage,
})(withStyles(styles)(NextSectionCalendarModal));
