import { call, put, takeLatest, all, select } from "redux-saga/effects";
import { push } from "connected-react-router";

import {
  SIGNUP_CREATE_USER_REQUESTING,
  SIGNUP_CREATE_USER_ERROR,
  SIGNUP_CREATE_USER_SUCCESS,
  SIGNUP_CREATE_CLIENT_ERROR,
  SIGNUP_CREATE_CLIENT_REQUESTING,
  SIGNUP_CREATE_CLIENT_SUCCESS,
  SIGNUP_SEND_VERIFICATION_EMAIL_REQUESTING,
  SIGNUP_SEND_VERIFICATION_EMAIL_SUCCESS,
  SIGNUP_SEND_VERIFICATION_EMAIL_ERROR,
  SIGNUP_CONFIRM_EMAIL_ERROR,
  SIGNUP_CONFIRM_EMAIL_REQUESTING,
  SIGNUP_CONFIRM_EMAIL_SUCCESS,
  INVITATION_CREATE_USER_REQUESTING,
  INVITATION_CREATE_USER_ERROR,
  INVITATION_CREATE_USER_SUCCESS,
  SIGNUP_ONBOARDING_END_REQUESTING,
  SIGNUP_ONBOARDING_END_ERROR,
  SIGNUP_ONBOARDING_END_SUCCESS,
} from "./constants";

import { fetchApi } from "../api";
import { setToken } from "../token/actions";
import { setUser, modifyUser } from "../user/actions";
import socket from "../socket";
import { getIsCoachingAccount } from "services/client/selectors";

const endPoints = {
  createClient: "/v1/clients/create",
  createAdminUser: "/v1/auth/admin/register",
  onboardingEnd: "/v1/users/onboarding-end",
  createUser: "/v1/auth/register",
  confirmEmail: "/v1/auth/email/confirmation",
  sendVerificationEmail: "/v1/auth/email/resend",
};

const onboardingEndApi = data => {
  return fetchApi(endPoints.onboardingEnd, data, "post");
};

const confirmEmailApi = data => {
  return fetchApi(endPoints.confirmEmail, data, "post");
};

const createAdminUserApi = data => {
  return fetchApi(endPoints.createAdminUser, data, "post");
};

const createInvitedUserApi = data => {
  return fetchApi(endPoints.createUser, data, "post");
};

const createClientApi = data => {
  return fetchApi(endPoints.createClient, data, "post");
};

const sendVerificationEmailApi = data => {
  return fetchApi(endPoints.sendVerificationEmail, data, "post");
};

function* signupOnboardingEndFlow(action) {
  try {
    // format JobFunction

    const response = yield call(onboardingEndApi, action.payload);
    yield put(modifyUser(response));

    socket.emit("admin-init", response._id);

    yield put({ type: SIGNUP_ONBOARDING_END_SUCCESS });
    yield put(push("/campaigns"));

    const isCoachingAccount = yield select(getIsCoachingAccount);
    if (isCoachingAccount) {
      if (window.Intercom) {
        window.Intercom("startTour", 197345);
      }
    }
  } catch (error) {
    console.log(error);
    yield put({ type: SIGNUP_ONBOARDING_END_ERROR, error: error.message });
  }
}

function* createInvitedUserFlow(action) {
  try {
    const response = yield call(createInvitedUserApi, action.payload);
    const { token, user } = response;
    yield put({ type: INVITATION_CREATE_USER_SUCCESS, payload: user });
    yield put(setToken(token));
    yield put(setUser(user));
  } catch (error) {
    console.log(error);
    yield put({
      type: INVITATION_CREATE_USER_ERROR,
      payload: error.error ? error.error.message : error.message,
    });
  }
}

function* createAdminUserFlow(action) {
  try {
    const response = yield call(createAdminUserApi, action.payload);
    const { token, user } = response;
    yield put({ type: SIGNUP_CREATE_USER_SUCCESS, payload: user });
    yield put(setToken(token));
    yield put(setUser(user));
  } catch (error) {
    yield put({
      type: SIGNUP_CREATE_USER_ERROR,
      payload: error.message,
    });
  }
}

function* createClientFlow(action) {
  try {
    const response = yield call(createClientApi, action.payload);
    yield put({ type: SIGNUP_CREATE_CLIENT_SUCCESS, payload: response });
    // yield put(
    //   push(`https://dashboard.microdoing.com/campaigns?product_tour_id=77919`)
    // );
    const state = yield select(state => state);

    const { organization, companySize } = state.signup;
    const user = state.user;
    yield window.Intercom("boot", {
      app_id: "f3rbos9o",
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phoneNumber,
      companies: [
        {
          id: user.client,
          size: companySize,
          name: organization,
        },
      ],
      name: `${user.firstName} ${user.lastName}`, // Full name
      email: `${user.email}`, // Email address
      created_at: `${user.createdAt}`, // Signup date as a Unix timestamp
    });
  } catch (error) {
    yield put({
      type: SIGNUP_CREATE_CLIENT_ERROR,
      payload: error.message,
    });
  }
}

function* sendVerificationEmailFlow(action) {
  try {
    const response = yield call(sendVerificationEmailApi, action.payload);
    yield put({
      type: SIGNUP_SEND_VERIFICATION_EMAIL_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: SIGNUP_SEND_VERIFICATION_EMAIL_ERROR,
      payload: error.message,
    });
  }
}

function* confirmEmailFlow(action) {
  try {
    yield call(confirmEmailApi, action.payload);
    yield put({
      type: SIGNUP_CONFIRM_EMAIL_SUCCESS,
    });

    const state = yield select(state => state);

    const { organization, companySize } = state.signup;
    const userId = state.user._id;
    yield put({
      type: SIGNUP_CREATE_CLIENT_REQUESTING,
      payload: { userId, organization, companySize },
    });
  } catch (error) {
    yield put({ type: SIGNUP_CONFIRM_EMAIL_ERROR, payload: error.message });
  }
}

function* Saga() {
  yield all([
    takeLatest(SIGNUP_CREATE_CLIENT_REQUESTING, createClientFlow),
    takeLatest(SIGNUP_CREATE_USER_REQUESTING, createAdminUserFlow),
    takeLatest(
      SIGNUP_SEND_VERIFICATION_EMAIL_REQUESTING,
      sendVerificationEmailFlow,
    ),
    takeLatest(SIGNUP_CONFIRM_EMAIL_REQUESTING, confirmEmailFlow),
    takeLatest(INVITATION_CREATE_USER_REQUESTING, createInvitedUserFlow),
    takeLatest(SIGNUP_ONBOARDING_END_REQUESTING, signupOnboardingEndFlow),
  ]);
}

export default Saga;
