import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import i18n from "i18n";
import ReviewFile from "../ReviewFile";
import { ReviewFileType } from "services/campaign/types";
import Colors from "../../../../../constants/Colors";
import { MainTitle } from "components/Titles/MainTitle";
import useReviewStyle from "../review.css";
import { CommentProps } from "../types";
import ReviewComment from "../ReviewComment";
import { useSelector } from "react-redux";
import { getUserId } from "services/user/selectors";
import ActionRating from "./ActionRating";

const useStyles = makeStyles(theme => ({
  main: {
    boxSizing: "border-box",
    flex: 1.2,
    paddingRight: 40,
    paddingLeft: 40,
    paddingTop: 30,
    paddingBottom: 70,
    borderRightWidth: 1,
    borderRightColor: Colors.lightGrey,
    borderRightStyle: "solid",
    height: "100%",
    overflowY: "scroll",
    position: "relative",
  },
  filesDiv: {
    columnCount: 1,
    MozColumnCount: 1,
    WebkitColumnCount: 1,
    "@media (min-width: 1400px)": {
      breakInside: "avoid-column",
      columnCount: 2,
      columnGap: 28,
      MozColumnCount: 2,
      WebkitColumnCount: 2,
    },
  },
  commentContainer: {
    overflow: "hidden",
  },
}));

interface Props {
  userValidationComment?: string;
  files: ReviewFileType[];
  date: string;
  rating: number;
  donePercentage: number;
  comments: CommentProps[];
  userCorrectedPicture: string;
  userCorrectedName: string;
  userCorrectedId: string;
}

const ReviewInfoAction = ({
  userValidationComment = "",
  date,
  files = [],
  rating,
  donePercentage,
  comments = [],
  userCorrectedPicture = "",
  userCorrectedName = "",
  userCorrectedId = "",
}: Props) => {
  const classes = useStyles();
  const classesReviewStyle = useReviewStyle();
  const userId = useSelector(getUserId);

  const user = {
    avatar: userCorrectedPicture,
    name: userCorrectedName,
    date: date,
  };

  return (
    <div className={`${classes.main}`}>
      {(!!userValidationComment ||
        !!(files?.length > 0) ||
        comments?.length > 0) && (
        <>
          {(!!userValidationComment || comments.length > 0) && (
            <div
              className={`${classes.commentContainer} ${classesReviewStyle.reviewDivSeparator}`}
            >
              <MainTitle
                size="medium"
                className={classesReviewStyle.elementSeparator}
                text={i18n.t("user-comment")}
              />
              {!!userValidationComment && (
                <ReviewComment
                  text={userValidationComment}
                  user={user}
                  createdAt={date?.toString()}
                  isComment
                />
              )}
              {comments.map(comment => (
                <ReviewComment
                  {...comment}
                  key={comment._id}
                  isMine={comment.user._id === userId}
                  isComment
                />
              ))}
            </div>
          )}
          {!!(files?.length > 0) && (
            <div className={classesReviewStyle.reviewDivSeparator}>
              <MainTitle
                className={`${classesReviewStyle.elementSeparator}`}
                size="medium"
                text={i18n.t("files-title")}
              />
              {
                <div className={`${classes.filesDiv}`}>
                  {files.map((file, index) => (
                    <ReviewFile key={index} {...file} fileNumber={index + 1} />
                  ))}
                </div>
              }
            </div>
          )}
        </>
      )}
      <div className={`${classesReviewStyle.reviewDivSeparator}`}>
        <MainTitle
          size="medium"
          className={classesReviewStyle.elementSeparator}
          text={i18n.t("note-and-success-rate")}
        />
        <ActionRating rating={rating} donePercentage={donePercentage} />
      </div>
    </div>
  );
};

export default ReviewInfoAction;
