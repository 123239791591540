import i18n from "i18n";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchApi } from "services/api";
import { openSnackMessage } from "services/general/actions";
import { getUserId } from "services/user/selectors";

export const useConnectGoogleCalendar = () => {
  const [requesting, setRequesting] = useState(false);
  const [error, setError] = useState({ message: "" });
  const userId = useSelector(getUserId);
  const dispatch = useDispatch();

  const sendRequest = async () => {
    try {
      setRequesting(true);
      setError({ message: "" });
      const response = await fetchApi(
        "/v1/auth/google/oauth",
        {
          userId,
        },
        "post",
      );
      const { url } = response;
      if (url) {
        window.open(url, "_blank");
        dispatch(
          openSnackMessage({
            snackMessage: i18n.t("google-calendar-invitation-setup"),
          }),
        );
      }
    } catch (error) {
      setError(error);
    } finally {
      setRequesting(false);
    }
  };

  return [requesting, sendRequest, error] as const;
};

interface InviteGoogleCalendarRequestParams {
  email: string;
  date: Date;
  duration: number;
  summary: string;
  message: string;
}

interface UpdateGoogleCalendarRequestParams {
  googleCalendarEventId: string;
  email: string;
  date: Date;
  duration: number;
  summary: string;
  message: string;
}

interface DeleteGoogleCalendarRequestParams {
  googleCalendarEventId: string;
}

export function useDeleteGoogleCalendarInvitation() {
  const [requesting, setRequesting] = useState(false);
  const [error, setError] = useState({ message: "" });
  const userId = useSelector(getUserId);
  const dispatch = useDispatch();

  const sendDeleteRequest = async ({
    googleCalendarEventId,
  }: DeleteGoogleCalendarRequestParams) => {
    try {
      setRequesting(true);
      setError({ message: "" });
      const response = await fetchApi(
        "/v1/coaching/google-calendar/delete",
        {
          googleCalendarEventId,
          userId,
        },
        "delete",
      );
      dispatch(
        openSnackMessage({
          snackMessage: i18n.t("google-calendar-invitation-update"),
        }),
      );
      return response;
    } catch (e) {
      setError(e);
      console.log(e);
    } finally {
      setRequesting(false);
    }
  };

  return [requesting, sendDeleteRequest, error, setError] as const;
}

export function useSendCalendarInvitation() {
  const [requesting, setRequesting] = useState(false);
  const [error, setError] = useState({ message: "" });
  const userId = useSelector(getUserId);
  const dispatch = useDispatch();

  const sendUpdateRequest = async ({
    email,
    date,
    duration,
    summary,
    message,
    googleCalendarEventId,
  }: UpdateGoogleCalendarRequestParams) => {
    try {
      setRequesting(true);
      setError({ message: "" });
      const response = await fetchApi(
        "/v1/coaching/google-calendar/update",
        {
          googleCalendarEventId,
          userId,
          email,
          date,
          duration,
          summary,
          message,
        },
        "patch",
      );
      dispatch(
        openSnackMessage({
          snackMessage: i18n.t("google-calendar-invitation-update"),
        }),
      );
      return response;
    } catch (e) {
      setError(e);
      console.log(e);
      throw e;
    } finally {
      setRequesting(false);
    }
  };

  const sendCreateRequest = async ({
    email,
    date,
    duration,
    summary,
    message,
  }: InviteGoogleCalendarRequestParams) => {
    try {
      setRequesting(true);
      setError({ message: "" });
      const response = await fetchApi(
        "/v1/coaching/google-calendar/add",
        {
          userId,
          email,
          date,
          duration,
          summary,
          message,
        },
        "post",
      );
      dispatch(
        openSnackMessage({
          snackMessage: i18n.t("google-calendar-invitation-sent"),
        }),
      );
      return response;
    } catch (e) {
      setError(e);
      console.log(e);
      throw e;
    } finally {
      setRequesting(false);
    }
  };

  return [
    requesting,
    sendCreateRequest,
    sendUpdateRequest,
    error,
    setError,
  ] as const;
}
