import React from "react";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import i18n from "i18n";
import Colors from "constants/Colors";
import CommentsIcon from "./CommentsIcon";
import { isEqual } from "lodash";
import areEqualShallow from "utils/areEqualShallow";

interface TopBarType {
  classes: any;
  isLibrary: boolean;
  views: number;
  totalDone: number;
  comments: [];
  rating: number;
  onOpenCommentsModal: () => void;
  isStepLocked?: boolean;
}

const useStyles = makeStyles(theme => ({
  topBar: {
    overflow: "scroll",
    width: "100%",
    height: 60,
    minHeight: 60,
    background: Colors.white,
    // boxShadow: "0px 2px 15px rgba(169, 169, 169, 0.25)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 25,
    paddingRight: 25,
    zIndex: 301,
    border: `solid 1px ${Colors.lightGrey}`,
  },
  topBarTitle: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 20,
    lineHeight: "27px",
    color: Colors.text,
  },
  stats: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: 20,
  },
  statDiv: {
    marginRight: 10,
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    borderRadius: 4,
    padding: "5px 15px",
    fontWeight: 500,
  },
  subTitle: {
    marginLeft: 10,
  },
  stat: {
    fontFamily: "Montserrat",
    fontSize: 32,
    fontWeight: 700,
    lineHeight: 1,
  },
  commentButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
    cursor: "pointer",
    padding: "8px 15px",
    transition: "all 0.2s",
    backgroundColor: Colors.lightGrey,
    "&:hover": {
      opacity: 0.8,
    },
  },
  commentText: {
    marginLeft: 10,
    fontSize: 14,
    color: Colors.text,
  },
  description: {
    fontSize: 11,
    fontWeight: "normal",
    color: Colors.text,
  },
  divButton: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    flexDirection: "column",
    flex: 1,
  },
}));

const TopBar = ({
  isLibrary,
  rating,
  views = 0,
  totalDone = 0,
  comments = [],
  onOpenCommentsModal = () => null,
}: TopBarType) => {
  const classes = useStyles();

  return (
    <div className={classes.topBar}>
      <Typography className={classes.topBarTitle}>
        {isLibrary ? i18n.t("challenge-stats") : i18n.t("selected-challenge")}
      </Typography>
      {isLibrary && (
        <div className={classes.stats}>
          <div className={classes.statDiv}>
            <Typography
              className={classes.stat}
              style={{ color: Colors.orange }}
            >
              {rating.toFixed(1)}
            </Typography>
            <Typography className={classes.subTitle}>
              {i18n.t("MediumRating")}
            </Typography>
          </div>
          <div className={classes.statDiv}>
            <Typography
              className={classes.stat}
              style={{ color: Colors.pureMain }}
            >
              {views}
            </Typography>
            <Typography className={classes.subTitle}>
              {views === 1 ? i18n.t("View") : i18n.t("Views")}
            </Typography>
          </div>
          <div className={classes.statDiv}>
            <Typography className={classes.stat} style={{ color: Colors.done }}>
              {totalDone}
            </Typography>
            <Typography className={classes.subTitle}>
              {totalDone === 1 ? i18n.t("Action") : i18n.t("Actions")}
            </Typography>
          </div>
          <div className={classes.statDiv}>
            <Typography className={classes.stat} style={{ color: Colors.pink }}>
              {comments.length}
            </Typography>
            <Typography className={classes.subTitle}>
              {i18n.t("Comments")}
            </Typography>
          </div>
          {comments.length > 0 && (
            <div className={classes.divButton}>
              <div
                className={classes.commentButton}
                onClick={onOpenCommentsModal}
              >
                <CommentsIcon sizePercentage={1.2} />
                <Typography className={classes.commentText}>
                  {i18n.t("see-comments")}
                </Typography>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */

  const equal = areEqualShallow(
    { ...prevProps, comments: undefined },
    { ...nextProps, comments: undefined },
  );

  if (!equal) return false;

  if (prevProps.comments.length === 0 && nextProps.comments.length === 0)
    return true;

  if (prevProps.comments.length === nextProps.comments.length) {
    return isEqual(prevProps.comments, nextProps.comments);
  }
}

export default React.memo(TopBar, areEqual);
