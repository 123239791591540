import React from "react";
import Colors from "@microdoing/shared/constants/Colors";
import { Typography } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  main: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    color: Colors.text,
    fontSize: 16,
  },
  extraSmall: {
    fontSize: 14,
  },
  small: {
    fontSize: 16,
  },
  medium: {
    fontSize: 18,
  },
  large: {
    fontSize: 20,
  },
  extraLarge: {
    fontSize: 22,
  },
}));

interface TitleProps {
  text: string;
  className?: string;
  style?: {};
  size?: "extraSmall" | "small" | "medium" | "large" | "extraLarge";
}

const MainTitle = ({
  text,
  style = {},
  className = "",
  size = "medium",
}: TitleProps) => {
  const classes = useStyles();

  return (
    <Typography
      style={style}
      className={`${classes.main} ${classes?.[size]} ${className}`}
    >
      {text}
    </Typography>
  );
};

export { MainTitle };
