import React from "react";

import withStyles from '@mui/styles/withStyles';

import Colors from "../../constants/Colors";

import RatingIcon from "./RatingIcon";
import SettingsIcon from "./SettingsIcon";
import LightIcon from "./LightIcon";
import CrownIcon from "./CrownIcon";
import FreeAnswerIcon from "./StepIcons/FreeAnswerIcon";
import MultipleChoicesIcon from "scenes/CreateTrack/MultipleChoicesIcon";

// const HEIGHT_BAR = 60;

const styles = theme => ({
  main: {
    width: 40,
    height: 40,
    backgroundColor: "rgba(0, 170, 255, 0.17)",
    // borderRadius: 13,
    borderRadius: 6,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  selected: {
    backgroundColor: "rgba(155, 174, 184, 0.1)",
  },
  textSymbol: {
    // color: Colors.black,
    fontWeight: "bold",
    color: Colors.main,
    // fontSize: "1.2em",
    fontSize: 18,
    marginTop: 2,
  },
  library: {
    backgroundColor: "rgba(255,230, 109, 0.7)",
  },
  settings: {
    backgroundColor: "#ECEBF1",
  },
  rating: {
    backgroundColor: "#CEE9FF",
  },
  choices: {
    backgroundColor: "#DDDCF8",
  },
  freeAnswer: {
    backgroundColor: "#FFDFED",
  },
  isSmall: {
    width: 35,
    height: 35,
  },
});

const StepSymbol = ({ classes, onClick, stepType }) => {
  if (stepType === "library")
    return (
      <div className={`${classes.main} ${classes.library}`} onClick={onClick}>
        <CrownIcon sizePercentage={1.2} />
      </div>
    );
  if (stepType === "settings")
    return (
      <div className={`${classes.main} ${classes.settings}`} onClick={onClick}>
        <SettingsIcon sizePercentage={0.85} />
      </div>
    );

  if (stepType === "RATING")
    return (
      <div className={`${classes.main} ${classes.rating}`} onClick={onClick}>
        <RatingIcon sizePercentage={1.2} />
      </div>
    );

  if (stepType === "MULTIPLE_CHOICES")
    return (
      <div className={`${classes.main} ${classes.choices}`} onClick={onClick}>
        <MultipleChoicesIcon sizePercentage={1.2} />
      </div>
    );

  if (stepType === "FREE_ANSWER")
    return (
      <div
        className={`${classes.main} ${classes.freeAnswer}`}
        onClick={onClick}
      >
        <FreeAnswerIcon sizePercentage={1.2} />
      </div>
    );
  return (
    <div className={`${classes.main}`} onClick={onClick}>
      <LightIcon />
    </div>
  );
};

StepSymbol.defaultProps = {
  stepType: "text",
};

export default withStyles(styles)(StepSymbol);
