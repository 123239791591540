import React, { useState } from "react";
import withStyles from "@mui/styles/withStyles";
import FillStarIcon from "./FillStarIcon";
import EmptyStarIcon from "./EmptyStarIcon";
import IconButton from "@mui/material/IconButton";
import i18n from "../../i18n";
import Colors from "constants/Colors";
import CustomArrowTooltip from "components/CustomArrowTooltip";

const styles = theme => ({
  main: {
    width: 44,
    height: 44,
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: Colors.lightGrey,
    },
  },
});

const StarComponent = ({
  classes,
  settingsDisabled = false,
  onClick,
  isTemplate,
  emptyStarColor = "white",
  isTemplateToolTip = i18n.t("star-template-toolTip-off"),
  isNotTemplateToolTip = i18n.t("star-template-toolTip-on"),
}) => {
  const [isHovered, setHover] = useState(false);

  const handleMouseLeave = () => setHover(false);

  const handleMouseEnter = () => setHover(true);

  return (
    <CustomArrowTooltip
      title={isTemplate ? isTemplateToolTip : isNotTemplateToolTip}
    >
      <IconButton
        className={classes.main}
        disabled={settingsDisabled}
        onClick={onClick}
        onMouseLeave={handleMouseLeave}
        onMouseEnter={handleMouseEnter}
        onMouseOver={handleMouseEnter}
        size="large"
      >
        {isTemplate || isHovered ? (
          <FillStarIcon />
        ) : (
          <EmptyStarIcon color={emptyStarColor} />
        )}
      </IconButton>
    </CustomArrowTooltip>
  );
};

export default withStyles(styles)(StarComponent);
