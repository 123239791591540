import React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import makeStyles from '@mui/styles/makeStyles';

const useStyle = makeStyles({
  toolTip: {
    fontFamily: "Roboto",
    // backgroundColor: "#262C31",
    // backgroundColor: "black",
    fontSize: 13,
  },
});

const ToolTip = ({ children, content }) => {
  const classes = useStyle();
  return (
    <Tippy content={content} className={classes.toolTip}>
      {children}
    </Tippy>
  );
};

export default ToolTip;
