import {
  GET_ALL_INVOICES_SUCCESS,
  CREATE_INVOICE_SUCCESS,
  CREATE_INVOICE_ERROR,
  GET_ALL_INVOICES_REQUESTING,
  CREATE_INVOICE_REQUESTING,
  GET_ALL_INVOICES_ERROR,
  DELETE_INVOICE_REQUESTING,
  UPDATE_INVOICE,
  START_NEW_INVOICE,
  GET_INVOICE_CLIENTS_REQUESTING,
  GET_INVOICE_CLIENTS_SUCCESS,
  GET_INVOICE_CLIENTS_ERROR,
  UPDATE_INVOICE_SUCCESS,
} from "./constants";

import { AUTH_LOGOUT_SUCCESS } from "../auth/reducer";
import moment from "moment";

const initialState = {
  requesting: false,
  loading: false,
  error: "",
  latestInvoice: {},
  invoices: [],
  invoiceClients: [],
  initialInvoice: {},
};

const reducer = function userReducer(state = initialState, action) {
  switch (action.type) {
    case GET_INVOICE_CLIENTS_REQUESTING:
    case CREATE_INVOICE_REQUESTING:
    case GET_ALL_INVOICES_REQUESTING: {
      return {
        ...state,
        requesting: true,
        loading: true,
        error: "",
      };
    }

    case UPDATE_INVOICE_SUCCESS:
    case UPDATE_INVOICE: {
      const invoices = state.invoices.map(el => {
        if (el._id === action.payload._id) {
          return {
            ...el,
            ...action.payload,
          };
        }
        return el;
      });
      return {
        ...state,
        invoices,
        requesting: false,
      };
    }

    case GET_ALL_INVOICES_SUCCESS: {
      return {
        ...state,
        invoices: action.payload,
        loading: false,
        requesting: false,
      };
    }

    case CREATE_INVOICE_SUCCESS: {
      return {
        ...state,
        invoices: [action.payload, ...state.invoices],
        latestInvoice: action.payload,
        requesting: false,
      };
    }

    case DELETE_INVOICE_REQUESTING: {
      const invoices = state.invoices.filter(
        el => el._id !== action.payload.coachingSessionId,
      );
      return {
        ...state,
        invoices,
      };
    }

    case GET_INVOICE_CLIENTS_ERROR:
    case CREATE_INVOICE_ERROR:
    case GET_ALL_INVOICES_ERROR: {
      return {
        ...state,
        requesting: false,
        loading: false,
        error: action.payload,
      };
    }

    case START_NEW_INVOICE: {
      const latestInvoice = state.invoices.length
        ? state.invoices[0]
        : state.latestInvoice;
      return {
        ...state,
        initialInvoice: {
          ...state.initialInvoice,
          ...latestInvoice,
          ...action.payload,
          isPaid: false,
          invoiceDate: moment(),
          invoiceDueDate: moment().add(30, "days").toString(),
          invoiceName: `Facture ${action.payload.clientName}`,
        },
      };
    }

    case GET_INVOICE_CLIENTS_SUCCESS: {
      return {
        ...state,
        invoiceClients: action.payload,
        requesting: false,
      };
    }

    case AUTH_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
