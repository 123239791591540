import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";
import { closeSnackMessage } from "../../services/createTrack/actions";
import Colors from "../../constants/Colors";

const styles = theme => ({
  close: {
    padding: theme.spacing.unit / 2,
  },
  success: {
    backgroundColor: Colors.green,
  },
});

class SimpleSnackbar extends React.Component {
  handleClick = () => {
    this.setState({ open: true });
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.props.closeSnackMessage();
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          // className={`${snackStatus === "success" && classes.success}`}
          open={Boolean(this.props.snackMessage)}
          autoHideDuration={3000}
          onClose={this.handleClose}
          ContentProps={{
            // className: snackStatus === "success" && classes.success,
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.props.snackMessage}</span>}
          action={[
            // <Button
            //   key="undo"
            //   color="secondary"
            //   size="small"
            //   onClick={this.handleClose}
            // >
            //   UNDO
            // </Button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
              size="large">
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}

SimpleSnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const { snackMessage, snackStatus } = state.createTrack;
  return {
    snackMessage,
    snackStatus,
  };
};

export default connect(mapStateToProps, { closeSnackMessage })(
  withStyles(styles)(SimpleSnackbar),
);
