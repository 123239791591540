export const UPLOAD_PICTURE_REQUESTING = "UPLOAD_PICTURE_REQUESTING";
export const UPLOAD_PICTURE_SUCCESS = "UPLOAD_PICTURE_SUCCESS";
export const UPLOAD_PICTURE_ERROR = "UPLOAD_PICTURE_ERROR";

export const UPLOAD_RESET = "UPLOAD_RESET";

export const SEARCH_UNSPLASH_REQUESTING = "upload/SEARCH_UNSPLASH_REQUESTING";
export const SEARCH_UNSPLASH_SUCCESS = "upload/SEARCH_UNSPLASH_SUCCESS";
export const SEARCH_UNSPLASH_ERROR = "upload/SEARCH_UNSPLASH_ERROR";

export const GET_RANDOM_UNSPLASH_REQUESTING =
  "upload/GET_RANDOM_UNSPLASH_REQUESTING";
export const GET_RANDOM_UNSPLASH_SUCCESS = "upload/GET_RANDOM_UNSPLASH_SUCCESS";
export const GET_RANDOM_UNSPLASH_ERROR = "upload/GET_RANDOM_UNSPLASH_ERROR";

export const UPLOAD_FILE_REQUESTING = "upload/UPLOAD_FILE_REQUESTING";
export const UPLOAD_FILE_SUCCESS = "upload/UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_ERROR = "upload/UPLOAD_FILE_ERROR";

export const DOWNLOAD_FILE_REQUESTING = "upload/DOWNLOAD_FILE_REQUESTING";
export const DOWNLOAD_FILE_SUCCESS = "upload/DOWNLOAD_FILE_SUCCESS";
export const DOWNLOAD_FILE_ERROR = "upload/DOWNLOAD_FILE_ERROR";

export const UPLOAD_PROGRESS_CHANGE = "upload/UPLOAD_PROGRESS_CHANGE";
export const DOWNLOAD_PROGRESS_CHANGE = "upload/DOWNLOAD_PROGRESS_CHANGE";
export const TEXT_EDITOR_PICTURE_UPLOADING =
  "upload/TEXT_EDITOR_PICTURE_UPLOADING";
export const TEXT_EDITOR_PICTURE_UPLOADED_SUCCESS =
  "upload/TEXT_EDITOR_PICTURE_UPLOADED_SUCCESS";
export const TEXT_EDITOR_PICTURE_UPLOADED_ERROR =
  "upload/TEXT_EDITOR_PICTURE_UPLOADED_ERROR";

export const UPDATE_FILES_TO_UPLOAD = "upload/UPDATE_FILES_TO_UPLOAD";

export const EXPORT_VIDEO_DONE = "upload/EXPORT_VIDEO_DONE";
