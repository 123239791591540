import React from "react";
import withStyles from '@mui/styles/withStyles';
import { Menu, Typography } from "@mui/material";
import CheckIcon from "../Tags/CheckIcon";
import DoneIcon from "../CheckIcon";
import i18n from "../../../i18n";
import Colors from "../../../constants/Colors";
import CloseIcon from "../Tags/CloseIcon";
import RatingAscIcon from "./SortIcons/RatingAscIcon";
import RatingDescIcon from "./SortIcons/RatingDescIcon";
import ViewsAscIcon from "./SortIcons/ViewsAscIcon";
import ViewsDescIcon from "./SortIcons/ViewsDescIcon";
import selectLanguage from "../../../utils/selectLanguage";

import {
  SORT_RATING_ASC,
  SORT_RATING_DESC,
  SORT_VIEWS_ASC,
  SORT_VIEWS_DESC,
  SORT_DONE_DESC,
  SORT_DONE_ASC,
} from "services/createTrack/sortingConstants";

const SORT_OPTIONS = [
  { value: SORT_RATING_ASC, icon: <RatingAscIcon sizePercentage={1.5} /> },
  { value: SORT_RATING_DESC, icon: <RatingDescIcon sizePercentage={1.55} /> },
  { value: SORT_VIEWS_ASC, icon: <ViewsAscIcon sizePercentage={1.5} /> },
  { value: SORT_VIEWS_DESC, icon: <ViewsDescIcon sizePercentage={1.55} /> },
  {
    value: SORT_DONE_ASC,
    icon: <DoneIcon sizePercentage={1} color={Colors.done} />,
  },
  {
    value: SORT_DONE_DESC,
    icon: <DoneIcon sizePercentage={1} color={Colors.done} />,
  },
];

const styles = theme => ({
  main: {
    outline: "none",
    display: "flex",
    flexDirection: "column",
    width: 450,
    // width: "110%",
    // width: "200px",
    minWidth: 180,
    maxWidth: "calc(100vw - 24px)",
    height: "100%",
    maxHeight: "70vh",
    borderRadius: 4,
    border: "solid 1px",
    borderColor: Colors.lightGrey,
  },
  checkIcon: {
    flex: 1,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  colors: {
    boxShadow: "rgba(55, 53, 47, 0.09) 0px -1px 0px",
    paddingTop: 6,
    paddingBottom: 6,
    marginTop: 6,
    // justifyContent: "center",
    // alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  colorDiv: {
    paddingLeft: 14,
    paddingRight: 14,
    minHeight: 28,
    fontSize: 14,
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    padding: "5px 10px",
    cursor: "pointer",
    transition: "all 0.2s",
    "&:hover": {
      background: Colors.lightGrey,
    },
  },
  colorBlock: {
    width: 18,
    height: 18,
    background: "rgba(206, 205, 202, 0.5)",
    boxShadow: "rgba(15, 15, 15, 0.1) 0px 0px 0px 1px inset",
    borderRadius: 3,
  },
  colorTitle: {
    color: "rgba(55, 53, 47, 0.6)",
    paddingLeft: 14,
    paddingRight: 14,
    marginTop: 6,
    fontSize: 11,
    marginBottom: 8,
  },
  colorName: {
    fontSize: 14,
    marginLeft: 8,
    marginRight: 14,
    color: "rgb(55, 53, 47, 1)",
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 18,
    height: 18,
  },
  filterText: {
    marginLeft: 8,
  },
  menu: {
    paddingBottom: 0,
  },
  tagDivider: {},
  divSelected: {
    background: Colors.lightGrey,
  },
});

const FilterContent = ({
  language,
  classes,
  onClose,
  anchorEl,
  skills = [],
  selectFilter,
  filterSelected,
  selectSort = () => () => null,
  sortSelected,
  isLibrary = false,
}) => {
  return (
    <Menu
      onClose={onClose}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
    >
      <div className={classes.main}>
        {isLibrary && (
          <div className={classes.libraryFilter}>
            {SORT_OPTIONS.map((el, i) => {
              const isSelected = sortSelected === el.value;
              return (
                <div
                  className={`${classes.colorDiv} ${
                    isSelected && classes.divSelected
                  }`}
                  onClick={selectSort(el.value)}
                  key={i}
                >
                  <div className={classes.icon}>{el.icon}</div>
                  <Typography className={classes.filterText}>
                    {i18n.t(el.value)}
                  </Typography>
                  {isSelected && (
                    <div className={classes.checkIcon}>
                      <CheckIcon />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
        {skills.length < 1 ? (
          <Typography className={classes.colorTitle}>
            {i18n.t("no-skills-yet")}
          </Typography>
        ) : (
          <Typography className={classes.colorTitle}>
            {i18n.t("by-competency")}
          </Typography>
        )}

        {skills.length > 1 && filterSelected.value && (
          <div
            className={classes.colorDiv}
            style={{
              color: Colors.grey,
              fill: Colors.grey,
              paddingTop: 10,
              paddingBottom: 10,
            }}
            onClick={selectFilter({})}
          >
            <CloseIcon sizePercentage={1.2} />
            <Typography
              className={classes.colorName}
              style={{ color: Colors.grey }}
            >
              {i18n.t("reset-filters")}
            </Typography>
          </div>
        )}
        {skills
          .sort((a, b) => {
            const aLabel = a.value.toLowerCase();
            const bLabel = b.value.toLowerCase();
            if (aLabel < bLabel) {
              return -1;
            }
            if (aLabel > bLabel) {
              return 1;
            }
            return 0;
          })
          .map((el, index) => {
            const isSelected = el.value === filterSelected.value;
            return (
              <div
                className={`${classes.colorDiv} ${
                  isSelected && classes.divSelected
                }`}
                onClick={selectFilter(el)}
                key={index}
              >
                <div
                  className={classes.colorBlock}
                  style={{ background: el.color }}
                />
                <Typography className={classes.colorName}>
                  {selectLanguage({ text: el.label, language })}
                </Typography>
                {isSelected && (
                  <div className={classes.checkIcon}>
                    <CheckIcon />
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </Menu>
  );
};

FilterContent.defaultProps = {};

export default withStyles(styles)(FilterContent);
