import React, { PureComponent } from "react";
import { connect } from "react-redux";

import moment from "moment";
import withStyles from "@mui/styles/withStyles";
import Fuse from "fuse.js";
import {
  getAllDraftsRequest,
  removeDraftRequest,
} from "../../services/campaign/actions";
import { navigateCreateTrack } from "../../services/createTrack/actions";
import { push } from "connected-react-router";
import "moment/locale/fr";
import { Loader } from "../../components";
import NewCampaignModal from "./NewCampaignModal";
import selectLanguage from "../../utils/selectLanguage";
import CampaignBox from "./CampaignBox";
import i18n from "../../i18n";
import { getSelectedClientId, getUserId } from "../../services/user/selectors";
import { getAppLanguage } from "../../services/general/selectors";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import SearchBar from "components/SearchBar";
import { getDrafts } from "services/campaign/selectors";

moment.locale("fr");

const fuseOptions = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 80,
  maxPatternLength: 20,
  minMatchCharLength: 1,
  keys: [
    "title.fr",
    "title.en",
    "description.fr",
    "description.en",
    "label",
    "notes",
  ],
};

const styles = theme => ({
  main: {
    width: "90%",
    marginTop: 15,
    flexDirection: "column",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  paper: {
    flex: 1,
    marginTop: "calc(1rem)",
    marginLeft: "-1.5rem",
    marginRight: "-1.5rem",
    marginBottom: "calc(-3rem)",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  topDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  searchBar: {
    marginTop: 5,
    marginBottom: 5,
    minWidth: 300,
    width: 300,
    flex: 0,
  },
  inputClassName: {
    border: "1px solid rgba(0,0,0,.09)",
    backgroundColor: "#fff",
  },
});

class MyDrafts extends PureComponent {
  state = {
    expanded: null,
    labelWidth: 0,
    isCreationModalVisible: false,
    isDeleteConfirmModalVisible: false,
    search: "",
    trackSelected: {},
  };

  componentDidMount() {
    this.props.getAllDraftsRequest({
      clientId: this.props.clientId,
      isDraft: true,
      isPrivate: true,
    });
  }

  onChangeTime = event => {
    const time = event.target.value;
    const minute = Number(time.split(":")[1]);
    const hour = Number(time.split(":")[0]);

    const reminderTime = moment(this.props.reminderTime)
      .minute(minute)
      .hour(hour);
    this.props.changeCoffeeSettings({ reminderTime });
  };

  onClickMore = () => {};

  onConfirmDelete = event => {
    event.stopPropagation();
    this.props.removeDraftRequest({
      trackId: this.state.trackSelected._id,
      isRemoved: true,
    });
    this.setState({ isDeleteConfirmModalVisible: false });
  };

  onClickDelete = track => () => {
    this.setState({
      isDeleteConfirmModalVisible: true,
      trackSelected: track,
    });
  };

  onClickTrack = trackId => () => {
    this.props.navigateCreateTrack({ trackId });
  };

  displayDrafts = drafts => {
    const { language } = this.props;
    return drafts.map((el, i) => {
      return (
        <CampaignBox
          key={i}
          {...el}
          participants={[]}
          showSteps
          hasAddTemplate={false}
          onClickDelete={this.onClickDelete(el)}
          onClick={this.onClickTrack(el._id)}
          title={selectLanguage({ text: el.title, language })}
          description={selectLanguage({ text: el.description, language })}
          hoverText={i18n.t("open-draft")}
        />
      );
    });
  };

  onCloseCreationModal = () => {
    this.setState({ isCreationModalVisible: false });
  };

  onChangeSearch = search => {
    this.setState({ search });
  };

  displayCreationModal = () => {
    this.setState({ isCreationModalVisible: true });
  };

  render() {
    const { classes, drafts } = this.props;
    const { search } = this.state;

    const fuse = new Fuse(drafts, fuseOptions);
    const searchResults = search.length > 0 ? fuse.search(search) : drafts;

    return (
      <div className={classes.main}>
        <DeleteConfirmModal
          title={i18n.t("delete-campaign-confirm-title")}
          onClickConfirm={this.onConfirmDelete}
          isVisible={this.state.isDeleteConfirmModalVisible}
          onClose={() => this.setState({ isDeleteConfirmModalVisible: false })}
        />
        <NewCampaignModal
          openFromTemplate
          isVisible={this.state.isCreationModalVisible}
          onClose={this.onCloseCreationModal}
        />
        <div className={classes.topDiv}>
          <SearchBar
            placeholder={i18n.t("search-drafts-placeholder")}
            className={classes.searchBar}
            inputClassName={classes.inputClassName}
            onChangeSearch={this.onChangeSearch}
            value={this.state.search}
          />
        </div>
        <div className={classes.paper}>
          {this.props.requesting ? (
            <Loader size={75} />
          ) : (
            this.displayDrafts(searchResults)
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { requesting } = state.campaign;
  const drafts = getDrafts(state);
  const language = getAppLanguage(state);
  const clientId = getSelectedClientId(state);
  const userId = getUserId(state);
  return {
    token: state.auth.token,
    userId,
    language,
    requesting,
    clientId,
    drafts,
  };
};

export default connect(mapStateToProps, {
  getAllDraftsRequest,
  navigateCreateTrack,
  push,
  removeDraftRequest,
})(withStyles(styles)(MyDrafts));
