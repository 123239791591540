import React, { PureComponent } from "react";
import { connect } from "react-redux";

import withStyles from '@mui/styles/withStyles';

import BestAction from "./BestAction";

const colorRange = [
  "rgba(252,87,122,0.8)",
  "rgba(66,80,251,0.8)",
  "rgba(0,170,255,0.8)",
  "#FF9F40",
  "#FFC233",
];

const styles = theme => ({
  main: {
    // backgroundColor: "#FAFAFA",
    backgroundColor: "#fff",
    flex: 1,
    minHeight: 300,
    paddingBottom: 35,
    flexDirection: "row",
    paddingRight: "2%",
    paddingLeft: "2%",
    paddingTop: 35,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  bottomSection: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flex: 1,
  },
  leftSection: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: 350,
    alignItems: "center",
    justifyContent: "center",
  },
  rightSection: {
    flex: 1,
    height: 350,
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  accent: {
    color: "#6198DE",
  },
  bold: {
    fontWeight: 500,
    marginBottom: 15,
  },
  actionLegend: {
    color: "#fff",
  },
  box: {
    webkitBoxShadow: "0px 0px 4px 1px #D8D8D8",
    mozBoxShadow: "0px 0px 4px 1px #D8D8D8",
    boxShadow: "0px 0px 4px 1px #D8D8D8",
    height: 200,
    flex: 1,
    padding: 5,
    flexDirection: "column",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  chartContainer: {
    backgroundColor: "blue",
    height: 350,
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

/*const isNumber = n => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};*/

class FourthSection extends PureComponent {
  state = {
    name: "",
    owner: "",
    domain: "",
  };

  render() {
    const { classes } = this.props;
    const {
      leadership,
      resilience,
      teamCohesion,
      publicSpeaking,
      proactivity,
    } = this.props.bestActions;
    return (
      <div className={classes.main}>
        <BestAction
          title={leadership.title}
          isFake={leadership.isFake}
          category="Leadership"
          count={leadership.count}
          rating={leadership.rating}
          donePercentage={leadership.donePercentage}
          color={colorRange[0]}
        />
        <BestAction
          title={resilience.title}
          isFake={resilience.isFake}
          category="Résilience"
          count={resilience.count}
          rating={resilience.rating}
          donePercentage={resilience.donePercentage}
          color={colorRange[1]}
        />
        <BestAction
          title={teamCohesion.title}
          isFake={teamCohesion.isFake}
          category="Cohésion d'équipe"
          count={teamCohesion.count}
          rating={teamCohesion.rating}
          donePercentage={teamCohesion.donePercentage}
          color={colorRange[2]}
        />
        <BestAction
          title={publicSpeaking.title}
          isFake={publicSpeaking.isFake}
          category="Capacité à convaincre"
          count={publicSpeaking.count}
          rating={publicSpeaking.rating}
          donePercentage={publicSpeaking.donePercentage}
          color={colorRange[3]}
        />
        <BestAction
          title={proactivity.title}
          isFake={proactivity.isFake}
          category="Pro-activité"
          count={proactivity.count}
          rating={proactivity.rating}
          donePercentage={proactivity.donePercentage}
          color={colorRange[4]}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { bestActions } = state.action;
  return {
    bestActions,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(FourthSection));
