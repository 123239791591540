import { createSelector } from "reselect";

import { getCampaignResources, getLibraryResources } from "./selectors";
import { getCoachingResources } from "../coaching/selectors";

const getAvailableResources = createSelector(
  [getLibraryResources, getCoachingResources, getCampaignResources],
  (libraryResources, coachResources, campaignResources) => {
    const idsToExclude = {};
    coachResources.map(el => idsToExclude[el.resourceId] = true);
    campaignResources.map(el => idsToExclude[el.resourceId] = true);

    return libraryResources.filter(resource => !idsToExclude[resource._id]);
  },
);

export default getAvailableResources;
