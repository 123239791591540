import React from "react";

const Logo = ({ sizePercentage }) => (
  <svg
    width={sizePercentage * 58}
    height={sizePercentage * 58}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 58 58"
    version="1.1"
  >
    <g id="logo" stroke="none" strokeWidth="1" fill="none">
      <g id="Dashboard-perfect" transform="translate(-14.000000, -9.000000)">
        <g id="Group-2" transform="translate(15.000000, 10.000000)">
          <g id="Group-3">
            <circle
              id="Oval-2"
              stroke="#FFFFFF"
              fill="#FFFFFF"
              cx="28"
              cy="28"
              r="28"
            ></circle>
            <g id="Group" transform="translate(2.000000, 2.000000)">
              <circle
                id="Oval-2"
                fill="#00AAFF"
                cx="26"
                cy="26"
                r="26"
              ></circle>
              <g
                id="startup"
                transform="translate(28.452830, 23.547170) scale(-1, 1) translate(-28.452830, -23.547170) translate(13.735849, 8.830189)"
                fill="#FFFFFF"
                fillRule="nonzero"
              >
                <path
                  d="M28.3327448,13.4236956 C26.7405162,9.22600209 22.5428227,7.05478133 17.9108851,7.92326963 C13.4236956,3.43608007 7.19952938,0.396371006 0.975363208,0.251622955 C0.541119056,0.251622955 0.251622955,0.541119056 0.251622955,0.975363208 C0.396371006,7.19952938 3.43608007,13.4236956 7.77852158,17.9108851 C7.05478133,22.3980747 9.08125404,26.7405162 13.2789475,28.3327448 C12.5552073,26.4510201 12.4104592,24.2797993 12.9894514,22.1085786 C13.7131917,22.5428227 14.4369319,22.9770669 15.1606722,23.266563 C15.4501683,23.411311 15.7396644,23.411311 16.0291605,23.1218149 L23.266563,15.8844124 C23.411311,15.7396644 23.411311,15.4501683 23.266563,15.1606722 C22.9770669,14.4369319 22.5428227,13.7131917 22.1085786,13.1341995 C24.2797993,12.5552073 26.4510201,12.6999553 28.3327448,13.4236956 Z M12.4104592,12.4104592 C11.2524748,13.5684436 9.51549819,13.5684436 8.35751378,12.4104592 C7.19952938,11.2524748 7.19952938,9.51549819 8.35751378,8.35751378 C9.51549819,7.19952938 11.2524748,7.19952938 12.4104592,8.35751378 C13.5684436,9.51549819 13.5684436,11.2524748 12.4104592,12.4104592 Z"
                  id="Shape"
                  transform="translate(14.292184, 14.292184) rotate(1.000000) translate(-14.292184, -14.292184) "
                ></path>
              </g>
              <circle
                id="Oval"
                fill="#00AAFF"
                cx="33.3584906"
                cy="19.6226415"
                r="2.94339623"
              ></circle>
              <path
                d="M14.3684211,26.6842105 L14.3684211,26.6842105 C15.1241791,26.6842105 15.7368421,27.2968735 15.7368421,28.0526316 L15.7368421,48.5789474 C15.7368421,49.3347054 15.1241791,49.9473684 14.3684211,49.9473684 L14.3684211,49.9473684 C13.612663,49.9473684 13,49.3347054 13,48.5789474 L13,28.0526316 C13,27.2968735 13.612663,26.6842105 14.3684211,26.6842105 Z"
                id="Rectangle"
                fill="#FFFFFF"
                transform="translate(14.368421, 38.315789) rotate(45.000000) translate(-14.368421, -38.315789) "
              ></path>
              <path
                d="M13.3421053,24.6315789 L13.3421053,24.6315789 C14.0978633,24.6315789 14.7105263,25.2442419 14.7105263,26 L14.7105263,40.7105263 C14.7105263,41.4662844 14.0978633,42.0789474 13.3421053,42.0789474 L13.3421053,42.0789474 C12.5863472,42.0789474 11.9736842,41.4662844 11.9736842,40.7105263 L11.9736842,26 C11.9736842,25.2442419 12.5863472,24.6315789 13.3421053,24.6315789 Z"
                id="Rectangle-Copy"
                fill="#0560C9"
                transform="translate(13.342105, 33.355263) rotate(45.000000) translate(-13.342105, -33.355263) "
              ></path>
              <path
                d="M19.1578947,30.4473684 L19.1578947,30.4473684 C19.9136528,30.4473684 20.5263158,31.0600314 20.5263158,31.8157895 L20.5263158,46.5263158 C20.5263158,47.2820739 19.9136528,47.8947368 19.1578947,47.8947368 L19.1578947,47.8947368 C18.4021367,47.8947368 17.7894737,47.2820739 17.7894737,46.5263158 L17.7894737,31.8157895 C17.7894737,31.0600314 18.4021367,30.4473684 19.1578947,30.4473684 Z"
                id="Rectangle-Copy-2"
                fill="#D0021B"
                transform="translate(19.157895, 39.171053) rotate(45.000000) translate(-19.157895, -39.171053) "
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Logo.defaultProps = {
  sizePercentage: 1,
};

export default Logo;
