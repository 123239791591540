import React from "react";

const CheckIcon = ({ sizePercentage, color, className }) => (
  <svg
    // width={sizePercentage * 24}
    className={className}
    width={sizePercentage * 16}
    height={sizePercentage * 13}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 16 13"
    version="1.1"
  >
    <path
      d="M6.45559 12.4668C6.31061 12.6126 6.1128 12.694 5.90732 12.694C5.70185 12.694 5.50404 12.6126 5.35906 12.4668L0.980451 8.08749C0.526048 7.63309 0.526048 6.89625 0.980451 6.4427L1.52872 5.89429C1.98326 5.43988 2.71926 5.43988 3.17366 5.89429L5.90732 8.62809L13.2941 1.24119C13.7486 0.78679 14.4853 0.78679 14.939 1.24119L15.4873 1.7896C15.9417 2.24401 15.9417 2.98071 15.4873 3.4344L6.45559 12.4668Z"
      fill={color}
    />
  </svg>
);

CheckIcon.defaultProps = {
  sizePercentage: 1,
  color: "#fff",
};

export default CheckIcon;
