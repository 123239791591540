import React from "react";
import withStyles from "@mui/styles/withStyles";
import IconButton from "@mui/material/IconButton";
import i18n from "i18n";
import Colors from "constants/Colors";
import DeleteIcon from "scenes/Campaigns/DeleteIcon";
import CustomArrowTooltip from "components/CustomArrowTooltip";

const styles = theme => ({
  main: {
    width: 44,
    height: 44,
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: Colors.lightGrey,
    },
  },
});

interface Props {
  classes: any;
  settingsDisabled?: boolean;
  onClick: (a: any) => void;
  className?: string;
  style?: any;
}

const DeleteComponent = ({
  classes,
  settingsDisabled = false,
  onClick,
  className = "",
  style,
}: Props) => {
  return (
    <CustomArrowTooltip title={i18n.t("delete-campaign-tooltip")}>
      <IconButton
        className={`${classes.main} ${className}`}
        style={style}
        disabled={settingsDisabled}
        onClick={onClick}
        size="large"
      >
        <DeleteIcon sizePercentage={1.4} />
      </IconButton>
    </CustomArrowTooltip>
  );
};

export default withStyles(styles)(DeleteComponent);
