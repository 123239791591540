import React from "react";
import withStyles from '@mui/styles/withStyles';
import Colors from "../constants/Colors";
import { Loader } from "./Loader";
import { Typography } from "@mui/material";

const styles = {
  main: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 20,
    backgroundColor: Colors.darkBlue,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  text: {
    marginTop: 20,
    color: Colors.white,
    fontSize: 16,
  },
};

const FullScreenLoading = ({ classes, title }) => {
  return (
    <div className={classes.main}>
      <Loader size={45} />
      <Typography className={classes.text}>{title}</Typography>
    </div>
  );
};

export default withStyles(styles)(FullScreenLoading);
