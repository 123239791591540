import React from "react";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { motion } from "framer-motion";
import Colors from "constants/Colors";
import displayDateWithoutHour from "utils/displayDateWithoutHour";
import Objective from "scenes/CreateTrack/Objectives/Objective";
import StarIcon from "../../../CreateTrack/StarIcon";
import i18n from "i18n";
import { RemoveButtonIcon } from "components";
import { ReviewFileType } from "services/campaign/types";

const variants = {
  visible: i => ({
    opacity: 1,
    y: 0,
  }),
  hidden: { opacity: 0, y: 30 },
};

const getPercentageColor = percentage => {
  if (percentage > 50) {
    return Colors.green;
  } else if (percentage > 25) {
    return Colors.orange;
  }
  return Colors.red;
};

const useStyles = makeStyles(theme => ({
  firstRow: {
    display: "flex",
    flex: 2,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  row: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  tableRow: {
    boxSizing: "border-box",
    borderTop: "1px solid rgba(0,0,0,.09)",
    cursor: "pointer",
    width: "100%",
    backgroundColor: "#fff",
    padding: 20,
    paddingLeft: 45,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
    transition: "0.2s all ease",
    "&:hover": {
      opacity: 0.9,
      backgroundColor: "#ECF6FF",
    },
  },
  actionTitle: {
    cursor: "pointer",
    color: Colors.black,
    // flex: 1,
    minWidth: 0,
    marginRight: 15,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 1 /* number of lines to show */,
    lineHeight: "1.5em" /* fallback */,
    maxHeight: "1.5em",
    wordBreak: "break-all",
    fontSize: "0.9em",
  },
  rating: {
    marginRight: 4,
    marginTop: 2,
  },
  smallText: {
    fontWeight: "normal",
    fontSize: "0.85em",
  },
  sortingRow: {
    cursor: "pointer",
  },
  indicator: {
    marginLeft: 5,
    backgroundColor: Colors.orange,
    height: "10px",
    width: "10px",
    borderRadius: "50%",
  },
  box: {
    position: "relative",
    marginTop: 30,
    width: "100%",
    overflow: "hidden",
    backgroundColor: "#fff",
    borderRadius: 10,
  },
  topBar: {
    paddingLeft: 45,
    paddingRight: 45,
    height: 90,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    borderBottom: `solid 1px ${Colors.lightGrey}`,
    backgroundColor: "#fff",
  },
  objective: {
    margin: 0,
  },
  rowSelected: {
    backgroundColor: "#ECF6FF",
  },
  firstHeader: {
    "&:hover": {
      textDecoration: "none",
    },
  },
  points: {
    fontWeight: "bold",
  },
  done: {
    color: Colors.darkBlue,
    fontWeight: "bold",
  },
  date: {},
  donePercentage: {},
  removeButton: {
    right: 0,
    position: "absolute",
  },
}));

interface Step {
  stepId: string;
  comments: any[];
  totalDone: number;
  rating: number;
  donePercentage: number;
  totalComments: number;
  createdAt: Date;
  views: number;
  totalViews: number;
  category: string;
  files: ReviewFileType[];
}

interface Props {
  onClickRow: (
    stepId: string,
    comments: String[],
    files: ReviewFileType[],
  ) => () => void;
  onRemove?: (stepId: string) => (e: any) => void;
  hasComments?: boolean;
  hasDateRow?: boolean;
  isStepSelected?: boolean;
  title: string;
  step: Step;
}

const ActionRow = ({
  onClickRow,
  onRemove = null,
  hasComments = false,
  hasDateRow = false,
  isStepSelected = false,
  title,
  step,
}: Props) => {
  const classes = useStyles();
  const {
    stepId,
    comments,
    totalDone,
    rating,
    donePercentage,
    totalComments,
    createdAt,
    views,
    totalViews,
    category,
    files,
  } = step;

  return (
    <motion.div
      onClick={onClickRow(stepId, comments, files)}
      className={`${classes.tableRow} ${isStepSelected && classes.rowSelected}`}
      //   custom={index}
      initial="hidden"
      animate="visible"
      variants={variants}
    >
      <div className={classes.firstRow}>
        <Typography
          className={classes.actionTitle}
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        ></Typography>
      </div>
      <div className={classes.row} style={{ justifyContent: "flex-start" }}>
        <Objective label={i18n.t(category)} className={classes.objective} />
      </div>
      <div className={classes.row}>
        <Typography className={classes.done}>
          {totalDone}{" "}
          <span className={classes.smallText}>{i18n.t("times")}</span>
        </Typography>
      </div>
      <div className={classes.row}>
        <Typography
          className={classes.points}
          style={{ color: Colors.pureMain }}
        >
          {views || totalViews}
        </Typography>
      </div>
      <div className={classes.row}>
        <Typography className={classes.rating}>{rating}</Typography>
        <StarIcon sizePercentage={0.6} />
      </div>
      <div className={classes.row}>
        <Typography className={classes.donePercentage}>
          {`${donePercentage}%`}
        </Typography>
        <div
          className={classes.indicator}
          style={{
            backgroundColor: getPercentageColor(donePercentage),
          }}
        />
      </div>
      {hasComments && (
        <div className={classes.row}>
          <Typography
            className={classes.date}
            style={{ color: Colors.darkBlue }}
          >
            {totalComments}
          </Typography>
        </div>
      )}
      {hasDateRow && (
        <div className={classes.row}>
          <Typography className={classes.date}>
            {displayDateWithoutHour(createdAt, "Europe/Paris")}
          </Typography>
        </div>
      )}
      {onRemove && (
        <RemoveButtonIcon
          onClick={onRemove(stepId)}
          className={classes.removeButton}
        />
      )}
    </motion.div>
  );
};

export default ActionRow;
