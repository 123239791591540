const videoExtensions = {
  webm: true,
  mkv: true,
  flv: true,
  vob: true,
  ogv: true,
  ogg: true,
  rrc: true,
  gifv: true,
  mng: true,
  mov: true,
  avi: true,
  qt: true,
  wmv: true,
  yuv: true,
  rm: true,
  asf: true,
  amv: true,
  mp4: true,
  m4p: true,
  m4v: true,
  mpg: true,
  mp2: true,
  mpeg: true,
  mpe: true,
  mpv: true,
  svi: true,
  "3gp": true,
  "3g2": true,
  mxf: true,
  roq: true,
  nsv: true,
  f4v: true,
  f4p: true,
  f4a: true,
  f4b: true,
};

const hasVideoExtension = (fileName: string): boolean => {
  if (!fileName) return false;

  const fileSplit = fileName.split(".");

  if (!fileSplit.length) return false;

  const fileExtension = fileSplit[fileSplit.length - 1]?.toLowerCase();

  return videoExtensions[fileExtension] || false;
};

export default hasVideoExtension;
