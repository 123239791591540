export const POLL_GET_SETTINGS_REQUESTING = "POLL_GET_SETTINGS_REQUESTING";
export const POLL_GET_SETTINGS_SUCCESS = "POLL_GET_SETTINGS_SUCCESS";
export const POLL_GET_SETTINGS_ERROR = "POLL_GET_SETTINGS_ERROR";

export const ADD_STEP = "ADD_STEP";

export const REMOVE_STEP = "REMOVE_STEP";

export const REORDER_STEPS = "REORDER_STEPS";

export const STEP_CREATE_REQUESTING = "STEP_CREATE_REQUESTING";
export const STEP_CREATE_SUCCESS = "STEP_CREATE_SUCCESS";
export const STEP_CREATE_ERROR = "STEP_CREATE_ERROR";

export const STEP_GET_ALL_REQUESTING = "STEP_GET_ALL_REQUESTING";
export const STEP_GET_ALL_SUCCESS = "STEP_GET_ALL_SUCCESS";
export const STEP_GET_ALL_ERROR = "STEP_GET_ALL_ERROR";

export const GET_MY_LIBRARY_STEPS_REQUESTING =
  "createTrack/GET_MY_LIBRARY_STEPS_REQUESTING";
export const GET_MY_LIBRARY_STEPS_SUCCESS =
  "createTrack/GET_MY_LIBRARY_STEPS_SUCCESS";
export const GET_MY_LIBRARY_STEPS_ERROR =
  "createTrack/GET_MY_LIBRARY_STEPS_ERROR";

export const STEP_GET_LIST_REQUESTING = "STEP_GET_LIST_REQUESTING";
export const STEP_GET_LIST_SUCCESS = "STEP_GET_LIST_SUCCESS";
export const STEP_GET_LIST_ERROR = "STEP_GET_LIST_ERROR";

export const STEP_MODIFY_REQUESTING = "STEP_MODIFY_REQUESTING";
export const STEP_MODIFY_SUCCESS = "STEP_MODIFY_SUCCESS";
export const STEP_MODIFY_ERROR = "STEP_MODIFY_ERROR";

export const STEP_DELETE_REQUESTING = "STEP_DELETE_REQUESTING";
export const STEP_DELETE_SUCCESS = "STEP_DELETE_SUCCESS";
export const STEP_DELETE_ERROR = "STEP_DELETE_ERROR";

export const STEP_ADMIN_MODIFY_REQUESTING = "STEP_ADMIN_MODIFY_REQUESTING";
export const STEP_ADMIN_MODIFY_SUCCESS = "STEP_ADMIN_MODIFY_SUCCESS";
export const STEP_ADMIN_MODIFY_ERROR = "STEP_ADMIN_MODIFY_ERROR";

export const STEP_ADMIN_CREATE_REQUESTING = "STEP_ADMIN_CREATE_REQUESTING";
export const STEP_ADMIN_CREATE_SUCCESS = "STEP_ADMIN_CREATE_SUCCESS";
export const STEP_ADMIN_CREATE_ERROR = "STEP_ADMIN_CREATE_ERROR";

export const STEP_ADMIN_DELETE_REQUESTING = "STEP_ADMIN_DELETE_REQUESTING";
export const STEP_ADMIN_DELETE_SUCCESS = "STEP_ADMIN_DELETE_SUCCESS";
export const STEP_ADMIN_DELETE_ERROR = "STEP_ADMIN_DELETE_ERROR";

export const POLL_GET_ALL_REQUESTING = "POLL_GET_ALL_REQUESTING";
export const POLL_GET_ALL_SUCCESS = "POLL_GET_ALL_SUCCESS";
export const POLL_GET_ALL_ERROR = "POLL_GET_ALL_ERROR";

export const POll_CHANGE_FIELD = "POll_CHANGE_FIELD";

export const CREATE_CLOSE_SNACK_MESSAGE = "CREATE_CLOSE_SNACK_MESSAGE";
export const CREATE_OPEN_SNACK_MESSAGE = "CREATE_OPEN_SNACK_MESSAGE";

export const CREATE_TRACK_CHANGE_FIELD = "CREATE_TRACK_CHANGE_FIELD";
export const CHANGE_STEP_SELECTED = "CHANGE_STEP_SELECTED";

export const TRACK_CREATE_REQUESTING = "TRACK_CREATE_REQUESTING";
export const TRACK_CREATE_SUCCESS = "TRACK_CREATE_SUCCESS";
export const TRACK_CREATE_ERROR = "TRACK_CREATE_ERROR";

export const TRACK_CREATE_GET_REQUESTING = "TRACK_CREATE_GET_REQUESTING";
export const TRACK_CREATE_GET_SUCCESS = "TRACK_CREATE_GET_SUCCESS";
export const TRACK_CREATE_GET_ERROR = "TRACK_CREATE_GET_ERROR";

export const TRACK_GET_REQUESTING = "TRACK_GET_REQUESTING";
export const TRACK_GET_SUCCESS = "TRACK_GET_SUCCESS";
export const TRACK_GET_ERROR = "TRACK_GET_ERROR";

export const TRACK_MODIFY_REQUESTING = "TRACK_MODIFY_REQUESTING";
export const TRACK_MODIFY_SUCCESS = "TRACK_MODIFY_SUCCESS";
export const TRACK_MODIFY_ERROR = "TRACK_MODIFY_ERROR";

export const ADMIN_MOBILE_CONNECTION = "ADMIN_MOBILE_CONNECTION";

export const SAVE_TRACK_REQUESTING = "SAVE_TRACK_REQUESTING";

export const TRACK_SET = "TRACK_SET";

export const CREATE_AND_MODIFY_TEMPLATE_REQUESTING =
  "CREATE_AND_MODIFY_TEMPLATE_REQUESTING";
export const CREATE_AND_MODIFY_TEMPLATE_SUCCESS =
  "CREATE_AND_MODIFY_TEMPLATE_SUCCESS";
export const CREATE_AND_MODIFY_TEMPLATE_ERROR =
  "CREATE_AND_MODIFY_TEMPLATE_ERROR";

export const GET_ALL_COMPETENCIES_REQUESTING =
  "GET_ALL_COMPETENCIES_REQUESTING";
export const GET_ALL_COMPETENCIES_SUCCESS = "GET_ALL_COMPETENCIES_SUCCESS";
export const GET_ALL_COMPETENCIES_ERROR = "GET_ALL_COMPETENCIES_ERROR";

export const GET_ALL_OBJECTIVES_REQUESTING = "GET_ALL_OBJECTIVES_REQUESTING";
export const GET_ALL_OBJECTIVES_SUCCESS = "GET_ALL_OBJECTIVES_SUCCESS";
export const GET_ALL_OBJECTIVES_ERROR = "GET_ALL_OBJECTIVES_ERROR";

export const GET_TAGS_LIST_REQUESTING = "GET_TAGS_LIST_REQUESTING";
export const GET_TAGS_LIST_SUCCESS = "GET_TAGS_LIST_SUCCESS";
export const GET_TAGS_LIST_ERROR = "GET_TAGS_LIST_ERROR";

export const UPDATE_TAGS_LIST_REQUESTING = "UPDATE_TAGS_LIST_REQUESTING";
export const UPDATE_TAGS_LIST_SUCCESS = "UPDATE_TAGS_LIST_SUCCESS";
export const UPDATE_TAGS_LIST_ERROR = "UPDATE_TAGS_LIST_ERROR";

export const GET_SKILLS_LIST_REQUESTING = "GET_SKILLS_LIST_REQUESTING";
export const GET_SKILLS_LIST_SUCCESS = "GET_SKILLS_LIST_SUCCESS";
export const GET_SKILLS_LIST_ERROR = "GET_SKILLS_LIST_ERROR";

export const UPDATE_SKILLS_LIST_REQUESTING = "UPDATE_SKILLS_LIST_REQUESTING";
export const UPDATE_SKILLS_LIST_SUCCESS = "UPDATE_SKILLS_LIST_SUCCESS";
export const UPDATE_SKILLS_LIST_ERROR = "UPDATE_SKILLS_LIST_ERROR";

export const GET_FOLDER_LIST_REQUESTING = "GET_FOLDER_LIST_REQUESTING";
export const GET_FOLDER_LIST_SUCCESS = "GET_FOLDER_LIST_SUCCESS";
export const GET_FOLDER_LIST_ERROR = "GET_FOLDER_LIST_ERROR";

export const UPDATE_FOLDER_LIST_REQUESTING = "UPDATE_FOLDER_LIST_REQUESTING";
export const UPDATE_FOLDER_LIST_SUCCESS = "UPDATE_FOLDER_LIST_SUCCESS";
export const UPDATE_FOLDER_LIST_ERROR = "UPDATE_FOLDER_LIST_ERROR";

export const DUPLICATE_STEP = "DUPLICATE_STEP";

export const MY_LIBRARY_GET_TOTAL_COUNT_REQUESTING =
  "MY_LIBRARY_GET_TOTAL_COUNT_REQUESTING";
export const MY_LIBRARY_GET_TOTAL_COUNT_SUCCESS =
  "MY_LIBRARY_GET_TOTAL_COUNT_SUCCESS";
export const MY_LIBRARY_GET_TOTAL_COUNT_ERROR =
  "MY_LIBRARY_GET_TOTAL_COUNT_ERROR";

export const PREMIUM_GET_TOTAL_COUNT_REQUESTING =
  "PREMIUM_GET_TOTAL_COUNT_REQUESTING";
export const PREMIUM_GET_TOTAL_COUNT_SUCCESS =
  "PREMIUM_GET_TOTAL_COUNT_SUCCESS";
export const PREMIUM_GET_TOTAL_COUNT_ERROR = "PREMIUM_GET_TOTAL_COUNT_ERROR";

export const CREATE_NEW_STEP = "CREATE_NEW_STEP";

export const STEP_OVERRIDE = "STEP_OVERRIDE";

export const REMOVE_STEP_FROM_LIST = "REMOVE_STEP_FROM_LIST";

export const SELECT_STEP = "SELECT_STEP";

export const REORDER_ALL_STEPS = "REORDER_ALL_STEPS";

export const ADD_STEP_WITHOUT_SAVE = "ADD_STEP_WITHOUT_SAVE";

export const REMOVE_STEP_WITHOUT_SAVE = "REMOVE_STEP_WITHOUT_SAVE";

export const NAVIGATE_CREATE_TRACK = "createTrack/NAVIGATE_CREATE_TRACK";

export const CHANGE_STEP_SELECTED_INDEX =
  "createTrack/CHANGE_STEP_SELECTED_INDEX";

export const SEARCH_CHANGE = "createTrack/SEARCH_CHANGE";

export const MOVE_STEP_SELECTION = "createTrack/MOVE_STEP_SELECTION";

export const CLICK_ADMIN_AVATAR = "createTrack/CLICK_ADMIN_AVATAR";
