import React, { useRef } from "react";
import { Dialog } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ReactHlsPlayer from "react-hls-player";
import { useDispatch, useSelector } from "react-redux";
import { downloadFileRequest } from "services/upload/actions";
import { getIsExportingVideo } from "services/upload/selectors";
import Colors from "constants/Colors";
import { getIsSuperAdmin } from "services/user/selectors";

const useStyles = makeStyles(theme => ({
  download: {
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": { backgroundColor: Colors.hoverBlue },
  },
  video: {
    maxHeight: "80vh",
  },
}));

interface Props {
  isVisible: boolean;
  onClose: () => void;
  video: {
    fileUrl: string;
    fileType: string;
  };
}

const ModalPreviewVideo = ({ isVisible, onClose, video }: Props) => {
  const classes = useStyles();
  const { fileUrl } = video;
  const playerRef = useRef();

  const dispatch = useDispatch();

  const isSuperAdmin = useSelector(getIsSuperAdmin);

  const isExportingVideo = useSelector(getIsExportingVideo);

  const muxId = fileUrl.split("/").pop();
  const playbackId = muxId.split(".").shift();

  const handleDownloadVideo = e => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(
      downloadFileRequest({
        fileType: ".m3u8",
        fileName: "nice",
        urlName: fileUrl,
        isExportingVideo: true,
      }),
    );
  };

  return (
    <Dialog open={isVisible} onClose={onClose}>
      {isSuperAdmin && (
        <div className={classes.download} onClick={handleDownloadVideo}>
          {isExportingVideo ? "Downloading..." : "Download"}
        </div>
      )}
      <ReactHlsPlayer
        playerRef={playerRef}
        autoPlay={isVisible}
        controls
        src={fileUrl}
        hlsConfig={{
          controls: true,
        }}
        className={classes.video}
      />
    </Dialog>
  );
};

export default ModalPreviewVideo;
