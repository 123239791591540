import React from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography } from "@mui/material";
import i18n from "i18n";
import FeelingBar from "scenes/Campaigns/CampaignScreen/StatsSide/FeelingSection/FeelingBar";
import Colors from "constants/Colors";

const styles = theme => ({
  main: {
    boxSizing: "border-box",
    width: "100%",
    paddingRight: 45,
    paddingLeft: 45,
  },
  topDiv: {
    marginTop: 25,
    marginBottom: 35,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  firstTitle: {
    textAlign: "left",
  },
  secondTitle: {
    marginLeft: 50,
    textAlign: "left",
  },
  lastTitle: {
    textAlign: "right",
    flex: 1,
  },
});

const COLORS = [
  Colors.feeling1,
  Colors.feeling2,
  Colors.feeling3,
  Colors.feeling4,
];

const InitialFeelingChart = ({
  classes,
  stats = {},
  hasOnboardingSurvey = false,
}) => {
  const displayFeelingBar = () => {
    if (!hasOnboardingSurvey)
      return (
        <>
          <FeelingBar
            number={stats["publicSpeaking"]}
            color={Colors.feeling1}
            title={i18n.t("convince")}
          />
          <FeelingBar
            number={stats["confidence"]}
            color={Colors.feeling2}
            title={i18n.t("confidence")}
          />
          <FeelingBar
            number={stats["stress"]}
            color={Colors.feeling3}
            title={i18n.t("stress")}
          />
          <FeelingBar
            number={stats["teamCohesion"]}
            color={Colors.feeling4}
            title={i18n.t("cohesion")}
          />
        </>
      );

    return Object.keys(stats).map((key, index) => {
      if (index < 4) {
        return (
          <FeelingBar
            key={index}
            number={stats[key]}
            color={COLORS[index]}
            title={key}
          />
        );
      }
      return null;
    });
  };

  return (
    <div className={classes.main}>
      <div className={classes.topDiv}>
        <Typography className={classes.firstTitle}>
          {i18n.t("skill")}
        </Typography>
        <Typography className={classes.secondTitle}>
          {i18n.t("niv-current")}
        </Typography>
        <Typography className={classes.lastTitle}>
          {i18n.t("niv-max")}
        </Typography>
      </div>

      <div className={classes.bottomDiv}>{displayFeelingBar()}</div>
    </div>
  );
};

export default withStyles(styles)(InitialFeelingChart);
