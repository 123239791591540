import React from "react";

import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import MenuBookIcon from "@mui/icons-material/Book";

import Colors from "../../constants/Colors";
import getLabelColor from "../../utils/getLabelColor";

import i18n from "../../i18n";
import PeopleIcon from "./PeopleIcon";
import StarComponent from "./StarComponent";
import LightIcon from "../CreateTrack/LightIcon";
import ArchiveComponent from "scenes/Campaigns/ArchiveComponent";
import DeleteComponent from "scenes/Campaigns/DeleteComponent";
import PremiumComponent from "scenes/Campaigns/PremiumComponent";
import { useSelector } from "react-redux";
import { getIsSuperAdminClientSelected } from "services/client/selectors";
import CustomArrowTooltip from "components/CustomArrowTooltip";
import { getIsTrainerOrObserver } from "services/user/selectors";

export const DEFAULT_PICTURE =
  "https://pulse-experience.s3.eu-west-3.amazonaws.com/images/background.png";

const variants = {
  hidden: { opacity: 0, y: 30 },
  visible: { opacity: 1, y: 0 },
};

const styles = theme => ({
  main: {
    transition: "all 0.2s ease",
    width: "auto",
    flex: "0 0 auto",
    padding: "0px 1.5rem calc(2rem)",
  },
  box: {
    width: 300,
    position: "relative",
    boxShadow: "0 2px 4px rgba(0,0,0,.08)",
    display: "flex",
    height: 450,
    backgroundColor: "#fff",
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    cursor: "pointer",
    animation: "FadeIn 0.3s ease-in-out",
    transition: "all 0.2s cubic-bezier(0.41, 0.094, 0.54, 0.07) 0s",
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: "rgba(0, 0, 0, 0.07) 1px 7px 15px",
    },
  },
  bottomDiv: {
    zIndex: 5,
    height: 50,
    background: Colors.darkBlue,
    paddingTop: 1,
    paddingBottom: 1,
    width: "100%",
    borderTopWidth: 0,
    borderTop: "solid",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderTopColor: Colors.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  votesText: {
    fontSize: 14,
    alignSelf: "left",
    borderRadius: 8,
    color: "white",
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 8,
    paddingRight: 8,
  },
  pictureDiv: {
    position: "relative",
    height: 400,
    borderRadius: 8,
    width: "100%",
  },
  picture: {
    position: "relative",
    filter: "brightness(0.8)",
    flex: 1,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  textDiv: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    top: 50,
    left: 0,
    paddingLeft: 35,
    paddingRight: 35,
  },
  title: {
    fontFamily: "Montserrat",
    marginTop: 15,
    lineHeight: "24px",
    color: Colors.white,
    fontSize: 20,
    fontWeight: 700,
  },
  description: {
    fontFamily: "Montserrat",
    marginTop: 10,
    fontSize: 16,
    color: Colors.white,
  },
  label: {
    color: "#fff",
    fontSize: 16,
    fontWeight: 500,
  },
  labelDiv: {
    position: "absolute",
    zIndex: 20,
    top: -5,
    left: -5,
    borderRadius: 6,
    backgroundColor: Colors.pink,
    padding: "3px 10px",
  },
  delete: {
    color: Colors.red,
  },
  status: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "fit-content",
    backgroundColor: "#3CC926",
    borderRadius: 3,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 13,
    paddingRight: 18,
    marginBottom: 10,
  },
  statusText: {
    marginLeft: 10,
    color: "white",
    fontWeight: 700,
    fontFamily: "Roboto",
  },
  date: {
    fontSize: "1rem",
    fontWeight: 400,
    color: "white",
    fontFamily: "Roboto",
  },
  firstBottomDiv: {
    paddingLeft: 15,
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  toolTip: {
    borderRadius: 4,
    paddingRight: 10,
    paddingLeft: 10,
    fontSize: 12,
    fontFamily: "Roboto",
  },
  iconDiv: {
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  hoveredBox: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    width: "100%",
    top: 0,
    height: "100%",
    opacity: 0,
    zIndex: 100,
    position: "absolute",
    transition: "all 100ms ease",
    "&:hover": {
      opacity: 1,
      backgroundColor: "rgba(31, 31, 31, 0.8)",
    },
  },
  hoverCampaignText: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    color: "#fff",
    fontWeight: 400,
    textAlign: "center",
    fontSize: 20,
    transition: "11.4s opacity ease-in-out",
    transitionProperty: "opacity",
    transitionDuration: "11.4s",
    transitionTimingFunction: "ease-in-out",
    transitionDelay: "0s",
  },
  arrowAnimate: {
    display: "inline-block",
    height: "10px",
    backgroundPosition: "-40px -40px",
    width: "6px",
    fontSize: 20,
    marginLeft: 10,
    animationDuration: "1s",
    animationTimingFunction: "ease",
    animationDelay: "0s",
    animationIterationCount: "infinite",
    animationDirection: "normal",
    animationFillMode: "none",
    animationPlayState: "running",
    animationName: "wf-animate-right-arrow",
    animation: "paint 10s ease-in-out infinite",
    backgroundImage:
      "url(https://pulse-experience.s3.eu-west-3.amazonaws.com/images/sprite-dashboard.png)",
    backgroundSize: 520,
  },
  "@keyframes wf-animate-right-arrow": {
    "0%": {
      transform: "translateX(5px)",
      opacity: 1,
    },
    "100%": {
      transform: "translateX(16px)",
      opacity: 0,
    },
  },
  "@keyframes myEffectExit": {
    "0%": {
      opacity: 1,
      transform: "translateY(0)",
    },
    "100%": {
      opacity: 0,
      transform: "translateY(-200%)",
    },
  },
  secondBottomDiv: {},
  "@media (max-width: 1024px)": {
    main: { width: "50%" },
  },
});

const CampaignBox = ({
  classes,
  onClick,
  trackId,
  campaignId,
  onClickMore,
  onAddTemplate,
  onClickDelete,
  onClickModify,
  onClickArchive,
  onAddPremiumDemo = () => () => null,
  title,
  description,
  picture,
  participants,
  label,
  showSteps,
  settingsDisabled,
  steps,
  createdAt,
  endDate,
  startDate,
  boxClassName,
  isTemplate,
  isPrivate = true,
  isPremiumDemo = false,
  status = { icon: () => <div />, color: Colors.pureMain, text: "current" },
  isDone = false,
  totalDone = 0,
  hasDelete = true,
  hasArchive = false,
  hasAddTemplate = true,
  hoverText = "",
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isSuperAdminSelected = useSelector(getIsSuperAdminClientSelected);
  const isModifyForbidden = useSelector(getIsTrainerOrObserver);

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleDelete = event => {
    event.stopPropagation();
    onClickDelete();
    setAnchorEl(null);
  };

  const onClickAddTemplate = event => {
    event.stopPropagation();
    if (isPrivate) {
      onAddTemplate({ trackId, isTemplate: !isTemplate });
    }
  };

  const onClickAddPremiumDemo = event => {
    event.stopPropagation();
    onAddPremiumDemo({ trackId, isPremiumDemo: !isPremiumDemo, campaignId });
  };

  const onArchive = event => {
    event.stopPropagation();
    onClickArchive(campaignId)(isDone);
  };

  return (
    <div
      initial="hidden"
      animate="visible"
      transition={{ duration: 0.2 }}
      variants={variants}
      className={classes.main}
    >
      <div
        onClick={onClick}
        className={`${classes.box} ${boxClassName}`}
        data-intercom-target="First Campaign"
      >
        {label && (
          <div
            className={classes.labelDiv}
            style={{ backgroundColor: getLabelColor(label) }}
          >
            <Typography className={classes.label}>{i18n.t(label)}</Typography>
          </div>
        )}
        <div className={classes.pictureDiv}>
          <img
            alt=""
            src={picture || DEFAULT_PICTURE}
            className={classes.picture}
          />
          {hoverText && (
            <div className={classes.hoveredBox}>
              <Typography className={classes.hoverCampaignText}>
                {hoverText}
                <i className={classes.arrowAnimate}></i>
              </Typography>
            </div>
          )}
        </div>
        <div className={classes.textDiv}>
          <div
            className={classes.status}
            style={{ backgroundColor: status?.color }}
          >
            {status?.icon && status?.icon()}
            <Typography className={classes.statusText}>
              {i18n.t(status?.text).toUpperCase()}
            </Typography>
          </div>
          <Typography className={classes.date}>
            {`${i18n.t("created")}
                  ${moment(createdAt).format("L")}`}
          </Typography>
          <Typography className={classes.title}>{title}</Typography>
          <Typography className={classes.description}>{description}</Typography>
        </div>
        <div className={classes.bottomDiv}>
          <div className={classes.firstBottomDiv}>
            {totalDone > 0 && (
              <CustomArrowTooltip title={i18n.t("total-done")}>
                <div className={classes.iconDiv}>
                  <LightIcon color={"#FFC41F"} />
                  <Typography
                    className={classes.votesText}
                    style={{ marginRight: 5 }}
                  >
                    {totalDone}
                  </Typography>
                </div>
              </CustomArrowTooltip>
            )}
            {showSteps && (
              <div className={classes.iconDiv}>
                <MenuBookIcon
                  color="white"
                  style={{ color: "#fff", marginLeft: 0 }}
                />
                <Typography className={classes.votesText}>
                  {i18n.t("steps", { steps: steps.length })}
                </Typography>
              </div>
            )}
            {!showSteps && (
              <CustomArrowTooltip title={i18n.t("total-participant")}>
                <div className={classes.iconDiv}>
                  <PeopleIcon />
                  <Typography className={classes.votesText}>
                    {participants.length}
                  </Typography>
                </div>
              </CustomArrowTooltip>
            )}
          </div>
          <div className={classes.secondBottomDiv}>
            {hasDelete && !isModifyForbidden && (
              <DeleteComponent
                disabled={settingsDisabled}
                style={{ marginRight: hasArchive ? -8 : 0 }}
                onClick={handleDelete}
              />
            )}
            {hasArchive && !isModifyForbidden && (
              <ArchiveComponent
                isDone={isDone}
                onClick={onArchive}
                style={{ marginRight: hasAddTemplate ? -12 : 0 }}
              />
            )}
            {isSuperAdminSelected && (
              <PremiumComponent
                disabled={settingsDisabled}
                onClick={onClickAddPremiumDemo}
                isPremiumDemo={isPremiumDemo}
              />
            )}
            {hasAddTemplate && !isModifyForbidden && (
              <StarComponent
                disabled={settingsDisabled}
                onClick={onClickAddTemplate}
                isTemplate={isTemplate}
              />
            )}
          </div>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem className={classes.delete} onClick={handleDelete}>
              {i18n.t("delete")}
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
};

CampaignBox.defaultProps = {
  settingsDisabled: false,
  onAddTemplate: () => null,
  onClickDelete: () => null,
};

export default withStyles(styles)(CampaignBox);
