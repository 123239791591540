import React from "react";

const SelectedIcon = ({ sizePercentage, color }) => (
  <svg
    width={sizePercentage * 32}
    height={sizePercentage * 32}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 32 32"
    version="1.1"
  >
    <circle cx="15.9187" cy="15.9119" r="15.3494" fill={color} />
    <path
      d="M12.7191 20.2123L8.51914 16.0123L7.11914 17.4123L12.7191 23.0123L24.7191 11.0123L23.3191 9.6123L12.7191 20.2123Z"
      fill="white"
    />
  </svg>
);

SelectedIcon.defaultProps = {
  sizePercentage: 1,
  color: "#00A7FC",
};

export default SelectedIcon;
