import React, { useCallback, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Modal from "components/Modal";
import { getCoachingFiles } from "services/coaching/selectors";
import { useDispatch, useSelector } from "react-redux";
import File from "./File";
import { getUserId } from "services/user/selectors";
import Fuse from "fuse.js";
import { downloadFileRequest } from "services/upload/actions";
import slugify from "slugify";
import PdfUploader from "../Resources/NewResourceModal/PdfUploader";
import { getUserSelectedId } from "services/manager/selectors";
import {
  createCoachingFileRequest,
  deleteCoachingFilesRequest,
  getCoachingFilesRequest,
} from "services/coaching/actions";
import { sortBy as lodashSortBy } from "lodash";
import DeleteConfirmModal from "components/DeleteConfirmModal";
import i18n from "i18n";
import SearchBar from "components/SearchBar";
import FlatButton from "components/FlatButton";
import TableHeader from "components/TableHeader";
import UploaderSnack from "./UploaderSnack";

// 50mo
const FILE_MAX_SIZE = 1048576 * 50;

const fuseOptions = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 80,
  maxPatternLength: 20,
  minMatchCharLength: 1,
  keys: ["fileName"],
};

const ImportIcon = ({ className }) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    className={className}
  >
    <path
      d="M14.0458 13.2896C14.2686 13.2896 14.4831 13.3528 14.6459 13.4663C14.8087 13.5799 14.9077 13.7353 14.9229 13.9012C14.9381 14.0671 14.8683 14.2311 14.7277 14.3601C14.5871 14.4891 14.3862 14.5735 14.1654 14.5961L14.0458 14.6022H1.74142C1.51862 14.6022 1.30414 14.539 1.14134 14.4255C0.978535 14.312 0.879538 14.1566 0.864351 13.9907C0.849165 13.8247 0.918922 13.6607 1.05953 13.5317C1.20013 13.4027 1.4011 13.3184 1.62183 13.2957L1.74142 13.2896H14.0458Z"
      fill="white"
    />
    <path
      d="M8.33052 12.2569C8.21118 12.3611 8.05815 12.4186 7.8997 12.4189L7.89883 12.4197C7.72476 12.4197 7.55781 12.3506 7.43473 12.2275C7.31164 12.1044 7.24249 11.9375 7.24249 11.7634V2.84603L4.64079 5.44248L4.56728 5.50637C4.43409 5.60537 4.26833 5.65024 4.10339 5.63195C3.93844 5.61365 3.78655 5.53355 3.67829 5.40776C3.57002 5.28197 3.51344 5.11985 3.51991 4.95401C3.52638 4.78818 3.59544 4.63096 3.71317 4.51399L7.4674 0.764146C7.53317 0.706624 7.61 0.663147 7.69317 0.636379L7.76318 0.616252C7.80663 0.607016 7.85091 0.602324 7.89533 0.60225L7.94783 0.604L8.00909 0.611001L8.1141 0.637255L8.19374 0.670508L8.22524 0.688886L8.30575 0.740518L8.36701 0.79565L12.0801 4.51487L12.1431 4.58838C12.2367 4.71487 12.2818 4.8708 12.2701 5.02774C12.2583 5.18468 12.1906 5.33218 12.0792 5.44336L12.0048 5.50637C11.8783 5.59964 11.7225 5.64441 11.5658 5.63252C11.409 5.62064 11.2618 5.5529 11.1507 5.44161L8.55516 2.84253V11.7625L8.54903 11.8518C8.52748 12.0088 8.44987 12.1527 8.33052 12.2569Z"
      fill="white"
    />
  </svg>
);

const useStyles = makeStyles(theme => ({
  main: {
    minWidth: 800,
    marginRight: 30,
    overflow: "hidden",
  },
  modal: {
    paddingTop: 5,
    paddingLeft: 20,
    overflow: "hidden",
  },
  subtitle: {
    color: "#959EA8",
    marginBottom: 10,
    marginTop: -5,
  },
  topDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: 30,
  },
  searchBar: {
    backgroundColor: "#fff",
    border: "1px solid #D1D1D1",
    color: "#282828",
    flex: 1,
    width: "100%",
  },
  importButton: {
    marginLeft: 50,
    height: 40,
  },
  icon: {
    marginRight: 15,
  },
  uploader: {},
  table: {
    paddingRight: 25,
    height: 500,
    overflow: "scroll",
    paddingBottom: 80,
  },
  emptyText: {
    height: "100%",
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    alignSelf: "center",
    textAlign: "center",
    color: "#A8A8A8",
    fontSize: 18,
    transition: "all 0.2s ease",
  },
}));

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

const CoachingFilesModal = ({ isVisible, onClose }: Props) => {
  const [fileToDelete, setFileToDelete] = useState("");
  const [isSortedDesc, setIsSortedDesc] = useState(true);
  const [sortedBy, setSortedBy] = useState("createdAt");
  const [search, setSearch] = useState("");

  const classes = useStyles();
  const coachingFiles = useSelector(getCoachingFiles);
  const coachId = useSelector(getUserId);
  const userId = useSelector(getUserSelectedId);

  const dispatch = useDispatch();

  const onLoadCoachFiles = useCallback(() => {
    if (isVisible) {
      console.log({ isVisible });
      dispatch(getCoachingFilesRequest({ coachId, userId }));
    }
  }, [dispatch, coachId, isVisible, userId]);

  useEffect(onLoadCoachFiles, [isVisible]);

  const onFileUploaded = async files => {
    const date = new Date().toISOString().toString();
    const file = files[0];
    if (!file) {
      return;
    }
    const { name = "" } = file ? file : { name: "" };
    const fileType = name.split(".").pop();
    const fileName = name.split(".").shift();

    await dispatch(
      createCoachingFileRequest({
        fileType,
        fileName,
        urlName: slugify(`file/coach/${coachId}/${date}`),
        file: files[0],
        userId,
        coachId,
      }),
    );
  };

  const onClickDownload = ({ fileType, fileName, urlName }) => e => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(downloadFileRequest({ fileType, fileName, urlName }));
  };

  const closeDeleteModal = () => {
    setFileToDelete("");
  };

  const onClickDelete = coachingFileId => e => {
    e.preventDefault();
    e.stopPropagation();

    setFileToDelete(coachingFileId);
  };

  const onConfirmDelete = () => {
    const coachingFileId = fileToDelete;

    dispatch(deleteCoachingFilesRequest({ coachingFileId }));
    setFileToDelete("");
  };

  const onChangeSearch = searchValue => {
    setSearch(searchValue);
  };

  const changeSortingParam = param => () => {
    setSortedBy(param);
    setIsSortedDesc(!isSortedDesc);
  };

  const sortBy = files => {
    if (isSortedDesc) {
      return lodashSortBy(files, sortedBy).reverse();
    }

    return lodashSortBy(files, sortedBy);
  };

  const fuse = new Fuse(coachingFiles, fuseOptions);
  const searchResults = search.length > 0 ? fuse.search(search) : coachingFiles;

  return (
    <Modal
      className={classes.modal}
      title={i18n.t("coaching-file-safe")}
      isVisible={isVisible}
      onClose={onClose}
    >
      <>
        <DeleteConfirmModal
          onClose={closeDeleteModal}
          isVisible={Boolean(fileToDelete)}
          title={i18n.t("delete-confirmation-modal-title")}
          onClickConfirm={onConfirmDelete}
        />
        <div className={classes.main}>
          <Typography className={classes.subtitle}>
            {i18n.t("coaching-file-subtitle")}
          </Typography>
          <div className={classes.topDiv}>
            <SearchBar
              value={search}
              placeholder={i18n.t("search-file")}
              inputClassName={classes.searchBar}
              onChangeSearch={onChangeSearch}
            />
            <div className={classes.uploader}>
              <PdfUploader
                onFileUploaded={onFileUploaded}
                maxSize={FILE_MAX_SIZE}
                hasButton
              >
                <FlatButton
                  onClick={null}
                  icon={<ImportIcon className={classes.icon} />}
                  className={classes.importButton}
                  title={i18n.t("import-file-button")}
                />
              </PdfUploader>
            </div>
          </div>
          <TableHeader
            sortedBy={sortedBy}
            isSortedDesc={isSortedDesc}
            changeSortingParam={changeSortingParam}
          />
          <div className={classes.table}>
            {coachingFiles.length === 0 && (
              <Typography className={classes.emptyText}>
                {i18n.t("no-file-yet-click-on-download")}
              </Typography>
            )}
            {sortBy(searchResults).map((file, index) => (
              <File
                {...file}
                key={index}
                onClickDownload={onClickDownload}
                onClickDelete={onClickDelete(file._id)}
              />
            ))}
          </div>
          <UploaderSnack />
        </div>
      </>
    </Modal>
  );
};

export default CoachingFilesModal;
