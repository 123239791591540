import React from "react";
import { IconButton } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const CloseIcon = ({ sizePercentage, color = "black", className = "" }) => (
  <svg
    className={className}
    width={sizePercentage * 8}
    height={sizePercentage * 8}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 8 8"
  >
    <polygon
      fill={color}
      points="8 1.01818182 6.98181818 0 4 2.98181818 1.01818182 0 0 1.01818182 2.98181818 4 0 6.98181818 1.01818182 8 4 5.01818182 6.98181818 8 8 6.98181818 5.01818182 4"
    ></polygon>
  </svg>
);

const useStyles = makeStyles(theme => ({
  closeButton: {},
}));

interface Props {
  onClick: () => void;
  className?: string;
  color?: string;
}

const ExitButton = ({ onClick, className, color = "black" }: Props) => {
  const classes = useStyles();

  return (
    <IconButton
      className={`${classes.closeButton} ${className}`}
      onClick={onClick}
      size="large"
    >
      <CloseIcon sizePercentage={2} color={color} />
    </IconButton>
  );
};

export default ExitButton;
