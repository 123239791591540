import React from "react";

const CommentsIcon = ({ sizePercentage, color, className }) => (
  <svg
    className={className}
    width={sizePercentage * 16}
    height={sizePercentage * 14}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 16 14"
    version="1.1"
  >
    <path
      d="M14.4182 0.90918H1.75997C1.0157 0.90918 0.410156 1.51472 0.410156 2.25899V9.45799C0.410156 10.2023 1.0157 10.8078 1.75997 10.8078H3.13978V13.0575C3.13978 13.4295 3.56522 13.6383 3.85967 13.4174L7.33919 10.8078H14.4182C15.1625 10.8078 15.768 10.2023 15.768 9.45799V2.25899C15.768 1.51472 15.1625 0.90918 14.4182 0.90918ZM14.8681 9.45799C14.8681 9.70604 14.6663 9.90792 14.4182 9.90792H7.18921C7.09184 9.90792 6.99717 9.93956 6.91925 9.99791L4.03965 12.1576V10.3579C4.03965 10.1093 3.83823 9.90792 3.58971 9.90792H1.75997C1.51192 9.90792 1.31003 9.70604 1.31003 9.45799V2.25899C1.31003 2.01094 1.51192 1.80905 1.75997 1.80905H14.4182C14.6663 1.80905 14.8681 2.01094 14.8681 2.25899V9.45799Z"
      fill={color}
    />
    <path
      d="M12.5886 3.60938H3.58983C3.34131 3.60938 3.13989 3.81079 3.13989 4.05931C3.13989 4.30783 3.34131 4.50925 3.58983 4.50925H12.5886C12.8371 4.50925 13.0385 4.30783 13.0385 4.05931C13.0385 3.81079 12.8371 3.60938 12.5886 3.60938Z"
      fill={color}
    />
    <path
      d="M9.43901 5.85863C9.43901 5.61011 9.2376 5.40869 8.98908 5.40869H3.58983C3.34131 5.40869 3.13989 5.61011 3.13989 5.85863C3.13989 6.10715 3.34131 6.30857 3.58983 6.30857H8.98908C9.2376 6.30857 9.43901 6.10715 9.43901 5.85863Z"
      fill={color}
    />
    <path
      d="M12.5886 5.40869H10.7888C10.5403 5.40869 10.3389 5.61011 10.3389 5.85863C10.3389 6.10715 10.5403 6.30857 10.7888 6.30857H12.5886C12.8371 6.30857 13.0385 6.10715 13.0385 5.85863C13.0385 5.61011 12.8371 5.40869 12.5886 5.40869Z"
      fill={color}
    />
    <path
      d="M6.28945 7.2085H3.58983C3.34131 7.2085 3.13989 7.40991 3.13989 7.65843C3.13989 7.90695 3.34131 8.10837 3.58983 8.10837H6.28945C6.53797 8.10837 6.73939 7.90695 6.73939 7.65843C6.73939 7.40991 6.53797 7.2085 6.28945 7.2085Z"
      fill={color}
    />
    <path
      d="M12.5885 7.2085H8.0891C7.84058 7.2085 7.63916 7.40991 7.63916 7.65843C7.63916 7.90695 7.84058 8.10837 8.0891 8.10837H12.5885C12.837 8.10837 13.0384 7.90695 13.0384 7.65843C13.0384 7.40991 12.837 7.2085 12.5885 7.2085Z"
      fill={color}
    />
  </svg>
);

CommentsIcon.defaultProps = {
  sizePercentage: 1,
  color: "black",
  className: "",
};

export default CommentsIcon;
