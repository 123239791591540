import React from "react";

const ReviewValidatedIcon = ({
  sizePercentage,
  color = "#8CCC2C",
  className,
  style = {},
}) => (
  <svg
    className={className}
    style={style}
    width={sizePercentage * 24}
    height={sizePercentage * 15}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 15"
    version="1.1"
  >
    <path
      d="M17.0395 0.269803C16.9102 0.167415 16.7619 0.0916514 16.6031 0.0468856C16.4444 0.00211977 16.2783 -0.0107624 16.1145 0.00898232C15.9508 0.028727 15.7925 0.0807079 15.649 0.161925C15.5054 0.243142 15.3793 0.351987 15.278 0.48218L6.53312 11.7257L2.2481 6.50369C2.14759 6.36989 2.02123 6.25766 1.87651 6.17364C1.73179 6.08962 1.57166 6.03553 1.40564 6.01459C1.23961 5.99365 1.07107 6.00627 0.91002 6.05171C0.748968 6.09716 0.598687 6.17449 0.468098 6.27913C0.337508 6.38377 0.229272 6.51358 0.149814 6.66085C0.0703564 6.80812 0.0212963 6.96986 0.00554594 7.13646C-0.0102044 7.30306 0.00767634 7.47112 0.0581264 7.63067C0.108576 7.79023 0.190567 7.93802 0.299233 8.06528L5.50871 14.5365C5.62629 14.6815 5.77485 14.7982 5.94348 14.8782C6.1121 14.9582 6.29651 14.9994 6.48315 14.9988C6.68125 15.0076 6.87862 14.9692 7.05892 14.8866C7.23923 14.8041 7.39729 14.6798 7.52004 14.524L17.3019 2.03128C17.4016 1.89889 17.474 1.74798 17.5148 1.58733C17.5556 1.42669 17.5641 1.25953 17.5397 1.09559C17.5153 0.931647 17.4585 0.774203 17.3726 0.632429C17.2867 0.490655 17.1735 0.367386 17.0395 0.269803ZM23.2859 0.269803C23.1566 0.167415 23.0082 0.0916514 22.8495 0.0468856C22.6907 0.00211977 22.5247 -0.0107624 22.3609 0.00898232C22.1971 0.028727 22.0389 0.0807079 21.8953 0.161925C21.7518 0.243142 21.6257 0.351987 21.5244 0.48218L12.7795 11.7257L12.0174 10.7887L10.4433 12.8125L11.8176 14.524C11.9351 14.669 12.0837 14.7857 12.2523 14.8657C12.4209 14.9457 12.6054 14.9869 12.792 14.9863C12.9796 14.9854 13.1647 14.9423 13.3334 14.8601C13.5021 14.7779 13.6501 14.6588 13.7664 14.5115L23.5482 2.01879C23.646 1.88678 23.7168 1.73679 23.7565 1.5774C23.7963 1.418 23.8042 1.25234 23.7798 1.08988C23.7555 0.927427 23.6993 0.771373 23.6145 0.630653C23.5298 0.489932 23.4181 0.367309 23.2859 0.269803Z"
      fill={color}
    />
    <path
      d="M7.15769 8.82736L8.76926 6.80354L8.5194 6.50371C8.42077 6.36707 8.29552 6.25179 8.15117 6.16482C8.00683 6.07785 7.84638 6.02098 7.67948 5.99764C7.51258 5.9743 7.34269 5.98496 7.18002 6.02899C7.01735 6.07303 6.86527 6.14952 6.73294 6.25386C6.60461 6.35695 6.49791 6.48439 6.41899 6.62885C6.34007 6.77332 6.29048 6.93195 6.27306 7.09564C6.25565 7.25933 6.27076 7.42485 6.31752 7.58268C6.36428 7.74051 6.44178 7.88755 6.54555 8.01533L7.15769 8.82736Z"
      fill={color}
    />
  </svg>
);

export default ReviewValidatedIcon;
