import React from "react";
import { IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import displayDate from "utils/displayDate";
import DeleteIcon from "scenes/Campaigns/DeleteIcon";
import DownloadIcon from "components/DownloadIcon";
import FileIcon from "./FileIcon";
import Colors from "constants/Colors";
import useHover from "hooks/useHover";

const ROW_HEIGHT = 60;

const FILE_ICON_HEIGHT = 20;

const useStyles = makeStyles(theme => ({
  main: {
    height: ROW_HEIGHT,
    borderBottom: "1px solid #F1F1F1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    transition: "all 0.2s ease",
    "&:hover": {},
  },
  fileIcon: {
    height: FILE_ICON_HEIGHT,
    width: FILE_ICON_HEIGHT,
    minWidth: FILE_ICON_HEIGHT,
    borderRadius: 6,
    borderWidth: "1px solid #ffffff",
  },
  fileName: {
    "-webkit-line-clamp": 1 /* number of lines to show */,
    lineHeight: "1.5em" /* fallback */,
    maxHeight: "1.5em",
    wordBreak: "break-all",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#282828",
    fontSize: 16,
    maxWidth: "100%",
    marginLeft: 12,
    marginRight: 30,
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": { textDecoration: "underline" },
  },
  date: {
    overflow: "hidden",
    lineHeight: "1.5em" /* fallback */,
    maxHeight: "1.5em",
    wordBreak: "break-all",
    textOverflow: "ellipsis",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 1,
    display: "flex",
    flex: 1,
  },
  firstRow: {
    flex: 1.5,
    display: "flex",
    flexDirection: "row",
  },
  fileType: {
    overflow: "hidden",
    lineHeight: "1.5em" /* fallback */,
    maxHeight: "1.5em",
    wordBreak: "break-all",
    textOverflow: "ellipsis",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 1,
    display: "flex",
    flex: 1,
    fontSize: 16,
    color: "#282828",
  },
  emptyRow: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row",
    flex: 0.5,
  },
  deleteIcon: {
    width: 30,
    height: 30,
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: 2,
    marginRight: 10,
    transition: "all 0.2s ease",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#F2F2F2",
    },
  },
  downloadIcon: {
    cursor: "pointer",
    transition: "all 0.2s ease",
    backgroundColor: "#fff",
    "&:hover": { opacity: 1, backgroundColor: "#F4FBFF" },
    width: 30,
    height: 30,
    padding: 0,
    borderRadius: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
}));

interface Props {
  userId?: string;
  coachId?: string;
  isTagged?: boolean;
  url: string;
  fileName: string;
  isRemoved?: boolean;
  fileType?: string;
  createdAt?: string;
  isFavorite?: boolean;
  _id?: string;
  onClickDelete: () => void;
  onClickDownload: ({ fileType, fileName, urlName }) => () => void;
}

const File = ({
  fileType,
  fileName,
  createdAt,
  url,
  _id,
  onClickDelete,
  onClickDownload,
}: Props) => {
  const [isHovered, hoverProperties] = useHover();
  const classes = useStyles();

  const handleClickFile = e => {
    e.preventDefault();
    e.stopPropagation();

    window.open(url);
  };

  return (
    <div {...hoverProperties} className={classes.main}>
      <div className={classes.firstRow}>
        <div className={classes.fileIcon}>
          <FileIcon fileType={fileType} />
        </div>
        <Typography onClick={handleClickFile} className={classes.fileName}>
          {fileName}
        </Typography>
      </div>
      <Typography className={classes.fileType}>{fileType}</Typography>
      <Typography className={classes.date}>
        {displayDate(createdAt, "Europe/Paris")}
      </Typography>
      <div className={classes.emptyRow}>
        {isHovered && (
          <>
            <IconButton
              className={classes.deleteIcon}
              onClick={onClickDelete}
              size="large"
            >
              <DeleteIcon color={"#6B6B6B"} sizePercentage={1.1} />
            </IconButton>
            <IconButton
              className={classes.downloadIcon}
              onClick={onClickDownload({ fileType, fileName, urlName: url })}
              size="large"
            >
              <DownloadIcon color={Colors.pureMain} />
            </IconButton>
          </>
        )}
      </div>
    </div>
  );
};

export default File;
