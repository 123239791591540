import React from "react";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CheckIcon from "components/CheckIcon";
import Colors from "constants/Colors";

// const CheckIcon <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
// <path d="M6.01113 10.8288L1.81113 6.62876L0.411133 8.02876L6.01113 13.6288L18.0111 1.62876L16.6111 0.22876L6.01113 10.8288Z" fill="#5FB8F9"/>
// </svg>

const useStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    width: "100%",
    marginBottom: 5,
  },
  text: {
    color: "#E2E2E2",
    marginLeft: 15,
  },
  textChecked: {
    color: "rgba(75, 75, 75, 1)",
    fontWeight: 550,
  },
}));

interface Props {
  isChecked: boolean;
  info: string;
}

const InfoCheck = ({ isChecked, info }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <CheckIcon color={isChecked ? Colors.pureMain : "#E2E2E2"} />
      <Typography
        className={`${classes.text} ${isChecked && classes.textChecked}`}
      >
        {info}
      </Typography>
    </div>
  );
};

export default InfoCheck;
