import React from "react";

const MicrodoingLogo = ({ sizePercentage }) => (
  <svg
    width={sizePercentage * 103}
    height={sizePercentage * 17}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 103 17"
    version="1.1"
    fill="none"
  >
    <path
      d="M11.2355 13.1705L11.2191 6.25955L7.8294 11.9529H6.62821L3.25501 6.40764V13.1705H0.753906V1.65226H2.95883L7.26994 8.81002L11.5152 1.65226H13.7037L13.7366 13.1705H11.2355Z"
      fill="white"
    />
    <path
      d="M16.2884 4.31791H18.8553V13.1705H16.2884V4.31791ZM17.5718 3.08381C17.1001 3.08381 16.7162 2.94669 16.42 2.67244C16.1238 2.3982 15.9757 2.05814 15.9757 1.65226C15.9757 1.24638 16.1238 0.906313 16.42 0.632069C16.7162 0.357825 17.1001 0.220703 17.5718 0.220703C18.0435 0.220703 18.4275 0.35234 18.7236 0.615615C19.0198 0.878889 19.1679 1.20798 19.1679 1.60289C19.1679 2.03071 19.0198 2.38723 18.7236 2.67244C18.4275 2.94669 18.0435 3.08381 17.5718 3.08381Z"
      fill="white"
    />
    <path
      d="M25.5158 13.3021C24.5724 13.3021 23.7223 13.1102 22.9653 12.7262C22.2194 12.3313 21.6325 11.7883 21.2047 11.0972C20.7878 10.4061 20.5794 9.62178 20.5794 8.7442C20.5794 7.86662 20.7878 7.08228 21.2047 6.39119C21.6325 5.70009 22.2194 5.16258 22.9653 4.77864C23.7223 4.38372 24.5724 4.18627 25.5158 4.18627C26.4482 4.18627 27.26 4.38372 27.9511 4.77864C28.6532 5.16258 29.1633 5.71655 29.4814 6.44055L27.4904 7.5101C27.0296 6.69834 26.366 6.29246 25.4994 6.29246C24.8302 6.29246 24.2762 6.51186 23.8374 6.95065C23.3986 7.38944 23.1792 7.98729 23.1792 8.7442C23.1792 9.50111 23.3986 10.099 23.8374 10.5378C24.2762 10.9765 24.8302 11.1959 25.4994 11.1959C26.3769 11.1959 27.0406 10.7901 27.4904 9.9783L29.4814 11.0643C29.1633 11.7664 28.6532 12.3149 27.9511 12.7098C27.26 13.1047 26.4482 13.3021 25.5158 13.3021Z"
      fill="white"
    />
    <path
      d="M33.4111 5.48618C33.7182 5.05836 34.1296 4.73476 34.6452 4.51536C35.1717 4.29597 35.7751 4.18627 36.4552 4.18627V6.55574C36.17 6.5338 35.978 6.52283 35.8793 6.52283C35.1443 6.52283 34.5684 6.73125 34.1515 7.1481C33.7347 7.55398 33.5263 8.16829 33.5263 8.99102V13.1705H30.9593V4.31791H33.4111V5.48618Z"
      fill="white"
    />
    <path
      d="M42.1135 13.3021C41.1811 13.3021 40.3419 13.1102 39.596 12.7262C38.861 12.3313 38.2851 11.7883 37.8682 11.0972C37.4514 10.4061 37.2429 9.62178 37.2429 8.7442C37.2429 7.86662 37.4514 7.08228 37.8682 6.39119C38.2851 5.70009 38.861 5.16258 39.596 4.77864C40.3419 4.38372 41.1811 4.18627 42.1135 4.18627C43.0459 4.18627 43.8796 4.38372 44.6146 4.77864C45.3496 5.16258 45.9255 5.70009 46.3424 6.39119C46.7592 7.08228 46.9676 7.86662 46.9676 8.7442C46.9676 9.62178 46.7592 10.4061 46.3424 11.0972C45.9255 11.7883 45.3496 12.3313 44.6146 12.7262C43.8796 13.1102 43.0459 13.3021 42.1135 13.3021ZM42.1135 11.1959C42.7717 11.1959 43.3092 10.9765 43.7261 10.5378C44.1539 10.088 44.3678 9.49014 44.3678 8.7442C44.3678 7.99826 44.1539 7.40589 43.7261 6.9671C43.3092 6.51734 42.7717 6.29246 42.1135 6.29246C41.4553 6.29246 40.9123 6.51734 40.4845 6.9671C40.0567 7.40589 39.8428 7.99826 39.8428 8.7442C39.8428 9.49014 40.0567 10.088 40.4845 10.5378C40.9123 10.9765 41.4553 11.1959 42.1135 11.1959Z"
      fill="white"
    />
    <path
      d="M57.0259 0.961162V13.1705H55.907V11.4592C55.556 12.0406 55.0897 12.4849 54.5083 12.792C53.9379 13.0992 53.2907 13.2528 52.5667 13.2528C51.7549 13.2528 51.02 13.0663 50.3618 12.6933C49.7036 12.3203 49.188 11.7993 48.815 11.1301C48.4421 10.461 48.2556 9.69857 48.2556 8.84293C48.2556 7.98729 48.4421 7.22489 48.815 6.55574C49.188 5.88658 49.7036 5.371 50.3618 5.009C51.02 4.63603 51.7549 4.44954 52.5667 4.44954C53.2688 4.44954 53.8995 4.59763 54.459 4.89382C55.0294 5.17903 55.4956 5.60137 55.8576 6.16082V0.961162H57.0259ZM52.6654 12.2161C53.2688 12.2161 53.8118 12.079 54.2944 11.8048C54.7881 11.5195 55.172 11.1192 55.4463 10.6036C55.7315 10.088 55.8741 9.50111 55.8741 8.84293C55.8741 8.18474 55.7315 7.59786 55.4463 7.08228C55.172 6.56671 54.7881 6.17179 54.2944 5.89755C53.8118 5.61234 53.2688 5.46973 52.6654 5.46973C52.0511 5.46973 51.4972 5.61234 51.0035 5.89755C50.5208 6.17179 50.1369 6.56671 49.8517 7.08228C49.5774 7.59786 49.4403 8.18474 49.4403 8.84293C49.4403 9.50111 49.5774 10.088 49.8517 10.6036C50.1369 11.1192 50.5208 11.5195 51.0035 11.8048C51.4972 12.079 52.0511 12.2161 52.6654 12.2161Z"
      fill="white"
    />
    <path
      d="M63.8173 13.2528C62.9836 13.2528 62.2322 13.0663 61.563 12.6933C60.8939 12.3094 60.3673 11.7828 59.9834 11.1137C59.5994 10.4445 59.4075 9.6876 59.4075 8.84293C59.4075 7.99826 59.5994 7.24135 59.9834 6.57219C60.3673 5.90304 60.8939 5.38197 61.563 5.009C62.2322 4.63603 62.9836 4.44954 63.8173 4.44954C64.651 4.44954 65.4024 4.63603 66.0716 5.009C66.7407 5.38197 67.2618 5.90304 67.6348 6.57219C68.0187 7.24135 68.2107 7.99826 68.2107 8.84293C68.2107 9.6876 68.0187 10.4445 67.6348 11.1137C67.2618 11.7828 66.7407 12.3094 66.0716 12.6933C65.4024 13.0663 64.651 13.2528 63.8173 13.2528ZM63.8173 12.2161C64.4316 12.2161 64.9801 12.079 65.4628 11.8048C65.9564 11.5195 66.3403 11.1192 66.6146 10.6036C66.8888 10.088 67.0259 9.50111 67.0259 8.84293C67.0259 8.18474 66.8888 7.59786 66.6146 7.08228C66.3403 6.56671 65.9564 6.17179 65.4628 5.89755C64.9801 5.61234 64.4316 5.46973 63.8173 5.46973C63.203 5.46973 62.649 5.61234 62.1554 5.89755C61.6727 6.17179 61.2888 6.56671 61.0036 7.08228C60.7293 7.59786 60.5922 8.18474 60.5922 8.84293C60.5922 9.50111 60.7293 10.088 61.0036 10.6036C61.2888 11.1192 61.6727 11.5195 62.1554 11.8048C62.649 12.079 63.203 12.2161 63.8173 12.2161Z"
      fill="white"
    />
    <path
      d="M70.5958 4.51536H71.7641V13.1705H70.5958V4.51536ZM71.1882 2.62308C70.9469 2.62308 70.7439 2.54081 70.5794 2.37626C70.4148 2.21171 70.3326 2.01426 70.3326 1.78389C70.3326 1.5645 70.4148 1.37253 70.5794 1.20798C70.7439 1.04343 70.9469 0.961162 71.1882 0.961162C71.4295 0.961162 71.6325 1.04343 71.797 1.20798C71.9616 1.36156 72.0438 1.54804 72.0438 1.76744C72.0438 2.00877 71.9616 2.21171 71.797 2.37626C71.6325 2.54081 71.4295 2.62308 71.1882 2.62308Z"
      fill="white"
    />
    <path
      d="M79.4246 4.44954C80.5106 4.44954 81.3718 4.76767 82.008 5.40391C82.6552 6.02919 82.9788 6.94516 82.9788 8.15183V13.1705H81.8106V8.26702C81.8106 7.3675 81.5857 6.68189 81.1359 6.21019C80.6862 5.73849 80.0444 5.50264 79.2107 5.50264C78.2783 5.50264 77.5378 5.78237 76.9894 6.34183C76.4518 6.89031 76.1831 7.65271 76.1831 8.62902V13.1705H75.0148V4.51536H76.1337V6.11146C76.4518 5.58491 76.8906 5.17903 77.4501 4.89382C78.0205 4.59763 78.6787 4.44954 79.4246 4.44954Z"
      fill="white"
    />
    <path
      d="M94.1961 4.51536V12.1174C94.1961 13.5873 93.8341 14.6734 93.1101 15.3754C92.3971 16.0885 91.3166 16.445 89.8686 16.445C89.0678 16.445 88.3054 16.3243 87.5814 16.083C86.8683 15.8526 86.287 15.529 85.8372 15.1121L86.4296 14.2236C86.8464 14.5966 87.351 14.8873 87.9434 15.0957C88.5467 15.3041 89.1775 15.4083 89.8357 15.4083C90.9326 15.4083 91.7389 15.1505 92.2545 14.635C92.7701 14.1304 93.0279 13.3405 93.0279 12.2655V11.163C92.6659 11.7115 92.1887 12.1284 91.5963 12.4136C91.0149 12.6988 90.3677 12.8414 89.6547 12.8414C88.8429 12.8414 88.1024 12.6659 87.4333 12.3149C86.7751 11.9529 86.254 11.4537 85.8701 10.8175C85.4971 10.1703 85.3106 9.44078 85.3106 8.62902C85.3106 7.81726 85.4971 7.09325 85.8701 6.45701C86.254 5.82076 86.7751 5.32712 87.4333 4.97609C88.0915 4.62506 88.8319 4.44954 89.6547 4.44954C90.3896 4.44954 91.0533 4.59763 91.6457 4.89382C92.238 5.19 92.7152 5.61782 93.0772 6.17728V4.51536H94.1961ZM89.7698 11.8048C90.3951 11.8048 90.9601 11.6731 91.4647 11.4099C91.9693 11.1356 92.3587 10.7572 92.633 10.2745C92.9182 9.79181 93.0608 9.24332 93.0608 8.62902C93.0608 8.01471 92.9182 7.47171 92.633 7.00001C92.3587 6.51734 91.9693 6.14437 91.4647 5.8811C90.971 5.60685 90.4061 5.46973 89.7698 5.46973C89.1446 5.46973 88.5796 5.60137 88.075 5.86464C87.5814 6.12792 87.192 6.50089 86.9067 6.98356C86.6325 7.46622 86.4954 8.01471 86.4954 8.62902C86.4954 9.24332 86.6325 9.79181 86.9067 10.2745C87.192 10.7572 87.5814 11.1356 88.075 11.4099C88.5796 11.6731 89.1446 11.8048 89.7698 11.8048Z"
      fill="white"
    />
    <circle cx="100.376" cy="10.77" r="2.37744" fill="#5FB8F9" />
  </svg>
);

export default MicrodoingLogo;
