import {
  call,
  put,
  takeLatest,
  takeEvery,
  all,
  select,
  delay,
} from "redux-saga/effects";
import { push } from "connected-react-router";
import ObjectID from "bson-objectid";

import {
  STEP_CREATE_SUCCESS,
  STEP_CREATE_ERROR,
  STEP_CREATE_REQUESTING,
  TRACK_CREATE_REQUESTING,
  TRACK_CREATE_SUCCESS,
  TRACK_CREATE_ERROR,
  TRACK_MODIFY_REQUESTING,
  TRACK_MODIFY_ERROR,
  TRACK_MODIFY_SUCCESS,
  STEP_MODIFY_REQUESTING,
  STEP_MODIFY_ERROR,
  STEP_MODIFY_SUCCESS,
  REMOVE_STEP,
  ADD_STEP,
  SAVE_TRACK_REQUESTING,
  TRACK_CREATE_GET_REQUESTING,
  TRACK_CREATE_GET_ERROR,
  TRACK_CREATE_GET_SUCCESS,
  STEP_ADMIN_MODIFY_REQUESTING,
  STEP_ADMIN_MODIFY_ERROR,
  STEP_ADMIN_MODIFY_SUCCESS,
  STEP_ADMIN_CREATE_REQUESTING,
  STEP_ADMIN_CREATE_ERROR,
  STEP_ADMIN_CREATE_SUCCESS,
  CREATE_AND_MODIFY_TEMPLATE_REQUESTING,
  CREATE_AND_MODIFY_TEMPLATE_ERROR,
  CREATE_AND_MODIFY_TEMPLATE_SUCCESS,
  STEP_ADMIN_DELETE_REQUESTING,
  STEP_ADMIN_DELETE_ERROR,
  STEP_ADMIN_DELETE_SUCCESS,
  REORDER_STEPS,
  GET_TAGS_LIST_REQUESTING,
  GET_TAGS_LIST_SUCCESS,
  GET_TAGS_LIST_ERROR,
  UPDATE_TAGS_LIST_REQUESTING,
  UPDATE_TAGS_LIST_SUCCESS,
  UPDATE_TAGS_LIST_ERROR,
  STEP_GET_ALL_REQUESTING,
  STEP_GET_ALL_SUCCESS,
  STEP_GET_ALL_ERROR,
  STEP_DELETE_SUCCESS,
  STEP_DELETE_ERROR,
  STEP_DELETE_REQUESTING,
  MY_LIBRARY_GET_TOTAL_COUNT_REQUESTING,
  MY_LIBRARY_GET_TOTAL_COUNT_SUCCESS,
  MY_LIBRARY_GET_TOTAL_COUNT_ERROR,
  PREMIUM_GET_TOTAL_COUNT_REQUESTING,
  PREMIUM_GET_TOTAL_COUNT_SUCCESS,
  PREMIUM_GET_TOTAL_COUNT_ERROR,
  GET_SKILLS_LIST_REQUESTING,
  GET_SKILLS_LIST_SUCCESS,
  GET_SKILLS_LIST_ERROR,
  UPDATE_SKILLS_LIST_REQUESTING,
  UPDATE_SKILLS_LIST_SUCCESS,
  UPDATE_SKILLS_LIST_ERROR,
  GET_ALL_OBJECTIVES_REQUESTING,
  GET_ALL_OBJECTIVES_SUCCESS,
  GET_ALL_OBJECTIVES_ERROR,
  GET_ALL_COMPETENCIES_SUCCESS,
  GET_ALL_COMPETENCIES_ERROR,
  GET_ALL_COMPETENCIES_REQUESTING,
  CREATE_NEW_STEP,
  GET_FOLDER_LIST_REQUESTING,
  GET_FOLDER_LIST_SUCCESS,
  GET_FOLDER_LIST_ERROR,
  UPDATE_FOLDER_LIST_REQUESTING,
  UPDATE_FOLDER_LIST_SUCCESS,
  UPDATE_FOLDER_LIST_ERROR,
  STEP_GET_LIST_SUCCESS,
  STEP_GET_LIST_REQUESTING,
  STEP_GET_LIST_ERROR,
  DUPLICATE_STEP,
  NAVIGATE_CREATE_TRACK,
  GET_MY_LIBRARY_STEPS_REQUESTING,
  GET_MY_LIBRARY_STEPS_SUCCESS,
  GET_MY_LIBRARY_STEPS_ERROR,
  SELECT_STEP,
  MOVE_STEP_SELECTION,
  CLICK_ADMIN_AVATAR,
} from "./constants";

import { fetchApi } from "../api";
import {
  modifyTrackRequest,
  openSnackMessage,
  emitStepUpdate,
  emitTrackUpdate,
  emitFolderListUpdate,
  createNewStep,
  getSkillsListRequest,
  getFolderListRequest,
  selectStep,
  changeField,
} from "./actions";
import { updateAdminConnection } from "services/general/actions";
import i18n from "../../i18n";
import {
  getSelectedClientId,
  getIsSuperAdmin,
  getUserClientId,
  getUserId,
} from "services/user/selectors";
import { getIsSuperAdminClientSelected } from "services/client/selectors";
import { sendAdminConnectionSocket } from "services/general/actions";
import {
  getFolderSelected,
  getIsLibrary,
  getIsMobileConnected,
  getNumberOfSteps,
  getStepSelected,
  getStepSelectedIndex,
  getStepSelectedId,
  getTrackId,
  getTrackStepsToSave,
  getTrackInfosToSave,
  getStepsIds,
  getStepsToDisplay,
} from "services/createTrack/selectors";
import { normalizeStepData } from "./normalizers";
import socket from "services/socket";

const apiEndpoints = {
  getTagsList: "/v1/tags-list",
  updateTagsList: "/v1/tags-list/",
  getSkillsList: "/v1/skills-list",
  getFolderList: "/v1/folder-list",
  getAllObjectives: "/v1/objectives/all",
  getAllCompetencies: "/v1/competencies/all",
  updateSkillsList: "/v1/skills-list/",
  updateFolderList: "/v1/folder-list/",
  getUserPolls: "/v1/polls/user/", // userId
  createAdminStep: "/v1/steps/admin",
  modifyAdminStep: "/v1/steps/admin",
  deleteAdminStep: "/v1/steps/admin",
  deleteStep: "/v1/steps/",
  createStep: "/v1/steps/",
  modifyStep: "/v1/steps/",
  updateStepPartially: "/v1/steps/update/partially",
  createTrack: "/v1/tracks/",
  modifyTrack: "/v1/tracks/",
  getCreateTrack: "/v1/tracks/create/",
  createAndModifyTemplate: "/v1/tracks/templates/",
  getAllSteps: "/v1/steps/all/get-quickly",
  getMyLibraryTotalCount: "/v1/steps/all/count/", // clientId
  getPremiumTotalCount: "/v1/steps/premium/count/", // clientId
  getStepsList: "/v1/steps/list/get",
};

const getAllStepsApi = data => {
  const { clientId, noStats = false } = data;
  return fetchApi(
    `${apiEndpoints.getAllSteps}?clientId=${clientId}&noStats=${noStats}`,
    {},
    "get",
  );
};

const getStepsListApi = data => {
  return fetchApi(`${apiEndpoints.getStepsList}`, data, "post");
};

const getCreateTrackApi = data => {
  return fetchApi(apiEndpoints.getCreateTrack + data, {}, "get");
};

const getMyLibraryTotalCountApi = data => {
  return fetchApi(apiEndpoints.getMyLibraryTotalCount + data, {}, "get");
};

const getPremiumTotalCountApi = data => {
  return fetchApi(apiEndpoints.getPremiumTotalCount + data, {}, "get");
};

const createStepApi = data => {
  return fetchApi(apiEndpoints.createStep, data, "post");
};

const modifyStepApi = data => {
  return fetchApi(apiEndpoints.modifyStep, data, "patch");
};

const updateStepPartiallyApi = data => {
  return fetchApi(apiEndpoints.updateStepPartially, data, "patch");
};

const createAdminStepApi = data => {
  return fetchApi(apiEndpoints.createAdminStep, data, "post");
};

const modifyAdminStepApi = data => {
  return fetchApi(apiEndpoints.modifyAdminStep, data, "patch");
};
/*
const deleteAdminStepApi = data => {
  return fetchApi(apiEndpoints.deleteAdminStep, data, "delete");
};

const deleteStepApi = data => {
  return fetchApi(apiEndpoints.deleteStep, data, "delete");
};*/

const createTrackApi = data => {
  return fetchApi(apiEndpoints.createTrack, data, "post");
};

export const modifyTrackApi = data => {
  return fetchApi(apiEndpoints.modifyTrack, data, "patch");
};

const createAndModifyTemplateApi = data => {
  return fetchApi(apiEndpoints.createAndModifyTemplate, data, "patch");
};

const getTagsListApi = data => {
  const { clientId } = data;
  return fetchApi(
    `${apiEndpoints.getTagsList}/?clientId=${clientId}`,
    {},
    "get",
  );
};

const getAllObjectivesApi = data => {
  const { clientId } = data;
  return fetchApi(
    `${apiEndpoints.getAllObjectives}/?clientId=${clientId}`,
    {},
    "get",
  );
};

const getAllCompetenciesApi = data => {
  const { clientId } = data;
  return fetchApi(
    `${apiEndpoints.getAllCompetencies}/?clientId=${clientId}`,
    {},
    "get",
  );
};

const updateTagsListApi = data => {
  return fetchApi(apiEndpoints.updateTagsList, data, "patch");
};

const getFolderListApi = data => {
  const { clientId } = data;
  return fetchApi(
    `${apiEndpoints.getFolderList}/?clientId=${clientId}`,
    {},
    "get",
  );
};

const updateFolderListApi = data => {
  return fetchApi(apiEndpoints.updateFolderList, data, "patch");
};

const getSkillsListApi = data => {
  const { clientId } = data;
  return fetchApi(
    `${apiEndpoints.getSkillsList}/?clientId=${clientId}`,
    {},
    "get",
  );
};

const updateSkillsListApi = data => {
  return fetchApi(apiEndpoints.updateSkillsList, data, "patch");
};

function* getStepsListFlow(action) {
  try {
    const result = yield call(getStepsListApi, action.payload);
    const normalizedSteps = normalizeStepData({ steps: result });

    yield put({ type: STEP_GET_LIST_SUCCESS, payload: normalizedSteps });
  } catch (error) {
    console.log(error);
    yield put({ type: STEP_GET_LIST_ERROR, payload: error });
  }
}

function* getAllStepsFlow(action) {
  try {
    const { clientId } = action.payload;
    const result = yield call(getAllStepsApi, action.payload);

    const normalizedSteps = normalizeStepData({ steps: result });

    yield put({ type: STEP_GET_ALL_SUCCESS, payload: normalizedSteps });

    const userId = yield select(getUserId);

    sendAdminConnectionSocket({
      userId,
      clientId,
      trackId: "library",
      stepId: result.length ? result[0]._id : null,
      contentSelected: null,
    });

    yield put(getFolderListRequest({ clientId }));
    yield put(getSkillsListRequest({ clientId }));
  } catch (error) {
    console.log(error);
    yield put({ type: STEP_GET_ALL_ERROR, payload: error });
  }
}

function* getMyLibraryStepsFlow(action) {
  try {
    const { clientId } = action.payload;
    const result = yield call(getAllStepsApi, {
      ...action.payload,
      noStats: true,
    });
    yield put({ type: GET_MY_LIBRARY_STEPS_SUCCESS, payload: result });

    console.log("ALL MY LIBRARY STEPS");

    yield put(getFolderListRequest({ clientId }));
    yield put(getSkillsListRequest({ clientId }));
  } catch (error) {
    console.log(error);
    yield put({ type: GET_MY_LIBRARY_STEPS_ERROR, payload: error });
  }
}

function* getMyLibraryTotalCountFlow(action) {
  try {
    const result = yield call(getMyLibraryTotalCountApi, action.payload);
    yield put({ type: MY_LIBRARY_GET_TOTAL_COUNT_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: MY_LIBRARY_GET_TOTAL_COUNT_ERROR, payload: error });
  }
}

function* getPremiumTotalCountFlow(action) {
  try {
    const result = yield call(getPremiumTotalCountApi, action.payload);
    yield put({ type: PREMIUM_GET_TOTAL_COUNT_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: PREMIUM_GET_TOTAL_COUNT_ERROR, payload: error });
  }
}

function* getTagsListFlow(action) {
  try {
    const result = yield call(getTagsListApi, action.payload);
    yield put({ type: GET_TAGS_LIST_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: GET_TAGS_LIST_ERROR, payload: error });
  }
}

function* getAllObjectivesFlow(action) {
  try {
    const result = yield call(getAllObjectivesApi, action.payload);
    yield put({ type: GET_ALL_OBJECTIVES_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: GET_ALL_OBJECTIVES_ERROR, payload: error });
  }
}

function* getAllCompetenciesFlow(action) {
  try {
    const result = yield call(getAllCompetenciesApi, action.payload);
    yield put({ type: GET_ALL_COMPETENCIES_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: GET_ALL_COMPETENCIES_ERROR, payload: error });
  }
}

function* updateTagsListFlow(action) {
  try {
    const result = yield call(updateTagsListApi, action.payload);
    yield put({ type: UPDATE_TAGS_LIST_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: UPDATE_TAGS_LIST_ERROR, payload: error });
  }
}

function* getSkillsListFlow(action) {
  try {
    const result = yield call(getSkillsListApi, action.payload);
    yield put({ type: GET_SKILLS_LIST_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: GET_SKILLS_LIST_ERROR, payload: error });
  }
}

function* updateSkillsListFlow(action) {
  try {
    const result = yield call(updateSkillsListApi, action.payload);
    yield put({ type: UPDATE_SKILLS_LIST_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: UPDATE_SKILLS_LIST_ERROR, payload: error });
  }
}

function* getFolderListFlow(action) {
  try {
    const result = yield call(getFolderListApi, action.payload);
    yield put({ type: GET_FOLDER_LIST_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: GET_FOLDER_LIST_ERROR, payload: error });
  }
}

function* updateFolderListFlow(action) {
  try {
    const result = yield call(updateFolderListApi, action.payload);
    yield put({ type: UPDATE_FOLDER_LIST_SUCCESS, payload: result });
    emitFolderListUpdate({ clientId: result.clientId, folderList: result });
  } catch (error) {
    yield put({ type: UPDATE_FOLDER_LIST_ERROR, payload: error });
  }
}

function* modifyStepFlow(action) {
  try {
    const result = yield call(updateStepPartiallyApi, action.payload);
    const { contentHow, contentWhy, contentTitle } = action.payload;
    yield put({ type: STEP_MODIFY_SUCCESS, payload: result });

    const clientId = yield select(getUserClientId);
    const selectedClientId = yield select(getSelectedClientId);
    const isSuperAdmin = yield select(getIsSuperAdmin);
    const trackId = yield select(getTrackId);

    // Update in realtime only part of content that has been modified to allow multiple edit at same time
    const content = {};

    if (contentWhy) {
      content.why = contentWhy;
    }
    if (contentHow) {
      content.how = contentHow;
    }
    if (contentTitle) {
      content.title = contentTitle;
    }

    if (isSuperAdmin) {
      emitStepUpdate({
        step: { ...result, content },
        clientId: selectedClientId,
        trackId,
      });
    } else {
      emitStepUpdate({ step: { ...result, content }, clientId, trackId });
    }
  } catch (error) {
    yield put({ type: STEP_MODIFY_ERROR, payload: error });
  }
}

function* createStepFlow(action) {
  try {
    const { idToUpdate } = action.payload;
    const result = yield call(createStepApi, action.payload);
    yield put({
      type: STEP_CREATE_SUCCESS,
      payload: { ...result, idToUpdate },
    });

    const clientId = yield select(getUserClientId);
    const selectedClientId = yield select(getSelectedClientId);
    const userId = yield select(getUserId);
    const isSuperAdmin = yield select(getIsSuperAdmin);
    const trackId = yield select(getTrackId);
    yield select(getTrackInfosToSave);

    if (isSuperAdmin) {
      emitStepUpdate({
        step: result,
        clientId: selectedClientId,
        trackId,
        updateType: "add",
      });
      // emitTrackUpdate({
      //   track,
      // });
    } else {
      emitStepUpdate({ step: result, clientId, trackId, updateType: "add" });
      // emitTrackUpdate({
      //   track,
      // });
    }
    sendAdminConnectionSocket({ userId, clientId, stepId: result._id });
  } catch (error) {
    yield put({ type: STEP_CREATE_ERROR, payload: error });
  }
}

function* createNewStepFlow(action) {
  try {
    const folderSelected = yield select(getFolderSelected);
    const trackId = yield select(getTrackId);
    const numberOfSteps = yield select(getNumberOfSteps);
    const selectedClientId = yield select(getSelectedClientId);
    const userClientId = yield select(getUserClientId);
    const stepSelectedIndex = yield select(getStepSelectedIndex);

    const userId = yield select(getUserId);

    const isSuperAdmin = yield select(getIsSuperAdmin);
    const isSuperAdminClientSelected = yield select(
      getIsSuperAdminClientSelected,
    );

    const length = numberOfSteps + 1;

    const newObjectId = ObjectID().toHexString();

    const newPosition = numberOfSteps === 0 ? 0 : stepSelectedIndex + 1;

    const step = {
      _id: newObjectId,
      idToUpdate: newObjectId,
      content: {
        title: { fr: "", en: "" },
        how: {},
        why: {},
      },
      author: userId,
      category: "leadership",
      clientId: isSuperAdmin ? selectedClientId : userClientId,
      categories: [],
      tags: [],
      skills: [],
      folders: folderSelected?.value ? [folderSelected] : [],
      creating: false,
      title: {},
      isPrivate: true,
      stepIndex: numberOfSteps,
      origin: trackId || null,
      order: newPosition,
      settings: {
        uploadOptions: ["video", "picture", "document"],
      },
      ...action.payload,
    };

    if (trackId) {
      step.origin = trackId;
    }

    yield put({ type: ADD_STEP, payload: step });
    yield put(selectStep({ stepId: newObjectId }));

    if (isSuperAdmin && isSuperAdminClientSelected) {
      yield createAdminStepFlow({ payload: step });
    } else {
      yield createStepFlow({ payload: step });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: STEP_CREATE_ERROR, payload: error });
  }
}

function* modifyAdminStepFlow(action) {
  try {
    const result = yield call(modifyAdminStepApi, action.payload);
    yield put({ type: STEP_ADMIN_MODIFY_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: STEP_ADMIN_MODIFY_ERROR, payload: error });
  }
}

function* deleteAdminStepFlow(action) {
  try {
    const removedStep = { ...action.payload, isRemoved: true };
    yield call(modifyAdminStepApi, removedStep);
    // for letting algolia update..
    yield delay(250);
    yield put({ type: STEP_ADMIN_DELETE_SUCCESS, payload: removedStep.stepId });
    yield put(openSnackMessage(i18n.t("step-deleted-success")));
  } catch (error) {
    yield put({ type: STEP_ADMIN_DELETE_ERROR, payload: error });
  }
}

function* deleteStepFlow(action) {
  try {
    const removedStep = { ...action.payload, isRemoved: true };
    yield call(modifyStepApi, removedStep);
    // for letting algolia update..
    yield delay(250);
    yield put({ type: STEP_DELETE_SUCCESS, payload: removedStep.stepId });
    yield put(openSnackMessage(i18n.t("step-deleted-success")));

    const clientId = yield select(getUserClientId);
    const selectedClientId = yield select(getSelectedClientId);
    if (selectedClientId !== clientId) {
      emitStepUpdate({
        step: removedStep,
        clientId: selectedClientId,
        updateType: "remove",
      });
    } else {
      emitStepUpdate({ step: removedStep, clientId, updateType: "remove" });
    }
    // yield put({ type: REMOVE_STEP, payload: { stepId: removedStep.stepId } });
  } catch (error) {
    yield put({ type: STEP_DELETE_ERROR, payload: error });
  }
}

function* createAdminStepFlow(action) {
  try {
    const { idToUpdate } = action.payload;
    const result = yield call(createAdminStepApi, action.payload);
    yield put({
      type: STEP_ADMIN_CREATE_SUCCESS,
      payload: { ...result, idToUpdate },
    });
  } catch (error) {
    yield put({ type: STEP_ADMIN_CREATE_ERROR, payload: error });
  }
}

function* createTrackFlow(action) {
  try {
    const result = yield call(createTrackApi, action.payload);
    // this.props.history.push('/create')
    yield put({ type: TRACK_CREATE_SUCCESS, payload: result });

    yield put({
      type: NAVIGATE_CREATE_TRACK,
      payload: { trackId: result._id },
    });
    // yield put(push(`/create/${result._id}`));
  } catch (error) {
    yield put({ type: TRACK_CREATE_ERROR, payload: error });
  }
}

function* getCreateTrackFlow(action) {
  try {
    const { trackId } = action.payload;
    const result = yield call(getCreateTrackApi, trackId);
    const normalizedSteps = normalizeStepData({ steps: result.steps });

    const { entities = {} } = normalizedSteps;

    const stepsIds = normalizedSteps?.result?.steps;
    const stepsByIds = entities?.steps || {};

    yield put({
      type: TRACK_CREATE_GET_SUCCESS,
      payload: { ...result, stepsByIds, stepsIds },
    });

    const clientId = yield select(getSelectedClientId);
    const userClientId = yield select(getUserClientId);
    const isSuperAdmin = yield select(getIsSuperAdmin);
    const userId = yield select(getUserId);

    const goodClientId = isSuperAdmin ? clientId : userClientId;

    sendAdminConnectionSocket({
      userId,
      clientId,
      contentSelected: null,
      stepId: stepsIds.length ? stepsIds[0] : null,
      trackId: trackId,
    });

    yield put(getSkillsListRequest({ clientId: goodClientId }));
    yield put(getFolderListRequest({ clientId: goodClientId }));
    // yield put(getAllObjectivesRequest({ clientId: goodClientId }));
    // yield put(getAllCompetenciesRequest({ clientId: goodClientId }));
  } catch (error) {
    console.log(error);
    yield put({ type: TRACK_CREATE_GET_ERROR, payload: error });
  }
}

function* navigateCreateTrackFlow(action) {
  try {
    const {
      trackId,
      isEditingCampaign,
      isCoachingCampaign = false,
      noNavigation = false,
      campaignId,
      campaignName,
    } = action.payload;
    if (noNavigation) return;

    if (isEditingCampaign) {
      yield put(
        push(
          `/create/editing/campaign?trackId=${trackId}&campaignId=${campaignId}&campaignName=${campaignName}&isCoachingCampaign=${isCoachingCampaign}`,
        ),
      );
    } else {
      yield put(push(`/create?trackId=${trackId}`));
    }
  } catch (error) {
    // yield put({ type: TRACK_CREATE_GET_ERROR, payload: error });
  }
}

function* modifyTrackFlow(action) {
  try {
    const result = yield call(modifyTrackApi, action.payload);
    // this.props.history.push('/create')
    yield put({ type: TRACK_MODIFY_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: TRACK_MODIFY_ERROR, payload: error });
  }
}

function* removeStepFlow(action) {
  try {
    // REMOVE_STEP
    const clientId = yield select(getUserClientId);
    const selectedClientId = yield select(getSelectedClientId);
    const isSuperAdmin = yield select(getIsSuperAdmin);
    const trackId = yield select(getTrackId);
    yield select(getUserId);
    yield select(getStepSelectedId);

    if (isSuperAdmin) {
      emitStepUpdate({
        step: { _id: action.payload },
        clientId: selectedClientId,
        trackId,
        updateType: "remove",
      });
    } else {
      emitStepUpdate({
        step: { _id: action.payload },
        clientId,
        trackId,
        updateType: "remove",
      });
    }

    // sendAdminConnectionSocket({ userId, clientId, stepId });
    // this.props.history.push('/create')
    yield saveTrackFlow();
  } catch (error) {
    yield put({ type: TRACK_MODIFY_ERROR, payload: error });
  }
}

function* reorderStepsFlow(action) {
  try {
    const trackInfos = yield select(getTrackInfosToSave);
    const stepsIds = yield select(getStepsIds);
    const clientId = yield select(getSelectedClientId);

    emitTrackUpdate({
      track: { ...trackInfos, stepsIds },
      clientId,
    });

    // this.props.history.push('/create')
    yield saveTrackFlow();
  } catch (error) {
    yield put({ type: TRACK_MODIFY_ERROR, payload: error });
  }
}

function* createAndModifyTemplateFlow(action) {
  try {
    const result = yield call(createAndModifyTemplateApi, action.payload);
    yield put({ type: CREATE_AND_MODIFY_TEMPLATE_SUCCESS, payload: result });
    yield put(openSnackMessage(i18n.t("template-modify-success")));
  } catch (error) {
    yield put({ type: CREATE_AND_MODIFY_TEMPLATE_ERROR, payload: error });
  }
}

function* saveTrackFlow(action) {
  try {
    const trackInfos = yield select(getTrackInfosToSave);
    const trackId = yield select(getTrackId);
    const isLibrary = yield select(getIsLibrary);
    const steps = yield select(getTrackStepsToSave);

    if (!isLibrary) {
      yield put(modifyTrackRequest({ trackId, ...trackInfos, steps }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* duplicateStepFlow(action) {
  try {
    const stepSelected = action.payload;

    const trackId = yield select(getTrackId);

    const deepCopy = JSON.parse(JSON.stringify(stepSelected));
    const { title } = deepCopy;

    const stepCopy = {
      ...deepCopy,
      title: {
        fr: title?.["fr"] ? `(Copie) ${title["fr"]}` : "",
        en: title?.["en"] ? `(Copy) ${title["en"]}` : "",
      },
      totalDone: 0,
      totalViews: 0,
      rating: 0,
      creating: false,
      isPrivate: true,
      isPremium: false,
    };
    delete stepCopy["origin"];
    delete stepCopy["stepIndex"];
    delete stepCopy["__v"];
    delete stepCopy["clientId"];
    delete stepCopy["_id"];
    delete stepCopy["step"];

    if (trackId) {
      stepCopy.origin = trackId;
    }

    yield put(createNewStep(stepCopy));
  } catch (error) {
    console.log(error);
  }
}

function* selectStepFlow(action) {
  try {
    const { stepId } = action.payload;

    const userId = yield select(getUserId);
    const clientId = yield select(getUserClientId);
    const selectedClientId = yield select(getSelectedClientId);
    const isSuperAdmin = yield select(getIsSuperAdmin);
    const trackId = yield select(getTrackId);

    const isMobileConnected = yield select(getIsMobileConnected);

    if (isMobileConnected) {
      const stepSelected = yield select(getStepSelected);

      socket.emit("mobile-connection-step-change", {
        step: {
          ...stepSelected,
        },
        userId,
      });
    }

    const adminConnectionObject = {
      userId,
      clientId: isSuperAdmin ? selectedClientId : clientId,
      trackId: trackId || "library",
      stepId,
      contentSelected: `title`,
    };

    yield put(updateAdminConnection(adminConnectionObject));

    sendAdminConnectionSocket(adminConnectionObject);
  } catch (error) {
    console.log(error);
  }
}

function* moveStepFlow(action) {
  try {
    const stepMovement = action.payload;
    const stepSelectedIndex = yield select(getStepSelectedIndex);
    const steps = yield select(getStepsToDisplay);

    if (stepMovement === 1) {
      if (stepSelectedIndex < steps.length - 1) {
        yield put(selectStep({ stepId: steps[stepSelectedIndex + 1] }));
      }
    } else if (stepMovement === -1) {
      if (stepSelectedIndex > 0) {
        yield put(selectStep({ stepId: steps[stepSelectedIndex - 1] }));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* clickAdminAvatarFlow(action) {
  try {
    const admin = action.payload;
    const { stepId = "" } = admin;
    if (!stepId) return;

    const steps = yield select(getStepsToDisplay);
    const indexToScroll = steps.findIndex(el => el === stepId);

    yield put(selectStep({ stepId: stepId }));
    yield put(changeField({ indexToScroll, lastScrollDate: new Date() }));
  } catch (error) {
    console.log(error);
  }
}

function* Saga() {
  yield all([
    takeLatest(STEP_CREATE_REQUESTING, createStepFlow),
    takeLatest(STEP_MODIFY_REQUESTING, modifyStepFlow),
    takeLatest(STEP_ADMIN_CREATE_REQUESTING, createAdminStepFlow),
    takeLatest(STEP_ADMIN_MODIFY_REQUESTING, modifyAdminStepFlow),
    takeLatest(TRACK_CREATE_REQUESTING, createTrackFlow),
    takeLatest(TRACK_MODIFY_REQUESTING, modifyTrackFlow),
    takeLatest(STEP_ADMIN_DELETE_REQUESTING, deleteAdminStepFlow),
    takeLatest(REMOVE_STEP, removeStepFlow),
    takeLatest(REORDER_STEPS, reorderStepsFlow),
    takeLatest(
      [
        STEP_ADMIN_DELETE_SUCCESS,
        STEP_DELETE_SUCCESS,
        STEP_ADMIN_CREATE_SUCCESS,
        STEP_CREATE_SUCCESS,
        SAVE_TRACK_REQUESTING,
        ADD_STEP,
      ],
      saveTrackFlow,
    ),
    takeLatest(TRACK_CREATE_GET_REQUESTING, getCreateTrackFlow),
    takeLatest(
      CREATE_AND_MODIFY_TEMPLATE_REQUESTING,
      createAndModifyTemplateFlow,
    ),
    takeLatest(GET_TAGS_LIST_REQUESTING, getTagsListFlow),
    takeLatest(GET_ALL_OBJECTIVES_REQUESTING, getAllObjectivesFlow),
    takeLatest(GET_ALL_COMPETENCIES_REQUESTING, getAllCompetenciesFlow),
    takeLatest(UPDATE_TAGS_LIST_REQUESTING, updateTagsListFlow),
    takeLatest(GET_SKILLS_LIST_REQUESTING, getSkillsListFlow),
    takeLatest(UPDATE_SKILLS_LIST_REQUESTING, updateSkillsListFlow),
    takeLatest(GET_FOLDER_LIST_REQUESTING, getFolderListFlow),
    takeLatest(UPDATE_FOLDER_LIST_REQUESTING, updateFolderListFlow),
    takeLatest(STEP_GET_ALL_REQUESTING, getAllStepsFlow),
    takeLatest(STEP_DELETE_REQUESTING, deleteStepFlow),
    takeLatest(
      MY_LIBRARY_GET_TOTAL_COUNT_REQUESTING,
      getMyLibraryTotalCountFlow,
    ),
    takeLatest(PREMIUM_GET_TOTAL_COUNT_REQUESTING, getPremiumTotalCountFlow),
    takeEvery(CREATE_NEW_STEP, createNewStepFlow),
    takeLatest(STEP_GET_LIST_REQUESTING, getStepsListFlow),
    takeEvery(DUPLICATE_STEP, duplicateStepFlow),
    takeLatest(NAVIGATE_CREATE_TRACK, navigateCreateTrackFlow),
    takeLatest(GET_MY_LIBRARY_STEPS_REQUESTING, getMyLibraryStepsFlow),
    takeLatest(SELECT_STEP, selectStepFlow),
    takeEvery(MOVE_STEP_SELECTION, moveStepFlow),
    takeLatest(CLICK_ADMIN_AVATAR, clickAdminAvatarFlow),
  ]);
}

export default Saga;
