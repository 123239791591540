import React, { useState } from "react";
import withStyles from '@mui/styles/withStyles';
import Colors from "constants/Colors";
import { Typography } from "@mui/material";
import { connect } from "react-redux";
import { getSelectedQuestion } from "services/survey/selectors";
import ChoiceBox from "scenes/SurveyBuilder/CreateQuestionSide/ChoiceBox";
import i18n from "i18n";

const styles = theme => ({
  main: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  title: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 14,
    color: Colors.darkBlue,
    marginBottom: 10,
  },
  leftLabel: {
    color: Colors.red,
  },
  rightLabel: {
    color: Colors.green,
  },
  goalsSelected: {
    textAlign: "center",
    color: Colors.pureMain,
    fontSize: 14,
    marginBottom: 15,
  },
});

const getChoicesLength = userChoices => {
  const keys = Object.keys(userChoices).filter(el => userChoices[el]);
  return keys.length;
};

const MultipleChoicesPreview = ({
  classes,
  question = {},
  languageSelected = "fr",
}) => {
  const {
    questionTitle = "Qu'avez vous pensez de cette session ?",
    choices = [],
  } = question;

  // const choices = [
  //   { fr: "top", en: "Cooool" },
  //   { fr: "top", en: "Cooool" },
  //   { fr: "top", en: "Cooool" },
  //   { fr: "top", en: "Cooool" },
  //   { fr: "top", en: "Cooool" },
  // ];

  const [userChoices, setUserChoices] = useState({});

  const onClickBox = index => () => {
    setUserChoices({ ...userChoices, [index]: !userChoices[index] });
  };

  return (
    <div className={classes.main}>
      <Typography className={classes.title}>
        {questionTitle[languageSelected]}
      </Typography>
      <Typography className={classes.goalsSelected}>
        <strong>{getChoicesLength(userChoices)} </strong>
        {i18n.t("choices-selected", { lng: languageSelected })}
      </Typography>
      {choices.map((choice, index) => {
        return (
          <ChoiceBox
            onClick={onClickBox(index)}
            key={index}
            title={choice[languageSelected] || ""}
            isUserChoice={userChoices[index]}
          />
        );
      })}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    question: getSelectedQuestion(state),
    languageSelected: state.survey.languageSelected,
  };
};

export default connect(
  mapStateToProps,
  {},
)(withStyles(styles)(MultipleChoicesPreview));
