export const USER_SET = "USER_SET";
export const USER_UNSET = "USER_UNSET";

export const USER_GET_REQUESTING = "USER_GET_REQUESTING";
export const USER_GET_SUCCESS = "USER_GET_SUCCESS";
export const USER_GET_ERROR = "USER_GET_ERROR";

export const USER_MODIFY = "USER_MODIFY";
export const USER_MODIFY_PICTURE = "USER_MODIFY_PICTURE";

export const USER_MODIFY_NAME = "USER_MODIFY_NAME";
export const USER_MODIFY_REQUESTING = "USER_MODIFY_REQUESTING";
export const USER_MODIFY_ERROR = "USER_MODIFY_ERROR";

export const NOTIFICATIONS_SEEN = "NOTIFICATIONS_SEEN";
export const NOTIFICATIONS_NEW = "NOTIFICATIONS_NEW";

export const USER_GET_COMPANY_LOGO_REQUESTING =
  "USER_GET_COMPANY_LOGO_REQUESTING";
export const USER_GET_COMPANY_LOGO_SUCCESS = "USER_GET_COMPANY_LOGO_SUCCESS";
export const USER_GET_COMPANY_LOGO_ERROR = "USER_GET_COMPANY_LOGO_ERROR";
