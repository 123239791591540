import { normalize, schema } from "normalizr";

// content : title {} / how {} / en {}

// const skill = new schema.Entity("skills", {}, { idAttribute: "_id" });
const stepSchema = new schema.Entity("steps", {}, { idAttribute: "_id" });

// We assume articlesData is the (parsed) JSON object that we got
export const normalizeStepData = stepsData =>
  normalize(stepsData, { steps: [stepSchema] });
