import React from "react";
import withStyles from '@mui/styles/withStyles';
import Button from "@mui/material/Button";
import Colors from "../constants/Colors";
import { Loader } from "./Loader";

const AddButtonBoxToCustomize = withStyles({
  root: {
    height: 40,
    width: 45,
    padding: 0,
    minWidth: 0,
    minHeight: 0,
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    fontWeight: "bold",
    color: Colors.white,
    lineHeight: 1.5,
    opacity: 1,
    borderRadius: 4,
    backgroundColor: Colors.pureMain,
    borderColor: Colors.pureMain,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      boxShadow: "none",
      opacity: 0.9,
      borderColor: Colors.pureMain,
      backgroundColor: Colors.pureMain,
    },
    "&:active": {
      boxShadow: "none",
      border: "none",
      borderColor: Colors.pureMain,
      backgroundColor: Colors.pureMain,
      opacity: 0.9,
    },
    "&:focus": {
      border: "none",
      borderColor: Colors.pureMain,
      backgroundColor: Colors.pureMain,
    },
  },
  disabled: {
    fontWeight: "normal",
    color: Colors.black,
  },
  outlined: {
    backgroundColor: "transparent",
    border: "1px solid",
    borderColor: "rgba(29, 28, 29, 0.3)",
  },
  "@media (max-width: 375px)": {
    root: {
      fontSize: 14,
    },
  },
})(Button);

const AddButtonBox = function CustomizedButtons({
  title,
  onClick,
  disabled = false,
  className = "",
  fullWidth = false,
  requesting = false,
}) {
  if (requesting) {
    return <Loader size={25} />;
  }
  return (
    <AddButtonBoxToCustomize
      className={className}
      disabled={disabled}
      fullWidth={fullWidth}
      variant="contained"
      color="primary"
      disableRipple
      onClick={onClick}
    >
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
        <path
          d="M7.95112 6.28687V0.408764H6.27166V6.28687H0.393555V7.96633H6.27166V13.8444H7.95112V7.96633H13.8292V6.28687H7.95112Z"
          fill="white"
        />
      </svg>
    </AddButtonBoxToCustomize>
  );
};

export { AddButtonBox };
