import React from "react";
import withStyles from '@mui/styles/withStyles';
import Colors from "constants/Colors";
import { Typography } from "@mui/material";
import QuestionSlider from "scenes/SurveyBuilder/CreateQuestionSide/QuestionSlider";
import { connect } from "react-redux";
import { getSelectedQuestion } from "services/survey/selectors";

const styles = theme => ({
  main: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  title: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 14,
    color: Colors.darkBlue,
    marginBottom: 60,
  },
  leftLabel: {
    color: Colors.red,
  },
  rightLabel: {
    color: Colors.green,
  },
  space: {
    flex: 1,
  },
});

const RatingQuestionPreview = ({
  classes,
  question = {},
  languageSelected = "fr",
}) => {
  const {
    questionTitle = "Qu'avez vous pensez de cette session ?",
    leftLabel = "Left label",
    rightLabel = "Right label",
  } = question;

  return (
    <div className={classes.main}>
      <Typography className={classes.title}>
        {questionTitle[languageSelected]}
      </Typography>
      <div className={classes.space} />
      <QuestionSlider
        leftLabel={leftLabel[languageSelected]}
        rightLabel={rightLabel[languageSelected]}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    question: getSelectedQuestion(state),
    languageSelected: state.survey.languageSelected,
  };
};

export default connect(
  mapStateToProps,
  {},
)(withStyles(styles)(RatingQuestionPreview));
