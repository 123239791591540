export const GET_RESOURCE_REQUESTING = "resource/GET_RESOURCE_REQUESTING";
export const GET_RESOURCE_SUCCESS = "resource/GET_RESOURCE_SUCCESS";
export const GET_RESOURCE_ERROR = "resource/GET_RESOURCE_ERROR";

export const UPDATE_RESOURCE_REQUESTING = "resource/UPDATE_RESOURCE_REQUESTING";
export const UPDATE_RESOURCE_SUCCESS = "resource/UPDATE_RESOURCE_SUCCESS";
export const UPDATE_RESOURCE_ERROR = "resource/UPDATE_RESOURCE_ERROR";

export const CREATE_RESOURCE_REQUESTING = "resource/CREATE_RESOURCE_REQUESTING";
export const CREATE_RESOURCE_SUCCESS = "resource/CREATE_RESOURCE_SUCCESS";
export const CREATE_RESOURCE_ERROR = "resource/CREATE_RESOURCE_ERROR";

export const CLEAR_RESOURCE = "resource/CLEAR_RESOURCE";

export const GET_RESOURCE_LIST_REQUESTING =
  "resource/GET_RESOURCE_LIST_REQUESTING";
export const GET_RESOURCE_LIST_SUCCESS = "resource/GET_RESOURCE_LIST_SUCCESS";
export const GET_RESOURCE_LIST_ERROR = "resource/GET_RESOURCE_LIST_ERROR";

export const GET_LIBRARY_RESOURCES_REQUESTING =
  "resource/GET_LIBRARY_RESOURCES_REQUESTING";
export const GET_LIBRARY_RESOURCES_SUCCESS =
  "resource/GET_LIBRARY_RESOURCES_SUCCESS";
export const GET_LIBRARY_RESOURCES_ERROR =
  "resource/GET_LIBRARY_RESOURCES_ERROR";

export const DELETE_RESOURCE_REQUESTING = "resource/DELETE_RESOURCE_REQUESTING";

export const ADD_RESOURCE = "resource/ADD_RESOURCE";

export const ADD_CAMPAIGN_RESOURCE = "resource/ADD_CAMPAIGN_RESOURCE";

export const REMOVE_CAMPAIGN_RESOURCE = "resource/REMOVE_CAMPAIGN_RESOURCE";

export const UPDATE_CAMPAIGN_RESOURCES_REQUESTING =
  "resource/UPDATE_CAMPAIGN_RESOURCES_REQUESTING";
export const UPDATE_CAMPAIGN_RESOURCES_ERROR =
  "resource/UPDATE_CAMPAIGN_RESOURCES_ERROR";
export const UPDATE_CAMPAIGN_RESOURCES_SUCCESS =
  "resource/UPDATE_CAMPAIGN_RESOURCES_SUCCESS";

export const GET_CAMPAIGN_RESOURCES_REQUESTING =
  "resource/GET_CAMPAIGN_RESOURCES_REQUESTING";
export const GET_CAMPAIGN_RESOURCES_SUCCESS =
  "resource/GET_CAMPAIGN_RESOURCES_SUCCESS";
export const GET_CAMPAIGN_RESOURCES_ERROR =
  "resource/GET_CAMPAIGN_RESOURCES_ERROR";

export const ADD_LIBRARY_RESOURCE = "resource/ADD_LIBRARY_RESOURCE";
