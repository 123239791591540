import React from "react";

const FreeAnswerIcon = ({ sizePercentage, color }) => (
  <svg
    width={sizePercentage * 15}
    height={sizePercentage * 15}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 15 15"
    version="1.1"
  >
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.550537 0.61792H14.0746V1.97032H0.550537V0.61792Z"
        fill={color}
      />
      <path
        d="M3.25537 4.67505H11.3698V6.02745H3.25537V4.67505Z"
        fill={color}
      />
      <path
        d="M0.550537 8.73218H14.0746V10.0846H0.550537V8.73218Z"
        fill={color}
      />
      <path d="M3.25537 12.7896H11.3698V14.142H3.25537V12.7896Z" fill={color} />
    </svg>
  </svg>
);

FreeAnswerIcon.defaultProps = {
  sizePercentage: 1,
  color: "#19212B",
};

export default FreeAnswerIcon;
