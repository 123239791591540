import React from "react";
import withStyles from '@mui/styles/withStyles';
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import Colors from "../../constants/Colors";
import Grow from "@mui/material/Grow";
import GradeIcon from "@mui/icons-material/Grade";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import displayDateWithoutHour from "../../utils/displayDateWithoutHour";

const styles = theme => ({
  main: {
    marginTop: 20,
    marginRight: 20,
    marginLeft: 20,
    // width: "25%",
    minWidth: 100,
    // maxWidth: "20%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    // width: "100%",
    width: 150,
    minWidth: 150,
    height: 250,
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  bold: {
    fontWeight: 500,
    marginBottom: 10,
  },
  box: {
    flex: 1,
    padding: "24px 16px 16px",
    position: "relative",
    border: "solid",
    borderWidth: 1,
    borderColor: "#DEDEDE",
    // width: "calc(33.33% - 25px)",
    boxShadow: "0 2px 4px rgba(0,0,0,.08)",
    "-webkit-box-shadow": "0 2px 4px rgba(0,0,0,.08)",
    "-moz-box-shadow": "0 2px 4px rgba(0,0,0,.08)",
    minHeight: 180,
    // height: 200,
    height: 300,
    backgroundColor: "#fff",
    borderRadius: 8,
    flexDirection: "column",
    cursor: "pointer",
    animation: "FadeIn 0.3s ease-in-out",
    transition: "all 0.2s cubic-bezier(0.41, 0.094, 0.54, 0.07) 0s",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    "&:hover": {
      // transform: "translateY(-10px)",
      boxShadow: "rgba(0, 0, 0, 0.07) 1px 7px 15px",
    },
  },
  rating: {
    color: Colors.main,
    marginTop: 10,
    fontWeight: 500,
  },
  category: {
    color: "#fff",
    fontWeight: 500,
    // marginBottom: 10,
  },
  categoryBox: {
    borderRadius: 15,
    padding: "1px 8px",
    marginBottom: 10,
  },
  actionText: {
    // fontFamily: [
    //   "-apple-system",
    //   "BlinkMacSystemFont",
    //   '"Segoe UI"',
    //   "Roboto",
    //   '"Helvetica Neue"',
    //   "Arial",
    //   "sans-serif",
    //   '"Apple Color Emoji"',
    //   '"Segoe UI Emoji"',
    //   '"Segoe UI Symbol"'
    // ].join(","),
    color: Colors.text,
    textAlign: "center",
  },
  doneText: {
    marginTop: 15,
    marginBottom: 5,
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
    color: Colors.black,
    textAlign: "center",
    lineHeight: 1.5,
    letterSpacing: "0.01071em",
    fontWeight: 500,
    // marginTop: 10
  },
  ratingDiv: {
    // marginTop: 10,
    flexDirection: "row",
    justifyContent: "center",
    display: "flex",
  },
});

const getColor = donePercent => {
  if (donePercent <= 25) {
    return "red";
  } else if (donePercent >= 75) {
    return Colors.strongGreen;
  }
};

const BestAction = ({
  title,
  category,
  count,
  color,
  classes,
  isManager,
  donePercentage,
  rating,
  isFake,
  onClick,
  doneDate,
}) => {
  const { t } = useTranslation();
  return (
    <div className={classes.main} onClick={onClick}>
      <div style={{ backgroundColor: color }} className={classes.categoryBox}>
        <Typography classes={{ root: classes.category }} align="center">
          {t(category)}
        </Typography>
      </div>
      <Grow in>
        <Paper className={classes.box}>
          <div className={classes.root}>
            <Typography
              className={classes.actionText}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>
        </Paper>
      </Grow>
      <Typography className={classes.doneText}>
        {displayDateWithoutHour(doneDate)}
      </Typography>
      {isManager && (
        <Typography style={{ marginTop: 5 }} className={classes.doneText}>
          {i18n.t("done-with")}{" "}
          <span style={{ color: getColor(donePercentage), fontWeight: "bold" }}>
            {count && !isFake
              ? (donePercentage / count).toFixed(2)
              : donePercentage || 0}{" "}
            %
          </span>
        </Typography>
      )}
      <div className={classes.ratingDiv}>
        <GradeIcon color="primary" style={{ color: Colors.yellow }} />
        <Typography
          classes={{ root: classes.rating }}
          style={{ color: Colors.text, marginLeft: 5, marginTop: 2 }}
        >
          {count && !isFake ? (rating / count).toFixed(2) : rating || 0}
        </Typography>
      </div>
      {!isManager && (
        <Typography
          style={{ color: Colors.text }}
          classes={{ root: classes.rating }}
          align="center"
        >
          {i18n.t("done-with")}{" "}
          <span style={{ color: getColor(donePercentage), fontWeight: "bold" }}>
            {count && !isFake
              ? (donePercentage / count).toFixed(2)
              : donePercentage || 0}{" "}
            %
          </span>
        </Typography>
        // <p
        //   // style={{ color: getColor(donePercent) }}
        //   className={classes.doneText}
        // >
        //   Fait à{" "}
        //   <span style={{ color: getColor(donePercentage), fontWeight: "bold" }}>
        //     {(donePercentage / count).toFixed(2)} %
        //   </span>
        // </p>
      )}
      {!isManager && (
        <Typography
          style={{ color }}
          classes={{ root: classes.rating }}
          align="center"
        >
          {`Fait ${count} fois`}
        </Typography>
      )}
    </div>
  );
};

export default withStyles(styles)(BestAction);
