import React from "react";
import Colors from "constants/Colors";
import i18n from "i18n";
import ActionBox from "./PreviewSide/ActionBox";
import { useSelector } from "react-redux";
import { getStepSelectedContent } from "services/createTrack/selectors";

interface Props {
  languageSelected: string;
}

const PhonePreviewBoxes = ({ languageSelected }: Props) => {
  const content = useSelector(getStepSelectedContent);
  return (
    <>
      <ActionBox
        uniqueKey={"unique-key-title"}
        language={languageSelected}
        text={content?.title ? content?.title?.[languageSelected] : null}
        title={i18n.t("my-challenge", { lng: languageSelected })}
        color={Colors.pureMain}
      />
      <ActionBox
        language={languageSelected}
        uniqueKey={"unique-key-why"}
        text={content?.why ? content?.why?.[languageSelected] : null}
        title={i18n.t("why", { lng: languageSelected })}
        color={Colors.pink}
      />
      <ActionBox
        language={languageSelected}
        uniqueKey={"unique-key-how"}
        text={content?.how ? content?.how?.[languageSelected] : null}
        title={i18n.t("concrete-exemple", {
          lng: languageSelected,
        })}
        color={Colors.orange}
      />
    </>
  );
};

export default React.memo(PhonePreviewBoxes);
