import React from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography } from "@mui/material";
import i18n from "../../i18n";
import LottieDesktop from "./LottieDesktop";

const styles = theme => ({
  main: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    flex: 1,
  },
  title: {
    // lineHeight: "2.6rem",
    marginLeft: "10%",
    marginRight: "10%",
    textAlign: "center",
  },
});

const ConnectOnDesktop = ({ classes }) => {
  return (
    <div className={classes.main}>
      <Typography className={classes.title} variant="h5">
        {i18n.t("connect-on-desktop")}
      </Typography>
      <LottieDesktop />
    </div>
  );
};

export default withStyles(styles)(ConnectOnDesktop);
