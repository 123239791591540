import React, { useCallback, useEffect, useRef, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import InvoicePage from "./InvoicePage";
import { useDispatch, useSelector } from "react-redux";
import { createInvoiceRequest } from "services/invoice/actions";
import { useHistory } from "react-router-dom";
import { Invoice } from "../data/types";
import { initialInvoice } from "../data/initialData";
import { getInitialInvoice } from "services/invoice/selectors";
import RecapInvoicePage from "scenes/Invoice/InvoicePage/RecapInvoicePage";
import PreviousButton from "./PreviousButton";
import ConfirmPublishInvoiceModal from "scenes/Invoice/InvoicePage/ConfirmPublishInvoiceModal";

const useStyles = makeStyles(theme => ({
  main: {
    overflow: "hidden",
    overflowX: "scroll",
    height: "88vh",
    // margin: "30px auto 50px auto",
    // width: "700px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  middleDiv: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column",
    paddingRight: 270,
    position: "relative",
  },
  invoicePage: {
    overflow: "auto",
    marginBottom: 25,
  },
  publishButton: {},
}));

const InvoiceRoute = () => {
  const startingData = useSelector(getInitialInvoice);
  const [invoice, setInvoice] = useState<Invoice>(
    startingData
      ? { ...initialInvoice, ...startingData }
      : { ...initialInvoice },
  );
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

  const setInvoiceName = invoiceName => {
    const newInvoice = { ...invoice };
    newInvoice["invoiceName"] = invoiceName;
    setInvoice(newInvoice);
  };

  const setCoacheeId = coacheeId => {
    const newInvoice = { ...invoice };
    newInvoice["coacheeId"] = coacheeId;
    setInvoice(newInvoice);
  };

  const history = useHistory();

  const classes = useStyles();
  const dispatch = useDispatch();

  const openConfirmationModal = () => {
    setIsConfirmModalVisible(true);
  };

  const handleClickPrevious = () => {
    history.goBack();
  };

  // Needed to get invoice updated within the modal
  const handleCreateInvoice = useCallback(() => {
    dispatch(createInvoiceRequest({ invoice }));
  }, [dispatch, invoice]);

  const ref = useRef(handleCreateInvoice);

  useEffect(() => {
    ref.current = handleCreateInvoice;
  }, [handleCreateInvoice]);

  return (
    <div className={classes.main}>
      <ConfirmPublishInvoiceModal
        onClickConfirm={ref.current}
        isVisible={isConfirmModalVisible}
        onClose={() => setIsConfirmModalVisible(false)}
      />
      <PreviousButton onClick={handleClickPrevious} />
      <div className={classes.middleDiv}>
        <div className={classes.invoicePage}>
          <InvoicePage invoice={invoice} setInvoice={setInvoice} />
        </div>
        <RecapInvoicePage
          {...invoice}
          onCreateInvoice={openConfirmationModal}
          setInvoiceName={setInvoiceName}
          setCoacheeId={setCoacheeId}
        />
      </div>
    </div>
  );
};

export default InvoiceRoute;
