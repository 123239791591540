import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import i18n from "i18n";
import { MainText } from "components/Texts/MainText";

const useStyles = makeStyles({
  main: {
    alignSelf: "flex-start",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    padding: "6px 12px",
    borderRadius: 6,
    cursor: "pointer",
    border: "1px solid",
    borderColor: "#fff",
    transition: "all 0.2s ease",
    textDecoration: "none",
    "&:hover": {
      opacity: 0.8,
    },
  },
  text: {
    marginTop: 2,
    fontSize: 16,
    textDecoration: "none !important",
    color: "black",
    marginLeft: 10,
  },
});

const DownloadIcon = ({ onClick = null, color = "black" }) => {
  const classes = useStyles();

  return (
    <div className={classes.main} onClick={onClick}>
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
        <path
          d="M6.68994 10.9096H14.0733L12.6269 12.3616L13.4174 13.1521L16.2205 10.349L13.4174 7.5459L12.6269 8.33637L14.0733 9.78838H6.68994V10.9096Z"
          fill={color}
        />
        <path
          d="M11.7354 6.98527V4.74278C11.7358 4.669 11.7216 4.59586 11.6937 4.52756C11.6658 4.45925 11.6247 4.39713 11.5728 4.34474L7.64843 0.420402C7.59605 0.368443 7.53392 0.327335 7.46562 0.299436C7.39731 0.271537 7.32417 0.257395 7.25039 0.257822H1.64419C1.34682 0.257822 1.06163 0.375952 0.851353 0.586226C0.64108 0.796499 0.522949 1.08169 0.522949 1.37906V14.8339C0.522949 15.1313 0.64108 15.4165 0.851353 15.6268C1.06163 15.8371 1.34682 15.9552 1.64419 15.9552H10.6141C10.9115 15.9552 11.1967 15.8371 11.4069 15.6268C11.6172 15.4165 11.7354 15.1313 11.7354 14.8339V13.7127H10.6141V14.8339H1.64419V1.37906H6.12915V4.74278C6.12915 5.04016 6.24728 5.32535 6.45756 5.53562C6.66783 5.74589 6.95302 5.86402 7.25039 5.86402H10.6141V6.98527H11.7354ZM7.25039 4.74278V1.60892L10.3843 4.74278H7.25039Z"
          fill={color}
        />
      </svg>
      <MainText
        text={i18n.t("export-csv")}
        className={classes.text}
        style={{ color: color }}
      />
    </div>
  );
};

export default DownloadIcon;
