import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import withStyles from '@mui/styles/withStyles';
import i18n from "../../i18n";
import { Link } from "react-router-dom";
import Colors from "../../constants/Colors";

const styles = theme => ({
  menu: {
    borderRadius: 4,
    boxShadow: "0 2px 8px rgba(0,0,0,.1)",
    transition:
      "transform .2s ease-in-out,opacity .2s ease-in-out,width .2s ease-in-out,height .2s ease-in-out",
    border: "1px solid #dfdfdf",
  },
  link: {
    outlineWidth: 0,
    textDecoration: "none",
    color: Colors.black,
  },
});

const AvatarMenu = ({ onClose, classes, isOpen, anchorEl, onClickLogout }) => {
  //   const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClose = () => {
    onClose();
  };

  const handleLogout = () => {
    onClickLogout();
    handleClose();
  };

  return (
    <div>
      <Menu
        classes={{ paper: classes.menu }}
        // className={classes.menu}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        id="simple-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={isOpen}
        onClose={handleClose}
      >
        <Link to={"/me"} className={classes.link}>
          <MenuItem onClick={handleClose}>{i18n.t("profile")}</MenuItem>
        </Link>
        <MenuItem onClick={handleLogout}>{i18n.t("logout")}</MenuItem>
      </Menu>
    </div>
  );
};

export default withStyles(styles)(AvatarMenu);
