import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Colors from "../../../constants/Colors";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { changeField } from "../../../services/manager/actions";
import moment from "moment";
import i18n from "../../../i18n";
import FlatButton from "components/FlatButton";
import { getManager } from "services/manager/selectors";
import {
  getCampaignSelectedEndDate,
  getCampaignSelectedStartDate,
  getSelectedCampaignId,
} from "services/campaign/selectors";
import { getNotificationsHistoryRequest } from "services/notification/actions";
import CustomDatePicker from "components/Pickers/DatePicker";
import CheckBox from "components/CheckBox";
import { MainText } from "components/Texts/MainText";
import { LightButton } from "components";
import NotificationLibraryModal from "./NotificationLibraryModal";
import LibraryIcon from "../SeeUserModal/Coaching/Resources/LibraryIcon";
import StarIcon from "scenes/CreateTrack/StarIcon";

moment.locale("fr");

const MAX_WIDTH = 400;

const useStyle = makeStyles({
  mainDiv: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  saveButton: {
    height: 50,
    marginTop: 35,
    marginBottom: 15,
  },
  checkProgramDiv: {
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": { textDecoration: "underline" },
  },
  explanation: {
    maxWidth: MAX_WIDTH,
    fontSize: 15,
    color: Colors.darkBlue,
    alignSelf: "flex-start",
    textAlign: "left",
    marginBottom: 5,
  },
  participants: {
    width: "100%",
    marginTop: 10,
    textAlign: "left",
    fontWeight: "bold",
    fontFamily: "Montserrat",
    fontSize: 20,
  },
  libraryIcon: {
    marginRight: 10,
  },
});

const Notifications = ({ userIds, sendNotification }) => {
  const {
    notificationTitle,
    notificationText,
    notificationDate,
    requesting,
  } = useSelector(getManager);
  const campaignId = useSelector(getSelectedCampaignId);
  const startDate = useSelector(getCampaignSelectedStartDate);
  const endDate = useSelector(getCampaignSelectedEndDate);

  const [
    isNotificationLibraryVisible,
    setIsNotificationLibraryVisible,
  ] = useState<boolean>(false);

  const [notificationToProgram, setNotificationToProgram] = useState<boolean>(
    false,
  );
  const maxDate = new Date(endDate ? endDate : "");
  const minDate = moment(new Date()).isBefore(startDate ? startDate : "")
    ? new Date(startDate)
    : new Date();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!requesting) {
      dispatch(
        getNotificationsHistoryRequest({
          campaignId,
          page: 0,
          numberByPage: 20,
        }), // ! attention, ici le numbre de limit ne fonctionne pas, il a été retiré en back afin de tout récupérer
      );
    }
  }, [requesting]);

  const onEditTitle = event => {
    const value = event.target.value;
    dispatch(changeField({ notificationTitle: value }));
  };

  const onEditText = event => {
    const value = event.target.value;
    dispatch(changeField({ notificationText: value }));
  };

  const onEditCalendarDate = date => {
    dispatch(
      changeField({ notificationDate: moment(date ? date : 0).toDate() }),
    );
  };

  const handleChangeNotificationToProgram = () => {
    dispatch(changeField({ notificationDate: moment(new Date()).toDate() }));
    setNotificationToProgram(!notificationToProgram);
  };

  const handleImportNotificationFromLibrary = notificationSelected => () => {
    const { text = "" } = notificationSelected;
    const notificationSplitted = text?.split(/\r?\n/);
    const notificationTitle = notificationSplitted?.[0] || "";
    const notificationText = notificationSplitted.slice(1).join("\n") || "";

    closeNotificationLibraryModal();
    dispatch(
      changeField({
        notificationTitle,
        notificationText,
      }),
    );
  };

  const closeNotificationLibraryModal = () => {
    setIsNotificationLibraryVisible(false);
  };

  const openNotificationLibraryModal = () => {
    setIsNotificationLibraryVisible(true);
  };

  const classes = useStyle();

  return (
    <>
      <div className={classes.mainDiv}>
        <NotificationLibraryModal
          onImportNotification={handleImportNotificationFromLibrary}
          onClose={closeNotificationLibraryModal}
          isVisible={isNotificationLibraryVisible}
        />
        <Typography className={classes.explanation}>
          {i18n.t("notification-explanation")}
        </Typography>
        <Typography className={classes.participants}>
          {userIds.length} {i18n.t("participants-selected")}
        </Typography>
        <TextField
          inputProps={{ maxLength: 40 }}
          fullWidth
          helperText={i18n.t("characters-max", { chars: 40 })}
          label={i18n.t("title")}
          value={notificationTitle}
          onChange={onEditTitle}
          margin="normal"
          variant="outlined"
        />
        <TextField
          multiline
          fullWidth
          label={i18n.t("message")}
          value={notificationText}
          onChange={onEditText}
          margin="normal"
          variant="outlined"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            marginBottom: 15,
          }}
        >
          <CheckBox
            isChecked={notificationToProgram}
            onCheck={handleChangeNotificationToProgram}
          />
          {notificationToProgram ? (
            <CustomDatePicker
              minDate={minDate}
              maxDate={maxDate}
              dateSelected={moment(
                notificationDate ? notificationDate : new Date(),
              ).toDate()}
              handleChangeDateSelected={onEditCalendarDate}
            />
          ) : (
            <div
              onClick={handleChangeNotificationToProgram}
              className={classes.checkProgramDiv}
            >
              <MainText text={i18n.t("notification-send-check-to-program")} />
            </div>
          )}
        </div>
        <LightButton
          icon={
            <StarIcon
              color={Colors.pureMain}
              sizePercentage={0.65}
              className={classes.libraryIcon}
            />
          }
          fullWidth
          title={i18n.t("notification-import-from-library")}
          onClick={openNotificationLibraryModal}
        />
        <FlatButton
          requesting={requesting}
          title={i18n.t("send-the-notification")}
          className={classes.saveButton}
          fullWidth
          disabled={!notificationTitle}
          onClick={() =>
            sendNotification(
              notificationTitle,
              notificationText,
              notificationDate,
            )
          }
        />
      </div>
    </>
  );
};

export default Notifications;
