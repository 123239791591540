import React from "react";

const HomeIcon = ({ sizePercentage, color, className }) => (
  <svg
    className={className}
    width={sizePercentage * 18}
    height={sizePercentage * 18}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 18 18"
    version="1.1"
  >
    <path
      d="M17.7299 9.05094L10.0807 0.551893C9.7586 0.193233 9.13987 0.193233 8.81775 0.551893L1.16861 9.05094C1.05851 9.17299 0.986238 9.32439 0.960588 9.48674C0.934938 9.64909 0.95701 9.8154 1.02412 9.96544C1.16011 10.2723 1.46437 10.4694 1.80009 10.4694H3.4999V16.4188C3.4999 16.6442 3.58944 16.8604 3.74883 17.0197C3.90822 17.1791 4.12439 17.2687 4.3498 17.2687H6.89952C7.12493 17.2687 7.3411 17.1791 7.50049 17.0197C7.65988 16.8604 7.74942 16.6442 7.74942 16.4188V13.0191H11.149V16.4188C11.149 16.6442 11.2386 16.8604 11.398 17.0197C11.5574 17.1791 11.7735 17.2687 11.9989 17.2687H14.5487C14.7741 17.2687 14.9902 17.1791 15.1496 17.0197C15.309 16.8604 15.3986 16.6442 15.3986 16.4188V10.4694H17.0984C17.263 10.4701 17.4242 10.4229 17.5625 10.3336C17.7007 10.2442 17.8099 10.1166 17.8769 9.9662C17.9438 9.81583 17.9656 9.64923 17.9395 9.48671C17.9134 9.32419 17.8405 9.17278 17.7299 9.05094Z"
      fill={color}
    />
  </svg>
);

HomeIcon.defaultProps = {
  sizePercentage: 1,
  color: "#fff",
};

export default HomeIcon;
