import React from "react";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Colors from "constants/Colors";
import i18n from "i18n";

const useStyles = makeStyles(theme => ({
  main: {
    padding: "20px 25px",
    backgroundColor: "#F4FBFF",
    width: 260,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: 22,
    color: Colors.darkBlue,
    fontWeight: 600,
    fontFamily: "Montserrat",
  },
  image: {
    marginBottom: 50,
    width: "90%",
    alignSelf: "center",
    borderRadius: 8,
    height: "auto",
    objectFit: "contain",
  },
  explanation: {
    paddingLeft: 10,
    paddingRight: 10,
    whiteSpace: "pre-line",
    fontSize: 14,
    color: "#454545",
    lineHeight: "150%",
    fontFamily: "Arial",
    textAlign: "left",
  },
  subtitle: {
    color: "black",
    fontSize: 14,
    fontFamily: "Montserrat",
    fontWeight: 300,
    marginBottom: 50,
  },
  space: {
    flex: 1,
  },
}));

interface Props {
  picture: string;
  title: string;
  explanation: string;
  bottomElement?: JSX.Element;
}

const ModalSideHelper = ({
  picture,
  title,
  explanation,
  bottomElement = null,
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.subtitle}>
        {i18n.t("how-it-works")}
      </Typography>
      <img className={classes.image} src={picture} alt="title" />
      <Typography className={classes.explanation}>{explanation}</Typography>
      <div className={classes.space} />
      {bottomElement}
    </div>
  );
};

export default ModalSideHelper;
