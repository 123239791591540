import React from "react";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import useHover from "hooks/useHover";
import ActionIcon from "scenes/Invoice/InvoiceList/ActionIcon";
import DownloadIcon from "components/DownloadIcon";
// import format from "date-fns/format";
import moment from "moment";
import i18n from "i18n";
import formatCurrency from "utils/formatCurrency";

const ROW_HEIGHT = 90;

const CheckIcon = ({ color }) => (
  <svg
    width="15"
    height="11"
    viewBox="0 0 15 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.69515 8.52195L1.40404 5.23084L0.307007 6.32788L4.69515 10.716L14.0983 1.31286L13.0013 0.21582L4.69515 8.52195Z"
      fill={color}
    />
  </svg>
);

const useStyles = makeStyles(theme => ({
  main: {
    position: "relative",
    border: "1px solid #EEEEEE",
    borderRadius: 5,
    height: ROW_HEIGHT,
    backgroundColor: "#FCFCFC",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    marginBottom: 15,
    paddingLeft: 35,
    // cursor: "pointer",
  },
  text: {
    flex: 1,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    fontSize: 16,
  },
  bold: {
    fontWeight: "bold",
  },
  paid: {
    color: "#8BD85C",
  },
  notPaid: {
    color: "#F3B515",
  },
  actionIcons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    position: "absolute",
    right: 0,
    marginRight: 20,
  },
  emptyRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    flex: 0.5,
  },
}));

interface Props {
  invoiceName: string;
  invoiceDate: string;
  invoiceDueDate: string;
  invoiceTitle: string;
  clientName: string;
  quantity: number;
  rate: string;
  totalAmount: number;
  isPaid: boolean;
  onClickDownload: () => void;
  onClickPaid: () => void;
}

const InvoiceRow = ({
  invoiceName,
  invoiceDate,
  invoiceDueDate,
  invoiceTitle,
  quantity,
  rate,
  clientName,
  totalAmount,
  isPaid,
  onClickDownload,
  onClickPaid,
}: Props) => {
  const classes = useStyles();
  const [isHovered, hoverProperties] = useHover();

  return (
    <div className={classes.main} {...hoverProperties}>
      <Typography className={classes.text}>{invoiceName}</Typography>
      <Typography className={classes.text}>{clientName}</Typography>
      <Typography className={classes.text}>
        {moment(invoiceDueDate).format("LL")}
      </Typography>
      <Typography className={classes.text}>{invoiceTitle}</Typography>
      <Typography className={`${classes.text} ${classes.bold}`}>
        {formatCurrency(totalAmount)}
      </Typography>
      <Typography
        className={`${classes.text} ${isPaid ? classes.paid : classes.notPaid}`}
      >
        {isPaid ? i18n.t("paid") : i18n.t("to-be-paid")}
      </Typography>
      <div className={classes.emptyRow} />
      {isHovered && (
        <div className={classes.actionIcons}>
          <ActionIcon
            onClick={onClickDownload}
            hoverBackgroundColor={"#F5FBFF"}
            isHoveredIconColor={"#5FB8F9"}
          >
            <DownloadIcon />
          </ActionIcon>
          <ActionIcon
            onClick={onClickPaid}
            hoverBackgroundColor={"rgba(139, 216, 92, 0.1)"}
            isHoveredIconColor={"rgba(139, 216, 92, 1)"}
          >
            <CheckIcon color="rgba(139, 216, 92, 0.1)" />
          </ActionIcon>
        </div>
      )}
    </div>
  );
};

export default InvoiceRow;
