import React from "react";
import { makeStyles } from "@mui/styles";
import { MainText } from "./MainText";

const useStyles = makeStyles(theme => ({
  pointer: {
    cursor: "pointer",
  },
}));

interface TextProps {
  text: string;
  style?: {};
  size?: "extraSmall" | "small" | "medium" | "large" | "extraLarge";
  isBold?: boolean;
  className?: string;
  onClick: () => void;
}

const PressableMainText = ({
  text,
  style,
  size = "medium",
  isBold = false,
  className = "",
  onClick = () => {},
}: TextProps) => {
  const classes = useStyles();

  return (
    <div className={classes.pointer} onClick={onClick}>
      <MainText
        text={text}
        style={style}
        size={size}
        isBold={isBold}
        className={className}
      />
    </div>
  );
};

export { PressableMainText };
