import React from "react";

const ReviewIcon = ({ sizePercentage, color, className, style = {} }) => (
  <svg
    className={className}
    style={style}
    width={sizePercentage * 13}
    height={sizePercentage * 16}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 13 16"
    version="1.1"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25 2H10.75C11.5781 2 12.25 2.67188 12.25 3.5V14.5C12.25 15.3281 11.5781 16 10.75 16H1.75C0.921875 16 0.25 15.3281 0.25 14.5V3.5C0.25 2.67188 0.921875 2 1.75 2H4.25C4.25 0.896875 5.14687 0 6.25 0C7.35313 0 8.25 0.896875 8.25 2ZM7 2C7 1.58438 6.66563 1.25 6.25 1.25C5.83437 1.25 5.5 1.58438 5.5 2C5.5 2.41563 5.83437 2.75 6.25 2.75C6.66563 2.75 7 2.41563 7 2ZM9.49422 8.23166L5.93013 11.476C5.81299 11.5836 5.62357 11.5813 5.50643 11.4738L3.44773 9.56785C3.33059 9.46032 3.33308 9.28643 3.45022 9.17889L4.15806 8.53368C4.2752 8.42614 4.46462 8.42843 4.58176 8.53597L5.72825 9.5976L8.37016 7.19063C8.4873 7.08309 8.67672 7.08538 8.79387 7.19292L9.49671 7.84271C9.61386 7.95253 9.61136 8.12413 9.49422 8.23166Z"
      fill={color}
    />
  </svg>
);

ReviewIcon.defaultProps = {
  sizePercentage: 1,
  color: "#fff",
};

export default ReviewIcon;
