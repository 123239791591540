import React from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography } from "@mui/material";
import i18n from "i18n";
import Colors from "constants/Colors";

const styles = theme => ({
  main: {
    borderRadius: 4,
    border: "solid 1px #E5E2E2",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  box: {
    borderRadius: 4,
    padding: "7px 18px",
    width: 80,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    transition: "all 0.2s ease",
    cursor: "pointer",
    color: "#C9C9C9",
    "&:hover": {
      backgroundColor: Colors.pureMain,
      color: "#fff",
    },
  },
  leftBox: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  rightBox: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  boxSelected: {
    color: "#fff",
    fontWeight: "bold",
    backgroundColor: Colors.pureMain,
  },
  text: {
    fontSize: 14,
    color: "inherit",
    fontWeight: "inherit",
  },
});

const Toggle = ({
  classes,
  toggleVisible = 0,
  onClickFirstBox = () => null,
  onClickSecondBox = () => null,
}) => {
  return (
    <div className={classes.main}>
      <div
        onClick={onClickFirstBox}
        className={`${classes.box} ${classes.leftBox} ${
          toggleVisible === 0 && classes.boxSelected
        }`}
      >
        <Typography className={classes.text}>{i18n.t("local")}</Typography>
      </div>
      <div
        onClick={onClickSecondBox}
        className={`${classes.box} ${classes.rightBox} ${
          toggleVisible === 1 && classes.boxSelected
        }`}
      >
        <Typography className={classes.text}>{i18n.t("mobile")}</Typography>
      </div>
    </div>
  );
};

export default withStyles(styles)(React.memo(Toggle));
