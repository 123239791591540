import {
  SIGNUP_CREATE_CLIENT_REQUESTING,
  SIGNUP_CREATE_USER_REQUESTING,
  SIGNUP_SEND_VERIFICATION_EMAIL_REQUESTING,
  SIGNUP_CONFIRM_EMAIL_REQUESTING,
  CHANGE_FIELD,
  SIGNUP_CLOSE_SNACK_MESSAGE,
  INVITATION_CREATE_USER_REQUESTING,
  SIGNUP_ONBOARDING_END_REQUESTING,
} from "./constants";

export const confirmEmailRequest = payload => {
  return {
    type: SIGNUP_CONFIRM_EMAIL_REQUESTING,
    payload,
  };
};

export const closeSnackMessage = () => {
  return {
    type: SIGNUP_CLOSE_SNACK_MESSAGE,
  };
};

export const changeField = payload => {
  return {
    type: CHANGE_FIELD,
    payload,
  };
};

export const createClientRequest = payload => {
  return {
    type: SIGNUP_CREATE_CLIENT_REQUESTING,
    payload,
  };
};

export const createUserRequest = payload => {
  return {
    type: SIGNUP_CREATE_USER_REQUESTING,
    payload,
  };
};

export const createInvitedUserRequest = payload => {
  return {
    type: INVITATION_CREATE_USER_REQUESTING,
    payload,
  };
};

export const onboardingEndRequest = payload => {
  return {
    type: SIGNUP_ONBOARDING_END_REQUESTING,
    payload,
  };
};

export const sendEmailVerificationRequest = payload => {
  return {
    type: SIGNUP_SEND_VERIFICATION_EMAIL_REQUESTING,
    payload,
  };
};
