import React from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography } from "@mui/material";
import Colors from "constants/Colors";
import RecapEmailButton from "./StatsSide/RecapEmailButton";

const styles = (theme): any => ({
  main: {
    width: "100%",
    marginTop: 30,
    marginBottom: 30,
    alignSelf: "center",
    maxWidth: "1200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  leftDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    cursor: "pointer",
    transition: "all 0.2s",
    borderRadius: 4,
    paddingLeft: 7,
    paddingRight: 10,
    paddingTop: 3,
    paddingBottom: 3,
    marginRight: 10,
    "&:hover": {
      backgroundColor: Colors.veryLightMain,
    },
  },
  middleDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    flex: 1,
  },
  title: {
    textAlign: "left",
    fontSize: 22,
    fontFamily: "Montserrat",
    fontWeight: "bold",
    color: Colors.blackGrey,
  },
  close: {
    fontSize: 18,
    textAlign: "right",
    color: "#282828",
    cursor: "pointer",
    transition: "all 0.2s",
    borderRadius: 4,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 2,
    paddingBottom: 2,
    marginRight: -5,
    "&:hover": {
      backgroundColor: Colors.veryLightMain,
    },
  },
});

const HeaderBar = ({
  classes,
  onClick = () => null,
  title,
  tabSelected = 0,
}) => {
  // ^^ probleme, on utilise pas onclick mais on lui passe un onclick
  return (
    <div className={classes.main}>
      <Typography className={classes.title}>{title}</Typography>
      <div className={classes.leftDiv}></div>
      <div className={classes.middleDiv} />
      {tabSelected === 2 && <RecapEmailButton />}
    </div>
  );
};

export default withStyles(styles)(HeaderBar);
