import React from "react";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Colors from "constants/Colors";

const useStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 30,
  },
  leftDiv: {
    height: 28,
    marginRight: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  bigLeftDiv: {},
  rightDiv: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  title: {
    fontWeight: 500,
    fontSize: 16,
    color: Colors.darkBlue,
  },
  info: {
    textTransform: "capitalize",
    fontSize: 16,
    color: "#797979",
  },
  bigTitle: {
    color: Colors.darkBlue,
    fontFamily: "Montserrat",
    fontSize: 19,
    fontWeight: "bold",
  },
  bigInfo: {
    fontSize: 18,
  },
}));

interface Props {
  icon: JSX.Element;
  title: string;
  info: string;
  isBig?: boolean;
}

const SummaryRow = ({ icon, title, info, isBig = false }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <div className={`${classes.leftDiv} ${isBig && classes.bigLeftDiv}`}>
        {icon}
      </div>
      <div className={classes.rightDiv}>
        <Typography className={`${classes.title} ${isBig && classes.bigTitle}`}>
          {title}
        </Typography>
        <Typography className={`${classes.info} ${isBig && classes.bigInfo}`}>
          {info}
        </Typography>
      </div>
    </div>
  );
};

export default SummaryRow;
