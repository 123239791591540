import React from "react";
import withStyles from '@mui/styles/withStyles';
import FeelingBox from "scenes/Campaigns/CampaignScreen/StatsSide/FeelingSection/FeelingBox";
import Colors from "constants/Colors";
import i18n from "i18n";

const styles = theme => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 10,
  },
});

const COLORS = [
  Colors.feeling1,
  Colors.feeling2,
  Colors.feeling3,
  Colors.feeling4,
];

const FeelingEvolution = ({
  classes,
  feelingEvolution = {},
  isCustomStats = false,
}) => {
  const displayFeelingBoxes = () => {
    if (!isCustomStats)
      return (
        <>
          <FeelingBox
            number={feelingEvolution["publicSpeaking"]}
            accentColor={Colors.feeling1}
            title={i18n.t("convince")}
          />
          <FeelingBox
            number={feelingEvolution["confidence"]}
            accentColor={Colors.feeling2}
            title={i18n.t("confidence")}
          />
          <FeelingBox
            number={feelingEvolution["stress"]}
            accentColor={Colors.feeling3}
            title={i18n.t("stress")}
          />
          <FeelingBox
            number={feelingEvolution["teamCohesion"]}
            accentColor={Colors.feeling4}
            title={i18n.t("cohesion")}
          />
        </>
      );

    return Object.keys(feelingEvolution).map((key, index) => {
      if (index < 4)
        return (
          <FeelingBox
            key={index}
            number={feelingEvolution[key]}
            accentColor={COLORS[index]}
            title={key}
          />
        );
      return null;
    });
  };

  return <div className={classes.main}>{displayFeelingBoxes()}</div>;
};

export default withStyles(styles)(FeelingEvolution);
