import React, { useState } from "react";
import withStyles from "@mui/styles/withStyles";
import Box from "components/Box";
import i18n from "i18n";
import BoxTitle from "components/BoxTitle";
import BoxHeader from "components/BoxHeader";
import Colors from "constants/Colors";
import { AddButtonBox, LightButton, Loader } from "components";
import NewResourceModal from "scenes/Campaigns/SeeUserModal/Coaching/Resources/NewResourceModal";
import LibraryResourcesModal from "scenes/Campaigns/SeeUserModal/Coaching/Resources/LibraryResourcesModal";
import LibraryIcon from "scenes/Campaigns/SeeUserModal/Coaching/Resources/LibraryIcon";
import ResourceBox from "scenes/Campaigns/SeeUserModal/Coaching/Resources/ResourceBox";
import {
  getCoachingResources,
  getCoachingResourcesWithDetails,
  getCoachingSessionId,
} from "services/coaching/selectors";
import {
  updateCoachingSessionRequest,
  updateCoachingSession,
} from "services/coaching/actions";
import {
  addResource,
  getLibraryResourcesRequest,
} from "services/resource/actions";
import { connect, useDispatch, useSelector } from "react-redux";
import { getUserId } from "services/user/selectors";
import EmptyText from "scenes/Campaigns/SeeUserModal/Coaching/RoadmapCoaching/EmptyText";

const styles = theme => ({
  main: {
    height: 360,
    marginLeft: 12.5,
    paddingBottom: 0,
  },
  body: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  withResources: {
    overflowY: "scroll",
    justifyContent: "flex-start",
  },
  firstResources: {
    alignSelf: "center",
    textAlign: "center",
    color: Colors.pureMain,
    fontSize: 18,
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  bold: {
    color: Colors.pureMain,
  },
  title: {
    flex: 1,
  },
  lightButton: {
    paddingLeft: 15,
    paddingRight: 15,
    height: 42,
    marginRight: 15,
  },
  icon: {
    marginRight: 10,
  },
  noBold: {
    fontWeight: "normal",
  },
});

const Resources = ({
  classes,
  resources = [],
  requesting,
  updateCoachingSessionRequest,
  getLibraryResourcesRequest,
  coachingSessionId,
  userId,
}) => {
  const [isResourceModalVisible, openResourceModal] = useState(false);
  const [
    isNewResourceModalOpenFromLibrary,
    setNewResourceModalOpenFromLibrary,
  ] = useState(false);
  const [isLibraryModalVisible, openLibraryModal] = useState(false);
  const [resourceSelected, setResourceSelected] = useState({});
  const coachingResources = useSelector(getCoachingResources);

  const dispatch = useDispatch();

  const onOpenLibraryModal = () => {
    getLibraryResourcesRequest({ userId });
    openLibraryModal(true);
  };

  const onSelectResource = resource => e => {
    e.stopPropagation();
    setResourceSelected(resource);
    onOpenResourceModal();
  };

  const onClickAddResource = resource => e => {
    e.stopPropagation();
    const resources = [{ resourceId: resource._id }, ...coachingResources];
    dispatch(addResource(resource));
    updateCoachingSessionRequest({
      coachingSessionId,
      resources,
    });
  };

  const onClickRemove = resourceId => e => {
    e.stopPropagation();

    const newResources = resources.filter(el => el.resourceId !== resourceId);
    updateCoachingSession({ resources: newResources });
    updateCoachingSessionRequest({
      coachingSessionId,
      resources: newResources,
    });
  };

  const onOpenResourceModal = () => {
    openResourceModal(true);
    setNewResourceModalOpenFromLibrary(false);
  };

  const onOpenResourceModalFromLibrary = () => {
    openResourceModal(true);
    setNewResourceModalOpenFromLibrary(true);
  };

  return (
    <>
      <LibraryResourcesModal
        onClickAdd={onClickAddResource}
        isVisible={isLibraryModalVisible}
        onClose={() => openLibraryModal(false)}
        onClickNewResource={onOpenResourceModalFromLibrary}
      />
      <NewResourceModal
        isCreatedFromLibrary={isNewResourceModalOpenFromLibrary}
        setResourceSelected={setResourceSelected}
        resourceSelected={resourceSelected}
        isVisible={isResourceModalVisible}
        onClose={() => openResourceModal(false)}
      />
      <Box className={classes.main} intercomTarget={"coaching-resources"}>
        <BoxHeader className={classes.box}>
          <BoxTitle className={classes.title}>
            {i18n.t("resources")}
            <span className={classes.noBold}> ({resources.length})</span>
          </BoxTitle>
          <LightButton
            icon={<LibraryIcon className={classes.icon} />}
            title={i18n.t("library")}
            className={classes.lightButton}
            onClick={onOpenLibraryModal}
          />
          <AddButtonBox onClick={onOpenResourceModal} />
        </BoxHeader>
        <div
          className={`${classes.body} ${
            resources.length > 0 && classes.withResources
          }`}
        >
          {resources.length < 1 && (
            <EmptyText
              onClick={onOpenResourceModal}
              className={classes.firstResources}
              firstTextPart={`${i18n.t("add")} ${i18n.t("first-resources")}`}
            />
          )}
          {requesting ? (
            <Loader size={30} />
          ) : (
            resources.map((el, index) => (
              <ResourceBox
                {...el}
                key={index}
                onClick={onSelectResource(el)}
                onClickEdit={onSelectResource(el)}
                onClickRemove={onClickRemove(el.resourceId)}
                isLast={index === resources.length - 1}
              />
            ))
          )}
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = state => {
  return {
    resources: getCoachingResourcesWithDetails(state),
    requesting: state.resource.requesting,
    coachingSessionId: getCoachingSessionId(state),
    userId: getUserId(state),
  };
};

export default connect(mapStateToProps, {
  updateCoachingSessionRequest,
  updateCoachingSession,
  getLibraryResourcesRequest,
})(withStyles(styles)(Resources));
