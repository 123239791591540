import React from "react";
import { makeStyles } from "@mui/styles";
import LinearProgressBar from "components/LinearProgressBar";
import StarRate from "components/StarRate";
import { MainText } from "components/Texts/MainText";
import i18n from "i18n";
import useReviewStyle from "../review.css";

const useStyle = makeStyles({
  nameRate: {
    textAlign: "start",
    flex: 1,
  },
  namePercentage: {
    textAlign: "end",
    flex: 1,
  },
  ratingContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },
});

export interface IActionRatingProps {
  rating: number;
  donePercentage: number;
}

const ActionRating = ({
  rating = 0,
  donePercentage = 0,
}: IActionRatingProps) => {
  const classes = useStyle();
  const classesReviewStyle = useReviewStyle();

  return (
    <>
      <div
        className={`${classes.ratingContainer} ${classesReviewStyle.elementSeparator}`}
      >
        <MainText
          className={`${classes.nameRate} ${classesReviewStyle.text}`}
          text={i18n.t("challenge-note-rate")}
        />
        <StarRate rate={rating} size={0.6} />
        <MainText
          className={`${classes.namePercentage} ${classesReviewStyle.text}`}
          text={rating?.toString() + "/5"}
        />
      </div>
      <div
        className={`${classes.ratingContainer} ${classesReviewStyle.elementSeparator}`}
      >
        <MainText
          className={`${classes.nameRate} ${classesReviewStyle.text}`}
          text={i18n.t("challenge-success-rate")}
        />
        <LinearProgressBar percentage={donePercentage} />
        <MainText
          className={`${classes.namePercentage} ${classesReviewStyle.text}`}
          text={donePercentage?.toString() + "%"}
        />
      </div>
    </>
  );
};

export default ActionRating;
