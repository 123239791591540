import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Timer from "./Timer";
import { MainText } from "components/Texts/MainText";
import Colors from "constants/Colors";
import i18n from "i18n";
import FlatButton from "components/FlatButton";

const useStyles = makeStyles(theme => ({
  main: {},
  stopRecord: {
    height: "2.5rem",
    minWidth: "2.5rem",
    width: "auto",
    padding: "0.5rem",
    marginRight: "1rem",
    borderRadius: "0.25rem",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.04)",
    outline: "0",
    border: "1px solid #e6e9ef",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    transition: "all .05s ease-in-out",
    cursor: "pointer",
    "&:hover": { backgroundColor: Colors.hoverBlue },
  },
  bottomDiv: {
    width: "95%",
    display: "flex",
    alignSelf: "flex-start",
    marginTop: "2.5%",
    marginBottom: "2.5%",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: "2.5%",
  },
  startIcon: {
    borderColor: "red",
    border: "solid",
    width: 12,
    height: 12,
    borderWidth: 2,
    borderRadius: "50%",
    marginRight: "0.5rem",
    cursor: "pointer",
    transition: "all 0.2s ease",
  },
  recordingIcon: {
    backgroundColor: "red",
  },
  smallText: {
    color: Colors.text,
    textAlign: "center",
  },
  submit: {},
  spaceDiv: {
    display: "flex",
    flex: "1",
  },
}));

interface Props {
  mediaStatus: string;
  onClick: () => void;
  onClickSubmit: () => void;
  requesting: boolean;
}

const getStatusText = mediaStatus => {
  if (mediaStatus === "recording") return i18n.t("stop-recording");
  if (mediaStatus === "paused") return i18n.t("play-recording");
  if (mediaStatus === "stopped") return i18n.t("cancel-recording");
};

const RecordBottomBar = ({
  mediaStatus,
  onClick,
  onClickSubmit,
  requesting = false,
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.bottomDiv}>
      <div className={classes.stopRecord} onClick={onClick}>
        <div
          className={`${classes.startIcon} ${
            mediaStatus === "recording" && classes.recordingIcon
          }`}
        />
        <MainText
          text={getStatusText(mediaStatus)}
          className={classes.smallText}
        />
      </div>
      <Timer isPlaying={mediaStatus === "recording"} />
      <div className={classes.spaceDiv} />
      {mediaStatus === "stopped" && (
        <FlatButton
          requesting={requesting}
          title={i18n.t("send")}
          onClick={onClickSubmit}
          className={classes.submit}
        />
      )}
    </div>
  );
};

export default RecordBottomBar;
