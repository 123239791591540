import React from "react";
import { Typography } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const ROW_HEIGHT = 50;

const useStyles = makeStyles(theme => ({
  main: {
    width: "100%",
    position: "relative",
    height: ROW_HEIGHT,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  line: {
    position: "absolute",
    top: 0,
    height: 1,
    background: "#EBEBEB",
    width: "100%",
  },
  title: {
    color: "#101534",
  },
  result: {
    color: "#16193F",
    fontFamily: "Avenir",
    fontWeight: "bold",
  },
}));

interface Props {
  title: string;
  result: string;
  hasSeparator?: boolean;
}

const SummaryRow = ({ title, result, hasSeparator = true }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      {hasSeparator && <div className={classes.line} />}
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.result}>{result}</Typography>
    </div>
  );
};

export default SummaryRow;
