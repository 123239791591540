import React from "react";

const NotFound = () => {
  return (
    <div>
      <h1>Rien par ici <span role="img" aria-label="angel smile">😇</span></h1>
    </div>
  );
};

export default NotFound;
