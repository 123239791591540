import React from "react";

import Typography from "@mui/material/Typography";

import Colors from "../../../constants/Colors";
import makeStyles from "@mui/styles/makeStyles";

const useStyle = makeStyles({
  main: {
    width: "100%",
    marginRight: 30,
    maxWidth: 350,
  },
  box: {
    padding: "20px 25px",
    position: "relative",
    border: "solid",
    borderColor: "#DEDEDE",
    borderWidth: 0,
    // width: "100%",
    display: "flex",
    // height: 120,
    backgroundColor: "#fff",
    borderRadius: 10,
    alignItems: "center",
    flexDirection: "row",
    transition: "all 0.2s ease-in-out",
  },
  legend: {
    textAlign: "left",
    color: "#909090",
    fontSize: 18,
    marginTop: 2,
  },
  stat: {
    fontFamily: "Montserrat",
    textAlign: "left",
    fontSize: 20,
    fontWeight: "bold",
    lineHeight: 1,
    color: Colors.text,
  },
  bottomChart: {
    height: 54,
    zIndex: 1,
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
  },
  lastBox: {
    marginRight: 0,
  },
  circle: {
    width: 75,
    height: 75,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: 10,
  },
  textDiv: {
    height: 75,
    marginLeft: 25,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
  },
  menu: {
    width: "100%",
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  subTitle: {
    color: "#BABCC5",
    fontSize: 16,
  },
});

const StatBox = ({
  onClick = () => null,
  title,
  stat,
  lightColor = Colors.veryLightMain,
  isLast = false,
  icon = null,
  subTitle = "",
  hoverComponent = null,
}) => {
  const classes = useStyle();
  const [isHover, setIsHover] = React.useState(false);

  return (
    <div className={`${classes.main} ${isLast && classes.lastBox}`}>
      <div
        onClick={hoverComponent ? () => setIsHover(prev => !prev) : onClick}
        className={`${classes.box}`}
      >
        {isHover && !!hoverComponent && hoverComponent}

        {!(isHover && hoverComponent) && (
          <>
            <div
              className={classes.circle}
              style={{ backgroundColor: lightColor }}
            >
              {icon}
            </div>
            <div className={classes.textDiv}>
              <Typography className={classes.stat}>{stat}</Typography>
              <Typography className={classes.legend}>
                {title}
                <span className={classes.subTitle}>{subTitle}</span>
              </Typography>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

StatBox.defaultProps = {
  chartData: [
    {
      id: "users",
      data: [
        {
          x: "Septembre 8",
          y: 1,
        },
        { x: "Septembre 9", y: 3 },
        { x: "Septembre 10", y: 5 },
        { x: "Septembre 11", y: 7 },
        { x: "Septembre 12", y: 5 },
        { x: "Septembre 13", y: 8 },
      ],
    },
  ],
  chartColor: Colors.main,
};

export default StatBox;
