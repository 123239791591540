import {
  RESET_PASSWORD_REQUESTING,
  SEND_EMAIL_INVITATIONS_REQUESTING,
} from "./constants";

interface mailPayload {
  emails: { role: String; email: String }[];
  clientId: String;
  firstName: String;
  lastName?: String;
  dynamicLink?: String;
  accessCode?: String;
  userId?: String;
  language?: String;
}

export const resetPasswordRequest = payload => {
  return {
    type: RESET_PASSWORD_REQUESTING,
    payload,
  };
};

export const sendEmailInvitationsRequest = (payload: mailPayload) => {
  return {
    type: SEND_EMAIL_INVITATIONS_REQUESTING,
    payload,
  };
};
