import React from "react";

const IphoneMockup = ({ sizePercentage, style, color, children }) => {
  return (
    <svg
      style={style}
      width={sizePercentage * 361}
      height={sizePercentage * 718}
      // style={{ height: "auto" }}
      viewBox="0 0 361 718"
      version="1.1"
      preserveAspectRatio="xMidYMin meet"
    >
      <defs>
        <linearGradient
          x1="-21.5256374%"
          y1="14.8348886%"
          x2="121.525637%"
          y2="85.1651114%"
          id="linearGradient-1"
        >
          <stop stopColor="#CCCCCC" offset="0%" />
          <stop stopColor="#808080" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-23.1308284%"
          y1="14.8389474%"
          x2="123.130828%"
          y2="85.1610526%"
          id="linearGradient-2"
        >
          <stop stopColor="#666666" offset="0%" />
          <stop stopColor="#000000" offset="100%" />
        </linearGradient>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        // fill="none"
        fill={color}
        fillRule="evenodd"
      >
        <g id="iphone-x-minimal-buttons">
          <path
            d="M357,52.1 C357,23.3 333.7,0 304.9,0 L56.1,0 C27.3,7.10542736e-15 4,23.3 4,52.1 L4,665.9 C4,694.7 27.3,718 56.1,718 L304.9,718 C333.7,718 357,694.7 357,665.9 L357,52.1 Z M349,665.4 C349,690 329,710 304.4,710 L55.6,710 C31,710 11,690 11,665.4 L11,51.6 C11,27 31,7 55.6,7 L304.4,7 C329,7 349,27 349,51.6 L349,665.4 Z"
            id="XMLID_428_"
            fill="url(#linearGradient-1)"
            fillRule="nonzero"
          />
          <polyline
            id="XMLID_271_"
            fill="#CCCCCC"
            fillRule="nonzero"
            points="4 94.4 0.2 94.4 0.2 121.6 4 121.6"
          />
          <polyline
            id="XMLID_270_"
            fill="#CCCCCC"
            fillRule="nonzero"
            points="357 246.5 360.8 246.5 360.8 162.9 357 162.9"
          />
          <g
            id="XMLID_267_"
            transform="translate(0.000000, 146.000000)"
            fill="#CCCCCC"
            fillRule="nonzero"
          >
            <polyline id="XMLID_269_" points="4 0.7 0.2 0.7 0.2 51.5 4 51.5" />
            <polyline
              id="XMLID_268_"
              points="4 65.9 0.2 65.9 0.2 116.8 4 116.8"
            />
          </g>
          <path
            d="M305.9,19 L277.4,19 C271.3,19 269.1,18.9 268.7,26.6 C268.3,34.6 262.8,39.1 255.4,41.4 C252.9,42.2 250.1,42 247.4,42 L242,42 L121.2,42 L115.8,42 C113.1,42 110.3,42.3 107.8,41.5 C100.4,39.2 94.8,34.5 94.5,26.5 C94.1,18.8 91.9,19 85.8,19 L57,19 C39.4,19 26,33 26,50.5 L26,663.8 C26,681.3 39.4,695 56.9,695 L305.9,695 C323.4,695 338,681.2 338,663.8 L338,50.5 C338,33 323.4,19 305.9,19 Z"
            id="XMLID_425_"
          />
          <path
            d="M349,51.6 C349,27 329,7 304.4,7 L55.6,7 C31,7 11,27 11,51.6 L11,665.4 C11,690 31,710 55.6,710 L304.4,710 C329,710 349,690 349,665.4 L349,51.6 Z M221.7,24.1 C221.7,26.5 219.7,28.5 217.3,28.5 C214.9,28.5 212.9,26.5 212.9,24.1 C212.9,21.7 214.9,19.7 217.3,19.7 C219.8,19.8 221.7,21.7 221.7,24.1 Z M162.2,22 L201.2,22 C202.9,22 204.2,23 204.2,24.5 C204.2,26 202.9,27 201.2,27 L162.2,27 C160.5,27 159.2,26 159.2,24.5 C159.2,23 160.6,22 162.2,22 Z M338,663.8 C338,681.3 323.4,695 305.9,695 L56.9,695 C39.4,695 26,681.2 26,663.8 L26,50.5 C26,33 39.4,19 56.9,19 L85.7,19 C91.8,19 94,18.9 94.4,26.6 C94.8,34.6 100.3,39.1 107.7,41.4 C110.2,42.2 113,42 115.7,42 L121.1,42 L241.8,42 L247.2,42 C249.9,42 252.7,42.3 255.2,41.5 C262.6,39.2 268.2,34.5 268.5,26.5 C268.9,18.8 271.1,19 277.2,19 L305.7,19 C323.2,19 337.8,33 337.8,50.5 L337.8,663.8 L338,663.8 Z"
            id="XMLID_437_"
            fill="url(#linearGradient-2)"
            fillRule="nonzero"
          />
          <circle
            id="XMLID_429_"
            fill="#333333"
            fillRule="nonzero"
            cx="217.3"
            cy="24.1"
            r="4.4"
          />
          <path
            d="M162.2,27 L201.2,27 C202.9,27 204.2,26 204.2,24.5 C204.2,23 202.9,22 201.2,22 L162.2,22 C160.5,22 159.2,23 159.2,24.5 C159.2,26 160.6,27 162.2,27 Z"
            id="XMLID_430_"
            fill="#333333"
            fillRule="nonzero"
          />
        </g>
      </g>
      {children}
    </svg>
  );
};

IphoneMockup.defaultProps = {
  sizePercentage: 1,
  color: "#EEF2F9",
};

export default React.memo(IphoneMockup);
