import React from "react";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import i18n from "i18n";
import Colors from "constants/Colors";

const useStyles = makeStyles(theme => ({
  link: {
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    textDecoration: "none",
    width: "100%",
    textAlign: "center",
    color: Colors.pureMain,
    fontSize: 12,
    "&:hover": {
      textDecoration: "underline",
      transition: "all 0.2s ease",
    },
  },
}));

const MyCalendarLink = () => {
  const classes = useStyles();

  return (
    <a
      className={classes.link}
      target="_blank"
      rel="noopener noreferrer"
      href={`https://calendar.google.com/calendar/`}
    >
      <Typography>{i18n.t("open-google-calendar")}</Typography>
    </a>
  );
};

export default MyCalendarLink;
