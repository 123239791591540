import React, { useRef } from "react";
import { TextField, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import i18n from "i18n";
import {
  useConnectGoogleCalendar,
  useSendCalendarInvitation,
} from "scenes/Campaigns/SeeUserModal/Coaching/Sessions/hooks";
import { getGoogleTokens } from "services/user/selectors";
import { useSelector } from "react-redux";
import Colors from "constants/Colors";
import GoogleCalendarButton from "scenes/Campaigns/SeeUserModal/Coaching/Sessions/GoogleCalendarButton";
import displayMeetingDate from "utils/displayMeetingDate";
import { getAppLanguage } from "services/general/selectors";

const useStyles = makeStyles(theme => ({
  main: {},
  summary: {
    backgroundColor: "#F5F5F5",
    marginTop: 0,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 4,
    paddingLeft: 15,
    paddingRight: 25,
  },
  message: {
    marginTop: 15,
    backgroundColor: "#F5F5F5",
    paddingTop: 15,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 25,
    borderRadius: 4,
    minHeight: 150,
  },
  summaryText: {
    fontSize: 18,
  },
  messageText: {
    fontSize: 16,
  },
  error: {
    color: Colors.red,
  },
  emailError: {
    color: Colors.red,
    marginBottom: "10px",
    marginTop: "-20px",
  },
  text: {
    marginBottom: 15,
    fontSize: 16,
    fontWeight: 500,
    color: Colors.darkBlue,
  },
  email: {
    marginBottom: 30,
  },
  dateDiv: {
    marginBottom: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  date: {
    fontSize: 16,
    fontWeight: 500,
    color: Colors.darkBlue,
  },
  updateText: {
    textTransform: "capitalize",
    fontSize: 16,
    fontWeight: 500,
    color: Colors.pureMain,
    cursor: "pointer",
  },
  calendarButton: {},
}));

interface Props {
  summary: string;
  email: string;
  date: Date;
  duration: number;
  message: string;
  isPlannedWithGoogleCalendar: boolean;
  googleCalendarEventId: string;
  onClickUpdateDate: () => void;
  onChangeIsPlannedWithGoogleCalendar: (isPlanned: boolean) => () => void;
  onChangeGoogleCalendarEventId: (googleCalendarEventId: string) => void;
  onChangeMessage: () => void;
  onChangeSummary: () => void;
  onChangeEmail: () => void;
}

const InviteCoacheeAgendaStep = ({
  summary,
  email,
  date,
  duration,
  message,
  isPlannedWithGoogleCalendar,
  googleCalendarEventId,
  onClickUpdateDate,
  onChangeSummary,
  onChangeMessage,
  onChangeEmail,
  onChangeGoogleCalendarEventId,
  onChangeIsPlannedWithGoogleCalendar,
}: Props) => {
  const classes = useStyles();
  const inputRef = useRef(null);
  const googleTokens = useSelector(getGoogleTokens);
  const language = useSelector(getAppLanguage);

  const [
    requesting,
    connectGoogleCalendarRequest,
    error,
  ] = useConnectGoogleCalendar();

  const [
    invitationRequesting,
    sendCalendarInvitationRequest,
    sendUpdateCalendarInvitationRequest,
    errorSendInvitation,
    setError,
  ] = useSendCalendarInvitation();

  const onClickGoogle = async () => {
    if (
      error?.message === "Invalid Credentials" ||
      errorSendInvitation?.message === "invalid_grant" ||
      !googleTokens
    ) {
      setError({ message: "" });
      return await connectGoogleCalendarRequest();
    }
    try {
      if (googleCalendarEventId) {
        await sendUpdateCalendarInvitationRequest({
          googleCalendarEventId,
          email,
          date,
          duration,
          summary,
          message,
        });
      } else {
        const response = await sendCalendarInvitationRequest({
          email,
          date,
          duration,
          summary,
          message,
        });
        console.log({ response });
        const googleCalendarEventId = response?.data?.id;
        onChangeGoogleCalendarEventId(googleCalendarEventId);
      }
      onChangeIsPlannedWithGoogleCalendar(true)();
    } catch (error) {
      onChangeIsPlannedWithGoogleCalendar(false)();
    }

    return;
  };

  const handleClickInput = () => {
    if (inputRef && inputRef.current) {
      return inputRef?.current?.focus();
    }
  };

  return (
    <div className={classes.main}>
      <div className={classes.dateDiv}>
        <Typography className={classes.date}>
          {displayMeetingDate({
            date,
            durationInMinutes: duration,
            language,
            timezone: "Europe/Paris",
          })}
        </Typography>
        <Typography className={classes.updateText} onClick={onClickUpdateDate}>
          {i18n.t("modify")}
        </Typography>
      </div>
      <Typography className={classes.text}>
        {i18n.t("coachee-email")}
      </Typography>
      <TextField
        InputProps={{
          disableUnderline: true,
          classes: {
            input: classes.summaryText,
          },
        }}
        variant="standard"
        type="email"
        className={`${classes.summary} ${classes.email}`}
        fullWidth
        value={email}
        onChange={onChangeEmail}
        placeholder={i18n.t("email-label")}
      />
      {errorSendInvitation?.message === "Email is invalid" && (
        <Typography className={classes.emailError}>
          {i18n.t("email-invalid")}
        </Typography>
      )}
      <Typography className={classes.text}>
        {i18n.t("invitation-message")}
      </Typography>
      <TextField
        InputProps={{
          disableUnderline: true,
          classes: {
            input: classes.summaryText,
          },
        }}
        className={classes.summary}
        value={summary}
        placeholder={i18n.t("invitation-summary")}
        onChange={onChangeSummary}
        fullWidth
        margin="normal"
        variant="standard"
      />
      <TextField
        inputRef={inputRef}
        InputProps={{
          disableUnderline: true,
          classes: {
            input: classes.messageText,
          },
        }}
        className={classes.message}
        value={message}
        onClick={handleClickInput}
        multiline
        placeholder={i18n.t("invitation-message-placeholder")}
        onChange={onChangeMessage}
        fullWidth
        margin="normal"
        variant="standard"
      />
      <GoogleCalendarButton
        className={classes.calendarButton}
        hasToken={
          error?.message === "Invalid Credentials" ||
          errorSendInvitation?.message === "invalid_grant"
            ? false
            : !!googleTokens
        }
        requesting={requesting || invitationRequesting}
        onClick={onClickGoogle}
      />
      {!!error && (
        <Typography className={classes.error}>{error?.message}</Typography>
      )}
      {!!errorSendInvitation &&
        errorSendInvitation?.message !== "Email is invalid" && (
          <Typography className={classes.error}>
            {errorSendInvitation?.message}
          </Typography>
        )}
    </div>
  );
};

export default InviteCoacheeAgendaStep;
