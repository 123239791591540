import { io } from "socket.io-client";

import config from "./api/config";
const { url } = config;

const socket = io.connect(url, {
  extraHeaders: {
    "my-custom-header": "abcd",
  },
});

export default socket;
