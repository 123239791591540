import React from "react";
import withStyles from '@mui/styles/withStyles';

import Colors from "../../../constants/Colors";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Feedback from "../../Recap/Feedback";
import { Typography } from "@mui/material";
import i18n from "../../../i18n";

const styles = theme => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    alignItems: "center",
    display: "flex",
    // minWidth: "50%",
    minHeight: "66%",
    justifyContent: "center",
    lineHeight: 1.46666667,
    overflowY: "auto",
    padding: "3vh 16px 32px 16px",
    borderRight: ".25rem solid transparent",
    borderRadius: 12,
  },
  exitModalText: {
    marginTop: -4,
    fontSize: 13,
  },
  title: {
    // color: Colors.black,
    fontFamily: "Montserrat",
    alignSelf: "center",
    marginBottom: 10,
    fontSize: 28,
    lineHeight: 1.2143,
    fontWeight: "bold",
  },
  closeButton: {
    width: 64,
    borderRadius: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    zIndex: 10,
    position: "absolute",
    // marginRight: 16,
    top: 5,
    right: 5,
    "&:hover": {
      color: Colors.black,
    },
  },
  comments: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginBottom: 20,
  },
  comment: {
    // width: "100%",
    width: 400,
    // maxWidth: 400,
    alignSelf: "center",
  },
  bodyDiv: {
    width: "100%",
  },
});

const sortComments = comments => {
  return comments.sort((a, b) => b.length - a.length);
};

const CommentsModal = ({ classes, isVisible, onClose, comments = [] }) => {
  return (
    <div className={classes.main}>
      <Dialog
        open={isVisible}
        onClose={onClose}
        classes={{ paper: classes.paper }}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        <IconButton
          className={classes.closeButton}
          classes={{ label: classes.labelIconButton }}
          onClick={onClose}
          size="large">
          <CloseIcon fontSize="large" />
        </IconButton>
        <Typography className={classes.title}>{i18n.t("Comments")}</Typography>

        <DialogContent className={classes.bodyDiv}>
          <div className={classes.comments}>
            {sortComments(comments).map((el, index) => (
              <Feedback key={index} comment={el} className={classes.comment} />
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(CommentsModal);
