import React from "react";
import withStyles from "@mui/styles/withStyles";
import { Typography } from "@mui/material";
import Colors from "constants/Colors";

const styles = theme => ({
  main: {},
  empty: {
    height: "100%",
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    alignSelf: "center",
    textAlign: "center",
    color: "#A8A8A8",
    fontSize: 18,
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": {
      textDecoration: "underline",
      color: Colors.pureMain,
    },
  },
  bold: {
    color: Colors.pureMain,
  },
});

const EmptyText = ({
  className = "",
  classes,
  firstTextPart,
  secondTextPart = "",
  onClick = () => null,
}) => {
  return (
    <Typography className={`${classes.empty} ${className}`} onClick={onClick}>
      <span className={classes.bold}>{firstTextPart} </span>&nbsp;
      {secondTextPart}
    </Typography>
  );
};

export default withStyles(styles)(EmptyText);
