import React from "react";

const FillPremiumIcon = ({ sizePercentage }) => (
  <svg
    width={sizePercentage * 25}
    height={sizePercentage * 25}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 25"
    version="1.1"
  >
    <path
      d="M7.20186 4.4004C7.17815 4.40018 7.15476 4.40586 7.13379 4.41694C7.11282 4.42801 7.09494 4.44413 7.08175 4.46384C7.06857 4.48355 7.06049 4.50623 7.05825 4.52983C7.05602 4.55344 7.05969 4.57723 7.06894 4.59907L7.15112 4.79309L8.60542 8.18406C8.6153 8.20713 8.63108 8.22718 8.65117 8.24221C8.67126 8.25724 8.69496 8.26672 8.71987 8.26969C8.74479 8.27266 8.77005 8.26902 8.79311 8.25913C8.81617 8.24924 8.83622 8.23346 8.85125 8.21336L11.5354 4.62908C11.5514 4.60785 11.5611 4.5826 11.5635 4.55616C11.5658 4.52972 11.5608 4.50315 11.5489 4.47941C11.5371 4.45566 11.5188 4.4357 11.4962 4.42174C11.4737 4.40779 11.4476 4.4004 11.4211 4.4004H7.20186Z"
      fill="#fff"
    />
    <path
      d="M17.8576 5.33149L16.3826 8.77499C16.3733 8.79672 16.3695 8.82043 16.3717 8.84397C16.3738 8.86751 16.3817 8.89015 16.3948 8.90987C16.4078 8.92959 16.4255 8.94578 16.4463 8.95697C16.4672 8.96816 16.4904 8.97402 16.5141 8.97401H20.123C20.1485 8.97402 20.1735 8.96722 20.1955 8.95431C20.2174 8.9414 20.2356 8.92285 20.248 8.90058C20.2603 8.87832 20.2666 8.85314 20.2659 8.82766C20.2653 8.80219 20.2579 8.77734 20.2445 8.75569L18.1102 5.31255C18.0964 5.29038 18.0768 5.27242 18.0535 5.2606C18.0303 5.24878 18.0042 5.24357 17.9782 5.24552C17.9521 5.24747 17.9271 5.25651 17.9059 5.27167C17.8846 5.28682 17.8679 5.30751 17.8576 5.33149Z"
      fill="#fff"
    />
    <path
      d="M5.88944 5.31413L3.75588 8.75584C3.74244 8.77749 3.73503 8.80234 3.73442 8.82782C3.73381 8.85329 3.74002 8.87847 3.75241 8.90074C3.7648 8.92301 3.78292 8.94156 3.80489 8.95447C3.82686 8.96738 3.85188 8.97418 3.87737 8.97417H7.4863C7.50996 8.97414 7.53323 8.96824 7.55404 8.957C7.57485 8.94577 7.59255 8.92954 7.60555 8.90978C7.61855 8.89002 7.62644 8.86734 7.62851 8.84378C7.63059 8.82022 7.62678 8.79651 7.61744 8.77478L6.13956 5.33307C6.12899 5.30969 6.11233 5.28959 6.09131 5.27487C6.07029 5.26016 6.0457 5.25137 6.02012 5.24943C5.99453 5.24749 5.9689 5.25248 5.9459 5.26386C5.92291 5.27525 5.90341 5.29261 5.88944 5.31413Z"
      fill="#fff"
    />
    <path
      d="M16.9049 4.40039H12.4223C12.4094 4.40031 12.3967 4.40385 12.3856 4.4106C12.3746 4.41736 12.3657 4.42707 12.3599 4.43863C12.3541 4.45019 12.3516 4.46315 12.3528 4.47603C12.354 4.48891 12.3588 4.5012 12.3666 4.51152L15.2223 8.31555C15.2296 8.32532 15.2393 8.333 15.2505 8.33781C15.2617 8.34261 15.274 8.34438 15.2861 8.34293C15.2982 8.34148 15.3097 8.33687 15.3194 8.32955C15.3292 8.32223 15.3368 8.31248 15.3416 8.30126L16.8452 4.79344L16.9667 4.49544C16.971 4.48521 16.9727 4.4741 16.9718 4.46305C16.9708 4.452 16.9672 4.44135 16.9612 4.43201C16.9553 4.42266 16.9471 4.41491 16.9375 4.4094C16.9279 4.40389 16.917 4.4008 16.906 4.40039H16.9049Z"
      fill="#fff"
    />
    <path
      d="M11.3926 19.2651L8.19713 10.2074C8.18774 10.1811 8.17044 10.1583 8.14759 10.1423C8.12475 10.1262 8.09749 10.1176 8.06956 10.1177H3.771C3.7461 10.1176 3.72168 10.1245 3.70052 10.1377C3.67936 10.1508 3.66232 10.1696 3.65133 10.192C3.64035 10.2143 3.63586 10.2393 3.63838 10.2641C3.6409 10.2888 3.65033 10.3124 3.66559 10.3321L11.3837 20.3303C11.4098 20.3641 11.4471 20.3876 11.4889 20.3965C11.5307 20.4055 11.5743 20.3994 11.612 20.3792C11.65 20.3593 11.6794 20.3263 11.6951 20.2865C11.7108 20.2466 11.7116 20.2024 11.6974 20.162L11.3926 19.2651Z"
      fill="#fff"
    />
    <path
      d="M20.2287 10.1177H15.9302C15.9024 10.1177 15.8753 10.1263 15.8526 10.1424C15.83 10.1584 15.8129 10.1811 15.8037 10.2074L12.2948 20.1466C12.2801 20.1883 12.2807 20.2339 12.2965 20.2752C12.3124 20.3166 12.3424 20.3509 12.3813 20.3721C12.4201 20.3931 12.4652 20.3997 12.5085 20.3906C12.5517 20.3815 12.5904 20.3573 12.6175 20.3224L20.3342 10.3321C20.3494 10.3124 20.3588 10.2888 20.3614 10.2641C20.3639 10.2393 20.3594 10.2143 20.3484 10.192C20.3374 10.1696 20.3204 10.1508 20.2992 10.1377C20.2781 10.1245 20.2536 10.1176 20.2287 10.1177Z"
      fill="#fff"
    />
    <path
      d="M12.1148 6.07719L14.1158 8.7453C14.1317 8.76654 14.1414 8.79179 14.1438 8.81822C14.1462 8.84466 14.1412 8.87124 14.1293 8.89498C14.1174 8.91872 14.0992 8.93869 14.0766 8.95264C14.054 8.9666 14.028 8.97399 14.0015 8.97399H9.99948C9.97294 8.97399 9.94692 8.9666 9.92434 8.95264C9.90176 8.93869 9.88351 8.91872 9.87164 8.89498C9.85977 8.87124 9.85474 8.84466 9.85713 8.81822C9.85951 8.79179 9.86921 8.76654 9.88514 8.7453L11.8861 6.07719C11.8994 6.05944 11.9167 6.04503 11.9366 6.03511C11.9564 6.02519 11.9783 6.02002 12.0005 6.02002C12.0227 6.02002 12.0445 6.02519 12.0644 6.03511C12.0842 6.04503 12.1015 6.05944 12.1148 6.07719ZM11.8647 17.1652L9.44563 10.3079C9.43804 10.2863 9.43571 10.2633 9.43887 10.2406C9.44202 10.218 9.45055 10.1965 9.46374 10.1778C9.47693 10.1592 9.49441 10.1439 9.51469 10.1334C9.53498 10.1229 9.55749 10.1174 9.58034 10.1174H14.4206C14.4435 10.1174 14.466 10.1229 14.4863 10.1334C14.5065 10.1439 14.524 10.1592 14.5372 10.1778C14.5504 10.1965 14.5589 10.218 14.5621 10.2406C14.5652 10.2633 14.5629 10.2863 14.5553 10.3079L12.1352 17.1652C12.1253 17.193 12.1071 17.2171 12.0829 17.2342C12.0588 17.2512 12.03 17.2604 12.0005 17.2604C11.9709 17.2604 11.9421 17.2512 11.918 17.2342C11.8939 17.2171 11.8756 17.193 11.8658 17.1652H11.8647Z"
      fill="#fff"
    />
  </svg>
);

FillPremiumIcon.defaultProps = {
  sizePercentage: 1,
};

export default FillPremiumIcon;
