import React from "react";

const ReviewPendingIcon = ({
  sizePercentage = 1,
  color = "#F9B44D",
  className,
}) => (
  <svg
    className={className}
    width={sizePercentage * 21}
    height={sizePercentage * 21}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 21 21"
    fill={"transparent"}
  >
    <path
      d="M1 10.5C1 15.7468 5.25315 20 10.5 20C15.7468 20 20 15.7468 20 10.5C20 5.25315 15.7468 1 10.5 1"
      stroke="#F9B44D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.2 10.5C16.2 7.35216 13.6478 4.79999 10.5 4.79999C7.35216 4.79999 4.79999 7.35216 4.79999 10.5C4.79999 13.6478 7.35216 16.2 10.5 16.2"
      stroke="#F9B44D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ReviewPendingIcon;
