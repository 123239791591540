import { useCallback, useEffect } from "react";

export function useKeyboardEvent(key, callback, params = []) {
  
  const handler = useCallback((event) => {
    if (event.key === key) {
      callback();
    }
  }, [key, callback]);

  useEffect(() => {
    window.addEventListener("keydown", handler);
    return () => {
      window.removeEventListener("keydown", handler);
    };
  }, [params, handler]);

  return () => window.removeEventListener("keydown", handler);
}
