import React from "react";

const DeleteIcon = ({ sizePercentage, color, style = {} }) => (
  <svg
    width={sizePercentage * 13}
    height={sizePercentage * 13}
    preserveAspectRatio="xMidYMid meet"
    style={style}
    viewBox="0 0 13 13"
  >
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
      <path
        d="M4.32614 1.65155H4.2073C4.27266 1.65155 4.32614 1.59807 4.32614 1.5327V1.65155H8.84242V1.5327C8.84242 1.59807 8.8959 1.65155 8.96127 1.65155H8.84242V2.7212H9.91206V1.5327C9.91206 1.00828 9.48569 0.581909 8.96127 0.581909H4.2073C3.68287 0.581909 3.2565 1.00828 3.2565 1.5327V2.7212H4.32614V1.65155ZM11.8136 2.7212H1.35491C1.09196 2.7212 0.879517 2.93364 0.879517 3.19659V3.67199C0.879517 3.73736 0.932999 3.79084 0.998366 3.79084H1.89568L2.26262 11.5606C2.28639 12.0672 2.70534 12.4668 3.21193 12.4668H9.95663C10.4647 12.4668 10.8822 12.0687 10.9059 11.5606L11.2729 3.79084H12.1702C12.2356 3.79084 12.289 3.73736 12.289 3.67199V3.19659C12.289 2.93364 12.0766 2.7212 11.8136 2.7212ZM9.84224 11.3972H3.32633L2.96681 3.79084H10.2018L9.84224 11.3972Z"
        fill={color}
      />
      <line
        x1="5.42027"
        y1="5.36078"
        x2="5.42027"
        y2="9.72351"
        stroke={color}
        strokeWidth="0.6"
      />
      <line
        x1="7.93834"
        y1="5.36078"
        x2="7.93834"
        y2="9.72351"
        stroke={color}
        strokeWidth="0.6"
      />
    </svg>
  </svg>
);

DeleteIcon.defaultProps = {
  sizePercentage: 1,
  color: "#fff",
};

export default DeleteIcon;
