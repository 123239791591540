import React from "react";

const NotPaidIcon = () => {

  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.25111 15.7301C10.1266 15.7301 10.8364 15.0204 10.8364 14.1448C10.8364 13.2693 10.1266 12.5596 9.25111 12.5596C8.37558 12.5596 7.66583 13.2693 7.66583 14.1448C7.66583 15.0204 8.37558 15.7301 9.25111 15.7301Z"
        fill="#F3B515"
      />
      <path
        d="M20.348 15.7301C21.2236 15.7301 21.9333 15.0204 21.9333 14.1448C21.9333 13.2693 21.2236 12.5596 20.348 12.5596C19.4725 12.5596 18.7628 13.2693 18.7628 14.1448C18.7628 15.0204 19.4725 15.7301 20.348 15.7301Z"
        fill="#F3B515"
      />
      <path
        d="M14.7996 15.7301C15.6751 15.7301 16.3848 15.0204 16.3848 14.1448C16.3848 13.2693 15.6751 12.5596 14.7996 12.5596C13.924 12.5596 13.2143 13.2693 13.2143 14.1448C13.2143 15.0204 13.924 15.7301 14.7996 15.7301Z"
        fill="#F3B515"
      />
      <path
        d="M14.7996 28.1445C12.0306 28.1445 9.32387 27.3235 7.02158 25.7851C4.7193 24.2468 2.92488 22.0603 1.86525 19.5021C0.805627 16.9439 0.52838 14.129 1.06857 11.4133C1.60877 8.69754 2.94214 6.20298 4.90007 4.24504C6.85801 2.28711 9.35257 0.953737 12.0683 0.413544C14.784 -0.126649 17.599 0.150597 20.1571 1.21022C22.7153 2.26985 24.9018 4.06427 26.4401 6.36655C27.9785 8.66884 28.7996 11.3756 28.7996 14.1445C28.7953 17.8563 27.319 21.4148 24.6944 24.0394C22.0698 26.6639 18.5113 28.1403 14.7996 28.1445ZM14.7996 2.14454C12.4262 2.14454 10.1061 2.84833 8.13272 4.1669C6.15933 5.48548 4.62126 7.35962 3.71301 9.55234C2.80476 11.7451 2.56712 14.1578 3.03014 16.4856C3.49317 18.8134 4.63605 20.9516 6.31429 22.6298C7.99252 24.308 10.1307 25.4509 12.4585 25.914C14.7863 26.377 17.1991 26.1393 19.3918 25.2311C21.5845 24.3228 23.4586 22.7848 24.7772 20.8114C26.0958 18.838 26.7996 16.5179 26.7996 14.1445C26.7959 10.9631 25.5304 7.91298 23.2808 5.66335C21.0311 3.41371 17.981 2.14824 14.7996 2.14454Z"
        fill="#F3B515"
      />
    </svg>
  );
};

export default NotPaidIcon;
