import {
  ADD_QUESTION,
  REMOVE_QUESTION,
  SURVEY_CHANGE_FIELD,
  CHANGE_QUESTION_SELECTED,
  QUESTION_CREATE_ERROR,
  QUESTION_CREATE_REQUESTING,
  QUESTION_CREATE_SUCCESS,
  SURVEY_CREATE_REQUESTING,
  SURVEY_CREATE_SUCCESS,
  SURVEY_CREATE_ERROR,
  SURVEY_GET_REQUESTING,
  SURVEY_GET_SUCCESS,
  SURVEY_GET_ERROR,
  SURVEY_MODIFY_REQUESTING,
  SURVEY_MODIFY_ERROR,
  SURVEY_MODIFY_SUCCESS,
  QUESTION_MODIFY_REQUESTING,
  QUESTION_MODIFY_ERROR,
  QUESTION_MODIFY_SUCCESS,
  SAVE_SURVEY_REQUESTING,
  REORDER_QUESTIONS,
  QUESTION_DELETE_REQUESTING,
  QUESTION_DELETE_SUCCESS,
  QUESTION_DELETE_ERROR,
  DUPLICATE_QUESTION,
  CREATE_NEW_QUESTION,
  GET_SURVEYS_REQUESTING,
  GET_SURVEYS_ERROR,
  GET_SURVEYS_SUCCESS,
  SURVEY_SET,
  NEW_SURVEY,
  SURVEY_DELETE_REQUESTING,
  SURVEY_DELETE_ERROR,
  SURVEY_DELETE_SUCCESS,
  GET_CAMPAIGN_SURVEYS_SUCCESS,
  GET_CAMPAIGN_SURVEYS_ERROR,
  GET_CAMPAIGN_SURVEYS_REQUESTING,
  SEND_SURVEY_SUCCESS,
  SEND_SURVEY_ERROR,
  SEND_SURVEY_REQUESTING,
  SEND_EVOLUTION_SURVEY_ERROR,
  SEND_EVOLUTION_SURVEY_SUCCESS,
  SEND_EVOLUTION_SURVEY_REQUESTING,
} from "./constants";

import { AUTH_LOGOUT_SUCCESS } from "../auth/reducer";
import i18n from "../../i18n";

const initialState = {
  _id: "",
  error: null,
  questions: [],
  surveys: [],
  surveysByCampaign: {
    surveys: [{ questions: [{ answers: [] }] }],
    surveyAnswers: [],
  },
  evolutionSurvey: {},
  evolutionSurveyAnswers: [],
  saving: false,
  title: { fr: "", en: "" },
  questionSelectedIndex: 0,
  languageSelected: "fr",
  questionRequesting: false,
  rightSideScreen: "none", // createActionTextStep
  requesting: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NEW_QUESTION: {
      return {
        ...state,
        snackMessage: i18n.t("step-added"),
        questions: [...state.questions, action.payload],
        questionSelectedIndex: state.questions.length,
      };
    }

    case ADD_QUESTION: {
      return {
        ...state,
        snackMessage: i18n.t("step-added"),
        questions: [
          ...state.questions,
          { ...action.payload, step: action.payload._id },
        ],
      };
    }

    case DUPLICATE_QUESTION: {
      return {
        ...state,
        snackMessage: i18n.t("step-duplicated"),
        questions: [...state.questions],
      };
    }

    case REORDER_QUESTIONS: {
      const { startIndex, endIndex } = action.payload;
      const questions = [...state.questions];
      const [removed] = questions.splice(startIndex, 1);
      questions.splice(endIndex, 0, removed);
      return {
        ...state,
        questions,
        questionSelectedIndex: endIndex,
      };
    }

    case SEND_EVOLUTION_SURVEY_REQUESTING:
    case SURVEY_DELETE_REQUESTING:
    case GET_SURVEYS_REQUESTING:
    case SURVEY_GET_REQUESTING:
    case SURVEY_CREATE_REQUESTING: {
      return {
        ...state,
        requesting: true,
      };
    }

    case SAVE_SURVEY_REQUESTING:
    case SURVEY_MODIFY_REQUESTING:
      return {
        ...state,
        saving: true,
      };

    case SURVEY_MODIFY_SUCCESS: {
      const { _id } = action.payload;
      const surveys = state.surveys.map((survey, i) => {
        if (survey._id === _id) {
          return { ...survey, ...action.payload };
        }
        return survey;
      });
      return {
        ...state,
        title: action.payload.title,
        questions: action.payload.questions,
        surveys,
        saving: false,
      };
    }

    case SURVEY_DELETE_SUCCESS: {
      const { _id } = action.payload;
      const surveys = state.surveys.filter(el => el._id !== _id);
      const newSurveys = state.surveysByCampaign.surveys.filter(
        survey => survey._id !== _id,
      );
      const surveysByCampaign = {
        ...state.surveysByCampaign,
        surveys: newSurveys,
      };
      return {
        ...state,
        requesting: false,
        surveysByCampaign,
        surveys,
      };
    }

    case SURVEY_MODIFY_ERROR:
      return {
        ...state,
        saving: false,
        error: action.payload,
      };

    case SURVEY_GET_SUCCESS:
      return { ...state, requesting: false };

    case SURVEY_CREATE_SUCCESS: {
      const surveys = [action.payload, ...state.surveys];
      return {
        ...state,
        ...action.payload,
        surveys,
        requesting: false,
      };
    }

    case GET_SURVEYS_SUCCESS: {
      return {
        ...state,
        requesting: false,
        surveys: action.payload,
      };
    }

    case SEND_EVOLUTION_SURVEY_ERROR:
    case SURVEY_DELETE_ERROR:
    case GET_SURVEYS_ERROR:
    case SURVEY_GET_ERROR:
    case SURVEY_CREATE_ERROR:
      return {
        ...state,
        requesting: false,
        error: action.payload,
      };

    case REMOVE_QUESTION: {
      let questionSelectedIndex = state.questionSelectedIndex;
      let rightSideScreen = state.rightSideScreen;
      const questions = state.questions.filter(
        (el, index) => index !== action.payload,
      );
      if (questionSelectedIndex === 0 && questions.length === 1) {
        rightSideScreen = "library";
      }
      if (questions.length < 1) {
        questionSelectedIndex = 0;
        rightSideScreen = "library";
      } else if (
        questionSelectedIndex === 0 &&
        questionSelectedIndex + 1 < questions.length
      ) {
        questionSelectedIndex += 1;
      } else if (questionSelectedIndex - 1 >= 0) {
        questionSelectedIndex -= 1;
      }

      return {
        ...state,
        snackMessage: i18n.t("step-removed"),
        questionSelectedIndex,
        questions,
        rightSideScreen,
      };
    }

    case QUESTION_DELETE_SUCCESS: {
      let questionSelectedIndex = state.questionSelectedIndex;
      let rightSideScreen = state.rightSideScreen;
      const questions = state.questions.filter(
        (el, index) => el._id !== action.payload._id,
      );
      if (questionSelectedIndex === 0 && questions.length === 1) {
        rightSideScreen = "library";
      }
      if (questions.length < 1) {
        questionSelectedIndex = 0;
        rightSideScreen = "library";
      } else if (
        questionSelectedIndex === 0 &&
        questionSelectedIndex + 1 < questions.length
      ) {
        questionSelectedIndex += 1;
      } else if (questionSelectedIndex - 1 >= 0) {
        questionSelectedIndex -= 1;
      }

      return {
        ...state,
        stepRequesting: false,
        questionSelectedIndex,
        questions,
        rightSideScreen,
      };
    }

    case CHANGE_QUESTION_SELECTED: {
      const questions = state.questions.map((el, i) => {
        if (i === state.questionSelectedIndex) {
          return { ...el, ...action.payload };
        }
        return el;
      });
      return {
        ...state,
        questions,
      };
    }

    case QUESTION_DELETE_REQUESTING:
    case QUESTION_MODIFY_REQUESTING:
    case QUESTION_CREATE_REQUESTING:
      return {
        ...state,
        stepRequesting: true,
      };

    case QUESTION_MODIFY_SUCCESS: {
      const { _id } = action.payload;
      const questions = state.questions.map((el, i) => {
        if (el._id === _id) {
          return { ...action.payload, ...el, step: action.payload._id };
        }
        return el;
      });
      return {
        ...state,
        questions,
        stepRequesting: false,
      };
    }

    case QUESTION_CREATE_SUCCESS: {
      const { stepIndex } = action.payload;
      const questions = state.questions.map((el, i) => {
        if (i === stepIndex) {
          return { ...action.payload, step: action.payload._id };
        }
        return el;
      });
      return {
        ...state,
        questions,
        snackStatus: "success",
        snackMessage: i18n.t("step-created"),
        stepRequesting: false,
      };
    }

    case GET_CAMPAIGN_SURVEYS_REQUESTING: {
      return {
        ...state,
        requesting: true,
      };
    }

    case GET_CAMPAIGN_SURVEYS_SUCCESS: {
      return {
        ...state,
        requesting: false,
        surveysByCampaign: action.payload,
      };
    }

    case GET_CAMPAIGN_SURVEYS_ERROR: {
      return {
        ...state,
        requesting: false,
        error: action.payload,
      };
    }

    case SEND_SURVEY_REQUESTING: {
      return {
        ...state,
        requesting: true,
      };
    }

    case SEND_SURVEY_SUCCESS: {
      return {
        ...state,
        surveysByCampaign: {
          ...state.surveysByCampaign,
          surveys: [...state.surveysByCampaign.surveys, action.payload],
        },
        surveys: [...state.surveys, action.payload],
        requesting: false,
      };
    }

    case SEND_EVOLUTION_SURVEY_SUCCESS: {
      return {
        ...state,
        requesting: false,
      };
    }

    case SEND_SURVEY_ERROR: {
      return {
        ...state,
        requesting: false,
      };
    }

    case QUESTION_DELETE_ERROR:
    case QUESTION_MODIFY_ERROR:
    case QUESTION_CREATE_ERROR:
      return {
        ...state,
        stepRequesting: false,
        stepError: action.payload,
      };

    case SURVEY_CHANGE_FIELD:
      return {
        ...state,
        ...action.payload,
      };

    case SURVEY_SET:
      return {
        ...state,
        ...action.payload,
      };

    case NEW_SURVEY:
      return {
        ...initialState,
        surveys: state.surveys,
        surveysByCampaign: state.surveysByCampaign,
      };

    case AUTH_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
