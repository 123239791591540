import React, { useEffect, useState } from "react";
import { Dialog, IconButton } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import InfosSide from "scenes/MyCoachees/NewCoacheeModal/InfosSide";
import AvatarSide from "scenes/MyCoachees/NewCoacheeModal/AvatarSide";
import { modifyMyUserRequest } from "services/myUsers/actions";
import { useDispatch, useSelector } from "react-redux";
import { getUserSelected, getUserSelectedId } from "services/manager/selectors";
import FlatButton from "components/FlatButton";
import i18n from "i18n";
import { getUserRequesting } from "services/myUsers/selectors";

const ExitIcon = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6192 1.42315C12.6913 1.35118 12.7484 1.26573 12.7875 1.17167C12.8265 1.07761 12.8466 0.976779 12.8467 0.874942C12.8467 0.773105 12.8267 0.672253 12.7878 0.578143C12.7489 0.484034 12.6919 0.398511 12.6199 0.326457C12.5479 0.254402 12.4625 0.197229 12.3684 0.1582C12.2743 0.11917 12.1735 0.09905 12.0717 0.0989874C11.9698 0.0989248 11.869 0.118921 11.7749 0.157835C11.6808 0.196748 11.5952 0.253817 11.5232 0.325782L6.84658 5.00239L2.17132 0.325782C2.0258 0.180263 1.82843 0.0985107 1.62263 0.0985107C1.41684 0.0985107 1.21947 0.180263 1.07395 0.325782C0.928432 0.471302 0.84668 0.668669 0.84668 0.874465C0.84668 1.08026 0.928432 1.27763 1.07395 1.42315L5.75056 6.09841L1.07395 10.7737C1.0019 10.8457 0.944741 10.9313 0.905746 11.0254C0.86675 11.1196 0.84668 11.2205 0.84668 11.3224C0.84668 11.4243 0.86675 11.5252 0.905746 11.6193C0.944741 11.7134 1.0019 11.799 1.07395 11.871C1.21947 12.0166 1.41684 12.0983 1.62263 12.0983C1.72453 12.0983 1.82544 12.0782 1.91958 12.0392C2.01372 12.0002 2.09926 11.9431 2.17132 11.871L6.84658 7.19443L11.5232 11.871C11.6687 12.0164 11.866 12.098 12.0717 12.0978C12.2773 12.0977 12.4745 12.0159 12.6199 11.8704C12.7652 11.7248 12.8468 11.5276 12.8467 11.3219C12.8466 11.1162 12.7647 10.919 12.6192 10.7737L7.9426 6.09841L12.6192 1.42315Z"
      fill="black"
    />
  </svg>
);

const useStyles = makeStyles(theme => ({
  modal: {
    minWidth: 840,
    maxWidth: 840,
    borderRadius: 10,
    minHeight: "40%",
    display: "flex",
    flexDirection: "row",
  },
  leftSide: {
    backgroundColor: "#F4FBFF",
    paddingRight: 75,
    paddingLeft: 75,
    width: 420,
    background: "#F4FBFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    flex: 1,
  },
  rightSide: {
    width: 420,
    paddingTop: 50,
    paddingBottom: 40,
    paddingRight: 75,
    paddingLeft: 75,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    flex: 1,
  },
  exitButton: {
    position: "absolute",
    top: 10,
    right: 10,
  },
  button: {
    height: 50,
    fontSize: 14,
  },
}));

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

const EditUserInfoModal = ({ isVisible, onClose }: Props) => {
  const classes = useStyles();
  const userSelected = useSelector(getUserSelected);
  const [firstName, setFirstName] = useState("");
  const [picture, setPicture] = useState("");
  const [lastName, setLastName] = useState("");
  const [hasAvatars, setHasAvatars] = useState(true);

  useEffect(() => {
    if (isVisible) {
      const { firstName, lastName, picture, hasAvatars } = userSelected;
      setFirstName(firstName);
      setLastName(lastName);
      setPicture(picture);
      setHasAvatars(hasAvatars);
    }
  }, [isVisible, userSelected]);

  const userId = useSelector(getUserSelectedId);
  const requesting = useSelector(getUserRequesting);

  const dispatch = useDispatch();

  const onSubmit = () => {
    console.log("CREATE NEW");
    dispatch(
      modifyMyUserRequest({ userId, firstName, lastName, hasAvatars, picture }),
    );
  };

  const handleFirstNameChange = e => {
    const value = e.target.value;
    setFirstName(value);
  };

  const handleLastNameChange = e => {
    const value = e.target.value;
    setLastName(value);
  };

  const handlePictureChange = value => {
    setPicture(value);
  };

  const handleHasAvatars = value => () => {
    setHasAvatars(value);
  };

  return (
    <Dialog
      classes={{ paper: `${classes.modal}` }}
      open={isVisible}
      onClose={onClose}
    >
      <IconButton onClick={onClose} className={classes.exitButton} size="large">
        <ExitIcon />
      </IconButton>
      <div className={classes.leftSide}>
        <AvatarSide
          hasAvatars={hasAvatars}
          handleHasAvatars={handleHasAvatars}
          picture={picture}
          handlePictureChange={handlePictureChange}
        />
      </div>
      <div className={classes.rightSide}>
        <InfosSide
          title={i18n.t("edit-coachee-info")}
          lastName={lastName}
          firstName={firstName}
          handleLastNameChange={handleLastNameChange}
          handleFirstNameChange={handleFirstNameChange}
        />
        <FlatButton
          className={classes.button}
          requesting={requesting}
          onClick={onSubmit}
          fullWidth
          title={i18n.t("save")}
        />
      </div>
    </Dialog>
  );
};

export default EditUserInfoModal;
