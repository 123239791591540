import React from "react";
import withStyles from '@mui/styles/withStyles';
import Button from "@mui/material/Button";
import Colors from "../constants/Colors";
import { Loader } from "./Loader";
import { MainText } from "./Texts/MainText";

const FlatButtonToCustomize = withStyles({
  root: {
    padding: "10px 20px",
    boxShadow: "none",
    textTransform: "none",
    color: Colors.white,
    lineHeight: 1.5,
    opacity: 1,
    borderRadius: 4,
    backgroundColor: Colors.pureMain,
    borderColor: Colors.pureMain,
    fontFamily: "Hind",
    fontSize: 16,
    "&:hover": {
      boxShadow: "none",
      opacity: 0.9,
      borderColor: Colors.pureMain,
      backgroundColor: Colors.pureMain,
    },
    "&:active": {
      boxShadow: "none",
      border: "none",
      borderColor: Colors.pureMain,
      backgroundColor: Colors.pureMain,
      opacity: 0.9,
    },
  },
  disabled: {
    fontWeight: "normal",
    color: Colors.black,
  },
  outlined: {
    backgroundColor: "transparent",
    border: "1px solid",
    borderColor: "rgba(29, 28, 29, 0.3)",
  },
  "@media (max-width: 375px)": {
    root: {
      fontSize: 14,
    },
  },
})(Button);

const FlatButton = function CustomizedButtons({
  title,
  onClick,
  disabled = false,
  className = "",
  fullWidth = false,
  requesting = false,
  icon = null,
  onKeyDown = null,
  intercomTarget = null,
  size = "medium",
}) {
  if (requesting) {
    return (
      <FlatButtonToCustomize
        className={className}
        fullWidth={fullWidth}
        variant="contained"
        color="primary"
        disableRipple
        onClick={null}
      >
        <Loader size={25} />
      </FlatButtonToCustomize>
    );
  }
  return (
    <FlatButtonToCustomize
      data-intercom-target={intercomTarget}
      className={className}
      disabled={disabled}
      fullWidth={fullWidth}
      variant="contained"
      color="primary"
      disableRipple
      onClick={onClick}
    >
      {icon}
      <MainText size={size} text={title} />
    </FlatButtonToCustomize>
  );
};

export default FlatButton;
