// First, we need to import the FlatList and other React Native component
import "intersection-observer";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// We also need to add the connectInfiniteHits connector to our import
import AutoSizer from "react-virtualized-auto-sizer";
import StepRow from "./StepRow";
import memoize from "memoize-one";
import {
  removeStep,
  reorderSteps,
  selectStep,
} from "services/createTrack/actions";

import { FixedSizeList, areEqual } from "react-window";
import { useDispatch, useSelector } from "react-redux";
import { getOtherAdminConnections } from "services/general/selectors";
import selectLanguage from "utils/selectLanguage";
import makeStyles from "@mui/styles/makeStyles";
import {
  getIsLibrary,
  getLanguageSelected,
  getStepSelectedId,
  getStepsToDisplay,
  getStepsByIds,
  getIndexToScroll,
  getLastScrollDate,
} from "services/createTrack/selectors";
import "./StepList.css";
import { getIsModifyForbidden } from "services/user/selectors";

const useStyles = makeStyles(theme => ({
  list: {
    flex: 1,
    display: "flex",
    height: "100%",
    overflow: "auto",
    minWidth: "100%",
  },
}));

function getStyle({ provided, style, isDragging }) {
  // If you don't want any spacing between your items
  // then you could just return this.
  // I do a little bit of magic to have some nice visual space
  // between the row items
  const combined = {
    ...style,
    ...provided.draggableProps.style,
  };

  const marginBottom = 8;
  const withSpacing = {
    ...combined,
    height: isDragging ? combined.height : combined.height - marginBottom,
    marginBottom,
  };
  return withSpacing;
}

const Item = ({ provided, index, data, style, isDragging, stepId }) => {
  const dispatch = useDispatch();
  const stepSelectedId = useSelector(getStepSelectedId);
  const isLibrary = useSelector(getIsLibrary);
  const otherAdminConnections = useSelector(getOtherAdminConnections);
  const item = useSelector(getStepsByIds)?.[stepId];
  const isModifyForbidden = useSelector(getIsModifyForbidden);

  const onClickStep = useCallback(() => {
    dispatch(selectStep({ stepId }));
  }, [dispatch, stepId]);

  const handleRemoveStep = useCallback(() => {
    if (isLibrary) return null;
    dispatch(removeStep(stepId));
  }, [dispatch, isLibrary, stepId]);

  const otherAdminsConnected = useMemo(
    () => otherAdminConnections?.filter(el => el?.stepId === stepId),
    [stepId, otherAdminConnections],
  );

  if (!item || !data) {
    return null;
  }

  const { languageSelected } = data;

  const { title, isPrivate, _id, content, creating } = item;

  const titleToDisplay =
    title?.[languageSelected] ||
    selectLanguage({
      text: content.title,
      language: languageSelected,
    });

  return (
    <div
      id={`fixed-size-row-${index}`}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getStyle({ provided, style, isDragging })}
    >
      <StepRow
        {...item}
        otherAdminsConnected={otherAdminsConnected}
        isFirst={index === 0}
        isSelected={stepSelectedId === _id}
        stepIndex={index + 1}
        stepType={isPrivate ? null : "library"}
        onClick={onClickStep}
        creating={creating}
        label={null}
        noRightBorder
        title={titleToDisplay}
        isLibrary={isLibrary}
        languageSelected={languageSelected}
        onClickDeleteButton={
          isLibrary || isModifyForbidden ? null : handleRemoveStep
        }
      />
    </div>
  );
};

const createItemData = memoize((items, languageSelected) => ({
  items,
  languageSelected,
}));

// Recommended react-window performance optimisation: memoize the row render function
// Things are still pretty fast without this, but I am a sucker for making things faster
const Row = React.memo(function Row(props) {
  const { data, index, style } = props;
  const stepId = data.items[index];
  if (!stepId) return null;

  return (
    <Draggable draggableId={stepId} index={index} key={stepId}>
      {provided => (
        <Item
          key={stepId}
          provided={provided}
          data={data}
          index={index}
          stepId={stepId}
          style={style}
        />
      )}
    </Draggable>
  );
}, areEqual);

const StepList = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const listRef = useRef(null);

  const languageSelected = useSelector(getLanguageSelected);
  const stepsToDisplay = useSelector(getStepsToDisplay);
  const indexToScroll = useSelector(getIndexToScroll);
  const lastScrollDate = useSelector(getLastScrollDate);

  useEffect(() => {
    if (listRef && listRef.current) {
      listRef.current.scrollToItem(indexToScroll);
    }
  }, [listRef, lastScrollDate, indexToScroll]);

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    if (result.source.index === result.destination.index) {
      return;
    }

    dispatch(
      reorderSteps({
        startIndex: result.source.index,
        endIndex: result.destination.index,
      }),
    );
  };

  const itemData = createItemData(stepsToDisplay, languageSelected);

  const cloneData = useMemo(() => {
    return {
      languageSelected,
      onClickStep: () => null,
      otherAdminConnections: [{}],
      handleRemoveStep: () => () => null,
    };
  }, [languageSelected]);

  const renderClone = useCallback(
    (provided, snapshot, rubric) => (
      <Item
        provided={provided}
        isDragging={snapshot.isDragging}
        stepId={stepsToDisplay[rubric.source.index]}
        index={rubric.source.index}
        data={cloneData}
      />
    ),
    [cloneData, stepsToDisplay],
  );

  const renderChildren = useCallback(
    provided => (
      <AutoSizer minWidth={1} minHeight={1}>
        {({ width, height }) => (
          <FixedSizeList
            ref={listRef}
            height={height}
            itemCount={stepsToDisplay.length}
            itemSize={80}
            width={width}
            outerRef={provided.innerRef}
            itemData={itemData}
          >
            {Row}
          </FixedSizeList>
        )}
      </AutoSizer>
    ),
    [listRef, itemData, stepsToDisplay],
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={classes.list}>
        <Droppable
          droppableId="droppable"
          mode="virtual"
          renderClone={renderClone}
        >
          {renderChildren}
        </Droppable>
      </div>
    </DragDropContext>
  );
};

export default React.memo(StepList);
