import React, { PureComponent } from "react";
import { connect } from "react-redux";

import withStyles from '@mui/styles/withStyles';

import Colors from "../../constants/Colors";

import AccountSettings from "./AccountSettings";
import WhiteLabelSettings from "./WhiteLabelSettings";
import Tab from "./Tab";
import CustomOnboarding from "./CustomOnboarding";
import AccountUsage from "./AccountUsage";

const styles = theme => ({
  main: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 100,
  },
  root: {
    width: "90%",
    minHeight: 500,
    paddingTop: 20,
    paddingLeft: 45,
    paddingRight: 45,
    paddingBottom: 25,
    marginTop: theme.spacing.unit * 3,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "column",
    // backgroundColor: "#FAFAFA",
    backgroundColor: "#fff",
    border: "solid",
    borderWidth: 1,
    borderRadius: 8,
    borderColor: Colors.mediumGrey,
    // backgroundColor: "#fff",
    overflowX: "auto",
  },
  button: {
    backgroundColor: Colors.pink,
  },
  languageDiv: {
    marginTop: 10,
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
  },
  title: {
    // color: Colors.black,
    marginBottom: 10,
    fontSize: 28,
    lineHeight: 1.2143,
    fontWeight: 700,
  },
  subTitle: {
    // color: Colors.black,
    marginTop: 20,
    marginBottom: 5,
    fontSize: 22,
    lineHeight: 1.2143,
    fontWeight: 700,
  },
  textField: {
    maxWidth: 300,
    minWidth: "33%",
  },
  uploader: {
    marginTop: 16,
  },
  saveButton: {
    marginTop: 25,
  },
  logoutButton: {
    marginTop: 15,
  },
  explanation: {
    marginTop: 5,
  },
});

class Settings extends PureComponent {
  state = {
    tabSelected: 0,
  };

  onChangeTab = tabSelected => {
    this.setState({ tabSelected });
  };

  render() {
    const { classes } = this.props;
    const { tabSelected } = this.state;
    return (
      <div className={classes.main}>
        <div className={classes.root}>
          <Tab
            tabSelected={this.state.tabSelected}
            onChangeTab={this.onChangeTab}
          />
          {tabSelected === 0 && <AccountSettings />}
          {tabSelected === 1 && <CustomOnboarding />}
          {tabSelected === 2 && <AccountUsage />}
          {tabSelected === 3 && <WhiteLabelSettings />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {};

export default connect(mapStateToProps, {})(withStyles(styles)(Settings));
