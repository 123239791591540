import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Colors from "../../constants/Colors";
import RoundLogo from "../../resources/RoundLogo";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import { createCampaignRequest } from "../../services/campaign/actions";
import i18n from "../../i18n";
import { Loader } from "../../components";
import PublishCampaignModal from "./PublishCampaignModal";
import { getSelectedClient } from "../../services/myClients/selectors";
import {
  getIsModifyForbidden,
  getSelectedClientId,
  getUserFirstName,
  getUserId,
  getUserPicture,
} from "../../services/user/selectors";
import CheckIcon from "./CheckIcon";
import {
  getIsEditingCampaign,
  getNumberOfSteps,
} from "services/createTrack/selectors";
import { clickAdminAvatar } from "services/createTrack/actions";
import SettingsIcon from "scenes/CreateTrack/SettingsIcon";
import {
  getAdminConnections,
  getRouterQuery,
} from "services/general/selectors";
import AdminAvatar from "scenes/Dashboard/AdminAvatar";
import { getIsCoachingAccount } from "services/client/selectors";

const HEIGHT_BAR = 60;

const styles = theme => ({
  main: {
    width: "100%",
    height: HEIGHT_BAR,
    background: Colors.darkBlue,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  button: {
    backgroundColor: Colors.pink,
  },
  leftDiv: {
    color: Colors.white,
    display: "flex",
    flex: 1,
    paddingLeft: 10,
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  title: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "1.1em",
    marginRight: 15,
    marginLeft: 15,
    color: Colors.white,
  },
  middleDiv: {
    borderRight: "solid 1px #fff",
    color: Colors.white,
    display: "flex",
    alignItems: "flex-end",
    marginRight: 15,
    flexDirection: "row",
  },
  rightDiv: {
    color: Colors.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row",
  },
  logoContainer: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: "solid 1px #fff",
    flexDirection: "row",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 0.2s",
    borderRadius: 4,
    "&:hover": {
      background: Colors.lightGrey,
    },
  },
  menu: {
    cursor: "pointer",
    position: "relative",
    height: HEIGHT_BAR,
    paddingLeft: 5,
    paddingRight: 5,
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 20,
  },
  menuText: {
    color: "rgba(255,255,255, 0.7)",
    fontSize: "1.1em",
  },
  menuTextSelected: {
    fontWeight: "bold",
  },
  bottomBorder: {
    position: "absolute",
    bottom: 0,
    backgroundColor: Colors.white,
    borderRadius: 3,
    height: 5,
    width: "100%",
  },
  publishButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: 4,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 8,
    paddingBottom: 8,
    marginRight: 15,
    marginLeft: 20,
    backgroundColor: Colors.pureMain,
    transition: "all 0.2s",
    "&:hover": {
      opacity: 0.85,
    },
  },
  publishText: {
    color: Colors.white,
    marginLeft: 10,
    fontSize: 16,
  },
  savingText: {
    fontSize: "1.1em",
    color: Colors.lightWhite,
    fontStyle: "italic",
  },
  steps: {
    color: Colors.lightWhite,
    fontSize: "0.9em",
  },
  disabledButton: {
    backgroundColor: Colors.mediumGrey,
    "&:hover": {
      opacity: 1,
    },
  },
  disabledText: {
    fontWeight: "normal",
    color: Colors.hardGrey,
  },
  text: {
    fontFamily: "Montserrat",
    fontSize: "1.1em",
    fontWeight: 700,
    marginLeft: 12,
    color: "white",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  logo: {
    maxHeight: 30,
    width: "auto",
    height: "auto",
    borderRadius: 4,
  },
  logoEmpty: {
    width: 1,
    height: 30,
  },
  clientContainer: {
    width: 200,
    padding: "5px 8px",
    border: "solid",
    borderWidth: 0,
    borderColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  libraryButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: 4,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 8,
    paddingBottom: 8,
    marginLeft: 20,
    transition: "all 0.2s",
    "&:hover": {
      backgroundColor: Colors.lightGrey,
    },
  },
  libraryText: {
    color: Colors.white,
    marginLeft: 10,
    fontSize: 16,
  },
  savingStep: {
    marginRight: 25,
  },
  bold: {
    fontWeight: "bold",
  },
  chevron: {
    color: "#fff",
    marginLeft: -5,
    fontSize: 32,
  },
});

class Header extends PureComponent {
  state = {
    name: "",
    owner: "",
    domain: "",
    isPublishModalVisible: false,
    isSelected: true,
  };

  onClickPublishButton = () => {
    if (this.isPublishButtonDisabled()) {
      return null;
    }
    this.setState({ isPublishModalVisible: true });
  };

  isPublishButtonDisabled = () => {
    return this.props.numberOfSteps < 1;
  };

  onClosePublishModal = () => {
    this.setState({ isPublishModalVisible: false });
  };

  getTitle = () => {
    const { isLibrary, isEditingCampaign } = this.props;
    if (isLibrary) return i18n.t("my-library");
    if (isEditingCampaign) return i18n.t("editing-campaign-track");
    return i18n.t("track-creation");
  };

  getPreviousLink = () => {
    const { routerQuery, isEditingCampaign, isCoachingAccount } = this.props;

    if (isEditingCampaign) {
      const { campaignId, isCoachingCampaign } = routerQuery;

      if (isCoachingCampaign === "true") {
        return `/my-coachees?campaignId=${campaignId}`;
      }
      if (campaignId) {
        return `/campaigns/${campaignId}`;
      }
    }

    if (isCoachingAccount) {
      return "/";
    }

    return `/campaigns`;
  };

  onClickAdminAvatar = admin => () => {
    this.props.clickAdminAvatar(admin);
  };

  render() {
    const {
      classes,
      selectedClient,
      isLibrary = false,
      isEditingCampaign = false,
      onClickSettings = () => null,
      routerQuery,
      trackId,
      userId,
      adminConnections,
    } = this.props;

    const { campaignName } = routerQuery;

    return (
      <div className={classes.main}>
        {!isLibrary &&
          !isEditingCampaign &&
          this.state.isPublishModalVisible && (
            <PublishCampaignModal
              trackId={this.props.trackId}
              isVisible={this.state.isPublishModalVisible}
              onClose={this.onClosePublishModal}
              languageSelected={this.props.languageSelected}
              title={this.props.title}
              requesting={this.props.requesting}
              description={this.props.description}
              picture={this.props.picture}
              templateSettings={this.props.templateSettings}
            />
          )}
        <div className={classes.leftDiv}>
          <Link to={this.getPreviousLink()} className={classes.logoContainer}>
            <ChevronLeftIcon className={classes.chevron} />
            {selectedClient ? (
              <div className={classes.clientContainer}>
                {selectedClient.logo ? (
                  <img
                    src={selectedClient.logo}
                    className={classes.logo}
                    alt={selectedClient.name}
                  />
                ) : (
                  <div className={classes.logoEmpty} />
                )}
                <Typography className={classes.text}>
                  {selectedClient.name}
                </Typography>
              </div>
            ) : (
              <RoundLogo sizePercentage={0.82} />
            )}
          </Link>
          <Typography className={classes.title}>
            {this.getTitle()}
            {isEditingCampaign && (
              <span className={classes.bold}> "{campaignName}"</span>
            )}
          </Typography>
        </div>
        <div className={classes.middleDiv}>
          {!isLibrary && !isEditingCampaign && (
            <div
              className={`${classes.libraryButton}`}
              data-intercom-target="Library modal"
              onClick={onClickSettings}
            >
              <SettingsIcon sizePercentage={0.75} color={"#fff"} />
              <Typography
                className={`${classes.libraryText} ${
                  this.isPublishButtonDisabled() && classes.disabledText
                }`}
              >
                {i18n.t("settings")}
              </Typography>
            </div>
          )}
        </div>
        {adminConnections
          .sort((a, b) => {
            if (a.userId === userId) return 1;
            if (b.userId === userId) return -1;
            return a.userId < b.userId ? 1 : -1;
          })
          .map(admin => {
            if (!isLibrary && admin.trackId !== trackId) {
              return null;
            }
            if (isLibrary && admin.trackId !== "library") return null;
            return (
              <AdminAvatar
                key={admin.userId}
                {...admin}
                onClick={this.onClickAdminAvatar(admin)}
              />
            );
          })}

        {!isLibrary && !isEditingCampaign ? (
          <div className={classes.rightDiv}>
            <Typography className={classes.savingText}>
              {i18n.t("saved")}
            </Typography>
            {this.props.requesting ? (
              <Loader size={50} />
            ) : (
              <div
                className={`${classes.publishButton} ${
                  this.isPublishButtonDisabled() && classes.disabledButton
                }`}
                data-intercom-target="Publish Campaign"
                onClick={this.onClickPublishButton}
              >
                <CheckIcon />
                <Typography
                  className={`${classes.publishText} ${
                    this.isPublishButtonDisabled() && classes.disabledText
                  }`}
                >
                  {i18n.t("publish")}
                </Typography>
              </div>
            )}
          </div>
        ) : (
          <div className={classes.rightDiv}>
            <Typography
              className={`${classes.savingText} ${classes.savingStep}`}
            >
              {i18n.t("saved")}
            </Typography>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { name } = state.client;
  const userId = getUserId(state);
  const clientId = getSelectedClientId(state);
  const isEditingCampaign = getIsEditingCampaign(state);
  const routerQuery = getRouterQuery(state);
  const { requesting } = state.campaign;
  const {
    saving,
    picture,
    description,
    title,
    templateSettings,
    stepRequesting,
  } = state.createTrack;
  const selectedClient = getSelectedClient(state);

  return {
    token: state.auth.token,
    numberOfSteps: getNumberOfSteps(state),
    name,
    saving,
    client: state.client,
    clientId,
    userId,
    picture,
    firstName: getUserFirstName(state),
    userPicture: getUserPicture(state),
    description,
    requesting,
    stepRequesting,
    title,
    selectedClient,
    templateSettings,
    isEditingCampaign,
    routerQuery,
    trackId: state.createTrack._id,
    isCoachingAccount: getIsCoachingAccount(state),
    adminConnections: getAdminConnections(state),
  };
};

export default React.memo(
  connect(mapStateToProps, {
    createCampaignRequest,
    clickAdminAvatar,
  })(withStyles(styles)(Header)),
);
