import {
  MESSAGE_SET_RECEIVER,
  MESSAGE_CLEAN,
  MESSAGE_SEE_CONVERSATION,
  MESSAGE_GET_LATEST_CONVERSATIONS_REQUEST,
  GET_CHAT_MESSAGES_REQUESTING,
  MESSAGE_OPEN_CONVERSATION_REQUESTING,
  MESSAGE_SET_CONVERSATION,
  MESSAGE_GET_CLIENT_NAME_REQUESTING,
  MESSAGE_LIKE_REQUESTING,
} from "./constants";

export const seeConversation = payload => {
  return {
    type: MESSAGE_SEE_CONVERSATION,
    payload,
  };
};

export const setReceiver = (payload: {}) => {
  return {
    type: MESSAGE_SET_RECEIVER,
    payload,
  };
};

export const setConversation = (payload: string) => {
  return {
    type: MESSAGE_SET_CONVERSATION,
    payload,
  };
};

export const getLatestConversationsRequest = (payload: { userId: string }) => {
  return {
    type: MESSAGE_GET_LATEST_CONVERSATIONS_REQUEST,
    payload,
  };
};

export const cleanMessage = () => {
  return {
    type: MESSAGE_CLEAN,
  };
};

export const getChatMessagesRequest = (payload: {
  conversationId: string;
  page: number;
}) => {
  return {
    type: GET_CHAT_MESSAGES_REQUESTING,
    payload,
  };
};

export const openConversationRequest = payload => {
  return {
    type: MESSAGE_OPEN_CONVERSATION_REQUESTING,
    payload,
  };
};

export const getMessageClientNameRequest = (payload: { userId: string }) => {
  return {
    type: MESSAGE_GET_CLIENT_NAME_REQUESTING,
    payload,
  };
};

export const likeMessage = (payload: { messageId: string; userId: string }) => {
  return {
    type: MESSAGE_LIKE_REQUESTING,
    payload,
  };
};
