import React, { useState } from "react";
import withStyles from "@mui/styles/withStyles";
import Switch from "react-switch";
import i18n from "i18n";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import moment from "moment";

import "./calendar.css";
import { Typography } from "@mui/material";
import TextBox from "components/TextBox";
import Colors from "constants/Colors";
import getCampaignStatus from "utils/getCampaignStatus";
import displayNextActiveDay from "utils/displayNextActiveDay";

const styles = theme => ({
  main: {},
  subTitle: {
    fontFamily: "Montserrat",
    marginTop: 20,
    marginBottom: 5,
    fontSize: 18,
    lineHeight: 1.2143,
    fontWeight: 700,
  },
  dateBox: {
    zIndex: 10,
    marginTop: 15,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  switchDate: {
    flex: 1,
    paddingRight: 75,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    flexDirection: "column",
  },
  setDateDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  textInfo: {
    fontWeight: "normal",
  },
  summary: {
    marginTop: 20,
    fontSize: 16,
    color: Colors.pureMain,
  },
  normal: {
    color: Colors.text,
  },
});

const getDates = ({ languageSelected }) => [
  {
    title: i18n.t("set-days", {
      days: 30,
      lng: languageSelected,
    }),
    days: 30,
  },
  {
    title: i18n.t("set-days", {
      days: 60,
      lng: languageSelected,
    }),
    days: 60,
  },
  {
    title: i18n.t("set-days", {
      days: 90,
      lng: languageSelected,
    }),
    days: 90,
  },
];

const DateCalendarInput = ({
  classes,
  endDate,
  startDate,
  onDatesChange,
  languageSelected,
}) => {
  const [focusedInput, setFocusInput] = useState(null);
  const [days, setDays] = useState(0);

  const onClickSwitch = () => {
    onDatesChange({
      startDate: startDate ? null : moment(),
      endDate: endDate ? null : moment().add(1, "month"),
    });
  };

  const onClickAddDays = daysToAdd => () => {
    if (daysToAdd === days) {
      setDays(0);
      onDatesChange({ startDate: null, endDate: null });
    } else {
      setDays(daysToAdd);
      onDatesChange({
        startDate: moment(),
        endDate: moment().add(daysToAdd, "days"),
      });
    }
  };

  const status = getCampaignStatus({ startDate, endDate, isDone: false });

  return (
    <div className={classes.main}>
      <Typography className={classes.subTitle}>
        {i18n.t("campaign-date", { lng: languageSelected })}
        <span className={classes.textInfo}>
          {i18n.t("optional", { lng: languageSelected })}
        </span>
      </Typography>
      <div className={classes.dateBox}>
        <DateRangePicker
          // enableOutsideDays
          isOutsideRange={() => false}
          startDatePlaceholderText={i18n.t("start-date")}
          endDatePlaceholderText={i18n.t("end-date")}
          startDate={startDate} // momentPropTypes.momentObj or null,
          startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
          endDate={endDate} // momentPropTypes.momentObj or null,
          endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
          onDatesChange={onDatesChange} // PropTypes.func.isRequired,
          focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={focusedInputArg => setFocusInput(focusedInputArg)} // PropTypes.func.isRequired,
        />
        <div className={classes.setDateDiv}>
          {getDates({ languageSelected }).map((el, i) => (
            <TextBox
              key={i}
              title={el?.title}
              isSelected={el?.days === days}
              onClick={onClickAddDays(el?.days)}
            />
          ))}
        </div>

        <div className={classes.switchDate}>
          <Switch
            onColor="#00A7FC"
            onChange={onClickSwitch}
            checked={!!startDate || false}
          />
        </div>
      </div>
      {status.text === "current" && (
        <Typography className={classes.summary}>
          {i18n.t("date-campaign-settings-summary")}
        </Typography>
      )}
      {status.text === "incoming" && (
        <Typography className={classes.summary}>
          <span className={classes.normal}>
            {i18n.t("date-campaign-settings-incoming-1")}
          </span>
          {i18n.t("date-campaign-settings-incoming-2")}
          {displayNextActiveDay({
            nextDate: startDate,
          }).toLowerCase()}
        </Typography>
      )}
      {status.text === "done" && (
        <Typography className={classes.summary}>
          <span className={classes.normal}>
            {i18n.t("date-campaign-settings-done")}
          </span>
        </Typography>
      )}
    </div>
  );
};

export default withStyles(styles)(DateCalendarInput);
