import React from "react";
import Colors from "constants/Colors";
import i18n from "i18n";
import selectLanguage from "utils/selectLanguage";
import { Typography } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import "./ActionBox.css";

const displaySkills = (skills, language) => {
  let initialText = `(${i18n.t("reward-second")}`;
  if (skills && skills.length > 0) {
    skills.map((skill, index) => {
      const isFirst = index === 0;
      const isLast = index === skills.length - 1;
      const skillName = selectLanguage({ text: skill.label, language });
      if (isFirst && isLast) {
        initialText += `${skillName})`;
      } else if (isFirst) {
        if (isFirst && skills.length === 2) {
          initialText += `${skillName} `;
        } else {
          initialText += `${skillName}`;
        }
      } else if (isLast) {
        initialText += `& ${skillName})`;
      } else {
        initialText += `, ${skillName} `;
      }
      return initialText;
    });
  }
  return initialText;
};

const useStyles = makeStyles(theme => ({
  main: {
    boxSizing: "border-box",
    width: "75%",
    backgroundColor: "#fff",
    borderRadius: 10,
    marginTop: 5,
    marginBottom: 10,
    paddingTop: 10,
    paddingBottom: 10,
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    borderColor: "#fff",
    flexDirection: "column",
  },
  titleView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  title: {
    textAlign: "center",
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: 16,
    color: Colors.darkBlue,
    fontFamily: "Montserrat",
    fontWeight: "bold",
  },
  separator: {
    width: "50%",
    marginTop: 5,
    height: 4,
    backgroundColor: Colors.skyBlue2,
    borderRadius: 20,
    marginBottom: 5,
  },
  actionView: {
    fontSize: 12,
    // fontFamily: "Montserrat",
    fontFamily: "Avenir",
    width: "100%",
    borderColor: Colors.main,
    paddingLeft: 15,
    paddingRight: 15,
    padding: 5,
    borderRadius: 8,
    borderWidth: 0,
    backgroundColor: Colors.white,
  },
  totalUsersView: {
    marginTop: 10,
    marginBottom: 5,
    borderRadius: 8,
    padding: 5,
    paddingLeft: 8,
    paddingRight: 8,
    backgroundColor: Colors.veryLightMain,
  },
  totalUsers: {
    color: Colors.main,
    fontSize: 14,
  },
  gainText: {
    marginLeft: 25,
    marginRight: 25,
    textAlign: "center",
    color: Colors.main,
    marginBottom: 10,
    fontSize: 10,
  },
  rankView: {
    marginTop: 5,
    marginBottom: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  starView: {
    marginRight: 8,
  },
  image: {
    marginBottom: 5,
    borderRadius: 8,
    alignSelf: "center",
  },
  figcaption: {
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    width: "100%",
    color: Colors.hardGrey,
    textAlign: "center",
    marginBottom: 5,
    fontSize: 16,
    // color: Colors.black
  },
}));

const ActionBox = ({
  uniqueKey,
  text,
  title,
  language,
  color,
  skills = [],
}) => {
  const classes = useStyles();

  if (text === '<p class="md-block-unstyled"><br/></p>' || !text) {
    return null;
  }

  return (
    <div className={classes.main}>
      <div className={classes.titleView}>
        <Typography className={classes.title}>{title}</Typography>
        <div className={classes.separator} style={{ backgroundColor: color }} />
      </div>
      {skills.length > 0 && (
        <Typography className={classes.gainText}>
          {displaySkills(skills, language)}
        </Typography>
      )}
      <Typography
        key={uniqueKey}
        value-attr="action-preview-html"
        className={classes.actionView}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
};

export default React.memo(ActionBox);
