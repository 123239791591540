import React, { useState } from "react";
import { Avatar, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Colors from "constants/Colors";
import { MainText } from "components/Texts/MainText";
import displayDate from "utils/displayDate";
import ReviewFile from "./ReviewFile";
import Linkify from "react-linkify";
import { useDispatch, useSelector } from "react-redux";
import { getUserId } from "services/user/selectors";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { styled } from "@mui/material/styles";
import { likeMessage } from "services/message/actions";

const AVATAR_HEIGHT = 17;

const useStyles = makeStyles(theme => ({
  main: {
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    position: "relative",
    marginBottom: 17,
  },
  toRight: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  commentDiv: {
    borderRightWidth: 1,
    borderRightColor: Colors.lightGrey,
    borderRightStyle: "solid",
    boxSizing: "border-box",
    width: "100%",
    maxWidth: 400,
    paddingTop: 20,
    paddingLeft: 25,
    paddingRight: 25,
    paddingBottom: 10,
    borderRadius: 8,
    backgroundColor: "#FAFAFA",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  correctorCommentDiv: {
    backgroundColor: "#EDF6FE",
  },
  commentText: {
    marginRight: 15,
    color: "#5C5C5C",
    wordBreak: "break-all",
  },
  divCommentInfo: {
    display: "flex",
    flexDirection: "row",
    marginTop: 15,
    alignItems: "center",
    justifyContent: "flex-start",
  },
  correctorCommentInfo: {
    width: "100%",
  },
  commentInfo: {
    color: "#747474",
  },
  avatar: {
    marginBottom: 0,
    marginTop: 0,
    height: AVATAR_HEIGHT,
    width: AVATAR_HEIGHT,
    borderRadius: AVATAR_HEIGHT / 2,
    marginRight: 10,
  },
  firstName: {
    marginRight: 3,
  },
  elementSeparator: {
    marginBottom: 16,
  },
  reviewDivSeparator: {
    marginBottom: 17,
  },
  text: {
    color: Colors.text,
  },
}));

interface Props {
  _id?: string;
  isMine?: boolean;
  text?: string;
  audio?: string;
  video?: string;
  image?: string;
  likedBy?: string[];
  createdAt?: string | Date;
  isComment?: boolean;
  user: {
    avatar: string;
    name: string;
    _id?: string;
  };
}

const LikeButton = styled(IconButton)({
  color: "red",
});

const ReviewComment = ({
  isMine = false,
  user = { avatar: "", name: "", _id: "" },
  text,
  audio,
  video,
  image,
  isComment,
  likedBy = [],
  _id,
  createdAt = "",
}: Props) => {
  const userId = useSelector(getUserId);
  const [isLiked, setIsLiked] = useState(likedBy.includes(userId));
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleLikeClick = () => {
    setIsLiked(!isLiked);
    dispatch(likeMessage({ messageId: _id, userId }));
  };

  return (
    <div className={`${classes.main} ${isMine && classes.toRight}`}>
      <div
        className={`${classes.commentDiv} ${
          isMine && classes.correctorCommentDiv
        }`}
      >
        {(!!video || !!image) && (
          <div className="media-container">
            <ReviewFile
              createdAt={createdAt}
              fileName={!!video ? "video.mov" : "image.png"}
              fileType={!!video ? "video" : "image"}
              fileUrl={video || image}
              filePreview={image}
            />
          </div>
        )}

        {!!audio && (
          <div className="audio-container">
            <audio controls src={audio}></audio>
          </div>
        )}

        {!!text && !video && !image && !audio && (
          <MainText
            size="medium"
            className={`${classes.commentText}`}
            text={<Linkify>{text}</Linkify>} // * pas le meilleur truc puisque l'on passe un Element et plus un string
          />
        )}

        <div
          className={`${classes.divCommentInfo} ${
            isMine && classes.correctorCommentInfo
          }`}
        >
          {user._id !== userId && (
            <Avatar src={user.avatar} className={classes.avatar} />
          )}
          <MainText
            size="small"
            className={`${classes.commentInfo} ${classes.firstName}`}
            text={user.name + " -"}
          />
          <MainText
            size="small"
            className={classes.commentInfo}
            text={displayDate(createdAt, "Europe/Paris").toLowerCase()}
          />
          {!isComment && (
            <LikeButton onClick={handleLikeClick}>
              {isLiked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            </LikeButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReviewComment;
