import React from "react";
import withStyles from '@mui/styles/withStyles';
import TopBar from "components/TopBar";
import i18n from "i18n";
import SliderBars from "scenes/Campaigns/CampaignScreen/SurveysSide/SliderResults/SliderBars";
import selectLanguage from "utils/selectLanguage";

const styles = theme => ({
  main: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "row",
  },
  firstBox: {
    overflow: "hidden",
    marginTop: 30,
    paddingBottom: 30,
    borderRadius: 10,
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flex: 5,
  },
});

const formatStats = questions => {
  const results = questions.map(question => {
    const { questionTitle, answers } = question;
    const totalCount = answers.length;
    const result = {};
    result.title = selectLanguage({ text: questionTitle });
    const total = answers.reduce((prev, curr) => {
      return prev + curr.value;
    }, 0);
    result.value = (total / totalCount).toFixed(1);
    result.totalCount = totalCount;
    return result;
  });

  return results;
};

const SliderResults = ({ classes, className, results }) => {
  const formattedStats = formatStats(results);
  console.log(formattedStats);
  return (
    <div className={`${classes.main} ${className}`}>
      <div className={classes.firstBox}>
        <TopBar title={i18n.t("initial-feeling")} />
        <SliderBars stats={formattedStats} />
      </div>
    </div>
  );
};

export default withStyles(styles)(SliderResults);
