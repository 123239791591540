// First, we need to import the FlatList and other React Native component
import "intersection-observer";
import React, { Component } from "react";
// We also need to add the connectInfiniteHits connector to our import
import { connectInfiniteHits } from "react-instantsearch-dom";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import "moment/locale/fr";
import i18n from "../../i18n";
import { getAppLanguage } from "../../services/general/selectors";
import {
  getUserRequest,
  modifyMyUserRequest,
  modifyMyUserRoleRequest,
  deleteMyUserRequest,
  changeField,
} from "../../services/myUsers/actions";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";

import UserRow from "./UserRow";
import UsersAccessModal from "./UsersAccessModal";
import { getUserId } from "services/user/selectors";
import { setReceiver, cleanMessage } from "../../services/message/actions";
import { push } from "connected-react-router";
import {
  getCoachingSessionRequest,
  clearCoachingSession,
} from "services/coaching/actions";
import { seeUserProfileRequest } from "services/manager/actions";

const styles = theme => ({
  list: {
    minWidth: "100%",
    marginTop: 20,
  },
  title: {
    color: "black",
    backgroundColor: "white",
    border: "1px solid rgba(0,0,0,.09)",
    boxShadow: "0 2px 0 0 rgba(0,0,0,.03)",
    borderRadius: 5,
    padding: 10,
    fontWeight: 500,
    // marginBottom: 10,
    marginBottom: 20,
  },
  row: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
});

class MyUsersHits extends Component {
  state = {
    isDeleteConfirmModalVisible: false,
    isUsersAccessModalVisible: false,
    userIdSelected: "",
    userSelected: {},
  };

  onSentinelIntersection = entries => {
    const { hasMore, refine } = this.props;

    entries.forEach(entry => {
      if (entry.isIntersecting && hasMore) {
        // if (hasMore) {
        refine();
      }
    });
  };

  sentinel = null;

  componentDidMount() {
    this.observer = new IntersectionObserver(this.onSentinelIntersection);
    this.observer.observe(this.sentinel);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  onChangeRole = userId => event => {
    const value = event.target.value;

    const user = this.props.hits.find(el => el._id === userId);
    user.role = value;
    this.props.modifyMyUserRoleRequest({ role: value, userId });
    // this.props.modifyMyUserRequest({ role: value, userId });
  };

  onClickDelete = userIdSelected => () => {
    this.setState({ isDeleteConfirmModalVisible: true, userIdSelected });
  };

  onClickUsersAccess = userSelected => () => {
    this.setState({ userSelected, isUsersAccessModalVisible: true });
    // reset state
    this.props.changeField({
      userSelected: {
        ...this.props.userSelected,
        hasUsersAccessRestricted: false,
        usersAccess: [],
      },
    });
    this.props.getUserRequest({ userId: userSelected._id });
  };

  onConfirmDelete = event => {
    event.stopPropagation();
    const userIdSelected = this.state.userIdSelected;
    this.props.deleteMyUserRequest({
      userId: userIdSelected,
    });
    const user = this.props.hits.find(el => el._id === userIdSelected);
    user.isDeleted = true;
    this.setState({ isDeleteConfirmModalVisible: false });
  };

  isRoleInputDisabled = () => {
    const { role } = this.props;
    return role !== "owner" && role !== "admin" && role !== "superAdmin";
  };

  onClickUser = userSelected => {
    this.props.cleanMessage();

    this.props.clearCoachingSession();
    this.props.setReceiver({
      _id: userSelected.userId,
      picture: userSelected.picture,
    });

    this.props.seeUserProfileRequest({
      userId: userSelected.userId,
      managerId: this.props.userId,
    });

    this.props.getCoachingSessionRequest({
      userId: userSelected.userId,
      coachId: this.props.userId,
    });

    this.props.push(`/user/${userSelected.userId}`);
  };

  render() {
    const { hits, classes } = this.props;
    // return null;
    return (
      <div className={classes.list}>
        <UsersAccessModal
          {...this.state.userSelected}
          isVisible={this.state.isUsersAccessModalVisible}
          onClose={() => this.setState({ isUsersAccessModalVisible: false })}
        />
        <DeleteConfirmModal
          title={i18n.t("delete-user-confirm-title")}
          onClickConfirm={this.onConfirmDelete}
          isVisible={this.state.isDeleteConfirmModalVisible}
          onClose={() => this.setState({ isDeleteConfirmModalVisible: false })}
        />
        {hits.map((item, index) => {
          const {
            firstName,
            lastName,
            picture,
            createdAt,
            email,
            role,
            _id,
            isDeleted,
          } = item;
          return (
            <UserRow
              isDeleted={isDeleted}
              key={index}
              firstName={firstName}
              lastName={lastName}
              picture={picture}
              createdAt={createdAt}
              email={email}
              role={role}
              isEmail
              _id={_id}
              index={index}
              onClick={this.onClickUser}
              onClickDelete={this.onClickDelete(_id)}
              onClickUsersAccess={this.onClickUsersAccess(item)}
              isRoleInputDisabled={this.isRoleInputDisabled}
              onChangeRole={this.onChangeRole(_id)}
            />
          );
        })}
        <div
          className="ais-InfiniteHits-sentinel"
          ref={c => (this.sentinel = c)}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const language = getAppLanguage(state);
  const { requesting, userSelected } = state.myUsers;
  const { role } = state.user;
  const { plan } = state.client;
  const isSuperAdmin = role === "superAdmin";
  const userId = getUserId(state);
  return {
    userSelected,
    requesting,
    language,
    plan,
    role,
    isSuperAdmin,
    userId,
  };
};

export default connect(mapStateToProps, {
  modifyMyUserRequest,
  modifyMyUserRoleRequest,
  deleteMyUserRequest,
  getUserRequest,
  changeField,
  cleanMessage,
  seeUserProfileRequest,
  setReceiver,
  getCoachingSessionRequest,
  push,
  clearCoachingSession,
})(connectInfiniteHits(withStyles(styles)(MyUsersHits)));
