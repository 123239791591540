import React from "react";
import withStyles from "@mui/styles/withStyles";
import { Typography } from "@mui/material";
import CopyIcon from "@mui/icons-material/FileCopy";
import Colors from "constants/Colors";
import i18n from "i18n";
import { CopyToClipboard } from "react-copy-to-clipboard";
import beautifyCode from "utils/beautifyCode";
import { openSnackMessage } from "services/general/actions";
import { connect } from "react-redux";

const MAGIC_BOX_WIDTH = 300;

const styles = theme => ({
  main: {
    width: MAGIC_BOX_WIDTH,
    minWidth: MAGIC_BOX_WIDTH,
    position: "relative",
    cursor: "pointer",
    paddingTop: 35,
    borderRadius: 14,
    background: "#fff",
    border: "solid 1px #E4E4E4",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  code: {
    fontFamily: "Montserrat",
    fontSize: 32,
    color: Colors.pureMain,
    fontWeight: "bold",
    marginBottom: 35,
  },
  copyText: {
    marginLeft: 8,
    fontFamily: "Hind",
    color: "#525252",
  },
  copyDiv: {
    borderRadius: 6,
    padding: "22px 75px",
    backgroundColor: "#F3F3F3",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    fontFamily: "Hind",
    color: "#919191",
    fontSize: 16,
    marginBottom: 15,
  },
});

const MagicCodeBox = ({
  classes,
  accessCode,
  className = "",
  openSnackMessage,
}) => {
  const beautifulCode = beautifyCode(accessCode);
  return (
    <CopyToClipboard
      text={beautifulCode}
      onCopy={() =>
        openSnackMessage({
          snackMessage: i18n.t("access-code-copied"),
        })
      }
    >
      <div className={`${classes.main} ${className}`}>
        <Typography className={classes.text}>{i18n.t("magic-code")}</Typography>
        <Typography className={classes.code}>{beautifulCode}</Typography>
        <div className={classes.copyDiv}>
          <CopyIcon
            style={{ fontSize: 18, color: "#525252", marginBottom: 3 }}
          />
          <Typography className={classes.copyText}>
            {i18n.t("copy-code")}
          </Typography>
        </div>
      </div>
    </CopyToClipboard>
  );
};

export default connect(null, { openSnackMessage })(
  withStyles(styles)(MagicCodeBox),
);
