import React from "react";

const LikeIcon = ({
  width = 16,
  height = 16,
  color = "#007EFC",
  className = "",
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 16 16"
    version="1.1"
  >
    <path
      d="M6.99746 2.41971C7.09452 2.2048 7.30847 2.06667 7.54429 2.06667C8.13153 2.06667 8.69663 2.29616 9.11476 2.70765C9.53321 3.11946 9.77008 3.68006 9.77008 4.26666V5.79997H12.2345C12.4758 5.79781 12.7151 5.84703 12.9359 5.94458C13.1579 6.04264 13.3564 6.18737 13.5172 6.36936C13.678 6.55138 13.7973 6.76628 13.8661 6.9994C13.9348 7.23254 13.9513 7.47783 13.9143 7.71809L13.9141 7.719L13.1664 12.518L13.1663 12.5187C13.1046 12.9183 12.8985 13.2805 12.5887 13.5396C12.28 13.7979 11.8887 13.9368 11.4867 13.9333H3.75076C3.30724 13.9333 2.87999 13.76 2.56349 13.4485C2.24667 13.1367 2.06689 12.7118 2.06689 12.2666V8.53329C2.06689 8.08814 2.24667 7.6632 2.56349 7.35141C2.87999 7.03994 3.30724 6.86663 3.75076 6.86663H4.98918L6.99746 2.41971Z"
      fill={color}
    />
  </svg>
);

export default LikeIcon;
