import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Colors from "../constants/Colors";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FlatButton from "components/FlatButton";
import { LightButton } from "components/LightButton";
import i18n from "i18n";
import { makeStyles } from "@mui/styles";

const RemoveIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8245 10.9203L6.75262 5.84845L5.30353 7.29755L10.3754 12.3694L5.30353 17.4412L6.75262 18.8903L11.8245 13.8185L16.8963 18.8903L18.3454 17.4412L13.2736 12.3694L18.3454 7.29755L16.8963 5.84845L11.8245 10.9203Z"
      fill="#9E9E9E"
    />
  </svg>
);

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: 650,
  },
  line: {
    height: 1,
    backgroundColor: "#D8D8D8",
    width: "100%",
  },
  subtitleModal: {
    textAlign: "center",
    color: "#19212A",
    fontSize: 20,
  },
  buttons: {
    marginBottom: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  deleteText: {
    marginTop: 30,
    marginBottom: 30,
    fontSize: 16,
    textAlign: "center",
    color: "#19212A",
  },
  lightButton: {
    paddingLeft: 30,
    paddingRight: 30,
    borderColor: "#DFDFDF",
    borderRadius: 4,
    color: "#19212A",
    marginRight: 15,
    "&:hover": {
      borderColor: "#DFDFDF",
      backgroundColor: Colors.veryLightGrey,
      opacity: 0.9,
    },
  },
  deleteButton: {
    paddingLeft: 40,
    paddingRight: 40,
    backgroundColor: "#FF6565",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: "#FF6565",
      opacity: 0.9,
    },
  },
  removeButton: {
    position: "absolute",
    top: 7,
    right: 7,
  },
}));

interface IProps {
  title?: string;
  dialogTitle?: string;
  onClose: () => void;
  isVisible: boolean;
  onClickConfirm: () => void;
}

const DeleteConfirmModal = ({
  title,
  dialogTitle = i18n.t("delete-permanently"),
  onClose,
  isVisible,
  onClickConfirm,
}: IProps) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: `${classes.paper}` }}
      open={isVisible}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton
        className={classes.removeButton}
        onClick={onClose}
        size="large"
      >
        {<RemoveIcon />}
      </IconButton>
      <DialogTitle id="alert-dialog-title" className={classes.subtitleModal}>
        {dialogTitle}
      </DialogTitle>
      <div className={classes.line} />
      <DialogContent>
        <Typography className={classes.deleteText}>{title}</Typography>
        <div className={classes.buttons}>
          <LightButton
            onClick={onClose}
            className={classes.lightButton}
            title={i18n.t("Cancel")}
          />
          <FlatButton
            onClick={onClickConfirm}
            className={classes.deleteButton}
            title={i18n.t("Delete")}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(DeleteConfirmModal);
