import React, { PureComponent } from "react";
import { connect } from "react-redux";

import withStyles from '@mui/styles/withStyles';

import Colors from "../../constants/Colors";
import Tab from "./Tab";
import Snackbar from "./Snackbar";
import MyPolls from "./MyPolls";
import NewPoll from "./NewPoll";
import { getSelectedClientId } from "../../services/user/selectors";

const styles = theme => ({
  main: {
    flex: 1,
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // paddingTop: 20,
    paddingBottom: 100,
  },
  inputForm: {
    marginTop: 15,
    marginBottom: 15,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  inputLabel: {
    marginRight: 10,
    marginLeft: 10,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    textOverflow: "ellipsis",
    overflow: "hidden",
    // whiteSpace: "nowrap",
    color: Colors.text,
    width: "90%",
  },
  title: {
    fontSize: theme.typography.pxToRem(15),
    marginBottom: 15,
    color: Colors.text,
  },
  separator: {
    fontSize: theme.typography.pxToRem(15),
    marginBottom: 10,
    fontWeight: "bold",
    color: Colors.text,
  },
  text: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 700,
  },
  root: {
    width: "90%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
    marginBottom: 15,
  },
  button: {
    marginLeft: 15,
  },
  actionRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  rowSelected: {
    backgroundColor: Colors.lightGrey,
  },
  expansionDiv: {
    flex: 1,
  },
  details: {
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
  },
  checkDiv: {
    height: "100%",
    // backgroundColor: "#fff"
  },
  mainTitle: {
    alignSelf: "left",
    fontWeight: 500,
    marginBottom: 15,
  },
  saveButton: {
    backgroundColor: Colors.main,
  },
});

class PollsScreen extends PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.main}>
        <Tab />
        {this.props.tabSelected === 0 && <MyPolls />}
        {this.props.tabSelected === 1 && <NewPoll />}
        <Snackbar />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { requesting, tabSelected } = state.poll;
  const clientId = getSelectedClientId(state);
  return {
    token: state.auth.token,
    clientId,
    requesting,
    tabSelected,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(PollsScreen));
