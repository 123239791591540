import React from "react";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  main: {},
}));

interface Props {}

const ClientFilter = () => {
  const classes = useStyles();

  return <div className={classes.main}></div>;
};

export default ClientFilter;
