import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Avatar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import i18n from "i18n";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { getMyCoachees } from "services/myCoachees/selectors";
import { getMyCoacheesRequest } from "services/myCoachees/actions";
import { getUserId } from "services/user/selectors";

const useStyles = makeStyles(theme => ({
  main: {},
  topDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginBottom: 100,
  },
  text: {
    alignSelf: "flex-start",
    fontFamily: "DM Sans",
    color: "rgba(32, 32, 32, 1)",
    fontWeight: 500,
    marginBottom: 15,
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  avatar: {
    width: 20,
    height: 20,
    marginRight: 10,
  },
}));

interface Props {
  onSelectCoachee: (coachId: string) => void;
  coacheeId?: string;
}

const SelectCoachee = ({ onSelectCoachee, coacheeId }: Props) => {
  const classes = useStyles();
  const [coacheeName, setCoacheeName] = useState("");

  const myCoachees = useSelector(getMyCoachees);
  const coachId = useSelector(getUserId);

  const dispatch = useDispatch();

  const onInit = function () {
    dispatch(getMyCoacheesRequest({ coachId }));
  };

  useEffect(onInit, []);

  const formattedCoachees = useMemo(
    () =>
      myCoachees.map(coachee => {
        const { firstName, lastName } = coachee.userId;
        return {
          ...coachee.userId,
          coacheeName: `${firstName} ${lastName}`,
        };
      }),
    [myCoachees],
  );

  useEffect(() => {
    if (coacheeId) {
      const coachee = formattedCoachees.find(el => el._id === coacheeId);
      if (coachee) {
        setCoacheeName(coachee.coacheeName);
      }
    }
  }, [formattedCoachees, coacheeId]);

  const onChangeCoachName = useCallback(() => {
    const coachee = formattedCoachees.find(
      el => el.coacheeName === coacheeName,
    );
    if (coachee) {
      onSelectCoachee(coachee._id);
    }
  }, [coacheeName, formattedCoachees, onSelectCoachee]);

  useEffect(onChangeCoachName, [coacheeName]);

  return (
    <div className={classes.main}>
      <Autocomplete
        options={formattedCoachees}
        classes={{
          option: classes.option,
        }}
        onChange={(event, newValue) => {}}
        onInputChange={(event, newValue) => {
          setCoacheeName(newValue);
        }}
        inputValue={coacheeName}
        fullWidth
        autoHighlight
        getOptionLabel={(option: any) => option.coacheeName}
        renderOption={(option: any) => (
          <React.Fragment>
            <Avatar src={option.picture} className={classes.avatar} />
            {option.coacheeName}
          </React.Fragment>
        )}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={i18n.t("select-a-coachee")}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
      />
    </div>
  );
};

export default SelectCoachee;
