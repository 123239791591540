import React, { Component } from "react";
import withStyles from '@mui/styles/withStyles';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Colors from "../../constants/Colors";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";

import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import { connect } from "react-redux";
import {
  addOption,
  removeOption,
  modifyOption,
  changeTitle,
  createPollRequest,
} from "../../services/poll/actions";
import moment from "moment";
import { getUserId, getSelectedClientId } from "../../services/user/selectors";
import i18n from "../../i18n";
import { getSelectedClient } from "../../services/myClients/selectors";
moment.locale("fr");

const styles = theme => ({
  closeButton: {
    zIndex: 10,
    position: "absolute",
    top: 1,
    right: 1,
  },
  minDiv: {
    // width: 300,
    // minWidth: 100
  },
  accent: {
    color: "#6198DE",
  },
  commentDiv: {
    width: "100%",
    borderRadius: 8,
    border: "solid",
    borderWidth: 1,
    borderColor: Colors.lightGrey,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    marginBottom: 15,
  },
  date: {
    textAlign: "right",
    fontSize: 12,
  },
  commentMainDiv: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    flexDirection: "row",
  },
  actionsButtonDiv: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  deleteButton: {
    padding: 1,
  },
  editButton: {
    padding: 1,
    marginRight: -3,
  },
  commentBottom: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    display: "flex",
  },
  leftDiv: {
    flex: 2,
    flexWrap: "wrap",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  rightDiv: {
    flex: 3,
    height: 400,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  mainDiv: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  paper: {
    minWidth: "30%",
    maxWidth: "50%",
    // maxWidth: 1200
  },
  addOptionButton: {
    marginTop: 10,
  },
  saveButton: {
    marginTop: 35,
  },
  answerSeparatorText: {
    color: Colors.blackGrey,
    fontWeight: "bold",
    whiteSpace: "nowrap",
    marginLeft: 10,
    marginRight: 10,
  },
  separator: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  line: {
    width: "100%",
    borderRadius: 8,
    height: 2,
    backgroundColor: Colors.blackGrey,
  },
  errorText: {
    color: "#e6525a",
    padding: "13px 15px",
    borderRadius: 6,
    backgroundColor: "rgba(230,82,90,0.15)",
  },
});

class CreationModal extends Component {
  state = {
    open: false,
    error: "",
    titleError: "",
    commentId: "",
  };

  _handleKeyDown = event => {
    if (
      (window.navigator.platform.match("Mac")
        ? event.metaKey
        : event.ctrlKey) &&
      event.keyCode === 83
    ) {
      event.preventDefault();
      this.submitGoals();
    }
  };

  formatDate = date => {
    return moment(date).format("L");
  };

  onDelete = commentId => () => {
    this.setState({ open: true, commentId });
  };

  onRendered = () => {
    // document.addEventListener("keydown", this._handleKeyDown, false);
  };

  handleClose = onClose => () => {
    // document.removeEventListener("keydown", this._handleKeyDown, false);
    onClose();
  };

  onEditTitle = event => {
    if (this.state.titleError) {
      this.setState({ titleError: "" });
    }
    const value = event.target.value;
    this.props.changeTitle(value);
  };

  onEditText = event => {
    const value = event.target.value;
    this.props.changeField({ notificationText: value });
  };

  onEditOption = option => event => {
    const value = event.target.value;
    this.props.modifyOption({ option, value });
  };

  addOption = () => {
    this.props.addOption();
  };

  removeOption = option => () => {
    this.props.removeOption(option);
  };

  sendPoll = () => {
    this.setState({ error: "" });
    const { userId, title, options, employees, clientId } = this.props;
    // if (managerTeam.length < 2) {
    // if (employees.length < 2) {
    //   return this.setState({ error: i18n.t("poll-error-too-small") });
    // }
    if (!title) {
      return this.setState({ titleError: i18n.t("poll-error-empty-question") });
    }
    if (options.findIndex(el => !el) > -1) {
      return this.setState({ error: i18n.t("poll-error-empty-options") });
    }

    this.props.createPollRequest({
      receivers: employees,
      clientId,
      userId,
      title,
      options,
    });
    this.props.onClose();
  };

  displayMoreOptions = options => {
    const optionsToDisplay = options.slice(2);
    return optionsToDisplay.map((el, i) => {
      return (
        <TextField
          autoFocus
          multiline
          fullWidth
          label={`Option ${i + 3}`}
          value={this.props.options[i + 2]}
          onChange={this.onEditOption(i + 2)}
          margin="normal"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={this.removeOption(i + 2)}
                  size="large">
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      );
    });
  };

  displayTeam = () => {
    const { classes } = this.props;
    return (
      <div className={classes.selectedMembers}>
        {this.props.managerTeam.map(el => {
          return (
            <div
              key={el._id}
              id={el._id}
              firstName={el.firstName}
              lastName={el.lastName}
              picture={el.picture}
              isSelected
            />
          );
        })}
      </div>
    );
  };

  render() {
    const { classes, isVisible, onClose } = this.props;
    return (
      <Dialog
        open={isVisible}
        ref={this.onRendered}
        onClose={this.handleClose(onClose)}
        classes={{ paper: classes.paper }}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        <IconButton className={classes.closeButton} onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
        <DialogTitle id="scroll-dialog-title">
          {i18n.t("create-poll")}
        </DialogTitle>
        <DialogContent>
          <div className={classes.mainDiv}>
            {/* <div className={classes.teamDiv}>{this.displayTeam()}</div> */}
            <div className={classes.separator}>
              <div className={classes.line} />
              <p className={classes.answerSeparatorText}>
                {i18n.t("the-question")}
              </p>
              <div className={classes.line} />
            </div>
            <TextField
              fullWidth
              autoFocus
              multiline
              error={this.state.titleError}
              label={i18n.t("poll-what-question")}
              value={this.props.title}
              onChange={this.onEditTitle}
              margin="normal"
              variant="outlined"
            />
            <div className={classes.separator}>
              <div className={classes.line} />
              <p className={classes.answerSeparatorText}>
                {i18n.t("the-answers")}
              </p>
              <div className={classes.line} />
            </div>
            <TextField
              multiline
              fullWidth
              label="Option 1"
              value={this.props.notificationText}
              onChange={this.onEditOption(0)}
              margin="normal"
              variant="outlined"
            />
            <TextField
              multiline
              fullWidth
              label="Option 2"
              value={this.props.notificationText}
              onChange={this.onEditOption(1)}
              margin="normal"
              variant="outlined"
            />
            {this.displayMoreOptions(this.props.options)}
            <Button
              className={classes.addOptionButton}
              onClick={this.addOption}
              variant="outlined"
              color="primary"
            >
              {i18n.t("add-option")}
            </Button>
            <div className={classes.lastLine} />

            {this.state.error && (
              <p className={classes.errorText}>{this.state.error}</p>
            )}
            {this.props.requesting ? (
              <CircularProgress />
            ) : (
              <Button
                className={classes.saveButton}
                fullWidth
                onClick={this.sendPoll}
                variant="contained"
                color="primary"
              >
                {i18n.t("send-poll")}
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  const { requesting, pollSelected, polls, options, title } = state.poll;
  const userId = getUserId(state);
  const clientId = getSelectedClientId(state);
  const selectedClient = getSelectedClient(state);
  const { employees } = selectedClient;
  return {
    userId,
    clientId,
    requesting,
    options,
    title,
    pollSelected,
    polls,
    employees,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    addOption,
    removeOption,
    modifyOption,
    changeTitle,
    createPollRequest,
  })(CreationModal),
);
