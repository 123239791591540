import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { Typography } from "@mui/material";
import i18n from "i18n";
import Colors from "constants/Colors";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import UnderlineTitle from "scenes/CreateTrack/CreateActionTextStep/UnderlineTitle";
import { useSelector } from "react-redux";
import { getLanguageSelected } from "services/createTrack/selectors";
import UserComment from "./UserComment";
import { getStepCommentRequesting } from "services/campaign/selectors";
import { Loader } from "components";
import FilterList, { IFilter } from "components/FilterList";

const PADDING_RIGHT = 25;
const MARGIN_LEFT = 20;

const PREVIEW_SIDE_WIDTH = 800;

const useStyles = makeStyles(theme => ({
  main: {
    opacity: 0,
    zIndex: -10,
    backgroundColor: "#fff",
    width: 0,
    height: "100%",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    boxShadow: "-9px 4px 12px rgba(197, 178, 178, 0.14);",
    transition: "all 0.350s ease",
    display: "flex",
    flexDirection: "column",
    overflow: "scroll",
    paddingBottom: 50,
    flex: 1,
  },
  outsideDiv: {
    zIndex: -10,
    position: "fixed",
    left: 0,
    top: 0,
    minHeight: "100vh",
    backgroundColor: "black",
    opacity: 0,
    height: "100vh",
    transition: "all 0.350s ease",
    scale: 0.1,
  },
  outsideDivVisible: {
    zIndex: 1,
    scale: 1,
    opacity: 0.1,
  },
  exitIcon: {
    cursor: "pointer",
    borderBottomLeftRadius: 4,
    height: 40,
    width: 40,
    zIndex: 99999,
    top: 0,
    right: 0,
    transition: "all 0.2s ease",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "&:hover": {
      opacity: 0.9,
    },
    position: "absolute",
    backgroundColor: "rgba(0, 126, 252, 1)",
  },
  leftSide: {
    width: PREVIEW_SIDE_WIDTH,
    maxWidth: PREVIEW_SIDE_WIDTH,
    paddingLeft: 35,
    paddingRight: 15,
    paddingTop: 25,
    display: "flex",
    flexDirection: "column",
    overflow: "scroll",
    flex: 1,
  },
  rightSide: {
    width: PREVIEW_SIDE_WIDTH,
    maxWidth: PREVIEW_SIDE_WIDTH,
    paddingTop: 10,
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
    overflowX: "hidden",
    flex: 1,
    backgroundColor: "#FAFAFB",
  },
  titleInput: {
    fontWeight: "bold",
    fontFamily: "Montserrat",
    color: Colors.darkBlue,
    fontSize: 24,
    marginBottom: 20,
  },
  visible: {
    zIndex: 20,
    position: "fixed",
    width: PREVIEW_SIDE_WIDTH,
    opacity: 1,
  },
  close: {
    zIndex: 50,
    position: "fixed",
    top: -200,
    right: -200,
    opacity: 0,
    marginTop: 15,
  },
  buttonVisible: {
    top: 20,
    right: 20,
    opacity: 1,
  },
  body: {
    height: "100%",
    width: "100%",
    zIndex: 1,
    flexDirection: "row",
    display: "flex",
  },
  comments: {
    width: PREVIEW_SIDE_WIDTH,
    maxWidth: PREVIEW_SIDE_WIDTH,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    paddingBottom: 50,
    marginBottom: 25,
  },
  files: {
    paddingLeft: 40,
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    paddingBottom: 90,
    marginBottom: 25,
  },
  comment: {
    width: "66%",
  },
  title: {
    width: "100%",
    textAlign: "left",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    color: Colors.darkBlue,
    fontSize: 20,
    marginBottom: 10,
  },
  commentsTitle: {
    textAlign: "left",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    color: Colors.darkBlue,
    fontSize: 20,
    marginLeft: 8,
    marginRight: 5,
  },
  commentsHeaderDiv: {
    alignSelf: "flex-start",
    paddingRight: PADDING_RIGHT,
    marginLeft: MARGIN_LEFT + 5,
    marginBottom: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  bubble: {
    borderRadius: "50%",
    width: 24,
    height: 24,
    minWidth: 24,
    minHeight: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "black",
  },
  numberOfComments: {
    color: "#fff",
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Montserrat",
  },
  commentLine: {
    backgroundColor: "rgba(240, 240, 240, 1)",
    height: 1,
    width: "100%",
  },
  closeIcon: {
    color: "#fff",
    width: 20,
    height: 20,
    zIndex: 5,
  },
}));

const FILTER_1 = { label: i18n.t("FILTER_ALL_STEP_COMMENTS"), color: "black" };
const FILTER_2 = {
  color: "rgba(76, 188, 48, 1)",
  label: i18n.t("FILTER_PUBLISHED_STEP_COMMENTS"),
};
const FILTER_3 = {
  label: i18n.t("FILTER_NOT_PUBLISHED_STEP_COMMENTS"),
  color: "rgba(251, 145, 145, 1)",
};

const FILTERS: IFilter[] = [FILTER_1, FILTER_2, FILTER_3];

const filterFunction = filterSelected => el => {
  const commentStatus =
    el?.responses instanceof Array ? "published" : "not-published";

  return (
    (FILTER_2.label === filterSelected && commentStatus === "published") ||
    (FILTER_3.label === filterSelected && commentStatus === "not-published")
  );
};

interface IPreviewSideBar {
  onClose?: () => void;
  isVisible: boolean;
  comments: any[];
  stepPreview: {
    isPrivate: boolean;
    content: { title: {}; how: {}; why: {} };
    title: {};
    _id: string;
  };
  stepPreviewId: string;
  files: any[];
}

const PreviewStep = ({
  onClose = null,
  isVisible = true,
  comments = [],
  stepPreview = {
    isPrivate: true,
    content: { title: {}, how: {}, why: {} },
    title: {},
    _id: "",
  },
  stepPreviewId = "",
  files = [],
}: IPreviewSideBar) => {
  const { isPrivate = true, content, title } = stepPreview;
  const refMain = useRef(null);

  const [filterSelected, selectCommentsFilter] = useState(FILTER_1);

  const selectFilter = filter => {
    selectCommentsFilter(filter);
  };

  const languageSelected = useSelector(getLanguageSelected);

  const stepCommentRequesting = useSelector(getStepCommentRequesting);

  const classes = useStyles();

  useEffect(() => {
    if (refMain) {
      refMain.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [stepPreview]);

  const stepPreviewIndex = parseInt(stepPreviewId);

  const filteredComments = useMemo(
    () =>
      filterSelected?.label === i18n.t(FILTER_1.label)
        ? comments
        : comments.filter(filterFunction(filterSelected.label)),
    [comments, filterSelected.label, filterFunction],
  );

  return (
    <div
      className={`${classes.main} ${isVisible ? classes.visible : undefined}`}
      style={{
        opacity: isVisible ? 1 : 0,
        zIndex: isVisible ? 20 : -10,
        width: isVisible ? PREVIEW_SIDE_WIDTH : 0,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div className={classes.exitIcon} onClick={onClose}>
        <CloseIcon className={classes.closeIcon} />
      </div>
      <div
        onClick={onClose}
        className={`${classes.outsideDiv} ${
          isVisible && classes.outsideDivVisible
        }`}
        style={{
          width: `calc(100vw - ${PREVIEW_SIDE_WIDTH}px`,
        }}
      />
      <div className={classes.body} ref={refMain} style={{ display: "flex" }}>
        <div className={classes.leftSide}>
          {stepPreview.content && (
            <>
              {title && title[languageSelected] && (
                <Typography className={`${classes.titleInput} `}>
                  {title[languageSelected]}
                </Typography>
              )}
              <UnderlineTitle
                contentKey={"content.title"}
                modifyStep={() => null}
                contentObjectKey={"title"}
                stepPreviewIndex={stepPreviewIndex}
                stepSelectedIndex={stepPreviewIndex}
                languageSelected={languageSelected}
                isDisabled={true}
                placeholder={i18n.t("create-action-title-placeholder", {
                  lng: languageSelected,
                })}
                title={i18n.t("Challenge", { lng: languageSelected })}
                value={content?.title?.[languageSelected]}
                bottomColor={Colors.lightMain}
              />
              <UnderlineTitle
                contentKey={"content.how"}
                modifyStep={() => null}
                contentObjectKey={"how"}
                stepPreviewIndex={stepPreviewIndex}
                stepSelectedIndex={stepPreviewIndex}
                languageSelected={languageSelected}
                isDisabled={true}
                placeholder={i18n.t("create-action-how-placeholder", {
                  lng: languageSelected,
                })}
                title={i18n.t("create-action-how", { lng: languageSelected })}
                bottomColor={Colors.lightPink}
                value={content?.how?.[languageSelected]}
              />
              <UnderlineTitle
                contentKey={"content.why"}
                modifyStep={() => null}
                contentObjectKey={"why"}
                stepPreviewIndex={stepPreviewIndex}
                stepSelectedIndex={stepPreviewIndex}
                languageSelected={languageSelected}
                isDisabled={true}
                placeholder={i18n.t("create-action-why-placeholder", {
                  lng: languageSelected,
                })}
                title={i18n.t("create-action-why", { lng: languageSelected })}
                bottomColor={Colors.lightOrange}
                value={content?.why?.[languageSelected]}
              />
            </>
          )}
        </div>
        <div className={classes.rightSide}>
          <div className={classes.commentsHeaderDiv}>
            <div className={classes.bubble}>
              {stepCommentRequesting ? (
                <Loader size={15} />
              ) : (
                <Typography className={classes.numberOfComments}>
                  {comments.length}
                </Typography>
              )}
            </div>
            <Typography className={classes.commentsTitle}>
              {i18n.t("Comments")}
            </Typography>
            <FilterList
              isSmall
              filterSelected={filterSelected}
              selectFilter={selectFilter}
              filters={FILTERS}
            />
          </div>
          <div className={classes.comments}>
            {filteredComments.map((el, index) => (
              <div key={index}>
                <UserComment
                  stepCommentId={el?._id}
                  responses={el.responses}
                  like={el?.like || {}}
                  userName={
                    (el?.userId?.firstName || el?.user?.firstName) +
                    " " +
                    (el?.userId?.lastName || el?.user?.lastName)
                  }
                  userPicture={el?.userId?.picture || el?.user?.picture}
                  file={el?.files?.[0] || el?.fileId}
                  comment={el?.comment}
                  userId={el?.userId?._id || el?.user?._id}
                />
                <div className={classes.commentLine} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewStep;
