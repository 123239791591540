export const ADD_QUESTION = "survey/ADD_QUESTION";
export const CREATE_NEW_QUESTION = "survey/CREATE_NEW_QUESTION";
export const REMOVE_QUESTION = "survey/REMOVE_QUESTION";
export const REORDER_QUESTIONS = "survey/REORDER_QUESTIONS";

export const QUESTION_CREATE_REQUESTING = "survey/QUESTION_CREATE_REQUESTING";
export const QUESTION_CREATE_SUCCESS = "survey/QUESTION_CREATE_SUCCESS";
export const QUESTION_CREATE_ERROR = "survey/QUESTION_CREATE_ERROR";

export const QUESTION_MODIFY_REQUESTING = "survey/QUESTION_MODIFY_REQUESTING";
export const QUESTION_MODIFY_SUCCESS = "survey/QUESTION_MODIFY_SUCCESS";
export const QUESTION_MODIFY_ERROR = "survey/QUESTION_MODIFY_ERROR";

export const QUESTION_DELETE_REQUESTING = "survey/QUESTION_DELETE_REQUESTING";
export const QUESTION_DELETE_SUCCESS = "survey/QUESTION_DELETE_SUCCESS";
export const QUESTION_DELETE_ERROR = "survey/QUESTION_DELETE_ERROR";

export const SURVEY_CHANGE_FIELD = "survey/SURVEY_CHANGE_FIELD";

export const CHANGE_QUESTION_SELECTED = "survey/CHANGE_QUESTION_SELECTED";

export const SURVEY_CREATE_REQUESTING = "survey/SURVEY_CREATE_REQUESTING";
export const SURVEY_CREATE_SUCCESS = "survey/SURVEY_CREATE_SUCCESS";
export const SURVEY_CREATE_ERROR = "survey/SURVEY_CREATE_ERROR";

export const SURVEY_GET_REQUESTING = "survey/SURVEY_GET_REQUESTING";
export const SURVEY_GET_SUCCESS = "survey/SURVEY_GET_SUCCESS";
export const SURVEY_GET_ERROR = "survey/SURVEY_GET_ERROR";

export const SURVEY_MODIFY_REQUESTING = "survey/SURVEY_MODIFY_REQUESTING";
export const SURVEY_MODIFY_SUCCESS = "survey/SURVEY_MODIFY_SUCCESS";
export const SURVEY_MODIFY_ERROR = "survey/SURVEY_MODIFY_ERROR";

export const SURVEY_DELETE_REQUESTING = "survey/SURVEY_DELETE_REQUESTING";
export const SURVEY_DELETE_SUCCESS = "survey/SURVEY_DELETE_SUCCESS";
export const SURVEY_DELETE_ERROR = "survey/SURVEY_DELETE_ERROR";

export const SAVE_SURVEY_REQUESTING = "survey/SAVE_SURVEY_REQUESTING";

export const SURVEY_SET = "survey/SURVEY_SET";
export const NEW_SURVEY = "survey/NEW_SURVEY";

export const DUPLICATE_QUESTION = "survey/DUPLICATE_QUESTION";

export const GET_SURVEYS_REQUESTING = "survey/GET_SURVEYS_REQUESTING";
export const GET_SURVEYS_SUCCESS = "survey/GET_SURVEYS_SUCCESS";
export const GET_SURVEYS_ERROR = "survey/GET_SURVEYS_ERROR";

export const SEND_SURVEY_REQUESTING = "survey/SEND_SURVEY_REQUESTING";
export const SEND_SURVEY_SUCCESS = "survey/SEND_SURVEY_SUCCESS";
export const SEND_SURVEY_ERROR = "survey/SEND_SURVEY_ERROR";

export const GET_CAMPAIGN_SURVEYS_REQUESTING =
  "survey/GET_CAMPAIGN_SURVEYS_REQUESTING";
export const GET_CAMPAIGN_SURVEYS_SUCCESS =
  "survey/GET_CAMPAIGN_SURVEYS_SUCCESS";
export const GET_CAMPAIGN_SURVEYS_ERROR = "survey/GET_CAMPAIGN_SURVEYS_ERROR";

export const SEND_EVOLUTION_SURVEY_REQUESTING =
  "survey/SEND_EVOLUTION_SURVEY_REQUESTING";
export const SEND_EVOLUTION_SURVEY_ERROR = "survey/SEND_EVOLUTION_SURVEY_ERROR";
export const SEND_EVOLUTION_SURVEY_SUCCESS =
  "survey/SEND_EVOLUTION_SURVEY_SUCCESS";

export const GET_EVOLUTION_SURVEY_ANSWERS_REQUESTING =
  "survey/GET_EVOLUTION_SURVEY_ANSWERS_REQUESTING";
export const GET_EVOLUTION_SURVEY_ANSWERS_ERROR =
  "survey/GET_EVOLUTION_SURVEY_ANSWERS_ERROR";
export const GET_EVOLUTION_SURVEY_ANSWERS_SUCCESS =
  "survey/GET_EVOLUTION_SURVEY_ANSWERS_SUCCESS";
