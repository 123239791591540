import { uploadPictureWithoutRedux } from "services/upload/sagas";
import slugify from "slugify";

// 5 mo
const MAX_UPLOAD_SIZE = 5046507;

class MyUploadAdapter {
  constructor(loader) {
    // The file loader instance to use during the upload.
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then(
      file =>
        new Promise((resolve, reject) => {
          const toBase64 = file =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = error => reject(error);
            });

          return toBase64(file).then(async cFile => {
            try {
              console.log({ file });
              console.log({ cFile });
              if (file.size > MAX_UPLOAD_SIZE) {
                return reject(`File is to large > 5mo`);
              }

              const date = new Date().toISOString().toString();
              const urlName = slugify(
                `action-picture/${file.lastModified}/${date}`,
              );
              console.log(urlName);
              // const urlName = `action-picture/${this.props.userId}/${date}`;
              const finalUrl = `https://liife-db.s3.amazonaws.com/${urlName}`;

              const response = await uploadPictureWithoutRedux({
                urlName,
                file,
                fileType: file.type,
              });

              // console.log(response);
              console.log({ response });
              this.loader.uploaded = true;

              resolve({
                default: finalUrl,
              });
            } catch (e) {
              console.log(e);
              reject(`Couldn't upload file: ${file.name}.`);
            }
          });
        }),
    );
  }
}

// ...

export default function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = loader => {
    // Configure the URL to the upload script in your back-end here!
    return new MyUploadAdapter(loader);
  };
}
