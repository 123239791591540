import React from "react";

const VideoIcon = ({
  className = "",
  height = 41,
  width = 51,
  color = "#5FB8F9",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 51 41"
      fill="none"
      className={className}
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M44.6571 8.17085C44.4671 7.42585 43.9271 6.86335 43.3096 6.69085C42.2171 6.38335 36.8921 5.68335 25.6421 5.68335C14.3921 5.68335 9.07209 6.38335 7.96959 6.69085C7.35959 6.86085 6.81959 7.42335 6.62709 8.17085C6.35459 9.23085 5.64209 13.6733 5.64209 20.6833C5.64209 27.6933 6.35459 32.1334 6.62709 33.1983C6.81709 33.9408 7.35709 34.5034 7.97209 34.6734C9.07209 34.9834 14.3921 35.6833 25.6421 35.6833C36.8921 35.6833 42.2146 34.9833 43.3146 34.6758C43.9246 34.5059 44.4646 33.9433 44.6571 33.1958C44.9296 32.1358 45.6421 27.6833 45.6421 20.6833C45.6421 13.6833 44.9296 9.23335 44.6571 8.17085ZM49.4996 6.92835C50.6421 11.3833 50.6421 20.6833 50.6421 20.6833C50.6421 20.6833 50.6421 29.9834 49.4996 34.4384C48.8646 36.9009 47.0071 38.8383 44.6546 39.4934C40.3821 40.6833 25.6421 40.6833 25.6421 40.6833C25.6421 40.6833 10.9096 40.6833 6.62959 39.4934C4.26709 38.8284 2.41209 36.8934 1.78459 34.4384C0.64209 29.9834 0.64209 20.6833 0.64209 20.6833C0.64209 20.6833 0.64209 11.3833 1.78459 6.92835C2.41959 4.46585 4.27709 2.52835 6.62959 1.87335C10.9096 0.683349 25.6421 0.68335 25.6421 0.68335C25.6421 0.68335 40.3821 0.683349 44.6546 1.87335C47.0171 2.53835 48.8721 4.47335 49.4996 6.92835ZM20.6421 29.4333V11.9333L35.6421 20.6833L20.6421 29.4333Z"
        fill={color}
      />
    </svg>
  );
};

export default VideoIcon;
