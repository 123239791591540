import i18n from "i18n";
import moment from "moment";
import { ProductLine, Invoice } from "./types";

export const initialProductLine: ProductLine = {
  description: "",
  quantity: "1",
  rate: "0.00",
};

export const initialInvoice: Invoice = {
  title: "INVOICE",
  invoiceName: "",
  companyName: "",
  name: "",
  logo: "",
  companyAddress: "",
  companyAddress2: "",
  companyCountry: "France",
  billTo: i18n.t("invoice-billTo"),
  clientName: "",
  clientAddress: "",
  clientAddress2: "",
  clientCountry: "France",
  invoiceTitleLabel: i18n.t("invoice-invoiceTitleLabel"),
  invoiceTitle: "F202100001",
  invoiceDateLabel: i18n.t("invoice-dateLabel"),
  invoiceDate: new Date().toString(),
  invoiceDueDateLabel: i18n.t("invoice-dueDate"),
  invoiceDueDate: moment().add(30, "days").toString(),
  productLineDescription: i18n.t("invoice-itemDescription"),
  productLineQuantity: i18n.t("invoice-itemQuantity"),
  productLineQuantityRate: i18n.t("invoice-rate"),
  productLineQuantityAmount: i18n.t("invoice-amount"),
  productLines: [
    {
      description: "Coaching",
      quantity: "2",
      rate: "200.00",
    },
    { ...initialProductLine },
  ],
  totalAmount: 0,
  subTotalLabel: i18n.t("invoice-subTotal"),
  taxLabel: i18n.t("invoice-saleTax"),
  totalLabel: i18n.t("invoice-total"),
  currency: i18n.t("invoice-currency"),
  notesLabel: i18n.t("invoice-notesLabel"),
  notes: i18n.t("invoice-notesPlaceholder"),
  termLabel: i18n.t("invoice-termLabel"),
  term: i18n.t("invoice-term"),
};
