import React from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography, TextField } from "@mui/material";
import { getSelectedQuestion } from "services/survey/selectors";
import { changeQuestionSelected } from "services/survey/actions";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import FlatButton from "components/FlatButton";

import i18n from "i18n";

import { connect } from "react-redux";

const styles = theme => ({
  main: {
    width: "100%",
    height: "100%",
    flexDirection: "column",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingTop: 5,
  },
  title: {
    marginTop: 20,
    fontSize: 18,
    fontFamily: "Montserrat",
    fontWeight: "bold",
  },
  titleInput: {},
  addOptionButton: {
    marginTop: 10,
    marginBottom: 25,
  },
});

const CreateMultipleChoices = ({
  classes,
  languageSelected = "fr",
  isDisabled = false,
  changeQuestionSelected,
  selectedQuestion = {},
}) => {
  const { questionTitle, choices = [{ fr: "", en: "" }] } = selectedQuestion;

  const onChangeQuestionTitle = e => {
    const { value } = e.target;
    const newTitle = { ...questionTitle, [languageSelected]: value };
    changeQuestionSelected({ questionTitle: newTitle });
  };

  const onEditChoice = choiceIndex => event => {
    const { value } = event.target;
    const newChoices = choices.map((choice, index) => {
      if (index === choiceIndex) {
        return { ...choice, [languageSelected]: value };
      }
      return choice;
    });

    changeQuestionSelected({ choices: newChoices });
  };

  const addChoice = () => {
    console.log("CHOICES");
    console.log(choices);
    const newChoices = [...choices, { fr: "", en: "" }];
    console.log(newChoices);
    changeQuestionSelected({ choices: newChoices });
  };

  const removeChoice = choiceIndex => () => {
    const newChoices = choices.filter((choice, index) => index !== choiceIndex);
    changeQuestionSelected({ choices: newChoices });
  };

  const displayMoreChoices = () => {
    return choices.map((el, index) => {
      return (
        <TextField
          key={index}
          autoFocus
          multiline
          fullWidth
          label={`Option ${index + 1}`}
          value={choices[index][languageSelected] || ""}
          onChange={onEditChoice(index)}
          margin="normal"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  tabIndex="-1"
                  aria-label="Toggle password visibility"
                  onClick={removeChoice(index)}
                  size="large">
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      );
    });
  };

  return (
    <div className={classes.main}>
      <Typography className={classes.title}>
        {i18n.t("question-title", { lng: languageSelected })}
      </Typography>
      <TextField
        variant="outlined"
        InputProps={{
          // disableUnderline: true,
          classes: {
            input: classes.titleInput,
          },
        }}
        disabled={isDisabled}
        className={classes.titleInput}
        autoFocus
        fullWidth
        title={i18n.t("question-title", {
          lng: languageSelected,
        })}
        placeholder={i18n.t("question-title", {
          lng: languageSelected,
        })}
        value={
          questionTitle && questionTitle[languageSelected]
            ? questionTitle[languageSelected]
            : ""
        }
        onChange={onChangeQuestionTitle}
        margin="normal"
      />
      <Typography className={classes.title}>
        {i18n.t("question-options", { lng: languageSelected })}
      </Typography>
      {displayMoreChoices()}
      <FlatButton
        className={classes.addOptionButton}
        onClick={addChoice}
        variant="outlined"
        color="primary"
        title={i18n.t("add-option")}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    selectedQuestion: getSelectedQuestion(state),
    languageSelected: state.survey.languageSelected,
  };
};

export default connect(mapStateToProps, { changeQuestionSelected })(
  withStyles(styles)(CreateMultipleChoices),
);
