import React from "react";
import withStyles from '@mui/styles/withStyles';
import Title from "scenes/CreateTrack/PublishCampaignModal/Title";
import Explanation from "scenes/CreateTrack/PublishCampaignModal/Explanation";
import i18n from "i18n";
import TextBox from "components/TextBox";

const styles = theme => ({
  main: {
    marginTop: 15,
  },
  boxes: {
    marginTop: 10,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  box: {
    width: 200,
    marginLeft: 0,
    marginRight: 15,
  },
});

const Frequency = ({
  classes,
  languageSelected,
  onChangeFrequency,
  frequency,
}) => {
  return (
    <div className={classes.main}>
      <Title
        text={i18n.t("campaign-frequency", {
          lng: languageSelected,
        })}
        languageSelected={languageSelected}
      />
      <Explanation
        text={i18n.t("campaign-frequency-explanation", {
          lng: languageSelected,
        })}
      />
      <div className={classes.boxes}>
        {[
          {
            label: i18n.t("campaign-frequency-noLimit", {
              lng: languageSelected,
            }),
            value: "noLimit",
          },
          {
            label: i18n.t("campaign-frequency-oneADay", {
              lng: languageSelected,
            }),
            value: "oneADay",
          },
          {
            label: i18n.t("campaign-frequency-twoADay", {
              lng: languageSelected,
            }),
            value: "twoADay",
          },
        ].map((option, index) => (
          <TextBox
            key={index}
            className={classes.box}
            title={option.label}
            onClick={onChangeFrequency(option.value)}
            isSelected={frequency === option.value}
          />
        ))}
      </div>
    </div>
  );
};

export default withStyles(styles)(Frequency);
