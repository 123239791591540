import React from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography } from "@mui/material";
import FolderIcon from "scenes/CreateTrack/FolderList/FolderIcon";
import Colors from "constants/Colors";

const styles = theme => ({
  main: {
    whiteSpace: "nowrap",
    width: "fit-content%",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    padding: "7px 12px",
    borderRadius: 4,
    marginLeft: 10,
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: Colors.hoverBlue,
    },
  },
  selected: {
    backgroundColor: Colors.pureMain,
    "&:hover": {
      backgroundColor: Colors.pureMain,
      opacity: 0.9,
    },
  },
  labelSpan: {
    display: "inline-block",
    verticalAlign: "top",
    textAlign: "left",
  },
  label: {
    float: "left",
    fontFamily: "Roboto",
    marginLeft: 10,
    marginRight: 2,
    color: "#717780",
  },
  labelSelected: {
    color: "#fff",
    fontWeight: "600",
  },
  settings: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    cursor: "pointer",
    transition: "all 0.2s ease",
    padding: "5px 3px",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: Colors.veryLightGrey,
    },
  },
  folder: {
    height: 15,
    width: 15,
  },
});

const Folder = ({
  classes,
  label,
  isSelected = false,
  onClick = null,
  onClickSettings = null,
  id = 0,
}) => {
  return (
    <div
      id={`folder-${id}`}
      className={`${classes.main} ${isSelected && classes.selected}`}
      onClick={onClick}
    >
      <FolderIcon
        className={classes.folder}
        color={isSelected ? "#fff" : "#CBCBCB"}
      />
      <Typography
        className={`${classes.label} ${isSelected && classes.labelSelected}`}
      >
        <span className={classes.labelSpan}>{label}</span>
      </Typography>
    </div>
  );
};

export default withStyles(styles)(Folder);
