import React, { useMemo } from "react";
import { makeStyles } from "@mui/styles";
import { Typography, TextField } from "@mui/material";
import i18n from "i18n";
import TextBox from "components/TextBox";

const useStyles = makeStyles(theme => ({
  main: {
    marginBottom: 40,
  },
  subTitle: {
    fontFamily: "Montserrat",
    marginTop: 20,
    marginBottom: 5,
    fontSize: 18,
    lineHeight: 1.2143,
    fontWeight: 700,
  },
  containerObjectives: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 24,
  },
  box: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 16,
    paddingBottom: 16,
    width: "fit-content",
    height: "fit-content",
    marginRight: 24,
  },
  textOr: {
    fontFamily: "Montserrat",
    fontSize: 14,
    lineHeight: 1.4,
    fontWeight: 500,
    marginLeft: 16,
    marginRight: 40,
  },
  averageObjectivesDescription: {
    fontFamily: "Montserrat",
    fontSize: 12,
    lineHeight: 1.4,
    fontWeight: 400,
    marginLeft: 16,
    color: "#B0B2BD",
  },
}));

interface Props {
  objectiveSelected: string;
  languageSelected: string;
  onChangeCampaignObjectives: (numberOfChallenge: string) => () => void;
}

const CampaignObjectives = ({
  objectiveSelected,
  languageSelected,
  onChangeCampaignObjectives,
}: Props) => {
  const isObjectivePersonalized = useMemo(() => {
    if (
      objectiveSelected !== "6" &&
      objectiveSelected !== "12" &&
      objectiveSelected !== "18"
    ) {
      return true;
    }

    return false;
  }, [objectiveSelected]);

  const handlePersonalizeChallenge = event => {
    const value = event.target.value;
    onChangeCampaignObjectives(String(value))();
  };

  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Typography className={classes.subTitle}>
        {i18n.t("campaign-objective-title", { lng: languageSelected })}
      </Typography>
      <div className={classes.containerObjectives}>
        <TextBox
          className={classes.box}
          title={i18n.t("objective-6-challenge", { lng: languageSelected })}
          onClick={onChangeCampaignObjectives("6")}
          isSelected={objectiveSelected === "6"}
        />
        <TextBox
          className={classes.box}
          title={i18n.t("objective-12-challenge", { lng: languageSelected })}
          onClick={onChangeCampaignObjectives("12")}
          isSelected={objectiveSelected === "12"}
        />
        <TextBox
          className={classes.box}
          title={i18n.t("objective-18-challenge", { lng: languageSelected })}
          onClick={onChangeCampaignObjectives("18")}
          isSelected={objectiveSelected === "18"}
        />
        <Typography className={classes.textOr}>Ou</Typography>
        <TextField
          type="text"
          label={i18n.t("personalize-objective-challenge", {
            lng: languageSelected,
          })}
          value={isObjectivePersonalized ? objectiveSelected : ""}
          onChange={handlePersonalizeChallenge}
          margin="normal"
          variant="outlined"
        />
      </div>
      <Typography className={classes.averageObjectivesDescription}>
        {i18n.t("average-objectives-description")}
      </Typography>
    </div>
  );
};

export default CampaignObjectives;
