import React, { useState } from "react";
import { Avatar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { MainTitle } from "components/Titles/MainTitle";
import i18n from "i18n";
import NotificationHistoryDescription from "./NotificationHistoryDescription";
import moment from "moment";
import { MainText } from "components/Texts/MainText";
import CheckIcon from "components/CheckIcon";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignSelectedNotificationsHistory } from "services/notification/selectors";
import { PressableMainText } from "components/Texts/PressableMainText";
import FilterList, { IFilter } from "components/FilterList";
import { cancelManagerNotification } from "services/manager/actions";
import { CSVLink } from "react-csv";
import Colors from "constants/Colors";
import StarComponent from "../StarComponent";
import { addNotificationToFavoriteRequest } from "services/notification/actions";
import { getUserClientId } from "services/user/selectors";

const useStyle = makeStyles({
  box: {
    position: "relative",
    border: "solid",
    borderColor: "#DEDEDE",
    borderWidth: 0,
    display: "flex",
    backgroundColor: "#fff",
    borderRadius: 10,
    flexDirection: "column",
    alignItems: "flex-start",
    transition: "all 0.2s cubic-bezier(0.41, 0.094, 0.54, 0.07) 0s",
    marginBottom: 8,
  },
  notificationDescription: {
    position: "relative",
    padding: "20px 25px",
    display: "flex",
    flexDirection: "row",
  },
  avatar: {
    objectFit: "cover",
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: 20,
  },
  containerNotifHistory: {
    position: "fixed",
    zIndex: 10,
    width: 340,
    opacity: 1,
    backgroundColor: "#F8F8F8",
    height: "auto",
    top: 0,
    right: 0,
    bottom: 0,
    boxShadow: "-9px 4px 12px rgba(197, 178, 178, 0.14);",
    transition: "all 0.350s ease",
    display: "flex",
    flexDirection: "column",
    overflow: "scroll",
    paddingLeft: 28,
    paddingRight: 28,
    paddingTop: 60,
    paddingBottom: 30,
  },
  notificationHistoryTitle: {
    marginBottom: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  notificationState: {
    color: "white",
    borderBottomLeftRadius: 10,
    borderTopRightRadius: 10,
    paddingRight: 10,
    paddingLeft: 10,
    paddingTop: 2,
    paddingBottom: 2,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: 9,
  },
  notificationStateIcon: {
    paddingRight: 8,
  },
  notificationStateInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  verticaleSeparation: {
    borderRightWidth: 1,
    borderRightColor: "black",
    borderRightStyle: "solid",
    marginRight: 6,
    marginLeft: 6,
    height: 11,
  },
  link: {
    fontSize: 12,
    marginBottom: -3,
    marginTop: -2,
    bottom: 0,
    color: Colors.pureMain,
    cursor: "pointer",
    width: "fit-content",
    "&:hover": { textDecoration: "underline" },
  },
});

const FILTER_1 = "notification-history-filter-all";
const FILTER_2 = "notification-history-filter-program";
const FILTER_3 = "notification-history-filter-sent";

const filters: IFilter[] = [
  { label: FILTER_1, color: null },
  {
    label: FILTER_2,
    color: null,
  },
  {
    label: FILTER_3,
    color: null,
  },
];

export interface INotificationHistoryProps {
  editNotification: (notification) => void;
}

const NotificationHistory = ({
  editNotification,
}: INotificationHistoryProps) => {
  const [filterSelected, setFilterSelected] = useState<IFilter>(filters[0]);

  const notificationsList = useSelector(
    getCampaignSelectedNotificationsHistory,
  );

  const dispatch = useDispatch();
  const clientId = useSelector(getUserClientId);

  const addToFavorite = (notificationId, isFavorite) => () => {
    dispatch(
      addNotificationToFavoriteRequest({
        notificationId,
        isFavorite: !Boolean(isFavorite),
        clientId,
      }),
    );
  };

  const cancelNotification = notificationId => () => {
    dispatch(cancelManagerNotification({ notificationId }));
  };

  const CSVHeaders = () => {
    return [
      {
        label: i18n.t("notification-history-export-microdoing-expert"),
        key: "coach",
      },
      { label: i18n.t("notification-history-export-title"), key: "title" },
      { label: i18n.t("notification-history-export-text"), key: "text" },
      { label: i18n.t("notification-history-export-file"), key: "file" },
      {
        label: i18n.t("notification-history-export-sending-date"),
        key: "sendingDate",
      },
      {
        label: i18n.t("notification-history-export-receivers"),
        key: "receiver",
      },
    ];
  };

  const formatDataForExport = () => {
    const results = notificationsList.map(notification => {
      const { sender, text, video, data, receiver } = notification;

      const notificationText = text.split(/\r?\n/);
      const notificationTitle = notificationText?.[0]?.replace(/\"/g, "'");
      const notificationDescription = notificationText
        ?.slice(1)
        .join("\n")
        ?.replace(/\"/g, "'");
      const notificationReceivers = receiver
        ?.map(user => user?.firstName + " " + user?.lastName)
        .join(",\n");

      return {
        coach: sender?.firstName || "",
        title: notificationTitle || "",
        text: notificationDescription || "",
        file: video || "",
        sendingDate: moment(data.sendingDate).format("LLL") || "",
        receiver: notificationReceivers || "",
      };
    });
    return results;
  };

  const displayHistory = () => {
    return notificationsList.map((notification, index) => {
      const { picture } = notification.sender;
      const { sendingDate } = notification.data;
      const { isFavorite, clientFavoriteList } = notification;
      const isProgramForLater = moment(new Date()).isBefore(sendingDate);
      const date = moment(new Date(sendingDate)).format("DD MM YYYY HH:mm");
      if (
        isProgramForLater &&
        filterSelected.label !== filters[1].label &&
        filterSelected.label !== filters[0].label
      ) {
        return;
      } else if (
        !isProgramForLater &&
        filterSelected.label !== filters[2].label &&
        filterSelected.label !== filters[0].label
      ) {
        return;
      }

      return (
        <div className={classes.box} key={index}>
          <div style={{ position: "absolute", top: 5, right: 5, zIndex: 999 }}>
            <StarComponent
              isTemplateToolTip={i18n.t("notification-history-remove-favorite")}
              isNotTemplateToolTip={i18n.t("notification-history-add-favorite")}
              emptyStarColor="rgba(176, 178, 189, 1)"
              isTemplate={clientFavoriteList?.[clientId]}
              onClick={addToFavorite(
                notification?._id,
                clientFavoriteList?.[clientId],
              )}
            />
          </div>
          <div className={classes.notificationDescription} key={index}>
            <Avatar src={picture} className={classes.avatar} />
            <NotificationHistoryDescription
              notification={notification}
              isProgramForLater={isProgramForLater}
              date={date}
            />
          </div>
          <div className={classes.notificationStateInfo}>
            <div
              className={classes.notificationState}
              style={{
                backgroundColor: isProgramForLater ? "#C85BE3" : "#57BA55",
              }}
            >
              <CheckIcon
                color="white"
                className={classes.notificationStateIcon}
              />
              <MainText
                text={
                  isProgramForLater
                    ? i18n.t("notification-program")
                    : i18n.t("notification-history-sent")
                }
                isBold
                size="small"
              />
            </div>
            {isProgramForLater && (
              <>
                <PressableMainText
                  text={i18n.t("notification-history-edit")}
                  size="small"
                  onClick={() => editNotification(notification)}
                />
                <div className={classes.verticaleSeparation}></div>
                <PressableMainText
                  text={i18n.t("notification-history-cancel")}
                  size="small"
                  onClick={cancelNotification(notification?._id)}
                />
              </>
            )}
          </div>
        </div>
      );
    });
  };

  const classes = useStyle();

  return (
    <div className={`${classes.containerNotifHistory}`}>
      <div className={classes.notificationHistoryTitle}>
        <MainTitle
          text={i18n.t("notification-history-title")}
          size="extraLarge"
        />
        <CSVLink
          style={{ textDecoration: "none" }}
          filename={`${i18n.t("notification-history-export-filename")}.csv`}
          headers={CSVHeaders()}
          target="_blank"
          data={formatDataForExport()}
        >
          <MainText className={classes.link} text={i18n.t("export-csv")} />
        </CSVLink>
      </div>
      <FilterList
        filters={filters}
        filterSelected={filterSelected}
        selectFilter={setFilterSelected}
      />
      <div style={{ height: "100%", overflow: "scroll" }}>
        {displayHistory()}
      </div>
    </div>
  );
};

export default NotificationHistory;
