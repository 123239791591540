import React from "react";

import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import MoreIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Colors from "../../constants/Colors";
import moment from "moment";

import i18n from "../../i18n";
import EyeClosedIcon from "scenes/Campaigns/CampaignScreen/LearnerSide/EyeClosedIcon";
import EyeOpenIcon from "scenes/Campaigns/CampaignScreen/LearnerSide/EyeOpenIcon";
moment.locale("fr");

const variants = {
  hidden: { opacity: 0, y: 40 },
  visible: { opacity: 1, y: 0 },
};

const styles = theme => ({
  main: {
    flex: "0 0 auto",
    padding: "0px 1.5rem calc(2rem)",
  },
  box: {
    width: 250,
    position: "relative",
    boxShadow: "0 2px 4px rgba(0,0,0,.08)",
    display: "flex",
    height: 350,
    backgroundColor: "#fff",
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    cursor: "pointer",
    animation: "FadeIn 0.3s ease-in-out",
    transition: "all 0.2s cubic-bezier(0.41, 0.094, 0.54, 0.07) 0s",
    "&:hover": {
      transform: "translateY(-10px)",
      boxShadow: "rgba(0, 0, 0, 0.07) 1px 7px 15px",
    },
  },
  bottomDiv: {
    padding: "19px 18px 15px 22px",
    bottom: 0,
    paddingTop: 1,
    paddingBottom: 1,
    width: "100%",
    borderTopWidth: 1,
    borderTop: "solid",
    borderTopColor: Colors.lightGrey,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  participant: {
    fontSize: 14,
  },
  participantAccent: {
    borderRadius: 10,
    padding: "2px 5px",
    marginLeft: "8px",
    backgroundColor: Colors.deepBlue,
    color: "white",
    fontWeight: "500",
  },
  pictureDiv: {
    width: "100%",
    position: "relative",
    height: 100,
    borderRadius: 8,
  },
  picture: {
    position: "absolute",
    top: 0,
    bottom: 0,
    margin: "auto",
    height: "100%",
    maxHeight: 120,
    maxWidth: "100%",
  },
  topDiv: {
    width: "100%",
    padding: "19px 18px 15px 22px",
    flex: 1,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    overflow: "hidden",
  },
  textDiv: {
    flex: 1,
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    flexDirection: "column",
    overflow: "hidden",
  },
  title: {
    color: "#0069ff",
    "margin-bottom": 5,
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    fontSize: 26,
    fontWeight: 700,
  },
  description: {
    marginBottom: 5,
    color: "#333",
    fontSize: 16,
    width: "100%",
    maxHeight: "100%",
    overflow: "scroll",
  },
  accent: {
    fontWeight: "500",
    color: "#333",
  },
  info: {
    color: "#676767",
    fontSize: 13,
    lineHeight: "1.3rem",
  },
  label: {
    color: "#fff",
    fontSize: 12,
    fontWeight: 500,
  },
  labelDiv: {
    position: "absolute",
    zIndex: 20,
    top: -5,
    left: -5,
    borderRadius: 6,
    backgroundColor: Colors.pink,
    padding: "3px 10px",
  },
  delete: {
    color: Colors.red,
  },
  selected: {
    border: "solid",
    borderColor: Colors.main,
    borderWidth: 2,
  },
  hideButton: {},
  moreButton: {
    alignSelf: "flex-end",
  },
  "@media (max-width: 1024px)": {
    main: { width: "50%" },
  },
  containerCard: {
    height: "100%",
    flex: 1,
    flexDirection: "column",
    display: "flex",
    width: "100%",
  },
});

const ClientBox = ({
  classes,
  onClick,
  onClickMore,
  onClickDelete,
  onClickModify,
  onClickHide = () => null,
  isSelected,
  name,
  clientCreatorDescription,
  logo,
  employees = [],
  createdAt,
  label,
  noSettings,
  isHidden = false,
  _id,
  clientCreatorId = null,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleDelete = event => {
    event.stopPropagation();
    onClickDelete();
    setAnchorEl(null);
  };

  const handleModify = event => {
    event.stopPropagation();
    onClickModify();
    setAnchorEl(null);
  };

  const handleHideClient = event => {
    event.stopPropagation();
    onClickHide(!isHidden);
    setAnchorEl(null);
  };

  return (
    <div
      initial="hidden"
      animate="visible"
      transition={{ duration: 0.5 }}
      variants={variants}
      className={classes.main}
    >
      <div
        onClick={onClick}
        className={`${classes.box} ${isSelected && classes.selected}`}
        data-intercom-target="First Client"
      >
        {label && (
          <div className={classes.labelDiv} style={{ backgroundColor: "red" }}>
            <Typography className={classes.label}>{i18n.t(label)}</Typography>
          </div>
        )}
        {!label && clientCreatorId && clientCreatorId.name && (
          <div
            className={classes.labelDiv}
            style={{ backgroundColor: Colors.darkBlue }}
          >
            <Typography className={classes.label}>
              {clientCreatorId.name}
            </Typography>
          </div>
        )}
        <div className={classes.containerCard}>
          <div className={classes.topDiv}>
            <div className={classes.pictureDiv}>
              <img alt="" src={logo} className={classes.picture} />
            </div>
            <div className={classes.textDiv}>
              <Typography className={classes.title}>{name}</Typography>
              <Typography className={classes.description}>
                {clientCreatorDescription}
              </Typography>
            </div>
            <Typography
              className={classes.participant}
              style={{ marginTop: 5 }}
            >
              {i18n.t("users")}
              <span className={classes.participantAccent}>
                {employees?.length}
              </span>
            </Typography>
          </div>
          <div className={classes.bottomDiv}>
            <Typography className={classes.info}>
              {i18n.t("client-created")}
              <span className={classes.accent}>
                {moment(createdAt).format("L")}
              </span>
            </Typography>
            <IconButton
              data-tip={
                isHidden
                  ? i18n.t("show-client-toolTip")
                  : i18n.t("hide-client-toolTip")
              }
              className={classes.hideButton}
              onClick={handleHideClient}
              disabled={noSettings}
              size="large"
            >
              {!isHidden ? <EyeClosedIcon /> : <EyeOpenIcon />}
            </IconButton>
            <IconButton
              className={classes.moreButton}
              onClick={handleClick}
              disabled={noSettings}
              size="large"
            >
              <MoreIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleModify}>{i18n.t("modify")}</MenuItem>
              <MenuItem className={classes.delete} onClick={handleDelete}>
                {i18n.t("delete")}
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(ClientBox);
