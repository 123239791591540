import React, { useState } from "react";
import withStyles from "@mui/styles/withStyles";
import FillPremiumIcon from "./FillPremiumIcon";
import EmptyPremiumIcon from "./EmptyPremiumIcon";
import IconButton from "@mui/material/IconButton";
import i18n from "../../i18n";
import Colors from "constants/Colors";
import CustomArrowTooltip from "components/CustomArrowTooltip";

const styles = theme => ({
  main: {
    marginRight: -12,
    width: 44,
    height: 44,
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: Colors.lightGrey,
    },
  },
});

const PremiumComponent = ({
  classes,
  settingsDisabled,
  onClick,
  isPremiumDemo = false,
}) => {
  const [isHovered, setHover] = useState(false);

  return (
    <CustomArrowTooltip
      title={
        isPremiumDemo
          ? i18n.t("premium-demo-toolTip-off")
          : i18n.t("premium-demo-toolTip-on")
      }
    >
      <IconButton
        className={classes.main}
        disabled={settingsDisabled}
        onClick={onClick}
        onMouseLeave={() => setHover(false)}
        onMouseEnter={() => setHover(true)}
        size="large"
      >
        {isPremiumDemo || isHovered ? (
          <FillPremiumIcon />
        ) : (
          <EmptyPremiumIcon />
        )}
      </IconButton>
    </CustomArrowTooltip>
  );
};

export default withStyles(styles)(PremiumComponent);
