import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchApi } from "services/api";
import { resetTeamsRequest } from "services/campaign/actions";
import {
  getCampaignRequesting,
  getSelectedCampaignId,
} from "services/campaign/selectors";
import { getIsLocatedOnTrack } from "services/createTrack/selectors";

const GET_SURVEY_API_ENDPOINT = "/v1/surveys"; // :surveyId

export const useChangeTeams = () => {
  const campaignId = useSelector(getSelectedCampaignId);
  const requesting = useSelector(getCampaignRequesting);
  const isLocatedOnTrack = useSelector(getIsLocatedOnTrack);

  const dispatch = useDispatch();

  const sendRequest = async (teamSize: number) => {
    dispatch(
      resetTeamsRequest({
        campaignId,
        teamSize,
      }),
    );
  };

  return [requesting, sendRequest, isLocatedOnTrack] as const;
};

export const useGetSelectedSurveyName = () => {
  const [surveyName, setSurveyName] = useState("");

  const sendRequest = async (surveyId: string) => {
    const result = await fetchApi(GET_SURVEY_API_ENDPOINT, {}, "get");
    setSurveyName(result);
  };

  return [surveyName, sendRequest] as const;
};
