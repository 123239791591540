import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Colors from "../../../constants/Colors";

import { connect } from "react-redux";
import { sendEmailInvitationsRequest } from "../../../services/auth/actions";

import moment from "moment";
import { Typography } from "@mui/material";
import FlatButton from "../../../components/FlatButton";
import i18n from "../../../i18n";
import {
  getUserId,
  getSelectedClientId,
} from "../../../services/user/selectors";

import DoneLottie from "../DoneLottie";
import { getAppLanguage } from "../../../services/general/selectors";
import { getSelectedClient } from "../../../services/myClients/selectors";
import MagicCodeBox from "scenes/Campaigns/AddUsersToCampaignModal/MagicCodeBox";
import MyTabs from "components/MyTabs";
import AddNewLearnersTab from "scenes/Campaigns/AddUsersToCampaignModal/AddNewLearnersTab";
import AddExistingLearnersTab from "scenes/Campaigns/AddUsersToCampaignModal/AddExistingLearnersTab";
import selectLanguage from "utils/selectLanguage";
import { getMyUsersListRequest } from "services/myUsers/actions";
import { getIsCoachingAccount } from "services/client/selectors";
import { getUserSelectedFirstName } from "services/manager/selectors";
import CoachInvitation from "scenes/Campaigns/AddUsersToCampaignModal/CoachInvitation";
import { getCampaignSelected } from "services/campaign/selectors";

moment.locale("fr");

const styles = theme => ({
  paper: {
    alignItems: "center",
    display: "flex",
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: "center",
    lineHeight: 1.46666667,
    overflow: "scroll",
    overflowY: "auto",
    margin: 0,
    borderRadius: "0px",
    minHeight: "100%",
    minWidth: "100%",
    maxWidth: "100%",
  },
  content: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    overflow: "scroll",
  },
  topBar: {
    position: "relative",
    width: "100%",
    height: 75,
    minHeight: 75,
    background: Colors.white,
    boxShadow: "0px 2px 15px rgba(169, 169, 169, 0.25)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 25,
  },
  barBody: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    width: 960,
  },
  topBarTitle: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 24,
    lineHeight: "27px",
    color: Colors.text,
  },
  title: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 22,
    color: Colors.darkBlue,
  },
  buttonDiv: {
    flex: 1,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  topDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    paddingTop: 0,
  },
  subtitle: {
    marginTop: 10,
    color: "#6E757E",
    fontSize: 16,
  },
  line: {
    background: "#D8D8D8",
    height: 1,
    width: "100%",
    marginTop: 35,
    marginBottom: 25,
  },
  box: {
    marginTop: 10,
    marginBottom: 25,
  },
});

class AddUsersToCampaignModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabSelected: 0,
    };
    this.baseState = this.state;
  }

  componentDidMount = () => {
    const { clientId } = this.props;
    this.props.getMyUsersListRequest({ clientId, limit: 500 });
  };

  _handleKeyDown = event => {
    if (
      (window.navigator.platform.match("Mac")
        ? event.metaKey
        : event.ctrlKey) &&
      event.keyCode === 83
    ) {
      event.preventDefault();
    }
  };

  selectTab = tabSelected => {
    this.setState({ tabSelected });
  };

  formatDate = date => {
    return moment(date).format("L");
  };

  onRendered = () => {
    this.setState(this.baseState);
  };

  handleClose = onClose => () => {
    onClose();
  };

  onChangeState = key => event => {
    const value = event.target.value;
    const newState = { ...this.state[key] };
    newState[this.props.language] = value;

    this.setState({ [key]: newState });
  };

  onClickSubmit = () => {
    const { clientId, userId, firstName, lastName } = this.props;
    const { emails } = this.state;

    this.props.sendEmailInvitationsRequest({
      clientId,
      userId,
      firstName,
      lastName,
      emails,
    });
  };

  onChangeEmail = fieldNumber => event => {
    const value = event.target.value;
    const emails = [...this.state.emails];
    emails[fieldNumber].email = value;
    this.setState({ emails });
  };

  onClickDelete = fieldNumber => () => {
    const emails = this.state.emails.filter((el, i) => i !== fieldNumber);
    this.setState({ emails });
  };

  addField = () => {
    const emails = [...this.state.emails, { email: "", role: "user" }];
    this.setState({ emails });
  };

  render() {
    const {
      classes,
      isVisible,
      onClose,
      accessCode,
      clientName,
      language,
      hasPublishCampaignAnimation = false,
      requesting = false,
      campaignTitle,
      autoRegister,
      isCoachingAccount,
      userSelectedFirstName,
      dynamicLink,
    } = this.props;

    const { tabSelected } = this.state;
    return (
      <Dialog
        open={isVisible}
        ref={this.onRendered}
        onClose={this.handleClose(onClose)}
        classes={{ paper: classes.paper }}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        <div className={classes.topBar}>
          <div className={classes.barBody}>
            <Typography className={classes.topBarTitle}>
              {isCoachingAccount
                ? i18n.t("invite-x", { firstName: userSelectedFirstName })
                : i18n.t("add-participants")}
            </Typography>
            <div className={classes.buttonDiv}>
              <FlatButton
                requesting={requesting}
                disabled={false}
                variant="contained"
                color="primary"
                onClick={this.handleClose(onClose)}
                title={i18n.t("end-button")}
              />
            </div>
          </div>
        </div>
        <DialogContent className={classes.content}>
          <div className={classes.barBody}>
            <div className={classes.topDiv}>
              {hasPublishCampaignAnimation && (
                <>
                  <DoneLottie width={"10%"} height={"10%"} />
                  <Typography className={classes.title}>
                    {i18n.t("campaign-publish-success")}
                  </Typography>
                  <Typography className={classes.subtitle}>
                    {i18n.t("campaign-title-published", {
                      campaignTitle: selectLanguage({
                        text: campaignTitle,
                        language,
                      }),
                    })}
                  </Typography>
                  <div className={classes.line} />
                </>
              )}

              {isCoachingAccount && <CoachInvitation />}
              {!isCoachingAccount && (
                <>
                  <MagicCodeBox
                    accessCode={accessCode}
                    className={classes.box}
                  />
                  <MyTabs
                    tabSelected={this.state.tabSelected}
                    selectTab={this.selectTab}
                    tabs={
                      autoRegister && hasPublishCampaignAnimation
                        ? [i18n.t("invite-new-participants")]
                        : [
                            i18n.t("invite-new-participants"),
                            i18n.t("add-existing-participants"),
                          ]
                    }
                  />
                  {tabSelected === 0 && (
                    <AddNewLearnersTab
                      dynamicLink={dynamicLink}
                      accessCode={accessCode}
                      clientName={clientName}
                    />
                  )}
                  {tabSelected === 1 && <AddExistingLearnersTab />}
                </>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  const { requesting } = state.auth;
  const language = getAppLanguage(state);
  const userId = getUserId(state);
  const clientId = getSelectedClientId(state);
  const selectedClient = getSelectedClient(state);
  const { firstName, lastName, email } = state.user;
  const { uploading, picture } = state.upload;
  const { isMobileConnected } = state.createTrack;
  const clientName = selectedClient.name;
  const { hasPublishCampaignAnimation = false } = state.campaign;

  const campaignSelected = getCampaignSelected(state);
  const { accessCode, title, autoRegister, dynamicLink } = campaignSelected;
  const campaignTitle = title;

  return {
    isCoachingAccount: getIsCoachingAccount(state),
    userSelectedFirstName: getUserSelectedFirstName(state),
    clientName,
    language,
    clientId,
    userId,
    firstName,
    lastName,
    email,
    requesting,
    hasPublishCampaignAnimation,
    pictureUploading: uploading,
    isMobileConnected,
    campaignTitle,
    accessCode,
    dynamicLink,
    picture,
    autoRegister,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    sendEmailInvitationsRequest,
    getMyUsersListRequest,
  })(AddUsersToCampaignModal),
);
