import React from "react";

const MoreIcon = ({ sizePercentage, color }) => (
  <svg
    width={sizePercentage * 15}
    height={sizePercentage * 14}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 15 14"
    version="1.1"
  >
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.10581 13.6954H8.78395V8.33913H14.1402V5.66098H8.78395V0.304688H6.10581V5.66098H0.749512V8.33913H6.10581V13.6954Z"
        fill={color}
      />
    </svg>
  </svg>
);

MoreIcon.defaultProps = {
  sizePercentage: 1,
  color: "#fff",
};

export default MoreIcon;
