import React from "react";
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  main: {},
  bigBox: {
    paddingBottom: 35,
    marginTop: 20,
    // flex: "1 0 22%",
    overflow: "hidden",
    position: "relative",
    borderColor: "#DEDEDE",
    width: "100%",
    display: "flex",
    // height: 350,
    backgroundColor: "#fff",
    borderRadius: 10,
    alignItems: "center",
    flexDirection: "column",
    animation: "FadeIn 0.3s ease-in-out",
    transition: "all 0.2s cubic-bezier(0.41, 0.094, 0.54, 0.07) 0s",
    "&:hover": {
      // boxShadow: "rgba(0, 0, 0, 0.07) 1px 7px 15px",
    },
  },
});

const Box = ({ classes, className = "", children, intercomTarget = null }) => {
  return (
    <div
      data-intercom-target={intercomTarget}
      className={`${classes.bigBox} ${className}`}
    >
      {children}
    </div>
  );
};

export default withStyles(styles)(Box);
