import React, { PureComponent } from "react";
import { connect } from "react-redux";

import moment from "moment";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Colors from "../../constants/Colors";
import "moment/locale/fr";
import i18n from "../../i18n";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import {
  getSelectedClientId,
  getUserClientId,
} from "../../services/user/selectors";
import { changeField, getClientRequest } from "../../services/client/actions";
import { getAppLanguage } from "../../services/general/selectors";
import { changeGeneralSettings } from "../../services/general/actions";
import { Button } from "@mui/material";

import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  Configure,
  Index,
} from "react-instantsearch-dom";
import MyUserHits from "./MyUsersHits";
import { getSelectedClient } from "../../services/myClients/selectors";
import { getSelectedClientRequest } from "../../services/myClients/actions";

const searchClient = algoliasearch(
  "MTMFJ78PRC",
  "a6400a80c85f1dcceaaddde616c096a3",
);

moment.locale("fr");

const styles = theme => ({
  main: {
    marginLeft: "3%",
    width: "95%",
    overflow: "auto",
    height: "100%",
    alignSelf: "flex-start",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 1,
  },
  title: {
    color: "black",
    backgroundColor: "white",
    border: "1px solid rgba(0,0,0,.09)",
    boxShadow: "0 2px 0 0 rgba(0,0,0,.03)",
    borderRadius: 5,
    padding: 10,
    fontWeight: 500,
    marginBottom: 20,
  },
  firstSection: {
    marginTop: 0,
    margin: "12px 0 0",
    padding: "35px 30px 0",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    overflow: "auto",
    justifyContent: "space-around",
    alignItems: "flex-start",
    marginBottom: 15,
  },
  evolutionTitle: {
    width: "100%",
    color: Colors.black,
    textAlign: "left",
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.5,
    marginBottom: 16,
  },
  whiteBackground: {
    backgroundColor: Colors.white,
  },
  rankingSection: {
    paddingTop: 10,
  },
  firstTitle: {
    alignSelf: "center",
    marginTop: 15,
    marginBottom: 15,
  },
  userRow: {
    border: "1px solid rgba(0,0,0,.09)",
    width: "100%",
    backgroundColor: "#fff",
    marginBottom: 10,
    borderRadius: 8,
    padding: 15,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  userName: {
    marginLeft: 15,
  },
  subTitle: {
    fontWeight: 500,
    marginLeft: 10,
  },
  stat: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: 1,
  },
  progressSection: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  statDiv: {
    width: "fit-content",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    border: "1px solid rgba(0,0,0,.09)",
    borderRadius: 5,
    padding: "12px 15px",
    fontWeight: 500,
    marginBottom: 20,
  },
  biggerRow: {
    display: "flex",
    flex: 1.5,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  firstRow: {
    display: "flex",
    flex: 1.5,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  row: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  tableHeaderRow: {
    width: "100%",
    marginBottom: 10,
    borderRadius: 8,
    padding: 10,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  tableHeader: {
    fontSize: 15,
    fontWeight: 500,
    color: "black",
  },
  avatar: {
    width: 30,
    height: 30,
    borderRadius: 15,
  },
  date: {
    marginRight: 10,
    overflowWrap: "break-word",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    "text-overflow": "ellipsis",
  },
  searchBox: {
    marginTop: 5,
    marginBottom: 15,
  },
  topDiv: {
    width: "100%",
    position: "relative",
  },
  inviteButton: {
    top: 10,
    position: "absolute",
    right: 0,
    border: "1px solid rgba(0, 0, 0, 0.23)",
    color: "#19212B",
  },
  buttonRoot: {
    textTransform: "none",
  },
});

class MyUsers extends PureComponent {
  constructor() {
    super();

    this.state = {
      isInviteUsersModalVisible: false,
      refresh: false,
      page: 0,
      limit: 20,
      isDeleteConfirmModalVisible: false,
    };

    this.SearchBox = React.createRef();
  }

  componentDidMount = () => {
    const { clientId, userClientId } = this.props;

    if (clientId === userClientId) {
      this.props.getClientRequest({ clientId });
    } else {
      this.props.getSelectedClientRequest({ clientId });
    }
    this.refreshAlgoliaSearch();
  };

  onClickDelete = campaign => () => {
    this.setState({
      isDeleteConfirmModalVisible: true,
      campaignSelected: campaign,
    });
  };

  onConfirmDelete = event => {
    event.stopPropagation();
    this.props.deleteCampaignRequest({
      campaignId: this.state.campaignSelected._id,
    });
    this.setState({ isDeleteConfirmModalVisible: false });
  };

  onCloseCreationModal = () => {
    this.setState({ isCreationModalVisible: false });
  };

  displayCreationModal = () => {
    this.setState({ isCreationModalVisible: true });
  };

  isRoleInputDisabled = () => {
    const { role } = this.props;

    return role !== "owner" && role !== "admin";
  };

  componentDidUpdate = (prevProps, prevState) => {
    // for clearing algolia cache after updating an user
    if (
      prevProps.requesting !== this.props.requesting &&
      !this.props.requesting
    ) {
      this.refreshAlgoliaSearch();
    }
  };

  refreshAlgoliaSearch = () => {
    this.setState({ refresh: true }, () => {
      this.setState({ refresh: false });
    });
  };

  onClickInviteUsers = () => {
    this.props.changeGeneralSettings({
      isInviteUsersModalVisible: true,
      accessCode: null,
    });
  };

  render() {
    const { classes, employees } = this.props;
    return (
      <div className={classes.main}>
        <DeleteConfirmModal
          title={i18n.t("delete-campaign-confirm-title")}
          onClickConfirm={this.onConfirmDelete}
          isVisible={this.state.isDeleteConfirmModalVisible}
          onClose={() => this.setState({ isDeleteConfirmModalVisible: false })}
        />
        <div className={classes.firstSection}>
          <div className={classes.topDiv}>
            <Button
              onClick={this.onClickInviteUsers}
              className={classes.inviteButton}
              classes={{ root: classes.buttonRoot }}
              variant="outlined"
              sx={{
                ":hover": {
                  bgcolor: "rgba(25, 33, 43, 0.04)",
                  border: "1px solid rgba(0, 0, 0, 0.23)",
                },
              }}
            >
              {i18n.t("invite-users")}
            </Button>
            <div className={classes.statDiv} variant="h6">
              <Typography className={classes.stat}>
                {employees.length}
              </Typography>
              <Typography className={classes.subTitle}>
                {employees.length === 1 ? i18n.t("user") : i18n.t("users")}
              </Typography>
            </div>
          </div>
          <InstantSearch
            searchClient={searchClient}
            indexName={this.props.clientId}
            refresh={this.state.refresh}
          >
            <div className={classes.topSide}>
              <div className={classes.searchBox}>
                <SearchBox
                  ref={this.SearchBox}
                  translations={{
                    placeholder: i18n.t("search-user-placeholder"),
                  }}
                />
              </div>
            </div>
            <div className={classes.tableHeaderRow}>
              <div className={classes.firstRow}>
                <Typography className={classes.tableHeader}>
                  {i18n.t("table-header-name")}
                </Typography>
              </div>
              <div className={classes.biggerRow}>
                <Typography className={classes.tableHeader}>
                  {i18n.t("table-header-email")}
                </Typography>
              </div>
              <div className={classes.row}>
                <Typography className={classes.tableHeader}>
                  {i18n.t("table-header-role")}
                </Typography>
              </div>
              <div className={classes.row}>
                <Typography className={classes.tableHeader}>
                  {i18n.t("table-header-last-registered")}
                </Typography>
              </div>
            </div>
            <div className={classes.bottomSideWrapper}>
              <div className={classes.bottomSide}>
                <Index
                  className={classes.index}
                  indexName={this.props.clientId}
                >
                  <Configure filters="type:user" />
                  <MyUserHits />
                </Index>
              </div>
            </div>
          </InstantSearch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { role } = state.user;
  const { requesting } = state.myUsers;
  const selectedClient = getSelectedClient(state);
  const language = getAppLanguage(state);
  const clientId = getSelectedClientId(state);
  const { employees } = selectedClient;
  const userClientId = getUserClientId(state);

  return {
    employees,
    requesting,
    role,
    language,
    clientId,
    client: selectedClient,
    userClientId,
  };
};

export default connect(mapStateToProps, {
  changeField,
  getClientRequest,
  changeGeneralSettings,
  getSelectedClientRequest,
})(withStyles(styles)(MyUsers));
