import React, { useEffect, useRef } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useReactMediaRecorder } from "react-media-recorder";
import { useState } from "react";
import Colors from "constants/Colors";
import RecordAskPermission from "./RecordAskPermission";
import RecordBottomBar from "./RecordBottomBar";
import RecordPreview from "./RecordPreview";
import CancelButton from "./CancelButton";
import { useDispatch, useSelector } from "react-redux";
import { updateFilesToUpload } from "services/upload/actions";
import {
  getFileToUpload,
  getFileUploadProgress,
} from "services/upload/selectors";
import { Loader } from "components";
import { MediaRecorder } from "extendable-media-recorder";

const useStyles = makeStyles(theme => ({
  main: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  video: {
    width: "100%",
    height: "auto",
    position: "relative",
    borderRadius: 4,
  },
  videoPreview: {
    cursor: "pointer",
    transition: "all 0.2s ease",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    overflow: "hidden",
    // width: "100%",
    width: "100%",
    minHeight: 200,
    backgroundColor: "#f8f9fa",
    height: "auto",
    position: "relative",
    borderRadius: 4,
  },
  block: {
    padding: "1.5rem 0.5rem",
    borderRadius: "0.25rem",
    border: "1px solid #e6e9ef",
    background: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    cursor: "pointer",
    transition: "all 0.2s ease",
    width: 150,
    "&:hover": { backgroundColor: Colors.hoverBlue },
  },
  stopRecord: {
    height: "2.5rem",
    minWidth: "2.5rem",
    width: "auto",
    padding: "0.5rem",
    marginRight: "1rem",
    borderRadius: "0.25rem",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.04)",
    outline: "0",
    border: "1px solid #e6e9ef",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    transition: "all .05s ease-in-out",
    cursor: "pointer",
    "&:hover": { backgroundColor: Colors.hoverBlue },
  },
  smallText: {
    color: Colors.text,
    textAlign: "center",
  },
  bottomDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  stopIcon: {
    borderColor: "red",
    border: "solid",
    width: 20,
    height: 20,
    borderWidth: 1,
    borderRadius: "50%",
    marginRight: 5,
  },
  preview: {
    backgroundColor: "#f8f9fa",
  },
  uploadProgress: {
    position: "absolute",
    left: 0,
    bottom: 0,
    height: 3,
    borderRadius: 8,
    width: 5,
    backgroundColor: Colors.pureMain,
  },
}));

const EVENT_NAME = "dataavailable";

const requestRecorder = async () => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    return new MediaRecorder(stream, { mimeType: "audio/wav" });
  } catch (error) {
    console.log(error);
  }
};

interface Props {
  recordType: "camera" | "audio" | "screen";
  onClick?: () => void;
  onSubmit: () => void;
  onClearRecording?: () => void;
  requesting?: boolean;
}

// TODO : fix Error: "There is already an encoder stored which handles exactly the same mime types.""
// TODO - change hook "connect" in the react-media-recorder  https://github.com/chrisguttandin/extendable-media-recorder/issues/637

const RecordMediaBlock = ({
  recordType = "camera",
  onClick,
  onClearRecording = () => null,
  onSubmit,
  requesting = false,
}: Props) => {
  const classes = useStyles();
  const [video, setVideo] = useState(null);
  const [audio, setAudio] = useState<any>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isRecordingVisible, setIsRecordingVisible] = useState(false);
  const [mediaStatus, setMediaStatus] = useState("");
  const [recorder, setRecorder] = useState<any>(null);

  const fileUploadProgress = useSelector(getFileUploadProgress);
  const fileToUpload = useSelector(getFileToUpload);

  const dispatch = useDispatch();

  const onStop = (blobUrl, blob) => {
    setIsPlaying(false);
    if (recordType === "audio") {
      setAudio(blobUrl);
    } else {
      setVideo(blobUrl);
    }
    dispatch(
      updateFilesToUpload({
        file: blobUrl,
        fileUrl: blobUrl,
        fileName: recordType === "audio" ? "recording.wav" : "recording.mp4",
        fileType: recordType === "audio" ? "audio/wav" : "video/mp4",
      }),
    );
  };

  const {
    status,
    startRecording,
    pauseRecording,
    stopRecording,
    resumeRecording,
    clearBlobUrl,
    previewStream,
  } = useReactMediaRecorder({
    video: recordType === "camera",
    screen: recordType === "screen",
    audio: true,
    // blobPropertyBag: {
    //   type: recordType === "audio" ? "audio/aac" : "video/mp4",
    // },
    onStop,
  });

  const videoRef = useRef<HTMLVideoElement>(null);

  const reset: any = async () => {
    stopRecording();
    clearBlobUrl();

    recorder?.stop();

    await navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      stream.getTracks().forEach(track => {
        track.stop();
        stream.removeTrack(track);
      });

      stream.getAudioTracks().forEach(function (track) {
        track.stop();
      });

      stream.getVideoTracks().forEach(function (track) {
        track.stop();
      });
    });

    await navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(stream => {
        stream.getTracks().forEach(track => {
          track.stop();
          stream.removeTrack(track);
        });

        stream.getAudioTracks().forEach(function (track) {
          track.stop();
        });

        stream.getVideoTracks().forEach(function (track) {
          track.stop();
        });
      });

    recorder?.removeEventListener(EVENT_NAME, handleData);
    setIsPlaying(false);
    setMediaStatus("");
    setVideo(null);
    setAudio(null);
    setRecorder(null);
  };

  useEffect(() => {
    // to stream preview update ref
    if (videoRef.current && previewStream) {
      videoRef.current.srcObject = previewStream;
    }
  }, [previewStream]);

  useEffect(() => {
    // reset when clicked

    return () => reset();
  }, [isRecordingVisible]);

  useEffect(() => {
    setMediaStatus(status);
    if (recordType !== "audio") {
      if (status === "recording" && mediaStatus === "idle") {
        if (videoRef.current) {
          videoRef.current.currentTime = 0;
        }

        videoRef?.current?.load();

        // We want to wait before starting the record for showing a preview - user resume manually
        if (recordType === "camera") {
          pauseRecording();
        }
      }
    }
  }, [status]);

  useEffect(() => {
    if (!recorder && recordType === "audio") {
      requestRecorder().then((media: any) => setRecorder(media));
    }
  }, []);

  const handleStartRecording = async () => {
    if (recordType !== "audio") {
      startRecording();
    }
    setIsPlaying(true);
  };

  const onChangeRecording = () => {
    if (mediaStatus === "recording") {
      stopRecording();
      setIsPlaying(false);
    } else if (mediaStatus === "paused") {
      setIsPlaying(true);
      resumeRecording();
    } else if (mediaStatus === "stopped") {
      handleCloseRecording();
    } else {
      setMediaStatus("recording");
      setIsPlaying(true);
    }
  };

  const handleCloseRecording = () => {
    setIsRecordingVisible(false);
    clearBlobUrl();
    reset();
    onClearRecording();
  };

  useEffect(() => {
    // reset when file finished to upload
    if (!fileToUpload.file && (previewStream || audio)) {
      handleCloseRecording();
    }
  }, [fileToUpload.file, previewStream, handleCloseRecording]);

  const handleData = e => {
    const file = new Blob([e.data], { type: "audio/wav" });
    const url = URL.createObjectURL(file);
    console.log("url: ", url);
    onStop(url, null);
  };

  useEffect(() => {
    if (!recorder) return;

    if (isPlaying && recordType === "audio") {
      recorder.start();
      setMediaStatus("recording");
      setIsPlaying(true);
    } else if (!isPlaying && recordType === "audio") {
      recorder.stop();
      setMediaStatus("stopped");
      setIsPlaying(false);
    }

    recorder.addEventListener(EVENT_NAME, handleData);
    return () => recorder.removeEventListener(EVENT_NAME, handleData);
  }, [recorder, isPlaying]);

  return (
    <div className={`${classes.main}`}>
      <div className={classes.videoPreview}>
        <CancelButton onClick={handleCloseRecording} />
        {!isPlaying && !previewStream && !audio && (
          <RecordAskPermission
            onClick={handleStartRecording}
            recordType={recordType}
          />
        )}
        {(mediaStatus === "idle" ||
          mediaStatus === "acquiring_media" ||
          mediaStatus === "paused") &&
          !previewStream &&
          !recorder &&
          isPlaying && <Loader size={30} backgroundWhite />}
        {(!!previewStream || !!audio) && (
          <RecordPreview
            previewStream={previewStream}
            video={video}
            videoRef={videoRef}
            audioRecord={audio}
          />
        )}
        {(!!previewStream ||
          !!audio ||
          (recordType === "audio" && isPlaying)) && (
          <RecordBottomBar
            requesting={requesting}
            mediaStatus={mediaStatus}
            onClick={onChangeRecording}
            onClickSubmit={onSubmit}
          />
        )}
        <div
          className={classes.uploadProgress}
          style={{ width: `${fileUploadProgress}%` }}
        />
      </div>
    </div>
  );
};

export default RecordMediaBlock;
