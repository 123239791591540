import React from "react";
import { TextField, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import i18n from "i18n";

const useStyles = makeStyles(theme => ({
  main: {
    paddingBottom: 50,
  },
  textField: {
    marginBottom: 0,
    marginTop: 10,
  },
  title: {
    fontSize: 22,
    color: "black",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    marginBottom: 25,
    width: 250,
  },
  subtitle: {
    fontFamily: "DM Sans",
    color: "#454545",
    width: 250,
  },
  intro: {
    marginTop: 40,
    color: "#202020",
  },
}));

interface Props {
  firstName: string;
  lastName: string;
  handleFirstNameChange: (e: any) => void;
  handleLastNameChange: (e: any) => void;
  title?: string;
}

const InfosSide = ({
  firstName,
  lastName,
  handleFirstNameChange,
  handleLastNameChange,
  title = i18n.t("new-coaching-title"),
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.subtitle}>
        {i18n.t("new-coaching-subtitle")}
      </Typography>
      <Typography className={classes.intro}>
        {i18n.t("coaching-information")}
      </Typography>
      <TextField
        autoFocus={true}
        className={classes.textField}
        fullWidth
        placeholder={i18n.t("firstName")}
        value={firstName}
        onChange={handleFirstNameChange}
        margin="normal"
        variant="outlined"
      />
      <TextField
        className={classes.textField}
        fullWidth
        placeholder={i18n.t("lastName")}
        value={lastName}
        onChange={handleLastNameChange}
        margin="normal"
        variant="outlined"
      />
    </div>
  );
};

export default InfosSide;
