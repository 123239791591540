import React from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography } from "@mui/material";
import Colors from "../../../constants/Colors";
import CloseIcon from "./CloseIcon";

const styles = (theme): any => ({
  main: {
    width: "fit-content",
    backgroundColor: Colors.whiteBlue,
    borderRadius: 4,
    lineHeight: 20,
    height: 20,
    margin: "0px 6px 6px 0px",
    padding: "0px 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  label: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 1 /* number of lines to show */,
    lineHeight: "1.5em" /* fallback */,
    maxHeight: "1.5em",
    wordBreak: "break-all",
    fontWeight: "500",
    fontSize: 12,
    color: Colors.darkBlue,
    flex: 1,
  },
  removeIcon: {
    userSelect: "none",
    transition: "background 120ms ease-in 0s",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 0,
    flexShrink: 0,
    marginLeft: 2,
    marginRight: -7,
    width: 18,
    height: 18,
    color: "#fff",
  },
  smallLabel: {
    // fontSize: 10
  },
  smallMain: {
    marginTop: 4,
    marginBottom: 0,
    // padding: "0px 6px",
    // margin: "2px 4px",
    // marginLeft: 0,
    // height: 16
  },
  noMargin: {
    margin: 0,
  },
});

interface ObjectiveParams {
  classes?: any;
  onClick?: () => () => any;
  onClickRemove?: () => any;
  color?: string;
  isSmall?: boolean;
  className?: any;
  label: string;
  hasRemove?: boolean;
  noMargin?: boolean;
}

const Objective = ({
  classes,
  color = Colors.whiteBlue,
  label,
  hasRemove = false,
  onClickRemove = () => null,
  onClick = () => () => null,
  isSmall,
  className,
  noMargin = false,
}: ObjectiveParams) => {
  return (
    <div
      className={`${classes.main} ${isSmall && classes.smallMain} ${
        noMargin && classes.noMargin
      } ${className}`}
      style={{ backgroundColor: color }}
      onClick={onClick}
    >
      <Typography
        className={`${classes.label} ${isSmall && classes.smallLabel}`}
      >
        {label}
      </Typography>
      {hasRemove && (
        <div className={classes.removeIcon} onClick={onClickRemove}>
          <CloseIcon />
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(Objective);
