import { call, put, takeLatest, all, select } from "redux-saga/effects";

import {
  SURVEY_CREATE_REQUESTING,
  SURVEY_CREATE_SUCCESS,
  SURVEY_CREATE_ERROR,
  SURVEY_MODIFY_REQUESTING,
  SURVEY_MODIFY_ERROR,
  SURVEY_MODIFY_SUCCESS,
  REMOVE_QUESTION,
  ADD_QUESTION,
  SAVE_SURVEY_REQUESTING,
  REORDER_QUESTIONS,
  SURVEY_GET_SUCCESS,
  SURVEY_GET_ERROR,
  SURVEY_GET_REQUESTING,
  GET_SURVEYS_REQUESTING,
  GET_SURVEYS_ERROR,
  GET_SURVEYS_SUCCESS,
  SURVEY_DELETE_REQUESTING,
  SURVEY_DELETE_ERROR,
  SURVEY_DELETE_SUCCESS,
  GET_CAMPAIGN_SURVEYS_SUCCESS,
  GET_CAMPAIGN_SURVEYS_ERROR,
  GET_CAMPAIGN_SURVEYS_REQUESTING,
  SEND_SURVEY_REQUESTING,
  SEND_SURVEY_SUCCESS,
  SEND_SURVEY_ERROR,
  SEND_EVOLUTION_SURVEY_REQUESTING,
  SEND_EVOLUTION_SURVEY_ERROR,
  SEND_EVOLUTION_SURVEY_SUCCESS,
  GET_EVOLUTION_SURVEY_ANSWERS_REQUESTING,
} from "./constants";

import { fetchApi } from "../api";
import { modifySurveyRequest } from "./actions";
import { openSnackMessage } from "services/general/actions";
import i18n from "i18n";
import { CAMPAIGN_RECEIVED } from "services/campaign/constants";
import {
  getSelectedCampaignId,
  getCampaignSelectedEvolutionSurveyId,
} from "services/campaign/selectors";
import { getUserId } from "services/user/selectors";

const apiEndpoints = {
  createSurvey: "/v1/surveys/",
  modifySurvey: "/v1/surveys/",
  getSurvey: "/v1/surveys", // :surveyId
  getAllSurveys: "/v1/surveys/all",
  getSurveyAnswersByCampaign: "/v1/surveys/answers/by-campaign",
  sendSurvey: "/v1/surveys/send",
  sendEvolution: "/v1/surveys/evolution",
};

const getSurveyAnswersByCampaignApi = data => {
  const { campaignId, surveyId } = data;
  return fetchApi(
    `${apiEndpoints.getSurveyAnswersByCampaign}?campaignId=${campaignId}&surveyId=${surveyId}`,
    {},
    "get",
  );
};

const getSurveyApi = data => {
  const { surveyId } = data;
  return fetchApi(`${apiEndpoints.getSurvey}/${surveyId}`, {}, "get");
};

const getAllSurveysApi = data => {
  const { clientId, limit = 0, page = 1 } = data;
  return fetchApi(
    `${apiEndpoints.getAllSurveys}?clientId=${clientId}&page=${page}&limit=${limit}`,
    {},
    "get",
  );
};

const createSurveyApi = data => {
  return fetchApi(apiEndpoints.createSurvey, data, "post");
};

const sendSurveyApi = data => {
  return fetchApi(apiEndpoints.sendSurvey, data, "post");
};

const sendEvolutionSurveyApi = data => {
  return fetchApi(apiEndpoints.sendEvolution, data, "post");
};

export const modifySurveyApi = data => {
  return fetchApi(apiEndpoints.modifySurvey, data, "patch");
};

function* getSurveysAnswersByCampaignFlow(action) {
  try {
    const result = yield call(getSurveyAnswersByCampaignApi, action.payload);

    yield put({ type: GET_CAMPAIGN_SURVEYS_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: GET_CAMPAIGN_SURVEYS_ERROR, payload: error.message });
  }
}

function* getAllSurveysFlow(action) {
  try {
    const result = yield call(getAllSurveysApi, action.payload);

    yield put({ type: GET_SURVEYS_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: GET_SURVEYS_ERROR, payload: error.message });
  }
}

function* getSurveyFlow(action) {
  try {
    const result = yield call(getSurveyApi, action.payload);

    yield put({ type: SURVEY_GET_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: SURVEY_GET_ERROR, payload: error.message });
  }
}

function* createSurveyFlow(action) {
  try {
    const result = yield call(createSurveyApi, action.payload);

    yield put({ type: SURVEY_CREATE_SUCCESS, payload: result });
    yield put(openSnackMessage({ snackMessage: i18n.t("survey-created") }));
  } catch (error) {
    yield put({ type: SURVEY_CREATE_ERROR, payload: error });
  }
}

function* modifySurveyFlow(action) {
  try {
    const result = yield call(modifySurveyApi, action.payload);

    yield put({ type: SURVEY_MODIFY_SUCCESS, payload: result });
    yield put(openSnackMessage({ snackMessage: i18n.t("survey-saved") }));
  } catch (error) {
    yield put({ type: SURVEY_MODIFY_ERROR, payload: error });
  }
}

function* deleteSurveyFlow(action) {
  try {
    const result = yield call(modifySurveyApi, {
      ...action.payload,
      isRemoved: true,
    });

    yield put({ type: SURVEY_DELETE_SUCCESS, payload: result });
    yield put(openSnackMessage({ snackMessage: i18n.t("survey-deleted") }));
  } catch (error) {
    yield put({ type: SURVEY_DELETE_ERROR, payload: error });
  }
}

function* saveSurveyFlow(action) {
  try {
    const survey = yield select(state => state.survey);
    yield put(modifySurveyRequest({ surveyId: survey._id, ...survey }));
  } catch (error) {
    console.log(error);
  }
}

function* sendSurveyFlow(action) {
  try {
    const result = yield call(sendSurveyApi, action.payload);

    yield put({ type: SEND_SURVEY_SUCCESS, payload: result });
    yield put(openSnackMessage({ snackMessage: i18n.t("survey-sent") }));
  } catch (error) {
    console.log(error);
    yield put({ type: SEND_SURVEY_ERROR, payload: error.message });
  }
}

function* sendCampaignEvolutionSurveyFlow(action) {
  try {
    const campaignId = yield select(getSelectedCampaignId);
    const userId = yield select(getUserId);
    const result = yield call(sendEvolutionSurveyApi, { userId, campaignId });

    yield put({ type: SEND_EVOLUTION_SURVEY_SUCCESS, payload: result });
    yield put(openSnackMessage({ snackMessage: i18n.t("survey-sent") }));
    yield put({ type: CAMPAIGN_RECEIVED, payload: result });
  } catch (error) {
    console.log(error);
    yield put({ type: SEND_EVOLUTION_SURVEY_ERROR, payload: error.message });
  }
}

function* getEvolutionSurveyAnswersFlow(action) {
  try {
    yield select(getCampaignSelectedEvolutionSurveyId);
  } catch (error) {
    console.log(error);
    yield put({ type: SEND_EVOLUTION_SURVEY_ERROR, payload: error.message });
  }
}

function* Saga() {
  yield all([
    takeLatest(SURVEY_GET_REQUESTING, getSurveyFlow),
    takeLatest(SURVEY_CREATE_REQUESTING, createSurveyFlow),
    takeLatest(SURVEY_MODIFY_REQUESTING, modifySurveyFlow),
    takeLatest(GET_SURVEYS_REQUESTING, getAllSurveysFlow),
    takeLatest(SURVEY_DELETE_REQUESTING, deleteSurveyFlow),
    takeLatest(
      [
        ADD_QUESTION,
        REMOVE_QUESTION,
        REORDER_QUESTIONS,
        SAVE_SURVEY_REQUESTING,
      ],
      saveSurveyFlow,
    ),
    takeLatest(
      GET_CAMPAIGN_SURVEYS_REQUESTING,
      getSurveysAnswersByCampaignFlow,
    ),
    takeLatest(SEND_SURVEY_REQUESTING, sendSurveyFlow),
    takeLatest(
      SEND_EVOLUTION_SURVEY_REQUESTING,
      sendCampaignEvolutionSurveyFlow,
    ),
    takeLatest(
      GET_EVOLUTION_SURVEY_ANSWERS_REQUESTING,
      getEvolutionSurveyAnswersFlow,
    ),
  ]);
}

export default Saga;
