import React from "react";
import withStyles from "@mui/styles/withStyles";
import { Typography } from "@mui/material";
import Colors from "constants/Colors";
import CustomArrowTooltip from "components/CustomArrowTooltip";

const styles = theme => ({
  main: {
    boxSizing: "border-box",
    height: 32,
    marginBottom: 25,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  title: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    cursor: "pointer",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 1 /* number of lines to show */,
    fontSize: 14,
    textAlign: "left",
    flexWrap: "wrap",
    paddingRight: 15,
    width: 250,
    color: Colors.darkBlue,
    transition: "all 0.2s ease",
    padding: "3px 5px",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: Colors.hoverBlue,
    },
  },
  percent: {
    fontSize: 16,
    width: 130,
    textAlign: "center",
    color: Colors.darkBlue,
  },
  number: {
    marginRight: 25,
    marginLeft: 10,
    fontSize: 16,
    width: 80,
    textAlign: "center",
    fontWeight: "bold",
    color: Colors.darkBlue,
  },
  container: {
    position: "relative",
    flex: 1,
  },
  bar: {
    transition: "all .5s ease-in-out",
    height: 32,
    borderRadius: 2,
    width: "0%",
    backgroundColor: Colors.pureMain,
    zIndex: 1,
  },
  emptyBar: {
    zIndex: 0,
    width: "100%",
    backgroundColor: Colors.veryLightGrey,
    position: "absolute",
    top: 0,
  },
});

const ObjectiveBar = ({ classes, title, count, percent, titleClassName }) => {
  return (
    <div className={classes.main}>
      <CustomArrowTooltip title={title}>
        <Typography className={`${classes.title} ${titleClassName}`}>
          {title}
        </Typography>
      </CustomArrowTooltip>
      <Typography className={classes.percent}>{percent}%</Typography>
      <Typography className={classes.number}>{count}</Typography>
      <div className={classes.container}>
        <div className={classes.bar} style={{ width: `${percent}%` }} />
        <div className={`${classes.bar} ${classes.emptyBar}`} />
      </div>
    </div>
  );
};

export default withStyles(styles)(ObjectiveBar);
