export const MANAGER_GET_TEAM_REQUESTING = "MANAGER_GET_TEAM_REQUESTING";
export const MANAGER_GET_TEAM_SUCCESS = "MANAGER_GET_TEAM_SUCCESS";
export const MANAGER_GET_TEAM_ERROR = "MANAGER_GET_TEAM_ERROR";

export const MANAGER_GET_DASHBOARD_REQUESTING =
  "MANAGER_GET_DASHBOARD_REQUESTING";
export const MANAGER_GET_DASHBOARD_SUCCESS = "MANAGER_GET_DASHBOARD_SUCCESS";
export const MANAGER_GET_DASHBOARD_ERROR = "MANAGER_GET_DASHBOARD_ERROR";

export const MANAGER_SELECT_TEAM_MEMBER = "MANAGER_SELECT_TEAM_MEMBER";

export const MANAGER_CREATE_TEAM_REQUESTING = "MANAGER_CREATE_TEAM_REQUESTING";
export const MANAGER_CREATE_TEAM_SUCCESS = "MANAGER_CREATE_TEAM_SUCCESS";
export const MANAGER_CREATE_TEAM_ERROR = "MANAGER_CREATE_TEAM_ERROR";

export const MANAGER_CLOSE_SNACK_MESSAGE = "MANAGER_CLOSE_SNACK_MESSAGE";

export const MANAGER_SELECT_TAB = "MANAGER_SELECT_TAB";

export const MANAGER_SEE_USER_PROFILE_REQUESTING =
  "MANAGER_SEE_USER_PROFILE_REQUESTING";
export const MANAGER_SEE_USER_PROFILE_ERROR = "MANAGER_SEE_USER_PROFILE_ERROR";
export const MANAGER_SEE_USER_PROFILE_SUCCESS =
  "MANAGER_SEE_USER_PROFILE_SUCCESS";

export const MANAGER_CHANGE_FIELD = "MANAGER_CHANGE_FIELD";

export const MANAGER_SUBMIT_COMMENT_REQUESTING =
  "MANAGER_SUBMIT_COMMENT_REQUESTING";
export const MANAGER_SUBMIT_COMMENT_ERROR = "MANAGER_SUBMIT_COMMENT_ERROR";
export const MANAGER_SUBMIT_COMMENT_SUCCESS = "MANAGER_SUBMIT_COMMENT_SUCCESS";

export const MANAGER_GET_COMMENTS_REQUESTING =
  "MANAGER_GET_COMMENTS_REQUESTING";
export const MANAGER_GET_COMMENTS_SUCCESS = "MANAGER_GET_COMMENTS_SUCCESS";
export const MANAGER_GET_COMMENTS_ERROR = "MANAGER_GET_COMMENTS_ERROR";

export const MANAGER_DELETE_COMMENT_REQUESTING =
  "MANAGER_DELETE_COMMENT_REQUESTING";
export const MANAGER_DELETE_COMMENT_SUCCESS = "MANAGER_DELETE_COMMENT_SUCCESS";
export const MANAGER_DELETE_COMMENT_ERROR = "MANAGER_DELETE_COMMENT_ERROR";

export const MANAGER_ON_EDIT_GOAL = "MANAGER_ON_EDIT_GOAL";

export const MANAGER_SUBMIT_GOALS_REQUESTING =
  "MANAGER_SUBMIT_GOALS_REQUESTING";
export const MANAGER_SUBMIT_GOALS_SUCCESS = "MANAGER_SUBMIT_GOALS_SUCCESS";
export const MANAGER_SUBMIT_GOALS_ERROR = "MANAGER_SUBMIT_GOALS_ERROR";

export const MANAGER_GET_SURVEYS_SUCCESS = "MANAGER_GET_SURVEYS_SUCCESS";
export const MANAGER_GET_SURVEYS_ERROR = "MANAGER_GET_SURVEYS_ERROR";
export const MANAGER_GET_SURVEYS_REQUESTING = "MANAGER_GET_SURVEYS_REQUESTING";

export const MANAGER_MODIFY_SURVEY_SUCCESS = "MANAGER_MODIFY_SURVEY_SUCCESS";
export const MANAGER_MODIFY_SURVEY_ERROR = "MANAGER_MODIFY_SURVEY_ERROR";
export const MANAGER_MODIFY_SURVEY_REQUESTING =
  "MANAGER_MODIFY_SURVEY_REQUESTING";

export const MANAGER_CREATE_SURVEY_REQUESTING =
  "MANAGER_CREATE_SURVEY_REQUESTING";
export const MANAGER_CREATE_SURVEY_SUCCESS = "MANAGER_CREATE_SURVEY_SUCCESS";
export const MANAGER_CREATE_SURVEY_ERROR = "MANAGER_CREATE_SURVEY_ERROR";

export const MANAGER_DELETE_SURVEY_REQUESTING =
  "MANAGER_DELETE_SURVEY_REQUESTING";
export const MANAGER_DELETE_SURVEY_SUCCESS = "MANAGER_DELETE_SURVEY_SUCCESS";
export const MANAGER_DELETE_SURVEY_ERROR = "MANAGER_DELETE_SURVEY_ERROR";

export const MANAGER_SEND_NOTIFICATION_REQUESTING =
  "MANAGER_SEND_NOTIFICATION_REQUESTING";
export const MANAGER_SEND_NOTIFICATION_SUCCESS =
  "MANAGER_SEND_NOTIFICATION_SUCCESS";
export const MANAGER_SEND_NOTIFICATION_ERROR =
  "MANAGER_SEND_NOTIFICATION_ERROR";

export const MANAGER_EDIT_SCHEDULED_NOTIFICATION_REQUESTING =
  "manager/notification/MANAGER_EDIT_SCHEDULED_NOTIFICATION_REQUESTING";
export const MANAGER_EDIT_SCHEDULED_NOTIFICATION_SUCCESS =
  "manager/notification/MANAGER_EDIT_SCHEDULED_NOTIFICATION_SUCCESS";
export const MANAGER_EDIT_SCHEDULED_NOTIFICATION_ERROR =
  "manager/notification/MANAGER_EDIT_SCHEDULED_NOTIFICATION_ERROR";

export const MANAGER_DELETE_SCHEDULED_NOTIFICATION_REQUESTING =
  "manager/notification/MANAGER_DELETE_SCHEDULED_NOTIFICATION_REQUESTING";
export const MANAGER_DELETE_SCHEDULED_NOTIFICATION_SUCCESS =
  "manager/notification/MANAGER_DELETE_SCHEDULED_NOTIFICATION_SUCCESS";
export const MANAGER_DELETE_SCHEDULED_NOTIFICATION_ERROR =
  "manager/notification/MANAGER_DELETE_SCHEDULED_NOTIFICATION_ERROR";

export const MANAGER_MODIFY_USER_SELECTED = "MANAGER_MODIFY_USER_SELECTED";
