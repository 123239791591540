import React, { useState, useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import { Typography } from "@mui/material";
import Bar from "scenes/Campaigns/CampaignScreen/StatsSide/FeelingSection/Bar";
import Colors from "constants/Colors";
import CustomArrowTooltip from "components/CustomArrowTooltip";

const styles = theme => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    marginTop: 25,
  },
  title: {
    cursor: "pointer",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 14,
    color: Colors.darkBlue,
    width: 200,
    marginRight: 50,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 1 /* number of lines to show */,
    textAlign: "left",
    flexWrap: "wrap",
    transition: "all 0.2s ease",
    padding: "3px 5px",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: Colors.hoverBlue,
    },
  },
  number: {
    marginLeft: 10,
    marginRight: 20,
  },
  total: {
    marginLeft: 25,
    flex: 1,
  },
});

const formatNumber = number => {
  return parseInt(((number / 10) * 100).toFixed(1));
};

const FeelingBar = ({ classes, title, number, color, titleClassName }) => {
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    // Met à jour le titre du document via l’API du navigateur
    // setTimeout(() => setPercentage((number / 10) * 100), 1300);
    setTimeout(() => setPercentage(formatNumber(number)), 750);
  });

  return (
    <div className={classes.main}>
      <CustomArrowTooltip title={title}>
        <Typography className={`${classes.title} ${titleClassName}`}>
          {title}
        </Typography>
      </CustomArrowTooltip>
      <Typography className={classes.number}>{number}</Typography>
      <Bar percentage={percentage} color={color} />
      <Typography className={classes.total}>10</Typography>
    </div>
  );
};

export default withStyles(styles)(FeelingBar);
