import React from "react";
import withStyles from '@mui/styles/withStyles';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Typography } from "@mui/material";
import { openSnackMessage } from "services/general/actions";
import { connect } from "react-redux";
import CopyIcon from "@mui/icons-material/FileCopy";
import Colors from "constants/Colors";
import i18n from "i18n";

const styles = theme => ({
  main: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row",
  },
  copyButton: {
    color: "#3A4554",
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 10,
    borderRadius: 4,
    fontSize: 14,
    backgroundColor: Colors.lightGrey,
    padding: "5px 10px",
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: Colors.blueGrey,
    },
  },
  link: {
    overflow: "hidden",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 1 /* number of lines to show */,
    lineHeight: "1.5em" /* fallback */,
    wordBreak: "break-all",
    width: 400,
    backgroundColor: "#F2F2F2",
    padding: "3px 10px",
    borderRadius: 4,
    fontSize: 12,
  },
  copyText: {
    marginLeft: 3,
  },
});

const CopyBox = ({ classes, link, openSnackMessage }) => {
  return (
    <div className={classes.main}>
      <div className={classes.linkBox}>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <Typography className={classes.link}>{link}</Typography>{" "}
        </a>
      </div>
      <CopyToClipboard
        text={link}
        onCopy={() =>
          openSnackMessage({
            snackMessage: i18n.t("link-copied"),
          })
        }
      >
        <div className={classes.copyButton}>
          <CopyIcon />
          <span className={classes.copyText}>{i18n.t("copy")} </span>
        </div>
      </CopyToClipboard>
    </div>
  );
};

export default connect(null, { openSnackMessage })(withStyles(styles)(CopyBox));
