import React from "react";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CheckBox from "components/CheckBox";
import Colors from "constants/Colors";
import VideoIcon from "./VideoIcon";
import PictureIcon from "./PictureIcon";
import DocumentIcon from "./DocumentIcon";

const ROW_HEIGHT = 50;

const useStyles = makeStyles(theme => ({
  main: {
    height: ROW_HEIGHT,
    paddingLeft: 20,
    paddingRight: 20,
    border: "1px solid #E7E7E7",
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": { backgroundColor: Colors.hoverBlue },
    marginBottom: 8,
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
  },
  boxSelected: {
    borderColor: Colors.pureMain,
  },
  text: {
    fontFamily: "Hind",
    color: "#687688",
    marginLeft: 20,
    fontWeight: 500,
  },
  textSelected: {
    color: Colors.pureMain,
  },
  leftSide: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
}));

interface Props {
  text: string;
  icon: string;
  isSelected: boolean;
  onClick: () => void;
}

const getIcon = (icon = "video", isSelected) => {
  if (icon === "video")
    return <VideoIcon color={isSelected ? Colors.pureMain : "#687688"} />;
  if (icon === "picture")
    return <PictureIcon color={isSelected ? Colors.pureMain : "#687688"} />;

  return <DocumentIcon color={isSelected ? Colors.pureMain : "#687688"} />;
};

const OptionRow = ({ text, isSelected, onClick, icon }: Props) => {
  const classes = useStyles();

  return (
    <div
      className={`${classes.main} ${isSelected && classes.boxSelected}`}
      onClick={onClick}
    >
      <div className={classes.leftSide}>
        {getIcon(icon, isSelected)}
        <Typography
          className={`${classes.text} ${isSelected && classes.textSelected}`}
        >
          {text}
        </Typography>
      </div>
      <CheckBox onCheck={onClick} isChecked={isSelected} />
    </div>
  );
};

export default OptionRow;
