import React, { useState, useRef, useEffect } from "react";
import withStyles from '@mui/styles/withStyles';
import { TextField, Typography } from "@mui/material";
import i18n from "i18n";
import ClickOutside from "components/ClickOutside";
import Colors from "constants/Colors";
import MoreIcon from "scenes/Campaigns/MoreIcon";
import ToolTip from "components/ToolTip";

const styles = theme => ({
  main: {
    marginTop: 5,
    marginLeft: 20,
    marginRight: 20,
  },
  addNote: {
    width: 200,
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    padding: "7px 10px",
    transition: "all 0.2s",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: Colors.lightGrey,
      opacity: 0.9,
    },
  },
  text: {
    marginLeft: 10,
    color: Colors.white,
    fontStyle: "underline",
  },
  input: {
    marginRight: 25,
  },
  textField: {
    fontSize: 14,
    color: "#fff",
    "&:focus": {
      color: "#fff",
    },
  },
  label: {
    color: "#fff",
    "&:focus": {
      color: "#fff",
    },
  },
  cssFocused: {},
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: `${Colors.pureMain} !important`,
    },
  },

  notchedOutline: {
    borderWidth: "1px",
    borderColor: "rgba(255,255,255,0.3) !important",
  },
});

const AddNotes = ({ classes, onChangeNote, notes }) => {
  const [isNoteInputVisible, openNoteInput] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    if (isNoteInputVisible) {
      inputRef.current.focus();
    }
  }, [isNoteInputVisible]);

  const onClickOpen = () => {
    openNoteInput(true);
  };

  const onClickOutside = () => {
    if (isNoteInputVisible && !notes) {
      openNoteInput(false);
    }
  };

  return (
    <ClickOutside onClickOutside={onClickOutside}>
      <div className={classes.main}>
        {!notes && !isNoteInputVisible && (
          <ToolTip content={i18n.t("notes-info")}>
            <div className={classes.addNote} onClick={onClickOpen}>
              <MoreIcon color={Colors.main} sizePercentage={0.7} />
              <Typography className={classes.text}>
                {i18n.t("add-notes")}
              </Typography>
            </div>
          </ToolTip>
        )}
        {(notes || isNoteInputVisible) && (
          <TextField
            classes={{
              root: classes.textField,
              label: classes.textField,
              formLabel: classes.textField,
              focused: classes.textField,
            }}
            InputLabelProps={{
              className: classes.label,
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              },
              className: classes.textField,
            }}
            FormHelperTextProps={{
              className: classes.label,
            }}
            className={classes.input}
            inputRef={inputRef}
            fullWidth
            multiline
            label={i18n.t("campaign-notes")}
            value={notes}
            onChange={onChangeNote}
            margin="normal"
            variant="outlined"
          />
        )}
      </div>
    </ClickOutside>
  );
};

export default withStyles(styles)(AddNotes);
