import React from "react";
import withStyles from '@mui/styles/withStyles';
import VideoIcon from "./icons/VideoIcon";
import PdfIcon from "./icons/PdfIcon";
import LinkIcon from "./icons/LinkIcon";
import i18n from "i18n";
import { Typography } from "@mui/material";
import Colors from "constants/Colors";

const styles = theme => ({
  main: {
    width: 200,
    height: 300,
    border: "1px solid #E9E9E9",
    borderRadius: 9,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: Colors.hoverBlue,
    },
  },
  text: {
    marginTop: 25,
    fontSize: 18,
  },
});

const iconsType = {
  videoLink: <VideoIcon />,
  pdf: <PdfIcon />,
  webLink: <LinkIcon />,
};

const BoxType = ({ classes, type = "videoLink", onClick }) => {
  return (
    <div className={classes.main} onClick={onClick}>
      {iconsType[type]}
      <Typography className={classes.text}>{i18n.t(type)}</Typography>
    </div>
  );
};

export default withStyles(styles)(BoxType);
