import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import Tab from "./Tab";
import Colors from "../../../../../constants/Colors";

import { selectPreviewTab } from "../../../../../services/campaign/actions";
import { connect, useSelector } from "react-redux";
import i18n from "../../../../../i18n";
import PeopleIcon from "scenes/Campaigns/PeopleIcon";
import LightIcon from "scenes/CreateTrack/LightIcon";
import HomeIcon from "scenes/Campaigns/CampaignScreen/SideBar/NavBar/HomeIcon";
import SurveyIcon from "scenes/Campaigns/CampaignScreen/SideBar/NavBar/SurveyIcon";
import { getAppLanguage } from "services/general/selectors";
import ResourceIcon from "scenes/Campaigns/CampaignScreen/SideBar/NavBar/ResourceIcon";
import ReviewIcon from "./ReviewIcon";
import {
  getCampaignHasReviews,
  getUserCampaignDoneSteps,
} from "services/campaign/selectors";
import { Badge } from "@mui/material";
import { getIsTrainerOrObserver, getUserId } from "services/user/selectors";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  badge: {
    backgroundColor: Colors.pink,
    color: Colors.white,
    position: "inherit",
  },
});

const NavBar = ({
  classes,
  selectPreviewTab,
  previewTabSelected,
  userId,
  language,
  hasReviews,
  hasLimitedAccess,
}) => {
  const onClickTab = tabIndex => () => {
    selectPreviewTab(tabIndex);
  };

  const campaignDoneSteps = useSelector(getUserCampaignDoneSteps);
  const [numberOfUnseenComment, setNumberOfUnseenComment] = useState(0);

  useEffect(() => {
    const doneStepsWithComments = campaignDoneSteps?.filter(
      el => el.review?.comments?.length > 0,
    );
    const numberOfCommentNotSeen = doneStepsWithComments
      .map(element => {
        return (
          element.review?.comments?.filter(com => {
            const isNotSeenByMe = !com.isSeen && com?.user?._id !== userId;
            const amITheCorrector =
              userId === element?.review?.corrector?.correctorId;

            return isNotSeenByMe && amITheCorrector;
          })?.length || 0
        );
      })
      .reduce((a, b) => a + b, 0);

    setNumberOfUnseenComment(numberOfCommentNotSeen);
  }, [campaignDoneSteps, userId]);

  const getTabs = () => {
    const tabs = [
      {
        icon: <HomeIcon />,
        text: i18n.t("general"),
        hasAccess: true,
      },
      {
        icon: <PeopleIcon />,
        text: i18n.t("learners"),
        hasAccess: true,
      },
      {
        icon: <LightIcon color={"#fff"} style={{ width: 18 }} />,
        text: i18n.t("Actions"),
        hasAccess: true,
      },
      {
        icon: <ResourceIcon style={{ width: 18 }} />,
        text: i18n.t("resources"),
        hasAccess: hasLimitedAccess ? false : true,
      },
      {
        icon: <SurveyIcon color={"#fff"} style={{ width: 18 }} />,
        text: i18n.t("Surveys"),
        hasAccess: hasLimitedAccess ? false : true,
      },
    ];

    if (hasReviews) {
      tabs.push({
        icon: <ReviewIcon color={"#fff"} style={{ width: 18 }} />,
        text: (
          <Badge
            badgeContent={numberOfUnseenComment}
            classes={{ badge: classes.badge }}
          >
            {i18n.t("Reviews")}
          </Badge>
        ),
        hasAccess: hasLimitedAccess ? false : true,
      });
    }

    return tabs;
  };

  return (
    <div className={classes.root}>
      {getTabs().map((tab, index) => (
        <Tab
          {...tab}
          key={index}
          onClick={onClickTab(index)}
          isSelected={previewTabSelected === index}
        />
      ))}
    </div>
  );
};

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const { previewTabSelected } = state.campaign;
  const userId = getUserId(state);
  const hasLimitedAccess = getIsTrainerOrObserver(state);
  return {
    previewTabSelected,
    userId,
    hasLimitedAccess,
    language: getAppLanguage(state),
    hasReviews: getCampaignHasReviews(state),
  };
};

export default connect(mapStateToProps, {
  selectPreviewTab,
})(withStyles(styles)(NavBar));
