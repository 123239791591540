import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import auth from "./auth/reducer";
import client from "./client/reducer";
import manager from "./manager/reducer";
import poll from "./poll/reducer";
import token from "./token/reducer";
import createTrack from "./createTrack/reducer";
import campaign from "./campaign/reducer";
import upload from "./upload/reducer";
import user from "./user/reducer";
import signup from "./signup/reducer";
import general from "./general/reducer";
import myClients from "./myClients/reducer";
import myUsers from "./myUsers/reducer";
import message from "./message/reducer";
import notification from "./notification/reducer";
import survey from "./survey/reducer";
import coaching from "./coaching/reducer";
import resource from "./resource/reducer";
import myCoachees from "./myCoachees/reducer";
import invoice from "./invoice/reducer";

// for blackListing requesting / loading in the reducers
export const allReducersName = [
  "auth",
  "client",
  "manager",
  "poll",
  "token",
  "createTrack",
  "campaign",
  "upload",
  "user",
  "signup",
  "general",
  "myClients",
  "myUsers",
  "message",
  "notification",
  "survey",
  "coaching",
  "resource",
  "myCoachees",
  "invoice",
];

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    client,
    manager,
    poll,
    token,
    createTrack,
    campaign,
    upload,
    user,
    signup,
    general,
    myClients,
    myUsers,
    message,
    notification,
    survey,
    coaching,
    resource,
    myCoachees,
    invoice,
  });

export default createRootReducer;
