import React, { useState } from "react";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Colors from "constants/Colors";
import i18n from "i18n";
import moment from "moment";
import { useSelector } from "react-redux";
import { getCoachingFinishedDate } from "services/coaching/selectors";

const ExitIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8482 1.6524L10.4382 0.242401L6.14319 4.5374L1.84819 0.242401L0.438187 1.6524L4.73319 5.9474L0.438187 10.2424L1.84819 11.6524L6.14319 7.3574L10.4382 11.6524L11.8482 10.2424L7.55319 5.9474L11.8482 1.6524Z"
      fill="white"
    />
  </svg>
);

const useStyles = makeStyles(theme => ({
  main: {
    paddingLeft: 40,
    zIndex: -5,
    width: "100%",
    minHeight: 0,
    height: 0,
    top: 0,
    backgroundColor: Colors.darkBlue,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    transition: "all 0.2s ease-in-out",
    borderRadius: 4,
    "&:hover": {
      opacity: 0.9,
    },
  },
  text: {
    margin: 0,
    lineHeight: 1,
    marginBlockEnd: 0,
    marginBlockStart: 0,
    color: Colors.white,
    fontSize: 14,
    textAlign: "center",
  },
  open: {
    cursor: "pointer",
    position: "relative",
    zIndex: 100,
    minHeight: 40,
    marginBottom: 20,
  },
  exitIcon: {
    zIndex: 100,
    cursor: "pointer",
    position: "absolute",
    right: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  bold: {
    fontWeight: 500,
    textDecoration: "underline",
  },
}));

interface Props {
  className?: string;
}

const FinishedCoachingBanner = ({ className = "" }: Props) => {
  const [isOpen, setOpen] = useState(true);
  const classes = useStyles();

  const finishedDate = useSelector(getCoachingFinishedDate);

  return (
    <div
      onClick={() => setOpen(false)}
      className={`${classes.main} ${className} ${isOpen && classes.open}`}
    >
      {isOpen && (
        <Typography className={classes.text}>
          {i18n.t("coaching-finished-date")}
          {moment(finishedDate).format("LL")}
        </Typography>
      )}
      <div className={classes.exitIcon} onClick={() => setOpen(false)}>
        <ExitIcon />
      </div>
    </div>
  );
};

export default FinishedCoachingBanner;
