import React from "react";
import Colors from "../../../constants/Colors";
import { Typography, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import i18n from "../../../i18n";
import CheckIcon from "../CheckIcon";
import DuplicateIcon from "../DuplicateIcon";
import DeleteIcon from "../../Campaigns/DeleteIcon";
import RemoveIcon from "../DeleteIcon";
import { Loader } from "../../../components";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import useWindowSize from "@react-hook/window-size";

const variants = {
  hidden: { opacity: 0, y: 40 },
  visible: { opacity: 1, y: 0 },
};

const useStyles = makeStyles(theme => ({
  main: {
    zIndex: 300,
    width: "100%",
    height: 60,
    minHeight: 60,
    background: Colors.white,
    boxShadow: "0px 2px 15px rgba(169, 169, 169, 0.25)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  leftDiv: {
    paddingLeft: 25,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    flex: 1,
  },
  rightDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  saveButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: 4,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 8,
    paddingBottom: 8,
    marginRight: 10,
    marginLeft: 0,
    backgroundColor: Colors.pureMain,
    transition: "all 0.2s ease",
    "&:hover": {
      opacity: 0.75,
    },
  },
  secondaryButton: {
    backgroundColor: "#F4F6F9",
  },
  deleteButton: {
    color: Colors.red,
    marginRight: 10,
    backgroundColor: Colors.lightGrey,
    "&:hover": {
      backgroundColor: Colors.lightGrey,
      opacity: 0.85,
    },
  },
  deleteText: {
    color: Colors.white,
    fontWeight: "normal",
    marginLeft: 10,
    fontSize: 16,
  },
  duplicateText: {
    color: Colors.text,
    fontWeight: "normal",
    marginLeft: 10,
    fontSize: 14,
  },
  saveText: {
    color: Colors.white,
    // fontWeight: "bold",
    marginLeft: 10,
    fontSize: 16,
  },
  disabledButton: {
    backgroundColor: Colors.mediumGrey,
    "&:hover": {
      opacity: 1,
    },
  },
  disabledText: {
    fontWeight: "normal",
    color: Colors.hardGrey,
  },
  legendDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  legends: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  legend: {
    fontFamily: "Montserrat",
    marginLeft: 10,
    color: "#fff",
    fontSize: 14,
    fontWeight: 600,
  },
  savingText: {
    fontSize: "1.1em",
    color: Colors.lightWhite,
    fontStyle: "italic",
    marginRight: 20,
    marginLeft: 5,
  },
  topBarTitle: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 20,
    lineHeight: "27px",
    color: Colors.text,
  },
  previewDiv: {
    borderLeft: "solid 1px rgba(216,216,216,0.3)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    width: 375,
  },
  topPreview: {
    alignItems: "center",
    justifyContent: "center",
    // paddingRight: 15,
    // paddingLeft: 15,
    width: "100%",
    height: 60,
    display: "flex",
  },
  previewSubtitle: {
    marginTop: 30,
    marginBottom: 30,
    width: "100%",
    color: "#A6AECE",
    fontSize: 14,
    textAlign: "center",
    paddingLeft: 15,
  },
  topRightDiv: {
    paddingTop: 30,
    alignItems: "center",
    paddingRight: 30,
    paddingLeft: 40,
    width: "100%",
    height: 60,
    display: "flex",
  },
  firstDiv: {
    flex: 2,
    display: "flex",
  },
  menuButton: {
    position: "absolute",
    right: 10,
  },
  "@media (max-width: 1440px)": {
    previewDiv: {
      width: 325,
    },
  },
  "@media (max-width: 1200px)": {
    topBarTitle: {
      fontSize: 18,
    },
  },
}));

const EditBar = ({
  title = i18n.t("challenge-details"),
  previewTitle = i18n.t("challenge-preview"),
  isEditable = false,
  isLibrary = false,
  requesting = false,
  saveButtonTitle = "",
  isSaveButtonDisabled = false,
  onClickSave = null,
  onClickDuplicate = null,
  onClickDelete = null,
  onClickRemove = null,
  stepId = null,
  saving = false,
  isPreviewVisible = false,
  handlePreview = () => null,
}) => {
  const classes = useStyles();
  const [width] = useWindowSize();
  const isSmall = width < 1400;

  return (
    <div
      initial="hidden"
      animate="visible"
      variants={variants}
      className={classes.main}
    >
      <div className={classes.firstDiv}>
        <div className={classes.leftDiv}>
          <Typography className={classes.topBarTitle}>{title}</Typography>
        </div>
        <div className={classes.rightDiv}>
          {!isLibrary && (
            <div
              className={`${classes.saveButton} ${classes.secondaryButton}`}
              onClick={onClickRemove}
            >
              <RemoveIcon sizePercentage={0.5} color={Colors.text} />
              <Typography className={`${classes.duplicateText}`}>
                {!isSmall ? i18n.t("remove-selection") : i18n.t("remove")}
              </Typography>
            </div>
          )}

          {stepId && (
            <div
              className={`${classes.saveButton} ${classes.secondaryButton}`}
              onClick={requesting ? null : onClickDuplicate}
            >
              <DuplicateIcon />
              <Typography className={`${classes.duplicateText}`}>
                {i18n.t("duplicate")}
              </Typography>
            </div>
          )}
          {isEditable && isLibrary && (
            <div
              className={`${classes.saveButton} ${classes.deleteButton}`}
              onClick={onClickDelete}
            >
              <DeleteIcon color={Colors.red} sizePercentage={1.35} />
            </div>
          )}
          {isEditable && !requesting && (
            <>
              {!stepId && (
                <div
                  className={`${classes.saveButton} ${
                    (isSaveButtonDisabled || requesting) &&
                    classes.disabledButton
                  }`}
                  onClick={
                    !requesting && !isSaveButtonDisabled ? onClickSave : null
                  }
                >
                  {requesting ? <Loader size={25} /> : <CheckIcon />}
                  <Typography
                    className={`${classes.saveText} ${
                      (isSaveButtonDisabled || requesting) &&
                      classes.disabledText
                    }`}
                  >
                    {saveButtonTitle}
                  </Typography>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className={classes.previewDiv}>
        <div className={classes.topPreview}>
          <Typography className={classes.topBarTitle}>
            {previewTitle}
          </Typography>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handlePreview}
            className={classes.menuButton}
            size="large"
          >
            {isPreviewVisible ? <ChevronRightIcon /> : <MenuIcon />}
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default React.memo(EditBar);
