import React from "react";

const VideoIcon = ({
  className = "",
  height = 12,
  width = 12,
  color = "#5FB8F9",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      className={className}
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M6 0C4.81331 0 3.65327 0.351894 2.66658 1.01118C1.67989 1.67047 0.910851 2.60754 0.456725 3.7039C0.0025997 4.80025 -0.11622 6.00665 0.115291 7.17054C0.346802 8.33443 0.918247 9.40352 1.75736 10.2426C2.59648 11.0818 3.66557 11.6532 4.82946 11.8847C5.99334 12.1162 7.19974 11.9974 8.2961 11.5433C9.39246 11.0891 10.3295 10.3201 10.9888 9.33342C11.6481 8.34672 12 7.18669 12 6C12 5.21207 11.8448 4.43185 11.5433 3.7039C11.2417 2.97594 10.7998 2.31451 10.2426 1.75736C9.68549 1.20021 9.02405 0.758251 8.2961 0.456723C7.56815 0.155195 6.78793 0 6 0V0ZM6 10.8C5.05065 10.8 4.12262 10.5185 3.33326 9.99105C2.54391 9.46362 1.92868 8.71396 1.56538 7.83688C1.20208 6.95979 1.10702 5.99467 1.29223 5.06357C1.47744 4.13246 1.9346 3.27718 2.60589 2.60589C3.27718 1.93459 4.13246 1.47744 5.06357 1.29223C5.99468 1.10702 6.9598 1.20208 7.83688 1.56538C8.71397 1.92868 9.46362 2.54391 9.99105 3.33326C10.5185 4.12262 10.8 5.05065 10.8 6C10.8 7.27304 10.2943 8.49394 9.39411 9.39411C8.49394 10.2943 7.27304 10.8 6 10.8V10.8Z"
        fill={color}
      />
      <path
        d="M6.20414 3.26999C6.05712 3.13451 5.87353 3.04522 5.67619 3.01321C5.47884 2.98121 5.27644 3.00791 5.09414 3.08999C4.91717 3.16158 4.76557 3.2843 4.65869 3.44247C4.55182 3.60065 4.49453 3.7871 4.49414 3.97799V8.02199C4.49453 8.21289 4.55182 8.39933 4.65869 8.55751C4.76557 8.71568 4.91717 8.8384 5.09414 8.90999C5.22421 8.96898 5.36532 8.99966 5.50814 8.99999C5.76549 8.99885 6.01335 8.9027 6.20414 8.72999L8.40014 6.70799C8.49836 6.61805 8.57679 6.50866 8.63044 6.38677C8.6841 6.26488 8.71181 6.13317 8.71181 5.99999C8.71181 5.86682 8.6841 5.7351 8.63044 5.61321C8.57679 5.49132 8.49836 5.38193 8.40014 5.29199L6.20414 3.26999ZM5.70014 7.55999V4.43999L7.38614 5.99999L5.70014 7.55999Z"
        fill={color}
      />
    </svg>
  );
};

export default VideoIcon;
