import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Modal from "components/Modal";
import i18n from "i18n";
import UserList from "./UserList";
import FlatButton from "components/FlatButton";
import AddIcon from "scenes/Campaigns/SeeUserModal/Coaching/RoadmapCoaching/NewGoalModal/GoalSettings/AddIcon";

import START_COACHING_PICTURE from "../../../resources/StartCoaching.svg";

const useStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: 600,
    height: 600,
    overflow: "hidden",
  },
  icon: {
    marginRight: 10,
  },
  topComponent: {
    flex: 1,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    flexDirection: "row",
  },
}));

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onCreateCoachee: () => void;
}

const AddCoachingModal = ({ isVisible, onClose, onCreateCoachee }: Props) => {
  const classes = useStyles();

  return (
    <Modal
      title={i18n.t("start-coaching")}
      hasSideExplanation
      isVisible={isVisible}
      onClose={onClose}
      sideExplanation={{
        title: i18n.t("to-coach"),
        explanation: i18n.t("to-coach-explanation"),
        picture: START_COACHING_PICTURE,
      }}
      topComponentClassName={classes.topComponent}
      topComponent={
        <FlatButton
          icon={<AddIcon className={classes.icon} color={"#fff"} />}
          onClick={onCreateCoachee}
          title={i18n.t("create-new-coachee")}
        />
      }
    >
      <div className={classes.main}>
        <UserList />
      </div>
    </Modal>
  );
};

export default AddCoachingModal;
