import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";
import { closeSnackMessage } from "../../services/general/actions";
import Colors from "../../constants/Colors";

const styles = theme => ({
  close: {
    padding: theme.spacing.unit / 2,
  },
  normal: {},
  success: {
    backgroundColor: Colors.green,
  },
  alert: {
    backgroundColor: Colors.red,
  },
});

class SimpleSnackbar extends React.Component {
  handleClick = () => {
    this.setState({ open: true });
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.props.closeSnackMessage();
  };

  getSnackClassName = snackStatus => {
    const { classes } = this.props;
    let className = classes.normal;
    switch (snackStatus) {
      case "success":
        className = classes.success;
        break;
      case "alert":
        className = classes.alert;
        break;

      default:
        break;
    }
    return className;
  };

  render() {
    const { classes, snackStatus } = this.props;
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={
            this.props.snackMessage !== null && this.props.snackMessage !== ""
          }
          autoHideDuration={3000}
          onClose={this.handleClose}
          ContentProps={{
            "aria-describedby": "message-id",
            classes: {
              root: this.getSnackClassName(snackStatus),
            },
          }}
          message={<span id="message-id">{this.props.snackMessage}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
              size="large">
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}

SimpleSnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const { snackMessage, snackStatus } = state.general;
  return {
    snackMessage,
    snackStatus,
  };
};

export default connect(mapStateToProps, { closeSnackMessage })(
  withStyles(styles)(SimpleSnackbar),
);
