//@flow
import { Invoice } from "scenes/Invoice/data/types";
import {
  CREATE_INVOICE_REQUESTING,
  DELETE_INVOICE_REQUESTING,
  GET_ALL_INVOICES_REQUESTING,
  GET_INVOICE_CLIENTS_REQUESTING,
  START_NEW_INVOICE,
  UPDATE_INVOICE_REQUESTING,
} from "./constants";

export const createInvoiceRequest = (payload: { invoice: Invoice }) => {
  return {
    type: CREATE_INVOICE_REQUESTING,
    payload,
  };
};

export const getAllInvoicesRequest = () => {
  return {
    type: GET_ALL_INVOICES_REQUESTING,
  };
};

export const deleteInvoiceRequest = payload => {
  return {
    type: DELETE_INVOICE_REQUESTING,
    payload,
  };
};

export const startNewInvoice = payload => {
  return {
    type: START_NEW_INVOICE,
    payload,
  };
};

export const getInvoiceClientsRequest = () => {
  return {
    type: GET_INVOICE_CLIENTS_REQUESTING,
    // payload,
  };
};

export const updateInvoiceRequest = payload => {
  return {
    type: UPDATE_INVOICE_REQUESTING,
    payload,
  };
};
