import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Colors from "constants/Colors";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "scenes/CreateTrack/Skills/CloseIcon";

import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Notifications from "../Notifications";
import NotificationHistory from "../NotificationHistory";
import i18n from "i18n";

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: 10,
    minWidth: "33%",
    minHeight: "40%",
    display: "flex",
    flexDirection: "row",
    maxWidth: 900,
    maxHeight: `90vh`,
  },
  rightModal: {
    paddingTop: 5,
    paddingRight: 8,
    paddingLeft: 8,
    paddingBottom: 5,
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    flex: 1,
    marginRight: 15,
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 24,
    color: Colors.darkBlue,
  },
  closeButton: {
    zIndex: 10,
    marginRight: -15,
    padding: 12,
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 24px",
  },
}));

interface Props {
  className?: string;
  isVisible: boolean;
  onClose: () => void;
  onRendered?: () => void;
  onKeyDown?: (event: any) => void;
  isSendNotificationModalVisible: boolean;
  isHistoryNotificationModalVisible: boolean;
  handleEditNotification: () => void;
  handleSendNotification: () => void;
  usersSelected: string[];
}

const NotificationModal = ({
  className,
  isVisible,
  onClose,
  onRendered = null,
  isSendNotificationModalVisible = false,
  isHistoryNotificationModalVisible = false,
  onKeyDown = e => {
    if (e.key === "Escape" && isVisible) {
      onClose();
    }
  },
  handleEditNotification = () => {},
  handleSendNotification = () => {},
  usersSelected = [],
}: Props) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isVisible}
      PaperProps={{
        style: {
          borderRadius: 10,
          display: "flex",
          maxWidth: 900,
          maxHeight: `90vh`,
        },
      }}
      classes={{ paper: `${className}` }}
      onClose={onClose}
      onKeyDown={onKeyDown}
      ref={onRendered}
    >
      {isSendNotificationModalVisible && (
        <div className={classes.rightModal}>
          <DialogTitle
            classes={{
              root: classes.header,
            }}
            className={classes.header}
          >
            <Typography variant="h6" className={classes.title}>
              {i18n.t("send-notification")}
            </Typography>
            <IconButton className={classes.closeButton} onClick={onClose}>
              <CloseIcon sizePercentage={2} />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Notifications
              userIds={usersSelected}
              sendNotification={handleSendNotification}
            />
          </DialogContent>
        </div>
      )}
      {isHistoryNotificationModalVisible && (
        <DialogContent className={classes.dialogContent}>
          <NotificationHistory editNotification={handleEditNotification} />
        </DialogContent>
      )}
    </Dialog>
  );
};

export default NotificationModal;
