export const GET_COACHEE_REQUESTING = "coaching/GET_COACHEE_REQUESTING";
export const GET_COACHEE_SUCCESS = "coaching/GET_COACHEE_SUCCESS";
export const GET_COACHEE_ERROR = "coaching/GET_COACHEE_ERROR";

export const GET_MY_COACHEES_REQUESTING = "coaching/GET_MY_COACHEES_REQUESTING";
export const GET_MY_COACHEES_SUCCESS = "coaching/GET_MY_COACHEES_SUCCESS";
export const GET_MY_COACHEES_ERROR = "coaching/GET_MY_COACHEES_ERROR";

export const UPDATE_COACHEE_REQUESTING = "coaching/UPDATE_COACHEE_REQUESTING";
export const UPDATE_COACHEE_SUCCESS = "coaching/UPDATE_COACHEE_SUCCESS";
export const UPDATE_COACHEE_ERROR = "coaching/UPDATE_COACHEE_ERROR";

export const CREATE_COACHEE_REQUESTING = "coaching/CREATE_COACHEE_REQUESTING";
export const CREATE_COACHEE_SUCCESS = "coaching/CREATE_COACHEE_SUCCESS";
export const CREATE_COACHEE_ERROR = "coaching/CREATE_COACHEE_ERROR";

export const DELETE_COACHEE_REQUESTING = "coaching/DELETE_COACHEE_REQUESTING";
export const DELETE_COACHEE_SUCCESS = "coaching/DELETE_COACHEE_SUCCESS";
export const DELETE_COACHEE_ERROR = "coaching/DELETE_COACHEE_ERROR";

export const CLEAR_COACHEE = "coaching/CLEAR_COACHEE";

export const UPDATE_COACHEE = "coaching/UPDATE_COACHEE";

export const REMOVE_RESOURCE_REQUESTING = "coaching/REMOVE_COACHEE";
