import React, { useState } from "react";

import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";

import Colors from "../../constants/Colors";
import { connect } from "react-redux";
import {
  getSelectedClientName,
  getCreatorClientId,
} from "services/myClients/selectors";
import i18n from "i18n";
import {
  getIsSuperAdmin,
  getUserClientId,
} from "services/user/selectors";
import { getIsCoachingAccount } from "services/client/selectors";

const PULSE_CLIENT_ID = "5d77df8b9ce7930016f9a831";
const TEST_PULSE_CLIENT_ID = "5e062e2871f77d0016b77e05";

const ExitIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8482 1.6524L10.4382 0.242401L6.14319 4.5374L1.84819 0.242401L0.438187 1.6524L4.73319 5.9474L0.438187 10.2424L1.84819 11.6524L6.14319 7.3574L10.4382 11.6524L11.8482 10.2424L7.55319 5.9474L11.8482 1.6524Z"
      fill="white"
    />
  </svg>
);

const styles = theme => ({
  main: {
    zIndex: -1,
    width: "100%",
    height: 0,
    top: 0,
    backgroundColor: "#EB333A",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      opacity: 0.9,
    },
  },
  text: {
    margin: 0,
    lineHeight: 1,
    marginBlockEnd: 0,
    marginBlockStart: 0,
    color: Colors.white,
    // fontWeight: 600,
    fontSize: 14,
  },
  open: {
    cursor: "pointer",
    position: "relative",
    zIndex: 1,
    height: 40,
  },
  exitIcon: {
    cursor: "pointer",
    position: "absolute",
    right: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  bold: {
    fontWeight: 500,
  },
});

const WarningSubClient = ({
  classes,
  className,
  clientName,
  userClientId,
  clientCreatorId,
  isCoachingAccount = false,
  isSuperAdmin = false,
}) => {
  const [isOpen, setOpen] = useState(true);
  if (
    !isSuperAdmin &&
    clientCreatorId &&
    PULSE_CLIENT_ID !== clientCreatorId &&
    TEST_PULSE_CLIENT_ID !== clientCreatorId &&
    userClientId !== clientCreatorId &&
    !isCoachingAccount
  ) {
    return (
      <div
        onClick={() => setOpen(false)}
        className={`${classes.main} ${className} ${isOpen && classes.open}`}
      >
        <Typography className={classes.text}>
          {i18n.t("warning-subClient")}
          <span className={classes.bold}>{clientName}</span>
        </Typography>
        <div className={classes.exitIcon} onClick={() => setOpen(false)}>
          <ExitIcon />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const mapStateToProps = state => {
  return {
    isSuperAdmin: getIsSuperAdmin(state),
    clientName: getSelectedClientName(state),
    userClientId: getUserClientId(state),
    clientCreatorId: getCreatorClientId(state),
    isCoachingAccount: getIsCoachingAccount(state),
  };
};

export default connect(
  mapStateToProps,
  {},
)(withStyles(styles)(WarningSubClient));
