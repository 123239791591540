import React from "react";
import withStyles from "@mui/styles/withStyles";
import Title from "scenes/CreateTrack/PublishCampaignModal/Title";
import Explanation from "scenes/CreateTrack/PublishCampaignModal/Explanation";
import i18n from "i18n";
import TextBox from "components/TextBox";
import FlatButton from "components/FlatButton";
import { useChangeTeams } from "scenes/CreateTrack/PublishCampaignModal/hooks";

const styles = theme => ({
  main: {
    marginTop: 15,
  },
  boxes: {
    marginTop: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  box: {
    marginLeft: 0,
    marginRight: 15,
  },
  button: {
    marginLeft: 10,
    fontSize: 14,
    fontWeight: "normal",
    padding: "7px 9px",
  },
});

const TeamSize = ({
  classes,
  languageSelected,
  onChangeTeamSize,
  teamSize,
}) => {
  const [requesting, resetTeamRequest, isLocatedOnTrack] = useChangeTeams();

  const resetTeams = () => {
    resetTeamRequest(teamSize);
  };

  return (
    <div className={classes.main}>
      <Title
        text={i18n.t("campaign-team", {
          lng: languageSelected,
        })}
        optional
        languageSelected={languageSelected}
      />
      <Explanation
        text={i18n.t("campaign-team-explanation", {
          lng: languageSelected,
        })}
      />
      <div className={classes.boxes}>
        {[0, 2, 3, 4, 5, 10].map((option, index) => (
          <TextBox
            key={index}
            className={classes.box}
            title={option}
            onClick={onChangeTeamSize(option)}
            isSelected={teamSize === option}
          />
        ))}
        {!isLocatedOnTrack && (
          <FlatButton
            requesting={requesting}
            className={`${classes.button}`}
            onClick={resetTeams}
            title={i18n.t("reset-teams")}
          />
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(TeamSize);
