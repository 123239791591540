import React, { useRef, useState } from "react";
import { TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import i18n from "i18n";
import FlatButton from "components/FlatButton";
import { useSelector } from "react-redux";
import { getUserSelectedName } from "services/manager/selectors";
import Switch from "react-switch";
import useEndCoaching from "../hooks/useEndCoaching";
import Modal from "components/Modal";
import SummaryRow from "./SummaryRow";
import {
  getCoachingDurationInMonths,
  getTotalDoneActions,
  getTotalDoneSessions,
  getGoalsResult,
  getIsCoachingFinished,
  getEndCoachingMessage,
  getIsCoachingSessionForbidden,
} from "services/coaching/selectors";
import { getIsCoachingAccount } from "services/client/selectors";
import END_COACHING_PICTURE from "../../../../../resources/end-coaching.svg";

const TEXT_WIDTH = 320;
const IMAGE_HEIGHT = 80;

const ROW_MIN_WIDTH = 700;

const useStyles = makeStyles(theme => ({
  paper: {},
  main: {
    paddingLeft: 25,
    paddingRight: 25,
    display: "flex",
    flexDirection: "column",
  },
  modalTitle: {
    marginLeft: 25,
    marginTop: 20,
  },
  title: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    lineHeight: "120%",
    color: "#282828",
    fontSize: "1.1rem",
  },
  subtitle: {
    marginTop: 3,
    color: "#101534",
  },
  button: {
    fontSize: 14,
    width: TEXT_WIDTH,
    height: 50,
    marginBottom: 20,
    alignSelf: "flex-end",
  },
  summaryTopDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  summary: {
    paddingTop: 20,
    marginBottom: 15,
    minWidth: ROW_MIN_WIDTH,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  endCoachingMessageTopDiv: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textDiv: {
    height: IMAGE_HEIGHT,
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  picture: {
    height: IMAGE_HEIGHT,
    width: IMAGE_HEIGHT,
  },
  endCoachingMessage: {
    padding: "25px 30px",
    marginTop: 30,
    marginBottom: 35,
    border: "solid 1px #E7E7E7",
    borderRadius: 5,
    minHeight: 150,
  },
  accessDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: 35,
  },
  accessExplanation: {
    marginLeft: 15,
    fontSize: "0.9rem",
    color: "#282828",
  },
}));

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

const EndCoachingModal = ({ isVisible, onClose }: Props) => {
  const classes = useStyles();
  const inputRef = useRef(null);

  const existingCoachingMessage = useSelector(getEndCoachingMessage);
  const existingIsForbidden = useSelector(getIsCoachingSessionForbidden);

  const [requesting, endCoachingRequest] = useEndCoaching();
  const [endCoachingMessage, setEndCoachingMessage] = useState(
    existingCoachingMessage,
  );
  const [isForbidden, setIsForbidden] = useState(existingIsForbidden);

  const isCoachingFinished = useSelector(getIsCoachingFinished);

  const isCoachingAccount = useSelector(getIsCoachingAccount);
  const userName = useSelector(getUserSelectedName);

  const totalDurationInMonths = useSelector(getCoachingDurationInMonths);

  const totalDoneActions = useSelector(getTotalDoneActions);

  const totalDoneSessions = useSelector(getTotalDoneSessions);

  const goalsResult = useSelector(getGoalsResult);

  const handleEndCoachingClick = () => {
    endCoachingRequest({
      isFinished: isCoachingFinished ? false : true,
      isForbidden,
      endCoachingMessage,
    });
  };

  const handleEndCoachingMessageChange = e => {
    const value = e.target.value;
    setEndCoachingMessage(value);
  };

  const handleClickInput = () => {
    if (inputRef && inputRef.current) {
      return inputRef?.current?.focus();
    }
  };

  const handleAccessChange = () => {
    setIsForbidden(!isForbidden);
  };

  const finishedDate = null;

  return (
    <Modal
      className={classes.paper}
      isVisible={isVisible}
      onClose={onClose}
      titleClassName={classes.modalTitle}
      title={i18n.t("end-coaching-title")}
    >
      <div className={classes.main}>
        <div className={classes.summaryTopDiv}>
          <Typography className={classes.title}>
            {`${i18n.t("coaching-summary")}${userName}`}
          </Typography>
          {!!finishedDate && <Typography>{finishedDate}</Typography>}
        </div>
        <div className={classes.summary}>
          <SummaryRow
            hasSeparator={false}
            title={i18n.t("summary-total-duration")}
            result={`${totalDurationInMonths} ${i18n.t("months")}`}
          />
          <SummaryRow title={i18n.t("summary-goals")} result={goalsResult} />
          <SummaryRow
            title={i18n.t("summary-done-actions")}
            result={totalDoneActions}
          />
          <SummaryRow
            title={i18n.t("summary-done-sessions")}
            result={totalDoneSessions}
          />
        </div>
        <div className={classes.endCoachingMessageTopDiv}>
          <div className={classes.textDiv}>
            <Typography className={classes.title}>
              {`${i18n.t("end-coaching-message")}`}
            </Typography>
            <Typography className={classes.subtitle}>
              {i18n.t("end-coaching-message-explanation")}
            </Typography>
          </div>
          <img
            src={END_COACHING_PICTURE}
            className={classes.picture}
            alt="End coaching"
          />
        </div>
        <TextField
          variant="standard"
          inputRef={inputRef}
          InputProps={{
            disableUnderline: true,
          }}
          onClick={handleClickInput}
          placeholder={i18n.t("your-message")}
          multiline
          onChange={handleEndCoachingMessageChange}
          className={classes.endCoachingMessage}
          value={endCoachingMessage}
        />

        {isCoachingAccount && (
          <div className={classes.accessDiv}>
            <Switch
              onChange={handleAccessChange}
              checked={!isForbidden}
              onColor={"#00A7FC"}
            />
            <Typography className={`${classes.accessExplanation}`}>
              {i18n.t("end-coaching-access-explanation")}
            </Typography>
          </div>
        )}

        <FlatButton
          requesting={requesting}
          onClick={handleEndCoachingClick}
          className={classes.button}
          title={
            isCoachingFinished
              ? i18n.t("relaunch-coaching")
              : i18n.t("end-coaching")
          }
        />
      </div>
    </Modal>
  );
};

export default EndCoachingModal;
