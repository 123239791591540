import { select, call, put, takeLatest, all, delay } from "redux-saga/effects";

import {
  MY_USERS_GET_LIST_ERROR,
  MY_USERS_GET_LIST_REQUESTING,
  MY_USERS_GET_LIST_SUCCESS,
  MY_USERS_LOAD_MORE_ERROR,
  MY_USERS_LOAD_MORE_REQUESTING,
  MY_USERS_LOAD_MORE_SUCCESS,
  MY_USERS_MODIFY_SUCCESS,
  MY_USERS_MODIFY_ERROR,
  MY_USERS_MODIFY_REQUESTING,
  MY_USERS_DELETE_REQUESTING,
  MY_USERS_DELETE_SUCCESS,
  MY_USERS_DELETE_ERROR,
  MY_USERS_GET_ERROR,
  MY_USERS_GET_REQUESTING,
  MY_USERS_GET_SUCCESS,
  MY_USERS_UPDATE_ROLE_REQUESTING,
} from "./constants";

import { fetchApi } from "../api";
import { openSnackMessage } from "../general/actions";
import i18n from "../../i18n";
import { getAllMyClientsRequest } from "../myClients/actions";
import {
  getUserClientId,
  getUserFirstName,
  getUserLastName,
} from "../user/selectors";
import { UPDATE_COACHEE } from "services/myCoachees/constants";
import { MANAGER_MODIFY_USER_SELECTED } from "services/manager/constants";
import { getAppLanguage } from "services/general/selectors";

const apiEndpoints = {
  getMyUsersList: "/v1/users/list/all",
  getUser: "/v1/users", // :userId
  modifyUser: "/v1/users", // userId
  modifyUserRole: "/v1/users/role/update",
  deleteUser: "/v1/users", // userId
  generateDynamicAuthLink: "/v1/users/dynamic-auth-link/generate",
};

const getUserApi = data => {
  const { userId } = data;
  return fetchApi(`${apiEndpoints.getUser}/${userId}`, data, "get");
};

const modifyUserApi = data => {
  const { userId } = data;
  return fetchApi(`${apiEndpoints.modifyUser}/${userId}`, data, "patch");
};

const modifyUserRoleApi = data => {
  return fetchApi(`${apiEndpoints.modifyUserRole}`, data, "patch");
};

/*const generateDynamicAuthLinkApi = data => {
  return fetchApi(`${apiEndpoints.generateDynamicAuthLink}`, data, "post");
};*/

const deleteUserApi = data => {
  const { userId } = data;
  return fetchApi(`${apiEndpoints.deleteUser}/${userId}`, data, "delete");
};

const getMyUsersListApi = data => {
  const { clientId, page, limit } = data;
  return fetchApi(
    `${apiEndpoints.getMyUsersList}?clientId=${clientId}&page=${page}&limit=${limit}`,
    {},
    "get",
  );
};

function* getMyUserFlow(action) {
  try {
    const result = yield call(getUserApi, action.payload);
    yield put({ type: MY_USERS_GET_SUCCESS, payload: result });
  } catch (error) {
    console.log(error);
    yield put({ type: MY_USERS_GET_ERROR, payload: error });
  }
}

function* modifyMyUserFlow(action) {
  try {
    const result = yield call(modifyUserApi, action.payload);
    yield put({ type: MY_USERS_MODIFY_SUCCESS, payload: result });
    yield put({ type: UPDATE_COACHEE, payload: result });
    yield put({ type: MANAGER_MODIFY_USER_SELECTED, payload: result });
    yield put(
      openSnackMessage({ snackMessage: i18n.t("user-modified-success") }),
    );
  } catch (error) {
    yield put({ type: MY_USERS_MODIFY_ERROR, payload: error });
  }
}

function* modifyMyUserRoleFlow(action) {
  try {
    const firstName = yield select(getUserFirstName);
    const lastName = yield select(getUserLastName);
    const language = yield select(getAppLanguage);
    const result = yield call(modifyUserRoleApi, {
      ...action.payload,
      firstName,
      lastName,
      language,
    });
    yield put({ type: MY_USERS_MODIFY_SUCCESS, payload: result });
    yield put(
      openSnackMessage({ snackMessage: i18n.t("user-modified-success") }),
    );
  } catch (error) {
    yield put({ type: MY_USERS_MODIFY_ERROR, payload: error });
  }
}

function* deleteMyUserFlow(action) {
  try {
    const result = yield call(deleteUserApi, action.payload);
    yield put({ type: MY_USERS_DELETE_SUCCESS, payload: result });
    const clientId = yield select(getUserClientId);
    yield put(getAllMyClientsRequest(clientId));
    yield put(
      openSnackMessage({ snackMessage: i18n.t("user-deleted-success") }),
    );
  } catch (error) {
    console.log(error);
    yield put({ type: MY_USERS_DELETE_ERROR, payload: error });
  }
}

function* getUsersListFlow(action) {
  try {
    const result = yield call(getMyUsersListApi, action.payload);
    yield put({ type: MY_USERS_GET_LIST_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: MY_USERS_GET_LIST_ERROR, payload: error });
  }
}

function* loadMoreUsersListFlow(action) {
  try {
    const result = yield call(getMyUsersListApi, action.payload);
    yield delay(1000);
    yield put({ type: MY_USERS_LOAD_MORE_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: MY_USERS_LOAD_MORE_ERROR, payload: error });
  }
}

/*function* generateDynamicAuthLinkFlow(action) {
  try {
    const result = yield call(generateDynamicAuthLinkApi, action.payload);
    yield delay(1000);
    yield put({ type: MY_USERS_LOAD_MORE_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: MY_USERS_LOAD_MORE_ERROR, payload: error });
  }
}*/

function* Sagas() {
  yield all([
    takeLatest(MY_USERS_LOAD_MORE_REQUESTING, loadMoreUsersListFlow),
    takeLatest(MY_USERS_GET_LIST_REQUESTING, getUsersListFlow),
    takeLatest(MY_USERS_MODIFY_REQUESTING, modifyMyUserFlow),
    takeLatest(MY_USERS_DELETE_REQUESTING, deleteMyUserFlow),
    takeLatest(MY_USERS_GET_REQUESTING, getMyUserFlow),
    takeLatest(MY_USERS_UPDATE_ROLE_REQUESTING, modifyMyUserRoleFlow),
  ]);
}

export default Sagas;
