import React from "react";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import FlatButton from "components/FlatButton";
import connectedPhoneImage from "resources/phoneConnected.svg";
import i18n from "i18n";
import { useSelector } from "react-redux";
import { getUserEmail } from "services/user/selectors";

const CIRCLE_SIZE = 10;

const useStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  picture: {
    width: "70%",
    marginTop: 35,
    marginBottom: 50,
  },
  topDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  circle: {
    backgroundColor: "#75D67E",
    height: CIRCLE_SIZE,
    width: CIRCLE_SIZE,
    borderRadius: CIRCLE_SIZE / 2,
    marginRight: 10,
  },
  connectionAppDiv: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  connectionAppText: {
    wordBreak: "break-word",
    color: "black",
    width: 200,
    marginLeft: 25,
    marginRight: 25,
    fontSize: 14,
    textAlign: "center",
  },
  connectedText: {
    fontSize: "1.1rem",
  },
  text: {
    wordBreak: "break-word",
    color: "black",
    width: 270,
    marginBottom: 30,
    fontSize: "1.1rem",
    lineHeight: "150%",
    textAlign: "center",
  },
  connectionAppButton: {
    height: 50,
    fontWeight: "normal",
    width: 275,
    // width: "100%",
  },
  email: {
    marginTop: 20,
    color: "#8F8F8F",
  },
}));

interface Props {
  onClickConnectMobile: () => void;
}

const ConnectedLivePreview = ({ onClickConnectMobile }: Props) => {
  const classes = useStyles();
  const email = useSelector(getUserEmail);

  return (
    <div className={classes.main}>
      <div className={classes.topDiv}>
        <div className={classes.circle} />
        <Typography className={classes.connectedText}>
          {i18n.t("connected")}
        </Typography>
      </div>
      <img
        src={connectedPhoneImage}
        alt={`Phone connection`}
        className={classes.picture}
      />
      <Typography className={classes.text}>
        {i18n.t("click-to-preview")}
      </Typography>
      <FlatButton
        onClick={onClickConnectMobile}
        title={i18n.t("visualize")}
        className={classes.connectionAppButton}
      />
      <Typography className={classes.email}>{email}</Typography>
    </div>
  );
};

export default ConnectedLivePreview;
