import React from "react";
import { TextField, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import i18n from "i18n";
import {
  getSelectedQuestion,
  getSurveyLanguage,
} from "services/survey/selectors";
import { useDispatch, useSelector } from "react-redux";
import { changeQuestionSelected } from "services/survey/actions";

const useStyles = makeStyles(theme => ({
  main: {
    width: "100%",
    height: "100%",
    flexDirection: "column",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingTop: 5,
  },
  title: {
    marginTop: 20,
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 18,
  },
}));

interface Props {
  isDisabled: boolean;
}

const CreateFreeAnswer = ({ isDisabled }: Props) => {
  const selectedQuestion = useSelector(getSelectedQuestion);
  const {
    questionTitle = {
      questionTitle: { fr: "", en: "" },
    },
  } = selectedQuestion;

  const languageSelected = useSelector(getSurveyLanguage);
  const dispatch = useDispatch();

  const classes = useStyles();

  const onChangeParam = param => e => {
    const { value } = e.target;
    const newParam = {
      ...selectedQuestion[param],
      [languageSelected]: value,
    };
    dispatch(changeQuestionSelected({ [param]: newParam }));
  };

  return (
    <div className={classes.main}>
      <Typography className={classes.title}>
        {i18n.t("question-title", { lng: languageSelected })}
      </Typography>
      <TextField
        variant="outlined"
        InputProps={
          {
            // disableUnderline: true,
          }
        }
        disabled={isDisabled}
        autoFocus
        fullWidth
        title={i18n.t("question-title", {
          lng: languageSelected,
        })}
        placeholder={i18n.t("question-title", {
          lng: languageSelected,
        })}
        value={
          questionTitle && questionTitle[languageSelected]
            ? questionTitle[languageSelected]
            : ""
        }
        onChange={onChangeParam("questionTitle")}
        margin="normal"
      />
    </div>
  );
};

export default CreateFreeAnswer;
