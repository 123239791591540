import React, { useState } from "react";
import withStyles from "@mui/styles/withStyles";
import { connect, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import SeeGridIcon from "scenes/CreateTrack/FolderList/SeeGridIcon";
import CreateFolder from "scenes/CreateTrack/FolderList/CreateFolder";
import AddFolderIcon from "scenes/CreateTrack/FolderList/AddFolderIcon";
import { Typography } from "@mui/material";
import i18n from "i18n";
import Colors from "constants/Colors";
import { updateFolderListRequest } from "services/createTrack/actions";
import FolderRow from "scenes/CreateTrack/FolderList/FolderRow";
import CloseIcon from "components/SelectInput/CloseIcon";
import { getIsModifyForbidden } from "services/user/selectors";

const styles = theme => ({
  main: {
    marginTop: 10,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  fullDiv: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  folderRow: {
    marginBottom: 10,
    height: 70,
    flex: 1,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  settings: {
    marginRight: 5,
    alignSelf: "flex-end",
  },
  pivot: {
    marginRight: 8,
    transform: "rotate(180deg)",
  },
  createFolder: {
    marginLeft: 12,
  },
  newFolder: {
    marginLeft: 7,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    cursor: "pointer",
    transition: "all 0.2s ease",
    padding: "4px 8px",
    "&:hover": {
      backgroundColor: Colors.hoverBlue,
    },
  },
  folder: {
    height: 20,
    width: 20,
  },
  newFolderText: {
    color: "#707781",
    marginLeft: 8,
  },
  closeFolderDiv: {
    width: "100%",
    cursor: "pointer",
    transition: "all 0.2s ease",
    padding: "4px 8px",
    "&:hover": {
      backgroundColor: Colors.hoverBlue,
    },
    marginLeft: 7,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    alignSelf: "flex-start",
  },
});

const FolderSettings = ({
  classes,
  folders,
  onClickFolder,
  folderSelected,
  onClickSeeGrid,
  folderList = { folders: [] },
  updateFolderListRequest,
}) => {
  const [isCreating, setCreating] = useState(false);
  const isModifyForbidden = useSelector(getIsModifyForbidden);

  const onClickDelete = folder => () => {
    console.log("Settings");

    const newFolders = folders.filter(el => el.value !== folder.value);
    updateFolderListRequest({
      folderListId: folderList._id,
      folders: newFolders,
    });
    // unselect it
    if (folderSelected.value === folder.value) {
      onClickFolder(folder)();
    }
  };

  return (
    <div className={classes.main}>
      <div
        className={classes.closeFolderDiv}
        onClick={() => onClickFolder(folderSelected)()}
      >
        <IconButton className={classes.closeIcon} size="large">
          <CloseIcon color={"#707781"} />
        </IconButton>
        <Typography className={classes.closeFolder}>
          {i18n.t("close-folder")}
        </Typography>
      </div>
      <div className={`${classes.folderRow} ${classes.createFolder}`}>
        {!isModifyForbidden && (
          <div className={classes.fullDiv}>
            {isCreating ? (
              <CreateFolder
                selectFolder={onClickFolder}
                setCreating={setCreating}
              />
            ) : (
              <div
                className={classes.newFolder}
                onClick={() => setCreating(true)}
              >
                <AddFolderIcon className={classes.folder} color={"#707781"} />
                <Typography className={classes.newFolderText}>
                  {i18n.t("create-new-folder")}
                </Typography>
              </div>
            )}
          </div>
        )}
        <IconButton
          className={`${classes.iconButton} ${classes.pivot}`}
          onClick={onClickSeeGrid}
          size="large"
        >
          <SeeGridIcon />
        </IconButton>
      </div>
      {folders.map((folder, index) => (
        <FolderRow
          key={index}
          folder={folder}
          onClickDelete={onClickDelete(folder)}
          onClickFolder={onClickFolder(folder)}
          folderSelected={folderSelected}
        />
      ))}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    folderList: state.createTrack.folderList,
  };
};

export default connect(mapStateToProps, { updateFolderListRequest })(
  withStyles(styles)(FolderSettings),
);
