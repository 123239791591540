import React from "react";
import withStyles from '@mui/styles/withStyles';
import i18n from "i18n";
import { Typography } from "@mui/material";

const styles = theme => ({
  main: {
    marginTop: 20,
    fontSize: 18,
    fontFamily: "Montserrat",
    fontWeight: "bold",
  },
  textInfo: {
    fontWeight: "normal",
  },
});

const Title = ({ classes, text, optional = null, languageSelected }) => {
  return (
    <Typography className={classes.main}>
      {text}
      {optional && (
        <span className={classes.textInfo}>
          {i18n.t("optional", { lng: languageSelected })}
        </span>
      )}
    </Typography>
  );
};

export default withStyles(styles)(Title);
