import React, { useEffect, useState, useRef } from "react";
import IconButton from "@mui/material/IconButton";
import CreateActionTextStep from "scenes/CreateTrack/CreateActionTextStep";
import { Typography } from "@mui/material";
import i18n from "i18n";
import Feedback from "scenes/Recap/Feedback";
import AddIcon from "components/AddIcon";
import Colors from "constants/Colors";
import DeleteConfirmModal from "components/DeleteConfirmModal";
import DeleteIcon from "scenes/Campaigns/DeleteIcon";
import ExitButton from "components/ExitButton";
import { makeStyles } from "@mui/styles";
import FilesFeedback from "scenes/Recap/FilesFeedback";

const useStyles = makeStyles(theme => ({
  main: {
    opacity: 0,
    zIndex: -10,
    backgroundColor: "#fff",
    width: 0,
    height: "100%",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    boxShadow: "-9px 4px 12px rgba(197, 178, 178, 0.14);",
    transition: "all 0.350s ease",
    display: "flex",
    flexDirection: "column",
    overflow: "scroll",
    paddingLeft: 15,
    paddingBottom: 50,
  },
  visible: {
    position: "fixed",
    zIndex: 10,
    width: 550,
    opacity: 1,
    paddingLeft: 45,
    paddingRight: 45,
  },
  close: {
    zIndex: 50,
    position: "fixed",
    top: -200,
    right: -200,
    opacity: 0,
    marginTop: 15,
  },
  buttonVisible: {
    top: 20,
    right: 20,
    opacity: 1,
  },
  step: {
    paddingTop: 25,
    height: "100%",
    width: "100%",
    zIndex: 1,
  },
  comments: {
    paddingLeft: 40,
    marginTop: -50,
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    paddingBottom: 50,
    marginBottom: 25,
  },
  files: {
    paddingLeft: 40,
    marginTop: -50,
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    paddingBottom: 90,
    marginBottom: 25,
  },
  comment: {
    width: "66%",
  },
  title: {
    width: "100%",
    textAlign: "left",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    color: Colors.darkBlue,
    fontSize: 20,
    marginBottom: 10,
  },
  addButton: {
    width: "fit-content",
    cursor: "pointer",
    zIndex: 10,
    padding: "7px 15px",
    backgroundColor: Colors.pureMain,
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    transition: "all .15s ease-in-out",
    "&:hover": {
      backgroundColor: Colors.darkBlue,
      transform: "scale(1.025)",
    },
  },
  buttonText: {
    fontSize: 16,
    marginLeft: 8,
    color: "white",
  },
  buttons: {
    marginTop: 25,
    marginLeft: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  delete: {
    marginLeft: 10,
    backgroundColor: "#fff",
    color: Colors.red,
    border: "solid",
    borderWidth: 1,
    borderColor: "#fff",
    fontSize: 13,
    "&:hover": {
      backgroundColor: "#fff",
      borderWidth: 1,
      borderColor: Colors.red,
    },
    "&:focus": {
      backgroundColor: "#fff",
      borderColor: Colors.red,
      borderWidth: 1,
    },
  },
}));

interface IPreviewSideBar {
  onClose?: () => void;
  isVisible: boolean;
  comments: any[];
  stepPreview: { isPrivate: boolean; content: { title: {}; how: {}; why: {} } };
  stepPreviewIndex: number;
  onClickAdd?: () => {};
  onClickDelete?: (step) => {};
  deleteStepRequest?: () => {};
  isStepPreviewEditable: boolean;
  addButtonText?: string;
  files: any[];
}

const PreviewBlock = ({
  onClose = null,
  isVisible = true,
  comments = [],
  stepPreview = { isPrivate: true, content: { title: {}, how: {}, why: {} } },
  stepPreviewIndex = 0,
  onClickAdd,
  onClickDelete,
  deleteStepRequest = null,
  isStepPreviewEditable = false,
  addButtonText = "",
  files = [],
}: IPreviewSideBar) => {
  const { isPrivate = true } = stepPreview;
  const [isDeleteModalOpen, openDeleteModal] = useState(false);
  const refMain = useRef(null);
  const closeDeleteModal = () => openDeleteModal(false);
  const clickDeleteButton = () => openDeleteModal(true);
  const onClickConfirmDelete = () => {
    if (onClickDelete) {
      onClickDelete(stepPreview);
    }
    closeDeleteModal();
  };

  useEffect(() => {
    if (refMain) {
      refMain.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [stepPreview]);

  const classes = useStyles();

  return (
    <div className={`${classes.main} ${isVisible && classes.visible}`}>
      <DeleteConfirmModal
        onClose={closeDeleteModal}
        isVisible={isDeleteModalOpen}
        title={i18n.t("delete-confirmation-modal-title")}
        onClickConfirm={onClickConfirmDelete}
      />
      <ExitButton
        onClick={onClose}
        className={`${classes.close} ${isVisible && classes.buttonVisible}`}
      />

      <div className={classes.step} ref={refMain}>
        <div className={classes.buttons}>
          {onClickAdd && (
            <div className={classes.addButton} onClick={onClickAdd}>
              <AddIcon sizePercentage={0.6} />
              <Typography className={classes.buttonText}>
                {!!addButtonText
                  ? addButtonText
                  : isStepPreviewEditable
                  ? i18n.t("create")
                  : i18n.t("add-challenge")}
              </Typography>
            </div>
          )}
          {onClickDelete && isPrivate && (
            <IconButton
              className={classes.delete}
              onClick={clickDeleteButton}
              size="large"
            >
              <DeleteIcon color={Colors.blackGrey} />
            </IconButton>
          )}
        </div>
        {(stepPreview.content || isStepPreviewEditable) && (
          <CreateActionTextStep
            isStepPreviewEditable={isStepPreviewEditable}
            stepPreview={stepPreview}
            stepPreviewIndex={stepPreviewIndex}
          />
        )}
        <div className={classes.comments}>
          {comments.length > 0 && (
            <Typography className={classes.title}>
              {i18n.t("Comments")}
            </Typography>
          )}
          {comments.map((el, index) => (
            <Feedback key={index} comment={el} className={classes.comment} />
          ))}
        </div>
        <div className={classes.files}>
          {files.length > 0 && (
            <Typography className={classes.title}>{i18n.t("Files")}</Typography>
          )}
          {files.map((el, index) => (
            <FilesFeedback key={index} file={el} className={classes.comment} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PreviewBlock;
