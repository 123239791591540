// First, we need to import the FlatList and other React Native component
import "intersection-observer";
import React, { Component } from "react";
// We also need to add the connectInfiniteHits connector to our import
import { connectInfiniteHits } from "react-instantsearch-dom";
import { connect } from "react-redux";
import withStyles from '@mui/styles/withStyles';
import Colors from "../../constants/Colors";
import Typography from "@mui/material/Typography";
import { Avatar } from "@mui/material";
import { motion } from "framer-motion";
import "moment/locale/fr";
import i18n from "../../i18n";
import { getAppLanguage } from "../../services/general/selectors";
import { getUserId } from "../../services/user/selectors";

const variants = {
  visible: i => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.1,
    },
  }),
  hidden: { opacity: 0, y: 30 },
};

const styles = theme => ({
  list: {
    // flexDirection: "column",
    maxHeight: 600,
    height: "100%",
    overflowX: "hidden",
    minWidth: "100%",
    marginTop: 20,
  },
  name: {
    color: Colors.text,
  },
  widthDiv: {
    width: "100%",
  },
  title: {
    color: "black",
    backgroundColor: "white",
    border: "1px solid rgba(0,0,0,.09)",
    boxShadow: "0 2px 0 0 rgba(0,0,0,.03)",
    borderRadius: 5,
    padding: 10,
    fontWeight: 500,
    // marginBottom: 10,
    marginBottom: 20,
  },
  firstSection: {
    marginTop: 0,
    margin: "12px 0 0",
    padding: "35px 30px 0",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    overflow: "auto",
    justifyContent: "space-around",
    alignItems: "flex-start",
    marginBottom: 15,
  },
  evolutionTitle: {
    width: "100%",
    color: Colors.black,
    textAlign: "left",
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.5,
    marginBottom: 16,
  },
  whiteBackground: {
    backgroundColor: Colors.white,
  },
  rankingSection: {
    paddingTop: 10,
  },
  firstTitle: {
    alignSelf: "center",
    marginTop: 15,
    marginBottom: 15,
  },
  userRow: {
    cursor: "pointer",
    // boxShadow: "0 2px 0 0 rgba(0,0,0,.03)",
    // border: "1px solid rgba(0,0,0,.09)",
    width: "100%",
    backgroundColor: "#fff",
    marginBottom: 10,
    borderRadius: 8,
    padding: 15,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
    transition: "all .2s ease",
    "&:hover": {
      background: "#F5F8FA",
    },
  },
  userName: {
    marginLeft: 15,
  },
  subTitle: {
    fontWeight: 500,
    marginLeft: 10,
  },
  stat: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: 1,
  },
  progressSection: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  statDiv: {
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    border: "1px solid rgba(0,0,0,.09)",
    // boxShadow: "0 2px 0 0 rgba(0,0,0,.03)",
    borderRadius: 5,
    padding: "12px 15px",
    fontWeight: 500,
    // marginBottom: 10,
    marginBottom: 20,
  },
  biggerRow: {
    display: "flex",
    flex: 1.5,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  firstRow: {
    display: "flex",
    flex: 1.5,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  row: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  tableHeaderRow: {
    width: "100%",
    // backgroundColor: "#fff",
    marginBottom: 10,
    borderRadius: 8,
    padding: 10,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  tableHeader: {
    fontSize: 15,
    fontWeight: 500,
    color: "black",
    // color: "#fff"
  },
  avatar: {
    objectFit: "cover",
    width: 30,
    height: 30,
    borderRadius: 15,
  },
  date: {
    marginRight: 10,
    overflowWrap: "break-word",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    "text-overflow": "ellipsis",
  },
  peopleTitle: {
    marginBottom: 10,
    marginLeft: 5,
    fontSize: 15,
    textTransform: "upppercase",
    color: "rgba(0, 0, 0, .34)",
  },
});

class UserChatHits extends Component {
  onSentinelIntersection = entries => {
    const { hasMore, refine } = this.props;

    entries.forEach(entry => {
      if (entry.isIntersecting && hasMore) {
        // if (hasMore) {
        refine();
      }
    });
  };

  sentinel = null;

  componentDidMount() {
    this.observer = new IntersectionObserver(this.onSentinelIntersection);
    this.observer.observe(this.sentinel);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  render() {
    const { hits, classes, onClickUser } = this.props;
    // return null;
    return (
      <div className={classes.list}>
        <Typography className={classes.peopleTitle}>
          {i18n.t("message-people-list")}
        </Typography>
        {hits
          .filter(el => el._id !== this.props.userId)
          .map((item, index) => {
            const { firstName, lastName, picture, _id } = item;
            return (
              <motion.div
                key={_id}
                // onClick={onClickUser(item)}
                onClick={onClickUser(item)}
                className={classes.userRow}
                custom={index}
                initial="hidden"
                animate="visible"
                variants={variants}
              >
                <div className={classes.firstRow}>
                  <Avatar
                    style={{ objectFit: "cover" }}
                    src={picture}
                    className={classes.avatar}
                    classes={{ img: classes.avatar }}
                  />
                  <Typography
                    className={classes.userName}
                  >{`${firstName} ${lastName}`}</Typography>
                </div>
              </motion.div>
            );
          })}
        <div
          className="ais-InfiniteHits-sentinel"
          ref={c => (this.sentinel = c)}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const language = getAppLanguage(state);
  const { role } = state.user;
  const { plan } = state.client;
  const isSuperAdmin = role === "superAdmin";
  const userId = getUserId(state);
  return {
    userId,
    language,
    plan,
    role,
    isSuperAdmin,
  };
};

export default connect(
  mapStateToProps,
  {},
)(connectInfiniteHits(withStyles(styles)(UserChatHits)));
