import React, { Component } from "react";
import ReactFlagsSelect from "react-flags-select";

//import css module
import "react-flags-select/css/react-flags-select.css";
// import "./style.css";

class FlagLanguage extends Component {
  componentDidMount() {
    this.updateFlag(this.props.language);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.language !== this.props.language) {
      this.updateFlag(this.props.language);
    }
  }

  updateFlag = language => {
    if (language === "fr") {
      this.refs.userFlag.updateSelected("FR");
    } else if (language === "en") {
      this.refs.userFlag.updateSelected("GB");
    }
  };

  render() {
    const { language, onSelect, className } = this.props;
    return (
      <ReactFlagsSelect
        ref="userFlag"
        defaultCountry="FR"
        countries={["FR", "GB"]}
        className={className}
        // showSelectedLabel={false}
        // showOptionLabel={false}
        onSelect={onSelect}
        language={language}
        customLabels={{ GB: "English", FR: "Français" }}
      />
    );
  }
}

export default FlagLanguage;
