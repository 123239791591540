import React from "react";
import withStyles from '@mui/styles/withStyles';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Colors from "constants/Colors";

const styles = theme => ({
  root: {
    // backgroundColor: theme.palette.background.paper
    width: "100%",
    display: "flex",
    // alignItems: "flex-start",
    // justifyContent: "flex-start",
    flexDirection: "column",
  },
  tabsRoot: {
    width: "100%",
    borderBottom: "1px solid #e8e8e8",
  },
  tabsIndicator: {
    backgroundColor: Colors.pureMain,
    color: Colors.pureMain,
  },
  tabRoot: {
    textTransform: "initial",
    minWidth: 72,
    fontSize: 16,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing.unit * 4,
    fontFamily: ["Montserrat"].join(","),
    "&:hover": {
      color: Colors.pureMain,
      opacity: 1,
    },
    "&:tabSelected": {
      color: Colors.pureMain,
      fontWeight: "bold",
    },
    "&:focus": {
      color: Colors.pureMain,
    },
  },
  tabSelected: {
    fontWeight: "bold",
    color: Colors.pureMain,
  },
  typography: {
    padding: theme.spacing.unit * 3,
  },
  notif: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: Colors.pink,
    position: "absolute",
    top: 0,
    right: -15,
    opacity: 1,
    zIndex: 10,
  },
  button: {
    cursor: "pointer",
    color: "white",
    position: "absolute",
    right: 0,
    bottom: 0,
    borderRadius: 4,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    padding: "10px 14px 10px 14px",
    backgroundColor: Colors.pureMain,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 0.2s",
    "&:hover": {
      opacity: 0.8,
    },
  },
  buttonTitle: {
    color: "inherit",
    marginLeft: 10,
  },
});

const MyTabs = ({
  classes,
  selectTab,
  className = "",
  tabs = [],
  tabSelected = 0,
  variant = "fullWidth",
  centered = true,
}) => {
  const handleChange = (event, value) => {
    selectTab(value);
  };

  return (
    <div className={`${classes.root} ${className}`}>
      <Tabs
        centered={centered}
        variant={variant}
        // variant="contained"
        value={tabSelected}
        onChange={handleChange}
        classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
      >
        {tabs.map(el => (
          <Tab
            key={el}
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label={el}
          />
        ))}
      </Tabs>
    </div>
  );
};

MyTabs.propTypes = {};

export default withStyles(styles)(MyTabs);
