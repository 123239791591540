import React, { useEffect, useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import FlatButton from "components/FlatButton";
import { LightButton } from "components/LightButton";
import i18n from "i18n";
import { makeStyles } from "@mui/styles";
import Colors from "constants/Colors";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment, { Moment } from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { IEvents } from "@microdoing/shared/services/userCampaign/types";
import selectLanguage from "utils/selectLanguage";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignSurveys } from "services/survey/selectors";
import { updateEvent } from "services/campaign/actions";
import isValidHttpUrl from "utils/isValidHttpUrl";

const RemoveIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8245 10.9203L6.75262 5.84845L5.30353 7.29755L10.3754 12.3694L5.30353 17.4412L6.75262 18.8903L11.8245 13.8185L16.8963 18.8903L18.3454 17.4412L13.2736 12.3694L18.3454 7.29755L16.8963 5.84845L11.8245 10.9203Z"
      fill="#9E9E9E"
    />
  </svg>
);

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: 650,
  },
  line: {
    height: 1,
    backgroundColor: "#D8D8D8",
    width: "100%",
  },
  subtitleModal: {
    textAlign: "center",
    color: "#19212A",
    fontSize: 20,
  },
  buttons: {
    marginBottom: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  lightButton: {
    paddingLeft: 30,
    paddingRight: 30,
    borderColor: "#DFDFDF",
    borderRadius: 4,
    color: "#19212A",
    marginRight: 15,
    "&:hover": {
      borderColor: "#DFDFDF",
      backgroundColor: Colors.veryLightGrey,
      opacity: 0.9,
    },
  },
  modifyButton: {
    paddingLeft: 40,
    paddingRight: 40,
    backgroundColor: "#FF6565",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: "#FF6565",
      opacity: 0.9,
    },
  },
  removeButton: {
    position: "absolute",
    top: 7,
    right: 7,
  },
  eventName: {
    marginTop: 0,
    marginBottom: 24,
    flex: 1,
  },
  dateContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 24,
  },
  date: {
    maxWidth: 150,
    marginRight: 24,
  },
  time: {
    maxWidth: 75,
  },
  urlInput: {
    marginTop: 0,
    marginBottom: 24,
  },
  selectSurvey: {
    width: "100%",
    marginBottom: 24,
  },
  dialogContainer: {
    display: "flex",
    flexDirection: "column",
  },
}));

interface IEvent extends IEvents {
  _id: string;
  type: string;
}

interface IProps {
  event: IEvent;
  languageSelected: string;
  startDate: Moment;
  endDate: Moment;
  isVisible: boolean;
  onClose: () => void;
}

const DeleteConfirmModal = ({
  event,
  languageSelected,
  isVisible,
  startDate,
  endDate,
  onClose,
}: IProps) => {
  const [eventName, setEventName] = useState(null);
  const [eventDate, setEventDate] = useState(null);
  const [eventTime, setEventTime] = useState(null);
  const [url, setUrl] = useState(null);
  const [eventSurveySelected, setEventSurveySelected] = useState(null);
  const [errorDate, setErrorDate] = useState(false);

  const surveys = useSelector(getCampaignSurveys);

  const eventType = useMemo(() => {
    return event.type;
  }, [event.type]);

  const isStartDateBeforeToday = useMemo(() => {
    return startDate?.isBefore() || false;
  }, [startDate]);

  const isButtonDisable = useMemo(() => {
    return !(
      !!eventName &&
      eventDate?._isValid &&
      eventTime?._isValid &&
      !!eventType &&
      (!!isValidHttpUrl(url) || !!eventSurveySelected) &&
      !errorDate
    );
  }, [eventName, eventDate, eventTime, eventType, url, eventSurveySelected]);

  const dispatch = useDispatch();

  useEffect(() => {
    setEventName(event.title);
    setEventDate(moment(event.date));
    setEventTime(moment(event.date));
    if (!!event.data.url) {
      setUrl(event.data.url);
    }
    if (!!event.data.survey) {
      setEventSurveySelected(event.data.survey);
    }
  }, [event]);

  const handleChangeSelectedSurvey = (event: SelectChangeEvent) => {
    setEventSurveySelected(event.target.value);
  };

  const handleChangeName = event => {
    const value = event.target.value;
    setEventName(value);
  };

  const handleChangeUrl = event => {
    const value = event.target.value;
    setUrl(value);
  };

  const handleChangeDate = newDate => {
    setEventDate(newDate);
  };

  const formatDate = useMemo(() => {
    const date = eventDate;

    if (!!eventDate && !!eventTime) {
      date.set({
        hour: eventTime.get("hour"),
        minute: eventTime.get("minute"),
      });
    }

    return date;
  }, [eventDate, eventTime]);

  const resetAll = () => {
    setEventName(null);
    setEventDate(null);
    setEventTime(null);
    setUrl(null);
    setEventSurveySelected(null);
    setErrorDate(false);
  };

  const handleUpdateEvent = () => {
    dispatch(
      updateEvent({
        eventId: event._id,
        title: eventName,
        type: eventType,
        data: {
          url: url,
          survey: eventSurveySelected,
        },
        date: formatDate.toDate(),
      }),
    );
    handleClose();
    resetAll();
  };

  const handleClose = () => {
    resetAll();
    onClose();
  };

  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: `${classes.paper}` }}
      open={isVisible}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton
        className={classes.removeButton}
        onClick={handleClose}
        size="large"
      >
        {<RemoveIcon />}
      </IconButton>
      <DialogTitle id="alert-dialog-title" className={classes.subtitleModal}>
        {i18n.t("update-event-title")}
      </DialogTitle>
      <div className={classes.line} />
      <DialogContent className={classes.dialogContainer}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <TextField
            type="text"
            label={i18n.t("event-name-placeholder", {
              lng: languageSelected,
            })}
            className={classes.eventName}
            value={eventName}
            onChange={handleChangeName}
            margin="normal"
            variant="outlined"
          />
          <div className={classes.dateContainer}>
            <DatePicker
              label={i18n.t("event-date-placeholder", {
                lng: languageSelected,
              })}
              onError={(reason, value) => {
                if (reason !== null) {
                  setErrorDate(true);
                }
              }}
              onAccept={event => {
                setErrorDate(false);
              }}
              className={classes.date}
              disablePast
              maxDate={endDate}
              minDate={isStartDateBeforeToday ? moment() : startDate}
              showToolbar={false}
              orientation="portrait"
              views={["day"]}
              openTo="day"
              value={eventDate}
              onChange={handleChangeDate}
              renderInput={params => <TextField {...params} />}
            />
            <TimePicker
              label={i18n.t("event-time-placeholder", {
                lng: languageSelected,
              })}
              className={classes.time}
              showToolbar={true}
              ampm={false}
              disableOpenPicker
              ampmInClock
              orientation="portrait"
              value={eventTime}
              onChange={setEventTime}
              renderInput={params => <TextField {...params} />}
            />
          </div>
        </LocalizationProvider>
        {eventType === "visio" && (
          <TextField
            type="url"
            label={i18n.t("event-url-placeholder", {
              lng: languageSelected,
            })}
            className={classes.urlInput}
            value={url || ""}
            onChange={handleChangeUrl}
            margin="normal"
            variant="outlined"
          />
        )}
        {eventType === "survey" && (
          <FormControl className={classes.selectSurvey}>
            <InputLabel id="event-survey-selected-placeholder-id">
              {i18n.t("event-survey-selected-placeholder", {
                lng: languageSelected,
              })}
            </InputLabel>
            <Select
              MenuProps={{
                PaperProps: {
                  style: { maxHeight: 250 },
                },
              }}
              labelId="event-survey-selected-placeholder-id"
              id="event-survey-selected-placeholder"
              label={i18n.t("event-survey-selected-placeholder", {
                lng: languageSelected,
              })}
              value={eventSurveySelected || ""}
              onChange={handleChangeSelectedSurvey}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {surveys.map((survey, index) => {
                return (
                  <MenuItem key={index} value={survey._id}>
                    {selectLanguage({
                      text: survey.title,
                      language: languageSelected,
                    })}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
        <div className={classes.buttons}>
          <LightButton
            onClick={handleClose}
            className={classes.lightButton}
            title={i18n.t("Cancel")}
          />
          <FlatButton
            disabled={isButtonDisable}
            onClick={handleUpdateEvent}
            className={classes.modifyButton}
            title={i18n.t("modify")}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(DeleteConfirmModal);
