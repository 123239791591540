export const CLOSE_SNACK_MESSAGE = "general/CLOSE_SNACK_MESSAGE";
export const OPEN_SNACK_MESSAGE = "general/OPEN_SNACK_MESSAGE";
export const CHANGE_GENERAL_SETTINGS = "general/CHANGE_GENERAL_SETTINGS";

export const SEND_MOBILE_NAVIGATION_EVENT =
  "general/SEND_MOBILE_NAVIGATION_EVENT";

export const MY_MOBILE_CONNECTION = "general/MY_MOBILE_CONNECTION";

export const UPDATE_ADMIN_CONNECTION = "general/UPDATE_ADMIN_CONNECTION";
