import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import CheckBox from "components/CheckBox";
import { MainText } from "components/Texts/MainText";
import i18n from "i18n";
import { FilterParticipantsType } from "./types";

const useStyles = makeStyles({
  checkActionsDiv: {
    paddingTop: 33,
    paddingBottom: 33,
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "row",
  },
  checkButton: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    fontSize: 16,
    fontWeight: "normal",
    backgroundColor: "#FBFBFB",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
    marginRight: 15,
    color: "rgba(131,131,131,1)",
    borderRadius: 4,
    cursor: "pointer",
    "&:focus": {
      backgroundColor: "#FBFBFB",
    },
    "&:hover": {
      backgroundColor: "#FBFBFB",
      opacity: 0.8,
    },
  },
  removeSelection: {
    cursor: "pointer",
    fontSize: 16,
    fontWeight: "normal",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
    alignSelf: "center",
  },
});

export const filters: { [key: string]: FilterParticipantsType } = {
  allUser: "allUser",
  inactiveUser: "inactiveUser",
  activeUser: "activeUser",
  realisationCampaign: "realisationCampaign",
  participationCampaign: "participationCampaign",
  mixedUser: "mixedUser",
  cancelSelection: "cancelSelection",
};

export interface IFilterParticipantsProps {
  numberOfInactiveUser: number;
  numberOfActiveUser: number;
  numberOfRealisation: number;
  numberOfParticipation: number;
  selectedFilter: FilterParticipantsType;
  filterUser: (selectedFilter: FilterParticipantsType) => void;
}

export default function FilterParticipants({
  numberOfInactiveUser = 0,
  numberOfActiveUser = 0,
  numberOfRealisation = 0,
  numberOfParticipation = 0,
  selectedFilter = filters.allUser,
  filterUser = () => {},
}: IFilterParticipantsProps) {
  const classes = useStyles();

  return (
    <div className={classes.checkActionsDiv}>
      <div
        className={classes.checkButton}
        onClick={() => {
          if (selectedFilter === filters.allUser) {
            return filterUser(filters.mixedUser);
          }
          filterUser(filters.allUser);
        }}
      >
        <CheckBox
          size={14}
          isChecked={selectedFilter === filters.allUser}
          onCheck={() => {}}
        />
        <MainText
          text={i18n.t("all-participants", {
            number: numberOfActiveUser + numberOfInactiveUser,
          })}
          size="medium"
        />
      </div>
      <div
        className={classes.checkButton}
        onClick={() => {
          if (selectedFilter === filters.activeUser) {
            return filterUser(filters.mixedUser);
          }
          filterUser(filters.activeUser);
        }}
      >
        <CheckBox
          size={14}
          isChecked={selectedFilter === filters.activeUser}
          onCheck={() => {}}
        />
        <MainText
          text={i18n.t("active-participants", {
            number: numberOfActiveUser,
          })}
          size="medium"
        />
      </div>
      <div
        className={classes.checkButton}
        onClick={() => {
          if (selectedFilter === filters.inactiveUser) {
            return filterUser(filters.mixedUser);
          }
          filterUser(filters.inactiveUser);
        }}
      >
        <CheckBox
          size={14}
          isChecked={selectedFilter === filters.inactiveUser}
          onCheck={() => {}}
        />
        <MainText
          text={i18n.t("inactive-participants", {
            number: numberOfInactiveUser,
          })}
          size="medium"
        />
      </div>
      <div
        className={classes.checkButton}
        onClick={() => {
          if (selectedFilter === filters.realisationCampaign) {
            return filterUser(filters.mixedUser);
          }
          filterUser(filters.realisationCampaign);
        }}
      >
        <CheckBox
          size={14}
          isChecked={selectedFilter === filters.realisationCampaign}
          onCheck={() => {}}
        />
        <MainText
          text={i18n.t("campaign-realisation-participants", {
            number: numberOfRealisation,
          })}
          size="medium"
        />
      </div>
      <div
        className={classes.checkButton}
        onClick={() => {
          if (selectedFilter === filters.participationCampaign) {
            return filterUser(filters.mixedUser);
          }
          filterUser(filters.participationCampaign);
        }}
      >
        <CheckBox
          size={14}
          isChecked={selectedFilter === filters.participationCampaign}
          onCheck={() => {}}
        />
        <MainText
          text={i18n.t("campaign-participation-participants", {
            number: numberOfParticipation,
          })}
          size="medium"
        />
      </div>
    </div>
  );
}
