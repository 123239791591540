import React from "react";

const NotificationIcon = ({ sizePercentage, color, className }) => (
  <svg
    className={className}
    width={sizePercentage * 12}
    height={sizePercentage * 15}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 12 15"
    version="1.1"
  >
    <path
      d="M6.16534 14.7995C6.95467 14.7995 7.60048 14.1371 7.60048 13.3275H4.73021C4.73021 13.7179 4.88141 14.0923 5.15055 14.3683C5.41969 14.6444 5.78472 14.7995 6.16534 14.7995ZM10.4707 10.3837V6.70383C10.4707 4.44442 9.29393 2.55298 7.24169 2.05253V1.55207C7.24169 0.941217 6.76092 0.44812 6.16534 0.44812C5.56976 0.44812 5.08899 0.941217 5.08899 1.55207V2.05253C3.02957 2.55298 1.85994 4.43706 1.85994 6.70383V10.3837L0.424805 11.8556V12.5916H11.9059V11.8556L10.4707 10.3837Z"
      fill={color}
    />
  </svg>
);

NotificationIcon.defaultProps = {
  sizePercentage: 1,
  color: "#fff",
};

export default NotificationIcon;
