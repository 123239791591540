import React, { useMemo } from "react";
import withStyles from '@mui/styles/withStyles';
import StatBox from "./StatBox";
import Colors from "constants/Colors";
import i18n from "i18n";
import LightIcon from "scenes/CreateTrack/LightIcon";
import ParticipantIcon from "scenes/Campaigns/CampaignScreen/StatsSide/ParticipantIcon";
import CheckIcon from "components/CheckIcon";
import { connect } from "react-redux";
import {
  getMyCoachees,
  getTotalDoneSessions,
} from "services/myCoachees/selectors";

const styles = theme => ({
  main: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row",
    marginBottom: 40,
  },
});

const getCoachingHours = totalDoneSessions => {
  return ((totalDoneSessions * 90) / 60).toFixed(1);
};

const CoachingStats = ({ classes, myCoachees, totalDoneSessions }) => {
  const coachingHours = useMemo(() => getCoachingHours(totalDoneSessions), [
    totalDoneSessions,
  ]);

  return (
    <div className={classes.main}>
      <StatBox
        legend={i18n.t("Coachees")}
        stat={myCoachees.length}
        iconBackgroundColor={Colors.veryLightMain}
        icon={<ParticipantIcon color={Colors.pureMain} />}
      />
      <StatBox
        legend={i18n.t("Done-sessions")}
        stat={totalDoneSessions}
        iconBackgroundColor={Colors.lightOrange}
        icon={<LightIcon color={Colors.orange} sizePercentage={1.5} />}
      />
      <StatBox
        legend={i18n.t("coaching-hours")}
        stat={coachingHours}
        // iconBackgroundColor={Colors.veryLightGrey}
        iconBackgroundColor={"rgb(121, 234, 144, 0.25)"}
        icon={<CheckIcon color={Colors.green} sizePercentage={1.75} />}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    myCoachees: getMyCoachees(state),
    totalDoneSessions: getTotalDoneSessions(state),
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(CoachingStats));
