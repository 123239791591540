import React, { PureComponent } from "react";
import { connect } from "react-redux";

import withStyles from '@mui/styles/withStyles';
import Colors from "../../constants/Colors";
import LottieEmpty from "../Recap/LottieEmpty";
import { getSelectedClientId } from "../../services/user/selectors";

const styles = theme => ({
  main: {
    width: "90%",
    marginTop: 15,
    flexDirection: "row",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  root: {
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
    marginBottom: 15,
  },
  leftSide: {
    width: "45%",
    minHeight: 700,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    display: "flex",
  },
  rightSide: {
    width: "45%",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    display: "flex",
    position: "relative",
    minHeight: 800,
    // flex: 1
  },
  container: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: 15,
    flex: 1,
    flexDirection: "row",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  saveButton: {
    marginTop: 15,
  },
  title: {
    fontWeight: 500,
    marginTop: 15,
    marginBottom: 15,
  },
  appTitleDiv: {
    width: 280,
    backgroundColor: Colors.mediumGreen,
    padding: 15,
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: 8,
    wordWrap: "break-word",
    // wordWrap: "break-word"
    // maxWidth: 280
  },
  appText: {
    minWidth: "1%",
    textAlign: "center",
    wordWrap: "break-word",
  },
  separator: {
    height: 1,
    marginTop: 5,
    marginBottom: 10,
    width: "45%",
    backgroundColor: Colors.mediumGrey,
  },
  separatorContainer: {
    marginTop: 15,
    width: 280,
    display: "flex",
    flexWrap: "wrap",
    wordBreak: "break-all",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  content: {
    width: 280,
    marginTop: 80,
    wordWrap: "break-word",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
  },
  formControl: {
    marginTop: 16,
    marginBottom: 8,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  paper: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    width: "100%",
  },
  noData: {
    color: Colors.main,
    fontWeight: "bold",
  },
});

class NewPoll extends PureComponent {
  state = {
    expanded: null,
    labelWidth: 0,
  };

  componentDidMount() {}

  handleChange = name => event => {
    let value = event.target.value;
    // if (event.target.value) {
    //   value = value.charAt(0).toUpperCase() + value.slice(1);
    // }
    this.props.modifyNewPoll({
      [name]: value,
    });
  };

  onSubmit = () => {
    const { how, title, why, clientId, category } = this.props;
    this.props.createActionRequest({ how, title, why, clientId, category });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.main}>
        <div className={classes.paper}>
          <LottieEmpty />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    selectionList,
    allActions,
    requesting,
    tabSelected,
    title,
    how,
    why,
    category,
  } = state.action;
  const clientId = getSelectedClientId(state);
  return {
    token: state.auth.token,
    clientId,
    selectionList,
    allActions,
    requesting,
    tabSelected,
    title,
    how,
    why,
    category,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(NewPoll));
