import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import {
  changeField,
  onboardingEndRequest,
} from "../../services/signup/actions";
import { uploadPictureRequest } from "../../services/upload/actions";

import Typography from "@mui/material/Typography";
import withStyles from '@mui/styles/withStyles';
import TextField from "@mui/material/TextField";
import FlatButton from "../../components/FlatButton";

import Colors from "../../constants/Colors";
import i18n from "../../i18n";
import Uploader from "../../components/Uploader";
import slugify from "slugify";

const styles = theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "100vh",
    width: "100vw",
    backgroundColor: Colors.pureMain,
  },
  logo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: 75,
    // paddingTop: 0,
    margin: "20px auto 20px",
  },
  paper: {
    width: 470,
    display: "flex",
    borderRadius: 3,
    background: "white",
    padding: 40,
    paddingBottom: 20,
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 20,
  },
  title: {
    textAlign: "center",
    fontWeight: 300,
    color: "#444",
    margin: 0,
    marginTop: 0,
    fontSize: 30,
    lineHeight: "38px",
    textTransform: "none",
    letterSpacing: 0,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE11 issue.
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  loginError: {
    backgroundColor: "#FCE6E7",
    borderRadius: 8,
  },
  errorText: {
    color: "#e6525a",
    padding: "13px 15px",
    borderRadius: 6,
    backgroundColor: "rgba(230,82,90,0.15)",
  },
  textField: {
    marginTop: 20,
  },
  submitButton: {
    marginTop: 20,
    padding: 12,
  },
  noDecoration: {
    textDecoration: "none",
  },
  forgotPassword: {
    marginTop: 20,
    cursor: "pointer",
    textDecoration: "none",
    color: Colors.pureMain,
    fontSize: 17,
    lineHeight: 1.5,
    textAlign: "center",
  },
  newAccountText: {
    color: Colors.white,
    fontSize: 16,
    textAlign: "center",
  },
  bold: {
    marginLeft: 5,
    color: Colors.white,
    fontSize: 16,
    cursor: "pointer",
    fontWeight: "bold",
    textDecoration: "underline",
  },
  noAccountDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  agreeTerms: {
    fontSize: 13,
    marginTop: 22,
    marginBottom: 10,
  },
  link: {
    textDecoration: "none",
    color: Colors.pureMain,
    fontWeight: "bold",
  },
  stepDiv: {
    marginBottom: 25,
    marginTop: 10,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  step: {
    borderRadius: 30,
    height: 4,
    width: 40,
    marginLeft: 5,
    marginRight: 5,
    backgroundColor: Colors.lightMain,
  },
  stepActive: {
    backgroundColor: Colors.pureMain,
  },
  pictureText: {
    fontSize: 12,
    marginBottom: 20,
    marginTop: 20,
    lineHeight: "150.7%",
    color: "#707070",
    textAlign: "left",
  },
  upload: {
    marginBottom: 8,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  "@media (max-width: 355px)": {
    topField: {
      flexDirection: "column",
    },
    textFields: {
      paddingRight: 0,
    },
    upload: {
      marginBottom: 0,
      marginTop: 15,
      alignItems: "center",
      justifyContent: "center",
    },
  },
  topField: {
    display: "flex",
    flexDirection: "row",
  },
  textFields: {
    flex: 1,
    paddingRight: 20,
    display: "flex",
    flexDirection: "column",
  },
  splitTextField: {
    width: "100%",
  },
});

class CreateUserForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pictureUploading: false,
    };
    window.addEventListener("keydown", this.keyPress);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.keyPress);
  }

  componentDidUpdate = prevProps => {
    if (
      prevProps.uploadedPicture !== this.props.uploadedPicture &&
      this.state.pictureUploading
    ) {
      this.setState({ pictureUploading: false });
      this.props.changeField({ picture: this.props.uploadedPicture });
    }
  };

  onchangeSignupInput = field => event => {
    this.props.changeField({ [field]: event.target.value });
    // this.setState({ [field]: event.target.value });
  };

  onSubmit = () => {
    const { picture, firstName, lastName, userId } = this.props;
    this.props.onboardingEndRequest({
      picture,
      firstName,
      lastName,
      userId,
      hasCompleteOnboarding: false,
    });
  };

  keyPress = e => {
    if (e.keyCode === 13) {
      this.onSubmit();
      // put the login here
    }
  };

  onFileUploaded = async files => {
    const { userId } = this.props;

    const date = new Date().getTime();
    this.setState({ pictureUploading: true });
    await this.props.uploadPictureRequest({
      urlName: slugify(`profile-picture/${userId}/${date}`),
      file: files[0],
    });
  };

  render() {
    const { error, classes, requesting } = this.props;

    return (
      <React.Fragment>
        <div className={classes.form}>
          {error && <p className={classes.errorText}>{error}</p>}
          <div className={classes.topField}>
            <div className={classes.textFields}>
              <TextField
                id="outlined-multiline-flexible"
                label={i18n.t("firstName")}
                fullWidth
                autoFocus
                autoComplete="given-name"
                // rowsMax="4"
                value={this.props.firstName}
                onChange={this.onchangeSignupInput("firstName")}
                className={`${classes.textField} ${classes.splitTextField}`}
                margin="normal"
                variant="outlined"
              />
              <TextField
                id="outlined-multiline-flexible"
                label={i18n.t("lastName")}
                fullWidth
                autoComplete="family-name"
                value={this.props.lastName}
                onChange={this.onchangeSignupInput("lastName")}
                className={`${classes.textField} ${classes.splitTextField}`}
                margin="normal"
                variant="outlined"
              />
            </div>
            <div className={classes.upload}>
              <Uploader
                width={140}
                height={140}
                placeholder={i18n.t("import-picture")}
                picture={this.props.picture}
                className={classes.uploader}
                onFileUploaded={this.onFileUploaded}
                uploading={this.props.uploading}
                isSmall
              />
            </div>
          </div>
          <Typography className={classes.pictureText}>
            {i18n.t("import-picture-text")}
          </Typography>
          <FlatButton
            requesting={requesting}
            fullWidth
            disabled={!this.props.firstName || !this.props.lastName}
            title={i18n.t("continue")}
            onClick={this.onSubmit}
            className={classes.submitButton}
          />
          <Typography className={classes.agreeTerms}>
            {i18n.t("agree-terms-by-signing-up")}
            <a
              target="_blank"
              href="https://microdoing.com/conditions-generales-dutilisation-du-site"
              className={classes.link}
              rel="noopener noreferrer"
            >
              {i18n.t("terms-of-service")}
            </a>
            {i18n.t("and-the")}
            <a
              href="https://microdoing.com/privacy"
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {i18n.t("privacy-policy")}
            </a>
          </Typography>
        </div>
      </React.Fragment>
    );
  }
}

CreateUserForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const {
    step,
    email,
    error,
    requesting,
    picture,
    firstName,
    lastName,
  } = state.signup;
  const { uploading } = state.upload;
  return {
    email,
    step,
    firstName,
    picture,
    lastName,
    requesting,
    error,
    uploading,
    uploadedPicture: state.upload.picture,
  };
};

export default connect(mapStateToProps, {
  push,
  changeField,
  uploadPictureRequest,
  onboardingEndRequest,
})(withStyles(styles)(CreateUserForm));
