import React from "react";
import { LinearProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles({
  container: {
    flex: 1,
    marginRight: 3,
    marginLeft: 3,
  },
  colorPrimary: {
    backgroundColor: "#E4E3E3",
  },
  barColorPrimary: {
    backgroundColor: "#BED846",
  },
  root: {
    height: 6,
    borderRadius: 4,
  },
});

export interface ILinearProgressBarProps {
  percentage: number;
}

const LinearProgressBar = ({ percentage = 0 }: ILinearProgressBarProps) => {
  const classes = useStyle();

  return (
    <div className={classes.container}>
      <LinearProgress
        variant="determinate"
        value={percentage}
        classes={{
          colorPrimary: classes.colorPrimary,
          barColorPrimary: classes.barColorPrimary,
          root: classes.root,
        }}
      />
    </div>
  );
};

export default LinearProgressBar;
