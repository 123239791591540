import {
  MESSAGE_OPEN_CHAT,
  MESSAGE_GOT_NEW,
  MESSAGE_SET_RECEIVER,
  MESSAGE_CLEAN,
  MESSAGE_GET_LATEST_CONVERSATIONS_SUCCESS,
  MESSAGE_GET_LATEST_CONVERSATIONS_ERROR,
  MESSAGE_GET_LATEST_CONVERSATIONS_REQUEST,
  MESSAGE_SEE_CONVERSATION,
  GET_CHAT_MESSAGES_REQUESTING,
  GET_CHAT_MESSAGES_SUCCESS,
  GET_CHAT_MESSAGES_ERROR,
  MESSAGE_OPEN_CONVERSATION_REQUESTING,
  MESSAGE_OPEN_CONVERSATION_SUCCESS,
  MESSAGE_OPEN_CONVERSATION_ERROR,
  MESSAGE_SET_CONVERSATION,
  MESSAGE_GET_CLIENT_NAME_SUCCESS,
  MESSAGE_GET_CLIENT_NAME_ERROR,
  MESSAGE_GET_CLIENT_NAME_REQUESTING,
  MESSAGE_LIKE_REQUESTING,
  MESSAGE_LIKE_ERROR,
} from "./constants";
import { AUTH_LOGOUT_SUCCESS } from "../auth/constants";

const initialState = {
  messages: [
    //  {
    //   text: String,
    //   seen: { type: Boolean, default: false },
    //   conversationId: { type: mongoose.Schema.Types.ObjectId, ref: "Conversation" },
    //   createdAt: { type: Date, default: Date.now },
    //   user: {
    //     _id: { type: mongoose.Schema.Types.ObjectId, ref: "User" },
    //     name: String,
    //     avatar: String
    //   }
    // }
  ],
  messagePage: 1,
  loadingMessage: false,
  conversations: [
    // {
    //   name: 'Clement Dutartre',
    //   picture: 'https://s3.eu-west-3.amazonaws.com/liife-db/clem-pp',
    //   date: moment(),
    //   lastMessage: 'Holaaaaaaaaaaaaaaaaaaasd sdf sdf asdfa sfaasdf asf as'
    // },
    // {
    //   name: 'Clement Dutartre',
    //   picture: 'https://s3.eu-west-3.amazonaws.com/liife-db/clem-pp',
    //   date: moment(),
    //   lastMessage: 'Holaaaaaaaaaaaaaaaaaaasd sdf sdf asdfa sfaasdf asf as'
    // },
    // {
    //   name: 'Clement Dutartre',
    //   picture: 'https://s3.eu-west-3.amazonaws.com/liife-db/clem-pp',
    //   date: moment(),
    //   lastMessage: 'Holaaaaaaaaaaaaaaaaaaasd sdf sdf asdfa sfaasdf asf as'
    // }
  ],
  receiver: { _id: "", picture: "", name: "" },
  conversationId: "",
  error: "",
  chatRequesting: false,
  requesting: false,
  clientNameRequesting: false,
  userSelectedClientName: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MESSAGE_OPEN_CHAT: {
      const { priorMessages, conversationId } = action.payload;
      return { ...state, messages: priorMessages, conversationId };
    }

    case MESSAGE_CLEAN: {
      return { ...state, messages: [], messagePage: 1 };
    }

    case MESSAGE_SEE_CONVERSATION: {
      const newConversation = state.conversations.map((conv, index) => {
        if (conv._id === action.payload) {
          conv.latestMessage.seen = true;
        }
        return conv;
      });
      return {
        ...state,
        conversations: newConversation,
      };
    }

    case MESSAGE_GET_CLIENT_NAME_SUCCESS: {
      return {
        ...state,
        userSelectedClientName: action.payload,
        clientNameRequesting: false,
      };
    }

    case MESSAGE_GOT_NEW:
      return { ...state, messages: [action.payload, ...state.messages] };

    case MESSAGE_SET_RECEIVER:
      return { ...state, receiver: action.payload };

    case MESSAGE_GET_CLIENT_NAME_REQUESTING:
      return { ...state, clientNameRequesting: true };

    case MESSAGE_GET_LATEST_CONVERSATIONS_REQUEST:
      return { ...state, requesting: true };

    case MESSAGE_GET_LATEST_CONVERSATIONS_SUCCESS:
      return { ...state, requesting: false, conversations: action.payload };

    case MESSAGE_GET_CLIENT_NAME_ERROR:
      return { ...state, clientNameRequesting: false, error: action.payload };

    case MESSAGE_GET_LATEST_CONVERSATIONS_ERROR:
      return { ...state, requesting: false, error: action.payload };

    case GET_CHAT_MESSAGES_SUCCESS: {
      return {
        ...state,
        messages: [...state.messages, ...action.payload],
        messagePage: state.messagePage + 1,
        loadingMessage: false,
      };
    }

    case GET_CHAT_MESSAGES_REQUESTING:
      return { ...state, loadingMessage: true };

    case GET_CHAT_MESSAGES_ERROR:
      return {
        ...state,
        loadingMessage: false,
        error: action.payload,
      };

    case MESSAGE_OPEN_CONVERSATION_REQUESTING:
      return { ...state, messages: [], chatRequesting: true };

    case MESSAGE_OPEN_CONVERSATION_SUCCESS:
      return {
        ...state,
        chatRequesting: false,
        conversationId: action?.payload?.conversationId,
        messages: action?.payload?.priorMessages || [],
      };

    case MESSAGE_OPEN_CONVERSATION_ERROR:
      return { ...state, chatRequesting: false };

    case MESSAGE_SET_CONVERSATION:
      return {
        ...state,
        conversationId: action.payload,
      };

    case AUTH_LOGOUT_SUCCESS:
      return initialState;

    case MESSAGE_LIKE_REQUESTING: {
      const { messageId, userId } = action?.payload || {};
      const updatedMessages = state.messages.map(message => {
        if (message._id === messageId) {
          let updatedLikedBy;
          if (!message.likedBy || !message?.likedBy?.includes(userId)) {
            updatedLikedBy = [userId];
          } else {
            updatedLikedBy = message?.likedBy?.filter(id => id !== userId);
          }
          return {
            ...message,
            likedBy: updatedLikedBy,
          };
        }
        return message;
      });
      return {
        ...state,
        messages: updatedMessages,
      };
    }

    case MESSAGE_LIKE_ERROR: {
      const { messageId, userId } = action?.payload || {};
      const updatedMessages = state.messages.map(message => {
        if (message._id === messageId) {
          const updatedLikedBy = message?.likedBy?.filter(id => id !== userId);
          return {
            ...message,
            likedBy: updatedLikedBy,
          };
        }
        return message;
      });
      return {
        ...state,
        messages: updatedMessages,
      };
    }

    default:
      return state;
  }
};
