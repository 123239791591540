import React from "react";
import { ResponsiveLine } from "@nivo/line";

import Colors from "../../../constants/Colors";

const tooltipStyle = {
  zIndex: 999,
  display: "flex",
  flexDirection: "column",
  background: "white",
  color: "inherit",
  fontSize: "inherit",
  borderRadius: "2px",
  boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
  padding: "5px 9px",
};

const lineStyle = {
  height: 1,
  width: "100%",
  backgroundColor: Colors.main,
  borderRadius: 20,
  marginTop: 3,
  marginBottom: 6,
};

const squareStyle = {
  display: "block",
  width: "12px",
  height: "12px",
  background: "rgba(0, 170, 255, 0.9)",
  marginRight: "7px",
};

const bottomDiv = {
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
};

const CumulativeEvolutionChart = ({ data, color }) => (
  // make sure parent container have a defined height when using
  // responsive component, otherwise height will be 0 and
  // no chart will be rendered.
  // website examples showcase many properties,
  // you'll often use just a few of them.
  <ResponsiveLine
    height={400}
    margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
    padding={0.85}
    data={data}
    xScale={{ type: "point" }}
    yScale={{ type: "linear", stacked: false, min: "auto", max: "auto" }}
    curve="monotoneX"
    tooltip={arg => {
      const { data, serieColor } = arg.point;
      const { x, y } = data;
      return (
        <div style={tooltipStyle}>
          <strong>{x}</strong>
          <div style={lineStyle} />
          <div style={bottomDiv}>
            <span style={{ ...squareStyle, background: serieColor }} />
            <span>
              Actions : <strong>{y}</strong>
            </span>
          </div>
        </div>
      );
    }}
    theme={{
      tooltip: {
        container: {},
      },
    }}
    axisTop={null}
    axisRight={null}
    enableGridX={true}
    enableGridY={false}
    enableArea={true}
    areaOpacity={0.07}
    motionStiffness={90}
    motionDamping={15}
    axisBottom={{
      orient: "bottom",
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "",
      legendOffset: 36,
      legendPosition: "middle",
    }}
    axisLeft={{
      orient: "left",
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "",
      legendOffset: -40,
      legendPosition: "middle",
    }}
    colors={[color]}
    enableCrosshair={true}
    pointSize={10}
    pointBorderWidth={0}
    pointBorderColor={{ from: "serieColor" }}
    pointLabel="y"
    pointLabelYOffset={-12}
    useMesh={true}
    isInteractive={true}
    legends={[]}
  />
);

export default CumulativeEvolutionChart;
