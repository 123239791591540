import React from "react";

interface IProps {
  width?: number;
  height?: number;
}

const InteractionIcon = ({ width = 29, height = 30 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 4C16.1046 4 17 3.10457 17 2C17 0.895431 16.1046 0 15 0C13.8954 0 13 0.895431 13 2C13 3.10457 13.8954 4 15 4Z"
        fill="#4CBC30"
      />
      <path
        d="M15 5C12.8039 5 11.0392 6.77778 11 9H19C18.9608 6.77778 17.1961 5 15 5Z"
        fill="#4CBC30"
      />
      <path
        d="M15 25C16.1046 25 17 24.1046 17 23C17 21.8954 16.1046 21 15 21C13.8954 21 13 21.8954 13 23C13 24.1046 13.8954 25 15 25Z"
        fill="#4CBC30"
      />
      <path
        d="M15 26C12.8039 26 11.0392 27.7778 11 30H19C18.9608 27.7778 17.1961 26 15 26Z"
        fill="#4CBC30"
      />
      <path
        d="M25 14C26.1046 14 27 13.1046 27 12C27 10.8954 26.1046 10 25 10C23.8954 10 23 10.8954 23 12C23 13.1046 23.8954 14 25 14Z"
        fill="#4CBC30"
      />
      <path
        d="M25 15C22.8039 15 21.0392 16.7778 21 19H29C28.9216 16.7778 27.1569 15 25 15Z"
        fill="#4CBC30"
      />
      <path
        d="M4 14C5.10457 14 6 13.1046 6 12C6 10.8954 5.10457 10 4 10C2.89543 10 2 10.8954 2 12C2 13.1046 2.89543 14 4 14Z"
        fill="#4CBC30"
      />
      <path
        d="M4 15C1.80392 15 0.0392157 16.7778 0 19H8C7.96078 16.7778 6.19608 15 4 15Z"
        fill="#4CBC30"
      />
      <path
        d="M18.7931 24.5711L19.1379 25.5C21.5517 24.62 23.5714 22.9089 24.9507 20.8067L24.1133 20.2689C22.8818 22.2244 21.0099 23.74 18.7931 24.5711ZM11.2562 4.42889L10.9113 3.5C8.44828 4.38 6.37931 6.09111 5 8.24222L5.83744 8.78C7.11823 6.77556 9.03941 5.21111 11.2562 4.42889ZM24.1626 8.78L25 8.24222C23.6207 6.09111 21.5517 4.42889 19.1379 3.5L18.7931 4.42889C21.0099 5.26 22.8818 6.77556 24.1626 8.78ZM5.8867 20.2689L5.04926 20.8067C6.42857 22.9578 8.44828 24.62 10.9113 25.5L11.2562 24.5711C8.99015 23.74 7.11823 22.2244 5.8867 20.2689Z"
        fill="#A5DD97"
      />
    </svg>
  );
};

export default InteractionIcon;
