import React from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography } from "@mui/material";
import Colors from "../../constants/Colors";
import CountUp from "react-countup";

const styles = theme => ({
  main: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  bold: {
    fontWeight: 500,
    marginBottom: 10,
  },
  box: {
    webkitBoxShadow: "0px 0px 4px 1px #D8D8D8",
    mozBoxShadow: "0px 0px 4px 1px #D8D8D8",
    boxShadow: "0px 0px 4px 1px #D8D8D8",
    flex: 1,
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 15,
    flexDirection: "column",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  rating: {
    color: Colors.main,
    marginTop: 10,
    fontWeight: 500,
  },
  actionText: {
    // fontFamily: [
    //   "-apple-system",
    //   "BlinkMacSystemFont",
    //   '"Segoe UI"',
    //   "Roboto",
    //   '"Helvetica Neue"',
    //   "Arial",
    //   "sans-serif",
    //   '"Apple Color Emoji"',
    //   '"Segoe UI Emoji"',
    //   '"Segoe UI Symbol"'
    // ].join(","),
    color: Colors.text,
    textAlign: "center",
  },
  title: {
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
    color: Colors.text,
    textAlign: "center",
    lineHeight: 1.5,
    marginTop: 10,
    letterSpacing: "0.01071em",
    fontWeight: 500,
    // marginTop: 10
  },
  circle: {
    width: 100,
    height: 100,
    borderRadius: "50%",
    backgroundColor: Colors.lightBlue,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  evolution: {
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
    color: Colors.green,
    textAlign: "center",
    lineHeight: 1.5,
    letterSpacing: "0.01071em",
    fontWeight: 500,
    fontSize: "1.5em",
  },
});

const KeyMetric = ({
  title,
  color,
  classes,
  children,
  evolution,
  animationDelay,
}) => {
  return (
    <div className={classes.main}>
      <div className={classes.circle} style={{ backgroundColor: color }}>
        {children}
      </div>
      <Typography className={classes.title} align="center">
        {title}
      </Typography>
      <Typography
        className={classes.evolution}
        style={{ color: evolution < 0 ? "red" : Colors.green }}
        align="center"
      >
        {evolution > 0 && "+"}
        <CountUp
          decimals={2}
          end={evolution}
          duration={6}
          start={0.0}
          delay={animationDelay}
        />
        {/* {evolution > 0 ? `+${evolution}` : evolution} */}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(KeyMetric);
