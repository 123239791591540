import React from "react";

const TeamsIcon = ({ sizePercentage, color, className }) => (
  <svg
    className={className}
    width={sizePercentage * 64}
    height={sizePercentage * 25}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 64 43"
    version="1.1"
    fill="none"
  >
    <path
      d="M38.0391 17.9165H45.9375C46.6837 17.9165 47.2886 18.5261 47.2886 19.2782V26.5287C47.2886 29.2926 45.0654 31.5332 42.3229 31.5332H42.2994C39.5569 31.5336 37.3334 29.2933 37.333 26.5294V18.6282C37.333 18.2351 37.6492 17.9165 38.0391 17.9165V17.9165Z"
      fill="#5059C9"
    />
    <path
      d="M43.3777 16.4834C45.145 16.4834 46.5777 15.0396 46.5777 13.2584C46.5777 11.4773 45.145 10.0334 43.3777 10.0334C41.6104 10.0334 40.1777 11.4773 40.1777 13.2584C40.1777 15.0396 41.6104 16.4834 43.3777 16.4834Z"
      fill="#5059C9"
    />
    <path
      d="M33.422 16.4837C35.9748 16.4837 38.0443 14.3981 38.0443 11.8253C38.0443 9.2526 35.9748 7.16699 33.422 7.16699C30.8692 7.16699 28.7998 9.2526 28.7998 11.8253C28.7998 14.3981 30.8692 16.4837 33.422 16.4837Z"
      fill="#7B83EB"
    />
    <path
      d="M39.5854 17.9165H26.5479C25.8106 17.9349 25.2273 18.5516 25.2444 19.2947V27.5643C25.1414 32.0235 28.6421 35.724 33.0666 35.8332C37.4912 35.724 40.9918 32.0235 40.8889 27.5643V19.2947C40.9059 18.5516 40.3227 17.9349 39.5854 17.9165V17.9165Z"
      fill="#7B83EB"
    />
    <path
      opacity="0.1"
      d="M33.7779 17.9165V29.505C33.7743 30.0364 33.4548 30.5138 32.9672 30.7162C32.8119 30.7823 32.6451 30.8165 32.4765 30.8165H25.8703C25.7779 30.58 25.6925 30.3435 25.6214 30.0998C25.3725 29.2775 25.2455 28.4226 25.2445 27.5628V19.2925C25.2274 18.5506 25.8097 17.9349 26.5458 17.9165H33.7779Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M33.0667 17.9165V30.2217C33.0667 30.3916 33.0329 30.5597 32.9672 30.7162C32.7664 31.2076 32.2927 31.5296 31.7654 31.5332H26.2045C26.0836 31.2967 25.9699 31.0602 25.8703 30.8165C25.7707 30.5728 25.6925 30.3435 25.6214 30.0998C25.3725 29.2775 25.2455 28.4226 25.2445 27.5628V19.2925C25.2274 18.5506 25.8097 17.9349 26.5458 17.9165H33.0667Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M33.0667 17.9165V28.7883C33.0613 29.5104 32.4818 30.0944 31.7654 30.0998H25.6214C25.3725 29.2775 25.2455 28.4226 25.2445 27.5628V19.2925C25.2274 18.5506 25.8097 17.9349 26.5458 17.9165H33.0667Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M32.3556 17.9165V28.7883C32.3502 29.5104 31.7707 30.0944 31.0543 30.0998H25.6214C25.3725 29.2775 25.2455 28.4226 25.2445 27.5628V19.2925C25.2274 18.5506 25.8097 17.9349 26.5458 17.9165H32.3556Z"
      fill="black"
    />
    <path
      opacity="0.1"
      d="M33.7775 14.2114V16.4689C33.6566 16.476 33.5429 16.4832 33.422 16.4832C33.3011 16.4832 33.1873 16.4761 33.0664 16.4689C32.8264 16.4528 32.5883 16.4144 32.3553 16.3542C30.9153 16.0105 29.7256 14.9928 29.1553 13.6166C29.0572 13.3855 28.981 13.1455 28.9277 12.8999H32.4762C33.1938 12.9026 33.7748 13.4882 33.7775 14.2114V14.2114Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M33.0664 14.9282V16.469C32.8264 16.453 32.5883 16.4146 32.3553 16.3544C30.9153 16.0107 29.7256 14.9929 29.1553 13.6167H31.7651C32.4826 13.6194 33.0637 14.205 33.0664 14.9282Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M33.0664 14.9282V16.469C32.8264 16.453 32.5883 16.4146 32.3553 16.3544C30.9153 16.0107 29.7256 14.9929 29.1553 13.6167H31.7651C32.4826 13.6194 33.0637 14.205 33.0664 14.9282Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M32.3553 14.928V16.3541C30.9153 16.0104 29.7256 14.9926 29.1553 13.6165H31.0539C31.7715 13.6192 32.3525 14.2048 32.3553 14.928Z"
      fill="black"
    />
    <path
      d="M18.0144 13.6165H31.0519C31.7718 13.6165 32.3554 14.2046 32.3554 14.9301V28.0695C32.3554 28.795 31.7718 29.3831 31.0519 29.3831H18.0144C17.2945 29.3831 16.7109 28.795 16.7109 28.0695V14.9301C16.7109 14.2046 17.2945 13.6165 18.0144 13.6165Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M27.9633 18.6174H25.3571V25.7698H23.6967V18.6174H21.1025V17.23H27.9633V18.6174Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="19.4287"
        y1="12.59"
        x2="29.7572"
        y2="30.34"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A62C3" />
        <stop offset="0.5" stopColor="#4D55BD" />
        <stop offset="1" stopColor="#3940AB" />
      </linearGradient>
    </defs>
  </svg>
);

TeamsIcon.defaultProps = {
  sizePercentage: 1,
  color: "#00A7FC",
  className: {},
};

export default TeamsIcon;
