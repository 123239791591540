import React, { useMemo } from "react";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import { Avatar, Badge } from "@mui/material";
import Colors from "../../../../constants/Colors";
import displayDateWithoutHour from "../../../../utils/displayDateWithoutHour";

import i18n from "../../../../i18n";
import { useSelector } from "react-redux";
import { getUserId } from "services/user/selectors";
import ListItemLoader from "../ListItemLoader";
import selectLanguage from "utils/selectLanguage";
import { getAppLanguage } from "services/myUsers/selectors";

const styles = theme => ({
  userRow: {
    boxSizing: "border-box",
    position: "relative",
    cursor: "pointer",
    borderTop: "1px solid rgba(0,0,0,.09)",
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 0,
    padding: 15,
    paddingLeft: 45,
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
    transition: "0.2s all",
    "&:hover": {
      opacity: 0.9,
      backgroundColor: "#ECF6FF",
    },
  },
  date: {
    fontSize: 16,
    color: "#474747",
  },
  firstRow: {
    marginRight: 25,
    minHeight: 50,
    display: "flex",
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  avatar: {
    objectFit: "cover",
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  userName: {
    lineHeight: "1.5em" /* fallback */,
    maxHeight: "1.5em",
    wordBreak: "break-all",
    color: Colors.darkBlue,
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Montserrat",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 1,
  },
  nameDiv: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    height: 50,
    marginLeft: 15,
  },
  actionTitle: {
    cursor: "pointer",
    color: Colors.black,
    minWidth: 0,
    marginRight: 15,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 1 /* number of lines to show */,
    lineHeight: "1.5em" /* fallback */,
    maxHeight: "1.5em",
    wordBreak: "break-all",
    fontSize: "1em",
  },
  badge: {
    backgroundColor: Colors.pink,
    color: Colors.white,
    marginRight: 25,
    display: "flex",
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
});

export const getStatusColor = status => {
  if (status === "toComplete") return Colors.orange;
  if (status === "validated") return Colors.green;
  return Colors.pureMain;
};

const ReviewRow = ({ classes, doneStep, index, onClick = () => null }) => {
  const {
    review,
    firstName,
    lastName,
    picture,
    createdAt,
    status,
    stepId,
  } = doneStep;

  const userId = useSelector(getUserId);
  const language = useSelector(getAppLanguage);

  const numberOfUnseenComment = useMemo(() => {
    return (
      review?.comments?.filter(
        com =>
          !com.isSeen &&
          com?.user?._id !== userId &&
          userId === review?.corrector?.correctorId,
      )?.length || 0
    );
  }, [review, userId]);

  return (
    <div
      key={index}
      onClick={onClick}
      className={classes.userRow}
      custom={index}
      initial="hidden"
      animate="visible"
    >
      {!firstName && !lastName ? (
        <div className={classes.firstRow}>
          <ListItemLoader />
        </div>
      ) : (
        <div className={classes.firstRow}>
          <Avatar src={picture} className={classes.avatar} />
          <div className={classes.nameDiv}>
            <Typography
              className={classes.userName}
            >{`${firstName} ${lastName}`}</Typography>
          </div>
        </div>
      )}
      <div className={classes.firstRow}>
        <Typography
          dangerouslySetInnerHTML={{
            __html: selectLanguage({
              text: stepId?.title,
              language: language,
              noFallBack: true,
            }),
          }}
          className={classes.actionTitle}
        ></Typography>
      </div>
      <div className={classes.firstRow}>
        <Typography className={classes.date}>
          {displayDateWithoutHour(createdAt)}
        </Typography>
      </div>
      <div className={classes.firstRow}>
        <Typography
          className={classes.date}
          style={{ color: getStatusColor(status) }}
        >
          {i18n.t(`review-status-${status}`)}
        </Typography>
      </div>
      <Badge
        badgeContent={numberOfUnseenComment}
        classes={{ badge: classes.badge }}
      ></Badge>
    </div>
  );
};

export default withStyles(styles)(ReviewRow);
