import { createSelector } from "reselect";
import { getAdminConnections } from "services/general/selectors";

import Fuse from "fuse.js";

import {
  SORT_RATING_ASC,
  SORT_RATING_DESC,
  SORT_VIEWS_ASC,
  SORT_VIEWS_DESC,
  SORT_DONE_DESC,
  SORT_DONE_ASC,
} from "services/createTrack/sortingConstants";

const fuseOptions = {
  isCaseSensitive: false,
  shouldSort: true,
  threshold: 0.6,
  location: 0,
  distance: 1000,
  maxPatternLength: 80,
  minMatchCharLength: 1,
  keys: [
    "title.fr",
    "title.en",
    "content.title.fr",
    "content.title.en",
    "content.why.fr",
    "content.why.en",
    "content.how.fr",
    "content.how.en",
  ],
};

const locationSelector = state => {
  return state.router?.location?.pathname;
};

export const getLastUpdateDate = state => state.createTrack.lastUpdateDate;

export const getLanguageSelected = state =>
  state.createTrack.languageSelected || "fr";

export const getFolderSelected = state =>
  state.createTrack.folderSelected || {};

export const getFolderSelectedValue = createSelector(
  getFolderSelected,
  folderSelected => folderSelected.value,
);

export const getIsEditingCampaign = createSelector(
  locationSelector,
  pathname => {
    const isEditingCampaign = pathname.indexOf("editing/campaign") > -1;
    return isEditingCampaign;
  },
);

export const getTrackId = state => state.createTrack._id || null;

export const getIsLibrary = createSelector(locationSelector, pathname => {
  return pathname === "/library";
});

export const getIsLocatedOnTrack = createSelector(
  locationSelector,
  pathname => {
    return pathname.includes("/create");
  },
);

export const getFolderList = state =>
  state.createTrack.folderList || { folders: [] };

export const getSkillsList = state =>
  state.createTrack.skillsList || { _id: "", skills: [] };

export const getStepsIds = state => state.createTrack.stepsIds || [];

export const getMyLibrarySteps = state =>
  state.createTrack.myLibrarySteps || [];

export const getNumberOfSteps = createSelector(
  getStepsIds,
  steps => steps?.length || 0,
);

export const getStepSelectedId = state => state.createTrack.stepSelectedId;

export const getLastSelectedStepId = state =>
  state.createTrack.lastStepSelectedId;

export const getIsMobileConnected = state =>
  state.createTrack.isMobileConnected;

export const getNumberOfAdminsConnectedOnStep = createSelector(
  getStepSelectedId,
  getAdminConnections,
  (stepId, adminConnections) => {
    return adminConnections.filter(el => el.stepId === stepId).length;
  },
);

export const getStepsByIds = state => state.createTrack.stepsByIds;

export const getSkillSelected = state => state.createTrack.skillSelected || {};

export const getSkillSelectedValue = createSelector(
  getSkillSelected,
  skillSelected => skillSelected.value,
);

export const getCreateTrackSearch = state => state.createTrack.search || "";

export const getSortSelected = state => state.createTrack.sortSelected || "";

export const getStepsIdsSorted = createSelector(
  getStepsIds,
  getStepsByIds,
  getSortSelected,
  (stepsIds, stepsByIds, sortSelected) => {
    if (!sortSelected) return stepsIds;

    const steps = stepsIds.map(el => stepsByIds[el]);

    if (sortSelected === SORT_RATING_ASC) {
      steps.sort((a, b) => b.rating - a.rating);
    } else if (sortSelected === SORT_RATING_DESC) {
      steps.sort((a, b) => a.rating - b.rating);
    } else if (sortSelected === SORT_DONE_ASC) {
      steps.sort((a, b) => b.totalDone - a.totalDone);
    } else if (sortSelected === SORT_DONE_DESC) {
      steps.sort((a, b) => a.totalDone - b.totalDone);
    } else if (sortSelected === SORT_VIEWS_ASC) {
      steps.sort((a, b) => b.views - a.views);
    } else if (sortSelected === SORT_VIEWS_DESC) {
      steps.sort((a, b) => a.views - b.views);
    }

    return steps.map(el => el._id);
  },
);

export const getStepsWithData = createSelector(
  getStepsByIds,
  getStepsIds,
  (stepsByIds, stepsIds) => {
    return stepsIds.map(stepId => stepsByIds[stepId]);
  },
);

export const getStepsToDisplay = createSelector(
  getStepsIdsSorted,
  getStepsByIds,
  getFolderSelectedValue,
  getSkillSelectedValue,
  getCreateTrackSearch,
  (stepsIds, stepsByIds, folderSelectedValue, skillSelectedValue, search) => {
    if (!folderSelectedValue && !skillSelectedValue && !search) return stepsIds;

    let result;
    if (folderSelectedValue || skillSelectedValue) {
      result = stepsIds.reduce((acc, curr) => {
        const { folders, skills } = stepsByIds[curr];
        const hasFolder =
          !!folderSelectedValue &&
          folders &&
          folders.some(el => el.value === folderSelectedValue);

        const hasSkills =
          !!skillSelectedValue &&
          skills &&
          skills.some(el => el.value === skillSelectedValue);

        if (folderSelectedValue && skillSelectedValue) {
          if (hasFolder && hasSkills) {
            acc.push(stepsByIds[curr]);
          }
          return acc;
        } else if (folderSelectedValue && hasFolder) {
          acc.push(stepsByIds[curr]);
          return acc;
        } else if (skillSelectedValue && hasSkills) {
          acc.push(stepsByIds[curr]);
        }

        return acc;
      }, []);

      if (!search) return result.map(el => el._id);
    } else {
      result = stepsIds.map(stepId => stepsByIds[stepId]);
    }

    const fuse = new Fuse(result, fuseOptions);
    const searchResults = search.length > 0 ? fuse.search(search) : result;

    return searchResults.map(el => el._id);
  },
);

export const getStepSelectedIndex = createSelector(
  getStepSelectedId,
  getStepsToDisplay,
  (stepSelectedId, stepsIds) => {
    return stepsIds.findIndex(el => el === stepSelectedId);
  },
);

export const getIndexToScroll = state => state.createTrack.indexToScroll || 0;

export const getLastScrollDate = state =>
  state.createTrack.lastScrollDate || new Date();

export const getStepSelected = createSelector(
  getStepsByIds,
  getStepSelectedId,
  (stepsByIds, stepSelectedId) => {
    return stepsByIds?.[stepSelectedId] || { content: {}, isPrivate: true };
  },
);

export const getStepSelectedContent = createSelector(
  getStepSelected,
  stepSelected => {
    return stepSelected?.content || {};
  },
);

export const getTrackStepsToSave = createSelector(getStepsIds, stepsIds => {
  return stepsIds.map(stepId => {
    return {
      step: stepId,
    };
  });
});

export const getTrackInfosToSave = state => {
  const {
    _id,
    title,
    picture,
    description,
    isTemplate,
    templateSettings,
  } = state.createTrack;

  return {
    _id,
    title,
    picture,
    description,
    isTemplate,
    templateSettings,
  };
};

export const getUniqueStepsSkills = createSelector(
  getStepsIds,
  getStepsByIds,
  (stepsIds, stepsByIds) => {
    return stepsIds.reduce((prev, curr) => {
      const step = stepsByIds[curr];
      if (step?.skills) {
        step.skills.map(skill => {
          const skillIndex = prev.findIndex(el => el?.value === skill?.value);
          if (skillIndex === -1) {
            prev.push(skill);
          }
          return skillIndex;
        });
      }
      return prev;
    }, []);
  },
);
