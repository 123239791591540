import React, { useMemo } from "react";
import makeStyles from "@mui/styles/makeStyles";
import StatBox from "scenes/MyCoachees/StatBox";
import i18n from "i18n";
import { Invoice } from "../data/types";
import Colors from "constants/Colors";
import BillIcon from "./BillIcon";
import PaidIcon from "./PaidIcon";
import NotPaidIcon from "./NotPaidIcon";
import formatCurrency from "utils/formatCurrency";

const useStyles = makeStyles(theme => ({
  main: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "row",
    marginTop: 15,
    marginBottom: 25,
  },
}));

interface Props {
  invoices: Invoice[];
}

const InvoiceStats = ({ invoices }: Props) => {
  const classes = useStyles();

  const totalBilled = useMemo(
    () => invoices.reduce((prev, curr) => curr.totalAmount + prev, 0),
    [invoices],
  );

  const totalPaid = useMemo(
    () =>
      invoices.reduce((prev, curr) => {
        if (curr.isPaid) {
          return curr.totalAmount + prev;
        }
        return prev;
      }, 0),
    [invoices],
  );

  const totalNotPaid = useMemo(() => totalBilled - totalPaid, [
    totalBilled,
    totalPaid,
  ]);

  return (
    <div className={classes.main}>
      <StatBox
        legend={i18n.t("amount-billed")}
        stat={formatCurrency(totalBilled)}
        iconBackgroundColor={Colors.veryLightMain}
        icon={<BillIcon />}
      />
      <StatBox
        legend={i18n.t("amount-paid")}
        stat={formatCurrency(totalPaid)}
        iconBackgroundColor={"rgba(139, 216, 92, 0.2)"}
        icon={<PaidIcon />}
      />
      <StatBox
        legend={i18n.t("amount-not-paid")}
        stat={formatCurrency(totalNotPaid)}
        iconBackgroundColor={"rgba(243, 181, 21, 0.2)"}
        icon={<NotPaidIcon />}
      />
    </div>
  );
};

export default InvoiceStats;
