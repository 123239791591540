import React from "react";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Colors from "constants/Colors";
import i18n from "i18n";
import ChevronRight from "@mui/icons-material/ChevronRight";

const useStyles = makeStyles(theme => ({
  main: {},
  toggleSettingsDiv: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    flexDirection: "row",
    alignSelf: "flex-end",
    paddingLeft: 15,
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": { backgroundColor: Colors.hoverBlue },
    borderRadius: 4,
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 15,
  },
  toggleSettingsText: {
    fontSize: 16,
    fontFamily: "Hind",
    color: Colors.pureMain,
    marginRight: 15,
  },
  arrowIcon: {
    transform: "rotate(90deg)",
    color: Colors.pureMain,
  },
  arrowOpen: {
    transform: "rotate(-90deg)",
  },
}));

interface Props {
  isOpen: boolean;
  onClick: () => void;
}

const SettingsToggle = ({ isOpen, onClick }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.toggleSettingsDiv} onClick={onClick}>
      <Typography className={classes.toggleSettingsText}>
        {i18n.t("settings")}
      </Typography>
      <ChevronRight
        className={`${classes.arrowIcon} ${isOpen && classes.arrowOpen}`}
      />
    </div>
  );
};

export default React.memo(SettingsToggle);
