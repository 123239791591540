//@flow
import {
  call,
  put,
  all,
  takeLatest,
  select,
  takeEvery,
} from "redux-saga/effects";
import { goBack, push } from "connected-react-router";

import {
  GET_ALL_INVOICES_SUCCESS,
  CREATE_INVOICE_SUCCESS,
  CREATE_INVOICE_ERROR,
  GET_ALL_INVOICES_REQUESTING,
  CREATE_INVOICE_REQUESTING,
  GET_ALL_INVOICES_ERROR,
  START_NEW_INVOICE,
  GET_INVOICE_CLIENTS_REQUESTING,
  GET_INVOICE_CLIENTS_SUCCESS,
  GET_INVOICE_CLIENTS_ERROR,
  UPDATE_INVOICE_REQUESTING,
  UPDATE_INVOICE_ERROR,
  UPDATE_INVOICE_SUCCESS,
} from "./constants";

import { fetchApi } from "../api";
import { openSnackMessage } from "../general/actions";
import i18n from "../../i18n";
import {
  getSelectedClientId,
  getUserId,
} from "services/user/selectors";
import { getUserSelectedId } from "services/manager/selectors";

const endPoints = {
  getAllInvoices: "/v1/invoices/all",
  createInvoice: "/v1/invoices/create",
  getInvoiceClients: "/v1/invoices/clients",
  updateInvoice: "/v1/invoices/",
};

const getAllInvoicesApi = data => {
  const { coacheeId, clientId } = data;
  return fetchApi(
    `${endPoints.getAllInvoices}?clientId=${clientId}&coacheeId=${coacheeId}`,
    {},
    "get",
  );
};

const getInvoiceClientsApi = data => {
  const { clientId } = data;
  return fetchApi(
    `${endPoints.getInvoiceClients}?clientId=${clientId}`,
    {},
    "get",
  );
};

const updateInvoiceApi = data => {
  return fetchApi(`${endPoints.updateInvoice}`, data, "patch");
};

export const createInvoiceApi = data => {
  return fetchApi(endPoints.createInvoice, data, "post");
};

function* getAllInvoicesFlow(action) {
  try {
    const clientId = yield select(getSelectedClientId);
    const userSelectedId = yield select(getUserSelectedId);
    const response = yield call(getAllInvoicesApi, {
      clientId,
      coacheeId: userSelectedId || null,
    });
    // yield put(
    //   openSnackMessage({ snackMessage: i18n.t("data-modified-success") })
    // );
    yield put({ type: GET_ALL_INVOICES_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_ALL_INVOICES_ERROR, payload: error });
  }
}

function* createInvoiceFlow(action) {
  try {
    const { invoice } = action.payload;
    console.log(invoice);
    // const myInvoices = yield select(getAllInvoices);
    const clientId = yield select(getSelectedClientId);
    const coachId = yield select(getUserId);
    yield select(getUserSelectedId);

    const response = yield call(createInvoiceApi, {
      ...action.payload,
      ...invoice,
      coachId,
      clientId,
    });

    yield put({ type: CREATE_INVOICE_SUCCESS, payload: response });
    // First coachee creation
    console.log("INVOICE");
    yield put(
      openSnackMessage({ snackMessage: i18n.t("invoice-publish-success") }),
    );

    return yield put(goBack());
    // yield put(replace("/invoices"));
  } catch (error) {
    yield put({ type: CREATE_INVOICE_ERROR, payload: error });
  }
}

function* startNewInvoiceFlow(action) {
  try {
    yield put(push("/invoices-create"));
  } catch (error) {
    console.log(error);
  }
}

function* updateInvoiceFlow(action) {
  try {
    const result = yield call(updateInvoiceApi, action.payload);

    yield put({ type: UPDATE_INVOICE_SUCCESS, payload: result });
    yield put(
      openSnackMessage({ snackMessage: i18n.t("invoice-updated-success") }),
    );
  } catch (error) {
    console.log(error);
    yield put({ type: UPDATE_INVOICE_ERROR, payload: error });
  }
}

function* getInvoiceClientsFlow(action) {
  try {
    const clientId = yield select(getSelectedClientId);

    const response = yield call(getInvoiceClientsApi, {
      ...action.payload,
      clientId,
    });

    yield put({ type: GET_INVOICE_CLIENTS_SUCCESS, payload: response });
  } catch (error) {
    console.log(error);
    yield put({ type: GET_INVOICE_CLIENTS_ERROR, payload: error });
  }
}

function* userWatcher() {
  yield all([
    takeLatest(CREATE_INVOICE_REQUESTING, createInvoiceFlow),
    takeLatest([GET_ALL_INVOICES_REQUESTING], getAllInvoicesFlow),
    takeLatest([START_NEW_INVOICE], startNewInvoiceFlow),
    takeLatest([GET_INVOICE_CLIENTS_REQUESTING], getInvoiceClientsFlow),
    takeEvery(UPDATE_INVOICE_REQUESTING, updateInvoiceFlow),
  ]);
}

export default userWatcher;
