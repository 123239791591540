import React, { PureComponent } from "react";
import { connect } from "react-redux";

import withStyles from "@mui/styles/withStyles";
import Switch from "react-switch";

import Colors from "../../constants/Colors";

import { logout } from "../../services/auth/reducer";
import { getSelectedClient } from "../../services/myClients/selectors";
import {
  getSelectedClientRequest,
  modifyMyClientRequest,
} from "../../services/myClients/actions";
import {
  getIsMicrodoingAdmin,
  getIsSuperAdmin,
  getSelectedClientId,
} from "../../services/user/selectors";
import { changeField } from "../../services/client/actions";
import { uploadPictureRequest } from "../../services/upload/actions";
import { changeGeneralSettings } from "../../services/general/actions";
import i18n from "../../i18n";
import FlagLanguage from "../CreateTrack/CreateActionTextStep/FlagLanguage";
import { Typography, TextField } from "@mui/material";
import { getAppLanguage } from "../../services/general/selectors";
import RoundButton from "../../components/RoundButton";
import Uploader from "../../components/Uploader";
import changeLocal from "../../utils/changeLocal";
import slugify from "slugify";

const styles = theme => ({
  main: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 100,
  },
  root: {
    width: "100%",
    minHeight: 500,
    paddingLeft: 20,
    paddingRight: 45,
    marginTop: theme.spacing.unit * 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "column",
    // backgroundColor: "#FAFAFA",
    backgroundColor: "#fff",
    // backgroundColor: "#fff",
    overflowX: "auto",
  },
  button: {
    backgroundColor: Colors.pink,
  },
  languageDiv: {
    marginTop: 10,
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
  },
  title: {
    // color: Colors.black,
    fontFamily: "Montserrat",
    marginBottom: 10,
    fontSize: 28,
    lineHeight: 1.2143,
    fontWeight: 700,
  },
  subTitle: {
    // color: Colors.black,
    fontFamily: "Montserrat",
    marginTop: 20,
    marginBottom: 5,
    fontSize: 22,
    lineHeight: 1.2143,
    fontWeight: 700,
  },
  textField: {
    maxWidth: 300,
    minWidth: "33%",
  },
  uploader: {
    marginTop: 16,
  },
  saveButton: {
    marginTop: 25,
  },
  logoutButton: {
    marginTop: 15,
  },
  explanation: {
    marginTop: 5,
  },
  autoInviteDiv: {
    paddingTop: 15,
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "row",
  },
});

class Recap extends PureComponent {
  state = {
    ...this.props,
  };

  logout = () => {
    this.props.logout();
  };

  componentDidMount = () => {
    const { clientId } = this.props;
    this.props.getSelectedClientRequest({ clientId });
  };

  componentDidUpdate = prevProps => {
    if (prevProps.uploadedPicture !== this.props.uploadedPicture) {
      this.setState({ logo: this.props.uploadedPicture });
    }
  };

  onSelectLanguage = countryCode => {
    if (countryCode === "GB") {
      this.props.changeGeneralSettings({ language: "en" });
      i18n.changeLanguage("en");
      changeLocal("en");
    } else if (countryCode === "FR") {
      this.props.changeGeneralSettings({ language: "fr" });
      i18n.changeLanguage("fr");
      changeLocal("fr");
    }
  };

  saveChanges = () => {
    const {
      logo,
      name,
      plan,
      isEnterpriseAccount,
      signupPolicy,
      isPhoneNumberRequired,
      isBtoCAccount,
    } = this.state;
    const { clientId } = this.props;
    // const formatedSignupPolicy = signupPolicy.replace(/↵/g, "\n");
    // console.log({ formatedSignupPolicy });
    this.props.modifyMyClientRequest({
      clientId,
      logo,
      name,
      plan,
      isEnterpriseAccount,
      signupPolicy,
      isPhoneNumberRequired,
      isBtoCAccount,
    });
  };

  onChangeClientParams = param => event => {
    const value = event.target.value;
    this.setState({ [param]: value });
  };

  togglePremium = () => {
    const { plan } = this.state;

    const newPlan = plan === "free" ? "premium" : "free";
    this.setState({ plan: newPlan });
  };

  toggleIsPhoneNumberRequired = () => {
    this.setState({ isPhoneNumberRequired: !this.state.isPhoneNumberRequired });
  };

  toggleBtoAccount = () => {
    this.setState({ isBtoCAccount: !this.state.isBtoCAccount });
  };

  onFileUploaded = async files => {
    const { clientId } = this.props;

    const date = new Date().getTime();
    await this.props.uploadPictureRequest({
      urlName: slugify(`client-logo/${clientId}/${date}`),
      file: files[0],
    });
  };

  render() {
    const {
      classes,
      language,
      uploading,
      isSuperAdmin,
      isMicrodoingAdmin,
    } = this.props;
    const {
      name,
      logo,
      plan,
      signupPolicy,
      isPhoneNumberRequired,
      isBtoCAccount,
    } = this.state;
    const hasPremium = plan !== "free";
    return (
      <div className={classes.main}>
        <div className={classes.root}>
          <Typography className={classes.subTitle}>
            {i18n.t("language-dashboard")}
          </Typography>
          <div className={classes.languageDiv}>
            <FlagLanguage
              onSelect={this.onSelectLanguage}
              language={language}
            />
          </div>
          {(isSuperAdmin || isMicrodoingAdmin) && (
            <>
              <Typography className={classes.subTitle}>
                {i18n.t("has-premium")}
              </Typography>
              <div className={classes.autoInviteDiv}>
                <Switch onChange={this.togglePremium} checked={hasPremium} />
              </div>
              <Typography className={classes.subTitle}>
                {i18n.t("isPhoneNumberRequired")}
              </Typography>
              <div className={classes.autoInviteDiv}>
                <Switch
                  onChange={this.toggleIsPhoneNumberRequired}
                  checked={isPhoneNumberRequired}
                />
              </div>
              <Typography className={classes.subTitle}>
                {i18n.t("is-BtoC-account")}
              </Typography>
              <div className={classes.autoInviteDiv}>
                <Switch
                  onChange={this.toggleBtoAccount}
                  checked={isBtoCAccount}
                />
              </div>
              <Typography className={classes.subTitle}>
                {i18n.t("signup-policy")}
              </Typography>
              <TextField
                fullWidth
                multiline
                className={classes.textField}
                label={i18n.t("signup-policy")}
                value={signupPolicy}
                onChange={this.onChangeClientParams("signupPolicy")}
                margin="normal"
                variant="outlined"
              />
            </>
          )}
          <Typography className={classes.subTitle}>
            {i18n.t("account-name-description")}
          </Typography>
          <TextField
            fullWidth
            className={classes.textField}
            label={i18n.t("account-name-description")}
            value={name}
            onChange={this.onChangeClientParams("name")}
            margin="normal"
            variant="outlined"
          />
          <Typography className={classes.subTitle}>
            {i18n.t("account-logo-description")}
          </Typography>
          <Typography className={classes.explanation}>
            {i18n.t("account-logo-explanation")}
          </Typography>
          <Uploader
            placeholder={i18n.t("upload-client-logo")}
            width={300}
            height={175}
            picture={logo}
            className={classes.uploader}
            onFileUploaded={this.onFileUploaded}
            uploading={uploading}
          />
          <RoundButton
            onClick={this.saveChanges}
            className={classes.saveButton}
            title={i18n.t("save-changes")}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const language = getAppLanguage(state);
  const clientId = getSelectedClientId(state);
  // const clientId = getUserClientId(state);
  const selectedClient = getSelectedClient(state);
  const { uploading } = state.upload;
  const {
    name,
    logo,
    plan,
    isEnterpriseAccount,
    signupPolicy,
    isPhoneNumberRequired,
    isBtoCAccount,
  } = selectedClient;
  const isSuperAdmin = getIsSuperAdmin(state);
  const isMicrodoingAdmin = getIsMicrodoingAdmin(state);
  const uploadedPicture = state.upload.picture;

  return {
    language,
    clientId,
    name,
    logo,
    plan,
    uploadedPicture,
    isEnterpriseAccount,
    isBtoCAccount,
    isPhoneNumberRequired,
    uploading,
    isSuperAdmin,
    isMicrodoingAdmin,
    signupPolicy,
  };
};

export default connect(mapStateToProps, {
  logout,
  changeGeneralSettings,
  changeField,
  modifyMyClientRequest,
  uploadPictureRequest,
  getSelectedClientRequest,
})(withStyles(styles)(Recap));
