import "./style.css";
import React, { Component } from "react";
import withStyles from '@mui/styles/withStyles';
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";

import { connect } from "react-redux";
import { getAllMyClientsTracksRequest } from "../../../services/campaign/actions";
import moment from "moment";
import selectLanguage from "../../../utils/selectLanguage";
import CampaignBox from "../CampaignBox";
import i18n from "../../../i18n";
import { getUserId, getUserClientId } from "../../../services/user/selectors";
import { getAppLanguage } from "../../../services/general/selectors";

moment.locale("fr");

const styles = theme => ({
  box: {
    width: 280,
  },
});

const Row = props => {
  const { data, index, style } = props;
  // const el = data.tracks[index];
  const onClickMyCampaignTemplate = data.onClickMyCampaignTemplate;
  const selectLanguage = data.selectLanguage;
  const boxClassName = data.boxClassName;

  const language = data.language;
  if (!data.tracks[index]) return null;

  return (
    <div
      style={{ ...style, flexDirection: "row", display: "flex" }}
      key={`campaign-${index}`}
    >
      {data.tracks.slice(index * 3, index * 3 + 3).map((el, index) => (
        <CampaignBox
          {...el}
          participants={[]}
          showSteps
          boxClassName={boxClassName}
          settingsDisabled
          hasDelete={false}
          onClickDelete={null}
          onClick={onClickMyCampaignTemplate(el)}
          title={selectLanguage({ text: el.title, language })}
          description={selectLanguage({
            text: el.description,
            language,
          })}
          hoverText={i18n.t("use-campaign")}
        />
      ))}
    </div>
  );
};

class MyPastCampaigns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.baseState = this.state;
  }

  componentDidMount = () => {
    this.props.getAllMyClientsTracksRequest({ clientId: this.props.clientId });
  };

  onClickMyCampaignTemplate = campaign => () => {
    this.props.onClickMyCampaignTemplate(campaign);
  };

  displayTracks = () => {
    const { classes, language, tracks } = this.props;
    return (
      <AutoSizer minWidth={1} minHeight={1} maxHeight={800}>
        {({ width, height }) => (
          <FixedSizeList
            className="past-campaigns"
            height={800}
            itemCount={Math.ceil(tracks.length / 3)}
            itemSize={520}
            width={width}
            itemData={{
              onClickMyCampaignTemplate: this.onClickMyCampaignTemplate,
              tracks,
              selectLanguage,
              language,
              boxClassName: classes.box,
            }}
          >
            {Row}
          </FixedSizeList>
        )}
      </AutoSizer>
    );
  };

  render() {
    return this.displayTracks();
  }
}

const mapStateToProps = state => {
  const { requesting } = state.campaign;
  const language = getAppLanguage(state);
  const clientId = getUserClientId(state);
  const userId = getUserId(state);
  return {
    clientId,
    userId,
    requesting,
    language,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    getAllMyClientsTracksRequest,
  })(MyPastCampaigns),
);
