import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { getRankingRequest } from "../../services/client/actions";

import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";

import Paper from "@mui/material/Paper";

import RankingComponent from "./RankingComponent";
import { getUserId, getSelectedClientId } from "../../services/user/selectors";
import i18n from "../../i18n";

// const colorRange = [
//   "rgba(252,87,122,0.8)",
//   "rgba(66,80,251,0.8)",
//   "rgba(0,170,255,0.8)",
//   "#FF9F40",
//   "#FFC233"
// ];

const styles = theme => ({
  main: {
    backgroundColor: "#FAFAFA",
    // backgroundColor: "#fff",
    flex: 1,
    minHeight: 300,
    paddingBottom: 35,
    flexDirection: "row",
    paddingRight: "2%",
    paddingLeft: "2%",
    paddingTop: 35,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  leftSection: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: 650,
    alignItems: "center",
    justifyContent: "center",
  },
  rightSection: {
    flex: 1,
    height: 650,
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  accent: {
    color: "#6198DE",
  },
  bold: {
    fontWeight: 500,
    marginBottom: 15,
  },
  actionLegend: {
    color: "#fff",
  },
  box: {
    webkitBoxShadow: "0px 0px 4px 1px #D8D8D8",
    mozBoxShadow: "0px 0px 4px 1px #D8D8D8",
    boxShadow: "0px 0px 4px 1px #D8D8D8",
    height: 200,
    flex: 1,
    padding: 5,
    flexDirection: "column",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  chartContainer: {
    backgroundColor: "blue",
    height: 350,
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

/*const isNumber = n => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};*/

class RankingSection extends PureComponent {
  state = {
    name: "",
    owner: "",
    domain: "",
  };

  componentDidMount = () => {
    // this.props.getRankingRequest({
    //   userId: this.props.userId,
    //   clientId: this.props.clientId
    // });
  };

  onChangeClient = event => {
    this.props.changeCreateField({ name: event.target.value });
  };

  onChangeEmail = event => {
    this.props.changeCreateField({ owner: event.target.value });
  };

  onChangeDomain = event => {
    this.props.changeCreateField({ domain: event.target.value });
  };

  onSubmit = () => {
    this.props.createClientRequest({
      name: this.props.name,
      owner: this.props.owner,
      domain: this.props.domain,
    });
  };

  render() {
    const { classes, className } = this.props;
    return (
      <div className={`${classes.main} ${className}`}>
        <div className={classes.leftSection}>
          <Typography
            align="center"
            variant="h6"
            classes={{ root: classes.bold }}
          >
            {i18n.t("top-players")}
          </Typography>
          <Paper className={classes.box}>
            <RankingComponent ranking={this.props.userRanking} hasPicture />
          </Paper>
        </div>
        <div className={classes.rightSection}>
          <Typography
            align="center"
            variant="h6"
            classes={{ root: classes.bold }}
          >
            {i18n.t("top-teams")}
          </Typography>
          <Paper className={classes.box}>
            <RankingComponent ranking={this.props.teamRanking} />
          </Paper>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  let userId = getUserId(state);
  let clientId = getSelectedClientId(state);
  const { userRanking, teamRanking } = state.client;
  return {
    token: state.auth.token,
    userRanking,
    teamRanking,
    userId,
    clientId,
  };
};

export default connect(mapStateToProps, { getRankingRequest })(
  withStyles(styles)(RankingSection),
);
