import React, { useMemo } from "react";
import { IconButton } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Colors from "constants/Colors";

const DEFAULT_SIZE = 20;

const CheckIcon = () => (
  <svg width="14" height="11" viewBox="0 0 14 11" fill="none">
    <path
      d="M5.12518 8.204L2.0269 5.10571L0.994141 6.13847L5.12518 10.2695L13.9774 1.41728L12.9447 0.384521L5.12518 8.204Z"
      fill="white"
    />
  </svg>
);

const useStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    border: "1px solid #D9D9D9",
    borderRadius: 2,
    transition: "all 0.2s easeIn",
  },
  isChecked: {
    backgroundColor: Colors.pureMain,
    borderColor: "#fff",
  },
}));

interface Props {
  isChecked: boolean;
  onCheck: () => void;
  size?: number;
  className?: string;
}

const CheckBox = ({
  isChecked,
  onCheck,
  size = DEFAULT_SIZE,
  className,
}: Props) => {
  const classes = useStyles();

  const sizeStyle = useMemo(() => {
    return { height: size, width: size };
  }, [size]);

  return (
    <IconButton onClick={onCheck} className={className} size="large">
      <div
        className={`${classes.main} ${isChecked && classes.isChecked}`}
        style={sizeStyle}
      >
        {isChecked && <CheckIcon />}
      </div>
    </IconButton>
  );
};

export default CheckBox;
