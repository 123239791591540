import {
  MANAGER_GET_TEAM_REQUESTING,
  MANAGER_GET_TEAM_SUCCESS,
  MANAGER_GET_TEAM_ERROR,
  MANAGER_GET_DASHBOARD_REQUESTING,
  MANAGER_GET_DASHBOARD_SUCCESS,
  MANAGER_SELECT_TEAM_MEMBER,
  MANAGER_CREATE_TEAM_REQUESTING,
  MANAGER_CREATE_TEAM_SUCCESS,
  MANAGER_CREATE_TEAM_ERROR,
  MANAGER_CLOSE_SNACK_MESSAGE,
  MANAGER_SELECT_TAB,
  MANAGER_SEE_USER_PROFILE_REQUESTING,
  MANAGER_SEE_USER_PROFILE_SUCCESS,
  MANAGER_SEE_USER_PROFILE_ERROR,
  MANAGER_CHANGE_FIELD,
  MANAGER_SUBMIT_COMMENT_REQUESTING,
  MANAGER_SUBMIT_COMMENT_ERROR,
  MANAGER_SUBMIT_COMMENT_SUCCESS,
  MANAGER_GET_COMMENTS_ERROR,
  MANAGER_GET_COMMENTS_SUCCESS,
  MANAGER_DELETE_COMMENT_REQUESTING,
  MANAGER_DELETE_COMMENT_ERROR,
  MANAGER_ON_EDIT_GOAL,
  MANAGER_SUBMIT_GOALS_ERROR,
  MANAGER_SUBMIT_GOALS_SUCCESS,
  MANAGER_MODIFY_SURVEY_REQUESTING,
  MANAGER_CREATE_SURVEY_REQUESTING,
  MANAGER_CREATE_SURVEY_SUCCESS,
  MANAGER_CREATE_SURVEY_ERROR,
  MANAGER_MODIFY_SURVEY_SUCCESS,
  MANAGER_GET_SURVEYS_REQUESTING,
  MANAGER_GET_SURVEYS_SUCCESS,
  MANAGER_GET_SURVEYS_ERROR,
  MANAGER_DELETE_SURVEY_REQUESTING,
  MANAGER_DELETE_SURVEY_ERROR,
  MANAGER_DELETE_SURVEY_SUCCESS,
  MANAGER_MODIFY_SURVEY_ERROR,
  MANAGER_GET_DASHBOARD_ERROR,
  MANAGER_SEND_NOTIFICATION_ERROR,
  MANAGER_SEND_NOTIFICATION_SUCCESS,
  MANAGER_SEND_NOTIFICATION_REQUESTING,
  MANAGER_MODIFY_USER_SELECTED,
  MANAGER_EDIT_SCHEDULED_NOTIFICATION_SUCCESS,
  MANAGER_DELETE_SCHEDULED_NOTIFICATION_SUCCESS,
  MANAGER_EDIT_SCHEDULED_NOTIFICATION_REQUESTING,
  MANAGER_DELETE_SCHEDULED_NOTIFICATION_REQUESTING,
  MANAGER_DELETE_SCHEDULED_NOTIFICATION_ERROR,
  MANAGER_EDIT_SCHEDULED_NOTIFICATION_ERROR,
} from "./constants";

import { AUTH_LOGOUT_SUCCESS } from "../auth/reducer";
import moment from "moment";
import { CLEAR_COACHING_SESSION } from "services/coaching/constants";

const initialState = {
  tabSelected: 0,
  managerComments: [],
  userSelected: {
    firstName: "",
    lastName: "",
    picture: "",
    historical: [],
    data: [],
    goals: {},
    stats: {
      stress: 0,
      confidence: 0,
      publicSpeaking: 0,
      teamCohesion: 0,
    },
    feelingEvolution: [
      {
        mois: "septembre",
        Stress: 0,
        Confiance: 1,
        "Aisance à l'oral": -0.5,
        "Cohésion d'équipe": 2,
      },
    ],
    feelingEvolutions: [
      {
        publicSpeaking: 2,
        teamCohesion: 1,
        confidence: 2,
        productivity: -2,
        stress: 1,
        mois: "octobre",
        monthNumber: 9,
        users: 10,
        date: moment().subtract(2, "month"),
      },
      {
        publicSpeaking: 2,
        teamCohesion: 1,
        confidence: 2,
        productivity: -2,
        stress: 1,
        mois: "octobre",
        monthNumber: 8,
        users: 10,
        date: moment().subtract(1, "month"),
      },
      {
        publicSpeaking: 2,
        teamCohesion: 1,
        confidence: 2,
        productivity: -2,
        stress: 1,
        mois: "octobre",
        monthNumber: 7,
        users: 10,
      },
    ],
    totalDoneActions: 0,
  },
  notificationTitle: "",
  notificationText: "",
  notificationDate: new Date(),
  userComment: "",
  isSnackMessage: false,
  snackMessage: "",
  selectionList: [],
  selectedManagerNoteIndex: 0,
  managerTeam: [],
  requesting: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MANAGER_SEND_NOTIFICATION_REQUESTING:
    case MANAGER_EDIT_SCHEDULED_NOTIFICATION_REQUESTING:
    case MANAGER_DELETE_SCHEDULED_NOTIFICATION_REQUESTING:
    case MANAGER_DELETE_SURVEY_REQUESTING:
    case MANAGER_CREATE_SURVEY_REQUESTING:
    case MANAGER_GET_SURVEYS_REQUESTING:
    case MANAGER_MODIFY_SURVEY_REQUESTING:
    case MANAGER_DELETE_COMMENT_REQUESTING:
    case MANAGER_SUBMIT_COMMENT_REQUESTING:
    case MANAGER_GET_TEAM_REQUESTING:
    case MANAGER_CREATE_TEAM_REQUESTING:
    case MANAGER_GET_DASHBOARD_REQUESTING: {
      return {
        ...state,
        requesting: true,
      };
    }

    case MANAGER_SEE_USER_PROFILE_REQUESTING: {
      const { managerSurveys, managerSurvey, userSelected } = initialState;
      const { tabSelectedIndex = 0 } = action.payload;
      return {
        ...state,
        managerSurveys,
        managerSurvey,
        userSelected,
        tabSelected: tabSelectedIndex,
        requesting: true,
      };
    }

    case MANAGER_CHANGE_FIELD: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case MANAGER_GET_SURVEYS_SUCCESS: {
      return {
        ...state,
        requesting: false,
        selectedManagerNoteIndex: 0,
        // selectedManagerNoteIndex: action.payload.length
        //   ? action.payload.length - 1
        //   : 0,
        managerSurveys: action.payload,
      };
    }

    case MANAGER_DELETE_SURVEY_SUCCESS: {
      const managerSurveys = state.managerSurveys.filter(
        el => el._id !== action.payload.surveyId,
      );
      let selectedManagerNoteIndex = state.selectedManagerNoteIndex;
      if (selectedManagerNoteIndex > 0) {
        selectedManagerNoteIndex--;
      }
      const isCurrentSurvey =
        state.managerSurvey._id === action.payload.surveyId;
      const managerSurvey = isCurrentSurvey
        ? initialState.managerSurvey
        : state.managerSurvey;

      return {
        ...state,
        managerSurvey,
        requesting: false,
        isSnackMessage: true,
        selectedManagerNoteIndex,
        snackMessage: "Notes supprimées avec succès !",
        managerSurveys,
      };
    }

    case MANAGER_CREATE_SURVEY_SUCCESS:
      return {
        ...state,
        requesting: false,
        managerSurvey: action.payload,
        isSnackMessage: true,
        snackMessage: "Notes sauvegardées avec succès !",
      };

    case MANAGER_MODIFY_SURVEY_SUCCESS:
      return {
        ...state,
        requesting: false,
        isSnackMessage: true,
        snackMessage: "Notes sauvegardées avec succès !",
      };

    case MANAGER_ON_EDIT_GOAL: {
      return {
        ...state,
        userSelected: {
          ...state.userSelected,
          goals: { ...state.userSelected.goals, ...action.payload },
        },
      };
    }

    // case MY_USERS_MODIFY_SUCCESS: {
    //   // const userId = action.payload._id;
    //   // if (userSelected.)
    //   userSelected
    //   return {
    //     ...state,

    //   }
    // }

    case MANAGER_MODIFY_USER_SELECTED: {
      return {
        ...state,
        userSelected: { ...state.userSelected, ...action.payload },
      };
    }

    case MANAGER_GET_DASHBOARD_SUCCESS: {
      return {
        ...state,
        requesting: false,
        ...action.payload,
      };
    }

    case MANAGER_SUBMIT_COMMENT_SUCCESS: {
      return {
        ...state,
        isSnackMessage: true,
        snackMessage: "Commentaire sauvegardé !",
        requesting: false,
        userComment: "",
      };
    }

    case MANAGER_SEE_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        requesting: false,
        userSelected: { ...state.userSelected, ...action.payload },
      };
    }

    case MANAGER_GET_TEAM_SUCCESS:
      return {
        ...state,
        requesting: false,
        selectionList: action.payload,
        managerTeam: action.payload,
      };

    case MANAGER_CREATE_TEAM_SUCCESS:
      return {
        ...state,
        requesting: false,
        managerTeam: action.payload,
        isSnackMessage: true,
        snackMessage: "Équipe créée avec succès !",
      };

    case MANAGER_SELECT_TEAM_MEMBER: {
      const memberIndex = state.selectionList.findIndex(
        el => el === action.payload,
      );
      let selectionList = [];
      let snackMessage = "";
      if (memberIndex > -1) {
        selectionList = state.selectionList.filter(el => el !== action.payload);
        snackMessage = "Membre retiré de l'équipe";
      } else {
        selectionList = [...state.selectionList, action.payload];
        snackMessage = "Membre ajouté à l'équipe !";
      }
      return {
        ...state,
        selectionList,
        isSnackMessage: true,
        snackMessage,
      };
    }

    case MANAGER_GET_COMMENTS_SUCCESS: {
      return {
        ...state,
        requesting: false,
        managerComments: action.payload,
      };
    }

    case MANAGER_SUBMIT_GOALS_SUCCESS:
      return {
        ...state,
        requesting: false,
        isSnackMessage: true,
        snackMessage: "Objectifs modifiés avec succès !",
      };

    case MANAGER_SEND_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        isSnackMessage: true,
        snackMessage: "Notification envoyée !",
        requesting: false,
        notificationTitle: "",
        notificationText: "",
      };
    }

    case MANAGER_EDIT_SCHEDULED_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        isSnackMessage: true,
        snackMessage: "Notification modifiée !",
        requesting: false,
        notificationTitle: "",
        notificationText: "",
      };
    }

    case MANAGER_DELETE_SCHEDULED_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        isSnackMessage: true,
        snackMessage: "Notification supprimée !",
        requesting: false,
        notificationTitle: "",
        notificationText: "",
      };
    }

    case MANAGER_SEND_NOTIFICATION_ERROR:
    case MANAGER_EDIT_SCHEDULED_NOTIFICATION_ERROR:
    case MANAGER_DELETE_SCHEDULED_NOTIFICATION_ERROR:
    case MANAGER_GET_DASHBOARD_ERROR:
    case MANAGER_MODIFY_SURVEY_ERROR:
    case MANAGER_DELETE_SURVEY_ERROR:
    case MANAGER_GET_SURVEYS_ERROR:
    case MANAGER_CREATE_SURVEY_ERROR:
    case MANAGER_SUBMIT_GOALS_ERROR:
    case MANAGER_DELETE_COMMENT_ERROR:
    case MANAGER_GET_COMMENTS_ERROR:
    case MANAGER_SUBMIT_COMMENT_ERROR:
    case MANAGER_SEE_USER_PROFILE_ERROR:
    case MANAGER_CREATE_TEAM_ERROR:
    case MANAGER_GET_TEAM_ERROR: {
      return { ...state, error: action.payload, requesting: false };
    }

    case MANAGER_CLOSE_SNACK_MESSAGE: {
      return { ...state, isSnackMessage: false };
    }

    case MANAGER_SELECT_TAB: {
      return { ...state, tabSelected: action.payload };
    }

    // case MANAGER_RESET_SELECTED_USER: {
    //   return { ...state, userSelected: initialState.userSelected };
    // }

    case CLEAR_COACHING_SESSION:
    case AUTH_LOGOUT_SUCCESS: {
      return initialState;
    }

    default:
      return state;
  }
};

export default reducer;
