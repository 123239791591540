import React, { ReactElement } from "react";
import { Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  main: {
    fontFamily: "Hind",
    paddingTop: 3,
    whiteSpace: "pre-line",
  },
  bold: {
    fontWeight: 600,
  },
  extraSmall: {
    fontSize: 12,
  },
  small: {
    fontSize: 14,
  },
  medium: {
    fontSize: 16,
  },
  large: {
    fontSize: 18,
  },
  extraLarge: {
    fontSize: 20,
  },
}));

interface TextProps {
  text: string | ReactElement;
  style?: {};
  size?: "extraSmall" | "small" | "medium" | "large" | "extraLarge";
  isBold?: boolean;
  className?: string;
}

const MainText = ({
  text,
  style = {},
  size = "medium",
  isBold = false,
  className = "",
}: TextProps) => {
  const classes = useStyles();

  return (
    <Typography
      style={style}
      className={`${classes.main} ${classes?.[size]} ${
        isBold ? classes.bold : {}
      } ${className}`}
    >
      {text}
    </Typography>
  );
};

export { MainText };
