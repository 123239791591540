import React, { Component } from "react";
import withStyles from '@mui/styles/withStyles';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Colors from "../../constants/Colors";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";
import {
  createMyClientRequest,
  modifyMyClientRequest,
} from "../../services/myClients/actions";
import {
  uploadPictureRequest,
  resetUpload,
} from "../../services/upload/actions";
import moment from "moment";
import { Typography } from "@mui/material";
import i18n from "../../i18n";
import {
  getUserId,
  getUserClientId,
  getIsMicrodoingAdmin,
} from "../../services/user/selectors";
import NewClientForm from "./NewClientForm";
import { getAppLanguage } from "../../services/general/selectors";
import { getIsSuperAdmin } from "services/user/selectors";
import slugify from "slugify";

moment.locale("fr");

const styles = theme => ({
  paper: {
    alignItems: "center",
    display: "flex",
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: "center",
    lineHeight: 1.46666667,
    overflowY: "auto",
    padding: "3vh 64px 32px 64px",
    borderRight: ".25rem solid transparent",
    borderRadius: 0,
    minHeight: "100%",
    minWidth: "100%",
    maxWidth: "100%",
  },
  closeButton: {
    width: 64,
    borderRadius: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    zIndex: 10,
    position: "absolute",
    marginLeft: 16,
    top: 5,
    right: 5,
    "&:hover": {
      color: Colors.black,
    },
  },
  labelIconButton: {
    flexDirection: "column",
  },
  minDiv: {
    // width: 300,
    // minWidth: 100
  },
  accent: {
    color: "#6198DE",
  },
  commentDiv: {
    width: "100%",
    borderRadius: 8,
    border: "solid",
    borderWidth: 1,
    borderColor: Colors.lightGrey,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    marginBottom: 15,
  },
  date: {
    textAlign: "right",
    fontSize: 12,
  },
  commentMainDiv: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    flexDirection: "row",
  },
  actionsButtonDiv: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  deleteButton: {
    padding: 1,
  },
  editButton: {
    padding: 1,
    marginRight: -3,
  },
  commentBottom: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    display: "flex",
  },
  leftDiv: {
    flex: 2,
    flexWrap: "wrap",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  rightDiv: {
    flex: 3,
    height: 400,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  mainDiv: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  addOptionButton: {
    marginTop: 10,
  },
  saveButton: {
    marginTop: 35,
  },
  answerSeparatorText: {
    color: Colors.blackGrey,
    fontWeight: "bold",
    whiteSpace: "nowrap",
    marginLeft: 10,
    marginRight: 10,
  },
  separator: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  line: {
    width: "100%",
    marginBottom: 25,
    borderRadius: 8,
    height: 2,
    backgroundColor: Colors.blackGrey,
  },
  errorText: {
    color: "#e6525a",
    padding: "13px 15px",
    borderRadius: 6,
    backgroundColor: "rgba(230,82,90,0.15)",
  },
  bodyDiv: {
    maxWidth: "100%",
    width: 960,
  },
  exitModalText: {
    marginTop: -4,
    fontSize: 13,
  },
  title: {
    // color: Colors.black,
    fontFamily: "Montserrat",
    textAlign: "center",
    maxWidth: "100%",
    width: 960,
    marginBottom: 25,
    fontSize: 28,
    lineHeight: 1.2143,
    fontWeight: 700,
  },
  subTitle: {
    fontFamily: "Montserrat",
    textAlign: "center",
    maxWidth: "100%",
    width: 960,
    // color: Colors.grey,
    marginBottom: 25,
    fontSize: 18,
    lineHeight: 1.2143,
    // fontWeight: 700
  },
});

class CreationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      organization: "",
      clientCreatorDescription: "",
      picture: "",
      error: "",
      isCoachingAccount: false,
    };
    this.baseState = this.state;
  }

  _handleKeyDown = event => {
    if (
      (window.navigator.platform.match("Mac")
        ? event.metaKey
        : event.ctrlKey) &&
      event.keyCode === 83
    ) {
      event.preventDefault();
    }
  };

  onRendered = () => {
    // reset state and props
    const { clientSelected } = this.props;
    this.props.resetUpload();
    if (clientSelected) {
      console.log(clientSelected);
      const { name, clientCreatorDescription, _id, logo } = clientSelected;
      console.log(clientCreatorDescription);
      setTimeout(
        () => this.setState({ clientCreatorDescription, picture: logo }),
        100,
      );
      this.setState({
        picture: logo,
        _id: _id,
        organization: name,
        clientCreatorDescription: clientCreatorDescription,
      });
    } else {
      this.setState(this.baseState);
    }
  };

  handleClose = onClose => () => {
    onClose();
  };

  componentDidUpdate = prevProps => {
    if (prevProps.picture !== this.props.picture) {
      this.setState({ picture: this.props.picture });
    }
    if (
      prevProps.requesting !== this.props.requesting &&
      !this.props.requesting
    ) {
      this.props.onClose();
    }
  };

  onClickSubmit = () => {
    const {
      organization,
      clientCreatorDescription,
      picture,
      isCoachingAccount,
    } = this.state;
    const { clientId, defaultUserSkills, hasCustomOnboarding } = this.props;

    this.props.createMyClientRequest({
      clientCreatorId: clientId,
      organization,
      defaultUserSkills,
      hasCustomOnboarding: isCoachingAccount ? true : hasCustomOnboarding,
      clientCreatorDescription,
      isCoachingAccount,
      logo: picture,
    });
  };

  onClickModify = () => {
    const { organization, clientCreatorDescription, picture, _id } = this.state;
    this.props.modifyMyClientRequest({
      name: organization,
      clientCreatorDescription,
      logo: picture,
      clientId: _id,
      clientCreatorId: this.props.clientId,
    });
  };

  onChangeInput = key => event => {
    const value = event.target.value;
    this.setState({ [key]: value });
  };

  onChangeIsCoachingAccount = isCoachingAccount => {
    this.setState({ isCoachingAccount });
  };

  onFileUploaded = async files => {
    const { clientId } = this.props;

    const date = new Date().getTime();
    await this.props.uploadPictureRequest({
      urlName: slugify(`client-logo/${clientId}/${date}`),
      file: files[0],
    });
  };

  render() {
    const {
      classes,
      isVisible,
      onClose,
      clientSelected,
      isSuperAdmin,
    } = this.props;
    return (
      <Dialog
        open={isVisible}
        ref={this.onRendered}
        onClose={this.handleClose(onClose)}
        classes={{ paper: classes.paper }}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        <IconButton
          className={classes.closeButton}
          classes={{ label: classes.labelIconButton }}
          onClick={onClose}
          size="large">
          <CloseIcon fontSize="large" />
          <Typography className={classes.exitModalText}>échap</Typography>
        </IconButton>
        <Typography className={classes.title}>
          {clientSelected ? i18n.t("modify-client") : i18n.t("create-client")}
        </Typography>
        <DialogContent className={classes.bodyDiv}>
          <div className={classes.line} />
          <NewClientForm
            requesting={this.props.requesting}
            buttonText={clientSelected ? i18n.t("save") : i18n.t("create")}
            onFileUploaded={this.onFileUploaded}
            picture={this.state.picture}
            onClickSubmit={
              clientSelected ? this.onClickModify : this.onClickSubmit
            }
            language={this.props.language}
            onChangeClientCreatorDescription={this.onChangeInput(
              "clientCreatorDescription",
            )}
            onChangeOrganization={this.onChangeInput("organization")}
            onClickPrevious={this.handleClose(onClose)}
            clientCreatorDescription={this.state.clientCreatorDescription}
            organization={this.state.organization}
            isCoachingAccount={this.state.isCoachingAccount}
            onChangeIsCoachingAccount={this.onChangeIsCoachingAccount}
            isSuperAdmin={isSuperAdmin}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  const { requesting } = state.myClients;
  const {
    plan,
    defaultUserSkills = [],
    hasCustomOnboarding = false,
  } = state.client;
  const language = getAppLanguage(state);
  const clientId = getUserClientId(state);
  const userId = getUserId(state);
  const { uploading, picture } = state.upload;
  const isSuperAdmin = getIsSuperAdmin(state) || getIsMicrodoingAdmin(state);
  return {
    clientId,
    isSuperAdmin,
    userId,
    requesting,
    language,
    pictureUploading: uploading,
    picture,
    hasCustomOnboarding,
    defaultUserSkills,
    plan,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    createMyClientRequest,
    uploadPictureRequest,
    modifyMyClientRequest,
    resetUpload,
  })(CreationModal),
);
