import React from "react";
import QuoteIcon from "./QuoteIcon";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import getFileFormat from "utils/getFileFormat";
import ReviewFile from "scenes/Campaigns/CampaignScreen/ReviewsSide/ReviewFile";
import { MainText } from "components/Texts/MainText";

const useStyles = makeStyles(theme => ({
  main: {
    width: "100%",
    boxSizing: "border-box",
    position: "relative",
    display: "inline-block",
    backgroundColor: "#FAFAFC",
    borderRadius: 10,
    marginTop: 30,
    paddingTop: 25,
    paddingLeft: 30,
    paddingRight: 25,
  },
  quoteIcon: {
    left: 20,
    top: -15,
    position: "absolute",
  },
  date: {
    color: "#BEC6FF",
    textAlign: "end",
    marginBottom: 10,
  },
}));

const FilesFeedback = ({
  file = {
    fileUrl: "",
    fileType: "",
    filePreview: "",
    fileName: "",
    createdAt: "",
  },
  className = "",
}) => {
  const fileType = getFileFormat(file.fileType);

  const classes = useStyles();

  return (
    <>
      {!!fileType && (
        <div className={`${classes.main} ${className}`}>
          <div className={classes.quoteIcon}>
            <QuoteIcon />
          </div>
          <ReviewFile
            createdAt={file.createdAt}
            fileName={file.fileName}
            fileType={fileType}
            fileUrl={file.fileUrl}
            filePreview={file.filePreview}
          />
          <MainText
            className={classes.date}
            text={file.createdAt && moment(file.createdAt).format("L")}
          />
        </div>
      )}
    </>
  );
};

export default FilesFeedback;
