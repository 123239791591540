import React from "react";

const QuoteIcon = props => (
  <svg
    width="30"
    height="26"
    viewBox="0 0 30 26"
    className="ico-quote"
    {...props}
  >
    <path
      d="M0.235352 12.9099V25.515H12.8404V12.9099H4.43707C4.43707 8.27637 8.20691 4.50653 12.8404 4.50653V0.30481C5.88958 0.30481 0.235352 5.95903 0.235352 12.9099Z"
      fill="#17AFFC"
    />
    <path
      d="M29.6471 4.50653V0.30481C22.6962 0.30481 17.042 5.95903 17.042 12.9099V25.515H29.6471V12.9099H21.2437C21.2437 8.27637 25.0136 4.50653 29.6471 4.50653Z"
      fill="#17AFFC"
    />
  </svg>
);

export default QuoteIcon;
