import React, { PureComponent } from "react";
import { connect } from "react-redux";

import withStyles from '@mui/styles/withStyles';

import Colors from "../../constants/Colors";

import { logout } from "../../services/auth/reducer";
import { getSelectedClientId } from "../../services/user/selectors";
import { modifyMyClientRequest } from "../../services/myClients/actions";
import {
  changeField,
  getClientRequest,
  modifyClientRequest,
} from "../../services/client/actions";
import { uploadPictureRequest } from "../../services/upload/actions";
import { changeGeneralSettings } from "../../services/general/actions";
import i18n from "../../i18n";
import { Typography, TextField } from "@mui/material";
import { getAppLanguage } from "../../services/general/selectors";
import RoundButton from "../../components/RoundButton";
import Uploader from "../../components/Uploader";
import Switch from "react-switch";
import { getSelectedClient } from "../../services/myClients/selectors";
import slugify from "slugify";

const styles = theme => ({
  main: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    width: "100%",
    minHeight: 500,
    paddingRight: 45,
    paddingLeft: 20,
    marginTop: theme.spacing.unit * 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "column",
    // backgroundColor: "#FAFAFA",
    backgroundColor: "#fff",
    // backgroundColor: "#fff",
    overflowX: "auto",
  },
  button: {
    backgroundColor: Colors.pink,
  },
  languageDiv: {
    marginTop: 10,
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
  },
  title: {
    fontFamily: "Montserrat",
    // color: Colors.black,
    marginBottom: 10,
    fontSize: 28,
    lineHeight: 1.2143,
    fontWeight: 700,
  },
  subTitle: {
    // color: Colors.black,
    fontFamily: "Montserrat",
    marginTop: 20,
    marginBottom: 5,
    fontSize: 22,
    lineHeight: 1.2143,
    fontWeight: 700,
  },
  textField: {
    maxWidth: 300,
    minWidth: "33%",
  },
  uploader: {
    marginTop: 16,
  },
  saveButton: {
    marginTop: 25,
  },
  logoutButton: {
    marginTop: 15,
  },
  explanation: {
    marginTop: 5,
  },
  autoInviteDiv: {
    paddingTop: 15,
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "row",
  },
});

class Recap extends PureComponent {
  state = {
    whiteLabel: {},
    ...this.props,
  };

  logout = () => {
    this.props.logout();
  };

  componentDidUpdate = prevProps => {
    if (prevProps.uploadedPicture !== this.props.uploadedPicture) {
      const whiteLabel = { ...this.state.whiteLabel };
      whiteLabel.logo = this.props.uploadedPicture;
      this.setState({ whiteLabel });
    }
  };

  onSelectLanguage = countryCode => {
    if (countryCode === "GB") {
      this.props.changeGeneralSettings({ language: "en" });
      i18n.changeLanguage("en");
    } else if (countryCode === "FR") {
      this.props.changeGeneralSettings({ language: "fr" });
      i18n.changeLanguage("fr");
    }
  };

  saveChanges = () => {
    const { clientId } = this.props;
    const { whiteLabel } = this.state;
    this.props.modifyMyClientRequest({ clientId, whiteLabel });
  };

  onChangeClientParams = param => event => {
    const value = event.target.value;
    const whiteLabel = { ...this.state.whiteLabel };
    whiteLabel[param] = value;
    this.setState({ whiteLabel });
  };

  toggleWhiteLabel = () => {
    const hasWhiteLabel = !this.state.whiteLabel.hasWhiteLabel;
    const whiteLabel = { ...this.state.whiteLabel };
    whiteLabel.hasWhiteLabel = hasWhiteLabel;
    this.setState({ whiteLabel });
  };

  onFileUploaded = async files => {
    const { clientId } = this.props;

    const date = new Date().getTime();
    await this.props.uploadPictureRequest({
      urlName: slugify(`client-logo/${clientId}/${date}`),
      file: files[0],
    });
  };

  render() {
    const { classes, uploading } = this.props;
    const { whiteLabel } = this.state;
    const {
      logo,
      appName,
      iosAppLink,
      androidAppLink,
      hasWhiteLabel,
    } = whiteLabel;
    console.log(this.state);
    console.log(hasWhiteLabel);
    return (
      <div className={classes.main}>
        <div className={classes.root}>
          <Typography className={classes.subTitle}>
            {i18n.t("has-white-label")}
          </Typography>
          <div className={classes.autoInviteDiv}>
            <Switch onChange={this.toggleWhiteLabel} checked={hasWhiteLabel} />
          </div>
          <Typography className={classes.subTitle}>
            {i18n.t("app-name-description")}
          </Typography>
          <TextField
            fullWidth
            className={classes.textField}
            label={i18n.t("app-name-description")}
            value={appName}
            onChange={this.onChangeClientParams("appName")}
            margin="normal"
            variant="outlined"
          />
          <Typography className={classes.subTitle}>
            {i18n.t("ios-app-link-description")}
          </Typography>
          <TextField
            fullWidth
            className={classes.textField}
            label={i18n.t("ios-app-link-description")}
            value={iosAppLink}
            onChange={this.onChangeClientParams("iosAppLink")}
            margin="normal"
            variant="outlined"
          />
          <Typography className={classes.subTitle}>
            {i18n.t("android-app-link-description")}
          </Typography>
          <TextField
            fullWidth
            className={classes.textField}
            label={i18n.t("android-app-link-description")}
            value={androidAppLink}
            onChange={this.onChangeClientParams("androidAppLink")}
            margin="normal"
            variant="outlined"
          />

          <Typography className={classes.subTitle}>
            {i18n.t("white-label-logo")}
          </Typography>
          <Typography className={classes.explanation}>
            {i18n.t("white-label-logo-explanation")}
          </Typography>
          <Uploader
            placeholder={i18n.t("upload-white-label-logo")}
            width={100}
            height={100}
            picture={logo}
            className={classes.uploader}
            onFileUploaded={this.onFileUploaded}
            uploading={uploading}
          />
          <RoundButton
            onClick={this.saveChanges}
            className={classes.saveButton}
            title={i18n.t("save-changes")}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const language = getAppLanguage(state);
  const clientId = getSelectedClientId(state);
  const { uploading } = state.upload;
  const selectedClient = getSelectedClient(state);
  const { name, logo, whiteLabel } = selectedClient;
  const uploadedPicture = state.upload.picture;
  return {
    language,
    clientId,
    name,
    logo,
    uploadedPicture,
    uploading,
    whiteLabel: whiteLabel || {},
  };
};

export default connect(mapStateToProps, {
  logout,
  changeGeneralSettings,
  changeField,
  getClientRequest,
  modifyClientRequest,
  modifyMyClientRequest,
  uploadPictureRequest,
})(withStyles(styles)(Recap));
