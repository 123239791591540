import React from "react";
import { TextField, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import i18n from "i18n";
import InfoCheck from "scenes/Invoice/InvoicePage/InfoCheck";
import FlatButton from "components/FlatButton";
import SelectCoachee from "./SelectCoachee";

const WIDTH = 250;

const useStyles = makeStyles(theme => ({
  main: {
    right: 0,
    position: "absolute",
    width: WIDTH,
    padding: "15px 25px",
    borderRadius: 4,
    boxShadow: "0px 4px 4px 0px rgba(190, 190, 190, 0.25)",
    backgroundColor: "#FFFFFF",
    marginLeft: 20,
    height: "fit-content",
  },
  title: {
    color: "rgba(32, 32, 32, 1)",
    fontWeight: 600,
    marginBottom: 10,
    marginTop: 5,
    fontSize: 16,
    fontFamily: "Montserrat",
    // fontFamily: "Roboto",
  },
  separator: {
    marginLeft: -25,
    border: "1px solid #ECECEC",
    width: WIDTH,
    marginTop: 25,
    marginBottom: 15,
  },
  textField: {
    marginTop: 0,
  },
  button: {
    marginTop: 20,
    marginBottom: 5,
  },
}));

interface Props {
  companyName: string;
  clientName: string;
  onCreateInvoice: () => void;
  productLines: any[];
  invoiceTitle: string;
  invoiceName: string;
  companyAddress: string;
  clientAddress: string;
  coacheeId?: string;
  setInvoiceName: (name: string) => void;
  setCoacheeId: (coacheeId: string) => void;
}

const RecapInvoicePage = ({
  companyName,
  clientName,
  onCreateInvoice,
  productLines,
  invoiceTitle,
  invoiceName,
  companyAddress,
  clientAddress,
  coacheeId,
  setInvoiceName,
  setCoacheeId,
}: Props) => {
  const classes = useStyles();

  const onChangeInvoiceName = e => {
    const value = e.target.value;
    setInvoiceName(value);
  };

  const onSelectCoachee = coacheeId => {
    setCoacheeId(coacheeId);
  };

  return (
    <div className={classes.main}>
      <Typography className={classes.title}>
        {i18n.t("invoice-title")}
      </Typography>
      <TextField
        className={classes.textField}
        fullWidth
        placeholder={i18n.t("invoice-title")}
        value={invoiceName}
        onChange={onChangeInvoiceName}
        margin="normal"
        variant="outlined"
      />
      <Typography className={classes.title}>{i18n.t("coachee")}</Typography>
      <SelectCoachee onSelectCoachee={onSelectCoachee} coacheeId={coacheeId} />
      <div className={classes.separator} />
      <Typography className={classes.title}>
        {i18n.t("invoice-details")}
      </Typography>
      <InfoCheck info={i18n.t("invoice-title")} isChecked={!!invoiceName} />
      <InfoCheck
        info={i18n.t("invoice-companyName")}
        isChecked={!!companyName}
      />
      <InfoCheck
        info={i18n.t("invoice-companyAddress")}
        isChecked={!!companyAddress}
      />
      <InfoCheck
        info={i18n.t("invoice-clientName-required")}
        isChecked={!!clientName}
      />
      <InfoCheck
        info={i18n.t("client-address-required")}
        isChecked={!!clientAddress}
      />
      <InfoCheck
        info={i18n.t("invoice-name-required")}
        isChecked={!!invoiceTitle}
      />
      <InfoCheck
        info={i18n.t("invoice-item-required")}
        isChecked={!!productLines.length && !!productLines[0].description}
      />
      <FlatButton
        onClick={onCreateInvoice}
        className={classes.button}
        title={i18n.t("create-invoice")}
        fullWidth
        disabled={
          !invoiceName ||
          !companyName ||
          !clientName ||
          !invoiceTitle ||
          !productLines.length ||
          !productLines[0].description ||
          !clientAddress ||
          !companyAddress
        }
      />
    </div>
  );
};

export default RecapInvoicePage;
