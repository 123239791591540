export const USER_RECEIVED = "USER_RECEIVED";

export const MY_USERS_GET_LIST_ERROR = "MY_USERS_GET_LIST_ERROR";
export const MY_USERS_GET_LIST_SUCCESS = "MY_USERS_GET_LIST_SUCCESS";
export const MY_USERS_GET_LIST_REQUESTING = "MY_USERS_GET_LIST_REQUESTING";

export const MY_USERS_LOAD_MORE_ERROR = "MY_USERS_LOAD_MORE_ERROR";
export const MY_USERS_LOAD_MORE_SUCCESS = "MY_USERS_LOAD_MORE_SUCCESS";
export const MY_USERS_LOAD_MORE_REQUESTING = "MY_USERS_LOAD_MORE_REQUESTING";

export const MY_USERS_MODIFY_REQUESTING = "MY_USERS_MODIFY_REQUESTING";
export const MY_USERS_MODIFY_SUCCESS = "MY_USERS_MODIFY_SUCCESS";
export const MY_USERS_MODIFY_ERROR = "MY_USERS_MODIFY_ERROR";

export const MY_USERS_DELETE_REQUESTING = "MY_USERS_DELETE_REQUESTING";
export const MY_USERS_DELETE_SUCCESS = "MY_USERS_DELETE_SUCCESS";
export const MY_USERS_DELETE_ERROR = "MY_USERS_DELETE_ERROR";

export const MY_USERS_GET_ERROR = "MY_USERS_GET_ERROR";
export const MY_USERS_GET_SUCCESS = "MY_USERS_GET_SUCCESS";
export const MY_USERS_GET_REQUESTING = "MY_USERS_GET_REQUESTING";

export const MY_USERS_CHANGE_FIELD = "MY_USERS_CHANGE_FIELD";

export const GENERATE_DYNAMIC_AUTH_LINK_REQUESTING =
  "GENERATE_DYNAMIC_AUTH_LINK_REQUESTING";
export const GENERATE_DYNAMIC_AUTH_LINK_SUCCESS =
  "GENERATE_DYNAMIC_AUTH_LINK_SUCCESS";
export const GENERATE_DYNAMIC_AUTH_LINK_ERROR =
  "GENERATE_DYNAMIC_AUTH_LINK_ERROR";

export const MY_USERS_UPDATE_ROLE_REQUESTING =
  "MY_USERS_UPDATE_ROLE_REQUESTING";
