import React from "react";
import withStyles from '@mui/styles/withStyles';
import ArrowIcon from "scenes/Campaigns/CampaignScreen/StatsSide/FeelingSection/ArrowIcon";

const styles = theme => ({
  main: {
    borderRadius: "50%",
    height: 30,
    width: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "absolute",
    top: -10,
    right: -2,
    border: "solid 2px #fff",
    backgroundColor: "#fff",
  },
  negative: {
    transform: "rotate(180deg)",
  },
});

const FeelingArrow = ({ classes, isNegative = false }) => {
  return (
    <div className={`${classes.main} ${isNegative && classes.negative}`}>
      <ArrowIcon color={isNegative ? "#EE1E1E" : "#58DE7F"} />
    </div>
  );
};

export default withStyles(styles)(FeelingArrow);
