import React, { useEffect, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import { TextField, Typography } from "@mui/material";
import i18n from "i18n";
import Colors from "constants/Colors";
import { Loader } from "components";
import PdfUploader from "./PdfUploader";
import { uploadFileRequest } from "services/upload/actions";
import { connect } from "react-redux";
import { getUserId } from "services/user/selectors";
import slugify from "slugify";
import ResourceStats from "scenes/Campaigns/SeeUserModal/Coaching/Resources/ResourceStats";
import MyLink from "components/MyLink";

const styles = theme => ({
  resource: {
    paddingLeft: 30,
    paddingRight: 30,
    display: "flex",
    flexDirection: "column",
    paddingTop: 8,
    paddingBottom: 8,
  },
  title: {
    height: "auto",
    paddingRight: 45,
    textAlign: "left",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    color: Colors.darkBlue,
    fontSize: 28,
    lineHeight: "32px",
    marginBottom: 10,
  },
  link: {
    height: "auto",
    paddingRight: 45,
    textAlign: "left",
    color: Colors.darkBlue,
    fontSize: 14,
  },
  stats: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    width: "100%",
    paddingLeft: -15,
  },
  error: {
    color: "red",
  },
  seeResource: {},
});

const SetResourceParams = ({
  classes,
  resourceType,
  onChangeTitle,
  onChangeLink,
  error,
  setLink,
  userId,
  fileUrl,
  uploading,
  fileName,
  uploadFileName,
  resourceTitle,
  uploadFileRequest,
  onChangeFileName,
  totalViews,
  totalComments,
  comment,
  comments = [],
  rating = 0,
  link = "",
}) => {
  const [linkPreview] = useState({});
  const [loading] = useState(false);

  useEffect(() => {
    if (fileUrl) {
      setLink(fileUrl);
      onChangeFileName(uploadFileName);
    }
    if (uploadFileName && !resourceTitle) {
      onChangeTitle({ target: { value: uploadFileName } });
    }
  }, [
    fileUrl,
    setLink,
    onChangeFileName,
    onChangeTitle,
    resourceTitle,
    uploadFileName,
  ]);

  const onFileUploaded = async files => {
    const date = new Date().toISOString().toString();

    if (!files || !files[0]) {
      return;
    }

    await uploadFileRequest({
      fileType: "pdf",
      urlName: slugify(`file/${userId}/${date}`),
      fileName: files[0].path,
      file: files[0],
    });
  };

  return (
    <div className={classes.resource}>
      {rating > 0 && (
        <div className={classes.stats}>
          <ResourceStats
            isSmall={true}
            totalComments={totalComments}
            totalViews={totalViews}
            rating={rating}
            comment={comment}
            comments={comments}
            hasComment={!!comment || comments.length}
          />
        </div>
      )}
      <TextField
        variant="standard"
        InputProps={{
          disableUnderline: true,
          classes: {
            input: classes.title,
          },
        }}
        autoFocus={true}
        fullWidth
        title={i18n.t("resource-title")}
        placeholder={i18n.t("resource-title")}
        value={resourceTitle}
        onChange={onChangeTitle}
        margin="normal"
      />
      {resourceType === "pdf" ? (
        <>
          <PdfUploader
            width={600}
            height={250}
            onFileUploaded={onFileUploaded}
            fileName={fileName}
            uploading={uploading}
          />
        </>
      ) : (
        <>
          <TextField
            variant="standard"
            InputProps={{
              classes: {
                input: classes.link,
              },
            }}
            fullWidth
            title={
              resourceType === "webLink"
                ? i18n.t("placeholder-web-link")
                : i18n.t("placeholder-video")
            }
            placeholder={
              resourceType === "webLink"
                ? i18n.t("placeholder-web-link")
                : i18n.t("placeholder-video")
            }
            value={link}
            onChange={onChangeLink}
            margin="normal"
          />
          {!!error && (
            <Typography className={classes.error}>{error}</Typography>
          )}

          <div className={classes.preview}>
            {loading && <Loader />}
            <img
              src={linkPreview?.image?.url}
              className={classes.image}
              alt={linkPreview.description}
            />
            <Typography className={classes.linkDescription}>
              {linkPreview.description}
            </Typography>
          </div>
        </>
      )}
      {!!link && (
        <MyLink
          href={link}
          text={i18n.t("see-resource")}
          className={classes.seeResource}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userId: getUserId(state),
    fileUrl: state.upload.fileUrl,
    uploading: state.upload.uploading,
    uploadFileName: state.upload.fileName,
  };
};

export default connect(mapStateToProps, { uploadFileRequest })(
  withStyles(styles)(SetResourceParams),
);
