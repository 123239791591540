import React from "react";
import withStyles from '@mui/styles/withStyles';
import AdministrationBox from "components/AdministrationMode/AdministrationBox";
import { Typography } from "@mui/material";
import CopyBox from "./CopyBox";

const styles = theme => ({
  main: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    marginBottom: 30,
  },
  middleDiv: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
  },
  linkDiv: {
    marginTop: 5,
    marginBottom: 5,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  name: {
    fontWeight: "bold",
    fontSize: 14,
    fontFamily: "Montserrat",
  },
});

const IconRow = ({ classes, icon, text, supports = [] }) => {
  return (
    <div className={classes.main}>
      <AdministrationBox icon={icon} text={text} notSelectable />
      <div className={classes.middleDiv}>
        {supports.map((el, index) => (
          <div key={index}>
            <div className={classes.linkDiv}>
              <Typography className={classes.name}>{el.name}</Typography>
              <CopyBox link={el.link} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default withStyles(styles)(IconRow);
