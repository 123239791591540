import React from "react";
import { Radio, RadioProps } from "@mui/material";
import { makeStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import Colors from "constants/Colors";
import { MainText } from "./Texts/MainText";

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    flex: 1,
    paddingVertical: 20,
    display: "flex",
    height: 55,
    borderStyle: "solid",
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.lightGrey,
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    opacity: 0.8,
  },
  text: {
    flex: 1,
    width: "100%",
    justifyContent: "flex-start",
    color: "#7c7c7c",
    marginLeft: 5,
  },
  selected: {
    color: Colors.pureMain,
    borderColor: Colors.pureMain,
    opacity: 1,
  },
  checkIcon: {
    justifyContent: "flex-end",
    paddingRight: 30,
  },
}));

interface Props {
  text: string;
  isSelected: boolean;
  onPress(): void;
  className?: string;
  icon: any;
}

const CheckedRadio = withStyles({
  root: {
    marginLeft: 10,
    color: Colors.grey,
    "&$checked": {
      color: Colors.pureMain,
    },
    "&:hover": { background: "none" },
  },
  checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

const CheckRow = ({
  text = "",
  isSelected = false,
  onPress = () => {},
  className = "",
  icon = {},
}: Props) => {
  const classes = useStyles();

  return (
    <div
      className={`${classes.container} ${
        isSelected && classes.selected
      } ${className}`}
      onClick={onPress}
    >
      <CheckedRadio checked={isSelected} />
      <MainText
        size="medium"
        text={text}
        className={`${classes.text} ${isSelected && classes.selected}`}
      />
      <div className={classes.checkIcon}>{icon}</div>
    </div>
  );
};

export default CheckRow;
