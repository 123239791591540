import { createSelector } from "reselect";

export const getSelectedClient = state => {
  const selectedClientId = state.myClients.selectedClientId;
  if (selectedClientId) {
    const client = state.myClients.selectedClient;
    if (client) {
      return client;
    }
  }
  return state.client;
};

export const getSelectedClientName = createSelector(
  getSelectedClient,
  selectedClient => {
    return selectedClient.name;
  },
);

export const getCreatorClientId = createSelector(
  getSelectedClient,
  selectedClient => {
    return selectedClient.clientCreatorId;
  },
);

// export const getAccessCode = (state) => {
//   // campaign has set up a specific accessCode and inviteModal opened
//   const { accessCode, isInviteUsersModalVisible } = state.general;
//   if (accessCode && isInviteUsersModalVisible) {
//     return accessCode;
//   }
//   const client = getSelectedClient(state);
//   return client.accessCode;
// };

const getGeneralAccessCode = state => state.general.accessCode;
const getIsInviteUsersModalVisible = state =>
  state.general.isInviteUsersModalVisible;

export const getAccessCode = createSelector(
  getGeneralAccessCode,
  getIsInviteUsersModalVisible,
  getSelectedClient,
  (accessCode, isInviteUsersModalVisible, client) => {
    // campaign has set up a specific accessCode and inviteModal opened
    if (accessCode && isInviteUsersModalVisible) {
      return accessCode;
    }
    return client.accessCode;
  },
);

export const getSelectedClientIsEnterpriseAccount = state => {
  const client = getSelectedClient(state);
  return client.isEnterpriseAccount;
};

export const getSelectedClientIsBtoCAccount = state => {
  const client = getSelectedClient(state);
  return client?.isBtoCAccount || false;
};
