import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Colors from "../../constants/Colors";

import { connect } from "react-redux";
import i18n from "../../i18n";

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: "90%",
  },
  tabsRoot: {
    borderBottom: "1px solid #e8e8e8",
  },
  tabsIndicator: {
    backgroundColor: Colors.pureMain,
  },
  tabRoot: {
    textTransform: "initial",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing.unit * 4,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: Colors.pureMain,
      opacity: 1,
    },
    "&$tabSelected": {
      color: Colors.pureMain,
      // fontWeight: "bold"
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: Colors.pureMain,
    },
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing.unit * 3,
  },
});

class CustomizedTabs extends React.Component {
  handleChange = (event, value) => {
    this.props.onChangeTab(value)();
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Tabs
          value={this.props.tabSelected}
          onChange={this.handleChange}
          classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
        >
          <Tab
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label={i18n.t("tab-my-users")}
          />
          <Tab
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label={i18n.t("tab-my-users-pending")}
          />
        </Tabs>
      </div>
    );
  }
}

CustomizedTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(withStyles(styles)(CustomizedTabs));
