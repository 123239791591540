import React from "react";

const CheckIcon = ({ sizePercentage, color, className }) => (
  <svg
    className={className}
    width={sizePercentage * 14}
    height={sizePercentage * 14}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 14 14"
    version="1.1"
  >
    <polygon
      fill={color}
      points="5.5 11.9993304 14 3.49933039 12.5 2 5.5 8.99933039 1.5 4.9968652 0 6.49933039"
    ></polygon>
  </svg>
);

CheckIcon.defaultProps = {
  sizePercentage: 1,
  color: "black",
  className: {},
};

export default CheckIcon;
