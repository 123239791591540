import React from "react";
import withStyles from "@mui/styles/withStyles";
import Colors from "constants/Colors";
import { Typography, TextField } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Loader } from "components";
import CheckIcon from "scenes/CreateTrack/CheckIcon";

import { changeField } from "services/survey/actions";
import { connect } from "react-redux";

import i18n from "i18n";

const styles = theme => ({
  main: {
    width: "100%",
    height: 60,
    backgroundColor: Colors.darkBlue,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  title: {
    marginTop: 0,
    marginBottom: 0,
    fontFamily: "Montserrat",
    fontSize: 22,
    fontWeight: "bold",
    color: "#fff",
    marginLeft: 5,
  },
  leftDiv: {
    color: Colors.white,
    display: "flex",
    flex: 1,
    paddingLeft: 15,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  logoContainer: {
    paddingLeft: 5,
    cursor: "pointer",
    // backgroundColor: Colors.black,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: "solid 1px #fff",
    flexDirection: "row",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 0.2s",
    borderRadius: 4,
    "&:hover": {
      background: Colors.lightGrey,
    },
  },
  publishButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: 4,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 8,
    paddingBottom: 8,
    marginRight: 15,
    marginLeft: 20,
    backgroundColor: Colors.pureMain,
    transition: "all 0.2s",
    "&:hover": {
      opacity: 0.85,
    },
  },
  publishText: {
    color: Colors.white,
    // fontWeight: "bold",
    marginLeft: 10,
    fontSize: 16,
  },
  savingText: {
    fontSize: "1.1em",
    color: Colors.lightWhite,
    fontStyle: "italic",
    // textDecoration: "underline"
  },
  steps: {
    // fontWeight: "bold",
    color: Colors.lightWhite,
    fontSize: "0.9em",
  },
  disabledButton: {
    backgroundColor: Colors.mediumGrey,
    "&:hover": {
      opacity: 1,
    },
  },
  disabledText: {
    fontWeight: "normal",
    color: Colors.hardGrey,
  },
  text: {
    fontFamily: "Montserrat",
    fontSize: "1.1em",
    fontWeight: 700,
    marginLeft: 12,
    // color: "white",
    color: "white",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  rightDiv: {
    flex: 1,
    color: Colors.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row",
  },
  previous: {
    marginRight: 15,
    fontSize: 16,
  },
  noMargin: {
    marginTop: 0,
    marginBottom: 0,
  },
});

const Header = ({
  classes,
  surveyTitle = {},
  onClickPrevious = () => null,
  onClickPublishButton = () => null,
  saving = false,
  requesting = false,
  isPublishButtonDisabled = false,
  languageSelected = "fr",
  buttonTitle = "",
  changeField,
}) => {
  const onChangeTitle = e => {
    const { value } = e.target;
    const title = { ...surveyTitle, [languageSelected]: value };
    changeField({ title });
  };

  return (
    <div className={classes.main}>
      <div className={classes.leftDiv}>
        <div onClick={onClickPrevious} className={classes.logoContainer}>
          <ChevronLeftIcon
            style={{ color: "#fff", marginLeft: -5, fontSize: 32 }}
          />
          <Typography className={classes.previous}>
            {i18n.t("previous")}
          </Typography>
        </div>
        <TextField
          variant="standard"
          InputProps={{
            disableUnderline: true,
            classes: {
              input: classes.title,
              root: classes.noMargin,
            },
          }}
          className={classes.title}
          autoFocus
          fullWidth
          title={i18n.t("survey-title", {
            lng: languageSelected,
          })}
          placeholder={i18n.t("survey-title", {
            lng: languageSelected,
          })}
          value={
            surveyTitle && surveyTitle[languageSelected]
              ? surveyTitle[languageSelected]
              : ""
          }
          onChange={onChangeTitle}
          margin="normal"
        />
      </div>
      <div className={classes.rightDiv}>
        <Typography className={classes.savingText}>
          {saving ? i18n.t("saving") : i18n.t("saved")}
        </Typography>
        {requesting ? (
          <Loader size={50} />
        ) : (
          <div
            className={`${classes.publishButton} ${
              isPublishButtonDisabled && classes.disabledButton
            }`}
            data-intercom-target="Publish Survey"
            onClick={onClickPublishButton}
          >
            <CheckIcon />
            <Typography
              className={`${classes.publishText} ${
                isPublishButtonDisabled && classes.disabledText
              }`}
            >
              {buttonTitle}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { saving, requesting, languageSelected, title } = state.survey;
  return {
    languageSelected,
    surveyTitle: title,
    saving,
    requesting,
  };
};

export default connect(mapStateToProps, { changeField })(
  withStyles(styles)(Header),
);
