import {
  ADD_QUESTION,
  REMOVE_QUESTION,
  CHANGE_QUESTION_SELECTED,
  QUESTION_CREATE_REQUESTING,
  SURVEY_CREATE_REQUESTING,
  SURVEY_MODIFY_REQUESTING,
  QUESTION_MODIFY_REQUESTING,
  SAVE_SURVEY_REQUESTING,
  SURVEY_SET,
  REORDER_QUESTIONS,
  DUPLICATE_QUESTION,
  CREATE_NEW_QUESTION,
  SURVEY_CHANGE_FIELD,
  GET_SURVEYS_REQUESTING,
  NEW_SURVEY,
  SURVEY_DELETE_REQUESTING,
  GET_CAMPAIGN_SURVEYS_REQUESTING,
  SEND_SURVEY_REQUESTING,
  SEND_EVOLUTION_SURVEY_REQUESTING,
  GET_EVOLUTION_SURVEY_ANSWERS_REQUESTING,
} from "./constants";

type stepType = {
  _id: string;
  content: {};
  title: string;
};

export const changeField = payload => {
  return {
    type: SURVEY_CHANGE_FIELD,
    payload,
  };
};

export const changeQuestionSelected = payload => {
  return {
    type: CHANGE_QUESTION_SELECTED,
    payload,
  };
};

export const removeQuestion = (payload: number) => {
  return {
    type: REMOVE_QUESTION,
    payload,
  };
};

export const addQuestion = (payload: stepType) => {
  return {
    type: ADD_QUESTION,
    payload,
  };
};

export const duplicateQuestion = (payload: stepType) => {
  return {
    type: DUPLICATE_QUESTION,
    payload,
  };
};

export const createQuestionRequest = (payload: stepType) => {
  return {
    type: QUESTION_CREATE_REQUESTING,
    payload,
  };
};

export const modifyQuestionRequest = (payload: { stepId: string }) => {
  return {
    type: QUESTION_MODIFY_REQUESTING,
    payload,
  };
};

export const deleteSurveyRequest = (payload: { surveyId: string }) => {
  return {
    type: SURVEY_DELETE_REQUESTING,
    payload,
  };
};

export const createSurveyRequest = (payload: any) => {
  return {
    type: SURVEY_CREATE_REQUESTING,
    payload,
  };
};

export const modifySurveyRequest = (payload: { trackId: string }) => {
  return {
    type: SURVEY_MODIFY_REQUESTING,
    payload,
  };
};

export const saveSurveyRequest = () => {
  return {
    type: SAVE_SURVEY_REQUESTING,
  };
};

export const setSurvey = payload => {
  return {
    type: SURVEY_SET,
    payload,
  };
};

export const createNewSurvey = payload => {
  return {
    type: NEW_SURVEY,
    payload,
  };
};

export const reorderQuestions = payload => {
  return {
    type: REORDER_QUESTIONS,
    payload,
  };
};

export const createNewQuestion = (payload: stepType) => {
  return {
    type: CREATE_NEW_QUESTION,
    payload,
  };
};

export const getAllSurveysRequest = (payload: { clientId: string }) => {
  return {
    type: GET_SURVEYS_REQUESTING,
    payload,
  };
};

export const sendSurveyRequest = (payload: { userId: string; survey: {} }) => {
  return {
    type: SEND_SURVEY_REQUESTING,
    payload,
  };
};

export const sendCampaignEvolutionSurveyRequest = (payload: {}) => {
  return {
    type: SEND_EVOLUTION_SURVEY_REQUESTING,
    payload,
  };
};

export const getSurveysByCampaignRequest = (payload: {
  campaignId: string;
  surveyId?: string;
}) => {
  return {
    type: GET_CAMPAIGN_SURVEYS_REQUESTING,
    payload,
  };
};

export const getEvolutionSurveyAnswersRequest = (payload: {}) => {
  return {
    type: GET_EVOLUTION_SURVEY_ANSWERS_REQUESTING,
    payload,
  };
};
