import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from "@mui/material";
import i18n from "i18n";
import Colors from "constants/Colors";
import Objective from "scenes/CreateTrack/Objectives/Objective";

const useStyles = makeStyles(theme => ({
  main: {
    boxShadow: "rgba(55, 53, 47, 0.16) 0px -1px inset",
    background: "white",
    paddingTop: 6,
    paddingBottom: 6,
  },
  objectiveDiv: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    paddingTop: 5,
    paddingLeft: 14,
    paddingRight: 14,
    minHeight: 28,
    userSelect: "none",
    transition: "background 120ms ease-in 0s",
    cursor: "pointer",
    width: "100%",
    "&:hover": {
      background: Colors.lightGrey,
    },
  },
  delimiter: {
    marginTop: 6,
    marginBottom: 8,
    fontSize: 12,
    paddingLeft: 14,
    paddingRight: 14,
    color: "rgba(55, 53, 47, 0.6)",
  },
  moreButton: {
    top: 0,
    padding: 5,
    position: "absolute",
    right: 2,
  },
  create: {
    marginRight: 7,
  },
  suggestions: {},
  createDiv: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    position: "relative",
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 14,
    paddingRight: 14,
    minHeight: 28,
    userSelect: "none",
    transition: "background 120ms ease-in 0s",
    cursor: "pointer",
    width: "100%",
    "&:hover": {
      background: Colors.lightGrey,
    },
  },
}));

interface Suggestion {
  value: string;
  label: string;
}

interface Props {
  search: string;
  placeholder: string;
  suggestions: Suggestion[];
  onClickSuggestion: (suggestion) => () => void;
  onSubmit: () => void;
}

const SuggestionBox = ({
  suggestions,
  onClickSuggestion,
  placeholder,
  search,
  onSubmit,
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Typography className={classes.delimiter}>{placeholder}</Typography>
      <div className={classes.suggestions}>
        {search.length > 0 && (
          <div className={classes.createDiv} onClick={onSubmit}>
            <Typography className={classes.create}>
              {i18n.t("create")}
            </Typography>
            <Objective noMargin={true} label={search} />
          </div>
        )}
        {suggestions.map((el, index) => (
          <div
            key={index}
            onClick={onClickSuggestion(el)}
            className={classes.objectiveDiv}
          >
            <Objective {...el} label={el.label} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SuggestionBox;
