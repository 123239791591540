import React from "react";

const UploaderIcon = ({ sizePercentage, color, className }) => (
  <svg
    className={className}
    width={sizePercentage * 22}
    height={sizePercentage * 17}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 22 17"
    fill="none"
  >
    <path
      d="M10.5022 7.28788C10.5199 7.26528 10.5425 7.247 10.5683 7.23443C10.5941 7.22186 10.6224 7.21533 10.6511 7.21533C10.6798 7.21533 10.7081 7.22186 10.7339 7.23443C10.7597 7.247 10.7823 7.26528 10.8 7.28788L13.4472 10.637C13.469 10.6649 13.4826 10.6984 13.4863 10.7336C13.49 10.7688 13.4837 10.8043 13.4682 10.8361C13.4526 10.8679 13.4285 10.8947 13.3984 10.9134C13.3684 10.9321 13.3337 10.942 13.2983 10.9419H11.5516V16.6712C11.5516 16.7752 11.4665 16.8603 11.3625 16.8603H9.9444C9.8404 16.8603 9.75531 16.7752 9.75531 16.6712V10.9443H8.00392C7.84556 10.9443 7.75811 10.7623 7.85502 10.6394L10.5022 7.28788Z"
      fill={color}
    />
    <path
      d="M3.57794 5.10618C4.66045 2.251 7.41872 0.220703 10.6497 0.220703C13.8807 0.220703 16.639 2.24864 17.7215 5.10381C19.747 5.63561 21.2432 7.48155 21.2432 9.67493C21.2432 12.2867 19.1278 14.402 16.5184 14.402H15.5706C15.4666 14.402 15.3815 14.317 15.3815 14.213V12.7948C15.3815 12.6908 15.4666 12.6057 15.5706 12.6057H16.5184C17.3149 12.6057 18.0642 12.289 18.622 11.7147C19.1774 11.1427 19.4729 10.3722 19.4469 9.5733C19.4256 8.94932 19.2129 8.36316 18.8276 7.86917C18.4329 7.36574 17.8798 6.99939 17.2653 6.8363L16.3695 6.60231L16.041 5.73725C15.8377 5.19835 15.5541 4.69492 15.1972 4.23875C14.8448 3.78662 14.4275 3.38917 13.9587 3.05934C12.9873 2.37627 11.8433 2.01464 10.6497 2.01464C9.45611 2.01464 8.31214 2.37627 7.34072 3.05934C6.87037 3.39023 6.45439 3.78731 6.10222 4.23875C5.74532 4.69492 5.46169 5.20072 5.25843 5.73725L4.93226 6.59994L4.03883 6.8363C2.75778 7.18138 1.862 8.34661 1.862 9.67493C1.862 10.4573 2.16689 11.1947 2.71997 11.7478C2.9912 12.0206 3.31386 12.2369 3.66926 12.3842C4.02466 12.5314 4.40574 12.6068 4.79045 12.6057H5.73823C5.84223 12.6057 5.92731 12.6908 5.92731 12.7948V14.213C5.92731 14.317 5.84223 14.402 5.73823 14.402H4.79045C2.18108 14.402 0.0656929 12.2867 0.0656929 9.67493C0.0656929 7.48391 1.5571 5.64034 3.57794 5.10618Z"
      fill={color}
    />
  </svg>
);

UploaderIcon.defaultProps = {
  sizePercentage: 1,
  color: "#535461",
  className: {},
};

export default UploaderIcon;
