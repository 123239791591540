import slugify from "slugify";

interface Params {
	userId?: string;
	fileName: string;
}

const createUploadUrlName = ({ userId, fileName }: Params): string => {
	if (!fileName && !userId) return "";

	const date = new Date().toISOString().toString().replace(".", "-");
	const urlName = slugify(`${userId}/${date}/${fileName}`).replace(
		/[^0-9a-zA-Z.]/g,
		"-",
	);
	return urlName;
};

export default createUploadUrlName;
