import { call, put, takeLatest, all } from "redux-saga/effects";

import {
  MY_CLIENTS_CREATE_NEW_ERROR,
  MY_CLIENTS_CREATE_NEW_REQUESTING,
  MY_CLIENTS_CREATE_NEW_SUCCESS,
  MY_CLIENTS_GET_ALL_REQUESTING,
  MY_CLIENTS_GET_ALL_ERROR,
  MY_CLIENTS_GET_ALL_SUCCESS,
  MY_CLIENTS_DELETE_ERROR,
  MY_CLIENTS_DELETE_SUCCESS,
  MY_CLIENTS_DELETE_REQUESTING,
  MY_CLIENTS_MODIFY_REQUESTING,
  MY_CLIENTS_MODIFY_SUCCESS,
  MY_CLIENTS_MODIFY_ERROR,
  MY_CLIENTS_GET_SELECTED_REQUESTING,
  MY_CLIENTS_GET_SELECTED_SUCCESS,
  MY_CLIENTS_GET_SELECTED_ERROR,
  MY_CLIENTS_GET_ACTIVE_USERS_REQUESTING,
  MY_CLIENTS_GET_ACTIVE_USERS_SUCCESS,
  MY_CLIENTS_GET_ACTIVE_USERS_ERROR,
} from "./constants";

import { fetchApi } from "../api";
import i18n from "../../i18n";
import { openSnackMessage } from "../general/actions";
import { getClientApi } from "../client/saga";

const apiEndpoints = {
  getAllMyClients: "/v1/clients/my-clients/all",
  createMyNewClient: "/v1/clients/my-clients/create",
  deleteMyClient: "/v1/clients/my-clients/delete",
  modifyMyClient: "/v1/clients/my-clients/modify",
  getAllActiveUsers: "/v1/clients/users/active",
};

const modifyMyClientApi = data => {
  return fetchApi(apiEndpoints.modifyMyClient, data, "patch");
};

const deleteMyClientApi = data => {
  return fetchApi(apiEndpoints.deleteMyClient, data, "delete");
};

const createMyNewClientApi = data => {
  return fetchApi(apiEndpoints.createMyNewClient, data, "post");
};

const getAllMyClientsApi = data => {
  return fetchApi(
    `${apiEndpoints.getAllMyClients}?clientId=${data}`,
    {},
    "get",
  );
};

const getAllActiveUsersApi = data => {
  const { clientId } = data;
  return fetchApi(
    `${apiEndpoints.getAllActiveUsers}?clientId=${clientId}`,
    {},
    "get",
  );
};

function* deleteMyClientFlow(action) {
  try {
    yield call(deleteMyClientApi, action.payload);
    yield put({ type: MY_CLIENTS_DELETE_SUCCESS, payload: action.payload });
    yield put(
      openSnackMessage({ snackMessage: i18n.t("delete-client-success") }),
    );
  } catch (error) {
    yield put({ type: MY_CLIENTS_DELETE_ERROR, payload: error });
  }
}

function* modifyMyClientFlow(action) {
  try {
    const result = yield call(modifyMyClientApi, action.payload);
    yield put({ type: MY_CLIENTS_MODIFY_SUCCESS, payload: result });
    yield put(
      openSnackMessage({ snackMessage: i18n.t("modify-client-success") }),
    );
  } catch (error) {
    yield put({ type: MY_CLIENTS_MODIFY_ERROR, payload: error });
  }
}

function* getAllMyClientsFlow(action) {
  try {
    const result = yield call(getAllMyClientsApi, action.payload);
    yield put({ type: MY_CLIENTS_GET_ALL_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: MY_CLIENTS_GET_ALL_ERROR, payload: error });
  }
}

function* getAllActiveUsersFlow(action) {
  try {
    const result = yield call(getAllActiveUsersApi, action.payload);
    yield put({ type: MY_CLIENTS_GET_ACTIVE_USERS_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: MY_CLIENTS_GET_ACTIVE_USERS_ERROR, payload: error });
  }
}

function* getSelectedClientFlow(action) {
  try {
    const result = yield call(getClientApi, action.payload);
    yield put({ type: MY_CLIENTS_GET_SELECTED_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: MY_CLIENTS_GET_SELECTED_ERROR, payload: error });
  }
}

function* createMyNewClientFlow(action) {
  try {
    const result = yield call(createMyNewClientApi, action.payload);

    yield put({
      type: MY_CLIENTS_CREATE_NEW_SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: MY_CLIENTS_CREATE_NEW_ERROR,
      payload: error,
    });
  }
}

function* Sagas() {
  yield all([
    takeLatest(MY_CLIENTS_CREATE_NEW_REQUESTING, createMyNewClientFlow),
    takeLatest(MY_CLIENTS_GET_ALL_REQUESTING, getAllMyClientsFlow),
    takeLatest(MY_CLIENTS_DELETE_REQUESTING, deleteMyClientFlow),
    takeLatest(MY_CLIENTS_MODIFY_REQUESTING, modifyMyClientFlow),
    takeLatest(MY_CLIENTS_GET_SELECTED_REQUESTING, getSelectedClientFlow),
    takeLatest(MY_CLIENTS_GET_ACTIVE_USERS_REQUESTING, getAllActiveUsersFlow),
  ]);
}

export default Sagas;
