import { call, put, takeLatest, all } from "redux-saga/effects";

import {
  POLL_GET_ALL_SUCCESS,
  POLL_GET_ALL_ERROR,
  POLL_GET_ALL_REQUESTING,
  POLL_CREATE_REQUESTING,
  POLL_CREATE_ERROR,
  POLL_CREATE_SUCCESS,
} from "./constants";

import { fetchApi } from "../api";
import { openSnackMessage } from "../general/actions";
import i18n from "../../i18n";

const apiEndpoints = {
  getUserPolls: "/v1/polls/user/", // userId
  getClientPolls: "/v1/polls/client/", // clientId
  createPoll: "/v1/polls/",
};

// const getUserPollsApi = data => {
//   return fetchApi(apiEndpoints.getUserPolls + data, {}, "get");
// };

const getClientPollsApi = data => {
  return fetchApi(apiEndpoints.getClientPolls + data, {}, "get");
};

const createPollApi = data => {
  return fetchApi(apiEndpoints.createPoll, data, "post");
};

// function* getUserPollsFlow(action) {
//   try {
//     const result = yield call(getUserPollsApi, action.payload);
//     yield put({ type: POLL_GET_ALL_SUCCESS, payload: result });
//   } catch (error) {
//     yield put({ type: POLL_GET_ALL_ERROR, payload: error });
//   }
// }

function* getClientPollsFlow(action) {
  try {
    const result = yield call(getClientPollsApi, action.payload);
    yield put({ type: POLL_GET_ALL_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: POLL_GET_ALL_ERROR, payload: error });
  }
}

function* createPollFlow(action) {
  try {
    const result = yield call(createPollApi, action.payload);
    yield put({ type: POLL_CREATE_SUCCESS, payload: result });
    yield put(openSnackMessage({ snackMessage: i18n.t("poll-sent-success") }));
  } catch (error) {
    yield put({ type: POLL_CREATE_ERROR, payload: error });
  }
}

function* Saga() {
  yield all([
    takeLatest(POLL_GET_ALL_REQUESTING, getClientPollsFlow),
    takeLatest(POLL_CREATE_REQUESTING, createPollFlow),
  ]);
}

export default Saga;
