import React, { PureComponent } from "react";
import { connect } from "react-redux";
import moment from "moment";

import withStyles from '@mui/styles/withStyles';
import Paper from "@mui/material/Paper";

import Colors from "../../constants/Colors";
import EvolutionChart2 from "./EvolutionChart2";

import "moment/locale/fr";
moment.locale("fr");

/*const dataTest = [
  {
    Actions: 0,
  },
  {
    Actions: 34,
  },
  {
    Actions: 84,
  },
  {
    Actions: 94,
  },
  {
    Actions: 124,
  },
  {
    Actions: 165,
  },
  {
    Actions: 200,
  },
];*/

const getWeeklyData = allActions => {
  const result = {};
  allActions.forEach(el => {
    const week = moment(el.date).week();
    if (result[week]) {
      result[week].actions++;
    } else {
      result[week] = {
        actions: 1,
      };
    }
    result[week].week = week;
    result[week].date = moment().week(week).format("D MMMM");
  });
  // Initial date -> 0 actions
  const finalArray = [{ actions: 0 }];
  Object.keys(result).forEach(el => {
    finalArray.push(result[el]);
  });
  finalArray.sort((a, b) => a.week - b.week);
  // cumulative
  finalArray.forEach((el, i) => {
    if (i > 0) {
      finalArray[i].actions += finalArray[i - 1].actions;
    }
  });
  if (allActions.length < 1) {
    return [
      {
        actions: 0,
      },
      {
        actions: 0,
      },
      {
        actions: 0,
      },
      {
        actions: 0,
      },
      {
        actions: 0,
      },
      {
        actions: 0,
      },
      {
        actions: 0,
      },
    ];
  }
  return finalArray;
};

const styles = theme => ({
  main: {
    backgroundColor: "#FAFAFA",
    // backgroundColor: "#fff",
    flex: 1,
    paddingRight: "5%",
    paddingLeft: "5%",
    paddingTop: 35,
    paddingBottom: 35,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  leftSection: {
    display: "flex",
    flex: 1,
    height: 250,
    marginRight: "5%",
    alignItems: "center",
    justifyContent: "center",
  },
  rightSection: {
    flex: 3,
    flexDirection: "column",
    display: "fex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  companyLogo: {
    width: "40%",
  },
  accent: {
    color: "#6198DE",
  },
  rightTitle: {
    fontWeight: 500,
    marginBottom: 5,
  },
  totalDone: {
    color: "#fff",
    fontWeight: 500,
  },
  actionLegend: {
    color: "#fff",
  },
  circle: {
    width: 200,
    height: 200,
    borderRadius: 100,
    webkitBoxShadow: "0px 0px 15px 2px rgba(66,80,251,1)",
    mozBoxShadow: "0px 0px 15px 2px rgba(66,80,251,1)",
    boxShadow: "0px 0px 15px 2px rgba(66,80,251,1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.main,
  },
  rightBox: {
    webkitBoxShadow: "0px 0px 4px 1px #D8D8D8",
    mozBoxShadow: "0px 0px 4px 1px #D8D8D8",
    boxShadow: "0px 0px 4px 1px #D8D8D8",
    height: 250,
    width: "100%",
    // flex: 1,
    // padding: 5,
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
  },
  bold: {
    marginBottom: 15,
    fontWeight: 500,
  },
});

class ProgressionSection extends PureComponent {
  state = {
    name: "",
    owner: "",
    domain: "",
  };

  onChangeClient = event => {
    this.props.changeCreateField({ name: event.target.value });
  };

  onChangeEmail = event => {
    this.props.changeCreateField({ owner: event.target.value });
  };

  onChangeDomain = event => {
    this.props.changeCreateField({ domain: event.target.value });
  };

  onSubmit = () => {
    this.props.createClientRequest({
      name: this.props.name,
      owner: this.props.owner,
      domain: this.props.domain,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.main}>
        {/* <Typography variant="h6" classes={{ root: classes.bold }}>
          Nombre d'actions réalisées
        </Typography> */}
        <Paper className={classes.rightBox}>
          <EvolutionChart2 data={this.props.weeklyData} />
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    allActions,
  } = state.client;
  return {
    token: state.auth.token,
    weeklyData: getWeeklyData(allActions),
  };
};

export default connect(
  mapStateToProps,
  {},
)(withStyles(styles)(ProgressionSection));
