import React from "react";
import withStyles from '@mui/styles/withStyles';
import Colors from "constants/Colors";
import NewButton from "scenes/CreateTrack/NewButton";
import i18n from "i18n";
import { Typography } from "@mui/material";

const styles = theme => ({
  main: {},
  helperBar: {
    position: "relative",
    background: Colors.white,
    boxShadow: "0px 2px 15px rgba(169, 169, 169, 0.25)",
    height: 60,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 10,
  },
  title: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 20,
    lineHeight: "27px",
    color: Colors.text,
  },
  text: {
    fontFamily: "Montserrat",
    fontSize: 18,
    lineHeight: "27px",
    flex: 1,
    marginLeft: 7,
  },
  libraryButton: {
    minWidth: 0,
    fontWeight: "normal",
    backgroundColor: Colors.lightGrey,
    color: Colors.text,
    borderRadius: 6,
    marginRight: 15,
    padding: "8px 14px",
    fontSize: 14,
    "&:hover": {
      opacity: 0.85,
      color: "white",
    },
  },
  "@media (max-width: 1200px)": {
    title: {
      fontSize: 18,
    },
  },
});

const HelperBar = ({ classes, numberOfQuestions = 0, onCreateQuestion }) => {
  return (
    <div className={classes.helperBar}>
      <Typography className={classes.title}>
        {i18n.t("all-questions")}
      </Typography>
      <Typography
        className={classes.text}
      >{`(${numberOfQuestions})`}</Typography>
      <NewButton onClick={onCreateQuestion} />
    </div>
  );
};

export default withStyles(styles)(HelperBar);
