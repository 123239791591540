import socket from "services/socket";
import {
  ADD_STEP,
  REMOVE_STEP,
  CREATE_CLOSE_SNACK_MESSAGE,
  CREATE_OPEN_SNACK_MESSAGE,
  CREATE_TRACK_CHANGE_FIELD,
  CHANGE_STEP_SELECTED,
  STEP_CREATE_REQUESTING,
  STEP_ADMIN_CREATE_REQUESTING,
  STEP_ADMIN_MODIFY_REQUESTING,
  STEP_ADMIN_DELETE_REQUESTING,
  TRACK_CREATE_REQUESTING,
  TRACK_MODIFY_REQUESTING,
  STEP_MODIFY_REQUESTING,
  SAVE_TRACK_REQUESTING,
  STEP_DELETE_REQUESTING,
  TRACK_CREATE_GET_REQUESTING,
  CREATE_AND_MODIFY_TEMPLATE_REQUESTING,
  TRACK_SET,
  REORDER_STEPS,
  UPDATE_TAGS_LIST_REQUESTING,
  GET_TAGS_LIST_REQUESTING,
  STEP_GET_ALL_REQUESTING,
  DUPLICATE_STEP,
  MY_LIBRARY_GET_TOTAL_COUNT_REQUESTING,
  PREMIUM_GET_TOTAL_COUNT_REQUESTING,
  GET_SKILLS_LIST_REQUESTING,
  UPDATE_SKILLS_LIST_REQUESTING,
  GET_ALL_OBJECTIVES_REQUESTING,
  GET_ALL_COMPETENCIES_REQUESTING,
  UPDATE_FOLDER_LIST_REQUESTING,
  GET_FOLDER_LIST_REQUESTING,
  CREATE_NEW_STEP,
  STEP_GET_LIST_REQUESTING,
  REMOVE_STEP_FROM_LIST,
  SELECT_STEP,
  NAVIGATE_CREATE_TRACK,
  CHANGE_STEP_SELECTED_INDEX,
  GET_MY_LIBRARY_STEPS_REQUESTING,
  SEARCH_CHANGE,
  MOVE_STEP_SELECTION,
  CLICK_ADMIN_AVATAR,
} from "./constants";

type stepType = {
  _id?: string;
  content?: {};
  title?: string;
};

export const emitStepUpdate = (payload: {
  stepType: stepType;
  clientId: string;
  trackId: string;
  updateType: string;
}) => {
  socket.emit("step-update", payload);
};

export const emitTrackUpdate = (payload: { clientId: string; track: {} }) => {
  socket.emit("track-update", payload);
};

export const emitFolderListUpdate = (payload: {
  folderList: {};
  clientId: string;
}) => {
  socket.emit("folder-list-update", payload);
};

export const changeField = payload => {
  return {
    type: CREATE_TRACK_CHANGE_FIELD,
    payload,
  };
};

export const changeStepSelected = payload => {
  return {
    type: CHANGE_STEP_SELECTED,
    payload,
  };
};

export const changeStepSelectedIndex = payload => {
  return {
    type: CHANGE_STEP_SELECTED_INDEX,
    payload,
  };
};

export const removeStep = (payload: number) => {
  return {
    type: REMOVE_STEP,
    payload,
  };
};

export const addStep = (payload: stepType) => {
  return {
    type: ADD_STEP,
    payload,
  };
};

export const duplicateStep = (payload: stepType) => {
  return {
    type: DUPLICATE_STEP,
    payload,
  };
};

export const closeSnackMessage = () => {
  return {
    type: CREATE_CLOSE_SNACK_MESSAGE,
  };
};

export const openSnackMessage = (payload: string) => {
  return {
    type: CREATE_OPEN_SNACK_MESSAGE,
    payload,
  };
};

export const createStepRequest = (payload: stepType) => {
  return {
    type: STEP_CREATE_REQUESTING,
    payload,
  };
};

export const modifyAdminStepRequest = (payload: { stepId: string }) => {
  return {
    type: STEP_ADMIN_MODIFY_REQUESTING,
    payload,
  };
};

export const deleteAdminStepRequest = (payload: { stepId: string }) => {
  return {
    type: STEP_ADMIN_DELETE_REQUESTING,
    payload,
  };
};

export const createAdminStepRequest = (payload: stepType) => {
  return {
    type: STEP_ADMIN_CREATE_REQUESTING,
    payload,
  };
};

export const modifyStepRequest = (payload: { stepId: string }) => {
  return {
    type: STEP_MODIFY_REQUESTING,
    payload,
  };
};

export const deleteStepRequest = (payload: { stepId: string }) => {
  return {
    type: STEP_DELETE_REQUESTING,
    payload,
  };
};

export const createTrackRequest = (payload: any) => {
  return {
    type: TRACK_CREATE_REQUESTING,
    payload,
  };
};

export const modifyTrackRequest = (payload: { trackId: string }) => {
  return {
    type: TRACK_MODIFY_REQUESTING,
    payload,
  };
};

export const saveTrackRequest = () => {
  return {
    type: SAVE_TRACK_REQUESTING,
  };
};

export const setTrack = payload => {
  return {
    type: TRACK_SET,
    payload,
  };
};

export const getCreateTrackRequest = (payload: {
  trackId: string;
  isEditingCampaign?: boolean;
}) => {
  return {
    type: TRACK_CREATE_GET_REQUESTING,
    payload,
  };
};

export const navigateCreateTrack = (payload: {
  trackId: string;
  isEditingCampaign?: boolean;
}) => {
  return {
    type: NAVIGATE_CREATE_TRACK,
    payload,
  };
};

export const createAndModifyTemplateRequest = payload => {
  return {
    type: CREATE_AND_MODIFY_TEMPLATE_REQUESTING,
    payload,
  };
};

export const reorderSteps = payload => {
  return {
    type: REORDER_STEPS,
    payload,
  };
};

export const getTagsListRequest = (payload: { clientId: string }) => {
  return {
    type: GET_TAGS_LIST_REQUESTING,
    payload,
  };
};

export const updateTagsListRequest = (payload: { tagsListId: string }) => {
  return {
    type: UPDATE_TAGS_LIST_REQUESTING,
    payload,
  };
};

export const getSkillsListRequest = (payload: { clientId: string }) => {
  return {
    type: GET_SKILLS_LIST_REQUESTING,
    payload,
  };
};

export const getFolderListRequest = (payload: { clientId: string }) => {
  return {
    type: GET_FOLDER_LIST_REQUESTING,
    payload,
  };
};

export const updateFolderListRequest = (payload: { folderListId: string }) => {
  return {
    type: UPDATE_FOLDER_LIST_REQUESTING,
    payload,
  };
};

export const getAllObjectivesRequest = (payload: { clientId: string }) => {
  return {
    type: GET_ALL_OBJECTIVES_REQUESTING,
    payload,
  };
};

export const getAllCompetenciesRequest = (payload: { clientId: string }) => {
  return {
    type: GET_ALL_COMPETENCIES_REQUESTING,
    payload,
  };
};

export const updateSkillsListRequest = (payload: { skillsListId: string }) => {
  return {
    type: UPDATE_SKILLS_LIST_REQUESTING,
    payload,
  };
};

export const getAllStepsRequest = (payload: { clientId: string }) => {
  return {
    type: STEP_GET_ALL_REQUESTING,
    payload,
  };
};

export const getMyLibraryStepsRequest = (payload: { clientId: string }) => {
  return {
    type: GET_MY_LIBRARY_STEPS_REQUESTING,
    payload,
  };
};

export const getMyLibraryTotalCountRequest = (payload: string) => {
  return {
    type: MY_LIBRARY_GET_TOTAL_COUNT_REQUESTING,
    payload,
  };
};

export const getPremiumTotalCountRequest = (payload: string) => {
  return {
    type: PREMIUM_GET_TOTAL_COUNT_REQUESTING,
    payload,
  };
};

export const createNewStep = (payload: stepType) => {
  return {
    type: CREATE_NEW_STEP,
    payload,
  };
};

export const getListOfStepsRequest = (payload: { stepIds: [] }) => {
  return {
    type: STEP_GET_LIST_REQUESTING,
    payload,
  };
};

export const removeStepFromList = payload => {
  return {
    type: REMOVE_STEP_FROM_LIST,
    payload,
  };
};

export const selectStep = (payload: { stepId: string }) => {
  return {
    type: SELECT_STEP,
    payload,
  };
};

export const updateSearch = (payload: string) => {
  return {
    type: SEARCH_CHANGE,
    payload,
  };
};

export const moveStepSelection = (stepMovement: number) => {
  return {
    type: MOVE_STEP_SELECTION,
    payload: stepMovement,
  };
};

export const clickAdminAvatar = (payload: {}) => {
  return {
    type: CLICK_ADMIN_AVATAR,
    payload,
  };
};
