import React from "react";

const DuplicateIcon = ({ sizePercentage, color, className }) => (
  <svg
    // width={sizePercentage * 24}
    className={className}
    width={sizePercentage * 15}
    height={sizePercentage * 19}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 15 19"
    version="1.1"
  >
    <svg
      width="15"
      height="19"
      viewBox="0 0 15 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.519 4.87251L10.6091 1.07481C10.5019 0.970718 10.3575 0.914551 10.2102 0.914551H4.55138C4.24018 0.914551 3.98752 1.15996 3.98752 1.46222V4.60678H0.750139C0.438941 4.60678 0.186279 4.85205 0.186279 5.15445V18.3669C0.186279 18.6691 0.438941 18.9146 0.750139 18.9146H10.3188C10.6301 18.9146 10.8828 18.6691 10.8828 18.3669V15.2223H14.1202C14.4314 15.2223 14.684 14.9771 14.684 14.6747V5.25992C14.684 5.12067 14.6289 4.97922 14.519 4.87251ZM13.5563 14.127H10.8828V8.95215C10.8828 8.81029 10.8259 8.6698 10.7176 8.56461L6.80781 4.76704C6.70163 4.66377 6.55755 4.60664 6.40895 4.60664H5.11538V2.00989H9.64633V5.25992C9.64633 5.56218 9.899 5.80759 10.2102 5.80759H13.5563V14.127ZM9.75492 17.8192H1.314V5.70212H5.84495V8.95215C5.84495 9.25441 6.09761 9.49982 6.40895 9.49982H9.75492V17.8192ZM6.97281 6.47652L8.95763 8.40434H6.97281V6.47652ZM12.7589 4.71225H10.7741V2.78442C11.0222 3.0253 12.5609 4.51999 12.7589 4.71225Z"
        fill={color}
      />
    </svg>
  </svg>
);

DuplicateIcon.defaultProps = {
  sizePercentage: 1,
  color: "#19212B",
};

export default DuplicateIcon;
