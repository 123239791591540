import React from "react";
import withStyles from "@mui/styles/withStyles";
import Colors from "constants/Colors";
import { Typography } from "@mui/material";
import { flatten, uniqBy, sortBy } from "lodash";
import ObjectiveBar from "scenes/Campaigns/CampaignScreen/StatsSide/ObjectivesSection/ObjectiveBar";
import selectLanguage from "utils/selectLanguage";
import i18n from "i18n";

const styles = theme => ({
  main: {
    maxWidth: 768,
    width: "100%",
    paddingTop: 20,
    paddingBottom: 38,
    marginTop: theme.spacing.unit * 3,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#fff",
    border: "solid",
    borderWidth: 1,
    borderRadius: 8,
    borderColor: Colors.mediumGrey,
  },
  title: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    lineHeight: 1.5,
    fontSize: 16,
    color: "#262627",
  },

  numberDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#0070f3",
    position: "relative",
    marginRight: 10,
    height: 28,
    width: 28,
    borderRadius: 14,
  },
  number: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    color: "#fff",
    lineHeight: 1.5,
    fontSize: 16,
  },
  topDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  averageContainer: {
    marginLeft: 20,
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 30,
    backgroundColor: "#F0F0F0",
    borderRadius: 8,
    paddingLeft: 12,
    paddingRight: 12,
  },
  average: {
    fontSize: 14,
    color: "#898989",
  },
  answers: {
    color: "#898989",
    fontSize: 12,
    paddingTop: 8,
    paddingBottom: 64,
  },
  leftDiv: {
    paddingLeft: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 30,
  },
  rightDiv: {
    paddingRight: 32,
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  chart: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    paddingRight: 15,
  },
  rateScale: {
    marginTop: 36,
    paddingRight: 55,
    display: "flex",
    flexDirection: "row",
  },
  leftScale: {
    textAlign: "left",
    flex: 1,
    color: "#262627",
    opacity: 0.5,
  },
  rightScale: {
    textAlign: "right",
    flex: 1,
    color: "#262627",
    opacity: 0.5,
  },
  comments: {
    marginTop: 25,
    width: "fit-content",
    cursor: "pointer",
    padding: "5px 10px",
    borderColor: Colors.grey,
    border: "solid",
    borderWidth: 1,
    borderRadius: 4,
    transition: "all 0.2s",
    "&:hover": {
      background: Colors.lightGrey,
    },
  },
  accent: {
    color: "#0070f3",
    fontWeight: "bold",
  },
  chartLegend: {
    marginBottom: 25,
    marginTop: -25,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  firstTitle: {
    width: 150,
    color: "#898989",
  },
  secondTitle: {
    textAlign: "center",
    width: 130,
    color: "#898989",
  },
  numberTitle: {
    marginLeft: 10,
    textAlign: "center",
    width: 80,
    color: "#898989",
  },
  barTitle: {
    width: 150,
  },
});

const formatTopicChoices = topicChoices => {
  const choices = flatten(topicChoices);
  const choicesCount = choices.reduce((prev, curr) => {
    const { value } = curr;
    prev[value] = (prev[value] || 0) + 1;
    return prev;
  }, {});
  const uniqChoices = uniqBy(choices, "value");
  const uniqWithCount = uniqChoices.map(el => {
    el.count = choicesCount[el.value];
    return el;
  });

  const sortedChoices = sortBy(uniqWithCount, "count").reverse();
  return sortedChoices;
};

const MultipleChoiceResult = ({
  classes,
  index = 0,
  title,
  topicChoices = [],
  answers = [],
  participants = 1,
  language = "fr",
}) => {
  const totalVotes = answers.length;
  const average =
    answers.reduce((prev, curr) => prev + curr.choices.length, 0) / totalVotes;

  const formattedChoices = formatTopicChoices(topicChoices);
  return (
    <div className={classes.main}>
      <div className={classes.leftDiv}>
        <span className={classes.numberDiv}>
          <Typography className={classes.number}>{index + 1}</Typography>
        </span>
      </div>
      <div className={classes.rightDiv}>
        <div className={classes.topDiv}>
          <Typography className={classes.title}>{title}</Typography>
          <div className={classes.averageContainer}>
            <Typography className={classes.average}>
              {i18n.t("avg-choice-per-participant", {
                avg: average.toFixed(1),
              })}
            </Typography>
          </div>
        </div>
        <Typography className={classes.answers}>
          {i18n.t("total-answered", { totalVotes, participants })}
        </Typography>
        <div className={classes.chart}>
          <div className={classes.chartLegend}>
            <Typography className={classes.firstTitle}>
              {i18n.t("choice")}
            </Typography>
            <Typography className={classes.secondTitle}>
              {i18n.t("selection-rate")}
            </Typography>
            <Typography className={classes.numberTitle}>
              {i18n.t("selections")}
            </Typography>
          </div>
          <div className={classes.bottomDiv}>
            {formattedChoices.map((el, index) => {
              const { count, title } = el;
              return (
                <ObjectiveBar
                  key={index}
                  titleClassName={classes.barTitle}
                  {...el}
                  title={selectLanguage({ text: title, language })}
                  percent={((count / totalVotes) * 100).toFixed(0)}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(MultipleChoiceResult);
