import React from "react";
import PeopleIcon from "@mui/icons-material/People";
import BusinessIcon from "@mui/icons-material/Business";
import SettingsIcon from "@mui/icons-material/Settings";
import MessageIcon from "@mui/icons-material/Message";
import PollIcon from "@mui/icons-material/Poll";
import SchoolIcon from "@mui/icons-material/School";
import LibraryIcon from "@mui/icons-material/Folder";
import MoneyIcon from "@mui/icons-material/AttachMoney";

import i18n from "../../i18n";
import { Badge } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Colors from "constants/Colors";

const useStyles = makeStyles(theme => ({
  badge: {
    backgroundColor: Colors.pink,
    color: Colors.white,
    position: "inherit",
    marginTop: 3,
  },
}));

const ConversationBadge = ({ messageNotifications }) => {
  const classes = useStyles();

  return (
    <Badge
      badgeContent={messageNotifications}
      classes={{ badge: classes.badge }}
    >
      {i18n.t("conversations")}
    </Badge>
  );
};

const menu = ({ messageNotifications }) => [
  {
    icon: <BusinessIcon />,
    route: "my-clients",
    text: i18n.t("clients"),
    accessRequired: false,
    clientAccessRequired: true,
    isEnterpriseAccountForbidden: true,
    observerAccess: false,
    trainerAccess: false,
  },
  {
    icon: <SchoolIcon />,
    route: "campaigns",
    text: i18n.t("campaigns"),
    accessRequired: false,
    clientAccessRequired: false,
    isEnterpriseAccountForbidden: false,
    observerAccess: true,
    trainerAccess: true,
  },
  {
    icon: <PeopleIcon />,
    route: "my-coachees",
    text: i18n.t("my-coachees"),
    isCoachingMenu: true,
    isCoachingRequired: false,
    observerAccess: false,
    trainerAccess: false,
  },
  {
    icon: <PollIcon />,
    route: "polls",
    text: i18n.t("polls"),
    isEnterpriseAccountForbidden: true,
    accessRequired: false,
    observerAccess: false,
    trainerAccess: false,
  },
  {
    icon: <MessageIcon />,
    route: "conversations",
    text: <ConversationBadge messageNotifications={messageNotifications} />,
    accessRequired: false,
    isEnterpriseAccountForbidden: true,
    isCoachingMenu: true,
    observerAccess: true,
    trainerAccess: false,
  },
  {
    icon: <PeopleIcon />,
    route: "my-users",
    text: i18n.t("my-users"),
    isEnterpriseAccountForbidden: false,
    accessRequired: false,
    isCoachingMenu: false,
    observerAccess: false,
    trainerAccess: false,
  },
  {
    icon: <LibraryIcon />,
    route: "library",
    text: i18n.t("my-library"),
    accessRequired: false,
    isEnterpriseAccountForbidden: false,
    isCoachingMenu: true,
    observerAccess: false,
    trainerAccess: true,
  },
  {
    icon: <MoneyIcon />,
    route: "invoices",
    text: i18n.t("invoices"),
    accessRequired: false,
    isEnterpriseAccountForbidden: false,
    isCoachingMenu: true,
    isCoachingRequired: true,
    observerAccess: false,
    trainerAccess: false,
  },
  {
    icon: <SettingsIcon />,
    route: "settings",
    text: i18n.t("settings"),
    isEnterpriseAccountForbidden: false,
    accessRequired: false,
    isCoachingMenu: true,
    trainerAccess: false,
    observerAccess: false,
  },
];

export default menu;
