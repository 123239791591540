import React from "react";
import { ResponsiveStream } from "@nivo/stream";
import { linearGradientDef } from "@nivo/core";
import Colors from "../../constants/Colors";

/*const dataTest = [
  {
    Actions: 0,
  },
  {
    Actions: 34,
  },
  {
    Actions: 84,
  },
  {
    Actions: 94,
  },
  {
    Actions: 124,
  },
  {
    Actions: 165,
  },
  {
    Actions: 200,
  },
];

const colorRange = [
  "rgba(252,87,122,0.8)",
  "rgba(66,80,251,0.8)",
  "rgba(0,170,255,0.8)",
  "#FF9F40",
  "#FFC233",
];*/

const EvolutionChart = ({ data }) => {
  return (
    <ResponsiveStream
      data={data}
      colors="#32C1FF"
      defs={[
        linearGradientDef("gradientA", [
          { offset: 0, color: "#25E8C8" },
          { offset: 100, color: "#32C1FF" },
        ]),
      ]}
      fill={[{ match: "*", id: "gradientA" }]}
      keys={["actions"]}
      margin={{
        // top: 20
        // top: 40
        right: 10,
        bottom: 45,
        left: 10,
      }}
      axisTop={null}
      axisRight={null}
      // axisBottom={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Semaine",
        legendPosition: "middle",
        legendOffset: 36,
      }}
      // axisLeft={{
      //     "orient": "left",
      //     "tickSize": 5,
      //     "tickPadding": 5,
      //     "tickRotation": 0,
      //     "legend": "",
      //     "legendOffset": -40
      // }}
      // curve="linear"
      curve="natural"
      offsetType="none"
      fillOpacity={1}
      borderWidth={1}
      borderColor="#25E8C8"
      dotSize={8}
      enableDots={false}
      enableGridX={true}
      dotBorderWidth={2}
      dotBorderColor={"rgba(255,255,255, 1)"}
      dotColor={Colors.yellow}
      // dotColor={"#25E8C8"}
      dotPosition="center"
      layersBorderColor="white"
      // dotBorderColor="inherit:brighter(0.7)"
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      // enableStackTooltip
      // isInteractive={false}
      theme={{
        grid: {
          line: {
            stroke: "white",
            strokeWidth: 1,
          },
        },
        tooltip: {
          container: { borderRadius: 5, color: Colors.blackGrey },
          item: { background: "red" },
        },
      }}
      legends={[
        {
          anchor: "top-left",
          direction: "row",
          // text: "Actions réalisées",
          translateX: 20,
          translateY: 10,
          itemWidth: 80,
          itemHeight: 20,
          itemTextColor: Colors.blackGrey,
          symbolSize: 12,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ]}
    />
  );
};

export default EvolutionChart;
