import React, { MouseEvent, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import i18n from "i18n";
import SearchBar from "components/SearchBar";
import moment, { Moment } from "moment";
import AddNewEvent from "./AddNewEvent";
import CustomTable from "components/Table/CustomTable";
import { getEvents } from "services/campaign/selectors";
import { useDispatch, useSelector } from "react-redux";
import DeleteConfirmModal from "components/DeleteConfirmModal";
import { deleteEvent } from "services/campaign/actions";
import UpdateEventModal from "./UpdateEventModal";
import { getCampaignSurveys } from "services/survey/selectors";
import selectLanguage from "utils/selectLanguage";

const useStyles = makeStyles(theme => ({
  subTitle: {
    fontFamily: "Montserrat",
    fontSize: 18,
    lineHeight: 1.2143,
    fontWeight: 700,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 16,
  },
  searchBar: {
    maxWidth: 300,
  },
  separationDiv: {
    borderTopWidth: 1,
    borderTopColor: "#E6E6E6",
    borderTopStyle: "solid",
    marginBottom: 48,
  },
  addEventContainer: {
    marginBottom: 30,
  },
}));

interface Props {
  languageSelected: string;
  startDate: Moment;
  endDate: Moment;
}

const headerTable = [
  {
    id: "title",
    label: "Nom de l'évènement",
    disablePadding: false,
  },
  {
    id: "date",
    label: "Date de l'évènement",
    disablePadding: true,
  },
  {
    id: "hour",
    label: "Heure de l'évènement",
    disablePadding: true,
  },
  { id: "type", label: "Type d'évènement", disablePadding: true },
  { id: "link", label: "Lien", disablePadding: true },
  { id: "delete", label: "", disablePadding: true },
  { id: "modify", label: "", disablePadding: true },
];

const CampaignEvents = ({ languageSelected, startDate, endDate }: Props) => {
  const [searchText, setSearchText] = useState("");
  const [deleteEventModal, setDeleteEventModal] = useState(false);
  const [modifyEventModal, setModifyEventModal] = useState(false);
  const [eventSelected, setEventSelected] = useState({
    _id: "",
    title: "",
    date: moment().toDate(),
    data: {
      url: "",
      survey: "",
    },
    type: "",
    campaignId: "",
    user: "",
  });

  const events = useSelector(getEvents);
  const surveys = useSelector(getCampaignSurveys);

  const dispatch = useDispatch();

  const formattedEvent = useMemo(() => {
    return events.map(event => {
      const date = new Date(event.date);
      const surveyTitle = surveys.find(
        survey => survey._id === event.data.survey,
      )?.title;

      return {
        id: event._id,
        title: event.title,
        date: moment(date).format("LL"),
        hour: moment(date).format("HH:mm"),
        type: event.type,
        link: event.data.url
          ? event.data.url
          : selectLanguage({
              text: surveyTitle,
              language: languageSelected,
            }),
      };
    });
  }, [events]);

  const handleClickRow = (
    event: MouseEvent<unknown>,
    id: string,
    functionName: string,
  ) => {
    const eventSelected = events.find(event => event._id === id);

    setEventSelected(eventSelected);
    if (functionName === "delete") {
      setDeleteEventModal(true);
    } else if (functionName === "modify") {
      setModifyEventModal(true);
    }
  };

  const handleDeleteElement = () => {
    dispatch(deleteEvent({ eventId: eventSelected._id }));
    setDeleteEventModal(false);
  };

  const classes = useStyles();

  return (
    <div>
      <DeleteConfirmModal
        title={i18n.t("delete-event", { lng: languageSelected })}
        isVisible={deleteEventModal}
        onClose={() => setDeleteEventModal(false)}
        onClickConfirm={handleDeleteElement}
      />
      <UpdateEventModal
        event={eventSelected}
        languageSelected={languageSelected}
        startDate={startDate}
        endDate={endDate}
        isVisible={modifyEventModal}
        onClose={() => setModifyEventModal(false)}
      />
      <div className={classes.header}>
        <Typography className={classes.subTitle}>
          {i18n.t("campaign-events-title", { lng: languageSelected })}
        </Typography>
        <SearchBar
          placeholder={i18n.t("search-actions-placeholder", {
            lng: languageSelected,
          })}
          className={classes.searchBar}
          onChangeSearch={setSearchText}
          value={searchText}
        />
      </div>
      <div className={classes.separationDiv} />
      <div className={classes.addEventContainer}>
        <AddNewEvent
          languageSelected={languageSelected}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
      <CustomTable
        headerTableCells={headerTable}
        bodyTableCells={formattedEvent}
        hasCheckbox
        handleClickEvent={handleClickRow}
        hasDeletion={true}
        hasModification={true}
      />
    </div>
  );
};

export default CampaignEvents;
