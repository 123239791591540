import React from "react";

const ModifyPencil = ({
  sizePercentage = 1,
  color = "#7C7C7C",
  className = "",
  style = {},
}) => (
  <svg
    className={className}
    style={style}
    width={sizePercentage * 19}
    height={sizePercentage * 18}
    viewBox="0 0 19 18"
    fill="none"
    preserveAspectRatio="xMidYMid meet"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.75839 17.8002C1.81339 17.8002 1.86839 17.7947 1.92339 17.7864L6.54889 16.9752C6.60389 16.9642 6.65614 16.9394 6.69464 16.8982L18.3519 5.24094C18.3774 5.2155 18.3976 5.18528 18.4114 5.15202C18.4252 5.11875 18.4323 5.08309 18.4323 5.04707C18.4323 5.01105 18.4252 4.97539 18.4114 4.94212C18.3976 4.90886 18.3774 4.87864 18.3519 4.8532L13.7814 0.279945C13.7291 0.227695 13.6604 0.200195 13.5861 0.200195C13.5119 0.200195 13.4431 0.227695 13.3909 0.279945L1.73364 11.9372C1.69239 11.9784 1.66764 12.0279 1.65664 12.0829L0.84539 16.7084C0.818638 16.8558 0.828197 17.0074 0.87324 17.1502C0.918282 17.293 0.99745 17.4226 1.10389 17.5279C1.28539 17.7039 1.51364 17.8002 1.75839 17.8002ZM3.61189 13.0042L13.5861 3.0327L15.6019 5.04844L5.62764 15.0199L3.18289 15.4517L3.61189 13.0042Z"
      fill={color}
    />
  </svg>
);

export default ModifyPencil;
