import React from "react";

const PeopleIcon = ({ sizePercentage, color = "#fff" }) => (
  <svg
    width={sizePercentage * 19}
    height={sizePercentage * 14}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 19 14"
    version="1.1"
  >
    <path
      d="M13.2441 6.96823C14.4989 6.96823 15.5081 5.94988 15.5081 4.69512C15.5081 3.44036 14.4989 2.42201 13.2441 2.42201C11.9894 2.42201 10.971 3.44036 10.971 4.69512C10.971 5.94988 11.9894 6.96823 13.2441 6.96823ZM6.42477 6.05899C7.93412 6.05899 9.14341 4.8406 9.14341 3.33125C9.14341 1.8219 7.93412 0.603516 6.42477 0.603516C4.91543 0.603516 3.69704 1.8219 3.69704 3.33125C3.69704 4.8406 4.91543 6.05899 6.42477 6.05899ZM13.2441 8.78672C11.5802 8.78672 8.24326 9.62323 8.24326 11.2871V13.3329H18.245V11.2871C18.245 9.62323 14.908 8.78672 13.2441 8.78672ZM6.42477 7.87748C4.30623 7.87748 0.0600586 8.94129 0.0600586 11.0598V13.3329H6.42477V11.2871C6.42477 10.5143 6.72482 9.15951 8.57968 8.13206C7.78864 7.9684 7.02487 7.87748 6.42477 7.87748Z"
      fill={color}
    />
  </svg>
);

PeopleIcon.defaultProps = {
  sizePercentage: 1,
};

export default PeopleIcon;
