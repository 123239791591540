import React from "react";

const FrenchFlagIcon = ({ sizePercentage, color }) => (
  <svg
    width={sizePercentage * 25}
    height={sizePercentage * 17}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 25 17"
    version="1.1"
  >
    <path
      d="M8.3334 0H0.806494L0.511963 0.806446V14.9566C0.511963 15.402 0.873047 15.7631 1.31841 15.7631H8.3334L9.27427 14.9192V1.20981L8.3334 0Z"
      fill="#5055A0"
    />
    <path
      d="M16.6667 15.7549H24.1936L25 15.3226V0.806446C25 0.361084 24.639 0 24.1936 0H16.6667L15.7258 1.20986V14.9192L16.6667 15.7549Z"
      fill="#E5646E"
    />
    <path
      d="M25 15.3232H16.0396L16.6667 16.1297H24.1936C24.639 16.1297 25 15.7687 25 15.3232Z"
      fill="#DB4655"
    />
    <path
      d="M0.806445 12.9032V0C0.361084 0 0 0.361035 0 0.806446V15.3226C0 15.768 0.361084 16.129 0.806445 16.129H8.33335L8.9605 15.3226H3.22583C1.88965 15.3226 0.806445 14.2394 0.806445 12.9032Z"
      fill="#3F3F8F"
    />
    <path
      d="M9.14702 15.7631H16.0318L16.6665 15.3226V0H8.33325V15.3226L9.14702 15.7631Z"
      fill="#F2F2F6"
    />
    <path
      d="M8.33325 15.3232H16.6665V16.1297H8.33325V15.3232Z"
      fill="#E1E1E6"
    />
  </svg>
);

FrenchFlagIcon.defaultProps = {
  sizePercentage: 1,
  color: "#fff",
};

export default FrenchFlagIcon;
