import {
  GET_COACHING_SESSION_REQUESTING,
  GET_COACHING_SESSION_SUCCESS,
  GET_COACHING_SESSION_ERROR,
  UPDATE_COACHING_SESSION_REQUESTING,
  UPDATE_COACHING_SESSION_SUCCESS,
  UPDATE_COACHING_SESSION_ERROR,
  CREATE_COACHING_SESSION_REQUESTING,
  CREATE_COACHING_SESSION_SUCCESS,
  CREATE_COACHING_SESSION_ERROR,
  CLEAR_COACHING_SESSION,
  UPDATE_COACHING_SESSION,
  GET_COACHING_NOTES_SUCCESS,
  GET_COACHING_NOTES_ERROR,
  GET_COACHING_NOTES_REQUESTING,
  UPDATE_COACHING_NOTE_REQUESTING,
  UPDATE_COACHING_NOTE_SUCCESS,
  UPDATE_COACHING_NOTE_ERROR,
  DELETE_COACHING_NOTE_REQUESTING,
  DELETE_COACHING_NOTE_SUCCESS,
  DELETE_COACHING_NOTE_ERROR,
  CREATE_COACHING_NOTE_REQUESTING,
  CREATE_COACHING_NOTE_SUCCESS,
  CREATE_COACHING_NOTE_ERROR,
  SELECT_COACHING_NOTE,
  END_COACHING_REQUESTING,
  END_COACHING_SUCCESS,
  END_COACHING_ERROR,
  GET_COACHING_FILES_SUCCESS,
  GET_COACHING_FILES_ERROR,
  GET_COACHING_FILES_REQUESTING,
  DELETE_COACHING_FILE_REQUESTING,
  DELETE_COACHING_FILE_SUCCESS,
  DELETE_COACHING_FILE_ERROR,
  CREATE_COACHING_FILE_REQUESTING,
  CREATE_COACHING_FILE_SUCCESS,
  CREATE_COACHING_FILE_ERROR,
} from "./constants";

import { ADD_RESOURCE } from "../resource/constants";

import { AUTH_LOGOUT_SUCCESS } from "../auth/reducer";

const initialState = {
  requesting: false,
  noteRequesting: false,
  fileRequesting: false,
  files: [],
  selectedNote: {},
  notes: [],
  myCoachees: [],
  selectedResourceId: null,
  coachingSession: {
    clientId: "",
    userId: "",
    coachId: "",
    goals: [
      {
        skill: {},
        goalNumber: 0,
        doneSteps: [],
        recommendedSteps: [],
      },
    ],
    sessions: [
      {
        done: false,
        doneDate: "",
        nextDate: "",
      },
    ],
    resources: [
      {
        resourceId: "",
        visits: 0,
        comment: "",
        rating: 5,
      },
    ],
    isRemoved: false,
  },
};

const reducer = function userReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_COACHING_NOTE:
      return {
        ...state,
        selectedNote: action.payload,
      };

    case GET_COACHING_NOTES_REQUESTING: {
      return {
        ...state,
        notes: initialState.notes,
        noteRequesting: true,
      };
    }

    case END_COACHING_REQUESTING: {
      return {
        ...state,
        requesting: true,
      };
    }

    case CREATE_COACHING_FILE_REQUESTING:
    case GET_COACHING_FILES_REQUESTING: {
      return {
        ...state,
        fileRequesting: true,
      };
    }

    case DELETE_COACHING_FILE_REQUESTING: {
      const { coachingFileId } = action.payload;
      const files = state.files.filter(file => file._id !== coachingFileId);

      return {
        ...state,
        files,
        fileRequesting: true,
      };
    }

    case DELETE_COACHING_FILE_SUCCESS: {
      return {
        ...state,
        fileRequesting: false,
      };
    }

    case GET_COACHING_FILES_SUCCESS: {
      return {
        ...state,
        files: action.payload,
        fileRequesting: false,
      };
    }

    case CREATE_COACHING_FILE_SUCCESS: {
      return {
        ...state,
        files: [action.payload, ...state.files],
        fileRequesting: false,
      };
    }

    case DELETE_COACHING_FILE_ERROR:
    case CREATE_COACHING_FILE_ERROR:
    case GET_COACHING_FILES_ERROR: {
      return {
        ...state,
        error: action.payload,
        fileRequesting: false,
      };
    }

    case UPDATE_COACHING_NOTE_REQUESTING: {
      const notes = state.notes.map(note => {
        if (note._id === action.payload.coachingNoteId) {
          return {
            ...note,
            ...action.payload,
          };
        }
        return note;
      });
      return {
        ...state,
        notes,
        noteRequesting: true,
      };
    }

    case GET_COACHING_NOTES_SUCCESS: {
      return {
        ...state,
        notes: action.payload,
        noteRequesting: false,
      };
    }

    case DELETE_COACHING_NOTE_SUCCESS: {
      const notes = state.notes.filter(el => el._id !== action.payload._id);
      return {
        ...state,
        notes,
        noteRequesting: false,
        selectedNote: initialState.selectedNote,
      };
    }

    case CREATE_COACHING_NOTE_SUCCESS: {
      return {
        ...state,
        noteRequesting: false,
        notes: [action.payload, ...state.notes],
        selectedNote: action.payload,
      };
    }

    case END_COACHING_SUCCESS: {
      return {
        ...state,
        coachingSession: {
          ...state.coachingSession,
          ...action.payload,
        },
        requesting: false,
      };
    }

    case UPDATE_COACHING_NOTE_SUCCESS: {
      return {
        ...state,
        noteRequesting: false,
      };
    }

    case DELETE_COACHING_NOTE_REQUESTING:
    case CREATE_COACHING_NOTE_REQUESTING: {
      return {
        ...state,
        noteRequesting: true,
      };
    }

    case DELETE_COACHING_NOTE_ERROR:
    case CREATE_COACHING_NOTE_ERROR:
    case UPDATE_COACHING_NOTE_ERROR:
    case GET_COACHING_NOTES_ERROR: {
      return {
        ...state,
        noteRequesting: false,
        error: action.payload,
      };
    }

    case GET_COACHING_SESSION_REQUESTING: {
      return {
        ...state,
        coachingSession: initialState.coachingSession,
        requesting: true,
      };
    }

    case CREATE_COACHING_SESSION_REQUESTING:
    case UPDATE_COACHING_SESSION_REQUESTING:
      return {
        ...state,
        requesting: true,
      };

    case CREATE_COACHING_SESSION_SUCCESS:
    case GET_COACHING_SESSION_SUCCESS: {
      return {
        ...state,
        requesting: false,
        coachingSession: {
          ...state.coachingSession,
          ...action.payload,
        },
      };
    }

    case UPDATE_COACHING_SESSION_SUCCESS: {
      return {
        ...state,
        requesting: false,
        coachingSession: {
          ...state.coachingSession,
          ...action.payload,
        },
      };
    }

    case UPDATE_COACHING_SESSION: {
      return {
        ...state,
        coachingSession: {
          ...state.coachingSession,
          ...action.payload,
        },
      };
    }

    case END_COACHING_ERROR:
    case CREATE_COACHING_SESSION_ERROR:
    case UPDATE_COACHING_SESSION_ERROR:
    case GET_COACHING_SESSION_ERROR: {
      return {
        ...state,
        requesting: false,
        error: action.payload,
      };
    }

    case ADD_RESOURCE: {
      return {
        ...state,
        coachingSession: {
          ...state.coachingSession,
          resources: [
            { resourceId: action.payload._id },
            ...state.coachingSession.resources,
          ],
        },
      };
    }

    case CLEAR_COACHING_SESSION:
    case AUTH_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
