import React, { useMemo, useState } from "react";
import Typography from "@mui/material/Typography";
import { Avatar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Colors from "../../../../constants/Colors";
import CheckBox from "components/CheckBox";
import displayDateWithoutHour from "../../../../utils/displayDateWithoutHour";

import { motion } from "framer-motion";
import DeleteIcon from "../../../CreateTrack/DeleteIcon";
import AddIcon from "scenes/CreateTrack/AddIcon";
import i18n from "i18n";
import ToolTip from "components/ToolTip";
import AppleIcon from "scenes/Campaigns/CampaignScreen/LearnerSide/AppleIcon";
import AndroidIcon from "scenes/Campaigns/CampaignScreen/LearnerSide/AndroidIcon";
import { displayNumberOfActionDone } from "utils/doneStepsByUser";
import { MainText } from "components/Texts/MainText";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignSelected } from "services/campaign/selectors";
import { regenerateCertificateRequest } from "services/campaign/actions";
import DownloadIcon from "components/DownloadIcon";
import useZipeDownload from "hooks/useZipDownload";
import CircleStarIcon from "components/Icons/CircleStarIcon";
import RefreshIcon from "components/Icons/RefreshIcon";

const variants = {
  visible: i => ({
    opacity: 1,
    y: 0,
  }),
  hidden: { opacity: 0, y: 20 },
};

const useStyle = makeStyles({
  userRow: {
    boxSizing: "border-box",
    position: "relative",
    cursor: "pointer",
    borderTop: "1px solid rgba(0,0,0,.09)",
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 0,
    padding: 15,
    paddingLeft: 0,
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
    transition: "0.2s all",
    "&:hover": {
      opacity: 0.9,
      backgroundColor: "#ECF6FF",
    },
  },
  date: {
    fontSize: 16,
    color: "#474747",
  },
  points: {
    fontSize: 18,
    color: Colors.pureMain,
    fontWeight: "bold",
  },
  firstRow: {
    display: "flex",
    flex: 2,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  row: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  avatar: {
    objectFit: "cover",
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  removeButton: {
    // position: "absolute",
    right: 15,
    cursor: "pointer",
    zIndex: 10,
    padding: 5,
    marginRight: 15,
    backgroundColor: Colors.red,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    transition: "all .2s ease",
    "&:hover": {
      transform: "scale(1.07)",
    },
  },
  indicator: {
    marginRight: 7,
    backgroundColor: Colors.orange,
    height: "12px",
    width: "12px",
    minWidth: "12px",
    minHeight: "12px",
    borderRadius: "50%",
  },
  refreshIcon: {
    position: "absolute",
    left: -40,
    cursor: "pointer",
    zIndex: 20,
    padding: 5,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    transition: "all .2s ease",
    "&:hover": {
      transform: "scale(1.07)",
      opacity: 0.9,
    },
  },
  addButton: {
    // position: "absolute",
    marginTop: 10,
    width: 10,
    height: 10,
    right: 0,
    cursor: "pointer",
    zIndex: 20,
    padding: 5,
    backgroundColor: Colors.darkBlue,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    transition: "all .2s ease",
    "&:hover": {
      transform: "scale(1.07)",
      opacity: 0.9,
    },
  },
  userName: {
    lineHeight: "1.5em" /* fallback */,
    maxHeight: "1.5em",
    wordBreak: "break-all",
    color: Colors.darkBlue,
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: "Montserrat",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 1,
  },
  nameDiv: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    height: 50,
    marginLeft: 15,
  },
  jobDescription: {
    "-webkit-line-clamp": 1 /* number of lines to show */,
    lineHeight: "1.5em" /* fallback */,
    maxHeight: "1.5em",
    wordBreak: "break-all",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#BABCC5",
    fontSize: 16,
  },
  checkBox: {
    marginRight: 8,
  },
  notificationInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  notificationDate: {
    fontSize: 8,
    marginTop: 5,
  },
  notificationDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "absolute",
    left: 8,
  },
  certificationReached: {
    color: "rgba(76, 188, 48, 1)",
  },
  certificationNotReached: {
    color: "rgba(252, 148, 3, 1)",
  },
  optionsDiv: {
    right: 0,
    position: "absolute",
    display: "flex",
    flexDirection: "column",
  },
});

// export interface ILearnerRowProps {
//   timezone: string;
//   userCampaign: {};
//   index: number;
//   openUserModal: () => void;
//   hasAccess: (_id: string) => boolean;
//   hasUsersAccessRestricted: boolean;
//   onClickDelete: (event: any) => void;
//   onClickAddRandomStep: (event: React.MouseEvent) => void;
//   isSuperAdmin: boolean;
//   hasCheckBox: boolean;
//   isChecked: boolean;
//   handleCheck: () => void;
//   isNotificationStatVisible: boolean;
// } // TODO transform to typescript file

const LearnerRow = ({
  userCampaign,
  timezone,
  index,
  openUserModal,
  hasAccess,
  hasUsersAccessRestricted,
  onClickDelete,
  onClickAddRandomStep = () => {},
  isSuperAdmin = false,
  hasCheckBox = false,
  isChecked = false,
  handleCheck = () => {},
  isNotificationStatVisible = false,
}) => {
  const {
    lastDoneDate,
    hasNotificationsEnabledCheckDate,
    hasNotificationsEnabled,
    deviceOs,
    steps,
    certificateUrl,
    firstName,
    lastName,
    userId,
  } = userCampaign;
  const [isHovered, setHover] = useState(false);
  const dispatch = useDispatch();

  const campaignSelected = useSelector(getCampaignSelected);

  const { campaignActionGoal } = campaignSelected;

  const [generateZipFile] = useZipeDownload();

  const hasReachGoal = useMemo(
    () => displayNumberOfActionDone(steps) >= campaignActionGoal,
    [campaignActionGoal, steps],
  );

  const onClickRegenerateCertificate = event => {
    event.stopPropagation();
    dispatch(
      regenerateCertificateRequest({
        userCampaignId: userCampaign._id,
        userId,
      }),
    );
  };

  const downloadCertificate = event => {
    event.stopPropagation();
    console.log("downloadCertificate", certificateUrl);
    generateZipFile(`${firstName} ${lastName}-certificat`, [
      {
        url: certificateUrl,
        name: `${firstName} ${lastName}.pdf`,
      },
    ]);
  };

  const displayLastActionDate = () => {
    if (lastDoneDate) {
      return displayDateWithoutHour(lastDoneDate, timezone);
    }
    return i18n.t("no-actions-done-yet");
  };
  const classes = useStyle();

  return (
    <motion.div
      key={index}
      onMouseLeave={() => setHover(false)}
      onMouseEnter={() => setHover(true)}
      onClick={() => openUserModal()}
      className={classes.userRow}
      custom={index}
      initial="hidden"
      animate="visible"
      variants={variants}
    >
      <div className={classes.firstRow}>
        {isSuperAdmin && isNotificationStatVisible && (
          <div className={classes.notificationDiv}>
            <div className={classes.notificationInfo}>
              <div
                className={classes.indicator}
                style={{
                  backgroundColor: hasNotificationsEnabled
                    ? Colors.green
                    : Colors.red,
                }}
              />
              {deviceOs === "ios" ? (
                <AppleIcon sizePercentage={0.04} color={"#ACACAC"} />
              ) : deviceOs === "android" ? (
                <AndroidIcon sizePercentage={0.035} />
              ) : (
                ""
              )}
            </div>
            <Typography className={classes.notificationDate}>
              {displayDateWithoutHour(
                hasNotificationsEnabledCheckDate,
                timezone,
              )}
            </Typography>
          </div>
        )}
        {hasCheckBox && (
          <CheckBox
            isChecked={isChecked}
            onCheck={handleCheck}
            className={classes.checkBox}
          />
        )}
        {hasUsersAccessRestricted && (
          <div
            className={classes.indicator}
            style={{
              backgroundColor: hasAccess(userCampaign.userId)
                ? Colors.green
                : Colors.red,
            }}
          />
        )}
        <Avatar src={userCampaign.picture} className={classes.avatar} />
        <div className={classes.nameDiv}>
          <Typography
            className={classes.userName}
          >{`${userCampaign.firstName} ${userCampaign.lastName}`}</Typography>
          {userCampaign.jobDescription && (
            <Typography
              className={classes.jobDescription}
            >{`${userCampaign.jobDescription}`}</Typography>
          )}
        </div>
      </div>
      <div className={classes.row}>
        <Typography className={classes.points}>
          {displayNumberOfActionDone(steps)}
        </Typography>
      </div>
      <div className={classes.row}>
        <Typography className={classes.date}>
          {displayLastActionDate()}
        </Typography>
      </div>
      <div className={classes.row}>
        <Typography className={classes.date}>
          {displayDateWithoutHour(userCampaign.createdAt)}
        </Typography>
      </div>
      <div className={classes.row}>
        <div style={{ marginRight: 20, position: "relative" }}>
          {isSuperAdmin && isHovered && (
            <ToolTip content={i18n.t("regenerate-certificate")}>
              <div
                className={classes.refreshIcon}
                onClick={onClickRegenerateCertificate}
              >
                <RefreshIcon height={20} width={20} />
              </div>
            </ToolTip>
          )}
          <MainText
            size="medium"
            text={i18n.t("campaign-certificate-file-title")}
          />
          <MainText
            size="small"
            className={
              hasReachGoal
                ? classes.certificationReached
                : classes.certificationNotReached
            }
            text={i18n.t(
              `campaign-${hasReachGoal ? "realisation" : "participation"}-file`,
            )}
          />
        </div>
        <ToolTip content={i18n.t("download-certificate-tooltip")}>
          <div
            style={{
              zIndex: 15,
              padding: 5,
            }}
            onClick={downloadCertificate}
          >
            <DownloadIcon />
          </div>
        </ToolTip>
      </div>
      {isHovered && (
        <div className={classes.optionsDiv}>
          {((hasUsersAccessRestricted && hasAccess(userCampaign.userId)) ||
            !hasUsersAccessRestricted) && (
            <ToolTip content={i18n.t("remove-userCampaign-tooltip")}>
              <div
                className={`${classes.removeButton}`}
                onClick={e => onClickDelete(e)}
              >
                <DeleteIcon sizePercentage={0.45} />
              </div>
            </ToolTip>
          )}
          {isSuperAdmin && (
            <div
              className={`${classes.addButton}`}
              onClick={onClickAddRandomStep}
            >
              <AddIcon sizePercentage={0.45} />
            </div>
          )}
        </div>
      )}
    </motion.div>
  );
};

export default LearnerRow;
