import React, { useEffect, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import Colors from "constants/Colors";
import i18n from "i18n";
import { connect } from "react-redux";
import { addParticipantsRequest } from "services/campaign/actions";
import { changeGeneralSettings } from "services/general/actions";
import { getSelectedClientId } from "services/user/selectors";
import { Typography } from "@mui/material";
import FlatButton from "components/FlatButton";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  Configure,
  Index,
} from "react-instantsearch-dom";
import UserRow from "./UserRow";
import { Loader } from "components";
import UserAddHits from "scenes/Campaigns/AddUsersToCampaignModal/UserAddHits";
import { getCampaignSelected } from "services/campaign/selectors";

const searchClient = algoliasearch(
  "MTMFJ78PRC",
  "a6400a80c85f1dcceaaddde616c096a3",
);

const styles = theme => ({
  main: {
    marginTop: 50,
  },
  leftDiv: {
    height: "100%",
    borderRight: "solid 1px",
    paddingRight: 25,
    borderRightColor: Colors.lightGrey,
    flex: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  rightDiv: {
    paddingLeft: 25,
    flex: 3,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  mainDiv: {
    height: 700,
    flex: 1,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  saveButton: {
    alignSelf: "center",
    width: "75%",
    marginTop: 20,
    marginBottom: 10,
  },
  answerSeparatorText: {
    color: Colors.black,
    fontSize: 16,
    fontWeight: "500",
    whiteSpace: "nowrap",
    marginTop: 5,
    marginLeft: 10,
    marginRight: 10,
  },
  clickText: {
    fontSize: 16,
    fontWeight: "500",
    marginBottom: 15,
    marginLeft: 10,
    marginRight: 10,
  },
  separator: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  invite: {
    cursor: "pointer",
    color: "#1264A3",
    transition: "all 0.2s",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  participantText: {
    marginBottom: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  accessUsers: {
    width: 400,
  },
  addEveryoneButton: {
    fontSize: 12,
    alignSelf: "center",
    marginTop: 15,
  },
  removeButton: {
    backgroundColor: Colors.orange,
    "&:hover": {
      backgroundColor: Colors.orange,
    },
    "&:focus": {
      backgroundColor: Colors.orange,
    },
  },
});

const AddExistingLearnersTab = ({
  classes,
  clientId,
  campaignId,
  addParticipantsRequest = () => null,
  users,
  participants = [],
  autoRegister,
  accessCode,
  changeGeneralSettings,
  requesting = false,
}) => {
  const [usersSelected, setSelectedUsers] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);

  useEffect(() => {
    const allUsers = users.filter(
      user => usersSelected.findIndex(el => el._id === user._id) === -1,
    );
    const availableUsers = allUsers.filter(
      user => participants.findIndex(id => id === user._id) === -1,
    );

    setAvailableUsers(availableUsers);
  }, [participants, users, usersSelected]);

  const onClickAdd = userAdded => () => {
    const newUsersSelected = [userAdded, ...usersSelected];
    setSelectedUsers(newUsersSelected);
  };

  const save = () => {
    const newParticipantsSelected = usersSelected.filter(
      user => participants.findIndex(el => el === user._id) === -1,
    );
    const newParticipants = newParticipantsSelected.map(el => el._id);
    addParticipantsRequest({
      campaignId,
      newParticipants,
    });
    setSelectedUsers([]);
  };

  const clickInvite = () => {
    changeGeneralSettings({
      isInviteUsersModalVisible: true,
      accessCode: autoRegister ? null : accessCode,
    });
  };

  const onClickRemove = user => () => {
    const newUsersSelected = usersSelected.filter(el => el._id !== user._id);
    setSelectedUsers(newUsersSelected);
  };

  const displayUsers = users => {
    return users.map((el, index) => {
      return (
        <UserRow
          {...el}
          key={index}
          isSelected
          onClick={onClickRemove(el)}
          isRoleInputDisabled={() => true}
        />
      );
    });
  };

  const isExcluded = usersRegistered => item => {
    const formattedIds = participants.map(el => {
      const result = { _id: el };
      return result;
    });
    const remainingUsers = [...usersSelected, ...formattedIds];
    const userId = item._id;
    return remainingUsers.findIndex(el => el._id === userId) > -1;
  };

  const addEveryone = () => {
    if (availableUsers.length === usersSelected.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(availableUsers);
    }
  };

  return (
    <div className={classes.main}>
      <div className={classes.mainDiv}>
        <div className={classes.leftDiv}>
          <div className={classes.separator}>
            <Typography className={classes.clickText}>
              {i18n.t("users-search-add")}
            </Typography>
          </div>
          <div className={classes.searchDiv}>
            <InstantSearch searchClient={searchClient} indexName={clientId}>
              <div className={classes.searchBox}>
                <SearchBox
                  showLoadingIndicator
                  translations={{
                    placeholder: i18n.t("search-user-placeholder"),
                  }}
                />
              </div>
              <FlatButton
                className={`${classes.addEveryoneButton} ${
                  availableUsers.length === usersSelected.length &&
                  classes.removeButton
                }`}
                fullWidth
                onClick={addEveryone}
                disabled={availableUsers.length === 0}
                title={
                  availableUsers.length !== usersSelected.length ||
                  availableUsers.length === 0
                    ? i18n.t("select-everyone")
                    : i18n.t("unselect-everyone")
                }
              />
              <Index className={classes.index} indexName={clientId}>
                <Configure filters="type:user" hitsPerPage={1000} />
                <UserAddHits
                  onClick={onClickAdd}
                  isExcluded={isExcluded(usersSelected)}
                />
              </Index>
            </InstantSearch>
          </div>
        </div>

        <div className={classes.rightDiv}>
          <div className={classes.userDiv}>
            <Typography className={classes.subtitle}>
              {i18n.t("invite-first-1")}
              <span className={classes.invite} onClick={clickInvite}>
                {i18n.t("invite-first-2")}
              </span>
            </Typography>
          </div>

          {requesting ? (
            <Loader />
          ) : (
            <FlatButton
              className={classes.saveButton}
              fullWidth
              disabled={usersSelected.length === 0}
              onClick={save}
              variant="contained"
              color="primary"
              title={i18n.t("add-participant-save")}
            />
          )}
          <div className={classes.participantText}>
            <Typography className={classes.answerSeparatorText}>
              {i18n.t("add-new-participant-1")}
              <span className={classes.accent}>{usersSelected.length}</span>
              {usersSelected.length > 1
                ? i18n.t("add-new-participant-2-plural")
                : i18n.t("add-new-participant-2-singular")}
            </Typography>
          </div>
          <div className={classes.accessUsers}>
            {displayUsers(usersSelected)}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const clientId = getSelectedClientId(state);
  const { users } = state.myUsers;
  const { requesting, autoRegister } = state.campaign;
  const campaignSelected = getCampaignSelected(state);
  const campaignId = campaignSelected._id;
  const { accessCode, participants } = campaignSelected;
  return {
    clientId,
    campaignId,
    requesting,
    accessCode,
    autoRegister,
    users,
    participants,
  };
};

export default connect(mapStateToProps, {
  changeGeneralSettings,
  addParticipantsRequest,
})(withStyles(styles)(AddExistingLearnersTab));
