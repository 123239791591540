import { createSelector } from "reselect";
import {
  getSelectedClientId,
  getUserClientId,
  getIsSuperAdmin,
} from "services/user/selectors";
import { getSelectedClient } from "services/myClients/selectors";

export const getSelectedClientAccessCode = createSelector(
  getSelectedClient,
  client => {
    return client.accessCode;
  },
);

export const getSelectedClientIsPhoneNumberRequired = createSelector(
  getSelectedClient,
  client => {
    return client.isPhoneNumberRequired || false;
  },
);

export const getAllActionsClient = state => state.client?.allActions;

export const getIsEnterpriseAccount = state => {
  return state.client.isEnterpriseAccount;
};

export const getIsCoachingAccount = state => {
  return state.client.isCoachingAccount;
};

export const getIsSuperAdminClientSelected = createSelector(
  getSelectedClientId,
  getUserClientId,
  getIsSuperAdmin,
  (selectedClientId, clientId, isSuperAdmin) => {
    return selectedClientId === clientId && isSuperAdmin;
  },
);

export const getPendingInvitations = createSelector(
  getSelectedClient,
  client => {
    return client.pendingInvitations;
  },
);
