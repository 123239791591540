import React from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography } from "@mui/material";
import i18n from "../../../i18n";
import Folder from "./Folder";
import Colors from "../../../constants/Colors";

const styles = theme => ({
  main: {
    boxShadow: "rgba(55, 53, 47, 0.16) 0px -1px inset",
    background: "white",
    paddingTop: 6,
    paddingBottom: 6,
  },
  objectiveDiv: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    paddingTop: 5,
    paddingLeft: 14,
    paddingRight: 14,
    minHeight: 28,
    userSelect: "none",
    transition: "background 120ms ease-in 0s",
    cursor: "pointer",
    width: "100%",
    "&:hover": {
      background: Colors.lightGrey,
    },
  },
  delimiter: {
    marginTop: 6,
    marginBottom: 8,
    fontSize: 12,
    paddingLeft: 14,
    paddingRight: 14,
    color: "rgba(55, 53, 47, 0.6)",
  },
  moreButton: {
    top: 0,
    padding: 5,
    position: "absolute",
    right: 2,
  },
  create: {
    marginRight: 7,
  },
});

const AddFolderBox = ({
  classes,
  foldersSuggestion,
  onClickFolder,
}) => {

  return (
    <div className={classes.main}>
      {/* <FolderOptionMenu
        isVisible={Boolean(anchorEl)}
        anchorEl={anchorEl}
        objective={objectiveSelected}
        onClose={onCloseMenu}
      /> */}
      <Typography className={classes.delimiter}>
        {i18n.t("select-folders")}
      </Typography>
      <div className={classes.foldersSuggestion}>
        {/* {search.length > 0 && (
          <div className={classes.objectiveDiv} onClick={onClickCreate}>
            <Typography className={classes.create}>
              {i18n.t("create")}
            </Typography>
            <Folder label={search} />
          </div>
        )} */}
        {foldersSuggestion.map((el, index) => (
          <div
            key={index}
            onClick={onClickFolder(el)}
            className={classes.objectiveDiv}
          >
            <Folder {...el} />
            {/* {isFolderHovered(el.id) && (
              <IconButton
                size="small"
                className={classes.moreButton}
                onClick={onClickFolderOption(el)}
              >
                <MoreIcon style={{ fontSize: 16 }} />
              </IconButton>
            )} */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default withStyles(styles)(AddFolderBox);
