import React from "react";

const FolderIcon = ({
  color = "#CBCBCB",
  className = "",
  width = 11,
  height = 10,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 11 10"
    fill="none"
    preserveAspectRatio="xMidYMid meet"
    className={className}
  >
    <path
      d="M9.87308 1.66475H4.64749L3.71696 0.798707C3.66641 0.751525 3.60633 0.714104 3.54019 0.688594C3.47405 0.663083 3.40315 0.649986 3.33155 0.650055H1.15105C0.54977 0.650055 0.060791 1.10514 0.060791 1.66475V8.76758C0.060791 9.32718 0.54977 9.78227 1.15105 9.78227H9.87308C10.4744 9.78227 10.9633 9.32718 10.9633 8.76758V2.67944C10.9633 2.11983 10.4744 1.66475 9.87308 1.66475Z"
      fill={color}
    />
  </svg>
);

export default FolderIcon;
