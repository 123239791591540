import React, { PureComponent } from "react";
import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Colors from "../../constants/Colors";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";
import { changeField, addStep } from "../../services/createTrack/actions";
import moment from "moment";
import { Typography } from "@mui/material";
import i18n from "../../i18n";
import { getSelectedClientId, getUserId } from "../../services/user/selectors";
import { getAppLanguage } from "../../services/general/selectors";
import Library from "./Library";

moment.locale("fr");

const styles = theme => ({
  container: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  paper: {
    alignItems: "center",
    display: "flex",
    minHeight: "calc(100% - 0px)",
    // minWidth: "100%",
    minWidth: "70%",
    justifyContent: "flex-start",
    lineHeight: 1.46666667,
    overflowY: "auto",
    margin: 0,
    paddingTop: "25px",
    borderRight: ".25rem solid transparent",
    borderRadius: 12,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    position: "unset",
    // borderBottomLeftRadius: 0,
  },
  closeButton: {
    // width: 64,
    fontSize: 16,
    borderRadius: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    zIndex: 10,
    position: "absolute",
    right: 10,
    top: -15,
    color: Colors.black,
    "&:hover": {
      color: Colors.black,
    },
  },
  labelIconButton: {
    flexDirection: "column",
  },
  line: {
    width: "100%",
    marginBottom: 25,
    borderRadius: 8,
    height: 2,
    backgroundColor: Colors.blackGrey,
  },
  errorText: {
    color: "#e6525a",
    padding: "13px 15px",
    borderRadius: 6,
    backgroundColor: "rgba(230,82,90,0.15)",
  },
  bodyDiv: {
    paddingTop: 8,
    paddingBottom: 8,
    width: "100%",
    maxWidth: "100%",
    // width: 960,
  },
  exitModalText: {
    marginTop: -4,
    fontSize: 13,
  },
  title: {
    textAlign: "center",
    fontFamily: "Montserrat",
    // color: Colors.black,
    alignSelf: "center",
    marginBottom: 10,
    fontSize: 28,
    lineHeight: 1.2143,
    fontWeight: 700,
  },
  subTitle: {
    textAlign: "center",
    // color: Colors.black,
    color: Colors.grey,
    marginTop: 5,
    fontSize: 14,
    lineHeight: 1.2143,
  },
  buttonDiv: {
    paddingTop: 64,
  },
  leftButton: {
    marginRight: 12,
    color: Colors.black,
    backgroundColor: "transparent",
    border: "1px solid",
    borderColor: "rgba(29, 28, 29, 0.3)",
    transition: "all 80ms linear",
    "&:hover": {
      backgroundColor: "transparent",
      border: "1px solid",
      borderColor: "rgba(29, 28, 29, 0.3)",
      boxShadow: "0 1px 3px 0 rgba(0,0,0,.08)",
    },
  },
  uploader: {
    marginTop: 16,
  },
  flagDiv: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  textInfo: {
    fontWeight: "normal",
    color: Colors.grey,
    fontSize: 18,
  },
  autoInviteDiv: {
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "row",
  },
  autoInvite: {
    marginTop: 5,
    flex: 1,
    maxWidth: "75%",
    marginRight: 25,
  },
  teamSizeField: {
    minWidth: "33%",
  },
  addButton: {
    position: "absolute",
    marginLeft: 16,
    top: 20,
    right: 0,
    cursor: "pointer",
    zIndex: 10,
    padding: "7px 15px",
    marginRight: 85,
    backgroundColor: Colors.pureMain,
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  buttonText: {
    fontSize: 16,
    marginLeft: 8,
    color: "white",
  },
  comments: {
    width: 960,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  ratingDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  star: {
    marginRight: 5,
  },
  rating: {
    marginTop: 5,
    marginLeft: 5,
    fontSize: "1rem",
  },
  topDiv: {
    paddingLeft: 25,
    paddingRight: 25,
    width: "100%",
    justifyContent: "center",
    alignItems: "flex-start",
    position: "relative",
  },
});

class ExistingContentModal extends PureComponent {
  constructor(props) {
    super(props);
    // participants
    this.state = {
      open: false,
      error: "",
    };
    this.baseState = this.state;
  }

  _handleKeyDown = event => {
    if (
      (window.navigator.platform.match("Mac")
        ? event.metaKey
        : event.ctrlKey) &&
      event.keyCode === 83
    ) {
      event.preventDefault();
    }
  };

  onClickAdd = () => {
    this.props.addStep({
      ...this.props.stepPreview,
      step: this.props.stepPreview._id,
    });
    this.props.onClose();
  };

  onRendered = () => {
    // reset state and props
    // this.setState({ stepPreview: this.props.stepPreview });
  };

  handleClose = onClose => () => {
    // document.removeEventListener("keydown", this._handleKeyDown, false);
    onClose();
  };

  render() {
    const { classes, isVisible, onClose, hasCreateNewAction } = this.props;
    return (
      <Dialog
        open={isVisible}
        // open={true}
        ref={this.onRendered}
        onClose={this.handleClose(onClose)}
        classes={{ paper: classes.paper, container: classes.container }}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        <>
          <div className={classes.topDiv}>
            <IconButton
              className={classes.closeButton}
              classes={{ label: classes.labelIconButton }}
              onClick={onClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>
            <Typography className={classes.title}>
              {i18n.t("add-existing-content")}
            </Typography>
            <Typography className={classes.subTitle}>
              {i18n.t("arrow-to-navigate-library")}
            </Typography>
          </div>
          <DialogContent className={classes.bodyDiv}>
            <Library hasCreateNewAction={hasCreateNewAction} />
          </DialogContent>
        </>
      </Dialog>
    );
  }
}

ExistingContentModal.defaultProps = {
  isAddButton: true,
  hasCreateNewAction: false,
  rating: null,
  comments: [],
};

const mapStateToProps = state => {
  const language = getAppLanguage(state);
  const clientId = getSelectedClientId(state);
  const userId = getUserId(state);
  const {
    languageSelected,
    title,
    description,
    picture,
    requesting,
  } = state.createTrack;
  const { uploading } = state.upload;

  return {
    trackId: state.createTrack._id,
    userId,
    clientId,
    language,
    languageSelected,
    title,
    description,
    uploading,
    picture,
    uploadedPicture: state.upload.picture,
    requesting,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    changeField,
    addStep,
  })(ExistingContentModal),
);
