import React, { PureComponent } from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import { logout } from "../../services/auth/reducer";
import {
  getSelectedClientId,
  getUserClientId,
} from "../../services/user/selectors";
import { changeField } from "../../services/client/actions";
import {
  getSelectedClientRequest,
  modifyMyClientRequest,
} from "../../services/myClients/actions";
import { getSkillsListRequest } from "../../services/createTrack/actions";
import { uploadPictureRequest } from "../../services/upload/actions";
import { changeGeneralSettings } from "../../services/general/actions";
import i18n from "../../i18n";
import { Typography } from "@mui/material";
import { getAppLanguage } from "../../services/general/selectors";
import RoundButton from "../../components/RoundButton";
import Switch from "react-switch";
import { getSelectedClient } from "../../services/myClients/selectors";
import SkillInput from "../CreateTrack/Skills/SkillInput";
import { getIsCoachingAccount } from "services/client/selectors";

const styles = theme => ({
  main: {
    height: "100%",
    width: "100%",
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    width: "100%",
    height: "100%",
    minHeight: 500,
    paddingRight: 45,
    paddingLeft: 20,
    marginTop: theme.spacing.unit * 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    overflowX: "auto",
  },
  title: {
    fontFamily: "Montserrat",
    marginBottom: 10,
    fontSize: 28,
    lineHeight: 1.2143,
    fontWeight: 700,
  },
  subTitle: {
    fontFamily: "Montserrat",
    marginTop: 20,
    marginBottom: 5,
    fontSize: 22,
    lineHeight: 1.2143,
    fontWeight: 700,
  },
  saveButton: {
    marginTop: 25,
  },
  autoInviteDiv: {
    paddingTop: 15,
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "row",
  },
});

class CustomOnboarding extends PureComponent {
  state = {
    ...this.props,
  };

  componentDidMount = () => {
    const { userClientId } = this.props;
    this.props.getSkillsListRequest({ clientId: userClientId });
  };

  saveChanges = () => {
    const { defaultUserSkills, hasCustomOnboarding } = this.state;
    const { clientId } = this.props;
    this.props.modifyMyClientRequest({
      clientId,
      defaultUserSkills,
      hasCustomOnboarding,
    });
  };

  onChangeClientParams = param => event => {
    const value = event.target.value;
    this.setState({ [param]: value });
  };

  toggleHasOnboarding = () => {
    const hasCustomOnboarding = !this.state.hasCustomOnboarding;
    this.setState({ hasCustomOnboarding });
  };

  onChangeSkills = defaultUserSkills => {
    this.setState({ defaultUserSkills });
  };

  render() {
    const { classes, language } = this.props;
    const {
      hasCustomOnboarding = false,
      isCoachingAccount = false,
      defaultUserSkills = [],
    } = this.state;

    return (
      <div className={classes.main}>
        <div className={classes.root}>
          <>
            <Typography className={classes.subTitle}>
              {i18n.t("has-custom-onboarding")}
            </Typography>
            <div className={classes.autoInviteDiv}>
              <Switch
                onChange={this.toggleHasOnboarding}
                checked={hasCustomOnboarding}
              />
            </div>
          </>
          {!isCoachingAccount && (
            <>
              <Typography
                className={classes.subTitle}
                style={{ marginBottom: 10 }}
              >
                {i18n.t("default-user-skills")}
              </Typography>
              <SkillInput
                language={language}
                skills={defaultUserSkills}
                updateStep={this.onChangeSkills}
              />
            </>
          )}
          <RoundButton
            onClick={this.saveChanges}
            className={classes.saveButton}
            title={i18n.t("save-changes")}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const language = getAppLanguage(state);
  const userClientId = getUserClientId(state);
  const clientId = getSelectedClientId(state);
  const selectedClient = getSelectedClient(state);
  const { uploading } = state.upload;
  const {
    name,
    logo,
    hasCustomOnboarding,
    defaultUserSkills,
    hasAvatars,
    hasMbti,
  } = selectedClient;
  const uploadedPicture = state.upload.picture;
  const isCoachingAccount = getIsCoachingAccount(state);
  return {
    language,
    hasCustomOnboarding,
    isCoachingAccount,
    defaultUserSkills,
    hasAvatars,
    hasMbti,
    userClientId,
    clientId,
    name,
    logo,
    uploadedPicture,
    uploading,
  };
};

export default connect(mapStateToProps, {
  logout,
  changeGeneralSettings,
  changeField,
  getSelectedClientRequest,
  modifyMyClientRequest,
  uploadPictureRequest,
  getSkillsListRequest,
})(withStyles(styles)(CustomOnboarding));
