import React, { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";

import withStyles from '@mui/styles/withStyles';
import Dialog from "@mui/material/Dialog";

import CloseIcon from "@mui/icons-material/Close";
import LeftUserProfile from "scenes/Campaigns/SeeUserModal/LeftUserProfile";
import RightUserProfile from "scenes/Campaigns/SeeUserModal/RightUserProfile";
import Colors from "constants/Colors";
import MyTabs from "components/MyTabs";
import i18n from "i18n";
import Coaching from "scenes/Campaigns/SeeUserModal/Coaching";
import { getHasCoachingSession } from "services/coaching/selectors";
import { clearCoachingSession } from "services/coaching/actions";
import SurveysSide from "scenes/Campaigns/CampaignScreen/SurveysSide";
import { getIsCoachingAccount } from "services/client/selectors";
import { getIsUserSelectedATestAccount } from "services/manager/selectors";
import TestAccountBanner from "scenes/Campaigns/SeeUserModal/TestAccountBanner";
import InvoiceList from "scenes/Invoice/InvoiceList/InvoiceList";
import queryString from "query-string";

const styles = theme => ({
  paper: {
    flex: 1,
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    borderRadius: 0,
    minHeight: "100%",
    minWidth: "100%",
  },
  leftDiv: {
    width: 240,
  },
  closeButton: {
    cursor: "pointer",
    backgroundColor: Colors.pureMain,
    borderBottomLeftRadius: 4,
    height: 40,
    width: 40,
    zIndex: 10,
    position: "absolute",
    top: 0,
    right: 0,
    transition: "all 0.2s ease",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "&:hover": {
      opacity: 0.9,
    },
  },
  rightDiv: {
    overflow: "auto",
    display: "flex",
    flex: 3,
    flexDirection: "column",
    backgroundColor: "#F2F3F5",
  },
  box: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "#F2F3F5",
    flex: 4,
    width: "100%",
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 50,
    paddingTop: 25,
    alignSelf: "center",
    maxWidth: "1200px",
  },
  "@media screen and (max-device-width: 1200px)": {
    box: {
      paddingLeft: 30,
      paddingRight: 30,
      maxWidth: 700,
    },
  },
  "@media screen and (max-device-width: 1440px) and (min-device-width: 1200px)": {
    box: {
      paddingLeft: 60,
      paddingRight: 60,
      maxWidth: 1000,
    },
  },
  "@media screen and (min-device-width: 1440px)": {
    box: {
      paddingLeft: 0,
      paddingRight: 0,
      maxWidth: 1100,
    },
  },
  closeIcon: {
    color: "#fff",
    width: 20,
    height: 20,
    zIndex: 5,
  },
  separator: {
    marginBottom: 25,
  },
});

const renderTabs = (hasCoaching, isCoachingAccount) => {
  if (hasCoaching && isCoachingAccount) {
    return [
      i18n.t("coaching"),
      i18n.t("Stats"),
      i18n.t("Surveys"),
      i18n.t("invoices"),
    ];
  } else if (hasCoaching) {
    return [i18n.t("coaching"), i18n.t("stats")];
  }
  return [i18n.t("stats")];
};

const renderTabsContent = (tabs, tabSelected) => {
  if (tabs.length === 1 || tabSelected === 1) {
    return <RightUserProfile />;
  }
  if (tabSelected === 0) return <Coaching />;
  if (tabSelected === 2) return <SurveysSide />;
  if (tabSelected === 3) return <InvoiceList />;
};

const UserModal = ({
  classes,
  onClose,
  open,
  hasCoaching = false,
  isCoachingAccount = false,
  isUserSelectedATestAccount = false,
}) => {
  const query = queryString.parse(window.location.search);
  const { tabSelectedIndex = 0 } = query;
  const [tabSelected, selectTab] = useState(parseInt(tabSelectedIndex, 10));
  const tabs = useMemo(() => renderTabs(hasCoaching, isCoachingAccount), [
    hasCoaching,
    isCoachingAccount,
  ]);

  const dispatch = useDispatch();

  const handleSelectTab = tabIndex => {
    const { campaignId } = query;
    if (isCoachingAccount) {
      window.history.replaceState(
        null,
        null,
        `/my-coachees?campaignId=${campaignId}&tabSelectedIndex=${tabIndex}`,
      );
    }

    selectTab(tabIndex);
  };

  useEffect(() => {
    return () => {
      dispatch(clearCoachingSession());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!open) {
      dispatch(clearCoachingSession());
    }
  }, [open, dispatch]);

  const onRendered = () => {
    const goodTabIndex = parseInt(tabSelectedIndex, 10);
    handleSelectTab(goodTabIndex);
    if (window.Intercom) {
      window.Intercom("update", {
        hide_default_launcher: true,
      });
    }
  };

  const handleClose = () => {
    dispatch(clearCoachingSession());
    if (window.Intercom) {
      window.Intercom("update", {
        hide_default_launcher: false,
      });
    }
    onClose();
  };

  const tabsContent = useMemo(() => renderTabsContent(tabs, tabSelected), [
    tabs,
    tabSelected,
  ]);

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={open}
      onClose={handleClose}
      scroll={"paper"}
      ref={onRendered}
    >
      <div className={classes.closeButton} onClick={handleClose}>
        <CloseIcon className={classes.closeIcon} />
      </div>
      <div className={classes.leftDiv}>
        <LeftUserProfile />
      </div>
      <div className={classes.rightDiv}>
        {isUserSelectedATestAccount && <TestAccountBanner />}
        <div className={classes.box}>
          <MyTabs
            variant="contained"
            centered={false}
            tabSelected={tabSelected}
            selectTab={handleSelectTab}
            tabs={tabs}
          />
          <div className={classes.separator} />
          {tabsContent}
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    hasCoaching: getHasCoachingSession(state),
    isCoachingAccount: getIsCoachingAccount(state),
    isUserSelectedATestAccount: getIsUserSelectedATestAccount(state),
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(UserModal));
