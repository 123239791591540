import React from "react";
import { styled } from "@mui/styles";
import { Dialog, DialogProps } from "@mui/material";

const BlurryDialog = styled(Dialog)<DialogProps>(({ theme }) => ({
  backdropFilter: "blur(3px)",
  opacity: 1,
  "& .MuiDialog-paper": {
    borderRadius: 10,
    backgroundColor: "white",
    width: "50vw",
    maxHeight: "80vh",
  },
}));

interface Props {
  isOpen: boolean;
  children: React.ReactElement;
  handleClose: () => void;
}

const DialogBlurParent = ({ isOpen, children, handleClose }: Props) => {
  return (
    <BlurryDialog open={isOpen} onClose={handleClose}>
      <div>{children}</div>
    </BlurryDialog>
  );
};

export default DialogBlurParent;
