import { call, put, takeLatest, all, select } from "redux-saga/effects";

import { REHYDRATE } from "redux-persist";

import {
  CLIENT_GET_REQUESTING,
  CLIENT_GET_ERROR,
  CLIENT_GET_SUCCESS,
  CLIENT_MODIFY_REQUESTING,
  CLIENT_MODIFY_SUCCESS,
  CLIENT_MODIFY_ERROR,
} from "./constants";

import { fetchApi } from "../api";
import i18n from "../../i18n";
import { getAppLanguage } from "../general/selectors";
import { openSnackMessage } from "../general/actions";
import changeLocal from "../../utils/changeLocal";

const apiEndpoints = {
  get: "/v1/clients/", // clientId
  create: "/v1/clients/",
  modify: "/v1/clients/", // clientId
};

export const getClientApi = data => {
  const { clientId } = data;
  return fetchApi(apiEndpoints.get + clientId, data, "get");
};

export const modifyClientApi = data => {
  const { clientId } = data;
  return fetchApi(apiEndpoints.modify + clientId, data, "patch");
};

function* getClientFlow(action) {
  try {
    const client = yield call(getClientApi, action.payload);
    yield put({ type: CLIENT_GET_SUCCESS, payload: client });
  } catch (error) {
    yield put({ type: CLIENT_GET_ERROR, payload: error });
  }
}

function* modifyClientFlow(action) {
  try {
    const client = yield call(modifyClientApi, action.payload);

    yield put({ type: CLIENT_MODIFY_SUCCESS, payload: client });
    yield put(
      openSnackMessage({ snackMessage: i18n.t("account-modified-success") }),
    );
  } catch (error) {
    yield put({ type: CLIENT_MODIFY_ERROR, payload: error });
  }
}

function* rehydrateLanguageFlow(action) {
  const language = yield select(getAppLanguage);
  i18n.changeLanguage(language);
  changeLocal(language);
}

function* Saga() {
  yield all([
    takeLatest(CLIENT_GET_REQUESTING, getClientFlow),
    takeLatest(CLIENT_MODIFY_REQUESTING, modifyClientFlow),
    takeLatest(REHYDRATE, rehydrateLanguageFlow),
  ]);
}

export default Saga;
