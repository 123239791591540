import {
  USER_SET,
  USER_UNSET,
  USER_MODIFY,
  USER_MODIFY_ERROR,
  USER_GET_COMPANY_LOGO_SUCCESS,
  USER_MODIFY_REQUESTING,
  USER_GET_ERROR,
  USER_GET_SUCCESS,
  USER_GET_REQUESTING,
} from "./constants";

import { AUTH_LOGOUT_SUCCESS, AUTH_SUCCESS } from "../auth/reducer";
import { SIGNUP_CREATE_CLIENT_SUCCESS } from "../signup/constants";

import moment from "moment";

const initialState = {
  requesting: false,
  createdAt: "",
  googleTokens: null,
  companyLogo: "",
  _id: "",
  email: "",
  picture: "",
  role: "user",
  client: "",
  experience: 0,
  level: 1,
  fullName: "Sans nom",
  firstName: "",
  lastName: "",
  jobFunction: "",
  manager: "",
  feed: [],
  deviceId: "",
  timezone: "Europe/Paris",
  ideaVoteHistorical: [],
  achievement: {
    firstOfAll: false,
  },
  bestStreak: 0,
  totalDoneActions: 0,
  currentStreak: 0,
  focus: false,
  latestDays: [
    {
      done: false,
    },
    { done: false },
    { done: false },
    { done: false },
    { done: false },
  ],
  historical: [{ date: moment().subtract(1, "day") }],
  teamRank: 0,
  rank: 0,
  bio: "",
  jobDescription: "",
  jobDuration: 0,
  appAccess: "all",
  notifications: [],
  team: { users: [] },
  actionSeenDate: moment().subtract(1, "day"),
  goals: { global: 0 },
  stats: {
    recruiting: 5,
    integration: 5,
    position: 5,
    training: 5,
    preparation: 5,
    briefing: 5,
    visualization: 5,
    debriefing: 5,
    capitalization: 5,
    teamCohesion: 5,
    motivation: 5,
    leadership: 5,
    confidence: 5,
    stress: 5,
    publicSpeaking: 5,
  },
  feelingEvolution: [],
  achievements: {
    first: { name: "first", level: 0 },
    streak: { name: "streak", level: 0 },
    medal: { name: "medal", level: 0 },
    ally: { name: "ally", level: 0 },
    star: { name: "star", level: 0 },
    champion: { name: "champion", level: 0 },
  },
  categories: {
    confidence: {
      level: 0,
      experience: 0,
    },
    stress: {
      level: 0,
      experience: 0,
    },
    publicSpeaking: {
      level: 0,
      experience: 0,
    },
    teamCohesion: {
      level: 0,
      experience: 0,
    },
    proactivity: {
      level: 0,
      experience: 0,
    },
    leadership: {
      level: 0,
      experience: 0,
    },
    resilience: {
      level: 0,
      experience: 0,
    },
  },
  teamPoints: 0,
};

const reducer = function userReducer(state = initialState, action) {
  switch (action.type) {
    case USER_GET_SUCCESS: {
      return { ...state, requesting: false };
    }

    case USER_SET:
      return { ...state, ...action.user };

    case USER_UNSET:
      return initialState;

    case USER_MODIFY: {
      return { ...state, ...action.payload };
    }

    case SIGNUP_CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        client: action.payload._id,
      };

    case USER_GET_REQUESTING:
    case USER_MODIFY_REQUESTING:
      return {
        ...state,
        requesting: true,
      };

    case USER_GET_COMPANY_LOGO_SUCCESS:
      return { ...state, companyLogo: action.payload };

    case USER_GET_ERROR:
    case USER_MODIFY_ERROR:
      return { ...state, error: action.payload, requesting: false };

    case AUTH_SUCCESS:
      return { ...state, ...action.user };

    case AUTH_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
