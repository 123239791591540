import React, { PureComponent } from "react";
import { connect } from "react-redux";

import withStyles from "@mui/styles/withStyles";
import i18n from "../../../i18n";

import RankingComponent from "../../Recap/RankingComponent";
import { Loader } from "../../../components";
import { seeUserProfileRequest } from "services/manager/actions";
import { cleanMessage, setReceiver } from "services/message/actions";
import { push } from "connected-react-router";
import {
  clearCoachingSession,
  getCoachingSessionRequest,
} from "services/coaching/actions";
import { getUserId } from "services/user/selectors";

const styles = theme => ({
  main: {
    flex: 1,
    width: "100%",
    flexDirection: "row",
    marginTop: 30,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  leftSection: {
    width: "calc(50% - 15px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  rightSection: {
    width: "calc(50% - 15px)",
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

class RankingSection extends PureComponent {
  componentDidMount = () => {};

  onClickUser = userSelected => {
    this.props.clearCoachingSession();
    this.props.cleanMessage();
    this.props.setReceiver({
      _id: userSelected._id,
      picture: userSelected.picture,
    });
    this.props.seeUserProfileRequest({
      userId: userSelected._id,
      managerId: this.props._id,
    });
    this.props.getCoachingSessionRequest({
      userId: userSelected._id,
      coachId: this.props._id,
    });
    this.props.push(`/user/${userSelected._id}`);
  };

  render() {
    const {
      classes,
      className,
      userRanking,
      teamRanking,
      userRankingRequesting,
      teamRankingRequesting,
    } = this.props;
    return (
      <div className={`${classes.main} ${className}`}>
        <div className={classes.leftSection}>
          {userRankingRequesting ? (
            <Loader />
          ) : (
            <RankingComponent
              onClickUser={this.onClickUser}
              title={i18n.t("top-players")}
              ranking={userRanking}
              hasPicture
            />
          )}
        </div>
        {this.props.isTeam && (
          <div className={classes.rightSection}>
            {teamRankingRequesting ? (
              <Loader />
            ) : (
              <RankingComponent
                onClickUser={this.props.handleClickTeam}
                isTeam
                title={i18n.t("top-teams")}
                ranking={teamRanking}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

RankingSection.defaultProps = {
  isTeam: true,
};

const mapStateToProps = state => {
  const {
    userRanking,
    teamRanking,
    userRankingRequesting,
    teamRankingRequesting,
  } = state.campaign;
  const userId = getUserId(state);

  return {
    userRanking,
    teamRanking,
    userRankingRequesting,
    teamRankingRequesting,
    userId,
  };
};

export default connect(mapStateToProps, {
  seeUserProfileRequest,
  cleanMessage,
  setReceiver,
  clearCoachingSession,
  getCoachingSessionRequest,
  push,
})(withStyles(styles)(RankingSection));
