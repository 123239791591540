import React, { useCallback, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Colors from "../../../../constants/Colors";
import { Loader } from "../../../../components";
import moment from "moment";
import "tippy.js/dist/tippy.css";
import { useDispatch, useSelector } from "react-redux";
import { getUserTimezone } from "../../../../services/user/selectors";
import {
  getUserCampaignsListRequest,
  setSelectedDoneStep,
  setSortedDoneStep,
  setSortedDoneStepDesc,
} from "../../../../services/campaign/actions";
import i18n from "../../../../i18n";
import ListItemLoader from "../ListItemLoader";
import StatBox from "scenes/Campaigns/CampaignScreen/StatBox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  getCampaignSelectedIsLoading,
  getSelectedCampaignId,
  getUserCampaignDoneStepsSorted,
  getUserCampaignDoneStepsSortedDesc,
  getUserCampaignSortedDoneSteps,
} from "services/campaign/selectors";
import ReviewRow from "./ReviewRow";
import ReviewSearchIcon from "./icons/ReviewSearchIcon";
import ReviewValidatedIcon from "./icons/ReviewValidatedIcon";
import ReviewPendingIcon from "./icons/ReviewPendingIcon";
import { push } from "connected-react-router";
import makeStyles from '@mui/styles/makeStyles';

moment.locale("fr");

const useStyles = makeStyles(theme => ({
  rightDiv: {
    overflow: "auto",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 4,
  },
  firstSection: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    overflow: "auto",
    justifyContent: "space-around",
    alignItems: "flex-start",
    marginBottom: 15,
  },
  firstRow: {
    marginRight: 25,
    display: "flex",
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  tableHeaderRow: {
    boxSizing: "border-box",
    color: "#101534",
    fontSize: 18,
    width: "100%",
    marginBottom: 30,
    marginTop: 30,
    borderRadius: 8,
    paddingLeft: 45,
    display: "flex",
    alignItems: "space-between",
    flexDirection: "row",
    justifyContent: "center",
  },
  tableHeader: {
    boxSizing: "border-box",
    fontSize: 18,
    fontWeight: 500,
    color: Colors.darkBlue,
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  topDiv: {
    overflow: "auto",
    position: "relative",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  box: {
    boxSizing: "border-box",
    marginTop: 30,
    marginBottom: 15,
    width: "100%",
    overflow: "hidden",
    backgroundColor: "#fff",
    borderRadius: 10,
  },
}));

const ReviewsSide = () => {
  const campaignId = useSelector(getSelectedCampaignId);
  const doneSteps = useSelector(getUserCampaignSortedDoneSteps).filter(
    el => el?.stepId || {},
  );
  const isLoading = useSelector(getCampaignSelectedIsLoading);
  const timezone = useSelector(getUserTimezone);
  const sortedBy = useSelector(getUserCampaignDoneStepsSorted);
  const isSortedDesc = useSelector(getUserCampaignDoneStepsSortedDesc);
  const dispatch = useDispatch();

  const classes = useStyles();

  const mountComponent = useCallback(() => {
    dispatch(
      getUserCampaignsListRequest({
        campaignId: campaignId,
        page: 0,
        limit: 1000,
      }),
    );
  }, [campaignId, dispatch]);

  useEffect(() => {
    mountComponent();
  }, [mountComponent]);

  const displayArrow = param => {
    return sortedBy === param ? (
      isSortedDesc ? (
        <ExpandLessIcon />
      ) : (
        <ExpandMoreIcon />
      )
    ) : null;
  };

  const changeSortingParam = param => () => {
    dispatch(setSortedDoneStep(param));
    dispatch(setSortedDoneStepDesc(!isSortedDesc));
  };

  const getTotalDoneReviews = () => {
    return doneSteps.filter(el => el.isReviewValidated).length;
  };

  const getTotalReviews = () => {
    return doneSteps.length;
  };

  const onClickReview = reviewSelectedId => () => {
    dispatch(setSelectedDoneStep(reviewSelectedId));
    dispatch(push(`/campaigns/${campaignId}/review/${reviewSelectedId}`));
  };

  return (
    <>
      <div className={classes.rightDiv}>
        <div className={classes.firstSection}>
          <div className={classes.topDiv}>
            <StatBox
              icon={<ReviewSearchIcon />}
              stat={getTotalReviews()}
              title={i18n.t("review-status-new")}
            />
            <StatBox
              icon={
                <ReviewValidatedIcon color={"#8CCC2C"} sizePercentage={1} />
              }
              lightColor={"rgba(140, 204, 44, 0.2)"}
              title={i18n.t("total-reviews-validated")}
              stat={getTotalDoneReviews()}
            />
            <StatBox
              isLast
              icon={<ReviewPendingIcon color={"#F9A849"} />}
              lightColor={"rgba(249, 180, 77, 0.2)"}
              stat={getTotalReviews() - getTotalDoneReviews()}
              title={i18n.t("review-status-pending")}
            />
          </div>

          <div className={classes.box}>
            <div className={classes.tableHeaderRow}>
              <div
                className={classes.firstRow}
                onClick={changeSortingParam("fullName")}
              >
                <Typography className={classes.tableHeader}>
                  {i18n.t("table-header-participant")}
                </Typography>
                {displayArrow("fullName")}
              </div>
              <div
                className={classes.firstRow}
                onClick={changeSortingParam("title")}
              >
                <Typography className={classes.tableHeader}>
                  {i18n.t("review-table-header-title")}
                </Typography>
                {displayArrow("title")}
              </div>
              <div
                className={classes.firstRow}
                onClick={changeSortingParam("createdAt")}
              >
                <Typography className={classes.tableHeader}>
                  {i18n.t("review-table-header-date")}
                </Typography>
                {displayArrow("createdAt")}
              </div>
              <div
                className={classes.firstRow}
                onClick={changeSortingParam("status")}
              >
                <Typography className={classes.tableHeader}>
                  {i18n.t("review-table-header-status")}
                </Typography>
                {displayArrow("status")}
              </div>
            </div>
            {doneSteps.length < 1 && isLoading && <ListItemLoader />}
            {doneSteps.map((el, index) => (
              <ReviewRow
                timezone={timezone}
                index={index}
                key={index}
                doneStep={el}
                onClick={onClickReview(el._id)}
              />
            ))}
            {isLoading && <Loader size={50} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewsSide;
