import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  changeField,
  confirmEmailRequest,
  sendEmailVerificationRequest,
} from "../../services/signup/actions";

import Typography from "@mui/material/Typography";
import withStyles from '@mui/styles/withStyles';
import TextField from "@mui/material/TextField";
import FlatButton from "../../components/FlatButton";
import EmailLottie from "./EmailLottie";

import Colors from "../../constants/Colors";
import i18n from "../../i18n";
import { Loader } from "../../components";

const styles = theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "100vh",
    width: "100vw",
    backgroundColor: Colors.pureMain,
  },
  logo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: 75,
    // paddingTop: 0,
    margin: "20px auto 20px",
  },
  paper: {
    width: 470,
    display: "flex",
    borderRadius: 3,
    background: "white",
    padding: 40,
    paddingBottom: 20,
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 20,
  },
  title: {
    textAlign: "center",
    fontWeight: 300,
    color: "#444",
    margin: "0 auto 30px",
    fontSize: 30,
    lineHeight: "38px",
    textTransform: "none",
    letterSpacing: 0,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE11 issue.
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  loginError: {
    backgroundColor: "#FCE6E7",
    borderRadius: 8,
  },
  errorText: {
    color: "#e6525a",
    padding: "13px 15px",
    borderRadius: 6,
    backgroundColor: "rgba(230,82,90,0.15)",
  },
  textField: {
    zIndex: 5,
    marginTop: 20,
  },
  submitButton: {
    zIndex: 5,
    marginTop: 20,
    padding: 12,
    backgroundColor: "#15CD72",
    "&:hover": {
      backgroundColor: "#15CD72",
    },
  },
  noDecoration: {
    textDecoration: "none",
  },
  forgotPassword: {
    marginTop: 20,
    cursor: "pointer",
    textDecoration: "none",
    color: Colors.pureMain,
    fontSize: 17,
    lineHeight: 1.5,
    textAlign: "center",
  },
  newAccountText: {
    color: Colors.white,
    fontSize: 16,
    textAlign: "center",
  },
  bold: {
    marginLeft: 5,
    color: Colors.white,
    fontSize: 16,
    cursor: "pointer",
    fontWeight: "bold",
    textDecoration: "underline",
  },
  noAccountDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  topField: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  splitTextField: {
    width: "48.5%",
  },
  agreeTerms: {
    fontSize: 13,
    marginTop: 22,
    marginBottom: 10,
  },
  link: {
    textDecoration: "none",
    color: Colors.pureMain,
    fontWeight: "bold",
  },
  lottieDiv: {
    position: "relative",
    width: "100%",
    height: 120,
    marginTop: 20,
    marginBottom: -10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

class SignupValidateEmail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      phoneNumber: "",
      firstName: "",
      lastName: "",
    };
  }

  onChangeField = field => event => {
    this.props.changeField({ [field]: event.target.value });
  };

  onSubmit = () => {
    const { email } = this.props;
    this.props.sendEmailVerificationRequest({ email });
  };

  onSubmitLoginCode = () => {
    const { email, loginCode } = this.props;
    let cleanLoginCode = loginCode.replace(/[^a-zA-Z0-9]/g, "");
    cleanLoginCode = cleanLoginCode.toLowerCase();

    this.props.confirmEmailRequest({ token: cleanLoginCode, email });
  };

  render() {
    const { error, classes, requesting } = this.props;

    return (
      <React.Fragment>
        <div className={classes.form}>
          {error && <p className={classes.errorText}>{error}</p>}
          <TextField
            id="outlined-multiline-flexible"
            label={i18n.t("your-email")}
            onChange={this.onChangeField("email")}
            fullWidth
            autoFocus
            autoComplete="email"
            // rowsMax="4"
            value={this.props.email}
            className={classes.textField}
            margin="normal"
            variant="outlined"
          />
          <TextField
            id="outlined-multiline-flexible"
            label={i18n.t("login-code")}
            onChange={this.onChangeField("loginCode")}
            fullWidth
            autoFocus
            // rowsMax="4"
            value={this.props.loginCode}
            className={classes.textField}
            margin="normal"
            variant="outlined"
          />
          <Typography className={classes.text}>
            {i18n.t("verification-email-sent")}
          </Typography>
          {requesting ? (
            <Loader />
          ) : (
            <FlatButton
              fullWidth
              disabled={!this.props.loginCode}
              title={i18n.t("continue-login-code")}
              onClick={this.onSubmitLoginCode}
              className={classes.submitButton}
            />
          )}
          <div className={classes.lottieDiv}>
            <EmailLottie />
          </div>
          {requesting ? (
            <Loader />
          ) : (
            <FlatButton
              fullWidth
              title={i18n.t("send-email-again")}
              onClick={this.onSubmit}
              className={classes.submitButton}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

SignupValidateEmail.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const { email, error, requesting, loginCode, loading } = state.signup;
  return {
    loginCode,
    email,
    requesting,
    loading,
    error,
  };
};

export default connect(mapStateToProps, {
  changeField,
  sendEmailVerificationRequest,
  confirmEmailRequest,
})(withStyles(styles)(SignupValidateEmail));
