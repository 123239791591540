export const getUnsplashResults = state => state.upload.unsplashResults;

export const getUploading = state => state.upload.uploading;

export const getUploadedPicture = state => state.upload.picture;

export const getFileNameUpload = state => state.upload.fileName;

export const getFileTypeUpload = state => state.upload.fileType;

export const getFileToUpload = state => state.upload;

export const getFileUploadProgress = state =>
  state.upload.uploadProgressPercent;

export const getIsExportingVideo = state => state.upload.isExportingVideo;
