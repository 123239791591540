import React, { Component } from "react";
import { connect } from "react-redux";

import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import Colors from "constants/Colors";

import i18n from "i18n";
import StepBoxRow from "scenes/CreateTrack/StepBoxRow";
import { changeField, addStep } from "services/createTrack/actions";

const variants = {
  visible: {
    display: "block",
    opacity: 1,
    x: 0,
    y: 0,
  },

  hidden: {
    opacity: 0,
    x: 10,
    y: 10,
    transitionEnd: {
      display: "none",
    },
  },
};

const styles = theme => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    left: 0,
    top: "100%",
    marginLeft: 25,
    marginBottom: 50,
    position: "absolute",
    zIndex: 1200,
  },
  card: {
    backgroundColor: Colors.white,
    display: "flex",
    flexDirection: "row",
    borderRadius: 8,
    boxShadow: "0 2px 4px rgba(0,0,0,.08), 0 2px 12px rgba(0,0,0,.06)",
  },
  title: {
    alignSelf: "center",
    color: Colors.black,
    marginBottom: 10,
    fontSize: "1em",
  },
  addButton: {
    cursor: "pointer",
    padding: 10,
    backgroundColor: Colors.black,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  none: {
    display: "none",
  },
  leftDiv: {
    width: 216,
  },
  topLeftDiv: {
    paddingRight: 16,
    paddingTop: 12,
    paddingBottom: 12,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    fontSize: 14,
    minHeight: 300,
  },
  rightDiv: {
    width: 196,
    minHeight: 300,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    backgroundColor: "#F0F0F0",
    padding: 16,
  },
  picture: {
    backgroundColor: Colors.white,
    borderRadius: 4,
    marginBottom: 10,
    width: 164,
    height: 112,
  },
  stepExplanationTitle: {
    fontWeight: 500,
    fontSize: 14,
    marginBottom: 3,
  },
  stepExplanationDetails: {
    fontSize: 12,
  },
});

class AddQuestionBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepHoveredIndex: 0,
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  changeStepHovered = stepHoveredIndex => () => {
    this.setState({ stepHoveredIndex });
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside = event => {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      this.props.isVisible
    ) {
      this.props.clickOutside();
    }
  };

  renderStepExplanation = ({
    explanationPicture,
    explanationTitle,
    explanationDetails,
  }) => {
    const { classes } = this.props;
    return (
      <div className={classes.stepExplanationDiv}>
        <img
          src={explanationPicture}
          alt={`Step explanation`}
          className={classes.picture}
        />
        <Typography className={classes.stepExplanationTitle}>
          {explanationTitle}
        </Typography>
        <Typography className={classes.stepExplanationDetails}>
          {explanationDetails}
        </Typography>
      </div>
    );
  };

  onClickLibrary = () => {
    this.props.setVisibility(false);
    this.props.displayLibrary();
  };

  onClickNewActionTextStep = () => {
    this.props.setVisibility(false);
    this.props.onCreateNewStep();
  };

  render() {
    const { classes, onClick, isVisible } = this.props;
    return (
      <motion.div
        className={`${classes.main} ${this.state.isNotThere}`}
        ref={this.setWrapperRef}
        variants={variants}
        onClick={onClick}
        initial="hidden"
        animate={isVisible ? "visible" : "hidden"}
      >
        <div className={classes.card}>
          <div className={classes.leftDiv}>
            <div className={classes.topLeftDiv}>
              <Typography className={`${classes.title}`}>
                {i18n.t("choose-question-type")}
              </Typography>
              {this.props.rows.map(
                ({ title, questionType, onClick }, index) => (
                  <StepBoxRow
                    key={title}
                    title={title}
                    stepType={questionType}
                    onClick={onClick}
                    isSelected={this.state.stepHoveredIndex === index}
                    onMouseEnter={this.changeStepHovered(index)}
                  />
                ),
              )}
            </div>
          </div>
          <div className={classes.rightDiv}>
            {this.renderStepExplanation(
              this.props.rows[this.state.stepHoveredIndex],
            )}
          </div>
        </div>
      </motion.div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {
  changeField,
  addStep,
})(withStyles(styles)(AddQuestionBox));
