import React from "react";
import withStyles from '@mui/styles/withStyles';

import Colors from "constants/Colors";
import { useSelector } from "react-redux";
import ConnectedLivePreview from "scenes/CreateTrack/CreateActionTextStep/PreviewSide/ConnectedLivePreview";
import StartLivePreview from "scenes/CreateTrack/CreateActionTextStep/PreviewSide/StartLivePreview";
import { getIsMyMobileConnected } from "services/general/selectors";

/*const APPLE_STORE_LINK =
  "https://apps.apple.com/us/app/microdoing/id1479333825?l=fr&ls=1";
const GOOGLE_PLAY_LINK =
  "https://play.google.com/store/apps/details?id=com.microdoing&gl=FR";
*/
const styles = theme => ({
  main: {
    marginTop: 30,
  },
  svgWrapperDiv: {
    position: "relative",
  },
  connectionAppDiv: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  connectionAppText: {
    wordBreak: "break-word",
    color: "black",
    width: 200,
    marginLeft: 25,
    marginRight: 25,
    fontSize: 14,
    textAlign: "center",
  },
  connectionAppButton: {
    marginBottom: 0,
    fontSize: 16,
    padding: "8px 35px",
  },
  picture: {
    backgroundColor: Colors.white,
    borderRadius: 4,
    width: "50%",
  },
  badges: {
    width: "90%",
    paddingLeft: 30,
    paddingRight: 30,
    marginBottom: 25,
    marginTop: 15,
    flexDirection: "row",
    display: "flex",
    alignItems: "space-between",
    justifyContent: "space-between",
  },
  badge: {
    width: "100%",
  },
  downloadIosBadge: {
    textDecoration: "none",
    marginRight: 10,
  },
  connectionStatus: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  connectionStatusText: {
    color: "#8A8A8A",
    marginTop: 5,
    fontSize: 14,
    marginLeft: 2,
  },
  connectedText: {
    fontSize: 16,
    color: "#75D67E",
    fontWeight: "bold",
  },
  bold: {
    fontWeight: "bold",
  },
  isPremiumDiv: {
    marginTop: 20,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  booleanText: {
    fontSize: "1em",
    flex: 1,
    maxWidth: "100%",
    marginRight: 25,
  },
  subtitleModal: {
    color: "red",
    textAlign: "center",
  },
});

const LivePreview = ({ classes, onClickConnectMobile, qrCodeParams = {} }) => {
  const isMyMobileConnected = useSelector(getIsMyMobileConnected);

  return (
    <div className={classes.main}>
      {isMyMobileConnected ? (
        <ConnectedLivePreview onClickConnectMobile={onClickConnectMobile} />
      ) : (
        <StartLivePreview qrCodeParams={qrCodeParams} />
      )}
    </div>
  );
};

export default withStyles(styles)(React.memo(LivePreview));
