import React from "react";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import i18n from "i18n";
import Colors from "constants/Colors";

const useStyles = makeStyles(theme => ({
  main: {},
  row: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  firstRow: {
    flex: 1.5,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  tableHeaderRow: {
    paddingRight: 25,
    marginRight: 25,
    fontWeight: "bold",
    fontFamily: "Montserrat",
    color: "#282828",
    fontSize: 16,
    width: "100%",
    // backgroundColor: "#fff",
    // marginBottom: 30,
    marginTop: 30,
    // borderRadius: 8,
    display: "flex",
    alignItems: "space-between",
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: 15,
    borderBottom: `solid 1px #DEDEDE`,
  },
  emptyRow: {
    flex: 0.5,
  },
  tableHeader: {
    fontSize: 18,
    fontWeight: 500,
    marginRight: 3,
    color: Colors.darkBlue,
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": {
      textDecoration: "underline",
    },
    // color: "#fff"
  },
}));

interface Props {
  sortedBy: string;
  isSortedDesc: boolean;
  changeSortingParam: (string) => () => void;
}

const TableHeader = ({ sortedBy, isSortedDesc, changeSortingParam }: Props) => {
  const classes = useStyles();

  const displayArrow = param => {
    return sortedBy === param ? (
      isSortedDesc ? (
        <ExpandLessIcon />
      ) : (
        <ExpandMoreIcon />
      )
    ) : null;
  };

  return (
    <div className={classes.tableHeaderRow}>
      <div
        className={classes.firstRow}
        onClick={changeSortingParam("fileName")}
      >
        <Typography className={classes.tableHeader}>
          {i18n.t("table-header-fileName")}
        </Typography>
        {displayArrow("fileName")}
      </div>
      <div className={classes.row} onClick={changeSortingParam("fileType")}>
        <Typography className={classes.tableHeader}>
          {i18n.t("table-header-file-type")}
        </Typography>
        {displayArrow("fileType")}
      </div>
      <div className={classes.row} onClick={changeSortingParam("createdAt")}>
        <Typography className={classes.tableHeader}>
          {i18n.t("table-header-file-created-at")}
        </Typography>
        {displayArrow("createdAt")}
      </div>
      <div className={classes.emptyRow} />
    </div>
  );
};

export default TableHeader;
