import React from "react";
import withStyles from '@mui/styles/withStyles';
import { Typography } from "@mui/material";
import Switch from "react-switch";

const styles = theme => ({
  main: {},
  autoInviteDiv: {
    paddingRight: 75,
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "row",
  },
  autoInvite: {
    marginTop: 5,
    flex: 1,
  },
  subTitle: {
    fontFamily: "Montserrat",
    // color: Colors.black,
    marginTop: 20,
    marginBottom: 5,
    fontSize: 18,
    lineHeight: 1.2143,
    fontWeight: 700,
  },
  explanation: {
    marginRight: 55,
    color: "#6E757E",
  },
});

const BooleanField = ({ classes, title, explanation, onChange, checked }) => {
  return (
    <div className={classes.main}>
      <Typography className={classes.subTitle}>{title}</Typography>
      <div className={classes.autoInviteDiv}>
        <Typography className={`${classes.explanation} ${classes.autoInvite}`}>
          {explanation}
        </Typography>
        <Switch onChange={onChange} checked={checked} onColor={"#00A7FC"} />
      </div>
    </div>
  );
};

export default withStyles(styles)(BooleanField);
