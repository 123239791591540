import React, { useMemo } from "react";
import { makeStyles } from "@mui/styles";
import CustomTable from "components/Table/CustomTable";
import { useSelector } from "react-redux";
import { getPendingInvitations } from "services/client/selectors";

const useStyles = makeStyles(theme => ({
  main: {
    width: "90%",
  },
}));

const headerTable = [
  {
    id: "email",
    label: "User email",
    disablePadding: false,
  },
  {
    id: "role",
    label: "role",
    disablePadding: false,
  },
];

interface Props {}

const MyUsersPending = ({}: Props) => {
  const pendingInvitations = useSelector(getPendingInvitations);

  const classes = useStyles();

  const formattedEvent = useMemo(() => {
    return pendingInvitations.map(invitation => {
      return {
        id: invitation._id,
        email: invitation.email,
        role: invitation.role,
      };
    });
  }, [pendingInvitations]);

  return (
    <div className={classes.main}>
      <CustomTable
        headerTableCells={headerTable}
        bodyTableCells={formattedEvent}
      />
    </div>
  );
};

export default MyUsersPending;
