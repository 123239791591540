import { createSelector } from "reselect";
import moment from "moment";

export const getMyCoachees = state => state.myCoachees.myCoachees || [];

export const getActiveCoachees = createSelector(getMyCoachees, myCoachees => {
  return myCoachees.filter(el => !el.isFinished);
});

export const getFinishedCoachees = createSelector(getMyCoachees, myCoachees => {
  return myCoachees
    .filter(el => el.isFinished)
    .sort((a, b) => moment(b.finishedDate) - moment(a.finishedDate));
});

export const getTotalDoneSessions = createSelector(
  getMyCoachees,
  myCoachees => {
    return myCoachees.reduce((prev, curr) => {
      const doneSession = curr.sessions.reduce((acc, current) => {
        return acc + current.isDone;
      }, 0);
      return prev + doneSession;
    }, 0);
  },
);
