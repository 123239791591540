import React from "react";
import withStyles from '@mui/styles/withStyles';
import Colors from "constants/Colors";
import { Typography } from "@mui/material";
import i18n from "i18n";
import { Loader } from "components";

const styles = theme => ({
  main: {
    boxSizing: "border-box",
    position: "relative",
    paddingLeft: 45,
    paddingRight: 45,
    height: 90,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    borderBottom: `solid 1px ${Colors.lightGrey}`,
    backgroundColor: "#fff",
  },
  title: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "29px",
    color: Colors.darkBlue,
    marginRight: 30,
  },
  link: {
    position: "absolute",
    bottom: 10,
    left: 45,
    color: Colors.pureMain,
    borderRadius: "4px",
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": { textDecoration: "underline" },
  },
});

const TopBar = ({
  classes,
  title,
  hasLink = false,
  onClickLink = null,
  requesting = false,
}) => {
  return (
    <div className={classes.main}>
      <Typography className={classes.title}>{title}</Typography>
      {hasLink && !requesting && (
        <Typography className={classes.link} onClick={onClickLink}>
          {i18n.t("send-evolution-survey")}
        </Typography>
      )}
      {hasLink && requesting && <Loader size={20} />}
    </div>
  );
};

export default withStyles(styles)(TopBar);
