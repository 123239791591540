import React from "react";

const RefreshIcon = ({
  width = 24,
  height = 24,
  color = "#006cfa",
  className = "",
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M20.2535 10.4306C20.2834 10.3789 20.3046 10.3226 20.316 10.2642L20.9856 6.76057C21.0283 6.52826 20.9751 6.28886 20.8378 6.09503C20.7005 5.9012 20.4903 5.76882 20.2535 5.727C20.0166 5.68519 19.7726 5.73737 19.575 5.87206C19.3774 6.00676 19.2424 6.21294 19.1998 6.44524L18.8694 8.19705C18.1262 6.8656 17.0154 5.7666 15.6645 5.02609C14.3137 4.28558 12.7774 3.93354 11.2319 4.01035C9.68636 4.08716 8.19421 4.5897 6.92655 5.46035C5.65889 6.331 4.66706 7.53449 4.06479 8.93281C3.97644 9.14773 3.97852 9.38823 4.07057 9.60165C4.16262 9.81507 4.33713 9.98402 4.5559 10.0715C4.66488 10.1157 4.78173 10.1384 4.89969 10.1381C5.01765 10.1378 5.13438 10.1146 5.24314 10.0698C5.3519 10.025 5.45054 9.95953 5.53335 9.87713C5.61616 9.79473 5.68151 9.69705 5.72562 9.58974C6.20649 8.43074 7.03354 7.44156 8.09809 6.75216C9.16265 6.06275 10.4149 5.70538 11.6903 5.727C12.8184 5.72184 13.9277 6.00913 14.9062 6.55978C15.8846 7.11043 16.6974 7.90489 17.2622 8.86274L15.7888 8.62624C15.6716 8.60784 15.5518 8.61227 15.4363 8.63928C15.3207 8.6663 15.2118 8.71537 15.1156 8.78369C15.0194 8.85201 14.9379 8.93825 14.8757 9.03748C14.8135 9.1367 14.7718 9.24698 14.7531 9.362C14.7343 9.47703 14.7388 9.59455 14.7664 9.70786C14.7939 9.82118 14.8439 9.92806 14.9136 10.0224C14.9832 10.1168 15.0711 10.1967 15.1723 10.2577C15.2734 10.3188 15.3858 10.3596 15.5031 10.378L19.298 10.9999H19.4409C19.5445 11.0012 19.6475 10.9833 19.7445 10.9474C19.7772 10.9352 19.8074 10.9174 19.8338 10.8948C19.8978 10.8715 19.958 10.839 20.0124 10.7985L20.0838 10.7109C20.1301 10.6684 20.172 10.6214 20.2088 10.5707C20.2305 10.5264 20.2456 10.4792 20.2535 10.4306V10.4306Z"
      fill={color}
    />
    <path
      d="M19.4341 13.9505C19.3245 13.9065 19.207 13.8843 19.0885 13.8851C18.9701 13.886 18.8529 13.91 18.744 13.9556C18.635 14.0013 18.5364 14.0677 18.4539 14.151C18.3714 14.2344 18.3066 14.3329 18.2633 14.441C17.7786 15.588 16.954 16.5659 15.8959 17.2484C14.8379 17.9308 13.5953 18.2864 12.3289 18.269C11.1998 18.2742 10.0894 17.9869 9.11007 17.4362C8.13073 16.8855 7.31723 16.091 6.75192 15.1331L8.22659 15.3696H8.36959C8.60663 15.3882 8.84149 15.3137 9.02251 15.1626C9.20352 15.0114 9.31587 14.796 9.33483 14.5637C9.3538 14.3314 9.27782 14.1012 9.12362 13.9238C8.96942 13.7463 8.74963 13.6362 8.51259 13.6176L4.71418 13.0132C4.61669 12.9956 4.51674 12.9956 4.41925 13.0132H4.34775C4.2466 13.0396 4.15016 13.0809 4.06175 13.1358C4.00149 13.1815 3.94743 13.2345 3.90088 13.2935L3.82044 13.3811C3.79282 13.4339 3.77181 13.4898 3.75788 13.5476C3.72656 13.5923 3.70524 13.6431 3.69532 13.6965L3.02501 17.2004C2.9963 17.3168 2.99226 17.4377 3.01313 17.5557C3.034 17.6738 3.07935 17.7863 3.14636 17.8865C3.21337 17.9866 3.30062 18.0723 3.40273 18.1381C3.50483 18.2039 3.61962 18.2484 3.74 18.269H3.90088C4.11251 18.2724 4.31849 18.2021 4.48216 18.0706C4.64582 17.939 4.75655 17.7548 4.79462 17.5507L5.1253 15.7988C5.86847 17.1307 6.97956 18.2303 8.33117 18.9716C9.68278 19.7129 11.2202 20.0658 12.7671 19.9899C14.314 19.914 15.8077 19.4123 17.077 18.5425C18.3463 17.6726 19.3398 16.4697 19.9436 15.0717C20.0255 14.8567 20.018 14.6187 19.9227 14.4091C19.8274 14.1994 19.652 14.0347 19.4341 13.9505Z"
      fill={color}
    />
  </svg>
);

export default RefreshIcon;
