//@flow
import {
  CREATE_RESOURCE_REQUESTING,
  GET_RESOURCE_REQUESTING,
  UPDATE_RESOURCE_REQUESTING,
  CLEAR_RESOURCE,
  GET_RESOURCE_LIST_REQUESTING,
  GET_LIBRARY_RESOURCES_REQUESTING,
  GET_CAMPAIGN_RESOURCES_REQUESTING,
  DELETE_RESOURCE_REQUESTING,
  ADD_RESOURCE,
  ADD_CAMPAIGN_RESOURCE,
  REMOVE_CAMPAIGN_RESOURCE,
  UPDATE_CAMPAIGN_RESOURCES_REQUESTING,
} from "./constants";

export const getResourceRequest = (payload: {
  coachId: string;
  userId: string;
}) => {
  return {
    type: GET_RESOURCE_REQUESTING,
    payload,
  };
};

export const updateResourceRequest = (payload: {}) => {
  return {
    type: UPDATE_RESOURCE_REQUESTING,
    payload,
  };
};

export const createResourceRequest = (payload: {}) => {
  return {
    type: CREATE_RESOURCE_REQUESTING,
    payload,
  };
};

export const deleteResourceRequest = (payload: {}) => {
  return {
    type: DELETE_RESOURCE_REQUESTING,
    payload,
  };
};

export const clearResource = payload => {
  return {
    type: CLEAR_RESOURCE,
    payload,
  };
};

export const getResourcesListRequest = (payload: {}) => {
  return {
    type: GET_RESOURCE_LIST_REQUESTING,
    payload,
  };
};

export const getLibraryResourcesRequest = payload => {
  return {
    type: GET_LIBRARY_RESOURCES_REQUESTING,
    payload,
  };
};

export const addResource = (payload: {}) => {
  return {
    type: ADD_RESOURCE,
    payload,
  };
};

export const addCampaignResource = (payload: {}) => {
  return {
    type: ADD_CAMPAIGN_RESOURCE,
    payload,
  };
};

export const removeCampaignResource = (payload: { resourceId: string }) => {
  return {
    type: REMOVE_CAMPAIGN_RESOURCE,
    payload,
  };
};

export const updateCampaignResources = (payload: {
  resources: any[];
  campaignId: string;
}) => {
  return {
    type: UPDATE_CAMPAIGN_RESOURCES_REQUESTING,
    payload,
  };
};

interface ICampaignResource {
  resourceId: string;
  addedDate: Date;
}

export const getCampaignResourcesRequest = (payload: {
  campaignId: string;
  resources: ICampaignResource[];
}) => {
  return {
    type: GET_CAMPAIGN_RESOURCES_REQUESTING,
    payload,
  };
};
