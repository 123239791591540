import {
  GET_RESOURCE_REQUESTING,
  GET_RESOURCE_SUCCESS,
  GET_RESOURCE_ERROR,
  UPDATE_RESOURCE_REQUESTING,
  UPDATE_RESOURCE_SUCCESS,
  UPDATE_RESOURCE_ERROR,
  CREATE_RESOURCE_REQUESTING,
  CREATE_RESOURCE_SUCCESS,
  CREATE_RESOURCE_ERROR,
  CLEAR_RESOURCE,
  GET_RESOURCE_LIST_ERROR,
  GET_RESOURCE_LIST_SUCCESS,
  GET_RESOURCE_LIST_REQUESTING,
  GET_LIBRARY_RESOURCES_REQUESTING,
  GET_LIBRARY_RESOURCES_SUCCESS,
  GET_LIBRARY_RESOURCES_ERROR,
  DELETE_RESOURCE_REQUESTING,
  ADD_CAMPAIGN_RESOURCE,
  REMOVE_CAMPAIGN_RESOURCE,
  ADD_RESOURCE,
  UPDATE_CAMPAIGN_RESOURCES_REQUESTING,
  UPDATE_CAMPAIGN_RESOURCES_SUCCESS,
  UPDATE_CAMPAIGN_RESOURCES_ERROR,
  GET_CAMPAIGN_RESOURCES_REQUESTING,
  GET_CAMPAIGN_RESOURCES_SUCCESS,
  GET_CAMPAIGN_RESOURCES_ERROR,
  ADD_LIBRARY_RESOURCE,
} from "./constants";

import {
  CAMPAIGN_RESOURCE_VISIT_RECEIVED,
  CAMPAIGN_RESOURCE_FEEDBACK_RECEIVED,
} from "services/campaign/constants";

import { AUTH_LOGOUT_SUCCESS } from "../auth/reducer";
import { GET_COACHING_SESSION_REQUESTING } from "services/coaching/constants";

const initialState = {
  resource: {},
  requesting: false,
  coachResources: [],
  campaignResources: [],
  libraryResources: [],
};

const reducer = function userReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIBRARY_RESOURCES_REQUESTING:
    case GET_RESOURCE_LIST_REQUESTING:
    case CREATE_RESOURCE_REQUESTING:
    case UPDATE_RESOURCE_REQUESTING:
    case GET_RESOURCE_REQUESTING: {
      return {
        ...state,
        requesting: true,
      };
    }

    case CREATE_RESOURCE_SUCCESS:
      return {
        ...state,
        requesting: false,
        coachResources: [action.payload, ...state.coachResources],
      };

    case GET_LIBRARY_RESOURCES_SUCCESS:
      return {
        ...state,
        requesting: false,
        libraryResources: action.payload,
      };

    case ADD_LIBRARY_RESOURCE:
      return {
        ...state,
        libraryResources: [action.payload, ...state.libraryResources],
      };

    case GET_RESOURCE_LIST_SUCCESS: {
      return {
        ...state,
        requesting: false,
        coachResources: action.payload,
        campaignResources: action.payload,
      };
    }

    case GET_RESOURCE_SUCCESS: {
      return {
        ...state,
        requesting: false,
        resource: action.payload,
      };
    }

    case UPDATE_RESOURCE_SUCCESS: {
      const coachResources = state.coachResources.map(el => {
        if (el._id === action.payload._id) {
          return {
            ...el,
            ...action.payload,
          };
        }
        return el;
      });

      const libraryResources = state.libraryResources.map(el => {
        if (el._id === action.payload._id) {
          return { ...el, ...action.payload };
        }
        return el;
      });

      const campaignResources = state.campaignResources.map(el => {
        if (el.resourceId === action.payload._id) {
          return { ...el, ...action.payload };
        }
        return el;
      });

      return {
        ...state,
        requesting: false,
        coachResources,
        libraryResources,
        campaignResources,
      };
    }

    case DELETE_RESOURCE_REQUESTING: {
      const libraryResources = state.libraryResources.filter(
        el => el._id !== action.payload._id,
      );
      return {
        ...state,
        libraryResources,
      };
    }

    case GET_CAMPAIGN_RESOURCES_ERROR:
    case GET_LIBRARY_RESOURCES_ERROR:
    case GET_RESOURCE_LIST_ERROR:
    case CREATE_RESOURCE_ERROR:
    case UPDATE_RESOURCE_ERROR:
    case GET_RESOURCE_ERROR: {
      return {
        ...state,
        requesting: false,
        error: action.payload,
      };
    }

    case ADD_RESOURCE: {
      return {
        ...state,
        coachResources: [action.payload, ...state.coachResources],
        campaignResources: [action.payload, ...state.campaignResources],
      };
    }

    case ADD_CAMPAIGN_RESOURCE: {
      console.log("payload");
      const newCampaignResource = {
        ...action.payload,
        totalViews: 0,
        numberOfComments: 0,
        rating: 0,
        resourceId: action.payload._id,
      };
      delete newCampaignResource["_id"];
      delete newCampaignResource["created_at"];
      delete newCampaignResource["updatedAt"];
      delete newCampaignResource["__v"];

      return {
        ...state,
        campaignResources: [
          {
            ...action.payload,
            totalViews: 0,
            numberOfComments: 0,
            rating: 0,
            resourceId: action.payload._id,
          },
          ...state.campaignResources,
        ],
      };
    }

    case REMOVE_CAMPAIGN_RESOURCE: {
      const campaignResources = state.campaignResources.filter(
        el => el.resourceId !== action.payload.resourceId,
      );
      return {
        ...state,
        campaignResources,
      };
    }

    case UPDATE_CAMPAIGN_RESOURCES_REQUESTING: {
      return {
        ...state,
        campaignResources: action.payload.resources,
      };
    }

    case GET_CAMPAIGN_RESOURCES_REQUESTING: {
      return {
        ...state,
        requesting: true,
      };
    }

    case GET_CAMPAIGN_RESOURCES_SUCCESS: {
      return {
        ...state,
        campaignResources: action.payload,
        requesting: false,
      };
    }

    case CAMPAIGN_RESOURCE_FEEDBACK_RECEIVED: {
      const { resource } = action.payload;
      const { resourceId, comment, rating } = resource;

      const campaignResources = state.campaignResources.map(el => {
        if (el.resourceId === resourceId) {
          return {
            ...el,
            rating:
              (el.rating * el.totalFeedbacks + rating) /
              (el.totalFeedbacks + 1),
            totalFeedbacks: el.totalFeedbacks + 1,
            totalComments: el.totalComments + !!resource.comment,
            comments: !!comment ? [...el.comments, comment] : el.comments,
          };
        }
        return el;
      });
      return {
        ...state,
        campaignResources,
      };
    }

    case CAMPAIGN_RESOURCE_VISIT_RECEIVED: {
      const { resourceId } = action.payload;

      const campaignResources = state.campaignResources.map(el => {
        if (el.resourceId === resourceId) {
          return {
            ...el,
            totalViews: el.totalViews + 1,
          };
        }
        return el;
      });
      return {
        ...state,
        campaignResources,
      };
    }

    case UPDATE_CAMPAIGN_RESOURCES_SUCCESS: {
      return {
        ...state,
      };
    }

    case UPDATE_CAMPAIGN_RESOURCES_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case GET_COACHING_SESSION_REQUESTING:
    case CLEAR_RESOURCE:
    case AUTH_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
