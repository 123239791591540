import moment from "moment-timezone";
import i18n from "../i18n";

interface Props {
  nextDate: Date;
  timezone: string;
}

const displayNextActiveDay = ({ nextDate, timezone }: Props) => {
  // const today = moment.tz(timezone).endOf("day");
  const today = moment.tz(timezone);
  const diffWithStart = moment(nextDate).endOf("day").diff(today, "days");

  if (diffWithStart === 0) {
    return i18n.t("today");
  }
  if (diffWithStart >= 1) {
    if (diffWithStart === 1) {
      return i18n.t("start-tomorrow");
    }
    return i18n.t("start-later", { days: diffWithStart });
  }
  return i18n.t("finished");
};

export default displayNextActiveDay;
