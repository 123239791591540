import {
  CAMPAIGN_GET_ALL_REQUESTING,
  CAMPAIGN_GET_ALL_SUCCESS,
  CAMPAIGN_GET_ALL_ERROR,
  CAMPAIGN_GET_REQUESTING,
  CAMPAIGN_CREATE_REQUESTING,
  CAMPAIGN_CREATE_SUCCESS,
  CAMPAIGN_CREATE_ERROR,
  TRACK_GET_ALL_REQUESTING,
  TRACK_GET_ALL_ERROR,
  TRACK_GET_ALL_SUCCESS,
  CAMPAIGN_SELECT,
  CAMPAIGN_GET_ALL_DONE_STEPS_ERROR,
  CAMPAIGN_GET_ALL_DONE_STEPS_REQUESTING,
  CAMPAIGN_GET_ALL_DONE_STEPS_SUCCESS,
  CAMPAIGN_GET_TEAM_RANKING_REQUESTING,
  CAMPAIGN_GET_TEAM_RANKING_SUCCESS,
  CAMPAIGN_GET_TEAM_RANKING_ERROR,
  CAMPAIGN_GET_USER_RANKING_SUCCESS,
  CAMPAIGN_GET_USER_RANKING_REQUESTING,
  CAMPAIGN_GET_USER_RANKING_ERROR,
  CAMPAIGN_CLOSE_SNACK_MESSAGE,
  CAMPAIGN_SELECT_TAB,
  CAMPAIGN_GET_ALL_TEMPLATES_REQUESTING,
  CAMPAIGN_GET_ALL_TEMPLATES_ERROR,
  CAMPAIGN_GET_ALL_TEMPLATES_SUCCESS,
  CHANGE_ADD_USERS_MODAL,
  DONE_STEP_RECEIVED,
  CAMPAIGN_RECEIVED,
  USER_CAMPAIGN_RECEIVED,
  CAMPAIGN_SELECT_PREVIEW_TAB,
  USER_CAMPAIGNS_GET_LIST_ERROR,
  USER_CAMPAIGNS_GET_LIST_SUCCESS,
  USER_CAMPAIGNS_GET_LIST_REQUESTING,
  USER_CAMPAIGNS_LOAD_MORE_ERROR,
  USER_CAMPAIGNS_LOAD_MORE_SUCCESS,
  USER_CAMPAIGNS_LOAD_MORE_REQUESTING,
  CAMPAIGN_MODIFY_REQUESTING,
  CAMPAIGN_MODIFY_SUCCESS,
  CAMPAIGN_MODIFY_ERROR,
  CAMPAIGN_ACTIVATE_EMAIL_REMINDER_REQUESTING,
  CAMPAIGN_ACTIVATE_EMAIL_REMINDER_SUCCESS,
  CAMPAIGN_ACTIVATE_EMAIL_REMINDER_ERROR,
  GET_ALL_MY_CLIENTS_TRACKS_ERROR,
  GET_ALL_MY_CLIENTS_TRACKS_REQUESTING,
  GET_ALL_MY_CLIENTS_TRACKS_SUCCESS,
  CAMPAIGN_DELETE_REQUESTING,
  CAMPAIGN_DELETE_SUCCESS,
  CAMPAIGN_DELETE_ERROR,
  CAMPAIGN_RESET_TEAMS_REQUESTING,
  CAMPAIGN_RESET_TEAMS_SUCCESS,
  CAMPAIGN_RESET_TEAMS_ERROR,
  TRACK_DELETE_REQUESTING,
  TRACK_DELETE_SUCCESS,
  TRACK_DELETE_ERROR,
  USER_CAMPAIGN_DELETE_REQUESTING,
  USER_CAMPAIGN_DELETE_SUCCESS,
  USER_CAMPAIGN_DELETE_ERROR,
  CAMPAIGN_ADD_PARTICIPANTS_REQUESTING,
  CAMPAIGN_ADD_PARTICIPANTS_SUCCESS,
  CAMPAIGN_ADD_PARTICIPANTS_ERROR,
  CAMPAIGN_GET_ALL_DRAFTS_ERROR,
  CAMPAIGN_GET_ALL_DRAFTS_REQUESTING,
  CAMPAIGN_GET_ALL_DRAFTS_SUCCESS,
  CAMPAIGN_ADD_TO_TEMPLATE_REQUESTING,
  CAMPAIGN_ADD_TO_TEMPLATE_SUCCESS,
  CAMPAIGN_ADD_TO_TEMPLATE_ERROR,
  CAMPAIGN_GET_ALL_PUBLIC_TEMPLATES_REQUESTING,
  CAMPAIGN_GET_ALL_PUBLIC_TEMPLATES_SUCCESS,
  CAMPAIGN_GET_ALL__PUBLIC_TEMPLATES_ERROR,
  DRAFT_REMOVE_REQUESTING,
  DRAFT_REMOVE_SUCCESS,
  DRAFT_REMOVE_ERROR,
  CAMPAIGN_GET_COMMENTS_ERROR,
  CAMPAIGN_GET_COMMENTS_REQUESTING,
  CAMPAIGN_GET_COMMENTS_SUCCESS,
  CAMPAIGN_GET_USERS_INFOS_REQUESTING,
  CAMPAIGN_GET_USERS_INFOS_SUCCESS,
  CAMPAIGN_GET_USERS_INFOS_ERROR,
  SELECT_CAMPAIGN_REQUESTING,
  SELECT_CAMPAIGN_SUCCESS,
  SELECT_CAMPAIGN_ERROR,
  CAMPAIGN_RESOURCE_VISIT_RECEIVED,
  CAMPAIGN_RESOURCE_FEEDBACK_RECEIVED,
  COMMENT_REVIEW_SUCCESS,
  UPDATE_STEP_FEEDBACK_FILE,
  UPDATE_STEP_FEEDBACK,
  CAMPAIGN_SELECTED_DONE_STEP,
  CHANGE_CAMPAIGN_DONE_STEP_SORTED,
  CHANGE_CAMPAIGN_DONE_STEP_SORTED_DESC,
  CAMPAIGN_VALIDATE_REVIEW_SUCCESS,
  CAMPAIGN_VALIDATE_REVIEW_ERROR,
  CAMPAIGN_VALIDATE_REVIEW_REQUESTING,
  COMMENT_REVIEW_ERROR,
  COMMENT_REVIEW_REQUESTING,
  CAMPAIGN_SEND_EMAIL_RECAP_REQUESTING,
  CAMPAIGN_SEND_EMAIL_RECAP_SUCCESS,
  CAMPAIGN_SEND_EMAIL_RECAP_ERROR,
  CAMPAIGN_CREATE_EVENT_SUCCESS,
  CAMPAIGN_CREATE_EVENT_ERROR,
  CAMPAIGN_DELETE_EVENT_SUCCESS,
  CAMPAIGN_UPDATE_EVENT_SUCCESS,
  CAMPAIGN_UPDATE_EVENT_ERROR,
  CAMPAIGN_DELETE_EVENT_ERROR,
  CAMPAIGN_REGENERATE_CERTIFICATE_SUCCESS,
  CAMPAIGN_GET_STEPS_SUCCESS,
  GET_STEP_COMMENT_SUCCESS,
  HIDE_STEP_COMMENT_REQUESTING,
  HIDE_STEP_COMMENT_SUCCESS,
  HIDE_STEP_COMMENT_ERROR,
  GET_STEP_COMMENT_REQUESTING,
  CAMPAIGN_GET_INTERACTIONS_SUCCESS,
  CAMPAIGN_GET_INTERACTIONS_REQUESTING,
  CAMPAIGN_GET_INTERACTIONS_ERROR,
  GET_STEP_COMMENT_ERROR,
} from "./constants";

import { AUTH_LOGOUT_SUCCESS } from "../auth/reducer";
import i18n from "../../i18n";
import { UPDATE_CAMPAIGN_RESOURCES_SUCCESS } from "services/resource/constants";

const initialState = {
  tabSelected: 0,
  previewTabSelected: 0,
  error: null,
  isAddUsersModalVisible: false,
  hasPublishCampaignAnimation: false,
  isLoading: false,
  userCampaigns: [],
  campaigns: [],
  publicTemplates: [],
  templates: [],
  drafts: [],
  campaignSelected: {
    title: {},
    participants: [],
    resourcesStats: {},
    events: [],
  },
  resources: [],
  doneSteps: [],
  steps: [],
  doneStepSelected: "",
  doneStepsSorted: "createdAt",
  doneStepsSortedDesc: true,
  tracks: [],
  goals: [],
  surveys: [],
  usersInfos: [{ stats: {}, topicChoices: [], feelingEvolution: [] }],
  usersInfosRequesting: false,
  commentsRequesting: false,
  campaignRequesting: false,
  requesting: false,
  templateRequesting: false,
  isCreateTrackModalOpen: false,
  title: { fr: "", en: "" },
  picture: "",
  description: { fr: "", en: "" },
  models: [],
  teamRankingRequesting: false,
  userRankingRequesting: false,
  isEmailRecapRequesting: false,
  comments: [],
  userRanking: [],
  teamRanking: [],
  snackMessage: null,
  isReviewRequesting: false,
  stepCommentRequesting: false,
  stepSelectedComments: [],
  snackStatus: "normal", // status
  interactions: {
    interactionFromSharedAnalysis: 0,
    interactionLikeSharedAnalysis: 0,
    interactionsPrivateMessage: 0,
    interactionGroupeMessage: 0,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_CAMPAIGN_REQUESTING:
    case CAMPAIGN_ADD_PARTICIPANTS_REQUESTING:
    case USER_CAMPAIGN_DELETE_REQUESTING:
    case CAMPAIGN_DELETE_REQUESTING:
    case CAMPAIGN_GET_ALL_DRAFTS_REQUESTING:
    case GET_ALL_MY_CLIENTS_TRACKS_REQUESTING:
    case TRACK_GET_ALL_REQUESTING:
    case CAMPAIGN_CREATE_REQUESTING:
    case CAMPAIGN_GET_REQUESTING:
    case CAMPAIGN_GET_ALL_REQUESTING:
    case CAMPAIGN_GET_INTERACTIONS_REQUESTING:
      return {
        ...state,
        requesting: true,
      };

    case CHANGE_CAMPAIGN_DONE_STEP_SORTED:
      return {
        ...state,
        doneStepsSorted: action.payload,
      };

    case CHANGE_CAMPAIGN_DONE_STEP_SORTED_DESC:
      return {
        ...state,
        doneStepsSortedDesc: action.payload,
      };

    case TRACK_DELETE_REQUESTING:
      return {
        ...state,
        requesting: true,
      };

    case TRACK_DELETE_SUCCESS: {
      const { trackId } = action.payload;
      const tracks = state.tracks.filter(el => el._id !== trackId);
      return {
        ...state,
        tracks,
        requesting: false,
      };
    }

    case COMMENT_REVIEW_REQUESTING:
    case CAMPAIGN_VALIDATE_REVIEW_REQUESTING: {
      return {
        ...state,
        isReviewRequesting: true,
      };
    }

    case SELECT_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaignSelected: action.payload,
        requesting: false,
      };

    case CAMPAIGN_GET_INTERACTIONS_SUCCESS:
      return {
        ...state,
        campaignSelected: {
          ...state.campaignSelected,
          interactions: action.payload,
        },
        requesting: false,
      };

    case CAMPAIGN_SELECTED_DONE_STEP:
      return {
        ...state,
        doneStepSelected: action.payload,
      };

    case CAMPAIGN_GET_ALL_PUBLIC_TEMPLATES_REQUESTING:
    case CAMPAIGN_GET_ALL_TEMPLATES_REQUESTING:
      return {
        ...state,
        templateRequesting: true,
      };

    case CAMPAIGN_GET_ALL_PUBLIC_TEMPLATES_SUCCESS:
      return {
        ...state,
        publicTemplates: action.payload,
        templateRequesting: false,
      };

    case CAMPAIGN_GET_ALL_DRAFTS_SUCCESS:
      return {
        ...state,
        requesting: false,
        drafts: action.payload,
      };

    case GET_ALL_MY_CLIENTS_TRACKS_SUCCESS:
    case TRACK_GET_ALL_SUCCESS:
      return {
        ...state,
        requesting: false,
        tracks: action.payload,
      };

    case CAMPAIGN_GET_ALL_SUCCESS:
      return {
        ...state,
        requesting: false,
        campaigns: action.payload,
      };

    case CAMPAIGN_SELECT:
      return {
        ...state,
        campaignSelected: action.payload,
      };

    case CAMPAIGN_GET_ALL__PUBLIC_TEMPLATES_ERROR:
    case CAMPAIGN_GET_ALL_TEMPLATES_ERROR:
      return {
        ...state,
        error: action.payload,
        templateRequesting: false,
      };

    case SELECT_CAMPAIGN_ERROR:
    case DRAFT_REMOVE_ERROR:
    case CAMPAIGN_GET_ALL_DRAFTS_ERROR:
    case CAMPAIGN_ADD_PARTICIPANTS_ERROR:
    case USER_CAMPAIGN_DELETE_ERROR:
    case GET_ALL_MY_CLIENTS_TRACKS_ERROR:
    case TRACK_DELETE_ERROR:
    case CAMPAIGN_DELETE_ERROR:
    case TRACK_GET_ALL_ERROR:
    case CAMPAIGN_CREATE_ERROR:
    case CAMPAIGN_GET_ALL_ERROR:
    case CAMPAIGN_GET_INTERACTIONS_ERROR:
      return {
        ...state,
        requesting: false,
        error: action.payload,
      };

    case COMMENT_REVIEW_ERROR:
    case CAMPAIGN_VALIDATE_REVIEW_ERROR:
      return {
        ...state,
        isReviewRequesting: false,
      };

    case CAMPAIGN_CREATE_SUCCESS:
      return {
        ...state,
        requesting: false,
        snackMessage: i18n.t("campaign-successfully-created"),
        tabSelected: 0,
      };

    case CAMPAIGN_DELETE_SUCCESS: {
      const { campaignId } = action.payload;
      let campaignSelected = state.campaignSelected;
      if (campaignSelected._id === campaignId) {
        campaignSelected = initialState.campaignSelected;
      }
      const campaigns = state.campaigns.filter(el => el._id !== campaignId);
      return {
        ...state,
        requesting: false,
        campaigns,
        campaignSelected,
      };
    }

    case USER_CAMPAIGN_DELETE_SUCCESS: {
      const { userCampaignId, campaign } = action.payload;

      const userCampaigns = state.userCampaigns.filter(
        el => el._id !== userCampaignId,
      );
      const campaignSelected = { ...state.campaignSelected, ...campaign };
      const campaigns = state.campaigns.map(el => {
        if (el._id === campaign._id) {
          return campaign;
        }
        return el;
      });

      return {
        ...state,
        requesting: false,
        userCampaigns,
        campaignSelected,
        campaigns,
      };
    }

    case CAMPAIGN_ADD_PARTICIPANTS_SUCCESS: {
      return {
        ...state,
        requesting: false,
      };
    }

    case UPDATE_STEP_FEEDBACK_FILE: {
      const doneSteps = state.doneSteps.map(el => {
        if (
          el._id === action.payload?.data?.stepFeedbackId ||
          el.userId === action.payload?.data?.userId
        ) {
          const files =
            el?.files?.map(file => {
              if (file._id === action.payload._id) {
                return { ...file, ...action.payload };
              }
              return file;
            }) || [];

          return {
            ...el,
            files,
          };
        }
        return el;
      });

      return {
        ...state,
        doneSteps,
      };
    }

    case UPDATE_STEP_FEEDBACK: {
      const doneSteps = state.doneSteps.map(el => {
        if (el._id === action.payload._id) {
          return {
            ...el,
            ...action.payload,
            stepId: el.stepId,
          };
        }
        return el;
      });

      return {
        ...state,
        doneSteps,
      };
    }

    case COMMENT_REVIEW_SUCCESS: {
      const doneSteps = state.doneSteps.map(el => {
        if (el._id === action.payload._id) {
          return {
            ...el,
            ...action.payload,
            files: el.files,
            stepId: el.stepId,
          };
        }
        return el;
      });

      return {
        ...state,
        doneSteps,
        isReviewRequesting: false,
      };
    }

    case CAMPAIGN_VALIDATE_REVIEW_SUCCESS: {
      const { reviewId, isReviewValidated } = action.payload;

      const doneSteps = state.doneSteps.map(el => {
        if (el._id === reviewId) {
          return {
            ...el,
            ...action.payload,
            files: el.files,
            isReviewValidated,
          };
        }
        return el;
      });

      return {
        ...state,
        doneSteps,
        isReviewRequesting: false,
      };
    }

    case CAMPAIGN_GET_ALL_DONE_STEPS_SUCCESS:
      return {
        ...state,
        campaignRequesting: false,
        doneSteps: action.payload,
      };

    case USER_CAMPAIGNS_GET_LIST_SUCCESS:
      return {
        ...state,
        userCampaigns: action.payload,
        isLoading: false,
      };

    case USER_CAMPAIGNS_LOAD_MORE_SUCCESS:
      return {
        ...state,
        userCampaigns: [...state.userCampaigns, ...action.payload],
        isLoading: false,
      };

    case CAMPAIGN_GET_ALL_TEMPLATES_SUCCESS:
      return {
        ...state,
        templateRequesting: false,
        templates: action.payload,
      };

    case USER_CAMPAIGNS_GET_LIST_REQUESTING:
      return {
        ...state,
        userCampaigns: initialState.userCampaigns,
        isLoading: true,
      };

    case USER_CAMPAIGNS_LOAD_MORE_REQUESTING:
      return {
        ...state,
        isLoading: true,
      };

    case CAMPAIGN_RESET_TEAMS_REQUESTING:
    case CAMPAIGN_ACTIVATE_EMAIL_REMINDER_REQUESTING:
    case CAMPAIGN_MODIFY_REQUESTING:
    case CAMPAIGN_GET_ALL_DONE_STEPS_REQUESTING:
      return {
        ...state,
        campaignRequesting: true,
      };

    case USER_CAMPAIGNS_GET_LIST_ERROR:
    case USER_CAMPAIGNS_LOAD_MORE_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case CAMPAIGN_RESET_TEAMS_ERROR:
    case CAMPAIGN_ACTIVATE_EMAIL_REMINDER_ERROR:
    case CAMPAIGN_MODIFY_ERROR:
    case CAMPAIGN_GET_ALL_DONE_STEPS_ERROR:
      return {
        ...state,
        error: action.payload,
        campaignRequesting: false,
      };

    case CAMPAIGN_RESET_TEAMS_SUCCESS:
      return {
        ...state,
        campaignRequesting: false,
      };

    case CAMPAIGN_ACTIVATE_EMAIL_REMINDER_SUCCESS:
    case CAMPAIGN_MODIFY_SUCCESS: {
      let { campaignSelected = {}, campaigns } = state;
      if (action?.payload?._id === campaignSelected?._id) {
        campaignSelected = { ...campaignSelected, ...action.payload };
      }
      campaigns = campaigns.map(el => {
        if (el._id === action.payload._id) {
          return { ...el, ...action.payload };
        }
        return el;
      });
      return {
        ...state,
        campaigns,
        campaignSelected,
        campaignRequesting: false,
      };
    }

    case CAMPAIGN_RESOURCE_VISIT_RECEIVED: {
      const { resourcesStats } = state.campaignSelected || {};
      const campaignSelected = {
        ...state.campaignSelected,
        resourcesStats: {
          ...resourcesStats,
          totalViews: resourcesStats.totalViews + 1,
        },
      };

      return {
        ...state,
        campaignSelected,
      };
    }

    case CAMPAIGN_RESOURCE_FEEDBACK_RECEIVED: {
      const { resourcesStats } = state.campaignSelected || {};

      const campaignSelected = {
        ...state.campaignSelected,
        resourcesStats: {
          ...resourcesStats,
          ...action.payload.resourcesStats,
        },
      };

      return {
        ...state,
        campaignSelected,
      };
    }

    case CAMPAIGN_GET_USERS_INFOS_ERROR:
      return {
        ...state,
        usersInfosRequesting: false,
      };

    case CAMPAIGN_GET_USERS_INFOS_REQUESTING:
      return {
        ...state,
        usersInfosRequesting: true,
      };

    case CAMPAIGN_GET_USERS_INFOS_SUCCESS: {
      return {
        ...state,
        usersInfos: action.payload,
      };
    }

    case CAMPAIGN_GET_COMMENTS_REQUESTING:
      return {
        ...state,
        commentsRequesting: true,
      };

    case CAMPAIGN_GET_COMMENTS_SUCCESS:
      return {
        ...state,
        commentsRequesting: false,
        comments: action.payload,
      };

    case CAMPAIGN_GET_COMMENTS_ERROR:
      return {
        ...state,
        commentsRequesting: false,
        error: action.payload,
      };

    case CAMPAIGN_GET_TEAM_RANKING_REQUESTING:
      return {
        ...state,
        teamRankingRequesting: true,
      };

    case CAMPAIGN_GET_TEAM_RANKING_SUCCESS: {
      return {
        ...state,
        teamRankingRequesting: false,
        teamRanking: action.payload,
      };
    }

    case CAMPAIGN_GET_TEAM_RANKING_ERROR: {
      return {
        ...state,
        teamRankingRequesting: false,
        error: action.payload,
      };
    }

    case CAMPAIGN_GET_USER_RANKING_ERROR: {
      return {
        ...state,
        userRankingRequesting: false,
        error: action.payload,
      };
    }

    case CAMPAIGN_GET_USER_RANKING_SUCCESS: {
      return {
        ...state,
        userRankingRequesting: false,
        userRanking: action.payload,
      };
    }

    case HIDE_STEP_COMMENT_REQUESTING: {
      const comments = state.comments.filter(
        el => el?._id !== action.payload.stepCommentId,
      );

      const stepSelectedComments = state.stepSelectedComments.filter(
        el => el?._id !== action.payload.stepCommentId,
      );

      return {
        ...state,
        stepCommentRequesting: true,
        stepSelectedComments,
        comments,
      };
    }

    case HIDE_STEP_COMMENT_SUCCESS: {
      return {
        ...state,
        stepCommentRequesting: false,
      };
    }

    case HIDE_STEP_COMMENT_ERROR: {
      return {
        ...state,
        stepCommentRequesting: false,
      };
    }

    case UPDATE_CAMPAIGN_RESOURCES_SUCCESS:
    case CAMPAIGN_RECEIVED: {
      let campaignSelected = state.campaignSelected || {};
      if (campaignSelected?._id === action?.payload?._id) {
        campaignSelected = { ...campaignSelected, ...action.payload };
      }

      const campaigns =
        state.campaigns?.map(el => {
          if (el._id === action.payload._id) {
            return { ...el, ...action.payload };
          }
          return el;
        }) || [];

      return {
        ...state,
        campaigns,
        campaignSelected,
      };
    }

    case DONE_STEP_RECEIVED: {
      const campaigns = state.campaigns.map(el => {
        if (el._id === action?.payload?.campaignId) {
          return { ...el, totalDone: el.totalDone + 1 };
        }
        return el;
      });

      return {
        ...state,
        campaigns,
        doneSteps: [...state.doneSteps, action.payload],
      };
    }

    case CAMPAIGN_REGENERATE_CERTIFICATE_SUCCESS: {
      const userCampaigns =
        state.userCampaigns?.map(el => {
          if (el._id === action?.payload?._id) {
            return { ...el, certificateUrl: action.payload.certificateUrl };
          }
          return el;
        }) || [];

      return {
        ...state,
        userCampaigns,
      };
    }

    case USER_CAMPAIGN_RECEIVED: {
      const userCampaigns =
        state.userCampaigns?.map(el => {
          if (el._id === action?.payload?._id) {
            return { ...el, ...action.payload };
          }
          return el;
        }) || [];
      return {
        ...state,
        userCampaigns,
      };
    }

    case CAMPAIGN_ADD_TO_TEMPLATE_REQUESTING: {
      const { trackId, isTemplate, isPremiumDemo = false } = action.payload;

      const templates = state.templates.map(el => {
        if (el._id === trackId) {
          return { ...el, isTemplate, isPremiumDemo };
        }
        return el;
      });

      const campaigns = state.campaigns.map(el => {
        if (el.trackId === trackId) {
          return { ...el, track: { ...el.track, isTemplate, isPremiumDemo } };
        }
        return el;
      });

      return {
        ...state,
        campaigns,
        templates,
      };
    }

    case CAMPAIGN_DELETE_EVENT_ERROR:
    case CAMPAIGN_UPDATE_EVENT_ERROR:
    case CAMPAIGN_CREATE_EVENT_ERROR:
    case CAMPAIGN_ADD_TO_TEMPLATE_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case CAMPAIGN_GET_STEPS_SUCCESS: {
      return {
        ...state,
        steps: action.payload,
      };
    }

    case CAMPAIGN_ADD_TO_TEMPLATE_SUCCESS: {
      const { _id, isTemplate } = action.payload;

      const templates = state.templates.map(el => {
        if (el._id === _id) {
          return { ...el, isTemplate };
        }
        return el;
      });

      const campaigns = state.campaigns.map(el => {
        if (el.trackId === _id) {
          return { ...el, track: { ...el.track, isTemplate } };
        }
        return el;
      });

      return {
        ...state,
        campaigns,
        templates,
      };
    }

    case DRAFT_REMOVE_REQUESTING:
      return {
        ...state,
        requesting: true,
      };

    case DRAFT_REMOVE_SUCCESS: {
      const { _id } = action.payload;
      const drafts = state.drafts.filter(el => el._id !== _id);
      return {
        ...state,
        drafts,
        requesting: false,
      };
    }

    case CAMPAIGN_CREATE_EVENT_SUCCESS: {
      return {
        ...state,
        campaignSelected: {
          ...state.campaignSelected,
          events: [...state.campaignSelected.events, action.payload],
        },
      };
    }

    case GET_STEP_COMMENT_REQUESTING: {
      return {
        ...state,
        stepCommentRequesting: true,
      };
    }

    case GET_STEP_COMMENT_SUCCESS: {
      /*
          We update activeStep if concerned and stepSelected
      */
      return {
        ...state,
        stepSelectedComments: action.payload,
        stepCommentRequesting: false,
      };
    }

    case GET_STEP_COMMENT_ERROR: {
      return {
        ...state,
        stepCommentRequesting: false,
      };
    }

    case CAMPAIGN_CREATE_EVENT_SUCCESS: {
      return {
        ...state,
        campaignSelected: {
          ...state.campaignSelected,
          events: [...state.campaignSelected.events, action.payload],
        },
      };
    }

    case CAMPAIGN_SEND_EMAIL_RECAP_REQUESTING: {
      return {
        ...state,
        isEmailRecapRequesting: true,
        campaignSelected: {
          ...state.campaignSelected,
          events: [...state.campaignSelected.events, action.payload],
        },
      };
    }

    case CAMPAIGN_UPDATE_EVENT_SUCCESS: {
      const newEvents = state.campaignSelected?.events?.filter(
        event => event._id !== action.payload._id,
      );

      return {
        ...state,
        campaignSelected: {
          ...state.campaignSelected,
          events: [...newEvents, action.payload],
        },
      };
    }

    case CAMPAIGN_DELETE_EVENT_SUCCESS: {
      const { eventId } = action.payload;

      const newEvents = state.campaignSelected?.events?.filter(
        event => event._id !== eventId,
      );
      return {
        ...state,
        campaignSelected: {
          ...state.campaignSelected,
          events: newEvents,
        },
      };
    }

    case CAMPAIGN_SEND_EMAIL_RECAP_ERROR:
    case CAMPAIGN_SEND_EMAIL_RECAP_SUCCESS: {
      return {
        ...state,
        isEmailRecapRequesting: false,
      };
    }

    case CAMPAIGN_GET_USER_RANKING_REQUESTING:
      return {
        ...state,
        userRankingRequesting: true,
      };

    case CAMPAIGN_CLOSE_SNACK_MESSAGE:
      return {
        ...state,
        snackMessage: null,
      };

    case CAMPAIGN_SELECT_TAB:
      return {
        ...state,
        tabSelected: action.payload,
      };

    case CAMPAIGN_SELECT_PREVIEW_TAB:
      return {
        ...state,
        previewTabSelected: action.payload,
      };

    case CHANGE_ADD_USERS_MODAL:
      return {
        ...state,
        ...action.payload,
      };

    case AUTH_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
