import React from "react";

const SurveyIcon = ({ sizePercentage, color, className }) => (
  <svg
    className={className}
    width={sizePercentage * 16}
    height={sizePercentage * 17}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 16 17"
    version="1.1"
  >
    <rect
      x="0.192383"
      y="4.38226"
      width="4.01337"
      height="12.074"
      rx="1"
      fill={color}
    />
    <rect
      x="5.8623"
      y="0.417847"
      width="4.01337"
      height="16.0384"
      rx="1"
      fill={color}
    />
    <rect
      x="11.5322"
      y="7.10004"
      width="4.01337"
      height="9.35613"
      rx="1"
      fill={color}
    />
  </svg>
);

SurveyIcon.defaultProps = {
  sizePercentage: 1,
  color: "#fff",
};

export default SurveyIcon;
