import React, { useMemo } from "react";
import { makeStyles } from "@mui/styles";
import { MainText } from "components/Texts/MainText";
import CloseIcon from "components/SelectInput/CloseIcon";
import FlatButton from "components/FlatButton";
import NotificationIcon from "../SideBar/NotificationIcon";
import DownloadIcon from "../DownloadIcon";
import { CSVLink } from "react-csv";
import i18n from "i18n";
import { filters } from "./FilterParticipant";
import { FilterParticipantsType } from "./types";
import AwardIcon from "components/AwardIcon";
import { useSelector } from "react-redux";
import { getIsSuperAdmin } from "services/user/selectors";
import { getUserCampaigns } from "services/campaign/selectors";
import {
  getCampaignSurveyStatsForExportBtoC,
  getCampaignSurveys,
} from "services/survey/selectors";
import { getSelectedClientIsBtoCAccount } from "services/myClients/selectors";
import { getLanguageSelected } from "services/createTrack/selectors";
import selectLanguage from "utils/selectLanguage";
import moment from "moment";
import useZipeDownload from "hooks/useZipDownload";
import { getCampaignSelectedNotificationsHistory } from "services/notification/selectors";

const CSVHeaders = [
  { label: i18n.t("firstName"), key: "firstName" },
  { label: i18n.t("lastName"), key: "lastName" },
  { label: i18n.t("email"), key: "email" },
  { label: i18n.t("done-actions"), key: "points" },
  { labe: i18n.t("Mbti"), key: "mbti" },
  { label: i18n.t("table-header-last-action"), key: "lastDoneDate" },
  { label: i18n.t("table-header-last-registered"), key: "createdAt" },
];

const useStyles = makeStyles(theme => ({
  selectedActionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 13,
    marginBottom: 31,
    alignItems: "flex-end",
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  selectedParticipants: {
    height: "fit-content",
    alignItems: "center",
  },
  removeSelectionContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },
  removeSelectionText: {
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": { opacity: 0.8 },
    color: "rgba(0, 126, 252, 1)",
  },
  notificationButton: {
    padding: 16,
    marginRight: 22,
    backgroundColor: "rgba(244, 250, 255, 1)",
    color: "rgba(0, 126, 252, 1)",
    opacity: 0.35,
    borderRadius: 8,
    "&:focus": {
      opacity: 0.35,
      backgroundColor: "rgba(244, 250, 255, 1)",
    },
    "&:hover": {
      opacity: 0.35,
      backgroundColor: "rgba(244, 250, 255, 1)",
    },
  },
  icon: {
    marginRight: 16,
    display: "flex",
    alignItems: "center",
  },
  csvContainer: {
    opacity: 0.25,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: "rgba(0, 126, 252, 1)",
    cursor: "pointer",
    "&:focus": {
      opacity: 0.25,
      color: "rgba(0, 126, 252, 1)",
    },
    "&:hover": {
      opacity: 0.25,
      color: "rgba(0, 126, 252, 1)",
    },
  },
  buttonActive: {
    opacity: 1,
    "&:focus": {
      opacity: 0.8,
    },
    "&:hover": {
      opacity: 0.8,
    },
  },
  notificationSent: {
    cursor: "pointer",
    backgroundColor: "rgba(113, 184, 97, 1)",
    paddingTop: 2,
    paddingRight: 13,
    paddingLeft: 13,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    width: "fit-content",
    marginRight: 30,
  },
}));

interface Props {
  usersSelected: string[];
  isAUserSelected: boolean;
  openSendNotificationModal: () => void;
  setIsHistoryNotificationModalVisible: (open: boolean) => void;
  cancelSelection: (selectedFilter: FilterParticipantsType) => void;
}

const SelectedActions = ({
  usersSelected = [],
  isAUserSelected,
  openSendNotificationModal,
  setIsHistoryNotificationModalVisible,
  cancelSelection,
}: Props) => {
  const classes = useStyles();

  const isSuperAdmin = useSelector(getIsSuperAdmin);
  const userCampaigns = useSelector(getUserCampaigns);
  const surveys = useSelector(getCampaignSurveys);
  const isBtoCAccount = useSelector(getSelectedClientIsBtoCAccount);
  const language = useSelector(getLanguageSelected);
  const dataWithSurveys = useSelector(getCampaignSurveyStatsForExportBtoC);
  const notificationsList = useSelector(
    getCampaignSelectedNotificationsHistory,
  );

  const certificateSelectedToDownload = useMemo(() => {
    // const dataToMap = isBtoCAccount ? dataWithSurveys : userCampaigns;
    const dataToMap = dataWithSurveys;
    const results = dataToMap.map((userCampaign, index) => {
      const { firstName, lastName, certificateUrl, userId } = userCampaign;

      if (
        !usersSelected.includes(userId) ||
        certificateUrl === undefined ||
        certificateUrl === "" ||
        certificateUrl === null
      ) {
        return false;
      }

      return {
        url: certificateUrl,
        name: `${firstName} ${lastName} ${index}.pdf`,
      };
    });

    return results.filter(el => el);
  }, [usersSelected, userCampaigns, dataWithSurveys, isBtoCAccount]);

  const [generateZipFile] = useZipeDownload();

  const downloadCertification = (userSelected = []) => () => {
    if (isAUserSelected) {
      generateZipFile(`${i18n.t("participants")}-certificat`, userSelected);
    }
  };

  const getCSVHeaders = () => {
    if (!isBtoCAccount) {
      const surveyQuestions =
        surveys?.[0]?.questions?.map((question, i) => {
          return {
            label: selectLanguage({ text: question?.questionTitle, language }),
            key: question?._id,
          };
        }) || [];

      return [
        ...CSVHeaders,
        {
          label: i18n.t("table-header-phoneNumber"),
          key: "phoneNumber",
        },
        {
          label: i18n.t("device-os"),
          key: "deviceOs",
        },
        ...surveyQuestions,
      ];
    } else if (isSuperAdmin) {
      return [
        ...CSVHeaders,
        {
          label: i18n.t("table-header-phoneNumber"),
          key: "phoneNumber",
        },
      ];
    }
    return CSVHeaders;
  };

  const formatDataForExport = () => {
    const dataToMap = dataWithSurveys;
    const results = dataToMap.map(userCampaign => {
      const {
        firstName,
        lastName,
        points,
        email,
        createdAt,
        phoneNumber,
        deviceOs,
        steps,
        surveyAnswers = [],
        userId,
        mbti,
      } = userCampaign;

      if (!usersSelected.includes(userId)) {
        return false;
      }

      const surveyAnswersObject = {};

      // if (isBtoCAccount) {
      surveyAnswers.map((el, i) => {
        if (el?.choices?.length) {
          const choices = el?.choices?.map(choice =>
            selectLanguage({ text: choice, language }),
          );
          const choicesString = choices?.join("\n");

          surveyAnswersObject[el.questionId] = choicesString;
        } else {
          surveyAnswersObject[el.questionId] = el?.value;
        }
        return false;
      });
      // }

      const lastDoneStepIndex = steps.findIndex(el => el!.done) - 1;
      const lastDoneStep =
        steps?.[lastDoneStepIndex > -1 ? lastDoneStepIndex : 0];
      const lastDoneDate = lastDoneStep?.doneDate || "";

      return {
        email,
        firstName,
        lastName,
        mbti,
        points,
        phoneNumber: isSuperAdmin && phoneNumber ? phoneNumber : "",
        deviceOs: isBtoCAccount && deviceOs,
        ...surveyAnswersObject,
        createdAt: moment(createdAt).format("LL") || " ",
        lastDoneDate: lastDoneDate ? moment(lastDoneDate).format("LL") : " ",
      };
    });
    return results.filter(el => el);
  };

  return (
    <div className={classes.selectedActionContainer}>
      <div className={classes.selectedParticipants}>
        {usersSelected.length > 0 && (
          <>
            <MainText
              size="medium"
              text={`${usersSelected.length} ${i18n.t(
                "participants-selected",
              )}`}
            />
            <div
              className={classes.removeSelectionContainer}
              onClick={() => cancelSelection(filters.cancelSelection)}
            >
              <CloseIcon
                sizePercentage={1.5}
                className={classes.icon}
                color={"rgba(0, 126, 252, 1)"}
              />
              <MainText
                size="medium"
                text={i18n.t("remove-selection-button")}
                className={classes.removeSelectionText}
              />
            </div>
          </>
        )}
      </div>
      <div className={classes.actionContainer}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <div
            className={classes.notificationSent}
            onClick={() => setIsHistoryNotificationModalVisible(true)}
          >
            <MainText
              style={{ color: "white" }}
              text={i18n.t(
                notificationsList.length > 1
                  ? "notifications-history-participant-header-text"
                  : "notification-history-participant-header-text",
                {
                  number: notificationsList.length,
                },
              )}
            />
          </div>
          <FlatButton
            icon={
              <NotificationIcon
                className={classes.icon}
                color={"rgba(0, 126, 252, 1)"}
              />
            }
            title={i18n.t("send-notification")}
            onClick={openSendNotificationModal}
            className={`${classes.notificationButton} ${
              isAUserSelected && classes.buttonActive
            }`}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: "fit-content",
          }}
        >
          <FlatButton
            icon={
              <AwardIcon
                color="rgba(0, 126, 252, 1)"
                className={classes.icon}
              />
            }
            title={i18n.t("get-certificate")}
            onClick={downloadCertification(certificateSelectedToDownload)}
            className={`${classes.notificationButton} ${
              isAUserSelected && classes.buttonActive
            }`}
          />
          <div
            className={`${classes.csvContainer} ${
              isAUserSelected && classes.buttonActive
            }`}
          >
            <CSVLink
              style={{ textDecoration: "none", "&:focus": {} }}
              filename={`${i18n.t("participants")}.csv`}
              headers={getCSVHeaders()}
              target="_blank"
              data={formatDataForExport()}
              onClick={event => isAUserSelected}
            >
              <DownloadIcon color="rgba(0, 126, 252, 1)" />
            </CSVLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectedActions;
