import React from "react";

const RatingDescIcon = ({ sizePercentage, color, className }) => (
  <svg
    // width={sizePercentage * 24}
    className={className}
    width={sizePercentage * 10}
    height={sizePercentage * 10}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 10 10"
    version="1.1"
  >
    <path
      fill={color}
      d="M9.69062 3.56825C9.61955 3.34977 9.4077 3.19966 9.10952 3.1564L6.67919 2.80383L5.5923 0.605595C5.45897 0.33584 5.25049 0.181152 5.02037 0.181152C4.79026 0.181152 4.58178 0.33584 4.44838 0.605595L3.36163 2.80383L0.931149 3.1564C0.632972 3.19966 0.421125 3.34977 0.350055 3.56825C0.278986 3.78673 0.361948 4.03246 0.577736 4.24235L2.33642 5.9535L1.92118 8.36978C1.86809 8.67879 1.95858 8.86402 2.04383 8.96493C2.14392 9.08335 2.28971 9.14858 2.45449 9.14858C2.57857 9.14858 2.71047 9.11239 2.84659 9.04095L5.02037 7.90014L7.19423 9.04095C7.33035 9.11239 7.46224 9.14858 7.58626 9.14865C7.75103 9.14865 7.89697 9.08335 7.99698 8.96493C8.08224 8.86402 8.17272 8.67879 8.11956 8.36978L7.70447 5.9535L9.46301 4.24242C9.6788 4.03246 9.76176 3.78673 9.69062 3.56825Z"
    />
  </svg>
);

RatingDescIcon.defaultProps = {
  sizePercentage: 1,
  color: "#FFD059",
};

export default RatingDescIcon;
