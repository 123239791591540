import {
  UPLOAD_PICTURE_REQUESTING,
  UPLOAD_PICTURE_SUCCESS,
  UPLOAD_PICTURE_ERROR,
  UPLOAD_RESET,
  SEARCH_UNSPLASH_ERROR,
  SEARCH_UNSPLASH_REQUESTING,
  SEARCH_UNSPLASH_SUCCESS,
  GET_RANDOM_UNSPLASH_REQUESTING,
  GET_RANDOM_UNSPLASH_SUCCESS,
  GET_RANDOM_UNSPLASH_ERROR,
  UPLOAD_FILE_REQUESTING,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_ERROR,
  UPLOAD_PROGRESS_CHANGE,
  DOWNLOAD_FILE_SUCCESS,
  DOWNLOAD_FILE_ERROR,
  DOWNLOAD_FILE_REQUESTING,
  TEXT_EDITOR_PICTURE_UPLOADING,
  TEXT_EDITOR_PICTURE_UPLOADED_SUCCESS,
  TEXT_EDITOR_PICTURE_UPLOADED_ERROR,
  UPDATE_FILES_TO_UPLOAD,
  EXPORT_VIDEO_DONE,
} from "./constants";

import { AUTH_LOGOUT_SUCCESS } from "../auth/reducer";
import {
  CREATE_COACHING_FILE_SUCCESS,
  CREATE_COACHING_FILE_ERROR,
  CREATE_COACHING_FILE_REQUESTING,
} from "../coaching/constants";

const initialState = {
  picture: "",
  fileUrl: "",
  file: "",
  fileName: "",
  fileType: "",
  uploading: false,
  downloading: false,
  isExportingVideo: false,
  requesting: false,
  unsplashResults: [],
  uploadProgressPercent: 0,
  downloadProgress: 0,
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_COACHING_FILE_REQUESTING:
    case TEXT_EDITOR_PICTURE_UPLOADING:
    case UPLOAD_FILE_REQUESTING:
    case UPLOAD_PICTURE_REQUESTING: {
      return {
        ...state,
        ...action.payload,
        uploading: true,
        uploadProgressPercent: 0,
      };
    }

    case DOWNLOAD_FILE_REQUESTING:
      return {
        ...state,
        isExportingVideo: action.payload.isExportingVideo,
        downloading: true,
        downloadProgress: 0,
      };

    case EXPORT_VIDEO_DONE: {
      return {
        ...state,
        isExportingVideo: false,
      };
    }

    case DOWNLOAD_FILE_SUCCESS:
    case DOWNLOAD_FILE_ERROR:
      return {
        ...state,
        downloading: false,
        isExportingVideo: false,
        downloadProgress: 0,
      };

    case GET_RANDOM_UNSPLASH_REQUESTING:
    case SEARCH_UNSPLASH_REQUESTING: {
      return {
        ...state,
        requesting: true,
      };
    }

    case GET_RANDOM_UNSPLASH_SUCCESS:
    case SEARCH_UNSPLASH_SUCCESS: {
      return {
        ...state,
        requesting: false,
        unsplashResults: action.payload,
      };
    }

    case GET_RANDOM_UNSPLASH_ERROR:
    case SEARCH_UNSPLASH_ERROR: {
      return {
        ...state,
        requesting: false,
        error: action.payload,
      };
    }

    case CREATE_COACHING_FILE_SUCCESS:
    case UPLOAD_FILE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        uploading: false,
        requesting: false,
      };
    }

    case UPLOAD_PROGRESS_CHANGE: {
      return {
        ...state,
        uploadProgressPercent: action.payload.progress,
      };
    }

    case TEXT_EDITOR_PICTURE_UPLOADED_SUCCESS:
    case UPLOAD_PICTURE_SUCCESS: {
      return {
        ...state,
        picture: action.payload,
        uploading: false,
        requesting: false,
      };
    }

    case CREATE_COACHING_FILE_ERROR:
    case TEXT_EDITOR_PICTURE_UPLOADED_ERROR:
    case UPLOAD_FILE_ERROR:
    case UPLOAD_PICTURE_ERROR: {
      return {
        ...state,
        error: action.payload,
        uploadProgressPercent: 0,
        uploading: false,
        requesting: false,
      };
    }

    case UPDATE_FILES_TO_UPLOAD: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case UPLOAD_RESET:
    case AUTH_LOGOUT_SUCCESS: {
      return initialState;
    }

    default:
      return state;
  }
};

export default reducer;
