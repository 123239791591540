import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Colors from "../../../../constants/Colors";
import StatBox from "../StatBox";
import RankingSection from "../RankingSection";
import FeedbackSection from "../FeedbackSection";

import { useDispatch, useSelector } from "react-redux";

import i18n from "../../../../i18n";
import { getAppLanguage } from "../../../../services/general/selectors";
import EvolutionSection from "../EvolutionSection";
import ParticipantIcon from "scenes/Campaigns/CampaignScreen/StatsSide/ParticipantIcon";
import LightIcon from "scenes/CreateTrack/LightIcon";
import PulseIcon from "scenes/Campaigns/CampaignScreen/StatsSide/PulseIcon";
import FeelingSection from "scenes/Campaigns/CampaignScreen/StatsSide/FeelingSection";
import ObjectivesSection from "scenes/Campaigns/CampaignScreen/StatsSide/ObjectivesSection";
import { getSelectedClient } from "services/myClients/selectors";
import {
  getCampaignOnboardingSurveyStats,
  getFeelingEvolutionSurveyStats,
} from "services/survey/selectors";
import {
  getCampaign,
  getCampaignSelected,
  getCampaignSelectedActionPerParticipants,
  getCampaignSelectedParticipationRate,
} from "services/campaign/selectors";
import { getUserCampaignsListRequest } from "services/campaign/actions";
import InteractionIcon from "./InteractionIcon";
import TeamDialog from "./TeamDialog";
import ToolTip from "components/ToolTip";
import Tippy from "@tippyjs/react";
import CustomArrowTooltip from "components/CustomArrowTooltip";
import { getIsSuperAdmin } from "services/user/selectors";

const useStyle = makeStyles({
  rightDiv: {
    position: "relative",
    height: "100%",
    marginBottom: 30,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    alignSelf: "center",
  },
  firstSection: {
    overflow: "auto",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    display: "flex",
  },
});

const StatsSide = () => {
  const [teamDialogIsOpen, setTeamDialogIsOpen] = useState(false);
  const [teamSelected, setTeamSelected] = useState({ users: [] });

  const language = useSelector(getAppLanguage);
  const selectedClient = useSelector(getSelectedClient);
  const { hasCustomOnboarding = false } = selectedClient;
  const { doneSteps, usersInfos = [] } = useSelector(getCampaign);

  const campaignSelected = useSelector(getCampaignSelected);

  const isSuperAdmin = useSelector(getIsSuperAdmin);

  const {
    participants,
    createdAt,
    onboardingSurveyId,
    teamSize,
    interactions,
  } = campaignSelected;
  const campaignId = campaignSelected._id;

  const userInfoReduce = usersInfos.reduce(
    (prev, curr) => {
      prev.topicChoices.push(curr.topicChoices);
      prev.stats.push(curr.stats);
      prev.feelingEvolution.push(curr.feelingEvolution);
      return prev;
    },
    { topicChoices: [], stats: [], feelingEvolution: [] },
  );

  const onboardingSurveyStats = useSelector(getCampaignOnboardingSurveyStats);

  const actionPerParticipants = useSelector(
    getCampaignSelectedActionPerParticipants,
  );
  const participationRate = useSelector(getCampaignSelectedParticipationRate);
  const onBoardingSurveyFeelingEvolution = useSelector(
    getFeelingEvolutionSurveyStats,
  );
  const topicChoices = !onboardingSurveyId
    ? userInfoReduce.topicChoices
    : onboardingSurveyStats.topicChoices;
  const stats = !onboardingSurveyId
    ? userInfoReduce.stats
    : onboardingSurveyStats.stats;
  const feelingEvolution = !onboardingSurveyId
    ? userInfoReduce.feelingEvolution
    : onBoardingSurveyFeelingEvolution;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getUserCampaignsListRequest({
        campaignId: campaignId,
        page: 0,
        limit: 1000,
      }),
    );
  }, [dispatch, campaignId]);

  const handleClickTeam = team => {
    setTeamSelected(team);
    setTeamDialogIsOpen(true);
  };

  const handleClose = () => {
    setTeamDialogIsOpen(false);
  };

  const classes = useStyle();

  return (
    <div className={classes.rightDiv}>
      <TeamDialog
        team={teamSelected}
        teamDialogIsOpen={teamDialogIsOpen}
        handleClose={handleClose}
      />
      <div className={classes.firstSection}>
        <StatBox
          icon={<ParticipantIcon />}
          title={i18n.t("Participants")}
          stat={participants.length}
        />
        <StatBox
          icon={<LightIcon color={"#F9A849"} sizePercentage={2} />}
          title={"Actions"}
          stat={doneSteps.length}
          subTitle={`  (${actionPerParticipants} / pers)`}
          lightColor={"rgba(238, 134, 13, 0.22)"}
        />
        <StatBox
          icon={<PulseIcon />}
          title={i18n.t("participation-rate")}
          stat={`${participationRate}%`}
          chartColor={Colors.orange}
          lightColor={"rgba(252, 23, 174, 0.22)"}
        />

        <StatBox
          hoverComponent={
            isSuperAdmin ? (
              <div>
                {interactions
                  ? Object.keys(interactions).map(el => (
                      <p>
                        {el}:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {interactions[el]}{" "}
                        </span>
                      </p>
                    ))
                  : 0}
              </div>
            ) : null
          }
          isLast={true}
          icon={<InteractionIcon width={35} height={36} />}
          title={i18n.t("campaign-interactions")}
          stat={
            interactions
              ? Object.values(interactions).reduce((a, b) => a + b, 0)
              : 0
          }
          lightColor={"rgba(233, 255, 230, 1)"}
        />
      </div>
      <EvolutionSection
        createdAt={createdAt}
        doneSteps={doneSteps}
        participants={participants}
      />
      <RankingSection handleClickTeam={handleClickTeam} isTeam={teamSize > 1} />
      {!(hasCustomOnboarding && !onboardingSurveyId) && (
        <FeelingSection
          stats={stats}
          feelingEvolution={feelingEvolution}
          hasOnboardingSurvey={!!onboardingSurveyId}
        />
      )}
      {!(hasCustomOnboarding && !onboardingSurveyId) &&
        topicChoices.length > 0 && (
          <ObjectivesSection topicChoices={topicChoices} language={language} />
        )}
      <FeedbackSection />
    </div>
  );
};

export default StatsSide;
